import TaskGetters from '@/store/task/_getters'
import TaskMutations from '@/store/task/_mutations'
import TaskActions from '@/store/task/_actions'

import { Task } from '@/model/Task'

export class TaskState {
    tasks: Array<Task> = []
    tasksMap: { [taskId: string]: Task } = {}
}

const TaskSubModule = {
    namespaced: true,
    state: new TaskState(),
    getters: TaskGetters,
    mutations: TaskMutations,
    actions: TaskActions
}

export default TaskSubModule
