<template>
    <div class="form-input">
        <div
            v-if="label"
            class="form-input__label"
        >
            <span
                v-if="required"
                class="form-input__label_required"
            >*</span>
            <span class="form-input__label_text">{{ label }}:</span>
        </div>
        <slot name="form-input-body" />
    </div>
</template>

<script>
export default {
    props: {
        label: { type: String, default: '' },
        required: { type: Boolean, default: false }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/colors.styl'

.form-input__label
    height 40px
    .form-input__label_required
        margin-right 5px
        color $red
    .form-input__label_text
        line-height 40px
</style>
