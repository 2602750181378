import { MutationTree } from 'vuex'
import { DirectoryState } from '@/store/directories/directoryState'
import Vue from 'vue'

import { Directory, Record } from '@/model/Directory'

const DirectoryMutations: MutationTree<DirectoryState> = {
    putDirectory(state: DirectoryState, dir: Directory) {
        state.directories.push(dir)
        Vue.set(state.directoriesObject, dir.id, dir)
        Vue.set(state.dirsModificationTime, dir.id, dir.modificationTime)
    },

    replaceDirectory(state: DirectoryState, dir: Directory) {
        const index = state.directories.findIndex((d: Directory) => d.id === dir.id)

        if (index === -1) {
            console.warn('cannot find directory:: replaceDirectory:: directoryId: ', dir.id)
        } else {
            Vue.set(state.directories, index, dir)
            state.directoriesObject[dir.id] = dir
            state.dirsModificationTime[dir.id] = dir.modificationTime
        }
    },

    removeDirectory(state: DirectoryState, directoryId: string) {
        const index = state.directories.findIndex((dir: Directory) => dir.id === directoryId)
        if (index === -1) {
            console.warn('cannot find directory:: removeDirectory: directoryId', directoryId)
        } else {
            state.directories.splice(index, 1)
            Vue.delete(state.directoriesObject, directoryId)
            Vue.delete(state.dirsModificationTime, directoryId)
        }
    },

    ////////////////////////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////  RECORD      //////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////

    putRecord(state: DirectoryState, record: Record) {
        state.records.push(record)
        Vue.set(state.recordsObject, record.id, record)
    },

    replaceRecord(state: DirectoryState, record: Record) {
        const index = state.records.findIndex((r: Record) => r.id === record.id)

        if (index === -1) {
            console.warn('cannot find record:: replaceRecord: recordId', record.id)
        } else {
            Vue.set(state.records, index, record)
            state.recordsObject[record.id] = record
        }
    },

    removeRecord(state: DirectoryState, recordId: string) {
        const index = state.records.findIndex((r: Record) => r.id === recordId)
        if (index === -1) {
            console.warn('cannot find record:: deleteRecord: recordId', recordId)
        } else {
            state.records.splice(index, 1)
            delete state.recordsObject[recordId]
        }
    },

    cleanUpState(state: DirectoryState) {
        state.directories = []
        state.directoriesObject = {}
        state.dirsModificationTime = {}
        state.records = []
        state.recordsObject = {}
    }
}

export default DirectoryMutations
