var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "work-flow-stage",
    class: {
      'work-flow-stage_active': _vm.active,
      'work-flow-stage_passed': _vm.passed
    }
  }, _vm.$listeners), [_c('div', {
    staticClass: "work-flow-stage__header",
    class: {
      'work-flow-stage__header_approval': _vm.stageType === 'approval',
      'work-flow-stage__header_execution': _vm.stageType === 'execution' || _vm.stageType === 'fluent'
    }
  }, [_vm.stageType === 'draft' ? _c('DWF_ApprovalHint', {
    staticClass: "work-flow-document__hint"
  }) : _vm._e(), _vm._v(" "), _vm.stageType === 'approval' ? _c('ApprovalActions', {
    staticClass: "work-flow-stage__approval-actions",
    attrs: {
      "checked-approvals": _vm.checkedApprovals
    }
  }) : _vm._e(), _vm._v(" "), _vm.showAddTask ? _c('ButtonViolet', {
    staticClass: "mr-1",
    attrs: {
      "small": true,
      "text": "Добавить задачу"
    },
    on: {
      "click": _vm.addTask
    }
  }) : _vm._e(), _vm._v(" "), _c('h3', {
    staticClass: "work-flow-stage__h3",
    class: {
      'pink-1': _vm.active,
      'green-1': _vm.passed
    }
  }, [_vm._v("\n            " + _vm._s(_vm.stage.displayName) + "\n        ")])], 1), _vm._v(" "), _vm.stageType === 'draft' ? _c('DWF_Draft', {
    attrs: {
      "passed": _vm.passed,
      "svg-width": _vm.svgWidth,
      "author": _vm.openDocument.base.author
    }
  }) : _vm._e(), _vm._v(" "), _vm.stageType === 'approval' ? _c('DWF_ApprovalStage', {
    attrs: {
      "approvals": _vm.stage.graph,
      "active": _vm.active,
      "svgWidth": _vm.svgWidth,
      "status": _vm.openDocument.base.status
    },
    on: {
      "checked": function checked($event) {
        _vm.checkedApprovals = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.stageType === 'execution' || _vm.stageType === 'fluent' ? _c('DWF_TaskStage', {
    attrs: {
      "tasks": _vm.stage.graph,
      "stage-index": _vm.stageIndex,
      "stage-type": _vm.stage.type,
      "active": _vm.active
    }
  }) : _vm._e(), _vm._v(" "), _c('DWF_AddTask', {
    attrs: {
      "visible": _vm.visibleAddTaskModal,
      "graph": _vm.stage.graph
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleAddTaskModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }