var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "edit-record"
  }, [_vm._l(_vm.directoryFields, function (field, index) {
    return _c('th', {
      key: field.name
    }, [field.type === 'string' ? _c('InputBase', {
      attrs: {
        "placeholder": 'Введите ' + field.name
      },
      model: {
        value: _vm.cells[index],
        callback: function callback($$v) {
          _vm.$set(_vm.cells, index, $$v);
        },
        expression: "cells[index]"
      }
    }) : _vm._e(), _vm._v(" "), field.type === 'number' ? _c('InputNumber', {
      model: {
        value: _vm.cells[index],
        callback: function callback($$v) {
          _vm.$set(_vm.cells, index, $$v);
        },
        expression: "cells[index]"
      }
    }) : _vm._e(), _vm._v(" "), field.type === 'boolean' ? _c('Select', {
      staticClass: "h-36",
      attrs: {
        "options": _vm.booleanOptions,
        "placeholder": "Выбрать значение"
      },
      model: {
        value: _vm.cells[index],
        callback: function callback($$v) {
          _vm.$set(_vm.cells, index, $$v);
        },
        expression: "cells[index]"
      }
    }) : _vm._e(), _vm._v(" "), field.type === 'time' ? _c('DateTimeInput', {
      staticClass: "record-edit__datetimepicker",
      attrs: {
        "value": _vm.cells[index]
      },
      model: {
        value: _vm.cells[index],
        callback: function callback($$v) {
          _vm.$set(_vm.cells, index, $$v);
        },
        expression: "cells[index]"
      }
    }) : _vm._e(), _vm._v(" "), field.type === 'reference' ? _c('div', {
      staticClass: "record-edit__reference",
      on: {
        "click": function click($event) {
          return _vm.onClickEditReference(field.subType, index);
        }
      }
    }, [_c('div', {
      staticClass: "record-edit__reference_name"
    }, [_vm._v("\n                " + _vm._s(_vm.referenceCells[_vm.cells[index]]) + "\n            ")]), _vm._v(" "), _c('div', {
      staticClass: "record-edit__reference_show pink-1"
    }, [_vm._v("\n                …\n            ")])]) : _vm._e()], 1);
  }), _vm._v(" "), _c('th', {
    staticClass: "record-edit__icons-cell"
  }, [_c('RecordSelectModal', {
    ref: "refReference",
    on: {
      "changeItem": function changeItem($event) {
        return _vm.changeReference($event);
      },
      "close": function close($event) {
        _vm.selectedIndex = null;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "record-edit__icons"
  }, [_c('div', {
    attrs: {
      "tooltip": _vm.emptyRequiredFields ? 'Заполните обязательные поля' : 'Сохранить элемент',
      "flow": "left"
    },
    on: {
      "click": _vm.canSave
    }
  }, [_c('FAIcon', {
    staticClass: "record-edit__icon pink-1",
    class: {
      'gray': !_vm.changingFields || _vm.emptyRequiredFields
    },
    attrs: {
      "icon": "save"
    }
  })], 1), _vm._v(" "), _c('div', {
    attrs: {
      "tooltip": "Закрыть",
      "flow": "left"
    }
  }, [_c('ButtonClose', {
    staticClass: "record-edit__icon",
    attrs: {
      "color": "violet"
    },
    on: {
      "click": _vm.emitClose
    }
  })], 1)])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }