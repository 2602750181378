import { Numerator, NumeratorField } from '@/model/numerator.js'

export default function (state, { numerator }) {
    state.versions[numerator.id]++
    state.savesNumerators[numerator.id] = new Numerator(
        numerator.id,
        numerator.centerId,
        numerator.name,
        numerator.fields.map(field => {
            return new NumeratorField(
                field.id,
                field.type,
                field.code,
                field.text,
                field.pad,
                field.resetPeriod
            )
        })
    )
}
