













































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors';
import { User } from '@/model/User'
import { Unit } from '@/model/Unit'
import { NodeTemplate } from '@/tools/template_builder/viewData'
import { SelectOption } from '@/components/common/select/model'

import Modal from '@/components/common/modals/Modal.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard.vue'
import Button from '@/components/common/buttons/Button.vue'

@Component({
    components: {
        Modal,
        RadioButton,
        ActionSelectCard,
        Button
    }
})

export default class DWF_AddActorModal extends Vue {
    // *************************************************************
    // DATA PARAMS
    node: NodeTemplate | null = null
    documentId = ''
    selectCardId = ''

    approvalType = 'user'
    approvalTypes: Array<SelectOption> = [
        { id: 'user', name: 'Сотрудник' },
        { id: 'unit', name: 'Подразделение' }
    ]

    visibleModal = false

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUsers') getUsers!: Array<User>
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>

    // *************************************************************
    // METHODS
    @Action('document/callAddActorToWorkflowApproval')
    callAddActorToWorkflowApproval!: (params: cuntPB.AddActorToWorkflowApprovalReq) => Promise<void>

    show(options: { node: NodeTemplate, documentId: string }): void {
        this.documentId = options.documentId
        this.node = options.node

        this.visibleModal = true

        if (options.node.actor) {
            if (options.node.actor.user) {
                this.approvalType = 'user'
                this.selectCardId = options.node.actor.user.id ?? ''
            } else if (options.node.actor.unit) {
                this.approvalType = 'unit'
                this.selectCardId = options.node.actor.unit.id ?? ''
            }
        }
    }

    close(): void {
        this.node = null
        this.documentId = ''
        this.approvalType = 'user'
        this.selectCardId = ''
        this.visibleModal = false
    }

    selectCard(entityId: string): void {
        this.selectCardId = entityId
    }

    changeApprovalType(): void {
        // Если сменили тип user/unit, то обнуляем selectCardId
        this.selectCardId = ''
    }

    save(): void {
        if (!this.selectCardId) {
            this.$snotify.warning('Выберите согласующего!')
            return
        }

        if (!this.node) {
            return
        }

        const cancelPreloaderAddActorToApproval = this.$preloader('add_actor_to_approval', 'Добавление участника согласования')
        this.callAddActorToWorkflowApproval({
            DocumentID: this.documentId,
            ApprovalID: this.node.id,
            Actor: {
                UserID: this.approvalType === 'user' ? this.selectCardId : '',
                UnitID: this.approvalType === 'unit' ? this.selectCardId : ''
            }
        })
            .then(() => this.close())
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderAddActorToApproval())
    }
}
