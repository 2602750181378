











































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import getError, { GrpcError } from '@/tools/errors/errors'
import { User } from '@/model/User'
import { Task, TASK_STATUS } from '@/model/Task'

import moment from 'moment'

import ButtonCreate from '@/components/common/buttons/ButtonCreate.vue'
import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import Button from '@/components/common/buttons/Button.vue'
import ListCount from '@/components/common/ListCount.vue'

import InputSearch from '@/components/common/inputs/InputSearch.vue'
import TaskTree from '@/components/tasks/TaskTree.vue'
import DateTimeInput from '@/components/common/dateTimePicker/DateTimeInput.vue'
import Checkbox from '@/components/common/inputs/Checkbox.vue'
import ModalCreateTask from '@/components/tasks/ModalCreateTask.vue'
import EmptyList from '@/components/common/EmptyList.vue'

@Component({
    components: {
        ListCount,
        ButtonCreate,
        ButtonViolet,
        InputSearch,
        TaskTree,
        DateTimeInput,
        Checkbox,
        ModalCreateTask,
        EmptyList,
        Button
    }
})

export default class Tasks extends Vue {
    // *************************************************************
    // DATA PARAMS
    showFilters = true

    tasksFilters: {
        searchText: string
        searchAuthor: string
        searchAssignee: string
        searchConfirmer: string

        notCompleted: boolean
        waitingForMyExecution: boolean
        waitingForMyConfirmation: boolean
        iAmAuthor: boolean
        overdue: boolean
        endDay: boolean
    } = {
        searchText: '',
        searchAuthor: '',
        searchAssignee: '',
        searchConfirmer: '',
        notCompleted: false,
        waitingForMyExecution: false,
        waitingForMyConfirmation: false,
        iAmAuthor: false,
        overdue: false,
        endDay: false
    }

    parent = ''
    startTime = 0
    endTime = 0
    visibleCreateModal = false

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    @Getter('company_structure/getUserById') getUserById!: (id: string) => User | null

    @Getter('task/getTasks') getTasks!: Array<Task>

    @Getter('user_options/getArchivedTasksReceived') getArchivedTasksReceived!: boolean

    get tab(): string | null {
        const type = this.$route.query.type
        if (!type) {
            return null
        }
        if (typeof type === 'string') {
            return type
        }
        return ''
    }

    get filteredTasks(): Array<Task> {
        return this.getTasks.filter((task: Task) => {
            // ждут моего выполнения
            const myExecution = task.status === TASK_STATUS.STATUS_ON_EXECUTION && task.assignee.user?.id === this.getMe.id
            if (this.tasksFilters.waitingForMyExecution && !this.tasksFilters.waitingForMyConfirmation) {
                if (!myExecution) {
                    return
                }
            }

            // ждут моего подтверждения
            const myConfirmation = task.status === TASK_STATUS.STATUS_ON_CONFIRMATION && task.confirmer && task.confirmer.user?.id === this.getMe.id
            if (this.tasksFilters.waitingForMyConfirmation && !this.tasksFilters.waitingForMyExecution) {
                if (!myConfirmation) {
                    return
                }
            }

            if (this.tasksFilters.waitingForMyConfirmation && this.tasksFilters.waitingForMyExecution) {
                if (!myExecution && !myConfirmation) {
                    return
                }
            }
            // задачи, в которых user - author
            if (this.tasksFilters.iAmAuthor) {
                if (task.author.user?.id !== this.getMe.id) {
                    return
                }
            }

            // незавершенные задачи
            if (this.tasksFilters.notCompleted) {
                if (task.status === TASK_STATUS.STATUS_CLOSED || task.status === TASK_STATUS.STATUS_CANCELED) {
                    return
                }
            }

            // просроченные задачи
            if (this.tasksFilters.overdue) {
                if (!task.dueTime || task.dueTime > Date.now()) {
                    return
                }
            }

            // необходимо закрыть сегодня
            if (this.tasksFilters.endDay) {
                if (!task.dueTime) {
                    return
                } else {
                    let due = moment(task.dueTime).startOf('date').unix()
                    let start = moment().startOf('date').unix()
                    if (due !== start) {
                        return
                    }
                }
            }
            // фильруем по автору задачи
            if (this.tasksFilters.searchAuthor) {
                const querySearch = this.tasksFilters.searchAuthor.toLowerCase().trim()
                if (!task.author.user?.name.shortName.toLowerCase().includes(querySearch)) {
                    return
                }
            }

            // Фильтруем по исполнителю
            if (this.tasksFilters.searchAssignee) {
                const querySearch = this.tasksFilters.searchAssignee.toLowerCase().trim()
                if (!task.assignee.user?.name.shortName.toLowerCase().includes(querySearch)) {
                    return
                }
            }

            // поиск по тексту и содержимому
            if (this.tasksFilters.searchText) {
                const querySearch = this.tasksFilters.searchText.toLowerCase().trim()
                if (!task.text.toLowerCase().includes(querySearch) && !task.description.toLowerCase().includes(querySearch)) {
                    return
                }
            }

            // поиск по confirmer
            if (this.tasksFilters.searchConfirmer) {
                const querySearch = this.tasksFilters.searchConfirmer.toLowerCase().trim()
                if (!task.confirmer) {
                    return
                } else if (!task.confirmer.user?.name.shortName.toLowerCase().includes(querySearch)) {
                    return
                }
            }

            const startTime = this.startTime * 1000
            const endTime = this.endTime * 1000
            // фильтрация по дате создания

            if (startTime && task.creationTime < startTime) {
                return
            }

            if (endTime && task.creationTime > endTime) {
                return
            }

            return true
        })
    }

    // *************************************************************
    // WATCH
    @Watch('tab', { immediate: true })
    handleTabChange(to: string | null): void {
        this.setChecked(to)
    }

    // *************************************************************
    // METHODS
    @Action('task/callArchivedTasksStream')
    callArchivedTasksStream!: () => Promise<void>

    @Action('user_options/setArchived')
    setArchived!: (key: string) => void

    create(taskId: string): void {
        this.visibleCreateModal = true
        this.parent = taskId
    }

    openTask(taskId: string): void {
        this.$router.push({
            name: 'document',
            params: {
                section: 'system',
                module: 'tasks',
                id: taskId
            }
        })
    }

    clearFilters(): void {
        this.tasksFilters = {
            searchText: '',
            searchAuthor: '',
            searchAssignee: '',
            searchConfirmer: '',
            notCompleted: false,
            waitingForMyExecution: false,
            waitingForMyConfirmation: false,
            iAmAuthor: false,
            overdue: false,
            endDay: false
        }
        this.parent = ''
        this.startTime = 0
        this.endTime = 0
    }

    clearDate(): void {
        this.startTime = 0
        this.endTime = 0
    }

    getArchivedTasks(): void {
        const cancelPreloaderArchivedTasksStream = this.$preloader('archived_tasks_stream', 'Получение архивных задач')

        this.callArchivedTasksStream()
            .then(() => {
                this.setArchived('tasks')
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderArchivedTasksStream())
    }

    setChecked(tab: string | null): void {
        if (!tab) {
            this.tasksFilters.notCompleted = false
            this.tasksFilters.waitingForMyExecution = false
            this.tasksFilters.waitingForMyConfirmation = false
            this.tasksFilters.iAmAuthor = false
            return
        }
        if (tab === 'incoming') {
            this.tasksFilters.notCompleted = false
            this.tasksFilters.waitingForMyExecution = true
            this.tasksFilters.waitingForMyConfirmation = true
            this.tasksFilters.iAmAuthor = false
            return
        }
        if (tab === 'outgoing') {
            this.tasksFilters.notCompleted = true
            this.tasksFilters.waitingForMyExecution = false
            this.tasksFilters.waitingForMyConfirmation = false
            this.tasksFilters.iAmAuthor = true
        }
    }
}
