var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Button', _vm._g({
    staticClass: "button-green",
    attrs: {
      "type": "button",
      "color": "green",
      "disabled": _vm.disabled,
      "add": true
    }
  }, _vm.$listeners), [_vm._v(_vm._s(_vm.text))]);
}
var staticRenderFns = []

export { render, staticRenderFns }