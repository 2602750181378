<template>
    <div class="requisites-type">
        <div class="requisites-type__header">
            <div class="requisites-type__title">
                Реквизиты типа
            </div>
            <ButtonAdd
                tooltip="Добавить реквизит"
                flow="left"
                @click="clickAddRequisite"
            />
        </div>
        <NewRequisiteCard
            v-if="showAddRequisiteCard"
            class="requisites-type__card"
            :type-id="typeId"
            @addedRequisite="showAddRequisiteCard = false"
        />

        <div class="requisites-type__cards">
            <RequisiteCard
                v-for="(requisite, index) in requisites"
                :key="requisite.id"
                :first="index === 0 || (requisites[index - 1] || {}).fromExternalSource"
                :last="index === requisites.length - 1 || (requisites[index + 1] || {}).fromExternalSource"
                :requisite="requisite"
                :type-id="typeId"
                class="requisites-type__card"
                @moveUp="moveUp(index, requisite.id)"
                @moveDown="moveDown(index, requisite.id)"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ButtonAdd from '@/components/common/buttons/ButtonAdd'
import NewRequisiteCard from '@/components/document_type/DocumentTypeCard/NewRequisiteCard'
import RequisiteCard from '@/components/document_type/DocumentTypeCard/RequisiteCard'

import { requisitesTypesDisplayName } from '@/components/document_type/util'
import errors from '@/tools/errors/errors.ts'

export default {
    name: 'DocumentTypeRequisites',
    components: {
        ButtonAdd,
        NewRequisiteCard,
        RequisiteCard
    },
    props: {
        typeId: {
            type: String,
            default: ''
        },
        oneAss: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            showAddRequisiteCard: false
        }
    },
    computed: {
        ...mapGetters('document_type', ['getTypeById']),
        requisites() {
            const type = this.getTypeById(this.typeId)
            if (!type) {
                return []
            }
            return type.requisites.map(requisite => {
                return Object.assign({
                    'typeDisplayName': requisitesTypesDisplayName[requisite.type]
                }, requisite)
            })
        }
    },
    methods: {
        ...mapActions('document_type', ['callRearangeRequisites']),
        clickAddRequisite() {
            this.showAddRequisiteCard = !this.showAddRequisiteCard
        },
        moveUp(index, id) {
            console.log('moveUp', index)
            let order = this.requisites.map(req => req.id)
            order.splice(index, 1)
            order.splice(index - 1, 0, id)
            this.rearangeRequisites(order)
        },
        moveDown(index, id) {
            console.log('moveDown', index)
            let order = this.requisites.map(req => req.id)
            order.splice(index, 1)
            order.splice(index + 1, 0, id)
            this.rearangeRequisites(order)
        },
        rearangeRequisites(order) {
            console.log('rearangeRequisites', this.typeId, order)
            this._cancelPreloaderAddDocumentTypeRequisite = this.$preloader('rearange_requisites', 'Изменение порядка реквизитов')
            this.callRearangeRequisites({
                TypeID: this.typeId,
                Order: order
            }).then(() => this._cancelPreloaderAddDocumentTypeRequisite())
                .catch(error => {
                    this._cancelPreloaderAddDocumentTypeRequisite()
                    if (error) {
                        this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                            timeout: 0
                        })
                    }
                })
        }
    }
}
</script>

<style lang="stylus" scoped>
.requisites-type
    display flex
    flex-direction column
    overflow hidden

.requisites-type__header
    display flex
    flex-direction row
    flex-wrap nowrap
    align-items center
    margin-bottom 10px
    padding-right 5px

.requisites-type__title
    flex-grow 1
    text-align left
    line-height 30px
    font-size 1.4rem

.requisites-type__cards
    flex 1
    padding-top 5px
    overflow-y auto

.requisites-type__card
    margin 0 5px 7px
    width calc(100% - 10px)
</style>
