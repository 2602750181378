var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.familiarization ? _c('div', {
    staticClass: "my-3"
  }, [_c('div', {
    staticClass: "flex-row-center justify-between mb-3"
  }, [_c('Checkbox', {
    attrs: {
      "label": "Ознакомлены"
    },
    model: {
      value: _vm.familiarized,
      callback: function callback($$v) {
        _vm.familiarized = $$v;
      },
      expression: "familiarized"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Не ознакомлены"
    },
    model: {
      value: _vm.notFamiliarized,
      callback: function callback($$v) {
        _vm.notFamiliarized = $$v;
      },
      expression: "notFamiliarized"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "familiarization__print",
    attrs: {
      "tooltip": "Распечатать журнал",
      "flow": "left"
    },
    on: {
      "click": _vm.print
    }
  }, [_c('FAIcon', {
    attrs: {
      "icon": "print"
    }
  })], 1)], 1), _vm._v(" "), _c('table', {
    ref: "table",
    staticClass: "table"
  }, [_vm._m(0), _vm._v(" "), _c('tbody', _vm._l(_vm.checklist, function (item) {
    return _c('tr', {
      key: item.user.id
    }, [_c('td', {
      style: {
        color: item.status ? 'black' : 'red'
      }
    }, [_vm._v("\n                    " + _vm._s(item.status ? 'Ознакомлен' : 'Не ознакомлен') + "\n                ")]), _vm._v(" "), _c('td', [_vm._v(_vm._s(item.user.name.fullName))]), _vm._v(" "), _c('td', [item.when ? _c('span', {
      style: {
        color: _vm.familiarization.until && item.when / 1000 > _vm.familiarization.until ? 'red' : 'black'
      }
    }, [_vm._v(_vm._s(item.creationTimeLocalizedLongString))]) : _vm._e()])]);
  }), 0)])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('th', [_vm._v("Статус")]), _vm._v(" "), _c('th', [_vm._v("Пользователь")]), _vm._v(" "), _c('th', [_vm._v("Дата")])]);
}]

export { render, staticRenderFns }