var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Dropdown', {
    staticClass: "approval-hint",
    attrs: {
      "position": "left"
    }
  }, [_c('div', {
    staticClass: "c-pointer",
    attrs: {
      "slot": "activator"
    },
    slot: "activator"
  }, [_c('FAIcon', {
    staticClass: "icon",
    attrs: {
      "icon": "question-circle"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row flex-nowrap approval-hint__body"
  }, [_c('div', {
    staticClass: "p-1"
  }, [_c('div', {
    staticClass: "fs-16 fw-600 mb-1"
  }, [_vm._v("\n                Согласование\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "approval-hint__text"
  }, [_vm._v("\n                Для согласования документа убедитесь, что в данный момент\n                подошла Ваша очередь согласовывать документ, а именно кружочек с Вашим именем\n                сейчас фиолетового цвета и моргает.\n                Если все верно, то в правом верхнем углу находится кнопка \"Согласовать\".\n                Просто нажмите ее и убедитесь, что кружочек с Вашей\n                фамилией больше не мигает, и стал зеленого цвета.\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "p-1"
  }, [_c('div', {
    staticClass: "fs-16 fw-600 mb-1"
  }, [_vm._v("\n                Отклонение\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "approval-hint__text"
  }, [_vm._v("\n                Для отклонения документа Вам необходимо убедиться, что в данный момент\n                цепочка согласования дошла до Вас - кружочек рядом с Вашей фамилией мигает и он фиолетового цвета.\n                "), _c('br'), _vm._v("\n                Если все верно - кликнете на тех сотрудников, к которым Вы хотели бы отклонить документ.\n                "), _c('br'), _vm._v("\n                Как только Вы выберете одного или нескольких сотрудников, в правом верхнем углу\n                появится кнопка \"Сбросить\" - просто нажмите ее.\n                "), _c('br'), _vm._v("\n                Учитывайте, что отклонять документ Вы можете только вниз по дереву - т.е. только тем сотрудникам,\n                которые стоят в цепочке согласования перед Вами.\n            ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }