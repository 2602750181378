





























import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Tab } from '@/components/common/tabs/model'
import { DocumentType } from '@/model/DocumentType'
import { Location } from 'vue-router'

import GoBack from '@/components/common/GoBack.vue'
import Tabs from '@/components/common/tabs/Tabs.vue'
import DocumentTypeCard from '@/components/document_type/DocumentTypeCard.vue'
import LifeCycleTemplateBuilder from '@/components/workflow_template_builder/LifeCycleTemplateBuilder.vue'

const tabs: Array<Tab> = [
    {
        id: 'type',
        name: 'Просмотр/Редактирование типа документа'
    },
    {
        id: 'workflow',
        name: 'Конструктор жизненного цикла'
    }
]

@Component({
    components: {
        GoBack,
        Tabs,
        DocumentTypeCard,
        LifeCycleTemplateBuilder
    }
})

export default class DocumentTypePage extends Vue {
    // *************************************************************
    // DATA PARAMS
    modes = tabs
    mode = 'type'

    // *************************************************************
    // COMPUTED
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    get typeId(): string {
        return this.$route.params.id
    }

    get type(): DocumentType | null {
        return this.getTypeById(this.typeId)
    }

    get oneAss(): boolean {
        if (!this.type) {
            return false
        }
        return !!this.type.oneAss?.collectionID
    }

    get goBackPath(): Location {
        return {
            name: 'module',
            params: {
                section: 'system',
                module: 'document-types'
            }
        }
    }

    // *************************************************************
    // WATCH
    @Watch('type', { immediate: true, deep: true })
    handleTypeChange(to: DocumentType | null, from: DocumentType | null): void {
        if (!to && !!from) {
            // удалили тип документа
            this.openTypes()
        }
    }

    // *************************************************************
    // METHODS
    openTypes(): void {
        this.$router.push(this.goBackPath)
    }
}
