var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "uploader"
  }, _vm.$listeners), [_c('input', {
    ref: "inputField",
    attrs: {
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.sendFile($event);
      }
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }