



















































































import { Component, Emit, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { DOC_STATUSES } from '@/model/Document'
import { DocumentType } from '@/model/DocumentType'
import { DocumentListFilters } from '@/model/UserOptions'
import { Unit } from '@/model/Unit'

import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import Checkbox from '@/components/common/inputs/Checkbox.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'
import TableSettings from '@/components/document/list/TableSettings.vue'

@Component({
    components: {
        ButtonViolet,
        Checkbox,
        RadioButton,
        TableSettings
    }
})

export default class DocumentsFilters extends Vue {
    // *************************************************************
    // DATA PARAMS
    visibleTableModal = false

    // *************************************************************
    // COMPUTED
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null
    @Getter('user_options/getDocumentListFilters') getDocumentListFilters!: (docTypeId: string) => DocumentListFilters
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>
    @Getter('user_options/getArchivedDocumentsReceived') getArchivedDocumentsReceived!: (documentTypeId: string) => boolean

    get typeId(): string {
        return this.$route.params.module
    }

    get allStatuses(): { [status: string]: string } {
        if (!this.getArchivedDocumentsReceived(this.typeId)) {
            let statuses = Object.assign({}, DOC_STATUSES)
            delete statuses.archived
            return statuses
        }
        return DOC_STATUSES
    }

    get docTypeFilters() {
        return this.getDocumentListFilters(this.typeId)
    }

    get organisations(): Array<Unit> {
        // Собираем все units с пометкой 'Организация'
        return this.getUnits.filter((unit: Unit) => unit.root)
    }

    get type(): DocumentType | null {
        return this.getTypeById(this.typeId)
    }

    // *************************************************************
    // METHODS
    @Action('document/callArchivedListStream')
    callArchivedListStream!: (docTypeId: string) => Promise<() => void>

    @Action('user_options/resetDocumentListFilter')
    resetDocumentListFilter!: (docTypeId: string) => void

    @Action('user_options/setArchivedDocuments')
    setArchivedDocuments!: (docTypeId: string) => void

    @Action('user_options/setDocumentListFilter')
    setDocumentListFilter!: (params: { docTypeId: string, mut: (filter: DocumentListFilters) => void }) => void

    getArchive(): void {
        console.log('getArchive', this.getArchivedDocumentsReceived(this.typeId))
        if (this.getArchivedDocumentsReceived(this.typeId)) {
            return
        }

        const cancelPreloaderArchivedListStreamDocuments = this.$preloader('archived_list_stream_documents', `Получение архивных документов ${this.type?.name}`)
        this.callArchivedListStream(this.typeId)
            .then(() => {
                this.setArchivedDocuments(this.typeId)
                this.setStatusFilter('archived', true)
            })
            .finally(() => cancelPreloaderArchivedListStreamDocuments())
    }

    clickResetFilter(): void {
        this.resetDocumentListFilter(this.typeId)
    }

    showTableSettings(): void {
        this.visibleTableModal = true
    }

    setMyDocFilter(key: 'myPending' | 'myTasks', value: boolean): void {
        this.setDocumentListFilter({
            docTypeId: this.typeId,
            mut: (filter: DocumentListFilters) => filter[key] = value,
        })
    }

    setOrganizationFilter(orgId: string): void {
        this.setDocumentListFilter({
            docTypeId: this.typeId,
            mut: (filter: DocumentListFilters) => filter.organisation = orgId
        })
    }

    setStatusFilter(status: 'draft' | 'approval' | 'execution' | 'archived', value: boolean): void {
        this.setDocumentListFilter({
            docTypeId: this.typeId,
            mut: (filter: DocumentListFilters) => filter.status[status] = value
        })
    }

    // *************************************************************
    // EMIT
    @Emit('print')
    emitPrint(): void {
        // Do nothing
    }
}
