var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.airForce ? _c('h4', {
    staticClass: "template-builder__h4"
  }, [_vm._v("\n        Укажите, необходимо или нет вертикальное всплывание в данном согласовании.\n    ")]) : _vm._e(), _vm._v(" "), !_vm.airForce ? _c('p', [_vm._v("\n        Отметив этот пункт, Вы подтверждаете, что сначала цепочка согласования пройдет по всем\n        подразделениям, стоящим выше в оргструктуре, а только потом начнется цепочка согласования по заложенному\n        Вами маршруту.\n    ")]) : _vm._e(), _vm._v(" "), _c('Select', {
    staticClass: "h-40 mt-4",
    attrs: {
      "placeholder": "Выбрать",
      "options": _vm.types
    },
    on: {
      "change": function change($event) {
        return _vm.changeAirForce($event);
      }
    },
    model: {
      value: _vm.airForce,
      callback: function callback($$v) {
        _vm.airForce = $$v;
      },
      expression: "airForce"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }