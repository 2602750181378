





































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Directory } from '@/model/Directory'
import { Unit } from '@/model/Unit'

import ActionSelectCard from '@/components/common/cards/ActionSelectCard.vue'
import InputSearch from '@/components/common/inputs/InputSearch.vue'
import Button from '@/components/common/buttons/Button.vue'
import DirectoryRecords from '@/components/directory/DirectoryRecords.vue'

@Component({
    components: {
        ActionSelectCard,
        InputSearch,
        Button,
        DirectoryRecords
    }
})

export default class EditRecords extends Vue {
    // *************************************************************
    // DATA PARAMS
    searchText = ''
    addingRecord = false
    selectedUnitId = ''

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryById') getDirectoryById!: (dirId: string) => Directory | null

    @Getter('company_structure/getUnits') getUnits!: Array<Unit>
    @Getter('company_structure/getRootUnit') getRootUnit!: (unitId: string) => Unit | null

    get directoryId(): string {
        return this.$route.params.id
    }

    get directory(): Directory | null {
        return this.getDirectoryById(this.directoryId)
    }

    get selectedOrganisationId(): string {
        // По выбранному unit получаем его корень
        if (!this.selectedUnitId) {
            return ''
        }

        const root = this.getRootUnit(this.selectedUnitId)
        console.log('root-----', root)
        if (root) {
            return root.id
        }

        this.$snotify.error('Обратитесь в техподдержку - у подразделения не существует родителя', 'Отклонено!')
        return ''
    }

    get units(): Array<Unit> {
        return this.getUnits.filter((unit: Unit) => unit.parent !== 'root' && unit.id !== 'root')
    }

    get showAddRecord(): { disabled: boolean, reason: string } {
        if (this.directory?.external) {
            return {
                disabled: true,
                reason: 'Нельзя добавлять элементы в справочники внешних систем'
            }
        } else if (this.directory?.archived) {
            return {
                disabled: true,
                reason: 'Нельзя добавлять элементы в архивный справочник'
            }
        } else {
            return {
                disabled: false,
                reason: ''
            }
        }
    }

    // *************************************************************
    // METHODS
    addRecord(): void {
        if (this.showAddRecord.disabled) {
            this.$snotify.error(`Отклонено: "${this.showAddRecord.reason}"`)
            return
        }
        this.addingRecord = !this.addingRecord
    }
}

