














































import { Component, PropSync, Vue } from 'vue-property-decorator'

import InputWithLabel from '@/components/common/inputs/InputWithLabel.vue'
import Button from '@/components/common/buttons/Button.vue'

interface ShowOptions {
    itemName: string
    itemType: string
    warning: string
    callback: () => void
}

@Component({
    components: {
        InputWithLabel,
        Button
    }
})

export default class RemoveModal extends Vue {
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean

    // *************************************************************
    // DATA PARAMS
    name = ''
    itemName = ''
    itemType = '' // в родительном падеже
    warning = ''
    callback = () => { /**/ }

    // *************************************************************
    // COMPUTED
    get disabled(): boolean {
        return !(this.name.trim().toLowerCase() === this.itemName.trim().toLowerCase())
    }

    // *************************************************************
    // METHODS

    show(options: ShowOptions): void {
        this.syncedVisible = true
        this.itemName = options.itemName
        this.itemType = options.itemType
        this.warning = options.warning
        this.callback = options.callback
    }

    hide(): void {
        this.name = ''
        this.itemName = ''
        this.itemType = ''
        this.warning = ''
        this.syncedVisible = false
    }

    remove(): void {
        if (this.disabled) {
            return
        }

        this.callback()
    }
}
