var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "action-delete-node"
  }, [_c('div', [_vm._v("\n        Удалить задачу: '" + _vm._s(_vm.getActiveTask.text) + "'?\n    ")]), _vm._v(" "), _c('Button', {
    staticClass: "action-delete-node__button",
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.onRemoveTask
    }
  }, [_vm._v("Удалить")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }