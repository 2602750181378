

































import { Component, PropSync, Vue } from 'vue-property-decorator'

import Modal from '@/components/common/modals/Modal.vue'
import TextareaNew from '@/components/common/inputs/TextareaNew.vue'
import Button from '@/components/common/buttons/Button.vue'

@Component({
    components: {
        Modal,
        TextareaNew,
        Button
    }
})

export default class OneAssDocTypeSettings extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visibleModal', { type: Boolean }) syncedVisible!: boolean

    // *************************************************************
    // DATA PARAMS
    requestOne = ''
    requestTwo = ''
}
