import { ActionTree } from 'vuex'
import { RootState } from '@/store'

import MeGetters from '@/store/me/_getters'
import MeMutations from '@/store/me/_mutations'

import IdentityActions from '@/store/me/_actions/identity'
import MeActions from '@/store/me/_actions/me'

import { User } from '@/model/User'
import { AuthenticationStatus, IdentitiesAndVerifiersList } from '@/model/Authentication'

export class MeState {
    status: AuthenticationStatus | null = null
    me: User | null = null
    listMyIdentitiesAndVerifiers: IdentitiesAndVerifiersList | null = null
    eventId = '' // last event in user_event_stream
}

const actions: ActionTree<MeState, RootState> = {
    ...IdentityActions,
    ...MeActions
}

const MeSubModule = {
    namespaced: true,
    state: new MeState(),
    getters: MeGetters,
    mutations: MeMutations,
    actions
}

export default MeSubModule
