import Vue from 'vue'

export default function (state, { numeratorId, field }) {
    let existingNumerator = state.numerators.find(num => num.id === numeratorId)
    let index = existingNumerator.fields.findIndex(f => f.id === field.id)
    if (index === -1) {
        existingNumerator.fields.push(field)
    } else {
        Vue.set(existingNumerator.fields, index, field)
    }
}
