









































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'

import { Unit } from '@/model/Unit'
import { User, Role } from '@/model/User'

import Modal from '@/components/common/modals/Modal.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import InputSearch from '@/components/common/inputs/InputSearch.vue'
import Checkbox from '@/components/common/inputs/Checkbox.vue'
import Button from '@/components/common/buttons/Button.vue'
import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import EmployeeCard from '@/components/common/cards/EmployeeCard.vue'

// Импортируем функцию сортировки
import dynamicSort from '@/tools/sort/dynamicSort'

interface RoleWithActive {
    name: string
    displayName: string
    wasActive: boolean
    active: boolean
}

const roles: Array<RoleWithActive> = [
    {
        name: 'Head',
        displayName: 'Руководитель',
        wasActive: false,
        active: false
    },
    {
        name: 'WorkflowManager',
        displayName: 'Менеджер документооборота',
        wasActive: false,
        active: false
    },
    {
        name: 'Employee',
        displayName: 'Сотрудник',
        wasActive: false,
        active: false
    },
    {
        name: 'Supervisor',
        displayName: 'Супервайзер',
        wasActive: false,
        active: false
    }
]

@Component({
    components: {
        EmployeeCard,
        InputBase,
        InputSearch,
        Modal,
        Checkbox,
        Button,
        ButtonViolet
    }
})

export default class SelectEmployees extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean
    @Prop({ default: '' }) unitId!: string

    // *************************************************************
    // DATA PARAMS
    searchText = ''
    chosenUser: User | null = null
    addingUser = false
    newUserId = ''
    roles = roles
    visibleModal = false

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null
    @Getter('company_structure/getUsersByCenterId') getUsersByCenterId!: (centerId: string) => Array<User>
    @Getter('company_structure/getUsers') getUsers!: Array<User>

    get unit(): Unit | null {
        return this.getUnitById(this.unitId)
    }

    get chief(): User | null {
        return this.getUsers.find((user: User) => user.roles.find((role: Role) => role.name === 'Head' && role.unit === this.unitId)) ?? null
    }

    get rolesFilterBySelectedUnit(): Array<RoleWithActive> {
        if (this.unit && this.unit.parent === 'root') {
            // Если сейчас выбран Центр - то возращаем весь список ролей
            return this.roles
        } else {
            return this.roles.filter((role: RoleWithActive) => role.name !== 'WorkflowManager')
        }
    }

    get employeesFilter(): Array<User> {
        // Фильтрация сотрудников по введенному в строку поиска значению
        if (!this.unit) {
            return []
        }

        const employeesFilter = this.getUsersByCenterId(this.unit.centerId).filter((user: User) =>
            user.name.fullName.toLowerCase().includes(this.searchText.toLowerCase())
        )

        // Сортируем по алфавиту
        employeesFilter.sort(dynamicSort('name', 'fullName'))
        return employeesFilter
    }

    get grantRevoke(): { grant: Array<string>, revoke: Array<string> } {
        if (!this.chosenUser) {
            return { grant: [], revoke: []}
        }

        // Проверяем массив с ролями в data
        const grant: Array<string> = []
        const revoke: Array<string> = []

        this.roles.forEach((role: RoleWithActive) => {
            // Находим роли, у которых поменялось значение
            if (role.active && !role.wasActive) {
                grant.push(role.name)
            } else if (!role.active && role.wasActive) {
                revoke.push(role.name)
            }
        })

        return { grant, revoke }
    }

    // *************************************************************
    // WATCH
    @Watch('chosenUser', { immediate: true, deep: true })
    handleChosenUserChange(val: User | null): void {
        if (val && val.roles.length) {
            // Берем все роли пользователя в данном подразделении
            const rolesInUnit: Array<Role> = val.roles.filter((r: Role) => r.unit === this.unitId)

            this.roles.forEach((role: RoleWithActive, index: number) => {
                if (rolesInUnit.find((r: Role) => r.name === role.name)) {
                    // Если данная роль у сотрудника стоит
                    this.roles[index].active = true
                    this.roles[index].wasActive = true
                } else {
                    this.roles[index].active = false
                    this.roles[index].wasActive = false
                }
            })
        } else {
            // В случае закрытие Modal
            this.roles.forEach((role: RoleWithActive) => {
                role.active = false
                role.wasActive = false
            })
        }
    }

    // *************************************************************
    // METHODS
    @Action('company_structure/callChangeRoles')
    callChangeRoles!: (params: cuntPB.RolesOp) => Promise<void>

    @Action('company_structure/callGetUser')
    callGetUser!: (id: string) => Promise<User>

    hide(): void {
        // Закрываем окно Modal
        this.syncedVisible = false
        this.close()
    }

    close(): void {
        // Обнуляем строку поиска
        this.searchText = ''
        // Обнуляем выбранного сотрудника
        this.chosenUser = null
        this.addingUser = false
        this.newUserId = ''
    }

    changeChosenUser(employee: User): void {
        this.chosenUser = employee
    }

    changeRoles(): void {
        const chosenUser = this.chosenUser
        if (!chosenUser) {
            return
        }

        // Проверяем массив с ролями в data
        const grant: Array<string> = []
        const revoke: Array<string> = []

        this.roles.forEach((role: RoleWithActive) => {
            // Находим роли, у которых поменялось значение
            if (role.active && !role.wasActive) {
                grant.push(role.name)
            } else if (!role.active && role.wasActive) {
                revoke.push(role.name)
            }
        })

        const cancelPreloaderChangeRoles = this.$preloader('change_roles', 'Смена ролей пользователя')

        this.callChangeRoles({
            User: chosenUser.id,
            Grant: grant.map((name: string) => {
                return { Unit: this.unitId, Name: name }
            }),
            Revoke: revoke.map((name: string) => {
                return { Unit: this.unitId, Name: name }
            })
        })
            .then(() => this.hide())
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderChangeRoles())
    }

    addUser(): void {
        this.addingUser = true
    }

    getNewUser() {
        if (this.newUserId.length !== 20) {
            return
        }

        const cancelPreloaderGetNewUser = this.$preloader('get_new_user', 'Получение нового пользователя')

        this.callGetUser(this.newUserId)
            .then((user: User) => {
                this.chosenUser = user
                this.addingUser = false
                this.newUserId = ''
                this.searchText = user.name.last
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderGetNewUser())
    }
}
