


















import { Component, Prop, Vue } from 'vue-property-decorator'

import dayjs from 'dayjs'
import { DocumentEvent, DocumentEventOptionRequisiteUpdated } from '@/model/Document'

import DocumentEventBase from '@/components/document/common_document/chat/document_events/DocumentEventBase.vue'

@Component({
    components: { DocumentEventBase }
})

export default class DocumentEventRequisiteCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) event!: DocumentEvent

    get fields(): Array<{ id: string, name: string, body: string }> {
        const fields = this.event.fields
        if (fields instanceof DocumentEventOptionRequisiteUpdated) {
            if (fields.requisiteType === 'req_editor') {
                return [{
                    id: 'name',
                    name: 'Реквизит',
                    body: fields.requisiteName
                }]
            }

            let value = fields.value
            if (fields.requisiteType === 'req_date') {
                value = dayjs(Number(fields.value)).format('DD/MM/YYYY HH:mm')
            }
            return [
                {
                    id: 'name',
                    name: 'Реквизит',
                    body: fields.requisiteName
                },
                {
                    id: 'value',
                    name: 'Значение',
                    body: value
                }
            ]
        }

        return []
    }
}
