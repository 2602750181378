var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pos-relative"
  }, [_c('div', {
    staticClass: "record-select",
    class: {
      'record-select_disabled': !_vm.canChangeRequisite,
      'record-select_empty': !_vm.canShowModal && !_vm.requisite.value
    },
    on: {
      "click": _vm.showModal
    }
  }, [_c('div', {
    staticClass: "record-select__name"
  }, [_vm._v("\n            " + _vm._s(_vm.displayRequisiteValue) + "\n        ")]), _vm._v(" "), _vm.canShowModal ? _c('div', {
    staticClass: "record-select__button"
  }, [_vm._v("\n            …\n        ")]) : _vm._e()]), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('div')]) : _vm._e(), _vm._v(" "), _c('RecordSelectModal', {
    ref: "selectItem",
    on: {
      "changeItem": function changeItem($event) {
        return _vm.onChangeRecord($event);
      }
    }
  }), _vm._v(" "), _c('TableModal', {
    ref: "tableModal"
  }), _vm._v(" "), _vm.document ? _c('EditorModal', {
    attrs: {
      "visible": _vm.visibleEditorModal,
      "value": _vm.requisite.value,
      "can-change-requisite": _vm.canChangeRequisite,
      "title": _vm.requisite.type.name,
      "document-requisites": _vm.document.requisites
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleEditorModal = $event;
      },
      "changeItem": function changeItem($event) {
        return _vm.emitChangeEditor($event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }