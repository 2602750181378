















import { Component, Prop, Vue } from 'vue-property-decorator'

import { DocumentEvent, DocumentEventApproved } from '@/model/Document'

import DocumentEventBase from '@/components/document/common_document/chat/document_events/DocumentEventBase.vue'

@Component({
    components: { DocumentEventBase }
})

export default class DocumentEventApprovedCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) event!: DocumentEvent

    get approvedUnit(): string | null {
        if (this.event.fields instanceof DocumentEventApproved) {
            return this.event.fields.actor.unit?.name || null
        }
        return null
    }
}
