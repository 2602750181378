


















import { Component, Prop, Vue } from 'vue-property-decorator'

import Spinner from '@/components/common/spinners/Spinner.vue'

@Component<Button>({
    components: { Spinner }
})

export default class Button extends Vue {
    @Prop({ type: Boolean, default: false }) defaultSize!: boolean // высота устанавливаться не будет
    @Prop({ type: Boolean, default: false }) reverse!: boolean // высота устанавливаться не будет
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: 'base' }) color!: string // base | green | blue | red
    @Prop({ default: '' }) size!: string
    @Prop({ default: false }) active!: boolean
    @Prop({ default: false }) loading!: boolean
    @Prop({ default: 'button' }) type!: string

    // *************************************************************
    // COMPUTED
    get buttonDisabled(): boolean {
        return this.disabled || this.loading
    }
}
