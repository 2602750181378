



































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { StageTemplate } from '@/model/Workflow'
import { User } from '@/model/User'
import { Unit } from '@/model/Unit'
import { NodeTemplate } from '@/tools/template_builder/viewData'

import InputSearch from '@/components/common/inputs/InputSearch.vue'
import EmployeeCard from '@/components/common/cards/EmployeeCard.vue'
import UnitCard from '@/components/common/cards/UnitCard.vue'

@Component({
    components: {
        InputSearch,
        EmployeeCard,
        UnitCard
    }
})

export default class WFTActionAddLink extends Vue {
    // *************************************************************
    // DATA PARAMS
    searchText = ''

    // *************************************************************
    // COMPUTED
    @Getter('workflow/getActiveStage') getActiveStage!: StageTemplate | null
    @Getter('workflow/getNodesCanLinked') getNodesCanLinked!: Array<NodeTemplate>

    @Getter('company_structure/getUserById') getUserById!: (id: string) => User | null
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get specialNodes(): Array<NodeTemplate> {
        return this.getNodesCanLinked.filter((node: NodeTemplate) => !node.actor)
    }

    get actorsNodes(): { users: Array<User>, units: Array<Unit>, actorNodeId: { [actorId: string]: string } } {
        const users: Array<User> = []
        const units: Array<Unit> = []
        const actorNodeId: { [actorId: string]: string } = {}

        this.getNodesCanLinked.forEach((node: NodeTemplate) => {
            if (node.actor?.user) {
                const user = this.getUserById(node.actor.user.id)
                if (!user) {
                    this.$snotify.error('Обратитесь в службу поддержки', `Пользователь не найден: ${node.actor.user.id}`)
                } else {
                    users.push(user)
                    actorNodeId[user.id] = node.id
                }
            } else if (node.actor?.unit) {
                const unit = this.getUnitById(node.actor.unit.id)
                if (!unit) {
                    this.$snotify.error('Обратитесь в службу поддержки', `Подразделение не найдено: ${node.actor.unit.id}`)
                } else {
                    units.push(unit)
                    actorNodeId[unit.id] = node.id
                }
            }
        })

        if (!this.searchText) {
            return { users, units, actorNodeId }
        }

        const filterRule = (name: string) => name.toLowerCase().includes(this.searchText.toLowerCase())

        return {
            users: users.filter((user: User) => filterRule(user.name.fullName)),
            units: units.filter((unit: Unit) => filterRule(unit.name)),
            actorNodeId
        }
    }

    // *************************************************************
    // METHODS
    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    @Action('workflow/addWFLink')
    addWFLink!: (nodeId: string) => void

    onClickAddLink(nodeId: string): void {
        this.addWFLink(nodeId)
        // Возвращаемся в меню выбора action
        this.changeWFAction('')
        // Отмечаем наличие изменений в workFlow
        this.workflowChange(true)
    }
}
