



















































import { Component, Emit, Ref, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { cuntPB } from 'api'

import getError, { GrpcError } from '@/tools/errors/errors'
import { Tab } from '@/components/common/tabs/model'
import { Directory, Record } from '@/model/Directory'
import { DocumentType, DocumentTypeRequisite } from '@/model/DocumentType'
import { Location } from 'vue-router'

import GoBack from '@/components/common/GoBack.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'
import Tabs from '@/components/common/tabs/Tabs.vue'
import EditDirectory from '@/components/directory/EditDirectory.vue'
import EditRecords from '@/components/directory/EditRecords.vue'
import RemoveModal from '@/components/common/modals/RemoveModal.vue'

const modes: Array<Tab> = [
    {
        id: 'directory',
        name: 'Просмотр/Редактирование справочника'
    },
    {
        id: 'records',
        name: 'Просмотр/Редактирование элементов справочника'
    }
]

@Component({
    components: {
        GoBack,
        ButtonDelete,
        Tabs,
        EditDirectory,
        EditRecords,
        RemoveModal
    }
})

export default class DirectoryPage extends Vue {
    @Ref() readonly removeModal!: RemoveModal

    // *************************************************************
    // DATA PARAMS
    modes = modes
    mode = 'directory'
    visibleRemoveModal = false

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryById') getDirectoryById!: (dirId: string) => Directory | null
    @Getter('directories/getDirectoryRecords') getDirectoryRecords!: (unitId: string, directoryId: string) => Array<Record>

    @Getter('document_type/getTypes') getTypes!: Array<DocumentType>

    get directoryId(): string {
        return this.$route.params.id
    }

    get directory(): Directory | null {
        return this.getDirectoryById(this.directoryId)
    }

    get canDelete(): { disabled: boolean, reason: string } {
        if (this.directory?.external) {
            return {
                disabled: true,
                reason: 'Нельзя удалять справочники внешних систем'
            }
        }

        let docTypes = ''
        this.getTypes.forEach((type: DocumentType) => {
            type.requisites.forEach((req: DocumentTypeRequisite) => {
                if (req.subType === this.directoryId) {
                    docTypes = docTypes + type.name + '/ '
                }
            })
        })
        if (docTypes) {
            return {
                disabled: true,
                reason: `Справочник используется в реквизитах типов документов ${docTypes}`
            }
        } else {
            return {
                disabled: false,
                reason: ''
            }
        }
    }

    get goBackPath(): Location {
        return {
            name: 'module',
            params: {
                section: 'system',
                module: 'directories'
            }
        }
    }

    // *************************************************************
    // WATCH
    @Watch('directory', { immediate: true, deep: true })
    handleDirectoryChange(to: Directory | null, from: Directory | null): void {
        if (!to && !!from && !!this.directoryId) {
            // Если у одного пользователя открыт справочник, а другой в это время его удалил
            this.openDirectories()
        }
    }

    // *************************************************************
    // METHODS
    @Action('directories/callRemoveDirectory')
    callRemoveDirectory!: (id: string) => Promise<void>

    @Action('directories/callSetDirectoryArchived')
    callSetDirectoryArchived!: (params: cuntPB.SetDirectoryArchivedReq) => Promise<void>

    openDirectories(): void {
        this.$router.push(this.goBackPath)
    }

    showRemoveDirectory(): void {
        if (this.canDelete.disabled) {
            this.$snotify.warning(`${this.canDelete.reason}`, 'Отклонено!')
            return
        }

        const thisDirectory = this.directory
        if (!thisDirectory) {
            return
        }


        const records = this.getDirectoryRecords(thisDirectory.centerId, thisDirectory.id)
        if (records.length) {
            const answer = window.confirm('Справочник не может быть удален! У него есть элементы! Перевести его в Архив?')
            if (answer) {
                this.archive()
            }
            return
        }

        this.removeModal.show({
            itemName: this.directory?.name ?? '',
            itemType: 'Справочника',
            warning: '',
            callback: this.removeDirectory
        })
    }

    removeDirectory(): void {
        const cancelPreloaderRemoveDirectory = this.$preloader('remove_directory', 'Удаление справочника')

        this.callRemoveDirectory(this.directoryId)
            .then(() => {
                this.visibleRemoveModal = false
                // this.openDirectories()
            })
            .catch((error: GrpcError) => {
                if (error.message === 'directory is used' && !this.directory?.archived) {
                    // Если справочник используется в документах - предлагаем перевести его в архив
                    const answer = window.confirm('Справочник используется в реквизитах типов документов или других справочниках! Перевести его в Архив?')
                    if (answer) {
                        this.archive()
                    }
                } else {
                    this.$snotify.error(getError(error))
                }
            })
            .finally(() => cancelPreloaderRemoveDirectory())
    }

    archive(): void {
        const thisDirectory = this.directory
        if (!thisDirectory) {
            return
        }
        if (thisDirectory.external) {
            this.$snotify.error('Нельзя архивировать справочники внешних систем', 'Отклонено!')
            return
        }

        const cancelPreloaderSetArchivedDirectory = this.$preloader('set_archived_directory', 'Перемещение справочника в Архив')

        this.callSetDirectoryArchived({
            ID: this.directoryId,
            Archived: !thisDirectory.archived
        })
            .then(() => this.emitClose())
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderSetArchivedDirectory())
    }

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose(): void {
        // Do nothing
    }
}
