var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "btn-spinner",
    style: _vm.styleObject
  }, _vm._l(8, function (item) {
    return _c('div', {
      key: item
    });
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }