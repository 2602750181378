var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.preloader.show,
      expression: "preloader.show"
    }],
    class: {
      'v-spinner-bind': _vm.bindParent,
      'v-spinner-fixed': !_vm.bindParent
    },
    style: {
      'z-index': 50,
      'background-color': _vm.backgroundColor
    }
  }, [_c('div', [_c('div', {
    staticClass: "v-scale v-scale1",
    style: [_vm.spinnerStyle, _vm.spinnerDelay1]
  }), _vm._v(" "), _c('div', {
    staticClass: "v-scale v-scale2",
    style: [_vm.spinnerStyle, _vm.spinnerDelay2]
  }), _vm._v(" "), _c('div', {
    staticClass: "v-scale v-scale3",
    style: [_vm.spinnerStyle, _vm.spinnerDelay3]
  }), _vm._v(" "), _c('div', {
    staticClass: "v-scale v-scale4",
    style: [_vm.spinnerStyle, _vm.spinnerDelay4]
  }), _vm._v(" "), _c('div', {
    staticClass: "v-scale v-scale5",
    style: [_vm.spinnerStyle, _vm.spinnerDelay5]
  })]), _vm._v(" "), _c('div', {
    staticClass: "text"
  }, [_vm.preloader.text ? _c('div', {
    staticClass: "text-info"
  }, _vm._l(_vm.preloader.text, function (field) {
    return _c('div', {
      key: field
    }, [_vm._v("\n                " + _vm._s(field) + "\n            ")]);
  }), 0) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }