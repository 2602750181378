


















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component<InputNumber>({})

export default class InputNumber extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) value!: string
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: false }) loading!: boolean
    @Prop({ default: true }) scale!: boolean

    // *************************************************************
    // DATA PARAMS
    numberModel = ''

    // *************************************************************
    // COMPUTED
    get disabledInput(): boolean {
        return this.disabled || this.loading
    }

    get placeholder(): string {
        if (!this.scale) {
            return '00000'
        } else {
            return '00000.00'
        }
    }

    // *************************************************************
    // WATCH
    @Watch('value', { immediate: true })
    handleValueChange(to: string): void {
        this.numberModel = to
    }

    // *************************************************************
    // METHODS
    onInput(val: string): void {
        let normalizedValue = val.trim()
            .replaceAll(',', '.') // замена запятых на точки
            .replace(/[^.\d]/g, '') // убираем все кроме цифр и точек
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2') // оставляем одну точку

        if (!this.scale) {
            normalizedValue = normalizedValue.replace('.', '')
        }
        if (normalizedValue[0] === ',' || normalizedValue[0] === '.') {
            normalizedValue = `0${normalizedValue}`
        }
        this.numberModel = normalizedValue
        this.emitInput(normalizedValue)
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(val: string): string {
        return val
    }

    @Emit('blur')
    emitBlur(): void {
        // Do nothing
    }

    @Emit('enter')
    emitEnter(): void {
        // Do nothing
    }
}
