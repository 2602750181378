import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { DirectoryState } from '@/store/directories/directoryState'

import { cuntPB, service } from 'api'
import { Record } from '@/model/Directory'

const RecordActions: ActionTree<DirectoryState, RootState> = {
    async callCreateRecord({ dispatch }, params: cuntPB.AddRecordReq) {
        const data: cuntPB.Record = await service.directory.AddRecord(params)
        console.log('::DATA::DirectoriesSvc.addRecord:', data)
        dispatch('putRecord', new Record(data))
    },

    async callUpdateRecordFields({ dispatch }, params: cuntPB.UpdateRecordFieldsReq) {
        const data: cuntPB.Record = await service.directory.UpdateRecordFields(params)
        console.log('::DATA::DirectoriesSvc.updateRecordFields:', data)
        dispatch('putRecord', new Record(data))
    },

    async callSetRecordArchived({ dispatch }, params: cuntPB.SetRecordArchivedReq) {
        const data: cuntPB.Record = await service.directory.SetRecordArchived(params)
        console.log('::DATA::DirectoriesSvc.setRecordArchived:', data)
        dispatch('putRecord', new Record(data))
    },

    async callRemoveRecord({ dispatch }, id: string) {
        await service.directory.RemoveRecord({ ID: id })
        console.log('::DATA::DirectoriesSvc.removeRecord:', id)
        dispatch('removeRecord', id)
    },

    putRecord({ commit, getters }, record: Record) {
        const existingRecord = getters.getRecordById(record.id)
        if (existingRecord) {
            if (record.modificationTime > existingRecord.modificationTime) {
                commit('replaceRecord', record)
            }
        } else {
            commit('putRecord', record)
        }
    },

    removeRecord({ commit }, recordId: string) {
        commit('removeRecord', recordId)
    }
}

export default RecordActions
