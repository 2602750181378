var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({}, _vm.$listeners), [_c('Popover', {
    attrs: {
      "shift-ypopover": 170,
      "arrow": false
    },
    on: {
      "mouse-leave": function mouseLeave($event) {
        _vm.version = false;
      }
    }
  }, [_c('div', {
    staticClass: "file",
    attrs: {
      "slot": "host"
    },
    slot: "host"
  }, [_c('FAIcon', {
    staticClass: "file__icon",
    attrs: {
      "icon": _vm.icon
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "file__name"
  }, [_vm._v("\n                " + _vm._s(_vm.file.name) + "\n            ")])], 1), _vm._v(" "), _c('div', {
    attrs: {
      "slot": "popover"
    },
    slot: "popover"
  }, [_c('div', {
    staticClass: "file__popover"
  }, [_c('a', {
    staticClass: "file__popover_header",
    attrs: {
      "tooltip": "Скачать",
      "target": "_blank",
      "href": "https://kant.themake.rs/api/storage/".concat(_vm.groupName, "/download/original?").concat(_vm.name, "=").concat(_vm.documentId, "&file=").concat(_vm.file.id)
    }
  }, [_c('FAIcon', {
    staticClass: "file__popover_header-icon",
    attrs: {
      "icon": _vm.icon
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "file__popover_header-name"
  }, [_vm._v("\n                        " + _vm._s(_vm.file.name) + "\n                    ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "file__popover_action"
  }, [_vm.canChange ? _c('div', {
    attrs: {
      "tooltip": "Заменить"
    },
    on: {
      "click": function click($event) {
        return _vm.emitReplaceFile(_vm.file.id);
      }
    }
  }, [_c('FAIcon', {
    staticClass: "file__popover_action_icon",
    attrs: {
      "icon": "sync-alt"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "file__text-left"
  }, [_vm._v("\n                    Size:" + _vm._s(_vm.file.size) + "\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "file__text-left"
  }, [_vm._v("\n                    Version:\n                    "), _c('span', [_vm._v(_vm._s(_vm.file.version))])])]), _vm._v(" "), _vm.version ? _c('div', {
    staticClass: "file_versions"
  }, [_c('Popover', {
    attrs: {
      "shift-ypopover": 0,
      "arrow": true
    }
  }, [_c('div', {
    staticClass: "file_versions_icon",
    attrs: {
      "slot": "host"
    },
    slot: "host"
  }, [_vm._v("\n                        " + _vm._s(_vm.file.version + 1) + "\n                    ")]), _vm._v(" "), _c('div', {
    staticClass: "file_versions_icon_popover",
    attrs: {
      "slot": "popover"
    },
    slot: "popover"
  }, [_vm._v("\n                        Version: " + _vm._s(_vm.file.version + 1) + " "), _c('br'), _vm._v("\n                        Size: "), _c('br'), _vm._v("\n                        Data creating:\n                    ")])])], 1) : _vm._e()])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }