


































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Task } from '@/model/Task'

import DocumentBlockTitle from '@/components/document/common_document/DocumentBlockTitle.vue'
import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'
import ModalCreateTask from '@/components/tasks/ModalCreateTask.vue'
import TaskCardSmall from '@/components/tasks/cards/TaskCardSmall.vue'
import TaskModal from '@/components/tasks/TaskModal.vue'

@Component<DocumentTasks>({
    components: {
        DocumentBlockTitle,
        ButtonAdd,
        ModalCreateTask,
        TaskCardSmall,
        TaskModal
    }
})

export default class DocumentTasks extends Vue {
    @Ref() readonly modal!: TaskModal

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) openDocumentId!: string
    @Prop({ default: '' }) openDocumentName!: string

    // *************************************************************
    // DATA PARAMS
    visibleCreateModal = false

    // *************************************************************
    // COMPUTED
    @Getter('task/getByDocument') getByDocument!: (documentId: string) => Array<Task>

    // *************************************************************
    // METHODS
    get tasks(): Array<Task> {
        return this.getByDocument(this.openDocumentId)
    }

    openCreateModal() {
        this.visibleCreateModal = true
    }

    openTask(taskId: string): void {
        this.modal.show(taskId)
    }
}
