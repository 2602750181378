<template>
    <div class="dismissal">
        <Select
            v-if="centers.length > 1"
            v-model="centerId"
            :options="centers"
            placeholder="Выбрать организацию"
            class="dismissal__center"
        />

        <ActionSelectCard
            v-if="centerId"
            ref="user"
            v-model="userId"
            placeholder="Выбор сотрудника"
            entity="user"
            :items="usersByCenter"
            sort="name"
            sub-sort-property="fullName"
        />

        <div
            v-if="userId"
            class="dismissal__title"
        >
            Необходимо указать Дефолтного сотрудника, который заменит увольняемого.
        </div>
        <ActionSelectCard
            v-if="userId"
            v-model="defaultUserId"
            placeholder="Выбор сотрудника"
            entity="user"
            :items="users"
            sort="name"
            sub-sort-property="fullName"
        />

        <div v-if="defaultUserId && roles.length > 1">
            <div class="dismissal__title">
                Для каждой роли Вы можете выбрать конкретного пользователя
            </div>
            <div
                v-for="(role, index) in roles"
                :key="`${role.unitId}-${role.roleName}`"
                class="dismissal__employee-card"
            >
                <div>{{ role.unitName }} : {{ role.displayName }}</div>
                <ActionSelectCard
                    v-model="usersByRoles[index].userId"
                    placeholder="Выбор сотрудника"
                    entity="user"
                    :items="users"
                    sort="name"
                    sub-sort-property="fullName"
                    @input="setUserByRole($event, index, role)"
                />
            </div>
        </div>
        <div v-if="defaultUserId && defaultRoles.length">
            <div class="dismissal__title">
                Согласно переназначению Дефолтному сотруднику будут назначены роли:
            </div>
            <div
                v-for="role in defaultRoles"
                :key="`${role.unitId}-${role.roleName}`"
            >
                {{ role.unitName }}: {{ role.displayName }}
            </div>
        </div>
        <ButtonGreen
            v-if="defaultUserId"
            text="Сохранить"
            class="dismissal__save"
            @click="save"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Select from '@/components/common/select/Select'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard'
import ButtonGreen from '@/components/common/buttons/ButtonGreen'
import errors from '@/tools/errors/errors.ts'

export default {
    name: 'Dismissal',
    components: {
        Select,
        ActionSelectCard,
        ButtonGreen
    },
    data() {
        return {
            centerId: '',
            userId: '',
            defaultUserId: '',
            usersByRoles: []
        }
    },
    computed: {
        ...mapState(['company_structure']),
        ...mapGetters('me', ['getMe']),
        ...mapGetters('company_structure', ['getUserById', 'getUnitById', 'getUsersByCenterId']),
        usersByCenter() {
            if (!this.centerId) {
                return []
            }
            return this.getUsersByCenterId(this.centerId).filter(user => user.id !== this.getMe.id)
        },
        users() {
            // Список тех, кто может замещать
            return this.usersByCenter.filter(user => user.id !== this.userId)
        },
        roles() {
            const user = this.getUserById(this.userId)
            if (!user) {
                return []
            }
            const roles = []
            user.roles.filter(role => role.name !== 'WorkflowManager' && role.name !== 'Supervisor').forEach(role => {
                const unit = this.getUnitById(role.unit)
                if (unit && unit.centerId === this.centerId) {
                    roles.push({
                        userId: '',
                        unitId: role.unit,
                        roleName: role.name,
                        unitName: unit.name,
                        displayName: role.displayName
                    })
                }
            })
            return roles
        },
        defaultRoles() {
            return this.roles.filter(role => !this.usersByRoles.find(r => r.unitId === role.unitId && !!role.userId))
        },
        centers() {
            const managers = this.getMe.roles.filter(role => role.name === 'WorkflowManager' && role.unit !== 'root')
            const centers = []
            managers.forEach(role => {
                const unit = this.getUnitById(role.unit)
                if (unit) {
                    centers.push({
                        id: role.unit,
                        name: unit.name
                    })
                }
            })
            return centers
        }
    },
    watch: {
        userId() {
            this.defaultUserId = ''
        },
        defaultUserId() {
            this.usersByRoles = this.roles || []
        }
    },
    mounted() {
        this.setCenterId()
    },
    methods: {
        ...mapActions('company_structure', ['callRetire']),
        setCenterId() {
            if (!this.centers) {
                this.centerId = ''
                return
            }
            if (this.centers.length === 1) {
                this.centerId = this.centers[0].id
            }
        },
        setUserByRole(userId, index, role) {
            this.usersByRoles[index].userId = userId
            this.usersByRoles[index].unitId = role.unitId
            this.usersByRoles[index].roleName = role.roleName
        },
        save() {
            if (!this.centerId) {
                return
            }
            let roles = this.usersByRoles.filter(role => !!role.userId)
            if (this.roles.length === 1) {
                roles = [{ ...this.roles[0], userId: this.defaultUserId }]
            }
            this.callRetire({
                UserID: this.userId,
                DefaultAlternateUserID: this.defaultUserId,
                CenterID: this.centerId,
                Substitutions: roles.map(user => {
                    return {
                        UnitID: user.unitId,
                        Name: user.roleName,
                        UserID: user.userId
                    }
                })
            })
                .then(() => {
                    this.$snotify.success('Сотрудник уволен', {
                        position: 'centerTop'
                    })
                    this.clear()
                })
                .catch(error => {
                    this.$snotify.error(`Ошибка: "${errors(error) || (error || {}).message}"`, {
                        timeout: 0
                    })
                })

        },
        clear() {
            this.$refs.user.close()
            this.defaultUserId = ''
            this.usersByRoles = this.roles || []
        }
    }
}
</script>

<style lang="stylus" scoped>
.dismissal
    display grid
    grid-row-gap 10px

.dismissal__center
    height 32px

.dismissal__title
    margin-bottom 15px
    font-weight bold

.dismissal__employee-card
    display grid
    grid-template-columns 1fr 1fr
    grid-column-gap 20px
    align-items center
    margin-bottom 10px
    height 40px

.dismissal__save
    float right
</style>
