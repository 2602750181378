<template>
    <div
        v-show="preloader.show"
        :class="{'v-spinner-bind': bindParent, 'v-spinner-fixed': !bindParent}"
        :style="{
            'z-index': 50,
            'background-color': backgroundColor
        }"
    >
        <div>
            <div
                class="v-scale v-scale1"
                :style="[spinnerStyle,spinnerDelay1]"
            />
            <div
                class="v-scale v-scale2"
                :style="[spinnerStyle,spinnerDelay2]"
            />
            <div
                class="v-scale v-scale3"
                :style="[spinnerStyle,spinnerDelay3]"
            />
            <div
                class="v-scale v-scale4"
                :style="[spinnerStyle,spinnerDelay4]"
            />
            <div
                class="v-scale v-scale5"
                :style="[spinnerStyle,spinnerDelay5]"
            />
        </div>
        <div class="text">
            <div
                v-if="preloader.text"
                class="text-info"
            >
                <div
                    v-for="field in preloader.text"
                    :key="field"
                >
                    {{ field }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScalePreloader',
    props: {
        // TODO watch
        bindParent: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: '#5dc596'
        },
        height: {
            type: String,
            default: '75px'
        },
        width: {
            type: String,
            default: '8px'
        },
        margin: {
            type: String,
            default: '2px'
        },
        radius: {
            type: String,
            default: '2px'
        },
        backgroundColor: {
            type: String,
            default: 'rgba(255, 255, 255, 0.7)'
        },
        state: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            spinnerStyle: {
                backgroundColor: this.color,
                height: this.height,
                width: this.width,
                margin: this.margin,
                borderRadius: this.radius,
                display: 'inline-block',
                animationName: 'v-scaleStretchDelay',
                animationDuration: '1s',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'cubic-bezier(.2,.68,.18,1.08)',
                animationFillMode: 'both'
                // TODO
                // animation: ['sk-waveStretchDelay', '1s', 'infinite', 'cubic-bezier(.2,.68,.18,1.08)'].join(' '),
                // animationFillMode: "both"
            },
            spinnerDelay1: {
                animationDelay: '0.1s'
            },
            spinnerDelay2: {
                animationDelay: '0.2s'
            },
            spinnerDelay3: {
                animationDelay: '0.3s'
            },
            spinnerDelay4: {
                animationDelay: '0.4s'
            },
            spinnerDelay5: {
                animationDelay: '0.5s'
            }
        }
    },
    computed: {
        preloader() {
            // Если ассоциативный список в preloader пуст
            let keys = Object.keys(this.state)

            if (!keys.length) {
                return {
                    show: false,
                    text: undefined
                }
            }
            return {
                show: true,
                text: Object.values(this.state)
            }
        }
    },
    mounted() {
        // fixme: Hack
        if (this.bindParent) {
            this.$_resizeObserverInterval = setInterval(
                (() => {
                    let h, w
                    return () => {
                        if (
                            w !== this.$parent.$el.clientWidth ||
                            h !== this.$parent.$el.clientHeight
                        ) {
                            h = this.$parent.$el.clientHeight
                            w = this.$parent.$el.clientWidth
                            this.$el.style.height = h + 'px'
                            this.$el.style.width = w + 'px'
                        }
                    }
                })(),
                100
            )
        }
    },
    beforeDestroy: function () {
        if (this.$_resizeObserverInterval) {
            // убираем лишний наблюдатель за шириной пользовательского окна
            clearInterval(this.$_resizeObserverInterval)
        }
    },
}
</script>

<style lang="stylus">
    .v-spinner-bind
        display flex
        position absolute
        top 0
        text-align center
        box-sizing border-box

    .v-spinner-fixed
        position fixed
        top 0
        bottom 0
        right 0
        left 0
        display flex
        z-index 20
    //background-color attr(opacity)

    .v-spinner-bind > div, .v-spinner-fixed > div
        margin auto

    .text
        position absolute
        top 0
        bottom 0
        left calc(50% + 45px)
        display flex

    .text-info
        margin auto auto auto 10px

    @-webkit-keyframes v-scaleStretchDelay
    {
        0%,
        100%
        {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
        50%
        {
            -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
        }
    }

    @keyframes v-scaleStretchDelay
    {
        0%,
        100%
        {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
        50%
        {
            -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
        }
    }
</style>
