
























































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import Printd from 'printd'
import { DOC_MAIN_CELLS, DOC_TABLE_CELLS_DISPLAY_NAME, DocumentListFilters } from '@/model/UserOptions'
import { Document } from '@/model/Document'
import { DocumentType, DocumentTypeRequisite } from '@/model/DocumentType'

import Datepicker from '@/components/common/datepicker/Datepicker.vue'
import DocumentCreationModal from '@/components/document/list/DocumentCreationModal.vue'
import DocumentLine from '@/components/document/list/DocumentLine.vue'
import DocumentsFilters from '@/components/document/list/DocumentsFilters.vue'
import ApproveDocuments from '@/components/document/list/ApproveDocuments.vue'
import ButtonCreate from '@/components/common/buttons/ButtonCreate.vue'
import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'
import Button from '@/components/common/buttons/Button.vue'
import InputSearch from '@/components/common/inputs/InputSearch.vue'
import ListCount from '@/components/common/ListCount.vue'

const documentListCount = 200
const stepSize = documentListCount / 4
const documentHeight = 131 // 120 - height + padding: 5px + border: 1px

@Component<DocumentList>({
    components: {
        DocumentsFilters,
        Datepicker,
        DocumentCreationModal,
        DocumentLine,
        ApproveDocuments,
        ButtonCreate,
        ButtonAdd,
        InputSearch,
        ListCount,
        Button
    }
})

export default class DocumentList extends Vue {
    @Ref() readonly tableContainer!: HTMLDivElement
    @Ref() readonly summaryTable!: HTMLTableElement

    // *************************************************************
    // DATA PARAMS
    DOC_TABLE_CELLS_DISPLAY_NAME = DOC_TABLE_CELLS_DISPLAY_NAME
    showFilters = false

    selectedDocumentIds: Array<string> = []
    selectedDocuments: Array<Document> = []

    visibleCreation = false
    d = new Printd()

    $_scrollTimer: ReturnType<typeof setTimeout> | undefined = undefined
    $_searchTimer: ReturnType<typeof setTimeout> | undefined = undefined

    // *************************************************************
    // COMPUTED
    @Getter('document/getDocuments') getDocuments!: Array<Document>
    @Getter('document/getDocumentById') getDocumentById!: (documentId: string) => Document | null
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null
    @Getter('user_options/getDocumentListFilters') getDocumentListFilters!: (docTypeId: string) => DocumentListFilters
    @Getter('user_options/getDocumentHiddenCells') getDocumentHiddenCells!: (docTypeId: string) => Array<string>

    get typeId(): string {
        return this.$route.params.module
    }

    get type(): DocumentType | null {
        return this.getTypeById(this.typeId)
    }

    get docTypeFilters(): DocumentListFilters {
        return this.getDocumentListFilters(this.typeId)
    }

    get documentHiddenCells(): Array<string> {
        return this.getDocumentHiddenCells(this.typeId)
    }

    get visibleMainCells(): Array<string> {
        return DOC_MAIN_CELLS.filter((cell: string) => !this.documentHiddenCells.includes(cell))
    }

    get visibleRequisites(): Array<DocumentTypeRequisite> {
        return this.requisites.filter((req: DocumentTypeRequisite) => !this.documentHiddenCells.includes(req.id))
    }

    get tableTopMargin(): number {
        return this.startIndex * documentHeight
    }

    get tableBottomMargin(): number {
        return (this.filteredDocuments.length - this.endIndex) * documentHeight
    }

    get remainder(): number {
        return this.filteredDocuments.length % stepSize
    }

    get startIndex(): number {
        if (!this.docTypeFilters.scrollTop) {
            return 0
        }
        const startStep = Math.ceil(this.docTypeFilters.scrollTop / documentHeight)
        const maxStartIndex = this.filteredDocuments.length - this.remainder - documentListCount
        const index = Math.ceil(Number(startStep) / stepSize) * stepSize
        const firstElemIndex = index - stepSize * 3
        return firstElemIndex > 0 ? Math.min(firstElemIndex, maxStartIndex) : 0
    }

    get endIndex(): number {
        const end = this.startIndex + documentListCount
        if (this.filteredDocuments.length - end < stepSize) {
            return this.filteredDocuments.length
        }
        return end
    }

    get visibleDocuments(): Array<Document> {
        return this.filteredDocuments.slice(this.startIndex, this.endIndex)
    }

    get filteredDocuments(): Array<Document> {
        function Comparator(a: Document, b: Document) {
            if (a.base.creationTime < b.base.creationTime) {return -1}
            if (a.base.creationTime > b.base.creationTime) {return 1}
            return 0
        }

        const startTime = this.docTypeFilters.startTime * 1000
        const endTime = this.docTypeFilters.endTime * 1000

        return this.getDocuments.filter((doc: Document) => {
            // Фильтрация по типу документа
            if (doc.base.type !== this.$route.params.module) {
                return
            }

            if (!this.docTypeFilters.status[doc.base.status]) {
                // Фильтрация по статусу
                return
            }

            if (this.docTypeFilters.organisation && doc.base.unit !== this.docTypeFilters.organisation) {
                // Фильтрация по организации
                return
            }

            if (doc.base.creationTime < startTime) {
                return
            }

            if (doc.base.creationTime > endTime) {
                return
            }

            // только те документы, которые сейчас согласует авторизованный user
            // либо на нем висят активные задачи
            if (this.docTypeFilters.myPending && !this.docTypeFilters.myTasks) {
                if (!doc.myPendingApproval) {
                    return
                }
            } else if (!this.docTypeFilters.myPending && this.docTypeFilters.myTasks) {
                if (!doc.myPendingTasks.length) {
                    return
                }
            } else if (this.docTypeFilters.myPending && this.docTypeFilters.myTasks) {
                if (!doc.myPendingApproval && !doc.myPendingTasks.length) {
                    return
                }
            }

            // Фильтруем документы по имени документа/значению реквизитов/автору/номеру
            if (this.docTypeFilters.searchText) {
                if (!doc.filterDisplayName.toLowerCase().includes(this.docTypeFilters.searchText.toLowerCase())) {
                    return
                }
            }

            return true
        }).sort(Comparator)
    }

    get requisites(): Array<DocumentTypeRequisite> {
        // Берем весь список реквизитов данного типа вместе с required
        if (!this.type) {
            return []
        }

        return this.type.requisites.filter((req: DocumentTypeRequisite) => req.type !== 'req_editor')
    }

    // *************************************************************
    // MOUNTED
    mounted(): void {
        // Устанавливаем контейнеру с таблицей прокрутку, на которой остановился наш пользователь
        this.tableContainer.scrollTop = this.docTypeFilters.scrollTop

        // const { contentWindow } = d.getIFrame()
        // contentWindow.addEventListener(
        //     'beforeprint', () => console.log('before print event!')
        // )
        // contentWindow.addEventListener(
        //     'afterprint', () => console.log('after print event!')
        // )
    }

    // *************************************************************
    // METHODS
    @Action('user_options/setDocumentListFilter')
    setDocumentListFilter!: (params: { docTypeId: string, mut: (filter: DocumentListFilters) => void }) => void

    print(): void {
        this.d.print( this.summaryTable, [
            'table { border: 2px solid black; border-collapse: collapse; }',
            'th { border: 2px solid black; border-collapse: collapse; }',
            'td { border: 2px solid black; border-collapse: collapse; }'
        ])
    }

    removeFromSelected(docId: string): void {
        this.selectedDocuments = this.selectedDocuments.filter((doc: Document) => doc.base.id !== docId)
        this.selectedDocumentIds = this.selectedDocumentIds.filter((id: string) => id !== docId)
    }

    checkedDocument(doc: Document, checked: boolean): void {
        if (checked) {
            this.selectedDocumentIds.push(doc.base.id)
            this.selectedDocuments.push(doc)
        } else {
            this.removeFromSelected(doc.base.id)
        }
    }

    cleanCheckedDocuments(): void {
        this.selectedDocuments = []
        this.selectedDocumentIds = []
    }

    showDocumentCreate(): void {
        // Разрешено добавлять только документы без пометки 1С
        if (!this.type?.oneAss?.collectionID) {
            this.visibleCreation = true
        }
    }

    onScroll(e: Event) {
        clearTimeout(this.$_scrollTimer)
        const target = e.target as HTMLDivElement

        if (Math.abs(this.docTypeFilters.scrollTop - target.scrollTop) > stepSize * documentHeight) {
            this.setDocumentListFilter({
                docTypeId: this.typeId,
                mut: (filter: DocumentListFilters) => filter.scrollTop = target.scrollTop,
            })
            return
        }
        const docTypeId = this.typeId
        this.$_scrollTimer = setTimeout(() => {
            this.setDocumentListFilter({
                docTypeId,
                mut: (filter: DocumentListFilters) => filter.scrollTop = target.scrollTop,
            })
        }, 500)
    }

    changeSearchText(text: string): void {
        clearTimeout(this.$_searchTimer)
        this.$_searchTimer = setTimeout(() => {
            this.cleanScrollTop()
            this.setDocumentListFilter({
                docTypeId: this.typeId,
                mut: (filter: DocumentListFilters) => filter.searchText = text
            })
        }, 500)
    }

    changeTime(key: 'startTime' | 'endTime', ts: number): void {
        console.log('changeTime', ts, this.docTypeFilters.startTime, this.docTypeFilters.endTime, typeof ts)
        if (ts !== this.docTypeFilters[key]) {
            this.setDocumentListFilter({
                docTypeId: this.typeId,
                mut: (filter: DocumentListFilters) => filter[key] = ts
            })
        }
    }

    cleanScrollTop(): void {
        if (this.tableContainer) {
            this.tableContainer.scrollTop = 0
        }
        this.setDocumentListFilter({
            docTypeId: this.typeId,
            mut: (filter: DocumentListFilters) => filter.scrollTop = 0
        })
    }

    clickDocument(documentId: string): void {
        this.$router.push({
            name: 'document',
            params: {
                section: 'documents',
                module: this.$route.params.module,
                id: documentId
            }
        })
    }
}
