<template>
    <div
        v-if="report"
        class="report"
    >
        <div class="report__header">
            <h2 class="report__title">
                {{ report.name }}
            </h2>

            <ButtonViolet
                text="Фильтры"
                :small="true"
                :active="filters"
                @click="filters = !filters"
            />

            <ButtonViolet
                text="Обновить отчет"
                :small="true"
                class="report__update"
                @click="updateReport"
            />

            <div
                tooltip="Распечатать отчет"
                flow="left"
                @click="print"
            >
                <FAIcon
                    icon="print"
                    class="report__print"
                />
            </div>
        </div>

        <div
            ref="table"
            class="report__wrapper"
        >
            <Component
                :is="component"
                ref="report"
                :filters="filters"
            />
        </div>
    </div>
</template>

<script>
import Printd from 'printd'

import Button from '@/components/common/buttons/Button'
import ButtonViolet from '@/components/common/buttons/ButtonViolet'
import ReportByAllUsers from '@/components/reports/ReportByAllUsers'
import ReportByAllUnits from '@/components/reports/ReportByAllUnits'
import { reports } from '@/components/reports/util'

export default {
    name: 'Report',
    components: {
        Button,
        ButtonViolet,
        ReportByAllUsers,
        ReportByAllUnits
    },
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            filters: false
        }
    },
    computed: {
        reportId() {
            return this.$route.params.id
        },
        report() {
            return reports.find(r => r.id === this.reportId)
        },
        component() {
            if (this.report) {
                return this.report.component
            }
            return ''
        }
    },
    mounted() {
        this.d = new Printd()

        const { contentWindow } = this.d.getIFrame()

        contentWindow.addEventListener(
            'beforeprint', () => console.log('before print event!')
        )
        contentWindow.addEventListener(
            'afterprint', () => console.log('after print event!')
        )
    },
    methods: {
        print() {
            this.d.print( this.$refs.table, [
                'table { border: 2px solid black; border-collapse: collapse; }',
                'th { border: 2px solid black; border-collapse: collapse; }',
                'td { border: 2px solid black; border-collapse: collapse; }'
            ])
        },
        updateReport() {
            this.$refs.report.updateReport()
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/table.styl'

.report
    display flex
    flex-direction column
    height 100%
    overflow hidden

.report__header
    display flex
    flex-direction row
    flex-wrap wrap
    align-items center
    margin 0 0 10px 0

.report__title
    flex-grow 1
    margin 0 10px 0 0

.report__update
    margin 0 10px

.report__print
    height 25px
    width 25px

.report__wrapper
    flex-grow 1
    overflow auto
</style>
