var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.syncedVisible,
      expression: "syncedVisible"
    }],
    staticClass: "rejected-reason",
    on: {
      "click": function click($event) {
        return _vm.hide();
      }
    }
  }, [_c('div', {
    staticClass: "rejected-reason__content",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "rejected-reason__header"
  }, [_c('p', {
    staticClass: "rejected-reason__title"
  }, [_vm._v("\n                Причина отклонения\n            ")]), _vm._v(" "), _c('ButtonClose', {
    attrs: {
      "small": true,
      "color": "violet"
    },
    on: {
      "click": function click($event) {
        return _vm.hide();
      }
    }
  })], 1), _vm._v(" "), _c('TextareaNew', {
    attrs: {
      "min-lines": 1,
      "max-lines": 5,
      "placeholder": "Введите причину"
    },
    model: {
      value: _vm.reason,
      callback: function callback($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  }), _vm._v(" "), _c('ButtonGreen', {
    staticClass: "rejected-reason__button",
    attrs: {
      "text": "Сохранить"
    },
    on: {
      "click": _vm.emitChangeReason
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }