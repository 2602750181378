// Функция сортировки по алфавиту
export default function dynamicSort(property, subProperty) {
    return function (a, b) {
        if (subProperty) {
            return (a[property][subProperty] < b[property][subProperty]) ? -1 : (a[property][subProperty] > b[property][subProperty]) ? 1 : 0
        } else {
            return (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        }
    }
}


