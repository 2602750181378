var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "link"
  }, _vm.$listeners), [_vm._v("\n    " + _vm._s(_vm.link) + "\n")]);
}
var staticRenderFns = []

export { render, staticRenderFns }