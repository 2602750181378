import { Familiarization, FamiliarizationDocument, FamiliarizationLog } from '@/model/familiarization.js'
import { newActor, newUserInfo } from '@/model/convert/newActor.js'

export class DocumentFile {
    constructor(id, name, type, size, version) {
        this.id = id
        this.name = name
        this.type = type
        this.size = size
        this.version = version
    }

    get computedType() {
        let extension = this.name.split('.').pop()
        switch (extension) {
        case 'doc':
        case 'docx':
        case 'txt':
        case 'pages':
        case 'odt':
        case 'rtf':
            return 'doc'
        case 'pdf':
        case 'PDF':
            return 'pdf'
        case 'xls':
        case 'xlsx':
        case 'ods':
        case 'numbers':
            return 'xls'
        case 'jpg':
        case 'JPG':
        case 'png':
        case 'jpeg':
            return 'jpg'
        case 'zip':
            return 'zip'
        default:
            return ''
        }
    }
}

export default function newFamiliarization(data) {
    return new Familiarization(
        data.ID,
        data.CenterID,
        newActor(data.Actor),
        data.Title,
        data.Description,
        data.CreationTime,
        data.ForceNewMembers,
        data.Files.map(f => new DocumentFile(f.ID, f.Name, f.Type, f.Size, f.Version)),
        data.Status,
        data.UnitIDs,
        data.Until,
        data.Documents.map(d => new FamiliarizationDocument(d.ID, d.Name, d.Type)),
        data.FamiliarizationLog.map(f => new FamiliarizationLog(newUserInfo(f.User), f.Status, f.When)),
        data.ModificationTime
    )
}
