import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { CompanyStructureState } from '@/store/company_structure/companyStructureState'

import { User, Role } from '@/model/User'
import { Unit } from '@/model/Unit'

export const CompanyStructureGetters: GetterTree<CompanyStructureState, RootState> = {
    ////////////////////////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////  Units       //////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    /** дочерние + сам unit */
    getChildrenUnits: (state: CompanyStructureState) => (unitId: string): Array<Unit> => {
        const units: Array<Unit> = []

        const unit: Unit | null = state.unitsObject[unitId]
        if (unit) {
            units.push(unit)
        }

        const findChildren = (parentId: string) => {
            const children = state.units.filter((unit: Unit) => unit.parent === parentId)
            units.push(...children)
            children.forEach((u: Unit) => findChildren(u.id))
        }

        findChildren(unitId)

        return units
    },

    getRootUnit: (state: CompanyStructureState) => (unitId: string): Unit | null => {
        const findRoot = (id: string): Unit | null => {
            const unit = state.unitsObject[id]

            if (!unit) {
                return null
            }

            if (unit.root) {
                // Если это корневой unit - возвращаем его
                return unit
            }

            return findRoot(unit.parent)
        }

        return findRoot(unitId)
    },

    getUnitById: (state: CompanyStructureState) => (id: string): Unit | null => state.unitsObject[id] ?? null,

    getUnitsByCenterId: (state: CompanyStructureState) => (centerId: string): Array<Unit> => state.units.filter(unit => unit.centerId === centerId),

    getUnits: (state: CompanyStructureState): Array<Unit> => state.units,

    ////////////////////////////////////////////////////////////////
    ////          //////////////////////////////////////////////////
    ////  Users       //////////////////////////////////////////////
    ////          //////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    getUserById: (state: CompanyStructureState) => (id: string): User | null => state.userObject[id] ?? null,

    getUsers: (state: CompanyStructureState): Array<User> => state.users,

    getUsersByCenterId: (state: CompanyStructureState) => (centerId: string): Array<User> => {
        return state.users.filter((user: User) => user.roles.some((role: Role) => state.unitsObject[role.unit]?.centerId === centerId))
    },

    getUsersByUnit: (state: CompanyStructureState) => (unitId: string): Array<User> => state.users.filter((user: User) => user.roles.find((r: Role) => r.unit === unitId))
}
