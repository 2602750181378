
























































import { Component, Vue, Prop, Emit, PropSync } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { cuntPB } from 'api'

import getError, { GrpcError } from '@/tools/errors/errors'
import { Unit } from '@/model/Unit'

import Modal from '@/components/common/modals/Modal.vue'
import SearchUnit from '@/components/company_structure/select_units/SearchUnit.vue'
import InputWithLabel from '@/components/common/inputs/InputWithLabel.vue'
import Button from '@/components/common/buttons/Button.vue'

@Component({
    components: {
        Modal,
        SearchUnit,
        InputWithLabel,
        Button
    }
})

export default class SelectUnits extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean
    @Prop({ default: '' }) unitId!: string

    // *************************************************************
    // DATA PARAMS
    currentTab = 'search'
    unitName = ''

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get title(): string {
        let unit = this.getUnitById(this.unitId)
        if (this.currentTab === 'search') {
            if (unit) {
                return `Выбор дочернего подразделения для ${unit.name}`
            } else {
                return 'Выбор дочернего подразделения'
            }
        } else {
            if (unit) {
                return `Создание дочернего подразделения для ${unit.name}`
            } else {
                return 'Создание дочернего подразделения'
            }
        }
    }

    // *************************************************************
    // METHODS
    @Action('company_structure/callCreateUnit')
    callCreateUnit!: (params: cuntPB.CreateUnitReq) => Promise<string>

    close(): void {
        this.unitName = ''
        this.currentTab = 'search'
        this.syncedVisible = false
    }

    create(): void {
        if (!this.unitName) {
            this.$snotify.warning('Имя подразделения не может быть пустым')
            return
        }

        const cancelPreloaderCreateUnit = this.$preloader('create_unit', 'Создание подразделения')

        this.callCreateUnit({
            Name: this.unitName,
            Parent: this.unitId
        })
            .then((unitId: string) => {
                this.emitCreate(unitId)
                this.close()
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderCreateUnit())
    }

    // *************************************************************
    // EMIT
    @Emit('create')
    emitCreate(unitId: string): string {
        return unitId
    }
}
