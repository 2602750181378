var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "action-add-approval"
  }, [_c('div', {
    staticClass: "action-add-approval__check-radio-container"
  }, [_c('Popover', {
    attrs: {
      "shift-ypopover": 5,
      "arrow": !_vm.getWorkflow.staticFlag
    }
  }, [_c('RadioButton', {
    staticClass: "action-add-approval__check-radio",
    attrs: {
      "slot": "host",
      "value": "user",
      "label": "user",
      "disabled": !_vm.getWorkflow.staticFlag
    },
    slot: "host",
    model: {
      value: _vm.approvalType,
      callback: function callback($$v) {
        _vm.approvalType = $$v;
      },
      expression: "approvalType"
    }
  }), _vm._v(" "), !_vm.getWorkflow.staticFlag ? _c('div', {
    staticClass: "action-add-approval__action_popover",
    attrs: {
      "slot": "popover"
    },
    slot: "popover"
  }, [_vm._v("\n                У Вас выбрана динамическая цепочка согласования! Добавлять пользователей возможно\n                только при статической цепочке.\n            ")]) : _vm._e()], 1), _vm._v(" "), _c('Popover', {
    attrs: {
      "shift-ypopover": 5,
      "arrow": _vm.getWorkflow.staticFlag
    }
  }, [_c('RadioButton', {
    staticClass: "action-add-approval__check-radio",
    attrs: {
      "slot": "host",
      "value": "unit",
      "label": "unit",
      "disabled": _vm.getWorkflow.staticFlag
    },
    slot: "host",
    model: {
      value: _vm.approvalType,
      callback: function callback($$v) {
        _vm.approvalType = $$v;
      },
      expression: "approvalType"
    }
  }), _vm._v(" "), !!_vm.getWorkflow.staticFlag ? _c('div', {
    staticClass: "action-add-approval__action_popover",
    attrs: {
      "slot": "popover"
    },
    slot: "popover"
  }, [_vm._v("\n                У Вас выбрана статическая цепочка согласования! Добавлять подразделения возможно\n                только при динамической цепочке.\n            ")]) : _vm._e()], 1), _vm._v(" "), _c('RadioButton', {
    staticClass: "action-add-approval__check-radio",
    attrs: {
      "value": "special",
      "label": "Специализированный узел"
    },
    model: {
      value: _vm.approvalType,
      callback: function callback($$v) {
        _vm.approvalType = $$v;
      },
      expression: "approvalType"
    }
  })], 1), _vm._v(" "), _vm.approvalType === 'special' ? _c('div', {
    staticClass: "action-add-approval__special-name-container"
  }, [_c('InputBase', {
    staticClass: "action-add-approval__special-name",
    attrs: {
      "placeholder": "Введите название узла"
    },
    model: {
      value: _vm.specialName,
      callback: function callback($$v) {
        _vm.specialName = $$v;
      },
      expression: "specialName"
    }
  }), _vm._v(" "), _c('ButtonGreen', {
    attrs: {
      "text": "OK",
      "disabled": !_vm.specialName
    },
    on: {
      "click": _vm.setSpecialApproval
    }
  })], 1) : _c('ActionSelectCard', {
    staticClass: "action-add-approval__select-card",
    attrs: {
      "placeholder": "Введите имя",
      "entity": _vm.approvalType,
      "items": _vm.approvalType === 'user' ? _vm.users : _vm.units,
      "sort": "name",
      "sub-sort-property": _vm.approvalType === 'user' ? 'fullName' : ''
    },
    on: {
      "input": function input($event) {
        return _vm.selectCard($event);
      }
    },
    model: {
      value: _vm.selectCardId,
      callback: function callback($$v) {
        _vm.selectCardId = $$v;
      },
      expression: "selectCardId"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }