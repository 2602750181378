var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "host",
    staticClass: "authentication-container"
  }, [_c('div', {
    staticClass: "authentication"
  }, [_c('div', {
    staticClass: "authentication__header"
  }, [_vm._v("\n            Welcome to the ADOC!\n        ")]), _vm._v(" "), _c('AuthenticationForm')], 1), _vm._v(" "), _c('div', {
    staticClass: "authentication__links"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'privacy'
      }
    }
  }, [_vm._v("\n            Политика конфиденциальности\n        ")]), _vm._v(" "), _c('a', {
    attrs: {
      "href": "mailto:support@themake.rs"
    }
  }, [_vm._v("support@themake.rs")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }