var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('g', _vm._g({
    staticClass: "approval-node",
    class: {
      'approval-node__node_cursor-pointer': !!_vm.cursor
    }
  }, _vm.$listeners), [!!_vm.selected ? _c('rect', {
    staticClass: "approval-node__rect",
    class: {
      'approval-node__rect_acceptance': _vm.selected === 'selected_for_acceptance',
      'approval-node__rect_rejection': _vm.selected === 'selected_for_rejection',
      'approval-node__rect_target': _vm.selected === 'selected_as_rejection_target'
    },
    attrs: {
      "rx": "10",
      "ry": "10",
      "height": "30",
      "width": "110"
    }
  }) : _vm._e(), _vm._v(" "), _vm.node.viewData.root ? _c('polygon', {
    staticClass: "approval-node__star",
    class: {
      'approval-node_blink': _vm.node.pending && _vm.node.actor.user.id === _vm.getMe.id,
      'approval-node_green': _vm.node.passed,
      'approval-node_purple': _vm.node.pending
    },
    attrs: {
      "points": "15,0 19,9 28,11 21,16 26,26 15,21 4,26 9,16 2,11 11,9"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.node.viewData.root ? _c('circle', {
    staticClass: "approval-node__circle",
    class: {
      'approval-node_blink': _vm.node.pending && _vm.node.actor.user.id === _vm.getMe.id,
      'approval-node_green': _vm.node.passed,
      'approval-node_purple': _vm.node.pending
    },
    attrs: {
      "r": "13",
      "cx": "15",
      "cy": "15"
    }
  }) : _vm._e(), _vm._v("\n    />\n    "), !!_vm.selected ? _c('text', {
    staticClass: "approval-node__check",
    attrs: {
      "x": "10",
      "y": "20"
    }
  }, [_vm._v("\n        ✓\n    ")]) : _vm._e(), _vm._v(" "), _c('text', {
    staticClass: "approval-node__text",
    class: {
      'approval-node__text_special': _vm.node.special && !_vm.node.actor.user && !_vm.node.actor.unit
    },
    attrs: {
      "x": "30",
      "y": "20"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.actor) + "\n    ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }