var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "requisites-type"
  }, [_c('div', {
    staticClass: "requisites-type__header"
  }, [_c('div', {
    staticClass: "requisites-type__title"
  }, [_vm._v("\n            Реквизиты типа\n        ")]), _vm._v(" "), _c('ButtonAdd', {
    attrs: {
      "tooltip": "Добавить реквизит",
      "flow": "left"
    },
    on: {
      "click": _vm.clickAddRequisite
    }
  })], 1), _vm._v(" "), _vm.showAddRequisiteCard ? _c('NewRequisiteCard', {
    staticClass: "requisites-type__card",
    attrs: {
      "type-id": _vm.typeId
    },
    on: {
      "addedRequisite": function addedRequisite($event) {
        _vm.showAddRequisiteCard = false;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "requisites-type__cards"
  }, _vm._l(_vm.requisites, function (requisite, index) {
    return _c('RequisiteCard', {
      key: requisite.id,
      staticClass: "requisites-type__card",
      attrs: {
        "first": index === 0 || (_vm.requisites[index - 1] || {}).fromExternalSource,
        "last": index === _vm.requisites.length - 1 || (_vm.requisites[index + 1] || {}).fromExternalSource,
        "requisite": requisite,
        "type-id": _vm.typeId
      },
      on: {
        "moveUp": function moveUp($event) {
          return _vm.moveUp(index, requisite.id);
        },
        "moveDown": function moveDown($event) {
          return _vm.moveDown(index, requisite.id);
        }
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }