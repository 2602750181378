




































import { Component, Emit, PropSync, Vue } from 'vue-property-decorator'

import ButtonClose from '@/components/common/buttons/ButtonClose.vue'
import TextareaNew from '@/components/common/inputs/TextareaNew.vue'
import ButtonGreen from '@/components/common/buttons/ButtonGreen.vue'

@Component({
    components: {
        ButtonClose,
        TextareaNew,
        ButtonGreen
    }
})

export default class DWF_RejectedReason extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean

    // *************************************************************
    // DATA PARAMS
    reason = ''

    // *************************************************************
    // METHODS
    hide(): void {
        this.reason = ''
        this.syncedVisible = false
    }

    // *************************************************************
    // EMIT
    @Emit('changeReason')
    emitChangeReason(): string {
        return this.reason
    }
}
