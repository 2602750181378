import { Actor } from '@/model/Actor'
import { cuntPB } from 'api'

import dayjs from 'dayjs'

export class WorkflowEventDocument {
    public id: string
    public name: string
    public type: string
    public creationTime: number

    constructor(doc: cuntPB.WorkflowEventDocument) {
        this.id = doc.ID
        this.name = doc.Name
        this.type = doc.Type
        this.creationTime = doc.CreationTime
    }

    get creationTimeLocalizedLongString(): string {
        return dayjs(this.creationTime / 1000).format('DD/MM/YYYY HH:mm')
    }
}

export class WorkflowEventApproval {}

export class WorkflowEventTask {
    public text: string
    public description: string
    public type: string

    constructor(task: cuntPB.WorkflowEventTask) {
        this.text = task.Text
        this.description = task.Description
        this.type = task.Type
    }
}

export class WorkflowEvent {
    public id: string
    public actor: Actor
    public type: string
    public document: WorkflowEventDocument | null
    public approval: WorkflowEventApproval | null
    public task: WorkflowEventTask | null

    constructor(event: cuntPB.WorkflowEvent) {
        this.id = event.ID
        this.actor = new Actor(event.Actor ?? null)
        this.type = event.Type
        this.document = event.Document ? new WorkflowEventDocument(event.Document) : null
        this.approval = event.Approval ? new WorkflowEventApproval() : null
        this.task = event.Task ? new WorkflowEventTask(event.Task) : null
    }
}
