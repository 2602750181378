import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { CompanyStructureState } from '@/store/company_structure/companyStructureState'
import { cuntPB, service } from 'api'

import { Unit } from '@/model/Unit'

const UnitsActions: ActionTree<CompanyStructureState, RootState> = {
    async callCreateUnit({ dispatch }, params: cuntPB.CreateUnitReq) {
        const data: cuntPB.Unit = await service.unit.Create(params)
        console.log('::DATA::UnitSvcClient.create:', data)
        dispatch('putUnit', new Unit(data))
        return data.ID
    },

    async callRemoveUnit({ commit }, unitId: string) {
        await service.unit.Remove({ ID: unitId })
        console.log('::DATA::UnitSvcClient.remove:', unitId)
        commit('removeUnit', unitId)
    },

    async callMoveUnit({ dispatch }, params: cuntPB.MoveUnitReq) {
        const data: cuntPB.Unit = await service.unit.Move(params)
        console.log('::DATA::UnitSvcClient.move:', data)
        dispatch('putUnit', new Unit(data))
    },

    async callMarkAsRoot({ dispatch }, params: cuntPB.MarkAsRootReq) {
        const data: cuntPB.Unit = await service.unit.MarkAsRoot(params)
        console.log('::DATA::UnitSvcClient.markAsRoot:', data)
        dispatch('putUnit', new Unit(data))
    },

    async callSetUnitName({ dispatch }, params: cuntPB.SetNameReq) {
        const data: cuntPB.Unit = await service.unit.SetName(params)
        console.log('::DATA::UnitSvcClient.setName:', data)
        dispatch('putUnit', new Unit(data))
    },

    async callSetUnitCode({ dispatch }, params: cuntPB.SetCodeReq) {
        const data: cuntPB.Unit = await service.unit.SetCode(params)
        console.log('::DATA::UnitSvcClient.setCode:', data)
        dispatch('putUnit', new Unit(data))
    },

    putUnit({ commit, getters }, unit: Unit) {
        const existingUnit = getters.getUnitById(unit.id)
        if (existingUnit) {
            if (unit.modificationTime > existingUnit.modificationTime) {
                commit('replaceUnit', unit)
            }
        } else {
            commit('putUnit', unit)
        }
    },

    removeUnit({ commit }, unitId: string) {
        commit('removeUnit', unitId)
    }
}

export default UnitsActions
