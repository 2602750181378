






















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component<QR>({})

export default class QR extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: 'google' }) source!: string
    @Prop({ default: true }) showButton!: boolean

    // *************************************************************
    // COMPUTED
    get href(): string {
        return this.source === 'google' ? 'https://play.google.com/store/apps/details?id=rs.themake.kant' : 'https://apps.apple.com/app/id1535631529'
    }

    get text(): string {
        return this.source === 'google' ? 'Android' : 'IOS'
    }
}
