import { grpc } from '@improbable-eng/grpc-web';
import { IdentityClientImpl } from '@/api/protocol_gen/identity-proto/identity';
import { BrowserHeaders } from 'browser-headers';
import { Observable } from 'rxjs';
import store from '@/store';
import { share } from 'rxjs/operators';
import {
    DocumentTypeSvcClientImpl,
    TaskSvcClientImpl,
    UnitSvcClientImpl,
    UserSvcClientImpl,
    DirectoriesSvcClientImpl,
    OneAssClientImpl,
    BackDoorClientImpl,
    DocumentSvcClientImpl,
    WorkflowSvcClientImpl
} from '@/api/protocol_gen/sad-cunt-backend-proto/sc'

export * as identityPB from '@/api/protocol_gen/identity-proto/identity'
export * as cuntPB from '@/api/protocol_gen/sad-cunt-backend-proto/sc'

console.log('window.location.origin', window.location.origin)
const location = 'https://kant.themake.rs'

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
    requestStream: any
    responseStream: any
}

const localStorageTokenKey = 'authorization';

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR

export class Impl {
    private host: string;
    private options: {
        transport?: grpc.TransportFactory;
        streamingTransport?: grpc.TransportFactory;
        debug?: boolean;
        metadata?: grpc.Metadata;
    };

    constructor(
        host: string,
        options: {
            transport?: grpc.TransportFactory;
            streamingTransport?: grpc.TransportFactory;
            debug?: boolean;
            metadata?: grpc.Metadata;
        },
    ) {
        this.host = host;
        this.options = options;
    }

    unary<T extends UnaryMethodDefinitionish>(
        methodDesc: T,
        _request: any,
        metadata: grpc.Metadata | undefined,
    ): Promise<any> {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata =
            metadata && this.options?.metadata
                ? new BrowserHeaders({
                    ...this.options.metadata.headersMap,
                    ...metadata?.headersMap,
                })
                : metadata || this.options.metadata;

        maybeCombinedMetadata?.set('authorization', localStorage.getItem(localStorageTokenKey) ?? '')

        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    const reportError = () => {
                        const err = new Error(response.statusMessage) as any;
                        err.code = response.status;
                        err.metadata = response.trailers;
                        reject(err)
                    }

                    const authorization = response.trailers?.headersMap?.authorization;
                    if (authorization && authorization[0]) {
                        localStorage.setItem(localStorageTokenKey, authorization[0]);
                    }

                    if (response.status === grpc.Code.OK) {
                        resolve(response.message);
                    } else if (response.status === grpc.Code.Unauthenticated) {
                        store.dispatch('me/logOut')
                        reportError();
                    } else {
                        reportError();
                    }
                },
            });
        });
    }

    invoke<T extends UnaryMethodDefinitionish>(
        methodDesc: T,
        _request: any,
        metadata: grpc.Metadata | undefined,
    ): Observable<any> {
        // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
        const upStreamCodes = [2, 4, 8, 9, 10, 14, 15];
        const DEFAULT_TIMEOUT_TIME = 3_000;
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata =
            metadata && this.options?.metadata
                ? new BrowserHeaders({
                    ...this.options.metadata.headersMap,
                    ...metadata?.headersMap,
                })
                : metadata || this.options.metadata;

        maybeCombinedMetadata?.set('authorization', localStorage.getItem(localStorageTokenKey) ?? '');

        return new Observable((observer) => {
            const upStream = () => {
                const client = grpc.invoke(methodDesc, {
                    host: this.host,
                    request,
                    transport: this.options.streamingTransport || this.options.transport,
                    metadata: maybeCombinedMetadata,
                    debug: this.options.debug,
                    onMessage: (next) => observer.next(next),
                    onEnd: (code: grpc.Code, message: string) => {
                        const reportError = () => observer.error(new Error(`Error ${code} ${message}`));
                        if (code === 0) {
                            observer.complete();
                        } else if (upStreamCodes.includes(code)) {
                            setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
                        } else if (code === grpc.Code.Unauthenticated) {
                            store.dispatch('me/logOut')
                            reportError();
                        } else {
                            reportError();
                        }
                    },
                });
                observer.add(() => client.close());
            };
            upStream();
        }).pipe(share());
    }
}

const transport = grpc.XhrTransport({
    withCredentials: true,
})


const rpc = new Impl(location, {
    debug: true,
    metadata: new grpc.Metadata({}),
    streamingTransport: transport,
    transport: transport,
});

class Services {
    get identity(): IdentityClientImpl {
        return new IdentityClientImpl(rpc)
    }

    get task(): TaskSvcClientImpl {
        return new TaskSvcClientImpl(rpc)
    }

    get documentType(): DocumentTypeSvcClientImpl {
        return new DocumentTypeSvcClientImpl(rpc)
    }

    get unit(): UnitSvcClientImpl {
        return new UnitSvcClientImpl(rpc)
    }

    get user(): UserSvcClientImpl {
        return new UserSvcClientImpl(rpc)
    }

    get directory(): DirectoriesSvcClientImpl {
        return new DirectoriesSvcClientImpl(rpc)
    }

    get document(): DocumentSvcClientImpl {
        return new DocumentSvcClientImpl(rpc)
    }

    get workflow(): WorkflowSvcClientImpl {
        return new WorkflowSvcClientImpl(rpc)
    }

    get oneAss(): OneAssClientImpl {
        return new OneAssClientImpl(rpc)
    }

    get backDoor(): BackDoorClientImpl {
        return new BackDoorClientImpl(rpc)
    }
}

export const service = new Services()
