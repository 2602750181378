var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tosser-rule"
  }, [_c('div', {
    staticClass: "tosser-rule__terms-header"
  }, [_c('ButtonAdd', {
    attrs: {
      "tooltip": !_vm.addButtonDisabled ? 'Добавить условие' : 'Все возможные условия учтены',
      "flow": "left",
      "disabled": _vm.addButtonDisabled
    },
    on: {
      "click": _vm.canAddTerm
    }
  }), _vm._v(" "), _c('ButtonDelete', {
    staticClass: "tosser-rule__delete",
    attrs: {
      "tooltip": "Удалить правило",
      "flow": "left"
    },
    on: {
      "click": _vm.remove
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "tosser-rule__icon-container pink-1",
    attrs: {
      "tooltip": !_vm.firstRule ? 'Увеличить приоритет' : '',
      "flow": "left"
    },
    on: {
      "click": _vm.moveUp
    }
  }, [_c('FAIcon', {
    staticClass: "tosser-rule__icon",
    class: {
      'tosser-rule__icon_disabled': _vm.firstRule
    },
    attrs: {
      "icon": "arrow-up"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "tosser-rule__icon-container pink-1",
    attrs: {
      "tooltip": !_vm.lastRule ? 'Снизить приоритет' : '',
      "flow": "left"
    },
    on: {
      "click": _vm.moveDown
    }
  }, [_c('FAIcon', {
    staticClass: "tosser-rule__icon",
    class: {
      'tosser-rule__icon_disabled': _vm.lastRule
    },
    attrs: {
      "icon": "arrow-down"
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "tosser-rule__terms"
  }, [_c('div', {
    staticClass: "tosser-rule__terms-title"
  }, [_vm._v("\n            Условия:\n        ")]), _vm._v(" "), _vm._l(_vm.ruleInfo.terms, function (term, termIndex) {
    return _c('TosserRuleTerm', {
      key: termIndex,
      staticClass: "tosser-rule__term",
      attrs: {
        "term-info": term,
        "requisites": _vm.requisitesFilter,
        "root-unit-id": _vm.unitId
      },
      on: {
        "changeTerm": function changeTerm($event) {
          return _vm.changeTerm($event);
        },
        "remove": function remove($event) {
          return _vm.removeTerm(term.id);
        }
      }
    });
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "tosser-rule__actor-container"
  }, [_c('div', {
    staticClass: "tosser-rule__actor-title"
  }, [_vm._v("\n            Согласует:\n        ")]), _vm._v(" "), _c('Select', {
    staticClass: "tosser-rule__actor-type",
    attrs: {
      "options": _vm.typeOptions
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }), _vm._v(" "), _vm.type === 'auto' ? _c('div', {
    key: "auto",
    staticClass: "tosser-rule__actor tosser-rule__actor_auto"
  }, [_vm._v("\n            Документ согласуется автоматически\n        ")]) : _vm.type === 'user' ? _c('ActionSelectCard', {
    key: "user",
    staticClass: "tosser-rule__actor",
    attrs: {
      "placeholder": "Выберите сотрудника",
      "entity": "user",
      "items": _vm.users,
      "sort": "name",
      "sub-sort-property": "fullName"
    },
    model: {
      value: _vm.actor.userId,
      callback: function callback($$v) {
        _vm.$set(_vm.actor, "userId", $$v);
      },
      expression: "actor.userId"
    }
  }) : _c('ActionSelectCard', {
    key: "unit",
    staticClass: "tosser-rule__actor",
    attrs: {
      "placeholder": "Выберите подразделение",
      "entity": "unit",
      "items": _vm.units,
      "sort": "name"
    },
    model: {
      value: _vm.actor.unitId,
      callback: function callback($$v) {
        _vm.$set(_vm.actor, "unitId", $$v);
      },
      expression: "actor.unitId"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }