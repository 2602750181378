<template>
    <div
        class="checkbox-container"
        :class="[classes]"
    >
        <div class="checkbox-group">
            <div
                class="checkbox-ripple-container"
                @click="toggle"
            >
                <input
                    :id="checkboxId"
                    :checked="checked"
                    :disabled="disabled"
                    type="checkbox"
                    class="checkbox__input"
                    @change="toggle"
                >
            </div>
        </div>
        <label
            v-if="label"
            :for="checkboxId"
            class="checkbox_label"
            @click="toggle"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'CheckboxGroup',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        model: {
            type: [String, Array],
            default: undefined
        },
        checked: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Boolean, Object],
            default: undefined
        },
        label: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            checkboxId: Math.random().toString(36).substring(7)
        }
    },
    computed: {
        checkboxState() {
            if (this.model === undefined) {
                return this.checked
            }
            if (Array.isArray(this.model)) {
                return this.model.indexOf(this.value) !== -1
            }
            return this.model
        },
        classes() {
            return {
                'checkbox-disabled': this.disabled,
                'checkbox-active': this.checkboxState
            }
        }
    },
    methods: {
        toggle() {
            console.log('toggle', this.model)
            if (this.disabled) {
                return
            }
            let value = this.model
            if (Array.isArray(value)) {
                value = value.slice()
                const i = value.indexOf(this.value)
                if (i === -1) {
                    value.push(this.value)
                } else {
                    value.splice(i, 1)
                }
            } else {
                value = !this.checkboxState
            }
            this.$emit('change', value)
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/colors.styl'

.checkbox-container
    box-sizing: border-box
    display: inline-flex
    position: relative
    cursor: pointer
    .checkbox_label
        position: relative
        padding-left: 1rem
        cursor: pointer
    .checkbox-group
        box-sizing border-box
        position: relative
        border-radius: 2px
        border: 2px solid rgba(0,0,0,.54)
        height: 20px
        width: 20px
        min-width: 20px
        transition: .4s cubic-bezier(.25,.8,.25,1)
        input[type=checkbox]
            position: absolute
            -webkit-appearance: none
            appearance: none
            left: -999rem
        &:after
            box-sizing inherit
            content: ''
            position: absolute
            transition: transform .25s ease
            width: 6px
            height: 13px
            top: 0
            left: 5px
            z-index: 6
            border: 2px solid #fff
            border-top: 0
            border-left: 0
            opacity: 0
            transform: rotate(45deg) scale3D(.1, .1, .1)

.checkbox-ripple-container
    box-sizing border-box
    z-index: 1
    position: absolute
    width: 48px
    height: 48px
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    border-radius: 50%

.checkbox-container.checkbox-active
    .checkbox-group
        background-color $pink-1
        border-color $pink-1
        &:after
            opacity 1
            transform rotate(45deg) scale3d(1,1,1)

.checkbox-disabled
    cursor: not-allowed
    .checkbox-group
        opacity: .14
    .checkbox_label
        opacity: .24
        cursor: not-allowed


</style>
