<template>
    <div class="message">
        <div
            class="message__info"
        >
            <div>
                {{ event.actor.name.shortName }}
            </div>
            <div class="message__info_date">
                {{ event.creationTimeStampString }}
            </div>
        </div>
        <div
            :class="{
                message__text: true,
                message__text_left: alignment === 'left',
                message__text_right: alignment === 'right'
            }"
        >
            {{ event.fields['message'] }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatMessageEvent',
    props: {
        alignment: {
            type: String,
            default: ''
        },
        event: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.message
    display flex
    flex-direction column

.message__info
    display flex
    flex-wrap nowrap
    height 25px
    line-height 25px

.message__info_date
    font-size 0.8rem
    margin 0 5px

.message__text
    position relative
    border-radius 10px
    box-sizing border-box
    box-shadow 0 14px 28px rgba(234, 234, 244, 1), 0 10px 10px rgba(234, 234, 244, 1)
    padding 15px
    height auto
    color #333
    background #fff
    word-wrap break-word

.message__text_right
    margin 0 25px 5px 10px

.message__text_right:before
    content ' '
    position absolute
    width 0
    top 2px
    bottom auto
    left auto
    right -42px
    box-shadow 0 14px 28px rgba(234, 234, 244, 1), 0 10px 10px rgba(234, 234, 244, 1)

.message__text_right:after
    content ' '
    position absolute
    top: 0
    bottom: auto
    left: auto
    right: -14px
    border-width: 0 0 30px 30px
    border-style solid
    border-color transparent #fff

.message__text_left
    margin 0 10px 5px 25px

.message__text_left:before
    content ' '
    position absolute
    width 0
    top 10px
    bottom auto
    left -30px
    box-shadow 0 14px 28px rgba(234, 234, 244, 1), 0 10px 10px rgba(234, 234, 244, 1)

.message__text_left:after
    content ' '
    position absolute
    top 0
    bottom auto
    left -14px
    border-width: 0 30px 30px 0
    border-style solid
    border-color transparent #fff
</style>
