<template>
    <div
        v-show="showing"
        class="confirm-modal"
        @click="hide()"
    >
        <div
            class="confirm-modal__content"
            @click.stop
        >
            <div class="confirm-modal__header">
                <div class="confirm-modal__title">
                    Подтверждение
                </div>
            </div>

            <div class="confirm-modal__body">
                {{ text }}
            </div>

            <div class="confirm-modal__footer">
                <ButtonBase
                    text="Отмена"
                    @click="cancel"
                />
                <ButtonGreen
                    text="OK"
                    @click="confirm"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonGreen from '@/components/common/buttons/ButtonGreen'
import ButtonBase from '@/components/common/buttons/ButtonBase'

export default {
    name: 'RemoveModal',
    components: {
        ButtonGreen,
        ButtonBase
    },
    data: () => {
        return {
            showing: false,
            text: '',
            onOk: () => { /**/ },
            onCancel: () => { /**/ }
        }
    },
    methods: {
        show(options) {
            this.text = options.text
            this.onOk = options.onOk
            this.onCancel = options.onCancel
            this.showing = true
        },
        hide() {
            this.text = ''
            this.showing = false
        },
        confirm() {
            this.onOk()
            this.hide()
        },
        cancel() {
            console.log('cancel')
            this.onCancel()
            this.hide()
        }
    }
}
</script>

<style lang="stylus" scoped>
.confirm-modal
    position: fixed
    width: 100%
    height: 100%
    left: 0
    top: 0
    display flex
    background rgba(0,0,0,0.45)
    z-index: 7
    overflow: hidden
    &.fadein-leave-active,
    &.fadein-enter-active
        transition: opacity 0.1s, transform 0.1s

.confirm-modal__content
    display grid
    grid-row-gap 20px
    width 500px
    background-color #ffffff
    border-radius 4px
    box-shadow 0 4px 12px rgba(0, 0, 0, 0.15)
    z-index 2
    margin 20px auto auto

.confirm-modal__header
    padding 16px 24px
    color rgba(0, 0, 0, 0.65)
    border-bottom 1px solid #e8e8e8

.confirm-modal__title
    font-style normal
    font-weight 900
    font-size 18px

.confirm-modal__body
    padding 24px

.confirm-modal__footer
    display grid
    grid-template-columns repeat(2, 100px)
    grid-column-gap 20px
    align-items center
    justify-content end
    padding 10px 16px
    border-top 1px solid #e8e8e8

</style>
