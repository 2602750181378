var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.task ? _c('div', _vm._g({
    staticClass: "big-task-card"
  }, _vm.$listeners), [_c('div', {
    staticClass: "text-wrap"
  }, [_c('div', [_vm._v(_vm._s(_vm.task.text))]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.task.description))])]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "big-task-card__participants-container"
  }, [_c('div', {
    staticClass: "big-task-card__participants"
  }, [_vm._v("\n                " + _vm._s(_vm.task.assignee.user.name.shortName) + "\n                 ←\n                "), _c('span', {
    staticClass: "fs-16"
  }, [_vm._v("\n                    " + _vm._s(_vm.task.author.user.name.shortName) + "\n                ")])]), _vm._v(" "), _c('ButtonAdd', {
    attrs: {
      "tooltip": "Создать дочернюю задачу",
      "flow": "left"
    },
    on: {
      "click": [function ($event) {
        $event.stopPropagation();
      }, function ($event) {
        return _vm.emitCreate(_vm.task.id);
      }]
    }
  })], 1), _vm._v(" "), _vm.documentPath ? _c('Attribute', {
    attrs: {
      "attribute": _vm.typeName
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('router-link', {
    staticClass: "fs-20 link",
    attrs: {
      "to": _vm.documentPath
    }
  }, [_vm._v("\n                " + _vm._s(_vm.task.document.name) + "\n            ")])], 1) : _vm._e(), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Создана",
      "text": _vm.task.creationTimeLocalizedShortString
    }
  }), _vm._v(" "), _vm.task.dueTime ? _c('Attribute', {
    attrs: {
      "attribute": "Выполнить до"
    }
  }, [_c('span', {
    class: {
      'red': _vm.task.dueTime < _vm.time
    }
  }, [_vm._v("\n                " + _vm._s(_vm.task.dueTimeLocalizedShortString) + "\n            ")])]) : _vm._e(), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Статус"
    }
  }, [_c('span', {
    class: _vm.statusColor
  }, [_vm._v(_vm._s(_vm.status))])]), _vm._v(" "), _c('div', {
    staticClass: "big-task-card__complete"
  }, [_vm.task.confirmer ? _c('div', {
    staticClass: "big-task-card__confirmer"
  }, [_vm._v("\n                Подтверждает: " + _vm._s(_vm.task.confirmer.user.name.shortName) + "\n            ")]) : _vm._e(), _vm._v(" "), _vm._l(_vm.buttons, function (button) {
    return _c('ButtonViolet', {
      key: button.key,
      staticClass: "ml-2",
      attrs: {
        "text": button.text
      },
      on: {
        "click": [function ($event) {
          $event.stopPropagation();
        }, function ($event) {
          return _vm.action(button.key);
        }]
      }
    });
  })], 2)], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }