
































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User } from '@/model/User'

import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import Attribute from '@/components/common/Attribute.vue'
import TaskCardMixin from '@/components/tasks/cards/TaskCardMixin.vue'

@Component({
    components: {
        ButtonViolet,
        Attribute
    }
})

export default class TaskCardSmall extends Mixins(TaskCardMixin) {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) tab!: string

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible

    get options(): { status: string, buttons: Array<{ key: string, text: string }> } {
        if (this.tab === 'outgoing' || this.task.closed) {
            // Если это панель справа с напоминалками - то в исходящих задачах отражаем только статусы,
            // а во входящих - отражаем кнопки для действий, чтобы не было дублирования в случаях,
            // когда автор задачи должен ее и подтвердить
            return {
                status: this.status,
                buttons: []
            }
        } else if (this.task.assignee.user?.id === this.getMe.id && !this.task.completed) {
            return {
                status: '',
                buttons: [{
                    key: 'complete',
                    text: 'Выполнить'
                }]
            }
        } else if (this.task.completed && this.task.confirmer && !this.task.confirmed && this.task.confirmer.user?.id === this.getMe.id) {
            // Если задача выполнена, требует подтверждения нашего пользователя
            return {
                status: '',
                buttons: [
                    {
                        key: 'confirm',
                        text: 'Подтвердить'
                    },
                    {
                        key: 'reject',
                        text: 'Отклонить'
                    }
                ]
            }
        } else {
            // Это внутри открытого документа
            return {
                status: this.status,
                buttons: []
            }
        }
    }

    get userName(): string {
        if (this.tab === 'incoming') {
            return this.task.author.user?.name.shortName ?? ''
        } else if (this.tab === 'outgoing') {
            if (this.task.completed) {
                return this.task.confirmer?.user?.name.shortName ?? ''
            } else {
                return this.task.assignee.user?.name.shortName ?? ''
            }
        } else {
            // Это в открытом документе - отражаем всех
            return ''
        }
    }
}
