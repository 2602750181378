var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "clickOutside",
      rawName: "v-clickOutside",
      value: _vm.handleClickOutside,
      expression: "handleClickOutside"
    }],
    staticClass: "dropdown",
    on: {
      "click": _vm.onClickDropdown,
      "mouseenter": _vm.onEnterDropdown,
      "mouseleave": _vm.onLeaveDropdown
    }
  }, [_c('div', {
    ref: "activator",
    staticClass: "dropdown__activator"
  }, [_vm._t("activator")], 2), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBody,
      expression: "showBody"
    }],
    ref: "body",
    staticClass: "dropdown__body",
    style: _vm.styleObject
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }