


















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})

export default class ButtonDelete extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: false }) small!: boolean
    @Prop({ default: false }) disabled!: boolean
}
