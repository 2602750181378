var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "unit-info"
  }, [_c('EditUnit', {
    attrs: {
      "unit-id": _vm.unitId
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "selectUnit": function selectUnit($event) {
        return _vm.$emit('selectUnit', $event);
      }
    }
  }), _vm._v(" "), _c('h3', {
    staticClass: "text-center"
  }, [_vm._v("\n        Руководитель подразделения\n    ")]), _vm._v(" "), _vm.head ? _c('EmployeeCard', {
    attrs: {
      "unit-user": _vm.head,
      "type": "system"
    }
  }) : _c('div', [_vm._v("\n        Руководитель не выбран\n    ")]), _vm._v(" "), _c('h3', {
    staticClass: "text-center"
  }, [_vm._v("\n        Супервайзеры подразделения\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "unit-info__users-list"
  }, _vm._l(_vm.supervisors, function (user) {
    return _c('EmployeeCard', {
      key: user.id,
      attrs: {
        "unit-user": user,
        "type": "system"
      }
    });
  }), 1), _vm._v(" "), !_vm.supervisors.length ? _c('div', [_vm._v("\n        В выбранном подразделении нет Супервайзеров.\n    ")]) : _vm._e(), _vm._v(" "), _c('h3', {
    staticClass: "text-center"
  }, [_vm._v("\n        Сотрудники\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "unit-info__users-list"
  }, _vm._l(_vm.employees, function (user) {
    return _c('EmployeeCard', {
      key: user.id,
      attrs: {
        "unit-user": user,
        "type": "system"
      }
    });
  }), 1), _vm._v(" "), !_vm.employees.length ? _c('div', [_vm._v("\n        В выбранном подразделении нет Сотрудников.\n    ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }