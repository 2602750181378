var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "familiarization-members",
    attrs: {
      "visible": _vm.visibleModal,
      "title": "Добавление пользователей и подразделений"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      }
    }
  }, [_c('div', {
    staticClass: "familiarization-documents__body",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('ActionSelectCard', {
    attrs: {
      "multiple": true,
      "placeholder": "Выбрать пользователей",
      "entity": "user",
      "items": _vm.users,
      "sort": "name",
      "sub-sort-property": "fullName"
    },
    model: {
      value: _vm.userIds,
      callback: function callback($$v) {
        _vm.userIds = $$v;
      },
      expression: "userIds"
    }
  }), _vm._v(" "), _c('ActionSelectCard', {
    staticClass: "familiarization__margin",
    attrs: {
      "multiple": true,
      "placeholder": "Выбрать подразделения",
      "entity": "unit",
      "items": _vm.units,
      "sort": "name"
    },
    model: {
      value: _vm.unitIds,
      callback: function callback($$v) {
        _vm.unitIds = $$v;
      },
      expression: "unitIds"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center p-3",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('ButtonGreen', {
    staticClass: "ml-auto",
    attrs: {
      "text": "Сохранить",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.save
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }