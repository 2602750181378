import api from 'api1'
import newNumerator from '@/model/convert/newNumerator'

export default function ({ dispatch }, { centerId, name, fields }) {
    return new Promise((resolve, reject) => {
        api.cunt.NumeratorSvcClient.create(api.cunt.CreateNumeratorReq({
            CenterID: centerId,
            Name: name,
            Fields: fields.map(field => {
                return api.cunt.NumeratorField({
                    ID: field.id,
                    Type: field.type,
                    Code: api.cunt.NumeratorCodeField({
                        Source: field.code
                    }),
                    Text: api.cunt.NumeratorTextField({
                        Text: field.text
                    }),
                    Incr: api.cunt.NumeratorIncrField({
                        Pad: Number(field.pad),
                        ResetPeriod: field.resetPeriod
                    })
                })
            })
        }), data => {
            data = api.cunt.Numerator(data)
            console.log('::DATA::NumeratorSvcClient.create:', data)

            dispatch('putNumerator', {
                numerator: newNumerator(data),
                modificationTime: data.ModificationTime
            })

            resolve(data.ID)
        }, error => {
            (error ? console.warn : console.log)('::DONE::NumeratorSvcClient.create:', error)
            reject(error)
        })
    })
}
