
































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { cuntPB } from 'api'

import getError, { GrpcError } from '@/tools/errors/errors'
import { User } from '@/model/User'
import dynamicSort from '@/tools/sort/dynamicSort'

import Modal from '@/components/common/modals/Modal.vue'
import InputSearch from '@/components/common/inputs/InputSearch.vue'
import EmployeeCard from '@/components/common/cards/EmployeeCard.vue'

@Component({
    components: {
        EmployeeCard,
        InputSearch,
        Modal
    }
})

export default class SelectUser extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean
    @Prop({ default: () => []}) users!: Array<User>

    // *************************************************************
    // DATA PARAMS
    searchText = ''

    // *************************************************************
    // COMPUTED
    get usersFilter(): Array<User> {
        // Фильтрация сотрудников по введенному в строку поиска значению
        return this.users
            .filter((user: User) => user.name.fullName.toLowerCase().includes(this.searchText.toLowerCase()))
            .sort(dynamicSort('name', 'fullName'))
    }

    // *************************************************************
    // METHODS
    @Action('me/callLoginAs')
    callLoginAs!: (params: cuntPB.LoginAsReq) => Promise<void>

    close(): void {
        this.syncedVisible = false
        this.searchText = ''
    }

    loginAs(userId: string): void {
        console.log('loginAs----', userId)
        const cancelPreloaderLoginAs = this.$preloader('login_as', 'Смена пользователя')

        this.callLoginAs({
            User: userId,
            Secret: ''
        })
            .then(() => {
                this.close()
                // this.$router.push({ name: 'root' })
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderLoginAs())
    }
}
