var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "template-builder"
  }, [_c('div', {
    staticClass: "template-builder__constructor"
  }, [_c('div', {
    staticClass: "template-builder__header"
  }, [_c('div', {
    staticClass: "template-builder__title"
  }, [_c('h3', {
    staticClass: "template-builder__h3"
  }, [_vm._v("\n                    Выбрано " + _vm._s(!!_vm.getWorkflow.staticFlag ? 'СТАТИЧЕСКОЕ согласование' : 'ДИНАМИЧЕСКОЕ согласование') + ".\n                ")]), _vm._v(" "), _c('Popover', {
    attrs: {
      "shift-ypopover": 5,
      "arrow": !_vm.canChangeStaticFlag.can,
      "popover-position": "bottom"
    }
  }, [_c('Button', {
    staticClass: "template-builder__action",
    attrs: {
      "slot": "host",
      "disabled": !_vm.canChangeStaticFlag.can,
      "reverse": "",
      "color": "blue"
    },
    on: {
      "click": _vm.changeStaticFlag
    },
    slot: "host"
  }, [_vm._v(_vm._s(_vm.getWorkflow.staticFlag ? 'Выбрать динамическое согласование' : 'Выбрать статическое согласование'))]), _vm._v(" "), !_vm.canChangeStaticFlag.can ? _c('div', {
    staticClass: "template-builder__action_popover",
    attrs: {
      "slot": "popover"
    },
    slot: "popover"
  }, [_vm._v("\n                        " + _vm._s(_vm.canChangeStaticFlag.reason) + "\n                    ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c('Button', {
    staticClass: "template-builder__save",
    attrs: {
      "color": "green",
      "disabled": !_vm.getWorkflow.change,
      "defaultSize": ""
    },
    on: {
      "click": _vm.saveWorkFlow
    }
  }, [_vm._v(_vm._s(_vm.getWorkflow.change ? 'Сохранить изменения' : 'Изменения сохранены'))])], 1), _vm._v(" "), !_vm.getWorkflow.staticFlag ? _c('WFTAirForce', {
    staticClass: "template-builder__air-force"
  }) : _vm._e(), _vm._v(" "), _c('hr', {
    staticClass: "template-builder__hr"
  }), _vm._v(" "), _c('div', {
    staticClass: "mb-3 flex-row-center"
  }, [_c('FAIcon', {
    staticClass: "gray-8",
    class: {
      'c-pointer pink-1': _vm.getWorkflow.activeStage !== null
    },
    attrs: {
      "icon": "home"
    },
    on: {
      "click": _vm.clearActiveStage
    }
  }), _vm._v(" "), _vm.getWorkflow.activeStage !== null ? _c('FAIcon', {
    staticClass: "gray-8 mx-2",
    staticStyle: {
      "font-size": "11px"
    },
    attrs: {
      "icon": "arrow-right"
    }
  }) : _vm._e(), _vm._v(" "), _vm.getActiveStage ? _c('span', {
    staticClass: "gray-8",
    class: {
      'pink-1 c-pointer': !!_vm.getWorkflow.action
    },
    on: {
      "click": _vm.clearAction
    }
  }, [_vm._v("\n                " + _vm._s(_vm.getActiveStage.displayName) + "\n            ")]) : _vm._e(), _vm._v(" "), !!_vm.getWorkflow.action ? _c('FAIcon', {
    staticClass: "gray-8 mx-2",
    staticStyle: {
      "font-size": "11px"
    },
    attrs: {
      "icon": "arrow-right"
    }
  }) : _vm._e(), _vm._v(" "), !!_vm.getWorkflow.action ? _c('span', {
    staticClass: "gray-8"
  }, [_vm._v("\n                " + _vm._s(_vm.actions.find(function (action) {
    return action.id === _vm.getWorkflow.action;
  }).text) + "\n            ")]) : _vm._e()], 1), _vm._v(" "), _vm.getWorkflow.activeStage !== null && !_vm.getWorkflow.action ? _c('WFTStageName') : _vm._e(), _vm._v(" "), !_vm.getWorkflow.action ? _c('div', [_vm._v("\n            " + _vm._s(_vm.messageAboutChildren) + "\n        ")]) : _vm._e(), _vm._v(" "), !_vm.getWorkflow.action ? _c('div', {
    staticClass: "template-builder__actions-container"
  }, _vm._l(_vm.actions, function (action) {
    return _c('Popover', {
      key: action.id,
      attrs: {
        "shift-ypopover": 10,
        "arrow": action.disabled
      }
    }, [_c('Button', {
      staticClass: "template-builder__action",
      attrs: {
        "slot": "host",
        "disabled": action.disabled,
        "color": "blue",
        "reverse": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickAddStage(action);
        }
      },
      slot: "host"
    }, [_vm._v(_vm._s(action.text))]), _vm._v(" "), action.disabled ? _c('div', {
      staticClass: "template-builder__action_popover",
      attrs: {
        "slot": "popover"
      },
      slot: "popover"
    }, [_vm._v("\n                    " + _vm._s(action.tooltip) + "\n                ")]) : _vm._e()], 1);
  }), 1) : _vm._e(), _vm._v(" "), _c('WTBActions')], 1), _vm._v(" "), _c('WorkFlowTemplate', {
    staticClass: "template-builder__graph box-shadow__double"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }