















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component<ButtonAdd>({})

export default class ButtonAdd extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: 'medium' }) size!: string
}
