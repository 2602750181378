/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.api.themakers.identity.CancelAuthenticationReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.IdentityData', null, global);
goog.exportSymbol('proto.api.themakers.identity.InitializeStaticVerifierReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.InitializeStaticVerifierResp', null, global);
goog.exportSymbol('proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp', null, global);
goog.exportSymbol('proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.LoginAsReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.LoginAsResp', null, global);
goog.exportSymbol('proto.api.themakers.identity.LogoutReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.StartAttachReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.StartReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.StartResp', null, global);
goog.exportSymbol('proto.api.themakers.identity.StartSignInReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.StartSignUpReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.Status', null, global);
goog.exportSymbol('proto.api.themakers.identity.StatusAuthenticated', null, global);
goog.exportSymbol('proto.api.themakers.identity.StatusAuthenticating', null, global);
goog.exportSymbol('proto.api.themakers.identity.StatusCompletedFactors', null, global);
goog.exportSymbol('proto.api.themakers.identity.StatusReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.UserMergeReq', null, global);
goog.exportSymbol('proto.api.themakers.identity.UserMergeResp', null, global);
goog.exportSymbol('proto.api.themakers.identity.VerifierDetails', null, global);
goog.exportSymbol('proto.api.themakers.identity.VerifierDetailsResp', null, global);
goog.exportSymbol('proto.api.themakers.identity.VerifyReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StartSignInReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.StartSignInReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StartSignInReq.displayName = 'proto.api.themakers.identity.StartSignInReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StartSignUpReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.StartSignUpReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StartSignUpReq.displayName = 'proto.api.themakers.identity.StartSignUpReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StartAttachReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.StartAttachReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StartAttachReq.displayName = 'proto.api.themakers.identity.StartAttachReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.displayName = 'proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.displayName = 'proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.VerifierDetailsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.identity.VerifierDetailsResp.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.identity.VerifierDetailsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.VerifierDetailsResp.displayName = 'proto.api.themakers.identity.VerifierDetailsResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.VerifierDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.VerifierDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.VerifierDetails.displayName = 'proto.api.themakers.identity.VerifierDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.displayName = 'proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.IdentityData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.IdentityData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.IdentityData.displayName = 'proto.api.themakers.identity.IdentityData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StartReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.StartReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StartReq.displayName = 'proto.api.themakers.identity.StartReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StartResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.StartResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StartResp.displayName = 'proto.api.themakers.identity.StartResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StatusReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.StatusReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StatusReq.displayName = 'proto.api.themakers.identity.StatusReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.Status.displayName = 'proto.api.themakers.identity.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StatusAuthenticated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.StatusAuthenticated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StatusAuthenticated.displayName = 'proto.api.themakers.identity.StatusAuthenticated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StatusAuthenticating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.identity.StatusAuthenticating.repeatedFields_, proto.api.themakers.identity.StatusAuthenticating.oneofGroups_);
};
goog.inherits(proto.api.themakers.identity.StatusAuthenticating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StatusAuthenticating.displayName = 'proto.api.themakers.identity.StatusAuthenticating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.StatusCompletedFactors = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.StatusCompletedFactors, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.StatusCompletedFactors.displayName = 'proto.api.themakers.identity.StatusCompletedFactors';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.UserMergeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.UserMergeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.UserMergeReq.displayName = 'proto.api.themakers.identity.UserMergeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.UserMergeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.UserMergeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.UserMergeResp.displayName = 'proto.api.themakers.identity.UserMergeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.CancelAuthenticationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.CancelAuthenticationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.CancelAuthenticationReq.displayName = 'proto.api.themakers.identity.CancelAuthenticationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.LogoutReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.LogoutReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.LogoutReq.displayName = 'proto.api.themakers.identity.LogoutReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.InitializeStaticVerifierReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.InitializeStaticVerifierReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.InitializeStaticVerifierReq.displayName = 'proto.api.themakers.identity.InitializeStaticVerifierReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.InitializeStaticVerifierResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.InitializeStaticVerifierResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.InitializeStaticVerifierResp.displayName = 'proto.api.themakers.identity.InitializeStaticVerifierResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.VerifyReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.VerifyReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.VerifyReq.displayName = 'proto.api.themakers.identity.VerifyReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.LoginAsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.LoginAsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.LoginAsReq.displayName = 'proto.api.themakers.identity.LoginAsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.identity.LoginAsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.identity.LoginAsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.identity.LoginAsResp.displayName = 'proto.api.themakers.identity.LoginAsResp';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StartSignInReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StartSignInReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StartSignInReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartSignInReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StartSignInReq}
 */
proto.api.themakers.identity.StartSignInReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StartSignInReq;
  return proto.api.themakers.identity.StartSignInReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StartSignInReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StartSignInReq}
 */
proto.api.themakers.identity.StartSignInReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StartSignInReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StartSignInReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StartSignInReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartSignInReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StartSignUpReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StartSignUpReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StartSignUpReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartSignUpReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StartSignUpReq}
 */
proto.api.themakers.identity.StartSignUpReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StartSignUpReq;
  return proto.api.themakers.identity.StartSignUpReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StartSignUpReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StartSignUpReq}
 */
proto.api.themakers.identity.StartSignUpReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StartSignUpReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StartSignUpReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StartSignUpReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartSignUpReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StartAttachReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StartAttachReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StartAttachReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartAttachReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StartAttachReq}
 */
proto.api.themakers.identity.StartAttachReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StartAttachReq;
  return proto.api.themakers.identity.StartAttachReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StartAttachReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StartAttachReq}
 */
proto.api.themakers.identity.StartAttachReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StartAttachReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StartAttachReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StartAttachReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartAttachReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq}
 */
proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq;
  return proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq}
 */
proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq;
  return proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.identity.VerifierDetailsResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.VerifierDetailsResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.VerifierDetailsResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.VerifierDetailsResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.VerifierDetailsResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    identitiynamesList: jspb.Message.getRepeatedField(msg, 1),
    verifiersList: jspb.Message.toObjectList(msg.getVerifiersList(),
    proto.api.themakers.identity.VerifierDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.VerifierDetailsResp}
 */
proto.api.themakers.identity.VerifierDetailsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.VerifierDetailsResp;
  return proto.api.themakers.identity.VerifierDetailsResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.VerifierDetailsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.VerifierDetailsResp}
 */
proto.api.themakers.identity.VerifierDetailsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdentitiynames(value);
      break;
    case 2:
      var value = new proto.api.themakers.identity.VerifierDetails;
      reader.readMessage(value,proto.api.themakers.identity.VerifierDetails.deserializeBinaryFromReader);
      msg.addVerifiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.VerifierDetailsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.VerifierDetailsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.VerifierDetailsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.VerifierDetailsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentitiynamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getVerifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.themakers.identity.VerifierDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string IdentitiyNames = 1;
 * @return {!Array<string>}
 */
proto.api.themakers.identity.VerifierDetailsResp.prototype.getIdentitiynamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.api.themakers.identity.VerifierDetailsResp.prototype.setIdentitiynamesList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.identity.VerifierDetailsResp.prototype.addIdentitiynames = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.identity.VerifierDetailsResp.prototype.clearIdentitiynamesList = function() {
  this.setIdentitiynamesList([]);
};


/**
 * repeated VerifierDetails Verifiers = 2;
 * @return {!Array<!proto.api.themakers.identity.VerifierDetails>}
 */
proto.api.themakers.identity.VerifierDetailsResp.prototype.getVerifiersList = function() {
  return /** @type{!Array<!proto.api.themakers.identity.VerifierDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.identity.VerifierDetails, 2));
};


/** @param {!Array<!proto.api.themakers.identity.VerifierDetails>} value */
proto.api.themakers.identity.VerifierDetailsResp.prototype.setVerifiersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.themakers.identity.VerifierDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.identity.VerifierDetails}
 */
proto.api.themakers.identity.VerifierDetailsResp.prototype.addVerifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.themakers.identity.VerifierDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.identity.VerifierDetailsResp.prototype.clearVerifiersList = function() {
  this.setVerifiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.VerifierDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.VerifierDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.VerifierDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.VerifierDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identityname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supportregular: jspb.Message.getFieldWithDefault(msg, 3, false),
    supportreverse: jspb.Message.getFieldWithDefault(msg, 4, false),
    supportoauth2: jspb.Message.getFieldWithDefault(msg, 5, false),
    supportstatic: jspb.Message.getFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.VerifierDetails}
 */
proto.api.themakers.identity.VerifierDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.VerifierDetails;
  return proto.api.themakers.identity.VerifierDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.VerifierDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.VerifierDetails}
 */
proto.api.themakers.identity.VerifierDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentityname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportregular(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportreverse(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportoauth2(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportstatic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.VerifierDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.VerifierDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.VerifierDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.VerifierDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentityname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupportregular();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSupportreverse();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSupportoauth2();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSupportstatic();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.api.themakers.identity.VerifierDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.VerifierDetails.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string IdentityName = 2;
 * @return {string}
 */
proto.api.themakers.identity.VerifierDetails.prototype.getIdentityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.identity.VerifierDetails.prototype.setIdentityname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool SupportRegular = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.identity.VerifierDetails.prototype.getSupportregular = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.api.themakers.identity.VerifierDetails.prototype.setSupportregular = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool SupportReverse = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.identity.VerifierDetails.prototype.getSupportreverse = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.api.themakers.identity.VerifierDetails.prototype.setSupportreverse = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool SupportOAuth2 = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.identity.VerifierDetails.prototype.getSupportoauth2 = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.api.themakers.identity.VerifierDetails.prototype.setSupportoauth2 = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool SupportStatic = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.identity.VerifierDetails.prototype.getSupportstatic = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.api.themakers.identity.VerifierDetails.prototype.setSupportstatic = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    identitiesList: jspb.Message.toObjectList(msg.getIdentitiesList(),
    proto.api.themakers.identity.IdentityData.toObject, includeInstance),
    verifiersList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp;
  return proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.identity.IdentityData;
      reader.readMessage(value,proto.api.themakers.identity.IdentityData.deserializeBinaryFromReader);
      msg.addIdentities(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addVerifiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.themakers.identity.IdentityData.serializeBinaryToWriter
    );
  }
  f = message.getVerifiersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated IdentityData Identities = 1;
 * @return {!Array<!proto.api.themakers.identity.IdentityData>}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.getIdentitiesList = function() {
  return /** @type{!Array<!proto.api.themakers.identity.IdentityData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.identity.IdentityData, 1));
};


/** @param {!Array<!proto.api.themakers.identity.IdentityData>} value */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.setIdentitiesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.themakers.identity.IdentityData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.identity.IdentityData}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.addIdentities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.themakers.identity.IdentityData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.clearIdentitiesList = function() {
  this.setIdentitiesList([]);
};


/**
 * repeated string Verifiers = 2;
 * @return {!Array<string>}
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.getVerifiersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.setVerifiersList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.addVerifiers = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.prototype.clearVerifiersList = function() {
  this.setVerifiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.IdentityData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.IdentityData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.IdentityData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.IdentityData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identity: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.IdentityData}
 */
proto.api.themakers.identity.IdentityData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.IdentityData;
  return proto.api.themakers.identity.IdentityData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.IdentityData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.IdentityData}
 */
proto.api.themakers.identity.IdentityData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.IdentityData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.IdentityData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.IdentityData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.IdentityData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.api.themakers.identity.IdentityData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.IdentityData.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Identity = 2;
 * @return {string}
 */
proto.api.themakers.identity.IdentityData.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.identity.IdentityData.prototype.setIdentity = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StartReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StartReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StartReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    verifiername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identityname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    identity: jspb.Message.getFieldWithDefault(msg, 3, ""),
    argsMap: (f = msg.getArgsMap()) ? f.toObject(includeInstance, undefined) : [],
    valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StartReq}
 */
proto.api.themakers.identity.StartReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StartReq;
  return proto.api.themakers.identity.StartReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StartReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StartReq}
 */
proto.api.themakers.identity.StartReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerifiername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentityname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    case 4:
      var value = msg.getArgsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    case 5:
      var value = msg.getValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StartReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StartReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StartReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerifiername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentityname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArgsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string VerifierName = 1;
 * @return {string}
 */
proto.api.themakers.identity.StartReq.prototype.getVerifiername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.StartReq.prototype.setVerifiername = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string IdentityName = 2;
 * @return {string}
 */
proto.api.themakers.identity.StartReq.prototype.getIdentityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.identity.StartReq.prototype.setIdentityname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Identity = 3;
 * @return {string}
 */
proto.api.themakers.identity.StartReq.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.identity.StartReq.prototype.setIdentity = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, string> Args = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.themakers.identity.StartReq.prototype.getArgsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.api.themakers.identity.StartReq.prototype.clearArgsMap = function() {
  this.getArgsMap().clear();
};


/**
 * map<string, string> Values = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.themakers.identity.StartReq.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.api.themakers.identity.StartReq.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StartResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StartResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StartResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    directionsMap: (f = msg.getDirectionsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StartResp}
 */
proto.api.themakers.identity.StartResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StartResp;
  return proto.api.themakers.identity.StartResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StartResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StartResp}
 */
proto.api.themakers.identity.StartResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = msg.getDirectionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StartResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StartResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StartResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StartResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirectionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> Directions = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.themakers.identity.StartResp.prototype.getDirectionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.api.themakers.identity.StartResp.prototype.clearDirectionsMap = function() {
  this.getDirectionsMap().clear();
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StatusReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StatusReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StatusReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StatusReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StatusReq}
 */
proto.api.themakers.identity.StatusReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StatusReq;
  return proto.api.themakers.identity.StatusReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StatusReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StatusReq}
 */
proto.api.themakers.identity.StatusReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StatusReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StatusReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StatusReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StatusReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authenticating: (f = msg.getAuthenticating()) && proto.api.themakers.identity.StatusAuthenticating.toObject(includeInstance, f),
    authenticated: (f = msg.getAuthenticated()) && proto.api.themakers.identity.StatusAuthenticated.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.Status}
 */
proto.api.themakers.identity.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.Status;
  return proto.api.themakers.identity.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.Status}
 */
proto.api.themakers.identity.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new proto.api.themakers.identity.StatusAuthenticating;
      reader.readMessage(value,proto.api.themakers.identity.StatusAuthenticating.deserializeBinaryFromReader);
      msg.setAuthenticating(value);
      break;
    case 3:
      var value = new proto.api.themakers.identity.StatusAuthenticated;
      reader.readMessage(value,proto.api.themakers.identity.StatusAuthenticated.deserializeBinaryFromReader);
      msg.setAuthenticated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthenticating();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.identity.StatusAuthenticating.serializeBinaryToWriter
    );
  }
  f = message.getAuthenticated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.identity.StatusAuthenticated.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Token = 1;
 * @return {string}
 */
proto.api.themakers.identity.Status.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.Status.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StatusAuthenticating Authenticating = 2;
 * @return {?proto.api.themakers.identity.StatusAuthenticating}
 */
proto.api.themakers.identity.Status.prototype.getAuthenticating = function() {
  return /** @type{?proto.api.themakers.identity.StatusAuthenticating} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.identity.StatusAuthenticating, 2));
};


/** @param {?proto.api.themakers.identity.StatusAuthenticating|undefined} value */
proto.api.themakers.identity.Status.prototype.setAuthenticating = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.identity.Status.prototype.clearAuthenticating = function() {
  this.setAuthenticating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.identity.Status.prototype.hasAuthenticating = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StatusAuthenticated Authenticated = 3;
 * @return {?proto.api.themakers.identity.StatusAuthenticated}
 */
proto.api.themakers.identity.Status.prototype.getAuthenticated = function() {
  return /** @type{?proto.api.themakers.identity.StatusAuthenticated} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.identity.StatusAuthenticated, 3));
};


/** @param {?proto.api.themakers.identity.StatusAuthenticated|undefined} value */
proto.api.themakers.identity.Status.prototype.setAuthenticated = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.identity.Status.prototype.clearAuthenticated = function() {
  this.setAuthenticated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.identity.Status.prototype.hasAuthenticated = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StatusAuthenticated.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StatusAuthenticated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StatusAuthenticated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StatusAuthenticated.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StatusAuthenticated}
 */
proto.api.themakers.identity.StatusAuthenticated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StatusAuthenticated;
  return proto.api.themakers.identity.StatusAuthenticated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StatusAuthenticated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StatusAuthenticated}
 */
proto.api.themakers.identity.StatusAuthenticated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StatusAuthenticated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StatusAuthenticated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StatusAuthenticated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StatusAuthenticated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string User = 1;
 * @return {string}
 */
proto.api.themakers.identity.StatusAuthenticated.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.StatusAuthenticated.prototype.setUser = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.identity.StatusAuthenticating.repeatedFields_ = [4];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.identity.StatusAuthenticating.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.api.themakers.identity.StatusAuthenticating.ObjectiveCase = {
  OBJECTIVE_NOT_SET: 0,
  SIGNIN: 1,
  SIGNUP: 2,
  ATTACH: 3
};

/**
 * @return {proto.api.themakers.identity.StatusAuthenticating.ObjectiveCase}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.getObjectiveCase = function() {
  return /** @type {proto.api.themakers.identity.StatusAuthenticating.ObjectiveCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.identity.StatusAuthenticating.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StatusAuthenticating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StatusAuthenticating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StatusAuthenticating.toObject = function(includeInstance, msg) {
  var f, obj = {
    signin: jspb.Message.getFieldWithDefault(msg, 1, false),
    signup: jspb.Message.getFieldWithDefault(msg, 2, false),
    attach: jspb.Message.getFieldWithDefault(msg, 3, false),
    completedfactorsList: jspb.Message.toObjectList(msg.getCompletedfactorsList(),
    proto.api.themakers.identity.StatusCompletedFactors.toObject, includeInstance),
    remainingfactors: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StatusAuthenticating}
 */
proto.api.themakers.identity.StatusAuthenticating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StatusAuthenticating;
  return proto.api.themakers.identity.StatusAuthenticating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StatusAuthenticating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StatusAuthenticating}
 */
proto.api.themakers.identity.StatusAuthenticating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSignin(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSignup(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttach(value);
      break;
    case 4:
      var value = new proto.api.themakers.identity.StatusCompletedFactors;
      reader.readMessage(value,proto.api.themakers.identity.StatusCompletedFactors.deserializeBinaryFromReader);
      msg.addCompletedfactors(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemainingfactors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StatusAuthenticating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StatusAuthenticating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StatusAuthenticating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCompletedfactorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.themakers.identity.StatusCompletedFactors.serializeBinaryToWriter
    );
  }
  f = message.getRemainingfactors();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool SignIn = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.getSignin = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.api.themakers.identity.StatusAuthenticating.prototype.setSignin = function(value) {
  jspb.Message.setOneofField(this, 1, proto.api.themakers.identity.StatusAuthenticating.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.clearSignin = function() {
  jspb.Message.setOneofField(this, 1, proto.api.themakers.identity.StatusAuthenticating.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.hasSignin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool SignUp = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.getSignup = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.api.themakers.identity.StatusAuthenticating.prototype.setSignup = function(value) {
  jspb.Message.setOneofField(this, 2, proto.api.themakers.identity.StatusAuthenticating.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.clearSignup = function() {
  jspb.Message.setOneofField(this, 2, proto.api.themakers.identity.StatusAuthenticating.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.hasSignup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool Attach = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.getAttach = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.api.themakers.identity.StatusAuthenticating.prototype.setAttach = function(value) {
  jspb.Message.setOneofField(this, 3, proto.api.themakers.identity.StatusAuthenticating.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.clearAttach = function() {
  jspb.Message.setOneofField(this, 3, proto.api.themakers.identity.StatusAuthenticating.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.hasAttach = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated StatusCompletedFactors CompletedFactors = 4;
 * @return {!Array<!proto.api.themakers.identity.StatusCompletedFactors>}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.getCompletedfactorsList = function() {
  return /** @type{!Array<!proto.api.themakers.identity.StatusCompletedFactors>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.identity.StatusCompletedFactors, 4));
};


/** @param {!Array<!proto.api.themakers.identity.StatusCompletedFactors>} value */
proto.api.themakers.identity.StatusAuthenticating.prototype.setCompletedfactorsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.themakers.identity.StatusCompletedFactors=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.identity.StatusCompletedFactors}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.addCompletedfactors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.themakers.identity.StatusCompletedFactors, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.clearCompletedfactorsList = function() {
  this.setCompletedfactorsList([]);
};


/**
 * optional int64 RemainingFactors = 5;
 * @return {number}
 */
proto.api.themakers.identity.StatusAuthenticating.prototype.getRemainingfactors = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.api.themakers.identity.StatusAuthenticating.prototype.setRemainingfactors = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.StatusCompletedFactors.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.StatusCompletedFactors.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.StatusCompletedFactors} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StatusCompletedFactors.toObject = function(includeInstance, msg) {
  var f, obj = {
    verifiername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identityname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    identity: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.StatusCompletedFactors}
 */
proto.api.themakers.identity.StatusCompletedFactors.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.StatusCompletedFactors;
  return proto.api.themakers.identity.StatusCompletedFactors.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.StatusCompletedFactors} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.StatusCompletedFactors}
 */
proto.api.themakers.identity.StatusCompletedFactors.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerifiername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentityname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.StatusCompletedFactors.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.StatusCompletedFactors.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.StatusCompletedFactors} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.StatusCompletedFactors.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerifiername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentityname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string VerifierName = 1;
 * @return {string}
 */
proto.api.themakers.identity.StatusCompletedFactors.prototype.getVerifiername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.StatusCompletedFactors.prototype.setVerifiername = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string IdentityName = 2;
 * @return {string}
 */
proto.api.themakers.identity.StatusCompletedFactors.prototype.getIdentityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.identity.StatusCompletedFactors.prototype.setIdentityname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Identity = 3;
 * @return {string}
 */
proto.api.themakers.identity.StatusCompletedFactors.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.identity.StatusCompletedFactors.prototype.setIdentity = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.UserMergeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.UserMergeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.UserMergeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.UserMergeReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.UserMergeReq}
 */
proto.api.themakers.identity.UserMergeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.UserMergeReq;
  return proto.api.themakers.identity.UserMergeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.UserMergeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.UserMergeReq}
 */
proto.api.themakers.identity.UserMergeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.UserMergeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.UserMergeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.UserMergeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.UserMergeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.UserMergeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.UserMergeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.UserMergeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.UserMergeResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.UserMergeResp}
 */
proto.api.themakers.identity.UserMergeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.UserMergeResp;
  return proto.api.themakers.identity.UserMergeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.UserMergeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.UserMergeResp}
 */
proto.api.themakers.identity.UserMergeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.UserMergeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.UserMergeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.UserMergeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.UserMergeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.CancelAuthenticationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.CancelAuthenticationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.CancelAuthenticationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.CancelAuthenticationReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.CancelAuthenticationReq}
 */
proto.api.themakers.identity.CancelAuthenticationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.CancelAuthenticationReq;
  return proto.api.themakers.identity.CancelAuthenticationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.CancelAuthenticationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.CancelAuthenticationReq}
 */
proto.api.themakers.identity.CancelAuthenticationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.CancelAuthenticationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.CancelAuthenticationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.CancelAuthenticationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.CancelAuthenticationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.LogoutReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.LogoutReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.LogoutReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.LogoutReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    allsessions: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.LogoutReq}
 */
proto.api.themakers.identity.LogoutReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.LogoutReq;
  return proto.api.themakers.identity.LogoutReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.LogoutReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.LogoutReq}
 */
proto.api.themakers.identity.LogoutReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllsessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.LogoutReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.LogoutReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.LogoutReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.LogoutReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllsessions();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool AllSessions = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.identity.LogoutReq.prototype.getAllsessions = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.api.themakers.identity.LogoutReq.prototype.setAllsessions = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.InitializeStaticVerifierReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.InitializeStaticVerifierReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    verifiername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    argsMap: (f = msg.getArgsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.InitializeStaticVerifierReq}
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.InitializeStaticVerifierReq;
  return proto.api.themakers.identity.InitializeStaticVerifierReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.InitializeStaticVerifierReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.InitializeStaticVerifierReq}
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerifiername(value);
      break;
    case 2:
      var value = msg.getArgsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.InitializeStaticVerifierReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.InitializeStaticVerifierReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerifiername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArgsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string VerifierName = 1;
 * @return {string}
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.prototype.getVerifiername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.InitializeStaticVerifierReq.prototype.setVerifiername = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> Args = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.prototype.getArgsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.api.themakers.identity.InitializeStaticVerifierReq.prototype.clearArgsMap = function() {
  this.getArgsMap().clear();
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.InitializeStaticVerifierResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.InitializeStaticVerifierResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.InitializeStaticVerifierResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.InitializeStaticVerifierResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultMap: (f = msg.getResultMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.InitializeStaticVerifierResp}
 */
proto.api.themakers.identity.InitializeStaticVerifierResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.InitializeStaticVerifierResp;
  return proto.api.themakers.identity.InitializeStaticVerifierResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.InitializeStaticVerifierResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.InitializeStaticVerifierResp}
 */
proto.api.themakers.identity.InitializeStaticVerifierResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getResultMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.InitializeStaticVerifierResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.InitializeStaticVerifierResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.InitializeStaticVerifierResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.InitializeStaticVerifierResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> Result = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.themakers.identity.InitializeStaticVerifierResp.prototype.getResultMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.api.themakers.identity.InitializeStaticVerifierResp.prototype.clearResultMap = function() {
  this.getResultMap().clear();
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.VerifyReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.VerifyReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.VerifyReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.VerifyReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    verifiername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    verificationcode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    identityname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    identity: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.VerifyReq}
 */
proto.api.themakers.identity.VerifyReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.VerifyReq;
  return proto.api.themakers.identity.VerifyReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.VerifyReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.VerifyReq}
 */
proto.api.themakers.identity.VerifyReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerifiername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationcode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentityname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.VerifyReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.VerifyReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.VerifyReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.VerifyReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerifiername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVerificationcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIdentityname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string VerifierName = 1;
 * @return {string}
 */
proto.api.themakers.identity.VerifyReq.prototype.getVerifiername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.VerifyReq.prototype.setVerifiername = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string VerificationCode = 2;
 * @return {string}
 */
proto.api.themakers.identity.VerifyReq.prototype.getVerificationcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.identity.VerifyReq.prototype.setVerificationcode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string IdentityName = 3;
 * @return {string}
 */
proto.api.themakers.identity.VerifyReq.prototype.getIdentityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.identity.VerifyReq.prototype.setIdentityname = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Identity = 4;
 * @return {string}
 */
proto.api.themakers.identity.VerifyReq.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.identity.VerifyReq.prototype.setIdentity = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.LoginAsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.LoginAsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.LoginAsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.LoginAsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.LoginAsReq}
 */
proto.api.themakers.identity.LoginAsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.LoginAsReq;
  return proto.api.themakers.identity.LoginAsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.LoginAsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.LoginAsReq}
 */
proto.api.themakers.identity.LoginAsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.LoginAsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.LoginAsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.LoginAsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.LoginAsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string User = 1;
 * @return {string}
 */
proto.api.themakers.identity.LoginAsReq.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.LoginAsReq.prototype.setUser = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.identity.LoginAsResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.identity.LoginAsResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.identity.LoginAsResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.LoginAsResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.identity.LoginAsResp}
 */
proto.api.themakers.identity.LoginAsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.identity.LoginAsResp;
  return proto.api.themakers.identity.LoginAsResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.identity.LoginAsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.identity.LoginAsResp}
 */
proto.api.themakers.identity.LoginAsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.identity.LoginAsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.identity.LoginAsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.identity.LoginAsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.identity.LoginAsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Session = 1;
 * @return {string}
 */
proto.api.themakers.identity.LoginAsResp.prototype.getSession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.identity.LoginAsResp.prototype.setSession = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string User = 2;
 * @return {string}
 */
proto.api.themakers.identity.LoginAsResp.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.identity.LoginAsResp.prototype.setUser = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Error = 3;
 * @return {string}
 */
proto.api.themakers.identity.LoginAsResp.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.identity.LoginAsResp.prototype.setError = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.api.themakers.identity);
