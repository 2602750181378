var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Button', _vm._g({
    attrs: {
      "color": "green",
      "disabled": _vm.disabled,
      "add": true
    }
  }, _vm.$listeners), [_c('span', {
    staticClass: "fs-20 mr-3",
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  }, [_vm._v("\n        +\n    ")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.text))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }