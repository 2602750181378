import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { DocumentTypeState } from '@/store/document_type/documentTypeState'
import { DocumentType } from '@/model/DocumentType'

const DocumentTypeGetters: GetterTree<DocumentTypeState, RootState> = {
    getTypes: (state: DocumentTypeState): Array<DocumentType> => state.documentTypes,

    getTypesByCenterId: (state: DocumentTypeState) => (centerId: string): Array<DocumentType> => state.documentTypes.filter((type: DocumentType) => type.centerId === centerId),

    getTypeById: (state: DocumentTypeState) => (id: string): DocumentType | null => state.documentTypes.find((type: DocumentType) => type.id === id) ?? null
}

export default DocumentTypeGetters
