













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import TextareaNew from '@/components/common/inputs/TextareaNew.vue'

@Component<DocumentCommentary>({
    components: { TextareaNew }
})

export default class DocumentCommentary extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) documentId!: string
    @Prop({ default: '' }) comment!: string
    @Prop({ default: false }) canChangeRequisite!: boolean

    // *************************************************************
    // DATA PARAMS
    documentCommentary = ''
    loadingComment = false

    // *************************************************************
    // WATCH
    @Watch('comment', { immediate: true })
    handleCommentChange(to: string): void {
        this.documentCommentary = to
    }

    // *************************************************************
    // METHODS
    @Action('document/callUpdateComment')
    callUpdateComment!: (params: cuntPB.UpdateDocumentCommentReq) => Promise<void>

    updateComment(): void {
        if (this.loadingComment) {
            return
        }

        if (this.comment === this.documentCommentary.trim()) {
            return
        }

        this.loadingComment = true

        this.callUpdateComment({
            ID: this.documentId,
            Comment: this.documentCommentary.trim()
        })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingComment = false
            })
    }
}
