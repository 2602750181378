











































import { Component, Emit, Model, Prop, Ref, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Document, DocumentRequisite } from '@/model/Document'
import { Directory, Record } from '@/model/Directory'

import RecordSelectModal from '@/components/directory/RecordSelectModal.vue'
import TableModal from '@/components/document/common_document/requisites/TableModal.vue'
import EditorModal from '@/components/document/common_document/requisites/EditorModal.vue'

@Component<SelectDocumentRequisiteCard>({
    components: {
        RecordSelectModal,
        TableModal,
        EditorModal
    }
})

export default class SelectDocumentRequisiteCard extends Vue {
    @Ref() readonly tableModal!: TableModal
    @Ref() readonly selectItem!: RecordSelectModal

    @Model('input', { type: String }) readonly value!: string

    // *************************************************************
    // DATA PARAMS
    visibleEditorModal = false
    visibleRecordModal = false

    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) requisite!: DocumentRequisite
    @Prop({ default: false }) canChangeRequisite!: boolean
    @Prop({ default: false }) deleted!: boolean
    @Prop({ default: false }) loading!: boolean

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryById') getDirectoryById!: (dirId: string) => Directory | null
    @Getter('directories/getRecordById') getRecordById!: (recordId: string) => Record | null

    @Getter('document/getDocumentById') getDocumentById!: (documentId: string) => Document | null

    get documentId(): string {
        return this.$route.params.id
    }

    get document(): Document | null {
        return this.getDocumentById(this.documentId)
    }

    get canShowModal(): boolean {
        if (this.loading) {
            return false
        }

        if (this.requisite.type.type === 'req_editor') {
            return this.canChangeRequisite || !!this.requisite.value
        }

        if (this.requisite.type.type === 'req_table') {
            // Если есть заполненная таблица
            return !!this.requisite.value
        }

        // req_do
        // Если значение этого реквизита можно менять и у него нет пометки 'Удален'
        return (this.canChangeRequisite && !this.deleted)
    }

    get directoryInfo(): Directory | null {
        return this.getDirectoryById(this.requisite.type.subType)
    }

    get displayRequisiteValue(): string {
        // Если в открытом документе в данном реквизите задано значение
        // И тип реквизита - справочник
        if (this.requisite.type.type === 'req_editor') {
            if (!this.requisite.value) {
                return 'Не заполнено'
            }
            const value = this.requisite.value.replace(/<[^>]*>/g, ' ')
            const sim = value.length
            const words = value.split(' ').length
            return `Слов: ${words}, Символов: ${sim}`
        }

        if (this.requisite.type.type === 'req_do') {
            if (!this.requisite.value) {
                return 'Не заполнено'
            }
            return this.requisite.recordName
        }

        // this.requisite.type.type === "req_table"
        if (this.requisite.value) {
            // Если тип реквизита - таблица, просто возвращаем ее значение
            const value = JSON.parse(this.requisite.value)

            let columns = '0 столбцов'
            let rows = '0 строк'
            if (value.headlines && value.headlines.length) {
                columns = `${value.headlines.length} столбцов`
            }
            if (value.data && value.data.length) {
                rows = `${value.data.length} строк`
            }
            return `Таблица: ${columns}, ${rows} `
        } else {
            return 'Таблица не заполнена'
        }
    }

    showModal(): void {
        if (!this.canShowModal) {
            return
        }

        if (this.requisite.type.type === 'req_do') {
            if (!this.document) {
                return
            }

            this.selectItem.show(this.requisite.type.subType, this.document.base.unit)
            return
        }

        if (this.requisite.type.type === 'req_editor') {
            this.visibleEditorModal = true
            return
        }

        // req_table
        this.tableModal.show(this.requisite.value)
    }

    onChangeRecord(record: Record): void {
        this.emitChangeRecord(record.id)
    }

    // *************************************************************
    // EMIT
    @Emit('changeRecord')
    emitChangeRecord(val: string): string {
        return val
    }

    @Emit('changeEditor')
    emitChangeEditor(val: string): string {
        return val
    }
}
