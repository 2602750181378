var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-row-center"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('InputBase', {
    attrs: {
      "disabled": _vm.external,
      "loading": _vm.loadingName,
      "required": "",
      "placeholder": "Имя поля"
    },
    on: {
      "blur": _vm.onChangeName,
      "enter": _vm.onChangeName
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "form__error"
  }, [!_vm.name.trim() ? _c('span', [_vm._v("Имя поля не может быть пустым")]) : _vm._e()])], 1), _vm._v(" "), _c('Checkbox', {
    staticClass: "mx-3 mb-6 mt-0",
    attrs: {
      "disabled": _vm.external || _vm.loadingRequired,
      "tooltip": "Обязательное поле"
    },
    on: {
      "change": _vm.onChangeRequired
    },
    model: {
      value: _vm.required,
      callback: function callback($$v) {
        _vm.required = $$v;
      },
      expression: "required"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mr-3"
  }, [_c('Select', {
    staticClass: "w-100 h-36 min-width-200",
    attrs: {
      "placeholder": "Тип поля",
      "disabled": !_vm.columnInfo.new,
      "options": _vm.columnTypes,
      "required": ""
    },
    on: {
      "change": _vm.onChangeType
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "form__error"
  }, [!_vm.type ? _c('span', [_vm._v("Необходимо выбрать тип")]) : _vm._e()])], 1), _vm._v(" "), _vm.type === 'reference' ? _c('div', {
    staticClass: "mr-3"
  }, [_c('Select', {
    staticClass: "w-100 h-36 min-width-200",
    attrs: {
      "placeholder": "Справочник",
      "disabled": !_vm.columnInfo.new,
      "options": _vm.directories,
      "required": ""
    },
    on: {
      "change": _vm.onChangeSubType
    },
    model: {
      value: _vm.subType,
      callback: function callback($$v) {
        _vm.subType = $$v;
      },
      expression: "subType"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "form__error"
  }, [!_vm.subType ? _c('span', [_vm._v("Необходимо выбрать Справочник")]) : _vm._e()])], 1) : _vm._e(), _vm._v(" "), !!_vm.directoryId && _vm.columnInfo.new ? _c('Button', {
    staticClass: "mr-3 mb-6",
    attrs: {
      "disabled": _vm.addColumnDisabled,
      "color": "green"
    },
    on: {
      "click": _vm.addColumn
    }
  }, [_vm._v("Сохранить")]) : _vm._e(), _vm._v(" "), _c('ButtonDelete', {
    staticClass: "mb-6",
    attrs: {
      "disabled": _vm.external || _vm.columnInfo.removeDisabledReason,
      "tooltip": _vm.columnInfo.removeDisabledReason || 'Удалить поле',
      "flow": "left"
    },
    on: {
      "click": _vm.removeColumn
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }