<template>
    <Card class="document-event-card">
        <div slot="card-body">
            <div
                :class="action.class"
                class="document-event-card__action fs-20"
            >
                {{ action.text }}
            </div>
            <div
                v-if="event.type === 'task'"
                class="mt-1"
            >
                {{ event.task.text }}
            </div>
            <hr class="document-event-card__hr">

            <div class="my-1">
                <span class="gray">Тип:</span>
                <router-link
                    :to="typePath"
                    class="ml-2 document-event-card__link text-wrap"
                >
                    {{ typeName }}
                </router-link>
            </div>
            <div class="my-1">
                <span class="gray">Документ:</span>
                <router-link
                    :to="documentPath"
                    class="ml-2 document-event-card__link text-wrap"
                >
                    {{ event.document.name }}
                </router-link>
            </div>
            <Attribute
                attribute="от"
                :text="event.document.creationTimeLocalizedLongString"
            />
        </div>
    </Card>
</template>


<script>
import { mapGetters } from 'vuex'
import Card from '@/components/common/cards/Card'
import Attribute from '@/components/common/Attribute'

export default {
    components: { Card, Attribute },
    props: {
        event: { type: Object, default() { return {}} }
    },
    computed: {
        ...mapGetters('document_type', ['getTypeById']),
        typeName() {
            return (this.getTypeById(this.event.document.type) || {}).name
        },
        typePath() {
            return {
                name: 'module',
                params: {
                    section: 'documents',
                    module: this.event.document.type
                }
            }
        },
        documentPath() {
            return {
                name: 'document',
                params: {
                    section: 'documents',
                    module: this.event.document.type,
                    id: this.event.document.id
                }
            }
        },
        action() {
            if (this.event.type === 'approval') {
                return {
                    text: 'Согласовать',
                    class: 'green-2'
                }
            }

            if (this.event.task.type === 'confirmation') {
                return {
                    text: 'Подтвердить',
                    class: 'amber'
                }
            }

            return {
                text: 'Выполнить',
                class: 'pink-1'
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/colors.styl'

.document-event-card
    padding 10px 8px

.document-event-card__action
    font-weight 900

.document-event-card__hr
    height 1px
    background-color $gray-4
    border none

.document-event-card__link
    color $pink-1
    &:hover
        color $blue-1

</style>
