var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "click": function click($event) {
        return _vm.emitSelectUnit('');
      }
    }
  }, [_c('svg', {
    attrs: {
      "height": _vm.svgHeight,
      "width": _vm.svgWidth
    }
  }, [_vm._l(_vm.links, function (link) {
    return _c('path', {
      key: link.id,
      staticClass: "line",
      attrs: {
        "d": link.d
      }
    });
  }), _vm._v(" "), _vm._l(_vm.units, function (unit) {
    return _c('g', {
      key: unit.id,
      staticClass: "unit-list__unit",
      style: {
        transform: 'translate(' + unit.x + 'px, ' + unit.y + 'px)'
      },
      on: {
        "click": [function ($event) {
          $event.stopPropagation();
        }, function ($event) {
          return _vm.emitSelectUnit(unit.id);
        }]
      }
    }, [_c('circle', {
      attrs: {
        "stroke": "#DDA0DD",
        "r": unit.radius,
        "fill": unit.id === _vm.selectedUnit ? '#673AB7' : unit.root ? '#9f89ca' : '#f9bcf9'
      }
    }), _vm._v(" "), _c('text', {
      staticClass: "units-list__text",
      attrs: {
        "alignment-baseline": "middle"
      }
    }, [_vm._v("\n                " + _vm._s(unit.name) + " (level: " + _vm._s(unit.depth) + ")\n            ")])]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }