import api from 'api1'
import newFamiliarization from '@/model/convert/newFamiliarization'

export default function ({ dispatch }, { id, userIds, unitIds }) {
    return new Promise((resolve, reject) => {
        api.cunt.FamiliarizationSvcClient.addMembers(api.cunt.AddMembersReq({
            ID: id,
            UserIDs: userIds,
            UnitIDs: unitIds
        }), data => {
            data = api.cunt.Familiarization(data)
            console.log('::DATA::FamiliarizationSvc.addMembers:', data)

            dispatch('putFamiliarization', {
                familiarization: newFamiliarization(data)
            })

            resolve()
        }, error => {
            (error ? console.warn : console.log)('::DONE::FamiliarizationSvc.addMembers:', error)
            reject(error)
        })
    })
}
