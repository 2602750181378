var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tosser-builder"
  }, [_c('ButtonGreen', {
    staticClass: "tosser-builder__button-add",
    attrs: {
      "text": "Добавить правило"
    },
    on: {
      "click": _vm.addRule
    }
  }), _vm._v(" "), _c('div', {
    ref: "rules",
    staticClass: "tosser-builder__body"
  }, _vm._l(_vm.rulesFilterByDocType, function (rule, index) {
    return _c('Rule', {
      key: rule.id,
      staticClass: "tosser-builder__rule box-shadow__single",
      attrs: {
        "rule-info": rule,
        "requisites": _vm.requisites,
        "unit-id": _vm.rootUnitId,
        "users": _vm.users,
        "units": _vm.units,
        "first-rule": index === 0,
        "last-rule": index === _vm.rulesFilterByDocType.length - 1
      }
    });
  }), 1), _vm._v(" "), _c('ButtonGreen', {
    staticClass: "tosser-builder__save",
    attrs: {
      "disabled": !_vm.getTosserChange,
      "text": "Сохранить"
    },
    on: {
      "click": _vm.save
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }