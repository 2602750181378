import api from 'api1'
import newFamiliarization from '@/model/convert/newFamiliarization'

export default function ({ dispatch }, { id, title, description, forceNewMembers, unitIds, until, documentIds, userIds }) {
    return new Promise((resolve, reject) => {
        api.cunt.FamiliarizationSvcClient.update(api.cunt.UpdateFamiliarizationReq({
            ID: id,
            Title: title,
            Description: description,
            ForceNewMembers: forceNewMembers,
            UnitIDs: unitIds,
            Until: until,
            DocumentIDs: documentIds,
            UserIDs: userIds
        }), data => {
            data = api.cunt.Familiarization(data)
            console.log('::DATA::FamiliarizationSvc.updateFamiliarization:', data)

            dispatch('putFamiliarization', {
                familiarization: newFamiliarization(data)
            })

            resolve(data.ID)
        }, error => {
            (error ? console.warn : console.log)('::DONE::FamiliarizationSvc.updateFamiliarization:', error)
            reject(error)
        })
    })
}
