























import { Component, Vue, Prop, Ref } from 'vue-property-decorator'

@Component({})

export default class Dropdown extends Vue {
    @Ref() readonly activator!: HTMLDivElement
    @Ref() readonly body!: HTMLDivElement

    // *************************************************************
    // DATA PARAMS
    activatedByClick = false
    activatedByHover = false
    left = 0

    // *************************************************************
    // PROPS
    @Prop({ default: 'down' }) position!: 'right' | 'left' | 'down'
    @Prop({ default: true }) opensOnHover!: boolean // открывается ли при наведении
    @Prop({ default: false }) disabled!: boolean

    // *************************************************************
    // COMPUTED
    get styleObject(): { [key: string]: string | number } {
        if (this.position === 'down') {
            return {
                left: 0,
                top: 'calc(100% - 2px)'
            }
        }
        if (this.position === 'right') {
            return {
                top: 0,
                left: '100%'
            }
        }
        if (this.position === 'left') {
            return {
                top: 0,
                right: `${this.left}px`
            }
        }
        return {}
    }

    get showBody(): boolean {
        if (this.disabled) {
            return false
        }
        return this.activatedByHover || this.activatedByClick
    }

    // *************************************************************
    // MOUNTED
    mounted(): void {
        this.left = this.activator.clientWidth
    }

    // *************************************************************
    // METHODS
    close(): void {
        this.activatedByClick = false
        this.activatedByHover = false
    }

    onClickDropdown(): void {
        this.activatedByClick = true
    }

    onEnterDropdown(): void {
        if (this.opensOnHover) {
            this.activatedByHover = true
        }
    }

    onLeaveDropdown(): void {
        this.activatedByHover = false
    }

    handleClickOutside(): void {
        this.activatedByClick = false
    }
}
