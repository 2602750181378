var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pos-relative"
  }, [_vm.multiple ? _c('div', {
    key: "multiple",
    staticClass: "action-select-card__multiple"
  }, _vm._l(_vm.selectedList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "action-select-card__multiple-card"
    }, [_c('span', [_vm._v(_vm._s(item.name))]), _vm._v(" "), _c('ButtonClose', {
      staticClass: "select-card__selected-card_close",
      attrs: {
        "small": true,
        "color": "violet"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(item.id);
        }
      }
    })], 1);
  }), 0) : _vm._e(), _vm._v(" "), _vm.multiple || !_vm.value ? _c('InputSearch', {
    staticClass: "action-select-card__input",
    attrs: {
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "active-focus": false
    },
    on: {
      "focus": function focus($event) {
        _vm.focus = true;
      },
      "blur": function blur($event) {
        _vm.focus = false;
      }
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }) : !_vm.multiple && !!_vm.value ? _c('div', {
    key: "single",
    staticClass: "select-card__selected-card-container"
  }, [_vm.entity === 'user' && _vm.selectedCard ? _c('EmployeeCard', {
    staticClass: "select-card__selected-card",
    attrs: {
      "unit-user": _vm.selectedCard,
      "small": true,
      "type": "system"
    }
  }) : _vm._e(), _vm._v(" "), _vm.entity === 'unit' ? _c('UnitCard', {
    staticClass: "select-card__selected-card",
    attrs: {
      "unit-info": _vm.selectedCard,
      "small": true,
      "type": "system"
    }
  }) : _vm._e(), _vm._v(" "), _vm.entity === 'directoryItem' ? _c('div', {
    staticClass: "select-card__selected-card"
  }, [_vm._v("\n            " + _vm._s((_vm.selectedCard || {}).name) + "\n        ")]) : _vm._e(), _vm._v(" "), _vm.entity === 'item' ? _c('ItemSelectCard', {
    staticClass: "select-card__selected-directory",
    attrs: {
      "item": _vm.selectedCard
    }
  }) : _vm._e(), _vm._v(" "), _c('ButtonClose', {
    staticClass: "select-card__selected-card_close",
    attrs: {
      "small": true,
      "color": "violet"
    },
    on: {
      "click": function click($event) {
        return _vm.remove(_vm.value);
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.showList ? _c('div', {
    staticClass: "select-card__cards",
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.show = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.show = false;
      }
    }
  }, [_vm.entity === 'user' ? _c('div', {
    staticClass: "action-select-card__cards"
  }, _vm._l(_vm.itemsFilter, function (user) {
    return _c('EmployeeCard', {
      key: user.id,
      staticClass: "action-select-card__card",
      attrs: {
        "unit-user": user,
        "popover": false,
        "arrow": false,
        "type": "system"
      },
      on: {
        "click": function click($event) {
          return _vm.selectCard(user.id);
        }
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _vm.entity === 'unit' ? _c('div', {
    staticClass: "action-select-card__cards"
  }, _vm._l(_vm.itemsFilter, function (unit) {
    return _c('UnitCard', {
      key: unit.id,
      staticClass: "action-select-card__card",
      attrs: {
        "unit-info": unit
      },
      on: {
        "click": function click($event) {
          return _vm.selectCard(unit.id);
        }
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _vm.entity === 'directoryItem' ? _c('div', {
    staticClass: "action-select-card__cards"
  }, _vm._l(_vm.itemsFilter, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "action-select-card__card",
      on: {
        "click": function click($event) {
          return _vm.selectCard(item.id);
        }
      }
    }, [_vm._v("\n                " + _vm._s(item.name) + "\n            ")]);
  }), 0) : _vm._e(), _vm._v(" "), _vm.entity === 'item' ? _c('div', {
    staticClass: "action-select-card__cards"
  }, _vm._l(_vm.itemsFilter, function (item) {
    return _c('ItemSelectCard', {
      key: item.id,
      staticClass: "action-select-card__card",
      attrs: {
        "item": item
      },
      on: {
        "click": function click($event) {
          return _vm.selectCard(item.id);
        }
      }
    });
  }), 1) : _vm._e()]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }