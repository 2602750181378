import { cuntPB } from 'api'

export class ActorIds {
    public userId: string
    public unitId: string

    constructor(ids: cuntPB.ActorIDs) {
        this.userId = ids.UserID
        this.unitId = ids.UnitID
    }
}

export class UserName {
    public first: string
    public middle: string
    public last: string

    constructor(name: cuntPB.UserName | null) {
        this.first = name?.First ?? ''
        this.middle = name?.Middle ?? ''
        this.last = name?.Last ?? ''
    }

    get fullName(): string {
        if (!this.middle) {
            return `${this.last} ${this.first}`
        } else {
            return `${this.last} ${this.first} ${this.middle}`
        }
    }

    // Фамилия и инициалы
    get shortName(): string {
        if (!this.middle) {
            return `${this.last} ${this.first.substring(0, 1)}.`
        } else {
            return `${this.last} ${this.first.substring(
                0,
                1
            )}.${this.middle.substring(0, 1)}.`
        }
    }
}

export class UserInfo {
    public id = ''
    public name: UserName

    constructor(user: cuntPB.UserInfo | null) {
        this.id = user?.ID ?? ''
        this.name = new UserName(user?.Name ?? null)
    }
}

export class UnitInfo {
    public id: string
    public name: string

    constructor(unit: cuntPB.UnitInfo) {
        this.id = unit.ID
        this.name = unit.Name
    }
}

export class Actor {
    public user: UserInfo | null
    public unit: UnitInfo | null

    constructor(actor: cuntPB.Actor | null) {
        this.user = actor?.User ? new UserInfo(actor.User) : null
        this.unit = actor?.Unit ? new UnitInfo(actor.Unit) : null
    }
}
