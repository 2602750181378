var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.familiarization ? _c('div', {
    staticClass: "familiarization-view__grid mt-5"
  }, [_c('div', {
    staticClass: "familiarization-view__text familiarization-view__title"
  }, [_vm._v("\n        " + _vm._s(_vm.familiarization.name) + "\n    ")]), _vm._v(" "), _c('div', [_c('div', [_vm._v("\n            Дата создания:\n            "), _c('span', {
    staticClass: "familiarization__item"
  }, [_vm._v(_vm._s(_vm.familiarization.creationTimeLocalizedShortString))])]), _vm._v(" "), _c('div', [_vm._v("\n            Статус:\n            "), _c('span', {
    staticClass: "familiarization__item"
  }, [_vm._v(_vm._s(_vm.statuses[_vm.familiarization.status]))])]), _vm._v(" "), _c('div', [_vm._v("\n            Автор:\n            "), _c('span', {
    staticClass: "familiarization__item"
  }, [_vm._v(_vm._s(_vm.familiarization.actor.user.name.shortName))])]), _vm._v(" "), _vm.familiarization.until ? _c('div', [_vm._v("\n            Ознакомиться до:\n            "), _c('span', {
    style: {
      color: _vm.familiarization.until < _vm.timeNow ? 'red' : 'black'
    }
  }, [_vm._v(_vm._s(_vm.familiarization.untilLocalizedShortString) + "\n            ")])]) : _vm._e()]), _vm._v(" "), _c('div', [_vm.familiarization.description ? _c('EditorViewer', {
    staticClass: "familiarization-view__description",
    attrs: {
      "value": _vm.familiarization.description
    }
  }) : _vm._e(), _vm._v(" "), _vm.familiarization.documents.length ? _c('h3', [_vm._v("\n            Документы:\n        ")]) : _vm._e(), _vm._v(" "), _c('div', _vm._l(_vm.familiarization.documents, function (doc) {
    return _c('div', {
      key: doc.id,
      staticClass: "familiarization__document",
      on: {
        "click": function click($event) {
          return _vm.openDocument(doc);
        }
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.typeNames[doc.type]) + ": "), _c('span', {
      staticClass: "pink-1"
    }, [_vm._v(_vm._s(doc.name))])])]);
  }), 0), _vm._v(" "), _vm.familiarization.files.length ? _c('Files', {
    staticClass: "familiarization__margin",
    attrs: {
      "files": _vm.familiarization.files,
      "document-id": _vm.familiarization.id,
      "can-add-files": false,
      "can-change": false,
      "group-name": "familiarization_attachment",
      "name": "familiarization"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', [_vm.meFamiliarized ? _c('div', [!_vm.meFamiliarized.status ? _c('ButtonGreen', {
    staticClass: "mb-3 w-100",
    attrs: {
      "disabled": _vm.familiarization.status !== 'active',
      "text": "Я ознакомлен"
    },
    on: {
      "click": _vm.familiarize
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mb-1 flex-row-center"
  }, [_c('div', {
    staticClass: "familiarization__check",
    class: {
      'familiarization__check_checked': _vm.meFamiliarized.status
    }
  }), _vm._v(" "), _c('EmployeeCard', {
    attrs: {
      "unit-user": _vm.meFamiliarized,
      "small": true,
      "type": "system"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.users, function (user) {
    return _c('div', {
      key: user.id,
      staticClass: "mb-1 flex-row-center"
    }, [_c('div', {
      staticClass: "familiarization__check",
      class: {
        'familiarization__check_checked': user.status
      }
    }), _vm._v(" "), _c('EmployeeCard', {
      attrs: {
        "unit-user": user,
        "small": true,
        "type": "system"
      }
    })], 1);
  }), 0)])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }