var render = function () {
  var _vm$getStatus, _vm$getStatus2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authentication-form"
  }, [_c('Tabs', {
    staticClass: "mb-9",
    attrs: {
      "tabs": _vm.tabs,
      "tab-height": 40
    },
    model: {
      value: _vm.authType,
      callback: function callback($$v) {
        _vm.authType = $$v;
      },
      expression: "authType"
    }
  }), _vm._v(" "), (_vm$getStatus = _vm.getStatus) !== null && _vm$getStatus !== void 0 && _vm$getStatus.authenticating ? _c('div', {
    staticClass: "authentication-form__text"
  }, [_vm._v("\n        Введите номер мобильного телефона или email и мы отправим проверочный код\n    ")]) : _vm._e(), _vm._v(" "), (_vm$getStatus2 = _vm.getStatus) !== null && _vm$getStatus2 !== void 0 && _vm$getStatus2.authenticating ? _c('Tabs', {
    staticClass: "mb-9",
    attrs: {
      "tabs": _vm.identityTabs,
      "tab-height": 40
    },
    model: {
      value: _vm.identityName,
      callback: function callback($$v) {
        _vm.identityName = $$v;
      },
      expression: "identityName"
    }
  }) : _vm._e(), _vm._v(" "), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [!_vm.verifierName && _vm.identityName === 'phone' ? _c('PhoneInput', {
    key: "phone",
    model: {
      value: _vm.identity,
      callback: function callback($$v) {
        _vm.identity = $$v;
      },
      expression: "identity"
    }
  }) : !_vm.verifierName && _vm.identityName === 'email' ? _c('EmailInput', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Введите email"
    },
    model: {
      value: _vm.identity,
      callback: function callback($$v) {
        _vm.identity = $$v;
      },
      expression: "identity"
    }
  }) : !!_vm.verifierName ? _c('CodeInput', {
    key: "phoneCode",
    staticClass: "w-100",
    attrs: {
      "mask": _vm.codeMask
    },
    model: {
      value: _vm.verificationCode,
      callback: function callback($$v) {
        _vm.verificationCode = $$v;
      },
      expression: "verificationCode"
    }
  }) : _vm._e(), _vm._v(" "), _vm.visibleButton ? _c('Button', {
    staticClass: "mt-1 mb-7 w-100",
    attrs: {
      "type": "submit",
      "disabled": _vm.buttonDisabled,
      "size": "middle",
      "color": "green"
    }
  }, [_vm._v(_vm._s(_vm.buttonText))]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }