



































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { News } from '@/store/user_options/news'
import { User } from '@/model/User'

import Modal from '@/components/common/modals/Modal.vue'
import HelpCard from '@/components/about_system/HelpCard.vue'
import Checkbox from '@/components/common/inputs/Checkbox.vue'
import Button from '@/components/common/buttons/Button.vue'
import Popover from '@/components/common/Popover.vue'
import QR from '@/components/user/QR.vue'

@Component<InfoModal>({
    components: {
        Modal,
        HelpCard,
        Checkbox,
        Button,
        Popover,
        QR
    }
})

export default class InfoModal extends Vue {
    // *************************************************************
    // DATA PARAMS
    readAll = false
    visibleModal = false

    // *************************************************************
    // COMPUTED
    @Getter('user_options/getNews') getNews!: Array<News>
    @Getter('user_options/getNotReadNewsIds') getNotReadNewsIds!: Array<string>

    @Getter('me/getMe') getMe!: User | null

    get meId(): string {
        return this.getMe?.id || ''
    }

    // *************************************************************
    // WATCH
    @Watch('meId')
    handleMeIdChange(): void {
        if (this.getNotReadNewsIds.length) {
            this.show()
        }
    }

    // *************************************************************
    // MOUNTED
    mounted(): void {
        if (this.getNotReadNewsIds.length) {
            this.show()
        }
    }

    // *************************************************************
    // METHODS
    @Action('user_options/setReadNewsIds')
    setReadNewsIds!: (readNewsIds: Array<string>) => void

    show(): void {
        this.visibleModal = true
    }

    close(): void {
        this.visibleModal = false
    }

    onClickViewed(): void {
        // Если стоит галочка 'Больше не показывать'
        if (this.readAll) {
            this.setReadNewsIds(this.getNews.map((n: News) => n.id))
        }
        this.visibleModal = false
    }
}
