var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "event"
  }, [_c('div', {
    staticClass: "flex-row-center flex-nowrap"
  }, [_c('div', {
    staticClass: "mr-4"
  }, [_c('div', [_vm._v(_vm._s(_vm.actorName))]), _vm._v(" "), _c('div', {
    staticClass: "fs-12"
  }, [_vm._v(_vm._s(_vm.event.creationTimeStampString))]), _vm._v(" "), _c('div', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.name))])]), _vm._v(" "), _c('div', {
    staticClass: "event__fields"
  }, [_vm._t("fields")], 2)]), _vm._v(" "), _vm._t("body")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }