










































import { Component, Vue, Prop, Model, Emit } from 'vue-property-decorator'

import { SelectOption } from '@/components/common/select/model'

@Component({})

export default class Select extends Vue {
    @Model('change', { type: String }) readonly value!: string

    // *************************************************************
    // PROPS

    @Prop({ default: () => []}) options!: Array<SelectOption>
    @Prop({ default: '' }) placeholder!: string
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: false }) loading!: boolean
    @Prop({ default: true }) disabledChangedOption!: boolean //  запрет на повторный выбор option
    @Prop({ type: Boolean, default: false }) required!: boolean // только красный placeholder

    // *************************************************************
    // DATA PARAMS
    showSelect = false

    get selectedName(): string {
        if (!this.value) {
            return `...${this.placeholder}`
        }

        const selected = this.options.find((o: SelectOption) => o.id === this.value)
        if (!selected) {
            return 'Выбранное значение не найдено!'
        }
        return selected.name
    }

    get optionsWithDisabled(): Array<SelectOption> {
        return this.options.map((option: SelectOption) => {
            const disabled = !this.disabledChangedOption ? false : option.id === this.value
            return {
                ...option,
                disabled: disabled || option.disabled
            }
        })
    }

    // *************************************************************
    // COMPUTED
    get selectDisabled(): boolean {
        return this.disabled || this.loading
    }

    // *************************************************************
    // METHODS
    handleClickOutside(): void {
        this.showSelect = false
    }

    onClickSelect(): void {
        if (!this.disabled) {
            this.showSelect = true
        }
    }

    selectOption(option: SelectOption): void {
        if (option.disabled) {
            return
        }

        this.showSelect = false
        this.emitChange(option.id)
    }

    // *************************************************************
    // EMIT
    @Emit('change')
    emitChange(val: string): string {
        return val
    }
}
