var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DocumentEventBase', {
    attrs: {
      "event": _vm.event,
      "name": _vm.name
    }
  }, [_c('div', {
    attrs: {
      "slot": "fields"
    },
    slot: "fields"
  }, _vm._l(_vm.fields, function (field) {
    return _c('div', {
      key: field.id,
      staticClass: "mb-1"
    }, [_c('div', {
      staticClass: "fs-14"
    }, [_vm._v("\n                " + _vm._s(field.name) + ":\n            ")]), _vm._v(" "), _c('span', {
      staticClass: "fw-600"
    }, [_vm._v(_vm._s(field.body))])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }