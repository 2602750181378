var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TextareaNew', {
    attrs: {
      "min-lines": 2,
      "max-lines": 5,
      "font-size": 15,
      "placeholder": "Комментарий",
      "disabled": !_vm.canChangeRequisite,
      "loading": _vm.loadingComment
    },
    on: {
      "blur": _vm.updateComment
    },
    model: {
      value: _vm.documentCommentary,
      callback: function callback($$v) {
        _vm.documentCommentary = $$v;
      },
      expression: "documentCommentary"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }