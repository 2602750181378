import Vue from 'vue'
import { MutationTree } from 'vuex'
import { TaskState } from '@/store/task/task'

import { Task } from '@/model/Task'

const TaskMutations: MutationTree<TaskState> = {
    putTask(state: TaskState, task: Task) {
        state.tasks.push(task)
        Vue.set(state.tasksMap, task.id, task)
    },

    replaceTask(state: TaskState, task: Task) {
        const index = state.tasks.findIndex((t: Task) => t.id === task.id)
        if (index === -1) {
            console.error('replaceTask:: task not found', task.id)
        } else {
            Vue.set(state.tasks, index, task)
            state.tasksMap[task.id] = task
        }
    },

    removeTask(state: TaskState, taskId: string) {
        // Находим индекс task в массиве
        const index = state.tasks.findIndex((t: Task) => t.id === taskId)
        if (index === -1) {
            console.log('Ошибка при удалении task - с таким id не существует')
        } else {
            state.tasks.splice(index, 1)
            Vue.delete(state.tasksMap, taskId)
        }
    },

    cleanUpState(state: TaskState) {
        state.tasks = []
        state.tasksMap = {}
    }
}

export default TaskMutations
