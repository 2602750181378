var render = function () {
  var _vm$node$actor, _vm$node$actor2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    class: "node-popover box-shadow__double node-popover_".concat(_vm.node.viewData.position),
    style: {
      transform: _vm.node.viewData.cssTranslate
    }
  }, _vm.$listeners), [(_vm$node$actor = _vm.node.actor) !== null && _vm$node$actor !== void 0 && _vm$node$actor.user ? _c('div', [_c('Attribute', {
    attrs: {
      "attribute": "Ф.И.О.",
      "text": _vm.node.actor.user.name.fullName
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Email",
      "text": _vm.email
    }
  })], 1) : _vm._e(), _vm._v(" "), (_vm$node$actor2 = _vm.node.actor) !== null && _vm$node$actor2 !== void 0 && _vm$node$actor2.unit ? _c('div', [_c('Attribute', {
    attrs: {
      "attribute": "Подразделение",
      "text": _vm.node.actor.unit.name
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }