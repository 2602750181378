var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "visible": _vm.syncedVisible,
      "title": "Создание задачи"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      },
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "create-task__body",
    attrs: {
      "slot": "body"
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.save($event);
      }
    },
    slot: "body"
  }, [_c('SelectRoleUnit', {
    attrs: {
      "roles-list": _vm.rolesList
    },
    on: {
      "changeOriginUnit": function changeOriginUnit($event) {
        _vm.originUnitId = $event;
      }
    }
  }), _vm._v(" "), _c('InputBase', {
    staticClass: "create-task__name",
    attrs: {
      "type": "text",
      "multi-lines": false,
      "placeholder": "Название задачи"
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "create-task__select-actor"
  }, [_c('div', {
    staticClass: "create-task__actor"
  }, [_vm._v("\n                Исполнитель:\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "create-task__actor"
  }, [_vm._v("\n                Подтверждающий:\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "create-task__radio-container"
  }, [_c('RadioButton', {
    staticClass: "create-task__check-radio",
    attrs: {
      "value": "unit",
      "label": "Подразделение"
    },
    model: {
      value: _vm.assignee.type,
      callback: function callback($$v) {
        _vm.$set(_vm.assignee, "type", $$v);
      },
      expression: "assignee.type"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "create-task__check-radio",
    attrs: {
      "value": "user",
      "label": "Пользователь"
    },
    model: {
      value: _vm.assignee.type,
      callback: function callback($$v) {
        _vm.$set(_vm.assignee, "type", $$v);
      },
      expression: "assignee.type"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "create-task__radio-container"
  }, [_c('RadioButton', {
    staticClass: "create-task__check-radio",
    attrs: {
      "value": "unit",
      "label": "Подразделение"
    },
    model: {
      value: _vm.confirmer.type,
      callback: function callback($$v) {
        _vm.$set(_vm.confirmer, "type", $$v);
      },
      expression: "confirmer.type"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "create-task__check-radio",
    attrs: {
      "value": "user",
      "label": "Пользователь"
    },
    model: {
      value: _vm.confirmer.type,
      callback: function callback($$v) {
        _vm.$set(_vm.confirmer, "type", $$v);
      },
      expression: "confirmer.type"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "create-task__check-radio",
    attrs: {
      "value": "",
      "label": "Не требуется"
    },
    model: {
      value: _vm.confirmer.type,
      callback: function callback($$v) {
        _vm.$set(_vm.confirmer, "type", $$v);
      },
      expression: "confirmer.type"
    }
  })], 1), _vm._v(" "), _c('div', [_c('ActionSelectCard', {
    staticClass: "create-task__actor-card",
    attrs: {
      "placeholder": _vm.assignee.type === 'user' ? 'Укажите пользователя' : 'Укажите подразделение',
      "entity": _vm.assignee.type,
      "items": _vm.assignee.type === 'user' ? _vm.users : _vm.units,
      "disabled": !_vm.originUnitId,
      "sort": "name",
      "sub-sort-property": _vm.assignee.type === 'user' ? 'fullName' : ''
    },
    model: {
      value: _vm.assignee[_vm.assignee.type],
      callback: function callback($$v) {
        _vm.$set(_vm.assignee, _vm.assignee.type, $$v);
      },
      expression: "assignee[assignee.type]"
    }
  }), _vm._v(" "), _vm.assignee.type === 'unit' && _vm.assignee.unit ? _c('div', [_c('div', {
    staticClass: "my-3"
  }, [!_vm.assignees.length ? _c('span', [_vm._v("\n                            В выбранном подразделении нет сотрудников.\n                            Задача не может быть создана!\n                        ")]) : _vm.assigneeHead ? _c('span', [_vm._v("\n                            Вы можете указать конкретного сотрудника из выбранного подразделения,\n                            либо исполнителем данной задачи будет " + _vm._s(_vm.assigneeHead) + "\n                        ")]) : !_vm.assigneeHead ? _c('span', [_vm._v("\n                            В данном подразделении нет руководителя.\n                            Вам необходимо выбрать конкретного сотрудника из указанного подразделения!\n                        ")]) : _vm._e()]), _vm._v(" "), _vm.assignees.length ? _c('ActionSelectCard', {
    staticClass: "create-task__actor-card",
    attrs: {
      "placeholder": "Выбрать пользователя",
      "entity": "user",
      "items": _vm.assignees,
      "sort": "name",
      "sub-sort-property": "fullName"
    },
    model: {
      value: _vm.assignee.user,
      callback: function callback($$v) {
        _vm.$set(_vm.assignee, "user", $$v);
      },
      expression: "assignee.user"
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _vm._v(" "), _c('div', [_c('ActionSelectCard', {
    staticClass: "create-task__actor-card",
    attrs: {
      "placeholder": !_vm.confirmer.type ? 'Подтверждающий' : _vm.confirmer.type === 'user' ? 'Укажите пользователя' : 'Укажите подразделение',
      "entity": _vm.confirmer.type,
      "disabled": !_vm.confirmer.type || !_vm.originUnitId,
      "items": _vm.confirmer.type === 'user' ? _vm.users : _vm.units,
      "sort": "name",
      "sub-sort-property": _vm.confirmer.type === 'user' ? 'fullName' : ''
    },
    model: {
      value: _vm.confirmer[_vm.confirmer.type],
      callback: function callback($$v) {
        _vm.$set(_vm.confirmer, _vm.confirmer.type, $$v);
      },
      expression: "confirmer[confirmer.type]"
    }
  }), _vm._v(" "), _vm.confirmer.type === 'unit' && _vm.confirmer.unit ? _c('div', [_c('div', {
    staticClass: "my-3"
  }, [!_vm.confirmers.length ? _c('span', [_vm._v("\n                            В выбранном подразделении нет сотрудников.\n                            Задача не может быть создана!\n                        ")]) : _vm.confirmerHead ? _c('span', [_vm._v("\n                            Вы можете указать конкретного сотрудника из выбранного подразделения,\n                            либо подтверждающим данной задачи будет " + _vm._s(_vm.confirmerHead) + "\n                        ")]) : !_vm.confirmerHead ? _c('span', [_vm._v("\n                            В данном подразделении нет руководителя.\n                            Вам необходимо выбрать конкретного сотрудника из указанного подразделения!\n                        ")]) : _vm._e()]), _vm._v(" "), _vm.confirmers.length ? _c('ActionSelectCard', {
    staticClass: "create-task__actor-card",
    attrs: {
      "placeholder": "Выбрать пользователя",
      "entity": "user",
      "items": _vm.confirmers,
      "sort": "name",
      "sub-sort-property": "fullName"
    },
    model: {
      value: _vm.confirmer.user,
      callback: function callback($$v) {
        _vm.$set(_vm.confirmer, "user", $$v);
      },
      expression: "confirmer.user"
    }
  }) : _vm._e()], 1) : _vm._e()], 1)]), _vm._v(" "), _c('Textarea', {
    staticClass: "create-task__description",
    attrs: {
      "min-lines": 1,
      "max-lines": 5,
      "placeholder": "Описание задачи"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "create-task__due-time-container"
  }, [_c('div', [_vm._v("Решить задачу до:")]), _vm._v(" "), _c('DateTimeInput', {
    staticClass: "create-task__dueTime",
    attrs: {
      "value": _vm.dueTime
    },
    model: {
      value: _vm.dueTime,
      callback: function callback($$v) {
        _vm.dueTime = $$v;
      },
      expression: "dueTime"
    }
  })], 1)], 1), _vm._v(" "), _c('template', {
    slot: "footer"
  }, [_c('Button', {
    staticClass: "create-task__save",
    attrs: {
      "color": "green",
      "disabled": !!_vm.saveDisabled
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Создать")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }