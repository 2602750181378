var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('input', {
    staticClass: "input h-100 w-100",
    class: {
      'input_disabled': _vm.disabled
    },
    attrs: {
      "type": "text",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.onInput($event);
      },
      "select": function select($event) {
        return _vm.handleSelectionChange($event);
      },
      "blur": function blur($event) {
        return _vm.handleSelectionChange($event);
      },
      "focus": function focus($event) {
        return _vm.handleSelectionChange($event);
      },
      "keydown": function keydown($event) {
        return _vm.handleSelectionChange($event);
      },
      "keyup": function keyup($event) {
        return _vm.handleSelectionChange($event);
      },
      "keypress": function keypress($event) {
        return _vm.handleSelectionChange($event);
      },
      "mousedown": function mousedown($event) {
        return _vm.handleSelectionChange($event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }