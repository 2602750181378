







































import { Component, PropSync, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { DOC_MAIN_CELLS, DOC_TABLE_CELLS_DISPLAY_NAME } from '@/model/UserOptions'
import { DocumentType, DocumentTypeRequisite } from '@/model/DocumentType'

import Modal from '@/components/common/modals/Modal.vue'
import CheckboxGroupN from '@/components/common/inputs/CheckboxGroupN.vue'
import Button from '@/components/common/buttons/Button.vue'

@Component<TableSettings>({
    components: {
        Modal,
        CheckboxGroupN,
        Button
    }
})

export default class TableSettings extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean

    // *************************************************************
    // DATA PARAMS
    visibleCells: Array<string> = []

    // *************************************************************
    // COMPUTED
    @Getter('user_options/getDocumentHiddenCells') getDocumentHiddenCells!: (docTypeId: string) => Array<string>
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    get typeId(): string {
        return this.$route.params.module
    }

    get type(): DocumentType | null {
        return this.getTypeById(this.typeId)
    }

    get allCells(): Array<{ id: string, name: string }> {
        if (!this.type) {
            return []
        }

        return [
            ...DOC_MAIN_CELLS.map((cell: string) => {
                return {
                    id: cell,
                    name: DOC_TABLE_CELLS_DISPLAY_NAME[cell]
                }
            }),
            ...this.type.requisites.map((req: DocumentTypeRequisite) => {
                return {
                    id: req.id,
                    name: req.name
                }
            })
        ]
    }

    get documentHiddenCells(): Array<string> {
        return this.getDocumentHiddenCells(this.typeId)
    }

    // *************************************************************
    // WATCH
    @Watch('documentHiddenCells', { immediate: true, deep: true })
    handleDocumentHiddenCellsChange(cells: Array<string>): void {
        this.visibleCells = this.allCells
            .filter((cell: { id: string, name: string }) => !cells.includes(cell.id))
            .map((cell: { id: string, name: string }) => cell.id)

    }

    // *************************************************************
    // METHODS
    @Action('user_options/setDocumentHiddenCells')
    setDocumentHiddenCells!: (params: { docTypeId: string, cells: Array<string> }) => void

    close(): void {
        this.syncedVisible = false
    }

    save(): void {
        const cells = this.allCells
            .filter(cell => !this.visibleCells.includes(cell.id))
            .map(cell => cell.id)
        this.setDocumentHiddenCells({
            docTypeId: this.typeId,
            cells
        })
        this.syncedVisible = false
    }
}
