import Vue from 'vue'

export default function (state, { numerator, modificationTime }) {
    let index = state.numerators.findIndex(t => t.id === numerator.id)
    if (index === -1) {
        state.numerators.push(numerator)
        Vue.set(state.numModificationTime, numerator.id, modificationTime)
    } else {
        Vue.set(state.numerators, index, numerator)
        state.numModificationTime[numerator.id] = modificationTime
    }
    Vue.set(state.versions, numerator.id, 0)
    Vue.set(state.savesNumerators, numerator.id, numerator)
}
