var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-tasks"
  }, [_c('Tabs', {
    staticClass: "mb-3",
    attrs: {
      "tabs": _vm.tabs
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "user-tasks__eye",
    attrs: {
      "tooltip": "Список входящих",
      "flow": "right"
    }
  }, [_c('FAIcon', {
    staticClass: "user-tasks__icon",
    attrs: {
      "icon": "eye"
    },
    on: {
      "click": function click($event) {
        return _vm.openTasks('incoming');
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "user-tasks__eye user-tasks__eye_outgoing",
    attrs: {
      "tooltip": "Список исходящих",
      "flow": "right"
    }
  }, [_c('FAIcon', {
    staticClass: "user-tasks__icon",
    attrs: {
      "icon": "eye"
    },
    on: {
      "click": function click($event) {
        return _vm.openTasks('outgoing');
      }
    }
  })], 1), _vm._v(" "), _vm.tab === 'outgoing' ? _c('div', {
    staticClass: "user-tasks__container"
  }, _vm._l(_vm.tasksByAuthor, function (task) {
    return _c('TaskCardSmall', {
      key: task.id,
      staticClass: "tasks__container_card",
      attrs: {
        "task": task,
        "tab": _vm.tab
      },
      on: {
        "click": function click($event) {
          return _vm.openTask(task.id);
        }
      }
    });
  }), 1) : _c('div', {
    staticClass: "users-tasks__list",
    class: {
      'user-tasks__list_double': _vm.tasksByConfirmer.length
    }
  }, [_c('div', {
    staticClass: "user-tasks__container"
  }, _vm._l(_vm.tasksByAssignee, function (task) {
    return _c('TaskCardSmall', {
      key: task.id,
      staticClass: "tasks__container_card",
      attrs: {
        "task": task,
        "tab": _vm.tab
      },
      on: {
        "click": function click($event) {
          return _vm.openTask(task.id);
        }
      }
    });
  }), 1), _vm._v(" "), _vm.tasksByConfirmer.length ? _c('div', {
    staticClass: "text-center fs-20"
  }, [_vm._v("\n            На подтверждение:\n        ")]) : _vm._e(), _vm._v(" "), _vm.tasksByConfirmer.length ? _c('div', {
    staticClass: "user-tasks__container"
  }, _vm._l(_vm.tasksByConfirmer, function (task) {
    return _c('TaskCardSmall', {
      key: task.id,
      staticClass: "tasks__container_card",
      attrs: {
        "task": task,
        "tab": _vm.tab
      },
      on: {
        "click": function click($event) {
          return _vm.openTask(task.id);
        }
      }
    });
  }), 1) : _vm._e()]), _vm._v(" "), _c('TaskModal', {
    ref: "modal"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }