





























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { WorkflowTemplate } from '@/model/Workflow'
import introduceTaskOutputs, { TaskTemplateWithOutputs } from '@/tools/template_builder/introduceTaskOutputs'

import Card from '@/components/common/cards/Card.vue'
import Attribute from '@/components/common/Attribute.vue'

@Component<WFTaskListNode>({
    name: 'WFTaskListNode',
    components: { Card, Attribute, WFTaskListNode }
})

export default class WFTaskListNode extends Vue {
    @Prop({ default: '' }) taskId!: string
    @Prop({ default: 0 }) stageIndex!: number

    // *************************************************************
    // DATA PARAMS
    drop = true

    // *************************************************************
    // COMPUTED
    @Getter('workflow/getWorkflow') getWorkflow!: WorkflowTemplate

    get stageTasks(): Array<TaskTemplateWithOutputs> {
        const stage = this.getWorkflow.stages[this.stageIndex]
        if (!stage) {
            console.error('WFTaskListNode:: stage not found')
            return []
        }
        return introduceTaskOutputs(stage.tasks)
    }

    get task(): TaskTemplateWithOutputs | null {
        return this.stageTasks.find((task: TaskTemplateWithOutputs) => task.id === this.taskId) ?? null
    }

    get assignee(): string {
        if (!this.task) {
            return 'Обратитесь в техподдержку: нет assignee у task'
        } else if (this.task.assignee.user) {
            return this.task.assignee.user.name.fullName
        } else  if (this.task.assignee.unit) {
            return this.task.assignee.unit.name
        } else {
            console.error('TASKLISTNODE - no assignee', this.task)
            return 'Обратитесь в техподдержку: нет assignee у task'
        }
    }

    get confirmer(): string {
        if (!this.task) {
            return 'Подтверждение выполнения не требуется'
        } else if (this.task.confirmer?.user) {
            return this.task.confirmer.user.name.fullName
        } else if (this.task.confirmer?.unit) {
            return this.task.confirmer.unit.name
        } else {
            return 'Подтверждение выполнения не требуется'
        }
    }

    // *************************************************************
    // METHODS
    @Action('workflow/changeWFActiveTask')
    changeWFActiveTask!: (taskId: string) => void

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFActiveStage')
    changeWFActiveStage!: (activeStage: number | null) => void

    onClickTask(taskId: string): void {
        if (this.getWorkflow.activeTask === taskId) {
            this.changeWFActiveTask('')

            // Возвращаемся в меню выбора action
            this.changeWFAction('')
        } else {
            this.changeWFActiveTask(taskId)

            if (this.getWorkflow.activeStage !== this.stageIndex) {
                // Ставим активным данный блок
                this.changeWFActiveStage(this.stageIndex)
            }
        }
    }

    onClickDrop(): void {
        // Меняем по клику значение drop
        this.drop = !this.drop
        this.emitClickList()
    }

    // *************************************************************
    // EMIT
    @Emit('clickList')
    emitClickList(): void {
        console.log('emitClickList')
        // Do nothing
    }
}
