var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Button', _vm._g({
    staticClass: "btn-base",
    attrs: {
      "disabled": _vm.disabled
    }
  }, _vm.$listeners), [_vm._v(_vm._s(_vm.text))]);
}
var staticRenderFns = []

export { render, staticRenderFns }