var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "unit-card",
    class: {
      'unit-card_small': _vm.small
    }
  }, _vm.$listeners), [_c('Popover', {
    attrs: {
      "shift-ypopover": 10,
      "arrow": true
    }
  }, [_c('div', {
    staticClass: "unit-card__photo",
    class: {
      'unit-card__photo_small': _vm.small
    },
    attrs: {
      "slot": "host"
    },
    slot: "host"
  }), _vm._v(" "), _c('template', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    },
    slot: "popover"
  }, [_vm._v("\n            Подразделение: " + _vm._s(_vm.unitInfo.name) + "\n        ")])], 2), _vm._v(" "), _c('div', {
    staticClass: "unit-card__info",
    on: {
      "click": function click($event) {
        return _vm.$emit('infoUnitfromCard');
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.unitInfo.name) + "\n    ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }