import api from 'api1'

export default function (famId, famFunc, eventFunc, errorFunc) {
    return api.cunt.FamiliarizationSvcClient.stream(api.cunt.FamiliarizationStreamReq({
        ID: famId
    }),
    data => {
        data = api.cunt.FamiliarizationStreamMessage(data)

        if (data.Familiarization) {
            famFunc(data.Familiarization)
        } else if (data.Event) {
            eventFunc(data.Event)
        } else {
            console.error('FamiliarizationSvcClient.stream - пришла какая-то дичь', data)
        }
    }, error => {
        (error ? console.warn : console.log)('::DONE::FamiliarizationSvcClient.stream:', error)
        errorFunc(error)
    },
    status => {
        console.warn('FamiliarizationSvcClient::status', status)
    },
    {
        deadline: 0
    })
}
