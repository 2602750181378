import { render, staticRenderFns } from "./TableModal.vue?vue&type=template&id=60d2fadf&scoped=true&"
import script from "./TableModal.vue?vue&type=script&lang=ts&"
export * from "./TableModal.vue?vue&type=script&lang=ts&"
import style0 from "./TableModal.vue?vue&type=style&index=0&id=60d2fadf&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d2fadf",
  null
  
)

export default component.exports