import { ActionTree } from 'vuex'
import { WorkflowState } from '@/store/workflow'
import { RootState } from '@/store'

import { ApprovalTemplate } from '@/model/Workflow'
import { Actor } from '@/model/Actor'

const GraphActions: ActionTree<WorkflowState, RootState> = {
    addWFApproval({ commit, getters }, params: { approvalActor: Actor | null, specialName: string }) {
        // Добавляем новый node в цепочку согласования
        // В inputs изначально у него стоит только активный node
        // Actor прокидываем из компонента, чтобы не обращаться к геттерам других модулей

        const approval = new ApprovalTemplate(
            Math.random().toString(36).substring(7),
            params.approvalActor,
            params.specialName,
            []
        )

        if (getters.getWorkflow.activeNode) {
            approval.inputs = [getters.getWorkflow.activeNode]
        }

        commit('addWFApproval', approval)
    },

    addWFLink({ commit, getters }, nodeId: string) {
        if (!getters.getWorkflow.activeNode) {
            console.error('addWFLink:: activeNode not exist', nodeId)
        } else {
            commit('addWFLink', {
                nodeId: nodeId,
                inputId: getters.getWorkflow.activeNode
            })
        }
    },

    changeWFApproval({ commit }, params: { id: string, actor: Actor | null, specialName: string }) {
        commit('changeWFApproval', params)
    },

    removeWFApproval({ commit, getters }, nodeId: string) {
        if (!getters.getActiveStage) {
            console.error('removeWFApproval:: active stage not exist')
        } else {
            const approvals = getters.getActiveStage.approvals

            const activeNodeIndex = getters.getActiveStage.approvals.findIndex((app: ApprovalTemplate) => app.id === nodeId)

            commit('removeWFApproval', activeNodeIndex)

            const childrenNodes = approvals.filter((app: ApprovalTemplate) => app.inputs.includes(nodeId))

            // Если удаляемый node есть у каких-то nodes в inputs, то удаляем их
            childrenNodes.forEach((node: ApprovalTemplate) => {
                commit('removeWFLink', {
                    nodeId: node.id,
                    inputId: nodeId
                })
            })
        }
    },

    removeWFLink({ commit, getters }, nodeId: string) {
        commit('removeWFLink', {
            nodeId: nodeId,
            inputId: getters.getWorkflow.activeNode
        })
    }
}

export default GraphActions
