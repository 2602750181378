<template>
    <div
        class="unit-card"
        :class="{ 'unit-card_small': small}"
        v-on="$listeners"
    >
        <Popover
            :shift-ypopover="10"
            :arrow="true"
        >
            <div
                slot="host"
                class="unit-card__photo"
                :class="{ 'unit-card__photo_small': small}"
            />
            <template
                slot="popover"
                @click.stop
            >
                Подразделение: {{ unitInfo.name }}
            </template>
        </Popover>

        <div
            class="unit-card__info"
            @click="$emit('infoUnitfromCard')"
        >
            {{ unitInfo.name }}
        </div>
    </div>
</template>

<script>
import Popover from '@/components/common/Popover'

export default {
    name: 'UnitCard',
    components: { Popover },
    props: {
        unitInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        small: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="stylus" scoped>
.unit-card
    display flex
    flex-direction row
    flex-wrap nowrap
    box-sizing border-box
    width 200px
    height 50px
    &.unit-card_small
        height 35px

.unit-card__photo
    border-radius 40px
    width 50px
    height 50px
    background-image url('~@/assets/icons/unitCard.png')
    background-position top
    background-size cover
    &.unit-card__photo_small
        height 35px
        width 35px

.unit-card__info
    flex-grow 1
    margin auto 0 auto 15px
    text-align left
</style>
