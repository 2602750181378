



























import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator'

@Component<InputBase>({})

export default class InputBase extends Vue {
    @Ref() readonly input!: HTMLInputElement

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) value!: string
    @Prop({ default: '' }) placeholder!: string
    @Prop({ default: true }) activeFocus!: boolean // нужно ли выделять при фокусе
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: false }) loading!: boolean
    @Prop({ default: false }) search!: boolean
    @Prop({ type: Boolean, default: false }) required!: boolean
    @Prop({ default: false }) center!: boolean
    @Prop({ default: true }) trim!: boolean

    // *************************************************************
    // COMPUTED
    get disabledInput(): boolean {
        return this.disabled || this.loading
    }

    // *************************************************************
    // METHODS
    focus(): void {
        // Вызывается из родительского компонента
        this.input.focus()
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(val: string): string {
        if (this.trim) {
            return val.trim()
        } else {
            return val
        }
    }

    @Emit('focus')
    emitFocus(): void {
        // Do nothing
    }

    @Emit('blur')
    emitBlur(): void {
        // Do nothing
    }

    @Emit('enter')
    emitEnter(): void {
        // Do nothing
    }
}
