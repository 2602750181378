











import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { User, Role } from '@/model/User'
import { AuthenticationStatus } from '@/model/Authentication'

import PrivacyPolicy from '@/pages/about_system/PrivacyPolicy.vue'
import Support from '@/pages/Support.vue'
import Authentication from '@/pages/Authentication.vue'
import UserPage from '@/pages/system/UserPage.vue'

@Component({
    components: {
        PrivacyPolicy,
        Support,
        Authentication,
        UserPage
    }
})

export default class App extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User | null
    @Getter('me/getStatus') getStatus!: AuthenticationStatus
    // ...mapGetters('user_options', ['getPreloader']),

    get roles(): Array<Role> {
        return this.getMe?.roles ?? []
    }

    get user(): string | null {
        if (!this.getStatus) {
            return null
        } else if (!this.getStatus.authenticated) {
            return null
        } else {
            return this.getStatus.authenticated.user
        }
    }

    // *************************************************************
    // WATCH
    @Watch('user', { immediate: true })
    handleUserChange(to: string | null): void {
        // console.log('WATCH::user', to, from)
        if (to) {
            this.manageUserEventStream(!!to)
        }
    }

    @Watch('roles.length', { immediate: true })
    handleRolesChange(val: number): void {
        // console.log('WATCH::roles', val)
        this.callOpenListStream(!!val)

        if (val) {
            // OneAssDirectories
            this.callQueryAndWatch()
        }
    }

    // *************************************************************
    // CREATED
    // created(): void {
    //     window.backdoor = {
    //         logout: () => {
    //             this.exit()
    //             return 'ok'
    //         }
    //     }
    // }

    // *************************************************************
    // METHODS
    // @Action('me/logOut')
    // logOut!: () => Promise<void>

    @Action('me/manageUserEventStream')
    manageUserEventStream!: (_active: boolean) => Promise<void>

    @Action('directories/callQueryAndWatch')
    callQueryAndWatch!: () => Promise<void>

    @Action('document/callOpenListStream')
    callOpenListStream!: (_active: boolean) => Promise<void>

    // exit(): void {
    //     this.logOut()
    //         .then(() => this.$router.push({ name: 'login' }))
    // }
}
