var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.syncedVisible,
      expression: "syncedVisible"
    }],
    staticClass: "remove-modal",
    on: {
      "click": _vm.hide
    }
  }, [_c('div', {
    staticClass: "remove-modal__content",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "remove-modal__header"
  }, [_c('div', {
    staticClass: "remove-modal__title"
  }, [_vm._v("\n                Удаление " + _vm._s(_vm.itemType) + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "remove-modal__body"
  }, [_vm.warning ? _c('div', [_vm._v("\n                Предупреждение: "), _c('span', {
    staticClass: "remove-modal__warning_text"
  }, [_vm._v(_vm._s(_vm.warning))])]) : _vm._e(), _vm._v(" "), _vm.itemName ? _c('InputWithLabel', {
    attrs: {
      "label": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043C\u044F: ".concat(_vm.itemName),
      "min-lines": 1,
      "max-lines": 5
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "remove-modal__footer"
  }, [_c('Button', {
    staticClass: "mr-5",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v("\n                Отмена\n            ")]), _vm._v(" "), _c('Button', {
    attrs: {
      "color": "red",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("Удалить")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }