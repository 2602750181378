var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dismissal"
  }, [_vm.centers.length > 1 ? _c('Select', {
    staticClass: "dismissal__center",
    attrs: {
      "options": _vm.centers,
      "placeholder": "Выбрать организацию"
    },
    model: {
      value: _vm.centerId,
      callback: function callback($$v) {
        _vm.centerId = $$v;
      },
      expression: "centerId"
    }
  }) : _vm._e(), _vm._v(" "), _vm.centerId ? _c('ActionSelectCard', {
    ref: "user",
    attrs: {
      "placeholder": "Выбор сотрудника",
      "entity": "user",
      "items": _vm.usersByCenter,
      "sort": "name",
      "sub-sort-property": "fullName"
    },
    model: {
      value: _vm.userId,
      callback: function callback($$v) {
        _vm.userId = $$v;
      },
      expression: "userId"
    }
  }) : _vm._e(), _vm._v(" "), _vm.userId ? _c('div', {
    staticClass: "dismissal__title"
  }, [_vm._v("\n        Необходимо указать Дефолтного сотрудника, который заменит увольняемого.\n    ")]) : _vm._e(), _vm._v(" "), _vm.userId ? _c('ActionSelectCard', {
    attrs: {
      "placeholder": "Выбор сотрудника",
      "entity": "user",
      "items": _vm.users,
      "sort": "name",
      "sub-sort-property": "fullName"
    },
    model: {
      value: _vm.defaultUserId,
      callback: function callback($$v) {
        _vm.defaultUserId = $$v;
      },
      expression: "defaultUserId"
    }
  }) : _vm._e(), _vm._v(" "), _vm.defaultUserId && _vm.roles.length > 1 ? _c('div', [_c('div', {
    staticClass: "dismissal__title"
  }, [_vm._v("\n            Для каждой роли Вы можете выбрать конкретного пользователя\n        ")]), _vm._v(" "), _vm._l(_vm.roles, function (role, index) {
    return _c('div', {
      key: "".concat(role.unitId, "-").concat(role.roleName),
      staticClass: "dismissal__employee-card"
    }, [_c('div', [_vm._v(_vm._s(role.unitName) + " : " + _vm._s(role.displayName))]), _vm._v(" "), _c('ActionSelectCard', {
      attrs: {
        "placeholder": "Выбор сотрудника",
        "entity": "user",
        "items": _vm.users,
        "sort": "name",
        "sub-sort-property": "fullName"
      },
      on: {
        "input": function input($event) {
          return _vm.setUserByRole($event, index, role);
        }
      },
      model: {
        value: _vm.usersByRoles[index].userId,
        callback: function callback($$v) {
          _vm.$set(_vm.usersByRoles[index], "userId", $$v);
        },
        expression: "usersByRoles[index].userId"
      }
    })], 1);
  })], 2) : _vm._e(), _vm._v(" "), _vm.defaultUserId && _vm.defaultRoles.length ? _c('div', [_c('div', {
    staticClass: "dismissal__title"
  }, [_vm._v("\n            Согласно переназначению Дефолтному сотруднику будут назначены роли:\n        ")]), _vm._v(" "), _vm._l(_vm.defaultRoles, function (role) {
    return _c('div', {
      key: "".concat(role.unitId, "-").concat(role.roleName)
    }, [_vm._v("\n            " + _vm._s(role.unitName) + ": " + _vm._s(role.displayName) + "\n        ")]);
  })], 2) : _vm._e(), _vm._v(" "), _vm.defaultUserId ? _c('ButtonGreen', {
    staticClass: "dismissal__save",
    attrs: {
      "text": "Сохранить"
    },
    on: {
      "click": _vm.save
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }