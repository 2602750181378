import { User, Role } from '@/model/old/user1'
import { UserName } from '@/model/old/actor1.js'

export default function newUser(data) {
    return new User(
        data.ID,
        new UserName(
            data.Name.First,
            data.Name.Middle,
            data.Name.Last
        ),
        data.Roles.map(r => new Role(
            r.Unit,
            r.Name
        )),
        data.Avatar,
        data.Email,
        data.Phone,
        data.Modificationtime
    )
}
