import { Actor } from '@/model/Actor'
import { WorkflowApproval, WorkflowElement, WorkflowElementSpecial, WorkflowTask } from '@/model/Document'

const minDepthFlag = 9999999

interface NodeSimple {
    id: string
    actor: Actor | null
    specialName: string
    inputs: Array<string>
    passed?: boolean
}

export class NodeTemplate {
    public id: string
    public actor: Actor | null
    public specialName = ''
    public inputs: Array<string>

    public outputs: Array<string> = [] // исходящие связи

    public passed = false

    public special: WorkflowElementSpecial | null = null
    public pending = false
    public autoAccept = false
    public approval: WorkflowApproval | null = null
    public task: WorkflowTask | null = null

    public viewData: {
        minDepth: number
        maxDepth: number
        depth: number

        levelWidth: number
        orderX: number
        orderY: number
        translateX: number
        translateY: number

        position: 'left' | 'right'
        cssTranslate: string

        root: boolean // Todo - delete
    } = {
        minDepth: minDepthFlag,
        maxDepth: 0,
        depth: 0,

        levelWidth: 0,
        orderX: 0,
        orderY: 0,
        translateX: 0,
        translateY: 0,

        position: 'left',
        cssTranslate: '',

        root: false
    }

    constructor(elem: NodeSimple | WorkflowElement) {
        this.id = elem.id
        this.actor = elem.actor
        this.inputs = elem.inputs

        if (elem instanceof WorkflowElement) {
            console.log('pending', elem.pending)
            this.special = elem.special
            this.outputs = elem.outputs
            this.pending = elem.pending
            this.passed = elem.passed
            this.autoAccept = elem.autoAccept
            this.approval = elem.approval
            this.task = elem.task
        } else {
            this.specialName = elem.specialName
            this.passed = elem.passed ?? false
        }
    }

    get root(): boolean {
        return !this.inputs.length
    }
}

export interface LinkTemplate {
    id: string
    d: string // path
}

// Создаем ассоциативный список с id nodes
const verticesById: { [id: string]: NodeTemplate } = {}

// Задаем ширину одного node
const nodeWidth = 130

// Ммаксимальная глубину graph - самый длинный путь от node до root
let graphMaxDepth = 0

function introduceDepths(ids: Array<string>, depth: number): void {
    if (depth > graphMaxDepth) {
        graphMaxDepth = depth
    }

    ids.forEach(id => {
        const v: NodeTemplate = verticesById[id]
        if (depth > v.viewData.maxDepth) {
            v.viewData.maxDepth = depth
        }
        if (depth < v.viewData.minDepth) {
            v.viewData.minDepth = depth
        }

        // Берем за показатель глубины для расчетов maxDepth
        v.viewData.depth = v.viewData.maxDepth

        introduceDepths(v.outputs, depth + 1)
    })
}

/**
 *
 * @param nodeSize {number} - Высота одного node
 * @param svgWidth {number} - Ширина контейнера svg
 * @param svgHeight {number} - Высота контейнера svg
 * @param vertices {Array<ApprovalTemplate>} - Массив nodes, которому будут добавляться новые поля, необходимые для отображения графа
 * @param end {boolean} - Конец это нашего графа или нет
 * @param start {boolean} - Начало это нашего графа или нет
 * */
export default function (nodeSize: number, svgWidth: number, svgHeight: number, vertices: Array<NodeSimple | WorkflowElement>, end: boolean, start: boolean): { nodes: Array<NodeTemplate>, links: Array<LinkTemplate> } {
    const openVertices: Array<NodeTemplate> = vertices.map((approval: NodeSimple | WorkflowElement) => {

        const node: NodeTemplate = new NodeTemplate(approval)
        verticesById[node.id] = node

        return node
    })

    // Прокидываем исходящие связи
    if (vertices[0] instanceof WorkflowElement) {
        // Do nothing - outputs already exists
    } else {
        openVertices.forEach((node: NodeTemplate) => {
            node.inputs.forEach((inp: string) => {
                verticesById[inp].outputs.push(node.id)
            })
        })
    }

    const roots = openVertices.filter((node: NodeTemplate) => node.root)

    roots.forEach((root: NodeTemplate) => {
        introduceDepths([root.id], 0)
    })

    // Устанавливаем окончательный показатель по вертикали для каждого vertex, необходимый для позиционирования
    let levelCount = 0

    for (let i = 0; i <= graphMaxDepth; i++) {
        // Фильтруем массив - выбираем nodes с такой же глубиной
        const levelVertices = openVertices.filter(v => v.viewData.depth === i)

        // Выясняем длину массива - она показываем число nodes на выбранном уровне
        const levelSize = levelVertices.length

        levelVertices.forEach((v: NodeTemplate, index: number) => {
            // Располагаем nodes последовательно на каждом уровне (от 0 уровня) друг за другом
            v.viewData.orderY = levelCount++

            // Задаем вертикальный translate
            if (!start) {
                v.viewData.translateY = v.viewData.orderY * nodeSize + 40
            } else {
                v.viewData.translateY = v.viewData.orderY * nodeSize
            }

            // Задаем ширину уровню - это ширина svg-контейнера, разделенная на количество nodes на данном уровне
            v.viewData.levelWidth = svgWidth / levelSize

            // Это порядковый номер node на уровне
            v.viewData.orderX = index
            // Задаем горизонтальный translate
            // if (v.viewData.levelWidth > nodeWidth) {
            //     v.viewData.translateX = (v.viewData.levelWidth - nodeWidth) / 2 + v.viewData.levelWidth * v.viewData.orderX
            // } else {
            //     v.viewData.translateX = (nodeWidth - v.viewData.levelWidth) / 2 + v.viewData.levelWidth * v.viewData.orderX
            // }

            if (nodeWidth > v.viewData.levelWidth) {
                v.viewData.translateX = (svgWidth - nodeWidth) / (levelSize + 1) * (index + 1)
            } else {
                // Сдвиг по оси Х: берем количество элементов + 1 (levelSize + 1)
                // Ширину svg делим на это количество элементов
                // Умножаем на позицию элемента в этом горизонтальном блоке (orderX + 1),
                // +1 т.к. нумерация начинается с нуля
                // Вычитаем половину ширины одного node, чтобы центральный был четко посередине
                v.viewData.translateX = svgWidth / (levelSize + 1) * (index + 1) - nodeWidth / 2
            }
            v.viewData.cssTranslate = `translate(${v.viewData.translateX}px, ${v.viewData.translateY + 5}px)`

            if (levelSize === 1) {
                v.viewData.position = 'right'
            } else {
                v.viewData.position = index > 0 ? 'left' : 'right'
            }
        })
    }


    // Создаем массив links
    const links: Array<LinkTemplate> = []

    openVertices.forEach((v: NodeTemplate) => {
        if (v.outputs.length) {
            const MX = v.viewData.translateX + 13
            const MY = v.viewData.translateY + 18
            const CStartX = v.viewData.translateX - 73
            const CStartY = v.viewData.translateY + 18

            v.outputs.forEach((outputId: string) => {
                const output: NodeTemplate = verticesById[outputId]

                const CMiddleX = output.viewData.translateX - 73
                const CMiddleY = output.viewData.translateY + 18
                const CLastX = output.viewData.translateX + 13
                const CLastY = output.viewData.translateY + 18

                const l: LinkTemplate = {
                    id: `${v.id}-${outputId}`, // id могут повторяться, поэтому собираем их из 2-х (родительского и дочернего)
                    d: `M ${MX} ${MY} C ${CStartX} ${CStartY}, ${CMiddleX} ${CMiddleY}, ${CLastX} ${CLastY}` // Кривая Безье
                }
                links.push(l)
            })
        }

        if (!end && !v.outputs.length) {
            // end - это показатель, нужно ли продолжать данный граф вниз к следующему блоку
            // если end === true, то граф заканчивается

            const MX = v.viewData.translateX + 13
            const MY = v.viewData.translateY + 18
            const CStartX = v.viewData.translateX - 73
            const CStartY = v.viewData.translateY + 18
            const CMiddleX = 20
            const CMiddleY = svgHeight - 13
            const CLastX = 20

            const l: LinkTemplate = {
                id: `${v.id}-end`,
                d: `M ${MX} ${MY} C ${CStartX} ${CStartY}, ${CMiddleX} ${CMiddleY}, ${CLastX} ${svgHeight}` // Кривая Безье
            }
            links.push(l)
        }

        if (!start && !v.inputs.length) {
            // start - это показатель, нужно ли начинать данный граф сверху от предыдущего блока
            // если start === true, то граф только начинается

            const MX = 20
            const MY = 0
            const CStartX = 20
            const CStartY = 13
            const CMiddleX = v.viewData.translateX - 73
            const CMiddleY = v.viewData.translateY + 18
            const CLastX = v.viewData.translateX + 13
            const CLastY = v.viewData.translateY + 18

            const l: LinkTemplate = {
                id: `${v.id}-start`,
                d: `M ${MX} ${MY} C ${CStartX} ${CStartY}, ${CMiddleX} ${CMiddleY}, ${CLastX} ${CLastY}` // Кривая Безье
            }

            links.push(l)
        }
    })

    return {
        nodes: openVertices,
        links: links
    }
}
