/* eslint-disable */
import Long from "long";
import { grpc } from "@improbable-eng/grpc-web";
import _m0 from "protobufjs/minimal";
import { BrowserHeaders } from "browser-headers";

export const protobufPackage = "api.themakers.identity";

export interface StartSignInReq {}

export interface StartSignUpReq {}

export interface StartAttachReq {}

/** // ListMessages */
export interface ListSupportedIdentitiesAndVerifiersReq {}

export interface ListMyIdentitiesAndVerifiersReq {}

export interface VerifierDetailsResp {
  IdentitiyNames: string[];
  Verifiers: VerifierDetails[];
}

export interface VerifierDetails {
  Name: string;
  IdentityName: string;
  SupportRegular: boolean;
  SupportReverse: boolean;
  SupportOAuth2: boolean;
  SupportStatic: boolean;
}

export interface ListMyIdentitiesAndVerifiersResp {
  Identities: IdentityData[];
  Verifiers: string[];
}

export interface IdentityData {
  Name: string;
  Identity: string;
}

export interface StartReq {
  VerifierName: string;
  IdentityName: string;
  Identity: string;
  Args: { [key: string]: string };
  Values: { [key: string]: string };
}

export interface StartReq_ArgsEntry {
  key: string;
  value: string;
}

export interface StartReq_ValuesEntry {
  key: string;
  value: string;
}

export interface StartResp {
  Directions: { [key: string]: string };
}

export interface StartResp_DirectionsEntry {
  key: string;
  value: string;
}

export interface StatusReq {}

export interface Status {
  Token: string;
  Authenticating: StatusAuthenticating | undefined;
  Authenticated: StatusAuthenticated | undefined;
}

export interface StatusAuthenticated {
  User: string;
}

export interface StatusAuthenticating {
  SignIn: boolean | undefined;
  SignUp: boolean | undefined;
  Attach: boolean | undefined;
  CompletedFactors: StatusCompletedFactors[];
  RemainingFactors: number;
}

export interface StatusCompletedFactors {
  VerifierName: string;
  IdentityName: string;
  Identity: string;
}

export interface UserMergeReq {}

export interface UserMergeResp {}

export interface CancelAuthenticationReq {}

export interface LogoutReq {
  AllSessions: boolean;
}

export interface InitializeStaticVerifierReq {
  VerifierName: string;
  Args: { [key: string]: string };
}

export interface InitializeStaticVerifierReq_ArgsEntry {
  key: string;
  value: string;
}

export interface InitializeStaticVerifierResp {
  Result: { [key: string]: string };
}

export interface InitializeStaticVerifierResp_ResultEntry {
  key: string;
  value: string;
}

export interface VerifyReq {
  VerifierName: string;
  VerificationCode: string;
  IdentityName: string;
  Identity: string;
}

export interface LoginAsReq {
  User: string;
}

export interface LoginAsResp {
  Session: string;
  User: string;
  Error: string;
}

const baseStartSignInReq: object = {};

export const StartSignInReq = {
  encode(
    _: StartSignInReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartSignInReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStartSignInReq } as StartSignInReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): StartSignInReq {
    const message = { ...baseStartSignInReq } as StartSignInReq;
    return message;
  },

  toJSON(_: StartSignInReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<StartSignInReq>): StartSignInReq {
    const message = { ...baseStartSignInReq } as StartSignInReq;
    return message;
  },
};

const baseStartSignUpReq: object = {};

export const StartSignUpReq = {
  encode(
    _: StartSignUpReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartSignUpReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStartSignUpReq } as StartSignUpReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): StartSignUpReq {
    const message = { ...baseStartSignUpReq } as StartSignUpReq;
    return message;
  },

  toJSON(_: StartSignUpReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<StartSignUpReq>): StartSignUpReq {
    const message = { ...baseStartSignUpReq } as StartSignUpReq;
    return message;
  },
};

const baseStartAttachReq: object = {};

export const StartAttachReq = {
  encode(
    _: StartAttachReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartAttachReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStartAttachReq } as StartAttachReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): StartAttachReq {
    const message = { ...baseStartAttachReq } as StartAttachReq;
    return message;
  },

  toJSON(_: StartAttachReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<StartAttachReq>): StartAttachReq {
    const message = { ...baseStartAttachReq } as StartAttachReq;
    return message;
  },
};

const baseListSupportedIdentitiesAndVerifiersReq: object = {};

export const ListSupportedIdentitiesAndVerifiersReq = {
  encode(
    _: ListSupportedIdentitiesAndVerifiersReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListSupportedIdentitiesAndVerifiersReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseListSupportedIdentitiesAndVerifiersReq,
    } as ListSupportedIdentitiesAndVerifiersReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ListSupportedIdentitiesAndVerifiersReq {
    const message = {
      ...baseListSupportedIdentitiesAndVerifiersReq,
    } as ListSupportedIdentitiesAndVerifiersReq;
    return message;
  },

  toJSON(_: ListSupportedIdentitiesAndVerifiersReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<ListSupportedIdentitiesAndVerifiersReq>
  ): ListSupportedIdentitiesAndVerifiersReq {
    const message = {
      ...baseListSupportedIdentitiesAndVerifiersReq,
    } as ListSupportedIdentitiesAndVerifiersReq;
    return message;
  },
};

const baseListMyIdentitiesAndVerifiersReq: object = {};

export const ListMyIdentitiesAndVerifiersReq = {
  encode(
    _: ListMyIdentitiesAndVerifiersReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListMyIdentitiesAndVerifiersReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseListMyIdentitiesAndVerifiersReq,
    } as ListMyIdentitiesAndVerifiersReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ListMyIdentitiesAndVerifiersReq {
    const message = {
      ...baseListMyIdentitiesAndVerifiersReq,
    } as ListMyIdentitiesAndVerifiersReq;
    return message;
  },

  toJSON(_: ListMyIdentitiesAndVerifiersReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<ListMyIdentitiesAndVerifiersReq>
  ): ListMyIdentitiesAndVerifiersReq {
    const message = {
      ...baseListMyIdentitiesAndVerifiersReq,
    } as ListMyIdentitiesAndVerifiersReq;
    return message;
  },
};

const baseVerifierDetailsResp: object = { IdentitiyNames: "" };

export const VerifierDetailsResp = {
  encode(
    message: VerifierDetailsResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.IdentitiyNames) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.Verifiers) {
      VerifierDetails.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifierDetailsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseVerifierDetailsResp } as VerifierDetailsResp;
    message.IdentitiyNames = [];
    message.Verifiers = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IdentitiyNames.push(reader.string());
          break;
        case 2:
          message.Verifiers.push(
            VerifierDetails.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VerifierDetailsResp {
    const message = { ...baseVerifierDetailsResp } as VerifierDetailsResp;
    message.IdentitiyNames = [];
    message.Verifiers = [];
    if (object.IdentitiyNames !== undefined && object.IdentitiyNames !== null) {
      for (const e of object.IdentitiyNames) {
        message.IdentitiyNames.push(String(e));
      }
    }
    if (object.Verifiers !== undefined && object.Verifiers !== null) {
      for (const e of object.Verifiers) {
        message.Verifiers.push(VerifierDetails.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: VerifierDetailsResp): unknown {
    const obj: any = {};
    if (message.IdentitiyNames) {
      obj.IdentitiyNames = message.IdentitiyNames.map((e) => e);
    } else {
      obj.IdentitiyNames = [];
    }
    if (message.Verifiers) {
      obj.Verifiers = message.Verifiers.map((e) =>
        e ? VerifierDetails.toJSON(e) : undefined
      );
    } else {
      obj.Verifiers = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<VerifierDetailsResp>): VerifierDetailsResp {
    const message = { ...baseVerifierDetailsResp } as VerifierDetailsResp;
    message.IdentitiyNames = [];
    message.Verifiers = [];
    if (object.IdentitiyNames !== undefined && object.IdentitiyNames !== null) {
      for (const e of object.IdentitiyNames) {
        message.IdentitiyNames.push(e);
      }
    }
    if (object.Verifiers !== undefined && object.Verifiers !== null) {
      for (const e of object.Verifiers) {
        message.Verifiers.push(VerifierDetails.fromPartial(e));
      }
    }
    return message;
  },
};

const baseVerifierDetails: object = {
  Name: "",
  IdentityName: "",
  SupportRegular: false,
  SupportReverse: false,
  SupportOAuth2: false,
  SupportStatic: false,
};

export const VerifierDetails = {
  encode(
    message: VerifierDetails,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Name !== "") {
      writer.uint32(10).string(message.Name);
    }
    if (message.IdentityName !== "") {
      writer.uint32(18).string(message.IdentityName);
    }
    if (message.SupportRegular === true) {
      writer.uint32(24).bool(message.SupportRegular);
    }
    if (message.SupportReverse === true) {
      writer.uint32(32).bool(message.SupportReverse);
    }
    if (message.SupportOAuth2 === true) {
      writer.uint32(40).bool(message.SupportOAuth2);
    }
    if (message.SupportStatic === true) {
      writer.uint32(48).bool(message.SupportStatic);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifierDetails {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseVerifierDetails } as VerifierDetails;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Name = reader.string();
          break;
        case 2:
          message.IdentityName = reader.string();
          break;
        case 3:
          message.SupportRegular = reader.bool();
          break;
        case 4:
          message.SupportReverse = reader.bool();
          break;
        case 5:
          message.SupportOAuth2 = reader.bool();
          break;
        case 6:
          message.SupportStatic = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VerifierDetails {
    const message = { ...baseVerifierDetails } as VerifierDetails;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.IdentityName !== undefined && object.IdentityName !== null) {
      message.IdentityName = String(object.IdentityName);
    } else {
      message.IdentityName = "";
    }
    if (object.SupportRegular !== undefined && object.SupportRegular !== null) {
      message.SupportRegular = Boolean(object.SupportRegular);
    } else {
      message.SupportRegular = false;
    }
    if (object.SupportReverse !== undefined && object.SupportReverse !== null) {
      message.SupportReverse = Boolean(object.SupportReverse);
    } else {
      message.SupportReverse = false;
    }
    if (object.SupportOAuth2 !== undefined && object.SupportOAuth2 !== null) {
      message.SupportOAuth2 = Boolean(object.SupportOAuth2);
    } else {
      message.SupportOAuth2 = false;
    }
    if (object.SupportStatic !== undefined && object.SupportStatic !== null) {
      message.SupportStatic = Boolean(object.SupportStatic);
    } else {
      message.SupportStatic = false;
    }
    return message;
  },

  toJSON(message: VerifierDetails): unknown {
    const obj: any = {};
    message.Name !== undefined && (obj.Name = message.Name);
    message.IdentityName !== undefined &&
      (obj.IdentityName = message.IdentityName);
    message.SupportRegular !== undefined &&
      (obj.SupportRegular = message.SupportRegular);
    message.SupportReverse !== undefined &&
      (obj.SupportReverse = message.SupportReverse);
    message.SupportOAuth2 !== undefined &&
      (obj.SupportOAuth2 = message.SupportOAuth2);
    message.SupportStatic !== undefined &&
      (obj.SupportStatic = message.SupportStatic);
    return obj;
  },

  fromPartial(object: DeepPartial<VerifierDetails>): VerifierDetails {
    const message = { ...baseVerifierDetails } as VerifierDetails;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.IdentityName !== undefined && object.IdentityName !== null) {
      message.IdentityName = object.IdentityName;
    } else {
      message.IdentityName = "";
    }
    if (object.SupportRegular !== undefined && object.SupportRegular !== null) {
      message.SupportRegular = object.SupportRegular;
    } else {
      message.SupportRegular = false;
    }
    if (object.SupportReverse !== undefined && object.SupportReverse !== null) {
      message.SupportReverse = object.SupportReverse;
    } else {
      message.SupportReverse = false;
    }
    if (object.SupportOAuth2 !== undefined && object.SupportOAuth2 !== null) {
      message.SupportOAuth2 = object.SupportOAuth2;
    } else {
      message.SupportOAuth2 = false;
    }
    if (object.SupportStatic !== undefined && object.SupportStatic !== null) {
      message.SupportStatic = object.SupportStatic;
    } else {
      message.SupportStatic = false;
    }
    return message;
  },
};

const baseListMyIdentitiesAndVerifiersResp: object = { Verifiers: "" };

export const ListMyIdentitiesAndVerifiersResp = {
  encode(
    message: ListMyIdentitiesAndVerifiersResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Identities) {
      IdentityData.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Verifiers) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListMyIdentitiesAndVerifiersResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseListMyIdentitiesAndVerifiersResp,
    } as ListMyIdentitiesAndVerifiersResp;
    message.Identities = [];
    message.Verifiers = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Identities.push(IdentityData.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Verifiers.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListMyIdentitiesAndVerifiersResp {
    const message = {
      ...baseListMyIdentitiesAndVerifiersResp,
    } as ListMyIdentitiesAndVerifiersResp;
    message.Identities = [];
    message.Verifiers = [];
    if (object.Identities !== undefined && object.Identities !== null) {
      for (const e of object.Identities) {
        message.Identities.push(IdentityData.fromJSON(e));
      }
    }
    if (object.Verifiers !== undefined && object.Verifiers !== null) {
      for (const e of object.Verifiers) {
        message.Verifiers.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: ListMyIdentitiesAndVerifiersResp): unknown {
    const obj: any = {};
    if (message.Identities) {
      obj.Identities = message.Identities.map((e) =>
        e ? IdentityData.toJSON(e) : undefined
      );
    } else {
      obj.Identities = [];
    }
    if (message.Verifiers) {
      obj.Verifiers = message.Verifiers.map((e) => e);
    } else {
      obj.Verifiers = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<ListMyIdentitiesAndVerifiersResp>
  ): ListMyIdentitiesAndVerifiersResp {
    const message = {
      ...baseListMyIdentitiesAndVerifiersResp,
    } as ListMyIdentitiesAndVerifiersResp;
    message.Identities = [];
    message.Verifiers = [];
    if (object.Identities !== undefined && object.Identities !== null) {
      for (const e of object.Identities) {
        message.Identities.push(IdentityData.fromPartial(e));
      }
    }
    if (object.Verifiers !== undefined && object.Verifiers !== null) {
      for (const e of object.Verifiers) {
        message.Verifiers.push(e);
      }
    }
    return message;
  },
};

const baseIdentityData: object = { Name: "", Identity: "" };

export const IdentityData = {
  encode(
    message: IdentityData,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Name !== "") {
      writer.uint32(10).string(message.Name);
    }
    if (message.Identity !== "") {
      writer.uint32(18).string(message.Identity);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IdentityData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseIdentityData } as IdentityData;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Name = reader.string();
          break;
        case 2:
          message.Identity = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IdentityData {
    const message = { ...baseIdentityData } as IdentityData;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Identity !== undefined && object.Identity !== null) {
      message.Identity = String(object.Identity);
    } else {
      message.Identity = "";
    }
    return message;
  },

  toJSON(message: IdentityData): unknown {
    const obj: any = {};
    message.Name !== undefined && (obj.Name = message.Name);
    message.Identity !== undefined && (obj.Identity = message.Identity);
    return obj;
  },

  fromPartial(object: DeepPartial<IdentityData>): IdentityData {
    const message = { ...baseIdentityData } as IdentityData;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Identity !== undefined && object.Identity !== null) {
      message.Identity = object.Identity;
    } else {
      message.Identity = "";
    }
    return message;
  },
};

const baseStartReq: object = {
  VerifierName: "",
  IdentityName: "",
  Identity: "",
};

export const StartReq = {
  encode(
    message: StartReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.VerifierName !== "") {
      writer.uint32(10).string(message.VerifierName);
    }
    if (message.IdentityName !== "") {
      writer.uint32(18).string(message.IdentityName);
    }
    if (message.Identity !== "") {
      writer.uint32(26).string(message.Identity);
    }
    Object.entries(message.Args).forEach(([key, value]) => {
      StartReq_ArgsEntry.encode(
        { key: key as any, value },
        writer.uint32(34).fork()
      ).ldelim();
    });
    Object.entries(message.Values).forEach(([key, value]) => {
      StartReq_ValuesEntry.encode(
        { key: key as any, value },
        writer.uint32(42).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStartReq } as StartReq;
    message.Args = {};
    message.Values = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.VerifierName = reader.string();
          break;
        case 2:
          message.IdentityName = reader.string();
          break;
        case 3:
          message.Identity = reader.string();
          break;
        case 4:
          const entry4 = StartReq_ArgsEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.Args[entry4.key] = entry4.value;
          }
          break;
        case 5:
          const entry5 = StartReq_ValuesEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.Values[entry5.key] = entry5.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartReq {
    const message = { ...baseStartReq } as StartReq;
    message.Args = {};
    message.Values = {};
    if (object.VerifierName !== undefined && object.VerifierName !== null) {
      message.VerifierName = String(object.VerifierName);
    } else {
      message.VerifierName = "";
    }
    if (object.IdentityName !== undefined && object.IdentityName !== null) {
      message.IdentityName = String(object.IdentityName);
    } else {
      message.IdentityName = "";
    }
    if (object.Identity !== undefined && object.Identity !== null) {
      message.Identity = String(object.Identity);
    } else {
      message.Identity = "";
    }
    if (object.Args !== undefined && object.Args !== null) {
      Object.entries(object.Args).forEach(([key, value]) => {
        message.Args[key] = String(value);
      });
    }
    if (object.Values !== undefined && object.Values !== null) {
      Object.entries(object.Values).forEach(([key, value]) => {
        message.Values[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: StartReq): unknown {
    const obj: any = {};
    message.VerifierName !== undefined &&
      (obj.VerifierName = message.VerifierName);
    message.IdentityName !== undefined &&
      (obj.IdentityName = message.IdentityName);
    message.Identity !== undefined && (obj.Identity = message.Identity);
    obj.Args = {};
    if (message.Args) {
      Object.entries(message.Args).forEach(([k, v]) => {
        obj.Args[k] = v;
      });
    }
    obj.Values = {};
    if (message.Values) {
      Object.entries(message.Values).forEach(([k, v]) => {
        obj.Values[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<StartReq>): StartReq {
    const message = { ...baseStartReq } as StartReq;
    message.Args = {};
    message.Values = {};
    if (object.VerifierName !== undefined && object.VerifierName !== null) {
      message.VerifierName = object.VerifierName;
    } else {
      message.VerifierName = "";
    }
    if (object.IdentityName !== undefined && object.IdentityName !== null) {
      message.IdentityName = object.IdentityName;
    } else {
      message.IdentityName = "";
    }
    if (object.Identity !== undefined && object.Identity !== null) {
      message.Identity = object.Identity;
    } else {
      message.Identity = "";
    }
    if (object.Args !== undefined && object.Args !== null) {
      Object.entries(object.Args).forEach(([key, value]) => {
        if (value !== undefined) {
          message.Args[key] = String(value);
        }
      });
    }
    if (object.Values !== undefined && object.Values !== null) {
      Object.entries(object.Values).forEach(([key, value]) => {
        if (value !== undefined) {
          message.Values[key] = String(value);
        }
      });
    }
    return message;
  },
};

const baseStartReq_ArgsEntry: object = { key: "", value: "" };

export const StartReq_ArgsEntry = {
  encode(
    message: StartReq_ArgsEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartReq_ArgsEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStartReq_ArgsEntry } as StartReq_ArgsEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartReq_ArgsEntry {
    const message = { ...baseStartReq_ArgsEntry } as StartReq_ArgsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: StartReq_ArgsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(object: DeepPartial<StartReq_ArgsEntry>): StartReq_ArgsEntry {
    const message = { ...baseStartReq_ArgsEntry } as StartReq_ArgsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

const baseStartReq_ValuesEntry: object = { key: "", value: "" };

export const StartReq_ValuesEntry = {
  encode(
    message: StartReq_ValuesEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StartReq_ValuesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStartReq_ValuesEntry } as StartReq_ValuesEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartReq_ValuesEntry {
    const message = { ...baseStartReq_ValuesEntry } as StartReq_ValuesEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: StartReq_ValuesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(object: DeepPartial<StartReq_ValuesEntry>): StartReq_ValuesEntry {
    const message = { ...baseStartReq_ValuesEntry } as StartReq_ValuesEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

const baseStartResp: object = {};

export const StartResp = {
  encode(
    message: StartResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    Object.entries(message.Directions).forEach(([key, value]) => {
      StartResp_DirectionsEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StartResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStartResp } as StartResp;
    message.Directions = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          const entry3 = StartResp_DirectionsEntry.decode(
            reader,
            reader.uint32()
          );
          if (entry3.value !== undefined) {
            message.Directions[entry3.key] = entry3.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartResp {
    const message = { ...baseStartResp } as StartResp;
    message.Directions = {};
    if (object.Directions !== undefined && object.Directions !== null) {
      Object.entries(object.Directions).forEach(([key, value]) => {
        message.Directions[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: StartResp): unknown {
    const obj: any = {};
    obj.Directions = {};
    if (message.Directions) {
      Object.entries(message.Directions).forEach(([k, v]) => {
        obj.Directions[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<StartResp>): StartResp {
    const message = { ...baseStartResp } as StartResp;
    message.Directions = {};
    if (object.Directions !== undefined && object.Directions !== null) {
      Object.entries(object.Directions).forEach(([key, value]) => {
        if (value !== undefined) {
          message.Directions[key] = String(value);
        }
      });
    }
    return message;
  },
};

const baseStartResp_DirectionsEntry: object = { key: "", value: "" };

export const StartResp_DirectionsEntry = {
  encode(
    message: StartResp_DirectionsEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StartResp_DirectionsEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseStartResp_DirectionsEntry,
    } as StartResp_DirectionsEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartResp_DirectionsEntry {
    const message = {
      ...baseStartResp_DirectionsEntry,
    } as StartResp_DirectionsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: StartResp_DirectionsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<StartResp_DirectionsEntry>
  ): StartResp_DirectionsEntry {
    const message = {
      ...baseStartResp_DirectionsEntry,
    } as StartResp_DirectionsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

const baseStatusReq: object = {};

export const StatusReq = {
  encode(_: StatusReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StatusReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatusReq } as StatusReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): StatusReq {
    const message = { ...baseStatusReq } as StatusReq;
    return message;
  },

  toJSON(_: StatusReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<StatusReq>): StatusReq {
    const message = { ...baseStatusReq } as StatusReq;
    return message;
  },
};

const baseStatus: object = { Token: "" };

export const Status = {
  encode(
    message: Status,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Token !== "") {
      writer.uint32(10).string(message.Token);
    }
    if (message.Authenticating !== undefined) {
      StatusAuthenticating.encode(
        message.Authenticating,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.Authenticated !== undefined) {
      StatusAuthenticated.encode(
        message.Authenticated,
        writer.uint32(26).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatus } as Status;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Token = reader.string();
          break;
        case 2:
          message.Authenticating = StatusAuthenticating.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.Authenticated = StatusAuthenticated.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Status {
    const message = { ...baseStatus } as Status;
    if (object.Token !== undefined && object.Token !== null) {
      message.Token = String(object.Token);
    } else {
      message.Token = "";
    }
    if (object.Authenticating !== undefined && object.Authenticating !== null) {
      message.Authenticating = StatusAuthenticating.fromJSON(
        object.Authenticating
      );
    } else {
      message.Authenticating = undefined;
    }
    if (object.Authenticated !== undefined && object.Authenticated !== null) {
      message.Authenticated = StatusAuthenticated.fromJSON(
        object.Authenticated
      );
    } else {
      message.Authenticated = undefined;
    }
    return message;
  },

  toJSON(message: Status): unknown {
    const obj: any = {};
    message.Token !== undefined && (obj.Token = message.Token);
    message.Authenticating !== undefined &&
      (obj.Authenticating = message.Authenticating
        ? StatusAuthenticating.toJSON(message.Authenticating)
        : undefined);
    message.Authenticated !== undefined &&
      (obj.Authenticated = message.Authenticated
        ? StatusAuthenticated.toJSON(message.Authenticated)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Status>): Status {
    const message = { ...baseStatus } as Status;
    if (object.Token !== undefined && object.Token !== null) {
      message.Token = object.Token;
    } else {
      message.Token = "";
    }
    if (object.Authenticating !== undefined && object.Authenticating !== null) {
      message.Authenticating = StatusAuthenticating.fromPartial(
        object.Authenticating
      );
    } else {
      message.Authenticating = undefined;
    }
    if (object.Authenticated !== undefined && object.Authenticated !== null) {
      message.Authenticated = StatusAuthenticated.fromPartial(
        object.Authenticated
      );
    } else {
      message.Authenticated = undefined;
    }
    return message;
  },
};

const baseStatusAuthenticated: object = { User: "" };

export const StatusAuthenticated = {
  encode(
    message: StatusAuthenticated,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.User !== "") {
      writer.uint32(10).string(message.User);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StatusAuthenticated {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatusAuthenticated } as StatusAuthenticated;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StatusAuthenticated {
    const message = { ...baseStatusAuthenticated } as StatusAuthenticated;
    if (object.User !== undefined && object.User !== null) {
      message.User = String(object.User);
    } else {
      message.User = "";
    }
    return message;
  },

  toJSON(message: StatusAuthenticated): unknown {
    const obj: any = {};
    message.User !== undefined && (obj.User = message.User);
    return obj;
  },

  fromPartial(object: DeepPartial<StatusAuthenticated>): StatusAuthenticated {
    const message = { ...baseStatusAuthenticated } as StatusAuthenticated;
    if (object.User !== undefined && object.User !== null) {
      message.User = object.User;
    } else {
      message.User = "";
    }
    return message;
  },
};

const baseStatusAuthenticating: object = { RemainingFactors: 0 };

export const StatusAuthenticating = {
  encode(
    message: StatusAuthenticating,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.SignIn !== undefined) {
      writer.uint32(8).bool(message.SignIn);
    }
    if (message.SignUp !== undefined) {
      writer.uint32(16).bool(message.SignUp);
    }
    if (message.Attach !== undefined) {
      writer.uint32(24).bool(message.Attach);
    }
    for (const v of message.CompletedFactors) {
      StatusCompletedFactors.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.RemainingFactors !== 0) {
      writer.uint32(40).int64(message.RemainingFactors);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StatusAuthenticating {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatusAuthenticating } as StatusAuthenticating;
    message.CompletedFactors = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SignIn = reader.bool();
          break;
        case 2:
          message.SignUp = reader.bool();
          break;
        case 3:
          message.Attach = reader.bool();
          break;
        case 4:
          message.CompletedFactors.push(
            StatusCompletedFactors.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.RemainingFactors = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StatusAuthenticating {
    const message = { ...baseStatusAuthenticating } as StatusAuthenticating;
    message.CompletedFactors = [];
    if (object.SignIn !== undefined && object.SignIn !== null) {
      message.SignIn = Boolean(object.SignIn);
    } else {
      message.SignIn = undefined;
    }
    if (object.SignUp !== undefined && object.SignUp !== null) {
      message.SignUp = Boolean(object.SignUp);
    } else {
      message.SignUp = undefined;
    }
    if (object.Attach !== undefined && object.Attach !== null) {
      message.Attach = Boolean(object.Attach);
    } else {
      message.Attach = undefined;
    }
    if (
      object.CompletedFactors !== undefined &&
      object.CompletedFactors !== null
    ) {
      for (const e of object.CompletedFactors) {
        message.CompletedFactors.push(StatusCompletedFactors.fromJSON(e));
      }
    }
    if (
      object.RemainingFactors !== undefined &&
      object.RemainingFactors !== null
    ) {
      message.RemainingFactors = Number(object.RemainingFactors);
    } else {
      message.RemainingFactors = 0;
    }
    return message;
  },

  toJSON(message: StatusAuthenticating): unknown {
    const obj: any = {};
    message.SignIn !== undefined && (obj.SignIn = message.SignIn);
    message.SignUp !== undefined && (obj.SignUp = message.SignUp);
    message.Attach !== undefined && (obj.Attach = message.Attach);
    if (message.CompletedFactors) {
      obj.CompletedFactors = message.CompletedFactors.map((e) =>
        e ? StatusCompletedFactors.toJSON(e) : undefined
      );
    } else {
      obj.CompletedFactors = [];
    }
    message.RemainingFactors !== undefined &&
      (obj.RemainingFactors = message.RemainingFactors);
    return obj;
  },

  fromPartial(object: DeepPartial<StatusAuthenticating>): StatusAuthenticating {
    const message = { ...baseStatusAuthenticating } as StatusAuthenticating;
    message.CompletedFactors = [];
    if (object.SignIn !== undefined && object.SignIn !== null) {
      message.SignIn = object.SignIn;
    } else {
      message.SignIn = undefined;
    }
    if (object.SignUp !== undefined && object.SignUp !== null) {
      message.SignUp = object.SignUp;
    } else {
      message.SignUp = undefined;
    }
    if (object.Attach !== undefined && object.Attach !== null) {
      message.Attach = object.Attach;
    } else {
      message.Attach = undefined;
    }
    if (
      object.CompletedFactors !== undefined &&
      object.CompletedFactors !== null
    ) {
      for (const e of object.CompletedFactors) {
        message.CompletedFactors.push(StatusCompletedFactors.fromPartial(e));
      }
    }
    if (
      object.RemainingFactors !== undefined &&
      object.RemainingFactors !== null
    ) {
      message.RemainingFactors = object.RemainingFactors;
    } else {
      message.RemainingFactors = 0;
    }
    return message;
  },
};

const baseStatusCompletedFactors: object = {
  VerifierName: "",
  IdentityName: "",
  Identity: "",
};

export const StatusCompletedFactors = {
  encode(
    message: StatusCompletedFactors,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.VerifierName !== "") {
      writer.uint32(10).string(message.VerifierName);
    }
    if (message.IdentityName !== "") {
      writer.uint32(18).string(message.IdentityName);
    }
    if (message.Identity !== "") {
      writer.uint32(26).string(message.Identity);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): StatusCompletedFactors {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatusCompletedFactors } as StatusCompletedFactors;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.VerifierName = reader.string();
          break;
        case 2:
          message.IdentityName = reader.string();
          break;
        case 3:
          message.Identity = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StatusCompletedFactors {
    const message = { ...baseStatusCompletedFactors } as StatusCompletedFactors;
    if (object.VerifierName !== undefined && object.VerifierName !== null) {
      message.VerifierName = String(object.VerifierName);
    } else {
      message.VerifierName = "";
    }
    if (object.IdentityName !== undefined && object.IdentityName !== null) {
      message.IdentityName = String(object.IdentityName);
    } else {
      message.IdentityName = "";
    }
    if (object.Identity !== undefined && object.Identity !== null) {
      message.Identity = String(object.Identity);
    } else {
      message.Identity = "";
    }
    return message;
  },

  toJSON(message: StatusCompletedFactors): unknown {
    const obj: any = {};
    message.VerifierName !== undefined &&
      (obj.VerifierName = message.VerifierName);
    message.IdentityName !== undefined &&
      (obj.IdentityName = message.IdentityName);
    message.Identity !== undefined && (obj.Identity = message.Identity);
    return obj;
  },

  fromPartial(
    object: DeepPartial<StatusCompletedFactors>
  ): StatusCompletedFactors {
    const message = { ...baseStatusCompletedFactors } as StatusCompletedFactors;
    if (object.VerifierName !== undefined && object.VerifierName !== null) {
      message.VerifierName = object.VerifierName;
    } else {
      message.VerifierName = "";
    }
    if (object.IdentityName !== undefined && object.IdentityName !== null) {
      message.IdentityName = object.IdentityName;
    } else {
      message.IdentityName = "";
    }
    if (object.Identity !== undefined && object.Identity !== null) {
      message.Identity = object.Identity;
    } else {
      message.Identity = "";
    }
    return message;
  },
};

const baseUserMergeReq: object = {};

export const UserMergeReq = {
  encode(
    _: UserMergeReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMergeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserMergeReq } as UserMergeReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UserMergeReq {
    const message = { ...baseUserMergeReq } as UserMergeReq;
    return message;
  },

  toJSON(_: UserMergeReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<UserMergeReq>): UserMergeReq {
    const message = { ...baseUserMergeReq } as UserMergeReq;
    return message;
  },
};

const baseUserMergeResp: object = {};

export const UserMergeResp = {
  encode(
    _: UserMergeResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMergeResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserMergeResp } as UserMergeResp;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UserMergeResp {
    const message = { ...baseUserMergeResp } as UserMergeResp;
    return message;
  },

  toJSON(_: UserMergeResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<UserMergeResp>): UserMergeResp {
    const message = { ...baseUserMergeResp } as UserMergeResp;
    return message;
  },
};

const baseCancelAuthenticationReq: object = {};

export const CancelAuthenticationReq = {
  encode(
    _: CancelAuthenticationReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CancelAuthenticationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseCancelAuthenticationReq,
    } as CancelAuthenticationReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): CancelAuthenticationReq {
    const message = {
      ...baseCancelAuthenticationReq,
    } as CancelAuthenticationReq;
    return message;
  },

  toJSON(_: CancelAuthenticationReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<CancelAuthenticationReq>
  ): CancelAuthenticationReq {
    const message = {
      ...baseCancelAuthenticationReq,
    } as CancelAuthenticationReq;
    return message;
  },
};

const baseLogoutReq: object = { AllSessions: false };

export const LogoutReq = {
  encode(
    message: LogoutReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.AllSessions === true) {
      writer.uint32(8).bool(message.AllSessions);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLogoutReq } as LogoutReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AllSessions = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LogoutReq {
    const message = { ...baseLogoutReq } as LogoutReq;
    if (object.AllSessions !== undefined && object.AllSessions !== null) {
      message.AllSessions = Boolean(object.AllSessions);
    } else {
      message.AllSessions = false;
    }
    return message;
  },

  toJSON(message: LogoutReq): unknown {
    const obj: any = {};
    message.AllSessions !== undefined &&
      (obj.AllSessions = message.AllSessions);
    return obj;
  },

  fromPartial(object: DeepPartial<LogoutReq>): LogoutReq {
    const message = { ...baseLogoutReq } as LogoutReq;
    if (object.AllSessions !== undefined && object.AllSessions !== null) {
      message.AllSessions = object.AllSessions;
    } else {
      message.AllSessions = false;
    }
    return message;
  },
};

const baseInitializeStaticVerifierReq: object = { VerifierName: "" };

export const InitializeStaticVerifierReq = {
  encode(
    message: InitializeStaticVerifierReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.VerifierName !== "") {
      writer.uint32(10).string(message.VerifierName);
    }
    Object.entries(message.Args).forEach(([key, value]) => {
      InitializeStaticVerifierReq_ArgsEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): InitializeStaticVerifierReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseInitializeStaticVerifierReq,
    } as InitializeStaticVerifierReq;
    message.Args = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.VerifierName = reader.string();
          break;
        case 2:
          const entry2 = InitializeStaticVerifierReq_ArgsEntry.decode(
            reader,
            reader.uint32()
          );
          if (entry2.value !== undefined) {
            message.Args[entry2.key] = entry2.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InitializeStaticVerifierReq {
    const message = {
      ...baseInitializeStaticVerifierReq,
    } as InitializeStaticVerifierReq;
    message.Args = {};
    if (object.VerifierName !== undefined && object.VerifierName !== null) {
      message.VerifierName = String(object.VerifierName);
    } else {
      message.VerifierName = "";
    }
    if (object.Args !== undefined && object.Args !== null) {
      Object.entries(object.Args).forEach(([key, value]) => {
        message.Args[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: InitializeStaticVerifierReq): unknown {
    const obj: any = {};
    message.VerifierName !== undefined &&
      (obj.VerifierName = message.VerifierName);
    obj.Args = {};
    if (message.Args) {
      Object.entries(message.Args).forEach(([k, v]) => {
        obj.Args[k] = v;
      });
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<InitializeStaticVerifierReq>
  ): InitializeStaticVerifierReq {
    const message = {
      ...baseInitializeStaticVerifierReq,
    } as InitializeStaticVerifierReq;
    message.Args = {};
    if (object.VerifierName !== undefined && object.VerifierName !== null) {
      message.VerifierName = object.VerifierName;
    } else {
      message.VerifierName = "";
    }
    if (object.Args !== undefined && object.Args !== null) {
      Object.entries(object.Args).forEach(([key, value]) => {
        if (value !== undefined) {
          message.Args[key] = String(value);
        }
      });
    }
    return message;
  },
};

const baseInitializeStaticVerifierReq_ArgsEntry: object = {
  key: "",
  value: "",
};

export const InitializeStaticVerifierReq_ArgsEntry = {
  encode(
    message: InitializeStaticVerifierReq_ArgsEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): InitializeStaticVerifierReq_ArgsEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseInitializeStaticVerifierReq_ArgsEntry,
    } as InitializeStaticVerifierReq_ArgsEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InitializeStaticVerifierReq_ArgsEntry {
    const message = {
      ...baseInitializeStaticVerifierReq_ArgsEntry,
    } as InitializeStaticVerifierReq_ArgsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: InitializeStaticVerifierReq_ArgsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<InitializeStaticVerifierReq_ArgsEntry>
  ): InitializeStaticVerifierReq_ArgsEntry {
    const message = {
      ...baseInitializeStaticVerifierReq_ArgsEntry,
    } as InitializeStaticVerifierReq_ArgsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

const baseInitializeStaticVerifierResp: object = {};

export const InitializeStaticVerifierResp = {
  encode(
    message: InitializeStaticVerifierResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    Object.entries(message.Result).forEach(([key, value]) => {
      InitializeStaticVerifierResp_ResultEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): InitializeStaticVerifierResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseInitializeStaticVerifierResp,
    } as InitializeStaticVerifierResp;
    message.Result = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          const entry1 = InitializeStaticVerifierResp_ResultEntry.decode(
            reader,
            reader.uint32()
          );
          if (entry1.value !== undefined) {
            message.Result[entry1.key] = entry1.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InitializeStaticVerifierResp {
    const message = {
      ...baseInitializeStaticVerifierResp,
    } as InitializeStaticVerifierResp;
    message.Result = {};
    if (object.Result !== undefined && object.Result !== null) {
      Object.entries(object.Result).forEach(([key, value]) => {
        message.Result[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: InitializeStaticVerifierResp): unknown {
    const obj: any = {};
    obj.Result = {};
    if (message.Result) {
      Object.entries(message.Result).forEach(([k, v]) => {
        obj.Result[k] = v;
      });
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<InitializeStaticVerifierResp>
  ): InitializeStaticVerifierResp {
    const message = {
      ...baseInitializeStaticVerifierResp,
    } as InitializeStaticVerifierResp;
    message.Result = {};
    if (object.Result !== undefined && object.Result !== null) {
      Object.entries(object.Result).forEach(([key, value]) => {
        if (value !== undefined) {
          message.Result[key] = String(value);
        }
      });
    }
    return message;
  },
};

const baseInitializeStaticVerifierResp_ResultEntry: object = {
  key: "",
  value: "",
};

export const InitializeStaticVerifierResp_ResultEntry = {
  encode(
    message: InitializeStaticVerifierResp_ResultEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): InitializeStaticVerifierResp_ResultEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseInitializeStaticVerifierResp_ResultEntry,
    } as InitializeStaticVerifierResp_ResultEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InitializeStaticVerifierResp_ResultEntry {
    const message = {
      ...baseInitializeStaticVerifierResp_ResultEntry,
    } as InitializeStaticVerifierResp_ResultEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: InitializeStaticVerifierResp_ResultEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<InitializeStaticVerifierResp_ResultEntry>
  ): InitializeStaticVerifierResp_ResultEntry {
    const message = {
      ...baseInitializeStaticVerifierResp_ResultEntry,
    } as InitializeStaticVerifierResp_ResultEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

const baseVerifyReq: object = {
  VerifierName: "",
  VerificationCode: "",
  IdentityName: "",
  Identity: "",
};

export const VerifyReq = {
  encode(
    message: VerifyReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.VerifierName !== "") {
      writer.uint32(10).string(message.VerifierName);
    }
    if (message.VerificationCode !== "") {
      writer.uint32(18).string(message.VerificationCode);
    }
    if (message.IdentityName !== "") {
      writer.uint32(26).string(message.IdentityName);
    }
    if (message.Identity !== "") {
      writer.uint32(34).string(message.Identity);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseVerifyReq } as VerifyReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.VerifierName = reader.string();
          break;
        case 2:
          message.VerificationCode = reader.string();
          break;
        case 3:
          message.IdentityName = reader.string();
          break;
        case 4:
          message.Identity = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VerifyReq {
    const message = { ...baseVerifyReq } as VerifyReq;
    if (object.VerifierName !== undefined && object.VerifierName !== null) {
      message.VerifierName = String(object.VerifierName);
    } else {
      message.VerifierName = "";
    }
    if (
      object.VerificationCode !== undefined &&
      object.VerificationCode !== null
    ) {
      message.VerificationCode = String(object.VerificationCode);
    } else {
      message.VerificationCode = "";
    }
    if (object.IdentityName !== undefined && object.IdentityName !== null) {
      message.IdentityName = String(object.IdentityName);
    } else {
      message.IdentityName = "";
    }
    if (object.Identity !== undefined && object.Identity !== null) {
      message.Identity = String(object.Identity);
    } else {
      message.Identity = "";
    }
    return message;
  },

  toJSON(message: VerifyReq): unknown {
    const obj: any = {};
    message.VerifierName !== undefined &&
      (obj.VerifierName = message.VerifierName);
    message.VerificationCode !== undefined &&
      (obj.VerificationCode = message.VerificationCode);
    message.IdentityName !== undefined &&
      (obj.IdentityName = message.IdentityName);
    message.Identity !== undefined && (obj.Identity = message.Identity);
    return obj;
  },

  fromPartial(object: DeepPartial<VerifyReq>): VerifyReq {
    const message = { ...baseVerifyReq } as VerifyReq;
    if (object.VerifierName !== undefined && object.VerifierName !== null) {
      message.VerifierName = object.VerifierName;
    } else {
      message.VerifierName = "";
    }
    if (
      object.VerificationCode !== undefined &&
      object.VerificationCode !== null
    ) {
      message.VerificationCode = object.VerificationCode;
    } else {
      message.VerificationCode = "";
    }
    if (object.IdentityName !== undefined && object.IdentityName !== null) {
      message.IdentityName = object.IdentityName;
    } else {
      message.IdentityName = "";
    }
    if (object.Identity !== undefined && object.Identity !== null) {
      message.Identity = object.Identity;
    } else {
      message.Identity = "";
    }
    return message;
  },
};

const baseLoginAsReq: object = { User: "" };

export const LoginAsReq = {
  encode(
    message: LoginAsReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.User !== "") {
      writer.uint32(10).string(message.User);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginAsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLoginAsReq } as LoginAsReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LoginAsReq {
    const message = { ...baseLoginAsReq } as LoginAsReq;
    if (object.User !== undefined && object.User !== null) {
      message.User = String(object.User);
    } else {
      message.User = "";
    }
    return message;
  },

  toJSON(message: LoginAsReq): unknown {
    const obj: any = {};
    message.User !== undefined && (obj.User = message.User);
    return obj;
  },

  fromPartial(object: DeepPartial<LoginAsReq>): LoginAsReq {
    const message = { ...baseLoginAsReq } as LoginAsReq;
    if (object.User !== undefined && object.User !== null) {
      message.User = object.User;
    } else {
      message.User = "";
    }
    return message;
  },
};

const baseLoginAsResp: object = { Session: "", User: "", Error: "" };

export const LoginAsResp = {
  encode(
    message: LoginAsResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Session !== "") {
      writer.uint32(10).string(message.Session);
    }
    if (message.User !== "") {
      writer.uint32(18).string(message.User);
    }
    if (message.Error !== "") {
      writer.uint32(26).string(message.Error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginAsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLoginAsResp } as LoginAsResp;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Session = reader.string();
          break;
        case 2:
          message.User = reader.string();
          break;
        case 3:
          message.Error = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LoginAsResp {
    const message = { ...baseLoginAsResp } as LoginAsResp;
    if (object.Session !== undefined && object.Session !== null) {
      message.Session = String(object.Session);
    } else {
      message.Session = "";
    }
    if (object.User !== undefined && object.User !== null) {
      message.User = String(object.User);
    } else {
      message.User = "";
    }
    if (object.Error !== undefined && object.Error !== null) {
      message.Error = String(object.Error);
    } else {
      message.Error = "";
    }
    return message;
  },

  toJSON(message: LoginAsResp): unknown {
    const obj: any = {};
    message.Session !== undefined && (obj.Session = message.Session);
    message.User !== undefined && (obj.User = message.User);
    message.Error !== undefined && (obj.Error = message.Error);
    return obj;
  },

  fromPartial(object: DeepPartial<LoginAsResp>): LoginAsResp {
    const message = { ...baseLoginAsResp } as LoginAsResp;
    if (object.Session !== undefined && object.Session !== null) {
      message.Session = object.Session;
    } else {
      message.Session = "";
    }
    if (object.User !== undefined && object.User !== null) {
      message.User = object.User;
    } else {
      message.User = "";
    }
    if (object.Error !== undefined && object.Error !== null) {
      message.Error = object.Error;
    } else {
      message.Error = "";
    }
    return message;
  },
};

/**
 * //////////////////////////////////////////////////////////////
 * //
 * //
 */
export interface Identity {
  ListSupportedIdentitiesAndVerifiers(
    request: DeepPartial<ListSupportedIdentitiesAndVerifiersReq>,
    metadata?: grpc.Metadata
  ): Promise<VerifierDetailsResp>;
  CheckStatus(
    request: DeepPartial<StatusReq>,
    metadata?: grpc.Metadata
  ): Promise<Status>;
  Logout(
    request: DeepPartial<LogoutReq>,
    metadata?: grpc.Metadata
  ): Promise<Status>;
  UserMerge(
    request: DeepPartial<UserMergeReq>,
    metadata?: grpc.Metadata
  ): Promise<UserMergeResp>;
  /**
   * > Only applicable to unauthenticated requests
   *  Will NOT create/modify any new user record
   *  As result of successful authentication
   *    existing user will be signed in
   */
  StartSignIn(
    request: DeepPartial<StartSignInReq>,
    metadata?: grpc.Metadata
  ): Promise<Status>;
  /**
   * > Only applicable to unauthenticated requests
   *  WILL create new user record; Can only use new (unregistered) identities
   *  As result of successful authentication
   *    new user will be created
   *    or existing user will be signed in
   */
  StartSignUp(
    request: DeepPartial<StartSignUpReq>,
    metadata?: grpc.Metadata
  ): Promise<Status>;
  /**
   * > Only applicable to authenticated requests
   *  Will add new verifier/identity to existing user
   *  As result of successful authentication
   *    new identity/verifier will be added to existing user
   */
  StartAttach(
    request: DeepPartial<StartAttachReq>,
    metadata?: grpc.Metadata
  ): Promise<Status>;
  /**
   * //////////////////////////////////////////////////////////////
   * // Working within authentication process
   *
   * This methods can be called only when there is an active authentication process
   */
  CancelAuthentication(
    request: DeepPartial<CancelAuthenticationReq>,
    metadata?: grpc.Metadata
  ): Promise<Status>;
  /**
   * Applicable on authenticated requests or on requests with active authentication process with
   * at least one factor passed
   */
  ListMyIdentitiesAndVerifiers(
    request: DeepPartial<ListMyIdentitiesAndVerifiersReq>,
    metadata?: grpc.Metadata
  ): Promise<ListMyIdentitiesAndVerifiersResp>;
  Start(
    request: DeepPartial<StartReq>,
    metadata?: grpc.Metadata
  ): Promise<StartResp>;
  Verify(
    request: DeepPartial<VerifyReq>,
    metadata?: grpc.Metadata
  ): Promise<Status>;
}

export class IdentityClientImpl implements Identity {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListSupportedIdentitiesAndVerifiers =
      this.ListSupportedIdentitiesAndVerifiers.bind(this);
    this.CheckStatus = this.CheckStatus.bind(this);
    this.Logout = this.Logout.bind(this);
    this.UserMerge = this.UserMerge.bind(this);
    this.StartSignIn = this.StartSignIn.bind(this);
    this.StartSignUp = this.StartSignUp.bind(this);
    this.StartAttach = this.StartAttach.bind(this);
    this.CancelAuthentication = this.CancelAuthentication.bind(this);
    this.ListMyIdentitiesAndVerifiers =
      this.ListMyIdentitiesAndVerifiers.bind(this);
    this.Start = this.Start.bind(this);
    this.Verify = this.Verify.bind(this);
  }

  ListSupportedIdentitiesAndVerifiers(
    request: DeepPartial<ListSupportedIdentitiesAndVerifiersReq>,
    metadata?: grpc.Metadata
  ): Promise<VerifierDetailsResp> {
    return this.rpc.unary(
      IdentityListSupportedIdentitiesAndVerifiersDesc,
      ListSupportedIdentitiesAndVerifiersReq.fromPartial(request),
      metadata
    );
  }

  CheckStatus(
    request: DeepPartial<StatusReq>,
    metadata?: grpc.Metadata
  ): Promise<Status> {
    return this.rpc.unary(
      IdentityCheckStatusDesc,
      StatusReq.fromPartial(request),
      metadata
    );
  }

  Logout(
    request: DeepPartial<LogoutReq>,
    metadata?: grpc.Metadata
  ): Promise<Status> {
    return this.rpc.unary(
      IdentityLogoutDesc,
      LogoutReq.fromPartial(request),
      metadata
    );
  }

  UserMerge(
    request: DeepPartial<UserMergeReq>,
    metadata?: grpc.Metadata
  ): Promise<UserMergeResp> {
    return this.rpc.unary(
      IdentityUserMergeDesc,
      UserMergeReq.fromPartial(request),
      metadata
    );
  }

  StartSignIn(
    request: DeepPartial<StartSignInReq>,
    metadata?: grpc.Metadata
  ): Promise<Status> {
    return this.rpc.unary(
      IdentityStartSignInDesc,
      StartSignInReq.fromPartial(request),
      metadata
    );
  }

  StartSignUp(
    request: DeepPartial<StartSignUpReq>,
    metadata?: grpc.Metadata
  ): Promise<Status> {
    return this.rpc.unary(
      IdentityStartSignUpDesc,
      StartSignUpReq.fromPartial(request),
      metadata
    );
  }

  StartAttach(
    request: DeepPartial<StartAttachReq>,
    metadata?: grpc.Metadata
  ): Promise<Status> {
    return this.rpc.unary(
      IdentityStartAttachDesc,
      StartAttachReq.fromPartial(request),
      metadata
    );
  }

  CancelAuthentication(
    request: DeepPartial<CancelAuthenticationReq>,
    metadata?: grpc.Metadata
  ): Promise<Status> {
    return this.rpc.unary(
      IdentityCancelAuthenticationDesc,
      CancelAuthenticationReq.fromPartial(request),
      metadata
    );
  }

  ListMyIdentitiesAndVerifiers(
    request: DeepPartial<ListMyIdentitiesAndVerifiersReq>,
    metadata?: grpc.Metadata
  ): Promise<ListMyIdentitiesAndVerifiersResp> {
    return this.rpc.unary(
      IdentityListMyIdentitiesAndVerifiersDesc,
      ListMyIdentitiesAndVerifiersReq.fromPartial(request),
      metadata
    );
  }

  Start(
    request: DeepPartial<StartReq>,
    metadata?: grpc.Metadata
  ): Promise<StartResp> {
    return this.rpc.unary(
      IdentityStartDesc,
      StartReq.fromPartial(request),
      metadata
    );
  }

  Verify(
    request: DeepPartial<VerifyReq>,
    metadata?: grpc.Metadata
  ): Promise<Status> {
    return this.rpc.unary(
      IdentityVerifyDesc,
      VerifyReq.fromPartial(request),
      metadata
    );
  }
}

export const IdentityDesc = {
  serviceName: "api.themakers.identity.Identity",
};

export const IdentityListSupportedIdentitiesAndVerifiersDesc: UnaryMethodDefinitionish =
  {
    methodName: "ListSupportedIdentitiesAndVerifiers",
    service: IdentityDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ListSupportedIdentitiesAndVerifiersReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...VerifierDetailsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const IdentityCheckStatusDesc: UnaryMethodDefinitionish = {
  methodName: "CheckStatus",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StatusReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Status.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityLogoutDesc: UnaryMethodDefinitionish = {
  methodName: "Logout",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LogoutReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Status.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityUserMergeDesc: UnaryMethodDefinitionish = {
  methodName: "UserMerge",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UserMergeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...UserMergeResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityStartSignInDesc: UnaryMethodDefinitionish = {
  methodName: "StartSignIn",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartSignInReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Status.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityStartSignUpDesc: UnaryMethodDefinitionish = {
  methodName: "StartSignUp",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartSignUpReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Status.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityStartAttachDesc: UnaryMethodDefinitionish = {
  methodName: "StartAttach",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartAttachReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Status.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityCancelAuthenticationDesc: UnaryMethodDefinitionish = {
  methodName: "CancelAuthentication",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancelAuthenticationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Status.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityListMyIdentitiesAndVerifiersDesc: UnaryMethodDefinitionish =
  {
    methodName: "ListMyIdentitiesAndVerifiers",
    service: IdentityDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ListMyIdentitiesAndVerifiersReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ListMyIdentitiesAndVerifiersResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const IdentityStartDesc: UnaryMethodDefinitionish = {
  methodName: "Start",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...StartResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const IdentityVerifyDesc: UnaryMethodDefinitionish = {
  methodName: "Verify",
  service: IdentityDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return VerifyReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Status.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

/**
 * //////////////////////////////////////////////////////////////
 * // Private service
 * //
 */
export interface IdentityPrivate {
  LoginAs(
    request: DeepPartial<LoginAsReq>,
    metadata?: grpc.Metadata
  ): Promise<LoginAsResp>;
}

export class IdentityPrivateClientImpl implements IdentityPrivate {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.LoginAs = this.LoginAs.bind(this);
  }

  LoginAs(
    request: DeepPartial<LoginAsReq>,
    metadata?: grpc.Metadata
  ): Promise<LoginAsResp> {
    return this.rpc.unary(
      IdentityPrivateLoginAsDesc,
      LoginAsReq.fromPartial(request),
      metadata
    );
  }
}

export const IdentityPrivateDesc = {
  serviceName: "api.themakers.identity.IdentityPrivate",
};

export const IdentityPrivateLoginAsDesc: UnaryMethodDefinitionish = {
  methodName: "LoginAs",
  service: IdentityPrivateDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LoginAsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...LoginAsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    }
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          console.log('response', response);
          const authorization = response.trailers?.headersMap?.authorization
            console.log('authorization--', authorization)
          if (authorization && authorization[0]) {
              localStorage.setItem('authorization', authorization[0])
          }
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
