

































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { DOC_STATUSES } from '@/model/Document'
import { Unit } from '@/model/Unit'
import { default_doctype_filters, DocumentListFilters } from '@/model/UserOptions'

import HelpCard from '@/components/about_system/HelpCard.vue'
import Datepicker from '@/components/common/datepicker/Datepicker.vue'
import InputSearch from '@/components/common/inputs/InputSearch.vue'
import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import Button from '@/components/common/buttons/Button.vue'
import Checkbox from '@/components/common/inputs/Checkbox.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'

@Component({
    components: {
        HelpCard,
        Datepicker,
        InputSearch,
        ButtonViolet,
        Button,
        Checkbox,
        RadioButton
    }
})

export default class HelpDocumentListFilter extends Vue {
    // *************************************************************
    // DATA PARAMS
    defaultFilters: DocumentListFilters = default_doctype_filters()

    filters = false
    mainStatuses = {
        draft: true,
        approval: true,
        execution: true
    }
    archived = true
    statuses = DOC_STATUSES
    isReceivedArchived = false
    selectedDocuments = false
    rootUnit = ''

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>
    get rootUnits(): Array<Unit> {
        // Собираем все units с пометкой 'Организация'
        return this.getUnits.filter((unit: Unit) => unit.root)
    }
}
