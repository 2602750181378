






















































import { Component, Vue, Prop, Watch, Emit, PropSync } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User, Role } from '@/model/User'
import { Unit } from '@/model/Unit'

import InputSearch from '@/components/common/inputs/InputSearch.vue'
import ButtonCreate from '@/components/common/buttons/ButtonCreate.vue'
import Modal from '@/components/common/modals/Modal.vue'
import Button from '@/components/common/buttons/Button.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'

const types: { [key: string]: { buttonText: string, title: string } } = {
    docType: { buttonText: 'тип документа', title: 'типа документа' },
    numerator: { buttonText: 'нумератор', title: 'нумератора' },
    directory: { buttonText: 'справочник', title: 'справочника' },
    familiarization: { buttonText: 'ознакомление', title: 'ознакомления' }
}

@Component({
    components: {
        InputSearch,
        ButtonCreate,
        Modal,
        Button,
        RadioButton
    }
})

export default class BaseListPage extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) type!: string
    @Prop({ default: false }) disabledCreate!: boolean
    @PropSync('visibleModal', { type: Boolean }) syncedVisible!: boolean

    // *************************************************************
    // COMPUTED
    types = types
    centerId = ''
    search = ''

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>

    get centers(): Array<Unit> {
        if (this.getMe.roles.find((role: Role) => role.unit === 'root')) {
            return this.getUnits.filter((unit: Unit) => unit.parent === 'root')
        }

        const managerRoles = this.getMe.roles.filter((role: Role) => role.name === 'WorkflowManager' && role.unit !== 'root')
        const centers: Array<Unit> = []

        managerRoles.forEach((role: Role) => {
            const unit = this.getUnitById(role.unit)
            if (unit) {
                centers.push(unit)
            }
        })

        return centers
    }

    // *************************************************************
    // WATCH
    @Watch('search')
    handleSearchChange(val: string): void {
        this.emitInput(val)
    }

    @Watch('centerId')
    handleCenterIdChange(val: string): void {
        this.emitChangeCenterId(val)
    }

    // *************************************************************
    // MOUNTED
    mounted(): void {
        if (!this.centers.length) {
            this.centerId = ''
        } else if (this.centers.length === 1) {
            this.centerId = this.centers[0].id
        }
    }

    // *************************************************************
    // METHODS
    showCreate(): void {
        this.syncedVisible = true
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(val: string): string {
        return val
    }

    @Emit('changeCenterId')
    emitChangeCenterId(val: string): string {
        return val
    }

    @Emit('close')
    emitClose():  void {
        // Do nothing
    }

    @Emit('create')
    emitCreate():  void {
        // Do nothing
    }
}
