var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "action-delete-stage"
  }, [_c('div', [_vm._v("\n        Удалить блок \"" + _vm._s(_vm.getActiveStage.displayName) + "\"\n    ")]), _vm._v(" "), _c('ButtonGreen', {
    staticClass: "action-delete-stage__button",
    attrs: {
      "text": "Удалить блок"
    },
    on: {
      "click": _vm.deleteStage
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }