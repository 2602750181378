

























import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { WorkflowTemplate, StageTemplate, WFVvs } from '@/model/Workflow'

import Select from '@/components/common/select/Select.vue'

@Component({
    components: { Select }
})

export default class WFTAirForce extends Vue {
    // *************************************************************
    // DATA PARAMS
    airForce = ''
    type = ''

    types: Array<{ id: WFVvs, name: string }> = [
        { id: WFVvs.NONE, name: 'Нет всплывания' },
        { id: WFVvs.ROOT, name: 'До организации' },
        { id: WFVvs.CENTER, name: 'До центра' }
    ]

    // *************************************************************
    // COMPUTED
    @Getter('workflow/getWorkflow') getWorkflow!: WorkflowTemplate

    // *************************************************************
    // WATCH
    @Watch('getWorkflow', { immediate: true })
    handleWFChange(val: WorkflowTemplate): void {
        this.airForce = val.vvs
    }

    // *************************************************************
    // METHODS
    @Action('workflow/changeWFVVS')
    changeWFVVS!: (vvs: WFVvs) => void

    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    @Action('workflow/addWFStage')
    addWFStage!: (params: { stage: StageTemplate, position: string }) => Promise<void>

    @Action('workflow/changeWFStageVvs')
    changeWFStageVvs!: (vvs: boolean) => void

    @Action('workflow/changeWFActiveStage')
    changeWFActiveStage!: (activeStage: number | null) => void

    @Action('workflow/removeWFStage')
    removeWFStage!: () => void

    changeAirForce(to: string): void {
        if (to !== this.getWorkflow.vvs) {
            // Если поставили пометку vvs
            if (to !== 'none') {
                // Если есть stages
                if (this.getWorkflow.stages.length && this.getWorkflow.stages[0].type === 'approval') {
                    // Если первый stage идет согласование - то добавляем в него пометку vvs
                    this.changeWFStageVvs(true)
                    // Делаем первый stage активным
                    this.changeWFActiveStage(0)
                } else {
                    // Если массив блоков пуст или первый блок не с согласованием - то создаем новый блок с vvs
                    this.addStageVVs()
                }
            } else {
                // Делаем первый stage активным - это необходимо оставить здесь
                // Если мы запустим удаление, то принципиально, чтобы активным был именно первый блок
                // Т.к. удаляется всегда только активный блок
                this.changeWFActiveStage(0)

                // Пометка vvs могла стоять у нас только в первом блоке согласования
                // Если мы ее сняли, то проверяем:
                // Если первый блок содержит не пустой массив approvals, то просто снимаем пометку
                if (this.getWorkflow.stages[0].approvals.length) {
                    this.changeWFStageVvs(false)
                } else {
                    // Удаляем первый блок
                    this.removeWFStage()

                    // Убираем пометку активного блока
                    this.changeWFActiveStage(null)
                }
            }

            // Меняем показатель vvs в state
            this.changeWFVVS(to as WFVvs)
            // Отмечаем наличие изменений в workFlow
            this.workflowChange(true)
        }
    }

    /** Метод добавления нового блока в начало цепочки с пометкой vvs */
    addStageVVs(): void {
        this.addWFStage({
            stage: new StageTemplate(
                'approval',
                'Согласование',
                [],
                [],
                true
            ),
            position: 'start'
        })
    }
}
