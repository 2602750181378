var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "events"
  }, [_c('div', {
    staticClass: "flex-row-center justify-center events__header"
  }, [_c('div', {
    staticClass: "events__eye",
    attrs: {
      "tooltip": "Весь список событий",
      "flow": "right"
    }
  }, [_c('FAIcon', {
    staticClass: "events__icon",
    attrs: {
      "icon": "eye"
    },
    on: {
      "click": _vm.openEvents
    }
  })], 1), _vm._v(" "), _c('h2', {
    staticClass: "text-center"
  }, [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "events-container"
  }, _vm._l(_vm.events, function (event) {
    return _c('Card', {
      key: event.id,
      staticClass: "events__card",
      attrs: {
        "event": event
      },
      on: {
        "click": function click($event) {
          return _vm.openDocument(event);
        }
      }
    }, [event.document ? _c('div', {
      attrs: {
        "slot": "card-body"
      },
      slot: "card-body"
    }, [_c('div', {
      staticClass: "fs-16 text-wrap"
    }, [_vm._v("\n                    " + _vm._s(event.document.name) + "\n                ")])]) : _vm._e()]);
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }