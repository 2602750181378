var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.report ? _c('div', {
    staticClass: "report"
  }, [_c('div', {
    staticClass: "report__header"
  }, [_c('h2', {
    staticClass: "report__title"
  }, [_vm._v("\n            " + _vm._s(_vm.report.name) + "\n        ")]), _vm._v(" "), _c('ButtonViolet', {
    attrs: {
      "text": "Фильтры",
      "small": true,
      "active": _vm.filters
    },
    on: {
      "click": function click($event) {
        _vm.filters = !_vm.filters;
      }
    }
  }), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "report__update",
    attrs: {
      "text": "Обновить отчет",
      "small": true
    },
    on: {
      "click": _vm.updateReport
    }
  }), _vm._v(" "), _c('div', {
    attrs: {
      "tooltip": "Распечатать отчет",
      "flow": "left"
    },
    on: {
      "click": _vm.print
    }
  }, [_c('FAIcon', {
    staticClass: "report__print",
    attrs: {
      "icon": "print"
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    ref: "table",
    staticClass: "report__wrapper"
  }, [_c(_vm.component, {
    ref: "report",
    tag: "Component",
    attrs: {
      "filters": _vm.filters
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }