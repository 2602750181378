import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { DocumentTypeState } from '@/store/document_type/documentTypeState'
import { service, cuntPB } from 'api'

import { DocumentType } from '@/model/DocumentType'

const RequisiteActions: ActionTree<DocumentTypeState, RootState> = {
    async callAddRequisite({ dispatch }, params: cuntPB.AddDocumentTypeRequisiteReq) {
        const data: cuntPB.DocumentType = await service.documentType.AddRequisite(params)
        console.log('::DATA::DocumentTypeSvc.addRequisite:', data)

        dispatch('putDocumentType', new DocumentType(data))
    },

    async callUpdateTypeRequisite({ dispatch }, params: cuntPB.UpdateDocumentTypeRequisiteReq) {
        const data: cuntPB.DocumentType = await service.documentType.UpdateRequisite(params)
        console.log('::DATA::DocumentTypeSvc.updateRequisite:', data)

        dispatch('putDocumentType', new DocumentType(data))
    },

    async callRemoveRequisite({ dispatch }, params: cuntPB.RemoveDocumentTypeRequisite) {
        const data: cuntPB.DocumentType = await service.documentType.RemoveRequisite(params)
        console.log('::DATA::DocumentTypeSvc.removeRequisite:', data)

        dispatch('putDocumentType', new DocumentType(data))
    },

    async callRearangeRequisites({ dispatch }, params: cuntPB.RearangeRequisitesReq) {
        const data: cuntPB.DocumentType = await service.documentType.RearangeRequisites(params)
        console.log('::DATA::DocumentTypeSvc.rearangeRequisites:', data)

        dispatch('putDocumentType', new DocumentType(data))
    }
}

export default RequisiteActions
