var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "visible": _vm.syncedVisible,
      "title": "Сотрудники"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      },
      "close": _vm.close
    }
  }, [_c('template', {
    slot: "body"
  }, [_c('div', {
    staticClass: "select-users__body"
  }, [_c('div', {
    staticClass: "flex-row-center justify-between p-3"
  }, [_vm.addingUser ? _c('InputBase', {
    staticClass: "select-employees__search select-employees__search_user",
    attrs: {
      "required": "",
      "placeholder": "Введите id пользователя"
    },
    model: {
      value: _vm.newUserId,
      callback: function callback($$v) {
        _vm.newUserId = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "newUserId"
    }
  }) : _c('InputSearch', {
    staticClass: "select-employees__search",
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _vm._v(" "), _vm.addingUser ? _c('ButtonViolet', {
    attrs: {
      "disabled": _vm.newUserId.length !== 20,
      "text": "Добавить нового пользователя"
    },
    on: {
      "click": _vm.getNewUser
    }
  }) : _c('ButtonViolet', {
    attrs: {
      "text": "Добавить нового пользователя"
    },
    on: {
      "click": _vm.addUser
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "select-employees__employees"
  }, _vm._l(_vm.employeesFilter, function (employee) {
    return _c('EmployeeCard', {
      key: employee.id,
      staticClass: "select-employees__card",
      attrs: {
        "unit-user": employee,
        "type": "system"
      },
      on: {
        "click": function click($event) {
          return _vm.changeChosenUser(employee);
        }
      }
    });
  }), 1)])]), _vm._v(" "), _c('template', {
    slot: "footer"
  }, [_vm.chosenUser ? _c('div', {
    staticClass: "select-employees__footer"
  }, [_c('EmployeeCard', {
    attrs: {
      "unit-user": _vm.chosenUser,
      "type": "system"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "select-employees__employee-roles"
  }, _vm._l(_vm.rolesFilterBySelectedUnit, function (role) {
    return _c('Checkbox', {
      key: role.name,
      attrs: {
        "disabled": _vm.chief && role.name === 'Head' && _vm.chief.id !== _vm.chosenUser.id,
        "label": role.displayName
      },
      model: {
        value: role.active,
        callback: function callback($$v) {
          _vm.$set(role, "active", $$v);
        },
        expression: "role.active"
      }
    });
  }), 1), _vm._v(" "), _c('Button', {
    staticClass: "mx-4",
    attrs: {
      "color": "green",
      "disabled": !_vm.grantRevoke.grant.length && !_vm.grantRevoke.revoke.length
    },
    on: {
      "click": _vm.changeRoles
    }
  }, [_vm._v("OK")])], 1) : _vm._e()])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }