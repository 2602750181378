















































import { Component, Vue } from 'vue-property-decorator'

import Dropdown from '@/components/common/Dropdown.vue'

@Component({
    components: { Dropdown }
})

export default class DWF_ApprovalHint extends Vue {
}
