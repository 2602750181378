<template>
    <div class="numerator-field">
        <Select
            v-model="field.type"
            :options="types"
            placeholder="Тип поля"
            class="numerator-field__select"
            :disabled="removeDisabled"
        />
        <div class="numerator-field__field">
            <Select
                v-if="field.type === 'field_type_code'"
                v-model="field.code"
                :options="codes"
                placeholder="Выбрать шифр"
                class="numerator-field__select"
            />
            <InputBase
                v-else-if="field.type === 'field_type_text'"
                v-model="field.text"
                type="text"
                :multi-lines="false"
                placeholder="Разделитель"
                class="numerator-field__text"
            />
            <div
                v-else-if="field.type === 'field_type_incr'"
                class="numerator-field__field_incr"
            >
                <InputNumber
                    v-model="field.pad"
                    :scale="false"
                />

                <Select
                    v-model="field.resetPeriod"
                    :options="periods"
                    placeholder="Периодичность обнуления"
                    class="numerator-field__select"
                />
            </div>
        </div>

        <div
            class="pink-1"
            @click="moveUp"
        >
            <FAIcon
                icon="arrow-up"
                class="numerator-field__icon"
                :class="{ 'numerator-field__icon_disabled': firstField || movingDisabled }"
            />
        </div>
        <div
            class="pink-1"
            @click="moveDown"
        >
            <FAIcon
                icon="arrow-down"
                class="numerator-field__icon"
                :class="{ 'numerator-field__icon_disabled': lastField || movingDisabled }"
            />
        </div>

        <ButtonDelete
            tooltip="Удалить поле"
            flow="left"
            :disabled="removeDisabled"
            @click="remove"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { NUMERATOR_FIELD_TYPES, NUMERATOR_CODE_TYPES, NUMERATOR_RESET_PERIOD, NumeratorField } from '@/model/numerator.js'

import Select from '@/components/common/select/Select'
import InputBase from '@/components/common/inputs/InputBase'
import ButtonDelete from '@/components/common/buttons/ButtonDelete'
import InputNumber from '@/components/common/inputs/InputNumber'

export default {
    name: 'NumeratorRecord',
    components: {
        Select,
        InputBase,
        ButtonDelete,
        InputNumber
    },
    props: {
        fieldInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        numeratorId: {
            type: String,
            default: ''
        },
        firstField: {
            type: Boolean,
            default: false
        },
        lastField: {
            type: Boolean,
            default: false
        },
        movingDisabled: {
            type: Boolean,
            default: false
        },
        removeDisabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            field: {
                id: '',
                type: '',
                code: '',
                text: '',
                pad: '',
                resetPeriod: ''
            }
        }
    },
    computed: {
        types() {
            let types = []
            for (let i in NUMERATOR_FIELD_TYPES) {
                types.push({
                    id: i,
                    name: NUMERATOR_FIELD_TYPES[i]
                })
            }
            return types
        },
        codes() {
            let codes = []
            for (let i in NUMERATOR_CODE_TYPES) {
                codes.push({
                    id: i,
                    name: NUMERATOR_CODE_TYPES[i]
                })
            }
            return codes
        },
        periods() {
            let periods = []
            for (let i in NUMERATOR_RESET_PERIOD) {
                periods.push({
                    id: i,
                    name: NUMERATOR_RESET_PERIOD[i]
                })
            }
            return periods
        }
    },
    watch: {
        fieldInfo: {
            immediate: true,
            deep: true,
            handler(to) {
                if (to) {
                    this.field.id = to.id
                    this.field.type = to.type
                    this.field.code = to.code
                    this.field.text = to.text
                    this.field.pad = to.pad
                    this.field.resetPeriod = to.resetPeriod
                }
            }
        },
        field: {
            deep: true,
            handler(to) {
                if (!this.numeratorId) {
                    // Создание нового нумератора
                    this.$emit('change', to)
                } else {
                    switch (to.type) {
                    case 'field_type_code':
                        if (to.code && this.fieldInfo.code !== to.code) {
                            this.fieldSet(new NumeratorField(
                                to.id,
                                to.type,
                                to.code,
                                '', '', ''
                            ))
                        }
                        break
                    case 'field_type_text':
                        if (to.text.trim() && this.fieldInfo.text.trim() !== to.text.trim()) {
                            clearTimeout(this._timerText)
                            this._timerText = setTimeout(() => {
                                this.fieldSet(new NumeratorField(
                                    to.id,
                                    to.type,
                                    '',
                                    to.text,
                                    '', ''
                                ))
                            }, 1000)
                        }
                        break
                    case 'field_type_incr':
                        if (to.resetPeriod && (to.resetPeriod !== this.fieldInfo.resetPeriod || to.pad !== this.fieldInfo.pad)) {
                            clearTimeout(this._timerIncr)
                            this._timerIncr = setTimeout(() => {
                                this.fieldSet(new NumeratorField(
                                    to.id,
                                    to.type,
                                    '', '',
                                    to.pad,
                                    to.resetPeriod
                                ))
                            }, 1000)
                        }
                        break
                    default:
                        console.error('WATCH::field - unknown type', to)
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions('numerator', [
            'setField',
            'removeField'
        ]),
        moveDown() {
            if (this.movingDisabled) {
                this.$snotify.error('Сначала заполните все поля', {
                    timeout: 0
                })
                return
            }
            if (this.lastField) {
                return
            }
            this.$emit('moveDown')
        },
        moveUp() {
            if (this.movingDisabled) {
                this.$snotify.error('Сначала заполните все поля', {
                    timeout: 0
                })
                return
            }
            if (this.firstField) {
                return
            }
            this.$emit('moveUp')
        },
        fieldSet() {
            this.setField({
                numeratorId: this.numeratorId,
                field: this.field
            })
        },
        remove() {
            if (this.removeDisabled) {
                this.$snotify.error('Нельзя удалять единственное поле с типом Номер', {
                    timeout: 0
                })
                return
            }

            if (!this.numeratorId) {
                this.$emit('remove')
                return
            }

            this.removeField({
                numeratorId: this.numeratorId,
                fieldId: this.field.id
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.numerator-field
    display grid
    grid-template-columns 150px 1fr 20px 20px 20px
    grid-column-gap 10px
    align-items center
    height 48px

.numerator-field__field_incr
    display grid
    grid-template-columns 1fr 1fr
    grid-column-gap 10px
    height 100%

.numerator-field__field
    height 100%

.numerator-field__select
    height 100%

.numerator-field__text
    height 100%

.numerator-field__icon
      display block
      height 20px
      width 20px
      cursor pointer
      &.numerator-field__icon_disabled
          color #ccc
          cursor default

</style>
