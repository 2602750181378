var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "card",
    class: {
      'card_active': _vm.active
    }
  }, _vm.$listeners), [_vm._t("card-body")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }