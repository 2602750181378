<template>
    <div
        class="el-popover"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @click="handleClick"
    >
        <div
            ref="popover"
            class="popover"
            @click.stop
        >
            <slot
                name="popover"
            />
            <div
                v-if="arrow"
                ref="arrow"
                :class="{'popover-arrow': true, 'arrow-top': popoverPosition === 'top', 'arrow-bottom': popoverPosition === 'bottom'}"
            />
        </div>
        <slot
            ref="host"
            name="host"
        />
    </div>
</template>
<script>
export default {
    props: {
        // Сдвиг по оси Y относительно родителя
        shiftYpopover: {
            type: Number,
            default: 0
        },
        arrow: {
            // Показывать хвостик popover или нет
            type: Boolean,
            default: true
        },
        toggle: {
            type: Boolean,
            default: false
        },
        popoverPosition: {
            type: String,
            default: 'top'
        }
    },
    data: () => {
        return {
            showModal: false
        }
    },
    watch: {
        showModal: {
            // immediate: true,
            handler(to) {
                if (to) {
                    this.showPopover()
                } else {
                    this.hidePopover()
                }
            }
        }
    },
    methods: {
        handleClick() {
            if (!this.toggle) {
                return
            }
            this.showModal = !this.showModal
        },
        handleMouseEnter() {
            if (this.toggle) {
                return
            }
            this.showPopover()
        },
        handleMouseLeave() {
            if (this.toggle) {
                return
            }
            this.hidePopover()
        },
        showPopover() {
            let popover = this.$refs.popover
            let host = this.$slots.host[0].elm
            let hostWidth = this.$slots.host[0].elm.offsetWidth
            let hostHeight = this.$slots.host[0].elm.clientHeight
            let arrow = this.$refs.arrow
            // Вычисляем координаты top у slot 'host'. для того чтобы привязать к ним popover. Это необходимо, т.к. иначе при position fixed
            // Popover продолжает позиционироваться относительно окна и не учитывает overflow, т.е. при прокрутке будет оставаться на изначальном месте
            // А так при каждом вызове метода мы сначала позиционируем popover по вертикали, а только потом смещаем его к центру элемента
            let HostY = host.getBoundingClientRect().y
            // Тоже самое вычисляем для оси Х
            let HostX = host.getBoundingClientRect().x
            // Сдвиг 'popover' на середины блока при наличии overflow
            if (popover) {
                popover.style.top = HostY + 'px'
                popover.style.left = HostX + 'px'
                // При наведении появляется 'Popover'
                popover.classList.add('block-popover')
                // Вычисляем координаты popover
                let popoverX = popover.getBoundingClientRect().x
                let popoverY = popover.getBoundingClientRect().y
                // Отступ у хвостика 'popover' высчитывается в зависимости от ширины 'popover'
                if (this.arrow) {
                    arrow.style.left =
                        (popover.offsetWidth - arrow.offsetWidth) / 2 + 'px'
                }
                // Округляем значение x для transform до целого числа, чтобы избежать размытия - для этого делим на 2
                let xTransPopover = Math.round(
                    (popover.offsetWidth - hostWidth) / 2
                )
                xTransPopover *= -1

                // Делаем позиционирование popover
                // 2 варианта: если popover используется просто сам по себе, то
                // В данном случае координаты двух слотов: popover (popoverX)  и координаты host (HostX) будут совпадать
                // Если компонент Popover используется сам в себе, то координаты слотов не совпадают
                // Тогда необходимо вложенный экземпляр компонента сдвинуть на координаты родительского -
                // Для этого вводит дополнение: popoverX - HostX || popoverY - HostY

                // popover.clientHeight - this.shiftYpopover - это сдвигает popover относитель host по вертикали на заданное в props значение
                // т.е. мы сначала поднимаем popover над элементом, а затем сдвигаем его на заданное значение вниз
                // Приводим transformX к отрицательному числу, чтобы независимо от того, кто больше
                // popover или host - шло правильное позиционирование
                let transformY
                if (this.popoverPosition === 'top') {
                    transformY = popover.clientHeight - this.shiftYpopover + popoverY - HostY
                    if (transformY > 0) {
                        transformY *= -1
                    }
                } else {
                    // this.popoverPosition === 'bottom'
                    transformY = hostHeight + this.shiftYpopover + popoverY - HostY
                }
                popover.style.transform =
                    'translate(' +
                    (xTransPopover + popoverX - HostX) +
                    'px, ' + transformY + 'px'
            }

        },
        hidePopover() {
            let popover = this.$refs.popover
            if (popover) {
                popover.classList.remove('block-popover')
                // Возвращаем popover в изначальное позиционирование для чистоты расчетов при наведении
                popover.style.transform = 'translate(0px, 0px)'
                // Прокидываем событие, чтобы знать, когда Popover закрывается
                // Это, например, используется в компоненте 'File' для того, чтобы скрывать автоматические блоки Popover
                // и приводить все в первоначальный вид
                this.$emit('mouse-leave')
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.el-popover
    position relative

.popover
    position fixed
    display none
    margin-bottom 12px
    border-radius 4px
    border 1px solid #ebeef5
    box-shadow 0 2px 12px 0 rgba(0,0,0,.1)
    transform-origin center bottom 0px
    z-index 10
    background #fff
    color #606266
    line-height 1.4
    text-align justify
    font-size 14px

.popover-arrow
    position absolute
    display block
    margin-right 3px
    border-style solid
    border-color transparent
    border-width 8px
    width 0
    height 0
    filter drop-shadow(0 2px 12px rgba(0,0,0,.03))

.arrow-top
    bottom -5px
    border-top-color #fff
    border-bottom-width 0

.arrow-bottom
    top -5px
    border-top-width 0
    border-bottom-color #fff

.block-popover
    display block
</style>
