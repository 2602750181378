import Vue from 'vue'
import { MutationTree } from 'vuex'
import { WorkflowState } from '@/store/workflow'

import { TaskTemplate } from '@/model/Workflow'

const TaskMutations: MutationTree<WorkflowState> = {
    addWFTask: (state: WorkflowState, task: TaskTemplate) => {
        const activeStageIndex = state.workflow.activeStage
        if (activeStageIndex === null) {
            console.error('addWFTask:: mutation:: active stage not found')
        } else {
            state.workflow.stages[activeStageIndex].tasks.push(task)
        }
        console.log('state', state.workflow)
    },

    changeWFTask(state: WorkflowState, task: TaskTemplate) {
        const index = state.workflow.activeStage
        if (index !== null) {
            const taskIndex = state.workflow.stages[index].tasks.findIndex((t: TaskTemplate) => t.id === task.id)
            Vue.set(state.workflow.stages[index].tasks, taskIndex, task)
        }
    },

    removeWFTask(state: WorkflowState, taskIndex: number) {
        if (state.workflow.activeStage !== null) {
            state.workflow.stages[state.workflow.activeStage].tasks.splice(taskIndex, 1)
        }
    }
}

export default TaskMutations
