var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', _vm._g({
    class: "button button_color_".concat(_vm.color, " button_size_").concat(_vm.size),
    attrs: {
      "disabled": _vm.buttonDisabled,
      "defaultSize": _vm.defaultSize,
      "reverse": _vm.reverse,
      "active": _vm.active,
      "type": _vm.type
    }
  }, _vm.$listeners), [_c('span', {
    staticClass: "flex-row-center justify-center w-100"
  }, [_vm.loading ? _c('Spinner') : _vm._e(), _vm._v(" "), !_vm.loading ? _vm._t("icon") : _vm._e(), _vm._v(" "), !_vm.loading ? _vm._t("default") : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }