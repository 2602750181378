var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "familiarization-documents",
    attrs: {
      "visible": _vm.visibleModal,
      "title": "Добавление документов"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      }
    }
  }, [_c('div', {
    staticClass: "familiarization-documents__body",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('div', {
    staticClass: "flex-row-center my-3"
  }, [_c('Select', {
    staticClass: "h-100 min-width-200",
    attrs: {
      "options": _vm.types,
      "placeholder": "Выберите тип документа"
    },
    model: {
      value: _vm.documentType,
      callback: function callback($$v) {
        _vm.documentType = $$v;
      },
      expression: "documentType"
    }
  }), _vm._v(" "), _c('InputSearch', {
    staticClass: "familiarization-documents__filter",
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1), _vm._v(" "), _vm._l(_vm.value, function (link) {
    return _c('div', {
      key: link.id,
      staticClass: "flex-row-center mb-3"
    }, [_c('div', {
      staticClass: "flex-1"
    }, [_vm._v("\n                " + _vm._s(link.name) + "\n            ")]), _vm._v(" "), _c('ButtonDelete', {
      staticClass: "ml-5",
      on: {
        "click": function click($event) {
          return _vm.remove(link.id);
        }
      }
    })], 1);
  }), _vm._v(" "), _c('table', {
    staticClass: "table mt-2"
  }, _vm._l(_vm.documents, function (doc) {
    return _c('tr', {
      key: doc.base.id,
      staticClass: "familiarization-documents__document",
      on: {
        "click": function click($event) {
          return _vm.addDocument(doc.base);
        }
      }
    }, [_c('td', [_vm._v(" " + _vm._s(doc.base.name))])]);
  }), 0)], 2), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center p-3",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "disabled": _vm.disabledGetArchivedDocuments,
      "color": "blue",
      "reverse": ""
    },
    on: {
      "click": _vm.getArchived
    }
  }, [_vm._v("\n            " + _vm._s(_vm.getArchivedDocumentsReceived[_vm.documentType] ? 'Архивные документы получены' : 'Получить архивные документы') + "\n        ")]), _vm._v(" "), _c('Button', {
    staticClass: "ml-auto",
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("\n            Сохранить\n        ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }