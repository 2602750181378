import Vue from 'vue'
import { MutationTree } from 'vuex'
import { DocumentState } from '@/store/document/documentState'

import { Document } from '@/model/Document'

const DocumentMutations: MutationTree<DocumentState> = {
    putDocument(state: DocumentState, document: Document) {
        state.documents.push(document)

        if (!document.base.modificationTime) {
            console.log('putDocument', document.base.modificationTime)
        }

        Vue.set(state.documentsModificationTime, document.base.id, document.base.modificationTime)
        Vue.set(state.documentsMap, document.base.id, document)
    },

    putDocuments(state: DocumentState, documents: Array<Document>) {
        state.documents.push(...documents)

        documents.forEach(document => {
            Vue.set(state.documentsModificationTime, document.base.id, document.base.modificationTime)
            Vue.set(state.documentsMap, document.base.id, document)
        })
    },

    replaceDocument(state: DocumentState, document: Document) {
        const index = state.documents.findIndex((d: Document) => d.base.id === document.base.id)
        if (index === -1) {
            console.warn('ReplaceDocument - not found document')
        } else {
            Vue.set(state.documents, index, document)

            state.documentsModificationTime[document.base.id] = document.base.modificationTime
            Vue.set(state.documentsMap, document.base.id, document)
        }
    },

    removeDocument(state: DocumentState, documentId: string) {
        const index = state.documents.findIndex((d: Document) => d.base.id === documentId)
        if (index === -1) {
            console.warn('Ошибка при удалении документа - документа с таким id не существует')
        } else {
            state.documents.splice(index, 1)
            Vue.delete(state.documentsModificationTime, documentId)
            Vue.delete(state.documentsMap, documentId)
        }
    }
}

export default DocumentMutations
