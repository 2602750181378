var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table__tr"
  }, [_c('td', {
    staticClass: "c-default",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('Checkbox', {
    attrs: {
      "checked": _vm.selected,
      "disabled": !_vm.document.myPendingApproval
    },
    on: {
      "change": function change($event) {
        return _vm.emitSelectDocument($event);
      }
    }
  })], 1), _vm._v(" "), !_vm.documentHiddenCells.includes('number') ? _c('td', [_c('div', {
    staticClass: "document-line__cell"
  }, [_vm._v("\n            " + _vm._s(_vm.document.base.number) + "\n        ")])]) : _vm._e(), _vm._v(" "), !_vm.documentHiddenCells.includes('organisation') ? _c('td', [_c('div', {
    staticClass: "document-line__cell"
  }, [_vm._v("\n            " + _vm._s((_vm.getUnitById(_vm.document.base.unit) || {}).name) + "\n        ")])]) : _vm._e(), _vm._v(" "), !_vm.documentHiddenCells.includes('name') ? _c('td', [_c('Popover', {
    attrs: {
      "arrow": true
    }
  }, [_c('div', {
    staticClass: "document-line__cell",
    attrs: {
      "slot": "host"
    },
    slot: "host"
  }, [_vm._v("\n                " + _vm._s(_vm.document.base.name) + "\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "document-line__popover",
    attrs: {
      "slot": "popover"
    },
    slot: "popover"
  }, [_vm._v("\n                " + _vm._s(_vm.document.base.name) + "\n            ")])])], 1) : _vm._e(), _vm._v(" "), !_vm.documentHiddenCells.includes('author') ? _c('td', [_c('div', {
    staticClass: "document-line__cell"
  }, [_vm._v("\n            " + _vm._s(_vm.document.base.author.name.shortName) + "\n        ")])]) : _vm._e(), _vm._v(" "), !_vm.documentHiddenCells.includes('status') ? _c('td', [_c('div', {
    staticClass: "document-line__cell"
  }, [_vm._v("\n            " + _vm._s(_vm.document.base.statusName) + "\n        ")])]) : _vm._e(), _vm._v(" "), !_vm.documentHiddenCells.includes('creationTime') ? _c('td', [_c('div', {
    staticClass: "document-line__cell"
  }, [_vm._v("\n            " + _vm._s(_vm.document.base.creationTimeLocalizedShortString) + "\n        ")])]) : _vm._e(), _vm._v(" "), !_vm.documentHiddenCells.includes('actors') ? _c('td', [_vm.document.base.status === 'approval' ? _c('div', {
    staticClass: "document-line__cell"
  }, _vm._l(_vm.document.pendingApprovals, function (node) {
    return _c('div', {
      key: node.id
    }, [_vm._v("\n                " + _vm._s(node.actor.user.name.shortName) + "\n            ")]);
  }), 0) : _c('div', {
    staticClass: "document-line__cell"
  }, _vm._l(_vm.document.pendingTasks, function (node) {
    return _c('div', {
      key: node.id
    }, [_vm._v("\n                " + _vm._s(node.actor.user.name.shortName) + "\n            ")]);
  }), 0)]) : _vm._e(), _vm._v(" "), _vm._l(_vm.requisites, function (requisite) {
    return _c('RequisiteCell', {
      key: requisite.id,
      attrs: {
        "requisite": requisite,
        "document-requisite": _vm.document.requisites.find(function (r) {
          return r.type.id === requisite.id;
        })
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }