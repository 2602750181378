import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store'

import { WorkflowTemplate, WFVvs } from '@/model/Workflow'

import WorkflowGetters from '@/store/workflow/_getters'

import FlowMutations from '@/store/workflow/_mutations/workflow'
import TaskMutations from '@/store/workflow/_mutations/task'
import GraphMutations from '@/store/workflow/_mutations/graph'

import FlowActions from '@/store/workflow/_actions/workflow'
import TaskActions from '@/store/workflow/_actions/task'
import GraphActions from '@/store/workflow/_actions/graph'

import _actions_callGetWorkflowTemplate from './_actions/callGetWorkflowTemplate'
import _actions_callSetWorkflowTemplate from './_actions/callSetWorkflowTemplate'

export class WorkflowState {
    workflow: WorkflowTemplate = new WorkflowTemplate(
        false,
        WFVvs.NONE,
        null,
        '',
        '',
        '',
        false,
        []
    )
}

const WorkflowMutations: MutationTree<WorkflowState> = {
    ...FlowMutations,
    ...TaskMutations,
    ...GraphMutations
}

const WorkflowActions: ActionTree<WorkflowState, RootState> = {
    ...FlowActions,
    ...TaskActions,
    ...GraphActions,

    callGetWorkflowTemplate: _actions_callGetWorkflowTemplate,
    callSetWorkflowTemplate: _actions_callSetWorkflowTemplate
}

const WorkflowSubModule = {
    namespaced: true,
    state: new WorkflowState(),
    getters: WorkflowGetters,
    mutations: WorkflowMutations,
    actions: WorkflowActions
}

export default WorkflowSubModule
