var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "select-units",
    attrs: {
      "visible": _vm.syncedVisible,
      "title": _vm.title
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      },
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "select-units__body",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('div', {
    staticClass: "p-3"
  }, [_c('nav', {
    staticClass: "select-units__header_nav-container"
  }, [_c('div', {
    class: {
      active: _vm.currentTab === 'search',
      'not-active': _vm.currentTab !== 'search'
    },
    on: {
      "click": function click($event) {
        _vm.currentTab = 'search';
      }
    }
  }, [_vm._v("\n                    Поиск подразделений\n                ")]), _vm._v(" "), _c('div', {
    class: {
      active: _vm.currentTab === 'create',
      'not-active': _vm.currentTab !== 'create'
    },
    on: {
      "click": function click($event) {
        _vm.currentTab = 'create';
      }
    }
  }, [_vm._v("\n                    Создать\n                ")])])]), _vm._v(" "), _c('SearchUnit', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentTab === 'search',
      expression: "currentTab === 'search'"
    }],
    attrs: {
      "unit-id": _vm.unitId
    },
    on: {
      "goToCreate": function goToCreate($event) {
        _vm.currentTab = 'create';
      },
      "moved": _vm.close
    }
  }), _vm._v(" "), _c('InputWithLabel', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentTab === 'create',
      expression: "currentTab === 'create'"
    }],
    staticClass: "mx-3",
    attrs: {
      "label": "Имя подразделения",
      "required": true
    },
    model: {
      value: _vm.unitName,
      callback: function callback($$v) {
        _vm.unitName = $$v;
      },
      expression: "unitName"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center justify-end",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentTab === 'create',
      expression: "currentTab === 'create'"
    }],
    staticClass: "m-4",
    attrs: {
      "disabled": !_vm.unitName,
      "color": "green"
    },
    on: {
      "click": _vm.create
    }
  }, [_vm._v("\n            Создать\n        ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }