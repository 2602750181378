import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { DocumentState } from '@/store/document/documentState'

import { Document } from '@/model/Document'

const DocumentGetters: GetterTree<DocumentState, RootState> = {
    getDocuments: (state: DocumentState): Array<Document> => state.documents,

    getDocumentById: (state: DocumentState) => (documentId: string): Document | null => state.documentsMap[documentId] ?? null,

    getDocumentsByType: (state: DocumentState) => (typeId: string): Array<Document> => state.documents.filter(d => d.base.type === typeId),

    getActiveDocumentsByType: (state: DocumentState) => (typeId: string): Array<Document> => state.documents.filter((d: Document) => d.base.type === typeId && (d.myPendingApproval || d.myPendingTasks.length)),

    getDocumentModificationTime: (state: DocumentState) => (documentId: string): number => state.documentsModificationTime[documentId] || 0
}

export default DocumentGetters
