<template>
    <div
        class="item-card"
        v-on="$listeners"
    >
        <ButtonViolet
            :small="true"
            :text="item.name"
            class="item-card__name"
        />
    </div>
</template>

<script>
import ButtonViolet from '@/components/common/buttons/ButtonViolet'

export default {
    components: {
        ButtonViolet
    },
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.item-card
    flex-grow 1

.item-card__name
    width 100%
    height 100%
</style>
