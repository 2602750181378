

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Unit } from '@/model/Unit'

import InputSearch from '@/components/common/inputs/InputSearch.vue'
import UnitsListBlock from '@/components/company_structure/UnitsListBlock.vue'

@Component({
    components: {
        InputSearch,
        UnitsListBlock
    }
})

export default class UnitsList extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) selectedUnit!: string

    // *************************************************************
    // DATA PARAMS
    searchText = ''

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>

    get firstUnits(): Array<Unit> {
        return this.getUnits.filter((unit: Unit) => unit.parent === 'root')
    }

    get filteredUnits(): Array<Unit> {
        return this.getUnits.filter((unit: Unit) => unit.name.toLowerCase().includes(this.searchText.toLowerCase()))
    }
}

