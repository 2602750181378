var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', _vm._g({
    staticClass: "button-add",
    class: {
      'button-add_disabled': _vm.disabled,
      'button-add_medium': _vm.size === 'medium',
      'button-add_big': _vm.size === 'big'
    },
    attrs: {
      "disabled": _vm.disabled
    }
  }, _vm.$listeners), [_vm._v("\n    +\n")]);
}
var staticRenderFns = []

export { render, staticRenderFns }