import { render, staticRenderFns } from "./DocumentEventRejectedCard.vue?vue&type=template&id=a0f98910&scoped=true&"
import script from "./DocumentEventRejectedCard.vue?vue&type=script&lang=ts&"
export * from "./DocumentEventRejectedCard.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentEventRejectedCard.vue?vue&type=style&index=0&id=a0f98910&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0f98910",
  null
  
)

export default component.exports