import { Actor } from '@/model/Actor'

export class ApprovalTemplate {
    public id: string
    public actor: Actor | null
    public specialName: string
    public inputs: Array<string>

    constructor(
        id: string,
        actor: Actor | null,
        specialName: string,
        inputs: Array<string>
    ) {
        this.id = id
        this.actor = actor
        this.specialName = specialName
        this.inputs = inputs
    }
}

export class TaskTemplate {
    public id: string
    public assignee: Actor
    public text: string
    public description: string
    public confirmer: Actor | null
    inputs: Array<string>

    constructor(
        id: string,
        assignee: Actor,
        text: string,
        description: string,
        confirmer: Actor | null,
        inputs: Array<string>
    ) {
        this.id = id
        this.assignee = assignee
        this.text = text
        this.description = description
        this.confirmer = confirmer // кто подтверждает выполнение: user or unit
        this.inputs = inputs
    }
}

export class StageTemplate {
    public type: string
    public displayName: string
    public approvals: Array<ApprovalTemplate>
    public tasks: Array<TaskTemplate>
    public vvs: boolean

    constructor(
        type: string,
        displayName: string,
        approvals: Array<ApprovalTemplate>,
        tasks: Array<TaskTemplate>,
        vvs: boolean
    ) {
        this.type = type // Согласование (approval) или в работу (execution)
        this.displayName = displayName
        this.approvals = approvals
        this.tasks = tasks
        this.vvs = vvs
    }
}

export enum WFVvs {
    NONE = 'none',
    ROOT = 'root',
    CENTER = 'center'
}

export class WorkflowTemplate {
    public staticFlag: boolean
    public vvs: WFVvs
    public activeStage: number | null // index
    public activeNode: string
    public activeTask: string
    public action: string
    public change: boolean
    public stages: Array<StageTemplate>

    constructor(
        staticFlag: boolean,
        vvs: WFVvs,
        activeStage: number | null,
        activeNode: string,
        activeTask: string,
        action: string,
        change: boolean,
        stages: Array<StageTemplate>
    ) {
        this.staticFlag = staticFlag
        this.vvs = vvs
        this.activeStage = activeStage
        this.activeNode = activeNode
        this.activeTask = activeTask
        this.action = action
        this.change = change
        this.stages = stages
    }
}
