


























































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { Attachment } from '@/model/File'

import Popover from '@/components/common/Popover.vue'

@Component<OneFile>({
    components: { Popover }
})

// фантазия закончилась на названии
export default class OneFile extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) file!: Attachment
    @Prop({ default: '' }) documentId!: string
    @Prop({ default: '' }) groupName!: string
    @Prop({ default: '' }) name!: string
    @Prop({ default: false }) canChange!: boolean

    // *************************************************************
    // DATA PARAMS
    version = false

    // *************************************************************
    // COMPUTED
    get icon() {
        switch (this.file.computedType) {
        case 'doc':
            return 'file-word'
        case 'xls':
            return 'file-excel'
        case 'jpg':
            return 'file-image'
        case 'pdf':
            return 'file-pdf'
        case 'zip':
            return require('@/assets/icons/file-types/zip-compressed-files-extension.svg')
        default:
            return require('@/assets/icons/file-types/blank-file.svg')
        }
    }

    // *************************************************************
    // EMIT
    @Emit('replaceFile')
    emitReplaceFile(fileId: string): string {
        return fileId
    }
}
