





















































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { cuntPB } from 'api'

import { User } from '@/model/User'
import { Location } from 'vue-router'
import getError, { GrpcError } from '@/tools/errors/errors'

import Uploader from '@/components/common/Uploader.vue'
import Button from '@/components/common/buttons/Button.vue'
import InputWithLabel from '@/components/common/inputs/InputWithLabel.vue'
import PhoneInput from '@/components/authentication/PhoneInput.vue'
import QR from '@/components/user/QR.vue'
import UserAuthentication1 from '@/components/user/UserAuthentication1.vue'

const namePlaceholders: { [key: string]: string } = {
    first: 'Имя',
    middle: 'Отчество',
    last: 'Фамилия'
}

@Component({
    components: {
        Button,
        Uploader,
        PhoneInput,
        InputWithLabel,
        QR,
        UserAuthentication1
    }
})

export default class UserPage extends Vue {
    @Ref() readonly uploader!: Uploader
    @Ref() readonly dropZone!: HTMLDivElement

    // $refs!: {
    //     uploader: Uploader
    // }
    //
    // *************************************************************
    // DATA PARAMS
    namePlaceholders = namePlaceholders
    name: { last: string, first: string, middle: string} = {
        last: '',
        first: '',
        middle: ''
    }
    email = ''
    phone: { identity: string, valid: boolean } = { identity: '', valid: false }

    loadingName = false
    loadingPhone = false

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    get routeName(): string {
        return this.$route.name ?? ''
    }

    get privacyLink(): Location {
        if (this.routeName === 'me') {
            return { name: 'privacy' }
        }
        return { name: 'module', params: { section: 'about', module: 'privacy' } }
    }

    // *************************************************************
    // WATCH
    @Watch('getMe', { immediate: true, deep: true })
    handleMeChange(to: User): void {
        if (to) {
            this.name.first = to.name.first
            this.name.middle = to.name.middle
            this.name.last = to.name.last
            this.email = to.email
            this.phone.identity = to.phone
        }
    }

    mounted(): void {
        document.addEventListener('drop', function (e) {
            e.preventDefault()
            e.stopPropagation()
        }, false)
        document.addEventListener('dragover', function (e) {
            e.preventDefault()
            e.stopPropagation()
        }, false)

    }

    // *************************************************************
    // METHODS
    @Action('me/callSetPhone')
    callSetPhone!: (params: { id: string, phone: string }) => Promise<void>

    @Action('me/callSetEmail')
    callSetEmail!: (params: { id: string, email: string }) => Promise<void>

    @Action('me/callSetName')
    callSetName!: (params: cuntPB.SetUserNameReq) => Promise<void>

    exit(): void {
        this.$router.push({ name: 'logout' })
    }

    selectFile(): void {
        // При клике по кнопке инициируем клик по input - type === file в компоненте Uploader
        this.uploader.clickInput()
    }

    beforeUpload(files: Array<File>, upload: (formData: FormData, address: string) => void): void {
        // Этот метод через props прокидываем в компонент Uploader для отправки avatar на сервер
        const formData = new FormData()
        formData.append('avatar', files[0])
        const address = `https://kant.themake.rs/api/storage/avatar/upload?id=${this.getMe.id}`

        // Задаем размер === 10мб для последующей проверки размера прикрепляемого файла
        const attach_size = 10 * 1024 * 1024 // 10мб

        if (files[0].size > attach_size) {
            this.$snotify.warning('Слишком большой размер фото. Можно прикреплять фото размером до 10МБ', 'Отклонено!')
        } else {
            upload(formData, address)
        }
    }

    dragEnter(event: DragEvent): void {
        // Убираем автоматическое открытие картинки
        event.preventDefault()
        event.stopPropagation()
        this.dropZone.style.opacity = '0.5'
    }

    dragLeave(event: DragEvent): void {
        // Убираем автоматическое открытие картинки
        event.preventDefault()
        event.stopPropagation()
        this.dropZone.style.opacity = '1'
    }

    drop(event: DragEvent): void {
        event.preventDefault()
        event.stopPropagation()
        this.dropZone.style.opacity = '1'
        // Вызываем метод в компоненте Uploader для отправки avatar
        this.uploader.sendFile(event)
    }

    setPhone(): void {
        if (this.loadingPhone) {
            return
        }

        if (!this.phone.valid) {
            return
        }

        if (this.phone.identity === this.getMe.phone) {
            return
        }

        this.loadingPhone = true

        this.callSetPhone({
            id: this.getMe.id,
            phone: this.phone.identity
        })
            .then(() => {
                this.$snotify.success('Номер телефона сохранен')
                this.loadingPhone = false
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
    }

    setName(): void {
        if (this.loadingName) {
            return
        }

        if (this.name.first === this.getMe.name.first &&
                    this.name.middle === this.getMe.name.middle &&
                    this.name.last === this.getMe.name.last) {
            return
        }

        this.loadingName = true
        this.callSetName({
            ID: this.getMe.id,
            Name: {
                First: this.name.first,
                Middle: this.name.middle,
                Last: this.name.last

            }
        })
            .then(() => {
                this.$snotify.success('Изменения сохранены')
                this.loadingName = false
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
    }

    setEmail(): void {
        if (this.getMe.email === this.email) {
            return
        }

        this.callSetEmail({
            id: this.getMe.id,
            email: this.email
        })
            .then(() => this.$snotify.success('Email сохранен'))
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
    }
}
