<template>
    <div
        class="module"
        :style="{
            width: moduleConfig.width,
            left: moduleConfig.left
        }"
    >
        <Component :is="moduleComponentName" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { moduleConfig } from '@/store/items/model'

import DocumentList from '@/pages/system/DocumentList'

import User from '@/pages/system/UserPage'
import Tasks from '@/pages/system/Tasks'
import Events from '@/pages/system/Events'
import Familiarizations from '@/pages/system/Familiarizations'
import Directories from '@/pages/system/Directories'
import Tosser from '@/pages/system/Tosser'
import DocumentTypes from '@/pages/system/DocumentTypes'
import CompanyStructure from '@/pages/system/CompanyStructure'
import Numerators from '@/pages/system/Numerators'
import Reports from '@/pages/system/Reports'

import Dismissal from '@/pages/human_resources/Dismissal'
import Replacement from '@/pages/human_resources/Replacement'

import News from '@/pages/about_system/News'
import PrivacyPolicy from '@/pages/about_system/PrivacyPolicy'
import HelpDocumentListFilter from '@/pages/about_system/HelpDocumentListFilter'
import HelpDocumentCreation from '@/pages/about_system/HelpDocumentCreation'
import HelpToWorkWithDocument from '@/pages/about_system/HelpToWorkWithDocument'
import HelpTasks from '@/pages/about_system/HelpTasks'
import HelpTosser from '@/pages/about_system/HelpTosser'
import HelpNumerator from '@/pages/about_system/HelpNumerator'
import HelpCompanyStructure from '@/pages/about_system/HelpCompanyStructure'
import HelpDismissal from '@/pages/about_system/HelpDismissal'

import Familiarization from '@/pages/system/Familiarization'
import CommonDocument from '@/pages/system/CommonDocument'
import Numerator from '@/pages/system/Numerator'
import Directory from '@/pages/system/DirectoryPage'
import DocumentTypePage from '@/pages/system/DocumentTypePage'
import Report from '@/pages/system/Report'
import Task from '@/pages/system/TaskPage'

import NotFound from '@/pages/NotFound'

export default {
    name: 'Module',
    components: {
        DocumentList,
        CommonDocument,

        User,
        Tasks,
        Task,
        Events,
        Familiarizations,
        Familiarization,
        Directories,
        Directory,
        Tosser,
        DocumentTypes,
        DocumentTypePage,
        Numerators,
        Numerator,
        CompanyStructure,
        Reports,
        Report,

        Dismissal,
        Replacement,

        PrivacyPolicy,
        News,
        HelpDocumentListFilter,
        HelpDocumentCreation,
        HelpToWorkWithDocument,
        HelpTasks,
        HelpTosser,
        HelpCompanyStructure,
        HelpNumerator,
        HelpDismissal,

        NotFound
    },
    data: function () {
        return {
            moduleConfig: moduleConfig
        }
    },
    computed: {
        ...mapGetters('items', ['getItemByName']),
        ...mapGetters('workflow', ['getWorkflow']),
        ...mapGetters('tosser', [
            'getTosserChange',
            'getUnitId',
            'getDocumentTypeId'
        ]),
        dashItem() {
            return this.getItemByName(this.$route.params.module)
        },
        moduleComponentName() {
            if (this.dashItem) {
                if (this.$route.params.id !== undefined) {
                    return this.dashItem.subComponent
                } else {
                    return this.dashItem.component
                }
            } else {
                return 'NotFound'
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.$route.name === 'document' && this.$route.params.module === 'document-types' && this.getWorkflow.change) {
            // Если есть изменения в конструкторе типа документа
            const answer = window.confirm('Вы хотите уйти? У вас есть несохранённые изменения!')
            if (answer) {
                next()
            } else {
                next(false)
            }
        } else if (this.$route.params.module === 'tosser' && this.getTosserChange) {
            // Если есть несохраненные изменения в тоссере
            const answer = window.confirm('Вы хотите уйти? У вас есть несохранённые изменения!')
            if (answer) {
                // Если уходим со страницы тоссера, то
                // скидываем все значения
                this.changeTosser('', '', false)
                next()
            } else {
                next(false)
                // Добавляем пометку о несохраненных изменениях в тоссере
                // иначе при повторном нажатии вопрос уже не будет показан
                this.setTosserChange(true)
            }
        } else {
            next()
        }

    },
    beforeRouteUpdate(to, from, next) {
        if (from.params.section === 'system' && from.params.module === 'tosser') {
            if (this.getTosserChange) {
                // Если есть несохраненные изменения в тоссере и мы остаемся в тоссере,
                // просто меняем unitId или documentTypeId
                const answer = window.confirm('Вы хотите уйти? У вас есть несохранённые изменения!')
                if (answer) {
                    if (to.params.system === 'section' && to.params.module === 'tosser') {
                        // Если мы остаемся в тоссере, скидываем несохраненные изменения,
                        // но сохраняем информацию о выбранном unitId documentTypeId
                        this.changeTosser(false)
                        next()
                    } else {
                        // Если уходим со страницы тоссера, то
                        // скидываем все значения
                        this.changeTosser(false)
                        next()
                    }
                } else {
                    next(false)
                    // Добавляем пометку о несохраненных изменениях в тоссере
                    // иначе при повторном нажатии вопрос уже не будет показан
                    this.setTosserChange(true)
                }
            } else {
                if (to.params.module !== 'tosser') {
                    // Если уходим со страницы тоссера, то
                    // скидываем все значения
                    this.changeTosser(false)
                }
                next()
            }
        } else {
            next()
        }
    },
    methods: {
        ...mapActions('tosser', ['setTosserChange']),
        changeTosser(tosserChange) {
            this.setTosserChange(tosserChange)

        }
    }
}
</script>

<style lang="stylus" scoped>
.module
    position fixed
    top 15px
    padding 15px
    box-sizing border-box
    height calc(100vh - 30px)
    box-shadow 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
    background-color rgba(255,255,255,1)
    overflow-y auto
</style>
