









































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import Printd from 'printd'
import getError, { GrpcError } from '@/tools/errors/errors'
import { Document, DocumentBase, DocumentEvent } from '@/model/Document'
import { User } from '@/model/User'
import { DocumentType } from '@/model/DocumentType'
import { Unit } from '@/model/Unit'
import { Task } from '@/model/Task'

import Attribute from '@/components/common/Attribute.vue'
import DocumentStatus from '@/components/document/common_document/DocumentStatus.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'
import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import ConfirmModalNew from '@/components/common/modals/ConfirmModalNew.vue'
import RemoveModal from '@/components/common/modals/RemoveModal.vue'
import VueQRCodeComponent from 'vue-qrcode-component'

@Component<DocumentBaseInfo>({
    components: {
        Attribute,
        DocumentStatus,
        ButtonDelete,
        ButtonViolet,
        ConfirmModalNew,
        RemoveModal,
        VueQRCodeComponent
    }
})

export default class DocumentBaseInfo extends Vue {
    @Ref() readonly confirmModal!: ConfirmModalNew
    @Ref() readonly removeModal!: RemoveModal

    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) openDocument!: Document
    @Prop({ default: () => []}) events!: Array<DocumentEvent>
    @Prop({ default: false }) canChangeRequisite!: boolean
    @Prop({ default: false }) oneAss!: boolean

    // *************************************************************
    // DATA PARAMS
    visibleConfirm = false
    visibleRemoveModal = false

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null
    @Getter('task/getByDocument') getByDocument!: (documentId: string) => Array<Task>

    get base(): DocumentBase {
        return this.openDocument.base
    }

    get qrText(): string {
        return `https://kant.themake.rs/documents/${this.typeId}/${this.base.id}`
    }

    get typeId(): string {
        return this.$route.params.module
    }

    get typeName(): string {
        return this.getTypeById(this.typeId)?.name ?? ''
    }

    get unitName(): string {
        const unit = this.getUnitById(this.base.unit)
        return unit?.name ?? 'Имя недоступно для Вас'
    }

    get originUnitName(): string {
        const unit = this.getUnitById(this.base.originUnit)
        return unit?.name ?? 'Имя недоступно для Вас'
    }

    get removeDisable(): { tooltip: string, disabled: boolean } {
        if (this.oneAss) {
            // Если документ создан в 1С - то запрещаем удалять
            return {
                disabled: true,
                tooltip: 'Нельзя удалить 1С документ'
            }
        }

        if ((this.getMe.id === 'bgiagga08k0lpplv62f0' || this.getMe.id === 'bgjhoca08k0j989o72qg') && this.base.status === 'archived') {
            // Если пользователь - Оксана и документ в 'Архиве', то разрешаем удаление
            return {
                disabled: false,
                tooltip: 'Удалить документ'
            }
        }

        if (this.getMe.id !== this.base.author.id) {
            return {
                disabled: true,
                tooltip: 'Только автор может удалить документ'
            }
        }

        if (this.base.status !== 'draft') {
            // Документ можно удалить только в статусе Черновик
            return {
                disabled: true,
                tooltip: 'Запрещено! Была смена статуса'
            }
        }

        // Если хоть раз меняли статус в документе, то запрещаем его удаление
        if (this.events.find((event: DocumentEvent) => event.type === 'status_transition')) {
            return {
                disabled: true,
                tooltip: 'Запрещено! Была смена статуса'
            }
        } else {
            return {
                disabled: false,
                tooltip: 'Удалить документ'
            }
        }
    }

    // *************************************************************
    // METHODS
    @Action('document/callRemove')
    callRemove!: (id: string) => Promise<void>

    printQr(): void {
        const d = new Printd()
        const images = document.getElementsByTagName('img')
        if (images.length) {
            d.print(images[0])
        }
    }

    removeDocument(): void {
        if (this.removeDisable.disabled) {
            return
        }

        // Если в документе есть задачи - то уточняем необходимость удаления
        if (this.getByDocument(this.base.id).length) {
            this.confirmModal.show({
                title: 'Удаление документа',
                text: 'В данном документе есть задачи. Вы действительно хотите его удалить? Все задачи документа также будут удалены!',
                onConfirm: () => this.remove()
            })
        } else {
            this.removeModal.show({
                itemName: this.base.name,
                itemType: 'Документа',
                warning: '',
                callback: this.remove
            })
        }
    }

    remove(): void {
        const cancelPreloaderRemoveDocument = this.$preloader('remove_document', 'Удаление документа')

        this.callRemove(this.base.id)
            .then(() => this.returnToList())
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderRemoveDocument())
    }

    returnToList() {
        this.$router.push({
            name: 'module',
            params: {
                section: 'documents',
                module: this.$route.params.module
            }
        })
    }
}
