var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', _vm._g({
    staticClass: "unit-list-block"
  }, _vm.$listeners), [_vm.showDrop ? _c('div', {
    staticClass: "unit-list-block__drop",
    on: {
      "click": function click($event) {
        _vm.drop = !_vm.drop;
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.drop ? '‒' : '+') + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.showDrop ? _c('div', {
    staticClass: "unit-list-block__arrow-wrapper",
    on: {
      "click": _vm.onClickDrop
    }
  }, [_c('div', {
    staticClass: "unit-list-block__arrow",
    class: {
      'unit-list-block__arrow_bottom': _vm.drop
    }
  })]) : _vm._e(), _vm._v(" "), _vm.unit ? _c('UnitListCard', {
    staticClass: "unit-list-block__card",
    attrs: {
      "unit": _vm.unit
    },
    on: {
      "click": function click($event) {
        return _vm.emitSelectUnit(_vm.unit.id);
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.drop ? _c('ul', {
    staticClass: "unit-list-block__children"
  }, _vm._l(_vm.children, function (u) {
    return _c('UnitsListBlock', {
      key: u.id,
      attrs: {
        "unit-id": u.id,
        "filtered-units": _vm.filteredUnits
      },
      on: {
        "selectUnit": function selectUnit($event) {
          return _vm.emitSelectUnit($event);
        }
      }
    });
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }