













import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Task } from '@/model/Task'
import { Location } from 'vue-router'

import GoBack from '@/components/common/GoBack.vue'
import TaskCardFull from '@/components/tasks/cards/TaskCardFull.vue'

@Component({
    components: {
        GoBack,
        TaskCardFull
    }
})

export default class TaskPage extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('task/getTaskById') getTaskById!: (id: string) => Task | null

    get goBackPath(): Location {
        return {
            name: 'module',
            params: {
                section: 'system',
                module: 'tasks'
            }
        }
    }

    get taskId(): string {
        return this.$route.params.id
    }

    get task(): Task | null {
        return this.getTaskById(this.taskId)
    }

    // *************************************************************
    // WATCH
    @Watch('task')
    handleTaskChange(to: Task | null): void {
        console.log('handleTaskChange----------', to)
        // Если открыта страница - а другой пользователь удалил задачу
        if (!to) {
            this.$router.push(this.goBackPath)
        }
    }
}
