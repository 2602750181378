var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('GoBack', {
    attrs: {
      "text": "Вернуться к списку ознакомлений",
      "link": _vm.goBackPath
    }
  }), _vm._v(" "), _c(_vm.component, {
    tag: "component",
    attrs: {
      "events": _vm.events
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }