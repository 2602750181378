var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseListPage', {
    attrs: {
      "visibleModal": _vm.visibleCreationModal,
      "type": "directory",
      "disabledCreate": _vm.saveDisabled.disabled
    },
    on: {
      "update:visibleModal": function updateVisibleModal($event) {
        _vm.visibleCreationModal = $event;
      },
      "update:visible-modal": function updateVisibleModal($event) {
        _vm.visibleCreationModal = $event;
      },
      "create": _vm.create,
      "changeCenterId": function changeCenterId($event) {
        _vm.searchCenterId = $event;
      }
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }, [_c('template', {
    slot: "list"
  }, _vm._l(_vm.directories, function (dir) {
    return _c('ListCard', {
      key: dir.id,
      attrs: {
        "item": dir
      },
      on: {
        "click": function click($event) {
          return _vm.openDirectory(dir.id);
        }
      }
    }, [dir.archived ? _c('div', {
      attrs: {
        "slot": "info"
      },
      slot: "info"
    }, [_vm._v("\n                Справочник в Архиве\n            ")]) : _vm._e()]);
  }), 1), _vm._v(" "), _c('div', {
    attrs: {
      "slot": "create"
    },
    slot: "create"
  }, [_c('SelectCenterId', {
    staticClass: "my-5 mx-4",
    model: {
      value: _vm.centerId,
      callback: function callback($$v) {
        _vm.centerId = $$v;
      },
      expression: "centerId"
    }
  }), _vm._v(" "), _c('EditDirectory', {
    ref: "editDirectory",
    staticClass: "directories__create",
    attrs: {
      "center-id": _vm.centerId
    },
    on: {
      "saveDisabled": function saveDisabled($event) {
        return _vm.changeSaveDisabled($event);
      },
      "create": function create($event) {
        return _vm.createdDirectory($event);
      }
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }