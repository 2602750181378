








import { Component, Prop, Vue } from 'vue-property-decorator'

import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import { Viewer } from '@toast-ui/vue-editor'

@Component<EditorViewer>({
    components: { Viewer }
})

export default class EditorViewer extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) value!: string
}
