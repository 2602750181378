












































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Document, WorkflowElement, WorkflowStage, WorkflowStageType } from '@/model/Document'
import { User } from '@/model/User'

import DWF_ApprovalHint from '@/components/document/workflow/DWF_ApprovalHint.vue'
import DWF_Draft from '@/components/document/workflow/DWF_Draft.vue'
import ApprovalActions from '@/components/document/workflow/ApprovalActions.vue'
import DWF_ApprovalStage from '@/components/document/workflow/DWF_ApprovalStage.vue'
import DWF_TaskStage from '@/components/document/workflow/DWF_TaskStage.vue'
import DWF_AddTask from '@/components/document/workflow/DWF_AddTask.vue'
import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'

@Component<DWF_Stage>({
    components: {
        DWF_ApprovalHint,
        DWF_Draft,
        ApprovalActions,
        DWF_ApprovalStage,
        DWF_TaskStage,
        DWF_AddTask,
        ButtonViolet
    }
})

export default class DWF_Stage extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) openDocument!: Document
    @Prop({ default: '' }) stageType!: WorkflowStageType
    @Prop({ default: () => null }) stage!: WorkflowStage
    @Prop({ default: false }) active!: boolean // если блок сейчас активен
    @Prop({ default: false }) passed!: boolean
    @Prop({ default: null }) stageIndex!: number | null
    @Prop({ default: 0 }) svgWidth!: number

    // *************************************************************
    // DATA PARAMS
    checkedApprovals = []

    visibleAddTaskModal = false

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible
    @Getter('document/getDocumentById') getDocumentById!: (documentId: string) => Document | null

    get showActionTask(): boolean {
        const stages = this.openDocument.workflow.stages

        const fluentIndex = this.openDocument.workflow.stages.findIndex(s => s.type === 'fluent')
        if (fluentIndex === -1) {
            return false
        }

        const fluent = stages[fluentIndex]
        if (fluent.graph.length) {
            // Если сейчас согласование стоит на даном пользователе, то показываем ему возможность добавить задачу
            return fluent.graph.some((e: WorkflowElement) => e.actor && e.actor.user?.id === this.getMe.id && e.pending && !e.outputs.length)
        }

        if (fluentIndex === 0) {
            // Если это первый блок согласования, то показываем возможность добавления первой задачи
            return true
        }


        const previousStage = stages[fluentIndex - 1]
        if (!previousStage) {
            console.error('Не найден previousStage in WorkflowStage')
            return false
        }

        const lastElement = previousStage.graph.find((e: WorkflowElement) => !e.outputs.length)
        // Если у последнего элемента есть actor
        if (lastElement && lastElement.actor?.user && lastElement.actor.user.id === this.getMe.id && lastElement.pending) {
            return true
        }

        return false
    }

    get showAddTask(): boolean {
        return this.stageType === 'fluent' && this.showActionTask
    }

    // *************************************************************
    // METHODS
    addTask(): void {
        if (!this.showAddTask) {
            return
        }

        this.visibleAddTaskModal = true
    }
}
