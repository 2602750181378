












import { Component, Prop, Vue } from 'vue-property-decorator'

import Button from '@/components/common/buttons/Button.vue'

@Component({
    components: { Button }
})

export default class ButtonViolet extends Vue {
    @Prop({ default: '' }) text!: string
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: false }) small!: boolean
    @Prop({ default: false }) active!: boolean
    @Prop({ default: false }) loading!: boolean

    get size(): string {
        return this.small ? 'small' : ''
    }
}
