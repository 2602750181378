var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-row-center justify-center w-100 h-100vh"
  }, [_c('Button', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.showUsers
    }
  }, [_vm._v("Войти как")]), _vm._v(" "), _c('SelectUser', {
    attrs: {
      "visible": _vm.visibleUsersModal,
      "users": _vm.users
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleUsersModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }