









































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { WorkflowElement } from '@/model/Document'
import { User } from '@/model/User'
import { Unit } from '@/model/Unit'

import Modal from '@/components/common/modals/Modal.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard.vue'
import Button from '@/components/common/buttons/Button.vue'
import TextareaNew from '@/components/common/inputs/TextareaNew.vue'

@Component({
    components: {
        Modal,
        InputBase,
        RadioButton,
        ActionSelectCard,
        Button,
        TextareaNew
    }
})

export default class DWF_AddTask extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean
    @Prop({ default: () => []}) graph!: Array<WorkflowElement>

    // *************************************************************
    // DATA PARAMS
    text = ''
    assigneeType = 'user'
    assignee = ''
    confirmerType: 'user' | 'unit' | '' = ''
    confirmer = ''
    description = ''

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUsers') getUsers!: Array<User>
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>

    get saveDisabledReason(): string {
        if (!this.text) {
            return 'Необходимо ввести название задачи!'
        }

        if (!this.assignee) {
            return 'Необходимо выбрать исполнителя задачи!'
        }

        if (this.confirmerType && !this.confirmer) {
            return 'Укажите подтверждающего!'
        }

        return ''
    }

    // *************************************************************
    // METHODS
    @Action('document/callAddWorkflowTask')
    callAddWorkflowTask!: (params: cuntPB.AddWorkflowTaskReq) => Promise<void>

    hide(): void {
        // Закрываем окно Modal
        this.syncedVisible = false
        this.text = ''
        this.assigneeType = 'user'
        this.assignee = ''
        this.confirmerType = ''
        this.confirmer = ''
        this.description = ''
    }

    selectAssignee(entityId: string): void {
        this.assignee = entityId
    }

    selectConfirmer(entityId: string): void {
        this.confirmer = entityId
    }

    save(): void {
        if (!!this.saveDisabledReason) {
            return
        }

        const cancelPreloaderAddWorkflowTask = this.$preloader('create_workflow_task', 'Добавление задачи')

        const assignee: cuntPB.ActorIDs = {
            UserID: this.assigneeType === 'user' ? this.assignee : '',
            UnitID: this.assigneeType === 'unit' ? this.assignee : ''
        }

        const confirmer: cuntPB.ActorIDs = {
            UserID: this.confirmerType === 'user' ? this.confirmer : '',
            UnitID: this.confirmerType === 'unit' ? this.confirmer : ''
        }

        this.callAddWorkflowTask({
            Assignee: assignee,
            Text: this.text,
            Description: this.description,
            Confirmer: confirmer,
            Document: this.$route.params.id
        })
            .then(() => {
                this.hide()
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderAddWorkflowTask())
    }
}
