import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'

////////////////////////////////////////////////////////////////
////          //////////////////////////////////////////////////
////  Getters //////////////////////////////////////////////////
////          //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import _getters_getFamiliarizationById from './_getters/getFamiliarizationById'
import _getters_getUserFamiliarization from './_getters/getUserFamiliarization'

////////////////////////////////////////////////////////////////
////              //////////////////////////////////////////////
////  Mutations   //////////////////////////////////////////////
////              //////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import _mutations_putFamiliarization from './_mutations/putFamiliarization'
import _mutations_replaceFamiliarization from './_mutations/replaceFamiliarization'
import _mutations_removeFamiliarization from './_mutations/removeFamiliarization'

////////////////////////////////////////////////////////////////
////            ////////////////////////////////////////////////
////  Actions   ////////////////////////////////////////////////
////            ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
import _actions_callArchivedListStream from './_actions/callArchivedListStream'
import _actions_callCreate from './_actions/callCreate'
import _actions_callUpdate from './_actions/callUpdate'
import _actions_callFamiliarize from './_actions/callFamiliarize'
import _actions_callActive from './_actions/callActive'
import _actions_callDraft from './_actions/callDraft'
import _actions_callArchived from './_actions/callArchive'
import _actions_callAddMembers from './_actions/callAddMembers'

import _actions_putFamiliarization from './_actions/putFamiliarization'
import _actions_removeFamiliarization from './_actions/removeFamiliarization'

export class FamiliarizationState {
    familiarizations = []
    familiarizationsObject = {} // id:familiarization
}

const getters: GetterTree<FamiliarizationState, RootState> = {
    getFamiliarizationById: _getters_getFamiliarizationById,
    getUserFamiliarization: _getters_getUserFamiliarization,
}

const mutations: MutationTree<FamiliarizationState> = {
    putFamiliarization: _mutations_putFamiliarization,
    replaceFamiliarization: _mutations_replaceFamiliarization,
    removeFamiliarization: _mutations_removeFamiliarization,

    cleanUpState(state: FamiliarizationState) {
        state.familiarizations = []
        state.familiarizationsObject = {}
    }
}

const actions: ActionTree<FamiliarizationState, RootState> = {
    callArchivedListStream: _actions_callArchivedListStream,
    callCreate: _actions_callCreate,
    callUpdate: _actions_callUpdate,
    callFamiliarize: _actions_callFamiliarize,
    callActive: _actions_callActive,
    callDraft: _actions_callDraft,
    callArchived: _actions_callArchived,
    callAddMembers: _actions_callAddMembers,

    putFamiliarization: _actions_putFamiliarization,
    removeFamiliarization: _actions_removeFamiliarization,

    cleanUpState({ commit }) {
        commit('cleanUpState')
    }
}

const FamiliarizationSubModule = {
    namespaced: true,
    state: new FamiliarizationState(),
    getters,
    mutations,
    actions
}

export default FamiliarizationSubModule
