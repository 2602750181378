<template>
    <div>
        <div class="work__version">
            Версия от 22 ноября 2022г.
        </div>
        <Transition name="routerTransitionRoot">
            <RouterView />
        </Transition>
        <SideBar />
        <InfoModal />
        <ScalePreloader />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Vue from 'vue'

import SideBar from '@/components/sidebar/SideBar'
import InfoModal from '@/components/about_system/InfoModal'
import ScalePreloader from '@/components/ScalePreloader'

export default {
    name: 'Workspace',
    components: {
        SideBar,
        InfoModal,
        ScalePreloader
    },
    watch: {
        // передаем имя роутера для использование в конечном автомате
        $route: {
            immediate: true,
            handler(to) {
                switch (to.name) {
                case 'root':
                    this.goTo('root')
                    break
                case 'section':
                    this.goTo('section')
                    break
                case 'module':
                    this.goTo('module')
                    break
                case 'document':
                    this.goTo('document')
                    break
                default:
                    console.error('$route - unknown name', to)
                }
            }
        }
    },
    // gotoParams:
    // this code belongs here (not in watch)
    // change keys && transition first
    // then change newState
    beforeMount() {
        this.$router.beforeEach((to, from, next) => {
            // section -> section - change transition key (vertical transition)
            if (
                from !== undefined &&
                to.name === 'section' &&
                from.name === 'section' &&
                to.params.section !== from.params.section
            ) {
                this.gotoParams({
                    configuration: to.name,
                    from: from.params.section,
                    to: to.params.section
                })
                this.$store.commit('items/resetRootRouterViewKey', {})
            } else if (
                // module -> module - change transition key (vertical transition)
                from !== undefined &&
                to.name === 'module' &&
                from.name === 'module' &&
                to.params.module !== from.params.module
            ) {
                this.gotoParams({
                    configuration: to.name,
                    from: from.params.module,
                    to: to.params.module
                })
                this.$store.commit('items/resetSectionRouterViewKey', {})
            } else if (
                // document -> document - change transition key (horizontal transition)
                from !== undefined &&
                to.name === 'document' &&
                from.name === 'document'
            ) {
                this.$store.commit('items/setDefaultTransition', {})
                this.$store.commit('items/resetSectionRouterViewKey', {})
            } else if (
                // module -> document
                from !== undefined &&
                to.name === 'document' &&
                from.name === 'module' &&
                to.params.id !== undefined
            ) {
                this.$store.commit('items/transitionModule', 'transitionModuleEnter')
                this.$store.commit('items/resetSectionRouterViewKey', {})
            } else if (
                // document -> module - subComponent go right,
            // Module remains in place (disappearance delay)
                from !== undefined &&
                to.name === 'module' &&
                from.name === 'document'
            ) {
                this.$store.commit('items/transitionModule', 'transitionModuleLeave')
                this.$store.commit('items/resetSectionRouterViewKey', {})
            } else if (
                //  module -> section
                from !== undefined &&
                to.name === 'section' &&
                from.name === 'module' &&
                to.params.section !== from.params.section
            ) {
                Vue.nextTick(() => {
                    this.$store.commit('items/setDefaultTransition', {})
                }, 0)
                this.$store.commit('items/resize', {
                    role: 'section',
                    size: 'small'
                })
            } else if (
                // document -> section
                from !== undefined &&
                to.name === 'section' &&
                from.name === 'document' &&
                to.params.section !== from.params.section
            ) {
                Vue.nextTick(() => {
                    this.$store.commit('items/setDefaultTransition', {})
                }, 0)
                this.$store.commit('items/resetSectionRouterViewKey', {})
                this.$store.commit('items/resize', {
                    role: 'section',
                    size: 'small'
                })
            } else {
                this.$store.commit('items/setDefaultTransition', {})
            }

            next()
            // Vue.nextTick(next, 0)
        })
    },
    methods: {
        ...mapActions('items', ['goTo', 'gotoParams'])
    }
}
</script>

<style lang="stylus">
@import '~@/assets/styles/colors.styl'

.work
    display flex
    background-color $gray-4

.work__version
    position fixed
    left 5px

////////////////////////////////////////////////////////////////
////               /////////////////////////////////////////////
////  Transition   /////////////////////////////////////////////
////               /////////////////////////////////////////////
////////////////////////////////////////////////////////////////

.routerTransitionRoot-enter-active, .routerTransitionRoot-leave-active
     transition-property: all
     transition-timing-function: linear
     transition-duration: 0.4s

 .routerTransitionRoot-enter
     transform: translate(100vw, 0)

 .routerTransitionRoot-leave-to
     transform: translate(70vw, 0)

</style>
