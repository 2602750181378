var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "visible": _vm.visibleModal,
      "title": "Добавление сотрудника/подразделения в узел согласования"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      },
      "close": _vm.close
    }
  }, [_vm.node ? _c('div', {
    staticClass: "p-5",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('div', {
    staticClass: "add-actor-modal__select"
  }, [_c('div', _vm._l(_vm.approvalTypes, function (type) {
    return _c('RadioButton', {
      key: type.id,
      staticClass: "add-actor-modal__check-radio",
      attrs: {
        "label": type.name,
        "value": type.id
      },
      model: {
        value: _vm.approvalType,
        callback: function callback($$v) {
          _vm.approvalType = $$v;
        },
        expression: "approvalType"
      }
    });
  }), 1), _vm._v(" "), _c('ActionSelectCard', {
    staticClass: "add-actor-modal__select-card",
    attrs: {
      "placeholder": _vm.approvalType === 'user' ? 'Введите имя сотрудника' : 'Введите название подразделения',
      "entity": _vm.approvalType,
      "items": _vm.approvalType === 'user' ? _vm.getUsers : _vm.getUnits,
      "sort": "name",
      "sub-sort-property": _vm.approvalType === 'user' ? 'fullName' : ''
    },
    on: {
      "input": function input($event) {
        return _vm.selectCard($event);
      }
    },
    model: {
      value: _vm.selectCardId,
      callback: function callback($$v) {
        _vm.selectCardId = $$v;
      },
      expression: "selectCardId"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c('template', {
    slot: "footer"
  }, [_c('Button', {
    staticClass: "add-actor-modal__save",
    attrs: {
      "color": "green",
      "disabled": !_vm.selectCardId
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }