import Vue from 'vue'
import { MutationTree } from 'vuex'
import { WorkflowState } from '@/store/workflow'

import { WorkflowTemplate, StageTemplate, WFVvs } from '@/model/Workflow'

const FlowMutations: MutationTree<WorkflowState> = {
    addWFStage(state: WorkflowState, params: { stage: StageTemplate, position: string }) {
        if (params.position === 'end') {
            state.workflow.stages.push(params.stage)
        } else {
            state.workflow.stages.unshift(params.stage)
        }
    },

    changeWFAction: (state: WorkflowState, actionId: string) => state.workflow.action = actionId,

    /** Одновременно и задача и узел не могут быть активными */
    changeWFActiveNode(state: WorkflowState, node: string) {
        state.workflow.activeNode = node
        state.workflow.activeTask = ''
        state.workflow.action = ''
    },

    changeWFActiveStage: (state: WorkflowState, activeStage: number | null) => state.workflow.activeStage = activeStage,

    /** Одновременно и задача и узел не могут быть активными */
    changeWFActiveTask(state: WorkflowState, taskId: string) {
        state.workflow.activeTask = taskId
        state.workflow.activeNode = ''
        state.workflow.action = ''
    },

    changeWFStageDisplayName(state: WorkflowState, newName: string) {
        if (state.workflow.activeStage !== null) {
            state.workflow.stages[state.workflow.activeStage].displayName = newName
        }
    },

    /** Только самы первый stage может содержать в себе vvs */
    changeWFStageVvs: (state: WorkflowState, vvs: boolean) => state.workflow.stages[0].vvs = vvs,

    changeWFStatic: (state: WorkflowState, staticFlag: boolean) => state.workflow.staticFlag = staticFlag,

    changeWFVVS: (state: WorkflowState, vvs: WFVvs) => state.workflow.vvs = vvs,

    removeWFStage(state: WorkflowState) {
        if (state.workflow.activeStage !== null) {
            state.workflow.stages.splice(state.workflow.activeStage, 1)
        }
    },

    moveWFStage(state: WorkflowState, params: { stageIndex: number, newStageIndex: number }) {
        const stageA = state.workflow.stages[params.stageIndex]
        const stageB = state.workflow.stages[params.newStageIndex]

        // Меняем местами stages
        Vue.set(state.workflow.stages, params.stageIndex, stageB)
        Vue.set(state.workflow.stages, params.newStageIndex, stageA)
    },

    putWorkflow: (state: WorkflowState, workflow: WorkflowTemplate) => Vue.set(state, 'workflow', workflow),

    workflowChange(state: WorkflowState, change: boolean) {
        state.workflow.change = change
    }
}

export default FlowMutations
