import dayjs from 'dayjs'

export const DOC_TABLE_CELLS_DISPLAY_NAME: { [key: string]: string } = {
    number: 'Номер',
    organisation: 'Организация',
    name: 'Документ',
    author: 'Автор',
    status: 'Статус',
    creationTime: 'Дата создания',
    actors: 'Согласует'
}

export const DOC_MAIN_CELLS: Array<string> = ['number', 'organisation', 'name', 'author', 'status', 'creationTime', 'actors']

interface TDocumentListFilters {
    status: {
        draft: boolean
        approval: boolean
        execution: boolean
        archived: boolean
    }
    myPending: boolean
    myTasks: boolean
    organisation: string
    startTime: number
    endTime: number
    searchText: string
    scrollTop: number
}

export class DocumentListFilters {
    public status: {
        draft: boolean
        approval: boolean
        execution: boolean
        archived: boolean
    }

    public myPending: boolean
    public myTasks: boolean
    public organisation: string
    public startTime: number
    public endTime: number
    public searchText: string
    public scrollTop: number

    constructor(o: TDocumentListFilters) {
        this.status = {
            draft: o.status.draft,
            approval: o.status.approval,
            execution: o.status.execution,
            archived: o.status.archived
        }

        this.myPending = o.myPending
        this.myTasks = o.myTasks
        this.organisation = o.organisation
        this.startTime = o.startTime
        this.endTime = o.endTime
        this.searchText = o.searchText
        this.scrollTop = o.scrollTop
    }

    clone(): DocumentListFilters {
        return new DocumentListFilters(this)
    }

    // Функция сравнения для двух классов DocumentListFilters
    equal(obj: DocumentListFilters): boolean {
        if (obj.status.draft !== this.status.draft) {
            return false
        } else if (obj.myPending !== this.myPending) {
            return false
        } else if (obj.myTasks !== this.myTasks) {
            return false
        } else if (obj.organisation !== this.organisation) {
            return false
        } else if (obj.status.approval !== this.status.approval) {
            return false
        } else if (obj.status.execution !== this.status.execution) {
            return false
        } else if (obj.status.archived !== this.status.archived) {
            return false
        } else if (obj.startTime !== this.startTime) {
            return false
        } else if (obj.endTime !== this.endTime) {
            return false
        } else if (obj.searchText !== this.searchText) {
            return false
        } else if (obj.scrollTop !== this.scrollTop) {
            return false
        } else {
            return true
        }
    }
}

export const default_doctype_filters = (): DocumentListFilters => {
    const startOfYear = dayjs().startOf('year').unix() * 1000
    const previewMonth = dayjs().subtract(6, 'month').startOf('month').unix() * 1000
    const startTime = Math.min(startOfYear, previewMonth)

    const endTime = dayjs().endOf('day').unix() * 1000

    return new DocumentListFilters({
        status: {
            draft: true,
            approval: true,
            execution: true,
            archived: false
        },
        myPending: false,
        myTasks: false,
        organisation: '',
        startTime,
        endTime,
        searchText: '',
        scrollTop: 0
    })
}
