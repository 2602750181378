










































































































































































import { Component, Vue, Prop, Emit, PropSync, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { cuntPB } from 'api'

import getError, { GrpcError } from '@/tools/errors/errors'
import { User, Role } from '@/model/User'
import { Unit } from '@/model/Unit'

import Modal from '@/components/common/modals/Modal.vue'
import SelectRoleUnit from '@/components/common/SelectRoleUnit.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard.vue'
import Button from '@/components/common/buttons/Button.vue'
import Textarea from '@/components/common/Textarea.vue'
import DateTimeInput from '@/components/common/dateTimePicker/DateTimeInput.vue'

const rolesList: Array<string> = ['WorkflowManager', 'Employee', 'Head'] // под какими ролями разрешено создавать задачи

@Component({
    components: {
        Modal,
        SelectRoleUnit,
        InputBase,
        RadioButton,
        ActionSelectCard,
        Button,
        Textarea,
        DateTimeInput
    }
})

export default class ModalCreateTask extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean

    @Prop({ default: '' }) parentTaskId!: string
    @Prop({ default: '' }) documentId!: string // задача создается в документе

    // *************************************************************
    // DATA PARAMS
    rolesList = rolesList
    originUnitId = ''

    text = ''
    assignee = {
        type: 'user',
        user: '',
        unit: ''
    }
    confirmer = {
        type: '',
        user: '',
        unit: ''
    }
    description = ''
    dueTime = 0

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null
    @Getter('company_structure/getUsersByCenterId') getUsersByCenterId!: (centerId: string) => Array<User>
    @Getter('company_structure/getUnitsByCenterId') getUnitsByCenterId!: (centerId: string) => Array<Unit>

    get originUnit(): Unit | null {
        return this.getUnitById(this.originUnitId)
    }

    get users(): Array<User> {
        if (!this.originUnit) {
            return []
        }
        return this.getUsersByCenterId(this.originUnit.centerId)
    }

    get units(): Array<Unit> {
        if (!this.originUnit) {
            return []
        }
        return this.getUnitsByCenterId(this.originUnit.centerId)
    }

    get assigneeHead(): string | null {
        const head = this.users.find((u: User) => u.roles.find((r: Role) => r.name === 'Head' && r.unit === this.assignee.unit))
        if (!head) {
            return null
        }
        return head.name.shortName
    }

    get confirmerHead(): string | null {
        if (!this.confirmer.unit) {
            return null
        }
        const head = this.users.find((u: User) => u.roles.find((r: Role) => r.name === 'Head' && r.unit === this.confirmer.unit))
        if (!head) {
            return null
        }
        return head.name.shortName
    }

    get assignees(): Array<User> {
        console.log('assignee.unit', this.assignee.unit)
        // Если assignee выбрано подразделение - то выводим сотрудников данного подразделения
        return this.users.filter((u: User) => u.roles.find((r: Role) => r.unit === this.assignee.unit))
    }

    get confirmers(): Array<User> {
        // Если confirmer выбрано подразделение - то выводим сотрудников данного подразделения
        return this.users.filter((u: User) => u.roles.find((r: Role) => r.unit === this.confirmer.unit))
    }

    get saveDisabled(): string {
        if (!this.originUnit) {
            return 'Необходимо выбрать подразделение'
        }
        if (!this.text) {
            return 'Необходимо ввести название задачи'
        }
        if (this.assignee.type === 'unit' && !this.assignee.unit) {
            return 'Необходимо выбрать исполнителя задачи'
        }
        if (this.assignee.type === 'user' && !this.assignee.user) {
            return 'Необходимо выбрать исполнителя задачи'
        }
        if (this.confirmer.type === 'unit' && !this.confirmer.unit) {
            return 'Укажите подтверждающего'
        }
        if (this.confirmer.type === 'user' && !this.confirmer.user) {
            return 'Укажите подтверждающего'
        }
        return ''
    }

    // *************************************************************
    // WATCH
    @Watch('assignee.unit')
    handleAssigneeUnitChange(): void {
        this.assignee.user = ''
    }

    @Watch('confirmer.unit')
    handleConfirmerUnitChange(): void {
        this.confirmer.user = ''
    }

    // *************************************************************
    // METHODS
    @Action('task/callCreateTask')
    callCreateTask!: (params: cuntPB.CreateTaskReq) => Promise<void>

    close(): void {
        this.text = ''
        this.assignee = {
            type: 'user',
            unit: '',
            user: ''
        }
        this.confirmer = {
            type: '',
            unit: '',
            user: ''
        }
        this.description = ''
        this.dueTime = 0

        this.emitClose()
    }

    hide(): void {
        this.syncedVisible = false
    }

    save(): void {
        if (this.saveDisabled) {
            this.$snotify.warning(this.saveDisabled, 'Отклонено!')
            return
        }

        const cancelPreloaderCreateTask = this.$preloader('create_task', 'Создание задачи')

        this.callCreateTask({
            AuthorUnitID: this.originUnitId,
            Assignee: {
                UserID: this.assignee.user,
                UnitID: this.assignee.unit
            },
            Text: this.text,
            Description: this.description,
            Confirmer: {
                UserID: this.confirmer.user,
                UnitID: this.confirmer.unit
            },
            Document: this.documentId,
            Parent: this.parentTaskId,
            DueTime: this.dueTime
        })
            .then(() => this.hide())
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderCreateTask())
    }

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose():  void {
        // Do nothing
    }
}
