var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "support"
  }, [_c('div', {
    staticClass: "support__container"
  }, [_c('h1', [_vm._v("Technical support is available only to authorized users.")]), _vm._v("\n        Unauthorized users: please contact us via email\n        "), _c('a', {
    attrs: {
      "href": "mailto:support@themake.rs"
    }
  }, [_vm._v("support@themake.rs")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }