


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Document } from '@/model/Document'
import { DocumentType, DocumentTypeRequisite } from '@/model/DocumentType'

import DocumentBlockTitle from '@/components/document/common_document/DocumentBlockTitle.vue'
import DocumentRequisiteCard from '@/components/document/common_document/requisites/DocumentRequisiteCard.vue'
import DocumentCommentary from '@/components/document/common_document/requisites/DocumentCommentary.vue'

@Component<DocumentRequisites>({
    components: {
        DocumentBlockTitle,
        DocumentRequisiteCard,
        DocumentCommentary
    }
})

export default class DocumentRequisites extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) openDocument!: Document
    @Prop({ default: false }) canChangeRequisite!: boolean

    // *************************************************************
    // COMPUTED
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    get typeRequisites(): Array<DocumentTypeRequisite> {
        // Берем весь список реквизитов данного типа вместе с required
        return this.getTypeById(this.openDocument.base.type)?.requisites ?? []
    }
}
