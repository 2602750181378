var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reports"
  }, [_c('h1', [_vm._v("Отчеты")]), _vm._v(" "), _vm._l(_vm.reports, function (report) {
    return _c('ListCard', {
      key: report.id,
      attrs: {
        "item": report
      },
      on: {
        "click": function click($event) {
          return _vm.openReport(report.id);
        }
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }