<template>
    <BaseListPage
        v-model="searchText"
        :visibleModal.sync="visibleCreationModal"
        type="familiarization"
        :disabled-create="!name || !unitId"
        @create="create"
        @changeCenterId="searchCenterId = $event"
    >
        <template slot="list">
            <div class="flex-row-center">
                <Checkbox
                    v-model="showDraft"
                    label="Черновики"
                />
                <Checkbox
                    v-model="showActive"
                    label="Активные"
                />
                <Checkbox
                    v-if="isReceivedArchivedFamiliarization"
                    key="archived-received"
                    v-model="showArchived"
                    label="Архивные"
                />
                <ButtonViolet
                    v-else
                    key="archived-not-received"
                    :small="true"
                    text="Получить архивные ознакомления"
                    @click="getArchived"
                />
            </div>

            <ListCard
                v-for="item in list"
                :key="item.id"
                :item="item"
                @click="openItem(item.id)"
            />
        </template>
        <div
            slot="create"
            class="create-familiarization__create"
        >
            <SelectRoleUnit
                :roles-list="rolesList"
                :is-includes-centers="true"
                @changeOriginUnit="unitId = $event"
            />
            <InputBase
                v-model.lazy.trim="name"
                type="text"
                :disabled="!unitId"
                placeholder="Название"
                class="create-familiarization__title"
            />
        </div>
    </BaseListPage>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import BaseListPage from '@/components/common/BaseListPage'
import SelectRoleUnit from '@/components/common/SelectRoleUnit'
import ListCard from '@/components/common/cards/ListCard'
import InputBase from '@/components/common/inputs/InputBase'
import Checkbox from '@/components/common/inputs/Checkbox'
import ButtonViolet from '@/components/common/buttons/ButtonViolet'
import dynamicSort from '@/tools/sort/dynamicSort'
import errors from '@/tools/errors/errors.ts'

export default {
    name: 'Familiarizations',
    components: {
        BaseListPage,
        SelectRoleUnit,
        ListCard,
        InputBase,
        Checkbox,
        ButtonViolet
    },
    data: () => {
        return {
            searchCenterId: '',
            unitId: '',
            searchText: '',
            name: '',
            showDraft: true,
            showActive: true,
            showArchived: true,
            visibleCreationModal: false
        }
    },
    computed: {
        ...mapState({ familiarizations: state => state.familiarization.familiarizations }),
        ...mapState({ isReceivedArchivedFamiliarization: state => state.user_options.isReceivedArchivedFamiliarization }),
        rolesList() {
            return ['WorkflowManager', 'Head']
        },
        list() {
            let list = this.familiarizations.filter(f => {
                if (this.searchText && !f.name.toLowerCase().includes(this.searchText.toLowerCase())) {
                    return false
                }
                if (this.searchCenterId && f.centerId !== this.searchCenterId) {
                    return false
                }
                if (!this.showDraft && f.status === 'draft') {
                    return false
                }
                if (!this.showActive && f.status === 'active') {
                    return false
                }
                if (!this.showArchived && f.status === 'archived') {
                    return false
                }
                return true
            })

            return list.sort(dynamicSort('name'))
        }
    },
    methods: {
        ...mapActions('familiarization', ['callCreate', 'callArchivedListStream']),
        ...mapActions('user_options', ['setArchived']),
        openItem(id) {
            this.$router.push({
                name: 'document',
                params: {
                    section: 'system',
                    module: 'familiarization',
                    id: id
                }
            })
        },
        create() {
            if (!this.name) {
                this.$snotify.error('Введите Название', 'Отклонено!', {
                    timeout: 0
                })
                return
            }
            if (!this.unitId) {
                this.$snotify.error('Необходимо выбрать подразделение', 'Отклонено!', {
                    timeout: 0
                })
                return
            }
            this._cancelPreloaderNewFamiliarization = this.$preloader('create_familiarization', 'Создание ознакомления')

            this.callCreate({
                unitId: this.unitId,
                title: this.name
            }).then((id) => {
                this.visibleCreationModal = false
                this._cancelPreloaderNewFamiliarization()
                this.unitId = ''
                this.name = ''
                Vue.nextTick(() => {
                    this.openItem(id)
                }, 0)
            }).catch(error => {
                this._cancelPreloaderNewFamiliarization()
                this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                    timeout: 0
                })
            })
        },
        getArchived() {
            this._cancelPreloaderArchivedFamiliarizationStream = this.$preloader('archived_familiarization_stream', 'Получение архивных ознакомлений')

            this.callArchivedListStream()
                .then(() => {
                    this._cancelPreloaderArchivedFamiliarizationStream()
                    this.setArchived('familiarization')
                })
                .catch(error => {
                    this._cancelPreloaderArchivedFamiliarizationStream()
                    this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                        timeout: 0
                    })
                })
        }
    }
}
</script>

<style lang="stylus" scoped>
.create-familiarization__create
    padding 10px
    width 100%
    box-sizing border-box

.create-familiarization__title
    margin-bottom 20px
    width 100%
    height 40px
</style>
