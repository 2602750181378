




































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { Role, User } from '@/model/User'
import { Task, TASK_EVENT_TYPE, TASK_STATUS, TaskEvent } from '@/model/Task'
import { SelectOption } from '@/components/common/select/model'
import reverseSort from '@/tools/sort/reverseSort'

import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import Select from '@/components/common/select/Select.vue'
import TaskChat from '@/components/tasks/TaskChat.vue'
import Attribute from '@/components/common/Attribute.vue'
import TaskCardMixin from '@/components/tasks/cards/TaskCardMixin.vue'

@Component({
    components: {
        ButtonViolet,
        Select,
        TaskChat,
        Attribute
    }
})

export default class TaskCardFull extends Mixins(TaskCardMixin) {
    // *************************************************************
    // DATA PARAMS
    events: Array<TaskEvent> = []
    typeMessages = true
    typeEvents = true
    time = Date.now()
    newAssignee = ''
    loadingDelegate = '' // assignee | confirmer

    timeNowObserver: ReturnType<typeof setInterval> | undefined = undefined

    taskStream: ReturnType<() => () => void> | undefined = undefined

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible

    @Getter('task/getTaskById') getTaskById!: (id: string) => Task | null

    @Getter('company_structure/getUsers') getUsers!: Array<User>

    get taskId(): string {
        if (this.$route.params.module === 'tasks') {
            return this.$route.params.id
        }
        return this.task?.id ?? ''
    }

    get author(): string {
        return `${this.task.author.user?.name.fullName} (${this.task.author.unit ? this.task.author.unit.name : 'Без подразделения'})`
    }

    get assignee(): string {
        return `${this.task.assignee.user?.name.fullName} (${this.task.assignee.unit ? this.task.assignee.unit.name : 'Без подразделения'})`
    }

    get confirmer(): string {
        return this.task.confirmer ? `${this.task.confirmer.user?.name.fullName} (${this.task.confirmer.unit ? this.task.confirmer.unit.name : 'Без подразделения'})` : 'Не требует подтверждения'
    }

    get assignees(): Array<SelectOption> {
        if (this.task.status === TASK_STATUS.STATUS_CLOSED || this.task.status === TASK_STATUS.STATUS_CANCELED) {
            return []
        }

        const assigneeUnit = this.task.assignee.unit

        if (!assigneeUnit) {
            // Если задача выставлена не на unit
            return []
        }

        const head = this.getMe.roles.find((r: Role) => r.name === 'Head' && r.unit === assigneeUnit.id)
        if (!head) {
            return []
        }

        const isIncludeQuery = (user: User) => user.roles.find((r: Role) => r.unit === assigneeUnit.id)

        return this.getUsers.filter((user: User) => (isIncludeQuery(user))).map((u: User) => {
            return {
                id: u.id,
                name: `${u.name.shortName} (${assigneeUnit.name})`
            }
        })
    }

    get confirmers(): Array<SelectOption> {
        if (this.task.status === TASK_STATUS.STATUS_CLOSED || this.task.status === TASK_STATUS.STATUS_CANCELED) {
            return []
        }

        const confirmerUnit = this.task.confirmer?.unit

        if (!confirmerUnit) {
            return []
        }

        const head = this.getMe.roles.find((r: Role) => r.name === 'Head' && r.unit === confirmerUnit.id)
        if (!head) {
            return []
        }
        const isIncludeQuery = (user: User) => user.roles.find((r: Role) => r.unit === confirmerUnit.id)

        return this.getUsers.filter(user => (isIncludeQuery(user))).map((u: User) => {
            return {
                id: u.id,
                name: `${u.name.shortName} (${confirmerUnit.name})`
            }
        })
    }

    get filterEvents(): Array<TaskEvent> {
        let events = this.events
        if (!this.typeEvents) {
            // Если включена галочка, что события не показываем в Чате, то показываем только события с типом message
            events = this.events.filter((e: TaskEvent) => e.type === TASK_EVENT_TYPE.TASK_EVENT_TYPE_MESSAGE)
        } else if (!this.typeMessages) {
            // Наоборот, показываем все, кроме сообщений
            events = this.events.filter((e: TaskEvent) => e.type !== TASK_EVENT_TYPE.TASK_EVENT_TYPE_MESSAGE)
        }
        return events.sort(reverseSort('ts'))
    }

    get eventFieldTypes(): { [key: string]: { name: string }} {
        return {
            [TASK_EVENT_TYPE.TASK_EVENT_TYPE_CREATED]: { name: 'Задача создана' },
            [TASK_EVENT_TYPE.TASK_EVENT_TYPE_COMPLETED]: { name: 'Задача выполнена' },
            [TASK_EVENT_TYPE.TASK_EVENT_TYPE_CONFIRMED]: { name: 'Задача подтверждена' },
            [TASK_EVENT_TYPE.TASK_EVENT_TYPE_REJECTED]: { name: 'Задача отклонена' },
            [TASK_EVENT_TYPE.TASK_EVENT_TYPE_CLOSED]: { name: 'Задача отменена' },
            [TASK_EVENT_TYPE.TASK_EVENT_TYPE_REOPENED]: { name: 'Задача возвращена в работу' },
            [TASK_EVENT_TYPE.TASK_EVENT_TYPE_DELEGATED]: { name: 'Изменен исполнитель' },
            [TASK_EVENT_TYPE.TASK_EVENT_TYPE_CONFIRMATION_DELEGATED]: { name: 'Изменен подтверждающий' }
        }
    }

    get buttons(): Array<{ key: string, text: string }> {
        if (!this.task) {
            return []
        }

        let buttons: Array<{ key: string, text: string }> = []

        if (!this.task.completed && !this.task.closed) {
            if (this.task.assignee.user?.id === this.getMe.id)  {
                // Если исполнитель и задача не выполнена
                buttons.push({
                    key: 'complete',
                    text: 'Выполнить'
                })
            }
            if (this.task.author.user?.id === this.getMe.id)  {
                buttons.push({
                    key: 'close',
                    text: 'Отменить'
                })
            }
        } else if (!this.task.confirmed && this.task.confirmer && this.task.confirmer.user && !this.task.closed) {
            // Если задача требует подтверждения
            if (this.task.confirmer.user.id === this.getMe.id) {
                // Если требует подтверждения данного пользователя и еще не подтверждена, но уже выполнена
                buttons.push(
                    {
                        key: 'confirm',
                        text: 'Подтвердить'
                    },
                    {
                        key: 'reject',
                        text: 'Отклонить'
                    }
                )
            }
            if (this.task.author.user?.id === this.getMe.id)  {
                buttons.push({
                    key: 'close',
                    text: 'Отменить'
                })
            }
        } else if (this.task.author.user?.id === this.getMe.id) {
            // Если это автор задачи, задача выполнена и подтверждена или не требует подтверждения
            const closed = this.task.closed
            const confirmed = this.task.completed && ((this.task.confirmer && this.task.confirmed) || !this.task.confirmer)
            if (closed || confirmed) {
                buttons = [{
                    key: 'fuck',
                    text: 'Вернуть в работу'
                }]
            }
        }
        return buttons
    }

    // *************************************************************
    // WATCH
    @Watch('taskId', { immediate: true })
    handleTaskIdChange(to: string, from: string | undefined): void {
        console.log('WATCH::taskId', to, from)
        if (from) {
            this.events = []

            if (this.taskStream) {
                this.taskStream()
            }
        }

        if (to) {
            this.openTaskStream()
        }
    }

    // *************************************************************
    // CREATED
    created() {
        this.timeNowObserver = setInterval(
            (() => {
                return () => {
                    this.time = Date.now()
                }
            })(),
            20000
        )
    }

    // *************************************************************
    // BEFORE DESTROY
    beforeDestroy(): void {
        clearInterval(this.timeNowObserver)

        if (this.taskStream) {
            this.taskStream()
        }
    }

    // *************************************************************
    // METHODS
    @Action('task/callOpenTaskStream')
    callOpenTaskStream!: (params: { taskId: string, eventFunc: (event: TaskEvent) => void }) => Promise<() => void>

    @Action('task/callPutMessage')
    callPutMessage!: (params: cuntPB.TaskPutMessageReq) => Promise<TaskEvent>

    @Action('task/callDelegate')
    callDelegate!: (params: cuntPB.DelegateTaskReq) => Promise<void>

    openTaskStream(): void {
        this.callOpenTaskStream({
            taskId: this.taskId,
            eventFunc: this.putEvent
        })
            .then((taskStream) => {
                this.taskStream = taskStream
            })

        // console.log('taskStream', taskStream.unsubscribe())
    }

    spendMessage(message: string): void {
        const cancelPreloaderPutTaskMessage = this.$preloader('put_task_message', 'Отправка сообщения')

        this.callPutMessage({
            TaskID: this.taskId,
            Message: message
        })
            .then((event: TaskEvent) => {
                this.putEvent(event)
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderPutTaskMessage())
    }

    putEvent(taskEvent: TaskEvent): void {
        // Если еще нет в массиве, то добавляем
        // Эта проверка нужна, т.к. если мы отправим сообщение, то оно прийдет и в
        // методе spendMessage и здесь
        if (!this.events.find((event: TaskEvent) => event.id === taskEvent.id)) {
            this.events.push(taskEvent)
        }
    }

    delegate(id: string, key: string): void {
        if (key === 'assignee' && id === this.task.assignee.user?.id) {
            return
        }
        if (this.task.confirmer && key === 'confirmer' && id === this.task.confirmer.user?.id) {
            return
        }

        this.loadingDelegate = key

        this.callDelegate({
            TaskID: this.taskId,
            NewAssigneeID: key === 'assignee' ? id : '',
            NewConfirmerID: key === 'confirmer' ? id : ''
        })
            .then(() => {
                this.$snotify.success(`${key === 'assignee' ? 'Исполнитель' : 'Подтверждающий'} изменен`)
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingDelegate = ''
            })
    }
}
