





















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { TaskTemplate } from '@/model/Workflow'

import WFTaskListNode from '@/components/workflow_template_builder/workflow_template/WFTaskListNode.vue'

@Component({
    components: { WFTaskListNode }
})

export default class WFTTaskGraph extends Vue {
    @Prop({ default: () => []}) tasks!: Array<TaskTemplate>
    @Prop({ default: null }) stageIndex!: number | null

    // *************************************************************
    // COMPUTED
    get newTasks(): Array<TaskTemplate> {
        // Нам нужны только первенцы, остальные заполнятся в компоненте TaskListNode
        return this.tasks.filter((task: TaskTemplate) => !task.inputs.length)
    }

    // *************************************************************
    // METHODS
    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFActiveTask')
    changeWFActiveTask!: (taskId: string) => void

    onClickList(): void {
        // Если мы делаем клик не по конкретному task
        // Ставим активным данный блок
        this.emitChangeActiveStage()

        // Убираем активную задачу и node
        this.changeWFActiveTask('')

        // Возвращаемся в меню выбора action
        this.changeWFAction('')
    }

    // *************************************************************
    // EMIT
    @Emit('changeActiveStage')
    emitChangeActiveStage(): void {
        // Do nothing
    }
}
