import { cuntPB } from 'api'

import dayjs from 'dayjs'
import { Actor, UserInfo } from '@/model/Actor'

export enum TASK_STATUS {
    STATUS_ON_EXECUTION = 'execution',
    STATUS_ON_CONFIRMATION = 'confirmation',
    STATUS_CANCELED = 'canceled',
    STATUS_CLOSED = 'closed'
}

export const STATUS_NAMES: { [key: string]: string } = {
    [TASK_STATUS.STATUS_ON_EXECUTION]: 'Ожидает выполнения',
    [TASK_STATUS.STATUS_ON_CONFIRMATION]: 'Ожидает подтверждения',
    [TASK_STATUS.STATUS_CANCELED]: 'Отменена',
    [TASK_STATUS.STATUS_CLOSED]: 'Закрыта'
}

export class TaskDocument {
    public id: string
    public name: string
    public type: string
    public creationTime: number

    constructor(doc: cuntPB.TaskDocument) {
        this.id = doc.ID
        this.name = doc.Name
        this.type = doc.Type
        this.creationTime = doc.CreationTime
    }
}

export class Task {
    public id: string
    public author: Actor
    public assignee: Actor
    public text: string
    public description: string
    public confirmer: Actor | null
    public document: TaskDocument | null
    public completed: boolean
    public confirmed: boolean
    public closed: boolean
    public parent: string
    public children: Array<string>

    public creationTime: number
    public dueTime: number
    public modificationTime: number

    constructor(task: cuntPB.Task) {
        this.id = task.ID
        this.author = new Actor(task.Author ?? null)
        this.assignee = new Actor(task.Assignee ?? null)
        this.text = task.Text
        this.description = task.Description
        this.confirmer = task.Confirmer ? new Actor(task.Confirmer) : null
        this.document = task.Document ? new TaskDocument(task.Document) : null
        this.completed = task.Completed
        this.confirmed = task.Confirmed
        this.closed = task.Closed // canceled
        this.parent = task.Parent
        this.children = task.Children

        this.creationTime = task.CreationTime
        this.dueTime = task.DueTime
        this.modificationTime = task.ModificationTime
    }

    get status(): TASK_STATUS {
        if (this.closed) {
            return TASK_STATUS.STATUS_CANCELED
        }
        if (!this.completed) {
            return TASK_STATUS.STATUS_ON_EXECUTION
        }
        if (this.completed && !this.confirmed && this.confirmer) {
            return TASK_STATUS.STATUS_ON_CONFIRMATION
        }
        return TASK_STATUS.STATUS_CLOSED
    }

    get creationTimeLocalizedShortString(): string {
        return dayjs(this.creationTime / 1000).format('DD/MM/YYYY HH:mm')
    }

    get dueTimeLocalizedShortString(): string {
        return dayjs(this.dueTime).format('DD/MM/YYYY HH:mm')
    }
}

export enum TASK_EVENT_TYPE {
    TASK_EVENT_TYPE_MESSAGE = 'message',
    TASK_EVENT_TYPE_CREATED = 'created',
    TASK_EVENT_TYPE_COMPLETED = 'completed',
    TASK_EVENT_TYPE_CONFIRMED = 'confirmed',
    TASK_EVENT_TYPE_REJECTED = 'rejected',
    TASK_EVENT_TYPE_CLOSED = 'closed',
    TASK_EVENT_TYPE_REOPENED = 'reopened',
    TASK_EVENT_TYPE_DELEGATED = 'delegated',
    TASK_EVENT_TYPE_CONFIRMATION_DELEGATED = 'confirmation_delegated'
}

export class TaskEvent {
    public id: string
    public taskId: string
    public type: TASK_EVENT_TYPE
    public actor: UserInfo | null
    public ts: number
    public fields: { [key: string]: string }

    constructor(event: cuntPB.TaskEvent) {
        console.log('event', event)
        this.id = event.ID
        this.taskId = event.TaskID
        this.type = event.Type as TASK_EVENT_TYPE
        this.actor = event.Actor ? new UserInfo(event.Actor) : null
        this.ts = event.TS
        this.fields = event.Fields
    }

    get creationTimeStampString(): string {
        return dayjs(this.ts / 1000).format('DD/MM/YYYY HH:mm')
    }
}
