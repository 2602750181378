var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.event ? _c('div', {
    staticClass: "chat-card",
    class: {
      'chat-card_left': _vm.alignment === 'left',
      'chat-card_right': _vm.alignment === 'right',
      'chat-card_center': _vm.alignment === 'none'
    }
  }, [_vm.event.type === 'message' ? _c('MessageEvent', {
    attrs: {
      "alignment": _vm.alignment,
      "event": _vm.event
    }
  }) : _c('BaseEventCard', {
    staticClass: "chat-card_center",
    attrs: {
      "event": _vm.event,
      "options": _vm.options
    }
  }), _vm._v(" "), _vm.alignment !== 'none' ? _c('div', {
    staticClass: "chat-card__avatar",
    style: {
      'background-image': 'url(' + _vm.smallAvatar + ')'
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }