

































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import WFTNode from '@/components/workflow_template_builder/workflow_template/WFTNode.vue'

import { WorkflowTemplate, ApprovalTemplate } from '@/model/Workflow'
import { Actor } from '@/model/Actor'

// Импортируем функцию для расчетов глубин для графа
import viewData, { LinkTemplate, NodeTemplate } from '@/tools/template_builder/viewData'

@Component({
    components: { WFTNode }
})

export default class WFTNodeGraph extends Vue {
    @Ref('graph') readonly graph!: Element

    @Prop({ default: false }) root!: boolean
    @Prop({ default: false }) dynamic!: boolean // не Черновик
    @Prop({ default: () => []}) approvals!: Array<ApprovalTemplate> // массив узлов для графа
    @Prop({ default: false }) stageVvs!: boolean // есть ли в stage пометка vvs

    // *************************************************************
    // DATA PARAMS
    svgWidth = 0
    resizeObserverInterval: ReturnType<typeof setInterval> | undefined = undefined

    // *************************************************************
    // COMPUTED
    @Getter('workflow/getWorkflow') getWorkflow!: WorkflowTemplate

    get svgHeight(): number {
        // Ставим высоту svg равную количеству nodes умноженному на высоту одного node
        // В данном случае высота node === 40px
        // Если это динамичный блок, то добавляем еще высоту загловка === 40px
        if (this.dynamic) {
            if (this.stageVvs) {
                return this.approvals.length * 40 + 80
            } else {
                return this.approvals.length * 40 + 40
            }
        }

        return this.approvals.length * 40
    }

    get vertices(): { nodes: Array<NodeTemplate>, links: Array<LinkTemplate> } {
        const vertices = this.approvals.map((node: ApprovalTemplate) => new ApprovalTemplate(node.id, node.actor, node.specialName, node.inputs))

        if (this.dynamic && this.stageVvs) {
            // Если стоит пометка vvs, добавляем еще один node, а node c root делаем ему дочерними
            const rootNodes = vertices.filter(v => !v.inputs.length)
            rootNodes.forEach(node => node.inputs = ['vvs'])

            vertices.push({
                id: 'vvs',
                actor: new Actor(null),
                specialName: '',
                inputs: []
            })
        }

        return viewData(40, this.svgWidth, this.svgHeight, vertices, false, !this.dynamic)
    }

    // *************************************************************
    // MOUNTED
    mounted(): void {
        // fixme: Hack
        // Устанавливаем ширину svg равную ширине родительского блока
        this.svgWidth = this.graph.clientWidth
        this.resizeObserverInterval = setInterval(
            (() => {
                return () => {
                    if (this.svgWidth !== this.graph.clientWidth) {
                        this.svgWidth = this.graph.clientWidth
                    }
                }
            })(),
            1000
        )
    }

    // *************************************************************
    // BEFORE DESTROY
    beforeDestroy(): void {
        // убираем лишний наблюдатель за шириной пользовательского окна
        clearInterval(this.resizeObserverInterval)
    }

    // *************************************************************
    // METHODS
    @Action('workflow/changeWFActiveNode')
    changeWFActiveNode!: (node: string) => void

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFActiveTask')
    changeWFActiveTask!: (taskId: string) => void

    onClickNode(nodeId: string): void {
        // только если это динамический блок, node в черновике не реагирует на клик
        if (this.dynamic && nodeId !== 'vvs') {
            // Если совершен клик повторно по одному и тому же node,
            // то обнуляем значение в state и снимаем выделение
            if (this.getWorkflow.activeNode === nodeId) {
                this.changeWFActiveNode('')
            } else {
                this.changeWFActiveNode(nodeId)

                // Ставим активным данный блок
                this.emitChangeActiveStage()
            }
        }
    }

    clickSvg(): void {
        // Если мы делаем клик не по конкретному node
        // Ставим активным данный блок
        this.emitChangeActiveStage()

        // Убираем активную задачу и node
        this.changeWFActiveTask('')
        this.changeWFActiveNode('')

        // Возвращаемся в меню выбора action
        this.changeWFAction('')
    }

    // *************************************************************
    // EMIT
    @Emit('changeActiveStage')
    emitChangeActiveStage(): void {
        // Do nothing
    }
}
