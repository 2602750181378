import store from '@/store'
import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { TaskState } from '@/store/task/task'

import { service, cuntPB } from 'api'
import { Task, TaskEvent } from '@/model/Task'
import getError, { GrpcError } from '@/tools/errors/errors'

const TaskActions: ActionTree<TaskState, RootState> = {
    callArchivedTasksStream({ dispatch }) {
        service.task.ArchivedTasksStream({})
            .subscribe({
                next(data: cuntPB.ArchivedTasksStreamMessage) {
                    if (data.Task) {
                        dispatch('putTask', new Task(data.Task))
                    } else if (data.EOS === 'EOS') {
                        console.warn('data.EOS', data.EOS)
                        return
                    } else {
                        console.error('callArchivedTasksStream - пришла какая-то дикая хрень!')
                    }
                },
                error: (err: GrpcError) => console.log('error', err),
                complete: () => console.log('complete---------')
            })
    },

    callOpenTaskStream({ dispatch }, params: { taskId: string, eventFunc: (event: TaskEvent) => void }) {
        const taskStream = service.task.TaskStream({ TaskID: params.taskId })
            .subscribe({
                next(data: cuntPB.TaskStreamMessage) {
                    if (data.Task) {
                        dispatch('putTask', new Task(data.Task))
                    } else if (data.Event) {
                        const event = new TaskEvent(data.Event)
                        params.eventFunc(event)
                    } else {
                        console.error('TaskSvcClient.taskStream - пришла какая-то дичь', data)
                    }
                },
                error: (error: GrpcError) => {
                    if (error && error.message !== 'Request was aborted') {
                        // Чтобы не выходило сообщение об ошибке, когда мы закрываем TaskStream
                        // при выходе из Modal
                        store.$snotify.error(getError(error))
                    }
                },
                complete: () => {
                    // Do nothing
                }
            })

        return () => taskStream.unsubscribe()
    },

    async callCreateTask({ dispatch }, params: cuntPB.CreateTaskReq) {
        const data: cuntPB.Task = await service.task.Create(params)
        console.log('::DATA::TASK.create:', data)
        dispatch('putTask', new Task(data))
    },

    async callComplete({ dispatch }, id: string) {
        const data: cuntPB.Task = await service.task.Complete({ ID: id })
        console.log('::DATA::TASK.complete:', data)
        dispatch('putTask', new Task(data))
    },

    async callReject({ dispatch }, id: string) {
        const data: cuntPB.Task = await service.task.Reject({ ID: id })
        console.log('::DATA::TASK.reject:', data)
        dispatch('putTask', new Task(data))
    },

    async callConfirm({ dispatch }, id: string) {
        const data: cuntPB.Task = await service.task.Confirm({ ID: id })
        console.log('::DATA::TASK.confirm:', data)
        dispatch('putTask', new Task(data))
    },

    async callClose({ dispatch }, id: string) {
        const data: cuntPB.Task = await service.task.Close({ ID: id })
        console.log('::DATA::TASK.close:', data)
        dispatch('putTask', new Task(data))
    },

    async callReopen({ dispatch }, id: string) {
        const data: cuntPB.Task = await service.task.Reopen({ ID: id })
        console.log('::DATA::TASK.reopen:', data)
        dispatch('putTask', new Task(data))
    },

    async callPutMessage({ dispatch }, params: cuntPB.TaskPutMessageReq) {
        const data: cuntPB.TaskEvent = await service.task.PutMessage(params)
        console.log('::DATA::TASK.putMessage:', data)
        return new TaskEvent(data)
    },

    async callDelegate({ dispatch }, params: cuntPB.DelegateTaskReq) {
        const data: cuntPB.Task = await service.task.Delegate(params)
        console.log('::DATA::TASK.delegate:', data)
        dispatch('putTask', new Task(data))
    },

    async callRemove({ dispatch }, id: string) {
        await service.task.Remove({ ID: id })
        console.log('::DATA::TASK.remove:', id)
        dispatch('removeTask', id)
    },

    putTask({ commit, getters }, task: Task) {
        const existingTask = getters.getTaskById(task.id)
        if (existingTask) {
            if (task.modificationTime > existingTask.modificationTime) {
                commit('replaceTask', task)
            }
        } else {
            commit('putTask', task)
        }
    },

    removeTask({ commit }, taskId: string) {
        commit('removeTask', taskId)
    },

    cleanUpState({ commit }) {
        commit('cleanUpState')
    }
}

export default TaskActions
