















































































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { cuntPB } from 'api'

import getError, { GrpcError } from '@/tools/errors/errors'
import { SelectOption } from '@/components/common/select/model'
import { Directory, DirectoryField, Record, RecordField, Time } from '@/model/Directory'

import InputBase from '@/components/common/inputs/InputBase.vue'
import InputNumber from '@/components/common/inputs/InputNumber.vue'
import ButtonClose from '@/components/common/buttons/ButtonClose.vue'
import DateTimeInput from '@/components/common/dateTimePicker/DateTimeInput.vue'
import Select from '@/components/common/select/Select.vue'
import RecordSelectModal from '@/components/directory/RecordSelectModal.vue'

const booleanOptions: Array<SelectOption> = [
    { id: 'false', name: 'Нет' },
    { id: 'true', name: 'Да' }
]

@Component({
    components: {
        InputBase,
        InputNumber,
        ButtonClose,
        DateTimeInput,
        Select,
        RecordSelectModal
    }
})

export default class EditRecord extends Vue {
    @Ref() readonly refReference!: RecordSelectModal

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) recordId!: string

    // *************************************************************
    // DATA PARAMS
    cells: Array<string|number> = []
    referenceCells: { [recordId: string]: string } = {} // если есть ссылки на другие справочники
    booleanOptions = booleanOptions
    selectedIndex: number | null = null

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryById') getDirectoryById!: (dirId: string) => Directory | null
    @Getter('directories/getRecordById') getRecordById!: (recordId: string) => Record | null

    get directoryId(): string {
        return this.$route.params.id
    }

    get directoryFields(): Array<DirectoryField> {
        let directory = this.getDirectoryById(this.directoryId)
        return (directory ? directory.fields : [])
    }

    get record(): Record | null {
        if (!this.recordId) {
            return null
        }
        return this.getRecordById(this.recordId)
    }

    get recordFields(): Array<RecordField> {
        return this.record?.fields ?? []
    }

    get changingFields(): boolean {
        if (!this.recordId) {
            return true
        }

        return this.recordFields.some((field: RecordField, index: number) => {
            return String(field.value) !== this.cells[index]
        })
    }

    get emptyRequiredFields(): boolean {
        return this.directoryFields.some((field: DirectoryField, index: number) => {
            return field.required && !this.cells[index]
        })
    }

    // *************************************************************
    // WATCH
    @Watch('recordFields', { immediate: true, deep: true })
    handleRecordFieldsChange(to: Array<RecordField>): void {
        to.forEach((field, index) => {
            if (field.type === 'time' && field.value instanceof Time) {
                Vue.set(this.cells, index, field.value.tUtc)
            } else {
                Vue.set(this.cells, index, String(field.value))
            }
            if (field.type === 'reference' && field.value) {
                Vue.set(this.referenceCells, String(field.value), field.displayName)
            }
        })
    }

    // *************************************************************
    // METHODS
    @Action('directories/callCreateRecord')
    callCreateRecord!: (params: cuntPB.AddRecordReq) => Promise<void>

    @Action('directories/callUpdateRecordFields')
    callUpdateRecordFields!: (params: cuntPB.UpdateRecordFieldsReq) => Promise<void>

    onClickEditReference(subType: string, index: number): void {
        // Сохранем index, чтобы знать, куда сохранить это значение
        this.selectedIndex = index
        this.refReference.show(subType, '')
    }

    changeReference(record: Record): void {
        if (this.selectedIndex === null) { // may be === 0
            return
        }

        Vue.set(this.cells, this.selectedIndex, record.id)
        Vue.set(this.referenceCells, record.id, record.displayName)
    }

    canSave(): void {
        if (!this.changingFields) {
            return
        }

        if (this.emptyRequiredFields) {
            this.$snotify.warning('Заполните обязательные поля')
            return
        }

        const fields: Array<cuntPB.RecordField> = this.directoryFields.map((field: DirectoryField, index: number) => {
            const newField: cuntPB.RecordField = {
                String: '',
                Number: '',
                Boolean: false,
                Reference: '',
                Time: {
                    UTC: 0,
                    Local: 0,
                    Offset: 0,
                    OffsetAbsent: false
                }
            }
            const value = this.cells[index]
            if (!value) {
                console.error('cannot set value in record field', field, index)
                return newField
            }

            if (field.type === 'string') {
                newField.String = String(value)
            } else if (field.type === 'reference') {
                newField.Reference = String(value)
            } else if (field.type === 'number') {
                newField.Number = String(value)
            } else if (field.type === 'boolean') {
                newField.Boolean = Boolean(value)
            } else if (field.type === 'time') {
                newField.Time = {
                    UTC: Number(value),
                    Local: Number(value),
                    Offset: 0,
                    OffsetAbsent: true
                }

            }
            return newField
        })

        if (!this.recordId) {
            // Если нет идентификатора - значит создаем новый record
            this.create(fields)
        } else {
            // Если сейчас редактируем уже существующий record
            this.update(fields)
        }
    }

    create(fields: Array<cuntPB.RecordField>): void {
        const cancelPreloaderCreateDirectoryRecord = this.$preloader('create_directory_record', 'Создание элемента справочника')

        this.callCreateRecord({
            DirectoryID: this.directoryId,
            Fields: fields
        })
            .then(() => {
                // Закрываем окно добавления record
                this.emitClose()
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderCreateDirectoryRecord())
    }

    update(fields: Array<cuntPB.RecordField>): void {
        console.log('update', fields)
        const cancelPreloaderUpdateDirectoryRecord = this.$preloader('update_directory_record', 'Изменение элемента справочника')

        this.callUpdateRecordFields({
            ID: this.recordId,
            Fields: fields
        })
            .then(() => {
                // Закрываем окно добавления record
                this.emitClose()
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderUpdateDirectoryRecord())
    }

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose(): void {
        // Do nothing
    }
}
