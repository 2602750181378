




































import { Component, Vue, Ref } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import dynamicSort from '@/tools/sort/dynamicSort'
import { Numerator } from '@/model/numerator.js'

import BaseListPage from '@/components/common/BaseListPage.vue'
import ListCard from '@/components/common/cards/ListCard.vue'
import SelectCenterId from '@/components/common/SelectCenterId.vue'
import EditNumerator from '@/components/numerators/EditNumerator.vue'

@Component({
    components: {
        BaseListPage,
        ListCard,
        SelectCenterId,
        EditNumerator
    }
})

export default class Numerators extends Vue {
    @Ref() readonly editNumerator!: EditNumerator

    // *************************************************************
    // DATA PARAMS
    searchCenterId = ''
    searchText = ''
    showAdd = false
    saveDisabled = {
        disabled: false,
        reason: ''
    }
    centerId = ''
    visibleCreationModal = false

    // *************************************************************
    // COMPUTED
    @Getter('numerator/getNumerators') getNumerators!: Array<Numerator>

    get filteredNumerators() {
        let numerators = this.getNumerators
        if (this.searchText) {
            numerators = numerators.filter((num: Numerator) => num.name.toLowerCase().includes(this.searchText.toLowerCase()))
        }
        if (this.searchCenterId) {
            numerators = numerators.filter((num: Numerator) => num.centerId === this.searchCenterId)
        }
        return numerators.sort(dynamicSort('name'))
    }

    // *************************************************************
    // METHODS
    changeSaveDisabled(e: { disabled: boolean, reason: string }): void {
        this.saveDisabled.disabled = e.disabled
        this.saveDisabled.reason = e.reason
    }

    openNumerator(id: string): void {
        Vue.nextTick(() => {
            this.$router.push({
                name: 'document',
                params: {
                    section: 'system',
                    module: 'numerators',
                    id
                }
            })
        }, 0)
    }

    create(): void {
        if (this.saveDisabled.disabled) {
            this.$snotify.warning(`Ошибка: "${this.saveDisabled.reason}"`)
            return
        }

        this.editNumerator.create()
    }

    onCreated(id: string): void {
        this.visibleCreationModal = false
        this.openNumerator(id)
    }
}
