
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User, Role } from '@/model/User'
import { DashboardItem, Configuration } from '@/store/items/model'
import { DocumentType } from '@/model/DocumentType'

import DashboardCard from '@/pages/DashboardCard.vue'

@Component({
    components: { DashboardCard }
})

export default class Dashboard extends Vue {
    @Prop({ default: '' }) role!: string

    // *************************************************************
    // DATA PARAMS
    // clientHeight = window.innerHeight,
    // clientWidth = document.body.clientWidth - 48

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User | null

    @Getter('items/getItemsBySection') getItemsBySection!: (section: string) => Array<DashboardItem>
    @Getter('items/getConfiguration') getConfiguration!: (role: string) => Configuration

    @Getter('getSelectedCenter') getSelectedCenter!: string
    @Getter('document_type/getTypesByCenterId') getTypesByCenterId!: (centerId: string) => Array<DocumentType>

    get dashItems(): Array<DashboardItem> {
        if (this.role === 'section') {
            const items: Array<DashboardItem> = this.getItemsBySection(this.$route.params.section)
            if (this.$route.params.section !== 'documents') {
                return items
            }

            if (this.getSelectedCenter === 'root') {
                return items
            }

            const docTypesFromCenter = this.getTypesByCenterId(this.getSelectedCenter).map((docType: DocumentType) => docType.id)
            return items.filter((d: DashboardItem) => docTypesFromCenter.includes(d.name))
        } else {
            return this.getItemsBySection('root')
        }
    }

    get configuration(): Configuration {
        if (this.role === 'section') {
            return this.getConfiguration('section')
        }

        // this.role === "root"
        return this.getConfiguration('root')
    }

    get dashItemsFilterByRoles(): Array<DashboardItem> {
        return this.dashItems.filter((d: DashboardItem) => {
            if (d.requiredRole === '') {
                // Возвращаем все items без ролей
                return true
            }
            if (this.getMe?.roles?.length) {
                return this.getMe.roles.find((role: Role) => role.name === d.requiredRole)
            }
            return false
        })
    }


    // *************************************************************
    // METHODS
    selectSection(name: string): void {
        switch (this.role) {
        case 'root':
            this.$router.push({
                name: 'section',
                params: { section: name }
            })
            break
        case 'section':
            this.$router.push({
                name: 'module',
                params: { module: name }
            })
            break
        default:
            // WTF
        }
    }
}
