import { ActionTree } from 'vuex'
import { RootState } from '@/store'

import DocumentGetters from '@/store/document/_getters'
import DocumentMutations from '@/store/document/_mutations'
import DocumentActions from '@/store/document/_actions/document'
import DocumentWorkflowActions from '@/store/document/_actions/workflow'

import { Document } from '@/model/Document'

export class DocumentState {
    documents: Array<Document> = []

    documentsModificationTime: { [documentId: string]: number } = {} //document.id: Number(document.modificationTime)
    documentsMap: { [documentId: string]: Document } = {}
}

const actions: ActionTree<DocumentState, RootState> = {
    ...DocumentActions,
    ...DocumentWorkflowActions
}

const DocumentSubModule = {
    namespaced: true,
    state: new DocumentState(),
    getters: DocumentGetters,
    mutations: DocumentMutations,
    actions
}

export default DocumentSubModule
