import { ActionTree } from 'vuex'
import { RootState } from '@/store'

import DocumentTypeGetters from '@/store/document_type/_getters'
import DocumentTypeMutations from '@/store/document_type/_mutations'
import TypeActions from '@/store/document_type/_actions/document_type'
import RequisiteActions from '@/store/document_type/_actions/requisite'

import { DocumentType } from '@/model/DocumentType'

export class DocumentTypeState {
    documentTypes: Array<DocumentType> = []
}

const actions: ActionTree<DocumentTypeState, RootState> = {
    ...TypeActions,
    ...RequisiteActions,

    cleanUpState({ commit }) {
        commit('cleanUpState')
    }
}

const DocumentTypeSubModule = {
    namespaced: true,
    state: new DocumentTypeState(),
    getters: DocumentTypeGetters,
    mutations: DocumentTypeMutations,
    actions
}

export default DocumentTypeSubModule
