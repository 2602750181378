import { Actor, UserInfo, UserName, UnitInfo } from '@/model/old/actor1.js'

export const newActor = (data) => {
    return !data ? undefined : new Actor(
        !data.User ? undefined : new UserInfo(
            data.User.ID,
            new UserName(
                data.User.Name.First,
                data.User.Name.Middle,
                data.User.Name.Last
            )
        ),
        !data.Unit ? undefined : new UnitInfo(
            data.Unit.ID,
            data.Unit.Name
        )
    )
}

export const newUserInfo = (data) => {
    return new UserInfo(
        data.ID,
        new UserName(
            data.Name.First,
            data.Name.Middle,
            data.Name.Last
        )
    )
}

export const newUnitInfo = (data) => {
    return new UnitInfo(
        data.ID,
        data.Name
    )
}
