var render = function () {
  var _vm$directory, _vm$directory2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-directory"
  }, [_c('div', {
    staticClass: "mt-5"
  }, [_c('InputBase', {
    staticClass: "fs-24",
    attrs: {
      "disabled": (_vm$directory = _vm.directory) === null || _vm$directory === void 0 ? void 0 : _vm$directory.external,
      "loading": _vm.loadingName,
      "required": "",
      "placeholder": "Имя справочника"
    },
    on: {
      "enter": _vm.updateName,
      "blur": _vm.updateName
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), !_vm.name.trim() ? _c('div', {
    staticClass: "form__error"
  }, [_vm._v("\n            Имя справочника не может быть пустым\n        ")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "pb-5"
  }, [_c('div', {
    staticClass: "flex-row-center justify-between"
  }, [_c('h3', {
    staticClass: "edit-directory__title_h3"
  }, [_c('span', [_vm._v("Поля справочника: ")]), _vm._v(" "), _vm.errorFields ? _c('span', {
    staticClass: "red"
  }, [_vm._v("\n                    В справочнике должно быть хотя бы одно обязательное поле\n                ")]) : _vm._e()]), _vm._v(" "), _c('Button', {
    attrs: {
      "color": "green",
      "disabled": _vm.addFieldDisabled.disabled
    },
    on: {
      "click": _vm.addColumn
    }
  }, [_vm._v("Добавить поле")])], 1), _vm._v(" "), _vm._l(_vm.fields.filter(function (f) {
    return f.new;
  }), function (field, index) {
    return _c('DirectoryColumn', {
      key: "".concat(index, "-").concat(field.new),
      staticClass: "mb-3",
      attrs: {
        "column-info": field,
        "directory-id": _vm.directoryId
      },
      on: {
        "removeColumn": function removeColumn($event) {
          return _vm.showRemoveColumn(field.index);
        },
        "changeColumn": function changeColumn($event) {
          return _vm.changeColumn($event);
        }
      }
    });
  }), _vm._v(" "), _vm._l(_vm.fields.filter(function (f) {
    return !f.new;
  }), function (field, index) {
    return _c('DirectoryColumn', {
      key: index,
      staticClass: "mb-3",
      attrs: {
        "column-info": field,
        "directory-id": _vm.directoryId
      },
      on: {
        "removeColumn": function removeColumn($event) {
          return _vm.showRemoveColumn(field.index);
        },
        "changeColumn": function changeColumn($event) {
          return _vm.changeColumn($event);
        }
      }
    });
  }), _vm._v(" "), _vm.fields.length ? _c('div', {
    staticClass: "edit-directory__display-name"
  }, [_c('h3', {
    staticClass: "edit-directory__title_h3"
  }, [_vm._v("\n                Редактирование отображаемого значения элементов справочника\n            ")]), _vm._v(" "), _vm._m(0), _vm._v(" "), _c('div', [_c('InputBase', {
    attrs: {
      "disabled": (_vm$directory2 = _vm.directory) === null || _vm$directory2 === void 0 ? void 0 : _vm$directory2.external,
      "loading": _vm.loadingDisplayName,
      "required": "",
      "placeholder": "текст {№поля} текст {№поля} и т.д."
    },
    on: {
      "blur": _vm.updateDisplayName,
      "enter": _vm.updateDisplayName
    },
    model: {
      value: _vm.displayName,
      callback: function callback($$v) {
        _vm.displayName = $$v;
      },
      expression: "displayName"
    }
  }), _vm._v(" "), !_vm.displayName.trim() ? _c('div', {
    staticClass: "form__error"
  }, [_vm._v("\n                    Отображаемое значение справочника должно быть заполнено\n                ")]) : _vm._e()], 1), _vm._v(" "), _c('h4', {
    staticClass: "edit-directory__title_h4"
  }, [_vm._v("\n                Результат:\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "edit-directory__result-display-name"
  }, [_vm._v("\n                " + _vm._s(_vm.resultDisplayName) + "\n            ")])]) : _vm._e()], 2), _vm._v(" "), _c('RemoveModal', {
    ref: "removeModal",
    attrs: {
      "visible": _vm.visibleRemoveModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleRemoveModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-3"
  }, [_vm._v("\n                Когда пользователь выбирает элемент справочника в документе, какое именно\n                поле/поля будут отображаться при выборе.\n                "), _c('br'), _vm._v("\n                Например, необходимо отразить 'Имя контрагента' - 'ИНН Контрагента'.\n                "), _c('br'), _vm._v("\n                Просто указывайте текст и номера полей между символами '{}' ({1}).\n                "), _c('br'), _vm._v("\n                Нумерация полей начинается с нуля (0).\n                "), _c('br'), _vm._v("\n                Не забывайте про пробелы!\n            ")]);
}]

export { render, staticRenderFns }