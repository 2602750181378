





































import { Component, Prop, Vue } from 'vue-property-decorator'

import { WorkflowEvent } from '@/model/WorkflowEvent'

import Card from '@/components/common/cards/Card.vue'

@Component({
    components: { Card }
})

export default class UserEvents extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) events!: Array<WorkflowEvent>
    @Prop({ default: '' }) title!: string
    @Prop({ default: '' }) type!: string

    // *************************************************************
    // METHODS
    openEvents(): void {
        this.$router.push({
            name: 'module',
            params: {
                section: 'system',
                module: 'events'
            },
            query: {
                type: this.type
            }
        })
    }

    openDocument(event: WorkflowEvent): void {
        if (!event.document) {
            return
        }

        // Открыть документ
        if (this.$route.params.id !== event.document.id) {
            this.$router.push({
                name: 'document',
                params: {
                    section: 'documents',
                    module: event.document.type,
                    id: event.document.id
                }
            })
        }
    }
}
