










































import { Component, Prop, Ref, Vue, Watch, Emit } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Directory, DirectoryField, Record, RecordField } from '@/model/Directory'

@Component({})

export default class DirectoryRecordsSelect extends Vue {
    @Ref() readonly tableBody!: HTMLTableElement

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) searchText!: string
    @Prop({ default: '' }) unitId!: string
    @Prop({ default: '' }) directory!: Directory // null in this component impossible

    // *************************************************************
    // DATA PARAMS
    cellWidth: Array<number> = []
    tableBodyWidth = 0

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryRecords') getDirectoryRecords!: (unitId: string, directoryId: string) => Array<Record>

    get directoryFields(): Array<DirectoryField> {
        return this.directory.fields
    }

    get records(): Array<Record> {
        let records: Array<Record> = []
        if (this.directory.external && this.unitId) {
            records = this.getDirectoryRecords(this.unitId, this.directory.id)
        } else if (!this.directory.external) {
            records = this.getDirectoryRecords(this.directory.centerId, this.directory.id)
        }

        // Фильтрация по строке поиска
        const query = this.searchText.toLowerCase().trim()
        const isIncludeQuery = (rec: Record) => rec.fields.some((f: RecordField) => f.displayName.toLowerCase().includes(query))

        return records.filter(isIncludeQuery)
    }

    get recordsAccessible(): boolean {
        // Через ref получаем вторую таблицу, в которой отражены records - ее верстаем
        // в отдельном блоке, чтобы была возможность добавить overflow.
        // Проверяем произошел ли рендеринг, т.е. сработали все v-for
        // Данный компонент из родительского компонента получает directory,
        // только после этого сработают v-for
        const table = this.tableBody
        return !!(table && table.children && table.children[0])
    }

    // *************************************************************
    // WATCH
    @Watch('records', { immediate: true, deep: true })
    handleRecordsChange(to: Array<Record>): void {
        if (to && to.length) {
            // nextTick необходим, иначе метод сработает раньше, чем необходимо
            Vue.nextTick(this.realignHeader)
        }
    }

    // *************************************************************
    // METHODS
    realignHeader(): void {
        // Передаем ширину table body в data, чтобы потом установить ее в шапку таблицы,
        // иначе она съедет на ширину прокрутки
        this.tableBodyWidth = this.tableBody.offsetWidth
        // Вычисляем ширину каждой ячейки в первой строке в body-table
        // передаем эти размеры в data в cellWidth, чтобы по ним устанавливать
        // ширину ячеек в заголовке таблицы
        const table = this.tableBody
        if (this.recordsAccessible) {
            for (let i = 0; i < table.children[0].children.length; i++) {
                if (this.cellWidth[i] !== table.children[0].children[i].clientWidth) {
                    Vue.set(
                        this.cellWidth,
                        i,
                        table.children[0].children[i].clientWidth + 1
                    )
                }
            }
        }
    }

    // *************************************************************
    // EMIT
    @Emit('changeRecord')
    emitChangeRecord(record: Record): Record {
        return record
    }
}
