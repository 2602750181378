var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "message"
  }, [_c('div', {
    staticClass: "message__info"
  }, [_c('div', [_vm._v(_vm._s(_vm.actorName))]), _vm._v(" "), _c('div', {
    staticClass: "fs-12 mx-1"
  }, [_vm._v("\n            " + _vm._s(_vm.event.creationTimeStampString) + "\n        ")])]), _vm._v(" "), _c('div', {
    class: {
      message__text: true,
      message__text_left: _vm.alignment === 'left',
      message__text_right: _vm.alignment === 'right'
    }
  }, [_vm._v("\n        " + _vm._s(_vm.event.fields.message) + "\n    ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }