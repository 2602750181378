import { cuntPB } from 'api'
import store from '@/store'

import dayjs from 'dayjs'

import { Actor, UserInfo } from '@/model/Actor'
import { DocumentTypeRequisite, DocumentTypeRequisiteType } from '@/model/DocumentType'
import { Attachment } from '@/model/File'

////////////////////////////////////////////////////////////////
////            ////////////////////////////////////////////////
////  Document  ////////////////////////////////////////////////
////            ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
type DOC_STATUS = 'draft' | 'approval' | 'execution' | 'archived'

export const DOC_STATUSES: { [status: string]: string } = {
    draft: 'Черновик',
    approval: 'На согласовании',
    execution: 'В работе',
    archived: 'Архив'
}

export const DOC_LINK_TYPES: { [key: string]: string } = {
    reply_to: 'В ответ на',
    contract: 'Договор',
    act: 'Акт'
}

export class DocumentBase {
    public id = ''
    public name = ''
    public type = ''
    public unit = ''
    public originUnit = ''
    public author: UserInfo = new UserInfo(null)
    public number = ''
    public status: DOC_STATUS = 'draft'

    public statusName = 'Черновик'

    public statusChangeTime = 0
    public creationTime = 0
    public modificationTime = 0

    constructor(base: cuntPB.DocumentBase | null) {
        if (base) {
            const status = base.Status as DOC_STATUS

            this.id = base.ID
            this.name = base.Name
            this.type = base.Type
            this.unit = base.Unit
            this.originUnit = base.OriginUnit
            this.author = new UserInfo(base.Author ?? null)
            this.number = base.Number
            this.status = status

            this.statusChangeTime = base.StatusChangeTime
            this.creationTime = base.CreationTime
            this.modificationTime = base.ModificationTime

            this.statusName = DOC_STATUSES[status]
        }
    }

    get creationTimeLocalizedLongString(): string {
        return dayjs(this.creationTime / 1000).format('dddd, MMMM DD, YYYY HH:mm')
    }

    get creationTimeLocalizedShortString(): string {
        return dayjs(this.creationTime / 1000).format('DD/MM/YYYY')
    }
}

export class DocumentRequisite {
    public id: string
    public type: DocumentTypeRequisite
    public value: string
    public recordName: string

    constructor(req: cuntPB.DocumentRequisite) {
        this.id = req.ID
        this.type = new DocumentTypeRequisite(req.Type ?? null)
        this.value = req.Value
        this.recordName = req.RecordName
    }

    get displayName(): string {
        if (this.type.type === 'req_do') {
            if (this.value) {
                if (this.recordName) {
                    return this.recordName
                } else {
                    return '?????'
                }
            }
        }

        if (this.type.type === 'req_table') {
            return 'Таблица'
        }

        if (this.type.type === 'req_date' && this.value) {
            return dayjs(Number(this.value)).format('DD/MM/YYYY HH:mm')
        }

        return this.value
    }
}

export type DocumentLinkKind = 'reply_to' | 'contract' | 'act'

export class DocumentLink {
    public target: string
    public kind: DocumentLinkKind

    constructor(link: cuntPB.DocumentLink) {
        this.target = link.Target
        this.kind = link.Kind as DocumentLinkKind
    }
}

export class WorkflowElementSpecial {
    public name: string

    constructor(special: cuntPB.WorkflowElementSpecial) {
        this.name = special.Name
    }
}

export class WorkflowApproval {
}

export type WorkflowTaskType = 'width_confirmation'

export class WorkflowTask {
    public text: string
    public description: string
    public type: WorkflowTaskType

    constructor(task: cuntPB.WorkflowTask) {
        this.text = task.Text
        this.description = task.Description
        this.type = task.Type as WorkflowTaskType
    }
}

export class WorkflowElement {
    public id: string
    public actor: Actor | null
    public special: WorkflowElementSpecial | null
    public inputs: Array<string>
    public outputs: Array<string>
    public passed: boolean
    public pending: boolean
    public autoAccept: boolean
    public approval: WorkflowApproval | null
    public task: WorkflowTask | null

    constructor(elem: cuntPB.WorkflowElement) {
        this.id = elem.ID
        this.actor = elem.Actor ? new Actor(elem.Actor) : null
        this.special = elem.Special ? new WorkflowElementSpecial(elem.Special) : null
        this.inputs = elem.Inputs
        this.outputs = elem.Outputs
        this.passed = elem.Passed
        this.pending = elem.Pending
        this.autoAccept = elem.AutoAccept
        this.approval = elem.Approval ? new WorkflowApproval() : null
        this.task = elem.Task ? new WorkflowTask(elem.Task) : null
    }
}

export type WorkflowStageType = 'draft' | 'approval' | 'execution' | 'fluent' | 'archived'

export class WorkflowStage {
    public type: WorkflowStageType
    public displayName: string
    public completed: boolean
    public graph: Array<WorkflowElement>

    public pending = false

    public pendingApprovals: Array<WorkflowElement>
    public myPendingApproval: string
    public pendingTasks: Array<WorkflowElement> = []
    public myPendingTasks: Array<WorkflowElement> = []

    constructor(stage: cuntPB.WorkflowStage, documentStatus: DOC_STATUS) {
        const graph: Array<WorkflowElement> = []
        const pendingApprovals: Array<WorkflowElement> = []
        let myPendingApproval = ''
        const pendingTasks: Array<WorkflowElement> = []
        const myPendingTasks: Array<WorkflowElement> = []

        const status = store.getters['me/getStatus']
        let userId = ''
        if (status && status.authenticated) {
            userId = status.authenticated.user
        }

        stage.Graph.forEach((elem: cuntPB.WorkflowElement) => {
            const element = new WorkflowElement(elem)

            if (documentStatus !== 'draft' && documentStatus !== 'archived' && !stage.Completed && element.pending) {
                this.pending = true
            }

            if (!stage.Completed && element.pending && element.actor?.user) {
                if (documentStatus === 'approval') {
                    pendingApprovals.push(element)
                    if (element.actor.user.id === userId) {
                        myPendingApproval = element.id
                    }
                } else {
                    if (documentStatus === 'execution') {
                        pendingTasks.push(element)
                        if (element.actor.user.id === userId) {
                            myPendingTasks.push(element)
                        }
                    }
                }
            }

            graph.push(element)
        })

        this.type = stage.Type as WorkflowStageType
        this.displayName = stage.DisplayName
        this.completed = stage.Completed
        this.graph = graph
        this.pendingApprovals = pendingApprovals
        this.myPendingApproval = myPendingApproval
        this.pendingTasks = pendingTasks
        this.myPendingTasks = myPendingTasks
    }
}

export class DocumentWorkFlow {
    public stages: Array<WorkflowStage>

    constructor(wf: cuntPB.Workflow | null, documentStatus: DOC_STATUS) {
        if (!wf) {
            this.stages = []
        } else {
            this.stages = wf.Stages.map((stage: cuntPB.WorkflowStage) => new WorkflowStage(stage, documentStatus))
        }
    }
}

export class DocumentOneAss {
    public author: UserInfo | null

    constructor(oneAss: cuntPB.DocumentOneAss) {
        this.author = !oneAss.Author ? null : new UserInfo({
            ID: oneAss.Author.ID,
            Name: {
                First: oneAss.Author.FirstName,
                Middle: oneAss.Author.MiddleName,
                Last: oneAss.Author.LastName
            }
        })
    }
}

export class Document {
    public base: DocumentBase
    public comment: string
    public requisites: Array<DocumentRequisite>
    public files: Array<Attachment>
    public links: Array<DocumentLink>
    public workflow: DocumentWorkFlow
    public oneAss: DocumentOneAss | null

    public filterDisplayName: string // общая строка для фильтрации документов в таблице

    public pendingApprovals: Array<WorkflowElement> = [] // Все, у кого есть approval.pending в данный момент
    public pendingTasks: Array<WorkflowElement> = [] // Все, у кого есть workflow.tasks в данный момент
    public myPendingApproval = '' // Есть ли в данный момент узлы !!pending на данного пользователя
    public myPendingTasks: Array<WorkflowElement> = [] // Есть ли в данный момент workflow.tasks !!pending на данного пользователя

    constructor(doc: cuntPB.Document) {
        const base = new DocumentBase(doc.Base ?? null)

        const searchString: Array<string> = [base.name, base.number, base.author.name.first, base.author.name.middle, base.author.name.last]

        const requisites: Array<DocumentRequisite> = []
        doc.Requisites.forEach((req: cuntPB.DocumentRequisite) => {
            const requisite = new DocumentRequisite(req)
            if (requisite.type.type !== 'req_table' && requisite.type.type !== 'req_editor') {
                searchString.push(requisite.displayName)
            }

            requisites.push(requisite)
        })

        this.base = base
        this.comment = doc.Comment
        this.requisites = requisites
        this.files = doc.Files.map((file: cuntPB.File) => new Attachment(file))
        this.links = doc.Links.map((link: cuntPB.DocumentLink) => new DocumentLink(link))
        this.workflow = new DocumentWorkFlow(doc.Workflow ?? null, base.status)
        this.oneAss = doc.OneAss ? new DocumentOneAss(doc.OneAss) : null

        this.filterDisplayName = searchString.join(' ')

        ///////// MyApproval
        this.workflow.stages.forEach((stage: WorkflowStage) => {
            if (stage.pendingApprovals.length !== 0) {
                this.pendingApprovals = stage.pendingApprovals
                this.myPendingApproval = stage.myPendingApproval
            }
            if (stage.pendingTasks.length !== 0) {
                this.pendingTasks = stage.pendingTasks
                this.myPendingTasks = stage.myPendingTasks
            }
            if (stage.pending) {
                this.base.statusName = stage.displayName
            }
        })
    }
}

////////////////////////////////////////////////////////////////
////                  //////////////////////////////////////////
////  DocumentEvent   //////////////////////////////////////////
////                  //////////////////////////////////////////
////////////////////////////////////////////////////////////////
export class DocumentEventMessage {
    public message: string

    constructor(field: cuntPB.Message | null) {
        this.message = field?.Message ?? ''
    }
}

export class DocumentEventStatusTransition {
    public previous = ''
    public current = ''

    constructor(field: cuntPB.StatusTransition | null) {
        if (field) {
            this.previous = field.Previous
            this.current = field.Current
        }
    }
}

export class DocumentEventRegistration {
    public number: string

    constructor(field: cuntPB.Registration | null) {
        this.number = field?.Number ?? ''
    }
}

export class DocumentEventApproved {
    public actor: Actor
    public elementId: string

    constructor(field: cuntPB.ActorElementIDPair | null) {
        this.actor = new Actor(field?.Actor || null)
        this.elementId = field?.ElementID || ''
    }
}

export class DocumentEventRejectedVictim {
    public actor: Actor | null
    public elementId: string

    constructor(victim: cuntPB.ActorElementIDPair) {
        this.actor = victim.Actor ? new Actor(victim.Actor) : null
        this.elementId = victim.ElementID
    }

    get fullName(): string {
        if (!this.actor) {
            return ''
        }

        let name = ''
        if (this.actor.user) {
            name = this.actor.user.name.shortName
        }

        if (this.actor.unit) {
            name = `${name}/${this.actor.unit.name}`
        }

        return name

    }
}

export class DocumentEventRejected {
    public actor: Actor = new Actor(null)
    public elementId = ''
    public reason = ''
    public victims: Array<DocumentEventRejectedVictim> = []

    constructor(field: cuntPB.Rejected | null) {
        if (field) {
            this.actor = new Actor(field.Actor?.Actor || null)
            this.elementId = field?.Actor?.ElementID ?? ''
            this.reason = field.Reason
            this.victims = field.Victims.map((v: cuntPB.ActorElementIDPair) => new DocumentEventRejectedVictim(v))
        }
    }
}

export class DocumentEventOptionRequisiteUpdated {
    public recordId = ''
    public requisiteId = ''
    public requisiteName = ''
    public requisiteType: DocumentTypeRequisiteType = 'req_string'
    public value = ''

    constructor(field: cuntPB.OptionRequisiteUpdated | null) {
        if (field) {
            this.recordId = field.RecordID
            this.requisiteId = field.RequisiteID
            this.requisiteName = field.RequisiteName
            this.requisiteType = field.RequisiteType as DocumentTypeRequisiteType
            this.value = field.Value
        }
    }
}

export type DocumentEventLinkedTaskType = 'linked_task_created' | 'linked_task_completed' | 'linked_task_rejected'
    | 'linked_task_confirmed' | 'linked_task_delegated' | 'linked_task_confirmation_delegated'
    | 'linked_task_closed' | 'linked_task_reopened'

export class DocumentEventLinkedTask {
    public type: DocumentEventLinkedTaskType = 'linked_task_created'
    public id = ''
    public text = ''
    public author: Actor = new Actor(null)
    public assignee: Actor = new Actor(null)
    public confirmer: Actor | null = null

    constructor(field: cuntPB.LinkedTask | null) {
        if (field) {
            this.type = field.Type as DocumentEventLinkedTaskType
            this.id = field.ID
            this.text = field.Text
            this.author = new Actor(field.Author || null)
            this.assignee = new Actor(field.Assignee || null)
            this.confirmer = field.Confirmer ? new Actor(field.Confirmer) : null
        }
    }

    get displayName(): string {
        if (this.type === 'linked_task_created') {
            return 'Создана задача'
        }
        if (this.type === 'linked_task_completed') {
            return 'Задача выполнена'
        }
        if (this.type === 'linked_task_rejected') {
            return 'Выполнение задачи отклонено'
        }
        if (this.type === 'linked_task_confirmed') {
            return 'Выполнение задачи подтверждено'
        }
        if (this.type === 'linked_task_delegated') {
            return 'Изменен исполнитель задачи'
        }
        if (this.type === 'linked_task_confirmation_delegated') {
            return 'Изменен подтверждающий задачи'
        }
        if (this.type === 'linked_task_closed') {
            return 'Задача отменена'
        }
        // reopened
        return 'Задача переоткрыта'

    }
}

export type DocumentEventType = 'message' | 'status_transition' | 'registration' |  'approved' | 'rejected' | 'optional_requisite_update' | 'linked_task'

export class DocumentEvent {
    public id: string
    public documentId: string
    public type: DocumentEventType
    public actor: UserInfo | null
    public ts: number

    public fields: DocumentEventMessage | DocumentEventStatusTransition | DocumentEventRegistration | DocumentEventApproved | DocumentEventRejected | DocumentEventOptionRequisiteUpdated | DocumentEventLinkedTask = new DocumentEventMessage(null)

    constructor(event: cuntPB.DocumentEvent) {
        const type = event.Type as DocumentEventType
        this.id = event.ID
        this.documentId = event.DocumentID
        this.type = type
        this.actor = new UserInfo(event.Author ?? null)
        this.ts = event.TS

        if (this.type === 'message') {
            this.fields = new DocumentEventMessage(event.Message || null)
        } else if (this.type === 'status_transition') {
            this.fields = new DocumentEventStatusTransition(event.StatusTransition || null)
        } else if (this.type === 'registration') {
            this.fields = new DocumentEventRegistration(event.Registration || null)
        } else if (this.type === 'approved') {
            this.fields = new DocumentEventApproved(event.Approved || null)
        } else if (this.type === 'rejected') {
            this.fields = new DocumentEventRejected(event.Rejected || null)
        } else if (this.type === 'optional_requisite_update') {
            this.fields = new DocumentEventOptionRequisiteUpdated(event.OptionRequisiteUpdated || null)
        } else if (this.type === 'linked_task') {
            this.fields = new DocumentEventLinkedTask(event.LinkedTask || null)
        }
    }

    get creationTimeStampString(): string {
        return dayjs(this.ts / 1000).format('DD/MM/YYYY HH:mm')
    }
}
