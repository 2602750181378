import api from 'api1'
import newFamiliarization from '@/model/convert/newFamiliarization'

export default function ({ dispatch }) {
    return new Promise((resolve, reject) => {
        api.cunt.FamiliarizationSvcClient.archivedListStream(api.cunt.ArchivedListFamiliarizationStreamReq({}),
            data => {
                data = api.cunt.ArchivedFamiliarizationStreamMessage(data)

                if (data.Familiarization) {
                    dispatch('putFamiliarization', {
                        familiarization: newFamiliarization(data.Familiarization)
                    })
                } else if (data.EOS === 'EOS') {
                    resolve()
                } else {
                    console.error('FamiliarizationSvcClient::callArchivedListStream - пришла какая-то дикая хрень!')
                }
            }, error => {
                (error ? console.warn : console.log)('::DONE::FamiliarizationSvcClient.archivedListStream:', error)
                reject(error)
            },
            status => {
                console.warn('archivedListStream::status', status)
            },
            {
                deadline: 0
            }
        )
    })
}
