


































import { Component, Emit, PropSync, Vue } from 'vue-property-decorator'

import ButtonGreen from '@/components/common/buttons/ButtonGreen.vue'
import ButtonBase from '@/components/common/buttons/ButtonBase.vue'

@Component({
    components: {
        ButtonGreen,
        ButtonBase
    }
})

export default class ConfirmModalNew extends Vue {
    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean

    // *************************************************************
    // DATA PARAMS
    title = ''
    text = ''
    confirmCallback: ReturnType<typeof Function> | undefined = undefined
    cancelCallback: ReturnType<typeof Function> | undefined = undefined

    // *************************************************************
    // METHODS
    hide(): void {
        this.syncedVisible = false

        this.title = 'Подтверждение'
        this.text = ''
        this.confirmCallback = undefined
        this.cancelCallback = undefined
    }

    show(params: { title?: string, text: string, onConfirm: () => void, onCancel?: () => void }): void {
        this.syncedVisible = true
        if (params.title) {
            this.title = params.title
        }
        this.text = params.text
        this.confirmCallback = params.onConfirm
        this.cancelCallback = params.onCancel
    }

    onConfirm(): void {
        if (this.confirmCallback) {
            this.confirmCallback()
        }
    }

    onCancel(): void {
        if (this.cancelCallback) {
            this.cancelCallback()
        } else {
            this.hide()
        }
    }

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose(): void {
        // Do nothing
    }

    @Emit('confirm')
    emitConfirm(): void {
        // Do nothing
    }
}
