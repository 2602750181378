import NumeratorGetters from '@/store/numerator/_getters'
import NumeratorMutations from '@/store/numerator/_mutations'
import NumeratorActions from '@/store/numerator/_actions'

import { Numerator } from '@/model/numerator.js'

export class NumeratorState {
    numerators: Array<Numerator> = [] // Нумераторы без modificationTime
    numModificationTime: { [numeratorId: string]: number } = {} // numeratorId: modificationTime
    versions: { [numeratorId: string]: number } = {} //numerator.id: version
    savesNumerators: { [numeratorId: string]: Numerator } = {} = {} // numerator.id: numerator - это на случай ошибки, чтобы вернуть в numerators
}

const NumeratorSubModule = {
    namespaced: true,
    state: new NumeratorState(),
    getters: NumeratorGetters,
    mutations: NumeratorMutations,
    actions: NumeratorActions
}

export default NumeratorSubModule
