import { identityPB } from 'api'

export class StatusCompletedFactors {
    public verifierName: string
    public identityName: string
    public identity: string

    constructor(completedFactor: identityPB.StatusCompletedFactors) {
        this.verifierName = completedFactor.VerifierName
        this.identityName = completedFactor.IdentityName
        this.identity = completedFactor.Identity
    }
}

export class StatusAuthenticating {
    public signIn: boolean
    public signUp: boolean
    public attach: boolean
    public completedFactors: Array<StatusCompletedFactors>
    public remainingFactors: number

    constructor(authenticating: identityPB.StatusAuthenticating) {
        console.log('authenticating', authenticating)
        const factors = authenticating.CompletedFactors.map((f: identityPB.StatusCompletedFactors) => new StatusCompletedFactors(f))

        this.signIn = !!authenticating.SignIn
        this.signUp = !!authenticating.SignUp
        this.attach = !!authenticating.Attach
        this.completedFactors = factors
        this.remainingFactors = authenticating.RemainingFactors
    }
}

export class StatusAuthenticated {
    public user: string

    constructor(authenticated: identityPB.StatusAuthenticated) {
        this.user = authenticated.User
    }
}

export class AuthenticationStatus {
    public token: string
    public authenticating: StatusAuthenticating | null
    public authenticated: StatusAuthenticated | null

    constructor(status: identityPB.Status) {
        this.token = status.Token
        this.authenticating = status.Authenticating ? new StatusAuthenticating(status.Authenticating) : null
        this.authenticated = status.Authenticated ? new StatusAuthenticated(status.Authenticated) : null
    }
}

export class IdentityData {
    public name: string
    public identity: string

    constructor(name: string, identity: string) {
        this.name = name
        this.identity = identity
    }
}

export class IdentitiesAndVerifiersList {
    public identities: Array<IdentityData>
    public verifiers: Array<string>

    constructor(data: identityPB.ListMyIdentitiesAndVerifiersResp) {
        this.identities = data.Identities.map((identity) => new IdentityData(identity.Name, identity.Identity))
        this.verifiers = data.Verifiers
    }
}
