var render = function () {
  var _vm$documentRequisite;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('Popover', {
    attrs: {
      "arrow": true
    }
  }, [_c('div', {
    staticClass: "document-line__cell",
    attrs: {
      "slot": "host"
    },
    slot: "host"
  }, [_vm._v("\n            " + _vm._s((_vm$documentRequisite = _vm.documentRequisite) === null || _vm$documentRequisite === void 0 ? void 0 : _vm$documentRequisite.displayName) + "\n        ")]), _vm._v(" "), _vm.showPopover ? _c('div', {
    staticClass: "document-line__popover",
    attrs: {
      "slot": "popover"
    },
    slot: "popover"
  }, [_vm._v("\n            " + _vm._s(_vm.documentRequisite.displayName) + "\n        ")]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }