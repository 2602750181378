<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState({ documentTypes: state => state.document_type.documentTypes }),
        ...mapGetters('familiarization', ['getFamiliarizationById']),
        familiarizationId() {
            return this.$route.params.id
        },
        familiarization() {
            return this.getFamiliarizationById(this.familiarizationId)
        },
        typeNames() {
            const typeNames = {} // typeId: name
            this.documentTypes.forEach(type => typeNames[type.id] = type.name)
            return typeNames
        }
    },
    methods: {
        openDocument(doc) {
            this.$router.push({
                name: 'document',
                params: {
                    section: 'documents',
                    module: doc.type,
                    id: doc.id
                }
            })
        }
    }
}
</script>

<style lang="stylus">
@import '~@/assets/styles/colors.styl'

.familiarization__margin
    margin 20px 0

.familiarization__item
    margin-left 5px
    font-weight bold

.familiarization__document
    cursor pointer
    &:hover
        text-decoration solid underline $pink-1

</style>
