

























































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { TosserTerm } from '@/model/Tosser'
import { TosserRuleRequisite } from '@/components/tosser/model'
import { Record } from '@/model/Directory'

import Select from '@/components/common/select/Select.vue'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard.vue'
import DateTimeInput from '@/components/common/dateTimePicker/DateTimeInput.vue'
import InputNumber from '@/components/common/inputs/InputNumber.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'

@Component({
    components: {
        Select,
        InputBase,
        ActionSelectCard,
        DateTimeInput,
        InputNumber,
        ButtonDelete
    }
})

export default class TosserRuleTerm extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: null }) termInfo!: TosserTerm
    @Prop({ default: null }) requisites!: { [id: string]: TosserRuleRequisite }
    @Prop({ default: '' }) rootUnitId!: string

    // *************************************************************
    // DATA PARAMS
    term: TosserTerm = new TosserTerm({ Requisite: '', Operator: '', Value: '' })

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryRecords') getDirectoryRecords!: (unitId: string, directoryId: string) => Array<Record>
    @Getter('directories/getRecordDisplayName') getRecordDisplayName!: (recordId: string) => string

    get requisite(): TosserRuleRequisite | null {
        return this.requisites[this.term.requisite] ?? null
    }

    /** Элементы справочника */
    get directoryItems() {
        if (!this.requisite) {
            return []
        }

        if (this.requisite.type !== 'req_do') {
            return []
        }

        const records = this.getDirectoryRecords(this.rootUnitId, this.requisite.subType)

        if (records && records.length) {
            return records.filter(r => !r.archived).map(rec => {
                return {
                    id: rec.id,
                    name: this.getRecordDisplayName(rec.id)
                }
            })
        }

        return []
    }

    // *************************************************************
    // WATCH
    @Watch('termInfo', { immediate: true, deep: true })
    handleTermInfoChange(to: TosserTerm): void {
        this.term.id = to.id
        this.term.requisite = to.requisite
        this.term.operator = to.operator
        this.term.value = to.value
    }

    @Watch('term.requisite')
    handleRequisiteChange(to: string): void {
        // На каждое изменение реквизита обнуляем value
        this.term.value = ''
        this.term.operator = ''

        if (to) {
            const operators = this.requisites[to].operators
            // Если есть только один оператор для выбора, то это может
            // быть только равно и выбираем его автоматически
            if (operators.length === 1) {
                this.term.operator = 'eq'
            }
        }
    }

    @Watch('term', { deep: true })
    handleTermChange(): void {
        this.emitChangeTerm()
    }

    // *************************************************************
    // EMIT
    @Emit('changeTerm')
    emitChangeTerm(): TosserTerm {
        return this.term
    }

    @Emit('remove')
    emitRemove(): void {
        // Do nothing
    }
}
