var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "news-container"
  }, _vm._l(_vm.getNews, function (one) {
    return _c('HelpCard', {
      key: one.id,
      attrs: {
        "news": one
      }
    }, [_c('template', {
      slot: "content"
    }, [_vm._v("\n            " + _vm._s(one.content) + "\n        ")])], 2);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }