var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-links"
  }, [_c('DocumentBlockTitle', {
    attrs: {
      "title": "Связанные документы"
    }
  }, [_vm.isMe ? _c('ButtonAdd', {
    staticClass: "ml-5",
    on: {
      "click": _vm.showModal
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', _vm._l(_vm.links, function (link) {
    return _c('div', {
      key: link.target
    }, [_c('span', [_vm._v("\n                " + _vm._s(_vm.DOC_LINK_TYPES[link.kind]) + ":\n            ")]), _vm._v(" "), _c('Link', {
      staticClass: "ml-3 document-links__link",
      attrs: {
        "link": link.name
      },
      on: {
        "click": function click($event) {
          return _vm.openLink(link);
        }
      }
    })], 1);
  }), 0), _vm._v(" "), _c('DocumentChangeLinks', {
    ref: "modal"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }