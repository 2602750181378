var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Editor', {
    ref: "editor",
    staticClass: "editor",
    attrs: {
      "value": _vm.value,
      "initial-value": _vm.value,
      "height": _vm.height,
      "options": _vm.editorOptions,
      "initial-edit-type": "wysiwyg",
      "disabled": _vm.disabled
    },
    on: {
      "change": _vm.onChange
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }