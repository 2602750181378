import { ActionTree } from 'vuex'
import { WorkflowState } from '@/store/workflow'
import { RootState } from '@/store'

import { WorkflowTemplate, StageTemplate, WFVvs } from '@/model/Workflow'

const FlowActions: ActionTree<WorkflowState, RootState> = {
    addWFStage({ commit, dispatch, getters }, params: { stage: StageTemplate, position: string }) {
        // Записываем в state индекс активной вкладки
        // Причем сначала записываем индекс, а только потом добавляем новый блок
        const countStages = getters.getWorkflow.stages.length

        // Если мы добавляем stage в конец массива
        if (params.position === 'end') {
            dispatch('changeWFActiveStage', countStages)
        } else {
            // Если в начало, то делаем активным первый stage
            dispatch('changeWFActiveStage', 0)
        }

        // Очищаем activeNode,  т.к. активный node может быть только в активном stage
        dispatch('changeWFActiveNode', '')
        dispatch('changeWFActiveTask', '')
        commit('addWFStage', params)
    },

    changeWFAction({ commit }, actionId: string) {
        commit('changeWFAction', actionId)
    },

    changeWFActiveNode({ commit }, node: string) {
        commit('changeWFActiveNode', node)
    },

    changeWFActiveStage({ commit }, activeStage: number | null) {
        commit('changeWFActiveStage', activeStage)
    },

    changeWFActiveTask({ commit }, taskId: string) {
        commit('changeWFActiveTask', taskId)
    },

    changeWFStageDisplayName({ commit }, name: string) {
        commit('changeWFStageDisplayName', name)
    },

    changeWFStageVvs({ commit }, vvs: boolean) {
        commit('changeWFStageVvs', vvs)
    },

    changeWFStatic({ commit }, staticFlag: boolean) {
        commit('changeWFStatic', staticFlag)
    },

    changeWFVVS({ commit }, vvs: WFVvs) {
        commit('changeWFVVS', vvs)
    },

    removeWFStage({ commit }) {
        commit('removeWFStage')
    },

    moveWFStage({ commit, dispatch }, params: { stageIndex: number, newStageIndex: number }) {
        commit('moveWFStage', {
            stageIndex: params.stageIndex,
            newStageIndex: params.newStageIndex
        })
        dispatch('workflowChange', true)
    },

    putWorkflow({ commit }, workflow: WorkflowTemplate) {
        commit('putWorkflow', workflow)
    },

    workflowChange({ commit }, change: boolean) {
        commit('workflowChange', change)
    }
}

export default FlowActions
