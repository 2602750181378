import { cuntPB } from 'api'

import { ActorIds } from '@/model/Actor'

export class TosserTerm {
    public id: string
    public requisite: string
    public operator: string
    public value: string

    constructor(term: cuntPB.TosserRuleTerm) {
        this.id = Math.random().toString(36).substring(7)
        this.requisite = term.Requisite
        this.operator = term.Operator
        this.value = term.Value
    }
}

export class TosserRule {
    public id: string
    public unitId: string
    public documentType: string
    public actor: ActorIds | null
    public autoAccept: boolean
    public terms: Array<TosserTerm>

    constructor(rule: cuntPB.TosserRule, unitId: string) {
        this.id = Math.random().toString(36).substring(7)
        this.unitId = unitId
        this.documentType = rule.DocumentType
        this.actor = rule.Actor ? new ActorIds(rule.Actor) : null
        this.autoAccept = rule.AutoAccept
        this.terms = rule.Terms.map((term: cuntPB.TosserRuleTerm) => new TosserTerm(term))
    }
}
