
































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import { Document, WorkflowStage } from '@/model/Document'

import DWF_Stage from '@/components/document/workflow/DWF_Stage.vue'

@Component<DocumentWorkflow>({
    components: { DWF_Stage }
})

export default class DocumentWorkflow extends Vue {
    @Ref('workflow') workflow!: HTMLDivElement

    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) openDocument!: Document

    // *************************************************************
    // DATA PARAMS
    svgWidth = 0
    resizeObserverInterval: ReturnType<typeof setInterval> | undefined = undefined

    // *************************************************************
    // COMPUTED
    get stages(): Array<WorkflowStage> {
        return this.openDocument.workflow.stages
    }

    get draftStage(): WorkflowStage {
        return new WorkflowStage({
            Type: 'draft',
            DisplayName: 'Черновик',
            Completed: this.openDocument.base.status === 'draft',
            Graph: []
        }, 'draft')
    }

    get archivedStage(): WorkflowStage {
        return new WorkflowStage({
            Type: 'archived',
            DisplayName: 'Архив',
            Completed: this.openDocument.base.status === 'archived',
            Graph: []
        }, 'archived')
    }

    /** Первый незавершенный stage */
    get activeStageIndex(): number {
        if (this.openDocument.base.status === 'approval' || this.openDocument.base.status === 'execution') {
            return this.stages.findIndex((stage: WorkflowStage) => !stage.completed) || 0
        }
        return 0
    }

    // *************************************************************
    // MOUNTED
    mounted(): void {
        // fixme: Hack
        // Устанавливаем ширину svg равную ширине родительского блока
        this.svgWidth = this.workflow.clientWidth
        this.resizeObserverInterval = setInterval(
            (() => {
                return () => {
                    if (this.workflow.clientWidth) {
                        this.svgWidth = this.workflow.clientWidth
                    }
                }
            })(),
            1000
        )
    }

    // *************************************************************
    // BEFORE DESTROY
    beforeDestroy() {
        // убираем лишний наблюдатель за шириной пользовательского окна
        clearInterval(this.resizeObserverInterval)
    }
}
