var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "doc-status"
  }, [_c('Dropdown', {
    attrs: {
      "position": _vm.statusDisableReason ? 'right' : 'down'
    }
  }, [_c('ButtonViolet', {
    attrs: {
      "slot": "activator",
      "small": true,
      "tooltip": _vm.statusDisableReason,
      "flow": "right",
      "disabled": !!_vm.statusDisableReason,
      "text": _vm.allStatuses[_vm.openDocument.base.status]
    },
    slot: "activator"
  }), _vm._v(" "), !_vm.statusDisableReason ? _c('div', {
    staticClass: "px-3 pt-2"
  }, [_vm._l(_vm.statuses.filter(function (s) {
    return !!s.disableReason;
  }), function (st) {
    return _c('div', {
      key: st.key,
      staticClass: "mb-2 fs-14 min-width-100 red"
    }, [_vm._v("\n                " + _vm._s(st.disableReason) + "\n            ")]);
  }), _vm._v(" "), _vm._l(_vm.statuses.filter(function (s) {
    return !s.disableReason;
  }), function (st) {
    return _c('ButtonViolet', {
      key: st.key,
      staticClass: "mb-2 min-width-75 w-100",
      attrs: {
        "disabled": st.disabled,
        "loading": _vm.loadingStatus,
        "text": st.name,
        "small": true
      },
      on: {
        "click": function click($event) {
          return _vm.onClickChangeStatus(st.key);
        }
      }
    });
  })], 2) : _vm._e()], 1), _vm._v(" "), _c('ConfirmModalNew', {
    ref: "confirmModal",
    attrs: {
      "visible": _vm.visibleConfirm
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleConfirm = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }