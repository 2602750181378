// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from './store'
import api from 'api1'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import ScalePreloader from '@/components/ScalePreloader.vue'
import './registerServiceWorker'
import './styles'
import clickOutside from '@/directives/clickOutside'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

Vue.config.productionTip = false

Vue.directive('focus', {
    // Когда привязанный элемент вставлен в DOM...
    inserted: function (el) {
        // Переключаем фокус на элемент
        el.focus()
    },
})

Vue.directive('clickOutside', clickOutside)

Vue.use(Snotify, {
    showProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    toast: {
        position: SnotifyPosition.rightTop
    },
    type: {
        success: {
            position: 'centerTop'
        },
        error: {
            timeout: 0
        },
        warning: {
            timeout: 6000
        }
    }
})

store.$snotify = Snotify

Vue.use({
    install(Vue) {
        // const mixin = {
        //     created: function() {
        //         this.hello();
        //     },
        //     methods: {
        //         hello: function() {
        //             console.log("hello from mixin!");
        //         }
        //     }
        // }

        const state = {}

        Vue.component('ScalePreloader', ScalePreloader)
        Vue.component('GlobalScalePreloader', {
            data() {
                return {
                    state: {},
                }
            },
            created() {
                this.state = state
            },
            template: '<ScalePreloader :state="state"/>',
        })

        Vue.prototype.$preloader = function (key: string, text: string) {
            Vue.set(state, key, text)
            return () => {
                Vue.delete(state, key)
            }
        }
    },
})

// Inject API to window
// Todo
declare global {
    interface Window {
        $API?: any;
    }
}
window.navigator.webdriver && (window.$API = api)

console.log('navigator', navigator)
navigator.serviceWorker.addEventListener('message', event => {
    console.log('message', event.data.msg, event.data.url)
})

/* eslint-disable no-new */
// new Vue({
//     el: "#app",
//     router,
//     store,
//     components: { App },
//     template: "<App/>",
// })
//
store.dispatch('startApp')
    .finally(() => {
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    })
