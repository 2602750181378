































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { User, Role } from '@/model/User'
import { Unit } from '@/model/Unit'
import { SelectOption } from '@/components/common/select/model'

import Attribute from '@/components/common/Attribute.vue'
import Select from '@/components/common/select/Select.vue'

@Component({
    components: {
        Attribute,
        Select
    }
})

export default class SideBarMe extends Vue {
    // *************************************************************
    // DATA PARAMS
    selectedCenterId = ''

    // *************************************************************
    // COMPUTED
    @Getter('getSelectedCenter') getSelectedCenter!: string

    @Getter('me/getMe') getMe!: User // null on this page impossible

    @Getter('company_structure/getUnitById') getUnitById!: (unitId: string) => Unit | null
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>

    get centers(): Array<SelectOption> {
        const rootRole = this.getMe.roles.find((role: Role) => role.unit === 'root')
        if (rootRole) {
            return this.getUnits.filter((unit: Unit) => unit.parent == 'root' || unit.id === 'root')
        }

        const centers: Array<Unit> = []
        const centerIdMap: { [centerId: string]: boolean } = {}

        this.getMe.roles.forEach((role: Role) => {
            const unit = this.getUnitById(role.unit)
            if (unit && !centerIdMap[unit.centerId]) {
                const center = this.getUnitById(unit.centerId)
                if (center) {
                    centerIdMap[center.id] = true
                    centers.push(center)
                }
            }
        })
        return centers
    }

    // *************************************************************
    // WATCH
    @Watch('getSelectedCenter', { immediate: true })
    handleSelectedCenterChange(centerId: string): void {
        this.selectedCenterId = centerId
    }

    @Watch('centers', { immediate: true })
    handleCentersChange(centers: Array<Unit>): void {
        if (!this.selectedCenterId && centers.length) {
            const rootUnit = centers.find((unit: Unit) => unit.id === 'root')
            if (rootUnit) {
                this.setCenter('root')
            } else {
                this.setCenter(centers[0].id)
            }
        }
    }

    // *************************************************************
    // METHODS
    @Action('setSelectedCenter')
    setSelectedCenter!: (centerId: string) => void

    setCenter(centerId: string): void {
        this.setSelectedCenter(centerId)
    }
}
