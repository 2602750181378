<template>
    <div
        class="checkbox-group-container"
        :class="[classes]"
    >
        <div
            class="checkbox-group"
            @click="toggle"
        />
        <label
            :for="checkboxId"
            class="checkbox-group__label"
            @click="toggle"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        model: {
            type: [String, Array],
            default: undefined
        },
        checked: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Boolean, Object],
            default: undefined
        },
        label: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            checkboxId: Math.random().toString(36).substring(7)
        }
    },
    computed: {
        checkboxState() {
            if (this.model === undefined) {
                return this.checked
            }
            if (Array.isArray(this.model)) {
                return this.model.indexOf(this.value) !== -1
            }
            return this.model
        },
        classes() {
            return {
                'checkbox-group_disabled': this.disabled,
                'checkbox-group_active': this.checkboxState
            }
        }
    },
    methods: {
        toggle() {
            if (this.disabled) {
                return
            }
            let value = this.model
            if (Array.isArray(value)) {
                value = value.slice()
                const i = value.indexOf(this.value)
                if (i === -1) {
                    value.push(this.value)
                } else {
                    value.splice(i, 1)
                }
            } else {
                value = !this.checkboxState
            }
            this.$emit('change', value)
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/colors.styl'

.checkbox-group-container
    display flex
    flex-direction row
    align-items center

    .checkbox-group
        position relative
        height 20px
        width 20px
        min-width 20px
        border 2px solid rgba(0,0,0,.54)
        border-radius 2px
        transition .4s cubic-bezier(.25,.8,.25,1)
        box-sizing border-box

    .checkbox-group__label
        margin-left 15px

    &.checkbox-group_active
        .checkbox-group
            background-color $pink-1
            border-color $pink-1
            &:after
                position absolute
                content: ''
                transition transform .25s ease
                width 4px
                height 11px
                top 0
                left 5px
                z-index 6
                border 2px solid #fff
                border-top 0
                border-left 0
                opacity 1
                transform rotate(45deg)

    &.checkbox-group_disabled
            cursor not-allowed
            .checkbox-group
                opacity .14
            .checkbox-group__label
                opacity .24
                cursor default

</style>
