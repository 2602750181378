export default function (state, { familiarization }) {
    let index = state.familiarizations.findIndex(d => d.id === familiarization.id)

    if (index === -1) {
        console.error('replace familiarization - not found familiarization', familiarization.id)
    } else {
        state.familiarizations.splice(index, 1, familiarization)
        state.familiarizationsObject[familiarization.id] = familiarization
    }
}
