import { Numerator, NumeratorField } from '@/model/numerator.js'

export default function newDocumentType(data) {
    return new Numerator(
        data.ID,
        data.CenterID,
        data.Name,
        data.Fields.map(field => {
            let newField = new NumeratorField(
                field.ID,
                field.Type,
                '',
                '',
                '',
                ''
            )
            switch (field.Type) {
            case 'field_type_code':
                newField.code = field.Code.Source
                break
            case 'field_type_text':
                newField.text = field.Text.Text
                break
            case 'field_type_incr':
                newField.pad = String(field.Incr.Pad)
                newField.resetPeriod = field.Incr.ResetPeriod
                break
            default:
                console.error('unknown type in numerator field', field)
            }
            return newField
        })
    )
}
