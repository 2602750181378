









































import { Component, Ref, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import dynamicSort from '@/tools/sort/dynamicSort'
import { Directory } from '@/model/Directory'

import BaseListPage from '@/components/common/BaseListPage.vue'
import ListCard from '@/components/common/cards/ListCard.vue'
import EditDirectory from '@/components/directory/EditDirectory.vue'
import SelectCenterId from '@/components/common/SelectCenterId.vue'

@Component({
    components: {
        BaseListPage,
        ListCard,
        SelectCenterId,
        EditDirectory
    }
})

export default class Directories extends Vue {
    @Ref() readonly editDirectory!: EditDirectory

    // *************************************************************
    // DATA PARAMS
    searchCenterId = ''
    centerId = ''
    searchText = ''
    saveDisabled: { disabled: boolean, reason: string } = {
        disabled: false,
        reason: ''
    }
    visibleCreationModal = false

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectories') getDirectories!: Array<Directory>

    get directories(): Array<Directory> {
        let directories = this.getDirectories.filter((dir: Directory) => dir.name.toLowerCase().includes(this.searchText.toLowerCase()))
        if (this.searchCenterId) {
            directories = directories.filter((dir: Directory) => dir.centerId === this.searchCenterId)
        }
        return directories.sort(dynamicSort('name'))
    }

    changeSaveDisabled(e: { disabled: boolean, reason: string }): void {
        this.saveDisabled.disabled = e.disabled
        this.saveDisabled.reason = e.reason
    }

    create() {
        if (this.saveDisabled.disabled) {
            this.$snotify.warning(`Ошибка: "${this.saveDisabled.reason}"`)
            return
        }

        this.editDirectory.create()
    }

    createdDirectory(directoryId: string): void {
        this.visibleCreationModal = false
        Vue.nextTick(() => {
            this.openDirectory(directoryId)
        }, 0)
    }

    openDirectory(dirId: string): void {
        this.$router.push({
            name: 'document',
            params: {
                section: 'system',
                module: 'directories',
                id: dirId
            }
        })
    }
}
