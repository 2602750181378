var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkbox-group-container",
    class: [_vm.classes]
  }, [_c('div', {
    staticClass: "checkbox-group",
    on: {
      "click": _vm.toggle
    }
  }), _vm._v(" "), _c('label', {
    staticClass: "checkbox-group__label",
    attrs: {
      "for": _vm.checkboxId
    },
    on: {
      "click": _vm.toggle
    }
  }, [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }