export class Actor {
    constructor(user, unit) {
        this.user = user
        this.unit = unit
    }
}

export class ActorIds {
    constructor(userId, unitId) {
        this.userId = userId
        this.unitId = unitId
    }
}

export class UserInfo {
    constructor(id, name) {
        this.id = id // userId
        this.name = name
    }
}

export class UserName {
    constructor(first, middle, last) {
        this.first = first
        this.middle = middle
        this.last = last
    }

    get fullName() {
        if (!this.middle) {
            return `${this.last} ${this.first}`
        } else {
            return `${this.last} ${this.first} ${this.middle}`
        }
    }
    // Фамилия и инициалы
    get shortName() {
        if (!this.middle) {
            return `${this.last} ${this.first.substring(0, 1)}.`
        } else {
            return `${this.last} ${this.first.substring(
                0,
                1
            )}.${this.middle.substring(0, 1)}.`
        }
    }

}

export class UnitInfo {
    constructor(id, name) {
        this.id = id // unitId
        this.name = name
    }
}
