









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class ListCount extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: 0 }) count!: number
}
