import { storage } from '@/utils'
import { cuntPB } from 'api'
import store from '@/store'

import { UserName } from '@/model/Actor'
import { Unit } from '@/model/Unit'

// const storage = (id: string, size = 'small', dick: string): string => `${config.origin}/api/storage/avatar/download/${size}?id=${id}&dick=${dick}`

export const rolesDisplayNames: { [key: string]: string } = {
    WorkflowManager: 'Менеджер документооборота',
    Head: 'Руководитель',
    Supervisor: 'Супервайзер',
    Employee: 'Сотрудник'
}

export class Role {
    public unit: string
    public name: string

    constructor(role: cuntPB.Role) {
        this.unit = role.Unit
        this.name = role.Name
    }

    get displayName(): string {
        return rolesDisplayNames[this.name] || ''
    }

    get structuralUnit(): Unit | null {
        return store.getters['company_structure/getUnitById'](this.unit)
    }

    get unitName(): string {
        return this.structuralUnit?.name ?? 'Подразделение не найдено'
    }
}

export interface Avatar {
    full: string
    sidebar: string
    small: string
    background: string
}

export class User {
    public id: string
    public name: UserName
    public roles: Array<Role>
    public avatar: Avatar
    public email: string
    public phone: string
    public modificationTime: number

    constructor(user: cuntPB.User) {
        this.id = user.ID
        this.name = new UserName(user.Name ?? null)
        this.roles = user.Roles.map((role: cuntPB.Role) => new Role(role))
        this.email = user.Email
        this.phone = user.Phone
        this.modificationTime = user.ModificationTime

        this.avatar = {
            get full(): string {
                return storage(user.ID,'full', user.Avatar)
            },
            get sidebar(): string {
                return storage(user.ID,'sidebar', user.Avatar)
            },
            get small(): string {
                return storage(user.ID,'small', user.Avatar)
            },
            get background(): string {
                return storage(user.ID,'background', user.Avatar)
            }
        }
    }
}
