var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('EventsOnlyInput', {
    class: {
      invalid: !_vm.valid,
      input: true
    },
    attrs: {
      "state": _vm.state,
      "disabled": _vm.disabled
    },
    on: {
      "replace": function replace($event) {
        return _vm.onReplace($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }