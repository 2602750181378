






















import { Component, Vue } from 'vue-property-decorator'

import AuthenticationForm from '@/components/authentication/AuthenticationForm.vue'
// import Spermatozoa from './spermatozoa'

@Component({
    components: { AuthenticationForm }
})
export default class Authentication extends Vue {
    // mounted() {
    //     this.animashka = new Spermatozoa({
    //         color: '#eeeeee',
    //         group: 'heart'
    //     }, this.$refs.spermatozoa, this.$refs.host)
    //     this.animashka.group(false)
    //     this.animashka.start()
    // },
    // beforeDestroy() {
    //     this.animashka.stop()
    // },
}
