








































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Unit } from '@/model/Unit'
import { SelectOption } from '@/components/common/select/model'

import Select from '@/components/common/select/Select.vue'
import UnitsTree from '@/components/company_structure/UnitsTree.vue'
import UnitsList from '@/components/company_structure/UnitsList.vue'
import UnitInfo from '@/components/company_structure/UnitInfo.vue'

const viewTypes: Array<SelectOption> = [
    { id: 'tree', name: 'Дерево' },
    { id: 'list', name: 'Список' }
]

@Component({
    components: {
        Select,
        UnitsTree,
        UnitsList,
        UnitInfo
    }
})

export default class CompanyStructure extends Vue {
    // *************************************************************
    // DATA PARAMS
    unitId = ''
    view = 'tree'
    viewTypes = viewTypes
    center = 'all'

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>

    get centers(): Array<SelectOption> {
        const centers: Array<SelectOption> = this.getUnits
            .filter((unit: Unit) => unit.parent === 'root')
            .map((unit: Unit) => {
                return { id: unit.id, name: unit.name }
            })

        if (centers.length < 2) {
            return []
        }

        centers.unshift({
            id: 'all',
            name: 'Все центры'
        })

        return centers
    }
}
