



















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { User, Role } from '@/model/User'

import EditUnit from '@/components/company_structure/EditUnit.vue'
import EmployeeCard from '@/components/common/cards/EmployeeCard.vue'

@Component({
    components: {
        EditUnit,
        EmployeeCard
    }
})

export default class UnitInfo extends Vue {
    @Prop({ default: '' }) unitId!: string

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUsersByUnit') getUsersByUnit!: (id: string) => Array<User>

    get users(): Array<User> {
        return this.getUsersByUnit(this.unitId)
    }

    get head(): User | undefined {
        return this.users.find(u => u.roles.find((r: Role) => r.unit === this.unitId && r.name === 'Head'))
    }

    get supervisors(): Array<User> {
        return this.users.filter(u => u.roles.find((r: Role) => r.unit === this.unitId && r.name === 'Supervisor'))
    }

    get employees(): Array<User> {
        return this.users.filter(u => u.roles.find((r: Role) => r.unit === this.unitId && r.name === 'Employee'))
    }
}
