import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { NumeratorState } from '@/store/numerator/numeratorState'

import _actions_callCreate from '@/store/numerator/_actions/callCreate';
import _actions_callGet from '@/store/numerator/_actions/callGet';
import _actions_callRemove from '@/store/numerator/_actions/callRemove';
import _actions_callSet from '@/store/numerator/_actions/callSet';
import _actions_putNumerator from '@/store/numerator/_actions/putNumerator';
import _actions_removeNumerator from '@/store/numerator/_actions/removeNumerator';
import _actions_setName from '@/store/numerator/_actions/setName';
import _actions_setField from '@/store/numerator/_actions/setField';
import _actions_removeField from '@/store/numerator/_actions/removeField';
import _actions_moveFields from '@/store/numerator/_actions/moveFields';

const NumeratorActions: ActionTree<NumeratorState, RootState> = {
    callCreate: _actions_callCreate,
    callGet: _actions_callGet,
    callRemove: _actions_callRemove,
    callSet: _actions_callSet,

    putNumerator: _actions_putNumerator,
    removeNumerator: _actions_removeNumerator,
    setName: _actions_setName,
    setField: _actions_setField,
    removeField: _actions_removeField,
    moveFields: _actions_moveFields,

    cleanUpState({ commit }) {
        commit('cleanUpState')
    }
}

export default NumeratorActions
