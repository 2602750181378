var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("\n        Ознакомления\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "events-container"
  }, _vm._l(_vm.familiarization, function (event) {
    return _c('div', {
      key: event.id,
      staticClass: "event-card",
      on: {
        "click": function click($event) {
          return _vm.openFamiliarization(event.id);
        }
      }
    }, [_vm._v("\n            " + _vm._s(event.name) + "\n        ")]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }