


































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import { Action, Getter } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { User } from '@/model/User'
import { WorkflowElement, WorkflowStageType } from '@/model/Document'

import Button from '@/components/common/buttons/Button.vue'
import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'

@Component<DWF_TaskCard>({
    components: {
        Button,
        ButtonViolet
    }
})

export default class DWF_TaskCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: null }) stageType!: WorkflowStageType | null
    @Prop({ default: () => null }) task!: WorkflowElement
    @Prop({ default: () => null }) childTask!: WorkflowElement | null

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible

    get actorName(): string {
        if (!this.task.actor) {
            return '????'
        }

        if (!!this.task.actor.user && !this.task.actor.unit) {
            // Если согласование - на user
            return this.task.actor.user.name.fullName
        } else if (!!this.task.actor.user && !!this.task.actor.unit) {
            // Если согласование - на unit - тогда должен быть еще и user из этого unit
            return `${this.task.actor.unit.name}: ${this.task.actor.user.name.fullName}`
        } else if (!this.task.actor.user && !!this.task.actor.unit) {
            return this.task.actor.unit.name
        } else {
            return '????'
        }
    }

    get assigneeName(): string {
        if (!this.childTask || !this.childTask.actor) {
            return '????'
        }

        if (!!this.childTask.actor.user && !this.childTask.actor.unit) {
            return this.childTask.actor.user.name.fullName
        } else if (!!this.childTask.actor.user && !!this.childTask.actor.unit) {
            return `${this.childTask.actor.unit.name}: ${this.childTask.actor.user.name.fullName}`
        } else if (!this.childTask.actor.user && !!this.childTask.actor.unit) {
            return this.childTask.actor.unit.name
        } else {
            return '??????'
        }
    }

    // *************************************************************
    // METHODS
    @Action('document/callTaskComplete')
    callTaskComplete!: (params: cuntPB.WorkflowTaskCompletereq) => Promise<void>

    @Action('document/callTaskConfirm')
    callTaskConfirm!: (params: cuntPB.WorkflowTaskConfirmreq) => Promise<void>

    @Action('document/callTaskReject')
    callTaskReject!: (params: cuntPB.WorkflowTaskRejectreq) => Promise<void>

    canComplete(): void {
        if (!this.task.pending) {
            return
        }

        if (this.stageType === 'fluent') {
            // Если это последняя задача в блоке свободного согласования
            if ((!this.task.outputs.length && this.task.task?.type !== 'width_confirmation') || (this.task.task?.type === 'width_confirmation' && this.childTask?.pending)) {
                const answer = window.confirm('Это последняя задача в данном блоке. Вы уверены, что не хотите добавить ей дочернюю?')
                if (answer) {
                    this.complete()
                }
            } else {
                this.complete()
            }
        } else {
            this.complete()
        }
    }

    complete(): void {
        const cancelPreloaderCompleteWorkflowTask = this.$preloader('complete_workflow_task', 'Выполнение задачи')

        this.callTaskComplete({
            ID: this.task.id,
            Document: this.$route.params.id
        })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderCompleteWorkflowTask())
    }

    canConfirm(): void {
        if (!this.childTask?.pending) {
            return
        }

        if (this.stageType === 'fluent') {
            // Если это последняя задача в блоке свободного согласования
            if ((!this.task.outputs.length && this.task.task?.type !== 'width_confirmation') ||
                (this.task.task?.type === 'width_confirmation' && !this.task.outputs.length)) {
                const answer = window.confirm('Это последняя задача в данном блоке. Вы уверены, что не хотите добавить ей дочернюю?')
                if (answer) {
                    this.confirm()
                }
            } else {
                this.confirm()
            }
        } else {
            this.confirm()
        }
    }

    confirm(): void {
        const cancelPreloaderConfirmWorkflowTask = this.$preloader('confirm_workflow_task', 'Подтверждение задачи')

        this.callTaskConfirm({
            ID: this.task.id,
            Document: this.$route.params.id
        })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderConfirmWorkflowTask())
    }

    reject(): void {
        if (!this.childTask?.pending) {
            return
        }

        const cancelPreloaderRejectWorkflowTask = this.$preloader('reject_workflow_task', 'Отклонение задачи')

        this.callTaskReject({
            ID: this.task.id,
            Document: this.$route.params.id
        })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderRejectWorkflowTask())
    }
}
