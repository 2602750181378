var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "visible": _vm.syncedVisible,
      "title": "Настройки таблицы"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      },
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "p-4",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('div', {
    staticClass: "table-settings__title"
  }, [_vm._v("\n            Данные настройки актуальны только на данном устройстве и только для данного типа документа.\n        ")]), _vm._v(" "), _vm._l(_vm.allCells, function (cell) {
    return _c('div', {
      key: cell.id,
      staticClass: "mb-3"
    }, [_c('CheckboxGroupN', {
      attrs: {
        "value": cell.id,
        "label": cell.name
      },
      model: {
        value: _vm.visibleCells,
        callback: function callback($$v) {
          _vm.visibleCells = $$v;
        },
        expression: "visibleCells"
      }
    })], 1);
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center justify-end p-3",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("\n            Сохранить\n        ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }