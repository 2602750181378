var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input"
  }, [_vm.label ? _c('div', {
    staticClass: "form-input__label"
  }, [_vm.required ? _c('span', {
    staticClass: "form-input__label_required"
  }, [_vm._v("*")]) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "form-input__label_text"
  }, [_vm._v(_vm._s(_vm.label) + ":")])]) : _vm._e(), _vm._v(" "), _vm._t("form-input-body")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }