var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "item-card"
  }, _vm.$listeners), [_c('ButtonViolet', {
    staticClass: "item-card__name",
    attrs: {
      "small": true,
      "text": _vm.item.name
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }