var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DocumentEventBase', {
    attrs: {
      "event": _vm.event,
      "name": "Зарегистрировано"
    }
  }, [_vm.newNumber ? _c('template', {
    slot: "fields"
  }, [_c('div', [_vm._v("Присвоен номер:")]), _vm._v(" "), _c('div', {
    staticClass: "fs-600"
  }, [_vm._v(_vm._s(_vm.newNumber))])]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }