import Vue from 'vue'
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex'

Vue.use(Vuex)

import MeSubModule from './me/meState'
import ItemsSubModule from './items/items'
import CompanyStructureSubModule from './company_structure/companyStructureState'
import DirectorySubModule from './directories/directoryState'
import DocumentTypeSubModule from './document_type/documentTypeState'
import DocumentSubModule from './document/documentState'
import TaskSubModule from './task/task'
import FamiliarizationSubModule from './familiarization/familiarizationState'
import NumeratorSubModule from './numerator/numeratorState'
import TosserSubModule from './tosser/tosser'
import UserOptionsSubModule, { UserOptionsState } from './user_options/user_options'
import WorkflowSubModule from './workflow'
import WorkflowEventSubModule from './workflow_event/workflowEventState'
// import * as _modules_common from "./common/index"

import VuexPersistence from 'vuex-persist'

export class RootState {
    user_options!: UserOptionsState

    selectedCenter = localStorage.getItem('center') ?? ''
}

const getters: GetterTree<RootState, RootState> = {
    isAuthorized: (state, getters): boolean => !!getters['me/getMeId'],

    getSelectedCenter: (state: RootState): string => state.selectedCenter
}

export const mutations: MutationTree<RootState> = {
    setSelectedCenter(state: RootState, centerId: string) {
        state.selectedCenter = centerId
    }
}

const actions: ActionTree<RootState, RootState> = {
    async startApp({ dispatch }) {
        return dispatch('me/callCheckStatus', {}, { root: true })
    },

    cleanUpStates({ dispatch }) {
        localStorage.removeItem('center')

        dispatch('directories/cleanUpState')
        dispatch('company_structure/cleanUpState')
        dispatch('task/cleanUpState')
        dispatch('workflow_event/cleanUpState')
        dispatch('numerator/cleanUpState')
        dispatch('familiarization/cleanUpState')
        dispatch('document_type/cleanUpState')
    },

    setSelectedCenter({ commit }, centerId: string) {
        commit('setSelectedCenter', centerId)
        localStorage.setItem('center', centerId)
    }
}

const modules = {
    me: MeSubModule, // Todo
    items: ItemsSubModule,
    company_structure: CompanyStructureSubModule, // Todo
    directories: DirectorySubModule,
    document_type: DocumentTypeSubModule,
    document: DocumentSubModule, // Todo
    familiarization: FamiliarizationSubModule, // Todo
    numerator: NumeratorSubModule, // Todo
    task: TaskSubModule,
    tosser: TosserSubModule, // Todo
    user_options: UserOptionsSubModule, // Todo
    workflow: WorkflowSubModule, // Todo
    workflow_event: WorkflowEventSubModule
    // common: _modules_common,
}

const vuexLocal = new VuexPersistence<RootState>({
    key: 'vuex_user',
    reducer: (state: RootState) => {
        return {
            user_options: {
                readNewsIds: state.user_options.readNewsIds,
                documentHiddenCells: state.user_options.documentHiddenCells
            }
        }
    },
    storage: window.localStorage
})

export default new Vuex.Store({
    state: new RootState(),
    getters,
    mutations,
    actions,
    modules,
    plugins: [vuexLocal.plugin]
})
