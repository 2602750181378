var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "el-popover",
    on: {
      "mouseenter": _vm.handleMouseEnter,
      "mouseleave": _vm.handleMouseLeave,
      "click": _vm.handleClick
    }
  }, [_c('div', {
    ref: "popover",
    staticClass: "popover",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._t("popover"), _vm._v(" "), _vm.arrow ? _c('div', {
    ref: "arrow",
    class: {
      'popover-arrow': true,
      'arrow-top': _vm.popoverPosition === 'top',
      'arrow-bottom': _vm.popoverPosition === 'bottom'
    }
  }) : _vm._e()], 2), _vm._v(" "), _vm._t("host")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }