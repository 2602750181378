import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { MeState } from '@/store/me/meState'
import { User, Role } from '@/model/User'
import { AuthenticationStatus, IdentitiesAndVerifiersList } from '@/model/Authentication'

const MeGetters: GetterTree<MeState, RootState> = {
    getMe: (state: MeState): User | null => state.me,

    getMeId: (state: MeState): string => state.status?.authenticated?.user ?? '',

    getStatus: (state: MeState): AuthenticationStatus | null => state.status,

    isCenterManager: (state: MeState) => (unitId: string): boolean => {
        const roles = state.me?.roles ?? []
        return roles.some((role: Role) => role.name === 'WorkflowManager' && (role.unit === unitId || role.unit === 'root'))
    },

    isGetUser: (state: MeState): boolean => !!state.me,

    isUserManager: (state: MeState): boolean => {
        const roles = (state.me || {}).roles || []
        return roles.some((role: Role) => role.name === 'WorkflowManager')
    },

    getEventId: (state: MeState): string => state.eventId,

    getListMyIdentitiesAndVerifiers: (state: MeState): IdentitiesAndVerifiersList | null => state.listMyIdentitiesAndVerifiers
}

export default MeGetters
