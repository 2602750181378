var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticClass: "document-event-card"
  }, [_c('div', {
    attrs: {
      "slot": "card-body"
    },
    slot: "card-body"
  }, [_c('div', {
    staticClass: "document-event-card__action fs-20",
    class: _vm.action.class
  }, [_vm._v("\n            " + _vm._s(_vm.action.text) + "\n        ")]), _vm._v(" "), _vm.event.type === 'task' ? _c('div', {
    staticClass: "mt-1"
  }, [_vm._v("\n            " + _vm._s(_vm.event.task.text) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('hr', {
    staticClass: "document-event-card__hr"
  }), _vm._v(" "), _c('div', {
    staticClass: "my-1"
  }, [_c('span', {
    staticClass: "gray"
  }, [_vm._v("Тип:")]), _vm._v(" "), _c('router-link', {
    staticClass: "ml-2 document-event-card__link text-wrap",
    attrs: {
      "to": _vm.typePath
    }
  }, [_vm._v("\n                " + _vm._s(_vm.typeName) + "\n            ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "my-1"
  }, [_c('span', {
    staticClass: "gray"
  }, [_vm._v("Документ:")]), _vm._v(" "), _c('router-link', {
    staticClass: "ml-2 document-event-card__link text-wrap",
    attrs: {
      "to": _vm.documentPath
    }
  }, [_vm._v("\n                " + _vm._s(_vm.event.document.name) + "\n            ")])], 1), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "от",
      "text": _vm.event.document.creationTimeLocalizedLongString
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }