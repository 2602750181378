var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "button-close-wrapper"
  }, _vm.$listeners), [_c('button', {
    staticClass: "button-close",
    class: {
      'button-close_small': _vm.small,
      'button-close_grey': _vm.color === 'grey',
      'button-close_violet': _vm.color === 'violet'
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }