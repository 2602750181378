









import { Component, Prop, Vue } from 'vue-property-decorator'

import { Unit } from '@/model/Unit'

@Component({})

export default class UnitListCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: null }) unit!: Unit
}
