var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('Transition', [_c('router-view')], 1), _vm._v(" "), _c('GlobalScalePreloader'), _vm._v(" "), _c('vue-snotify', {
    staticClass: "snotify-small"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }