










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { WorkflowEvent } from '@/model/WorkflowEvent'
import { User } from '@/model/User'

import Me from '@/components/sidebar/SideBarMe.vue'
import UserEvents from '@/components/sidebar/UserEvents.vue'
import UserTasks from '@/components/sidebar/UserTasks.vue'
import UserFamiliarization from '@/components/sidebar/UserFamiliarization.vue'

@Component({
    components: {
        UserEvents,
        UserTasks,
        UserFamiliarization,
        Me
    }
})

export default class SideBarPanel extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) componentPanel!: string
    @Prop({ default: () => []}) events!: Array<WorkflowEvent>
    @Prop({ default: '' }) title!: string
    @Prop({ default: '' }) type!: string

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    exit(): void {
        this.$router.push({ name: 'logout' })
    }
}
