import Vue from 'vue'
import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'

import ItemsActions  from '@/store/items/_action'
import dashItems from '@/store/items/dashItems'
import { DashboardItem, itemConfig, parentConfig, ParentConfig, ROLES, SIZES, Configuration, ItemConfig } from '@/store/items/model'

export class ItemsState {
    dashItems: Array<DashboardItem> = dashItems

    // role === 'root'
    rootConfiguration = {
        routerTransitionName: 'transitionRoot',
        keyRouterView: 'root',
        parentConfig: parentConfig.root.big,
        itemConfig: itemConfig.small
    }

    // role === 'section'
    sectionConfiguration: Configuration = {
        routerTransitionName: 'transitionSection',
        keyRouterView: 'section',
        parentConfig: parentConfig.section.big,
        itemConfig: itemConfig.small
    }

    setParentConfig = (role: ROLES, size: SIZES): ParentConfig => {
        return parentConfig[role][size]
    }

    setItemConfig = (size: SIZES): ItemConfig => {
        return itemConfig[size]
    }

    navState = 'initial'
}

const getters: GetterTree<ItemsState, RootState> = {
    getNavState: (state: ItemsState): string => state.navState,

    getItemByName: (state: ItemsState) => (name: string): DashboardItem | null => state.dashItems.find((d: DashboardItem) => d.name === name) || null,

    getItemsBySection: (state: ItemsState) => (section: string): Array<DashboardItem> => state.dashItems.filter((d: DashboardItem) => d.parent === section),

    getConfiguration: (state: ItemsState) => (role: ROLES): Configuration => state[`${role}Configuration`]
}

const mutations: MutationTree<ItemsState> = {
    setNavState(state: ItemsState, newState: string) {
        state.navState = newState
    },

    setDocumentItem(state: ItemsState, item: DashboardItem) {
        const index = state.dashItems.findIndex((d: DashboardItem) => d.name === item.name)
        if (index === -1) {
            state.dashItems.push(item)
        } else {
            Vue.set(state.dashItems, index, item)
        }
    },

    removeDocumentItem(state: ItemsState, itemName: string) {
        const index = state.dashItems.findIndex((d: DashboardItem) => d.name === itemName)

        if (index !== -1) {
            state.dashItems.splice(index, 1)
        }
    },

    /** необходимо менять ключ router-view для смены transition, чтобы блоки двигались вверз-вниз, а не вправо-влеов */
    resetRootRouterViewKey(state: ItemsState) {
        state.rootConfiguration.keyRouterView = Math.random()
            .toString(36)
            .substring(7)
    },

    resetSectionRouterViewKey(state: ItemsState) {
        state.sectionConfiguration.keyRouterView = Math.random()
            .toString(36)
            .substring(7)
    },

    resize(state: ItemsState, params: { role: ROLES, size: SIZES }) {
        state[`${params.role}Configuration`].parentConfig = state.setParentConfig(params.role, params.size)
        state[`${params.role}Configuration`].itemConfig = state.setItemConfig(params.size)
    },

    setDefaultTransition(state: ItemsState) {
        state.rootConfiguration.routerTransitionName = 'transitionRoot'
        state.sectionConfiguration.routerTransitionName = 'transitionSection'
    },

    transitionModule(state: ItemsState, transitionName: string) {
        state.sectionConfiguration.routerTransitionName = transitionName
    },

    // Todo - types
    transitionVertical(state: ItemsState, { configuration, transition }) {
        if (configuration === 'section') {
            state.rootConfiguration.routerTransitionName = transition
        } else {
            state.sectionConfiguration.routerTransitionName = transition
        }
    }
}

const ItemsSubModule = {
    namespaced: true,
    state: new ItemsState(),
    getters,
    mutations,
    actions: ItemsActions
}

export default ItemsSubModule
