<template>
    <HelpCard
        title="Увольнение"
        :help="true"
    >
        <div slot="content">
            <h4 class="help__h4">
                Уволить сотрудника (Увольняемый) может только Менеджер документооборота.
            </h4>
            <p>
                Для увольнения необходимо указать сотрудника (Дефолтный), который заменит увольняемого по умолчанию.
                <br>
                Также опционально можно указать замещение по каждой из ролей.
            </p>
            <h4 class="help__h4">
                Роли:
            </h4>
            <ol>
                <li>
                    Роль Менеджера документооборота не будет переназначена.
                    На данную роль пользователи назначаются напрямую в Оргструктуре;
                </li>
                <li>
                    Роль Супервайзера не будет переназначена.
                    На данную роль пользователи назначаются напрямую в Оргструктуре;
                </li>
                <li>
                    Для остальных ролей, если нет точного переназначения, то роль будет назначена Дефолтному сотруднику.
                </li>
            </ol>

            <h4 class="help__h4">
                Задачи:
            </h4>
            <ol>
                <li>
                    Невыполненные задачи, в которых исполнителем является Увольняемый:
                    <ul>
                        <li>
                            Если задача выставлена на конкретного пользователя,
                            то она будет перенаправлена на Дефолтного сотрудника;
                        </li>
                        <li>
                            Если задача выставлена на подразделение (по приоритету):
                            <ul>
                                <li>
                                    Если есть переназначение на должность руководителя данного подразделения,
                                    то она будет перенаправлена на вновь назначенного руководителя;
                                </li>
                                <li>
                                    Если есть переназначение на должность сотрудника в данном подразделении,
                                    то она будет перенаправлена на данного сотрудника;
                                </li>
                                <li>
                                    Если в данном подразделении есть руководитель, то она
                                    будет перенаправлена руководителю подразделения;
                                </li>
                                <li>Будет перенаправлена Дефолтному сотруднику.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    Неподтвержденные задачи, в которых подтверждающим является Увольняемый:
                    <ul>
                        <li>
                            Если задача выставлена на конкретного пользователя,
                            то она будет перенаправлена на Дефолтного сотрудника;
                        </li>
                        <li>
                            Если задача выставлена на подразделение (по приоритету):
                            <ul>
                                <li>
                                    Если есть переназначение на должность руководителя данного подразделения,
                                    то она будет перенаправлена на вновь назначенного руководителя;
                                </li>
                                <li>
                                    Если есть переназначение на должность сотрудника в данном подразделении,
                                    то она будет перенаправлена на данного сотрудника;
                                </li>
                                <li>
                                    Если в данном подразделении есть руководитель, то она
                                    будет перенаправлена руководителю подразделения;
                                </li>
                                <li>Будет перенаправлена Дефолтному сотруднику.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ol>

            <h4 class="help__h4">
                Документы:
            </h4>
            <p>
                Изменены будут только документы, в которых Статус не равен Черновик или Архив и
                есть несогласованные узлы с Увольняемым (по приоритету).
            </p>
            <ol>
                <li>
                    Если узел согласования выставлен на конкретного сотрудника без подразделения,
                    то данный узел согласования будет переназначен на Дефолтного сотрудника;
                </li>
                <li>
                    Если узел согласования выставлен на подразделение и есть переназначение
                    на должность руководителя в данном подразделении, то данный узел будет
                    перенаправлен вновь назначенному руководителю;
                </li>
                <li>
                    Если узел согласования выставлен на подразделение и есть переназначение
                    на должность сотрудника в данном подразделении, то данный узел будет
                    перенаправлен вновь назначенному сотруднику;
                </li>
                <li>
                    Если узел согласования выставлен на подразделение и в нем есть руководитель,
                    то данный узел будет переназначен руководителю подразделения;
                </li>
                <li>
                    Если узел согласования выставлен на подразделение и в нем нет руководителя,
                    то данный узел будет переназначен первому найденному сотруднику подразделения;
                </li>
                <li>
                    Если нет сотрудников в подразделении - вернется ошибка.
                </li>
            </ol>

            <h4 class="help__h4">
                Конструктор жизненного цикла:
            </h4>
            <ol>
                <li>
                    Если Увольняемый участвует в конструкторе жизненного цикла,
                    то он будет заменен на Дефолтного сотрудника.
                </li>
            </ol>

            <h4 class="help__h4">
                Тоссер (по приоритету):
            </h4>
            <ol>
                <li>
                    Если есть переназначение на должность сотрудника в подразделении,
                    то в правилах Тоссера Увольняемый будет изменен на сотрудника данного подразделения;
                </li>
                <li>
                    Если есть переназначение на должность руководителя в подразделении,
                    то в правилах Тоссера Увольняемый будет изменен на руководителя данного подразделения;
                </li>
                <li>
                    Если в данном подразделении есть руководитель, то правило будет перенаправлено
                    на руководителя подраделения;
                </li>
                <li>Иначе правило будет удалено.</li>
            </ol>
        </div>
    </HelpCard>
</template>

<script>
import HelpCard from '@/components/about_system/HelpCard'

export default {
    name: 'HelpDismissal',
    components: { HelpCard }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/help.styl'
</style>
