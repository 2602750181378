<template>
    <div
        class="employee-card"
        :class="{ 'employee-card_small': small}"
        v-on="$listeners"
    >
        <Popover
            :shift-ypopover="10"
            :arrow="arrow"
        >
            <div
                slot="host"
                :style="styleObjectType"
                class="employee-card__photo"
                :class="{ 'employee-card__photo_small': small}"
            />
            <!-- <img src="http://mediabanner.net/li/51-366-032.jpeg" alt="Загрузить изображение" width="50px" slot="host"> -->
            <!-- <img src="./person.png" alt="Загрузить изображение" width="50px" slot="host"> -->
            <div
                v-if="popover"
                slot="popover"
                class="employee-card__popover"
                @click.stop
            >
                Ф.И.О.: {{ unitUser.name.fullName }} <br>
                Должность: {{ unitUser.positionName }} <br>
                Телефон: {{ unitUser.phone }} <br>
                Email: {{ unitUser.email }}
            </div>
        </Popover>

        <div
            class="employee-card__info"
            @click="$emit('changeEmployee')"
        >
            <div class="employee-card__info_full-name">
                {{ unitUser.name.shortName }}
            </div>
            <div class="employee-card__info_post">
                {{ unitUser.positionName }}
            </div>
        </div>
    </div>
</template>

<script>
import Popover from '../Popover'

export default {
    name: 'EmployeeCard',
    components: { Popover },
    props: {
        unitUser: {
            type: Object,
            default: () => {
                return {}
            }
        },
        type: {
            type: String,
            default: ''
        },
        deleted: {
            // Показывать или нет возможность удаления сотрудника из подразделения
            type: Boolean,
            default: false
        },
        popover: {
            // показывать или нет popover
            type: Boolean,
            default: true
        },
        arrow: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        styleObjectType() {
            if (this.type === 'system') {
                // Если есть аватарка - ставим ее
                return {
                    'background-image':
                        `url("${this.unitUser.avatar.small}")`
                }
            } else {
                // this.type === "firstsys"
                return {
                    'background-image':
                        'url(' + require('@/assets/icons/firstSys.jpg') + ')'
                }
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.employee-card
    display flex
    flex-direction row
    flex-wrap nowrap
    position relative
    box-sizing border-box
    width 200px
    height 50px
    &.employee-card_small
        height 35px

.employee-card__photo
    border-radius 40px
    height 50px
    width 50px
    background-position top
    background-size cover
    background-color #f5f5f5
    &.employee-card__photo_small
        height 35px
        width 35px

.employee-card__popover
    padding 18px 20px

.employee-card__info
    flex 2
    display flex
    flex-direction column

.employee-card__info > div
    flex 1
    padding 0 0 0 15px
    text-align left

.employee-card__info_full-name
    line-height 25px
    font-size 16px
    color #000000

.employee-card__info_post
    color #C0C0C0
    font-size 14px

</style>
