export default function ({ state, commit }, { numeratorId, name }) {
    const existingNumerator = state.numerators.find(num => num.id === numeratorId)
    commit('saveVersion', {
        numerator: existingNumerator
    })

    commit('setName', {
        numeratorId,
        name
    })
}
