<template>
    <HelpCard
        title="Тоссер"
        :help="true"
    >
        <template slot="content">
            <p>
                <strong>ТОССЕР</strong> позволяет снять часть нагрузки по согласованию документов с Вас
                и перераспределить ее среди Ваших сотрудников и дочерних подразделений.
            </p>

            <p>
                Тоссер устанавливает правила отдельно для каждого типа документа в пределах
                одного подразделения.
            </p>
            <p>
                <strong>ПРАВИЛО</strong> - это набор <strong>УСЛОВИЙ</strong>, к которым привязан
                один <strong>СОГЛАСУЮЩИЙ</strong>.
            </p>
            <p>
                Если во вновь созданном документе, в котором Вы будите стоять в цепочке
                согласования, совпадут все условия, отраженные в правиле, то вместо Вас
                при переводе документа 'На согласование' (СТАРТ) будет назначен Согласующий.
            </p>

            <p>
                СОГЛАСУЮЩИМ можно выбрать:
            </p>
            <ul>
                <li>
                    Сотрудника - если в поле 'Согласует' Вы выбрали конкретного человека;
                    <br>
                    Если в подразделении, для которого Вы устанавливаете правила Тоссера,
                    нет Cотрудников и Участников документооборота,
                    пункт 'Сотрудник' в поле 'Согласует' будет заблокирован;
                </li>
                <li>
                    Подразделение - тогда в документ, попадающий под правило Тоссера,
                    попадет:
                    <ul>
                        <li>
                            Руководитель дочернего подразделения - если в поле 'Согласует' Вы указали
                            дочернее подразделение, у которого есть руководитель, и правила Тоссера дочернего
                            подразделения не совпали с реквизитами созданного документа;
                        </li>
                        <li>
                            Сотрудник одного из дочерних подразделений - если в поле 'Согласует' Вы указали
                            дочернее подразделение, в котором указано подходящее правило, и документ был перенаправлен
                            ниже по Оргструктуре;
                        </li>
                        <li>
                            Вернется ОШИБКА - если в поле 'Согласует' Вы указали дочернее под, у которого нет руководителя.
                        </li>
                    </ul>
                    Если указанное подразделениие не имеет дочерних подразделений, пункт
                    'Подразделение' в поле 'Согласует' будет заблокирован;
                </li>
            </ul>

            <p>
                ПРАВИЛО:
            </p>
            <ul>
                <li>
                    Состоит минимум из одного условия;
                </li>
                <li>
                    Правило действует только при соблюдении всех указанных условий;
                </li>
                <li>
                    Расположение Правил сверху вниз определяет их приоритет.
                    <br>
                    Если несколько Правил соответствуют документу, то сработает верхнее.
                    <br>
                    Если верхнее Правило не подходит под реквизиты документа, проверка переходит
                    к нижележащему Правилу.
                    <br>
                    Правила можно перемещать относительно друг друга, тем самым меняя им приоритет;
                </li>
                <li>
                    Правило будет распространяться только на документы, переведенные на Согласование
                    (СТАРТ) после его создания.
                    <br>
                    Если Вы хотите, чтобы документ со статусом 'СТАРТ' шел по цепочки согласования,
                    учитывающей правило, необходимо перевести его в 'ЧЕРНОВИК', а затем
                    заново в 'СТАРТ'.
                </li>
                <li>
                    Правило можно удалить.
                </li>
            </ul>

            <p>
                УСЛОВИЕ:
            </p>
            <ul>
                <li>
                    В условие необходимо выбрать реквизит и его значение;
                </li>
                <li>
                    Условие можно удалить;
                </li>
            </ul>

            <h2>Инструкция:</h2>
            <ol>
                <li>
                    На странице Тоссера Вы видите список подразделений, в которых Вы являетесь
                    руководителем.
                </li>
                <li>
                    Выберите подразделение и Тип документа, для которого хотите установить Правила
                    для перенаправления согласования на своих сотрудников или дочерние подразделения.
                </li>
                <li>
                    <div class="help-tosser__line">
                        <div>
                            Нажмите кнопку 'Добавить правило':
                        </div>
                        <ButtonGreen
                            text="Добавить правило"
                            class="help-tosser__button"
                        />
                    </div>
                </li>
                <li>
                    Можно добавить неограниченное количество правил.
                </li>
                <li>
                    Заполните условия.
                </li>
                <li>
                    <div class="help-tosser__line">
                        <div>
                            Для добавление Условия нажмите кнопку:
                        </div>
                        <ButtonAdd
                            tooltip="Добавить условие"
                            flow="left"
                            class="help-tosser__button"
                        />
                    </div>
                </li>
                <li>
                    Заполните поле 'Согласует'.
                </li>
                <li>
                    <div class="help-tosser__line">
                        <div>
                            Для удаления Условия или Правила используйте кнопки:
                        </div>
                        <ButtonDelete
                            class="help-tosser__button"
                        />
                    </div>
                </li>
                <li>
                    <div class="help-tosser__line">
                        <div>
                            Если Вы хотите поменять приоритет Правилам, воспользуйтесь стрелочками:
                        </div>
                        <div
                            tooltip="Увеличить приоритет"
                            flow="left"
                            class="help-tosser__button pink-1"
                        >
                            <FAIcon
                                icon="arrow-up"
                                class="help-tosser__icon"
                            />
                        </div>
                        <div
                            tooltip="Снизить приоритет"
                            flow="left"
                            class="help-tosser__button pink-1"
                        >
                            <FAIcon
                                icon="arrow-down"
                                class="help-tosser__icon"
                            />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="help-tosser__line">
                        <div>
                            Нажмите кнопку 'Сохранить':
                        </div>
                        <ButtonGreen
                            text="Сохранить"
                            class="help-tosser__button"
                        />
                    </div>
                    Кнопка "Сохранить" активна, если есть несохраненные изменения.
                </li>
            </ol>
        </template>
    </HelpCard>
</template>

<script>
import HelpCard from '@/components/about_system/HelpCard'
import ButtonGreen from '@/components/common/buttons/ButtonGreen'
import ButtonAdd from '@/components/common/buttons/ButtonAdd'
import ButtonDelete from '@/components/common/buttons/ButtonDelete'

export default {
    name: 'HelpTosser',
    components: {
        HelpCard,
        ButtonGreen,
        ButtonAdd,
        ButtonDelete
    }
}
</script>

<style lang="stylus" scoped>
.help-tosser__button
    margin-left 25px

.help-tosser__line
    display flex
    flex-direction row
    align-items center

.help-tosser__icon
    display block
    height 20px
    width 20px
    cursor pointer

</style>
