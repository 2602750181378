const errors: { [key: string]: string } = {
    'access denied': 'Доступ запрещен',

    // IDENTITY
    'different user': 'По нему зарегистрирован другой пользователь',
    'verification code mismatch': 'Введен неверный код',
    'identity not registered': 'Такой пользователь не зарегистрирован',
    'iqsms error: not enough balance': 'Обратитесь к менеджеру: закончился баланс на смс-сервисе',

    // UPLOADER
    'image: unknown format': 'Неподходящий формат изображения',

    // Directory
    'there are records that have the field unitialized': 'Данное поле нельзя сделать обязательным, т.к. существуют элементы справочников, у которых оно не заполнено', //ErrUnitialRecords
    'directory is used': 'Справочник используется в реквизитах типов документов или других справочниках', // ErrDirUsed
    'record is used': 'Элемент справочника используется в созданных документах', // ErrRecordUsed
    'there are records in directory': 'Сначала удалите все элементы справочника', // ErrDirHasRecords
    'should unarchive directory first': 'Сначала выведите справочник из Архива', // ErrDirMustUnarchived
    'cannot add required field': 'Добавьте необязательное поле, затем заполните его у всех существующих элементов справочника', // ErrAddRequiredField
    'there are non-archived records': 'Сначала переведите в Архив или удалите все элементы справочника', // ErrRecordsMustArchived
    'directory field name cannot be empty': 'Имя поля должно быть заполнено', // ErrEmptyDirFieldName
    'directory name cannot be empty': 'Имя справочника должно быть заполнено', // ErrEmptyDirName
    'directory displayName cannot be empty': 'Отображаемое значение справочника должно быть заполнено', // ErrEmptyDirDisplayName
    'incorrect number of record fields': 'Не совпадает количество полей элемента и справочника', // ErrIncorrectRecordFieldsNumber
    'directory should contain at least one required field': 'Справочник должен содержать минимум одно обязательное поле', // ErrNoRequiredFields
    'can not remove record, because it use in ': 'Элемент справочника используется в ', // ErrRemoveRecordContainedInOtherDirectories

    // USER
    ' retiring user ID is required': 'Необходимо выбрать пользователя, которого увольняют', // ErrNoUserId
    'default alternating user ID is required': 'При увольнении обязательно указывать Дефотного сотрудника', // ErrNoDefaultUserId
    'this user is not in your CenterID': 'Данный пользователь не является сотрудником организации', // ErrRetirngUserWrongCenterId
    'dismissed employee have not this role': 'Увольняемый не имеет перечисленной роли', // ErrDismissedUserNoHaveRole
    'management roles could not be reassigned': 'Руководящие роли не переназначаются', // ErrDismissedManagementRoles

    // UNIT
    'cannot move root unit': 'Нельзя перемещать корневое подразделение', // ErrMoveRootUnit
    'cannot modify root unit': 'Нельзя изменять корневое подразделение', // ErrModifyRootUnit
    'cannot rename root unit': 'Нельзя переименовывать корневое подразделение', // ErrRenameRootUnit
    'cannot remove root unit': 'Нельзя удалить корневое подразделение', // ErrRemoveRootUnit

    'unit name cannot be empty': 'Имя подразделения не может быть пустым', // ErrEmptyUnitName
    'unit code cannot be empty': 'Код подразделения не может быть пустым', // ErrEmptyUnitCode

    'unit with specified ID does not exists': 'Не существует подразделения с указанным идентификатором', // ErrUnitNotExists
    'specified unit should be organization': 'Указанное подразделение должно быть организацией', // ErrShouldBeOrganization
    'unit have children': 'У подразделения есть дочерние подразделения: операция запрещена', // ErrUnitHaveChildren
    'unit parent must be in the same center': 'Перемещение возможно только в пределах одного центра', // ErrUnitParentMustBeInTheSameCenter
    'unit use in document type template': 'Подразделение используется в конструкторе типа документа: ', //ErrUnitUsedInDocumentTypeTemplate

    // Numerator
    'numerator name cannot be empty or consists of whitespaces only': 'Имя нумератора должно быть заполнено',
    'numerator must contain at least one field': 'Нумератор должен содержать минимум одно поле',
    'no increment field in sequence': 'Нумератор должен содержать хотя бы одно поле с типом Номер',
    'unknown field type': 'Невалидный тип поля',
    'numerator text field cannot be empty': 'Разделитель не может быть пустой строкой',
    'increment field pad cannot be less than 0': 'Длина нумератора не может быть отрицательной',
    'unknown reset period was specified for a field': 'Невалидный тип у периодичности обнуления',
    'cannot obtain code from unknown source': 'Невалидный шифр',
    'numerator field ID cannot be empty': 'Поле нумератора не может быть с пустым идентификатором',
    'numerator field ID is duplicated': 'В идентификаторах полей нумератора обнаружены повторы',
    'unable to remove numerator: numerator is used in document type': 'Удаление запрещено: нумератор используется в типах документов',

    // Document
    'no document for specified id': 'Не существует документа с указанным идентификатором',
    'not found element in Workflow': 'Элемента с таким идентификатором не существует',
    'unable to remove document: document status was changed before': 'Запрещено удалять документ: было изменение статуса',
    'unable to remove document: document is not archived': 'Сначала переведите документ в Архив',
    'removing document should be a draft': 'Документ можно удалять только из Черновика',
    'specified document does not exists': 'Не существует документа с таким идентификатором',
    'only author may remove a document': 'Только автор может удалять документ', // ErrDocumentCanRemoveAuthor
    'only author may start a document': 'Только автор может стартануть документ', // ErrDocumentCanStartAuthor
    'performed operation outside active stage': 'Все операции могут проходить только внутри активного блока', // ErrOutsideActiveStage

    // Task
    'not originUnit in new task': 'Не выбрано подразделение, от лица которого автор создает задачу', // ErrNotOriginUnit
    'assignee can not be empty': 'Не выбран исполнитель задачи', // ErrAssigneeCantBeEmpty
    'no chief in a unit /': 'Нет руководителя в подразделении ', // ErrNoChiefInUnit
    'user and unit can not both be filled in confirmer': 'Подтверждающий не может одновременно содержать информацию и о пользователе и о подразделении', // ErrCantBeFilledInConfirmer
    'completing tasks allowed only for assignee': 'Выполнить задачу может только исполнитель', // ErrOnlyForAssignee
    'rejecting tasks allowed only for confirmer': 'Отклонить задачу может только подтверждающий', // ErrAllowedForConfirmerRejecting
    'confirming tasks allowed only for confirmer': 'Подтвердить задачу может только подтверждающий', // ErrAllowedForConfirmerConfirming
    'reopening tasks allowed only for author': 'Переоткрыть задачу может только автор', // ErrReopeningOnlyForAuthor
    'closing tasks allowed only for author' : 'Отменить задачу может только автор', // ErrClosingOnlyForAuthor
    'remove unexisting task': 'Удаление несуществующей задачи', // ErrRemoveUnexistingTask
    'task ID is required': 'Не указан обязательный параметр ID', // ErrNoTaskId
    'assignee ID is required': 'Не указан обязательный параметр Assignee', // ErrNoAssigneeId
    'task must be assigned to unit': 'Нельзя поменять исполнителя, т.к. задача выставлена на конкретного пользователя', // ErrTaskNotAssignedToUnit
    'only Head of Unit can delegate task': 'Только руководитель подразделения может поменять исполнителя', // ErrNotHeadRequest
    'new Assignee must be employed in target Unit': 'Новый исполнитель должен быть из подразделения, на которое выставлена задача', // ErrAlienAssignee

    // DocumentType
    'list of requisites in incomplete': 'Список реквизитов не полный', // ErrListRequisitesIsInComplete
    'document type and numerator have different centers': 'Тип документа и нумератор должны быть из одной организации', // ErrDocumentTypeForeignNumerator
    'no title in document type requisite': 'Необходимо ввести название реквизита', // ErrNoDocumentTypeRequisiteTitle
    'no type in document type requisite': 'Необходимо указать тип реквизита', // ErrNoDocumentTypeRequisiteType
    'no subtype in document type requisite': 'Необходимо указать справочник', // ErrNoDocumentTypeRequisiteSubType

    'document type name cannot be empty': 'Имя документа не может быть пустым', // ErrEmptyDocTypeName
    'document type code cannot be empty': 'Код документа не может быть пустым', // ErrEmptyDocTypeCode
    'there are documents with such document type': 'Существуют документы с таким типом', // ErrNoDocsWithType
    'changing document_types allowed only for WorkflowManager': 'Доступ запрещен', // ErrOnlyForWorkflowManager

    // Familiarization
    'no title in familiarization': 'Необходимо ввести название', // ErrNoFamiliarizationTitle
    'no UnitID in familiarization': 'Не выбрано подразделение', // ErrNoUnit
    'no ID in start familiarization': 'Не указан идентификатор ознакомления', // ErrNoID
    'cannot add unit from another center': 'Нельзя добавить подразделение из другого центра', // ErrUnitFromAnotherCenter
    'cannot attach document from another center': 'Нельзя добавить документ из другого центра', // ErrAttachDocument
    'update familiarization can only in draft': 'Обновление ознакомления возможно только в Черновике', // ErrFamiliarizationUpdateStatusDraft
    'familiarize can only in active': 'Ознакомиться можно только при статусе Активный', // ErrFamiliarizeStatusDraft
    'add members cannot in draft': 'Добавлять пользователей нельзя в статусе Черновик', // ErrAddMembersStatusDraft
    'no users in checklist': 'Нет пользователей в Списке', // ErrNoUsers
}

// ErrFieldNotExists      = errors.New("specified field does not exists")
// ErrCorruptNoOrg        = errors.New("organization structure is corrupted: no organization unit were found")
// ErrLinkedDocumentTypes = errors.New("unable to remove numerator: numerator is used in document type")
// ErrInvalidOrder        = errors.New("invalid field order")
// ErrNotEqField          = errors.New("new field is not equal to old while use same ID")

export default errors
