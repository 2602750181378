import api from 'api1'
import { AuthenticationStatus, StatusAuthenticating, StatusAuthenticated } from '@/model/Authentication.ts'

export default function ({ dispatch }) {
    return new Promise((resolve, reject) => {
        api.identity.IdentityClient.startAttach(api.identity.StartAttachReq({
        }), data => {
            data = api.identity.Status(data)

            let status = new AuthenticationStatus(
                !data.Authenticating ? null : new StatusAuthenticating(
                    data.Authenticating.SignIn,
                    data.Authenticating.SignUp,
                    data.Authenticating.Attach,
                    data.Authenticating.CompletedFactors,
                    data.Authenticating.RemainingFactors,

                ),
                !data.Authenticated ? null : new StatusAuthenticated(
                    data.Authenticated.User
                )
            )

            dispatch('setStatus', status)
            resolve(data)
        }, error => {
            (error ? console.warn : console.log)('::DONE::Identity.startAttach:', error)
            reject(error)
        })
    })
}
