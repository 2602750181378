













import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { ApprovalTemplate } from '@/model/Workflow'

import ButtonGreen from '@/components/common/buttons/ButtonGreen.vue'

@Component({
    components: { ButtonGreen }
})

export default class WFTActionDeleteNode extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('workflow/getActiveApproval') getActiveApproval!: ApprovalTemplate | null

    get message(): string {
        if (!this.getActiveApproval) {
            return 'Ошибка в данных: обратитесь в техподдержку'
        } else if (!this.getActiveApproval.actor) {
            return `Удалить из цепочки согласования ${this.getActiveApproval.specialName}?`
        } else if (this.getActiveApproval.actor.user) {
            return `Удалить из цепочки согласования ${this.getActiveApproval.actor.user.name.fullName}?`
        } else if (this.getActiveApproval.actor.unit) {
            return `Удалить из цепочки согласования ${this.getActiveApproval.actor.unit.name}?`
        } else {
            return ''
        }
    }

    // *************************************************************
    // METHODS
    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFActiveNode')
    changeWFActiveNode!: (node: string) => void

    @Action('workflow/removeWFApproval')
    removeWFApproval!: (nodeId: string) => void

    deleteNode(): void {
        if (!this.getActiveApproval) {
            return
        }

        this.removeWFApproval(this.getActiveApproval.id)
        // Отмечаем наличие изменений в workFlow
        this.workflowChange(true)
        this.changeWFActiveNode('')
        this.changeWFAction('')
    }
}
