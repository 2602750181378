import Vue from 'vue'
import { MutationTree } from 'vuex'
import { UserOptionsState } from '@/store/user_options/user_options'

import { default_doctype_filters, DocumentListFilters } from '@/model/UserOptions'

const UserOptionsMutations: MutationTree<UserOptionsState> = {
    setArchived(state: UserOptionsState, key: string) {
        if (key === 'tasks') {
            state.archivedTasksReceived = true
        } else if (key === 'familiarization') {
            state.isReceivedArchivedFamiliarization = true
        }
    },

    setArchivedDocuments(state: UserOptionsState, docTypeId: string) {
        Vue.set(state.archiveDocumentReceivedByDocType, docTypeId, true)
    },

    setDocumentHiddenCells(state: UserOptionsState, params: { docTypeId: string, cells: Array<string> }) {
        Vue.set(state.documentHiddenCells, params.docTypeId, params.cells)
    },

    setDocumentListFilter(state: UserOptionsState, params: { docTypeId: string, mut: (filter: DocumentListFilters) => void }) {
        if (!state.documentListFilters[params.docTypeId]) {
            Vue.set(state.documentListFilters, params.docTypeId, default_doctype_filters())
        }

        params.mut(state.documentListFilters[params.docTypeId])
    },

    resetDocumentListFilter(state: UserOptionsState, docTypeId: string) {
        Vue.set(state.documentListFilters, docTypeId, default_doctype_filters())
    },

    setReadNewsIds(state: UserOptionsState, readNewsIds: Array<string>) {
        state.readNewsIds = readNewsIds
    }
}

export default UserOptionsMutations
