var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "modal-select",
    attrs: {
      "visible": _vm.syncedVisible,
      "title": "Выбираем сотрудника"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      },
      "close": _vm.close
    }
  }, [_c('template', {
    slot: "body"
  }, [_c('div', {
    staticClass: "search-employees"
  }, [_c('InputSearch', {
    staticClass: "filter",
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "all-employees"
  }, [_c('div', {
    staticClass: "employees"
  }, _vm._l(_vm.usersFilter, function (user) {
    return _c('EmployeeCard', {
      key: user.id,
      staticClass: "c-pointer",
      attrs: {
        "unit-user": user,
        "popover": false,
        "arrow": false,
        "type": "system"
      },
      on: {
        "click": function click($event) {
          return _vm.loginAs(user.id);
        }
      }
    });
  }), 1)])], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }