<template>
    <div
        class="privacy-policy"
        :class="{'privacy-policy_notAuth': notAuth}"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="policy" />
    </div>
</template>


<script>
import { markdown } from 'markdown'
import policy from 'raw-loader!../../tools/privacy_policy.markdown'

export default {
    computed: {
        policy() {
            return markdown.toHTML(policy)
        },
        notAuth() {
            return this.$route.name === 'privacy'
        }
    }
}
</script>

<style lang="stylus" scoped>
.privacy-policy
    background-color #ffffff
    box-sizing border-box
    >>>
        h1
            text-align center
        p, li
            text-align justify
        strong
            font-size 17px
    &.privacy-policy_notAuth
        padding 0 20px 30px
        height 100vh
        overflow-y auto
</style>
