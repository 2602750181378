var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "workflow"
  }, [_c('DWF_Stage', {
    attrs: {
      "stage": _vm.draftStage,
      "stage-type": "draft",
      "svg-width": _vm.svgWidth,
      "active": _vm.openDocument.base.status === 'draft',
      "passed": _vm.openDocument.base.status !== 'draft',
      "openDocument": _vm.openDocument
    }
  }), _vm._v(" "), _vm._l(_vm.stages, function (stage, index) {
    return _c('DWF_Stage', {
      key: index,
      attrs: {
        "stage-type": stage.type,
        "stage": stage,
        "svg-width": _vm.svgWidth,
        "openDocument": _vm.openDocument,
        "stage-index": index,
        "active": index === _vm.activeStageIndex,
        "passed": index < _vm.activeStageIndex || _vm.openDocument.base.status === 'archived'
      }
    });
  }), _vm._v(" "), _c('DWF_Stage', {
    attrs: {
      "stage": _vm.archivedStage,
      "stage-type": "archived",
      "active": _vm.openDocument.base.status === 'archived',
      "openDocument": _vm.openDocument
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }