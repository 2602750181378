import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { DirectoryState } from '@/store/directories/directoryState'
import { cuntPB, service } from 'api'

import { GrpcError } from '@/tools/errors/errors'
import { Directory, Record } from '@/model/Directory'

const DirectoryActions: ActionTree<DirectoryState, RootState> = {
    callQueryAndWatch({ dispatch }) {
        service.oneAss.QueryAndWatch({})
            .subscribe({
                next(data: cuntPB.OneAssQueryAndWatchMsg) {
                    if (data.Directory) {
                        dispatch('putDirectory', new Directory(data.Directory))
                    } else if (data.RecordEvent) {
                        if (data.RecordEvent.ExistingRecord) {
                            dispatch('putRecord', new Record(data.RecordEvent.ExistingRecord))
                        } else if (data.RecordEvent.RecordCreated) {
                            dispatch('putRecord', new Record(data.RecordEvent.RecordCreated))
                        } else if (data.RecordEvent.RecordUpdated) {
                            dispatch('putRecord', new Record(data.RecordEvent.RecordUpdated))
                        } else if (data.RecordEvent.RecordDeleted) {
                            dispatch('removeRecord', data.RecordEvent.RecordDeleted)
                        }
                    } else {
                        console.log('data---', data)
                    }
                },
                error: (err: GrpcError) => console.log('error', err),
                complete: () => console.log('complete---------')
            })
    },

    async callCreateDirectory({ dispatch }, params: cuntPB.CreateDirectoryReq) {
        const data: cuntPB.Directory = await service.directory.CreateDirectory(params)
        console.log('::DATA::DirectoriesSvc.createDirectory:', data)
        dispatch('putDirectory', new Directory(data))
        return data.ID
    },

    async callSetDirectoryName({ dispatch }, params: cuntPB.SetDirectoryNameReq) {
        const data: cuntPB.Directory = await service.directory.SetDirectoryName(params)
        console.log('::DATA::DirectoriesSvc.setDirectoryName:', data)
        dispatch('putDirectory', new Directory(data))
    },

    async callSetDirectoryDisplayName({ dispatch }, params: cuntPB.SetDirectoryDisplayNameReq) {
        const data: cuntPB.Directory = await service.directory.SetDirectoryDisplayName(params)
        console.log('::DATA::DirectoriesSvc.setDirectoryDisplayName:', data)
        dispatch('putDirectory', new Directory(data))
    },

    async callRemoveDirectory({ dispatch }, id: string) {
        await service.directory.RemoveDirectory({ ID: id })
        console.log('::DATA::DirectoriesSvc.removeDirectory:', id)
        dispatch('removeDirectory', id)
    },

    async callSetDirectoryArchived({ dispatch }, params: cuntPB.SetDirectoryArchivedReq) {
        const data: cuntPB.Directory = await service.directory.SetDirectoryArchived(params)
        console.log('::DATA::DirectoriesSvc.setDirectoryArchived:', data)
        dispatch('putDirectory', new Directory(data))
    },

    putDirectory({ commit, getters }, dir: Directory) {
        const existingDir = getters.getDirectoryById(dir.id)
        if (!existingDir) {
            commit('putDirectory', dir)
        } else if (dir.modificationTime > existingDir.modificationTime) {
            commit('replaceDirectory', dir)
        } else {
            // Do nothing
        }
    },

    removeDirectory({ commit }, directoryId: string) {
        commit('removeDirectory', directoryId)
    },

    ////////////////////////////////////////////////////////////////
    ////                    ////////////////////////////////////////
    ////  DIRECTORY_FIELDS  ////////////////////////////////////////
    ////                    ////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    async callAddDirectoryField({ dispatch }, params: cuntPB.AddDirectoryFieldReq) {
        const data: cuntPB.Directory = await service.directory.AddDirectoryField(params)
        console.log('::DATA::DirectoriesSvc.addDirectoryField:', data)
        dispatch('putDirectory', new Directory(data))
    },

    async callSetDirectoryFieldName({ dispatch }, params: cuntPB.SetDirectoryFieldNameReq) {
        const data: cuntPB.Directory = await service.directory.SetDirectoryFieldName(params)
        console.log('::DATA::DirectoriesSvc.setDirectoryFieldName:', data)
        dispatch('putDirectory', new Directory(data))
    },

    async callSetDirectoryFieldRequired({ dispatch }, params: cuntPB.SetDirectoryFieldRequiredReq) {
        const data: cuntPB.Directory = await service.directory.SetDirectoryFieldRequired(params)
        console.log('::DATA::DirectoriesSvc.setDirectoryFieldRequired:', data)
        dispatch('putDirectory', new Directory(data))
    },

    async callRemoveDirectoryField({ dispatch }, params: cuntPB.RemoveDirectoryFieldReq) {
        const data: cuntPB.Directory = await service.directory.RemoveDirectoryField(params)
        console.log('::DATA::DirectoriesSvc.setDirectoryFieldRequired:', data)
        dispatch('putDirectory', new Directory(data))
    }
}

export default DirectoryActions
