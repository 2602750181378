




















import { Component, Prop, Vue } from 'vue-property-decorator'

import { DocumentTypeRequisite } from '@/model/DocumentType'
import { DocumentRequisite } from '@/model/Document'

import Popover from '@/components/common/Popover.vue'

@Component<RequisiteCell>({
    components: { Popover },
})

export default class RequisiteCell extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) requisite!: DocumentTypeRequisite
    @Prop({ default: () => null }) documentRequisite!: DocumentRequisite | null

    // *************************************************************
    // COMPUTED
    get showPopover(): boolean {
        if (this.requisite.type === 'req_number' || this.requisite.type === 'req_date') {
            return false
        }
        if (this.requisite.type === 'req_table' || this.requisite.type === 'req_editor') {
            return false
        }
        if (!this.documentRequisite) {
            return false
        }
        if (!this.documentRequisite.displayName) {
            return false
        }
        return true
    }

}
