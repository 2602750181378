var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "approve-documents box-shadow__triple"
  }, [_c('ButtonClose', {
    staticClass: "approve-documents__close",
    attrs: {
      "color": "violet",
      "tooltip": "Очистить список",
      "flow": "left"
    },
    on: {
      "click": _vm.emitClear
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "approve-documents__list"
  }, _vm._l(_vm.selectedDocuments, function (document) {
    return _c('Checkbox', {
      key: document.base.id,
      staticClass: "approve-documents__document",
      attrs: {
        "checked": true,
        "label": document.base.name
      },
      on: {
        "change": function change($event) {
          return _vm.emitRemoveFromSelected(document.base.id);
        }
      }
    });
  }), 1), _vm._v(" "), _c('Button', {
    staticClass: "approve-documents__button",
    attrs: {
      "color": "blue",
      "reverse": ""
    },
    on: {
      "click": _vm.accept
    }
  }, [_vm._v("Согласовать выбранные документы")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }