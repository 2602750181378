var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "visible": _vm.visibleModal,
      "title": _vm.task ? _vm.task.text || 'Задача без имени:' + _vm.task.id : undefined
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      },
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "task-modal__body",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('TaskCardFull', {
    staticClass: "h-100",
    attrs: {
      "task": _vm.task
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }