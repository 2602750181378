var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "me"
  }, _vm.$listeners), [_vm.centers.length > 1 ? _c('Select', {
    staticClass: "h-40",
    attrs: {
      "options": _vm.centers,
      "placeholder": "Выбрать центр"
    },
    on: {
      "change": function change($event) {
        return _vm.setCenter($event);
      }
    },
    model: {
      value: _vm.selectedCenterId,
      callback: function callback($$v) {
        _vm.selectedCenterId = $$v;
      },
      expression: "selectedCenterId"
    }
  }) : _vm._e(), _vm._v(" "), _c('h2', {
    staticClass: "pl-8"
  }, [_vm._v("\n        Ваши роли:\n    ")]), _vm._v(" "), _c('ol', {
    staticClass: "pl-8"
  }, _vm._l(_vm.getMe.roles, function (role) {
    return _c('li', {
      key: "".concat(role.unit, "-").concat(role.name),
      staticClass: "mb-1"
    }, [_c('Attribute', {
      attrs: {
        "attribute": role.displayName,
        "text": role.unitName
      }
    })], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }