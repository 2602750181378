

















import { Component, Prop, Vue } from 'vue-property-decorator'

import { DocumentEvent } from '@/model/Document'

@Component({})

export default class DocumentEventBase extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) event!: DocumentEvent
    @Prop({ default: '' }) name!: string

    // *************************************************************
    // COMPUTED
    get actorName(): string {
        if (!this.event.actor) {
            return ''
        }

        return this.event.actor.name.shortName
    }
}
