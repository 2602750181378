import api from 'api1'
import newFamiliarization from '@/model/convert/newFamiliarization'

export default function ({ dispatch }, { unitId, title }) {
    return new Promise((resolve, reject) => {
        api.cunt.FamiliarizationSvcClient.create(api.cunt.CreateFamiliarizationReq({
            UnitID: unitId,
            Title: title
        }), data => {
            data = api.cunt.Familiarization(data)

            dispatch('putFamiliarization', {
                familiarization: newFamiliarization(data)
            })

            resolve(data.ID)
        }, error => {
            (error ? console.warn : console.log)('::DONE::FamiliarizationSvc.createFamiliarization:', error)
            reject(error)
        })
    })
}
