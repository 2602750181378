var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "qr__title",
    attrs: {
      "href": _vm.href,
      "target": "_blank"
    }
  }, [_c('div', [_vm._v("Мобильное приложение\n        "), _c('br'), _vm._v("\n        для " + _vm._s(_vm.text) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "qr__image",
    class: "qr__image_".concat(_vm.source)
  }), _vm._v(" "), _vm.showButton ? _c('div', {
    staticClass: "qr__button",
    class: "qr__button_".concat(_vm.source)
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }