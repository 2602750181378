<template>
    <div class="requisite-card box-shadow__double">
        <div class="flex-row-center mb-3">
            <InputBase
                v-model.lazy.trim="name"
                :disabled="requisite.fromExternalSource"
                placeholder="Имя реквизита"
                :class="{
                    'requisite-card__name': true,
                    'box-shadow__error': !name
                }"
                @keyup.enter.native="updateRequisite('name')"
                @blur="updateRequisite('name')"
            />
            <hr class="requisite-card__hr">
            <div class="flex-row-center requisite-card__actions">
                <div
                    class="requisite-card__action requisite-card__action_border"
                    @click="moveUp"
                >
                    <FAIcon
                        icon="arrow-up"
                        class="requisite-card__icon"
                        :class="{ 'requisite-card__icon_disabled': first || requisite.fromExternalSource}"
                    />
                </div>
                <div
                    class="requisite-card__action requisite-card__action_border"
                    @click="moveDown"
                >
                    <FAIcon
                        icon="arrow-down"
                        class="requisite-card__icon"
                        :class="{ 'requisite-card__icon_disabled': last || requisite.fromExternalSource}"
                    />
                </div>
                <ButtonDelete
                    :tooltip="requisite.fromExternalSource ? 'Реквизит внешней системы' : 'Удалить реквизит'"
                    :disabled="requisite.fromExternalSource"
                    flow="left"
                    class="requisite-card__action"
                    @click="showRemove"
                />
            </div>
        </div>

        <div class="flex-row-center">
            <Checkbox
                v-model="required"
                :disabled="requisite.fromExternalSource"
                class="ml-5"
                label="Обязательный"
                @change="updateRequisite('required')"
            />
            <Checkbox
                v-model="editable"
                :disabled="requisite.fromExternalSource"
                class="ml-5"
                label="Редактируемый"
                @change="updateRequisite('editable')"
            />
            <div class="flex-row-center requisite-card__block ml-auto">
                <div class="requisite-card__select-title">
                    Тип реквизита:
                </div>
                <div>{{ requisitesTypesDisplayName[requisite.type] }}</div>
            </div>


            <div
                v-if="requisite.type === 'req_do'"
                class="flex-row-center requisite-card__block ml-5"
            >
                <div class="requisite-card__select-title">
                    Справочник:
                </div>
                <div>{{ directory }}</div>
            </div>
        </div>
        <RemoveModal
            ref="removeModal"
            :visible.sync="visibleRemoveModal"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import InputBase from '@/components/common/inputs/InputBase'
import Checkbox from '@/components/common/inputs/Checkbox'
import ButtonDelete from '@/components/common/buttons/ButtonDelete'
import RemoveModal from '@/components/common/modals/RemoveModal'

// Импортируем имена типов реквизитов
import { requisitesTypesDisplayName } from '@/components/document_type/util'
import getError from '@/tools/errors/errors.ts'

export default {
    name: 'RequisiteCard',
    components: {
        InputBase,
        Checkbox,
        ButtonDelete,
        RemoveModal
    },
    props: {
        requisite: {
            type: Object,
            default: () => {
                return {}
            }
        },
        typeId: {
            type: String,
            default: ''
        },
        first: {
            type: Boolean,
            default: false
        },
        last: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            requisitesTypesDisplayName,
            name: '',
            required: false,
            editable: false,
            visibleRemoveModal: false
        }
    },
    computed: {
        ...mapGetters('directories', ['getDirectoryById']),
        directory() {
            const directory = this.getDirectoryById(this.requisite.subType)
            return (directory || {}).name
        },
        change() {
            if (this.requisite.name !== this.name) {
                return true
            }
            if (this.requisite.required !== this.required) {
                return true
            }
            if (this.requisite.editable !== this.editable) {
                return true
            }
            return false
        }
    },
    watch: {
        requisite: {
            immediate: true,
            deep: true,
            handler(to) {
                if (to) {
                    this.name = to.name
                    this.required = to.required
                    this.editable = to.editable
                }
            }
        }
    },
    methods: {
        ...mapActions('document_type', [
            'callUpdateTypeRequisite',
            'callRemoveRequisite'
        ]),
        updateRequisite(key) {
            if (!this.change) {
                return
            }

            this.callUpdateTypeRequisite({
                ID: this.typeId,
                RequisiteID: this.requisite.id,
                Name: this.name,
                Required: this.required,
                Editable: this.editable
            }).then(() => {
                let text = ''
                switch (key) {
                case 'name':
                    text = `Имя реквизита сохранено: ${this.requisite.name}`
                    break
                case 'required':
                    text = `Реквизит помечен как: ${this.requisite.required ? 'Обязательный' : 'Необязательный'}`
                    break
                case 'editable':
                    text = `Реквизит помечен как: ${this.requisite.editable ? 'Редактируемый' : 'Нередактируемый'}`
                    break
                default:
                    text = 'Изменения сохранены'

                }
                this.$snotify.success(text, {
                    position: 'centerTop'
                })
            }).catch(error => {
                this.$snotify.error(`Ошибка: "${getError(error) || error.message}"`, {
                    timeout: 0
                })
                this.name = this.requisite.name
                this.editable = this.requisite.editable
                this.required = this.requisite.required
            })
        },
        moveDown() {
            if (this.last || this.requisite.fromExternalSource) {
                return
            }
            this.$emit('moveDown')
        },
        moveUp() {
            if (this.first || this.requisite.fromExternalSource) {
                return
            }
            this.$emit('moveUp')
        },
        showRemove() {
            if (this.requisite.fromExternalSource) {
                return
            }
            this.$refs.removeModal.show({
                itemName: this.requisite.name,
                itemType: 'Реквизита',
                warning: '',
                callback: this.removeRequisite
            })
        },
        removeRequisite() {
            if (!this.requisite.fromExternalSource) {
                this._cancelPreloaderRemoveDocumentTypeRequisite = this.$preloader('remove_document_type_requisite', 'Удаление реквизита типа документа')

                this.callRemoveRequisite({
                    ID: this.typeId,
                    Requisite: this.requisite.id
                }).then(() => {
                    this.visibleRemoveModal = false
                    this._cancelPreloaderRemoveDocumentTypeRequisite()
                }).catch(error => {
                    this._cancelPreloaderRemoveDocumentTypeRequisite()
                    if (error) {
                        this.$snotify.error(`Ошибка: "${error.message}"`, {
                            timeout: 0
                        })
                    }
                })
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/shadows/box-shadow__double.styl'
@import '~@/assets/styles/shadows/box-shadow__error.styl'
@import '~@/assets/styles/colors.styl'

.requisite-card
    padding 5px
    box-sizing border-box
    word-wrap break-word

.requisite-card__actions
    border 1px solid #D9D9D9
    border-radius 4px

.requisite-card__action
    display flex
    align-items center
    justify-content center
    height 24px
    width 24px
    &.requisite-card__action_border
        border-right 1px solid #D9D9D9

.requisite-card__icon
    width 13px
    color $pink-1
    cursor pointer
    &.requisite-card__icon_disabled
        color #ccc
        cursor not-allowed

.requisite-card__name
    max-width 300px
    height 32px
    &.requisite-card__name_new
        flex-grow 1
        max-width 100%

.requisite-card__hr
    flex-grow 1
    margin-right 16px
    height 1px
    background-color #D9D9D9
    border none

.requisite-card__select-title
    margin-right 5px
    font-weight bold

///////////////////////////////////////////////////////
////                 //////////////////////////////////
////  Media          //////////////////////////////////
////                 //////////////////////////////////
///////////////////////////////////////////////////////
@media screen and (max-width: 1099px)
    .requisite-card__block
        display block

</style>
