var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "change-links",
    attrs: {
      "visible": _vm.visibleModal,
      "title": "Добавление связанных документов"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      }
    }
  }, [_c('template', {
    slot: "body"
  }, [_c('div', {
    staticClass: "change-links__body"
  }, [_c('div', {
    staticClass: "flex-row-center my-3"
  }, [_c('Select', {
    staticClass: "h-36 min-width-200",
    attrs: {
      "placeholder": "Выбрать тип документа",
      "required": "",
      "options": _vm.getTypes
    },
    model: {
      value: _vm.documentType,
      callback: function callback($$v) {
        _vm.documentType = $$v;
      },
      expression: "documentType"
    }
  }), _vm._v(" "), _c('InputSearch', {
    staticClass: "flex-1 h-36 ml-3",
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "mb-3"
  }, _vm._l(_vm.links, function (link) {
    return _c('div', {
      key: link.target,
      staticClass: "flex-row-center mb-3"
    }, [_c('div', {
      staticClass: "flex-1"
    }, [_vm._v("\n                        " + _vm._s(link.name) + "\n                    ")]), _vm._v(" "), _c('Select', {
      staticClass: "h-36 min-width-200 mx-3",
      attrs: {
        "placeholder": "Выбрать тип ссылки",
        "required": "",
        "options": _vm.kindTypes
      },
      model: {
        value: link.kind,
        callback: function callback($$v) {
          _vm.$set(link, "kind", $$v);
        },
        expression: "link.kind"
      }
    }), _vm._v(" "), _c('ButtonDelete', {
      on: {
        "click": function click($event) {
          return _vm.removeLink(link.target);
        }
      }
    })], 1);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "change-links__documents"
  }, [_c('table', {
    staticClass: "table"
  }, _vm._l(_vm.documents, function (doc) {
    return _c('tr', {
      key: doc.base.id,
      staticClass: "c-pointer",
      on: {
        "click": function click($event) {
          return _vm.select(doc.base);
        }
      }
    }, [_c('td', [_vm._v(" " + _vm._s(doc.base.name))])]);
  }), 0)])])]), _vm._v(" "), _c('div', {
    staticClass: "flex-row justify-end",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    staticClass: "m-4",
    attrs: {
      "color": "green",
      "disabled": _vm.saveDisabled
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }