import api from 'api1'
import newUser from '@/model/convert/newUser'

export default function ({ commit }, { id, phone }) {

    return new Promise((resolve, reject) => {
        api.cunt.UserSvcClient.setPhone(api.cunt.SetUserPhoneReq({
            ID: id,
            Phone: phone
        }), data => {
            data = api.cunt.User(data)
            console.log('::DATA::UserSvcClient.setPhone:', data)

            commit('setMe', newUser(data))
            resolve()
        }, error => {
            (error ? console.warn : console.log)('::DONE::UserSvcClient.setPhone:', error)
            reject(error)
        })
    })
}
