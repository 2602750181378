<template>
    <div class="reports">
        <h1>Отчеты</h1>
        <ListCard
            v-for="report in reports"
            :key="report.id"
            :item="report"
            @click="openReport(report.id)"
        />
    </div>
</template>

<script>
import ListCard from '@/components/common/cards/ListCard'
import { reports } from '@/components/reports/util'

export default {
    name: 'Reports',
    components: {
        ListCard
    },
    data() {
        return {
            reports
        }
    },
    methods: {
        openReport(reportId) {
            this.$router.push({
                name: 'document',
                params: {
                    section: 'system',
                    module: 'reports',
                    id: reportId
                }
            })
        }
    }
}
</script>
