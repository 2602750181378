

































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { cuntPB } from 'api'

import getError, { GrpcError } from '@/tools/errors/errors'
import { DocumentType } from '@/model/DocumentType'
import { Numerator } from '@/model/numerator.js'

import InputBase from '@/components/common/inputs/InputBase.vue'
import Select from '@/components/common/select/Select.vue'
import Textarea from '@/components/common/Textarea.vue'
import Button from '@/components/common/buttons/Button.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'

import DocumentTypeRequisites from '@/components/document_type/DocumentTypeCard/DocumentTypeRequisites.vue'
import OneAssSettings from '@/components/document_type/OneAssDocTypeSettings.vue'
import RemoveModal from '@/components/common/modals/RemoveModal.vue'

@Component({
    components: {
        InputBase,
        Select,
        Textarea,
        Button,
        ButtonDelete,
        DocumentTypeRequisites,
        OneAssSettings,
        RemoveModal
    }
})

export default class DocumentTypeCard extends Vue {
    @Ref() readonly removeModal!: RemoveModal

    // *************************************************************
    // DATA PARAMS
    typeName = ''
    numerator = ''
    typeCode = ''
    oneAss = false
    description = ''

    loadingName = false
    loadingCode = false
    loadingNumerator = false

    visibleRemoveModal = false
    visibleOneAssModal = false

    // *************************************************************
    // COMPUTED
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null
    @Getter('numerator/getNumeratorsByCenterId') getNumeratorsByCenterId!: (centerId: string) => Array<Numerator>

    get typeId(): string {
        return this.$route.params.id
    }

    get type(): DocumentType | null {
        return this.getTypeById(this.typeId)
    }

    get numerators(): Array<Numerator> {
        if (!this.type) {
            return []
        }
        return this.getNumeratorsByCenterId(this.type.centerId)
    }

    // *************************************************************
    // WATCH
    @Watch('type', { immediate: true, deep: true })
    handleTypeChange(val: DocumentType | null): void {
        if (val) {
            this.typeName = val.name
            this.numerator = val.numerator
            this.typeCode = val.code
            this.description = val.description
            this.oneAss = !!val.oneAss?.collectionID
        } else {
            this.typeName = ''
            this.numerator = ''
            this.typeCode = ''
            this.description = ''
        }
    }

    // *************************************************************
    // METHODS
    @Action('document_type/callSetName')
    callSetName!: (params: cuntPB.SetNameReq) => Promise<void>

    @Action('document_type/callSetNumerator')
    callSetNumerator!: (params: cuntPB.SetnumeratorDocumenttypereq) => Promise<void>

    @Action('document_type/callSetCode')
    callSetCode!: (params: cuntPB.SetCodeReq) => Promise<void>

    @Action('document_type/callSetDescription')
    callSetDescription!: (params: cuntPB.SetDescriptionReq) => Promise<void>

    @Action('document_type/callRemove')
    callRemove!: (id: string) => Promise<void>

    updateName(): void {
        if (this.loadingName) {
            return
        }

        const name = this.typeName.trim()

        if (!name) {
            this.$snotify.warning('Имя типа не может быть пустым')
            this.typeName = this.type?.name ?? ''
            return
        }

        if (this.type?.name === name) {
            return
        }

        this.loadingName = true

        this.callSetName({
            ID: this.typeId,
            Name: name
        })
            .then(() => this.$snotify.success('Имя сохранено!'))
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingName = false
            })
    }

    setCode(): void {
        if (this.loadingCode) {
            return
        }

        const code = this.typeCode.trim()

        if (!code) {
            this.$snotify.warning('Код не может быть пустым')
            return
        }

        if (this.type?.code === code) {
            return
        }

        this.loadingCode = true

        this.callSetCode({
            ID: this.typeId,
            Code: this.typeCode
        }).then(() => this.$snotify.success('Код сохранен!'))
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingCode = false
            })
    }

    setNumerator(numeratorId: string): void {
        this.loadingNumerator = true

        this.callSetNumerator({
            DocumentTypeID: this.typeId,
            NumeratorID: numeratorId
        })
            .then(() => this.$snotify.success('Присвоен новый нумератор!'))
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingNumerator = false
            })
    }

    openOneAss(): void {
        this.visibleOneAssModal = true
    }

    updateDescription(): void {
        const description = this.description.trim()

        if (this.type?.description === description) {
            return
        }

        const cancelPreloaderUpdateDocumentTypeDescription = this.$preloader('set_document_type_description', 'Изменение описания типа документа')
        this.callSetDescription({
            ID: this.typeId,
            Description: this.description.trim()
        })
            .then(() => {
                if (!description) {
                    this.$snotify.success('Описание удалено!')
                } else {
                    this.$snotify.success('Описание сохранено!')
                }
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderUpdateDocumentTypeDescription())
    }

    showRemove(): void {
        if (this.oneAss) {
            this.$snotify.warning('Нельзя удалять типы документов из внешних систем', 'Отклонено!')
            return
        }

        this.removeModal.show({
            itemName: this.type?.name ?? '',
            itemType: 'Типа документа',
            warning: '',
            callback: this.removeType
        })
    }

    removeType(): void {
        const cancelPreloaderRemoveDocumentType = this.$preloader('remove_document_type', 'Удаление типа документа')

        this.callRemove(this.typeId)
            .then(() => this.visibleRemoveModal = false)
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderRemoveDocumentType())
    }
}
