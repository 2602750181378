var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: "files",
    ref: "dropZoneFiles",
    staticClass: "files-wrapper",
    on: {
      "dragenter": function dragenter($event) {
        return _vm.dragEnter($event);
      },
      "dragleave": function dragleave($event) {
        return _vm.dragLeave($event);
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.drop($event);
      }
    }
  }, [_c('div', {
    staticClass: "files"
  }, [_vm.canAddFiles ? _c('div', {
    staticClass: "file__add",
    on: {
      "click": _vm.addFile
    }
  }, [_c('ButtonAdd', {
    staticClass: "file__add_button",
    attrs: {
      "size": "big"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "file__add-title"
  }, [_vm._v("\n                Добавить файл\n            ")])], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.files, function (file) {
    return _c('OneFile', {
      key: file.id,
      attrs: {
        "file": file,
        "document-id": _vm.documentId,
        "can-change": _vm.canChange,
        "group-name": _vm.groupName,
        "name": _vm.name
      },
      on: {
        "replaceFile": function replaceFile($event) {
          return _vm.replaceFile($event);
        }
      }
    });
  })], 2), _vm._v(" "), _c('Uploader', {
    ref: "uploader",
    attrs: {
      "before-upload": _vm.beforeUpload
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }