import Vue from 'vue'

import { MutationTree } from 'vuex'
import { CompanyStructureState } from '@/store/company_structure/companyStructureState'

import { User } from '@/model/User'
import { Unit } from '@/model/Unit'

const CompanyStructureMutations: MutationTree<CompanyStructureState> = {
    ////////////////////////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////  Users       //////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    putUser(state: CompanyStructureState, user: User) {
        const index = state.users.findIndex((u: User) => u.id === user.id)
        if (index === -1) {
            state.users.push(user)
        } else {
            // Иначе заменяем существующий
            Vue.set(state.users, index, user)
        }

        Vue.set(state.userObject, user.id, user)
    },

    removeUser(state: CompanyStructureState, userId: string) {
        const index = state.users.findIndex((u: User) => u.id === userId)
        if (index === -1) {
            console.error('cannot find user:: removeUser: userId: ', userId)
        } else {
            // По найденному индексу удаляем элемент из массива
            state.users.splice(index, 1)
            delete state.userObject[userId]
        }
    },

    cleanUpState(state: CompanyStructureState) {
        state.units = []
        state.users = []
        state.unitsObject = {}
        state.userObject = {}
    },

    ////////////////////////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////  Units       //////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    putUnit(state: CompanyStructureState, unit: Unit) {
        state.units.push(unit)
        Vue.set(state.unitsObject, unit.id, unit)
    },

    replaceUnit(state: CompanyStructureState, unit: Unit) {
        const index = state.units.findIndex((u: Unit) => u.id === unit.id)
        if (index !== -1) {
            Vue.set(state.units, index, unit)
            state.unitsObject[unit.id] = unit
        }
    },

    removeUnit(state: CompanyStructureState, unitId: string) {
        if (state.unitsObject[unitId]) {
            const index = state.units.findIndex((u: Unit) => u.id === unitId)
            state.units.splice(index, 1)
            delete state.unitsObject[unitId]
        }
    }
}

export default CompanyStructureMutations
