









































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Task } from '@/model/Task'

import TaskCardBig from '@/components/tasks/cards/TaskCardBig.vue'

@Component({
    components: { TaskCardBig }
})

export default class TaskList extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) taskId!: string
    @Prop({ default: () => []}) filteredTasks!: Array<Task>

    // *************************************************************
    // DATA PARAMS
    drop = true

    // *************************************************************
    // COMPUTED
    @Getter('task/getTaskById') getTaskById!: (id: string) => Task | null

    get task(): Task | null {
        return this.getTaskById(this.taskId)
    }

    get taskHasParent(): boolean {
        const taskParent = this.task?.parent
        if (!taskParent) {
            return false
        }
        // Необходимо знать не только, есть ли у задачи parent, но еще и доступен ли он данному пользователю по фильтрам
        return !!this.filteredTasks.find((t: Task) => t.id === taskParent)
    }

    get children(): Array<string> {
        const thisTask = this.task
        if (!thisTask) {
            return []
        }

        return thisTask.children.filter((childId: string) => this.filteredTasks.find((task: Task) => task.id === childId))
    }

    // *************************************************************
    // METHODS
    onClickDrop(): void {
        // Меняем по клику значение drop
        this.drop = !this.drop
    }

    // *************************************************************
    // EMIT
    @Emit('openTask')
    emitOpenTask(taskId: string): string {
        return taskId
    }

    @Emit('create')
    emitCreate(taskId: string): string {
        return taskId
    }
}
