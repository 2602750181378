import { cuntPB } from 'api'
import store from '@/store'
import dayjs from 'dayjs'

export const UNIT_TO_ONE_ASS: { [key: string]: { companyId: string, clientId: string } } =  {
    // СВС
    bgqa14208k0ilfme39r0: {
        companyId: 'wergwtneyneett54nnt4n',
        clientId: 'ObcQQrUrcC'
    },
    // ЛГГ
    bgqa1cq08k0ilfme39rg: {
        companyId: 'wergwtneyneett54nnt4n',
        clientId: 'Qm8mIkF1Zr'
    },
    // БЦ
    bin61qa08k0l9b2kmltg: {
        companyId: 'wergwtneyneett54nnt4n',
        clientId: 'TiV9qMIg82'
    },
    // СУ
    bgqsbua08k0j5435mk80: {
        companyId: 'wergwtneyneett54nnt4n',
        clientId: 'GaDYAtIMVb'
    }
}

export const COLUMN_TYPES: { [key: string]: string } = {
    string: 'Строка',
    number: 'Число',
    boolean: 'Да/Нет',
    time: 'Дата',
    reference: 'Элемент справочника'
}

export class DirectoryField {
    public name: string
    public required: boolean
    public type: string
    public subType: string

    constructor(field: cuntPB.DirectoryField) {
        this.name = field.Name
        this.required = field.Required
        this.type = field.Type
        this.subType = field.SubType
    }
}

export class Directory {
    public id: string
    public centerId: string
    public name: string
    public displayNameFormatString: string

    public readOnly: boolean
    public external: boolean
    public archived: boolean

    public fields: Array<DirectoryField>

    public modificationTime: number

    constructor(dir: cuntPB.Directory) {
        this.id = dir.ID
        this.centerId = dir.CenterID
        this.name = dir.Name
        this.displayNameFormatString = dir.DisplayNameFormatString
        this.readOnly = dir.ReadOnly
        this.external = dir.External
        this.archived = dir.Archived
        this.fields = dir.Fields.map((field: cuntPB.DirectoryField) => new DirectoryField(field))

        this.modificationTime = dir.ModificationTime
    }
}

////////////////////////////////////////////////////////////////
////            ////////////////////////////////////////////////
////  Records   ////////////////////////////////////////////////
////            ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
export class Time {
    public tUtc: number
    public tLocal: number
    public tOffset: number
    public tOffsetAbsent: boolean

    constructor(time: cuntPB.Timex) {
        this.tUtc = time.UTC
        this.tLocal = time.Local
        this.tOffset = time.Offset
        this.tOffsetAbsent = time.OffsetAbsent
    }
}

export class RecordField {
    public type: string // from DirectoryField
    public required: boolean // from DirectoryField

    public value: string|number|Time|boolean
    public displayName: string

    constructor(df: DirectoryField, recField: cuntPB.RecordField|undefined) {
        this.type = df.type
        this.required = df.required

        if (!recField) {
            this.value = 'Значение не найдено'
            this.displayName = 'Значение не найдено'
        } else {
            if (df.type === 'string') {
                this.value = recField.String
                this.displayName = recField.String
            } else if (df.type === 'number') {
                this.value = recField.Number
                this.displayName = recField.Number
            } else if (df.type === 'boolean') {
                this.value = recField.Boolean
                if (recField.Boolean === false) {
                    this.displayName = 'Нет'
                } else {
                    this.displayName = 'Да'
                }
            } else if (df.type === 'reference') {
                this.value = recField.Reference
                if (!recField.Reference) {
                    this.displayName = ''
                } else {
                    const record: Record | null = store.getters['directories/getRecordById'](recField.Reference)
                    if (!record) {
                        this.displayName = 'cannot find record:: recordId' + recField.Reference
                    } else {
                        this.displayName = record.displayName
                    }
                }
            } else if (df.type === 'time' && recField.Time) {
                this.value = new Time(recField.Time)
                this.displayName = dayjs(recField.Time.UTC).format('DD/MM/YYYY HH:mm')
            } else {
                this.value = 'Обратитесь в службу поддержки: RecordField' + recField
                this.displayName = 'Обратитесь в службу поддержки: RecordField' + recField
            }
        }
    }
}

export class Record {
    public id: string
    public directoryId: string
    public centerId: string
    public unitId: string
    public archived: boolean

    private readonly recordFields: Array<cuntPB.RecordField>

    public modificationTime: number

    constructor(rec: cuntPB.Record) {
        if (!rec.Fields) {
            console.log('bullshit----------------', rec)
        }
        this.id = rec.ID
        this.directoryId = rec.DirectoryID
        this.centerId = rec.CenterID
        this.unitId = rec.UnitID
        this.archived = rec.Archived
        this.recordFields = rec.Fields
        this.modificationTime = rec.ModificationTime
    }

    get directory(): Directory | null {
        return store.getters['directories/getDirectoryById'](this.directoryId)
    }

    get fields(): Array<RecordField> {
        if (!this.directory) {
            return []
        }
        return this.directory.fields.map((df: DirectoryField, index: number) => new RecordField(df, this.recordFields[index]))
    }

    get displayName(): string {
        if (!this.directory) {
            return 'Справочник не найден'
        }

        const fieldDisplayName: Array<string> = this.fields.map((field: RecordField) => field.displayName)

        return this.directory.displayNameFormatString.replace(/\{(?:[0-9]+)\}/gi, (match)  => {
            const n = +match.slice(1, match.length - 1)
            if (!fieldDisplayName[n]) {
                return `Поля №${n} не существует!`
            } else {
                return '' + fieldDisplayName[n]
            }
        })
    }
}
