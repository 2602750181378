






















import { Component, Prop, Vue } from 'vue-property-decorator'

import { DocumentEvent } from '@/model/Document'
// Импортируем русские имена статусов
import { DOC_STATUSES } from '@/model/Document'

import DocumentEventBase from '@/components/document/common_document/chat/document_events/DocumentEventBase.vue'

@Component({
    components: { DocumentEventBase }
})

export default class DocumentEventTaskCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) event!: DocumentEvent

    // *************************************************************
    // DATA PARAMS
    DOC_STATUSES = DOC_STATUSES
}
