





























import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { Document } from '@/model/Document'

import Button from '@/components/common/buttons/Button.vue'
import ButtonClose from '@/components/common/buttons/ButtonClose.vue'
import Checkbox from '@/components/common/inputs/Checkbox.vue'

@Component<ApproveDocuments>({
    components: {
        Button,
        ButtonClose,
        Checkbox
    }
})

export default class ApproveDocuments extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) selectedDocuments!: Array<Document>

    // *************************************************************
    // METHODS
    @Action('document/callAccept')
    callAccept!: (params: cuntPB.WorkflowApprovalAcceptreq) => Promise<void>

    accept(): void {
        console.log('selectedDocuments', this.selectedDocuments)
        const cancelPreloaderAcceptDocument = this.$preloader('accept_document', 'Согласование документа')

        // Для всех выбранных документов запускаем согласование
        this.selectedDocuments.forEach((document: Document) => {
            this.callAccept({
                Document: document.base.id,
                ID: document.myPendingApproval
            })
                .then(() => {
                    this.$snotify.success(`Согласован ${document.base.name}`)
                    // Выкидываем наверх событие об удачном согласовании
                    this.emitRemoveFromSelected(document.base.id)
                })
                .catch((error: GrpcError) => {
                    this.$snotify.error(getError(error))
                })
                .finally(() => cancelPreloaderAcceptDocument())
        })
    }

    // *************************************************************
    // EMIT
    @Emit('removeFromSelected')
    emitRemoveFromSelected(documentId: string): string {
        return documentId
    }

    @Emit('clear')
    emitClear(): void {
        // Do nothing
    }
}
