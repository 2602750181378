
















































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import getError from '@/tools/errors/errors';
import { GrpcError } from '@/tools/errors/errors'
import { AuthenticationStatus, IdentitiesAndVerifiersList, IdentityData } from '@/model/Authentication';

@Component({
})

// TODO - add changing identity
export default class UserAuthentication extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('me/getStatus') getStatus!: AuthenticationStatus | null
    @Getter('me/getListMyIdentitiesAndVerifiers') getListMyIdentitiesAndVerifiers!: IdentitiesAndVerifiersList | null

    get list(): { phones: Array<IdentityData>, emails: Array<IdentityData> } {
        const list: { phones: Array<IdentityData>, emails: Array<IdentityData> } = {
            phones: [],
            emails: []
        }

        if (!this.getListMyIdentitiesAndVerifiers) {
            return list
        }

        this.getListMyIdentitiesAndVerifiers.identities.forEach((identity: IdentityData) => {
            if (identity.name === 'phone') {
                list.phones.push(identity)
            } else if (identity.name == 'email') {
                list.emails.push(identity)
            }
        })

        return list
    }

    // *************************************************************
    // MOUNTED
    mounted(): void {
        this.getList()
    }

    // *************************************************************
    // METHODS
    @Action('me/callListMyIdentitiesAndVerifiers')
    callListMyIdentitiesAndVerifiers!: () => Promise<void>

    getList(): void {
        this.callListMyIdentitiesAndVerifiers()
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
    }
}
