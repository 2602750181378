var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('WFTStage', {
    staticClass: "work-flow-template__stage work-flow-template__stage_draft",
    attrs: {
      "title": "Черновик"
    }
  }, [_c('div', {
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('p', [_vm._v("\n                Любой документ стартует с 'Черновика'. В этом состоянии автор документа\n                заполняет необходимые реквизиты.\n            ")]), _vm._v(" "), _c('WFTNodeGraph', {
    attrs: {
      "slot": "body",
      "approvals": _vm.draftApprovals,
      "root": true
    },
    slot: "body"
  })], 1)]), _vm._v(" "), _vm._l(_vm.getWorkflow.stages, function (stage, index) {
    return _c('WFTStage', {
      key: index,
      staticClass: "work-flow-template__stage",
      attrs: {
        "active": _vm.getWorkflow.activeStage === index,
        "title": stage.displayName,
        "stage": stage,
        "first": index === 0 || !!stage.vvs || index === 1 && _vm.getWorkflow.vvs !== 'none',
        "last": index === _vm.getWorkflow.stages.length - 1 || !!stage.vvs
      },
      on: {
        "clickStage": function clickStage($event) {
          return _vm.clickStage(index);
        },
        "moveUp": function moveUp($event) {
          return _vm.moveUp(index);
        },
        "moveDown": function moveDown($event) {
          return _vm.moveDown(index);
        }
      }
    }, [stage.type === 'approval' ? _c('WFTNodeGraph', {
      attrs: {
        "slot": "body",
        "dynamic": true,
        "approvals": stage.approvals,
        "stage-vvs": stage.vvs
      },
      on: {
        "changeActiveStage": function changeActiveStage($event) {
          return _vm.selectActiveStage(index);
        }
      },
      slot: "body"
    }) : _vm._e(), _vm._v(" "), stage.type === 'execution' ? _c('WFTTaskGraph', {
      attrs: {
        "slot": "body",
        "tasks": stage.tasks,
        "stage-index": index
      },
      on: {
        "changeActiveStage": function changeActiveStage($event) {
          return _vm.selectActiveStage(index);
        }
      },
      slot: "body"
    }) : _vm._e()], 1);
  }), _vm._v(" "), _c('WFTStage', {
    staticClass: "work-flow-template__stage work-flow-template__stage_archive",
    attrs: {
      "title": "Архив"
    }
  }, [_c('p', {
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_vm._v("\n            После прохождения всех вышестоящих этапов данный тип документа будет попадать автоматически в архив.\n        ")])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }