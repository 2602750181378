import { ActionTree } from 'vuex'
import { WorkflowState } from '@/store/workflow'
import { RootState } from '@/store'

import { TaskTemplate } from '@/model/Workflow'

const TaskActions: ActionTree<WorkflowState, RootState> = {
    addWFTask({ commit }, task: TaskTemplate) {
        console.log('addWFTask::action', task)
        commit('addWFTask', task)
    },

    changeWFTask({ commit }, task: TaskTemplate) {
        commit('changeWFTask', task)
    },

    removeWFTask({ commit, dispatch, getters }) {
        const activeStage = getters.getWorkflow.activeStage
        if (activeStage !== null) {
            const activeTaskIndex = getters.getWorkflow.stages[activeStage].tasks.findIndex((task: TaskTemplate) => task.id === getters.getWorkflow.activeTask)
            if (activeTaskIndex !== -1) {
                commit('removeWFTask', activeTaskIndex)

                // Удаляем активную задачу
                dispatch('changeWFActiveTask', '')
            }
        }
    }
}

export default TaskActions
