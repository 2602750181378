var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pos-relative"
  }, [_c('textarea', {
    ref: "textarea",
    staticClass: "textarea input",
    class: {
      'textarea_required': _vm.required,
      'input_disabled': _vm.disabledArea
    },
    style: _vm.styleObject,
    attrs: {
      "placeholder": _vm.placeholder,
      "rows": "1",
      "disabled": _vm.disabledArea
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.emitInput($event.target.value);
      },
      "blur": _vm.emitBlur
    }
  }), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('div')]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }