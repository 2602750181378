var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.roles.length !== 1 ? _c('div', [_vm.roles.length > 1 ? _c('FormInput', {
    key: "roles",
    attrs: {
      "required": "",
      "label": "От имени какого именно подразделения Вы создаете документ"
    }
  }, [_c('div', {
    attrs: {
      "slot": "form-input-body"
    },
    slot: "form-input-body"
  }, _vm._l(_vm.roles, function (role) {
    return _c('RadioButton', {
      key: "".concat(role.unit, "-").concat(role.name),
      staticClass: "mb-3",
      attrs: {
        "value": role.unit,
        "label": role.unitName
      },
      model: {
        value: _vm.checkOriginUnit,
        callback: function callback($$v) {
          _vm.checkOriginUnit = $$v;
        },
        expression: "checkOriginUnit"
      }
    });
  }), 1)]) : _c('div', {
    key: "empty"
  }, [_c('div', {
    staticClass: "fs-20 mb-3"
  }, [_vm._v("\n            У Вас нет прав на создание данных документов.\n            Для создания нужна одна из следующих ролей:\n        ")]), _vm._v(" "), _c('ul', _vm._l(_vm.rolesWidthNames, function (role) {
    return _c('li', {
      key: role.name,
      staticClass: "mb-1"
    }, [_vm._v("\n                " + _vm._s(role.displayName) + "\n            ")]);
  }), 0)])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }