<template>
    <div
        class="card"
        :class="{'card_active': active}"
        v-on="$listeners"
    >
        <slot name="card-body" />
    </div>
</template>

<script>
export default {
    props: {
        active: { type: Boolean, default: false }
    }
}
// box-shadow 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/colors.styl'

.card
    background-color #ffffff
    box-shadow 0px 2px 3px rgba(0, 0, 0, 0.1)
    border 1px solid $gray-4
    border-radius 4px
    box-sizing border-box
    &.card_active
        border-color $pink-2
</style>
