










































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { WorkflowElement } from '@/model/Document'
import { User } from '@/model/User'
import viewData, { NodeTemplate, LinkTemplate } from '@/tools/template_builder/viewData'

import DWF_ApprovalNode from '@/components/document/workflow/DWF_ApprovalNode.vue'
import DWF_NodePopover from '@/components/document/workflow/DWF_NodePopover.vue'
import DWF_AddActorModal from '@/components/document/workflow/DWF_AddActorModal.vue'

@Component<DWF_ApprovalStage>({
    components: {
        DWF_ApprovalNode,
        DWF_NodePopover,
        DWF_AddActorModal
    }
})

export default class DWF_ApprovalStage extends Vue {
    @Ref() readonly addActor!: DWF_AddActorModal

    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) approvals!: Array<WorkflowElement>
    @Prop({ default: false }) active!: boolean
    @Prop({ default: '' }) status!: string
    @Prop({ default: 0 }) svgWidth!: number

    // *************************************************************
    // DATA PARAMS
    checked: Array<string> = []
    selectedNode: NodeTemplate | null = null
    nodePopover = false

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible

    get documentId(): string {
        return this.$route.params.id
    }

    get svgHeight(): number {
        // Ставим высоту svg равную количеству nodes умноженному на высоту одного node
        // В данном случае высота === 40px
        // +40 - это чтобы компенсировать высоту заголовка WorkFlowStage и сделать симметричный отступ снизу
        return this.approvals.length * 40 + 80
    }

    get vertices(): { nodes: Array<NodeTemplate>, links: Array<LinkTemplate> } {
        return viewData(40, this.svgWidth, this.svgHeight, this.approvals, false, false)
    }

    get showPopover(): boolean {
        if (!this.selectedNode) {
            return false
        }

        return !!(this.selectedNode.actor?.user || this.selectedNode.actor?.unit)
    }

    /** От какого именно узла отклоняем Согласование */
    get firstNode(): NodeTemplate | null {
        // Находим все nodes, у которых наш авторизованный пользователь стоит actor и !!pending
        if (this.status !== 'approval' || !this.active) {
            return null
        }

        return this.vertices.nodes.find((vertex: NodeTemplate) => vertex.actor?.user?.id === this.getMe.id && vertex.pending) ?? null
    }

    get firstNodeId(): string {
        return this.firstNode?.id ?? ''
    }

    get nodesOptions(): { [nodeId: string]: { cursor: boolean, selected: '' | 'selected_for_rejection' | 'selected_for_acceptance' | 'selected_as_rejection_target' }} {
        const options: { [nodeId: string]: { cursor: boolean, selected: '' | 'selected_for_rejection' | 'selected_for_acceptance' | 'selected_as_rejection_target' }} = {}

        this.vertices.nodes.forEach((node: NodeTemplate) => {
            let cursor = false
            if (this.status === 'draft' && node.special) {
                cursor = true
            } else if (this.firstNode && node.viewData.depth < this.firstNode.viewData.depth) {
                cursor = true
            }

            let selected = ''
            if (this.checked.length) {
                if (this.checked[0] === node.id && this.checked.length === 1) {
                    selected = 'selected_for_acceptance'
                } else if (this.checked[0] === node.id && this.checked.length >= 1) {
                    selected = 'selected_for_rejection'
                } else if (this.checked.includes(node.id)) {
                    selected = 'selected_as_rejection_target'
                }
            }

            Vue.set(options, node.id, { cursor, selected })
        })

        return options
    }

    // *************************************************************
    // WATCH
    @Watch('checked', { immediate: true, deep: true })
    handleCheckedChange(to: Array<string>): void {
        // Наблюдаем за массивом с галочками
        this.emitChecked(to)
    }

    @Watch('firstNodeId', { immediate: true })
    handleFirstNodeIdChange(id: string): void {
        if (id) {
            this.checked = [id]
        } else {
            this.checked = []
        }
    }

    // *************************************************************
    // METHODS
    checkItem(node: NodeTemplate): void {
        // В массиве checked по умолчанию есть данные - и первый из них - наш пользователь
        // Он может скинуть согласование на любой уже согласованный node
        if (this.status === 'draft' && node.special) {
            this.addActor.show({
                node: node,
                documentId: this.documentId
            })
            return
        }

        if (!node.passed || !this.firstNode) {
            return
        }
        if (this.status !== 'approval' || !this.active) {
            return
        }

        const index = this.checked.indexOf(node.id)
        if (index === -1) {
            this.checked.push(node.id)
        } else {
            this.checked.splice(index, 1)
        }
    }

    nodeMouseLeave(): void {
        // setTimeout необходим, т.к. нужно учитывать наведение на Popover
        // без него работать не будет
        setTimeout(() => {
            if (!this.nodePopover) {
                this.selectedNode = null
            }
        }, 10)
    }

    nodePopoverMouseLeave(): void {
        this.nodePopover = false
        this.selectedNode = null
    }

    // *************************************************************
    // EMIT
    @Emit('checked')
    emitChecked(checked: Array<string>): Array<string> {
        return checked
    }
}
