import { ActionTree } from 'vuex'
import store, { RootState } from '@/store'
import { DocumentState } from '@/store/document/documentState'
import Vue from 'vue'

import { service, cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { Task } from '@/model/Task'
import { Document, DocumentEvent } from '@/model/Document'
import { Subscription } from 'rxjs'

let documentListStream: Subscription | undefined = undefined
let documentListSteamRestartTimeout: ReturnType<typeof setTimeout> | undefined = undefined
let activeVector = false

let showPreloader = true

const DocumentActions: ActionTree<DocumentState, RootState> = {
    ////////////////////////////////////////////////////////////////
    ////            ////////////////////////////////////////////////
    ////  STREAM    ////////////////////////////////////////////////
    ////            ////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    callOpenListStream({ dispatch }, active: boolean) {

        const openDocumentListSteam = () => {
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!openDocumentListSteam')
            if (!activeVector) {
                return
            }

            let cancelPreloader = () => {
                // Do nothing
            }
            if (showPreloader) {
                cancelPreloader = Vue.prototype.$preloader('documents_stream', 'Получение документов')
            }

            documentListStream = service.document.ListStream({})
                .subscribe({
                    next(data: cuntPB.DocumentListStreamMessage) {
                        if (data.Document) {
                            dispatch('putDocument', new Document(data.Document))
                        } else if (data.DocumentDeleted) {
                            dispatch('removeDocument', data.DocumentDeleted)
                        } else if (data.Dummy) {
                            console.warn('::DATA::DocumentSvcClient.listStream:', data)
                            cancelPreloader()
                            if (showPreloader) {
                                showPreloader = false
                            }
                        } else {
                            console.error('OpenListStream - пришла какая-то дикая хрень!', data)
                        }
                    },
                    error: () => {
                        documentListStream = undefined

                        if (activeVector) {
                            documentListSteamRestartTimeout = setTimeout(() => {
                                openDocumentListSteam()
                            }, 1000)
                        }
                    },
                    complete: () => {
                        // Do nothing
                    }
                })
        }

        activeVector = active

        if (documentListStream) {
            documentListStream.unsubscribe()
            documentListStream = undefined
        }

        if (documentListSteamRestartTimeout) {
            clearTimeout(documentListSteamRestartTimeout)
            documentListSteamRestartTimeout = undefined
        }

        if (activeVector) {
            openDocumentListSteam()
        }
    },

    callArchivedListStream({ dispatch, commit, getters }, docTypeId: string) {
        let count = 0
        let documents: Array<Document> = []

        const docArchiveStream = service.document.ArchivedListStream({ DocumentTypeID: docTypeId })
            .subscribe({
                next(data: cuntPB.ArchivedListStreamMessage) {
                    if (data.Document) {
                        count++
                        const document = new Document(data.Document)
                        const modificationTime = getters.getDocumentModificationTime(document.base.id)
                        if (!modificationTime) {
                            documents.push(document)
                        } else if (document.base.modificationTime > modificationTime) {
                            commit('replaceDocument', document)
                        }
                        if (documents.length >= 100) {
                            commit('putDocuments', documents)
                            documents = []
                        }
                    } else if (data.EOS === 'EOS') {
                        commit('putDocuments', documents)
                        documents = []
                        console.warn('callArchivedListStream::EOS - count', count)
                    } else {
                        console.error('callArchivedListStream - пришла какая-то дикая хрень!')
                    }
                },
                error: (error: GrpcError) => {
                    if (error.message !== 'Request was aborted') {
                        // Чтобы не выходило сообщение об ошибке, когда мы закрываем TaskStream
                        // при выходе из Modal
                        store.$snotify.error(getError(error))
                    }
                },
                complete: () => {
                    // Do nothing
                }
            })

        return () => docArchiveStream.unsubscribe()
    },

    callOpenDocumentStream({ dispatch }, params: { docId: string, eventFunc: (event: DocumentEvent) => void }) {
        const docStream = service.document.Stream({ ID: params.docId })
            .subscribe({
                next(data: cuntPB.DocumentStreamMessage) {
                    if (data.Document) {
                        dispatch('putDocument', new Document(data.Document))
                    } else if (data.Event) {
                        const event = new DocumentEvent(data.Event)
                        params.eventFunc(event)
                    } else if (data.Task) {
                        dispatch('task/putTask', new Task(data.Task), { root: true })
                    } else if (data.TaskDeleted) {
                        dispatch('task/removeTask', data.TaskDeleted, { root: true })
                    } else {
                        console.error('DocumentSvcClient.stream - пришла какая-то дичь', data)
                    }
                },
                error: (error: GrpcError) => {
                    if (error.message === 'access denied') {
                        // Todo
                        // this.returnToList()
                    } else if (error.message !== 'Request was aborted') {
                        // Чтобы не выходило сообщение об ошибке, когда мы закрываем TaskStream
                        // при выходе из Modal
                        store.$snotify.error(getError(error))
                    }
                },
                complete: () => {
                    // Do nothing
                }
            })

        return () => docStream.unsubscribe()
    },

    ////////////////////////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////  DOCUMENT    //////////////////////////////////////////////
    ////              //////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    async callAddWorkflowTask({ dispatch }, params: cuntPB.AddWorkflowTaskReq) {
        const data: cuntPB.Document = await service.document.AddWorkflowTask(params)
        console.log('::DATA::DocumentSvcClient.addWorkflowTask:', data)
        dispatch('putDocument', new Document(data))
    },

    async callAddActorToWorkflowApproval({ dispatch }, params: cuntPB.AddActorToWorkflowApprovalReq) {
        const data: cuntPB.Document = await service.document.AddActorToWorkflowApproval(params)
        console.log('::DATA::DocumentSvcClient.addActorToWorkflowApproval:', data)
        dispatch('putDocument', new Document(data))
    },

    async callUpdateRequisite({ dispatch }, params: cuntPB.UpdateDocumentRequisiteReq) {
        const data: cuntPB.Document = await service.document.UpdateRequisite(params)
        console.log('::DATA::DocumentSvcClient.updateRequisite:', data)
        dispatch('putDocument', new Document(data))
    },

    async callUpdateComment({ dispatch }, params: cuntPB.UpdateDocumentCommentReq) {
        const data: cuntPB.Document = await service.document.UpdateComment(params)
        console.log('::DATA::DocumentSvcClient.updateComment:', data)
        dispatch('putDocument', new Document(data))
    },

    // Todo - unused now
    async callRegister({ dispatch }, params: cuntPB.DocumentRegisterReq) {
        const data: cuntPB.Document = await service.document.Register(params)
        console.log('::DATA::DocumentSvcClient.register:', data)
        dispatch('putDocument', new Document(data))
    },

    async callSetLinks({ dispatch }, params: cuntPB.SetLinksReq) {
        const data: cuntPB.Document = await service.document.SetLinks(params)
        console.log('::DATA::DocumentSvcClient.setLinks:', data)
        dispatch('putDocument', new Document(data))
    },

    async callRemove({ dispatch }, id: string) {
        await service.document.Remove({ ID: id })
        console.log('::DATA::DocumentSvcClient.remove:', id)
        dispatch('removeDocument', id)
    },

    async callNewDocument({ dispatch }, params: cuntPB.NewDocumentReq) {
        const data: cuntPB.Document = await service.document.NewDocument(params)
        console.log('::DATA::DocumentSvcClient.newDocument:', data)
        const doc = new Document(data)
        dispatch('putDocument', doc)
        return doc.base.id
    },

    async callDraft({ dispatch }, id: string) {
        const data: cuntPB.Document = await service.document.Draft({ ID: id })
        console.log('::DATA::DocumentSvcClient.draft:', data)
        dispatch('putDocument', new Document(data))
    },

    async callStart({ dispatch }, id: string) {
        const data: cuntPB.Document = await service.document.Start({ ID: id })
        console.log('::DATA::DocumentSvcClient.start:', data)
        dispatch('putDocument', new Document(data))
    },

    async callArchive({ dispatch }, id: string) {
        const data: cuntPB.Document = await service.document.Archive({ ID: id })
        console.log('::DATA::DocumentSvcClient.archive:', data)
        dispatch('putDocument', new Document(data))
    },

    putDocument({ commit, getters }, document: Document) {
        const modificationTime = getters.getDocumentModificationTime(document.base.id)

        if (!modificationTime) {
            commit('putDocument', document)
        } else {
            if (document.base.modificationTime > modificationTime) {
                commit('replaceDocument', document)
            }
        }
    },

    removeDocument({ commit }, id: string) {
        commit('removeDocument', id)
    },
    ////////////////////////////////////////////////////////////////
    ////            ////////////////////////////////////////////////
    ////  EVENT     ////////////////////////////////////////////////
    ////            ////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    async callPutMessage({ dispatch }, params: cuntPB.DocumentPutMessageReq) {
        const data: cuntPB.DocumentEvent = await service.document.PutMessage(params)
        return new DocumentEvent(data)
    }
}

export default DocumentActions
