<template>
    <div
        v-if="familiarization"
        class="my-3"
    >
        <div class="flex-row-center justify-between mb-3">
            <Checkbox
                v-model="onlyFamiliarization"
                label="Только ознакомления"
            />
            <div
                tooltip="Распечатать журнал"
                flow="left"
                class="familiarization__print"
                @click="print"
            >
                <FAIcon icon="print" />
            </div>
        </div>

        <table
            ref="table"
            class="table"
        >
            <thead>
                <th>Тип события</th>
                <th>Автор</th>
                <th>Расшифровка</th>
                <th>Дата</th>
            </thead>
            <tbody>
                <tr v-if="!onlyFamiliarization">
                    <td>Создание</td>
                    <td>{{ familiarization.actor.user.name.shortName }}</td>
                    <td />
                    <td>{{ familiarization.creationTimeLocalizedLongString }}</td>
                </tr>
                <tr
                    v-for="event in filteredEvents"
                    :key="event.id"
                >
                    <td>{{ eventTypes[event.type].title }}</td>
                    <td>{{ event.actor ? event.actor.name.shortName : 'Автоматически' }}</td>
                    <td>
                        <div
                            v-for="(field, key) in event.fields"
                            :key="key"
                            class="flex-row-center"
                        >
                            <strong class="mr-3">{{ eventTypes[event.type].fields[key] }}:</strong>
                            <span
                                v-if="event.type === 'status_transition'"
                                key="status_transition"
                            >
                                {{ statuses[field] }}
                            </span>
                            <span
                                v-else-if="event.type === 'familiarize'"
                                key="familiarize"
                            >
                                {{ field.name.fullName }}
                            </span>
                            <span
                                v-else-if="event.type === 'new_members'"
                                key="new_members"
                            >
                                <div
                                    v-for="item in field"
                                    :key="item.id"
                                >
                                    {{ key === 'units' ? item.name : item.name.fullName }}
                                </div>
                            </span>
                        </div>
                    </td>
                    <td>{{ event.creationTimeLocalizedLongString }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Printd from 'printd'
import Checkbox from '@/components/common/inputs/Checkbox'
import { eventTypes, statuses } from '@/components/familiarization/util'
import dynamicSort from '@/tools/sort/dynamicSort'

export default {
    components: { Checkbox },
    props: {
        events: {
            type: Array, default() { return []}
        }
    },
    data() {
        return {
            eventTypes,
            statuses,
            onlyFamiliarization: false
        }
    },
    computed: {
        ...mapGetters('familiarization', ['getFamiliarizationById']),
        familiarizationId() {
            return this.$route.params.id
        },
        familiarization() {
            console.log('events', this.events)
            return this.getFamiliarizationById(this.familiarizationId)
        },
        filteredEvents() {
            let events = this.events
            if (this.onlyFamiliarization) {
                events = this.events.filter(event => event.type === 'familiarize')
            }
            return events.sort(dynamicSort('ts'))
        }
    },
    mounted() {
        this.d = new Printd()
    },
    methods: {
        print() {
            this.d.print( this.$refs.table, [
                'table { border: 2px solid black; border-collapse: collapse; width: 100% }',
                'th { border: 2px solid black; border-collapse: collapse; height: 90px; font-size: 14px; padding: 5px }',
                'td { border: 2px solid black; border-collapse: collapse; height: 50px; font-size: 14px; padding: 5px }'
            ])
        },
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/table.styl'
@import '~@/assets/styles/colors.styl'

.familiarization__print
    color $pink-1
    font-size 20px
    cursor pointer
</style>
