/* eslint-disable vue/no-v-html */
<template>
    <div
        v-if="showDocumentation"
        class="documentation"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="directories" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="document_types" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="documents" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="numerators" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="tasks" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="units" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { markdown } from 'markdown'
import directories from 'raw-loader!../../../../kant-documentation/directories.markdown'
import document_types from 'raw-loader!../../../../kant-documentation/document_types.markdown'
import documents from 'raw-loader!../../../../kant-documentation/documents.markdown'
import numerators from 'raw-loader!../../../../kant-documentation/numerators.markdown'
import tasks from 'raw-loader!../../../../kant-documentation/tasks.markdown'
import units from 'raw-loader!../../../../kant-documentation/units.markdown'
const usersIds = ['bgiagga08k0lpplv62f0', 'bgjhsgi08k0j989o72t0', 'bggkdia08k0ga59rraf0', 'bgjhoca08k0j989o72qg']

export default {
    name: 'Documentation',
    computed: {
        ...mapGetters('me', ['getStatus', 'getMe']),
        user() {
            if (!this.getStatus) {
                return undefined
            } else if (!this.getStatus.authenticated) {
                return undefined
            } else {
                return this.getStatus.authenticated.user
            }
        },
        showDocumentation() {
            return (!!this.user && usersIds.includes(this.user))
        },
        directories() {
            return markdown.toHTML(directories)
        },
        document_types() {
            return markdown.toHTML(document_types)
        },
        documents() {
            return markdown.toHTML(documents)
        },
        numerators() {
            return markdown.toHTML(numerators)
        },
        tasks() {
            return markdown.toHTML(tasks)
        },
        units() {
            return markdown.toHTML(units)
        }
    },
    watch: {
        user: {
            immediate: true,
            handler(to) {
                if (!to || !usersIds.includes(to)) {
                    this.ajaja()
                }
            }
        }
    },
    methods: {
        ajaja() {
            this.$router.push({ name: 'ajaja' })
        }
    }
}
</script>

<style lang="stylus" scoped>
.documentation
    padding 15px 15px 40px
    height 100%
    box-sizing border-box
    overflow-y auto
</style>
