










import { Component, Vue } from 'vue-property-decorator'
import { service, cuntPB } from 'api'

import getError, { GrpcError } from '@/tools/errors/errors'
import { User } from '@/model/User'

import Button from '@/components/common/buttons/Button.vue'
import SelectUser from '@/components/back_door/SelectUser.vue'

@Component({
    components: {
        Button,
        SelectUser
    }
})

export default class BackDoor extends Vue {
    // *************************************************************
    // DATA PARAMS
    visibleUsersModal = false
    users: Array<User> = []

    // *************************************************************
    // METHODS
    showUsers(): void {
        this.visibleUsersModal = true
        // Запрашиваем всех пользователей системы
        this.callGetUsersByRoles()
    }

    callGetUsersByRoles(): void {
        const cancelPreloaderGetUsersInBackdoor = this.$preloader('get_users_in_backdoor', 'Загрузка пользователей')

        // Запрашиваем всех пользователей системы - вызываем из метода show
        service.user.GetUsersByRoles({ Roles: []})
            .subscribe({
                next: (data: cuntPB.User) => {
                    const newUser = new User(data)
                    // Если такого сотрудника нет в нашем массиве, то добавляем его
                    if (!(this.users.find((user: User) => user.id === newUser.id))) {
                        this.users.push(newUser)
                    }
                },
                error: (error: GrpcError) => {
                    this.$snotify.error(getError(error))
                },
                complete: () => cancelPreloaderGetUsersInBackdoor()
            })
    }
}

