export default function ({ state, commit }, { numeratorId, fieldIndex, newFieldIndex }) {
    let existingNumerator = state.numerators.find(num => num.id === numeratorId)
    commit('saveVersion', {
        numerator: existingNumerator
    })

    commit('moveFields', {
        numeratorId,
        fieldIndex,
        newFieldIndex
    })
}
