import UserOptionsGetters from '@/store/user_options/_getters'
import UserOptionsMutations from '@/store/user_options/_mutations'
import UserOptionsActions from '@/store/user_options/_actions'

import news from '@/store/user_options/news'
import { News } from '@/store/user_options/news'
import { DocumentListFilters } from '@/model/UserOptions'

export class UserOptionsState {
    archiveDocumentReceivedByDocType: { [documentTypeId: string]: boolean } = {} // получены архивные документы данного типа
    documentHiddenCells: { [documentTypeId: string]: Array<string> } = {}
    documentListFilters: { [documentTypeId: string]: DocumentListFilters }= {}
    news: Array<News> = news
    readNewsIds: Array<string> = []

    archivedTasksReceived = false // архивные задачи получены
    isReceivedArchivedFamiliarization = false // архивные ознакомления получены
}

const UserOptionsSubModule = {
    namespaced: true,
    state: new UserOptionsState(),
    getters: UserOptionsGetters,
    mutations: UserOptionsMutations,
    actions: UserOptionsActions
}

export default UserOptionsSubModule
