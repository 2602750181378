var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "modal-select",
    attrs: {
      "visible": _vm.visibleModal,
      "title": "Таблица"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      },
      "close": _vm.closeModal
    }
  }, [_c('template', {
    slot: "body"
  }, [_c('div', {
    staticClass: "body-container"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', _vm._l(_vm.headlines, function (field, index) {
    return _c('th', {
      key: index
    }, [_vm._v("\n                            " + _vm._s(field) + "\n                        ")]);
  }), 0)]), _vm._v(" "), _c('tbody', _vm._l(_vm.body, function (coll, index) {
    return _c('tr', {
      key: index
    }, _vm._l(coll, function (field, fieldIndex) {
      return _c('td', {
        key: fieldIndex
      }, [_vm._v("\n                            " + _vm._s(field) + "\n                        ")]);
    }), 0);
  }), 0)])])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }