var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showDocumentation ? _c('div', {
    staticClass: "documentation"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.directories)
    }
  }), _vm._v(" "), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.document_types)
    }
  }), _vm._v(" "), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.documents)
    }
  }), _vm._v(" "), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.numerators)
    }
  }), _vm._v(" "), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.tasks)
    }
  }), _vm._v(" "), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.units)
    }
  })]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }