var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pl-3"
  }, [_c('ul', _vm._g({
    staticClass: "m-0 p-0"
  }, _vm.$listeners), _vm._l(_vm.tasksWithoutParents, function (task) {
    return _c('TaskList', {
      key: task.id,
      attrs: {
        "task-id": task.id,
        "filteredTasks": _vm.filteredTasks
      },
      on: {
        "create": function create($event) {
          return _vm.emitCreate($event);
        },
        "openTask": function openTask($event) {
          return _vm.emitOpenTask($event);
        }
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }