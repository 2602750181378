var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tosser"
  }, [_c('div', {
    staticClass: "tosser__list"
  }, [_c('h3', {
    staticClass: "tosser__title"
  }, [_vm._v("\n            Подразделение:\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "tosser__list-types"
  }, _vm._l(_vm.units, function (unit) {
    return _c('RadioButton', {
      key: unit.id,
      staticClass: "tosser__list-type",
      attrs: {
        "value": unit.id,
        "label": unit.name
      },
      model: {
        value: _vm.selectedUnit,
        callback: function callback($$v) {
          _vm.selectedUnit = $$v;
        },
        expression: "selectedUnit"
      }
    });
  }), 1), _vm._v(" "), _c('h3', {
    staticClass: "tosser__title"
  }, [_vm._v("\n            Тип документа:\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "tosser__list-types"
  }, _vm._l(_vm.types, function (type) {
    return _c('RadioButton', {
      key: type.id,
      staticClass: "tosser__list-type",
      attrs: {
        "disabled": !_vm.selectedUnit,
        "value": type.id,
        "label": type.name
      },
      model: {
        value: _vm.selectedType,
        callback: function callback($$v) {
          _vm.selectedType = $$v;
        },
        expression: "selectedType"
      }
    });
  }), 1)]), _vm._v(" "), _vm.selectedType ? _c('TosserBuilder', {
    staticClass: "tosser__main",
    attrs: {
      "unit-id": _vm.selectedUnit,
      "document-type-id": _vm.selectedType
    },
    on: {
      "changeDocTypeId": function changeDocTypeId($event) {
        _vm.selectedType = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }