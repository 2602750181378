var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat"
  }, [_c('div', {
    staticClass: "chat__header"
  }, [_c('div', {
    staticClass: "chat__header_title"
  }, [_vm._v("\n            ЧАТ\n        ")]), _vm._v(" "), _c('Checkbox', {
    staticClass: "chat__checkbox",
    attrs: {
      "label": "cобытия"
    },
    on: {
      "change": function change($event) {
        return _vm.onCheckEvents($event);
      }
    },
    model: {
      value: _vm.checkEvents,
      callback: function callback($$v) {
        _vm.checkEvents = $$v;
      },
      expression: "checkEvents"
    }
  }), _vm._v(" "), _c('Checkbox', {
    staticClass: "chat__checkbox",
    attrs: {
      "label": "cообщения"
    },
    on: {
      "change": function change($event) {
        return _vm.onCheckMessages($event);
      }
    },
    model: {
      value: _vm.checkMessages,
      callback: function callback($$v) {
        _vm.checkMessages = $$v;
      },
      expression: "checkMessages"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "chat__message-input"
  }, [_c('Textarea', {
    staticClass: "chat__textarea",
    attrs: {
      "min-lines": 1,
      "max-lines": 5,
      "name": "chat-message",
      "placeholder": "Введите сообщение"
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "chat__spend",
    on: {
      "click": _vm.spendMessage
    }
  }, [_c('svg', {
    staticClass: "chat__spend_icon",
    attrs: {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
    }
  })])])], 1), _vm._v(" "), _c('div', {
    staticClass: "chat__body"
  }, _vm._l(_vm.events, function (event) {
    return _c('ChatCardSelector', {
      key: event.id,
      attrs: {
        "event": event,
        "options": _vm.eventFieldTypes[event.type]
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }