var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "info-modal",
    attrs: {
      "visible": _vm.visibleModal,
      "title": "Просьба ознакомиться с информацией"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      }
    }
  }, [_c('template', {
    slot: "body"
  }, [_c('div', {
    staticClass: "info-modal__news-container"
  }, _vm._l(_vm.getNews, function (one) {
    return _c('HelpCard', {
      key: one.id,
      attrs: {
        "news": one,
        "read": !_vm.getNotReadNewsIds.includes(one.id)
      },
      on: {
        "close": _vm.close
      }
    }, [_c('template', {
      slot: "content"
    }, [_vm._v("\n                    " + _vm._s(one.content) + "\n                ")])], 2);
  }), 1)]), _vm._v(" "), _c('div', {
    staticClass: "info-modal__footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Checkbox', {
    staticClass: "info-modal__footer_checkbox",
    attrs: {
      "label": "Больше не показывать"
    },
    model: {
      value: _vm.readAll,
      callback: function callback($$v) {
        _vm.readAll = $$v;
      },
      expression: "readAll"
    }
  }), _vm._v(" "), _c('Popover', {
    attrs: {
      "arrow": true
    }
  }, [_c('div', {
    staticClass: "info-modal__qr-button info-modal__qr-button_google",
    attrs: {
      "slot": "host"
    },
    slot: "host"
  }), _vm._v(" "), _c('template', {
    slot: "popover"
  }, [_c('QR', {
    staticClass: "info-modal__qr",
    attrs: {
      "sourse": "google",
      "show-button": false
    }
  })], 1)], 2), _vm._v(" "), _c('Popover', {
    attrs: {
      "arrow": true
    }
  }, [_c('div', {
    staticClass: "info-modal__qr-button info-modal__qr-button_apple",
    attrs: {
      "slot": "host"
    },
    slot: "host"
  }), _vm._v(" "), _c('template', {
    slot: "popover"
  }, [_c('QR', {
    staticClass: "info-modal__qr",
    attrs: {
      "sourse": "apple",
      "show-button": false
    }
  })], 1)], 2), _vm._v(" "), _c('Button', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.onClickViewed
    }
  }, [_vm._v("\n            OK\n        ")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }