


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { DocumentEvent } from '@/model/Document'
import { User } from '@/model/User'

import DocumentEventRegistrationCard from '@/components/document/common_document/chat/document_events/DocumentEventRegistrationCard.vue'
import DocumentEventStatusTransitionCard from '@/components/document/common_document/chat/document_events/DocumentEventStatusTransitionCard.vue'
import DocumentEventApprovedCard from '@/components/document/common_document/chat/document_events/DocumentEventApprovedCard.vue'
import DocumentEventMessageCard from '@/components/document/common_document/chat/document_events/DocumentEventMessageCard.vue'
import DocumentEventRejectedCard from '@/components/document/common_document/chat/document_events/DocumentEventRejectedCard.vue'
import DocumentEventRequisiteCard from '@/components/document/common_document/chat/document_events/DocumentEventRequisiteCard.vue'
import DocumentEventTaskCard from '@/components/document/common_document/chat/document_events/DocumentEventTaskCard.vue'

@Component({
    components: {
        DocumentEventMessageCard,
        DocumentEventRegistrationCard,
        DocumentEventStatusTransitionCard,
        DocumentEventApprovedCard,
        DocumentEventRejectedCard,
        DocumentEventRequisiteCard,
        DocumentEventTaskCard
    }
})

export default class DocumentChartCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) event!: DocumentEvent

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible
    @Getter('company_structure/getUserById') getUserById!: (userId: string) => User | null

    get alignment(): 'right' | 'left' {
        if (this.event.type !== 'message' || !this.event.actor) {
            return 'left'
        }

        // Если тип = сообщение, показываем справа или слева в зависимости от пользователя
        if (this.event.actor.id === this.getMe.id) {
            return 'right'
        } else {
            return 'left'
        }
    }

    get smallAvatar(): string {
        if (!this.event.actor) {
            return ''
        }

        const actor = this.getUserById(this.event.actor.id)
        if (!actor) {
            return ''
        }

        return actor.avatar.small
    }
}
