var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex-row-center justify-end mb-3"
  }, [_c('ButtonViolet', {
    staticClass: "h-36",
    attrs: {
      "text": "Очистить фильтры",
      "small": true
    },
    on: {
      "click": _vm.clickResetFilter
    }
  }), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "ml-5 h-36",
    attrs: {
      "text": "Распечатать реестр",
      "small": true
    },
    on: {
      "click": _vm.emitPrint
    }
  }), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "ml-5 h-36",
    attrs: {
      "text": "Настойка таблицы",
      "small": true
    },
    on: {
      "click": _vm.showTableSettings
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "document-filters__body"
  }, [_c('div', {
    staticClass: "document-filters__block"
  }, [_c('div', {
    staticClass: "document-filters__title"
  }, [_vm._v("Статус")]), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, [_vm._l(_vm.allStatuses, function (value, key) {
    return _c('Checkbox', {
      key: key,
      staticClass: "document-filters__checkbox",
      attrs: {
        "checked": _vm.docTypeFilters.status[key],
        "label": value
      },
      on: {
        "change": function change($event) {
          return _vm.setStatusFilter(key, $event);
        }
      }
    });
  }), _vm._v(" "), !_vm.getArchivedDocumentsReceived(_vm.typeId) ? _c('ButtonViolet', {
    staticClass: "document-filters__checkbox",
    attrs: {
      "small": true,
      "text": "Получить архивные документы"
    },
    on: {
      "click": _vm.getArchive
    }
  }) : _vm._e()], 2)]), _vm._v(" "), _c('div', {
    staticClass: "document-filters__block"
  }, [_c('div', {
    staticClass: "document-filters__title"
  }, [_vm._v("Мои документы")]), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, [_c('Checkbox', {
    staticClass: "document-filters__checkbox",
    attrs: {
      "checked": _vm.docTypeFilters.myPending,
      "label": "Согласую Я"
    },
    on: {
      "change": function change($event) {
        return _vm.setMyDocFilter('myPending', $event);
      }
    }
  }), _vm._v(" "), _c('Checkbox', {
    staticClass: "document-filters__checkbox",
    attrs: {
      "checked": _vm.docTypeFilters.myTasks,
      "label": "Мои задачи"
    },
    on: {
      "change": function change($event) {
        return _vm.setMyDocFilter('myTasks', $event);
      }
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "document-filters__block"
  }, [_c('div', {
    staticClass: "document-filters__title"
  }, [_vm._v("Организация")]), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center flex-wrap"
  }, _vm._l(_vm.organisations, function (unit) {
    return _c('RadioButton', {
      key: unit.id,
      staticClass: "document-filters__checkbox",
      attrs: {
        "group-value": _vm.docTypeFilters.organisation,
        "value": unit.id,
        "label": unit.name
      },
      on: {
        "change": function change($event) {
          return _vm.setOrganizationFilter($event);
        }
      }
    });
  }), 1)])]), _vm._v(" "), _c('TableSettings', {
    attrs: {
      "visible": _vm.visibleTableModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleTableModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }