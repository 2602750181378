var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "textarea-container",
    class: {
      error: _vm.error,
      'textarea_disabled': _vm.disabled
    }
  }, [_c('textarea', {
    ref: "textarea",
    staticClass: "textarea",
    class: {
      'textarea_disabled': _vm.disabled
    },
    attrs: {
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "rows": "1"
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event.target.value);
      }
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }