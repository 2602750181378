var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-units"
  }, [_c('InputSearch', {
    staticClass: "search-units__filter",
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "search-units__body"
  }, [_c('div', {
    staticClass: "search-units__units-list"
  }, _vm._l(_vm.units, function (unit) {
    return _c('UnitCard', {
      key: unit.id,
      attrs: {
        "unit-info": unit
      },
      on: {
        "click": function click($event) {
          return _vm.canMoveUnit(unit.id);
        }
      }
    });
  }), 1), _vm._v(" "), _c('Button', {
    staticClass: "m-4",
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.emitGoToCreate
    }
  }, [_vm._v("\n            Не нашли?... Создать\n        ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }