





































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

const regPhone = (target: string): string => target.replace(/\+7 \(|\+7|\+|7 \(|-| |\*|\(|\)|\D/gi, '')
const isPhone = (value = '') => new RegExp('^(\\+7|7|8)?[\\s\\-]?\\(?[9][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$', 'im').test(value)

@Component({})

export default class PhoneInput extends Vue {
    @Ref() phone!: HTMLInputElement

    // *************************************************************
    // PROPS
    @Prop({ default: { identity: '', valid: false } }) value!: { identity: string, valid: boolean }

    // *************************************************************
    // DATA PARAMS
    errorPhone = ''

    // *************************************************************
    // COMPUTED
    get normalizedPhone(): string {
        let str = regPhone(this.denormalizedPhone)
        if (str[0] === '7') {
            str = str.substring(1)
        }
        return str
    }

    get isValid(): boolean {
        return isPhone(this.normalizedPhone)
    }

    get denormalizedPhone(): string {
        let str = regPhone(this.value.identity)
        if (str[0] === '7') {
            str = str.substring(1)
        }
        let phone = ''
        if (str.length) {
            phone = `( ${str.substring(0, 3)}`
        }
        if (str.length > 3) {
            phone = `${phone} ) ${str.substring(3, 6)}`
        }
        if (str.length > 6) {
            phone = `${phone} - ${str.substring(6, 8)}`
        }
        if (str.length > 8) {
            phone = `${phone} - ${str.substring(8, 10)}`
        }
        return phone
    }

    // *************************************************************
    // WATCH
    @Watch('isValid', { immediate: true })
    handleValidChange(): void {
        this.emitInput(this.normalizedPhone)
    }

    // *************************************************************
    // METHODS
    onClickPhone(): void {
        this.phone.focus()
    }

    onInputPhone(phone: string): void {
        this.errorPhone = ''
        let str = regPhone(phone)
        if (str[0] === '7') {
            str = str.substring(1)
        }

        if (str !== this.value.identity) {
            this.emitInput(str)
        }
    }

    onBlurPhone(): void {
        if (!this.isValid) {
            this.errorPhone = 'Номер телефона некорректен'
        }
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(phone: string): { identity: string, valid: boolean } {
        return { identity: phone, valid: isPhone(phone) }
    }
}
