import { GetterTree } from 'vuex'
import { RootState } from '@/store'

import TosserMutations from '@/store/tosser/_mutations'
import TosserActions from '@/store/tosser/_actions'

import { TosserRule } from '@/model/Tosser'

export class TosserState {
    tosserChange = false
    rules: Array<TosserRule> = []
}

const getters: GetterTree<TosserState, RootState> = {
    getTosserChange: (state: TosserState) => state.tosserChange,

    getRules: (state: TosserState) => state.rules,
}

const TosserSubModule = {
    namespaced: true,
    state: new TosserState(),
    getters,
    mutations: TosserMutations,
    actions: TosserActions
}

export default TosserSubModule
