
































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import { Getter } from 'vuex-class'

import { DocumentLink, DOC_LINK_TYPES, Document } from '@/model/Document'
import { DocumentLinkWithType } from '@/components/document/common_document/links/model'

import Link from '@/components/common/Link.vue'
import DocumentBlockTitle from '@/components/document/common_document/DocumentBlockTitle.vue'
import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'
import DocumentChangeLinks from '@/components/document/common_document/links/DocumentChangeLinks.vue'

@Component<DocumentLinks>({
    components: {
        Link,
        DocumentBlockTitle,
        ButtonAdd,
        DocumentChangeLinks
    }
})

export default class DocumentLinks extends Vue {
    @Ref() readonly modal!: DocumentChangeLinks

    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) linksInfo!: Array<DocumentLink>
    @Prop({ default: false }) isMe!: boolean

    // *************************************************************
    // DATA PARAMS
    DOC_LINK_TYPES = DOC_LINK_TYPES

    // *************************************************************
    // COMPUTED
    @Getter('document/getDocumentById') getDocumentById!: (documentId: string) => Document | null

    get links(): Array<DocumentLinkWithType> {
        return this.linksInfo.map(link => {
            const document = this.getDocumentById(link.target)

            return {
                target: link.target,
                name: document ? document.base.name : '????',
                type: document ? document.base.type : null,
                kind: link.kind
            }
        })
    }

    // *************************************************************
    // METHODS
    showModal(): void {
        this.modal.show(this.links)
    }

    openLink(link: DocumentLinkWithType): void {
        console.log('openLink', link)
        if (!link.type) {
            this.$snotify.warning('У Вас нет доступа к этому документу')
            return
        }

        this.$router.push({
            name: 'document',
            params: {
                section: 'documents',
                module: link.type,
                id: link.target
            }
        })
    }
}
