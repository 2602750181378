

















import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User } from '@/model/User'

@Component({})

export default class Ajaja extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User | null

    get name(): string {
        if (!this.getMe) {
            return ''
        }

        return this.getMe.name.fullName
    }
}
