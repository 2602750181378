
















import { Component, Prop, Vue } from 'vue-property-decorator'

import { WorkflowElement } from '@/model/Document'

import DWF_TaskStageNode from '@/components/document/workflow/DWF_TaskStageNode.vue'

@Component<DWF_TaskStage>({
    components: { DWF_TaskStageNode }
})

export default class DWF_TaskStage extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) tasks!: Array<WorkflowElement>
    @Prop({ default: null }) stageIndex!: number | null

    // *************************************************************
    // COMPUTED
    get newTasks(): Array<WorkflowElement> {
        // Нам нужны только первенцы, остальные заполнятся в компоненте TaskListNode
        return this.tasks.filter((task: WorkflowElement) => !task.inputs.length)
    }
}
