


















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { Task } from '@/model/Task'

import TaskList from '@/components/tasks/TaskList.vue'

@Component({
    components: { TaskList }
})

export default class TaskTree extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) filteredTasks!: Array<Task>

    get tasksWithoutParents(): Array<Task> {
        // Собираем задачи, у которых нет родителя либо родители которых нам не доступны
        return this.filteredTasks.filter((task: Task) => {
            if (!task.parent) {
                return true
            }

            if (!this.filteredTasks.find((t: Task) => t.id === task.parent)) {
                return true
            }

            return false
        })
    }

    // *************************************************************
    // EMIT
    @Emit('openTask')
    emitOpenTask(taskId: string): string {
        return taskId
    }

    @Emit('create')
    emitCreate(taskId: string): string {
        return taskId
    }
}
