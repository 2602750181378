










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component<DocumentBlockTitle>({})

export default class DocumentBlockTitle extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) title!: string
}
