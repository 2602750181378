var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', _vm._g({
    staticClass: "task-list-node",
    class: {
      'task-list-node_child': _vm.task.inputs.length !== 0
    }
  }, _vm.$listeners), [_vm.task.outputs.length !== 0 ? _c('div', {
    staticClass: "list-node__drop",
    on: {
      "click": _vm.onClickDrop
    }
  }, [_vm._v("\n        " + _vm._s(!!_vm.drop ? '‒' : '+') + "\n    ")]) : _vm._e(), _vm._v(" "), _c('Card', {
    staticClass: "task-list-node__card",
    attrs: {
      "active": _vm.getWorkflow.activeTask === _vm.task.id
    },
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.emitClickList($event);
      }
    }
  }, [_vm.task ? _c('div', {
    attrs: {
      "slot": "card-body"
    },
    slot: "card-body"
  }, [_c('div', {
    staticClass: "task-card-graph",
    class: {
      'task-card-graph_active': _vm.getWorkflow.activeTask === _vm.task.id
    },
    on: {
      "click": function click($event) {
        return _vm.onClickTask(_vm.task.id);
      }
    }
  }, [_c('Attribute', {
    attrs: {
      "attribute": "Исполнитель",
      "text": _vm.assignee
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Название",
      "text": _vm.task.text
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Описание",
      "text": _vm.task.description
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Контроль",
      "text": _vm.confirmer
    }
  })], 1)]) : _vm._e()]), _vm._v(" "), !!_vm.drop ? _c('ul', {
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.emitClickList($event);
      }
    }
  }, _vm._l(_vm.task.outputs, function (output) {
    return _c('WFTaskListNode', {
      key: output,
      attrs: {
        "task-id": output,
        "stage-index": _vm.stageIndex
      },
      on: {
        "clickList": _vm.emitClickList
      }
    });
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }