var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "side-bar",
    style: {
      'margin-left': _vm.marginLeft
    }
  }, [_c('div', {
    staticClass: "items-box"
  }, [_c('SideBarItem', {
    staticClass: "item",
    attrs: {
      "icon": "file-signature",
      "event": _vm.approvalEvents.length,
      "active": _vm.type === 'approval'
    },
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.setActiveItem('approval', _vm.approvalEvents, 'Согласование');
      }
    }
  }), _vm._v(" "), _c('SideBarItem', {
    staticClass: "item",
    attrs: {
      "icon": "thumbtack",
      "event": _vm.taskEvents.length,
      "active": _vm.type === 'approvalTasks'
    },
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.setActiveItem('approvalTasks', _vm.taskEvents, 'Задачи документа');
      }
    }
  }), _vm._v(" "), _c('SideBarItem', {
    staticClass: "item",
    attrs: {
      "icon": "tasks",
      "event": _vm.tasks,
      "active": _vm.type === 'tasks'
    },
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.type = 'tasks';
      }
    }
  }), _vm._v(" "), _c('SideBarItem', {
    staticClass: "item",
    attrs: {
      "icon": "info",
      "event": _vm.familiarization.length,
      "active": _vm.type === 'familiarization'
    },
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.type = 'familiarization';
      }
    }
  })], 1), _vm._v(" "), _c('SideBarPanel', {
    class: {
      panel: !_vm.root,
      'panel-root': _vm.root
    },
    attrs: {
      "component-panel": _vm.component,
      "events": _vm.events,
      "title": _vm.title,
      "type": _vm.type
    },
    on: {
      "me": function me($event) {
        _vm.type = 'me';
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }