





















import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import { Attachment } from '@/model/File'

import DocumentBlockTitle from '@/components/document/common_document/DocumentBlockTitle.vue'
import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'
import Files from '@/components/common/files/Files.vue'

@Component<DocumentFiles>({
    components: {
        DocumentBlockTitle,
        ButtonAdd,
        Files
    }
})

export default class DocumentFiles extends Vue {
    @Ref() readonly allFiles!: Files

    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) files!: Array<Attachment>
    @Prop({ default: '' }) documentId!: string
    @Prop({ default: false }) canChange!: boolean

    addFile(): void {
        this.allFiles.addFile()
    }
}
