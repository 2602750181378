<template>
    <div>
        <EventsOnlyInput
            :state="state"
            :class="{ invalid: !valid, input: true }"
            :disabled="disabled"
            @replace="onReplace($event)"
        />
    </div>
</template>

<script>
import moment from 'moment'

import EventsOnlyInput, { isAlpha, replaceChar } from '@/components/common/dateTimePicker/EventsOnlyInput'

function parse(val, fmt) {
    const m = moment(val, fmt)
    return m.isValid() ? m.unix() * 1000 : null
}

function format(ts, fmt) {
    return moment(ts).format(fmt)
}

function formatToMask(fmt, placeholder) {
    let mask = ''
    for (const c of fmt) {
        if (isAlpha(c)) {
            mask += placeholder
        } else {
            mask += c
        }
    }
    return mask
}

// { start: 8, end: 10, old: "19", new: "1-2019 1" }
function applyEventToState(repl, state, fmt, placeholder) {
    const mask = formatToMask(fmt, placeholder)
    let value = state.value

    if (repl.start >= mask.length) {
        return { value: state.value, start: state.start, end: state.end }
    }

    let n = 0
    let mi = repl.start
    let vi = repl.start

    const changeLength = (repl.old.length > repl.new.length ? repl.old.length : repl.new.length)
    for (; n < changeLength; n++, mi++, vi++) {
        if (n < repl.new.length) {
            if (repl.new.charAt(n) === mask.charAt(mi)) {
                //> if: input matches mask
                //> then: no-op
                console.log('input matches mask')
            } else if (mask.charAt(mi) === placeholder) {
                console.log('input on placeholder')
                //> * => placeholder => replace with char
                value = replaceChar(value, mi, repl.new.charAt(n))
            } else if (repl.new.charAt(n) !== mask.charAt(mi)) { //>
                console.log('placeholder', mask.charAt(mi), placeholder)
                n--
                vi--
            } else {
                console.warn('shit happened')
            }
        } else if (n < repl.old.length) {
            console.warn('backspace')
            // ???
            if (mask.charAt(mi) === placeholder) {
                value = replaceChar(value, mi, placeholder)
            }
        } else {
            console.warn('shit happened')
        }
    }

    let pos = 0
    if (repl.new.length > repl.old.length) {
        pos = mi
    } else {
        pos = repl.start
    }

    return { value: value, start: pos, end: pos }
}

export default {
    name: 'DateTimeInput',
    components: {
        EventsOnlyInput
    },
    model: {
        prop: 'input',
        value: 'value'
    },
    props: {
        value: {
            type: Number,
            required: true
        },
        format: {
            type: String,
            required: false,
            default: () => 'DD-MM-YYYY HH:mm'
        },
        placeholder: {
            type: String,
            required: false,
            default: () => '•'
        },
        disabled: { type: Boolean, default: false }
    },
    data: () => {
        return {
            state: { value: '', start: 0, end: 0 },

            valid: false
        }
    },
    computed: {
        mask() {
            return formatToMask(this.format, this.placeholder)
        }
    },
    watch: {
        value: { //> Handle value (v-model) change by replacing visual representation
            immediate: true,
            handler(to) {
                if (to) {
                    this.state = { value: format(to, this.format), start: this.state.start, end: this.state.end }
                } else {
                    this.state = { value: this.mask, start: this.state.start, end: this.state.end }
                }
            }
        },
        state: {
            handler(to) {
                const v = parse(to.value, this.format)

                if (v && !to.value.includes(this.placeholder)) {
                    this.$emit('input', v)
                    this.valid = true
                } else {
                    this.valid = false
                }
            },
            immediate: true
        }
    },
    methods: {
        onReplace(replace) {
            this.state = applyEventToState(replace, this.state, this.format, this.placeholder)
        }
    }
}
</script>

<style scoped lang="stylus">
.invalid
    color red
</style>
