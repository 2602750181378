var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "dashboard-item",
    class: {
      'dashboard-item_active': _vm.active
    }
  }, _vm.$listeners), [_c('div', {
    staticClass: "dashboard-item__title text-wrap",
    class: {
      'dashboard-item__title_big': _vm.isCardBig,
      'dashboard-item__title_small': !_vm.isCardBig
    }
  }, [_vm._v("\n        " + _vm._s(_vm.dashItem.title) + "\n    ")]), _vm._v(" "), _vm.isCardBig ? _c('div', {
    staticClass: "item-card__description"
  }, [_vm._v("\n        " + _vm._s(_vm.dashItem.description) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.documents ? _c('div', {
    staticClass: "dashboard-item__documents"
  }, [_vm._v("\n        " + _vm._s(_vm.documents) + "\n    ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }