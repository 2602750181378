import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { DirectoryState } from '@/store/directories/directoryState'

import dynamicSort from '@/tools/sort/dynamicSort'
import { Directory, Record } from '@/model/Directory'

const DirectoryGetters: GetterTree<DirectoryState, RootState> = {
    getDirectories: (state: DirectoryState): Array<Directory> => state.directories.sort(dynamicSort('name')),

    getDirectoryById: (state: DirectoryState) => (dirId: string): Directory | null => state.directoriesObject[dirId],

    ////////////////////////////////////////////////////////////////
    ////          //////////////////////////////////////////////////
    ////  RECORD  //////////////////////////////////////////////////
    ////          //////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    getDirectoryRecords: (state: DirectoryState) => (unitId: string, directoryId: string): Array<Record> => {
        const directory = state.directoriesObject[directoryId]
        if (!directory) {
            console.error('cannot find directory:: getDirectoryRecords', directoryId)
            return []
        }
        if (!directory.external) {
            return state.records.filter((rec: Record) => rec.directoryId === directoryId)
        } else {
            return state.records.filter((rec: Record) => rec.directoryId === directoryId && rec.unitId === unitId)
        }
    },

    getRecordById: (state: DirectoryState) => (recordId: string): Record | null => state.recordsObject[recordId] ?? null,

    getRecordDisplayName: (state: DirectoryState) => (recordId: string): string => {
        const record = state.recordsObject[recordId]

        if (!record) {
            return ''
        }

        return record.displayName
    }
}

export default DirectoryGetters
