<template>
    <Modal
        :visible.sync="visibleModal"
        title="Добавление пользователей и подразделений"
        class="familiarization-members"
    >
        <div
            slot="body"
            class="familiarization-documents__body"
        >
            <ActionSelectCard
                v-model="userIds"
                :multiple="true"
                placeholder="Выбрать пользователей"
                entity="user"
                :items="users"
                sort="name"
                sub-sort-property="fullName"
            />
            <ActionSelectCard
                v-model="unitIds"
                :multiple="true"
                placeholder="Выбрать подразделения"
                entity="unit"
                :items="units"
                sort="name"
                class="familiarization__margin"
            />
        </div>
        <div
            slot="footer"
            class="flex-row-center p-3"
        >
            <ButtonGreen
                class="ml-auto"
                text="Сохранить"
                :disabled="disabled"
                @click="save"
            />
        </div>
    </Modal>
</template>

<script>
import { mapActions } from 'vuex'

import Modal from '@/components/common/modals/Modal.vue'
import ButtonGreen from '@/components/common/buttons/ButtonGreen'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard'
import errors from '@/tools/errors/errors.ts'

export default {
    components: {
        Modal,
        ButtonGreen,
        ActionSelectCard
    },
    props: {
        users: { type: Array, default() {return []} },
        units: { type: Array, default() {return []} },
    },
    data: () => {
        return {
            userIds: [],
            unitIds: [],
            visibleModal: false
        }
    },
    computed: {
        familiarizationId() {
            return this.$route.params.id
        },
        disabled() {
            return !this.userIds.length && !this.unitIds.length
        }
    },
    methods: {
        ...mapActions('familiarization', ['callAddMembers']),
        show() {
            // Этот метод вызывается из родительского компонента
            this.visibleModal = true
        },
        save() {
            if (this.disabled) {
                return
            }
            console.log('save')
            this._cancelPreloaderAddMemebersFamiliarization = this.$preloader('add_members_familiarization', 'Добавление пользователей')
            this.callAddMembers({
                id: this.familiarizationId,
                userIds: this.userIds,
                unitIds: this.unitIds
            }).then(() => {
                this._cancelPreloaderAddMemebersFamiliarization()
                this.visibleModal = false
            })
                .catch(error => {
                    this._cancelPreloaderAddMemebersFamiliarization()
                    this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                        timeout: 0
                    })
                })
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/table.styl'

.familiarization-members
    background #ffffffee

.familiarization-documents__body
    display flex
    flex-direction column
    padding 20px 10px
    height 100%
    width 100%
    box-sizing border-box
    overflow-y auto

.familiarization-documents__save
    float right
    margin 15px
</style>
