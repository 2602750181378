// Module side margin
const SIDE_MARGIN = 15 // px
const SIDE_BAR_WIDTH = 48 // px

export enum SIZES {
    BIG = 'big',
    MIDDLE = 'middle',
    SMALL = 'small'
}

const rootWidth: { [SIZES.BIG]: number, [SIZES.MIDDLE]: number, [SIZES.SMALL]: number } = { // vw
    big: 75,
    middle: 20,
    small: 10
}

const horizontalMargin: { [SIZES.BIG]: number, [SIZES.MIDDLE]: number, [SIZES.SMALL]: number } = {
    big: 40,
    middle: 20,
    small: 20
}

// number of items in a row
const nx = 3

interface ItemSize {
    height: number,
    widthCoef: number,
    width?: string
}

const itemSize: { [SIZES.BIG]: ItemSize, [SIZES.MIDDLE]: ItemSize, [SIZES.SMALL]: ItemSize } = {
    big: {
        height: 200, // px
        widthCoef: 28, // %
    },
    middle: {
        height: 80, // px
        widthCoef: 94, // %
        width: '94%'
    },
    small: {
        height: 80, // px
        widthCoef: 94, // %
        width: '94%'
    }
}

export enum ROLES {
    ROOT = 'root',
    SECTION = 'section'
}

/* exported STATES */
export enum STATES {
    INITIAL= 'initial',
    ROOT = 'root',
    SECTION = 'section',
    MODULE = 'module',
    DOCUMENT = 'document'
}

export interface ParentConfig {
    width: string
    left: string
}

export const parentConfig: {
    root: { [SIZES.BIG]: ParentConfig, [SIZES.MIDDLE]: ParentConfig, [SIZES.SMALL]: ParentConfig },
    section: { [SIZES.BIG]: ParentConfig, [SIZES.MIDDLE]: ParentConfig, [SIZES.SMALL]: ParentConfig }
} = {
    root: {
        big: {
            width: `calc(${rootWidth.big}vw - ${SIDE_BAR_WIDTH}px)`,
            left: '0vw'
        },
        middle: {
            width: `${rootWidth.middle}vw`,
            left: '0vw'
        },
        small: {
            width: `${rootWidth.small}vw`,
            left: '0vw'
        }
    },
    section: {
        big: {
            width: `calc(${100 - rootWidth.middle}vw - ${SIDE_BAR_WIDTH}px)`,
            left: `${rootWidth.middle}vw`
        },
        middle: { // это херота и такого не бывает
            width: '',
            left: ''
        },
        small: {
            width: `${rootWidth.small}vw`,
            left: `${rootWidth.small}vw`
        }
    }
}

export const moduleConfig: ParentConfig = {
    width: `calc(${100 - rootWidth.small * 2}vw - ${SIDE_BAR_WIDTH}px - ${SIDE_MARGIN * 2}px)`,
    left: `calc(${rootWidth.small * 2}vw + ${SIDE_MARGIN}px)`
}

interface ItemTransform {
    left: (index: number) => string
    top: (index: number) => number
}

const itemTransform: { [SIZES.BIG]: ItemTransform, [SIZES.MIDDLE]: ItemTransform, [SIZES.SMALL]: ItemTransform } = {
    big: {
        left: (index: number) => {
            const column = (index + nx) % nx
            const sideMargin = (100 - itemSize.big.widthCoef * 3) / 4
            return `calc(${100 * column}% + ${sideMargin}vw - ${SIDE_BAR_WIDTH / 3}px + ${sideMargin * column}vw - ${SIDE_BAR_WIDTH / 3 * column}px)`
        },
        top: (index: number) => {
            const row = Math.floor(index / nx)
            return horizontalMargin.big + (horizontalMargin.big + itemSize.big.height) * row
        }
    },
    middle: {
        left: () => {
            return `${(100 - itemSize.middle.widthCoef) / 2}%`
        },
        top: (index: number) => {
            return (horizontalMargin.middle + itemSize.middle.height) * index + horizontalMargin.middle
        }
    },
    small: {
        // when item is small -> one item in row -> equal side margin
        left: () => {
            return `${(100 - itemSize.small.widthCoef) / 2}%`
        },
        top: (index: number) => {
            return (horizontalMargin.small + itemSize.small.height) * index + horizontalMargin.small
        }
    }
}

export interface ItemConfig extends ItemSize, ItemTransform{}

export const itemConfig: { [SIZES.BIG]: ItemConfig, [SIZES.MIDDLE]: ItemConfig, [SIZES.SMALL]: ItemConfig } = {
    big: { ...itemSize.big, ...itemTransform.big },
    middle: { ...itemSize.middle, ...itemTransform.middle },
    small: { ...itemSize.small, ...itemTransform.small }
}

export interface Configuration {
    routerTransitionName: string
    keyRouterView: string
    parentConfig: ParentConfig
    itemConfig: ItemConfig
}

export class DashboardItem {
    public name: string
    public title: string
    public description: string
    public parent: string
    public component: string | null
    public subComponent: string | null
    public requiredRole: string

    constructor(
        name: string,
        title: string,
        description: string,
        parent: string,
        component: string | null,
        subComponent: string | null,
        requiredRole: string
    ) {
        this.name = name
        this.title = title
        this.description = description
        this.parent = parent
        this.component = component
        this.subComponent = subComponent
        this.requiredRole = requiredRole
    }
}
