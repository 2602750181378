var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "radio",
    class: {
      'radio_disabled': _vm.disabled
    },
    on: {
      "click": _vm.change
    }
  }, [_c('div', {
    staticClass: "radio__box",
    class: {
      'radio__box_disabled': _vm.disabled
    },
    attrs: {
      "id": _vm.checkboxId
    }
  }, [_vm.groupValue === _vm.value && !_vm.disabled ? _c('div', {
    staticClass: "radio__box_full"
  }) : _vm._e()]), _vm._v(" "), _c('label', {
    staticClass: "radio__label",
    class: {
      'radio__label_disabled': _vm.disabled
    },
    attrs: {
      "for": _vm.checkboxId
    }
  }, [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }