<template>
    <div
        class="news-container"
    >
        <HelpCard
            v-for="one in getNews"
            :key="one.id"
            :news="one"
        >
            <template slot="content">
                {{ one.content }}
            </template>
        </HelpCard>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import HelpCard from '@/components/about_system/HelpCard'

export default {
    name: 'News',
    components: {
        HelpCard
    },
    computed: {
        ...mapGetters('user_options', ['getNews'])
    }
}
</script>

<style lang="stylus" scoped>
.news-container
    font-family Cormorant

.news-container__one-new
    margin 0 auto

</style>
