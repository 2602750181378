<template>
    <HelpCard
        title="Нумератор"
        :help="true"
    >
        <template slot="content">
            <ol>
                <li>создается/редактируется/удаляется только Менеджером документооборота;</li>
                <li>прикрепляется к типу документа;</li>
                <li>один нумератор может быть прикреплен к нескольким типам;</li>
                <li>имя нумератора не может быть пустой строкой;</li>
                <li>должен содержать не менее одного поля;</li>
                <li>одно из полей обязательно должно быть с типом Номер;</li>
                <li>всегда стартует с единицы.</li>
            </ol>

            <h3>Поля нумератора:</h3>
            <ol>
                <li>
                    <h4>Номер. Состоит из:</h4>
                    <ul>
                        <li>
                            Длины - минимальная длина данного поля.
                            <br>
                            Например, если длина равна 3, то первый номер будет '001'.
                            <br>
                            Это минимальная длина, но не максимальная, т.е. если номер превысил длину,
                            она просто будет наращиваться;
                        </li>
                        <li>
                            Периодичности обнуления - сутки/месяц/год/никогда.
                            <br>
                            Периодичность обнуления не зависит от того, когда был создан нумератор.
                            <br>
                            Номер будет обнуляться в 24.00 по временному поясу организации.
                        </li>
                    </ul>
                </li>
                <li>
                    <h4>Шифр:</h4>
                    <ul>
                        <li>
                            Организация - в номер документа попадет код из организации,
                            к которой относится подразделение, от которого пользователь
                            создал данный документ;
                        </li>
                        <li>
                            Подразделение - в номер документа попадет код из подразделения, от
                            которого пользователь создал данный документ;
                        </li>
                        <li>
                            Тип документа - в номер документа попадет код из типа документа.
                        </li>
                    </ul>
                </li>

                <li>
                    <h4>Разделитель:</h4>
                    <ul>
                        <li>может быть любым сиволом;</li>
                        <li>может содержать любое количество символов;</li>
                        <li>не может быть пустой строкой.</li>
                    </ul>
                </li>
            </ol>
        </template>
    </HelpCard>
</template>

<script>
import HelpCard from '@/components/about_system/HelpCard'

export default {
    name: 'HelpNumerator',
    components: {
        HelpCard
    }
}
</script>

<style lang="stylus" scoped>
.help-current-role__h4
    margin 10px 0 0 0
    padding 0

</style>
