










































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import getError, { GrpcError } from '@/tools/errors/errors'
import { DOC_STATUSES, Document, DocumentRequisite, WorkflowElement, WorkflowStage } from '@/model/Document'
import { User } from '@/model/User'
import { DocumentType, DocumentTypeRequisite } from '@/model/DocumentType'

import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import Dropdown from '@/components/common/Dropdown.vue'
import ConfirmModalNew from '@/components/common/modals/ConfirmModalNew.vue'

@Component({
    components: {
        ButtonViolet,
        Dropdown,
        ConfirmModalNew
    }
})

export default class DocumentStatus extends Vue {
    @Ref() readonly confirmModal!: ConfirmModalNew

    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) openDocument!: Document

    // *************************************************************
    // DATA PARAMS
    allStatuses = DOC_STATUSES

    visibleConfirm = false
    loadingStatus = false

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    get statuses(): Array<{ key: string, name: string, disabled: boolean, disableReason?: string }> {
        let startDisabled = this.status === 'start'
        let startDisabledReason = ''
        if (this.openDocument.base.status === 'draft') {
            if (!this.fullRequisitesList) {
                startDisabled = true
                startDisabledReason = 'Изменился список реквизитов! Удалите документ либо переведите в Архив!'
            } else {
                // Если в данном документе есть все реквизиты типа: fullRequisitesList === true
                const requiredRequisites: Array<DocumentRequisite> = this.openDocument.requisites.filter((req: DocumentRequisite) => req.type.required)

                if (requiredRequisites.some((req: DocumentRequisite) => !req.value)) {
                    startDisabled = true
                    startDisabledReason = 'Для старта документа заполните обязательные реквизиты!'
                }
            }
        } else if (this.openDocument.base.status === 'approval' || this.openDocument.base.status === 'execution') {
            startDisabled = true
        }

        return [
            { key: 'draft', name: 'Черновик', disabled: this.status === 'draft' },
            { key: 'start', name: 'Старт', disabled: startDisabled, disableReason: startDisabledReason },
            { key: 'archived', name: 'Архив', disabled: this.status === 'archived' },
        ]
    }

    get fullRequisitesList(): boolean {
        // Этот вариант возможен, когда пользователь создал документ.
        // После этого администратор документооборота изменил набор реквизитов
        // Пользователь пытается изменить статус документа, в котором список реквизитов уже не актуален
        if (!this.type) {
            return false
        }

        return this.type.requisites.every((req: DocumentTypeRequisite) => this.openDocument.requisites.find((r: DocumentRequisite) => r.type.id === req.id))
    }

    get status(): 'draft' | 'start' | 'archived' {
        if (this.openDocument.base.status === 'execution' || this.openDocument.base.status === 'approval') {
            return 'start'
        } else {
            return this.openDocument.base.status
        }
    }

    get type(): DocumentType | null {
        return this.getTypeById(this.openDocument.base.type)
    }

    get statusDisableReason(): string {
        if (this.status === 'archived') {
            return 'Запрещено возвращать документы из архива'
        }

        if (this.getMe.id === 'bgiagga08k0lpplv62f0') {
            // Если пользователь === Оксана
            return ''
        }

        if (this.getMe.id === 'bgjhoca08k0j989o72qg') {
            // Если пользователь === Анохин
            return ''
        }

        if (this.getMe.id === 'bgjhsgi08k0j989o72t0') {
            // Если пользователь === Влад
            return ''
        }

        if (this.getMe.id !== this.openDocument.base.author.id) {
            return 'Только автор документа может менять статус'
        }

        return ''
    }

    // *************************************************************
    // METHODS
    @Action('document/callDraft')
    callDraft!: (id: string) => Promise<void>

    @Action('document/callArchive')
    callArchive!: (id: string) => Promise<void>

    @Action('document/callStart')
    callStart!: (id: string) => Promise<void>

    onClickChangeStatus(key: string): void {
        if (this.loadingStatus) {
            return
        }

        if (!this.openDocument.workflow.stages.length) {
            this.changeStatus(key)
            return
        }

        const firstStage = this.openDocument.workflow.stages[0]

        if (firstStage.type === 'fluent' && this.status === 'draft' && key === 'start' && !firstStage.graph.length) {
            this.confirmModal.show({
                text: 'У Вас не заполнен блок со Свободным согласованием. Вы действительно хотите оставить его пустым?',
                onConfirm: () => this.changeStatus(key)
            })
        } else if (this.status === 'draft' && key === 'start') {
            // Если пытаемся перевести документ в Старт, то сначала убеждаемся,
            // что у всех специализированных узлов заполнен actor
            let start = true
            this.openDocument.workflow.stages.forEach((stage: WorkflowStage) => {
                if (stage.type === 'approval') {
                    start = stage.graph.every((e: WorkflowElement) => e.actor?.user || e.actor?.unit)
                }
            })

            if (start) {
                this.changeStatus(key)
            } else {
                this.$snotify.warning('Необходимо заполнить специализированные узлы согласования!')
            }
        } else {
            this.changeStatus(key)
        }
    }

    changeStatus(key: string): void {
        let action: (id: string) => Promise<void> = this.callArchive

        if (key === 'draft') {
            action = this.callDraft
        } else if (key === 'start') {
            action = this.callStart
        } else if (key === 'archived') {
            action = this.callArchive
        }

        this.loadingStatus = true
        {
            action(this.openDocument.base.id)
                .catch((error: GrpcError) => {
                    this.$snotify.error(getError(error))
                })
                .finally(() => {
                    this.visibleConfirm = false
                    this.loadingStatus = false
                })
        }
    }
}
