var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 mb-3 mt-5 flex-row-center common-document-block-title"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]), _vm._v(" "), _c('hr'), _vm._v(" "), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }