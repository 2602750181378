<template>
    <Button
        class="btn-base"
        :disabled="disabled"
        v-on="$listeners"
    >{{ text }}</Button>
</template>

<script>
import Button from './Button'

export default {
    components: { Button },
    props: {
        text: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>


<style lang="stylus" scoped>
@import '~@/assets/styles/colors.styl'

.btn-base
    box-shadow 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)
    border 1px solid #d9d9d9
    color $pink-2
    &:hover
        color $pink-1
        border-color $pink-1
    &.btn-base_disabled
        color rgba(0,0,0,0.65)
</style>
