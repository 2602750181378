
























import { Component, Prop, Vue } from 'vue-property-decorator'

import { DocumentEvent, DocumentEventRejected, DocumentEventRejectedVictim } from '@/model/Document'

import DocumentEventBase from '@/components/document/common_document/chat/document_events/DocumentEventBase.vue'

@Component({
    components: { DocumentEventBase }
})

export default class DocumentEventRejectedCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) event!: DocumentEvent

    get victims(): Array<DocumentEventRejectedVictim> {
        if (this.event.fields instanceof DocumentEventRejected) {
            return this.event.fields.victims
        }
        return []
    }
}
