var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HelpCard', {
    attrs: {
      "title": "Работа с документом",
      "help": true
    }
  }, [_c('template', {
    slot: "content"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }