var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    ref: "graph",
    style: {
      'height': _vm.svgHeight + 'px'
    }
  }, _vm.$listeners), [_c('svg', {
    ref: "svg",
    staticClass: "template-builder-graph__svg",
    attrs: {
      "height": _vm.svgHeight,
      "width": _vm.svgWidth
    },
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.clickSvg($event);
      }
    }
  }, [_vm._l(_vm.vertices.links, function (link) {
    return _c('path', {
      key: link.id,
      staticClass: "line",
      attrs: {
        "d": link.d
      }
    });
  }), _vm._v(" "), _vm._l(_vm.vertices.nodes, function (node) {
    return _c('WFTNode', {
      key: node.id,
      style: {
        transform: node.viewData.cssTranslate
      },
      attrs: {
        "node": node,
        "root": _vm.root,
        "dynamic": _vm.dynamic,
        "pending": _vm.getWorkflow.activeNode === node.id
      },
      on: {
        "click": function click($event) {
          return _vm.onClickNode(node.id);
        }
      }
    });
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }