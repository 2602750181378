<template>
    <div class="input-search-wrapper">
        <InputBase
            ref="input"
            v-model="base"
            type="text"
            :search="true"
            class="input_search"
            :active-focus="activeFocus"
            :placeholder="placeholder"
            :disabled="disabled"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
        />
        <div
            class="input-search__icon-wrapper"
            @click="clear"
        >
            <svg
                v-if="!!value"
                class="input-search__icon input-search__close"
                height="512pt"
                viewBox="0 0 512 512"
                width="512pt"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                <path d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0" />
                <path d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0" />
            </svg>
            <svg
                v-else
                id="Capa_1"
                class="input-search__icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 511.999 511.999"
                style="enable-background:new 0 0 511.999 511.999;"
                xml:space="preserve"
            >
                <path
                    d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667
			S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732
			c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667
			c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import InputBase from '@/components/common/inputs/InputBase'
export default {
    name: 'InputSearch',
    components: {
        InputBase
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Поиск'
        },
        activeFocus: {
            // нужно ли выделять при фокусе
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            base: ''
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(to) {
                this.base = to
            }
        },
        base(to) {
            this.$emit('input', to)
        }
    },
    methods: {
        clear() {
            this.$emit('input', '')
            this.$refs.input.focus()
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/colors.styl'

.input-search-wrapper
    position relative
    display flex
    align-items center
    justify-content center
    border none
    box-sizing border-box

.input_search
    display block
    width 100%
    height 100%

.input-search__icon-wrapper
    position absolute
    right 0
    display flex
    align-items center
    justify-content center
    height 100%
    width 25px

.input-search__icon
    height 15px
    width 15px
    path
        fill $pink-1
    &.input-search__close
        cursor pointer
</style>
