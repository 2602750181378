




import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import ScalePreloader from '@/components/ScalePreloader.vue'

import getError, { GrpcError } from '@/tools/errors/errors'

@Component({
    components: { ScalePreloader }
})

export default class Logout extends Vue {
    mounted(): void {
        this.logout()
    }

    // *************************************************************
    // METHODS
    @Action('me/logOut')
    logOut!: () => Promise<void>

    logout(): void {
        const cancelPreloaderLogout = this.$preloader('logout', 'Выход')

        this.logOut()
            .then(() => this.$router.push({ name: 'login' }))
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
                this.$router.go(-1)
            })
            .finally(() => cancelPreloaderLogout())
    }
}
