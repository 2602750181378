


























import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { WorkflowTemplate } from '@/model/Workflow'

import WFTActionDeleteStage from './WFTActionDeleteStage.vue'
import WFTActionApproval from './WFTActionApproval.vue'
import WFTActionAddLink from './WFTActionAddLink.vue'
import WFTActionDeleteLink from './WFTActionDeleteLink.vue'
import WFTActionDeleteNode from './WFTActionDeleteNode.vue'

import ActionTask from './WFTActionTask.vue'
import WFTActionDeleteTask from '@/components/workflow_template_builder/actions/WFTActionDeleteTask.vue'

@Component({
    components: {
        WFTActionDeleteStage,
        WFTActionApproval,
        WFTActionAddLink,
        WFTActionDeleteNode,
        WFTActionDeleteLink,
        ActionTask,
        WFTActionDeleteTask
    }
})

export default class WTBActions extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('workflow/getWorkflow') getWorkflow!: WorkflowTemplate
}
