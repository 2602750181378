var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "builder-task-graph pl-5",
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.onClickList($event);
      }
    }
  }, [_c('ul', _vm._g({
    staticClass: "builder-task-graph__list",
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.onClickList($event);
      }
    }
  }, _vm.$listeners), _vm._l(_vm.newTasks, function (task) {
    return _c('WFTaskListNode', {
      key: task.id,
      attrs: {
        "task-id": task.id,
        "stage-index": _vm.stageIndex
      },
      on: {
        "clickList": _vm.onClickList
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }