var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "numerator-field"
  }, [_c('Select', {
    staticClass: "numerator-field__select",
    attrs: {
      "options": _vm.types,
      "placeholder": "Тип поля",
      "disabled": _vm.removeDisabled
    },
    model: {
      value: _vm.field.type,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "type", $$v);
      },
      expression: "field.type"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "numerator-field__field"
  }, [_vm.field.type === 'field_type_code' ? _c('Select', {
    staticClass: "numerator-field__select",
    attrs: {
      "options": _vm.codes,
      "placeholder": "Выбрать шифр"
    },
    model: {
      value: _vm.field.code,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "code", $$v);
      },
      expression: "field.code"
    }
  }) : _vm.field.type === 'field_type_text' ? _c('InputBase', {
    staticClass: "numerator-field__text",
    attrs: {
      "type": "text",
      "multi-lines": false,
      "placeholder": "Разделитель"
    },
    model: {
      value: _vm.field.text,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "text", $$v);
      },
      expression: "field.text"
    }
  }) : _vm.field.type === 'field_type_incr' ? _c('div', {
    staticClass: "numerator-field__field_incr"
  }, [_c('InputNumber', {
    attrs: {
      "scale": false
    },
    model: {
      value: _vm.field.pad,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "pad", $$v);
      },
      expression: "field.pad"
    }
  }), _vm._v(" "), _c('Select', {
    staticClass: "numerator-field__select",
    attrs: {
      "options": _vm.periods,
      "placeholder": "Периодичность обнуления"
    },
    model: {
      value: _vm.field.resetPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "resetPeriod", $$v);
      },
      expression: "field.resetPeriod"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "pink-1",
    on: {
      "click": _vm.moveUp
    }
  }, [_c('FAIcon', {
    staticClass: "numerator-field__icon",
    class: {
      'numerator-field__icon_disabled': _vm.firstField || _vm.movingDisabled
    },
    attrs: {
      "icon": "arrow-up"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "pink-1",
    on: {
      "click": _vm.moveDown
    }
  }, [_c('FAIcon', {
    staticClass: "numerator-field__icon",
    class: {
      'numerator-field__icon_disabled': _vm.lastField || _vm.movingDisabled
    },
    attrs: {
      "icon": "arrow-down"
    }
  })], 1), _vm._v(" "), _c('ButtonDelete', {
    attrs: {
      "tooltip": "Удалить поле",
      "flow": "left",
      "disabled": _vm.removeDisabled
    },
    on: {
      "click": _vm.remove
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }