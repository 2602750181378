var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-unit"
  }, [_c('InputBase', {
    staticClass: "flex-1 mr-3 fs-24",
    attrs: {
      "placeholder": "Имя подразделения",
      "disabled": !_vm.manager,
      "required": "",
      "loading": _vm.loadingName
    },
    on: {
      "enter": _vm.rename,
      "blur": _vm.rename
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Организация",
      "disabled": !_vm.manager,
      "loading": _vm.loadingRoot
    },
    on: {
      "change": _vm.markAsRoot
    },
    model: {
      value: _vm.root,
      callback: function callback($$v) {
        _vm.root = $$v;
      },
      expression: "root"
    }
  }), _vm._v(" "), _c('InputBase', {
    attrs: {
      "placeholder": "Код",
      "disabled": !_vm.manager,
      "loading": _vm.loadingCode,
      "required": ""
    },
    on: {
      "enter": _vm.setCode,
      "blur": _vm.setCode
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }), _vm._v(" "), _vm.manager ? _c('div', {
    staticClass: "edit-units__icons"
  }, [_c('div', {
    attrs: {
      "tooltip": "Управление пользователями",
      "flow": "left"
    },
    on: {
      "click": _vm.openUsers
    }
  }, [_c('FAIcon', {
    staticClass: "edit-unit__icon",
    attrs: {
      "icon": "users"
    }
  })], 1), _vm._v(" "), _c('div', {
    attrs: {
      "tooltip": "Дочерние подразделения",
      "flow": "left"
    },
    on: {
      "click": _vm.openUnits
    }
  }, [_c('FAIcon', {
    staticClass: "edit-unit__icon",
    attrs: {
      "icon": "sitemap"
    }
  })], 1), _vm._v(" "), _c('ButtonDelete', {
    attrs: {
      "tooltip": _vm.removeTooltip || 'Удалить подразделение',
      "disabled": !!_vm.removeTooltip,
      "flow": "left"
    },
    on: {
      "click": _vm.showRemove
    }
  }), _vm._v(" "), _c('ButtonClose', {
    attrs: {
      "color": "violet"
    },
    on: {
      "click": _vm.emitClose
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('SelectEmployees', {
    attrs: {
      "visible": _vm.visibleUserModal,
      "unit-id": _vm.unitId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleUserModal = $event;
      }
    }
  }), _vm._v(" "), _c('SelectUnits', {
    attrs: {
      "unit-id": _vm.unitId,
      "visible": _vm.visibleUnitsModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleUnitsModal = $event;
      },
      "create": function create($event) {
        return _vm.emitSelectUnit($event);
      }
    }
  }), _vm._v(" "), _c('RemoveModal', {
    ref: "removeModal",
    attrs: {
      "visible": _vm.visibleRemoveModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleRemoveModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }