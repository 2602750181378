var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "numerator"
  }, [_vm.numerator ? _c('GoBack', {
    attrs: {
      "text": "Вернуться к списку нумераторов",
      "link": _vm.goBackPath
    }
  }) : _vm._e(), _vm._v(" "), _vm.numerator ? _c('div', {
    staticClass: "numerator__header"
  }, [_c('h1', {
    staticClass: "numerator__title"
  }, [_vm._v("\n            Просмотр/Редактирование нумератора\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "numerator__changes"
  }, [_vm._v("\n            " + _vm._s(_vm.canSave.reason) + "\n        ")])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "numerator__header"
  }, [_c('InputBase', {
    staticClass: "numerator__name",
    attrs: {
      "type": "text",
      "multi-lines": false,
      "placeholder": "Имя нумератора"
    },
    on: {
      "blur": _vm.saveName
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _vm.numerator ? _c('ButtonDelete', {
    staticClass: "numerator__button",
    attrs: {
      "tooltip": "Удалить нумератор",
      "flow": "left"
    },
    on: {
      "click": _vm.showRemove
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "numerator__header"
  }, [_c('h3', [_vm._v("Поля нумератора")]), _vm._v(" "), _c('ButtonAdd', {
    staticClass: "numerator__button",
    attrs: {
      "tooltip": "Добавить поле",
      "flow": "left"
    },
    on: {
      "click": _vm.addField
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "numerator__fields_list"
  }, _vm._l(_vm.fields, function (field, index) {
    return _c('NumeratorRecord', {
      key: field.id,
      attrs: {
        "field-info": field,
        "last-field": index === _vm.fields.length - 1,
        "first-field": index === 0,
        "numerator-id": _vm.numeratorId,
        "remove-disabled": field.type === 'field_type_incr' && _vm.fields.filter(function (f) {
          return f.type === 'field_type_incr';
        }).length === 1,
        "moving-disabled": _vm.canSave.disabled
      },
      on: {
        "remove": function remove($event) {
          return _vm.removeField(index);
        },
        "change": function change($event) {
          return _vm.changeField($event, index);
        },
        "moveDown": function moveDown($event) {
          return _vm.moveDown(index);
        },
        "moveUp": function moveUp($event) {
          return _vm.moveUp(index);
        }
      }
    });
  }), 1), _vm._v(" "), _c('RemoveModal', {
    ref: "removeModal",
    attrs: {
      "visible": _vm.visibleRemoveModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleRemoveModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }