import api from 'api1'

export default function ({ state, commit, dispatch }, { numerator, version }) {
    return new Promise((resolve, reject) => {
        api.cunt.NumeratorSvcClient.set(api.cunt.SetNumeratorReq({
            ID: numerator.id,
            Name: numerator.name,
            Fields: numerator.fields.map(field => {
                return api.cunt.NumeratorField({
                    ID: field.id,
                    Type: field.type,
                    Code: api.cunt.NumeratorCodeField({
                        Source: field.code
                    }),
                    Text: api.cunt.NumeratorTextField({
                        Text: field.text
                    }),
                    Incr: api.cunt.NumeratorIncrField({
                        Pad: Number(field.pad),
                        ResetPeriod: field.resetPeriod
                    })
                })
            })
        }), data => {
            data = api.cunt.Numerator(data)

            commit('setModificationTime', {
                numeratorId: data.ID,
                modificationTime: data.ModificationTime
            })

            if (state.versions[data.ID] === version) {
                commit('resetVersion', {
                    numeratorId: data.ID
                })
            }

            resolve()
        }, error => {
            (error ? console.warn : console.log)('::DONE::NumeratorSvcClient.set:', error)
            if (error) {
                dispatch('putNumerator', {
                    numerator: state.savesNumerators[numerator.id],
                    modificationTime: state.numModificationTime[numerator.id]
                })
            }

            reject(error)
        })
    })
}
