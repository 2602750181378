var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tasks"
  }, [_c('div', {
    staticClass: "flex-row-center"
  }, [_c('div', {
    staticClass: "flex-row-center flex-1"
  }, [_c('h1', {
    staticClass: "mr-3"
  }, [_vm._v("\n                Задачи\n            ")]), _vm._v(" "), _c('ListCount', {
    attrs: {
      "count": _vm.filteredTasks.length
    }
  })], 1), _vm._v(" "), _vm.showFilters ? _c('ButtonViolet', {
    staticClass: "ml-5",
    attrs: {
      "text": "Очистить фильтры"
    },
    on: {
      "click": _vm.clearFilters
    }
  }) : _vm._e(), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "ml-5",
    attrs: {
      "text": _vm.showFilters ? 'Скрыть фильтры' : 'Показать фильтры',
      "active": _vm.showFilters
    },
    on: {
      "click": function click($event) {
        _vm.showFilters = !_vm.showFilters;
      }
    }
  }), _vm._v(" "), _c('ButtonCreate', {
    staticClass: "ml-5",
    attrs: {
      "text": "Создать задачу"
    },
    on: {
      "click": function click($event) {
        return _vm.create('');
      }
    }
  })], 1), _vm._v(" "), _c('Transition', {
    attrs: {
      "name": "filters"
    }
  }, [_vm.showFilters ? _c('div', {
    staticClass: "tasks__filter_inputs"
  }, [_c('InputSearch', {
    staticClass: "h-36",
    attrs: {
      "placeholder": "Поиск по автору"
    },
    model: {
      value: _vm.tasksFilters.searchAuthor,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "searchAuthor", $$v);
      },
      expression: "tasksFilters.searchAuthor"
    }
  }), _vm._v(" "), _c('InputSearch', {
    staticClass: "h-36",
    attrs: {
      "placeholder": "Поиск по исполнителю"
    },
    model: {
      value: _vm.tasksFilters.searchAssignee,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "searchAssignee", $$v);
      },
      expression: "tasksFilters.searchAssignee"
    }
  }), _vm._v(" "), _c('InputSearch', {
    staticClass: "h-36",
    attrs: {
      "placeholder": "Поиск по названию и содержимому"
    },
    model: {
      value: _vm.tasksFilters.searchText,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "searchText", $$v);
      },
      expression: "tasksFilters.searchText"
    }
  }), _vm._v(" "), _c('InputSearch', {
    staticClass: "h-36",
    attrs: {
      "placeholder": "Поиск по подтверждающему"
    },
    model: {
      value: _vm.tasksFilters.searchConfirmer,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "searchConfirmer", $$v);
      },
      expression: "tasksFilters.searchConfirmer"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, [_c('Checkbox', {
    attrs: {
      "label": "Ждут моего выполнения"
    },
    model: {
      value: _vm.tasksFilters.waitingForMyExecution,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "waitingForMyExecution", $$v);
      },
      expression: "tasksFilters.waitingForMyExecution"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Ждут моего подверждения"
    },
    model: {
      value: _vm.tasksFilters.waitingForMyConfirmation,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "waitingForMyConfirmation", $$v);
      },
      expression: "tasksFilters.waitingForMyConfirmation"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, [_c('Checkbox', {
    attrs: {
      "label": "Я - автор"
    },
    model: {
      value: _vm.tasksFilters.iAmAuthor,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "iAmAuthor", $$v);
      },
      expression: "tasksFilters.iAmAuthor"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Просроченные"
    },
    model: {
      value: _vm.tasksFilters.overdue,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "overdue", $$v);
      },
      expression: "tasksFilters.overdue"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Закрыть сегодня"
    },
    model: {
      value: _vm.tasksFilters.endDay,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "endDay", $$v);
      },
      expression: "tasksFilters.endDay"
    }
  }), _vm._v(" "), _vm.getArchivedTasksReceived ? _c('Checkbox', {
    attrs: {
      "label": "Незавершенные"
    },
    model: {
      value: _vm.tasksFilters.notCompleted,
      callback: function callback($$v) {
        _vm.$set(_vm.tasksFilters, "notCompleted", $$v);
      },
      expression: "tasksFilters.notCompleted"
    }
  }) : _c('Button', {
    attrs: {
      "color": "blue",
      "reverse": "",
      "size": "small"
    },
    on: {
      "click": _vm.getArchivedTasks
    }
  }, [_vm._v("Получить завершенные задачи")])], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, [_c('DateTimeInput', {
    staticClass: "h-36",
    attrs: {
      "value": _vm.startTime
    },
    model: {
      value: _vm.startTime,
      callback: function callback($$v) {
        _vm.startTime = $$v;
      },
      expression: "startTime"
    }
  }), _vm._v(" "), _c('FAIcon', {
    staticClass: "tasks__filter_date-time_arrow pink-1",
    attrs: {
      "icon": "arrow-right"
    }
  }), _vm._v(" "), _c('DateTimeInput', {
    staticClass: "h-36",
    attrs: {
      "value": _vm.endTime
    },
    model: {
      value: _vm.endTime,
      callback: function callback($$v) {
        _vm.endTime = $$v;
      },
      expression: "endTime"
    }
  }), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "ml-5",
    attrs: {
      "text": "Очистить даты"
    },
    on: {
      "click": _vm.clearDate
    }
  })], 1)], 1) : _vm._e()]), _vm._v(" "), _vm.filteredTasks ? _c('div', {
    key: "list",
    staticClass: "tasks__list"
  }, [_c('TaskTree', {
    attrs: {
      "filteredTasks": _vm.filteredTasks
    },
    on: {
      "create": function create($event) {
        return _vm.create($event);
      },
      "openTask": function openTask($event) {
        return _vm.openTask($event);
      }
    }
  })], 1) : _c('EmptyList', {
    key: "emty"
  }), _vm._v(" "), _c('ModalCreateTask', {
    attrs: {
      "visible": _vm.visibleCreateModal,
      "parentTaskId": _vm.parent
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleCreateModal = $event;
      },
      "close": function close($event) {
        _vm.parent = '';
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }