


























import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class RadioButton extends Vue {
    @Model('change', { type: String }) readonly groupValue!: string

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) label!: string
    @Prop({ default: '' }) value!: string
    @Prop({ default: false }) disabled!: boolean

    // *************************************************************
    // DATA PARAMS
    checkboxId = Math.random().toString(36).substring(7)

    // *************************************************************
    // METHODS
    change(): void {
        if (this.disabled) {
            return
        }

        this.emitChange()
    }

    // *************************************************************
    // EMIT
    @Emit('change')
    emitChange(): string {
        return this.value
    }
}
