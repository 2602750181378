var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HelpCard', {
    attrs: {
      "title": "Фильтры документов в списках",
      "help": true
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n            В общем списке документов Вы видите документы, в которых:\n        ")]), _vm._v(" "), _c('ul', [_c('li', [_vm._v("Вы являетесь автором;")]), _vm._v(" "), _c('li', [_vm._v("Вы участвуете в цепочке согласовании;")]), _vm._v(" "), _c('li', [_vm._v("На Ваше имя созданы задачи;")]), _vm._v(" "), _c('li', [_vm._v("\n                Если Вы Руководитель или Супервайзер какого-либо подразделения,\n                то видите все документы своего и дочерних подразделений.\n            ")])]), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n            Фильтрация документов по дате создания.\n        ")]), _vm._v(" "), _c('p', [_vm._v("\n            Все настройки фильтров действуют только в пределах одного типа документа.\n        ")]), _vm._v(" "), _c('p', [_vm._v("\n            Вверху страницы справа от имени типа документа находятся 2 поля с календарями.\n            "), _c('br'), _vm._v("\n            В левом поле по умолчанию стоит дата и время начала прошлого года.\n            В правом поле по умолчанию стоит окончание текущего дня (время 23:59).\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "document-list__datetimepicker"
  }, [_c('Datepicker', {
    staticClass: "document-list__date-input",
    attrs: {
      "value": _vm.defaultFilters.startTime
    },
    model: {
      value: _vm.defaultFilters.startTime,
      callback: function callback($$v) {
        _vm.$set(_vm.defaultFilters, "startTime", $$v);
      },
      expression: "defaultFilters.startTime"
    }
  }), _vm._v(" "), _c('FAIcon', {
    staticClass: "document-list__datetimepicker_arrow pink-1",
    attrs: {
      "icon": "arrow-right"
    }
  }), _vm._v(" "), _c('Datepicker', {
    staticClass: "document-list__date-input",
    attrs: {
      "value": _vm.defaultFilters.endTime
    },
    model: {
      value: _vm.defaultFilters.endTime,
      callback: function callback($$v) {
        _vm.$set(_vm.defaultFilters, "endTime", $$v);
      },
      expression: "defaultFilters.endTime"
    }
  })], 1), _vm._v(" "), _c('p', [_vm._v("\n            Укажите здесь дату и время начала и окончания необходимого периода.\n        ")]), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n            Фильтрация документов по имени документа/номеру/автору/значению реквизитов.\n        ")]), _vm._v(" "), _c('p', [_vm._v("В данный момент все эти фильтры реализованы в одной строке поиска:")]), _vm._v(" "), _c('InputSearch', {
    staticClass: "help-doc-list-filter__search",
    attrs: {
      "placeholder": "Поиск"
    },
    model: {
      value: _vm.defaultFilters.searchText,
      callback: function callback($$v) {
        _vm.$set(_vm.defaultFilters, "searchText", $$v);
      },
      expression: "defaultFilters.searchText"
    }
  }), _vm._v(" "), _c('p', [_vm._v("\n            Просто введите часть имени или номера документа, Ф.И.О. автора документа либо значение любого реквизита\n            и в таблице останутся документы, удовлетворяющие Вашему запросу.\n            "), _c('br'), _vm._v("\n            Если Вы хотите очистить строку поиска, просто нажмите крестик, который появляется в строке\n            поиска справа в момент ввода.\n        ")]), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n            Если Вы хотите согласовать несколько документов одновременно, не проваливаясь в каждый документ отдельно,\n            то\n        ")]), _vm._v(" "), _c('ul', [_c('li', [_vm._v("Отметьте документы в таблице в первом столбце с заголовком 'Согласовать';")]), _vm._v(" "), _c('li', [_c('div', {
    staticClass: "help-doc-list-filter__line"
  }, [_c('div', [_vm._v("Документы, доступные для согласования, помечены:")]), _vm._v(" "), _c('Checkbox', {
    staticClass: "help-doc-list-filter__button",
    attrs: {
      "label": "Yes"
    },
    model: {
      value: _vm.selectedDocuments,
      callback: function callback($$v) {
        _vm.selectedDocuments = $$v;
      },
      expression: "selectedDocuments"
    }
  })], 1)]), _vm._v(" "), _c('li', [_c('div', {
    staticClass: "help-doc-list-filter__line"
  }, [_c('div', [_vm._v("Слева нажмите кнопку")]), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "help-doc-list-filter__button",
    attrs: {
      "small": true,
      "text": "Согласовать выбранные документы"
    }
  })], 1)])]), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n            Настройки позиции scroll.\n        ")]), _vm._v(" "), _c('p', [_vm._v("\n            Вы просматриваете таблицу с одним из типов документов и пролистываете таблицу вниз.\n            После перехода на другую страницу это пролистывание сохраняется.\n            Т.е. когда Вы вернетесь к данной таблице, то она будет пролистана на том же уровне,\n            на котором Вы ее покинули.\n        ")]), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n            Чтобы увидеть дополнительные фильтры\n        ")]), _vm._v(" "), _c('p', [_vm._v("\n            Нажмите кнопку\n            "), _c('Button', {
    staticClass: "help-doc-list-filter__button",
    attrs: {
      "active": _vm.filters,
      "color": "blue",
      "reverse": ""
    },
    on: {
      "click": function click($event) {
        _vm.filters = !_vm.filters;
      }
    }
  }, [_vm._v(_vm._s(_vm.filters ? 'Скрыть фильтры' : 'Показать фильтры'))])], 1), _vm._v(" "), _c('Transition', {
    attrs: {
      "name": "filters"
    }
  }, [_vm.filters ? _c('div', [_c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n                    Фильтрация документов по статусу.\n                ")]), _vm._v(" "), _c('p', [_vm._v("\n                    Под календарями Вы видите галочки рядом со статусами документов.\n                    "), _c('br'), _vm._v("\n                    Проставляя и убирая галочки Вы регулирете список документов в таблице, оставляя документы\n                    с необходимыми Вам статусами.\n                    "), _c('br'), _vm._v("\n                    По умолчанию Все документы, кроме архивных, попадают в таблицу.\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "help-doc-list-filter__line"
  }, [_vm._l(_vm.mainStatuses, function (_, key) {
    return _c('Checkbox', {
      key: key,
      attrs: {
        "label": _vm.statuses[key]
      },
      model: {
        value: _vm.mainStatuses[key],
        callback: function callback($$v) {
          _vm.$set(_vm.mainStatuses, key, $$v);
        },
        expression: "mainStatuses[key]"
      }
    });
  }), _vm._v(" "), _vm.isReceivedArchived ? _c('Checkbox', {
    attrs: {
      "label": _vm.statuses.archived
    },
    model: {
      value: _vm.archived,
      callback: function callback($$v) {
        _vm.archived = $$v;
      },
      expression: "archived"
    }
  }) : _c('ButtonViolet', {
    attrs: {
      "small": true,
      "text": "Получить архивные документы"
    },
    on: {
      "click": function click($event) {
        _vm.isReceivedArchived = true;
      }
    }
  })], 2), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n                    Фильтрация документов по необходимости согласования.\n                ")]), _vm._v(" "), _c('p', [_vm._v("\n                    Чтобы оставить только те документы, которые требуют в данный момент\n                    Вашего согласования или выполнения, воспользуйтесь следующим фильтром:\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "help-doc-list-filter__line"
  }, [_c('Checkbox', {
    attrs: {
      "label": "Согласую Я"
    },
    model: {
      value: _vm.defaultFilters.myPending,
      callback: function callback($$v) {
        _vm.$set(_vm.defaultFilters, "myPending", $$v);
      },
      expression: "defaultFilters.myPending"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Мои задачи"
    },
    model: {
      value: _vm.defaultFilters.myTasks,
      callback: function callback($$v) {
        _vm.$set(_vm.defaultFilters, "myTasks", $$v);
      },
      expression: "defaultFilters.myTasks"
    }
  })], 1), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n                    Фильтрация документов по организации.\n                ")]), _vm._v(" "), _c('p', [_vm._v("\n                    Вы можете оставить в таблице документы только одной необходимой организации.\n                    "), _c('br'), _vm._v("\n                    Просто выберите имя организации из списка:\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "help-doc-list-filter__root-units-container"
  }, _vm._l(_vm.rootUnits, function (unit) {
    return _c('RadioButton', {
      key: unit.id,
      staticClass: "help-doc-list-filter__root-units",
      attrs: {
        "value": unit.id,
        "label": unit.name
      },
      model: {
        value: _vm.rootUnit,
        callback: function callback($$v) {
          _vm.rootUnit = $$v;
        },
        expression: "rootUnit"
      }
    });
  }), 1), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n                    Сброс всех настроек фильтров.\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "help-doc-list-filter__line"
  }, [_c('div', [_vm._v("Чтобы сбросить Все фильтры документов, нажмите кнопку в фильтрах:")]), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "help-doc-list-filter__button",
    attrs: {
      "text": "Очистить фильтры"
    }
  })], 1), _vm._v(" "), _c('h4', {
    staticClass: "help__h4"
  }, [_vm._v("\n                    Распечатать реестр.\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "help-doc-list-filter__line"
  }, [_c('div', [_vm._v("\n                        После установки всех необходимых фильтров, Вы можете распечатать список документов из таблицы.\n                        Для этого нажмите кнопку:\n                    ")]), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "help-doc-list-filter__button",
    attrs: {
      "text": "Распечатать реестр"
    }
  })], 1)]) : _vm._e()])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }