<template>
    <Modal
        :visible.sync="visibleModal"
        class="familiarization-documents"
        title="Добавление документов"
    >
        <div
            slot="body"
            class="familiarization-documents__body"
        >
            <div class="flex-row-center my-3">
                <Select
                    v-model="documentType"
                    :options="types"
                    placeholder="Выберите тип документа"
                    class="h-100 min-width-200"
                />

                <InputSearch
                    v-model="searchText"
                    class="familiarization-documents__filter"
                />
            </div>
            <div
                v-for="link in value"
                :key="link.id"
                class="flex-row-center mb-3"
            >
                <div class="flex-1">
                    {{ link.name }}
                </div>

                <ButtonDelete
                    class="ml-5"
                    @click="remove(link.id)"
                />
            </div>
            <table class="table mt-2">
                <tr
                    v-for="doc in documents"
                    :key="doc.base.id"
                    class="familiarization-documents__document"
                    @click="addDocument(doc.base)"
                >
                    <td> {{ doc.base.name }}</td>
                </tr>
            </table>
        </div>
        <div
            slot="footer"
            class="flex-row-center p-3"
        >
            <Button
                :disabled="disabledGetArchivedDocuments"
                color="blue"
                reverse
                @click="getArchived"
            >
                {{ getArchivedDocumentsReceived[documentType] ? 'Архивные документы получены' : 'Получить архивные документы' }}
            </Button>
            <Button
                class="ml-auto"
                color="green"
                @click="save"
            >
                Сохранить
            </Button>
        </div>
    </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Modal from '@/components/common/modals/Modal.vue'
import InputSearch from '@/components/common/inputs/InputSearch'
import Button from '@/components/common/buttons/Button'
import ButtonDelete from '@/components/common/buttons/ButtonDelete'
import Select from '@/components/common/select/Select'
import errors from '@/tools/errors/errors.ts'

export default {
    components: {
        Modal,
        InputSearch,
        Button,
        ButtonDelete,
        Select
    },
    props: {
        value: { type: Array, default() {return []} },
        centerId: { type: String, default: '' }
    },
    data: () => {
        return {
            searchText: '',
            documentType: '',
            linksInfo: [],
            visibleModal: false
        }
    },
    computed: {
        ...mapGetters('document', ['getDocumentsByType']),
        ...mapGetters('document_type', ['getTypesByCenterId']),
        ...mapGetters('user_options', ['getArchivedDocumentsReceived']),
        types() {
            return this.getTypesByCenterId(this.centerId)
        },
        documents() {
            let documents = this.getDocumentsByType(this.documentType)
            this.value.forEach(link => {
                // Убираем уже прикрепленные документы
                documents = documents.filter(doc => doc.base.id !== link.id)
            })
            return documents.filter(x =>
                x.base.name.toLowerCase().includes(this.searchText.toLowerCase())
            )
        },
        disabledGetArchivedDocuments() {
            if (!this.documentType) {
                return true
            }
            return this.getArchivedDocumentsReceived[this.documentType]
        }
    },
    methods: {
        ...mapActions('document', ['callArchivedListStream']),
        ...mapActions('user_options', ['setArchivedDocuments']),
        show() {
            // Этот метод вызывается из родительского компонента
            this.visibleModal = true
            this.searchText = ''
        },
        addDocument(doc) {
            this.$emit('input', [...this.value, {
                id: doc.id,
                name: doc.name,
                type: doc.type
            }])
        },
        remove(docId) {
            this.$emit('input', this.value.filter(doc => doc.id !== docId))
        },
        save() {
            this.$emit('input', this.value)
            this.visibleModal = false
        },
        getArchived() {
            if (this.getArchivedDocumentsReceived[this.documentType]) {
                return
            }
            this._cancelPreloaderArchivedListStreamDocuments = this.$preloader('archived_list_stream_documents', 'Получение архивных документов')
            this.callArchivedListStream({
                docTypeId: this.documentType
            })
                .then(() => {
                    this._cancelPreloaderArchivedListStreamDocuments()
                    this.setArchivedDocuments(this.documentType)
                })
                .catch(error => {
                    this._cancelPreloaderArchivedListStreamDocuments()
                    this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                        timeout: 0
                    })
                })
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/table.styl'

.familiarization-documents
    background #ffffffee

.familiarization-documents__body
    display flex
    flex-direction column
    padding 0 10px 20px 10px
    height 100%
    width 100%
    box-sizing border-box
    overflow-y auto

.familiarization-documents__filter
    flex-grow 1
    margin-left 10px
    height 35px

.familiarization-documents__save
    float right
    margin 15px

.familiarization-documents__document
    cursor pointer
</style>
