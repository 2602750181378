var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Tabs', {
    attrs: {
      "tabs": _vm.modes
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }), _vm._v(" "), _vm.mode === 'edit' ? _c('FamiliarizationEdit', {
    key: "edit"
  }) : _vm._e(), _vm._v(" "), _vm.mode === 'report' ? _c('FamiliarizationReport', {
    key: "report",
    attrs: {
      "events": _vm.events
    }
  }) : _vm.mode === 'journal' ? _c('FamiliarizationJournal', {
    key: "journal",
    attrs: {
      "events": _vm.events
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }