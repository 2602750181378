<template>
    <div>
        <GoBack
            text="Вернуться к списку ознакомлений"
            :link="goBackPath"
        />
        <component
            :is="component"
            :events="events"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GoBack from '@/components/common/GoBack'
import FamiliarizationViewMode from '@/components/familiarization/FamiliarizationViewMode'
import FamiliarizationEditMode from '@/components/familiarization/FamiliarizationEditMode'
import callOpenStream from '@/components/familiarization/callOpenStream'
import newFamiliarization from '@/model/convert/newFamiliarization'
import newFamiliarizationEvent from '@/model/convert/newFamiliarizationEvent'

export default {
    name: 'Familiarization',
    components: { GoBack, FamiliarizationViewMode, FamiliarizationEditMode },
    data() {
        return {
            events: []
        }
    },
    computed: {
        ...mapGetters('me', ['getMe']),
        ...mapGetters('familiarization', ['getFamiliarizationById']),
        component() {
            if (this.familiarization) {
                if (this.familiarization.actor.user.id === this.getMe.id) {
                    return 'FamiliarizationEditMode'
                }
            }
            return 'FamiliarizationViewMode'
        },
        familiarizationId() {
            return this.$route.params.id
        },
        familiarization() {
            return this.getFamiliarizationById(this.familiarizationId)
        },
        goBackPath() {
            return {
                name: 'module',
                params: {
                    section: 'system',
                    module: 'familiarization'
                }
            }
        }
    },
    watch: {
        familiarizationId: {
            immediate: true,
            handler(to) {
                this.openStream(to)
            }
        },
        familiarization: {
            immediate: true,
            handler(to, from) {
                if (!to && !!from) {
                    // удалили familiarizationId
                    this.openFamiliarizations()
                }
            }
        }
    },
    beforeDestroy() {
        this._stream.cancel()
    },
    methods: {
        ...mapActions('familiarization', ['putFamiliarization']),
        openStream(id) {
            this._stream = callOpenStream(
                id,
                (fam) => {
                    this.putFamiliarization({ familiarization: newFamiliarization(fam) })
                },
                (event) => {
                    let ev = newFamiliarizationEvent(event)
                    if (!this.events.find(e => e.id === ev.id)) {
                        this.events.push(ev)
                    }
                },
                (error) => {
                    if (error && error.message !== 'Request was aborted') {
                        // Чтобы не выходило сообщение об ошибке, когда мы закрываем TaskStream
                        // при выходе из Modal
                        console.log('FamiliarizationStream::error', error)
                    }
                }
            )
        },
        openFamiliarizations() {
            this.$router.push(this.goBackPath)
        }
    }
}
</script>
