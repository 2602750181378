import utils from '@/utils'

import { UserName } from '@/model/old/actor1'
import store from '@/store'

export const rolesDisplayNames: { [key: string]: string } = {
    WorkflowManager: 'Менеджер документооборота',
    Head: 'Руководитель',
    Supervisor: 'Супервайзер',
    Employee: 'Сотрудник'
}

export class Role {
    public unit: string
    public name: string

    constructor(
        unit: string,
        name: string
    ) {
        this.unit = unit
        this.name = name
    }

    get displayName(): string {
        return rolesDisplayNames[this.name] || ''
    }

    get unitName(): string {
        const unit = store.getters['company_structure/getUnitById'](this.unit)
        return unit?.name ?? 'Подразделение не найдено'
    }
}

export interface Avatar {
    full: string
    sidebar: string
    small: string
    background: string
}

export class User {
    public id: string
    public name: UserName
    public roles: Array<Role>
    public avatar: Avatar
    public email: string
    public phone: string
    public modificationTime: number

    constructor(
        id: string,
        name: UserName,
        roles: Array<Role>,
        avatar: string,
        email: string,
        phone: string,
        modificationTime: number
    ) {
        this.id = id
        this.name = name
        this.roles = roles
        this.email = email
        this.phone = phone
        this.modificationTime = modificationTime
        this.avatar = {
            get full(): string {
                return utils.storage(id,'full', avatar)
            },
            get sidebar(): string {
                return utils.storage(id,'sidebar', avatar)
            },
            get small(): string {
                return utils.storage(id,'small', avatar)
            },
            get background(): string {
                return utils.storage(id,'background', avatar)
            }
        }
    }
}
