




































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { DOC_LINK_TYPES, Document, DocumentBase } from '@/model/Document'
import { DocumentType } from '@/model/DocumentType'
import { DocumentLinkWithType } from '@/components/document/common_document/links/model'
import { SelectOption } from '@/components/common/select/model'

import Select from '@/components/common/select/Select.vue'
import Modal from '@/components/common/modals/Modal.vue'
import InputSearch from '@/components/common/inputs/InputSearch.vue'
import Button from '@/components/common/buttons/Button.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'

@Component({
    components: {
        Select,
        Modal,
        InputSearch,
        Button,
        ButtonDelete
    }
})

export default class DocumentChangeLinks extends Vue {
    // *************************************************************
    // DATA PARAMS
    searchText = ''
    documentType = ''
    links: Array<DocumentLinkWithType> = []
    linksInfo: Array<DocumentLinkWithType> = []
    visibleModal = false

    // *************************************************************
    // COMPUTED
    @Getter('document_type/getTypes') getTypes!: Array<DocumentType>
    @Getter('document/getDocumentsByType') getDocumentsByType!: (typeId: string) => Array<Document>

    get kindTypes(): Array<SelectOption> {
        return Object.keys(DOC_LINK_TYPES).map(key => {
            return { id: key, name: DOC_LINK_TYPES[key] }
        })
    }

    get documents(): Array<Document> {
        const documents = this.getDocumentsByType(this.documentType)

        this.links.forEach((link: DocumentLinkWithType) => {
            // Убираем уже прикрепленные документы
            let index = documents.findIndex((d: Document) => d.base.id === link.target)
            if (index !== -1) {
                documents.splice(index, 1)
            }
        })

        return documents.filter((doc: Document) =>
            doc.base.name.toLowerCase().includes(this.searchText.toLowerCase())
        )
    }

    get saveDisabled(): boolean {
        if (!this.linksInfo.length && !this.links.length) {
            return true
        }

        if (this.links.some((link: DocumentLinkWithType) => !link.kind)) {
            return true
        }

        if (this.links.length !== this.linksInfo.length) {
            return false
        }

        // Если есть хоть одно изменение
        return !this.links.some((link: DocumentLinkWithType, index: number) => {
            const linkState = this.linksInfo[index]
            return link.target !== linkState.target || link.kind !== linkState.kind
        })
    }

    // *************************************************************
    // METHODS
    @Action('document/callSetLinks')
    callSetLinks!: (params: cuntPB.SetLinksReq) => Promise<void>


    show(links: Array<DocumentLinkWithType>): void {
        this.links = links.map((link: DocumentLinkWithType) => {
            return {
                target: link.target,
                name: link.name,
                kind: link.kind,
                type: link.type
            }
        })

        this.linksInfo = links
        // Этот метод вызывается из родительского компонента
        this.visibleModal = true
        // Очищаем строку поиска
        this.searchText = ''
    }

    select(doc: DocumentBase): void {
        // Добавление связанного документа
        this.links.push({
            target: doc.id,
            name: doc.name,
            kind: '',
            type: doc.type
        })
    }

    removeLink(linkTarget: string): void {
        let index = this.links.findIndex(link => link.target === linkTarget)
        if (index === -1) {
            console.error('link not found', linkTarget)
        } else {
            this.links.splice(index, 1)
        }
    }

    save(): void {
        console.log('save', this.links)
        const cancelPreloaderSetDocumentLinks = this.$preloader('set_document_links', 'Добавление связей документа')

        this.callSetLinks({
            ID: this.$route.params.id,
            Links: this.links.map((link: DocumentLinkWithType) => {
                return {
                    Target: link.target,
                    Kind: link.kind
                }
            })
        })
            .then(() => {
                this.links = []
                this.visibleModal = false
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderSetDocumentLinks())
    }
}
