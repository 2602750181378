























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { AuthenticationStatus } from '@/model/Authentication'
import { User } from '@/model/User'

import Tabs from '@/components/common/tabs/Tabs.vue'
import PhoneInput from '@/components/authentication/PhoneInput.vue'
import EmailInput from '@/components/authentication/EmailInput.vue'
import CodeInput from '@/components/authentication/CodeInput.vue'
import Button from '@/components/common/buttons/Button.vue'

import { Tab } from '@/components/common/tabs/model'
import getError, { GrpcError } from '@/tools/errors/errors'
import { identityPB } from 'api'

const tabs: Array<Tab> = [
    { id: 'sign_in', name: 'Войти' },
    { id: 'sign_up', name: 'Зарегистрироваться' }
]

const identityTabs: Array<Tab> = [
    { id: 'phone', name: 'По номеру телефона' },
    { id: 'email', name: 'По Email' }
]

@Component({
    components: {
        Tabs,
        PhoneInput,
        EmailInput,
        CodeInput,
        Button
    }
})

export default class AuthenticationForm extends Vue {
    // *************************************************************
    // DATA PARAMS
    tabs = tabs
    identityTabs = identityTabs
    authType = ''
    identityName = ''
    identity: { identity: string, valid: boolean } = { identity: '', valid: false }
    verifierName = ''
    verificationCode = ''
    cancelPreloaderVerify: (() => void) | null = null

    // *************************************************************
    // COMPUTED
    @Getter('me/getStatus') getStatus!: AuthenticationStatus | null
    @Getter('me/getMe') getMe!: User | null

    get visibleButton(): boolean {
        if (this.identityName && !this.verifierName) {
            return true
        }
        return this.verifierName === 'code_by_email'
    }

    get codeMask(): string {
        if (this.verifierName && this.identityName === 'email') {
            return '**** - **** - ****'
        }
        return '** ** **'
    }

    get buttonText(): string {
        if (this.identityName && !this.verifierName) {
            return 'Получить код'
        }
        return 'Подтвердить'
    }

    get buttonDisabled(): boolean {
        if (this.identityName && !this.verifierName) {
            return !this.identity.valid
        }
        return !this.verificationCode
    }

    // *************************************************************
    // WATCH
    @Watch('getStatus', { immediate: true, deep: true })
    handleStatusChange(to: AuthenticationStatus | null): void {
        console.log('Watch::getStatus', to)
        if (to && to.authenticating) {
            if (to.authenticating.signIn) {
                this.authType = 'sign_in'
            } else if (to.authenticating.signUp) {
                this.authType = 'sign_up'
            }
        }
    }

    @Watch('authType')
    handleAuthTypeChange(val: string): void {
        if (val === 'sign_in') {
            this.checkSingIn()
        } else if (val === 'sign_up') {
            this.checkSingUp()
        }
    }

    @Watch('verificationCode', { immediate: true })
    handleVerificationCodeChange(val: string): void {
        if (!!val && this.verifierName === 'code_by_sms') {
            if (val.length === 6) {
                this.verify()
            }
        }
    }

    @Watch('identityName', { immediate: true })
    handleIdentityNameChange(to: string, from: string | undefined): void {
        if (from) {
            this.identity = { identity: '', valid: false }
            this.verifierName = ''
            this.verificationCode = ''
        }
    }

    @Watch('getMe')
    handleMeChange(val: User | null): void {
        // Для verify - дожтдаемся получения юзера - потом перенаправляем
        if (val) {
            this.cancelPreloaderVerify?.()
            if (val.roles.length) {
                this.$router.push({ name: 'root' })
            } else {
                this.$router.push({ name: 'me' })
            }
        }
    }

    // *************************************************************
    // METHODS
    @Action('me/callStartSignIn')
    callStartSignIn!: () => Promise<void>

    @Action('me/callStartSignUp')
    callStartSignUp!: () => Promise<GrpcError>

    @Action('me/callCancelAuthentication')
    callCancelAuthentication!: () => Promise<GrpcError>

    @Action('me/callStart')
    callStart!: (params: identityPB.StartReq) => Promise<void>

    @Action('me/callVerify')
    callVerify!: (params: identityPB.VerifyReq) => Promise<AuthenticationStatus>

    checkSingIn(): void {
        if (!this.getStatus?.authenticating) {
            // Эта проверка необходима для того, чтобы убрать тупое повторное нажатие на кнопку
            this.startSignIn()
        } else if (this.getStatus.authenticating.signUp) {
            // Если сначала пытались зарегистрироваться, а потом войти, то сначала отменяем процесс
            this.cancelAuthentication('in')
        }
    }

    checkSingUp(): void {
        if (!this.getStatus?.authenticating) {
            // Эта проверка необходима для того, чтобы убрать тупое повторное нажатие на кнопку
            this.startSignUp()
        } else if (this.getStatus.authenticating.signIn) {
            // Если мы пытались сначала зарегистрироваться, а потом войти, то сначала отменяем процесс
            this.cancelAuthentication('up')
        }
    }

    startSignIn(): void {
        this.callStartSignIn()
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
    }

    startSignUp(): void {
        this.callStartSignUp()
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
    }

    cancelAuthentication(act: string): void {
        this.callCancelAuthentication()
            .then(() => {
                if (act === 'in') {
                    this.startSignIn()
                } else if (act === 'up') {
                    this.startSignUp()
                }
                this.identityName = ''
                this.identity = { identity: '', valid: false }
                this.verifierName = ''
                this.verificationCode = ''
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
    }

    onSubmit(): void {
        if (this.identityName && !this.verifierName) {
            if (this.identity.valid) {
                this.start()
            }
        } else if (this.verifierName === 'code_by_email') {
            this.verify()
        }
    }

    async start(): Promise<void> {
        const verifierName = this.identityName === 'phone' ? 'code_by_sms' : 'code_by_email'
        const cancelPreloaderStartAuthentication = this.$preloader('start_authentication', 'Авторизация')

        this.callStart({
            VerifierName: verifierName,
            IdentityName: this.identityName,
            Identity: this.identity.identity,
            Args: {},
            Values: {}
        })
            .then(() => this.verifierName = verifierName)
            .catch((error: GrpcError) => {
                if (error.message === 'identity already registered') {
                    this.onSignIn()
                } else {
                    this.$snotify.error(getError(error))
                }
            })
            .finally(() => cancelPreloaderStartAuthentication())
    }

    // если пытается зарегистрироваться уже зарегистрированный user
    async onSignIn(): Promise<void> {
        const cancelOnSignInPreloader = this.$preloader('on_sign_in', 'Вы уже зарегистрированы')
        try {
            await this.callCancelAuthentication()
            await this.callStartSignIn()
            await this.start()
        } catch (e: any) {
            this.$snotify.error(getError(e))
        } finally {
            cancelOnSignInPreloader()
        }
    }

    verify(): void {
        this.cancelPreloaderVerify = this.$preloader('verify', 'Аутентификация')
        this.callVerify({
            VerifierName: this.verifierName,
            VerificationCode: this.verificationCode,
            IdentityName: this.identityName,
            Identity: this.identity.identity
        })
            .then(() => {
                // this.animashka.group(true)
                // setTimeout(() => {
                //     this.setStatus(status) - > action
                // }, 4200)
            })
            .catch((error: GrpcError) => {
                this.cancelPreloaderVerify?.()
                console.log('catch--------------------', error)
                this.$snotify.error(getError(error))
            })
    }
}
