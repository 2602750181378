

























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Lang = DatePicker.Lang
import dayjs from 'dayjs'

@Component<Datepicker>({
    components: { DatePicker }
})

export default class Datepicker extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: 0 }) value!: number | string // timestamp
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: true }) clearable!: boolean
    @Prop({ default: false }) loading!: boolean
    @Prop({ default: 'DD/MM/YYYY' }) format!: string

    // *************************************************************
    // DATA PARAMS
    date: Date | null = null

    lang: Lang = {
        formatLocale: {
            months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            monthsShort: ['янв', 'февр', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сент', 'окт', 'нояб', 'дек'],
            weekdays: ['Воскресение', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
            weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1,
            meridiem: (h: number, _: number, isLowercase: boolean) => {
                const word = h < 12 ? 'AM' : 'PM';
                return isLowercase ? !!word.toLocaleLowerCase() : !!word
            },
            meridiemParse: /[ap]\.?m?\.?/i,
            isPM: (input: string)  => {
                return `${input}`.toLowerCase().charAt(0) === 'p'
            }
        },
        days: [],
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        // the calendar title of year
        yearFormat: 'YYYY',
        // the calendar title of month
        monthFormat: 'MMM',
        // the calendar title of month before year
        monthBeforeYear: false
    }

    // *************************************************************
    // COMPUTED
    get datepickerDisabled(): boolean {
        return this.disabled || this.loading
    }

    // *************************************************************
    // WATCH
    @Watch('value', { immediate: true })
    handleValueChange(val: number| string): void {
        if (!val) {
            this.date = null
        } else {
            this.date = dayjs(Number(val)).toDate()
        }
    }

    // *************************************************************
    // METHODS
    @Emit('input')
    emitInput(val: string): number {
        return (dayjs(val).add(12, 'hour').unix()) * 1000
    }

    @Emit('blur')
    emitBlur(): void {
        // Do nothing
    }
}
