<template>
    <div class="pos-relative">
        <div
            v-if="multiple"
            key="multiple"
            class="action-select-card__multiple"
        >
            <div
                v-for="item in selectedList"
                :key="item.id"
                class="action-select-card__multiple-card"
            >
                <span>{{ item.name }}</span>
                <ButtonClose
                    class="select-card__selected-card_close"
                    :small="true"
                    color="violet"
                    @click="remove(item.id)"
                />
                <!--<span class="select-card__selected-card_close" @click="remove(item)">x</span>-->
            </div>
        </div>

        <InputSearch
            v-if="multiple || !value"
            v-model="searchText"
            :placeholder="placeholder"
            :disabled="disabled"
            :active-focus="false"
            class="action-select-card__input"
            @focus="focus = true"
            @blur="focus = false"
        />

        <div
            v-else-if="!multiple && !!value"
            key="single"
            class="select-card__selected-card-container"
        >
            <EmployeeCard
                v-if="entity === 'user' && selectedCard"
                :unit-user="selectedCard"
                :small="true"
                class="select-card__selected-card"
                type="system"
            />
            <UnitCard
                v-if="entity === 'unit'"
                :unit-info="selectedCard"
                :small="true"
                class="select-card__selected-card"
                type="system"
            />
            <div
                v-if="entity === 'directoryItem'"
                class="select-card__selected-card"
            >
                {{ (selectedCard|| {}).name }}
            </div>
            <ItemSelectCard
                v-if="entity === 'item'"
                :item="selectedCard"
                class="select-card__selected-directory"
            />
            <ButtonClose
                class="select-card__selected-card_close"
                :small="true"
                color="violet"
                @click="remove(value)"
            />
        </div>

        <div
            v-if="showList"
            class="select-card__cards"
            @mouseenter="show = true"
            @mouseleave="show = false"
        >
            <div
                v-if="entity === 'user'"
                class="action-select-card__cards"
            >
                <EmployeeCard
                    v-for="user in itemsFilter"
                    :key="user.id"
                    class="action-select-card__card"
                    :unit-user="user"
                    :popover="false"
                    :arrow="false"
                    type="system"
                    @click="selectCard(user.id)"
                />
            </div>
            <div
                v-if="entity === 'unit'"
                class="action-select-card__cards"
            >
                <UnitCard
                    v-for="unit in itemsFilter"
                    :key="unit.id"
                    class="action-select-card__card"
                    :unit-info="unit"
                    @click="selectCard(unit.id)"
                />
            </div>

            <div
                v-if="entity === 'directoryItem'"
                class="action-select-card__cards"
            >
                <div
                    v-for="item in itemsFilter"
                    :key="item.id"
                    class="action-select-card__card"
                    @click="selectCard(item.id)"
                >
                    {{ item.name }}
                </div>
            </div>
            <div
                v-if="entity === 'item'"
                class="action-select-card__cards"
            >
                <ItemSelectCard
                    v-for="item in itemsFilter"
                    :key="item.id"
                    :item="item"
                    class="action-select-card__card"
                    @click="selectCard(item.id)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import InputSearch from '@/components/common/inputs/InputSearch'
import EmployeeCard from '@/components/common/cards/EmployeeCard'
import UnitCard from '@/components/common/cards/UnitCard'
import ItemSelectCard from '@/components/common/cards/ItemSelectCard'
import ButtonClose from '@/components/common/buttons/ButtonClose'

// Импортируем функцию сортировки
import dynamicSort from '@/tools/sort/dynamicSort'

export default {
    components: {
        InputSearch,
        ButtonClose,
        UnitCard,
        EmployeeCard,
        ItemSelectCard
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        entity: { type: String, default: '' },
        value: {
            type: [String, Array],
            default: ''
        },
        items: {
            type: Array,
            default: () => {
                return []
            }
        },
        sort: { type: String, default: '' },
        subSortProperty: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false }
    },
    data: () => {
        return {
            focus: false,
            show: false,
            searchText: ''
        }
    },
    computed: {
        showList() {
            if (!this.multiple) {
                return !this.value && (this.show || this.focus)
            }
            return this.show || this.focus
        },
        itemsFilter() {
            let itemsFilter = []
            if (!this.subSortProperty) {
                itemsFilter = this.items.filter(x =>
                    x[this.sort].toLowerCase().includes(this.searchText.toLowerCase())
                )
            } else {
                itemsFilter = this.items.filter(x =>
                    x[this.sort][this.subSortProperty].toLowerCase().includes(this.searchText.toLowerCase())
                )
            }
            if (this.multiple) {
                itemsFilter = itemsFilter.filter(item => !this.value.includes(item.id))
            }
            itemsFilter.sort(dynamicSort(this.sort, this.subSortProperty))
            return itemsFilter

        },
        selectedCard() {
            if (this.value) {
                return this.items.find(item => item.id === this.value)
            } else {
                return {}
            }
        },
        selectedList() {
            const selectedList = []
            this.value.forEach(id => {
                const item = this.items.find(item => item.id === id)
                if (item) {
                    const name = item.name.shortName || item.name
                    selectedList.push({ id, name })
                }
            })
            return selectedList
        }
    },
    methods: {
        selectCard(entityId) {
            // Выкидываем в родительский компонент выбранный card
            if (!this.multiple) {
                this.$emit('input', entityId)
            } else {
                this.$emit('input', [...this.value, entityId])
            }
        },
        remove(id) {
            this.searchText = ''
            if (!this.multiple) {
                this.$emit('input', '')
            } else {
                const items = this.value.filter(i => i !== id)
                this.$emit('input', items)
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.action-select-card__input
    height 40px

.action-select-card__multiple
    display flex
    flex-direction row
    margin-bottom 5px
    color rgba(0,0,0,0.6)

.action-select-card__multiple-card
    display flex
    flex-direction row
    margin-right 10px
    padding 3px
    background-color rgba(0,0,0,0.2)
    border 1px solid rgba(0,0,0,0.25)
    border-radius 4px

.select-card__selected-card-container
    display flex
    flex-direction row
    flex-wrap nowrap
    align-items center
    padding 2px 0
    height 40px
    border 1px solid #ced4da
    border-radius 5px
    box-sizing border-box

.select-card__selected-card
    flex-grow 1
    padding 0 5px
    min-height 14px
    background-color #fff
    line-height 14px
    box-sizing border-box

.select-card__selected-card_close
    padding 0 10px

.select-card__cards
    position sticky
    display block
    padding 5px 5px 0
    border 1px solid #ced4da
    border-radius 0 0 5px 5px
    max-height 150px
    min-height 50px
    width 100%
    background #ffffff
    box-sizing border-box
    overflow auto
    box-shadow 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12)
    z-index 110

.action-select-card__card
    margin-bottom 5px
    cursor pointer

</style>
