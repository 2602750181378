














import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator'

import 'codemirror/lib/codemirror.css'
import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/vue-editor'
// import { EditorOptions } from '@toast-ui/editor'

@Component<TextEditor>({
    components: { Editor }
})

export default class TextEditor extends Vue {
    @Ref() readonly editor!: Editor

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) value!: string
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: '200px' }) height!: string

    // *************************************************************
    // DATA PARAMS
    editorOptions = {
        hideModeSwitch: true,
        toolbarItems: [
            'heading',
            'bold',
            'italic',
            'strike',
            'divider',
            'hr',
            'quote',
            'divider',
            'ul',
            'ol',
            'task',
            'indent',
            'outdent',
            'divider',
            'table',
        ]
    }

    // *************************************************************
    // METHODS
    onChange(): void {
        let html = this.editor.invoke('getHtml')
        this.emitInput(html)
    }

    insertText(block: string): void {
        this.editor.invoke('setHtml', block)
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(val: string): string {
        return val
    }
}
