interface NewsLink {
    description: string
    title: string
    to: {
        name: string
        params: {
            section: string
            module: string
        }
    }
}

export interface News {
    id: string
    title: string
    date: string
    content?: string
    links?: Array<NewsLink>
}

const news: Array<News> = [
    {
        id: 'news-000029',
        title: 'Настройки списков документов',
        date: '07 января 2021',
        content: 'В списках документов в фильтрах добавлены Настройки для видимой части таблиц. Они актуальны в пределах одного устройства и одного типа документов.'
    },
    {
        id: 'news-000028',
        title: 'Ссылки на приложения',
        date: '31 декабря 2020',
        links: [
            {
                description: 'В личном кабинете указаны',
                title: 'ссылки на приложения.',
                to: {
                    name: 'module',
                    params: {
                        section: 'system',
                        module: 'me'
                    }
                }
            }
        ]
    },
    {
        id: 'news-000027',
        title: 'Изменения в панели быстрого доступа справа',
        date: '11 декабря 2020',
        content: 'В панели появились ссылки на полные списки активных задач и документов.'
    },
    {
        id: 'news-000026',
        title: 'Новые фильтры в задачах',
        date: '11 декабря 2020',
        content: "Во вкладке Задачи появилось 2 новых фильтра: 'Ждут моего выполнения' и 'Ждут моего подтверждения'"
    },
    {
        id: 'news-000025',
        title: 'Неактуальные задачи',
        date: '08 июня 2020',
        content:  'Если задача стала неактуальной, автор может самостоятельно Отменить задачу'
    },
    {
        id: 'news-000024',
        title: 'Причина отклонения согласования',
        date: '29 марта 2020',
        content: "При отклонении согласования документа теперь Вы можете указывать причину отклонения. Если причина не требуется, просто нажмите кнопку 'Сохранить'"

    },
    // {
    //     id: "news-000025",
    //     title: "Аватарки",
    //     date: "06 декабря 2019г",
    //     content: "Если Вы не успеете поставить себе аватарку до Сочельника, то в празничную ночь мы в случайном порядке установим Вам одну из заранее подготовленного списка."
    // },
    // {
    //     id: "news-000024",
    //     title: "Ура, товарищи! Наконец-то время загрузки документов увеличено!",
    //     date: "06 декабря 2019г",
    //     content: "Вы загружаете их единожды при перезагрузке страницы и больше долгих загрузок нет! А если Вы считаете, что документы могут загрузиться быстрее, то история... Однажды Пятачок пришел к Винни и говорит: 'Знаешь, Винни, ты как свинья. Полы в доме грязные, стол в варенье, носки на люстре...'. А Винни отвечает: 'Зато зеркало в коридоре чистое. И отражение в нем тебя порадует'."
    // },
    {
        id: 'news-000023',
        title: "Появился блок 'Задачи'.",
        date: '09 декабря 2019г',
        content: 'Появился новый раздел в Системе, который может упростить Ваше взаимодействие с коллегами и облегчить контроль поставленных задач.',
        links: [
            {
                description: 'Ознакомиться можете в разделе',
                title: 'Задачи',
                to: {
                    name: 'module',
                    params: {
                        section: 'about',
                        module: 'help-tasks'
                    }
                }
            }
        ]

    },
    {
        id: 'news-000022',
        title: 'Необходимо заполнить номер телефона!',
        date: '18 ноября 2019г',
        content: "Во вкладке 'Я' появилось поле 'Номер телефона'. Оно не имеет отношения к номеру телефона, который Вы указываете для Аутентификация. Необходимо указать в данном поле доступный для связи рабочий номер."

    },
    {
        id: 'news-000021',
        title: 'Связанные документы',
        date: '23 октября 2019г',
        content: 'В документах перед Чатом появились Связи. Теперь Вы можете добавлять привязки к другим документам системы, если это необходимо.'
    },
    {
        id: 'news-000020',
        title: 'ВНИМАНИЕ!!! ВНИМАНИЕ!!!',
        date: '28 августа 2019г',
        content: 'При возникновении любых вопросов и предложений необходимо звонить или писать Евгению!!! (8-927-282-94-36).'
    },
    {
        id: 'news-000019',
        title: 'Изменение при создании документа',
        date: '27 августа 2019г',
        content: "При создании документа пока он находится в статусе 'Черновик' Вы видите предварительную цепочку согласования. Как только Вы переведете документ в статус 'Старт', Вы увидите актуальную цепочку, по которой пойдет согласование документа. При повторном переводе документа в 'Черновик', цепочка сброситься в шаблон и будет пересоздана на актуальную при переводе в статус 'Черновик'."
    },
    {
        id: 'news-000018',
        title: 'Новая организация',
        date: '11 августа 2019г',
        content: "Добавлена возможность выкладывать документы от организации 'Строй Универсал'. Из 1С идет синхронизация по двум справочникам - Контрагенты и Договоры контрагентов."
    },
    {
        id: 'news-000017',
        title: 'Добавление файлов в документе',
        date: '01 августа 2019г',
        content: 'Теперь Вы можете прикрепить файлы в документе, просто перетащив их из папки с Вашего компьютера в окно с прикрепленными файлами внутри документа.'
    },
    {
        id: 'news-000016',
        title: 'Изменения в аутотентификации',
        date: '08 июня 2019г',
        content: "Во вкладке 'Я' появился раздел 'Аутентификация'. Вы можете добавить в данный раздел email, который будете использовать для входа в систему.",
        links: [
            {
                description: 'Ознакомиться можете в разделе',
                title: 'Я.',
                to: {
                    name: 'module',
                    params: {
                        section: 'system',
                        module: 'me'
                    }
                }
            }
        ]
    },
    {
        id: 'news-000015',
        title: 'Добавлен возможность согласования нескольких документов',
        date: '16 мая 2019г',
        content: 'В списках документов теперь есть возможность одновременного согласования нескольких документов, без необходимости открытия каждого документа в отдельности.',
        links: [
            {
                description: 'Ознакомиться можете в разделе',
                title: 'Фильтры документов.',
                to: {
                    name: 'module',
                    params: {
                        section: 'about',
                        module: 'document-list-filter'
                    }
                }
            }
        ]
    },
    {
        id: 'news-000014',
        title: "Добавлен фильтр 'Согласую Я'",
        date: '11 апреля 2019г',
        content: "В списках документов над таблицей с документами добавлена возможность выбора только тех документов, которые требуют в данный момент Вашего согласования. Поле называется 'Согласую Я'.",
        links: [
            {
                description: 'Ознакомиться можете в разделе',
                title: 'Фильтры документов.',
                to: {
                    name: 'module',
                    params: {
                        section: 'about',
                        module: 'document-list-filter'
                    }
                }
            }
        ]
    },
    {
        id: 'news-000013',
        title: 'Информация о пользователях',
        date: '8 апреля 2019г',
        content: 'Добавлена информация о пользователях системы. В документе в графе с согласованием при наведении на узел согласования выходит полная информация по пользователю.'
    },
    {
        id: 'news-000012',
        title: 'Оповещение по email о необходимости согласования',
        date: '21 марта 2019г',
        content: 'Если Вы заполните поле email в информации о себе, то будете получать уведомления о необходимости согласования документов.',
        links: [
            {
                description: 'Чтобы заполнить email, перейдите по ссылке',
                title: 'Информация о себе.',
                to: {
                    name: 'module',
                    params: {
                        section: 'system',
                        module: 'me'
                    }
                }
            }
        ]
    },
    {
        id: 'news-000011',
        title: "Добавлен раздел 'Фильтры документов'",
        date: '20 марта 2019г',
        content: "Во вкладке 'О системе' добавлен раздел 'Фильтры документов', в котором подробно описаны правила пользования фильтрами в списках документов.",
        links: [
            {
                description: 'Ознакомиться можете в разделе',
                title: 'Фильтры документов.',
                to: {
                    name: 'module',
                    params: {
                        section: 'about',
                        module: 'document-list-filter'
                    }
                }
            }
        ]
    },
    {
        id: 'news-000010',
        title: "Перевод документов 'В архив'",
        date: '17 марта 2019г',
        content: "В данный момент Вы создаете документы. Эти документы проходят цепочку согласования и переходят в статус 'В работе'. Если документ, созданный Вами, перешел в статус 'В работе', по нему не созданы и не планируются создаваться задачи, то Вы самостоятельно можете перевести его в статус 'Архив', таким образом закрыв всю работу по данному документу."
    },
    {
        id: 'news-000009',
        title: "Добавлен раздел 'Создание документа'",
        date: '12 марта 2019г',
        content: "Во вкладке 'О системе' добавлен раздел 'Создание документа', в котором подробно описаны правила создания нового документа в системе пользователем.",
        links: [
            {
                description: 'Ознакомиться можете в разделе',
                title: 'Создание документа.',
                to: {
                    name: 'module',
                    params: {
                        section: 'about',
                        module: 'document-creation'
                    }
                }
            }
        ]
    },
    {
        id: 'news-000008',
        title: 'Добавлены договоры лизинга',
        date: '11 марта 2019г',
        content: 'Список договоров дополнен договорами лизинга из 1С.'
    },
    {
        id: 'news-000007',
        title: 'Изменения в создании документа',
        date: '10 марта 2019г',
        content: 'Теперь при создании нового документа пользователю необходимо выбирать от имени какого подразделения он его создает. Также необходимо указать для какой именно организации создается новый документ. С этого момента согласование документа проходит сначала цепочку всех руководителей, а только потом попадает на согласование стандартному списку сотрудников, принятому в организации.'
    },
    {
        id: 'news-000006',
        title: 'Сортировка документов',
        date: '08 марта 2019г',
        content: 'Теперь документы в списках выходят с сортировкой по времени создания, т.е. в самом верху таблицы Вы видите документ с максимально ранней датой создания.'
    },
    {
        id: 'news-000005',
        title: "Добавлена вкладка 'О системе'",
        date: '07 марта 2019г',
        content: "Добавлена новая вкладка 'О системе', в которой можно увидеть последние новости по проекту, а также инструкции по работе в системе."
    },
    {
        id: 'news-000004',
        title: 'Главное правило обращения в тех.поддержку',
        date: '04 марта 2019г',
        content: "'Советую вам сдаться немедленно, потому что если моя армия войдёт в ваши земли, я уничтожу ваши сады, порабощу людей и разрушу город'."
    },
    {
        id: 'news-000003',
        title: 'Сохранение фильтров в списках документов',
        date: '04 марта 2019г',
        content: 'Теперь в списках документов сохраняются выбранные фильтры. Вы выбираете галочками документы с необходимыми статусами, устанавливаете даты в календаре, пишите запрос в строке поиска. Когда вы перейдете на любую другую страницу, данные настройки не собьются. Сейчас все эти настройки едины для всех типов документов. Но в каждом типе документа независимо сохраняется позиция scroll. Если Вы хотите сбросить настройки, просто нажмите крестик в верхнем правом углу. Если Вы обновите страницу, настройки также собьются.'
    },
    {
        id: 'news-000002',
        title: 'Выбор организации в оргструктуре',
        date: '03 марта 2019г',
        content: "В оргструктуре в карточке каждого подразделения появилась галочка в верхнем левом углу с пометкой 'Организация'. Если эта галочка стоит, то данное подразделение является корневым. На него могут создаваться свои собственные документы."
    },
    {
        id: 'news-000001',
        title: "Во вкладке 'Я' добавлено поле 'Email'",
        date: '03 марта 2019г',
        content: "Во вкладке 'Система' в пункте 'Я' добалено поле email. Можно заходить и вносить свои данные. В дальнейшем email, указанный в данным поле, будет использоваться для информирования пользователя о новых задачах."
    }
]

export default news
