







































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Unit } from '@/model/Unit'

interface UnitWithDepth {
    id: string
    name: string
    parent: string
    root: boolean
    centerId: string

    depth: number
    radius: number
    x: number
    y: number
}

interface Link {
    id: string
    d: string
}

@Component({})

export default class UnitsTree extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) selectedUnit!: string
    @Prop({ default: '' }) selectedCenter!: string

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get units(): Array<UnitWithDepth> {
        const units: Array<UnitWithDepth> = []

        // Задаем высоту блока svg
        let height = 70

        const addChildren = (structuralUnits: Array<Unit>, unit: Unit, count: number) => {
            // Вычисляем радиус unit
            // С каждым оборотом радиус уменьшается, но не делаем его меньше 10px
            const radius = count < 6 ? 60 / (count + 1) : 10

            units.push({
                ...unit,
                depth: count,
                radius: radius,
                x: (count + 1) * 70,
                y: height
            })

            height = height + (radius * 2) + 10

            const children = structuralUnits.filter((u: Unit) => u.parent === unit.id)
            if (children.length) {
                children.forEach((child: Unit) => addChildren(structuralUnits, child, count + 1))
            }
        }

        if (this.selectedCenter === 'all') {
            const root = this.getUnitById('root')
            if (root) {
                addChildren(this.getUnits, root, 0)
            } else {
                const centers = this.getUnits.filter((unit: Unit) => unit.parent === 'root')
                centers.forEach((center: Unit) => addChildren(this.getUnits, center, 0))
            }
        } else {
            const center = this.getUnitById(this.selectedCenter)
            if (!center) {
                return []
            }
            const unitsFilterByCenter = this.getUnits.filter(unit => unit.centerId === this.selectedCenter)
            addChildren(unitsFilterByCenter, center, 0)
        }

        return units
    }

    get svgHeight(): number {
        let height = 90
        this.units.forEach((unit: UnitWithDepth) => {
            height = height + (unit.radius * 2 + 10)
        })
        return height
    }

    get svgWidth(): number {
        return Math.max.apply(Math, this.units.map(function (o) { return o.x })) + 300
    }

    get links(): Array<Link> {
        return this.units.map((unit: UnitWithDepth) => {
            const parent = this.units.find((u: UnitWithDepth) => u.id === unit.parent)

            const MX = unit.x
            const MY = unit.y
            let CLastX = unit.x
            let CLastY = unit.y

            if (parent) {
                CLastX = parent.x
                CLastY = parent.y
            }

            const CStartX = MX - 33
            const CStartY = MY + 18
            const CMiddleX = CLastX - 33
            const CMiddleY = CLastY + 18

            return {
                id: `link-${unit.id}`,
                // Кривая Безье
                d: `M ${MX} ${MY} C ${CStartX} ${CStartY}, ${CMiddleX} ${CMiddleY}, ${CLastX} ${CLastY}`
            }
        })
    }

    // *************************************************************
    // EMIT
    @Emit('selectUnit')
    emitSelectUnit(unitId: string): string {
        return unitId
    }
}
