import api from 'api1'
import newFamiliarization from '@/model/convert/newFamiliarization'

export default function ({ dispatch }, { id }) {
    return new Promise((resolve, reject) => {
        api.cunt.FamiliarizationSvcClient.active(api.cunt.ActiveFamiliarizationReq({
            ID: id,
        }), data => {
            data = api.cunt.Familiarization(data)
            console.log('::DATA::FamiliarizationSvc.active:', data)

            dispatch('putFamiliarization', {
                familiarization: newFamiliarization(data)
            })

            resolve()
        }, error => {
            (error ? console.warn : console.log)('::DONE::FamiliarizationSvc.active:', error)
            reject(error)
        })
    })
}
