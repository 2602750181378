var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-requisite-card"
  }, [_c('div', {
    class: {
      'document-requisite-card__name_deleted': _vm.deleted
    }
  }, [_vm._v("\n        " + _vm._s(_vm.requisite.type.name) + "\n        "), _vm.requisite.type.required ? _c('span', {
    staticClass: "red"
  }, [_vm._v("\n            *\n        ")]) : _vm._e()]), _vm._v(" "), _vm.requisite.type.type === 'req_number' ? _c('InputNumber', {
    staticClass: "h-40",
    attrs: {
      "disabled": _vm.disabled,
      "loading": _vm.loadingRequisite
    },
    on: {
      "blur": _vm.updateSimple,
      "enter": _vm.updateSimple
    },
    model: {
      value: _vm.requisiteValue,
      callback: function callback($$v) {
        _vm.requisiteValue = $$v;
      },
      expression: "requisiteValue"
    }
  }) : _vm.requisite.type.type === 'req_date' ? _c('Datepicker', {
    staticClass: "h-40",
    attrs: {
      "disabled": _vm.disabled,
      "loading": _vm.loadingRequisite
    },
    on: {
      "input": _vm.updateSimple
    },
    model: {
      value: _vm.requisiteValue,
      callback: function callback($$v) {
        _vm.requisiteValue = $$v;
      },
      expression: "requisiteValue"
    }
  }) : _vm.requisite.type.type === 'req_string' ? _c('InputBase', {
    staticClass: "h-40",
    attrs: {
      "type": "text",
      "placeholder": "Значение реквизита",
      "disabled": _vm.disabled,
      "loading": _vm.loadingRequisite
    },
    on: {
      "blur": _vm.updateSimple,
      "enter": _vm.updateSimple
    },
    model: {
      value: _vm.requisiteValue,
      callback: function callback($$v) {
        _vm.requisiteValue = $$v;
      },
      expression: "requisiteValue"
    }
  }) : _c('SelectDocumentRequisiteCard', {
    staticClass: "h-40",
    attrs: {
      "requisite": _vm.requisite,
      "can-change-requisite": !_vm.disabled,
      "loading": _vm.loadingRequisite,
      "deleted": _vm.deleted
    },
    on: {
      "changeEditor": function changeEditor($event) {
        return _vm.updateEditor($event);
      },
      "changeRecord": function changeRecord($event) {
        return _vm.updateRecord($event);
      }
    },
    model: {
      value: _vm.requisiteValue,
      callback: function callback($$v) {
        _vm.requisiteValue = $$v;
      },
      expression: "requisiteValue"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }