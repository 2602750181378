var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "common-document__container"
  }, [_c('GoBack', {
    staticClass: "common-document__back",
    attrs: {
      "text": "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043A \u0441\u043F\u0438\u0441\u043A\u0443 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u0432 ".concat(_vm.type.name),
      "link": _vm.goBackPath
    }
  }), _vm._v(" "), _vm.openDocument ? _c('div', {
    staticClass: "common-document"
  }, [_c('div', {
    staticClass: "common-document__main-info"
  }, [_c('DocumentBaseInfo', {
    attrs: {
      "openDocument": _vm.openDocument,
      "can-change-requisite": _vm.canChangeRequisite,
      "one-ass": _vm.openDocument.oneAss,
      "events": _vm.events
    },
    on: {
      "removed": _vm.returnToList
    }
  }), _vm._v(" "), _c('DocumentRequisites', {
    attrs: {
      "openDocument": _vm.openDocument,
      "can-change-requisite": _vm.canChangeRequisite
    }
  }), _vm._v(" "), _c('DocumentFiles', {
    attrs: {
      "files": _vm.openDocument.files,
      "document-id": _vm.openDocument.base.id,
      "can-change": _vm.canChangeRequisite
    }
  }), _vm._v(" "), _vm.showLinks ? _c('DocumentLinks', {
    attrs: {
      "isMe": _vm.getMe.id === _vm.openDocument.base.author.id,
      "links-info": _vm.openDocument.links
    }
  }) : _vm._e(), _vm._v(" "), _c('DocumentChat', {
    staticClass: "common-document__chat mt-5",
    attrs: {
      "open-document-id": _vm.openDocument.base.id,
      "events": _vm.events,
      "title": "Чат"
    },
    on: {
      "addEvent": function addEvent($event) {
        return _vm.putEvent($event);
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "common-document__right"
  }, [_c('DocumentWorkflow', {
    staticClass: "document-graph",
    attrs: {
      "openDocument": _vm.openDocument
    }
  }), _vm._v(" "), _c('DocumentTasks', {
    staticClass: "mt-2",
    attrs: {
      "open-document-id": _vm.openDocument.base.id,
      "open-document-name": _vm.openDocument.base.name
    }
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }