






















































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Unit } from '@/model/Unit'
import { Document } from '@/model/Document'
import { DocumentTypeRequisite } from '@/model/DocumentType'

import Checkbox from '@/components/common/inputs/Checkbox.vue'
import RequisiteCell from '@/components/document/list/RequisiteCell.vue'
import Popover from '@/components/common/Popover.vue'

@Component<DocumentLine>({
    components: { RequisiteCell, Popover, Checkbox }
})

export default class DocumentLine extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) document!: Document
    @Prop({ default: () => []}) requisites!: Array<DocumentTypeRequisite>
    @Prop({ default: () => []}) documentHiddenCells!: Array<string>
    @Prop({ default: false }) selected!: boolean

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    // *************************************************************
    // EMIT
    @Emit('selectDocument')
    emitSelectDocument(value: boolean): boolean {
        return value
    }
}
