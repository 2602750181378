import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { ItemsState } from '@/store/items/items'

import {
    ROLES,
    STATES,
} from '@/store/items/model'

const ItemsActions: ActionTree<ItemsState, RootState> = {
    /** Получаем список типов документов с бэка и заполняем новые dashitem для каждого типа документа */
    setDocumentItem({ commit }, item) {
        commit('setDocumentItem', item)
    },

    goTo({ commit, getters }, newState: string) {
        const navState = getters.getNavState

        if (navState === STATES.INITIAL && newState === STATES.ROOT) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'big'
            })
        } else if (navState === STATES.INITIAL && newState === STATES.SECTION) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'middle'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'big'
            })
        } else if (navState === STATES.INITIAL && newState === STATES.MODULE) {
            commit('resize', {
                role: ROLES.SECTION,
                size: 'small'
            })
            commit('resize', {
                role: ROLES.ROOT,
                size: 'small'
            })
        } else if (navState === STATES.INITIAL && newState === STATES.DOCUMENT) {
            commit('resize', {
                role: ROLES.SECTION,
                size: 'small'
            })
            commit('resize', {
                role: ROLES.ROOT,
                size: 'small'
            })
        } else if (navState === STATES.ROOT && newState === STATES.SECTION) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'middle'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'big'
            })
        } else if (navState === STATES.SECTION && newState === STATES.MODULE) {
            commit('resize', {
                role: ROLES.SECTION,
                size: 'small'
            })
            commit('resize', {
                role: ROLES.ROOT,
                size: 'small'
            })
        } else if (navState === STATES.SECTION && newState === STATES.DOCUMENT) {
            commit('resize', {
                role: ROLES.SECTION,
                size: 'small'
            })
            commit('resize', {
                role: ROLES.ROOT,
                size: 'small'
            })
        } else if (navState === STATES.ROOT && newState === STATES.MODULE) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'small'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'small'
            })
        } else if (navState === STATES.ROOT && newState === STATES.DOCUMENT) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'small'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'small'
            })
        } else if (navState === STATES.SECTION && newState === STATES.ROOT) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'big'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'big'
            })
        } else if (navState === STATES.MODULE && newState === STATES.SECTION) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'middle'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'big'
            })
        } else if (navState === STATES.MODULE && newState === STATES.ROOT) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'big'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'big'
            })
        } else if (navState === STATES.DOCUMENT && newState === STATES.SECTION) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'middle'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'big'
            })
        } else if (navState === STATES.DOCUMENT && newState === STATES.ROOT) {
            commit('resize', {
                role: ROLES.ROOT,
                size: 'big'
            })
            commit('resize', {
                role: ROLES.SECTION,
                size: 'big'
            })
        }
        commit('setNavState', newState)
    },

    /**
     * Vertical transition (up -> down && down -> up) in SECTION & MODULE
     * if the first name === to -> transitionTop2Bottom
     * if the first name == from -> transitionBottom2Top
     */
    gotoParams({ state, commit }, { configuration, from, to }) {
        let stop = false

        for (let i = 0; i < state.dashItems.length && !stop; i++) {
            switch (state.dashItems[i].name) {
            case from:
                commit('transitionVertical', {
                    configuration: configuration,
                    transition: 'transitionBottom2Top'
                })
                stop = true
                break
            case to:
                commit('transitionVertical', {
                    configuration: configuration,
                    transition: 'transitionTop2Bottom'
                })
                stop = true
                break
            }
        }
    }
}

export default ItemsActions
