<template>
    <div class="requisite-card box-shadow__double box-shadow__error">
        <InputBase
            v-model.lazy.trim="changingRequisite.name"
            placeholder="Имя реквизита"
            class="mb-3 requisite-card__name"
            required
        />

        <div class="flex-row-center">
            <Checkbox
                v-model="changingRequisite.required"
                class="ml-5"
                label="Обязательный"
            />
            <Checkbox
                v-model="changingRequisite.editable"
                class="ml-5"
                label="Редактируемый"
            />
            <div class="flex-row-center requisite-card__block ml-auto">
                <Select
                    v-model="changingRequisite.type"
                    :options="types"
                    required
                    placeholder="Тип реквизита"
                    class="min-width-200 h-36"
                />
            </div>


            <div
                v-if="changingRequisite.type === 'req_do'"
                class="flex-row-center requisite-card__block ml-5"
            >
                <Select
                    v-model="changingRequisite.subType"
                    :options="directories"
                    required
                    placeholder="Справочник"
                    class="min-width-200 h-36"
                />
            </div>

            <ButtonGreen
                class="ml-auto"
                :disabled="disabled"
                text="Добавить"
                @click="addRequisite"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import InputBase from '@/components/common/inputs/InputBase'
import Checkbox from '@/components/common/inputs/Checkbox'
import ButtonGreen from '@/components/common/buttons/ButtonGreen'
import Select from '@/components/common/select/Select'

// Импортируем имена типов реквизитов
import { requisitesTypesDisplayName } from '@/components/document_type/util'
import errors from '@/tools/errors/errors.ts'

export default {
    name: 'RequisiteCard',
    components: {
        InputBase,
        Checkbox,
        ButtonGreen,
        Select
    },
    props: {
        typeId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            changingRequisite: {
                name: '',
                type: '',
                subType: '',
                required: false,
                editable: false
            }
        }
    },
    computed: {
        ...mapGetters('directories', ['getDirectories']),
        types() {
            let types = []
            for (let t in requisitesTypesDisplayName) {
                types.push({
                    id: t,
                    name: requisitesTypesDisplayName[t]
                })
            }
            return types
        },
        directories() {
            // Не показываем архивные справочники
            return this.getDirectories.filter(d => !d.archived)
        },
        disabled() {
            if (!this.changingRequisite.name) {
                return true
            }
            if (!this.changingRequisite.type) {
                return true
            }
            if (this.changingRequisite.type === 'req_do' && !this.changingRequisite.subType) {
                return true
            }
            return false
        }
    },
    methods: {
        ...mapActions('document_type', ['callAddRequisite']),
        addRequisite() {
            if (this.disabled) {
                return
            }

            this._cancelPreloaderAddDocumentTypeRequisite = this.$preloader('add_document_type_requisite', 'Добавление реквизита в тип документа')

            this.callAddRequisite({
                ID: this.typeId,
                Requisite: {
                    Name: this.changingRequisite.name,
                    Type: this.changingRequisite.type,
                    SubType: this.changingRequisite.subType,
                    Required: this.changingRequisite.required,
                    Editable: this.changingRequisite.editable
                }
            }).then(() => {
                this._cancelPreloaderAddDocumentTypeRequisite()

                // Прокидываем событие в родительский компонент, чтобы убрать блок с добавлением нового реквизита
                this.$emit('addedRequisite')
            })
                .catch(
                    error => {
                        this._cancelPreloaderAddDocumentTypeRequisite()
                        this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                            timeout: 0
                        })
                    }
                )
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/shadows/box-shadow__double.styl'
@import '~@/assets/styles/shadows/box-shadow__error.styl'

.requisite-card
    padding 5px
    box-sizing border-box
    word-wrap break-word

.requisite-card__name
    width 100%
    height 32px

///////////////////////////////////////////////////////
////                 //////////////////////////////////
////  Media          //////////////////////////////////
////                 //////////////////////////////////
///////////////////////////////////////////////////////
@media screen and (max-width: 1099px)
    .requisite-card__block
        display block

</style>
