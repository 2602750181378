var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "user-page user-page_".concat(_vm.routeName)
  }, [_vm.routeName === 'me' ? _c('Button', {
    staticClass: "user-page__exit",
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.exit
    }
  }, [_c('span', {
    staticClass: "mr-2 fs-18 user-page__exit-text"
  }, [_vm._v("Выход")]), _vm._v(" "), _c('FAIcon', {
    staticClass: "app__user_exit-icon",
    attrs: {
      "icon": "sign-out-alt"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "user__main-info"
  }, [_c('div', {
    ref: "dropZone",
    staticClass: "user__main-info_dropzone",
    style: {
      'background-image': 'url(' + _vm.getMe.avatar.full + ')'
    },
    on: {
      "dragenter": function dragenter($event) {
        return _vm.dragEnter($event);
      },
      "dragleave": function dragleave($event) {
        return _vm.dragLeave($event);
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.drop($event);
      }
    }
  }, [_c('div', {
    staticClass: "user__main-info_dropzone_select"
  }, [_c('Button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.selectFile
    }
  }, [_vm._v("Выбрать аватар")]), _vm._v(" "), _c('div', [_vm._v("Или перетащите его сюда")])], 1), _vm._v(" "), _c('Uploader', {
    ref: "uploader",
    attrs: {
      "before-upload": _vm.beforeUpload
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_vm._v("\n                Заполняя свои данные на сервисе, Вы подтверждаете, что ознакомлены с\n                "), _c('router-link', {
    attrs: {
      "to": _vm.privacyLink
    }
  }, [_vm._v("\n                    политикой конфиденциальности\n                ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "user-page__field"
  }, [_vm._v("\n                Ваш ID: " + _vm._s(_vm.getMe.id) + "\n            ")]), _vm._v(" "), _vm._l(_vm.name, function (_, key) {
    return _c('InputWithLabel', {
      key: key,
      staticClass: "user-page__field",
      attrs: {
        "label": _vm.namePlaceholders[key],
        "loading": _vm.loadingName
      },
      on: {
        "blur": _vm.setName,
        "enter": _vm.setName
      },
      model: {
        value: _vm.name[key],
        callback: function callback($$v) {
          _vm.$set(_vm.name, key, typeof $$v === 'string' ? $$v.trim() : $$v);
        },
        expression: "name[key]"
      }
    });
  })], 2)]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "page-subtitle mt-7 mb-5"
  }, [_vm._v("Контактная информация")]), _vm._v(" "), _c('InputWithLabel', {
    staticClass: "user-page__field",
    attrs: {
      "label": "Email"
    },
    on: {
      "blur": _vm.setEmail,
      "enter": _vm.setEmail
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "email"
    }
  }), _vm._v(" "), _c('PhoneInput', {
    staticClass: "user-page__field",
    on: {
      "input": _vm.setPhone
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "page-subtitle mt-7 mb-2"
  }, [_vm._v("Мобильное приложение")]), _vm._v(" "), _c('div', {
    staticClass: "user__qr"
  }, [_c('QR', {
    attrs: {
      "sourse": "google"
    }
  }), _vm._v(" "), _c('QR', {
    attrs: {
      "sourse": "apple"
    }
  })], 1)]), _vm._v(" "), _c('UserAuthentication1')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }