













import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { StageTemplate } from '@/model/Workflow'

import ButtonGreen from '@/components/common/buttons/ButtonGreen.vue'

@Component({
    components: { ButtonGreen }
})

export default class WFTActionDeleteStage extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('workflow/getActiveStage') getActiveStage!: StageTemplate | null

    // *************************************************************
    // METHODS
    @Action('workflow/removeWFStage')
    removeWFStage!: () => void

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFActiveStage')
    changeWFActiveStage!: (activeStage: number | null) => void

    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    deleteStage(): void {
        // Удаляем Stage по индексу
        this.removeWFStage()
        // Возвращаемся в меню выбора действия
        this.changeWFAction('')
        // Удаляем активный блок - передаем undefined, т.к. в activeStage содержится индекс блока,
        // а он может быть равен 0
        this.changeWFActiveStage(null)
        // Отмечаем наличие изменений в workFlow
        this.workflowChange(true)
    }
}
