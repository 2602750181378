var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onBlurPhone($event);
      }
    }
  }, [_c('div', {
    staticClass: "input-phone input",
    class: {
      'input_error': _vm.errorPhone
    },
    on: {
      "click": _vm.onClickPhone
    }
  }, [_c('div', {
    staticClass: "input-phone__flag mr-3"
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 512 512"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "y": "0px",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  }, [_c('polygon', {
    staticStyle: {
      "fill": "#F0F0F0"
    },
    attrs: {
      "points": "0,85.33 0,199.107 0,312.885 0,426.662 512,426.662 512,312.885 512,199.107 512,85.33 "
    }
  }), _vm._v(" "), _c('rect', {
    staticStyle: {
      "fill": "#0052B4"
    },
    attrs: {
      "y": "85.333",
      "width": "512",
      "height": "341.337"
    }
  }), _vm._v(" "), _c('rect', {
    staticStyle: {
      "fill": "#F0F0F0"
    },
    attrs: {
      "y": "85.333",
      "width": "512",
      "height": "113.775"
    }
  }), _vm._v(" "), _c('rect', {
    staticStyle: {
      "fill": "#D80027"
    },
    attrs: {
      "y": "312.884",
      "width": "512",
      "height": "113.775"
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "mr-2"
  }, [_vm._v("\n            +7\n        ")]), _vm._v(" "), _c('input', {
    ref: "phone",
    attrs: {
      "placeholder": "( *** ) ** - ** - **"
    },
    domProps: {
      "value": _vm.denormalizedPhone
    },
    on: {
      "input": function input($event) {
        return _vm.onInputPhone($event.target.value);
      },
      "blur": _vm.onBlurPhone
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "form__error"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorPhone,
      expression: "errorPhone"
    }]
  }, [_vm._v(_vm._s(_vm.errorPhone))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }