<template>
    <div
        class="textarea-container"
        :class="{
            error: error,
            'textarea_disabled': disabled
        }"
    >
        <textarea
            ref="textarea"
            :placeholder="placeholder"
            :disabled="disabled"
            rows="1"
            :value="value"
            class="textarea"
            :class="{'textarea_disabled': disabled}"
            @input="$emit('input', $event.target.value)"
        />
    </div>
</template>

<script>
export default {
    props: {
        placeholder: { type: String, default: 'Ввод' },
        value: { type: String, default: '' },
        minLines: { type: Number, default: 1 },
        maxLines: { type: Number, default: 1 },
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    },
    data: () => {
        return {
            error: false
        }
    },
    watch: {
        value: {
            immediate: false,
            handler(to, from) {
                if (this.required) {
                    this.error = !to
                }
                if (!!to && !from) {
                    // Срабатывает только при первом вводе
                    this.$refs.textarea.style.maxHeight = this.$refs.textarea.clientHeight * this.maxLines + 'px'
                    this.$refs.textarea.style.minHeight = this.$refs.textarea.clientHeight * this.minLines + 'px'
                }
                if (!!to && !!from) {
                    // Автоподгон высоты textarea под содержимое value
                    // Обязательно !!from, т.к. при инициализации $refs еще не доступны
                    // Обходим это в mounted
                    this.$refs.textarea.style.height = 0
                    this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
                } else if (!to && !!from) {
                    // Это запускается, когда мы скидываем значение
                    // Скидываем высоту в ноль
                    this.$refs.textarea.style.height = 0
                }
            }
        }
    },
    mounted() {
        // Мы устанавливаем нашему textarea высоту в одну строку по умолчанию
        // Далее через props maxLines && minLines устанавливаем максимальную и минимальную высоты
        // Прокрутка в textarea появляется после превышения максимальной высоты
        // В mounted устанавливаем ему height = scrollHeight, далее автоподгон высоты регулируем в watch 'value'
        if (this.$refs.textarea.clientHeight) {
            this.$refs.textarea.style.maxHeight = this.$refs.textarea.clientHeight * this.maxLines + 'px'
            this.$refs.textarea.style.minHeight = this.$refs.textarea.clientHeight * this.minLines + 'px'
        } else {
            this.$refs.textarea.style.maxHeight = '19px'
            this.$refs.textarea.style.minHeight = '19px'
        }
        if (this.$refs.textarea.scrollHeight) {
            this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
        } else {
            this.$refs.textarea.style.height = '19px'
        }
    }
}
</script>

<style lang="stylus" scoped>
.textarea-container
    padding 8px 0
    border-radius .25rem
    border-style solid
    border-width 1px
    border-color #ced4da
    background-color #ffffff
    box-sizing: content-box

.textarea
    display block
    border none
    background-color #ffffff
    -webkit-flex 1 1 auto
    flex 1 1 auto
    font-size 1rem
    font-weight normal
    padding 0
    min-width 0
    line-height 1.2
    text-align left
    outline none
    width 100%
    will-change width
    overflow-x hidden
    resize none
    transition border-color .15s ease-in-out,box-shadow .15s ease-in-out

.textarea_disabled
    background-color #f5f5f5

textarea[placeholder]
    text-align center

.error
    box-shadow inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 0, 0, 1)
</style>
