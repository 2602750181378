<template>
    <div>
        <div class="title">
            Ознакомления
        </div>
        <div class="events-container">
            <div
                v-for="event in familiarization"
                :key="event.id"
                class="event-card"
                @click="openFamiliarization(event.id)"
            >
                {{ event.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('me', ['getMe']),
        ...mapGetters('familiarization', ['getUserFamiliarization']),
        familiarization() {
            return this.getUserFamiliarization(this.getMe.id)
        }
    },
    methods: {
        openFamiliarization(id) {
            this.$router.push({
                name: 'document',
                params: {
                    section: 'system',
                    module: 'familiarization',
                    id: id
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.events
    display flex
    flex-direction column
    overflow hidden

.title
    margin 10px 0
    text-align center
    font-size 1.4rem

.events-container
    flex-grow 1
    overflow-y auto

.event-card
    margin 2px 2px 10px 2px
    padding 5px 10px
    border 1px solid #D9D9D9
    border-radius 4px
    box-shadow 0px 2px 6px rgba(0, 0, 0, 0.1)
    cursor pointer
</style>
