import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { NumeratorState } from '@/store/numerator/numeratorState'

import { Numerator } from '@/model/numerator.js'

const NumeratorGetters: GetterTree<NumeratorState, RootState> = {
    getNumerators: (state: NumeratorState): Array<Numerator> => state.numerators,

    getNumeratorById: (state: NumeratorState) => (id: string): Numerator | null => state.numerators.find((num: Numerator) => num.id === id) ?? null,

    getNumeratorsByCenterId: (state: NumeratorState) => (centerId: string): Array<Numerator> => state.numerators.filter((num: Numerator) => num.centerId === centerId),

    getNumeratorVersion: (state: NumeratorState) => (id: string): number => state.versions[id] ?? 0
}

export default NumeratorGetters
