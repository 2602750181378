var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "requisite-card box-shadow__double box-shadow__error"
  }, [_c('InputBase', {
    staticClass: "mb-3 requisite-card__name",
    attrs: {
      "placeholder": "Имя реквизита",
      "required": ""
    },
    model: {
      value: _vm.changingRequisite.name,
      callback: function callback($$v) {
        _vm.$set(_vm.changingRequisite, "name", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "changingRequisite.name"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, [_c('Checkbox', {
    staticClass: "ml-5",
    attrs: {
      "label": "Обязательный"
    },
    model: {
      value: _vm.changingRequisite.required,
      callback: function callback($$v) {
        _vm.$set(_vm.changingRequisite, "required", $$v);
      },
      expression: "changingRequisite.required"
    }
  }), _vm._v(" "), _c('Checkbox', {
    staticClass: "ml-5",
    attrs: {
      "label": "Редактируемый"
    },
    model: {
      value: _vm.changingRequisite.editable,
      callback: function callback($$v) {
        _vm.$set(_vm.changingRequisite, "editable", $$v);
      },
      expression: "changingRequisite.editable"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center requisite-card__block ml-auto"
  }, [_c('Select', {
    staticClass: "min-width-200 h-36",
    attrs: {
      "options": _vm.types,
      "required": "",
      "placeholder": "Тип реквизита"
    },
    model: {
      value: _vm.changingRequisite.type,
      callback: function callback($$v) {
        _vm.$set(_vm.changingRequisite, "type", $$v);
      },
      expression: "changingRequisite.type"
    }
  })], 1), _vm._v(" "), _vm.changingRequisite.type === 'req_do' ? _c('div', {
    staticClass: "flex-row-center requisite-card__block ml-5"
  }, [_c('Select', {
    staticClass: "min-width-200 h-36",
    attrs: {
      "options": _vm.directories,
      "required": "",
      "placeholder": "Справочник"
    },
    model: {
      value: _vm.changingRequisite.subType,
      callback: function callback($$v) {
        _vm.$set(_vm.changingRequisite, "subType", $$v);
      },
      expression: "changingRequisite.subType"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('ButtonGreen', {
    staticClass: "ml-auto",
    attrs: {
      "disabled": _vm.disabled,
      "text": "Добавить"
    },
    on: {
      "click": _vm.addRequisite
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }