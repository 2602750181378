var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "action-add-link"
  }, [_c('InputSearch', {
    staticClass: "action-add-link__search_filter",
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "action__nodes-container"
  }, [_vm._l(_vm.actorsNodes.users, function (user) {
    return _c('EmployeeCard', {
      key: user.id,
      attrs: {
        "unit-user": user,
        "type": "system"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteLink(_vm.actorsNodes.actorNodeId[user.id]);
        }
      }
    });
  }), _vm._v(" "), _vm._l(_vm.actorsNodes.units, function (unit) {
    return _c('UnitCard', {
      key: unit.id,
      attrs: {
        "unit-info": unit
      },
      on: {
        "click": function click($event) {
          return _vm.deleteLink(_vm.actorsNodes.actorNodeId[unit.id]);
        }
      }
    });
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }