



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User } from '@/model/User'
import { NodeTemplate } from '@/tools/template_builder/viewData'

////////////////////////////////////////////////////////////////
////                     ///////////////////////////////////////
////  Description Props  ///////////////////////////////////////
////                     ///////////////////////////////////////
////////////////////////////////////////////////////////////////

// Cursor: показывает для node значение cursor: 'pointer' or 'default'

/**
 * @prop {'' | 'selected_for_rejection' | 'selected_for_acceptance' | 'selected_as_rejection_target'} selected
 *
 * 'selected_for_acceptance' - выделен для того, чтобы согласовать данным пользователем
 * 'selected_for_rejection' - выделен для того, чтобы отклонить данным пользователем
 * 'selected_as_rejection_target' -  выделены как цель для отклонения согласования
 * '' -  для всех nodes, которые не участвуют в данном цикле согласования
 * */
@Component<DWF_ApprovalNode>({})

export default class DWF_ApprovalNode extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) node!: NodeTemplate
    @Prop({ default: false }) cursor!: boolean
    @Prop({ default: '' }) selected!: '' | 'selected_for_rejection' | 'selected_for_acceptance' | 'selected_as_rejection_target'

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible

    get actor(): string {
        // Если тип "root" - то это приминимо только к автору, автором не может быть подразделение,
        // только пользователь
        if (this.node.viewData.root) {
            if (!this.node.actor?.user) {
                return ''
            }

            return `${this.node.actor.user.name.shortName} (автор)`
        }

        if (this.node.special && !this.node.actor?.unit && !this.node.actor?.user) {
            return `${this.node.special.name}: Необходимо выбрать`
        }

        let actor = ''
        if (this.node.special) {
            actor = this.node.special.name
        }

        if (this.node.actor?.unit) {
            if (actor) {
                actor = `${actor}: ${this.node.actor.unit.name}`
            } else {
                actor = this.node.actor.unit.name
            }
        }
        if (this.node.actor?.user) {
            if (actor) {
                actor = `${actor}: ${this.node.actor.user.name.shortName}`
            } else {
                actor = this.node.actor.user.name.shortName
            }
        }
        if (this.node.autoAccept) {
            actor = `${actor}: автоматически`
        }

        return actor || 'Обратитесь в техподдержку!'
    }
}
