















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { News } from '@/store/user_options/news'

@Component<HelpCard>({})

export default class HelpCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) news!: News
    @Prop({ default: false }) read!: boolean
    @Prop({ default: false }) help!: boolean
    @Prop({ default: '' }) title!: string

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose(): void {
        // Do nothing
    }
}
