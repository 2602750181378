export default function ({ state, commit }, { numerator, modificationTime }) {
    let existingNumModTime = state.numModificationTime[numerator.id]
    let ok = true

    if (existingNumModTime && Number(modificationTime) <= Number(existingNumModTime)) {
        ok = false
    }

    if (ok) {
        commit('putNumerator', {
            numerator,
            modificationTime
        })
    }
}
