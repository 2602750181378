var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "privacy-policy",
    class: {
      'privacy-policy_notAuth': _vm.notAuth
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.policy)
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }