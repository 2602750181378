














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class SideBarItem extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) icon!: string
    @Prop({ default: false }) active!: boolean
    @Prop({ default: '' }) event!: string | number

    // *************************************************************
    // COMPUTED
    get pending(): string {
        if (typeof this.event === 'number') {
            return this.event > 100 ? '99+' : String(this.event)
        }
        return this.event
    }
}
