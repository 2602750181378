import { newUserInfo, newUnitInfo } from '@/model/convert/newActor.js'

import {
    FamiliarizationEvent,
    FamiliarizationEventStatusTransition,
    FamiliarizationEventFamiliarize,
    FamiliarizationEventNewMemebers
} from '@/model/familiarization.js'

export default function newFamiliarizationEvent(data) {
    let fields = {}

    if (data.Type === 'status_transition') {
        fields = new FamiliarizationEventStatusTransition(
            data.StatusTransition.Previous,
            data.StatusTransition.Current
        )
    } else if (data.Type === 'familiarize') {
        fields = new FamiliarizationEventFamiliarize(
            newUserInfo(data.Familiarize.Actor)
        )
    } else if (data.Type === 'new_members') {
        console.log('data', data)
        fields = new FamiliarizationEventNewMemebers(
            data.NewMembers.Users.map(user => newUserInfo(user)),
            data.NewMembers.Units.map(unit => newUnitInfo(unit))
        )
    } else {
        console.error('newFamiliarizationEvent - unknown type!!!', data)
    }

    let actor = undefined
    if (data.Author) {
        actor = newUserInfo(data.Author)
    }

    return new FamiliarizationEvent(
        data.ID,
        data.Type,
        data.FamiliarizationID,
        actor,
        data.TS,
        fields
    )
}
