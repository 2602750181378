








































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User, Role } from '@/model/User'
import { Unit } from '@/model/Unit'

import { rolesDisplayNames } from '@/model/User'

import FormInput from '@/components/common/inputs/FormInput.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'

@Component({
    components: {
        FormInput,
        RadioButton
    }
})

export default class SelectRoleUnit extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) typeId!: string
    @Prop({ default: false }) demo!: boolean
    @Prop({ default: () => []}) rolesList!: Array<string>
    @Prop({ default: '' }) centerId!: string
    @Prop({ default: false }) isIncludesCenters!: boolean

    // *************************************************************
    // DATA PARAMS
    checkOriginUnit = ''
    title = ''

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get rolesWidthNames(): Array<{ name: string, displayName: string }> {
        return this.rolesList.map((roleName: string) => {
            return {
                name: roleName,
                displayName: rolesDisplayNames[roleName]
            }
        })
    }

    get roles(): Array<Role> {
        const addedRoles: { [unitId: string]: boolean} = {}

        return this.getMe.roles.filter((role: Role) => {
            if (role.unit === 'root') {
                return false
            }

            if (!this.rolesList.includes(role.name)) {
                return false
            }

            const unit = this.getUnitById(role.unit)
            if (!unit) {
                return false
            }

            if (this.centerId && unit.centerId !== this.centerId) {
                return false
            }

            if (!this.isIncludesCenters && unit.parent === 'root') {
                return false
            }

            if (addedRoles[role.unit]) {
                return false
            }

            addedRoles[role.unit] = true
            return true
        })
    }

    // *************************************************************
    // WATCH
    @Watch('roles', { immediate: true, deep: true })
    handleRolesChange(): void {
        this.setDefaultValue()
    }

    @Watch('checkOriginUnit', { immediate: true })
    handleOriginUnitChange(unitId: string): void {
        this.emitChangeOriginUnit(unitId)
    }

    setDefaultValue(): void {
        if (!this.roles.length) {
            this.checkOriginUnit = 'empty'
            this.emitOneUnitWithRoles(true)
            return
        }
        if (this.roles.length === 1) {
            // Если у user роли всего в одном подразделении, то автоматически делаем за него выбор checkOriginUnit
            this.checkOriginUnit = this.roles[0].unit
            // Прокидываем наверх событие, которое показывает, что у пользователя роль/роли
            // только в одном подразделении
            // Это необходимо в компоненте HelpDocumentCreation
            // чтобы знать, какую информацию показывать пользователю
            this.emitOneUnitWithRoles(true)
            return
        }
        this.checkOriginUnit = ''
        this.emitOneUnitWithRoles(false)
    }

    // *************************************************************
    // EMIT
    @Emit('changeOriginUnit')
    emitChangeOriginUnit(unitId: string): string {
        return unitId
    }

    @Emit('oneUnitWithRoles')
    emitOneUnitWithRoles(val: boolean): boolean {
        return val
    }
}
