


























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

import { TaskEvent } from '@/model/Task'

import Checkbox from '@/components/common/inputs/Checkbox.vue'
import Textarea from '@/components/common/Textarea.vue'
import ChatCardSelector from '@/components/common/chat/ChatCardSelector.vue'

@Component({
    components: {
        Textarea,
        Checkbox,
        ChatCardSelector
    }
})

export default class TaskChat extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) events!: Array<TaskEvent>
    @Prop({ default: () => []}) eventFieldTypes!: { [key: string]: { name: string }}

    // *************************************************************
    // DATA PARAMS
    message = ''
    checkEvents = true
    checkMessages = true

    // *************************************************************
    // METHODS
    spendMessage(): void {
        this.emitSpendMessage()
        this.message = ''
    }

    onCheckMessages(checkMessages: boolean): void {
        console.log('eventFieldTypes', this.eventFieldTypes)
        this.emitSwitchMessages()

        // Если выключен,то автоматически включаем другую галочку
        if (!checkMessages) {
            this.checkEvents = true
            this.emitSwitchEvents()
        }
    }

    onCheckEvents(checkEvents: boolean): void {
        this.emitSwitchEvents()

        // Если выключен,то автоматически включаем другую галочку
        if (!checkEvents) {
            this.checkMessages = true
            this.emitSwitchMessages()
        }
    }

    // *************************************************************
    // EMIT
    @Emit('spendMessage')
    emitSpendMessage(): string {
        return this.message
    }

    @Emit('switchMessages')
    emitSwitchMessages(): boolean {
        return this.checkMessages
    }

    @Emit('switchEvents')
    emitSwitchEvents(): boolean {
        return this.checkEvents
    }
}
