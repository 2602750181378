/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.api.themakers.sc.ActiveFamiliarizationReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Actor', null, global);
goog.exportSymbol('proto.api.themakers.sc.ActorElementIDPair', null, global);
goog.exportSymbol('proto.api.themakers.sc.ActorIDs', null, global);
goog.exportSymbol('proto.api.themakers.sc.AddActorToWorkflowApprovalReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.AddDirectoryFieldReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.AddDocumentTypeRequisiteReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.AddEmployeeToUnitReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.AddMembersReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.AddRecordReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.AddWorkflowTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.ApprovalTemplate', null, global);
goog.exportSymbol('proto.api.themakers.sc.ArchiveFamiliarizationReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage', null, global);
goog.exportSymbol('proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.ArchivedListStreamMessage', null, global);
goog.exportSymbol('proto.api.themakers.sc.ArchivedListStreamReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.ArchivedTasksStreamMessage', null, global);
goog.exportSymbol('proto.api.themakers.sc.ArchivedTasksStreamReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.CloseTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.CompleteTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.ConfirmTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.CreateDirectoryReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.CreateDocumentTypeReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.CreateFamiliarizationReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.CreateNumeratorReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.CreateTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.CreateUnitReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.DelegateTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.DeletetionEvent', null, global);
goog.exportSymbol('proto.api.themakers.sc.Directory', null, global);
goog.exportSymbol('proto.api.themakers.sc.DirectoryField', null, global);
goog.exportSymbol('proto.api.themakers.sc.Document', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentArchiveReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentBase', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentDraftReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentEvent', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentLink', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentListStreamMessage', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentOneAss', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentOneAssAuthor', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentPutMessageReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentRegisterReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentRequisite', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentStartReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentStreamMessage', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentStreamReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentType', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentTypeOneAss', null, global);
goog.exportSymbol('proto.api.themakers.sc.DocumentTypeRequisite', null, global);
goog.exportSymbol('proto.api.themakers.sc.DraftFamiliarizationReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Dummy', null, global);
goog.exportSymbol('proto.api.themakers.sc.Event', null, global);
goog.exportSymbol('proto.api.themakers.sc.EventStreamReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Familiarization', null, global);
goog.exportSymbol('proto.api.themakers.sc.FamiliarizationDocument', null, global);
goog.exportSymbol('proto.api.themakers.sc.FamiliarizationEvent', null, global);
goog.exportSymbol('proto.api.themakers.sc.FamiliarizationLog', null, global);
goog.exportSymbol('proto.api.themakers.sc.FamiliarizationStreamMessage', null, global);
goog.exportSymbol('proto.api.themakers.sc.FamiliarizationStreamReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Familiarize', null, global);
goog.exportSymbol('proto.api.themakers.sc.FamiliarizeReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.File', null, global);
goog.exportSymbol('proto.api.themakers.sc.GetAllByTypeReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.GetNumeratorReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.GetTosserReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.GetUserReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.GetUsersByRolesReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.GetWorkflowTemplateReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.LinkedTask', null, global);
goog.exportSymbol('proto.api.themakers.sc.ListStreamReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.LoginAsReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.LoginAsResp', null, global);
goog.exportSymbol('proto.api.themakers.sc.MarkAsRootReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Message', null, global);
goog.exportSymbol('proto.api.themakers.sc.MoveUnitReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.NewDocumentReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.NewMembers', null, global);
goog.exportSymbol('proto.api.themakers.sc.Numerator', null, global);
goog.exportSymbol('proto.api.themakers.sc.NumeratorCodeField', null, global);
goog.exportSymbol('proto.api.themakers.sc.NumeratorField', null, global);
goog.exportSymbol('proto.api.themakers.sc.NumeratorIncrField', null, global);
goog.exportSymbol('proto.api.themakers.sc.NumeratorTextField', null, global);
goog.exportSymbol('proto.api.themakers.sc.OneAssQueryAndWatchMsg', null, global);
goog.exportSymbol('proto.api.themakers.sc.OneAssQueryAndWatchReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.OneAssRecordEvent', null, global);
goog.exportSymbol('proto.api.themakers.sc.OptionRequisiteUpdated', null, global);
goog.exportSymbol('proto.api.themakers.sc.Ping', null, global);
goog.exportSymbol('proto.api.themakers.sc.RearangeRequisitesReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Record', null, global);
goog.exportSymbol('proto.api.themakers.sc.RecordField', null, global);
goog.exportSymbol('proto.api.themakers.sc.Registration', null, global);
goog.exportSymbol('proto.api.themakers.sc.RejectTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Rejected', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveDirectoryFieldReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveDirectoryReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveDocumentReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveDocumentTypeRequisite', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveEmployeeFromUnitReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveFamiliarizationReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveNumeratorFieldReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveNumeratorReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveRecordReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveUnitReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RemoveWorkflowTaskReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.ReopenReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.RetireReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Role', null, global);
goog.exportSymbol('proto.api.themakers.sc.RolesOp', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetCodeReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetDescriptionReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetDirectoryArchivedReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetDirectoryDisplayNameReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetDirectoryFieldNameReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetDirectoryFieldRequiredReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetDirectoryNameReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetLinksReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetNameReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetNumeratorReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetNumerator_DocumentTypeReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetRecordArchivedReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetTosserReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetUnitHeadReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetUserEmailReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetUserNameReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetUserPhoneReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.SetWorkflowTemplateReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.StatusTransition', null, global);
goog.exportSymbol('proto.api.themakers.sc.Substitute', null, global);
goog.exportSymbol('proto.api.themakers.sc.Task', null, global);
goog.exportSymbol('proto.api.themakers.sc.TaskDocument', null, global);
goog.exportSymbol('proto.api.themakers.sc.TaskEvent', null, global);
goog.exportSymbol('proto.api.themakers.sc.TaskPutMessageReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.TaskStreamMessage', null, global);
goog.exportSymbol('proto.api.themakers.sc.TaskStreamReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.TaskTemplate', null, global);
goog.exportSymbol('proto.api.themakers.sc.Timex', null, global);
goog.exportSymbol('proto.api.themakers.sc.TosserRule', null, global);
goog.exportSymbol('proto.api.themakers.sc.TosserRuleTerm', null, global);
goog.exportSymbol('proto.api.themakers.sc.Unit', null, global);
goog.exportSymbol('proto.api.themakers.sc.UnitInfo', null, global);
goog.exportSymbol('proto.api.themakers.sc.UnitOneAss', null, global);
goog.exportSymbol('proto.api.themakers.sc.UnitTosser', null, global);
goog.exportSymbol('proto.api.themakers.sc.UpdateDocumentCommentReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.UpdateDocumentNameReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.UpdateDocumentRequisiteReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.UpdateFamiliarizationReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.UpdateRecordFieldsReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.User', null, global);
goog.exportSymbol('proto.api.themakers.sc.UserEvent', null, global);
goog.exportSymbol('proto.api.themakers.sc.UserInfo', null, global);
goog.exportSymbol('proto.api.themakers.sc.UserName', null, global);
goog.exportSymbol('proto.api.themakers.sc.UserOneAss', null, global);
goog.exportSymbol('proto.api.themakers.sc.Workflow', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowApproval', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowElement', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowElementSpecial', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowEvent', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowEventApproval', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowEventDocument', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowEventTask', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowStage', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowTask', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowTemplate', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowTemplateStage', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowTemplateStageApprovals', null, global);
goog.exportSymbol('proto.api.themakers.sc.WorkflowTemplateStageTasks', null, global);
goog.exportSymbol('proto.api.themakers.sc.Workflow_Approval_AcceptReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Workflow_Approval_DropToReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Workflow_Task_CompleteReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Workflow_Task_ConfirmReq', null, global);
goog.exportSymbol('proto.api.themakers.sc.Workflow_Task_RejectReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UserEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.UserEvent.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.UserEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UserEvent.displayName = 'proto.api.themakers.sc.UserEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.Event.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Event.displayName = 'proto.api.themakers.sc.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DeletetionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.DeletetionEvent.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.DeletetionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DeletetionEvent.displayName = 'proto.api.themakers.sc.DeletetionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.EventStreamReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.EventStreamReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.EventStreamReq.displayName = 'proto.api.themakers.sc.EventStreamReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RetireReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.RetireReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.RetireReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RetireReq.displayName = 'proto.api.themakers.sc.RetireReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Substitute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Substitute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Substitute.displayName = 'proto.api.themakers.sc.Substitute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.User.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.User.displayName = 'proto.api.themakers.sc.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UserName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.UserName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UserName.displayName = 'proto.api.themakers.sc.UserName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UserOneAss = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.UserOneAss.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.UserOneAss, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UserOneAss.displayName = 'proto.api.themakers.sc.UserOneAss';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Role.displayName = 'proto.api.themakers.sc.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RolesOp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.RolesOp.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.RolesOp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RolesOp.displayName = 'proto.api.themakers.sc.RolesOp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetUserNameReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetUserNameReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetUserNameReq.displayName = 'proto.api.themakers.sc.SetUserNameReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.GetUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.GetUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.GetUserReq.displayName = 'proto.api.themakers.sc.GetUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetUserEmailReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetUserEmailReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetUserEmailReq.displayName = 'proto.api.themakers.sc.SetUserEmailReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetUserPhoneReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetUserPhoneReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetUserPhoneReq.displayName = 'proto.api.themakers.sc.SetUserPhoneReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.GetUsersByRolesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.GetUsersByRolesReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.GetUsersByRolesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.GetUsersByRolesReq.displayName = 'proto.api.themakers.sc.GetUsersByRolesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.DocumentType.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.DocumentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentType.displayName = 'proto.api.themakers.sc.DocumentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.CreateDocumentTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.CreateDocumentTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.CreateDocumentTypeReq.displayName = 'proto.api.themakers.sc.CreateDocumentTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentTypeOneAss = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentTypeOneAss, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentTypeOneAss.displayName = 'proto.api.themakers.sc.DocumentTypeOneAss';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetNumerator_DocumentTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetNumerator_DocumentTypeReq.displayName = 'proto.api.themakers.sc.SetNumerator_DocumentTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentTypeRequisite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentTypeRequisite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentTypeRequisite.displayName = 'proto.api.themakers.sc.DocumentTypeRequisite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RearangeRequisitesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.RearangeRequisitesReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.RearangeRequisitesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RearangeRequisitesReq.displayName = 'proto.api.themakers.sc.RearangeRequisitesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.AddDocumentTypeRequisiteReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.AddDocumentTypeRequisiteReq.displayName = 'proto.api.themakers.sc.AddDocumentTypeRequisiteReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.displayName = 'proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveDocumentTypeRequisite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveDocumentTypeRequisite.displayName = 'proto.api.themakers.sc.RemoveDocumentTypeRequisite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentStreamReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentStreamReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentStreamReq.displayName = 'proto.api.themakers.sc.DocumentStreamReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentStreamMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.DocumentStreamMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentStreamMessage.displayName = 'proto.api.themakers.sc.DocumentStreamMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ArchivedListStreamReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ArchivedListStreamReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ArchivedListStreamReq.displayName = 'proto.api.themakers.sc.ArchivedListStreamReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ArchivedListStreamMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.ArchivedListStreamMessage.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.ArchivedListStreamMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ArchivedListStreamMessage.displayName = 'proto.api.themakers.sc.ArchivedListStreamMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.DocumentEvent.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.DocumentEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentEvent.displayName = 'proto.api.themakers.sc.DocumentEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Message.displayName = 'proto.api.themakers.sc.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.StatusTransition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.StatusTransition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.StatusTransition.displayName = 'proto.api.themakers.sc.StatusTransition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Registration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Registration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Registration.displayName = 'proto.api.themakers.sc.Registration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ActorElementIDPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ActorElementIDPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ActorElementIDPair.displayName = 'proto.api.themakers.sc.ActorElementIDPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Rejected = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Rejected.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Rejected, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Rejected.displayName = 'proto.api.themakers.sc.Rejected';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.OptionRequisiteUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.OptionRequisiteUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.OptionRequisiteUpdated.displayName = 'proto.api.themakers.sc.OptionRequisiteUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.LinkedTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.LinkedTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.LinkedTask.displayName = 'proto.api.themakers.sc.LinkedTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ListStreamReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ListStreamReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ListStreamReq.displayName = 'proto.api.themakers.sc.ListStreamReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentListStreamMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.DocumentListStreamMessage.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.DocumentListStreamMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentListStreamMessage.displayName = 'proto.api.themakers.sc.DocumentListStreamMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Document.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Document.displayName = 'proto.api.themakers.sc.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentDraftReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentDraftReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentDraftReq.displayName = 'proto.api.themakers.sc.DocumentDraftReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentArchiveReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentArchiveReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentArchiveReq.displayName = 'proto.api.themakers.sc.DocumentArchiveReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentStartReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentStartReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentStartReq.displayName = 'proto.api.themakers.sc.DocumentStartReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentOneAss = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentOneAss, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentOneAss.displayName = 'proto.api.themakers.sc.DocumentOneAss';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentOneAssAuthor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentOneAssAuthor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentOneAssAuthor.displayName = 'proto.api.themakers.sc.DocumentOneAssAuthor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.File.displayName = 'proto.api.themakers.sc.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentBase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentBase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentBase.displayName = 'proto.api.themakers.sc.DocumentBase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UserInfo.displayName = 'proto.api.themakers.sc.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentRequisite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentRequisite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentRequisite.displayName = 'proto.api.themakers.sc.DocumentRequisite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.GetAllByTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.GetAllByTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.GetAllByTypeReq.displayName = 'proto.api.themakers.sc.GetAllByTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.NewDocumentReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.NewDocumentReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.NewDocumentReq.displayName = 'proto.api.themakers.sc.NewDocumentReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveDocumentReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveDocumentReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveDocumentReq.displayName = 'proto.api.themakers.sc.RemoveDocumentReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UpdateDocumentNameReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.UpdateDocumentNameReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UpdateDocumentNameReq.displayName = 'proto.api.themakers.sc.UpdateDocumentNameReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.UpdateDocumentRequisiteReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UpdateDocumentRequisiteReq.displayName = 'proto.api.themakers.sc.UpdateDocumentRequisiteReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UpdateDocumentCommentReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.UpdateDocumentCommentReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UpdateDocumentCommentReq.displayName = 'proto.api.themakers.sc.UpdateDocumentCommentReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentPutMessageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentPutMessageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentPutMessageReq.displayName = 'proto.api.themakers.sc.DocumentPutMessageReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentRegisterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentRegisterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentRegisterReq.displayName = 'proto.api.themakers.sc.DocumentRegisterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetLinksReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.SetLinksReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.SetLinksReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetLinksReq.displayName = 'proto.api.themakers.sc.SetLinksReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DocumentLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DocumentLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DocumentLink.displayName = 'proto.api.themakers.sc.DocumentLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.AddWorkflowTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.AddWorkflowTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.AddWorkflowTaskReq.displayName = 'proto.api.themakers.sc.AddWorkflowTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.AddActorToWorkflowApprovalReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.AddActorToWorkflowApprovalReq.displayName = 'proto.api.themakers.sc.AddActorToWorkflowApprovalReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveWorkflowTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveWorkflowTaskReq.displayName = 'proto.api.themakers.sc.RemoveWorkflowTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Actor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Actor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Actor.displayName = 'proto.api.themakers.sc.Actor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ActorIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ActorIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ActorIDs.displayName = 'proto.api.themakers.sc.ActorIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UnitInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.UnitInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UnitInfo.displayName = 'proto.api.themakers.sc.UnitInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.WorkflowTemplate.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowTemplate.displayName = 'proto.api.themakers.sc.WorkflowTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowTemplateStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowTemplateStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowTemplateStage.displayName = 'proto.api.themakers.sc.WorkflowTemplateStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.WorkflowTemplateStageApprovals.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowTemplateStageApprovals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowTemplateStageApprovals.displayName = 'proto.api.themakers.sc.WorkflowTemplateStageApprovals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ApprovalTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.ApprovalTemplate.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.ApprovalTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ApprovalTemplate.displayName = 'proto.api.themakers.sc.ApprovalTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.WorkflowTemplateStageTasks.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowTemplateStageTasks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowTemplateStageTasks.displayName = 'proto.api.themakers.sc.WorkflowTemplateStageTasks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.TaskTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.TaskTemplate.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.TaskTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.TaskTemplate.displayName = 'proto.api.themakers.sc.TaskTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetWorkflowTemplateReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetWorkflowTemplateReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetWorkflowTemplateReq.displayName = 'proto.api.themakers.sc.SetWorkflowTemplateReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.GetWorkflowTemplateReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.GetWorkflowTemplateReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.GetWorkflowTemplateReq.displayName = 'proto.api.themakers.sc.GetWorkflowTemplateReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Workflow_Approval_AcceptReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Workflow_Approval_AcceptReq.displayName = 'proto.api.themakers.sc.Workflow_Approval_AcceptReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Workflow_Approval_DropToReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Workflow_Approval_DropToReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Workflow_Approval_DropToReq.displayName = 'proto.api.themakers.sc.Workflow_Approval_DropToReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Workflow_Task_CompleteReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Workflow_Task_CompleteReq.displayName = 'proto.api.themakers.sc.Workflow_Task_CompleteReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Workflow_Task_ConfirmReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Workflow_Task_ConfirmReq.displayName = 'proto.api.themakers.sc.Workflow_Task_ConfirmReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Workflow_Task_RejectReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Workflow_Task_RejectReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Workflow_Task_RejectReq.displayName = 'proto.api.themakers.sc.Workflow_Task_RejectReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Workflow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Workflow.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Workflow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Workflow.displayName = 'proto.api.themakers.sc.Workflow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.WorkflowStage.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowStage.displayName = 'proto.api.themakers.sc.WorkflowStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.WorkflowElement.repeatedFields_, proto.api.themakers.sc.WorkflowElement.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.WorkflowElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowElement.displayName = 'proto.api.themakers.sc.WorkflowElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowElementSpecial = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowElementSpecial, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowElementSpecial.displayName = 'proto.api.themakers.sc.WorkflowElementSpecial';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowApproval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowApproval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowApproval.displayName = 'proto.api.themakers.sc.WorkflowApproval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowTask.displayName = 'proto.api.themakers.sc.WorkflowTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.WorkflowEvent.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.WorkflowEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowEvent.displayName = 'proto.api.themakers.sc.WorkflowEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowEventApproval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowEventApproval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowEventApproval.displayName = 'proto.api.themakers.sc.WorkflowEventApproval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowEventTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowEventTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowEventTask.displayName = 'proto.api.themakers.sc.WorkflowEventTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.WorkflowEventDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.WorkflowEventDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.WorkflowEventDocument.displayName = 'proto.api.themakers.sc.WorkflowEventDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DelegateTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DelegateTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DelegateTaskReq.displayName = 'proto.api.themakers.sc.DelegateTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ArchivedTasksStreamReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ArchivedTasksStreamReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ArchivedTasksStreamReq.displayName = 'proto.api.themakers.sc.ArchivedTasksStreamReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.ArchivedTasksStreamMessage.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.ArchivedTasksStreamMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ArchivedTasksStreamMessage.displayName = 'proto.api.themakers.sc.ArchivedTasksStreamMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.TaskStreamReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.TaskStreamReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.TaskStreamReq.displayName = 'proto.api.themakers.sc.TaskStreamReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.TaskEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.TaskEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.TaskEvent.displayName = 'proto.api.themakers.sc.TaskEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.TaskStreamMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.TaskStreamMessage.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.TaskStreamMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.TaskStreamMessage.displayName = 'proto.api.themakers.sc.TaskStreamMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.TaskPutMessageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.TaskPutMessageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.TaskPutMessageReq.displayName = 'proto.api.themakers.sc.TaskPutMessageReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Task.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Task.displayName = 'proto.api.themakers.sc.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.TaskDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.TaskDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.TaskDocument.displayName = 'proto.api.themakers.sc.TaskDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.CreateTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.CreateTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.CreateTaskReq.displayName = 'proto.api.themakers.sc.CreateTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.CompleteTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.CompleteTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.CompleteTaskReq.displayName = 'proto.api.themakers.sc.CompleteTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RejectTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RejectTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RejectTaskReq.displayName = 'proto.api.themakers.sc.RejectTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ConfirmTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ConfirmTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ConfirmTaskReq.displayName = 'proto.api.themakers.sc.ConfirmTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ReopenReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ReopenReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ReopenReq.displayName = 'proto.api.themakers.sc.ReopenReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.CloseTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.CloseTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.CloseTaskReq.displayName = 'proto.api.themakers.sc.CloseTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveTaskReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveTaskReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveTaskReq.displayName = 'proto.api.themakers.sc.RemoveTaskReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Unit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Unit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Unit.displayName = 'proto.api.themakers.sc.Unit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UnitOneAss = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.UnitOneAss.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.UnitOneAss, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UnitOneAss.displayName = 'proto.api.themakers.sc.UnitOneAss';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.CreateUnitReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.CreateUnitReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.CreateUnitReq.displayName = 'proto.api.themakers.sc.CreateUnitReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveUnitReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveUnitReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveUnitReq.displayName = 'proto.api.themakers.sc.RemoveUnitReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.GetTosserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.GetTosserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.GetTosserReq.displayName = 'proto.api.themakers.sc.GetTosserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UnitTosser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.UnitTosser.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.UnitTosser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UnitTosser.displayName = 'proto.api.themakers.sc.UnitTosser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.TosserRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.TosserRule.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.TosserRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.TosserRule.displayName = 'proto.api.themakers.sc.TosserRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.TosserRuleTerm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.TosserRuleTerm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.TosserRuleTerm.displayName = 'proto.api.themakers.sc.TosserRuleTerm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetTosserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetTosserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetTosserReq.displayName = 'proto.api.themakers.sc.SetTosserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.FamiliarizationStreamReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.FamiliarizationStreamReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.FamiliarizationStreamReq.displayName = 'proto.api.themakers.sc.FamiliarizationStreamReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.displayName = 'proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.displayName = 'proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.FamiliarizationStreamMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.FamiliarizationStreamMessage.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.FamiliarizationStreamMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.FamiliarizationStreamMessage.displayName = 'proto.api.themakers.sc.FamiliarizationStreamMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.FamiliarizationEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.FamiliarizationEvent.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.FamiliarizationEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.FamiliarizationEvent.displayName = 'proto.api.themakers.sc.FamiliarizationEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Familiarize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Familiarize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Familiarize.displayName = 'proto.api.themakers.sc.Familiarize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.NewMembers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.NewMembers.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.NewMembers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.NewMembers.displayName = 'proto.api.themakers.sc.NewMembers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Familiarization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Familiarization.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Familiarization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Familiarization.displayName = 'proto.api.themakers.sc.Familiarization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.FamiliarizationDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.FamiliarizationDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.FamiliarizationDocument.displayName = 'proto.api.themakers.sc.FamiliarizationDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ActiveFamiliarizationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ActiveFamiliarizationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ActiveFamiliarizationReq.displayName = 'proto.api.themakers.sc.ActiveFamiliarizationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DraftFamiliarizationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DraftFamiliarizationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DraftFamiliarizationReq.displayName = 'proto.api.themakers.sc.DraftFamiliarizationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.ArchiveFamiliarizationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.ArchiveFamiliarizationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.ArchiveFamiliarizationReq.displayName = 'proto.api.themakers.sc.ArchiveFamiliarizationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.CreateFamiliarizationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.CreateFamiliarizationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.CreateFamiliarizationReq.displayName = 'proto.api.themakers.sc.CreateFamiliarizationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UpdateFamiliarizationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.UpdateFamiliarizationReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.UpdateFamiliarizationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UpdateFamiliarizationReq.displayName = 'proto.api.themakers.sc.UpdateFamiliarizationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.AddMembersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.AddMembersReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.AddMembersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.AddMembersReq.displayName = 'proto.api.themakers.sc.AddMembersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.FamiliarizationLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.FamiliarizationLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.FamiliarizationLog.displayName = 'proto.api.themakers.sc.FamiliarizationLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.FamiliarizeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.FamiliarizeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.FamiliarizeReq.displayName = 'proto.api.themakers.sc.FamiliarizeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveFamiliarizationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveFamiliarizationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveFamiliarizationReq.displayName = 'proto.api.themakers.sc.RemoveFamiliarizationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.CreateDirectoryReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.CreateDirectoryReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.CreateDirectoryReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.CreateDirectoryReq.displayName = 'proto.api.themakers.sc.CreateDirectoryReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveDirectoryReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveDirectoryReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveDirectoryReq.displayName = 'proto.api.themakers.sc.RemoveDirectoryReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetDirectoryNameReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetDirectoryNameReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetDirectoryNameReq.displayName = 'proto.api.themakers.sc.SetDirectoryNameReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetDirectoryDisplayNameReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetDirectoryDisplayNameReq.displayName = 'proto.api.themakers.sc.SetDirectoryDisplayNameReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.AddDirectoryFieldReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.AddDirectoryFieldReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.AddDirectoryFieldReq.displayName = 'proto.api.themakers.sc.AddDirectoryFieldReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveDirectoryFieldReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveDirectoryFieldReq.displayName = 'proto.api.themakers.sc.RemoveDirectoryFieldReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetDirectoryFieldNameReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetDirectoryFieldNameReq.displayName = 'proto.api.themakers.sc.SetDirectoryFieldNameReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetDirectoryFieldRequiredReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetDirectoryFieldRequiredReq.displayName = 'proto.api.themakers.sc.SetDirectoryFieldRequiredReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetDirectoryArchivedReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetDirectoryArchivedReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetDirectoryArchivedReq.displayName = 'proto.api.themakers.sc.SetDirectoryArchivedReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.AddRecordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.AddRecordReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.AddRecordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.AddRecordReq.displayName = 'proto.api.themakers.sc.AddRecordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveRecordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveRecordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveRecordReq.displayName = 'proto.api.themakers.sc.RemoveRecordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetRecordArchivedReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetRecordArchivedReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetRecordArchivedReq.displayName = 'proto.api.themakers.sc.SetRecordArchivedReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.UpdateRecordFieldsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.UpdateRecordFieldsReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.UpdateRecordFieldsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.UpdateRecordFieldsReq.displayName = 'proto.api.themakers.sc.UpdateRecordFieldsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Directory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Directory.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Directory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Directory.displayName = 'proto.api.themakers.sc.Directory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.DirectoryField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.DirectoryField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.DirectoryField.displayName = 'proto.api.themakers.sc.DirectoryField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Record = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Record.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Record, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Record.displayName = 'proto.api.themakers.sc.Record';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RecordField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RecordField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RecordField.displayName = 'proto.api.themakers.sc.RecordField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Timex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Timex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Timex.displayName = 'proto.api.themakers.sc.Timex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.MarkAsRootReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.MarkAsRootReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.MarkAsRootReq.displayName = 'proto.api.themakers.sc.MarkAsRootReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.MoveUnitReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.MoveUnitReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.MoveUnitReq.displayName = 'proto.api.themakers.sc.MoveUnitReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetUnitHeadReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetUnitHeadReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetUnitHeadReq.displayName = 'proto.api.themakers.sc.SetUnitHeadReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.AddEmployeeToUnitReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.AddEmployeeToUnitReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.AddEmployeeToUnitReq.displayName = 'proto.api.themakers.sc.AddEmployeeToUnitReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveEmployeeFromUnitReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveEmployeeFromUnitReq.displayName = 'proto.api.themakers.sc.RemoveEmployeeFromUnitReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.OneAssQueryAndWatchReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.OneAssQueryAndWatchReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.OneAssQueryAndWatchReq.displayName = 'proto.api.themakers.sc.OneAssQueryAndWatchReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.OneAssQueryAndWatchMsg.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.OneAssQueryAndWatchMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.OneAssQueryAndWatchMsg.displayName = 'proto.api.themakers.sc.OneAssQueryAndWatchMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.OneAssRecordEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.themakers.sc.OneAssRecordEvent.oneofGroups_);
};
goog.inherits(proto.api.themakers.sc.OneAssRecordEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.OneAssRecordEvent.displayName = 'proto.api.themakers.sc.OneAssRecordEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.LoginAsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.LoginAsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.LoginAsReq.displayName = 'proto.api.themakers.sc.LoginAsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.LoginAsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.LoginAsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.LoginAsResp.displayName = 'proto.api.themakers.sc.LoginAsResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Dummy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Dummy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Dummy.displayName = 'proto.api.themakers.sc.Dummy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveReq.displayName = 'proto.api.themakers.sc.RemoveReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetDescriptionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetDescriptionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetDescriptionReq.displayName = 'proto.api.themakers.sc.SetDescriptionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetNameReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetNameReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetNameReq.displayName = 'proto.api.themakers.sc.SetNameReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetCodeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.SetCodeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetCodeReq.displayName = 'proto.api.themakers.sc.SetCodeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Numerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.Numerator.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.Numerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Numerator.displayName = 'proto.api.themakers.sc.Numerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.NumeratorField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.NumeratorField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.NumeratorField.displayName = 'proto.api.themakers.sc.NumeratorField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.SetNumeratorReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.SetNumeratorReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.SetNumeratorReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.SetNumeratorReq.displayName = 'proto.api.themakers.sc.SetNumeratorReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.CreateNumeratorReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.themakers.sc.CreateNumeratorReq.repeatedFields_, null);
};
goog.inherits(proto.api.themakers.sc.CreateNumeratorReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.CreateNumeratorReq.displayName = 'proto.api.themakers.sc.CreateNumeratorReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.GetNumeratorReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.GetNumeratorReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.GetNumeratorReq.displayName = 'proto.api.themakers.sc.GetNumeratorReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveNumeratorReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveNumeratorReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveNumeratorReq.displayName = 'proto.api.themakers.sc.RemoveNumeratorReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.RemoveNumeratorFieldReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.RemoveNumeratorFieldReq.displayName = 'proto.api.themakers.sc.RemoveNumeratorFieldReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.NumeratorCodeField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.NumeratorCodeField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.NumeratorCodeField.displayName = 'proto.api.themakers.sc.NumeratorCodeField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.NumeratorTextField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.NumeratorTextField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.NumeratorTextField.displayName = 'proto.api.themakers.sc.NumeratorTextField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.NumeratorIncrField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.NumeratorIncrField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.NumeratorIncrField.displayName = 'proto.api.themakers.sc.NumeratorIncrField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.themakers.sc.Ping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.themakers.sc.Ping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.themakers.sc.Ping.displayName = 'proto.api.themakers.sc.Ping';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.UserEvent.oneofGroups_ = [[10,11,12,13,14,15,100,101]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.UserEvent.UsereventCase = {
  USEREVENT_NOT_SET: 0,
  ME: 10,
  HISTORICAL: 11,
  CREATED: 12,
  UPDATED: 13,
  DELETED: 14,
  ENDOFHISTORICALENTITIES: 15,
  ROLESCHANGE: 100,
  PING: 101
};

/**
 * @return {proto.api.themakers.sc.UserEvent.UsereventCase}
 */
proto.api.themakers.sc.UserEvent.prototype.getUsereventCase = function() {
  return /** @type {proto.api.themakers.sc.UserEvent.UsereventCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.UserEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UserEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UserEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UserEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UserEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    me: (f = msg.getMe()) && proto.api.themakers.sc.User.toObject(includeInstance, f),
    historical: (f = msg.getHistorical()) && proto.api.themakers.sc.Event.toObject(includeInstance, f),
    created: (f = msg.getCreated()) && proto.api.themakers.sc.Event.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && proto.api.themakers.sc.Event.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && proto.api.themakers.sc.DeletetionEvent.toObject(includeInstance, f),
    endofhistoricalentities: (f = msg.getEndofhistoricalentities()) && proto.api.themakers.sc.Dummy.toObject(includeInstance, f),
    roleschange: jspb.Message.getFieldWithDefault(msg, 100, false),
    ping: (f = msg.getPing()) && proto.api.themakers.sc.Ping.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UserEvent}
 */
proto.api.themakers.sc.UserEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UserEvent;
  return proto.api.themakers.sc.UserEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UserEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UserEvent}
 */
proto.api.themakers.sc.UserEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventid(value);
      break;
    case 10:
      var value = new proto.api.themakers.sc.User;
      reader.readMessage(value,proto.api.themakers.sc.User.deserializeBinaryFromReader);
      msg.setMe(value);
      break;
    case 11:
      var value = new proto.api.themakers.sc.Event;
      reader.readMessage(value,proto.api.themakers.sc.Event.deserializeBinaryFromReader);
      msg.setHistorical(value);
      break;
    case 12:
      var value = new proto.api.themakers.sc.Event;
      reader.readMessage(value,proto.api.themakers.sc.Event.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 13:
      var value = new proto.api.themakers.sc.Event;
      reader.readMessage(value,proto.api.themakers.sc.Event.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 14:
      var value = new proto.api.themakers.sc.DeletetionEvent;
      reader.readMessage(value,proto.api.themakers.sc.DeletetionEvent.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    case 15:
      var value = new proto.api.themakers.sc.Dummy;
      reader.readMessage(value,proto.api.themakers.sc.Dummy.deserializeBinaryFromReader);
      msg.setEndofhistoricalentities(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRoleschange(value);
      break;
    case 101:
      var value = new proto.api.themakers.sc.Ping;
      reader.readMessage(value,proto.api.themakers.sc.Ping.deserializeBinaryFromReader);
      msg.setPing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UserEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UserEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UserEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UserEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMe();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.themakers.sc.User.serializeBinaryToWriter
    );
  }
  f = message.getHistorical();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.api.themakers.sc.Event.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.api.themakers.sc.Event.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.api.themakers.sc.Event.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.api.themakers.sc.DeletetionEvent.serializeBinaryToWriter
    );
  }
  f = message.getEndofhistoricalentities();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.api.themakers.sc.Dummy.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 100));
  if (f != null) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.api.themakers.sc.Ping.serializeBinaryToWriter
    );
  }
};


/**
 * optional string EventID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UserEvent.prototype.getEventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UserEvent.prototype.setEventid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User Me = 10;
 * @return {?proto.api.themakers.sc.User}
 */
proto.api.themakers.sc.UserEvent.prototype.getMe = function() {
  return /** @type{?proto.api.themakers.sc.User} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.User, 10));
};


/** @param {?proto.api.themakers.sc.User|undefined} value */
proto.api.themakers.sc.UserEvent.prototype.setMe = function(value) {
  jspb.Message.setOneofWrapperField(this, 10, proto.api.themakers.sc.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.UserEvent.prototype.clearMe = function() {
  this.setMe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.hasMe = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Event Historical = 11;
 * @return {?proto.api.themakers.sc.Event}
 */
proto.api.themakers.sc.UserEvent.prototype.getHistorical = function() {
  return /** @type{?proto.api.themakers.sc.Event} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Event, 11));
};


/** @param {?proto.api.themakers.sc.Event|undefined} value */
proto.api.themakers.sc.UserEvent.prototype.setHistorical = function(value) {
  jspb.Message.setOneofWrapperField(this, 11, proto.api.themakers.sc.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.UserEvent.prototype.clearHistorical = function() {
  this.setHistorical(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.hasHistorical = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Event Created = 12;
 * @return {?proto.api.themakers.sc.Event}
 */
proto.api.themakers.sc.UserEvent.prototype.getCreated = function() {
  return /** @type{?proto.api.themakers.sc.Event} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Event, 12));
};


/** @param {?proto.api.themakers.sc.Event|undefined} value */
proto.api.themakers.sc.UserEvent.prototype.setCreated = function(value) {
  jspb.Message.setOneofWrapperField(this, 12, proto.api.themakers.sc.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.UserEvent.prototype.clearCreated = function() {
  this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Event Updated = 13;
 * @return {?proto.api.themakers.sc.Event}
 */
proto.api.themakers.sc.UserEvent.prototype.getUpdated = function() {
  return /** @type{?proto.api.themakers.sc.Event} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Event, 13));
};


/** @param {?proto.api.themakers.sc.Event|undefined} value */
proto.api.themakers.sc.UserEvent.prototype.setUpdated = function(value) {
  jspb.Message.setOneofWrapperField(this, 13, proto.api.themakers.sc.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.UserEvent.prototype.clearUpdated = function() {
  this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DeletetionEvent Deleted = 14;
 * @return {?proto.api.themakers.sc.DeletetionEvent}
 */
proto.api.themakers.sc.UserEvent.prototype.getDeleted = function() {
  return /** @type{?proto.api.themakers.sc.DeletetionEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DeletetionEvent, 14));
};


/** @param {?proto.api.themakers.sc.DeletetionEvent|undefined} value */
proto.api.themakers.sc.UserEvent.prototype.setDeleted = function(value) {
  jspb.Message.setOneofWrapperField(this, 14, proto.api.themakers.sc.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.UserEvent.prototype.clearDeleted = function() {
  this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Dummy EndOfHistoricalEntities = 15;
 * @return {?proto.api.themakers.sc.Dummy}
 */
proto.api.themakers.sc.UserEvent.prototype.getEndofhistoricalentities = function() {
  return /** @type{?proto.api.themakers.sc.Dummy} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Dummy, 15));
};


/** @param {?proto.api.themakers.sc.Dummy|undefined} value */
proto.api.themakers.sc.UserEvent.prototype.setEndofhistoricalentities = function(value) {
  jspb.Message.setOneofWrapperField(this, 15, proto.api.themakers.sc.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.UserEvent.prototype.clearEndofhistoricalentities = function() {
  this.setEndofhistoricalentities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.hasEndofhistoricalentities = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool RolesChange = 100;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.getRoleschange = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 100, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.UserEvent.prototype.setRoleschange = function(value) {
  jspb.Message.setOneofField(this, 100, proto.api.themakers.sc.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.UserEvent.prototype.clearRoleschange = function() {
  jspb.Message.setOneofField(this, 100, proto.api.themakers.sc.UserEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.hasRoleschange = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional Ping Ping = 101;
 * @return {?proto.api.themakers.sc.Ping}
 */
proto.api.themakers.sc.UserEvent.prototype.getPing = function() {
  return /** @type{?proto.api.themakers.sc.Ping} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Ping, 101));
};


/** @param {?proto.api.themakers.sc.Ping|undefined} value */
proto.api.themakers.sc.UserEvent.prototype.setPing = function(value) {
  jspb.Message.setOneofWrapperField(this, 101, proto.api.themakers.sc.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.UserEvent.prototype.clearPing = function() {
  this.setPing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserEvent.prototype.hasPing = function() {
  return jspb.Message.getField(this, 101) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.Event.oneofGroups_ = [[10,11,12,13,14,15,16,17,18]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.Event.EventCase = {
  EVENT_NOT_SET: 0,
  USER: 10,
  UNIT: 11,
  DOCUMENTTYPE: 12,
  WORKFLOWEVENT: 13,
  TASK: 14,
  DIRECTORY: 15,
  NUMERATOR: 16,
  FAMILIARIZATION: 17,
  RECORD: 18
};

/**
 * @return {proto.api.themakers.sc.Event.EventCase}
 */
proto.api.themakers.sc.Event.prototype.getEventCase = function() {
  return /** @type {proto.api.themakers.sc.Event.EventCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.Event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.api.themakers.sc.User.toObject(includeInstance, f),
    unit: (f = msg.getUnit()) && proto.api.themakers.sc.Unit.toObject(includeInstance, f),
    documenttype: (f = msg.getDocumenttype()) && proto.api.themakers.sc.DocumentType.toObject(includeInstance, f),
    workflowevent: (f = msg.getWorkflowevent()) && proto.api.themakers.sc.WorkflowEvent.toObject(includeInstance, f),
    task: (f = msg.getTask()) && proto.api.themakers.sc.Task.toObject(includeInstance, f),
    directory: (f = msg.getDirectory()) && proto.api.themakers.sc.Directory.toObject(includeInstance, f),
    numerator: (f = msg.getNumerator()) && proto.api.themakers.sc.Numerator.toObject(includeInstance, f),
    familiarization: (f = msg.getFamiliarization()) && proto.api.themakers.sc.Familiarization.toObject(includeInstance, f),
    record: (f = msg.getRecord()) && proto.api.themakers.sc.Record.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Event}
 */
proto.api.themakers.sc.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Event;
  return proto.api.themakers.sc.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Event}
 */
proto.api.themakers.sc.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.api.themakers.sc.User;
      reader.readMessage(value,proto.api.themakers.sc.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 11:
      var value = new proto.api.themakers.sc.Unit;
      reader.readMessage(value,proto.api.themakers.sc.Unit.deserializeBinaryFromReader);
      msg.setUnit(value);
      break;
    case 12:
      var value = new proto.api.themakers.sc.DocumentType;
      reader.readMessage(value,proto.api.themakers.sc.DocumentType.deserializeBinaryFromReader);
      msg.setDocumenttype(value);
      break;
    case 13:
      var value = new proto.api.themakers.sc.WorkflowEvent;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowEvent.deserializeBinaryFromReader);
      msg.setWorkflowevent(value);
      break;
    case 14:
      var value = new proto.api.themakers.sc.Task;
      reader.readMessage(value,proto.api.themakers.sc.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 15:
      var value = new proto.api.themakers.sc.Directory;
      reader.readMessage(value,proto.api.themakers.sc.Directory.deserializeBinaryFromReader);
      msg.setDirectory(value);
      break;
    case 16:
      var value = new proto.api.themakers.sc.Numerator;
      reader.readMessage(value,proto.api.themakers.sc.Numerator.deserializeBinaryFromReader);
      msg.setNumerator(value);
      break;
    case 17:
      var value = new proto.api.themakers.sc.Familiarization;
      reader.readMessage(value,proto.api.themakers.sc.Familiarization.deserializeBinaryFromReader);
      msg.setFamiliarization(value);
      break;
    case 18:
      var value = new proto.api.themakers.sc.Record;
      reader.readMessage(value,proto.api.themakers.sc.Record.deserializeBinaryFromReader);
      msg.setRecord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.themakers.sc.User.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.api.themakers.sc.Unit.serializeBinaryToWriter
    );
  }
  f = message.getDocumenttype();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.api.themakers.sc.DocumentType.serializeBinaryToWriter
    );
  }
  f = message.getWorkflowevent();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.api.themakers.sc.WorkflowEvent.serializeBinaryToWriter
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.api.themakers.sc.Task.serializeBinaryToWriter
    );
  }
  f = message.getDirectory();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.api.themakers.sc.Directory.serializeBinaryToWriter
    );
  }
  f = message.getNumerator();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.api.themakers.sc.Numerator.serializeBinaryToWriter
    );
  }
  f = message.getFamiliarization();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.api.themakers.sc.Familiarization.serializeBinaryToWriter
    );
  }
  f = message.getRecord();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.api.themakers.sc.Record.serializeBinaryToWriter
    );
  }
};


/**
 * optional User User = 10;
 * @return {?proto.api.themakers.sc.User}
 */
proto.api.themakers.sc.Event.prototype.getUser = function() {
  return /** @type{?proto.api.themakers.sc.User} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.User, 10));
};


/** @param {?proto.api.themakers.sc.User|undefined} value */
proto.api.themakers.sc.Event.prototype.setUser = function(value) {
  jspb.Message.setOneofWrapperField(this, 10, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasUser = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Unit Unit = 11;
 * @return {?proto.api.themakers.sc.Unit}
 */
proto.api.themakers.sc.Event.prototype.getUnit = function() {
  return /** @type{?proto.api.themakers.sc.Unit} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Unit, 11));
};


/** @param {?proto.api.themakers.sc.Unit|undefined} value */
proto.api.themakers.sc.Event.prototype.setUnit = function(value) {
  jspb.Message.setOneofWrapperField(this, 11, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearUnit = function() {
  this.setUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DocumentType DocumentType = 12;
 * @return {?proto.api.themakers.sc.DocumentType}
 */
proto.api.themakers.sc.Event.prototype.getDocumenttype = function() {
  return /** @type{?proto.api.themakers.sc.DocumentType} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DocumentType, 12));
};


/** @param {?proto.api.themakers.sc.DocumentType|undefined} value */
proto.api.themakers.sc.Event.prototype.setDocumenttype = function(value) {
  jspb.Message.setOneofWrapperField(this, 12, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearDocumenttype = function() {
  this.setDocumenttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasDocumenttype = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional WorkflowEvent WorkflowEvent = 13;
 * @return {?proto.api.themakers.sc.WorkflowEvent}
 */
proto.api.themakers.sc.Event.prototype.getWorkflowevent = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowEvent, 13));
};


/** @param {?proto.api.themakers.sc.WorkflowEvent|undefined} value */
proto.api.themakers.sc.Event.prototype.setWorkflowevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 13, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearWorkflowevent = function() {
  this.setWorkflowevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasWorkflowevent = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Task Task = 14;
 * @return {?proto.api.themakers.sc.Task}
 */
proto.api.themakers.sc.Event.prototype.getTask = function() {
  return /** @type{?proto.api.themakers.sc.Task} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Task, 14));
};


/** @param {?proto.api.themakers.sc.Task|undefined} value */
proto.api.themakers.sc.Event.prototype.setTask = function(value) {
  jspb.Message.setOneofWrapperField(this, 14, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearTask = function() {
  this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasTask = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Directory Directory = 15;
 * @return {?proto.api.themakers.sc.Directory}
 */
proto.api.themakers.sc.Event.prototype.getDirectory = function() {
  return /** @type{?proto.api.themakers.sc.Directory} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Directory, 15));
};


/** @param {?proto.api.themakers.sc.Directory|undefined} value */
proto.api.themakers.sc.Event.prototype.setDirectory = function(value) {
  jspb.Message.setOneofWrapperField(this, 15, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearDirectory = function() {
  this.setDirectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasDirectory = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Numerator Numerator = 16;
 * @return {?proto.api.themakers.sc.Numerator}
 */
proto.api.themakers.sc.Event.prototype.getNumerator = function() {
  return /** @type{?proto.api.themakers.sc.Numerator} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Numerator, 16));
};


/** @param {?proto.api.themakers.sc.Numerator|undefined} value */
proto.api.themakers.sc.Event.prototype.setNumerator = function(value) {
  jspb.Message.setOneofWrapperField(this, 16, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearNumerator = function() {
  this.setNumerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasNumerator = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Familiarization Familiarization = 17;
 * @return {?proto.api.themakers.sc.Familiarization}
 */
proto.api.themakers.sc.Event.prototype.getFamiliarization = function() {
  return /** @type{?proto.api.themakers.sc.Familiarization} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Familiarization, 17));
};


/** @param {?proto.api.themakers.sc.Familiarization|undefined} value */
proto.api.themakers.sc.Event.prototype.setFamiliarization = function(value) {
  jspb.Message.setOneofWrapperField(this, 17, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearFamiliarization = function() {
  this.setFamiliarization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasFamiliarization = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Record Record = 18;
 * @return {?proto.api.themakers.sc.Record}
 */
proto.api.themakers.sc.Event.prototype.getRecord = function() {
  return /** @type{?proto.api.themakers.sc.Record} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Record, 18));
};


/** @param {?proto.api.themakers.sc.Record|undefined} value */
proto.api.themakers.sc.Event.prototype.setRecord = function(value) {
  jspb.Message.setOneofWrapperField(this, 18, proto.api.themakers.sc.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Event.prototype.clearRecord = function() {
  this.setRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Event.prototype.hasRecord = function() {
  return jspb.Message.getField(this, 18) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.DeletetionEvent.oneofGroups_ = [[20,21,23,24,25,26,27,28,29]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.DeletetionEvent.EventCase = {
  EVENT_NOT_SET: 0,
  USER: 20,
  UNIT: 21,
  DOCUMENTTYPE: 23,
  WORKFLOWEVENT: 24,
  TASK: 25,
  DIRECTORY: 26,
  RECORD: 27,
  NUMERATOR: 28,
  FAMILIARIZATION: 29
};

/**
 * @return {proto.api.themakers.sc.DeletetionEvent.EventCase}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getEventCase = function() {
  return /** @type {proto.api.themakers.sc.DeletetionEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DeletetionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DeletetionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DeletetionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 20, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 21, ""),
    documenttype: jspb.Message.getFieldWithDefault(msg, 23, ""),
    workflowevent: jspb.Message.getFieldWithDefault(msg, 24, ""),
    task: jspb.Message.getFieldWithDefault(msg, 25, ""),
    directory: jspb.Message.getFieldWithDefault(msg, 26, ""),
    record: jspb.Message.getFieldWithDefault(msg, 27, ""),
    numerator: jspb.Message.getFieldWithDefault(msg, 28, ""),
    familiarization: jspb.Message.getFieldWithDefault(msg, 29, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DeletetionEvent}
 */
proto.api.themakers.sc.DeletetionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DeletetionEvent;
  return proto.api.themakers.sc.DeletetionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DeletetionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DeletetionEvent}
 */
proto.api.themakers.sc.DeletetionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumenttype(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkflowevent(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTask(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectory(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecord(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumerator(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamiliarization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DeletetionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DeletetionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DeletetionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeString(
      24,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeString(
      25,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeString(
      26,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeString(
      27,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeString(
      28,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeString(
      29,
      f
    );
  }
};


/**
 * optional string User = 20;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setUser = function(value) {
  jspb.Message.setOneofField(this, 20, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearUser = function() {
  jspb.Message.setOneofField(this, 20, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasUser = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string Unit = 21;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setUnit = function(value) {
  jspb.Message.setOneofField(this, 21, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearUnit = function() {
  jspb.Message.setOneofField(this, 21, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string DocumentType = 23;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getDocumenttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setDocumenttype = function(value) {
  jspb.Message.setOneofField(this, 23, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearDocumenttype = function() {
  jspb.Message.setOneofField(this, 23, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasDocumenttype = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string WorkflowEvent = 24;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getWorkflowevent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setWorkflowevent = function(value) {
  jspb.Message.setOneofField(this, 24, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearWorkflowevent = function() {
  jspb.Message.setOneofField(this, 24, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasWorkflowevent = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string Task = 25;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getTask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setTask = function(value) {
  jspb.Message.setOneofField(this, 25, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearTask = function() {
  jspb.Message.setOneofField(this, 25, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasTask = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string Directory = 26;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getDirectory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setDirectory = function(value) {
  jspb.Message.setOneofField(this, 26, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearDirectory = function() {
  jspb.Message.setOneofField(this, 26, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasDirectory = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string Record = 27;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getRecord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setRecord = function(value) {
  jspb.Message.setOneofField(this, 27, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearRecord = function() {
  jspb.Message.setOneofField(this, 27, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasRecord = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional string Numerator = 28;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getNumerator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setNumerator = function(value) {
  jspb.Message.setOneofField(this, 28, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearNumerator = function() {
  jspb.Message.setOneofField(this, 28, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasNumerator = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string Familiarization = 29;
 * @return {string}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.getFamiliarization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DeletetionEvent.prototype.setFamiliarization = function(value) {
  jspb.Message.setOneofField(this, 29, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DeletetionEvent.prototype.clearFamiliarization = function() {
  jspb.Message.setOneofField(this, 29, proto.api.themakers.sc.DeletetionEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DeletetionEvent.prototype.hasFamiliarization = function() {
  return jspb.Message.getField(this, 29) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.EventStreamReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.EventStreamReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.EventStreamReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.EventStreamReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.EventStreamReq}
 */
proto.api.themakers.sc.EventStreamReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.EventStreamReq;
  return proto.api.themakers.sc.EventStreamReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.EventStreamReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.EventStreamReq}
 */
proto.api.themakers.sc.EventStreamReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.EventStreamReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.EventStreamReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.EventStreamReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.EventStreamReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string EventID = 1;
 * @return {string}
 */
proto.api.themakers.sc.EventStreamReq.prototype.getEventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.EventStreamReq.prototype.setEventid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.RetireReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RetireReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RetireReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RetireReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RetireReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    defaultalternateuserid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    centerid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    substitutionsList: jspb.Message.toObjectList(msg.getSubstitutionsList(),
    proto.api.themakers.sc.Substitute.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RetireReq}
 */
proto.api.themakers.sc.RetireReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RetireReq;
  return proto.api.themakers.sc.RetireReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RetireReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RetireReq}
 */
proto.api.themakers.sc.RetireReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultalternateuserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.Substitute;
      reader.readMessage(value,proto.api.themakers.sc.Substitute.deserializeBinaryFromReader);
      msg.addSubstitutions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RetireReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RetireReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RetireReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RetireReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefaultalternateuserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubstitutionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.themakers.sc.Substitute.serializeBinaryToWriter
    );
  }
};


/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RetireReq.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RetireReq.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DefaultAlternateUserID = 2;
 * @return {string}
 */
proto.api.themakers.sc.RetireReq.prototype.getDefaultalternateuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RetireReq.prototype.setDefaultalternateuserid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CenterID = 3;
 * @return {string}
 */
proto.api.themakers.sc.RetireReq.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RetireReq.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Substitute Substitutions = 4;
 * @return {!Array<!proto.api.themakers.sc.Substitute>}
 */
proto.api.themakers.sc.RetireReq.prototype.getSubstitutionsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.Substitute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.Substitute, 4));
};


/** @param {!Array<!proto.api.themakers.sc.Substitute>} value */
proto.api.themakers.sc.RetireReq.prototype.setSubstitutionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.themakers.sc.Substitute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.Substitute}
 */
proto.api.themakers.sc.RetireReq.prototype.addSubstitutions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.themakers.sc.Substitute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.RetireReq.prototype.clearSubstitutionsList = function() {
  this.setSubstitutionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Substitute.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Substitute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Substitute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Substitute.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Substitute}
 */
proto.api.themakers.sc.Substitute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Substitute;
  return proto.api.themakers.sc.Substitute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Substitute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Substitute}
 */
proto.api.themakers.sc.Substitute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Substitute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Substitute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Substitute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Substitute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string UnitID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Substitute.prototype.getUnitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Substitute.prototype.setUnitid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.Substitute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Substitute.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.api.themakers.sc.Substitute.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Substitute.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.User.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.User.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto.api.themakers.sc.UserName.toObject(includeInstance, f),
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.api.themakers.sc.Role.toObject, includeInstance),
    avatar: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    oneass: (f = msg.getOneass()) && proto.api.themakers.sc.UserOneAss.toObject(includeInstance, f),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.User}
 */
proto.api.themakers.sc.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.User;
  return proto.api.themakers.sc.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.User}
 */
proto.api.themakers.sc.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.UserName;
      reader.readMessage(value,proto.api.themakers.sc.UserName.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.Role;
      reader.readMessage(value,proto.api.themakers.sc.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = new proto.api.themakers.sc.UserOneAss;
      reader.readMessage(value,proto.api.themakers.sc.UserOneAss.deserializeBinaryFromReader);
      msg.setOneass(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.UserName.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.themakers.sc.Role.serializeBinaryToWriter
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOneass();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.themakers.sc.UserOneAss.serializeBinaryToWriter
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.User.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserName Name = 2;
 * @return {?proto.api.themakers.sc.UserName}
 */
proto.api.themakers.sc.User.prototype.getName = function() {
  return /** @type{?proto.api.themakers.sc.UserName} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserName, 2));
};


/** @param {?proto.api.themakers.sc.UserName|undefined} value */
proto.api.themakers.sc.User.prototype.setName = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.User.prototype.clearName = function() {
  this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.User.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Role Roles = 3;
 * @return {!Array<!proto.api.themakers.sc.Role>}
 */
proto.api.themakers.sc.User.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.Role, 3));
};


/** @param {!Array<!proto.api.themakers.sc.Role>} value */
proto.api.themakers.sc.User.prototype.setRolesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.themakers.sc.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.Role}
 */
proto.api.themakers.sc.User.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.themakers.sc.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.User.prototype.clearRolesList = function() {
  this.setRolesList([]);
};


/**
 * optional string Avatar = 4;
 * @return {string}
 */
proto.api.themakers.sc.User.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.User.prototype.setAvatar = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Email = 5;
 * @return {string}
 */
proto.api.themakers.sc.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.User.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Phone = 6;
 * @return {string}
 */
proto.api.themakers.sc.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.api.themakers.sc.User.prototype.setPhone = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional UserOneAss OneAss = 7;
 * @return {?proto.api.themakers.sc.UserOneAss}
 */
proto.api.themakers.sc.User.prototype.getOneass = function() {
  return /** @type{?proto.api.themakers.sc.UserOneAss} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserOneAss, 7));
};


/** @param {?proto.api.themakers.sc.UserOneAss|undefined} value */
proto.api.themakers.sc.User.prototype.setOneass = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.User.prototype.clearOneass = function() {
  this.setOneass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.User.prototype.hasOneass = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.User.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.User.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UserName.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UserName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UserName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UserName.toObject = function(includeInstance, msg) {
  var f, obj = {
    first: jspb.Message.getFieldWithDefault(msg, 1, ""),
    middle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    last: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UserName}
 */
proto.api.themakers.sc.UserName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UserName;
  return proto.api.themakers.sc.UserName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UserName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UserName}
 */
proto.api.themakers.sc.UserName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirst(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UserName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UserName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UserName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UserName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirst();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiddle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLast();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string First = 1;
 * @return {string}
 */
proto.api.themakers.sc.UserName.prototype.getFirst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UserName.prototype.setFirst = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Middle = 2;
 * @return {string}
 */
proto.api.themakers.sc.UserName.prototype.getMiddle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UserName.prototype.setMiddle = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Last = 3;
 * @return {string}
 */
proto.api.themakers.sc.UserName.prototype.getLast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UserName.prototype.setLast = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.UserOneAss.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UserOneAss.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UserOneAss.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UserOneAss} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UserOneAss.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UserOneAss}
 */
proto.api.themakers.sc.UserOneAss.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UserOneAss;
  return proto.api.themakers.sc.UserOneAss.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UserOneAss} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UserOneAss}
 */
proto.api.themakers.sc.UserOneAss.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UserOneAss.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UserOneAss.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UserOneAss} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UserOneAss.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string IDs = 1;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.UserOneAss.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.UserOneAss.prototype.setIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.UserOneAss.prototype.addIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.UserOneAss.prototype.clearIdsList = function() {
  this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    unit: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Role}
 */
proto.api.themakers.sc.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Role;
  return proto.api.themakers.sc.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Role}
 */
proto.api.themakers.sc.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Unit = 1;
 * @return {string}
 */
proto.api.themakers.sc.Role.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Role.prototype.setUnit = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.Role.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Role.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.RolesOp.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RolesOp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RolesOp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RolesOp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RolesOp.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, ""),
    grantList: jspb.Message.toObjectList(msg.getGrantList(),
    proto.api.themakers.sc.Role.toObject, includeInstance),
    revokeList: jspb.Message.toObjectList(msg.getRevokeList(),
    proto.api.themakers.sc.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RolesOp}
 */
proto.api.themakers.sc.RolesOp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RolesOp;
  return proto.api.themakers.sc.RolesOp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RolesOp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RolesOp}
 */
proto.api.themakers.sc.RolesOp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Role;
      reader.readMessage(value,proto.api.themakers.sc.Role.deserializeBinaryFromReader);
      msg.addGrant(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.Role;
      reader.readMessage(value,proto.api.themakers.sc.Role.deserializeBinaryFromReader);
      msg.addRevoke(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RolesOp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RolesOp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RolesOp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RolesOp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGrantList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.themakers.sc.Role.serializeBinaryToWriter
    );
  }
  f = message.getRevokeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.themakers.sc.Role.serializeBinaryToWriter
    );
  }
};


/**
 * optional string User = 1;
 * @return {string}
 */
proto.api.themakers.sc.RolesOp.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RolesOp.prototype.setUser = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Role Grant = 2;
 * @return {!Array<!proto.api.themakers.sc.Role>}
 */
proto.api.themakers.sc.RolesOp.prototype.getGrantList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.Role, 2));
};


/** @param {!Array<!proto.api.themakers.sc.Role>} value */
proto.api.themakers.sc.RolesOp.prototype.setGrantList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.themakers.sc.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.Role}
 */
proto.api.themakers.sc.RolesOp.prototype.addGrant = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.themakers.sc.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.RolesOp.prototype.clearGrantList = function() {
  this.setGrantList([]);
};


/**
 * repeated Role Revoke = 3;
 * @return {!Array<!proto.api.themakers.sc.Role>}
 */
proto.api.themakers.sc.RolesOp.prototype.getRevokeList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.Role, 3));
};


/** @param {!Array<!proto.api.themakers.sc.Role>} value */
proto.api.themakers.sc.RolesOp.prototype.setRevokeList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.themakers.sc.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.Role}
 */
proto.api.themakers.sc.RolesOp.prototype.addRevoke = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.themakers.sc.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.RolesOp.prototype.clearRevokeList = function() {
  this.setRevokeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetUserNameReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetUserNameReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetUserNameReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetUserNameReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto.api.themakers.sc.UserName.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetUserNameReq}
 */
proto.api.themakers.sc.SetUserNameReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetUserNameReq;
  return proto.api.themakers.sc.SetUserNameReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetUserNameReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetUserNameReq}
 */
proto.api.themakers.sc.SetUserNameReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.UserName;
      reader.readMessage(value,proto.api.themakers.sc.UserName.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetUserNameReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetUserNameReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetUserNameReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetUserNameReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.UserName.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetUserNameReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetUserNameReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserName Name = 2;
 * @return {?proto.api.themakers.sc.UserName}
 */
proto.api.themakers.sc.SetUserNameReq.prototype.getName = function() {
  return /** @type{?proto.api.themakers.sc.UserName} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserName, 2));
};


/** @param {?proto.api.themakers.sc.UserName|undefined} value */
proto.api.themakers.sc.SetUserNameReq.prototype.setName = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.SetUserNameReq.prototype.clearName = function() {
  this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.SetUserNameReq.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.GetUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.GetUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.GetUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.GetUserReq}
 */
proto.api.themakers.sc.GetUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.GetUserReq;
  return proto.api.themakers.sc.GetUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.GetUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.GetUserReq}
 */
proto.api.themakers.sc.GetUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.GetUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.GetUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.GetUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.GetUserReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.GetUserReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetUserEmailReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetUserEmailReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetUserEmailReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetUserEmailReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetUserEmailReq}
 */
proto.api.themakers.sc.SetUserEmailReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetUserEmailReq;
  return proto.api.themakers.sc.SetUserEmailReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetUserEmailReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetUserEmailReq}
 */
proto.api.themakers.sc.SetUserEmailReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetUserEmailReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetUserEmailReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetUserEmailReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetUserEmailReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetUserEmailReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetUserEmailReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Email = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetUserEmailReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetUserEmailReq.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetUserPhoneReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetUserPhoneReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetUserPhoneReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetUserPhoneReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetUserPhoneReq}
 */
proto.api.themakers.sc.SetUserPhoneReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetUserPhoneReq;
  return proto.api.themakers.sc.SetUserPhoneReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetUserPhoneReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetUserPhoneReq}
 */
proto.api.themakers.sc.SetUserPhoneReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetUserPhoneReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetUserPhoneReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetUserPhoneReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetUserPhoneReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetUserPhoneReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetUserPhoneReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Phone = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetUserPhoneReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetUserPhoneReq.prototype.setPhone = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.GetUsersByRolesReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.GetUsersByRolesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.GetUsersByRolesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.GetUsersByRolesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetUsersByRolesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.api.themakers.sc.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.GetUsersByRolesReq}
 */
proto.api.themakers.sc.GetUsersByRolesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.GetUsersByRolesReq;
  return proto.api.themakers.sc.GetUsersByRolesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.GetUsersByRolesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.GetUsersByRolesReq}
 */
proto.api.themakers.sc.GetUsersByRolesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.Role;
      reader.readMessage(value,proto.api.themakers.sc.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.GetUsersByRolesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.GetUsersByRolesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.GetUsersByRolesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetUsersByRolesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.themakers.sc.Role.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Role Roles = 1;
 * @return {!Array<!proto.api.themakers.sc.Role>}
 */
proto.api.themakers.sc.GetUsersByRolesReq.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.Role, 1));
};


/** @param {!Array<!proto.api.themakers.sc.Role>} value */
proto.api.themakers.sc.GetUsersByRolesReq.prototype.setRolesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.themakers.sc.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.Role}
 */
proto.api.themakers.sc.GetUsersByRolesReq.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.themakers.sc.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.GetUsersByRolesReq.prototype.clearRolesList = function() {
  this.setRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.DocumentType.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentType.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    centerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    numerator: jspb.Message.getFieldWithDefault(msg, 6, ""),
    requisitesList: jspb.Message.toObjectList(msg.getRequisitesList(),
    proto.api.themakers.sc.DocumentTypeRequisite.toObject, includeInstance),
    oneass: (f = msg.getOneass()) && proto.api.themakers.sc.DocumentTypeOneAss.toObject(includeInstance, f),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentType}
 */
proto.api.themakers.sc.DocumentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentType;
  return proto.api.themakers.sc.DocumentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentType}
 */
proto.api.themakers.sc.DocumentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumerator(value);
      break;
    case 7:
      var value = new proto.api.themakers.sc.DocumentTypeRequisite;
      reader.readMessage(value,proto.api.themakers.sc.DocumentTypeRequisite.deserializeBinaryFromReader);
      msg.addRequisites(value);
      break;
    case 8:
      var value = new proto.api.themakers.sc.DocumentTypeOneAss;
      reader.readMessage(value,proto.api.themakers.sc.DocumentTypeOneAss.deserializeBinaryFromReader);
      msg.setOneass(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumerator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRequisitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.api.themakers.sc.DocumentTypeRequisite.serializeBinaryToWriter
    );
  }
  f = message.getOneass();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.api.themakers.sc.DocumentTypeOneAss.serializeBinaryToWriter
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentType.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CenterID = 2;
 * @return {string}
 */
proto.api.themakers.sc.DocumentType.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentType.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.api.themakers.sc.DocumentType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentType.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Description = 4;
 * @return {string}
 */
proto.api.themakers.sc.DocumentType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentType.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Code = 5;
 * @return {string}
 */
proto.api.themakers.sc.DocumentType.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentType.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Numerator = 6;
 * @return {string}
 */
proto.api.themakers.sc.DocumentType.prototype.getNumerator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentType.prototype.setNumerator = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated DocumentTypeRequisite Requisites = 7;
 * @return {!Array<!proto.api.themakers.sc.DocumentTypeRequisite>}
 */
proto.api.themakers.sc.DocumentType.prototype.getRequisitesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.DocumentTypeRequisite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.DocumentTypeRequisite, 7));
};


/** @param {!Array<!proto.api.themakers.sc.DocumentTypeRequisite>} value */
proto.api.themakers.sc.DocumentType.prototype.setRequisitesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.api.themakers.sc.DocumentTypeRequisite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.DocumentTypeRequisite}
 */
proto.api.themakers.sc.DocumentType.prototype.addRequisites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.api.themakers.sc.DocumentTypeRequisite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.DocumentType.prototype.clearRequisitesList = function() {
  this.setRequisitesList([]);
};


/**
 * optional DocumentTypeOneAss OneAss = 8;
 * @return {?proto.api.themakers.sc.DocumentTypeOneAss}
 */
proto.api.themakers.sc.DocumentType.prototype.getOneass = function() {
  return /** @type{?proto.api.themakers.sc.DocumentTypeOneAss} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DocumentTypeOneAss, 8));
};


/** @param {?proto.api.themakers.sc.DocumentTypeOneAss|undefined} value */
proto.api.themakers.sc.DocumentType.prototype.setOneass = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentType.prototype.clearOneass = function() {
  this.setOneass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentType.prototype.hasOneass = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.DocumentType.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.DocumentType.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.CreateDocumentTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.CreateDocumentTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.CreateDocumentTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateDocumentTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    centerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.CreateDocumentTypeReq}
 */
proto.api.themakers.sc.CreateDocumentTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.CreateDocumentTypeReq;
  return proto.api.themakers.sc.CreateDocumentTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.CreateDocumentTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.CreateDocumentTypeReq}
 */
proto.api.themakers.sc.CreateDocumentTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.CreateDocumentTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.CreateDocumentTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.CreateDocumentTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateDocumentTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string CenterID = 1;
 * @return {string}
 */
proto.api.themakers.sc.CreateDocumentTypeReq.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateDocumentTypeReq.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.CreateDocumentTypeReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateDocumentTypeReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentTypeOneAss.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentTypeOneAss.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentTypeOneAss} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentTypeOneAss.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentTypeOneAss}
 */
proto.api.themakers.sc.DocumentTypeOneAss.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentTypeOneAss;
  return proto.api.themakers.sc.DocumentTypeOneAss.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentTypeOneAss} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentTypeOneAss}
 */
proto.api.themakers.sc.DocumentTypeOneAss.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentTypeOneAss.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentTypeOneAss.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentTypeOneAss} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentTypeOneAss.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollectionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string CollectionID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentTypeOneAss.prototype.getCollectionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentTypeOneAss.prototype.setCollectionid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetNumerator_DocumentTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetNumerator_DocumentTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    documenttypeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numeratorid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetNumerator_DocumentTypeReq}
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetNumerator_DocumentTypeReq;
  return proto.api.themakers.sc.SetNumerator_DocumentTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetNumerator_DocumentTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetNumerator_DocumentTypeReq}
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumenttypeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumeratorid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetNumerator_DocumentTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetNumerator_DocumentTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumenttypeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumeratorid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string DocumentTypeID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.prototype.getDocumenttypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.prototype.setDocumenttypeid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string NumeratorID = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.prototype.getNumeratorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetNumerator_DocumentTypeReq.prototype.setNumeratorid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentTypeRequisite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentTypeRequisite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentTypeRequisite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subtype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    required: jspb.Message.getFieldWithDefault(msg, 5, false),
    editable: jspb.Message.getFieldWithDefault(msg, 6, false),
    fromexternalsource: jspb.Message.getFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentTypeRequisite}
 */
proto.api.themakers.sc.DocumentTypeRequisite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentTypeRequisite;
  return proto.api.themakers.sc.DocumentTypeRequisite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentTypeRequisite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentTypeRequisite}
 */
proto.api.themakers.sc.DocumentTypeRequisite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtype(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFromexternalsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentTypeRequisite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentTypeRequisite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentTypeRequisite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubtype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getFromexternalsource();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string SubType = 4;
 * @return {string}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.getSubtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.setSubtype = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool Required = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.setRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool Editable = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.setEditable = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool FromExternalSource = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.getFromexternalsource = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.DocumentTypeRequisite.prototype.setFromexternalsource = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.RearangeRequisitesReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RearangeRequisitesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RearangeRequisitesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RearangeRequisitesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RearangeRequisitesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RearangeRequisitesReq}
 */
proto.api.themakers.sc.RearangeRequisitesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RearangeRequisitesReq;
  return proto.api.themakers.sc.RearangeRequisitesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RearangeRequisitesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RearangeRequisitesReq}
 */
proto.api.themakers.sc.RearangeRequisitesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RearangeRequisitesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RearangeRequisitesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RearangeRequisitesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RearangeRequisitesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string TypeID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RearangeRequisitesReq.prototype.getTypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RearangeRequisitesReq.prototype.setTypeid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string Order = 2;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.RearangeRequisitesReq.prototype.getOrderList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.RearangeRequisitesReq.prototype.setOrderList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.RearangeRequisitesReq.prototype.addOrder = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.RearangeRequisitesReq.prototype.clearOrderList = function() {
  this.setOrderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.AddDocumentTypeRequisiteReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.AddDocumentTypeRequisiteReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requisite: (f = msg.getRequisite()) && proto.api.themakers.sc.DocumentTypeRequisite.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.AddDocumentTypeRequisiteReq}
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.AddDocumentTypeRequisiteReq;
  return proto.api.themakers.sc.AddDocumentTypeRequisiteReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.AddDocumentTypeRequisiteReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.AddDocumentTypeRequisiteReq}
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.DocumentTypeRequisite;
      reader.readMessage(value,proto.api.themakers.sc.DocumentTypeRequisite.deserializeBinaryFromReader);
      msg.setRequisite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.AddDocumentTypeRequisiteReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.AddDocumentTypeRequisiteReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequisite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.DocumentTypeRequisite.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DocumentTypeRequisite Requisite = 2;
 * @return {?proto.api.themakers.sc.DocumentTypeRequisite}
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.prototype.getRequisite = function() {
  return /** @type{?proto.api.themakers.sc.DocumentTypeRequisite} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DocumentTypeRequisite, 2));
};


/** @param {?proto.api.themakers.sc.DocumentTypeRequisite|undefined} value */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.prototype.setRequisite = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.prototype.clearRequisite = function() {
  this.setRequisite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.AddDocumentTypeRequisiteReq.prototype.hasRequisite = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requisiteid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    required: jspb.Message.getFieldWithDefault(msg, 4, false),
    editable: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq;
  return proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequisiteid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequisiteid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string RequisiteID = 2;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.getRequisiteid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.setRequisiteid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool Required = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.setRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool Editable = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq.prototype.setEditable = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveDocumentTypeRequisite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveDocumentTypeRequisite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requisite: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveDocumentTypeRequisite}
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveDocumentTypeRequisite;
  return proto.api.themakers.sc.RemoveDocumentTypeRequisite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveDocumentTypeRequisite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveDocumentTypeRequisite}
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequisite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveDocumentTypeRequisite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveDocumentTypeRequisite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequisite();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Requisite = 2;
 * @return {string}
 */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.prototype.getRequisite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveDocumentTypeRequisite.prototype.setRequisite = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentStreamReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentStreamReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentStreamReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentStreamReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentStreamReq}
 */
proto.api.themakers.sc.DocumentStreamReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentStreamReq;
  return proto.api.themakers.sc.DocumentStreamReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentStreamReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentStreamReq}
 */
proto.api.themakers.sc.DocumentStreamReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentStreamReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentStreamReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentStreamReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentStreamReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentStreamReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentStreamReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.DocumentStreamMessage.DocumentstreammessageCase = {
  DOCUMENTSTREAMMESSAGE_NOT_SET: 0,
  DUMMY: 1,
  DOCUMENT: 2,
  EVENT: 3,
  TASK: 4,
  TASKDELETED: 5
};

/**
 * @return {proto.api.themakers.sc.DocumentStreamMessage.DocumentstreammessageCase}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.getDocumentstreammessageCase = function() {
  return /** @type {proto.api.themakers.sc.DocumentStreamMessage.DocumentstreammessageCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentStreamMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentStreamMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentStreamMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: (f = msg.getDummy()) && proto.api.themakers.sc.Dummy.toObject(includeInstance, f),
    document: (f = msg.getDocument()) && proto.api.themakers.sc.Document.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && proto.api.themakers.sc.DocumentEvent.toObject(includeInstance, f),
    task: (f = msg.getTask()) && proto.api.themakers.sc.Task.toObject(includeInstance, f),
    taskdeleted: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentStreamMessage}
 */
proto.api.themakers.sc.DocumentStreamMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentStreamMessage;
  return proto.api.themakers.sc.DocumentStreamMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentStreamMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentStreamMessage}
 */
proto.api.themakers.sc.DocumentStreamMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.Dummy;
      reader.readMessage(value,proto.api.themakers.sc.Dummy.deserializeBinaryFromReader);
      msg.setDummy(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Document;
      reader.readMessage(value,proto.api.themakers.sc.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.DocumentEvent;
      reader.readMessage(value,proto.api.themakers.sc.DocumentEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.Task;
      reader.readMessage(value,proto.api.themakers.sc.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskdeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentStreamMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentStreamMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentStreamMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.Dummy.serializeBinaryToWriter
    );
  }
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Document.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.DocumentEvent.serializeBinaryToWriter
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.Task.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Dummy Dummy = 1;
 * @return {?proto.api.themakers.sc.Dummy}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.getDummy = function() {
  return /** @type{?proto.api.themakers.sc.Dummy} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Dummy, 1));
};


/** @param {?proto.api.themakers.sc.Dummy|undefined} value */
proto.api.themakers.sc.DocumentStreamMessage.prototype.setDummy = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.clearDummy = function() {
  this.setDummy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.hasDummy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Document Document = 2;
 * @return {?proto.api.themakers.sc.Document}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.getDocument = function() {
  return /** @type{?proto.api.themakers.sc.Document} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Document, 2));
};


/** @param {?proto.api.themakers.sc.Document|undefined} value */
proto.api.themakers.sc.DocumentStreamMessage.prototype.setDocument = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.clearDocument = function() {
  this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DocumentEvent Event = 3;
 * @return {?proto.api.themakers.sc.DocumentEvent}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.getEvent = function() {
  return /** @type{?proto.api.themakers.sc.DocumentEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DocumentEvent, 3));
};


/** @param {?proto.api.themakers.sc.DocumentEvent|undefined} value */
proto.api.themakers.sc.DocumentStreamMessage.prototype.setEvent = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.clearEvent = function() {
  this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Task Task = 4;
 * @return {?proto.api.themakers.sc.Task}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.getTask = function() {
  return /** @type{?proto.api.themakers.sc.Task} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Task, 4));
};


/** @param {?proto.api.themakers.sc.Task|undefined} value */
proto.api.themakers.sc.DocumentStreamMessage.prototype.setTask = function(value) {
  jspb.Message.setOneofWrapperField(this, 4, proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.clearTask = function() {
  this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.hasTask = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string TaskDeleted = 5;
 * @return {string}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.getTaskdeleted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentStreamMessage.prototype.setTaskdeleted = function(value) {
  jspb.Message.setOneofField(this, 5, proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.clearTaskdeleted = function() {
  jspb.Message.setOneofField(this, 5, proto.api.themakers.sc.DocumentStreamMessage.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentStreamMessage.prototype.hasTaskdeleted = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ArchivedListStreamReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ArchivedListStreamReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ArchivedListStreamReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedListStreamReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    documenttypeid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ArchivedListStreamReq}
 */
proto.api.themakers.sc.ArchivedListStreamReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ArchivedListStreamReq;
  return proto.api.themakers.sc.ArchivedListStreamReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ArchivedListStreamReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ArchivedListStreamReq}
 */
proto.api.themakers.sc.ArchivedListStreamReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumenttypeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ArchivedListStreamReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ArchivedListStreamReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ArchivedListStreamReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedListStreamReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumenttypeid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string DocumentTypeID = 1;
 * @return {string}
 */
proto.api.themakers.sc.ArchivedListStreamReq.prototype.getDocumenttypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ArchivedListStreamReq.prototype.setDocumenttypeid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.ArchivedListStreamMessage.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.ArchivedliststreammessageCase = {
  ARCHIVEDLISTSTREAMMESSAGE_NOT_SET: 0,
  EOS: 1,
  DOCUMENT: 2
};

/**
 * @return {proto.api.themakers.sc.ArchivedListStreamMessage.ArchivedliststreammessageCase}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.getArchivedliststreammessageCase = function() {
  return /** @type {proto.api.themakers.sc.ArchivedListStreamMessage.ArchivedliststreammessageCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.ArchivedListStreamMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ArchivedListStreamMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ArchivedListStreamMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedListStreamMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    eos: jspb.Message.getFieldWithDefault(msg, 1, ""),
    document: (f = msg.getDocument()) && proto.api.themakers.sc.Document.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ArchivedListStreamMessage}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ArchivedListStreamMessage;
  return proto.api.themakers.sc.ArchivedListStreamMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ArchivedListStreamMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ArchivedListStreamMessage}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEos(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Document;
      reader.readMessage(value,proto.api.themakers.sc.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ArchivedListStreamMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ArchivedListStreamMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedListStreamMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional string EOS = 1;
 * @return {string}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.getEos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.setEos = function(value) {
  jspb.Message.setOneofField(this, 1, proto.api.themakers.sc.ArchivedListStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.clearEos = function() {
  jspb.Message.setOneofField(this, 1, proto.api.themakers.sc.ArchivedListStreamMessage.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.hasEos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Document Document = 2;
 * @return {?proto.api.themakers.sc.Document}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.getDocument = function() {
  return /** @type{?proto.api.themakers.sc.Document} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Document, 2));
};


/** @param {?proto.api.themakers.sc.Document|undefined} value */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.setDocument = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.api.themakers.sc.ArchivedListStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.clearDocument = function() {
  this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.ArchivedListStreamMessage.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.DocumentEvent.oneofGroups_ = [[6,7,8,9,10,11,12]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.DocumentEvent.FieldsCase = {
  FIELDS_NOT_SET: 0,
  MESSAGE: 6,
  STATUSTRANSITION: 7,
  REGISTRATION: 8,
  APPROVED: 9,
  REJECTED: 10,
  OPTIONREQUISITEUPDATED: 11,
  LINKEDTASK: 12
};

/**
 * @return {proto.api.themakers.sc.DocumentEvent.FieldsCase}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getFieldsCase = function() {
  return /** @type {proto.api.themakers.sc.DocumentEvent.FieldsCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.DocumentEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    author: (f = msg.getAuthor()) && proto.api.themakers.sc.UserInfo.toObject(includeInstance, f),
    ts: jspb.Message.getFieldWithDefault(msg, 5, 0),
    message: (f = msg.getMessage()) && proto.api.themakers.sc.Message.toObject(includeInstance, f),
    statustransition: (f = msg.getStatustransition()) && proto.api.themakers.sc.StatusTransition.toObject(includeInstance, f),
    registration: (f = msg.getRegistration()) && proto.api.themakers.sc.Registration.toObject(includeInstance, f),
    approved: (f = msg.getApproved()) && proto.api.themakers.sc.ActorElementIDPair.toObject(includeInstance, f),
    rejected: (f = msg.getRejected()) && proto.api.themakers.sc.Rejected.toObject(includeInstance, f),
    optionrequisiteupdated: (f = msg.getOptionrequisiteupdated()) && proto.api.themakers.sc.OptionRequisiteUpdated.toObject(includeInstance, f),
    linkedtask: (f = msg.getLinkedtask()) && proto.api.themakers.sc.LinkedTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentEvent}
 */
proto.api.themakers.sc.DocumentEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentEvent;
  return proto.api.themakers.sc.DocumentEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentEvent}
 */
proto.api.themakers.sc.DocumentEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.UserInfo;
      reader.readMessage(value,proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTs(value);
      break;
    case 6:
      var value = new proto.api.themakers.sc.Message;
      reader.readMessage(value,proto.api.themakers.sc.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 7:
      var value = new proto.api.themakers.sc.StatusTransition;
      reader.readMessage(value,proto.api.themakers.sc.StatusTransition.deserializeBinaryFromReader);
      msg.setStatustransition(value);
      break;
    case 8:
      var value = new proto.api.themakers.sc.Registration;
      reader.readMessage(value,proto.api.themakers.sc.Registration.deserializeBinaryFromReader);
      msg.setRegistration(value);
      break;
    case 9:
      var value = new proto.api.themakers.sc.ActorElementIDPair;
      reader.readMessage(value,proto.api.themakers.sc.ActorElementIDPair.deserializeBinaryFromReader);
      msg.setApproved(value);
      break;
    case 10:
      var value = new proto.api.themakers.sc.Rejected;
      reader.readMessage(value,proto.api.themakers.sc.Rejected.deserializeBinaryFromReader);
      msg.setRejected(value);
      break;
    case 11:
      var value = new proto.api.themakers.sc.OptionRequisiteUpdated;
      reader.readMessage(value,proto.api.themakers.sc.OptionRequisiteUpdated.deserializeBinaryFromReader);
      msg.setOptionrequisiteupdated(value);
      break;
    case 12:
      var value = new proto.api.themakers.sc.LinkedTask;
      reader.readMessage(value,proto.api.themakers.sc.LinkedTask.deserializeBinaryFromReader);
      msg.setLinkedtask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.themakers.sc.Message.serializeBinaryToWriter
    );
  }
  f = message.getStatustransition();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.themakers.sc.StatusTransition.serializeBinaryToWriter
    );
  }
  f = message.getRegistration();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.api.themakers.sc.Registration.serializeBinaryToWriter
    );
  }
  f = message.getApproved();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.api.themakers.sc.ActorElementIDPair.serializeBinaryToWriter
    );
  }
  f = message.getRejected();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.themakers.sc.Rejected.serializeBinaryToWriter
    );
  }
  f = message.getOptionrequisiteupdated();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.api.themakers.sc.OptionRequisiteUpdated.serializeBinaryToWriter
    );
  }
  f = message.getLinkedtask();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.api.themakers.sc.LinkedTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentEvent.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DocumentID = 2;
 * @return {string}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getDocumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentEvent.prototype.setDocumentid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentEvent.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserInfo Author = 4;
 * @return {?proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getAuthor = function() {
  return /** @type{?proto.api.themakers.sc.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserInfo, 4));
};


/** @param {?proto.api.themakers.sc.UserInfo|undefined} value */
proto.api.themakers.sc.DocumentEvent.prototype.setAuthor = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentEvent.prototype.clearAuthor = function() {
  this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentEvent.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 TS = 5;
 * @return {number}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.api.themakers.sc.DocumentEvent.prototype.setTs = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Message Message = 6;
 * @return {?proto.api.themakers.sc.Message}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getMessage = function() {
  return /** @type{?proto.api.themakers.sc.Message} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Message, 6));
};


/** @param {?proto.api.themakers.sc.Message|undefined} value */
proto.api.themakers.sc.DocumentEvent.prototype.setMessage = function(value) {
  jspb.Message.setOneofWrapperField(this, 6, proto.api.themakers.sc.DocumentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentEvent.prototype.clearMessage = function() {
  this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentEvent.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional StatusTransition StatusTransition = 7;
 * @return {?proto.api.themakers.sc.StatusTransition}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getStatustransition = function() {
  return /** @type{?proto.api.themakers.sc.StatusTransition} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.StatusTransition, 7));
};


/** @param {?proto.api.themakers.sc.StatusTransition|undefined} value */
proto.api.themakers.sc.DocumentEvent.prototype.setStatustransition = function(value) {
  jspb.Message.setOneofWrapperField(this, 7, proto.api.themakers.sc.DocumentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentEvent.prototype.clearStatustransition = function() {
  this.setStatustransition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentEvent.prototype.hasStatustransition = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Registration Registration = 8;
 * @return {?proto.api.themakers.sc.Registration}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getRegistration = function() {
  return /** @type{?proto.api.themakers.sc.Registration} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Registration, 8));
};


/** @param {?proto.api.themakers.sc.Registration|undefined} value */
proto.api.themakers.sc.DocumentEvent.prototype.setRegistration = function(value) {
  jspb.Message.setOneofWrapperField(this, 8, proto.api.themakers.sc.DocumentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentEvent.prototype.clearRegistration = function() {
  this.setRegistration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentEvent.prototype.hasRegistration = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ActorElementIDPair Approved = 9;
 * @return {?proto.api.themakers.sc.ActorElementIDPair}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getApproved = function() {
  return /** @type{?proto.api.themakers.sc.ActorElementIDPair} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.ActorElementIDPair, 9));
};


/** @param {?proto.api.themakers.sc.ActorElementIDPair|undefined} value */
proto.api.themakers.sc.DocumentEvent.prototype.setApproved = function(value) {
  jspb.Message.setOneofWrapperField(this, 9, proto.api.themakers.sc.DocumentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentEvent.prototype.clearApproved = function() {
  this.setApproved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentEvent.prototype.hasApproved = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Rejected Rejected = 10;
 * @return {?proto.api.themakers.sc.Rejected}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getRejected = function() {
  return /** @type{?proto.api.themakers.sc.Rejected} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Rejected, 10));
};


/** @param {?proto.api.themakers.sc.Rejected|undefined} value */
proto.api.themakers.sc.DocumentEvent.prototype.setRejected = function(value) {
  jspb.Message.setOneofWrapperField(this, 10, proto.api.themakers.sc.DocumentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentEvent.prototype.clearRejected = function() {
  this.setRejected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentEvent.prototype.hasRejected = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OptionRequisiteUpdated OptionRequisiteUpdated = 11;
 * @return {?proto.api.themakers.sc.OptionRequisiteUpdated}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getOptionrequisiteupdated = function() {
  return /** @type{?proto.api.themakers.sc.OptionRequisiteUpdated} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.OptionRequisiteUpdated, 11));
};


/** @param {?proto.api.themakers.sc.OptionRequisiteUpdated|undefined} value */
proto.api.themakers.sc.DocumentEvent.prototype.setOptionrequisiteupdated = function(value) {
  jspb.Message.setOneofWrapperField(this, 11, proto.api.themakers.sc.DocumentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentEvent.prototype.clearOptionrequisiteupdated = function() {
  this.setOptionrequisiteupdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentEvent.prototype.hasOptionrequisiteupdated = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional LinkedTask LinkedTask = 12;
 * @return {?proto.api.themakers.sc.LinkedTask}
 */
proto.api.themakers.sc.DocumentEvent.prototype.getLinkedtask = function() {
  return /** @type{?proto.api.themakers.sc.LinkedTask} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.LinkedTask, 12));
};


/** @param {?proto.api.themakers.sc.LinkedTask|undefined} value */
proto.api.themakers.sc.DocumentEvent.prototype.setLinkedtask = function(value) {
  jspb.Message.setOneofWrapperField(this, 12, proto.api.themakers.sc.DocumentEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentEvent.prototype.clearLinkedtask = function() {
  this.setLinkedtask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentEvent.prototype.hasLinkedtask = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Message}
 */
proto.api.themakers.sc.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Message;
  return proto.api.themakers.sc.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Message}
 */
proto.api.themakers.sc.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Message = 1;
 * @return {string}
 */
proto.api.themakers.sc.Message.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Message.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.StatusTransition.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.StatusTransition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.StatusTransition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.StatusTransition.toObject = function(includeInstance, msg) {
  var f, obj = {
    previous: jspb.Message.getFieldWithDefault(msg, 1, ""),
    current: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.StatusTransition}
 */
proto.api.themakers.sc.StatusTransition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.StatusTransition;
  return proto.api.themakers.sc.StatusTransition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.StatusTransition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.StatusTransition}
 */
proto.api.themakers.sc.StatusTransition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevious(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.StatusTransition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.StatusTransition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.StatusTransition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.StatusTransition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrevious();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Previous = 1;
 * @return {string}
 */
proto.api.themakers.sc.StatusTransition.prototype.getPrevious = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.StatusTransition.prototype.setPrevious = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Current = 2;
 * @return {string}
 */
proto.api.themakers.sc.StatusTransition.prototype.getCurrent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.StatusTransition.prototype.setCurrent = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Registration.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Registration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Registration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Registration.toObject = function(includeInstance, msg) {
  var f, obj = {
    number: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Registration}
 */
proto.api.themakers.sc.Registration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Registration;
  return proto.api.themakers.sc.Registration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Registration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Registration}
 */
proto.api.themakers.sc.Registration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Registration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Registration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Registration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Registration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Number = 1;
 * @return {string}
 */
proto.api.themakers.sc.Registration.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Registration.prototype.setNumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ActorElementIDPair.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ActorElementIDPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ActorElementIDPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ActorElementIDPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    elementid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ActorElementIDPair}
 */
proto.api.themakers.sc.ActorElementIDPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ActorElementIDPair;
  return proto.api.themakers.sc.ActorElementIDPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ActorElementIDPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ActorElementIDPair}
 */
proto.api.themakers.sc.ActorElementIDPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setElementid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ActorElementIDPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ActorElementIDPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ActorElementIDPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ActorElementIDPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getElementid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Actor Actor = 1;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.ActorElementIDPair.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 1));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.ActorElementIDPair.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.ActorElementIDPair.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.ActorElementIDPair.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string ElementID = 2;
 * @return {string}
 */
proto.api.themakers.sc.ActorElementIDPair.prototype.getElementid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ActorElementIDPair.prototype.setElementid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Rejected.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Rejected.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Rejected.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Rejected} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Rejected.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && proto.api.themakers.sc.ActorElementIDPair.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 2, ""),
    victimsList: jspb.Message.toObjectList(msg.getVictimsList(),
    proto.api.themakers.sc.ActorElementIDPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Rejected}
 */
proto.api.themakers.sc.Rejected.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Rejected;
  return proto.api.themakers.sc.Rejected.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Rejected} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Rejected}
 */
proto.api.themakers.sc.Rejected.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.ActorElementIDPair;
      reader.readMessage(value,proto.api.themakers.sc.ActorElementIDPair.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.ActorElementIDPair;
      reader.readMessage(value,proto.api.themakers.sc.ActorElementIDPair.deserializeBinaryFromReader);
      msg.addVictims(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Rejected.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Rejected.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Rejected} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Rejected.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.ActorElementIDPair.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVictimsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.themakers.sc.ActorElementIDPair.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActorElementIDPair Actor = 1;
 * @return {?proto.api.themakers.sc.ActorElementIDPair}
 */
proto.api.themakers.sc.Rejected.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.ActorElementIDPair} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.ActorElementIDPair, 1));
};


/** @param {?proto.api.themakers.sc.ActorElementIDPair|undefined} value */
proto.api.themakers.sc.Rejected.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Rejected.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Rejected.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Reason = 2;
 * @return {string}
 */
proto.api.themakers.sc.Rejected.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Rejected.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ActorElementIDPair Victims = 3;
 * @return {!Array<!proto.api.themakers.sc.ActorElementIDPair>}
 */
proto.api.themakers.sc.Rejected.prototype.getVictimsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.ActorElementIDPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.ActorElementIDPair, 3));
};


/** @param {!Array<!proto.api.themakers.sc.ActorElementIDPair>} value */
proto.api.themakers.sc.Rejected.prototype.setVictimsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.themakers.sc.ActorElementIDPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.ActorElementIDPair}
 */
proto.api.themakers.sc.Rejected.prototype.addVictims = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.themakers.sc.ActorElementIDPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Rejected.prototype.clearVictimsList = function() {
  this.setVictimsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.OptionRequisiteUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.OptionRequisiteUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.OptionRequisiteUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requisiteid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requisitename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    requisitetype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    value: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.OptionRequisiteUpdated}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.OptionRequisiteUpdated;
  return proto.api.themakers.sc.OptionRequisiteUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.OptionRequisiteUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.OptionRequisiteUpdated}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequisiteid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequisitename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequisitetype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.OptionRequisiteUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.OptionRequisiteUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.OptionRequisiteUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequisiteid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequisitename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequisitetype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string RecordID = 1;
 * @return {string}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.getRecordid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.setRecordid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string RequisiteID = 2;
 * @return {string}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.getRequisiteid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.setRequisiteid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string RequisiteName = 3;
 * @return {string}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.getRequisitename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.setRequisitename = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string RequisiteType = 4;
 * @return {string}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.getRequisitetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.setRequisitetype = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Value = 5;
 * @return {string}
 */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.OptionRequisiteUpdated.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.LinkedTask.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.LinkedTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.LinkedTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.LinkedTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    author: (f = msg.getAuthor()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    assignee: (f = msg.getAssignee()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    confirmer: (f = msg.getConfirmer()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.LinkedTask}
 */
proto.api.themakers.sc.LinkedTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.LinkedTask;
  return proto.api.themakers.sc.LinkedTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.LinkedTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.LinkedTask}
 */
proto.api.themakers.sc.LinkedTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 5:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setAssignee(value);
      break;
    case 6:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setConfirmer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.LinkedTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.LinkedTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.LinkedTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.LinkedTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getAssignee();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getConfirmer();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Type = 1;
 * @return {string}
 */
proto.api.themakers.sc.LinkedTask.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.LinkedTask.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ID = 2;
 * @return {string}
 */
proto.api.themakers.sc.LinkedTask.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.LinkedTask.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Text = 3;
 * @return {string}
 */
proto.api.themakers.sc.LinkedTask.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.LinkedTask.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Actor Author = 4;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.LinkedTask.prototype.getAuthor = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 4));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.LinkedTask.prototype.setAuthor = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.LinkedTask.prototype.clearAuthor = function() {
  this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.LinkedTask.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Actor Assignee = 5;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.LinkedTask.prototype.getAssignee = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 5));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.LinkedTask.prototype.setAssignee = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.LinkedTask.prototype.clearAssignee = function() {
  this.setAssignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.LinkedTask.prototype.hasAssignee = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Actor Confirmer = 6;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.LinkedTask.prototype.getConfirmer = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 6));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.LinkedTask.prototype.setConfirmer = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.LinkedTask.prototype.clearConfirmer = function() {
  this.setConfirmer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.LinkedTask.prototype.hasConfirmer = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ListStreamReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ListStreamReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ListStreamReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ListStreamReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ListStreamReq}
 */
proto.api.themakers.sc.ListStreamReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ListStreamReq;
  return proto.api.themakers.sc.ListStreamReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ListStreamReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ListStreamReq}
 */
proto.api.themakers.sc.ListStreamReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ListStreamReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ListStreamReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ListStreamReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ListStreamReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.DocumentListStreamMessage.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.DocumentListStreamMessage.DocumentliststreammessageCase = {
  DOCUMENTLISTSTREAMMESSAGE_NOT_SET: 0,
  DUMMY: 1,
  DOCUMENT: 2,
  DOCUMENTDELETED: 3
};

/**
 * @return {proto.api.themakers.sc.DocumentListStreamMessage.DocumentliststreammessageCase}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.getDocumentliststreammessageCase = function() {
  return /** @type {proto.api.themakers.sc.DocumentListStreamMessage.DocumentliststreammessageCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.DocumentListStreamMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentListStreamMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentListStreamMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentListStreamMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: (f = msg.getDummy()) && proto.api.themakers.sc.Dummy.toObject(includeInstance, f),
    document: (f = msg.getDocument()) && proto.api.themakers.sc.Document.toObject(includeInstance, f),
    documentdeleted: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentListStreamMessage}
 */
proto.api.themakers.sc.DocumentListStreamMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentListStreamMessage;
  return proto.api.themakers.sc.DocumentListStreamMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentListStreamMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentListStreamMessage}
 */
proto.api.themakers.sc.DocumentListStreamMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.Dummy;
      reader.readMessage(value,proto.api.themakers.sc.Dummy.deserializeBinaryFromReader);
      msg.setDummy(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Document;
      reader.readMessage(value,proto.api.themakers.sc.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentdeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentListStreamMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentListStreamMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentListStreamMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.Dummy.serializeBinaryToWriter
    );
  }
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Document.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Dummy Dummy = 1;
 * @return {?proto.api.themakers.sc.Dummy}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.getDummy = function() {
  return /** @type{?proto.api.themakers.sc.Dummy} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Dummy, 1));
};


/** @param {?proto.api.themakers.sc.Dummy|undefined} value */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.setDummy = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.api.themakers.sc.DocumentListStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.clearDummy = function() {
  this.setDummy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.hasDummy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Document Document = 2;
 * @return {?proto.api.themakers.sc.Document}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.getDocument = function() {
  return /** @type{?proto.api.themakers.sc.Document} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Document, 2));
};


/** @param {?proto.api.themakers.sc.Document|undefined} value */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.setDocument = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.api.themakers.sc.DocumentListStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.clearDocument = function() {
  this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string DocumentDeleted = 3;
 * @return {string}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.getDocumentdeleted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.setDocumentdeleted = function(value) {
  jspb.Message.setOneofField(this, 3, proto.api.themakers.sc.DocumentListStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.clearDocumentdeleted = function() {
  jspb.Message.setOneofField(this, 3, proto.api.themakers.sc.DocumentListStreamMessage.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentListStreamMessage.prototype.hasDocumentdeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Document.repeatedFields_ = [2,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.api.themakers.sc.DocumentBase.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    requisitesList: jspb.Message.toObjectList(msg.getRequisitesList(),
    proto.api.themakers.sc.DocumentRequisite.toObject, includeInstance),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.api.themakers.sc.File.toObject, includeInstance),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.api.themakers.sc.DocumentLink.toObject, includeInstance),
    workflow: (f = msg.getWorkflow()) && proto.api.themakers.sc.Workflow.toObject(includeInstance, f),
    oneass: (f = msg.getOneass()) && proto.api.themakers.sc.DocumentOneAss.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Document}
 */
proto.api.themakers.sc.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Document;
  return proto.api.themakers.sc.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Document}
 */
proto.api.themakers.sc.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.DocumentBase;
      reader.readMessage(value,proto.api.themakers.sc.DocumentBase.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.DocumentRequisite;
      reader.readMessage(value,proto.api.themakers.sc.DocumentRequisite.deserializeBinaryFromReader);
      msg.addRequisites(value);
      break;
    case 5:
      var value = new proto.api.themakers.sc.File;
      reader.readMessage(value,proto.api.themakers.sc.File.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 6:
      var value = new proto.api.themakers.sc.DocumentLink;
      reader.readMessage(value,proto.api.themakers.sc.DocumentLink.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 7:
      var value = new proto.api.themakers.sc.Workflow;
      reader.readMessage(value,proto.api.themakers.sc.Workflow.deserializeBinaryFromReader);
      msg.setWorkflow(value);
      break;
    case 8:
      var value = new proto.api.themakers.sc.DocumentOneAss;
      reader.readMessage(value,proto.api.themakers.sc.DocumentOneAss.deserializeBinaryFromReader);
      msg.setOneass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.DocumentBase.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequisitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.themakers.sc.DocumentRequisite.serializeBinaryToWriter
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.api.themakers.sc.File.serializeBinaryToWriter
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.api.themakers.sc.DocumentLink.serializeBinaryToWriter
    );
  }
  f = message.getWorkflow();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.themakers.sc.Workflow.serializeBinaryToWriter
    );
  }
  f = message.getOneass();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.api.themakers.sc.DocumentOneAss.serializeBinaryToWriter
    );
  }
};


/**
 * optional DocumentBase Base = 1;
 * @return {?proto.api.themakers.sc.DocumentBase}
 */
proto.api.themakers.sc.Document.prototype.getBase = function() {
  return /** @type{?proto.api.themakers.sc.DocumentBase} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DocumentBase, 1));
};


/** @param {?proto.api.themakers.sc.DocumentBase|undefined} value */
proto.api.themakers.sc.Document.prototype.setBase = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Document.prototype.clearBase = function() {
  this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Document.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Comment = 3;
 * @return {string}
 */
proto.api.themakers.sc.Document.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Document.prototype.setComment = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DocumentRequisite Requisites = 2;
 * @return {!Array<!proto.api.themakers.sc.DocumentRequisite>}
 */
proto.api.themakers.sc.Document.prototype.getRequisitesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.DocumentRequisite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.DocumentRequisite, 2));
};


/** @param {!Array<!proto.api.themakers.sc.DocumentRequisite>} value */
proto.api.themakers.sc.Document.prototype.setRequisitesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.themakers.sc.DocumentRequisite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.DocumentRequisite}
 */
proto.api.themakers.sc.Document.prototype.addRequisites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.themakers.sc.DocumentRequisite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Document.prototype.clearRequisitesList = function() {
  this.setRequisitesList([]);
};


/**
 * repeated File Files = 5;
 * @return {!Array<!proto.api.themakers.sc.File>}
 */
proto.api.themakers.sc.Document.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.File, 5));
};


/** @param {!Array<!proto.api.themakers.sc.File>} value */
proto.api.themakers.sc.Document.prototype.setFilesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.api.themakers.sc.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.File}
 */
proto.api.themakers.sc.Document.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.api.themakers.sc.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Document.prototype.clearFilesList = function() {
  this.setFilesList([]);
};


/**
 * repeated DocumentLink Links = 6;
 * @return {!Array<!proto.api.themakers.sc.DocumentLink>}
 */
proto.api.themakers.sc.Document.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.DocumentLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.DocumentLink, 6));
};


/** @param {!Array<!proto.api.themakers.sc.DocumentLink>} value */
proto.api.themakers.sc.Document.prototype.setLinksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.api.themakers.sc.DocumentLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.DocumentLink}
 */
proto.api.themakers.sc.Document.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.api.themakers.sc.DocumentLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Document.prototype.clearLinksList = function() {
  this.setLinksList([]);
};


/**
 * optional Workflow Workflow = 7;
 * @return {?proto.api.themakers.sc.Workflow}
 */
proto.api.themakers.sc.Document.prototype.getWorkflow = function() {
  return /** @type{?proto.api.themakers.sc.Workflow} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Workflow, 7));
};


/** @param {?proto.api.themakers.sc.Workflow|undefined} value */
proto.api.themakers.sc.Document.prototype.setWorkflow = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Document.prototype.clearWorkflow = function() {
  this.setWorkflow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Document.prototype.hasWorkflow = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DocumentOneAss OneAss = 8;
 * @return {?proto.api.themakers.sc.DocumentOneAss}
 */
proto.api.themakers.sc.Document.prototype.getOneass = function() {
  return /** @type{?proto.api.themakers.sc.DocumentOneAss} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DocumentOneAss, 8));
};


/** @param {?proto.api.themakers.sc.DocumentOneAss|undefined} value */
proto.api.themakers.sc.Document.prototype.setOneass = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Document.prototype.clearOneass = function() {
  this.setOneass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Document.prototype.hasOneass = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentDraftReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentDraftReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentDraftReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentDraftReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentDraftReq}
 */
proto.api.themakers.sc.DocumentDraftReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentDraftReq;
  return proto.api.themakers.sc.DocumentDraftReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentDraftReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentDraftReq}
 */
proto.api.themakers.sc.DocumentDraftReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentDraftReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentDraftReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentDraftReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentDraftReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentDraftReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentDraftReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentArchiveReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentArchiveReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentArchiveReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentArchiveReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentArchiveReq}
 */
proto.api.themakers.sc.DocumentArchiveReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentArchiveReq;
  return proto.api.themakers.sc.DocumentArchiveReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentArchiveReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentArchiveReq}
 */
proto.api.themakers.sc.DocumentArchiveReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentArchiveReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentArchiveReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentArchiveReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentArchiveReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentArchiveReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentArchiveReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentStartReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentStartReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentStartReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentStartReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentStartReq}
 */
proto.api.themakers.sc.DocumentStartReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentStartReq;
  return proto.api.themakers.sc.DocumentStartReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentStartReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentStartReq}
 */
proto.api.themakers.sc.DocumentStartReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentStartReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentStartReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentStartReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentStartReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentStartReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentStartReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentOneAss.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentOneAss.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentOneAss} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentOneAss.toObject = function(includeInstance, msg) {
  var f, obj = {
    author: (f = msg.getAuthor()) && proto.api.themakers.sc.DocumentOneAssAuthor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentOneAss}
 */
proto.api.themakers.sc.DocumentOneAss.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentOneAss;
  return proto.api.themakers.sc.DocumentOneAss.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentOneAss} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentOneAss}
 */
proto.api.themakers.sc.DocumentOneAss.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.DocumentOneAssAuthor;
      reader.readMessage(value,proto.api.themakers.sc.DocumentOneAssAuthor.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentOneAss.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentOneAss.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentOneAss} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentOneAss.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.DocumentOneAssAuthor.serializeBinaryToWriter
    );
  }
};


/**
 * optional DocumentOneAssAuthor Author = 1;
 * @return {?proto.api.themakers.sc.DocumentOneAssAuthor}
 */
proto.api.themakers.sc.DocumentOneAss.prototype.getAuthor = function() {
  return /** @type{?proto.api.themakers.sc.DocumentOneAssAuthor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DocumentOneAssAuthor, 1));
};


/** @param {?proto.api.themakers.sc.DocumentOneAssAuthor|undefined} value */
proto.api.themakers.sc.DocumentOneAss.prototype.setAuthor = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentOneAss.prototype.clearAuthor = function() {
  this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentOneAss.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentOneAssAuthor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentOneAssAuthor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentOneAssAuthor.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentOneAssAuthor}
 */
proto.api.themakers.sc.DocumentOneAssAuthor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentOneAssAuthor;
  return proto.api.themakers.sc.DocumentOneAssAuthor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentOneAssAuthor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentOneAssAuthor}
 */
proto.api.themakers.sc.DocumentOneAssAuthor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentOneAssAuthor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentOneAssAuthor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentOneAssAuthor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FirstName = 2;
 * @return {string}
 */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.setFirstname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string MiddleName = 3;
 * @return {string}
 */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.setMiddlename = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string LastName = 4;
 * @return {string}
 */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentOneAssAuthor.prototype.setLastname = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.File.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    size: jspb.Message.getFieldWithDefault(msg, 4, 0),
    version: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.File}
 */
proto.api.themakers.sc.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.File;
  return proto.api.themakers.sc.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.File}
 */
proto.api.themakers.sc.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.File.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.File.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.File.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.File.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.File.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.File.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 Size = 4;
 * @return {number}
 */
proto.api.themakers.sc.File.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.api.themakers.sc.File.prototype.setSize = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 Version = 5;
 * @return {number}
 */
proto.api.themakers.sc.File.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.api.themakers.sc.File.prototype.setVersion = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentBase.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentBase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentBase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentBase.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    originunit: jspb.Message.getFieldWithDefault(msg, 5, ""),
    author: (f = msg.getAuthor()) && proto.api.themakers.sc.UserInfo.toObject(includeInstance, f),
    number: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    creationtime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    statuschangetime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentBase}
 */
proto.api.themakers.sc.DocumentBase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentBase;
  return proto.api.themakers.sc.DocumentBase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentBase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentBase}
 */
proto.api.themakers.sc.DocumentBase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginunit(value);
      break;
    case 6:
      var value = new proto.api.themakers.sc.UserInfo;
      reader.readMessage(value,proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationtime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatuschangetime(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentBase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentBase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentBase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentBase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOriginunit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.themakers.sc.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreationtime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStatuschangetime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentBase.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentBase.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.DocumentBase.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentBase.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.DocumentBase.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentBase.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Unit = 4;
 * @return {string}
 */
proto.api.themakers.sc.DocumentBase.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentBase.prototype.setUnit = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string OriginUnit = 5;
 * @return {string}
 */
proto.api.themakers.sc.DocumentBase.prototype.getOriginunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentBase.prototype.setOriginunit = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UserInfo Author = 6;
 * @return {?proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.DocumentBase.prototype.getAuthor = function() {
  return /** @type{?proto.api.themakers.sc.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserInfo, 6));
};


/** @param {?proto.api.themakers.sc.UserInfo|undefined} value */
proto.api.themakers.sc.DocumentBase.prototype.setAuthor = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentBase.prototype.clearAuthor = function() {
  this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentBase.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string Number = 7;
 * @return {string}
 */
proto.api.themakers.sc.DocumentBase.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentBase.prototype.setNumber = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Status = 8;
 * @return {string}
 */
proto.api.themakers.sc.DocumentBase.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentBase.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 CreationTime = 9;
 * @return {number}
 */
proto.api.themakers.sc.DocumentBase.prototype.getCreationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.api.themakers.sc.DocumentBase.prototype.setCreationtime = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 StatusChangeTime = 10;
 * @return {number}
 */
proto.api.themakers.sc.DocumentBase.prototype.getStatuschangetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.api.themakers.sc.DocumentBase.prototype.setStatuschangetime = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.DocumentBase.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.DocumentBase.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto.api.themakers.sc.UserName.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UserInfo;
  return proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.UserName;
      reader.readMessage(value,proto.api.themakers.sc.UserName.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.UserName.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UserInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UserInfo.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserName Name = 2;
 * @return {?proto.api.themakers.sc.UserName}
 */
proto.api.themakers.sc.UserInfo.prototype.getName = function() {
  return /** @type{?proto.api.themakers.sc.UserName} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserName, 2));
};


/** @param {?proto.api.themakers.sc.UserName|undefined} value */
proto.api.themakers.sc.UserInfo.prototype.setName = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.UserInfo.prototype.clearName = function() {
  this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.UserInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentRequisite.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentRequisite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentRequisite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentRequisite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: (f = msg.getType()) && proto.api.themakers.sc.DocumentTypeRequisite.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recordname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentRequisite}
 */
proto.api.themakers.sc.DocumentRequisite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentRequisite;
  return proto.api.themakers.sc.DocumentRequisite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentRequisite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentRequisite}
 */
proto.api.themakers.sc.DocumentRequisite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.DocumentTypeRequisite;
      reader.readMessage(value,proto.api.themakers.sc.DocumentTypeRequisite.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentRequisite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentRequisite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentRequisite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentRequisite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.DocumentTypeRequisite.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecordname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentRequisite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentRequisite.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DocumentTypeRequisite Type = 2;
 * @return {?proto.api.themakers.sc.DocumentTypeRequisite}
 */
proto.api.themakers.sc.DocumentRequisite.prototype.getType = function() {
  return /** @type{?proto.api.themakers.sc.DocumentTypeRequisite} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DocumentTypeRequisite, 2));
};


/** @param {?proto.api.themakers.sc.DocumentTypeRequisite|undefined} value */
proto.api.themakers.sc.DocumentRequisite.prototype.setType = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.DocumentRequisite.prototype.clearType = function() {
  this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.DocumentRequisite.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Value = 3;
 * @return {string}
 */
proto.api.themakers.sc.DocumentRequisite.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentRequisite.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string RecordName = 4;
 * @return {string}
 */
proto.api.themakers.sc.DocumentRequisite.prototype.getRecordname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentRequisite.prototype.setRecordname = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.GetAllByTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.GetAllByTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.GetAllByTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetAllByTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.GetAllByTypeReq}
 */
proto.api.themakers.sc.GetAllByTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.GetAllByTypeReq;
  return proto.api.themakers.sc.GetAllByTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.GetAllByTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.GetAllByTypeReq}
 */
proto.api.themakers.sc.GetAllByTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.GetAllByTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.GetAllByTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.GetAllByTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetAllByTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Type = 1;
 * @return {string}
 */
proto.api.themakers.sc.GetAllByTypeReq.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.GetAllByTypeReq.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.NewDocumentReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.NewDocumentReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.NewDocumentReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NewDocumentReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    originunit: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.NewDocumentReq}
 */
proto.api.themakers.sc.NewDocumentReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.NewDocumentReq;
  return proto.api.themakers.sc.NewDocumentReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.NewDocumentReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.NewDocumentReq}
 */
proto.api.themakers.sc.NewDocumentReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginunit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.NewDocumentReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.NewDocumentReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.NewDocumentReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NewDocumentReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOriginunit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.api.themakers.sc.NewDocumentReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.NewDocumentReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Type = 2;
 * @return {string}
 */
proto.api.themakers.sc.NewDocumentReq.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.NewDocumentReq.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string OriginUnit = 4;
 * @return {string}
 */
proto.api.themakers.sc.NewDocumentReq.prototype.getOriginunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.NewDocumentReq.prototype.setOriginunit = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveDocumentReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveDocumentReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveDocumentReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveDocumentReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveDocumentReq}
 */
proto.api.themakers.sc.RemoveDocumentReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveDocumentReq;
  return proto.api.themakers.sc.RemoveDocumentReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveDocumentReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveDocumentReq}
 */
proto.api.themakers.sc.RemoveDocumentReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveDocumentReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveDocumentReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveDocumentReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveDocumentReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveDocumentReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveDocumentReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UpdateDocumentNameReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UpdateDocumentNameReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UpdateDocumentNameReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateDocumentNameReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UpdateDocumentNameReq}
 */
proto.api.themakers.sc.UpdateDocumentNameReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UpdateDocumentNameReq;
  return proto.api.themakers.sc.UpdateDocumentNameReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UpdateDocumentNameReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UpdateDocumentNameReq}
 */
proto.api.themakers.sc.UpdateDocumentNameReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UpdateDocumentNameReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UpdateDocumentNameReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UpdateDocumentNameReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateDocumentNameReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentNameReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentNameReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentNameReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentNameReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UpdateDocumentRequisiteReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UpdateDocumentRequisiteReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requisite: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recordname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UpdateDocumentRequisiteReq}
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UpdateDocumentRequisiteReq;
  return proto.api.themakers.sc.UpdateDocumentRequisiteReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UpdateDocumentRequisiteReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UpdateDocumentRequisiteReq}
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequisite(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UpdateDocumentRequisiteReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UpdateDocumentRequisiteReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequisite();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecordname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Requisite = 2;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.getRequisite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.setRequisite = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Value = 3;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string RecordName = 4;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.getRecordname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentRequisiteReq.prototype.setRecordname = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UpdateDocumentCommentReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UpdateDocumentCommentReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UpdateDocumentCommentReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateDocumentCommentReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UpdateDocumentCommentReq}
 */
proto.api.themakers.sc.UpdateDocumentCommentReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UpdateDocumentCommentReq;
  return proto.api.themakers.sc.UpdateDocumentCommentReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UpdateDocumentCommentReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UpdateDocumentCommentReq}
 */
proto.api.themakers.sc.UpdateDocumentCommentReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UpdateDocumentCommentReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UpdateDocumentCommentReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UpdateDocumentCommentReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateDocumentCommentReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentCommentReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentCommentReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Comment = 2;
 * @return {string}
 */
proto.api.themakers.sc.UpdateDocumentCommentReq.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateDocumentCommentReq.prototype.setComment = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentPutMessageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentPutMessageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentPutMessageReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentPutMessageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentPutMessageReq}
 */
proto.api.themakers.sc.DocumentPutMessageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentPutMessageReq;
  return proto.api.themakers.sc.DocumentPutMessageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentPutMessageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentPutMessageReq}
 */
proto.api.themakers.sc.DocumentPutMessageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentPutMessageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentPutMessageReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentPutMessageReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentPutMessageReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Document = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentPutMessageReq.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentPutMessageReq.prototype.setDocument = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Message = 2;
 * @return {string}
 */
proto.api.themakers.sc.DocumentPutMessageReq.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentPutMessageReq.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentRegisterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentRegisterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentRegisterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentRegisterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentRegisterReq}
 */
proto.api.themakers.sc.DocumentRegisterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentRegisterReq;
  return proto.api.themakers.sc.DocumentRegisterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentRegisterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentRegisterReq}
 */
proto.api.themakers.sc.DocumentRegisterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentRegisterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentRegisterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentRegisterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentRegisterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentRegisterReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentRegisterReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Number = 2;
 * @return {string}
 */
proto.api.themakers.sc.DocumentRegisterReq.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentRegisterReq.prototype.setNumber = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.SetLinksReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetLinksReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetLinksReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetLinksReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetLinksReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.api.themakers.sc.DocumentLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetLinksReq}
 */
proto.api.themakers.sc.SetLinksReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetLinksReq;
  return proto.api.themakers.sc.SetLinksReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetLinksReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetLinksReq}
 */
proto.api.themakers.sc.SetLinksReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.DocumentLink;
      reader.readMessage(value,proto.api.themakers.sc.DocumentLink.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetLinksReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetLinksReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetLinksReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetLinksReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.themakers.sc.DocumentLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetLinksReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetLinksReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DocumentLink Links = 2;
 * @return {!Array<!proto.api.themakers.sc.DocumentLink>}
 */
proto.api.themakers.sc.SetLinksReq.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.DocumentLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.DocumentLink, 2));
};


/** @param {!Array<!proto.api.themakers.sc.DocumentLink>} value */
proto.api.themakers.sc.SetLinksReq.prototype.setLinksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.themakers.sc.DocumentLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.DocumentLink}
 */
proto.api.themakers.sc.SetLinksReq.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.themakers.sc.DocumentLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.SetLinksReq.prototype.clearLinksList = function() {
  this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DocumentLink.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DocumentLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DocumentLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DocumentLink}
 */
proto.api.themakers.sc.DocumentLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DocumentLink;
  return proto.api.themakers.sc.DocumentLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DocumentLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DocumentLink}
 */
proto.api.themakers.sc.DocumentLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DocumentLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DocumentLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DocumentLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DocumentLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Target = 1;
 * @return {string}
 */
proto.api.themakers.sc.DocumentLink.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentLink.prototype.setTarget = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Kind = 2;
 * @return {string}
 */
proto.api.themakers.sc.DocumentLink.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DocumentLink.prototype.setKind = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.AddWorkflowTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.AddWorkflowTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddWorkflowTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    assignee: (f = msg.getAssignee()) && proto.api.themakers.sc.ActorIDs.toObject(includeInstance, f),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    confirmer: (f = msg.getConfirmer()) && proto.api.themakers.sc.ActorIDs.toObject(includeInstance, f),
    document: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.AddWorkflowTaskReq}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.AddWorkflowTaskReq;
  return proto.api.themakers.sc.AddWorkflowTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.AddWorkflowTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.AddWorkflowTaskReq}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.ActorIDs;
      reader.readMessage(value,proto.api.themakers.sc.ActorIDs.deserializeBinaryFromReader);
      msg.setAssignee(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.ActorIDs;
      reader.readMessage(value,proto.api.themakers.sc.ActorIDs.deserializeBinaryFromReader);
      msg.setConfirmer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.AddWorkflowTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.AddWorkflowTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddWorkflowTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssignee();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.ActorIDs.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConfirmer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.ActorIDs.serializeBinaryToWriter
    );
  }
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional ActorIDs Assignee = 1;
 * @return {?proto.api.themakers.sc.ActorIDs}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.getAssignee = function() {
  return /** @type{?proto.api.themakers.sc.ActorIDs} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.ActorIDs, 1));
};


/** @param {?proto.api.themakers.sc.ActorIDs|undefined} value */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.setAssignee = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.clearAssignee = function() {
  this.setAssignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.hasAssignee = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Text = 2;
 * @return {string}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Description = 3;
 * @return {string}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ActorIDs Confirmer = 4;
 * @return {?proto.api.themakers.sc.ActorIDs}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.getConfirmer = function() {
  return /** @type{?proto.api.themakers.sc.ActorIDs} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.ActorIDs, 4));
};


/** @param {?proto.api.themakers.sc.ActorIDs|undefined} value */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.setConfirmer = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.clearConfirmer = function() {
  this.setConfirmer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.hasConfirmer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string Document = 5;
 * @return {string}
 */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddWorkflowTaskReq.prototype.setDocument = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.AddActorToWorkflowApprovalReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.AddActorToWorkflowApprovalReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    approvalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actor: (f = msg.getActor()) && proto.api.themakers.sc.ActorIDs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.AddActorToWorkflowApprovalReq}
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.AddActorToWorkflowApprovalReq;
  return proto.api.themakers.sc.AddActorToWorkflowApprovalReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.AddActorToWorkflowApprovalReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.AddActorToWorkflowApprovalReq}
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovalid(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.ActorIDs;
      reader.readMessage(value,proto.api.themakers.sc.ActorIDs.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.AddActorToWorkflowApprovalReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.AddActorToWorkflowApprovalReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApprovalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.ActorIDs.serializeBinaryToWriter
    );
  }
};


/**
 * optional string DocumentID = 1;
 * @return {string}
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.getDocumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.setDocumentid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ApprovalID = 2;
 * @return {string}
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.getApprovalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.setApprovalid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ActorIDs Actor = 3;
 * @return {?proto.api.themakers.sc.ActorIDs}
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.ActorIDs} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.ActorIDs, 3));
};


/** @param {?proto.api.themakers.sc.ActorIDs|undefined} value */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.AddActorToWorkflowApprovalReq.prototype.hasActor = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveWorkflowTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveWorkflowTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveWorkflowTaskReq}
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveWorkflowTaskReq;
  return proto.api.themakers.sc.RemoveWorkflowTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveWorkflowTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveWorkflowTaskReq}
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveWorkflowTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveWorkflowTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string TaskID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq.prototype.getTaskid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveWorkflowTaskReq.prototype.setTaskid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DocumentID = 2;
 * @return {string}
 */
proto.api.themakers.sc.RemoveWorkflowTaskReq.prototype.getDocumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveWorkflowTaskReq.prototype.setDocumentid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Actor.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Actor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Actor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Actor.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.api.themakers.sc.UserInfo.toObject(includeInstance, f),
    unit: (f = msg.getUnit()) && proto.api.themakers.sc.UnitInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.Actor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Actor;
  return proto.api.themakers.sc.Actor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Actor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.Actor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.UserInfo;
      reader.readMessage(value,proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.UnitInfo;
      reader.readMessage(value,proto.api.themakers.sc.UnitInfo.deserializeBinaryFromReader);
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Actor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Actor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Actor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Actor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.UnitInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserInfo User = 1;
 * @return {?proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.Actor.prototype.getUser = function() {
  return /** @type{?proto.api.themakers.sc.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserInfo, 1));
};


/** @param {?proto.api.themakers.sc.UserInfo|undefined} value */
proto.api.themakers.sc.Actor.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Actor.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Actor.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UnitInfo Unit = 2;
 * @return {?proto.api.themakers.sc.UnitInfo}
 */
proto.api.themakers.sc.Actor.prototype.getUnit = function() {
  return /** @type{?proto.api.themakers.sc.UnitInfo} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UnitInfo, 2));
};


/** @param {?proto.api.themakers.sc.UnitInfo|undefined} value */
proto.api.themakers.sc.Actor.prototype.setUnit = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Actor.prototype.clearUnit = function() {
  this.setUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Actor.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ActorIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ActorIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ActorIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ActorIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unitid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ActorIDs}
 */
proto.api.themakers.sc.ActorIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ActorIDs;
  return proto.api.themakers.sc.ActorIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ActorIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ActorIDs}
 */
proto.api.themakers.sc.ActorIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ActorIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ActorIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ActorIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ActorIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnitid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.api.themakers.sc.ActorIDs.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ActorIDs.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string UnitID = 2;
 * @return {string}
 */
proto.api.themakers.sc.ActorIDs.prototype.getUnitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ActorIDs.prototype.setUnitid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UnitInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UnitInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UnitInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UnitInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UnitInfo}
 */
proto.api.themakers.sc.UnitInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UnitInfo;
  return proto.api.themakers.sc.UnitInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UnitInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UnitInfo}
 */
proto.api.themakers.sc.UnitInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UnitInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UnitInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UnitInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UnitInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UnitInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UnitInfo.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.UnitInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UnitInfo.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.WorkflowTemplate.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    vvs: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pb_static: jspb.Message.getFieldWithDefault(msg, 3, false),
    stagesList: jspb.Message.toObjectList(msg.getStagesList(),
    proto.api.themakers.sc.WorkflowTemplateStage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowTemplate}
 */
proto.api.themakers.sc.WorkflowTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowTemplate;
  return proto.api.themakers.sc.WorkflowTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowTemplate}
 */
proto.api.themakers.sc.WorkflowTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVvs(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatic(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.WorkflowTemplateStage;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowTemplateStage.deserializeBinaryFromReader);
      msg.addStages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVvs();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatic();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.themakers.sc.WorkflowTemplateStage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string VVS = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowTemplate.prototype.getVvs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowTemplate.prototype.setVvs = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool Static = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowTemplate.prototype.getStatic = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.WorkflowTemplate.prototype.setStatic = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated WorkflowTemplateStage Stages = 4;
 * @return {!Array<!proto.api.themakers.sc.WorkflowTemplateStage>}
 */
proto.api.themakers.sc.WorkflowTemplate.prototype.getStagesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.WorkflowTemplateStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.WorkflowTemplateStage, 4));
};


/** @param {!Array<!proto.api.themakers.sc.WorkflowTemplateStage>} value */
proto.api.themakers.sc.WorkflowTemplate.prototype.setStagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.themakers.sc.WorkflowTemplateStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.WorkflowTemplateStage}
 */
proto.api.themakers.sc.WorkflowTemplate.prototype.addStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.themakers.sc.WorkflowTemplateStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.WorkflowTemplate.prototype.clearStagesList = function() {
  this.setStagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowTemplateStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowTemplateStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTemplateStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    approvals: (f = msg.getApprovals()) && proto.api.themakers.sc.WorkflowTemplateStageApprovals.toObject(includeInstance, f),
    tasks: (f = msg.getTasks()) && proto.api.themakers.sc.WorkflowTemplateStageTasks.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowTemplateStage}
 */
proto.api.themakers.sc.WorkflowTemplateStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowTemplateStage;
  return proto.api.themakers.sc.WorkflowTemplateStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowTemplateStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowTemplateStage}
 */
proto.api.themakers.sc.WorkflowTemplateStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.WorkflowTemplateStageApprovals;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowTemplateStageApprovals.deserializeBinaryFromReader);
      msg.setApprovals(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.WorkflowTemplateStageTasks;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowTemplateStageTasks.deserializeBinaryFromReader);
      msg.setTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowTemplateStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowTemplateStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTemplateStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApprovals();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.WorkflowTemplateStageApprovals.serializeBinaryToWriter
    );
  }
  f = message.getTasks();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.WorkflowTemplateStageTasks.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Type = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DisplayName = 2;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.setDisplayname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional WorkflowTemplateStageApprovals Approvals = 3;
 * @return {?proto.api.themakers.sc.WorkflowTemplateStageApprovals}
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.getApprovals = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowTemplateStageApprovals} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowTemplateStageApprovals, 3));
};


/** @param {?proto.api.themakers.sc.WorkflowTemplateStageApprovals|undefined} value */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.setApprovals = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.clearApprovals = function() {
  this.setApprovals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.hasApprovals = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional WorkflowTemplateStageTasks Tasks = 4;
 * @return {?proto.api.themakers.sc.WorkflowTemplateStageTasks}
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.getTasks = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowTemplateStageTasks} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowTemplateStageTasks, 4));
};


/** @param {?proto.api.themakers.sc.WorkflowTemplateStageTasks|undefined} value */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.setTasks = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.clearTasks = function() {
  this.setTasks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowTemplateStage.prototype.hasTasks = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowTemplateStageApprovals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowTemplateStageApprovals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.toObject = function(includeInstance, msg) {
  var f, obj = {
    approvalsList: jspb.Message.toObjectList(msg.getApprovalsList(),
    proto.api.themakers.sc.ApprovalTemplate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowTemplateStageApprovals}
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowTemplateStageApprovals;
  return proto.api.themakers.sc.WorkflowTemplateStageApprovals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowTemplateStageApprovals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowTemplateStageApprovals}
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.ApprovalTemplate;
      reader.readMessage(value,proto.api.themakers.sc.ApprovalTemplate.deserializeBinaryFromReader);
      msg.addApprovals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowTemplateStageApprovals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowTemplateStageApprovals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApprovalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.themakers.sc.ApprovalTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ApprovalTemplate Approvals = 1;
 * @return {!Array<!proto.api.themakers.sc.ApprovalTemplate>}
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.prototype.getApprovalsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.ApprovalTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.ApprovalTemplate, 1));
};


/** @param {!Array<!proto.api.themakers.sc.ApprovalTemplate>} value */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.prototype.setApprovalsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.themakers.sc.ApprovalTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.ApprovalTemplate}
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.prototype.addApprovals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.themakers.sc.ApprovalTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.WorkflowTemplateStageApprovals.prototype.clearApprovalsList = function() {
  this.setApprovalsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.ApprovalTemplate.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ApprovalTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ApprovalTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ApprovalTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actor: (f = msg.getActor()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    specialname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inputsList: jspb.Message.getRepeatedField(msg, 4)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ApprovalTemplate}
 */
proto.api.themakers.sc.ApprovalTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ApprovalTemplate;
  return proto.api.themakers.sc.ApprovalTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ApprovalTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ApprovalTemplate}
 */
proto.api.themakers.sc.ApprovalTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addInputs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ApprovalTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ApprovalTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ApprovalTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getSpecialname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInputsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ApprovalTemplate.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Actor Actor = 2;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 2));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.ApprovalTemplate.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string SpecialName = 3;
 * @return {string}
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.getSpecialname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ApprovalTemplate.prototype.setSpecialname = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string Inputs = 4;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.getInputsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.ApprovalTemplate.prototype.setInputsList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.addInputs = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.ApprovalTemplate.prototype.clearInputsList = function() {
  this.setInputsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowTemplateStageTasks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowTemplateStageTasks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.toObject = function(includeInstance, msg) {
  var f, obj = {
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.api.themakers.sc.TaskTemplate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowTemplateStageTasks}
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowTemplateStageTasks;
  return proto.api.themakers.sc.WorkflowTemplateStageTasks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowTemplateStageTasks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowTemplateStageTasks}
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.TaskTemplate;
      reader.readMessage(value,proto.api.themakers.sc.TaskTemplate.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowTemplateStageTasks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowTemplateStageTasks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.themakers.sc.TaskTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaskTemplate Tasks = 1;
 * @return {!Array<!proto.api.themakers.sc.TaskTemplate>}
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.TaskTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.TaskTemplate, 1));
};


/** @param {!Array<!proto.api.themakers.sc.TaskTemplate>} value */
proto.api.themakers.sc.WorkflowTemplateStageTasks.prototype.setTasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.themakers.sc.TaskTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.TaskTemplate}
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.themakers.sc.TaskTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.WorkflowTemplateStageTasks.prototype.clearTasksList = function() {
  this.setTasksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.TaskTemplate.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.TaskTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.TaskTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.TaskTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assignee: (f = msg.getAssignee()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    confirmer: (f = msg.getConfirmer()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    inputsList: jspb.Message.getRepeatedField(msg, 6)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.TaskTemplate}
 */
proto.api.themakers.sc.TaskTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.TaskTemplate;
  return proto.api.themakers.sc.TaskTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.TaskTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.TaskTemplate}
 */
proto.api.themakers.sc.TaskTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setAssignee(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setConfirmer(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addInputs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.TaskTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.TaskTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.TaskTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssignee();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfirmer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getInputsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.TaskTemplate.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskTemplate.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Actor Assignee = 2;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.TaskTemplate.prototype.getAssignee = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 2));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.TaskTemplate.prototype.setAssignee = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.TaskTemplate.prototype.clearAssignee = function() {
  this.setAssignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.TaskTemplate.prototype.hasAssignee = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Text = 3;
 * @return {string}
 */
proto.api.themakers.sc.TaskTemplate.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskTemplate.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Description = 4;
 * @return {string}
 */
proto.api.themakers.sc.TaskTemplate.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskTemplate.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Actor Confirmer = 5;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.TaskTemplate.prototype.getConfirmer = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 5));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.TaskTemplate.prototype.setConfirmer = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.TaskTemplate.prototype.clearConfirmer = function() {
  this.setConfirmer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.TaskTemplate.prototype.hasConfirmer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string Inputs = 6;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.TaskTemplate.prototype.getInputsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.TaskTemplate.prototype.setInputsList = function(value) {
  jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.TaskTemplate.prototype.addInputs = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.TaskTemplate.prototype.clearInputsList = function() {
  this.setInputsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetWorkflowTemplateReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetWorkflowTemplateReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    template: (f = msg.getTemplate()) && proto.api.themakers.sc.WorkflowTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetWorkflowTemplateReq}
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetWorkflowTemplateReq;
  return proto.api.themakers.sc.SetWorkflowTemplateReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetWorkflowTemplateReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetWorkflowTemplateReq}
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoctype(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.WorkflowTemplate;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetWorkflowTemplateReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetWorkflowTemplateReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.WorkflowTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string DocType = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.prototype.getDoctype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetWorkflowTemplateReq.prototype.setDoctype = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WorkflowTemplate Template = 2;
 * @return {?proto.api.themakers.sc.WorkflowTemplate}
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.prototype.getTemplate = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowTemplate} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowTemplate, 2));
};


/** @param {?proto.api.themakers.sc.WorkflowTemplate|undefined} value */
proto.api.themakers.sc.SetWorkflowTemplateReq.prototype.setTemplate = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.prototype.clearTemplate = function() {
  this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.SetWorkflowTemplateReq.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.GetWorkflowTemplateReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.GetWorkflowTemplateReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.GetWorkflowTemplateReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetWorkflowTemplateReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctype: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.GetWorkflowTemplateReq}
 */
proto.api.themakers.sc.GetWorkflowTemplateReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.GetWorkflowTemplateReq;
  return proto.api.themakers.sc.GetWorkflowTemplateReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.GetWorkflowTemplateReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.GetWorkflowTemplateReq}
 */
proto.api.themakers.sc.GetWorkflowTemplateReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoctype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.GetWorkflowTemplateReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.GetWorkflowTemplateReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.GetWorkflowTemplateReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetWorkflowTemplateReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string DocType = 1;
 * @return {string}
 */
proto.api.themakers.sc.GetWorkflowTemplateReq.prototype.getDoctype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.GetWorkflowTemplateReq.prototype.setDoctype = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Workflow_Approval_AcceptReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Workflow_Approval_AcceptReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Workflow_Approval_AcceptReq}
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Workflow_Approval_AcceptReq;
  return proto.api.themakers.sc.Workflow_Approval_AcceptReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Workflow_Approval_AcceptReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Workflow_Approval_AcceptReq}
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Workflow_Approval_AcceptReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Workflow_Approval_AcceptReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Document = 1;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.prototype.setDocument = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ID = 2;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Approval_AcceptReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Workflow_Approval_DropToReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Workflow_Approval_DropToReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: jspb.Message.getFieldWithDefault(msg, 1, ""),
    approval: jspb.Message.getFieldWithDefault(msg, 2, ""),
    droptoList: jspb.Message.getRepeatedField(msg, 3),
    reason: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Workflow_Approval_DropToReq}
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Workflow_Approval_DropToReq;
  return proto.api.themakers.sc.Workflow_Approval_DropToReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Workflow_Approval_DropToReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Workflow_Approval_DropToReq}
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproval(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDropto(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Workflow_Approval_DropToReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Workflow_Approval_DropToReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApproval();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDroptoList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string Document = 1;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.setDocument = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Approval = 2;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.getApproval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.setApproval = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string DropTo = 3;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.getDroptoList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.setDroptoList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.addDropto = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.clearDroptoList = function() {
  this.setDroptoList([]);
};


/**
 * optional string Reason = 4;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Approval_DropToReq.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Workflow_Task_CompleteReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Workflow_Task_CompleteReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    document: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Workflow_Task_CompleteReq}
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Workflow_Task_CompleteReq;
  return proto.api.themakers.sc.Workflow_Task_CompleteReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Workflow_Task_CompleteReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Workflow_Task_CompleteReq}
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Workflow_Task_CompleteReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Workflow_Task_CompleteReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Task_CompleteReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Document = 2;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Task_CompleteReq.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Task_CompleteReq.prototype.setDocument = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Workflow_Task_ConfirmReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Workflow_Task_ConfirmReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    document: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Workflow_Task_ConfirmReq}
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Workflow_Task_ConfirmReq;
  return proto.api.themakers.sc.Workflow_Task_ConfirmReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Workflow_Task_ConfirmReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Workflow_Task_ConfirmReq}
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Workflow_Task_ConfirmReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Workflow_Task_ConfirmReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Document = 2;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Task_ConfirmReq.prototype.setDocument = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Workflow_Task_RejectReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Workflow_Task_RejectReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Workflow_Task_RejectReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Task_RejectReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    document: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Workflow_Task_RejectReq}
 */
proto.api.themakers.sc.Workflow_Task_RejectReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Workflow_Task_RejectReq;
  return proto.api.themakers.sc.Workflow_Task_RejectReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Workflow_Task_RejectReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Workflow_Task_RejectReq}
 */
proto.api.themakers.sc.Workflow_Task_RejectReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Workflow_Task_RejectReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Workflow_Task_RejectReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Workflow_Task_RejectReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow_Task_RejectReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Task_RejectReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Task_RejectReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Document = 2;
 * @return {string}
 */
proto.api.themakers.sc.Workflow_Task_RejectReq.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Workflow_Task_RejectReq.prototype.setDocument = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Workflow.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Workflow.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Workflow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Workflow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow.toObject = function(includeInstance, msg) {
  var f, obj = {
    stagesList: jspb.Message.toObjectList(msg.getStagesList(),
    proto.api.themakers.sc.WorkflowStage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Workflow}
 */
proto.api.themakers.sc.Workflow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Workflow;
  return proto.api.themakers.sc.Workflow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Workflow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Workflow}
 */
proto.api.themakers.sc.Workflow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.WorkflowStage;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowStage.deserializeBinaryFromReader);
      msg.addStages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Workflow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Workflow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Workflow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Workflow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.themakers.sc.WorkflowStage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WorkflowStage Stages = 1;
 * @return {!Array<!proto.api.themakers.sc.WorkflowStage>}
 */
proto.api.themakers.sc.Workflow.prototype.getStagesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.WorkflowStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.WorkflowStage, 1));
};


/** @param {!Array<!proto.api.themakers.sc.WorkflowStage>} value */
proto.api.themakers.sc.Workflow.prototype.setStagesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.themakers.sc.WorkflowStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.WorkflowStage}
 */
proto.api.themakers.sc.Workflow.prototype.addStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.themakers.sc.WorkflowStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Workflow.prototype.clearStagesList = function() {
  this.setStagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.WorkflowStage.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowStage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    completed: jspb.Message.getFieldWithDefault(msg, 3, false),
    graphList: jspb.Message.toObjectList(msg.getGraphList(),
    proto.api.themakers.sc.WorkflowElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowStage}
 */
proto.api.themakers.sc.WorkflowStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowStage;
  return proto.api.themakers.sc.WorkflowStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowStage}
 */
proto.api.themakers.sc.WorkflowStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.WorkflowElement;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowElement.deserializeBinaryFromReader);
      msg.addGraph(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGraphList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.themakers.sc.WorkflowElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Type = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowStage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowStage.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DisplayName = 2;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowStage.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowStage.prototype.setDisplayname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool Completed = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowStage.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.WorkflowStage.prototype.setCompleted = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated WorkflowElement Graph = 4;
 * @return {!Array<!proto.api.themakers.sc.WorkflowElement>}
 */
proto.api.themakers.sc.WorkflowStage.prototype.getGraphList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.WorkflowElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.WorkflowElement, 4));
};


/** @param {!Array<!proto.api.themakers.sc.WorkflowElement>} value */
proto.api.themakers.sc.WorkflowStage.prototype.setGraphList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.themakers.sc.WorkflowElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.WorkflowElement}
 */
proto.api.themakers.sc.WorkflowStage.prototype.addGraph = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.themakers.sc.WorkflowElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.WorkflowStage.prototype.clearGraphList = function() {
  this.setGraphList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.WorkflowElement.repeatedFields_ = [4,5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.WorkflowElement.oneofGroups_ = [[9,10]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.WorkflowElement.ModelCase = {
  MODEL_NOT_SET: 0,
  APPROVAL: 9,
  TASK: 10
};

/**
 * @return {proto.api.themakers.sc.WorkflowElement.ModelCase}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getModelCase = function() {
  return /** @type {proto.api.themakers.sc.WorkflowElement.ModelCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.WorkflowElement.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowElement.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actor: (f = msg.getActor()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    special: (f = msg.getSpecial()) && proto.api.themakers.sc.WorkflowElementSpecial.toObject(includeInstance, f),
    inputsList: jspb.Message.getRepeatedField(msg, 4),
    outputsList: jspb.Message.getRepeatedField(msg, 5),
    passed: jspb.Message.getFieldWithDefault(msg, 6, false),
    pending: jspb.Message.getFieldWithDefault(msg, 7, false),
    autoaccept: jspb.Message.getFieldWithDefault(msg, 8, false),
    approval: (f = msg.getApproval()) && proto.api.themakers.sc.WorkflowApproval.toObject(includeInstance, f),
    task: (f = msg.getTask()) && proto.api.themakers.sc.WorkflowTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowElement}
 */
proto.api.themakers.sc.WorkflowElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowElement;
  return proto.api.themakers.sc.WorkflowElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowElement}
 */
proto.api.themakers.sc.WorkflowElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.WorkflowElementSpecial;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowElementSpecial.deserializeBinaryFromReader);
      msg.setSpecial(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addInputs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutputs(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassed(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPending(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoaccept(value);
      break;
    case 9:
      var value = new proto.api.themakers.sc.WorkflowApproval;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowApproval.deserializeBinaryFromReader);
      msg.setApproval(value);
      break;
    case 10:
      var value = new proto.api.themakers.sc.WorkflowTask;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowTask.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getSpecial();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.WorkflowElementSpecial.serializeBinaryToWriter
    );
  }
  f = message.getInputsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getOutputsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getPassed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPending();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAutoaccept();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getApproval();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.api.themakers.sc.WorkflowApproval.serializeBinaryToWriter
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.themakers.sc.WorkflowTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowElement.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Actor Actor = 2;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 2));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.WorkflowElement.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowElement.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowElement.prototype.hasActor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WorkflowElementSpecial Special = 3;
 * @return {?proto.api.themakers.sc.WorkflowElementSpecial}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getSpecial = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowElementSpecial} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowElementSpecial, 3));
};


/** @param {?proto.api.themakers.sc.WorkflowElementSpecial|undefined} value */
proto.api.themakers.sc.WorkflowElement.prototype.setSpecial = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowElement.prototype.clearSpecial = function() {
  this.setSpecial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowElement.prototype.hasSpecial = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string Inputs = 4;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getInputsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.WorkflowElement.prototype.setInputsList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.WorkflowElement.prototype.addInputs = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.WorkflowElement.prototype.clearInputsList = function() {
  this.setInputsList([]);
};


/**
 * repeated string Outputs = 5;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getOutputsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.WorkflowElement.prototype.setOutputsList = function(value) {
  jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.WorkflowElement.prototype.addOutputs = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.WorkflowElement.prototype.clearOutputsList = function() {
  this.setOutputsList([]);
};


/**
 * optional bool Passed = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getPassed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.WorkflowElement.prototype.setPassed = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool Pending = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getPending = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.WorkflowElement.prototype.setPending = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool AutoAccept = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getAutoaccept = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.WorkflowElement.prototype.setAutoaccept = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional WorkflowApproval Approval = 9;
 * @return {?proto.api.themakers.sc.WorkflowApproval}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getApproval = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowApproval} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowApproval, 9));
};


/** @param {?proto.api.themakers.sc.WorkflowApproval|undefined} value */
proto.api.themakers.sc.WorkflowElement.prototype.setApproval = function(value) {
  jspb.Message.setOneofWrapperField(this, 9, proto.api.themakers.sc.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowElement.prototype.clearApproval = function() {
  this.setApproval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowElement.prototype.hasApproval = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional WorkflowTask Task = 10;
 * @return {?proto.api.themakers.sc.WorkflowTask}
 */
proto.api.themakers.sc.WorkflowElement.prototype.getTask = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowTask} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowTask, 10));
};


/** @param {?proto.api.themakers.sc.WorkflowTask|undefined} value */
proto.api.themakers.sc.WorkflowElement.prototype.setTask = function(value) {
  jspb.Message.setOneofWrapperField(this, 10, proto.api.themakers.sc.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowElement.prototype.clearTask = function() {
  this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowElement.prototype.hasTask = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowElementSpecial.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowElementSpecial.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowElementSpecial} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowElementSpecial.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowElementSpecial}
 */
proto.api.themakers.sc.WorkflowElementSpecial.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowElementSpecial;
  return proto.api.themakers.sc.WorkflowElementSpecial.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowElementSpecial} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowElementSpecial}
 */
proto.api.themakers.sc.WorkflowElementSpecial.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowElementSpecial.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowElementSpecial.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowElementSpecial} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowElementSpecial.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowElementSpecial.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowElementSpecial.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowApproval.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowApproval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowApproval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowApproval.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowApproval}
 */
proto.api.themakers.sc.WorkflowApproval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowApproval;
  return proto.api.themakers.sc.WorkflowApproval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowApproval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowApproval}
 */
proto.api.themakers.sc.WorkflowApproval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowApproval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowApproval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowApproval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowApproval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowTask.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowTask}
 */
proto.api.themakers.sc.WorkflowTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowTask;
  return proto.api.themakers.sc.WorkflowTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowTask}
 */
proto.api.themakers.sc.WorkflowTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowTask.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowTask.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Description = 2;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowTask.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowTask.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowTask.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowTask.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.WorkflowEvent.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.WorkflowEvent.ModelCase = {
  MODEL_NOT_SET: 0,
  APPROVAL: 5,
  TASK: 6
};

/**
 * @return {proto.api.themakers.sc.WorkflowEvent.ModelCase}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.getModelCase = function() {
  return /** @type {proto.api.themakers.sc.WorkflowEvent.ModelCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.WorkflowEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    document: (f = msg.getDocument()) && proto.api.themakers.sc.WorkflowEventDocument.toObject(includeInstance, f),
    actor: (f = msg.getActor()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    approval: (f = msg.getApproval()) && proto.api.themakers.sc.WorkflowEventApproval.toObject(includeInstance, f),
    task: (f = msg.getTask()) && proto.api.themakers.sc.WorkflowEventTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowEvent}
 */
proto.api.themakers.sc.WorkflowEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowEvent;
  return proto.api.themakers.sc.WorkflowEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowEvent}
 */
proto.api.themakers.sc.WorkflowEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.WorkflowEventDocument;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowEventDocument.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.api.themakers.sc.WorkflowEventApproval;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowEventApproval.deserializeBinaryFromReader);
      msg.setApproval(value);
      break;
    case 6:
      var value = new proto.api.themakers.sc.WorkflowEventTask;
      reader.readMessage(value,proto.api.themakers.sc.WorkflowEventTask.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.WorkflowEventDocument.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getApproval();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.themakers.sc.WorkflowEventApproval.serializeBinaryToWriter
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.themakers.sc.WorkflowEventTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowEvent.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WorkflowEventDocument Document = 2;
 * @return {?proto.api.themakers.sc.WorkflowEventDocument}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.getDocument = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowEventDocument} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowEventDocument, 2));
};


/** @param {?proto.api.themakers.sc.WorkflowEventDocument|undefined} value */
proto.api.themakers.sc.WorkflowEvent.prototype.setDocument = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowEvent.prototype.clearDocument = function() {
  this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Actor Actor = 3;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 3));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.WorkflowEvent.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowEvent.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.hasActor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Type = 4;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowEvent.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional WorkflowEventApproval Approval = 5;
 * @return {?proto.api.themakers.sc.WorkflowEventApproval}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.getApproval = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowEventApproval} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowEventApproval, 5));
};


/** @param {?proto.api.themakers.sc.WorkflowEventApproval|undefined} value */
proto.api.themakers.sc.WorkflowEvent.prototype.setApproval = function(value) {
  jspb.Message.setOneofWrapperField(this, 5, proto.api.themakers.sc.WorkflowEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowEvent.prototype.clearApproval = function() {
  this.setApproval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.hasApproval = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional WorkflowEventTask Task = 6;
 * @return {?proto.api.themakers.sc.WorkflowEventTask}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.getTask = function() {
  return /** @type{?proto.api.themakers.sc.WorkflowEventTask} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.WorkflowEventTask, 6));
};


/** @param {?proto.api.themakers.sc.WorkflowEventTask|undefined} value */
proto.api.themakers.sc.WorkflowEvent.prototype.setTask = function(value) {
  jspb.Message.setOneofWrapperField(this, 6, proto.api.themakers.sc.WorkflowEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.WorkflowEvent.prototype.clearTask = function() {
  this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.WorkflowEvent.prototype.hasTask = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowEventApproval.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowEventApproval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowEventApproval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowEventApproval.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowEventApproval}
 */
proto.api.themakers.sc.WorkflowEventApproval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowEventApproval;
  return proto.api.themakers.sc.WorkflowEventApproval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowEventApproval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowEventApproval}
 */
proto.api.themakers.sc.WorkflowEventApproval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowEventApproval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowEventApproval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowEventApproval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowEventApproval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowEventTask.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowEventTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowEventTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowEventTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowEventTask}
 */
proto.api.themakers.sc.WorkflowEventTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowEventTask;
  return proto.api.themakers.sc.WorkflowEventTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowEventTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowEventTask}
 */
proto.api.themakers.sc.WorkflowEventTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowEventTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowEventTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowEventTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowEventTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowEventTask.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowEventTask.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Description = 2;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowEventTask.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowEventTask.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowEventTask.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowEventTask.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.WorkflowEventDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.WorkflowEventDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.WorkflowEventDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowEventDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creationtime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.WorkflowEventDocument}
 */
proto.api.themakers.sc.WorkflowEventDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.WorkflowEventDocument;
  return proto.api.themakers.sc.WorkflowEventDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.WorkflowEventDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.WorkflowEventDocument}
 */
proto.api.themakers.sc.WorkflowEventDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.WorkflowEventDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.WorkflowEventDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.WorkflowEventDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.WorkflowEventDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreationtime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowEventDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowEventDocument.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowEventDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowEventDocument.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.WorkflowEventDocument.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.WorkflowEventDocument.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 CreationTime = 4;
 * @return {number}
 */
proto.api.themakers.sc.WorkflowEventDocument.prototype.getCreationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.api.themakers.sc.WorkflowEventDocument.prototype.setCreationtime = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DelegateTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DelegateTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DelegateTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DelegateTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newassigneeid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newconfirmerid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DelegateTaskReq}
 */
proto.api.themakers.sc.DelegateTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DelegateTaskReq;
  return proto.api.themakers.sc.DelegateTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DelegateTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DelegateTaskReq}
 */
proto.api.themakers.sc.DelegateTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewassigneeid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewconfirmerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DelegateTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DelegateTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DelegateTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DelegateTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewassigneeid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewconfirmerid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string TaskID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DelegateTaskReq.prototype.getTaskid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DelegateTaskReq.prototype.setTaskid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string NewAssigneeID = 2;
 * @return {string}
 */
proto.api.themakers.sc.DelegateTaskReq.prototype.getNewassigneeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DelegateTaskReq.prototype.setNewassigneeid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string NewConfirmerID = 3;
 * @return {string}
 */
proto.api.themakers.sc.DelegateTaskReq.prototype.getNewconfirmerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DelegateTaskReq.prototype.setNewconfirmerid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ArchivedTasksStreamReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ArchivedTasksStreamReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ArchivedTasksStreamReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedTasksStreamReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ArchivedTasksStreamReq}
 */
proto.api.themakers.sc.ArchivedTasksStreamReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ArchivedTasksStreamReq;
  return proto.api.themakers.sc.ArchivedTasksStreamReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ArchivedTasksStreamReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ArchivedTasksStreamReq}
 */
proto.api.themakers.sc.ArchivedTasksStreamReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ArchivedTasksStreamReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ArchivedTasksStreamReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ArchivedTasksStreamReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedTasksStreamReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.ArchivedtasksstreammessageCase = {
  ARCHIVEDTASKSSTREAMMESSAGE_NOT_SET: 0,
  EOS: 1,
  TASK: 2
};

/**
 * @return {proto.api.themakers.sc.ArchivedTasksStreamMessage.ArchivedtasksstreammessageCase}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.getArchivedtasksstreammessageCase = function() {
  return /** @type {proto.api.themakers.sc.ArchivedTasksStreamMessage.ArchivedtasksstreammessageCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.ArchivedTasksStreamMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ArchivedTasksStreamMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ArchivedTasksStreamMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    eos: jspb.Message.getFieldWithDefault(msg, 1, ""),
    task: (f = msg.getTask()) && proto.api.themakers.sc.Task.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ArchivedTasksStreamMessage}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ArchivedTasksStreamMessage;
  return proto.api.themakers.sc.ArchivedTasksStreamMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ArchivedTasksStreamMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ArchivedTasksStreamMessage}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEos(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Task;
      reader.readMessage(value,proto.api.themakers.sc.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ArchivedTasksStreamMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ArchivedTasksStreamMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Task.serializeBinaryToWriter
    );
  }
};


/**
 * optional string EOS = 1;
 * @return {string}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.getEos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.setEos = function(value) {
  jspb.Message.setOneofField(this, 1, proto.api.themakers.sc.ArchivedTasksStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.clearEos = function() {
  jspb.Message.setOneofField(this, 1, proto.api.themakers.sc.ArchivedTasksStreamMessage.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.hasEos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Task Task = 2;
 * @return {?proto.api.themakers.sc.Task}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.getTask = function() {
  return /** @type{?proto.api.themakers.sc.Task} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Task, 2));
};


/** @param {?proto.api.themakers.sc.Task|undefined} value */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.setTask = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.api.themakers.sc.ArchivedTasksStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.clearTask = function() {
  this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.ArchivedTasksStreamMessage.prototype.hasTask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.TaskStreamReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.TaskStreamReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.TaskStreamReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskStreamReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.TaskStreamReq}
 */
proto.api.themakers.sc.TaskStreamReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.TaskStreamReq;
  return proto.api.themakers.sc.TaskStreamReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.TaskStreamReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.TaskStreamReq}
 */
proto.api.themakers.sc.TaskStreamReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.TaskStreamReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.TaskStreamReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.TaskStreamReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskStreamReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string TaskID = 1;
 * @return {string}
 */
proto.api.themakers.sc.TaskStreamReq.prototype.getTaskid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskStreamReq.prototype.setTaskid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.TaskEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.TaskEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.TaskEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actor: (f = msg.getActor()) && proto.api.themakers.sc.UserInfo.toObject(includeInstance, f),
    ts: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fieldsMap: (f = msg.getFieldsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.TaskEvent}
 */
proto.api.themakers.sc.TaskEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.TaskEvent;
  return proto.api.themakers.sc.TaskEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.TaskEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.TaskEvent}
 */
proto.api.themakers.sc.TaskEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.UserInfo;
      reader.readMessage(value,proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTs(value);
      break;
    case 6:
      var value = msg.getFieldsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.TaskEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.TaskEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.TaskEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getFieldsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.TaskEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskEvent.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TaskID = 2;
 * @return {string}
 */
proto.api.themakers.sc.TaskEvent.prototype.getTaskid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskEvent.prototype.setTaskid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.TaskEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskEvent.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserInfo Actor = 4;
 * @return {?proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.TaskEvent.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserInfo, 4));
};


/** @param {?proto.api.themakers.sc.UserInfo|undefined} value */
proto.api.themakers.sc.TaskEvent.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.TaskEvent.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.TaskEvent.prototype.hasActor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 TS = 5;
 * @return {number}
 */
proto.api.themakers.sc.TaskEvent.prototype.getTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.api.themakers.sc.TaskEvent.prototype.setTs = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * map<string, string> Fields = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.themakers.sc.TaskEvent.prototype.getFieldsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.api.themakers.sc.TaskEvent.prototype.clearFieldsMap = function() {
  this.getFieldsMap().clear();
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.TaskStreamMessage.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.TaskStreamMessage.TaskstreammessageCase = {
  TASKSTREAMMESSAGE_NOT_SET: 0,
  TASK: 1,
  EVENT: 2
};

/**
 * @return {proto.api.themakers.sc.TaskStreamMessage.TaskstreammessageCase}
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.getTaskstreammessageCase = function() {
  return /** @type {proto.api.themakers.sc.TaskStreamMessage.TaskstreammessageCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.TaskStreamMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.TaskStreamMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.TaskStreamMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskStreamMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: (f = msg.getTask()) && proto.api.themakers.sc.Task.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && proto.api.themakers.sc.TaskEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.TaskStreamMessage}
 */
proto.api.themakers.sc.TaskStreamMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.TaskStreamMessage;
  return proto.api.themakers.sc.TaskStreamMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.TaskStreamMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.TaskStreamMessage}
 */
proto.api.themakers.sc.TaskStreamMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.Task;
      reader.readMessage(value,proto.api.themakers.sc.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.TaskEvent;
      reader.readMessage(value,proto.api.themakers.sc.TaskEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.TaskStreamMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.TaskStreamMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskStreamMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.Task.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.TaskEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional Task Task = 1;
 * @return {?proto.api.themakers.sc.Task}
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.getTask = function() {
  return /** @type{?proto.api.themakers.sc.Task} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Task, 1));
};


/** @param {?proto.api.themakers.sc.Task|undefined} value */
proto.api.themakers.sc.TaskStreamMessage.prototype.setTask = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.api.themakers.sc.TaskStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.clearTask = function() {
  this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.hasTask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TaskEvent Event = 2;
 * @return {?proto.api.themakers.sc.TaskEvent}
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.getEvent = function() {
  return /** @type{?proto.api.themakers.sc.TaskEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.TaskEvent, 2));
};


/** @param {?proto.api.themakers.sc.TaskEvent|undefined} value */
proto.api.themakers.sc.TaskStreamMessage.prototype.setEvent = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.api.themakers.sc.TaskStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.clearEvent = function() {
  this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.TaskStreamMessage.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.TaskPutMessageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.TaskPutMessageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.TaskPutMessageReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskPutMessageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.TaskPutMessageReq}
 */
proto.api.themakers.sc.TaskPutMessageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.TaskPutMessageReq;
  return proto.api.themakers.sc.TaskPutMessageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.TaskPutMessageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.TaskPutMessageReq}
 */
proto.api.themakers.sc.TaskPutMessageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.TaskPutMessageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.TaskPutMessageReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.TaskPutMessageReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskPutMessageReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string TaskID = 1;
 * @return {string}
 */
proto.api.themakers.sc.TaskPutMessageReq.prototype.getTaskid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskPutMessageReq.prototype.setTaskid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Message = 2;
 * @return {string}
 */
proto.api.themakers.sc.TaskPutMessageReq.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskPutMessageReq.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Task.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    author: (f = msg.getAuthor()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    assignee: (f = msg.getAssignee()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    text: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    confirmer: (f = msg.getConfirmer()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    document: (f = msg.getDocument()) && proto.api.themakers.sc.TaskDocument.toObject(includeInstance, f),
    completed: jspb.Message.getFieldWithDefault(msg, 8, false),
    confirmed: jspb.Message.getFieldWithDefault(msg, 9, false),
    closed: jspb.Message.getFieldWithDefault(msg, 10, false),
    parent: jspb.Message.getFieldWithDefault(msg, 11, ""),
    childrenList: jspb.Message.getRepeatedField(msg, 12),
    creationtime: jspb.Message.getFieldWithDefault(msg, 13, 0),
    duetime: jspb.Message.getFieldWithDefault(msg, 14, 0),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Task}
 */
proto.api.themakers.sc.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Task;
  return proto.api.themakers.sc.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Task}
 */
proto.api.themakers.sc.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setAssignee(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setConfirmer(value);
      break;
    case 7:
      var value = new proto.api.themakers.sc.TaskDocument;
      reader.readMessage(value,proto.api.themakers.sc.TaskDocument.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmed(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClosed(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildren(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationtime(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuetime(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getAssignee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConfirmer();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.themakers.sc.TaskDocument.serializeBinaryToWriter
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getConfirmed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getClosed();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getCreationtime();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDuetime();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Task.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Task.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Actor Author = 2;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.Task.prototype.getAuthor = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 2));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.Task.prototype.setAuthor = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Task.prototype.clearAuthor = function() {
  this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Task.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Actor Assignee = 3;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.Task.prototype.getAssignee = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 3));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.Task.prototype.setAssignee = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Task.prototype.clearAssignee = function() {
  this.setAssignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Task.prototype.hasAssignee = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Text = 4;
 * @return {string}
 */
proto.api.themakers.sc.Task.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Task.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Description = 5;
 * @return {string}
 */
proto.api.themakers.sc.Task.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Task.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Actor Confirmer = 6;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.Task.prototype.getConfirmer = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 6));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.Task.prototype.setConfirmer = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Task.prototype.clearConfirmer = function() {
  this.setConfirmer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Task.prototype.hasConfirmer = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TaskDocument Document = 7;
 * @return {?proto.api.themakers.sc.TaskDocument}
 */
proto.api.themakers.sc.Task.prototype.getDocument = function() {
  return /** @type{?proto.api.themakers.sc.TaskDocument} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.TaskDocument, 7));
};


/** @param {?proto.api.themakers.sc.TaskDocument|undefined} value */
proto.api.themakers.sc.Task.prototype.setDocument = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Task.prototype.clearDocument = function() {
  this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Task.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool Completed = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Task.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Task.prototype.setCompleted = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool Confirmed = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Task.prototype.getConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Task.prototype.setConfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool Closed = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Task.prototype.getClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Task.prototype.setClosed = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string Parent = 11;
 * @return {string}
 */
proto.api.themakers.sc.Task.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Task.prototype.setParent = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string Children = 12;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.Task.prototype.getChildrenList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.Task.prototype.setChildrenList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.Task.prototype.addChildren = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Task.prototype.clearChildrenList = function() {
  this.setChildrenList([]);
};


/**
 * optional int64 CreationTime = 13;
 * @return {number}
 */
proto.api.themakers.sc.Task.prototype.getCreationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Task.prototype.setCreationtime = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 DueTime = 14;
 * @return {number}
 */
proto.api.themakers.sc.Task.prototype.getDuetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Task.prototype.setDuetime = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.Task.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Task.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.TaskDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.TaskDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.TaskDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creationtime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.TaskDocument}
 */
proto.api.themakers.sc.TaskDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.TaskDocument;
  return proto.api.themakers.sc.TaskDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.TaskDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.TaskDocument}
 */
proto.api.themakers.sc.TaskDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.TaskDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.TaskDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.TaskDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TaskDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreationtime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.TaskDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskDocument.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.TaskDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskDocument.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.TaskDocument.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TaskDocument.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 CreationTime = 4;
 * @return {number}
 */
proto.api.themakers.sc.TaskDocument.prototype.getCreationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.api.themakers.sc.TaskDocument.prototype.setCreationtime = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.CreateTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.CreateTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorunitid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assignee: (f = msg.getAssignee()) && proto.api.themakers.sc.ActorIDs.toObject(includeInstance, f),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    confirmer: (f = msg.getConfirmer()) && proto.api.themakers.sc.ActorIDs.toObject(includeInstance, f),
    document: jspb.Message.getFieldWithDefault(msg, 6, ""),
    parent: jspb.Message.getFieldWithDefault(msg, 7, ""),
    duetime: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.CreateTaskReq}
 */
proto.api.themakers.sc.CreateTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.CreateTaskReq;
  return proto.api.themakers.sc.CreateTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.CreateTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.CreateTaskReq}
 */
proto.api.themakers.sc.CreateTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorunitid(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.ActorIDs;
      reader.readMessage(value,proto.api.themakers.sc.ActorIDs.deserializeBinaryFromReader);
      msg.setAssignee(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto.api.themakers.sc.ActorIDs;
      reader.readMessage(value,proto.api.themakers.sc.ActorIDs.deserializeBinaryFromReader);
      msg.setConfirmer(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.CreateTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.CreateTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorunitid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssignee();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.ActorIDs.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfirmer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.themakers.sc.ActorIDs.serializeBinaryToWriter
    );
  }
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDuetime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string AuthorUnitID = 1;
 * @return {string}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.getAuthorunitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateTaskReq.prototype.setAuthorunitid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActorIDs Assignee = 2;
 * @return {?proto.api.themakers.sc.ActorIDs}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.getAssignee = function() {
  return /** @type{?proto.api.themakers.sc.ActorIDs} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.ActorIDs, 2));
};


/** @param {?proto.api.themakers.sc.ActorIDs|undefined} value */
proto.api.themakers.sc.CreateTaskReq.prototype.setAssignee = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.CreateTaskReq.prototype.clearAssignee = function() {
  this.setAssignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.hasAssignee = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Text = 3;
 * @return {string}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateTaskReq.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Description = 4;
 * @return {string}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateTaskReq.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ActorIDs Confirmer = 5;
 * @return {?proto.api.themakers.sc.ActorIDs}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.getConfirmer = function() {
  return /** @type{?proto.api.themakers.sc.ActorIDs} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.ActorIDs, 5));
};


/** @param {?proto.api.themakers.sc.ActorIDs|undefined} value */
proto.api.themakers.sc.CreateTaskReq.prototype.setConfirmer = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.CreateTaskReq.prototype.clearConfirmer = function() {
  this.setConfirmer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.hasConfirmer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string Document = 6;
 * @return {string}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateTaskReq.prototype.setDocument = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Parent = 7;
 * @return {string}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateTaskReq.prototype.setParent = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 DueTime = 8;
 * @return {number}
 */
proto.api.themakers.sc.CreateTaskReq.prototype.getDuetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.api.themakers.sc.CreateTaskReq.prototype.setDuetime = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.CompleteTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.CompleteTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.CompleteTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CompleteTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.CompleteTaskReq}
 */
proto.api.themakers.sc.CompleteTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.CompleteTaskReq;
  return proto.api.themakers.sc.CompleteTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.CompleteTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.CompleteTaskReq}
 */
proto.api.themakers.sc.CompleteTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.CompleteTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.CompleteTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.CompleteTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CompleteTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.CompleteTaskReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CompleteTaskReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RejectTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RejectTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RejectTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RejectTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RejectTaskReq}
 */
proto.api.themakers.sc.RejectTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RejectTaskReq;
  return proto.api.themakers.sc.RejectTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RejectTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RejectTaskReq}
 */
proto.api.themakers.sc.RejectTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RejectTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RejectTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RejectTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RejectTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RejectTaskReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RejectTaskReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ConfirmTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ConfirmTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ConfirmTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ConfirmTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ConfirmTaskReq}
 */
proto.api.themakers.sc.ConfirmTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ConfirmTaskReq;
  return proto.api.themakers.sc.ConfirmTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ConfirmTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ConfirmTaskReq}
 */
proto.api.themakers.sc.ConfirmTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ConfirmTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ConfirmTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ConfirmTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ConfirmTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.ConfirmTaskReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ConfirmTaskReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ReopenReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ReopenReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ReopenReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ReopenReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ReopenReq}
 */
proto.api.themakers.sc.ReopenReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ReopenReq;
  return proto.api.themakers.sc.ReopenReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ReopenReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ReopenReq}
 */
proto.api.themakers.sc.ReopenReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ReopenReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ReopenReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ReopenReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ReopenReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.ReopenReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ReopenReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.CloseTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.CloseTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.CloseTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CloseTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.CloseTaskReq}
 */
proto.api.themakers.sc.CloseTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.CloseTaskReq;
  return proto.api.themakers.sc.CloseTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.CloseTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.CloseTaskReq}
 */
proto.api.themakers.sc.CloseTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.CloseTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.CloseTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.CloseTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CloseTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.CloseTaskReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CloseTaskReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveTaskReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveTaskReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveTaskReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveTaskReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveTaskReq}
 */
proto.api.themakers.sc.RemoveTaskReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveTaskReq;
  return proto.api.themakers.sc.RemoveTaskReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveTaskReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveTaskReq}
 */
proto.api.themakers.sc.RemoveTaskReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveTaskReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveTaskReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveTaskReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveTaskReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveTaskReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveTaskReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Unit.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Unit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Unit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Unit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    centerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    root: jspb.Message.getFieldWithDefault(msg, 4, false),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    code: jspb.Message.getFieldWithDefault(msg, 6, ""),
    oneass: (f = msg.getOneass()) && proto.api.themakers.sc.UnitOneAss.toObject(includeInstance, f),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Unit}
 */
proto.api.themakers.sc.Unit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Unit;
  return proto.api.themakers.sc.Unit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Unit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Unit}
 */
proto.api.themakers.sc.Unit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRoot(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 7:
      var value = new proto.api.themakers.sc.UnitOneAss;
      reader.readMessage(value,proto.api.themakers.sc.UnitOneAss.deserializeBinaryFromReader);
      msg.setOneass(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Unit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Unit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Unit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Unit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRoot();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOneass();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.themakers.sc.UnitOneAss.serializeBinaryToWriter
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Unit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Unit.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CenterID = 2;
 * @return {string}
 */
proto.api.themakers.sc.Unit.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Unit.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Parent = 3;
 * @return {string}
 */
proto.api.themakers.sc.Unit.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Unit.prototype.setParent = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool Root = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Unit.prototype.getRoot = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Unit.prototype.setRoot = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string Name = 5;
 * @return {string}
 */
proto.api.themakers.sc.Unit.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Unit.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Code = 6;
 * @return {string}
 */
proto.api.themakers.sc.Unit.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Unit.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional UnitOneAss OneAss = 7;
 * @return {?proto.api.themakers.sc.UnitOneAss}
 */
proto.api.themakers.sc.Unit.prototype.getOneass = function() {
  return /** @type{?proto.api.themakers.sc.UnitOneAss} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UnitOneAss, 7));
};


/** @param {?proto.api.themakers.sc.UnitOneAss|undefined} value */
proto.api.themakers.sc.Unit.prototype.setOneass = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Unit.prototype.clearOneass = function() {
  this.setOneass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Unit.prototype.hasOneass = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.Unit.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Unit.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.UnitOneAss.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UnitOneAss.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UnitOneAss.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UnitOneAss} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UnitOneAss.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientidsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UnitOneAss}
 */
proto.api.themakers.sc.UnitOneAss.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UnitOneAss;
  return proto.api.themakers.sc.UnitOneAss.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UnitOneAss} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UnitOneAss}
 */
proto.api.themakers.sc.UnitOneAss.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addClientids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UnitOneAss.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UnitOneAss.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UnitOneAss} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UnitOneAss.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ClientIDs = 1;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.UnitOneAss.prototype.getClientidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.UnitOneAss.prototype.setClientidsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.UnitOneAss.prototype.addClientids = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.UnitOneAss.prototype.clearClientidsList = function() {
  this.setClientidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.CreateUnitReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.CreateUnitReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.CreateUnitReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateUnitReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parent: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.CreateUnitReq}
 */
proto.api.themakers.sc.CreateUnitReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.CreateUnitReq;
  return proto.api.themakers.sc.CreateUnitReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.CreateUnitReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.CreateUnitReq}
 */
proto.api.themakers.sc.CreateUnitReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.CreateUnitReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.CreateUnitReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.CreateUnitReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateUnitReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.api.themakers.sc.CreateUnitReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateUnitReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Parent = 2;
 * @return {string}
 */
proto.api.themakers.sc.CreateUnitReq.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateUnitReq.prototype.setParent = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveUnitReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveUnitReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveUnitReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveUnitReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveUnitReq}
 */
proto.api.themakers.sc.RemoveUnitReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveUnitReq;
  return proto.api.themakers.sc.RemoveUnitReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveUnitReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveUnitReq}
 */
proto.api.themakers.sc.RemoveUnitReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveUnitReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveUnitReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveUnitReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveUnitReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveUnitReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveUnitReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.GetTosserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.GetTosserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.GetTosserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetTosserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.GetTosserReq}
 */
proto.api.themakers.sc.GetTosserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.GetTosserReq;
  return proto.api.themakers.sc.GetTosserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.GetTosserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.GetTosserReq}
 */
proto.api.themakers.sc.GetTosserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.GetTosserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.GetTosserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.GetTosserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetTosserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string UnitID = 1;
 * @return {string}
 */
proto.api.themakers.sc.GetTosserReq.prototype.getUnitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.GetTosserReq.prototype.setUnitid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.UnitTosser.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UnitTosser.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UnitTosser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UnitTosser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UnitTosser.toObject = function(includeInstance, msg) {
  var f, obj = {
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    proto.api.themakers.sc.TosserRule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UnitTosser}
 */
proto.api.themakers.sc.UnitTosser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UnitTosser;
  return proto.api.themakers.sc.UnitTosser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UnitTosser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UnitTosser}
 */
proto.api.themakers.sc.UnitTosser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.TosserRule;
      reader.readMessage(value,proto.api.themakers.sc.TosserRule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UnitTosser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UnitTosser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UnitTosser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UnitTosser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.themakers.sc.TosserRule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TosserRule Rules = 1;
 * @return {!Array<!proto.api.themakers.sc.TosserRule>}
 */
proto.api.themakers.sc.UnitTosser.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.TosserRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.TosserRule, 1));
};


/** @param {!Array<!proto.api.themakers.sc.TosserRule>} value */
proto.api.themakers.sc.UnitTosser.prototype.setRulesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.themakers.sc.TosserRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.TosserRule}
 */
proto.api.themakers.sc.UnitTosser.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.themakers.sc.TosserRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.UnitTosser.prototype.clearRulesList = function() {
  this.setRulesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.TosserRule.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.TosserRule.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.TosserRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.TosserRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TosserRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    documenttype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    termsList: jspb.Message.toObjectList(msg.getTermsList(),
    proto.api.themakers.sc.TosserRuleTerm.toObject, includeInstance),
    actor: (f = msg.getActor()) && proto.api.themakers.sc.ActorIDs.toObject(includeInstance, f),
    autoaccept: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.TosserRule}
 */
proto.api.themakers.sc.TosserRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.TosserRule;
  return proto.api.themakers.sc.TosserRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.TosserRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.TosserRule}
 */
proto.api.themakers.sc.TosserRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumenttype(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.TosserRuleTerm;
      reader.readMessage(value,proto.api.themakers.sc.TosserRuleTerm.deserializeBinaryFromReader);
      msg.addTerms(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.ActorIDs;
      reader.readMessage(value,proto.api.themakers.sc.ActorIDs.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoaccept(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.TosserRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.TosserRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.TosserRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TosserRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumenttype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTermsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.themakers.sc.TosserRuleTerm.serializeBinaryToWriter
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.ActorIDs.serializeBinaryToWriter
    );
  }
  f = message.getAutoaccept();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string DocumentType = 2;
 * @return {string}
 */
proto.api.themakers.sc.TosserRule.prototype.getDocumenttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TosserRule.prototype.setDocumenttype = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TosserRuleTerm Terms = 3;
 * @return {!Array<!proto.api.themakers.sc.TosserRuleTerm>}
 */
proto.api.themakers.sc.TosserRule.prototype.getTermsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.TosserRuleTerm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.TosserRuleTerm, 3));
};


/** @param {!Array<!proto.api.themakers.sc.TosserRuleTerm>} value */
proto.api.themakers.sc.TosserRule.prototype.setTermsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.themakers.sc.TosserRuleTerm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.TosserRuleTerm}
 */
proto.api.themakers.sc.TosserRule.prototype.addTerms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.themakers.sc.TosserRuleTerm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.TosserRule.prototype.clearTermsList = function() {
  this.setTermsList([]);
};


/**
 * optional ActorIDs Actor = 4;
 * @return {?proto.api.themakers.sc.ActorIDs}
 */
proto.api.themakers.sc.TosserRule.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.ActorIDs} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.ActorIDs, 4));
};


/** @param {?proto.api.themakers.sc.ActorIDs|undefined} value */
proto.api.themakers.sc.TosserRule.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.TosserRule.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.TosserRule.prototype.hasActor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool AutoAccept = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.TosserRule.prototype.getAutoaccept = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.TosserRule.prototype.setAutoaccept = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.TosserRuleTerm.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.TosserRuleTerm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.TosserRuleTerm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TosserRuleTerm.toObject = function(includeInstance, msg) {
  var f, obj = {
    requisite: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.TosserRuleTerm}
 */
proto.api.themakers.sc.TosserRuleTerm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.TosserRuleTerm;
  return proto.api.themakers.sc.TosserRuleTerm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.TosserRuleTerm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.TosserRuleTerm}
 */
proto.api.themakers.sc.TosserRuleTerm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequisite(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.TosserRuleTerm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.TosserRuleTerm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.TosserRuleTerm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.TosserRuleTerm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequisite();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Requisite = 1;
 * @return {string}
 */
proto.api.themakers.sc.TosserRuleTerm.prototype.getRequisite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TosserRuleTerm.prototype.setRequisite = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Operator = 2;
 * @return {string}
 */
proto.api.themakers.sc.TosserRuleTerm.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TosserRuleTerm.prototype.setOperator = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Value = 3;
 * @return {string}
 */
proto.api.themakers.sc.TosserRuleTerm.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.TosserRuleTerm.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetTosserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetTosserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetTosserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetTosserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tosser: (f = msg.getTosser()) && proto.api.themakers.sc.UnitTosser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetTosserReq}
 */
proto.api.themakers.sc.SetTosserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetTosserReq;
  return proto.api.themakers.sc.SetTosserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetTosserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetTosserReq}
 */
proto.api.themakers.sc.SetTosserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitid(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.UnitTosser;
      reader.readMessage(value,proto.api.themakers.sc.UnitTosser.deserializeBinaryFromReader);
      msg.setTosser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetTosserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetTosserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetTosserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetTosserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTosser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.UnitTosser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string UnitID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetTosserReq.prototype.getUnitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetTosserReq.prototype.setUnitid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UnitTosser Tosser = 2;
 * @return {?proto.api.themakers.sc.UnitTosser}
 */
proto.api.themakers.sc.SetTosserReq.prototype.getTosser = function() {
  return /** @type{?proto.api.themakers.sc.UnitTosser} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UnitTosser, 2));
};


/** @param {?proto.api.themakers.sc.UnitTosser|undefined} value */
proto.api.themakers.sc.SetTosserReq.prototype.setTosser = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.SetTosserReq.prototype.clearTosser = function() {
  this.setTosser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.SetTosserReq.prototype.hasTosser = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.FamiliarizationStreamReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.FamiliarizationStreamReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.FamiliarizationStreamReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationStreamReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.FamiliarizationStreamReq}
 */
proto.api.themakers.sc.FamiliarizationStreamReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.FamiliarizationStreamReq;
  return proto.api.themakers.sc.FamiliarizationStreamReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.FamiliarizationStreamReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.FamiliarizationStreamReq}
 */
proto.api.themakers.sc.FamiliarizationStreamReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.FamiliarizationStreamReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.FamiliarizationStreamReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.FamiliarizationStreamReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationStreamReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.FamiliarizationStreamReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.FamiliarizationStreamReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq}
 */
proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq;
  return proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq}
 */
proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.ArchivedfamiliarizationstreammessageCase = {
  ARCHIVEDFAMILIARIZATIONSTREAMMESSAGE_NOT_SET: 0,
  EOS: 1,
  FAMILIARIZATION: 2
};

/**
 * @return {proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.ArchivedfamiliarizationstreammessageCase}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.getArchivedfamiliarizationstreammessageCase = function() {
  return /** @type {proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.ArchivedfamiliarizationstreammessageCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    eos: jspb.Message.getFieldWithDefault(msg, 1, ""),
    familiarization: (f = msg.getFamiliarization()) && proto.api.themakers.sc.Familiarization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage;
  return proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEos(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Familiarization;
      reader.readMessage(value,proto.api.themakers.sc.Familiarization.deserializeBinaryFromReader);
      msg.setFamiliarization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFamiliarization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Familiarization.serializeBinaryToWriter
    );
  }
};


/**
 * optional string EOS = 1;
 * @return {string}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.getEos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.setEos = function(value) {
  jspb.Message.setOneofField(this, 1, proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.clearEos = function() {
  jspb.Message.setOneofField(this, 1, proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.hasEos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Familiarization Familiarization = 2;
 * @return {?proto.api.themakers.sc.Familiarization}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.getFamiliarization = function() {
  return /** @type{?proto.api.themakers.sc.Familiarization} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Familiarization, 2));
};


/** @param {?proto.api.themakers.sc.Familiarization|undefined} value */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.setFamiliarization = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.clearFamiliarization = function() {
  this.setFamiliarization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.prototype.hasFamiliarization = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.FamiliarizationstreammessageCase = {
  FAMILIARIZATIONSTREAMMESSAGE_NOT_SET: 0,
  DUMMY: 1,
  FAMILIARIZATION: 2,
  EVENT: 3
};

/**
 * @return {proto.api.themakers.sc.FamiliarizationStreamMessage.FamiliarizationstreammessageCase}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.getFamiliarizationstreammessageCase = function() {
  return /** @type {proto.api.themakers.sc.FamiliarizationStreamMessage.FamiliarizationstreammessageCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.FamiliarizationStreamMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.FamiliarizationStreamMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.FamiliarizationStreamMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    dummy: (f = msg.getDummy()) && proto.api.themakers.sc.Dummy.toObject(includeInstance, f),
    familiarization: (f = msg.getFamiliarization()) && proto.api.themakers.sc.Familiarization.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && proto.api.themakers.sc.FamiliarizationEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.FamiliarizationStreamMessage}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.FamiliarizationStreamMessage;
  return proto.api.themakers.sc.FamiliarizationStreamMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.FamiliarizationStreamMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.FamiliarizationStreamMessage}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.Dummy;
      reader.readMessage(value,proto.api.themakers.sc.Dummy.deserializeBinaryFromReader);
      msg.setDummy(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.Familiarization;
      reader.readMessage(value,proto.api.themakers.sc.Familiarization.deserializeBinaryFromReader);
      msg.setFamiliarization(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.FamiliarizationEvent;
      reader.readMessage(value,proto.api.themakers.sc.FamiliarizationEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.FamiliarizationStreamMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.FamiliarizationStreamMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.Dummy.serializeBinaryToWriter
    );
  }
  f = message.getFamiliarization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.Familiarization.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.FamiliarizationEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional Dummy Dummy = 1;
 * @return {?proto.api.themakers.sc.Dummy}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.getDummy = function() {
  return /** @type{?proto.api.themakers.sc.Dummy} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Dummy, 1));
};


/** @param {?proto.api.themakers.sc.Dummy|undefined} value */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.setDummy = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.api.themakers.sc.FamiliarizationStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.clearDummy = function() {
  this.setDummy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.hasDummy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Familiarization Familiarization = 2;
 * @return {?proto.api.themakers.sc.Familiarization}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.getFamiliarization = function() {
  return /** @type{?proto.api.themakers.sc.Familiarization} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Familiarization, 2));
};


/** @param {?proto.api.themakers.sc.Familiarization|undefined} value */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.setFamiliarization = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.api.themakers.sc.FamiliarizationStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.clearFamiliarization = function() {
  this.setFamiliarization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.hasFamiliarization = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FamiliarizationEvent Event = 3;
 * @return {?proto.api.themakers.sc.FamiliarizationEvent}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.getEvent = function() {
  return /** @type{?proto.api.themakers.sc.FamiliarizationEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.FamiliarizationEvent, 3));
};


/** @param {?proto.api.themakers.sc.FamiliarizationEvent|undefined} value */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.setEvent = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.api.themakers.sc.FamiliarizationStreamMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.clearEvent = function() {
  this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationStreamMessage.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.FamiliarizationEvent.oneofGroups_ = [[6,7,8]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.FamiliarizationEvent.FieldsCase = {
  FIELDS_NOT_SET: 0,
  STATUSTRANSITION: 6,
  FAMILIARIZE: 7,
  NEWMEMBERS: 8
};

/**
 * @return {proto.api.themakers.sc.FamiliarizationEvent.FieldsCase}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getFieldsCase = function() {
  return /** @type {proto.api.themakers.sc.FamiliarizationEvent.FieldsCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.FamiliarizationEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.FamiliarizationEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.FamiliarizationEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    familiarizationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    author: (f = msg.getAuthor()) && proto.api.themakers.sc.UserInfo.toObject(includeInstance, f),
    ts: jspb.Message.getFieldWithDefault(msg, 5, 0),
    statustransition: (f = msg.getStatustransition()) && proto.api.themakers.sc.StatusTransition.toObject(includeInstance, f),
    familiarize: (f = msg.getFamiliarize()) && proto.api.themakers.sc.Familiarize.toObject(includeInstance, f),
    newmembers: (f = msg.getNewmembers()) && proto.api.themakers.sc.NewMembers.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.FamiliarizationEvent}
 */
proto.api.themakers.sc.FamiliarizationEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.FamiliarizationEvent;
  return proto.api.themakers.sc.FamiliarizationEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.FamiliarizationEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.FamiliarizationEvent}
 */
proto.api.themakers.sc.FamiliarizationEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamiliarizationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.UserInfo;
      reader.readMessage(value,proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTs(value);
      break;
    case 6:
      var value = new proto.api.themakers.sc.StatusTransition;
      reader.readMessage(value,proto.api.themakers.sc.StatusTransition.deserializeBinaryFromReader);
      msg.setStatustransition(value);
      break;
    case 7:
      var value = new proto.api.themakers.sc.Familiarize;
      reader.readMessage(value,proto.api.themakers.sc.Familiarize.deserializeBinaryFromReader);
      msg.setFamiliarize(value);
      break;
    case 8:
      var value = new proto.api.themakers.sc.NewMembers;
      reader.readMessage(value,proto.api.themakers.sc.NewMembers.deserializeBinaryFromReader);
      msg.setNewmembers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.FamiliarizationEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.FamiliarizationEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFamiliarizationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getTs();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatustransition();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.themakers.sc.StatusTransition.serializeBinaryToWriter
    );
  }
  f = message.getFamiliarize();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.api.themakers.sc.Familiarize.serializeBinaryToWriter
    );
  }
  f = message.getNewmembers();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.api.themakers.sc.NewMembers.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.FamiliarizationEvent.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FamiliarizationID = 2;
 * @return {string}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getFamiliarizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.FamiliarizationEvent.prototype.setFamiliarizationid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.FamiliarizationEvent.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserInfo Author = 4;
 * @return {?proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getAuthor = function() {
  return /** @type{?proto.api.themakers.sc.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserInfo, 4));
};


/** @param {?proto.api.themakers.sc.UserInfo|undefined} value */
proto.api.themakers.sc.FamiliarizationEvent.prototype.setAuthor = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.clearAuthor = function() {
  this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 TS = 5;
 * @return {number}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.api.themakers.sc.FamiliarizationEvent.prototype.setTs = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional StatusTransition StatusTransition = 6;
 * @return {?proto.api.themakers.sc.StatusTransition}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getStatustransition = function() {
  return /** @type{?proto.api.themakers.sc.StatusTransition} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.StatusTransition, 6));
};


/** @param {?proto.api.themakers.sc.StatusTransition|undefined} value */
proto.api.themakers.sc.FamiliarizationEvent.prototype.setStatustransition = function(value) {
  jspb.Message.setOneofWrapperField(this, 6, proto.api.themakers.sc.FamiliarizationEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.clearStatustransition = function() {
  this.setStatustransition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.hasStatustransition = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Familiarize Familiarize = 7;
 * @return {?proto.api.themakers.sc.Familiarize}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getFamiliarize = function() {
  return /** @type{?proto.api.themakers.sc.Familiarize} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Familiarize, 7));
};


/** @param {?proto.api.themakers.sc.Familiarize|undefined} value */
proto.api.themakers.sc.FamiliarizationEvent.prototype.setFamiliarize = function(value) {
  jspb.Message.setOneofWrapperField(this, 7, proto.api.themakers.sc.FamiliarizationEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.clearFamiliarize = function() {
  this.setFamiliarize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.hasFamiliarize = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NewMembers NewMembers = 8;
 * @return {?proto.api.themakers.sc.NewMembers}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.getNewmembers = function() {
  return /** @type{?proto.api.themakers.sc.NewMembers} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.NewMembers, 8));
};


/** @param {?proto.api.themakers.sc.NewMembers|undefined} value */
proto.api.themakers.sc.FamiliarizationEvent.prototype.setNewmembers = function(value) {
  jspb.Message.setOneofWrapperField(this, 8, proto.api.themakers.sc.FamiliarizationEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.clearNewmembers = function() {
  this.setNewmembers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationEvent.prototype.hasNewmembers = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Familiarize.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Familiarize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Familiarize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Familiarize.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: (f = msg.getActor()) && proto.api.themakers.sc.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Familiarize}
 */
proto.api.themakers.sc.Familiarize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Familiarize;
  return proto.api.themakers.sc.Familiarize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Familiarize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Familiarize}
 */
proto.api.themakers.sc.Familiarize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.UserInfo;
      reader.readMessage(value,proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Familiarize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Familiarize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Familiarize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Familiarize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserInfo Actor = 1;
 * @return {?proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.Familiarize.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserInfo, 1));
};


/** @param {?proto.api.themakers.sc.UserInfo|undefined} value */
proto.api.themakers.sc.Familiarize.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Familiarize.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Familiarize.prototype.hasActor = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.NewMembers.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.NewMembers.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.NewMembers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.NewMembers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NewMembers.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.api.themakers.sc.UserInfo.toObject, includeInstance),
    unitsList: jspb.Message.toObjectList(msg.getUnitsList(),
    proto.api.themakers.sc.UnitInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.NewMembers}
 */
proto.api.themakers.sc.NewMembers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.NewMembers;
  return proto.api.themakers.sc.NewMembers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.NewMembers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.NewMembers}
 */
proto.api.themakers.sc.NewMembers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.UserInfo;
      reader.readMessage(value,proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.UnitInfo;
      reader.readMessage(value,proto.api.themakers.sc.UnitInfo.deserializeBinaryFromReader);
      msg.addUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.NewMembers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.NewMembers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.NewMembers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NewMembers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.themakers.sc.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.api.themakers.sc.UnitInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserInfo Users = 1;
 * @return {!Array<!proto.api.themakers.sc.UserInfo>}
 */
proto.api.themakers.sc.NewMembers.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.UserInfo, 1));
};


/** @param {!Array<!proto.api.themakers.sc.UserInfo>} value */
proto.api.themakers.sc.NewMembers.prototype.setUsersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.themakers.sc.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.NewMembers.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.themakers.sc.UserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.NewMembers.prototype.clearUsersList = function() {
  this.setUsersList([]);
};


/**
 * repeated UnitInfo Units = 2;
 * @return {!Array<!proto.api.themakers.sc.UnitInfo>}
 */
proto.api.themakers.sc.NewMembers.prototype.getUnitsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.UnitInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.UnitInfo, 2));
};


/** @param {!Array<!proto.api.themakers.sc.UnitInfo>} value */
proto.api.themakers.sc.NewMembers.prototype.setUnitsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.api.themakers.sc.UnitInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.UnitInfo}
 */
proto.api.themakers.sc.NewMembers.prototype.addUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.api.themakers.sc.UnitInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.NewMembers.prototype.clearUnitsList = function() {
  this.setUnitsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Familiarization.repeatedFields_ = [8,10,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Familiarization.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Familiarization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Familiarization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Familiarization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    centerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actor: (f = msg.getActor()) && proto.api.themakers.sc.Actor.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    creationtime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    forcenewmembers: jspb.Message.getFieldWithDefault(msg, 7, false),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.api.themakers.sc.File.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unitidsList: jspb.Message.getRepeatedField(msg, 10),
    until: jspb.Message.getFieldWithDefault(msg, 11, 0),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.api.themakers.sc.FamiliarizationDocument.toObject, includeInstance),
    familiarizationlogList: jspb.Message.toObjectList(msg.getFamiliarizationlogList(),
    proto.api.themakers.sc.FamiliarizationLog.toObject, includeInstance),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Familiarization}
 */
proto.api.themakers.sc.Familiarization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Familiarization;
  return proto.api.themakers.sc.Familiarization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Familiarization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Familiarization}
 */
proto.api.themakers.sc.Familiarization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.Actor;
      reader.readMessage(value,proto.api.themakers.sc.Actor.deserializeBinaryFromReader);
      msg.setActor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationtime(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForcenewmembers(value);
      break;
    case 8:
      var value = new proto.api.themakers.sc.File;
      reader.readMessage(value,proto.api.themakers.sc.File.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnitids(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUntil(value);
      break;
    case 12:
      var value = new proto.api.themakers.sc.FamiliarizationDocument;
      reader.readMessage(value,proto.api.themakers.sc.FamiliarizationDocument.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 13:
      var value = new proto.api.themakers.sc.FamiliarizationLog;
      reader.readMessage(value,proto.api.themakers.sc.FamiliarizationLog.deserializeBinaryFromReader);
      msg.addFamiliarizationlog(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Familiarization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Familiarization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Familiarization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Familiarization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.Actor.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreationtime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getForcenewmembers();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.api.themakers.sc.File.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnitidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getUntil();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.api.themakers.sc.FamiliarizationDocument.serializeBinaryToWriter
    );
  }
  f = message.getFamiliarizationlogList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.api.themakers.sc.FamiliarizationLog.serializeBinaryToWriter
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Familiarization.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Familiarization.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CenterID = 2;
 * @return {string}
 */
proto.api.themakers.sc.Familiarization.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Familiarization.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Actor Actor = 3;
 * @return {?proto.api.themakers.sc.Actor}
 */
proto.api.themakers.sc.Familiarization.prototype.getActor = function() {
  return /** @type{?proto.api.themakers.sc.Actor} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Actor, 3));
};


/** @param {?proto.api.themakers.sc.Actor|undefined} value */
proto.api.themakers.sc.Familiarization.prototype.setActor = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.Familiarization.prototype.clearActor = function() {
  this.setActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.Familiarization.prototype.hasActor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Title = 4;
 * @return {string}
 */
proto.api.themakers.sc.Familiarization.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Familiarization.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Description = 5;
 * @return {string}
 */
proto.api.themakers.sc.Familiarization.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Familiarization.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 CreationTime = 6;
 * @return {number}
 */
proto.api.themakers.sc.Familiarization.prototype.getCreationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Familiarization.prototype.setCreationtime = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool ForceNewMembers = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Familiarization.prototype.getForcenewmembers = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Familiarization.prototype.setForcenewmembers = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated File Files = 8;
 * @return {!Array<!proto.api.themakers.sc.File>}
 */
proto.api.themakers.sc.Familiarization.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.File, 8));
};


/** @param {!Array<!proto.api.themakers.sc.File>} value */
proto.api.themakers.sc.Familiarization.prototype.setFilesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.api.themakers.sc.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.File}
 */
proto.api.themakers.sc.Familiarization.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.api.themakers.sc.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Familiarization.prototype.clearFilesList = function() {
  this.setFilesList([]);
};


/**
 * optional string Status = 9;
 * @return {string}
 */
proto.api.themakers.sc.Familiarization.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Familiarization.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string UnitIDs = 10;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.Familiarization.prototype.getUnitidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.Familiarization.prototype.setUnitidsList = function(value) {
  jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.Familiarization.prototype.addUnitids = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Familiarization.prototype.clearUnitidsList = function() {
  this.setUnitidsList([]);
};


/**
 * optional int64 Until = 11;
 * @return {number}
 */
proto.api.themakers.sc.Familiarization.prototype.getUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Familiarization.prototype.setUntil = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated FamiliarizationDocument Documents = 12;
 * @return {!Array<!proto.api.themakers.sc.FamiliarizationDocument>}
 */
proto.api.themakers.sc.Familiarization.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.FamiliarizationDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.FamiliarizationDocument, 12));
};


/** @param {!Array<!proto.api.themakers.sc.FamiliarizationDocument>} value */
proto.api.themakers.sc.Familiarization.prototype.setDocumentsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.api.themakers.sc.FamiliarizationDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.FamiliarizationDocument}
 */
proto.api.themakers.sc.Familiarization.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.api.themakers.sc.FamiliarizationDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Familiarization.prototype.clearDocumentsList = function() {
  this.setDocumentsList([]);
};


/**
 * repeated FamiliarizationLog FamiliarizationLog = 13;
 * @return {!Array<!proto.api.themakers.sc.FamiliarizationLog>}
 */
proto.api.themakers.sc.Familiarization.prototype.getFamiliarizationlogList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.FamiliarizationLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.FamiliarizationLog, 13));
};


/** @param {!Array<!proto.api.themakers.sc.FamiliarizationLog>} value */
proto.api.themakers.sc.Familiarization.prototype.setFamiliarizationlogList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.api.themakers.sc.FamiliarizationLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.FamiliarizationLog}
 */
proto.api.themakers.sc.Familiarization.prototype.addFamiliarizationlog = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.api.themakers.sc.FamiliarizationLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Familiarization.prototype.clearFamiliarizationlogList = function() {
  this.setFamiliarizationlogList([]);
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.Familiarization.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Familiarization.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.FamiliarizationDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.FamiliarizationDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.FamiliarizationDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.FamiliarizationDocument}
 */
proto.api.themakers.sc.FamiliarizationDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.FamiliarizationDocument;
  return proto.api.themakers.sc.FamiliarizationDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.FamiliarizationDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.FamiliarizationDocument}
 */
proto.api.themakers.sc.FamiliarizationDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.FamiliarizationDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.FamiliarizationDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.FamiliarizationDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.FamiliarizationDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.FamiliarizationDocument.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.FamiliarizationDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.FamiliarizationDocument.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Type = 3;
 * @return {string}
 */
proto.api.themakers.sc.FamiliarizationDocument.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.FamiliarizationDocument.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ActiveFamiliarizationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ActiveFamiliarizationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ActiveFamiliarizationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ActiveFamiliarizationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ActiveFamiliarizationReq}
 */
proto.api.themakers.sc.ActiveFamiliarizationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ActiveFamiliarizationReq;
  return proto.api.themakers.sc.ActiveFamiliarizationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ActiveFamiliarizationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ActiveFamiliarizationReq}
 */
proto.api.themakers.sc.ActiveFamiliarizationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ActiveFamiliarizationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ActiveFamiliarizationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ActiveFamiliarizationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ActiveFamiliarizationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.ActiveFamiliarizationReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ActiveFamiliarizationReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DraftFamiliarizationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DraftFamiliarizationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DraftFamiliarizationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DraftFamiliarizationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DraftFamiliarizationReq}
 */
proto.api.themakers.sc.DraftFamiliarizationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DraftFamiliarizationReq;
  return proto.api.themakers.sc.DraftFamiliarizationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DraftFamiliarizationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DraftFamiliarizationReq}
 */
proto.api.themakers.sc.DraftFamiliarizationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DraftFamiliarizationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DraftFamiliarizationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DraftFamiliarizationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DraftFamiliarizationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.DraftFamiliarizationReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DraftFamiliarizationReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.ArchiveFamiliarizationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.ArchiveFamiliarizationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.ArchiveFamiliarizationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchiveFamiliarizationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.ArchiveFamiliarizationReq}
 */
proto.api.themakers.sc.ArchiveFamiliarizationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.ArchiveFamiliarizationReq;
  return proto.api.themakers.sc.ArchiveFamiliarizationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.ArchiveFamiliarizationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.ArchiveFamiliarizationReq}
 */
proto.api.themakers.sc.ArchiveFamiliarizationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.ArchiveFamiliarizationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.ArchiveFamiliarizationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.ArchiveFamiliarizationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.ArchiveFamiliarizationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.ArchiveFamiliarizationReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.ArchiveFamiliarizationReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.CreateFamiliarizationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.CreateFamiliarizationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.CreateFamiliarizationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateFamiliarizationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.CreateFamiliarizationReq}
 */
proto.api.themakers.sc.CreateFamiliarizationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.CreateFamiliarizationReq;
  return proto.api.themakers.sc.CreateFamiliarizationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.CreateFamiliarizationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.CreateFamiliarizationReq}
 */
proto.api.themakers.sc.CreateFamiliarizationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.CreateFamiliarizationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.CreateFamiliarizationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.CreateFamiliarizationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateFamiliarizationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string UnitID = 1;
 * @return {string}
 */
proto.api.themakers.sc.CreateFamiliarizationReq.prototype.getUnitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateFamiliarizationReq.prototype.setUnitid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Title = 2;
 * @return {string}
 */
proto.api.themakers.sc.CreateFamiliarizationReq.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateFamiliarizationReq.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.repeatedFields_ = [6,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UpdateFamiliarizationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UpdateFamiliarizationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    forcenewmembers: jspb.Message.getFieldWithDefault(msg, 4, false),
    unitidsList: jspb.Message.getRepeatedField(msg, 6),
    until: jspb.Message.getFieldWithDefault(msg, 7, 0),
    documentidsList: jspb.Message.getRepeatedField(msg, 8),
    useridsList: jspb.Message.getRepeatedField(msg, 9)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UpdateFamiliarizationReq}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UpdateFamiliarizationReq;
  return proto.api.themakers.sc.UpdateFamiliarizationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UpdateFamiliarizationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UpdateFamiliarizationReq}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForcenewmembers(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnitids(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUntil(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentids(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UpdateFamiliarizationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UpdateFamiliarizationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getForcenewmembers();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUnitidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getUntil();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDocumentidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getUseridsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Title = 2;
 * @return {string}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Description = 3;
 * @return {string}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool ForceNewMembers = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.getForcenewmembers = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.setForcenewmembers = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated string UnitIDs = 6;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.getUnitidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.setUnitidsList = function(value) {
  jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.addUnitids = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.clearUnitidsList = function() {
  this.setUnitidsList([]);
};


/**
 * optional int64 Until = 7;
 * @return {number}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.getUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.setUntil = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string DocumentIDs = 8;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.getDocumentidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.setDocumentidsList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.addDocumentids = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.clearDocumentidsList = function() {
  this.setDocumentidsList([]);
};


/**
 * repeated string UserIDs = 9;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.getUseridsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.setUseridsList = function(value) {
  jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.addUserids = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.UpdateFamiliarizationReq.prototype.clearUseridsList = function() {
  this.setUseridsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.AddMembersReq.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.AddMembersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.AddMembersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.AddMembersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddMembersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    useridsList: jspb.Message.getRepeatedField(msg, 2),
    unitidsList: jspb.Message.getRepeatedField(msg, 3)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.AddMembersReq}
 */
proto.api.themakers.sc.AddMembersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.AddMembersReq;
  return proto.api.themakers.sc.AddMembersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.AddMembersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.AddMembersReq}
 */
proto.api.themakers.sc.AddMembersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserids(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnitids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.AddMembersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.AddMembersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.AddMembersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddMembersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUseridsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getUnitidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.AddMembersReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddMembersReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string UserIDs = 2;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.AddMembersReq.prototype.getUseridsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.AddMembersReq.prototype.setUseridsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.AddMembersReq.prototype.addUserids = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.AddMembersReq.prototype.clearUseridsList = function() {
  this.setUseridsList([]);
};


/**
 * repeated string UnitIDs = 3;
 * @return {!Array<string>}
 */
proto.api.themakers.sc.AddMembersReq.prototype.getUnitidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<string>} value */
proto.api.themakers.sc.AddMembersReq.prototype.setUnitidsList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.api.themakers.sc.AddMembersReq.prototype.addUnitids = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.AddMembersReq.prototype.clearUnitidsList = function() {
  this.setUnitidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.FamiliarizationLog.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.FamiliarizationLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.FamiliarizationLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.api.themakers.sc.UserInfo.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, false),
    when: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.FamiliarizationLog}
 */
proto.api.themakers.sc.FamiliarizationLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.FamiliarizationLog;
  return proto.api.themakers.sc.FamiliarizationLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.FamiliarizationLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.FamiliarizationLog}
 */
proto.api.themakers.sc.FamiliarizationLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.UserInfo;
      reader.readMessage(value,proto.api.themakers.sc.UserInfo.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWhen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.FamiliarizationLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.FamiliarizationLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.FamiliarizationLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizationLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getWhen();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional UserInfo User = 1;
 * @return {?proto.api.themakers.sc.UserInfo}
 */
proto.api.themakers.sc.FamiliarizationLog.prototype.getUser = function() {
  return /** @type{?proto.api.themakers.sc.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.UserInfo, 1));
};


/** @param {?proto.api.themakers.sc.UserInfo|undefined} value */
proto.api.themakers.sc.FamiliarizationLog.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.FamiliarizationLog.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationLog.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool Status = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.FamiliarizationLog.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.FamiliarizationLog.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 When = 3;
 * @return {number}
 */
proto.api.themakers.sc.FamiliarizationLog.prototype.getWhen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.api.themakers.sc.FamiliarizationLog.prototype.setWhen = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.FamiliarizeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.FamiliarizeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.FamiliarizeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.FamiliarizeReq}
 */
proto.api.themakers.sc.FamiliarizeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.FamiliarizeReq;
  return proto.api.themakers.sc.FamiliarizeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.FamiliarizeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.FamiliarizeReq}
 */
proto.api.themakers.sc.FamiliarizeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.FamiliarizeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.FamiliarizeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.FamiliarizeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.FamiliarizeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.FamiliarizeReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.FamiliarizeReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveFamiliarizationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveFamiliarizationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveFamiliarizationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveFamiliarizationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveFamiliarizationReq}
 */
proto.api.themakers.sc.RemoveFamiliarizationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveFamiliarizationReq;
  return proto.api.themakers.sc.RemoveFamiliarizationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveFamiliarizationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveFamiliarizationReq}
 */
proto.api.themakers.sc.RemoveFamiliarizationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveFamiliarizationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveFamiliarizationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveFamiliarizationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveFamiliarizationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveFamiliarizationReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveFamiliarizationReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.CreateDirectoryReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.CreateDirectoryReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.CreateDirectoryReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.CreateDirectoryReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateDirectoryReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    centerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.api.themakers.sc.DirectoryField.toObject, includeInstance),
    displaynameformatstring: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.CreateDirectoryReq}
 */
proto.api.themakers.sc.CreateDirectoryReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.CreateDirectoryReq;
  return proto.api.themakers.sc.CreateDirectoryReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.CreateDirectoryReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.CreateDirectoryReq}
 */
proto.api.themakers.sc.CreateDirectoryReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.DirectoryField;
      reader.readMessage(value,proto.api.themakers.sc.DirectoryField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaynameformatstring(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.CreateDirectoryReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.CreateDirectoryReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.CreateDirectoryReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateDirectoryReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.themakers.sc.DirectoryField.serializeBinaryToWriter
    );
  }
  f = message.getDisplaynameformatstring();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string CenterID = 1;
 * @return {string}
 */
proto.api.themakers.sc.CreateDirectoryReq.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateDirectoryReq.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.CreateDirectoryReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateDirectoryReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DirectoryField Fields = 3;
 * @return {!Array<!proto.api.themakers.sc.DirectoryField>}
 */
proto.api.themakers.sc.CreateDirectoryReq.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.DirectoryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.DirectoryField, 3));
};


/** @param {!Array<!proto.api.themakers.sc.DirectoryField>} value */
proto.api.themakers.sc.CreateDirectoryReq.prototype.setFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.themakers.sc.DirectoryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.DirectoryField}
 */
proto.api.themakers.sc.CreateDirectoryReq.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.themakers.sc.DirectoryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.CreateDirectoryReq.prototype.clearFieldsList = function() {
  this.setFieldsList([]);
};


/**
 * optional string DisplayNameFormatString = 4;
 * @return {string}
 */
proto.api.themakers.sc.CreateDirectoryReq.prototype.getDisplaynameformatstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateDirectoryReq.prototype.setDisplaynameformatstring = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveDirectoryReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveDirectoryReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveDirectoryReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveDirectoryReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveDirectoryReq}
 */
proto.api.themakers.sc.RemoveDirectoryReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveDirectoryReq;
  return proto.api.themakers.sc.RemoveDirectoryReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveDirectoryReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveDirectoryReq}
 */
proto.api.themakers.sc.RemoveDirectoryReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveDirectoryReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveDirectoryReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveDirectoryReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveDirectoryReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveDirectoryReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveDirectoryReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetDirectoryNameReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetDirectoryNameReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetDirectoryNameReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryNameReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetDirectoryNameReq}
 */
proto.api.themakers.sc.SetDirectoryNameReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetDirectoryNameReq;
  return proto.api.themakers.sc.SetDirectoryNameReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetDirectoryNameReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetDirectoryNameReq}
 */
proto.api.themakers.sc.SetDirectoryNameReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetDirectoryNameReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetDirectoryNameReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetDirectoryNameReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryNameReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetDirectoryNameReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDirectoryNameReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetDirectoryNameReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDirectoryNameReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetDirectoryDisplayNameReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetDirectoryDisplayNameReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetDirectoryDisplayNameReq}
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetDirectoryDisplayNameReq;
  return proto.api.themakers.sc.SetDirectoryDisplayNameReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetDirectoryDisplayNameReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetDirectoryDisplayNameReq}
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetDirectoryDisplayNameReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetDirectoryDisplayNameReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DisplayName = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDirectoryDisplayNameReq.prototype.setDisplayname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.AddDirectoryFieldReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.AddDirectoryFieldReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddDirectoryFieldReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    directoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0),
    field: (f = msg.getField()) && proto.api.themakers.sc.DirectoryField.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.AddDirectoryFieldReq}
 */
proto.api.themakers.sc.AddDirectoryFieldReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.AddDirectoryFieldReq;
  return proto.api.themakers.sc.AddDirectoryFieldReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.AddDirectoryFieldReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.AddDirectoryFieldReq}
 */
proto.api.themakers.sc.AddDirectoryFieldReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectoryid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.DirectoryField;
      reader.readMessage(value,proto.api.themakers.sc.DirectoryField.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.AddDirectoryFieldReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.AddDirectoryFieldReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddDirectoryFieldReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirectoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.DirectoryField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string DirectoryID = 1;
 * @return {string}
 */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.getDirectoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.setDirectoryid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 Position = 2;
 * @return {number}
 */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.setPosition = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DirectoryField Field = 3;
 * @return {?proto.api.themakers.sc.DirectoryField}
 */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.getField = function() {
  return /** @type{?proto.api.themakers.sc.DirectoryField} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.DirectoryField, 3));
};


/** @param {?proto.api.themakers.sc.DirectoryField|undefined} value */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.setField = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.clearField = function() {
  this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.AddDirectoryFieldReq.prototype.hasField = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveDirectoryFieldReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveDirectoryFieldReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    directoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveDirectoryFieldReq}
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveDirectoryFieldReq;
  return proto.api.themakers.sc.RemoveDirectoryFieldReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveDirectoryFieldReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveDirectoryFieldReq}
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectoryid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveDirectoryFieldReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveDirectoryFieldReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirectoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string DirectoryID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq.prototype.getDirectoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveDirectoryFieldReq.prototype.setDirectoryid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 Position = 3;
 * @return {number}
 */
proto.api.themakers.sc.RemoveDirectoryFieldReq.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.api.themakers.sc.RemoveDirectoryFieldReq.prototype.setPosition = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetDirectoryFieldNameReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetDirectoryFieldNameReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    directoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetDirectoryFieldNameReq}
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetDirectoryFieldNameReq;
  return proto.api.themakers.sc.SetDirectoryFieldNameReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetDirectoryFieldNameReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetDirectoryFieldNameReq}
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectoryid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetDirectoryFieldNameReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetDirectoryFieldNameReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirectoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string DirectoryID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.prototype.getDirectoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDirectoryFieldNameReq.prototype.setDirectoryid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 Position = 2;
 * @return {number}
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.api.themakers.sc.SetDirectoryFieldNameReq.prototype.setPosition = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.api.themakers.sc.SetDirectoryFieldNameReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDirectoryFieldNameReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetDirectoryFieldRequiredReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetDirectoryFieldRequiredReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    directoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0),
    required: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetDirectoryFieldRequiredReq}
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetDirectoryFieldRequiredReq;
  return proto.api.themakers.sc.SetDirectoryFieldRequiredReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetDirectoryFieldRequiredReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetDirectoryFieldRequiredReq}
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectoryid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetDirectoryFieldRequiredReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetDirectoryFieldRequiredReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirectoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string DirectoryID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.prototype.getDirectoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.prototype.setDirectoryid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 Position = 2;
 * @return {number}
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.prototype.setPosition = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool Required = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.SetDirectoryFieldRequiredReq.prototype.setRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetDirectoryArchivedReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetDirectoryArchivedReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetDirectoryArchivedReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryArchivedReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    archived: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetDirectoryArchivedReq}
 */
proto.api.themakers.sc.SetDirectoryArchivedReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetDirectoryArchivedReq;
  return proto.api.themakers.sc.SetDirectoryArchivedReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetDirectoryArchivedReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetDirectoryArchivedReq}
 */
proto.api.themakers.sc.SetDirectoryArchivedReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetDirectoryArchivedReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetDirectoryArchivedReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetDirectoryArchivedReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDirectoryArchivedReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetDirectoryArchivedReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDirectoryArchivedReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool Archived = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.SetDirectoryArchivedReq.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.SetDirectoryArchivedReq.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.AddRecordReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.AddRecordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.AddRecordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.AddRecordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddRecordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    directoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.api.themakers.sc.RecordField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.AddRecordReq}
 */
proto.api.themakers.sc.AddRecordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.AddRecordReq;
  return proto.api.themakers.sc.AddRecordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.AddRecordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.AddRecordReq}
 */
proto.api.themakers.sc.AddRecordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectoryid(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.RecordField;
      reader.readMessage(value,proto.api.themakers.sc.RecordField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.AddRecordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.AddRecordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.AddRecordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddRecordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirectoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.themakers.sc.RecordField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string DirectoryID = 1;
 * @return {string}
 */
proto.api.themakers.sc.AddRecordReq.prototype.getDirectoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddRecordReq.prototype.setDirectoryid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RecordField Fields = 4;
 * @return {!Array<!proto.api.themakers.sc.RecordField>}
 */
proto.api.themakers.sc.AddRecordReq.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.RecordField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.RecordField, 4));
};


/** @param {!Array<!proto.api.themakers.sc.RecordField>} value */
proto.api.themakers.sc.AddRecordReq.prototype.setFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.themakers.sc.RecordField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.RecordField}
 */
proto.api.themakers.sc.AddRecordReq.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.themakers.sc.RecordField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.AddRecordReq.prototype.clearFieldsList = function() {
  this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveRecordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveRecordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveRecordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveRecordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveRecordReq}
 */
proto.api.themakers.sc.RemoveRecordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveRecordReq;
  return proto.api.themakers.sc.RemoveRecordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveRecordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveRecordReq}
 */
proto.api.themakers.sc.RemoveRecordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveRecordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveRecordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveRecordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveRecordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveRecordReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveRecordReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetRecordArchivedReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetRecordArchivedReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetRecordArchivedReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetRecordArchivedReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    archived: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetRecordArchivedReq}
 */
proto.api.themakers.sc.SetRecordArchivedReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetRecordArchivedReq;
  return proto.api.themakers.sc.SetRecordArchivedReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetRecordArchivedReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetRecordArchivedReq}
 */
proto.api.themakers.sc.SetRecordArchivedReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetRecordArchivedReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetRecordArchivedReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetRecordArchivedReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetRecordArchivedReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetRecordArchivedReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetRecordArchivedReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool Archived = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.SetRecordArchivedReq.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.SetRecordArchivedReq.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.UpdateRecordFieldsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.UpdateRecordFieldsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.api.themakers.sc.RecordField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.UpdateRecordFieldsReq}
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.UpdateRecordFieldsReq;
  return proto.api.themakers.sc.UpdateRecordFieldsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.UpdateRecordFieldsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.UpdateRecordFieldsReq}
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.RecordField;
      reader.readMessage(value,proto.api.themakers.sc.RecordField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.UpdateRecordFieldsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.UpdateRecordFieldsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.themakers.sc.RecordField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.UpdateRecordFieldsReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RecordField Fields = 4;
 * @return {!Array<!proto.api.themakers.sc.RecordField>}
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.RecordField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.RecordField, 4));
};


/** @param {!Array<!proto.api.themakers.sc.RecordField>} value */
proto.api.themakers.sc.UpdateRecordFieldsReq.prototype.setFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.themakers.sc.RecordField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.RecordField}
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.themakers.sc.RecordField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.UpdateRecordFieldsReq.prototype.clearFieldsList = function() {
  this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Directory.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Directory.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Directory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Directory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Directory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    centerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displaynameformatstring: jspb.Message.getFieldWithDefault(msg, 4, ""),
    readonly: jspb.Message.getFieldWithDefault(msg, 5, false),
    external: jspb.Message.getFieldWithDefault(msg, 6, false),
    archived: jspb.Message.getFieldWithDefault(msg, 7, false),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.api.themakers.sc.DirectoryField.toObject, includeInstance),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Directory}
 */
proto.api.themakers.sc.Directory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Directory;
  return proto.api.themakers.sc.Directory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Directory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Directory}
 */
proto.api.themakers.sc.Directory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplaynameformatstring(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternal(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 8:
      var value = new proto.api.themakers.sc.DirectoryField;
      reader.readMessage(value,proto.api.themakers.sc.DirectoryField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Directory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Directory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Directory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Directory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplaynameformatstring();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReadonly();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getExternal();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.api.themakers.sc.DirectoryField.serializeBinaryToWriter
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Directory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Directory.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CenterID = 2;
 * @return {string}
 */
proto.api.themakers.sc.Directory.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Directory.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.api.themakers.sc.Directory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Directory.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string DisplayNameFormatString = 4;
 * @return {string}
 */
proto.api.themakers.sc.Directory.prototype.getDisplaynameformatstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Directory.prototype.setDisplaynameformatstring = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool ReadOnly = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Directory.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Directory.prototype.setReadonly = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool External = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Directory.prototype.getExternal = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Directory.prototype.setExternal = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool Archived = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Directory.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Directory.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated DirectoryField Fields = 8;
 * @return {!Array<!proto.api.themakers.sc.DirectoryField>}
 */
proto.api.themakers.sc.Directory.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.DirectoryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.DirectoryField, 8));
};


/** @param {!Array<!proto.api.themakers.sc.DirectoryField>} value */
proto.api.themakers.sc.Directory.prototype.setFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.api.themakers.sc.DirectoryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.DirectoryField}
 */
proto.api.themakers.sc.Directory.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.api.themakers.sc.DirectoryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Directory.prototype.clearFieldsList = function() {
  this.setFieldsList([]);
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.Directory.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Directory.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.DirectoryField.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.DirectoryField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.DirectoryField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DirectoryField.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    required: jspb.Message.getFieldWithDefault(msg, 2, false),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subtype: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.DirectoryField}
 */
proto.api.themakers.sc.DirectoryField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.DirectoryField;
  return proto.api.themakers.sc.DirectoryField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.DirectoryField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.DirectoryField}
 */
proto.api.themakers.sc.DirectoryField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.DirectoryField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.DirectoryField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.DirectoryField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.DirectoryField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubtype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.api.themakers.sc.DirectoryField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DirectoryField.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool Required = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.DirectoryField.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.DirectoryField.prototype.setRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string Type = 4;
 * @return {string}
 */
proto.api.themakers.sc.DirectoryField.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DirectoryField.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string SubType = 5;
 * @return {string}
 */
proto.api.themakers.sc.DirectoryField.prototype.getSubtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.api.themakers.sc.DirectoryField.prototype.setSubtype = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Record.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Record.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Record.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Record} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Record.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    directoryid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    centerid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unitid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    archived: jspb.Message.getFieldWithDefault(msg, 5, false),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.api.themakers.sc.RecordField.toObject, includeInstance),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Record}
 */
proto.api.themakers.sc.Record.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Record;
  return proto.api.themakers.sc.Record.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Record} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Record}
 */
proto.api.themakers.sc.Record.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectoryid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 6:
      var value = new proto.api.themakers.sc.RecordField;
      reader.readMessage(value,proto.api.themakers.sc.RecordField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Record.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Record.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Record} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Record.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDirectoryid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnitid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.api.themakers.sc.RecordField.serializeBinaryToWriter
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Record.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Record.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DirectoryID = 2;
 * @return {string}
 */
proto.api.themakers.sc.Record.prototype.getDirectoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Record.prototype.setDirectoryid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CenterID = 3;
 * @return {string}
 */
proto.api.themakers.sc.Record.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Record.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UnitID = 4;
 * @return {string}
 */
proto.api.themakers.sc.Record.prototype.getUnitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Record.prototype.setUnitid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool Archived = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Record.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Record.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated RecordField Fields = 6;
 * @return {!Array<!proto.api.themakers.sc.RecordField>}
 */
proto.api.themakers.sc.Record.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.RecordField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.RecordField, 6));
};


/** @param {!Array<!proto.api.themakers.sc.RecordField>} value */
proto.api.themakers.sc.Record.prototype.setFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.api.themakers.sc.RecordField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.RecordField}
 */
proto.api.themakers.sc.Record.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.api.themakers.sc.RecordField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Record.prototype.clearFieldsList = function() {
  this.setFieldsList([]);
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.Record.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Record.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RecordField.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RecordField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RecordField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RecordField.toObject = function(includeInstance, msg) {
  var f, obj = {
    string: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pb_boolean: jspb.Message.getFieldWithDefault(msg, 3, false),
    reference: jspb.Message.getFieldWithDefault(msg, 4, ""),
    time: (f = msg.getTime()) && proto.api.themakers.sc.Timex.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RecordField}
 */
proto.api.themakers.sc.RecordField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RecordField;
  return proto.api.themakers.sc.RecordField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RecordField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RecordField}
 */
proto.api.themakers.sc.RecordField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setString(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolean(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 5:
      var value = new proto.api.themakers.sc.Timex;
      reader.readMessage(value,proto.api.themakers.sc.Timex.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RecordField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RecordField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RecordField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RecordField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBoolean();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.themakers.sc.Timex.serializeBinaryToWriter
    );
  }
};


/**
 * optional string String = 1;
 * @return {string}
 */
proto.api.themakers.sc.RecordField.prototype.getString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RecordField.prototype.setString = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Number = 2;
 * @return {string}
 */
proto.api.themakers.sc.RecordField.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RecordField.prototype.setNumber = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool Boolean = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.RecordField.prototype.getBoolean = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.RecordField.prototype.setBoolean = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string Reference = 4;
 * @return {string}
 */
proto.api.themakers.sc.RecordField.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RecordField.prototype.setReference = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Timex Time = 5;
 * @return {?proto.api.themakers.sc.Timex}
 */
proto.api.themakers.sc.RecordField.prototype.getTime = function() {
  return /** @type{?proto.api.themakers.sc.Timex} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Timex, 5));
};


/** @param {?proto.api.themakers.sc.Timex|undefined} value */
proto.api.themakers.sc.RecordField.prototype.setTime = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.RecordField.prototype.clearTime = function() {
  this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.RecordField.prototype.hasTime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Timex.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Timex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Timex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Timex.toObject = function(includeInstance, msg) {
  var f, obj = {
    utc: jspb.Message.getFieldWithDefault(msg, 1, 0),
    local: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    offsetabsent: jspb.Message.getFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Timex}
 */
proto.api.themakers.sc.Timex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Timex;
  return proto.api.themakers.sc.Timex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Timex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Timex}
 */
proto.api.themakers.sc.Timex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtc(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLocal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOffsetabsent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Timex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Timex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Timex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Timex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtc();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLocal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOffsetabsent();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 UTC = 1;
 * @return {number}
 */
proto.api.themakers.sc.Timex.prototype.getUtc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Timex.prototype.setUtc = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 Local = 2;
 * @return {number}
 */
proto.api.themakers.sc.Timex.prototype.getLocal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Timex.prototype.setLocal = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 Offset = 3;
 * @return {number}
 */
proto.api.themakers.sc.Timex.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Timex.prototype.setOffset = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool OffsetAbsent = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.Timex.prototype.getOffsetabsent = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.Timex.prototype.setOffsetabsent = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.MarkAsRootReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.MarkAsRootReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.MarkAsRootReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.MarkAsRootReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    root: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.MarkAsRootReq}
 */
proto.api.themakers.sc.MarkAsRootReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.MarkAsRootReq;
  return proto.api.themakers.sc.MarkAsRootReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.MarkAsRootReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.MarkAsRootReq}
 */
proto.api.themakers.sc.MarkAsRootReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRoot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.MarkAsRootReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.MarkAsRootReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.MarkAsRootReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.MarkAsRootReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoot();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.MarkAsRootReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.MarkAsRootReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool Root = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.api.themakers.sc.MarkAsRootReq.prototype.getRoot = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.api.themakers.sc.MarkAsRootReq.prototype.setRoot = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.MoveUnitReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.MoveUnitReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.MoveUnitReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.MoveUnitReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parent: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.MoveUnitReq}
 */
proto.api.themakers.sc.MoveUnitReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.MoveUnitReq;
  return proto.api.themakers.sc.MoveUnitReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.MoveUnitReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.MoveUnitReq}
 */
proto.api.themakers.sc.MoveUnitReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.MoveUnitReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.MoveUnitReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.MoveUnitReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.MoveUnitReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.MoveUnitReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.MoveUnitReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Parent = 2;
 * @return {string}
 */
proto.api.themakers.sc.MoveUnitReq.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.MoveUnitReq.prototype.setParent = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetUnitHeadReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetUnitHeadReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetUnitHeadReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetUnitHeadReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chief: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetUnitHeadReq}
 */
proto.api.themakers.sc.SetUnitHeadReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetUnitHeadReq;
  return proto.api.themakers.sc.SetUnitHeadReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetUnitHeadReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetUnitHeadReq}
 */
proto.api.themakers.sc.SetUnitHeadReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChief(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetUnitHeadReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetUnitHeadReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetUnitHeadReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetUnitHeadReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChief();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetUnitHeadReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetUnitHeadReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Chief = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetUnitHeadReq.prototype.getChief = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetUnitHeadReq.prototype.setChief = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.AddEmployeeToUnitReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.AddEmployeeToUnitReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.AddEmployeeToUnitReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddEmployeeToUnitReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    employee: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.AddEmployeeToUnitReq}
 */
proto.api.themakers.sc.AddEmployeeToUnitReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.AddEmployeeToUnitReq;
  return proto.api.themakers.sc.AddEmployeeToUnitReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.AddEmployeeToUnitReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.AddEmployeeToUnitReq}
 */
proto.api.themakers.sc.AddEmployeeToUnitReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployee(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.AddEmployeeToUnitReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.AddEmployeeToUnitReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.AddEmployeeToUnitReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.AddEmployeeToUnitReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmployee();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Employee = 1;
 * @return {string}
 */
proto.api.themakers.sc.AddEmployeeToUnitReq.prototype.getEmployee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddEmployeeToUnitReq.prototype.setEmployee = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Unit = 2;
 * @return {string}
 */
proto.api.themakers.sc.AddEmployeeToUnitReq.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.AddEmployeeToUnitReq.prototype.setUnit = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveEmployeeFromUnitReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveEmployeeFromUnitReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    employee: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveEmployeeFromUnitReq}
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveEmployeeFromUnitReq;
  return proto.api.themakers.sc.RemoveEmployeeFromUnitReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveEmployeeFromUnitReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveEmployeeFromUnitReq}
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployee(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveEmployeeFromUnitReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveEmployeeFromUnitReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmployee();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Employee = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.prototype.getEmployee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.prototype.setEmployee = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Unit = 2;
 * @return {string}
 */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveEmployeeFromUnitReq.prototype.setUnit = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.OneAssQueryAndWatchReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.OneAssQueryAndWatchReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.OneAssQueryAndWatchReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.OneAssQueryAndWatchReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.OneAssQueryAndWatchReq}
 */
proto.api.themakers.sc.OneAssQueryAndWatchReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.OneAssQueryAndWatchReq;
  return proto.api.themakers.sc.OneAssQueryAndWatchReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.OneAssQueryAndWatchReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.OneAssQueryAndWatchReq}
 */
proto.api.themakers.sc.OneAssQueryAndWatchReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.OneAssQueryAndWatchReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.OneAssQueryAndWatchReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.OneAssQueryAndWatchReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.OneAssQueryAndWatchReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.MessageCase = {
  MESSAGE_NOT_SET: 0,
  DIRECTORY: 1,
  RECORDEVENT: 2,
  DUMMY: 3
};

/**
 * @return {proto.api.themakers.sc.OneAssQueryAndWatchMsg.MessageCase}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.getMessageCase = function() {
  return /** @type {proto.api.themakers.sc.OneAssQueryAndWatchMsg.MessageCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.OneAssQueryAndWatchMsg.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.OneAssQueryAndWatchMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.OneAssQueryAndWatchMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    directory: (f = msg.getDirectory()) && proto.api.themakers.sc.Directory.toObject(includeInstance, f),
    recordevent: (f = msg.getRecordevent()) && proto.api.themakers.sc.OneAssRecordEvent.toObject(includeInstance, f),
    dummy: (f = msg.getDummy()) && proto.api.themakers.sc.Dummy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.OneAssQueryAndWatchMsg}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.OneAssQueryAndWatchMsg;
  return proto.api.themakers.sc.OneAssQueryAndWatchMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.OneAssQueryAndWatchMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.OneAssQueryAndWatchMsg}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.themakers.sc.Directory;
      reader.readMessage(value,proto.api.themakers.sc.Directory.deserializeBinaryFromReader);
      msg.setDirectory(value);
      break;
    case 2:
      var value = new proto.api.themakers.sc.OneAssRecordEvent;
      reader.readMessage(value,proto.api.themakers.sc.OneAssRecordEvent.deserializeBinaryFromReader);
      msg.setRecordevent(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.Dummy;
      reader.readMessage(value,proto.api.themakers.sc.Dummy.deserializeBinaryFromReader);
      msg.setDummy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.OneAssQueryAndWatchMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.OneAssQueryAndWatchMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirectory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.themakers.sc.Directory.serializeBinaryToWriter
    );
  }
  f = message.getRecordevent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.themakers.sc.OneAssRecordEvent.serializeBinaryToWriter
    );
  }
  f = message.getDummy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.Dummy.serializeBinaryToWriter
    );
  }
};


/**
 * optional Directory Directory = 1;
 * @return {?proto.api.themakers.sc.Directory}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.getDirectory = function() {
  return /** @type{?proto.api.themakers.sc.Directory} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Directory, 1));
};


/** @param {?proto.api.themakers.sc.Directory|undefined} value */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.setDirectory = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.api.themakers.sc.OneAssQueryAndWatchMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.clearDirectory = function() {
  this.setDirectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.hasDirectory = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OneAssRecordEvent RecordEvent = 2;
 * @return {?proto.api.themakers.sc.OneAssRecordEvent}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.getRecordevent = function() {
  return /** @type{?proto.api.themakers.sc.OneAssRecordEvent} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.OneAssRecordEvent, 2));
};


/** @param {?proto.api.themakers.sc.OneAssRecordEvent|undefined} value */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.setRecordevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.api.themakers.sc.OneAssQueryAndWatchMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.clearRecordevent = function() {
  this.setRecordevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.hasRecordevent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Dummy Dummy = 3;
 * @return {?proto.api.themakers.sc.Dummy}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.getDummy = function() {
  return /** @type{?proto.api.themakers.sc.Dummy} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Dummy, 3));
};


/** @param {?proto.api.themakers.sc.Dummy|undefined} value */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.setDummy = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.api.themakers.sc.OneAssQueryAndWatchMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.clearDummy = function() {
  this.setDummy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.OneAssQueryAndWatchMsg.prototype.hasDummy = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.themakers.sc.OneAssRecordEvent.oneofGroups_ = [[8,9,10,11]];

/**
 * @enum {number}
 */
proto.api.themakers.sc.OneAssRecordEvent.EventpayloadCase = {
  EVENTPAYLOAD_NOT_SET: 0,
  EXISTINGRECORD: 8,
  RECORDCREATED: 9,
  RECORDUPDATED: 10,
  RECORDDELETED: 11
};

/**
 * @return {proto.api.themakers.sc.OneAssRecordEvent.EventpayloadCase}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.getEventpayloadCase = function() {
  return /** @type {proto.api.themakers.sc.OneAssRecordEvent.EventpayloadCase} */(jspb.Message.computeOneofCase(this, proto.api.themakers.sc.OneAssRecordEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.OneAssRecordEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.OneAssRecordEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.OneAssRecordEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    existingrecord: (f = msg.getExistingrecord()) && proto.api.themakers.sc.Record.toObject(includeInstance, f),
    recordcreated: (f = msg.getRecordcreated()) && proto.api.themakers.sc.Record.toObject(includeInstance, f),
    recordupdated: (f = msg.getRecordupdated()) && proto.api.themakers.sc.Record.toObject(includeInstance, f),
    recorddeleted: (f = msg.getRecorddeleted()) && proto.api.themakers.sc.Record.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.OneAssRecordEvent}
 */
proto.api.themakers.sc.OneAssRecordEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.OneAssRecordEvent;
  return proto.api.themakers.sc.OneAssRecordEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.OneAssRecordEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.OneAssRecordEvent}
 */
proto.api.themakers.sc.OneAssRecordEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = new proto.api.themakers.sc.Record;
      reader.readMessage(value,proto.api.themakers.sc.Record.deserializeBinaryFromReader);
      msg.setExistingrecord(value);
      break;
    case 9:
      var value = new proto.api.themakers.sc.Record;
      reader.readMessage(value,proto.api.themakers.sc.Record.deserializeBinaryFromReader);
      msg.setRecordcreated(value);
      break;
    case 10:
      var value = new proto.api.themakers.sc.Record;
      reader.readMessage(value,proto.api.themakers.sc.Record.deserializeBinaryFromReader);
      msg.setRecordupdated(value);
      break;
    case 11:
      var value = new proto.api.themakers.sc.Record;
      reader.readMessage(value,proto.api.themakers.sc.Record.deserializeBinaryFromReader);
      msg.setRecorddeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.OneAssRecordEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.OneAssRecordEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.OneAssRecordEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExistingrecord();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.api.themakers.sc.Record.serializeBinaryToWriter
    );
  }
  f = message.getRecordcreated();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.api.themakers.sc.Record.serializeBinaryToWriter
    );
  }
  f = message.getRecordupdated();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.api.themakers.sc.Record.serializeBinaryToWriter
    );
  }
  f = message.getRecorddeleted();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.api.themakers.sc.Record.serializeBinaryToWriter
    );
  }
};


/**
 * optional Record ExistingRecord = 8;
 * @return {?proto.api.themakers.sc.Record}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.getExistingrecord = function() {
  return /** @type{?proto.api.themakers.sc.Record} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Record, 8));
};


/** @param {?proto.api.themakers.sc.Record|undefined} value */
proto.api.themakers.sc.OneAssRecordEvent.prototype.setExistingrecord = function(value) {
  jspb.Message.setOneofWrapperField(this, 8, proto.api.themakers.sc.OneAssRecordEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.clearExistingrecord = function() {
  this.setExistingrecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.hasExistingrecord = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Record RecordCreated = 9;
 * @return {?proto.api.themakers.sc.Record}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.getRecordcreated = function() {
  return /** @type{?proto.api.themakers.sc.Record} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Record, 9));
};


/** @param {?proto.api.themakers.sc.Record|undefined} value */
proto.api.themakers.sc.OneAssRecordEvent.prototype.setRecordcreated = function(value) {
  jspb.Message.setOneofWrapperField(this, 9, proto.api.themakers.sc.OneAssRecordEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.clearRecordcreated = function() {
  this.setRecordcreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.hasRecordcreated = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Record RecordUpdated = 10;
 * @return {?proto.api.themakers.sc.Record}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.getRecordupdated = function() {
  return /** @type{?proto.api.themakers.sc.Record} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Record, 10));
};


/** @param {?proto.api.themakers.sc.Record|undefined} value */
proto.api.themakers.sc.OneAssRecordEvent.prototype.setRecordupdated = function(value) {
  jspb.Message.setOneofWrapperField(this, 10, proto.api.themakers.sc.OneAssRecordEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.clearRecordupdated = function() {
  this.setRecordupdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.hasRecordupdated = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Record RecordDeleted = 11;
 * @return {?proto.api.themakers.sc.Record}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.getRecorddeleted = function() {
  return /** @type{?proto.api.themakers.sc.Record} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.Record, 11));
};


/** @param {?proto.api.themakers.sc.Record|undefined} value */
proto.api.themakers.sc.OneAssRecordEvent.prototype.setRecorddeleted = function(value) {
  jspb.Message.setOneofWrapperField(this, 11, proto.api.themakers.sc.OneAssRecordEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.clearRecorddeleted = function() {
  this.setRecorddeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.OneAssRecordEvent.prototype.hasRecorddeleted = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.LoginAsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.LoginAsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.LoginAsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.LoginAsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.LoginAsReq}
 */
proto.api.themakers.sc.LoginAsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.LoginAsReq;
  return proto.api.themakers.sc.LoginAsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.LoginAsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.LoginAsReq}
 */
proto.api.themakers.sc.LoginAsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.LoginAsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.LoginAsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.LoginAsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.LoginAsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string User = 1;
 * @return {string}
 */
proto.api.themakers.sc.LoginAsReq.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.LoginAsReq.prototype.setUser = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Secret = 2;
 * @return {string}
 */
proto.api.themakers.sc.LoginAsReq.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.LoginAsReq.prototype.setSecret = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.LoginAsResp.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.LoginAsResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.LoginAsResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.LoginAsResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.LoginAsResp}
 */
proto.api.themakers.sc.LoginAsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.LoginAsResp;
  return proto.api.themakers.sc.LoginAsResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.LoginAsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.LoginAsResp}
 */
proto.api.themakers.sc.LoginAsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.LoginAsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.LoginAsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.LoginAsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.LoginAsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Session = 1;
 * @return {string}
 */
proto.api.themakers.sc.LoginAsResp.prototype.getSession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.LoginAsResp.prototype.setSession = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string User = 2;
 * @return {string}
 */
proto.api.themakers.sc.LoginAsResp.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.LoginAsResp.prototype.setUser = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Error = 3;
 * @return {string}
 */
proto.api.themakers.sc.LoginAsResp.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.LoginAsResp.prototype.setError = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Dummy.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Dummy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Dummy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Dummy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Dummy}
 */
proto.api.themakers.sc.Dummy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Dummy;
  return proto.api.themakers.sc.Dummy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Dummy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Dummy}
 */
proto.api.themakers.sc.Dummy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Dummy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Dummy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Dummy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Dummy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveReq}
 */
proto.api.themakers.sc.RemoveReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveReq;
  return proto.api.themakers.sc.RemoveReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveReq}
 */
proto.api.themakers.sc.RemoveReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetDescriptionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetDescriptionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetDescriptionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDescriptionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetDescriptionReq}
 */
proto.api.themakers.sc.SetDescriptionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetDescriptionReq;
  return proto.api.themakers.sc.SetDescriptionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetDescriptionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetDescriptionReq}
 */
proto.api.themakers.sc.SetDescriptionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetDescriptionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetDescriptionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetDescriptionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetDescriptionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetDescriptionReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDescriptionReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Description = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetDescriptionReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetDescriptionReq.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetNameReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetNameReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetNameReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetNameReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetNameReq}
 */
proto.api.themakers.sc.SetNameReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetNameReq;
  return proto.api.themakers.sc.SetNameReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetNameReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetNameReq}
 */
proto.api.themakers.sc.SetNameReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetNameReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetNameReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetNameReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetNameReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetNameReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetNameReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetNameReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetNameReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetCodeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetCodeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetCodeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetCodeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetCodeReq}
 */
proto.api.themakers.sc.SetCodeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetCodeReq;
  return proto.api.themakers.sc.SetCodeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetCodeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetCodeReq}
 */
proto.api.themakers.sc.SetCodeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetCodeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetCodeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetCodeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetCodeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetCodeReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetCodeReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Code = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetCodeReq.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetCodeReq.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.Numerator.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Numerator.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Numerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Numerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Numerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    centerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.api.themakers.sc.NumeratorField.toObject, includeInstance),
    modificationtime: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Numerator}
 */
proto.api.themakers.sc.Numerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Numerator;
  return proto.api.themakers.sc.Numerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Numerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Numerator}
 */
proto.api.themakers.sc.Numerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.NumeratorField;
      reader.readMessage(value,proto.api.themakers.sc.NumeratorField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModificationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Numerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Numerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Numerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Numerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.api.themakers.sc.NumeratorField.serializeBinaryToWriter
    );
  }
  f = message.getModificationtime();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.Numerator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Numerator.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CenterID = 2;
 * @return {string}
 */
proto.api.themakers.sc.Numerator.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Numerator.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.api.themakers.sc.Numerator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.api.themakers.sc.Numerator.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated NumeratorField Fields = 4;
 * @return {!Array<!proto.api.themakers.sc.NumeratorField>}
 */
proto.api.themakers.sc.Numerator.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.NumeratorField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.NumeratorField, 4));
};


/** @param {!Array<!proto.api.themakers.sc.NumeratorField>} value */
proto.api.themakers.sc.Numerator.prototype.setFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.api.themakers.sc.NumeratorField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.NumeratorField}
 */
proto.api.themakers.sc.Numerator.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.api.themakers.sc.NumeratorField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.Numerator.prototype.clearFieldsList = function() {
  this.setFieldsList([]);
};


/**
 * optional int64 ModificationTime = 100;
 * @return {number}
 */
proto.api.themakers.sc.Numerator.prototype.getModificationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Numerator.prototype.setModificationtime = function(value) {
  jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.NumeratorField.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.NumeratorField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.NumeratorField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NumeratorField.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: (f = msg.getCode()) && proto.api.themakers.sc.NumeratorCodeField.toObject(includeInstance, f),
    text: (f = msg.getText()) && proto.api.themakers.sc.NumeratorTextField.toObject(includeInstance, f),
    incr: (f = msg.getIncr()) && proto.api.themakers.sc.NumeratorIncrField.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.NumeratorField}
 */
proto.api.themakers.sc.NumeratorField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.NumeratorField;
  return proto.api.themakers.sc.NumeratorField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.NumeratorField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.NumeratorField}
 */
proto.api.themakers.sc.NumeratorField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.NumeratorCodeField;
      reader.readMessage(value,proto.api.themakers.sc.NumeratorCodeField.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 4:
      var value = new proto.api.themakers.sc.NumeratorTextField;
      reader.readMessage(value,proto.api.themakers.sc.NumeratorTextField.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    case 5:
      var value = new proto.api.themakers.sc.NumeratorIncrField;
      reader.readMessage(value,proto.api.themakers.sc.NumeratorIncrField.deserializeBinaryFromReader);
      msg.setIncr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.NumeratorField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.NumeratorField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.NumeratorField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NumeratorField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.themakers.sc.NumeratorCodeField.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.themakers.sc.NumeratorTextField.serializeBinaryToWriter
    );
  }
  f = message.getIncr();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.themakers.sc.NumeratorIncrField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.NumeratorField.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.NumeratorField.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Type = 2;
 * @return {string}
 */
proto.api.themakers.sc.NumeratorField.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.NumeratorField.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NumeratorCodeField Code = 3;
 * @return {?proto.api.themakers.sc.NumeratorCodeField}
 */
proto.api.themakers.sc.NumeratorField.prototype.getCode = function() {
  return /** @type{?proto.api.themakers.sc.NumeratorCodeField} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.NumeratorCodeField, 3));
};


/** @param {?proto.api.themakers.sc.NumeratorCodeField|undefined} value */
proto.api.themakers.sc.NumeratorField.prototype.setCode = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.NumeratorField.prototype.clearCode = function() {
  this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.NumeratorField.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NumeratorTextField Text = 4;
 * @return {?proto.api.themakers.sc.NumeratorTextField}
 */
proto.api.themakers.sc.NumeratorField.prototype.getText = function() {
  return /** @type{?proto.api.themakers.sc.NumeratorTextField} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.NumeratorTextField, 4));
};


/** @param {?proto.api.themakers.sc.NumeratorTextField|undefined} value */
proto.api.themakers.sc.NumeratorField.prototype.setText = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.NumeratorField.prototype.clearText = function() {
  this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.NumeratorField.prototype.hasText = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NumeratorIncrField Incr = 5;
 * @return {?proto.api.themakers.sc.NumeratorIncrField}
 */
proto.api.themakers.sc.NumeratorField.prototype.getIncr = function() {
  return /** @type{?proto.api.themakers.sc.NumeratorIncrField} */ (
    jspb.Message.getWrapperField(this, proto.api.themakers.sc.NumeratorIncrField, 5));
};


/** @param {?proto.api.themakers.sc.NumeratorIncrField|undefined} value */
proto.api.themakers.sc.NumeratorField.prototype.setIncr = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.api.themakers.sc.NumeratorField.prototype.clearIncr = function() {
  this.setIncr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.themakers.sc.NumeratorField.prototype.hasIncr = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.SetNumeratorReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.SetNumeratorReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.SetNumeratorReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.SetNumeratorReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetNumeratorReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.api.themakers.sc.NumeratorField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.SetNumeratorReq}
 */
proto.api.themakers.sc.SetNumeratorReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.SetNumeratorReq;
  return proto.api.themakers.sc.SetNumeratorReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.SetNumeratorReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.SetNumeratorReq}
 */
proto.api.themakers.sc.SetNumeratorReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.NumeratorField;
      reader.readMessage(value,proto.api.themakers.sc.NumeratorField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.SetNumeratorReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.SetNumeratorReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.SetNumeratorReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.SetNumeratorReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.themakers.sc.NumeratorField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.SetNumeratorReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetNumeratorReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.SetNumeratorReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.SetNumeratorReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated NumeratorField Fields = 3;
 * @return {!Array<!proto.api.themakers.sc.NumeratorField>}
 */
proto.api.themakers.sc.SetNumeratorReq.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.NumeratorField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.NumeratorField, 3));
};


/** @param {!Array<!proto.api.themakers.sc.NumeratorField>} value */
proto.api.themakers.sc.SetNumeratorReq.prototype.setFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.themakers.sc.NumeratorField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.NumeratorField}
 */
proto.api.themakers.sc.SetNumeratorReq.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.themakers.sc.NumeratorField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.SetNumeratorReq.prototype.clearFieldsList = function() {
  this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.themakers.sc.CreateNumeratorReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.CreateNumeratorReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.CreateNumeratorReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.CreateNumeratorReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateNumeratorReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    centerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.api.themakers.sc.NumeratorField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.CreateNumeratorReq}
 */
proto.api.themakers.sc.CreateNumeratorReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.CreateNumeratorReq;
  return proto.api.themakers.sc.CreateNumeratorReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.CreateNumeratorReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.CreateNumeratorReq}
 */
proto.api.themakers.sc.CreateNumeratorReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCenterid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.api.themakers.sc.NumeratorField;
      reader.readMessage(value,proto.api.themakers.sc.NumeratorField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.CreateNumeratorReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.CreateNumeratorReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.CreateNumeratorReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.CreateNumeratorReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenterid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.themakers.sc.NumeratorField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string CenterID = 1;
 * @return {string}
 */
proto.api.themakers.sc.CreateNumeratorReq.prototype.getCenterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateNumeratorReq.prototype.setCenterid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.api.themakers.sc.CreateNumeratorReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.CreateNumeratorReq.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated NumeratorField Fields = 3;
 * @return {!Array<!proto.api.themakers.sc.NumeratorField>}
 */
proto.api.themakers.sc.CreateNumeratorReq.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.api.themakers.sc.NumeratorField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.themakers.sc.NumeratorField, 3));
};


/** @param {!Array<!proto.api.themakers.sc.NumeratorField>} value */
proto.api.themakers.sc.CreateNumeratorReq.prototype.setFieldsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.themakers.sc.NumeratorField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.themakers.sc.NumeratorField}
 */
proto.api.themakers.sc.CreateNumeratorReq.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.themakers.sc.NumeratorField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.api.themakers.sc.CreateNumeratorReq.prototype.clearFieldsList = function() {
  this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.GetNumeratorReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.GetNumeratorReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.GetNumeratorReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetNumeratorReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.GetNumeratorReq}
 */
proto.api.themakers.sc.GetNumeratorReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.GetNumeratorReq;
  return proto.api.themakers.sc.GetNumeratorReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.GetNumeratorReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.GetNumeratorReq}
 */
proto.api.themakers.sc.GetNumeratorReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.GetNumeratorReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.GetNumeratorReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.GetNumeratorReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.GetNumeratorReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.GetNumeratorReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.GetNumeratorReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveNumeratorReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveNumeratorReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveNumeratorReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveNumeratorReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveNumeratorReq}
 */
proto.api.themakers.sc.RemoveNumeratorReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveNumeratorReq;
  return proto.api.themakers.sc.RemoveNumeratorReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveNumeratorReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveNumeratorReq}
 */
proto.api.themakers.sc.RemoveNumeratorReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveNumeratorReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveNumeratorReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveNumeratorReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveNumeratorReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveNumeratorReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveNumeratorReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.RemoveNumeratorFieldReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.RemoveNumeratorFieldReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.RemoveNumeratorFieldReq}
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.RemoveNumeratorFieldReq;
  return proto.api.themakers.sc.RemoveNumeratorFieldReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.RemoveNumeratorFieldReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.RemoveNumeratorFieldReq}
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.RemoveNumeratorFieldReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.RemoveNumeratorFieldReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveNumeratorFieldReq.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.api.themakers.sc.RemoveNumeratorFieldReq.prototype.getFieldid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.RemoveNumeratorFieldReq.prototype.setFieldid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.NumeratorCodeField.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.NumeratorCodeField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.NumeratorCodeField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NumeratorCodeField.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.NumeratorCodeField}
 */
proto.api.themakers.sc.NumeratorCodeField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.NumeratorCodeField;
  return proto.api.themakers.sc.NumeratorCodeField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.NumeratorCodeField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.NumeratorCodeField}
 */
proto.api.themakers.sc.NumeratorCodeField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.NumeratorCodeField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.NumeratorCodeField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.NumeratorCodeField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NumeratorCodeField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Source = 1;
 * @return {string}
 */
proto.api.themakers.sc.NumeratorCodeField.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.NumeratorCodeField.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.NumeratorTextField.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.NumeratorTextField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.NumeratorTextField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NumeratorTextField.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.NumeratorTextField}
 */
proto.api.themakers.sc.NumeratorTextField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.NumeratorTextField;
  return proto.api.themakers.sc.NumeratorTextField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.NumeratorTextField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.NumeratorTextField}
 */
proto.api.themakers.sc.NumeratorTextField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.NumeratorTextField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.NumeratorTextField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.NumeratorTextField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NumeratorTextField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.api.themakers.sc.NumeratorTextField.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.api.themakers.sc.NumeratorTextField.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.NumeratorIncrField.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.NumeratorIncrField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.NumeratorIncrField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NumeratorIncrField.toObject = function(includeInstance, msg) {
  var f, obj = {
    pad: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resetperiod: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.NumeratorIncrField}
 */
proto.api.themakers.sc.NumeratorIncrField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.NumeratorIncrField;
  return proto.api.themakers.sc.NumeratorIncrField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.NumeratorIncrField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.NumeratorIncrField}
 */
proto.api.themakers.sc.NumeratorIncrField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPad(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetperiod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.NumeratorIncrField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.NumeratorIncrField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.NumeratorIncrField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.NumeratorIncrField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPad();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getResetperiod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 Pad = 1;
 * @return {number}
 */
proto.api.themakers.sc.NumeratorIncrField.prototype.getPad = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.api.themakers.sc.NumeratorIncrField.prototype.setPad = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ResetPeriod = 2;
 * @return {string}
 */
proto.api.themakers.sc.NumeratorIncrField.prototype.getResetperiod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.api.themakers.sc.NumeratorIncrField.prototype.setResetperiod = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.themakers.sc.Ping.prototype.toObject = function(opt_includeInstance) {
  return proto.api.themakers.sc.Ping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.themakers.sc.Ping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Ping.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.themakers.sc.Ping}
 */
proto.api.themakers.sc.Ping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.themakers.sc.Ping;
  return proto.api.themakers.sc.Ping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.themakers.sc.Ping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.themakers.sc.Ping}
 */
proto.api.themakers.sc.Ping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.themakers.sc.Ping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.themakers.sc.Ping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.themakers.sc.Ping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.themakers.sc.Ping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 Timestamp = 1;
 * @return {number}
 */
proto.api.themakers.sc.Ping.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.api.themakers.sc.Ping.prototype.setTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.api.themakers.sc);
