var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "date-picker",
    class: {
      'date-picker_disabled': _vm.datepickerDisabled
    }
  }, [_c('DatePicker', {
    attrs: {
      "lang": _vm.lang,
      "format": _vm.format,
      "placeholder": _vm.format,
      "disabled": _vm.datepickerDisabled,
      "clearable": _vm.clearable,
      "show-time-header": true
    },
    on: {
      "input": function input($event) {
        return _vm.emitInput($event);
      },
      "blur": _vm.emitBlur
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('div')]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }