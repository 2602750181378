
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import getError, { GrpcError } from '@/tools/errors/errors'
import { STATUS_NAMES, Task } from '@/model/Task'
import { DocumentType } from '@/model/DocumentType'
import { Location } from 'vue-router'

@Component({})

export default class TaskCardMixin extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: null }) task!: Task

    // *************************************************************
    // COMPUTED
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    get status(): string {
        return STATUS_NAMES[this.task.status]
    }

    get statusColor(): string {
        if (this.task.status === 'execution') {
            return 'pink-1'
        }
        if (this.task.status === 'confirmation') {
            return 'amber'
        }
        return 'black'
    }

    get typeName(): string {
        // Если есть привязка к документу
        if (!this.task.document) {
            return ''
        }
        return this.getTypeById(this.task.document.type)?.name ?? ''
    }

    get documentPath(): Location | null {
        if (!this.task.document) {
            return null
        }

        return {
            name: 'document',
            params: {
                section: 'documents',
                module: this.task.document.type,
                id: this.task.document.id
            }
        }
    }

    // *************************************************************
    // METHODS
    @Action('task/callComplete')
    callComplete!: (id: string) => Promise<void>

    @Action('task/callConfirm')
    callConfirm!: (id: string) => Promise<void>

    @Action('task/callReject')
    callReject!: (id: string) => Promise<void>

    @Action('task/callClose')
    callClose!: (id: string) => Promise<void>

    @Action('task/callReopen')
    callReopen!: (id: string) => Promise<void>

    openDocument(): void {
        // Открыть документ
        if (!this.task.document) {
            return
        }

        this.$router.push({
            name: 'document',
            params: {
                section: 'documents',
                module: this.task.document.type,
                id: this.task.document.id
            }
        })
    }

    action(key: string): void {
        let preloaderText = ''
        let action: (id: string) => Promise<void> = this.callComplete

        if (key === 'complete') {
            preloaderText = 'Выполнение задачи'
            action = this.callComplete
        } else if (key === 'confirm') {
            preloaderText = 'Подтверждение задачи'
            action = this.callConfirm
        } else if (key === 'reject') {
            preloaderText = 'Отклонение задачи'
            action = this.callReject
        } else if (key === 'close') {
            preloaderText = 'Отмена задачи'
            action = this.callClose
        } else if (key === 'fuck') {
            preloaderText = 'Открытие задачи'
            action = this.callReopen
        } else {
            this.$snotify.error(`Обратитесь в тех.поддержку - TaskModal - action:: ${key}`, {
                timeout: 0
            })
        }

        const cancelPreloaderTaskAction = this.$preloader('task_action', preloaderText)

        {
            action(this.task.id)
                .catch((error: GrpcError) => {
                    this.$snotify.error(getError(error))
                })
                .finally(() => cancelPreloaderTaskAction())
        }
    }
}
