import Vue from 'vue'
import { MutationTree } from 'vuex'
import { WorkflowState } from '@/store/workflow'

import { ApprovalTemplate } from '@/model/Workflow'
import { Actor } from '@/model/Actor'

const GraphMutations: MutationTree<WorkflowState> = {
    addWFApproval(state: WorkflowState, approval: ApprovalTemplate) {
        const index = state.workflow.activeStage
        if (index !== null) {
            state.workflow.stages[index].approvals.push(approval)
        }
    },

    addWFLink(state: WorkflowState, params: { nodeId: string, inputId: string }) {
        if (state.workflow.activeStage === null) {
            console.error('addWFLink:: active stage is null')
        } else {
            const activeStage = state.workflow.stages[state.workflow.activeStage]
            if (!activeStage) {
                console.error('addWFLink:: active stage is null')
            } else {
                const approval = activeStage.approvals.find((app: ApprovalTemplate) => app.id === params.nodeId)
                if (!approval) {
                    console.error('addWFLink:: approval not found', params.nodeId)
                } else {
                    approval.inputs.push(params.inputId)
                }
            }
        }
    },

    changeWFApproval(state: WorkflowState, params: { id: string, actor: Actor | null, specialName: string }) {
        const activeStageIndex = state.workflow.activeStage
        if (activeStageIndex === null) {
            console.error('addWFLink:: active stage is null')
        } else {
            const nodeIndex = state.workflow.stages[activeStageIndex].approvals.findIndex((app: ApprovalTemplate) => app.id === params.id)

            if (nodeIndex !== -1) {
                Vue.set(state.workflow.stages[activeStageIndex].approvals[nodeIndex], 'actor', params.actor)
                Vue.set(state.workflow.stages[activeStageIndex].approvals[nodeIndex], 'specialName', params.specialName)
            } else {
                console.error('ChangeApproval:: nodeIndex not found')
            }
        }
    },

    removeWFApproval(state: WorkflowState, nodeIndex: number) {
        const activeStageIndex = state.workflow.activeStage
        if (activeStageIndex === null) {
            console.error('removeWFApproval:: mutation:: active stage is null')
        } else {
            state.workflow.stages[activeStageIndex].approvals.splice(nodeIndex, 1)
        }
    },

    removeWFLink(state: WorkflowState, params: { nodeId: string, inputId: string }) {
        const activeStageIndex = state.workflow.activeStage
        if (activeStageIndex === null) {
            console.error('removeWFLink:: active stage is null')
        } else {
            const activeStage = state.workflow.stages[activeStageIndex]
            const node = activeStage.approvals.find((app: ApprovalTemplate) => app.id === params.nodeId)
            if (!node) {
                console.error('removeWFLink:: mutation:: node not exists', params.nodeId)
            } else {
                const inputIndex = node.inputs.indexOf(params.inputId)
                node.inputs.splice(inputIndex, 1)
            }
        }
    }
}

export default GraphMutations
