import { render, staticRenderFns } from "./UserFamiliarization.vue?vue&type=template&id=639c364e&scoped=true&"
import script from "./UserFamiliarization.vue?vue&type=script&lang=js&"
export * from "./UserFamiliarization.vue?vue&type=script&lang=js&"
import style0 from "./UserFamiliarization.vue?vue&type=style&index=0&id=639c364e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639c364e",
  null
  
)

export default component.exports