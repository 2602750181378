









import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Spinner.
 *
 * @prop {Number} size - Высота и ширина spinner
 * @prop {String} color - Цвет spinner
 * @displayName SwSpinner
 * */
@Component<Spinner>({
})

export default class Spinner extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: 24 }) size!: number
    @Prop({ default: 'red' }) color!: string

    get styleObject() {
        return {}
    }
}
