var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [(_vm.checkedApprovals || []).length === 1 ? _c('ButtonViolet', {
    staticClass: "approval-actions__button",
    attrs: {
      "small": true,
      "text": "Согласовать"
    },
    on: {
      "click": _vm.accept
    }
  }) : _vm._e(), _vm._v(" "), (_vm.checkedApprovals || []).length >= 2 ? _c('ButtonViolet', {
    staticClass: "approval-actions__button",
    attrs: {
      "text": "Сбросить",
      "small": true
    },
    on: {
      "click": _vm.showReason
    }
  }) : _vm._e(), _vm._v(" "), _c('DWF_RejectedReason', {
    attrs: {
      "visible": _vm.visibleReject
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleReject = $event;
      },
      "changeReason": function changeReason($event) {
        return _vm.drop($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }