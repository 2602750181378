














































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { cuntPB } from 'api'
import { DocumentType } from '@/model/DocumentType'
import getError, { GrpcError } from '@/tools/errors/errors'

import Modal from '@/components/common/modals/Modal.vue'
import FormInput from '@/components/common/inputs/FormInput.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import SelectRoleUnit from '@/components/common/SelectRoleUnit.vue'
import Button from '@/components/common/buttons/Button.vue'

const rolesList: Array<string> = ['Employee', 'Head']

@Component({
    components: {
        Modal,
        FormInput,
        InputBase,
        SelectRoleUnit,
        Button
    }
})

export default class DocumentCreationModal extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) typeId!: string
    @Prop({ default: false }) demo!: boolean
    @Prop({ default: false }) title!: string
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean

    // *************************************************************
    // DATA PARAMS
    newDocumentName = ''
    originUnit = ''
    rolesList = rolesList

    // *************************************************************
    // COMPUTED
    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    get disabledCreate(): boolean {
        console.log('newDocumentName', this.newDocumentName)
        console.log('originUnit', this.originUnit)
        return (!this.newDocumentName || !this.originUnit)
    }

    get centerId(): string {
        return this.getTypeById(this.typeId)?.centerId ?? ''
    }

    // *************************************************************
    // METHODS
    @Action('document/callNewDocument')
    callNewDocument!: (params: cuntPB.NewDocumentReq) => Promise<string>

    close(): void {
        // Обнуляем выбранные units
        this.originUnit = ''
        this.newDocumentName = ''
    }

    hide(): void {
        this.syncedVisible = false
        this.close()
    }

    addNewDocument(): void {
        // Если заполнено имя документа и подразделение
        if (!this.disabledCreate) {
            // Если этот компонент используется не для демонстрации
            if (!this.demo) {
                // Запускаем создание документа - Вызываем функцию для отправки данных на сервер
                this.createNewDocument()
            } else {
                this.hide()
            }
        }
    }

    createNewDocument(): void {
        const cancelPreloaderCreateDocument = this.$preloader('create_document', 'Создание документа')

        this.callNewDocument({
            Name: this.newDocumentName,
            Type: this.typeId,
            OriginUnit: this.originUnit
        })
            .then((id: string) => {
                //Закрываем Modal
                this.hide()

                // Проваливаемся в созданный документ
                // В следующем цикле - это костыль
                // Не успела разобраться, когда правильно закрывать Modal & проваливаться во вновь созданный документ
                setTimeout(() => {
                    this.$router.push({
                        name: 'document',
                        params: {
                            section: 'documents',
                            module: this.$route.params.module,
                            id: id
                        }
                    })
                }, 0)
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderCreateDocument())
    }
}
