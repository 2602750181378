var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onBlurEmail($event);
      }
    }
  }, [_c('input', {
    staticClass: "input w-100",
    class: {
      'input_error': _vm.errorEmail
    },
    attrs: {
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.value.identity
    },
    on: {
      "input": function input($event) {
        return _vm.onInputEmail($event.target.value);
      },
      "blur": _vm.onBlurEmail
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "form__error"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorEmail,
      expression: "errorEmail"
    }]
  }, [_vm._v(_vm._s(_vm.errorEmail))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }