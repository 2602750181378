import { DocumentType, RequisiteType, DocumentTypeOneAss } from '@/model/old/documentType1'

export default function newDocumentType(data) {
    return new DocumentType(
        data.ID,
        data.CenterID,
        data.Name,
        data.Description,
        data.Code,
        data.Numerator,
        data.Requisites.map(requisite => new RequisiteType(
            requisite.ID,
            requisite.Name,
            requisite.Type,
            requisite.SubType,
            requisite.Required,
            requisite.Editable,
            requisite.FromExternalSource
        )),
        new DocumentTypeOneAss((data.OneAss || {}).CollectionID),
        data.Modificationtime
    )
}
