var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "requisite-card box-shadow__double"
  }, [_c('div', {
    staticClass: "flex-row-center mb-3"
  }, [_c('InputBase', {
    class: {
      'requisite-card__name': true,
      'box-shadow__error': !_vm.name
    },
    attrs: {
      "disabled": _vm.requisite.fromExternalSource,
      "placeholder": "Имя реквизита"
    },
    on: {
      "blur": function blur($event) {
        return _vm.updateRequisite('name');
      }
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.updateRequisite('name');
      }
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c('hr', {
    staticClass: "requisite-card__hr"
  }), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center requisite-card__actions"
  }, [_c('div', {
    staticClass: "requisite-card__action requisite-card__action_border",
    on: {
      "click": _vm.moveUp
    }
  }, [_c('FAIcon', {
    staticClass: "requisite-card__icon",
    class: {
      'requisite-card__icon_disabled': _vm.first || _vm.requisite.fromExternalSource
    },
    attrs: {
      "icon": "arrow-up"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "requisite-card__action requisite-card__action_border",
    on: {
      "click": _vm.moveDown
    }
  }, [_c('FAIcon', {
    staticClass: "requisite-card__icon",
    class: {
      'requisite-card__icon_disabled': _vm.last || _vm.requisite.fromExternalSource
    },
    attrs: {
      "icon": "arrow-down"
    }
  })], 1), _vm._v(" "), _c('ButtonDelete', {
    staticClass: "requisite-card__action",
    attrs: {
      "tooltip": _vm.requisite.fromExternalSource ? 'Реквизит внешней системы' : 'Удалить реквизит',
      "disabled": _vm.requisite.fromExternalSource,
      "flow": "left"
    },
    on: {
      "click": _vm.showRemove
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, [_c('Checkbox', {
    staticClass: "ml-5",
    attrs: {
      "disabled": _vm.requisite.fromExternalSource,
      "label": "Обязательный"
    },
    on: {
      "change": function change($event) {
        return _vm.updateRequisite('required');
      }
    },
    model: {
      value: _vm.required,
      callback: function callback($$v) {
        _vm.required = $$v;
      },
      expression: "required"
    }
  }), _vm._v(" "), _c('Checkbox', {
    staticClass: "ml-5",
    attrs: {
      "disabled": _vm.requisite.fromExternalSource,
      "label": "Редактируемый"
    },
    on: {
      "change": function change($event) {
        return _vm.updateRequisite('editable');
      }
    },
    model: {
      value: _vm.editable,
      callback: function callback($$v) {
        _vm.editable = $$v;
      },
      expression: "editable"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center requisite-card__block ml-auto"
  }, [_c('div', {
    staticClass: "requisite-card__select-title"
  }, [_vm._v("\n                Тип реквизита:\n            ")]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.requisitesTypesDisplayName[_vm.requisite.type]))])]), _vm._v(" "), _vm.requisite.type === 'req_do' ? _c('div', {
    staticClass: "flex-row-center requisite-card__block ml-5"
  }, [_c('div', {
    staticClass: "requisite-card__select-title"
  }, [_vm._v("\n                Справочник:\n            ")]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.directory))])]) : _vm._e()], 1), _vm._v(" "), _c('RemoveModal', {
    ref: "removeModal",
    attrs: {
      "visible": _vm.visibleRemoveModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleRemoveModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }