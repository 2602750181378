import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { DocumentState } from '@/store/document/documentState'

import { service, cuntPB } from 'api'
import { Document } from '@/model/Document'

const DocumentWorkflowActions: ActionTree<DocumentState, RootState> = {
    async callAccept({ dispatch }, params: cuntPB.WorkflowApprovalAcceptreq) {
        const data: cuntPB.Document = await service.workflow.Approval_Accept(params)
        console.log('::DATA::WorkflowSvcClient.approval_Accept:', data)
        dispatch('putDocument', new Document(data))
    },

    async callDropTo({ dispatch }, params: cuntPB.WorkflowApprovalDroptoreq) {
        const data: cuntPB.Document = await service.workflow.Approval_DropTo(params)
        console.log('::DATA::WorkflowSvcClient.approval_DropTo:', data)
        dispatch('putDocument', new Document(data))
    },

    async callTaskComplete({ dispatch }, params: cuntPB.WorkflowTaskCompletereq) {
        const data: cuntPB.Document = await service.workflow.Task_Complete(params)
        console.log('::DATA::DocumentSvcClient.task_Complete:', data)
        dispatch('putDocument', new Document(data))
    },

    async callTaskConfirm({ dispatch }, params: cuntPB.WorkflowTaskConfirmreq) {
        const data: cuntPB.Document = await service.workflow.Task_Confirm(params)
        console.log('::DATA::DocumentSvcClient.task_Confirm:', data)
        dispatch('putDocument', new Document(data))
    },

    async callTaskReject({ dispatch }, params: cuntPB.WorkflowTaskRejectreq) {
        const data: cuntPB.Document = await service.workflow.Task_Reject(params)
        console.log('::DATA::DocumentSvcClient.task_Reject:', data)
        dispatch('putDocument', new Document(data))
    },
}

export default DocumentWorkflowActions
