<template>
    <div
        v-if="familiarization"
        class="familiarization-edit"
    >
        <div class="flex-row-center familiarization__margin">
            <div>
                Дата создания:
                <span>{{ familiarization.creationTimeLocalizedShortString }}</span>
            </div>
            <div class="mx-auto flex-row-center">
                <span>Статус:</span>
                <Select
                    v-model="status"
                    placeholder="Тип поля"
                    :options="statusesWithDisabled"
                    class="familiarization__item familiarization-edit__status h-36 ml-2"
                    @change="changeStatus"
                />
            </div>
            <div>
                Автор:
                <span class="familiarization__item">{{ familiarization.actor.user.name.shortName }}</span>
            </div>
        </div>
        <InputBase
            v-model.lazy.trim="name"
            :disabled="familiarization.status !== 'draft'"
            type="text"
            placeholder="Название"
            class="familiarization__title familiarization__margin"
        />
        <TextEditor
            v-if="familiarization.status === 'draft'"
            key="draft"
            v-model="description"
        />
        <EditorViewer
            v-else
            key="active"
            :value="familiarization.description"
            class="familiarization__description"
        />
        <div class="familiarization__margin flex-row-center">
            <div v-if="familiarization.status === 'draft' || familiarization.documents.length">
                Документы:
            </div>
            <div
                v-if="familiarization.status === 'draft'"
                tooltip="Редактировать"
                flow="right"
                class="familiarization__icon"
                @click="addDocument"
            >
                <FAIcon
                    icon="edit"
                    class="pink-1"
                />
            </div>
        </div>
        <div
            v-for="doc in documents"
            :key="doc.id"
            class="familiarization__document"
            @click="openDocument(doc)"
        >
            <div>{{ typeNames[doc.type] }}: {{ doc.name }}</div>
        </div>

        <Files
            v-if="familiarization.status === 'draft' || familiarization.files.length"
            class="familiarization__margin"
            :files="familiarization.files"
            :document-id="familiarization.id"
            :can-add-files="familiarization.status === 'draft'"
            :can-change="familiarization.status === 'draft'"
            group-name="familiarization_attachment"
            name="familiarization"
        />
        <div class="flex-row-center">
            <h4>Кого необходимо ознакомить:</h4>
            <ButtonAdd
                v-if="familiarization.status !== 'draft'"
                tooltip="Добавить пользователей"
                flow="right"
                class="ml-5"
                @click="showAddMembers"
            />
        </div>

        <div
            v-if="familiarization.status === 'draft'"
            key="select-edit"
        >
            <ActionSelectCard
                v-model="userIds"
                :multiple="true"
                placeholder="Выбрать пользователей"
                entity="user"
                :items="users"
                sort="name"
                sub-sort-property="fullName"
            />
            <ActionSelectCard
                v-if="familiarization.status === 'draft'"
                v-model="unitIds"
                :multiple="true"
                placeholder="Выбрать подразделения"
                entity="unit"
                :items="units"
                sort="name"
                class="familiarization__margin"
            />
        </div>
        <ol
            v-else
            key="select-view"
        >
            <li
                v-for="item in familiarization.сheckList"
                :key="item.user.id"
            >
                {{ item.user.name.fullName }}
            </li>
        </ol>

        <div
            v-if="familiarization.status === 'draft'"
            key="until-edit"
        >
            <div class="familiarization__margin">
                Ознакомиться до:
            </div>
            <DateTimeInput
                v-model="until"
                :value="until"
                class="familiarization__until"
            />
        </div>
        <div
            v-else-if="familiarization.until"
            key="until-view"
        >
            <div class="familiarization__margin">
                Ознакомиться до:
            </div>
            <div :style="{color: familiarization.until < timeNow ? 'red': 'black'}">
                {{ familiarization.untilLocalizedShortString }}
            </div>
        </div>

        <ButtonGreen
            v-if="familiarization.status === 'draft'"
            text="Сохранить"
            class="ml-auto mt-5"
            @click="save"
        />

        <FamiliarizationAddDocument
            ref="addDocument"
            v-model="documents"
            :center-id="familiarization.centerId"
        />

        <FamiliarizationAddMemebers
            ref="addMembers"
            :users="usersForAdd"
            :units="units"
        />
        <ConfirmModal ref="confirm" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InputBase from '@/components/common/inputs/InputBase'
import TextEditor from '@/components/common/editor/TextEditor'
import EditorViewer from '@/components/common/editor/EditorViewer'
import Files from '@/components/common/files/Files'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard'
import ButtonGreen from '@/components/common/buttons/ButtonGreen'
import FamiliarizationAddDocument from '@/components/familiarization/FamiliarizationAddDocument'
import FamiliarizationAddMemebers from '@/components/familiarization/FamiliarizationAddMemebers'
import Select from '@/components/common/select/Select'
import ConfirmModal from '@/components/common/modals/ConfirmModal'
import DateTimeInput from '@/components/common/dateTimePicker/DateTimeInput'
import ButtonAdd from '@/components/common/buttons/ButtonAdd'
import FamiliarizationMixin from '@/components/familiarization/FamiliarizationMixin'
import errors from '@/tools/errors/errors.ts'
import { statuses, statusesArray } from '@/components/familiarization/util'

export default {
    components: { InputBase, Files, ActionSelectCard, ButtonGreen, FamiliarizationAddDocument,
        Select, ConfirmModal, DateTimeInput, ButtonAdd, FamiliarizationAddMemebers, TextEditor, EditorViewer
    },
    mixins: [FamiliarizationMixin],
    data() {
        return {
            statuses,
            timeNow: Date.now(),
            status: '',
            name: '',
            description: '',
            type: 'user',
            unitIds: [],
            userIds: [],
            documents: [],
            forceNewMembers: false,
            until: 0,
            documentId: ''
        }
    },
    computed: {
        ...mapGetters('me', ['getMe', 'isCenterManager']),
        ...mapGetters('company_structure', ['getUsersByCenterId', 'getUnitsByCenterId', 'getChildrenUnits']),
        manager() {
            return this.isCenterManager(this.familiarization.centerId)
        },
        headersRoles() {
            return this.getMe.roles.filter(role => role.name === 'Head')
        },
        users() {
            return this.getUsersByCenterId(this.familiarization.centerId).filter(u => u.id !== this.getMe.id)
        },
        usersForAdd() {
            const users = this.familiarization.сheckList.map(item => item.user.id)
            return this.users.filter(u => !users.includes(u.id))
        },
        units() {
            if (this.manager) {
                return this.getUnitsByCenterId(this.familiarization.centerId)
            }
            if (this.headersRoles.length) {
                const units = []
                this.headersRoles.forEach(role => {
                    const children = this.getChildrenUnits(role.unit)
                    children.forEach(ch => {
                        if (!units.find(u => u === ch.id)) {
                            units.push(ch)
                        }
                    })
                })
                return units
            }
            return []
        },
        statusesWithDisabled() {
            return statusesArray.map((s) => {
                return { ...s, disabled: (s.id === this.status || (this.status === 'archived' && s.id === 'active')) }
            })
        }
    },
    watch: {
        familiarization: {
            immediate: true,
            deep: true,
            handler(to) {
                console.log('WATCH::familiarization', to)
                if (to) {
                    this.name = to.name
                    this.description = to.description
                    this.documents = to.documents
                    this.userIds = to.сheckList.map(u => u.user.id)
                    this.unitIds = to.unitIds
                    this.status = to.status
                    this.until = to.until
                } else {
                    this.clearData()
                }
            }
        }
    },
    methods: {
        ...mapActions('familiarization', ['callUpdate', 'callActive', 'callDraft', 'callArchived']),
        clearData() {
            this.name = ''
            this.description = ''
            this.documents = []
            this.userIds = []
            this.unitIds = []
            this.status = ''
            this.until = 0
        },
        save() {
            if (this.until && this.until < Date.now()) {
                this.$snotify.error('Дата окончания ознакомления прошла', 'Отклонено!', {
                    timeout: 0
                })
                return
            }

            if (!this.name) {
                this.$snotify.error('Введите Название', 'Отклонено!', {
                    timeout: 0
                })
                return
            }
            this._cancelPreloaderUpdateFamiliarization = this.$preloader('update_familiarization', 'Сохранение ознакомления')

            this.callUpdate({
                id: this.familiarization.id,
                title: this.name,
                description: this.description,
                forceNewMembers: this.forceNewMembers,
                unitIds: this.unitIds,
                until: this.until,
                documentIds: this.documents.map(doc => doc.id),
                userIds: this.userIds
            }).then(() => {
                this._cancelPreloaderUpdateFamiliarization()
            }).catch(
                error => {
                    this._cancelPreloaderUpdateFamiliarization()
                    this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                        timeout: 0
                    })
                })
        },
        addDocument() {
            this.$refs.addDocument.show()
        },
        changeStatus() {
            if (this.status === 'active') {
                if (!this.unitIds.length && !this.userIds.length) {
                    this.$snotify.error('Кого необходимо ознакомить?', 'Отклонено!', {
                        timeout: 0
                    })
                    return
                }

                if (this.until && this.until < Date.now()) {
                    this.$snotify.error('Дата окончания ознакомления прошла', 'Отклонено!', {
                        timeout: 0
                    })
                    this.status = this.familiarization.status
                    return
                }

                this.$refs.confirm.show({
                    text: 'После перевода статуса в Ознакомление Вы не сможете поменять Название, Описание и Документы',
                    onOk: () => this.callChangeStatus(this.callActive),
                    onCancel: () => {
                        this.status = this.familiarization.status
                    }
                })
                return
            }
            if (this.status === 'draft') {
                this.$refs.confirm.show({
                    text: 'После перевода статуса в Черновик всем участникам придется ознакомиться заново',
                    onOk: () => this.callChangeStatus(this.callDraft),
                    onCancel: () => {
                        this.status = this.familiarization.status
                    }
                })
                return
            }
            this.callChangeStatus(this.callArchived)
        },
        callChangeStatus(callback) {
            this._cancelPreloaderChangeStatusFamiliarization = this.$preloader('change_status_familiarization', 'Смена статуса')
            callback({ id: this.familiarization.id }).then(() => {
                this._cancelPreloaderChangeStatusFamiliarization()
            }).catch(
                error => {
                    this._cancelPreloaderChangeStatusFamiliarization()
                    this.status = this.familiarization.status
                    this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                        timeout: 0
                    })
                })
        },
        showAddMembers() {
            this.$refs.addMembers.show()
        }
    }
}

</script>

<style lang="stylus" scoped>
.familiarization-edit
    display flex
    flex-direction column
    padding-bottom 20px

.familiarization-edit__status
    min-width 150px

.familiarization__title
    height 40px

.familiarization__description
    padding 5px
    border 1px solid #ccc
    box-shadow 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)

.familiarization__until
    height 32px

.familiarization__icon
    margin-left 15px
    cursor pointer
</style>
