import Vue from 'vue'
import { MutationTree } from 'vuex'
import { DocumentTypeState } from '@/store/document_type/documentTypeState'
import { DocumentType } from '@/model/DocumentType'

const DocumentTypeMutations: MutationTree<DocumentTypeState> = {
    putDocumentType(state: DocumentTypeState, type: DocumentType) {
        const index = state.documentTypes.findIndex((d: DocumentType) => d.id === type.id)
        if (index === -1) {
            state.documentTypes.push(type)
        } else {
            Vue.set(state.documentTypes, index, type)
        }
    },

    removeDocumentType(state: DocumentTypeState, typeId: string) {
        const index = state.documentTypes.findIndex((d: DocumentType) => d.id === typeId)
        if (index === -1) {
            console.warn('Ошибка при удалении типа документа - типа с таким id не существует', typeId)
        } else {
            // По найденному индексу удаляем элемент из массива
            state.documentTypes.splice(index, 1)
        }
    },

    cleanUpState(state: DocumentTypeState) {
        state.documentTypes = []
    }
}

export default DocumentTypeMutations
