

























import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator'

import InputBase from '@/components/common/inputs/InputBase.vue'

@Component({
    components: { InputBase }
})

export default class InputWithLabel extends Vue {
    @Ref() input!: HTMLInputElement

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) value!: string
    @Prop({ default: '' }) label!: string
    @Prop({ default: false }) center!: boolean
    @Prop({ default: false }) required!: boolean
    @Prop({ default: 1 }) minLength!: number
    @Prop({ default: false }) loading!: boolean

    // *************************************************************
    // DATA PARAMS
    focus = false
    errorValue = false

    // *************************************************************
    // METHODS
    onClick(): void {
        // this.onFocus()
        this.input.focus()
    }

    onFocus(): void {
        this.emitFocus()
        this.focus = true
    }

    onBlur(): void {
        this.emitBlur()
        this.focus = false
        console.log('required', this.required)
        if (this.required && this.value.length < this.minLength) {
            this.errorValue = true
        }
    }

    onInput(val: string): void {
        this.errorValue = false
        this.emitInput(val)
    }

    // *************************************************************
    // EMIT
    @Emit('focus')
    emitFocus(): void {
        // Do nothing
    }

    @Emit('blur')
    emitBlur(): void {
        // Do nothing
    }

    @Emit('input')
    emitInput(val: string): string {
        return val
    }

    @Emit('enter')
    emitEnter(): void {
        // Do nothing
    }
}
