var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseListPage', {
    attrs: {
      "visibleModal": _vm.visibleCreationModal,
      "type": "familiarization",
      "disabled-create": !_vm.name || !_vm.unitId
    },
    on: {
      "update:visibleModal": function updateVisibleModal($event) {
        _vm.visibleCreationModal = $event;
      },
      "update:visible-modal": function updateVisibleModal($event) {
        _vm.visibleCreationModal = $event;
      },
      "create": _vm.create,
      "changeCenterId": function changeCenterId($event) {
        _vm.searchCenterId = $event;
      }
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }, [_c('template', {
    slot: "list"
  }, [_c('div', {
    staticClass: "flex-row-center"
  }, [_c('Checkbox', {
    attrs: {
      "label": "Черновики"
    },
    model: {
      value: _vm.showDraft,
      callback: function callback($$v) {
        _vm.showDraft = $$v;
      },
      expression: "showDraft"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Активные"
    },
    model: {
      value: _vm.showActive,
      callback: function callback($$v) {
        _vm.showActive = $$v;
      },
      expression: "showActive"
    }
  }), _vm._v(" "), _vm.isReceivedArchivedFamiliarization ? _c('Checkbox', {
    key: "archived-received",
    attrs: {
      "label": "Архивные"
    },
    model: {
      value: _vm.showArchived,
      callback: function callback($$v) {
        _vm.showArchived = $$v;
      },
      expression: "showArchived"
    }
  }) : _c('ButtonViolet', {
    key: "archived-not-received",
    attrs: {
      "small": true,
      "text": "Получить архивные ознакомления"
    },
    on: {
      "click": _vm.getArchived
    }
  })], 1), _vm._v(" "), _vm._l(_vm.list, function (item) {
    return _c('ListCard', {
      key: item.id,
      attrs: {
        "item": item
      },
      on: {
        "click": function click($event) {
          return _vm.openItem(item.id);
        }
      }
    });
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "create-familiarization__create",
    attrs: {
      "slot": "create"
    },
    slot: "create"
  }, [_c('SelectRoleUnit', {
    attrs: {
      "roles-list": _vm.rolesList,
      "is-includes-centers": true
    },
    on: {
      "changeOriginUnit": function changeOriginUnit($event) {
        _vm.unitId = $event;
      }
    }
  }), _vm._v(" "), _c('InputBase', {
    staticClass: "create-familiarization__title",
    attrs: {
      "type": "text",
      "disabled": !_vm.unitId,
      "placeholder": "Название"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "name"
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }