











































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { WorkflowTemplate, StageTemplate, TaskTemplate } from '@/model/Workflow'
import { Actor, UserInfo, UnitInfo } from '@/model/Actor'
import { DocumentType } from '@/model/DocumentType'
import { User } from '@/model/User'
import { Unit } from '@/model/Unit'

import InputBase from '@/components/common/inputs/InputBase.vue'
import Textarea from '@/components/common/Textarea.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'
import ButtonGreen from '@/components/common/buttons/ButtonGreen.vue'

import ActionSelectCard from '@/components/common/cards/ActionSelectCard.vue'

@Component({
    components: {
        InputBase,
        Textarea,
        RadioButton,
        ButtonGreen,
        ActionSelectCard
    }
})

export default class WFTActionTask extends Vue {
    // *************************************************************
    // DATA PARAMS
    blockTitle = ''
    taskName = ''
    assigneeType = 'user'
    assignee = ''
    description = ''
    conformationType = ''
    confirmer = ''

    // *************************************************************
    // COMPUTED
    @Getter('workflow/getWorkflow') getWorkflow!: WorkflowTemplate
    @Getter('workflow/getActiveStage') getActiveStage!: StageTemplate | null

    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    @Getter('company_structure/getUsersByCenterId') getUsersByCenterId!: (centerId: string) => Array<User>
    @Getter('company_structure/getUnitsByCenterId') getUnitsByCenterId!: (centerId: string) => Array<Unit>
    @Getter('company_structure/getUserById') getUserById!: (id: string) => User | null
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get activeTask(): TaskTemplate | null {
        if (!this.getActiveStage) {
            return null
        }
        return this.getActiveStage.tasks.find((task: TaskTemplate) => task.id === this.getWorkflow.activeTask) || null
    }

    get centerId(): string {
        return this.getTypeById(this.$route.params.id)?.centerId || ''
    }

    get users(): Array<User> {
        return this.getUsersByCenterId(this.centerId)
    }

    get units(): Array<Unit> {
        return this.getUnitsByCenterId(this.centerId)
    }

    // *************************************************************
    // WATCH
    @Watch('activeTask', { immediate: true, deep: true })
    handleActiveTaskChange(val: TaskTemplate | null): void {
        if (val) {
            if (this.getWorkflow.action === 'changeTask') {
                this.blockTitle = 'Редактирование задачи'
                this.taskName = val.text
                if (val.assignee.user) {
                    this.assigneeType = 'user'
                    this.assignee = val.assignee.user.id
                } else if (val.assignee.unit) {
                    this.assigneeType = 'unit'
                    this.assignee = val.assignee.unit.id
                }
                this.description = val.description

                this.conformationType = val.confirmer?.user ? 'user' : val.confirmer?.unit ? 'unit' : ''
                this.confirmer = val.confirmer?.user ? val.confirmer?.user.id  : val.confirmer?.unit ? val.confirmer.unit.id : ''
            } else {
                // Если мы создаем дочернюю задачу
                this.blockTitle = 'Создание дочерней задачи'
            }
        } else {
            this.blockTitle = 'Создание новой задачи'
        }
    }

    @Watch('assigneeType')
    handleAssigneeTypeChange(to: string, from: string): void {
        if (to && from) {
            // Если меняем тип подтверждающего, то необходимо обнулить confirmer
            this.assignee = ''
        }
    }

    @Watch('conformationType')
    handleConformationTypeChange(to: string, from: string): void {
        if (to && from) {
            // Если меняем тип подтверждающего, то необходимо обнулить confirmer
            this.confirmer = ''
        }
    }

    // *************************************************************
    // METHODS
    @Action('workflow/addWFTask')
    addWFTask!: (task: TaskTemplate) => void

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFTask')
    changeWFTask!: (task: TaskTemplate) => void

    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    selectConformation(entityId: string): void {
        this.confirmer = entityId
    }

    selectAssignee(entityId: string): void {
        this.assignee = entityId
    }

    onSave(): void {
        if (!this.taskName) {
            this.$snotify.warning('Введите название задачи!')
            return
        }

        if (!this.assignee) {
            this.$snotify.warning('Выберите исполнителя задачи!')
            return
        }

        // ASSIGNEE
        const taskAssignee = new Actor({ User: undefined, Unit: undefined })

        if (this.assigneeType === 'user') {
            const assigneeEntity: User | null = this.getUserById(this.assignee)
            if (!assigneeEntity) {
                this.$snotify.warning('Исполнитель не найден!')
                return
            }

            taskAssignee.user = new UserInfo({
                ID: this.assignee,
                Name: {
                    First: assigneeEntity.name.first,
                    Middle: assigneeEntity.name.middle,
                    Last: assigneeEntity.name.last,
                }
            })
        } else if (this.assigneeType === 'unit') {
            const assigneeEntity: Unit | null = this.getUnitById(this.assignee)
            if (!assigneeEntity) {
                this.$snotify.warning('Исполнитель не найден!')
                return
            }

            taskAssignee.unit = new UnitInfo({ ID: this.assignee, Name: assigneeEntity.name })
        }


        //CONFIRMER
        let taskConfirmer: Actor | null = null

        if (this.conformationType === 'user') {
            const user = this.getUserById(this.confirmer)
            if (!user) {
                this.$snotify.warning('Подтверждающий не найден!')
                return
            }

            taskConfirmer = new Actor({
                User: {
                    ID: this.confirmer,
                    Name: {
                        First: user.name.first,
                        Middle: user.name.middle,
                        Last: user.name.last,
                    }
                },
                Unit: undefined
            })
        } else if (this.conformationType === 'unit') {
            const unit = this.getUnitById(this.confirmer)
            if (!unit) {
                this.$snotify.warning('Подтверждающий не найден!')
                return
            }
            taskConfirmer = new Actor({
                User: undefined,
                Unit : { ID: this.confirmer, Name: unit.name }
            })
        }

        const newTask = new TaskTemplate(
            Math.random().toString(36).substring(7),
            taskAssignee,
            this.taskName,
            this.description,
            taskConfirmer,
            []
        )

        console.log('this.getWorkflow.activeTask', this.getWorkflow.activeTask)
        console.log('this.getWorkflow.action', this.getWorkflow.action)
        if (!this.getWorkflow.activeTask) {
            // Если нет активной задачи, значит создается независимая новая задача без inputs
            this.addWFTask(newTask)
        } else if (this.getWorkflow.action === 'addTask') {
            // Если есть активная задача и actions === 'addTask', значит создаем дочернюю
            newTask.inputs = [this.getWorkflow.activeTask]
            this.addWFTask(newTask)
        } else if (this.getWorkflow.action === 'changeTask') {
            if (!this.activeTask) {
                this.$snotify.error('Обратитесь в техподдержку. Изменяемая задача не найдена!')
                return
            }

            newTask.id = this.activeTask.id
            this.changeWFTask(newTask)
        }

        // Возвращаемся в меню выбора action
        this.changeWFAction('')
        // Отмечаем наличие изменений в workFlow
        this.workflowChange(true)
    }
}
