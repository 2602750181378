



























































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { ApprovalTemplate, WorkflowTemplate } from '@/model/Workflow'
import { Actor } from '@/model/Actor'

import WFTStage from '@/components/workflow_template_builder/workflow_template/WFTStage.vue'
import WFTNodeGraph from '@/components/workflow_template_builder/workflow_template/WFTNodeGraph.vue'
import WFTTaskGraph from '@/components/workflow_template_builder/workflow_template/WFTTaskGraph.vue'

@Component({
    components: {
        WFTStage,
        WFTNodeGraph,
        WFTTaskGraph
    }
})

export default class WFTemplate extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('workflow/getWorkflow') getWorkflow!: WorkflowTemplate

    get draftApprovals(): Array<ApprovalTemplate> {
        console.log('getWorkflow', this.getWorkflow)
        return [new ApprovalTemplate(Math.random().toString(36).substring(7), new Actor({ User: undefined, Unit: undefined }), '', [])]
    }

    // *************************************************************
    // METHODS
    @Action('workflow/changeWFActiveStage')
    changeWFActiveStage!: (activeStage: number | null) => void

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFActiveTask')
    changeWFActiveTask!: (taskId: string) => void

    @Action('workflow/changeWFActiveNode')
    changeWFActiveNode!: (node: string) => void

    @Action('workflow/moveWFStage')
    moveWFStage!: (params: { stageIndex: number, newStageIndex: number }) => void

    selectActiveStage(index: number): void {
        if (this.getWorkflow.activeStage !== index) {
            // Изменяем значение activeStage в state
            // Не меняем его при действиях в пределах одного блока(при кликах по внутреннему содержимому)
            this.changeWFActiveStage(index)
        }
    }

    clickStage(index: number): void {
        // Этот метод прокидывается из дочернего компонента
        // В самом дочернем компоненте мы его не вызываем, т.к. нам необходимо знать индекс элемента
        // в массиве, а прокидывать эту информацию внутрь дочернего компонента нет смысла, т.к. это
        // не относится к внутренней логике

        this.selectActiveStage(index)

        // Обнуляем активный node и task
        this.changeWFActiveTask('')
        this.changeWFActiveNode('')

        // Возвращаемся в меню выбора action
        this.changeWFAction('')
    }

    moveDown(index: number): void {
        this.moveWFStage({
            stageIndex: index,
            newStageIndex: index + 1
        })

        // Обнуляем активный node и task
        this.changeWFActiveTask('')
        this.changeWFActiveNode('')
    }

    moveUp(index: number): void {
        this.moveWFStage({
            stageIndex: index,
            newStageIndex: index - 1
        })

        // Обнуляем активный node и task
        this.changeWFActiveTask('')
        this.changeWFActiveNode('')
    }
}
