

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { NodeTemplate } from '@/tools/template_builder/viewData'
import { User } from '@/model/User'

import Attribute from '@/components/common/Attribute.vue'

@Component<DWF_NodePopover>({
    components: { Attribute }
})

export default class DWF_NodePopover extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) node!: NodeTemplate

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUserById') getUserById!: (userId: string) => User | null

    get email(): string {
        if (!this.node.actor?.user) {
            return ''
        }

        const user = this.getUserById(this.node.actor.user.id)
        return user ? user.email : ''
    }
}
