var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "select-dir-record",
    attrs: {
      "visible": _vm.visibleModal,
      "title": _vm.modalTitle
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      },
      "close": _vm.close
    }
  }, [_c('template', {
    slot: "body"
  }, [_c('div', {
    staticClass: "select-dir-record__body"
  }, [_c('InputSearch', {
    staticClass: "select-dir-record__filter",
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _vm._v(" "), _vm.directory ? _c('DirectoryRecordsSelect', {
    attrs: {
      "directory": _vm.directory,
      "unit-id": _vm.documentUnitId,
      "search-text": _vm.searchText
    },
    on: {
      "changeRecord": function changeRecord($event) {
        return _vm.changeItem($event);
      }
    }
  }) : _vm._e()], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }