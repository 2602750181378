import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'

import Authentication from '@/pages/Authentication.vue'
import UserPage from '@/pages/system/UserPage.vue'
import Dashboard from '@/pages/Dashboard.vue'
import Module from '@/pages/Module.vue'
import BackDoor from '@/pages/BackDoor.vue'
import Workspace from '@/pages/Workspace.vue'
import Documentation from '@/pages/about_system/Documentation.vue'
import PrivacyPolicy from '@/pages/about_system/PrivacyPolicy.vue'
import Support from '@/pages/Support.vue'
import Ajaja from '@/pages/Ajaja.vue'
import Logout from '@/pages/Logout.vue'

import store from '@/store'

Vue.use(VueRouter)

// const checkAccess = (...args) => (to: Route, from: Route, next: NavigationGuardNext) => {
//     const stack = [...args]
//     const $next = () => {
//         if (!stack.length) {
//             return next()
//         }
//         const callee = stack.pop()
//         callee(to, from, redirectTo => {
//             if (typeof redirectTo !== 'undefined') {
//                 next(redirectTo)
//             } else {
//                 $next()
//             }
//         })
//     }
//     $next()
// }
//
const waitForUser = (): Promise<void> => new Promise((resolve) => {
    if (store.getters['me/getMe']) {
        return resolve()
    }

    const handleChangeLoad = (val: boolean) => {
        if (val) {
            resolve()
            unwatchFetchedUser()
        }
    }

    const unwatchFetchedUser = store.watch(() => !!store.getters['me/getMe'], handleChangeLoad)
})
//
// const ifManager = (to: Route, from: Route, next: NavigationGuardNext) => {
//     const modules: Array<string> = ['me', 'events', 'tasks', 'company-structure', 'tosser', 'familiarization']
//
//     if (to.params.section === 'system' && !modules.includes(to.params.module)) {
//         waitForUser().then(() => {
//             if (!store.getters['me/isUserManager']) {
//                 next('/ajaja')
//             } else {
//                 next()
//             }
//         })
//     } else {
//         next()
//     }
// }

const routes: Array<RouteConfig> = [
    {
        path: '/backdoor',
        name: 'backdoor',
        component: BackDoor
    },
    {
        path: '/ajaja',
        name: 'ajaja',
        component: Ajaja,
        redirect: 'root'
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: Documentation
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPolicy
    },
    {
        path: '/support',
        name: 'support',
        component: Support
    },
    {
        path: '/login',
        name: 'login',
        component: Authentication
    },
    {
        path: '/me',
        name: 'me',
        component: UserPage
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '',
        name: '',
        component: Workspace,
        children: [
            {
                path: '/',
                name: 'root',
                component: Dashboard,
                props: { role: 'root' },
                children: [
                    {
                        path: ':section',
                        name: 'section',
                        component: Dashboard,
                        props: { role: 'section' },
                        children: [
                            {
                                path: ':module',
                                name: 'module',
                                component: Module
                            },
                            {
                                path: ':module/:id',
                                name: 'document',
                                component: Module
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {

    if (!store.getters.isAuthorized) {
        // если не авторизованы - на LOGIN
        const pagesWithoutAuthorized: Array<string> = ['login', 'support', 'privacy']
        if (to.name && pagesWithoutAuthorized.includes(to.name)) {
            next()
        } else {
            next({ name: 'login' })
        }
    } else {
        waitForUser()
            .then(() => {
                const me = store.getters['me/getMe']
                const manageModules = ['document-types', 'directories', 'numerators', 'reports', 'replacement', 'dismissal', 'help-numerator', 'help-dismissal']
                // const modules: Array<string> = ['me', 'privacy', 'events', 'tasks', 'company-structure', 'tosser', 'familiarization']

                if (to.name === 'logout') {
                    next()
                } else if (!me.roles.length) {
                    const pagesWithoutRoles: Array<string> = ['me', 'privacy', 'support']
                    if (to.name && pagesWithoutRoles.includes(to.name)) {
                        next()
                    } else {
                        next({ name: 'me' })
                    }
                } else if (!store.getters['me/isUserManager'] && to.params.module && manageModules.includes(to.params.module)) {
                    next({ name: 'root' })
                } else if (to.name === 'login') {
                    next({ name: 'root' })
                } else {
                    next()
                }
            })
    }
})

export default router
