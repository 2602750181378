var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "item-card"
  }, _vm.$listeners), [_c('div', [_vm._v(_vm._s(_vm.item.name))]), _vm._v(" "), _vm._t("info")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }