import { MutationTree } from 'vuex'
import { MeState } from '@/store/me/meState'
import Vue from 'vue'

import { User } from '@/model/User'
import { AuthenticationStatus, IdentitiesAndVerifiersList } from '@/model/Authentication'

const MeMutations: MutationTree<MeState> = {
    putListMyIdentitiesAndVerifiers(state: MeState, listMyIdentitiesAndVerifiers: IdentitiesAndVerifiersList) {
        Vue.set(state, 'listMyIdentitiesAndVerifiers', listMyIdentitiesAndVerifiers)
    },

    setEventId(state: MeState, eventId: string) {
        state.eventId = eventId
    },

    setMe(state: MeState, me: User | null) {
        state.me = me
    },

    setStatus(state: MeState, status: AuthenticationStatus | null) {
        Vue.set(state, 'status', status)
        // state.status = status
    }
}

export default MeMutations
