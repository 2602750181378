var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "doc-type"
  }, [_c('GoBack', {
    attrs: {
      "text": "Вернуться к списку типов документов",
      "link": _vm.goBackPath
    }
  }), _vm._v(" "), _c('Tabs', {
    attrs: {
      "tabs": _vm.modes
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }), _vm._v(" "), _vm.mode === 'type' && _vm.type ? _c('DocumentTypeCard', {
    key: "type",
    staticClass: "doc-type__body"
  }) : _vm.mode === 'workflow' && _vm.type ? _c('LifeCycleTemplateBuilder', {
    key: "workflow",
    staticClass: "doc-type__body"
  }) : _c('div', {
    staticClass: "doc-type__unknown"
  }, [_vm._v("\n        Тип документа не найден\n    ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }