<template>
    <div>
        <h1>Роли оргструктуры:</h1>

        <h3>Сотрудник:</h3>
        <ol>
            <li>
                Может создавать документы от лица тех подразделений,
                в которых он является Сотрудником;
            </li>
            <li>
                Может создавать задачи от лица тех подразделений,
                в которых он является Сотрудником;
            </li>
            <li>
                Видит только документы, в которых он:
                <ul>
                    <li>Является автором документа;</li>
                    <li>Участвует в цепочке согласования;</li>
                    <li>На его имя созданы задачи, привязанные к данному документу.</li>
                </ul>
            </li>
            <li>
                Видит только те задачи, в которых он:
                <ul>
                    <li>Является автором задачи;</li>
                    <li>Является исполнителем задачи;</li>
                    <li>Является подтверждающим задачи.</li>
                </ul>
            </li>
            <li>
                Не имеет доступа к  Справочникам системы, Тосcеру,
                Типам документов, Отчетам.
            </li>
            <li>Может просматривать Оргструктуру, но не имеет доступа к ее редактированию.</li>
        </ol>

        <h3>Руководитель:</h3>
        <ol>
            <li>
                Роль может быть только у одного пользователя в подразделении.
            </li>
            <li>
                Может создавать документы от лица тех подразделений,
                в которых он является Руководителем;
            </li>
            <li>
                Может создавать задачи от лица тех подразделений,
                в которых он является Руководителем;
            </li>
            <li>
                Видит документы, в которых он:
                <ul>
                    <li>Является автором документа;</li>
                    <li>Участвует в цепочке согласования;</li>
                    <li>На его имя созданы задачи, привязанные к данному документу;</li>
                    <li>
                        Все документы его подразделения и всех дочерних подразделений.
                    </li>
                </ul>
            </li>
            <li>
                Видит те задачи, в которых он:
                <ul>
                    <li>Является автором задачи;</li>
                    <li>Является исполнителем задачи;</li>
                    <li>Является подтверждающим задачи;</li>
                    <li>Все задачи его подразделения и всех дочерних подразделений.</li>
                </ul>
            </li>
            <li>
                Не имеет доступа к  Справочникам системы,
                Типам документов, Отчетам.
            </li>
            <li>Может просматривать Оргструктуру, но не имеет доступа к ее редактированию.</li>
            <li>Имеет доступа к Тостеру в подразделениях, в которых у него роль 'Руководитель'.</li>
        </ol>

        <h3>Супервайзер:</h3>
        <ol>
            <li>Не может создавать документы.</li>
            <li>Не может создавать задачи.</li>
            <li>
                Видит документы, в которых он:
                <ul>
                    <li>Является автором документа;</li>
                    <li>Участвует в цепочке согласования;</li>
                    <li>На его имя созданы задачи, привязанные к данному документу;</li>
                    <li>
                        Все документы его подразделения и всех дочерних подразделений.
                    </li>
                </ul>
            </li>
            <li>
                Видит те задачи, в которых он:
                <ul>
                    <li>Является автором задачи;</li>
                    <li>Является исполнителем задачи;</li>
                    <li>Является подтверждающим задачи;</li>
                    <li>Все задачи его подразделения и всех дочерних подразделений.</li>
                </ul>
            </li>
            <li>
                Не имеет доступа к  Справочникам системы,
                Типам документов, Отчетам, Тоссеру.
            </li>
            <li>Может просматривать Оргструктуру, но не имеет доступа к ее редактированию.</li>
        </ol>

        <h3>Менеджер документооборота:</h3>
        <ol>
            <li>Роль может быть только в корневом подразделении.</li>
            <li>Не может создавать документы.</li>
            <li>Может создавать задачи от лица корневого подразделения.</li>
            <li>Видит абсолютно все документы.</li>
            <li>Видит абсолютно все задачи.</li>
            <li>Просматривает Справочники 1С.</li>
            <li>Редактирует справочники системы.</li>
            <li>Редактирует Оргструктуру.</li>
            <li>Редактирует типы документов.</li>
            <li>Имеет доступ к Отчетам.</li>
            <li>Не имеет доступа к Тоссеру (ПОКА ЧТО).</li>
        </ol>

        <table class="table">
            <thead>
                <tr>
                    <th>Набор прав</th>
                    <th>Менеджер документооборота</th>
                    <th>Руководитель</th>
                    <th>Супервайзер</th>
                    <th>Сотрудник</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Видит документы, в которых он автор</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Видит документы, в которых он участвует в жизненном цикле</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Видит документы, в которых на его имя созданы задачи</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Видит задачи, в которых он является автором</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Видит задачи, в которых он является исполнителем</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Видит задачи, в которых он является подтверждающим</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Создает документы</th>
                    <td />
                    <td>&#129412;</td>
                    <td />
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Создает задачи</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td />
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Видит полную оргструктуру</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                </tr>
                <tr>
                    <th>Видит все документы от своего подразделения и ниже</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td />
                </tr>
                <tr>
                    <th>Видит все задачи от своего подразделения и ниже</th>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td>&#129412;</td>
                    <td />
                </tr>
                <tr>
                    <th>Имеет доступ к Тоссеру своего подразделения</th>
                    <td />
                    <td>&#129412;</td>
                    <td />
                    <td />
                </tr>
                <tr>
                    <th>Видит абсолютно все документы</th>
                    <td>&#129412;</td>
                    <td />
                    <td />
                    <td />
                </tr>
                <tr>
                    <th>Видит абсолютно все задачи</th>
                    <td>&#129412;</td>
                    <td />
                    <td />
                    <td />
                </tr>
                <tr>
                    <th>Редактирует оргструктуру</th>
                    <td>&#129412;</td>
                    <td />
                    <td />
                    <td />
                </tr>
                <tr>
                    <th>Просматривает справочники 1С</th>
                    <td>&#129412;</td>
                    <td />
                    <td />
                    <td />
                </tr>
                <tr>
                    <th>Редактирует справочники системы</th>
                    <td>&#129412;</td>
                    <td />
                    <td />
                    <td />
                </tr>
                <tr>
                    <th>Редактирует Типы документов</th>
                    <td>&#129412;</td>
                    <td />
                    <td />
                    <td />
                </tr>
                <tr>
                    <th>Видит Отчеты</th>
                    <td>&#129412;</td>
                    <td />
                    <td />
                    <td />
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'HelpCompanyStructure'
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/table.styl'

td
    text-align center
</style>
