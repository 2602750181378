var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('DocumentBlockTitle', {
    attrs: {
      "title": "Вложения"
    }
  }, [_c('ButtonAdd', {
    staticClass: "ml-5",
    on: {
      "click": _vm.addFile
    }
  })], 1), _vm._v(" "), _c('Files', {
    ref: "allFiles",
    staticClass: "w-100",
    attrs: {
      "files": _vm.files,
      "document-id": _vm.documentId,
      "can-change": _vm.canChange,
      "can-add-files": false,
      "group-name": "document_attachment",
      "name": "document"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }