
































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { cuntPB } from 'api'

import dynamicSort from '@/tools/sort/dynamicSort'
import getError, { GrpcError } from '@/tools/errors/errors'
import { DocumentType } from '@/model/DocumentType'

import BaseListPage from '@/components/common/BaseListPage.vue'
import SelectCenterId from '@/components/common/SelectCenterId.vue'
import ListCard from '@/components/common/cards/ListCard.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'

@Component({
    components: {
        BaseListPage,
        SelectCenterId,
        ListCard,
        InputBase
    }
})

export default class DocumentTypes extends Vue {
    // *************************************************************
    // DATA PARAMS
    searchCenterId = ''
    centerId = ''
    searchText = ''
    name = ''
    visibleCreationModal = false

    // *************************************************************
    // COMPUTED
    @Getter('document_type/getTypes') getTypes!: Array<DocumentType>

    get types(): Array<DocumentType> {
        let types = this.getTypes
        if (this.searchText) {
            types = types.filter((type: DocumentType) => type.name.toLowerCase().includes(this.searchText.toLowerCase()))
        }

        if (this.searchCenterId) {
            types = types.filter((type: DocumentType) => type.centerId === this.searchCenterId)
        }

        return types.sort(dynamicSort('name'))
    }

    // *************************************************************
    // METHODS
    @Action('document_type/callCreateDocumentType')
    callCreateDocumentType!: (params: cuntPB.CreateDocumentTypeReq) => Promise<string>

    openType(id: string): void {
        this.$router.push({
            name: 'document',
            params: {
                section: 'system',
                module: 'document-types',
                id
            }
        })
    }

    create(): void {
        if (!this.name) {
            this.$snotify.warning('Необходимо заполнить имя типа документа', 'Отклонено!')
            return
        }
        if (!this.centerId) {
            this.$snotify.warning('Необходимо выбрать организацию', 'Отклонено!')
            return
        }

        const cancelPreloaderNewDocumentType = this.$preloader('create_document_type', 'Создание нового типа документа')

        this.callCreateDocumentType({
            CenterID: this.centerId,
            Name: this.name
        })
            .then((id: string) => {
                this.visibleCreationModal = false
                this.name = ''
                Vue.nextTick(() => {
                    this.openType(id)
                }, 0)
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderNewDocumentType())
    }
}
