var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Button', _vm._g({
    staticClass: "button_violet",
    class: {
      'button_violet_active': _vm.active
    },
    attrs: {
      "type": "button",
      "disabled": _vm.disabled,
      "loading": _vm.loading,
      "size": _vm.size
    }
  }, _vm.$listeners), [_vm._v(_vm._s(_vm.text))]);
}
var staticRenderFns = []

export { render, staticRenderFns }