import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { UserOptionsState } from '@/store/user_options/user_options'
import { default_doctype_filters, DocumentListFilters } from '@/model/UserOptions'
import { News } from '@/store/user_options/news'

const UserOptionsGetters: GetterTree<UserOptionsState, RootState> = {
    getArchivedDocumentsReceived: (state: UserOptionsState) => (documentTypeId: string): boolean => state.archiveDocumentReceivedByDocType[documentTypeId] ?? false,

    getDocumentHiddenCells: (state: UserOptionsState) => (docTypeId: string): Array<string> => state.documentHiddenCells[docTypeId],

    getDocumentListFilters: (state: UserOptionsState) => (docTypeId: string): DocumentListFilters => state.documentListFilters[docTypeId] || default_doctype_filters(),

    getNews: (state: UserOptionsState): Array<News> => state.news,

    /** Непрочитанные новости */
    getNotReadNewsIds: (state: UserOptionsState): Array<string> => {
        if (state.readNewsIds[0] === 'news-000029') {
            return state.news.filter((n: News) => !state.readNewsIds.includes(n.id)).map((news: News) => news.id)
        }

        return state.news.map((n: News) => n.id)
    },

    getArchivedTasksReceived: (state: UserOptionsState): boolean => state.archivedTasksReceived
}

export default UserOptionsGetters
