













































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User, Role } from '@/model/User'
import { Unit } from '@/model/Unit'
import { DocumentType } from '@/model/DocumentType'

import RadioButton from '@/components/common/inputs/RadioButton.vue'
import TosserBuilder from '@/components/tosser/TosserBuilder.vue'

// Импортируем функцию сортировки
import dynamicSort from '@/tools/sort/dynamicSort'

@Component({
    components: {
        RadioButton,
        TosserBuilder
    }
})

export default class Tosser extends Vue {
    // *************************************************************
    // DATA PARAMS
    selectedUnit = ''
    selectedType = ''
    centerId = ''

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null
    @Getter('document_type/getTypes') getDocumentTypes!: Array<DocumentType>

    get units(): Array<Unit> {
        // Берем весь список подразделений, в которых у user стоит роль 'Head'
        // Получаем по данным подразделениям всю информацию
        const headUnits: Array<Unit> = []
        this.getMe.roles.forEach((role: Role) => {
            if (role.name === 'Head') {
                const unit = this.getUnitById((role.unit))
                if (unit) {
                    headUnits.push(unit)
                }
            }
        })

        return headUnits.sort(dynamicSort('name'))
    }

    get types(): Array<DocumentType> {
        return this.getDocumentTypes.filter((type: DocumentType) => type.centerId === this.centerId)
    }

    // *************************************************************
    // WATCH
    @Watch('selectedUnit')
    handleSelectedUnitChange(to: string): void {
        // При смене подразделения обнуляем выбор типа
        this.selectedType = ''
        const unit = this.units.find((u: Unit) => u.id === to)
        if (unit) {
            this.centerId = unit.centerId
        }
    }
}
