
























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { Document, DocumentEvent } from '@/model/Document'
import { DocumentType } from '@/model/DocumentType'
import { User } from '@/model/User'
import { Location } from 'vue-router'

import GoBack from '@/components/common/GoBack.vue'
import DocumentBaseInfo from '@/components/document/common_document/DocumentBaseInfo.vue'
import DocumentRequisites from '@/components/document/common_document/DocumentRequisites.vue'
import DocumentFiles from '@/components/document/common_document/DocumentFiles.vue'
import DocumentLinks from '@/components/document/common_document/links/DocumentLinks.vue'
import DocumentChat from '@/components/document/common_document/DocumentChat.vue'
import DocumentWorkflow from '@/components/document/workflow/DocumentWorkflow.vue'
import DocumentTasks from '@/components/document/common_document/DocumentTasks.vue'

@Component<CommonDocument>({
    components: {
        GoBack,
        DocumentBaseInfo,
        DocumentRequisites,
        DocumentFiles,
        DocumentLinks,
        DocumentChat,
        DocumentWorkflow,
        DocumentTasks
    }
})

export default class CommonDocument extends Vue {
    // *************************************************************
    // DATA PARAMS
    // Просто заглушка для тестирования прав
    dummyRights = false

    events: Array<DocumentEvent> = []

    docStream: ReturnType<() => () => void> | undefined = undefined

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    @Getter('document/getDocumentById') getDocumentById!: (documentId: string) => Document | null

    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    get documentId(): string {
        return this.$route.params.id
    }

    get openDocument(): Document | null {
        const openDocument = this.getDocumentById(this.documentId)
        console.log('openDocument', openDocument)
        return openDocument
    }

    get goBackPath(): Location {
        return {
            name: 'module',
            params: {
                section: 'documents',
                module: this.typeId
            }
        }
    }

    get typeId(): string {
        return this.$route.params.module
    }

    get type(): DocumentType | null {
        return this.getTypeById(this.typeId)
    }

    get canChangeRequisite(): boolean {
        if (!this.openDocument) {
            return false
        }

        // Прокидываем это через props в Requisites - только автор в черновике может менять реквизиты
        return this.getMe.id === this.openDocument.base.author.id && this.openDocument.base.status === 'draft'
    }

    get showLinks(): boolean {
        if (!this.openDocument) {
            return false
        }

        return !!this.openDocument.links.length || this.getMe.id === this.openDocument.base.author.id
    }

    // *************************************************************
    // WATCH
    @Watch('documentId', { immediate: true })
    handleDocIdChange(to: string, from: string | undefined): void {
        console.log('WATCH::documentId', to, from)
        if (from) {
            this.events = []

            this.closeDocumentStream()
        }

        if (to) {
            this.openDocumentStream()
        }
    }

    // watch: {
    //     $route: {
    //         immediate: true,
    //         handler(to) {
    //             Vue.nextTick(() => {
    //                 // Только после перезаписи id документа получаем данные с сервера
    //                 if (!this.documentId) {
    //                     console.warn('CommonDocument::nextTick', to)
    //                     // Только если name === 'document', т.к. id используется еще в конструкторе типа
    //                     // документа, чтобы openDocumentStream не происходило везде подряд
    //                     if (this.$route.name === 'document') {
    //                         this.documentId = this.$route.params.id
    //                         this.openDocumentStream()
    //                     }
    //                 }
    //             }, 0)
    //         }
    //     },
    // },

    beforeDestroy(): void {
        this.closeDocumentStream()
    }

    // *************************************************************
    // METHODS
    @Action('document/callOpenDocumentStream')
    callOpenDocumentStream!: (params: { docId: string, eventFunc: (event: DocumentEvent) => void }) => Promise<() => void>

    returnToList(): void {
        console.log('returnToList')
        this.$router.push(this.goBackPath)
    }

    openDocumentStream(): void {
        console.warn('openDocumentStream', this.documentId)
        this.callOpenDocumentStream({
            docId: this.documentId,
            eventFunc: this.putEvent
        })
            .then((docStream) => {
                this.docStream = docStream
            })
    }

    putEvent(docEvent: DocumentEvent): void {
        // Если еще нет в массиве, то добавляем
        // Эта проверка нужна, т.к. если мы отправим сообщение, то оно придет и в
        // методе spendMessage и здесь
        if (!this.events.find((event: DocumentEvent) => event.id === docEvent.id)) {
            this.events.push(docEvent)
        }
    }

    closeDocumentStream(): void {
        if (this.docStream) {
            this.docStream()
        }
    }
}
