var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.familiarization ? _c('div', {
    staticClass: "my-3"
  }, [_c('div', {
    staticClass: "flex-row-center justify-between mb-3"
  }, [_c('Checkbox', {
    attrs: {
      "label": "Только ознакомления"
    },
    model: {
      value: _vm.onlyFamiliarization,
      callback: function callback($$v) {
        _vm.onlyFamiliarization = $$v;
      },
      expression: "onlyFamiliarization"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "familiarization__print",
    attrs: {
      "tooltip": "Распечатать журнал",
      "flow": "left"
    },
    on: {
      "click": _vm.print
    }
  }, [_c('FAIcon', {
    attrs: {
      "icon": "print"
    }
  })], 1)], 1), _vm._v(" "), _c('table', {
    ref: "table",
    staticClass: "table"
  }, [_vm._m(0), _vm._v(" "), _c('tbody', [!_vm.onlyFamiliarization ? _c('tr', [_c('td', [_vm._v("Создание")]), _vm._v(" "), _c('td', [_vm._v(_vm._s(_vm.familiarization.actor.user.name.shortName))]), _vm._v(" "), _c('td'), _vm._v(" "), _c('td', [_vm._v(_vm._s(_vm.familiarization.creationTimeLocalizedLongString))])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.filteredEvents, function (event) {
    return _c('tr', {
      key: event.id
    }, [_c('td', [_vm._v(_vm._s(_vm.eventTypes[event.type].title))]), _vm._v(" "), _c('td', [_vm._v(_vm._s(event.actor ? event.actor.name.shortName : 'Автоматически'))]), _vm._v(" "), _c('td', _vm._l(event.fields, function (field, key) {
      return _c('div', {
        key: key,
        staticClass: "flex-row-center"
      }, [_c('strong', {
        staticClass: "mr-3"
      }, [_vm._v(_vm._s(_vm.eventTypes[event.type].fields[key]) + ":")]), _vm._v(" "), event.type === 'status_transition' ? _c('span', {
        key: "status_transition"
      }, [_vm._v("\n                            " + _vm._s(_vm.statuses[field]) + "\n                        ")]) : event.type === 'familiarize' ? _c('span', {
        key: "familiarize"
      }, [_vm._v("\n                            " + _vm._s(field.name.fullName) + "\n                        ")]) : event.type === 'new_members' ? _c('span', {
        key: "new_members"
      }, _vm._l(field, function (item) {
        return _c('div', {
          key: item.id
        }, [_vm._v("\n                                " + _vm._s(key === 'units' ? item.name : item.name.fullName) + "\n                            ")]);
      }), 0) : _vm._e()]);
    }), 0), _vm._v(" "), _c('td', [_vm._v(_vm._s(event.creationTimeLocalizedLongString))])]);
  })], 2)])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('th', [_vm._v("Тип события")]), _vm._v(" "), _c('th', [_vm._v("Автор")]), _vm._v(" "), _c('th', [_vm._v("Расшифровка")]), _vm._v(" "), _c('th', [_vm._v("Дата")])]);
}]

export { render, staticRenderFns }