import api from 'api1'

let users = []

import newUser from '@/model/convert/newUser'

export default function (ctx, { roles }) {
    return new Promise((resolve, reject) => {
        api.cunt.UserSvcClient.getUsersByRoles(api.cunt.GetUsersByRolesReq({
            Roles: roles
        }), data => {
            data = api.cunt.User(data)
            // console.log("::DATA::UserSvcClient.getUsersByRoles:", data)
            // Если такого сотрудника нет в нашем массиве, то добавляем его
            if (!(users.find(e => e.id === data.ID))) {
                users.push(newUser(data))
            }

            resolve(users)
        }, error => {
            (error ? console.warn : console.log)('::DONE::UserSvcClient.getUsersByRoles:', error)
            reject(error)
        })
    })
}
