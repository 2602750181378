var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tabs"
  }, [_c('div', {
    staticClass: "tabs__list",
    style: {
      'grid-template-columns': 'repeat(' + _vm.tabs.length + ', 1fr)',
      height: "".concat(_vm.tabHeight, "px")
    }
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.id,
      staticClass: "tab",
      class: {
        'tab_active': tab.id === _vm.value,
        'tab_not-active': tab.id !== _vm.value
      },
      style: {
        height: "".concat(_vm.tabHeight, "px"),
        'line-height': "".concat(_vm.tabHeight, "px")
      },
      on: {
        "click": function click($event) {
          return _vm.emitChange(tab.id);
        }
      }
    }, [_vm._v("\n            " + _vm._s(tab.name) + "\n        ")]);
  }), 0), _vm._v(" "), _vm.value ? _c('hr', {
    staticClass: "tab__hr",
    style: {
      'margin-left': _vm.leftHr + '%',
      width: _vm.widthHr + '%'
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }