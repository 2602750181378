var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "dashboard",
    style: {
      width: _vm.configuration.parentConfig.width,
      left: _vm.configuration.parentConfig.left
    }
  }, _vm._l(_vm.dashItemsFilterByRoles, function (dashboardItem, i) {
    return _c('DashboardCard', {
      key: dashboardItem.name,
      staticClass: "dashboard__item",
      class: {
        'dashboard__item_last': i === _vm.dashItemsFilterByRoles.length - 1
      },
      style: {
        width: "".concat(_vm.configuration.itemConfig.widthCoef, "%"),
        height: _vm.configuration.itemConfig.height + 'px',
        transform: "translate(".concat(_vm.configuration.itemConfig.left(i), ", ").concat(_vm.configuration.itemConfig.top(i), "px)")
      },
      attrs: {
        "dash-item": dashboardItem
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.selectSection(dashboardItem.name);
        }
      }
    });
  }), 1), _vm._v(" "), _c('KeepAlive', [_c('Transition', {
    attrs: {
      "name": _vm.configuration.routerTransitionName
    }
  }, [_c('RouterView', {
    key: _vm.configuration.keyRouterView
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }