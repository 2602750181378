<template>
    <input
        class="input h-100 w-100"
        type="text"
        :class="{'input_disabled': disabled}"
        :disabled="disabled"
        @input="onInput($event)"
        @select="handleSelectionChange($event)"
        @blur="handleSelectionChange($event)"
        @focus="handleSelectionChange($event)"
        @keydown="handleSelectionChange($event)"
        @keyup="handleSelectionChange($event)"
        @keypress="handleSelectionChange($event)"
        @mousedown="handleSelectionChange($event)"
    >
</template>

<script>
import Vue from 'vue'

export function isAlpha(c) {
    return c.toLowerCase() !== c.toUpperCase()
}

export function replaceChar(str, index, replacement) {
    if (!str) {
        return str
    }
    return str.substring(0, index) + replacement + str.substring(index + 1)
}

export default {
    name: 'EventsOnlyInput',
    props: {
        state: { // FIXME Change prop to method
            type: Object,
            required: true,
            default: () => { return { value: '', start: 0, end: 0 } }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            value: '',

            oldSelection: { start: 0, end: 0 },
            newSelection: { start: 0, end: 0 },
            oldValue: '',
            newValue: ''
        }
    },
    watch: {
        state: {
            handler(to) {
                const set = () => {
                    this.$el.value = to.value
                    this.$el.selectionStart = to.start
                    this.$el.selectionEnd = to.end
                    this.handleValueChange(to.value)
                }
                if (this.$el) {
                    set()
                } else {
                    Vue.nextTick(set)
                }
            },
            immediate: true
        }
    },
    methods: {
        onInput(event) {
            this.handleValueChange(event.target.value)
            this.handleSelectionChange(event)

            // FIXME Does not handle delete key
            // FIXME Does not handle macos char replacement
            const replace = {
                start: this.oldSelection.start < this.newSelection.start ? this.oldSelection.start : this.newSelection.start,
                end: this.oldSelection.end,
                old: '',
                new: ''
            }

            replace.old = this.oldValue.substring(replace.start, replace.end)
            replace.new = event.data ? event.data : ''

            this.$emit('replace', replace)
        },
        handleValueChange(value) {
            if (this.newValue !== value) {
                this.oldValue = this.newValue
                this.newValue = value
            }
        },
        handleSelectionChange(event) {
            const target = event.target
            if (this.newSelection.start !== target.selectionStart || this.newSelection.end !== target.selectionEnd) {
                this.oldSelection = this.newSelection
                this.newSelection = { start: target.selectionStart, end: target.selectionEnd }
            }
        }
    }
}

/*
*
* char remove         - { start: 1, end: 2, old: 'c', new: '' }
* chars remove        - { start: 1, end: 3, old: 'ch', new: '' }
* char append         - { start: 1, end: 1, old: '', new: 'c' }
* chars append        - { start: 1, end: 1, old: '', new: 'ch' }
* chars replace       - { start: 1, end: 3, old: 'ch', new: 'hcx' }
*
* { value: 'val', start: 1, end: 2 }
*
* */
</script>

<style scoped lang="stylus">
@import '~@/assets/styles/input.styl'
</style>
