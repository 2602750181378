var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-requisites"
  }, [_c('DocumentBlockTitle', {
    attrs: {
      "title": "Реквизиты"
    }
  }), _vm._v(" "), _vm._l(_vm.openDocument.requisites, function (requisite) {
    return _c('DocumentRequisiteCard', {
      key: requisite.id,
      attrs: {
        "requisite": requisite,
        "deleted": !_vm.typeRequisites.find(function (req) {
          return req.id === requisite.type.id;
        }),
        "can-change-requisite": _vm.canChangeRequisite
      }
    });
  }), _vm._v(" "), _c('DocumentCommentary', {
    attrs: {
      "document-id": _vm.openDocument.base.id,
      "comment": _vm.openDocument.comment,
      "can-change-requisite": _vm.canChangeRequisite
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }