import { render, staticRenderFns } from "./EditRecord.vue?vue&type=template&id=6d4a3384&scoped=true&"
import script from "./EditRecord.vue?vue&type=script&lang=ts&"
export * from "./EditRecord.vue?vue&type=script&lang=ts&"
import style0 from "./EditRecord.vue?vue&type=style&index=0&id=6d4a3384&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d4a3384",
  null
  
)

export default component.exports