var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "action-delete-node"
  }, [_c('div', [_vm._v("\n        " + _vm._s(_vm.message) + "\n    ")]), _vm._v(" "), _c('ButtonGreen', {
    staticClass: "action-delete-node__button",
    attrs: {
      "text": "Удалить"
    },
    on: {
      "click": _vm.deleteNode
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }