var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "side-bar-panel"
  }, _vm.$listeners), [_c('div', {
    staticClass: "side-bar-panel_user-info",
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.$emit('me');
      }
    }
  }, [_c('div', {
    staticClass: "side-bar-panel_user-info_avatar",
    style: {
      'background-image': 'url(' + _vm.getMe.avatar.sidebar + ')'
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "side-bar-panel_user-info_name"
  }, [_c('div', [_vm._v("\n                " + _vm._s(_vm.getMe.name.first) + " " + _vm._s(_vm.getMe.name.middle) + "\n                "), _c('br'), _vm._v("\n                " + _vm._s(_vm.getMe.name.last) + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "exit-svg",
    attrs: {
      "tooltip": "Выйти",
      "flow": "left"
    },
    on: {
      "click": _vm.exit
    }
  }, [_c('FAIcon', {
    staticClass: "icon",
    attrs: {
      "icon": "sign-out-alt"
    }
  })], 1)]), _vm._v(" "), _c(_vm.componentPanel, {
    tag: "Component",
    staticClass: "side-bar-panel_change-component",
    attrs: {
      "events": _vm.events,
      "title": _vm.title,
      "type": _vm.type
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }