import { cuntPB } from 'api'

export class Attachment {
    public id: string
    public name: string
    public type: string
    public size: number
    public version: number

    constructor(file: cuntPB.File) {
        this.id = file.ID
        this.name = file.Name
        this.type = file.Type
        this.size = file.Size
        this.version = file.Version
    }

    get computedType(): string {
        const extension = this.name.split('.').pop()

        switch (extension) {
        case 'doc':
        case 'docx':
        case 'txt':
        case 'pages':
        case 'odt':
        case 'rtf':
            return 'doc'
        case 'pdf':
        case 'PDF':
            return 'pdf'
        case 'xls':
        case 'xlsx':
        case 'ods':
        case 'numbers':
            return 'xls'
        case 'jpg':
        case 'JPG':
        case 'png':
        case 'jpeg':
            return 'jpg'
        case 'zip':
            return 'zip'
        default:
            return ''
        }
    }
}
