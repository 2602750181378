var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "modal-select",
    attrs: {
      "visible": _vm.syncedVisible,
      "title": "Создание задачи"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      },
      "close": _vm.hide
    }
  }, [_c('div', {
    staticClass: "add-task__body",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('InputBase', {
    staticClass: "h-40 mb-5",
    attrs: {
      "required": "",
      "placeholder": "Название задачи"
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "add-task__select-actor"
  }, [_c('div', {
    staticClass: "fw-900"
  }, [_vm._v("Исполнитель:")]), _vm._v(" "), _c('div', {
    staticClass: "fw-900"
  }, [_vm._v("Подтверждающий:")]), _vm._v(" "), _c('div', {
    staticClass: "add-task__radio-container"
  }, [_c('RadioButton', {
    staticClass: "add-task__check-radio",
    attrs: {
      "value": "unit",
      "label": "Подразделение"
    },
    model: {
      value: _vm.assigneeType,
      callback: function callback($$v) {
        _vm.assigneeType = $$v;
      },
      expression: "assigneeType"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "add-task__check-radio",
    attrs: {
      "value": "user",
      "label": "Пользователь"
    },
    model: {
      value: _vm.assigneeType,
      callback: function callback($$v) {
        _vm.assigneeType = $$v;
      },
      expression: "assigneeType"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "add-task__radio-container"
  }, [_c('RadioButton', {
    staticClass: "add-task__check-radio",
    attrs: {
      "value": "unit",
      "label": "Подразделение"
    },
    model: {
      value: _vm.confirmerType,
      callback: function callback($$v) {
        _vm.confirmerType = $$v;
      },
      expression: "confirmerType"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "add-task__check-radio",
    attrs: {
      "value": "user",
      "label": "Пользователь"
    },
    model: {
      value: _vm.confirmerType,
      callback: function callback($$v) {
        _vm.confirmerType = $$v;
      },
      expression: "confirmerType"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "add-task__check-radio",
    attrs: {
      "value": "",
      "label": "Не требуется"
    },
    model: {
      value: _vm.confirmerType,
      callback: function callback($$v) {
        _vm.confirmerType = $$v;
      },
      expression: "confirmerType"
    }
  })], 1), _vm._v(" "), _c('ActionSelectCard', {
    staticClass: "add-task__select-card",
    attrs: {
      "placeholder": "Исполнитель",
      "entity": _vm.assigneeType,
      "items": _vm.assigneeType === 'user' ? _vm.getUsers : _vm.getUnits,
      "sort": "name",
      "sub-sort-property": _vm.assigneeType === 'user' ? 'fullName' : ''
    },
    on: {
      "input": function input($event) {
        return _vm.selectAssignee($event);
      }
    },
    model: {
      value: _vm.assignee,
      callback: function callback($$v) {
        _vm.assignee = $$v;
      },
      expression: "assignee"
    }
  }), _vm._v(" "), _c('ActionSelectCard', {
    staticClass: "add-task__select-card",
    attrs: {
      "placeholder": "Подтверждает задачу",
      "entity": _vm.confirmerType,
      "items": _vm.confirmerType === 'user' ? _vm.getUsers : _vm.getUnits,
      "sort": "name",
      "sub-sort-property": _vm.confirmerType === 'user' ? 'fullName' : ''
    },
    on: {
      "input": function input($event) {
        return _vm.selectConfirmer($event);
      }
    },
    model: {
      value: _vm.confirmer,
      callback: function callback($$v) {
        _vm.confirmer = $$v;
      },
      expression: "confirmer"
    }
  })], 1), _vm._v(" "), _c('TextareaNew', {
    staticClass: "my-5",
    attrs: {
      "min-lines": 1,
      "max-lines": 5,
      "placeholder": "Описание задачи"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1), _vm._v(" "), _c('template', {
    slot: "footer"
  }, [_c('div', {
    staticClass: "flex-row-center p-3"
  }, [!!_vm.saveDisabledReason ? _c('div', {
    staticClass: "red"
  }, [_vm._v("\n                " + _vm._s(_vm.saveDisabledReason) + "\n            ")]) : _vm._e(), _vm._v(" "), _c('Button', {
    staticClass: "ml-auto",
    attrs: {
      "color": "green",
      "disabled": !!_vm.saveDisabledReason
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Создать")])], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }