var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-type-card"
  }, [_c('div', {
    staticClass: "document-type-card__row"
  }, [_c('InputBase', {
    staticClass: "document-type-card__name",
    attrs: {
      "loading": _vm.loadingName,
      "placeholder": "Имя типа документа"
    },
    on: {
      "blur": _vm.updateName,
      "enter": _vm.updateName
    },
    model: {
      value: _vm.typeName,
      callback: function callback($$v) {
        _vm.typeName = $$v;
      },
      expression: "typeName"
    }
  }), _vm._v(" "), _vm.type ? _c('ButtonDelete', {
    attrs: {
      "tooltip": "Удалить тип",
      "disabled": _vm.oneAss,
      "flow": "left"
    },
    on: {
      "click": _vm.showRemove
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "document-type-card__row"
  }, [_c('Select', {
    staticClass: "document-type-card__numerator",
    attrs: {
      "selected": _vm.numerator,
      "options": _vm.numerators,
      "loading": _vm.loadingNumerator,
      "placeholder": "Установить нумератор"
    },
    on: {
      "change": function change($event) {
        return _vm.setNumerator($event);
      }
    },
    model: {
      value: _vm.numerator,
      callback: function callback($$v) {
        _vm.numerator = $$v;
      },
      expression: "numerator"
    }
  }), _vm._v(" "), _c('InputBase', {
    staticClass: "document-type-card__code",
    attrs: {
      "placeholder": "Код",
      "loading": _vm.loadingCode
    },
    on: {
      "blur": _vm.setCode,
      "enter": _vm.setCode
    },
    model: {
      value: _vm.typeCode,
      callback: function callback($$v) {
        _vm.typeCode = $$v;
      },
      expression: "typeCode"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "document-type-card__row"
  }, [_c('Textarea', {
    staticClass: "document-type-card__description",
    attrs: {
      "min-lines": 1,
      "max-lines": 5,
      "placeholder": "Описание типа документа"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }), _vm._v(" "), _c('Button', {
    staticClass: "document-type-card__description_save",
    attrs: {
      "color": "green",
      "disabled": !_vm.description.trim() || _vm.description.trim() === _vm.type.description
    },
    on: {
      "click": _vm.updateDescription
    }
  }, [_vm._v("\n            Сохранить описание\n        ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "document-type-card__row"
  }, [_vm.oneAss ? _c('div', {
    staticClass: "pink-1"
  }, [_vm._v("\n            Документ создается в 1С\n        ")]) : _vm._e(), _vm._v(" "), _vm.oneAss ? _c('div', {
    attrs: {
      "tooltip": "Настройки",
      "flow": "left"
    },
    on: {
      "click": _vm.openOneAss
    }
  }, [_c('FAIcon', {
    staticClass: "document-type-card__one-ass_icon",
    attrs: {
      "icon": "cog"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _vm.type ? _c('DocumentTypeRequisites', {
    staticClass: "document-type-card__requisites",
    attrs: {
      "type-id": _vm.type.id,
      "one-ass": _vm.oneAss
    }
  }) : _vm._e(), _vm._v(" "), _c('OneAssSettings', {
    attrs: {
      "visibleModal": _vm.visibleOneAssModal
    },
    on: {
      "update:visibleModal": function updateVisibleModal($event) {
        _vm.visibleOneAssModal = $event;
      },
      "update:visible-modal": function updateVisibleModal($event) {
        _vm.visibleOneAssModal = $event;
      }
    }
  }), _vm._v(" "), _c('RemoveModal', {
    ref: "removeModal",
    attrs: {
      "visible": _vm.visibleRemoveModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleRemoveModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }