var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DocumentEventBase', {
    attrs: {
      "event": _vm.event,
      "name": "Изменен статус"
    }
  }, [_c('template', {
    slot: "fields"
  }, [_c('div', [_vm._v(_vm._s(_vm.DOC_STATUSES[_vm.event.fields.previous]))]), _vm._v(" "), _c('svg', {
    staticClass: "document-event-status__arrow ml-3",
    attrs: {
      "viewBox": "0 0 100 200"
    }
  }, [_c('path', {
    attrs: {
      "d": "M 50,0 L 50,200 M 50,200 L 100,110 M 50,200 L 0,110",
      "stroke-width": "20",
      "stroke": "#000"
    }
  })]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.DOC_STATUSES[_vm.event.fields.current]))])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }