<template>
    <HelpCard
        title="Задачи"
        :help="true"
    >
        <template slot="content">
            <h4 class="help-tasks__h4">
                Во вкладке <strong>Система - Задачи</strong> Вы видите задачи, в которых:
            </h4>
            <ul>
                <li>Вы являетесь автором;</li>
                <li>Вы являетесь исполнителем;</li>
                <li>Вы являетесь подтверждающим</li>
                <li>
                    Если у Вас есть роли 'Руководитель' или 'Супервайзер', то Вы также видите задачи:
                    <ul>
                        <li>
                            Если у автора задачи стоит подразделение от Вашего подразделения и ниже
                            по дереву Оргструктуры;
                        </li>
                        <li>
                            Если исполнителем задачи было выбрано подразделение (Ваше или ниже по дереву Оргструктуры);
                            <br>
                            Если в задаче исполнителем был выбран конкретный пользователь без подразделения,
                            то это условие не работает, даже если это Ваш подчиненный.
                        </li>
                        <li>
                            Если подтверждающим задачи было выбрано подразделение (Ваше или ниже по дереву Оргструктуры);
                            <br>
                            Если в задаче подтверждающим был выбран конкретный пользователь без подразделения,
                            то это условие не работает, даже если это Ваш подчиненный.
                        </li>
                    </ul>
                </li>
                <li>
                    Если у Вас есть роль Менеджер документооборота, то Вы получаете абсолютно все задачи, созданные после
                    11 декабря 2019г.
                </li>
            </ul>

            <h4 class="help-tasks__h4">
                Все фильтры во вкладке 'Система - Задачи' работают по принципу 'И', а не 'ИЛИ'.
                <br>
                Вы можете отфильтровать задачи по следующим фильтрам:
            </h4>

            <ol>
                <li>Только свои задачи;</li>
                <li>Только незавершенные;</li>
                <li>Только просроченные;</li>
                <li>Те задачи, которые необходимо выполнить Сегодня;</li>
                <li>Отфильтровать по тексту в названии и описании задачи;</li>
                <li>По Ф.И.О. автора;</li>
                <li>По Ф.И.О. исполнителя;</li>
                <li>По Ф.И.О. подтверждающего.</li>
            </ol>

            <h4 class="help-tasks__h4">
                Задачу можно создать в 2-х местах:
            </h4>
            <ol>
                <li>Во вкладке 'Система - Задачи';</li>
                <li>
                    Внутри любого документа в блоке 'Задачи' под цепочкой согласования.
                    <br>
                    При созданни задачи внутри документа, она автоматически будет привязана к этому документу
                    и видна всем, кто иммет доступ к данному документу.
                </li>
            </ol>

            <h4 class="help-tasks__h4">
                При создании задачи учитывайте:
            </h4>
            <ul>
                <li>Обязательными для заполнения являются Название задачи и Исполнитель;</li>
                <li>
                    При создании задачи внимательно выбирайте от лица какого подразделения Вы ее создаете.
                    <br>
                    Руководитель подразделения, от лица которого Вы создаете задачу, видит все задачи по данному подразделению.
                </li>
                <li>
                    Вы можете указать исполнителем как пользователя, так и подразделение.
                    <br>
                    Задачи, созданные на подразделение автоматически упадут на исполнение Руководителю подразделения,
                    которое Вы выбрали.
                    <br>
                    Если руководитель подразделения хочет перенаправить данную задачу на своего сотрудника,
                    то он создает новую Дочернюю задачу во вкладке 'Система - Задачи'.
                    <br>
                    Ваша задача, созданная на подразделение все равно остается на выполнении у руководителя.
                </li>
                <li>Тот же алгоритм работает и с подтверждающим.</li>
            </ul>

            <h4 class="help-tasks__h4">
                В панели справа во вкладке с задачами Вы видите:
            </h4>
            <ol>
                Во вкладке Входящие:
                <li>Задачи, в которых Вы являетесь Исполнителем и которые находятся в статусе 'Ожидает выполнения';</li>
                <li>Задачи, в которых Вы являетесь подтверждающим и которые находятся в статусе 'Ожидает подтверждения'.</li>
            </ol>
            <ol>
                Во вкладке Исходящие:
                <li>Задачи, в которых Вы являетесь Автором и которые находятся в статусе 'Ожидает выполнения';</li>
                <li>Задачи, в которых Вы являетесь Автором и которые находятся в статусе 'Ожидает подтверждения'.</li>
            </ol>

            <h4 class="help-tasks__h4">
                Вы можете увидеть полную информацию по задаче и прикрепить сообщение в полной карточке задачи:
            </h4>
            <ul>
                <li>Кликнув на задачу в панели справа;</li>
                <li>Кликнув на задачу во вкладке 'Система - Задачи';</li>
                <li>Кликнув на задачу внутри документа.</li>
            </ul>

            <h4 class="help-tasks__h4">
                Если Вы создаете задачу в документе, будьте внимательны:
            </h4>
            <ul>
                <li>
                    Документы в статусе 'Черновик', если им никогда не меняли статус, могут быть удалены.
                    <br>
                    Вместе с документом удаляются все привязанная к данному документу задачи.
                </li>
            </ul>
        </template>
    </HelpCard>
</template>

<script>
import HelpCard from '@/components/about_system/HelpCard'

export default {
    name: 'HelpTasks',
    components: {
        HelpCard
    }
}
</script>

<style lang="stylus" scoped>
.help-tasks__h4
    margin 10px 0 0 0
    padding 0
</style>
