var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "base-list-page__header"
  }, [_c('InputSearch', {
    staticClass: "base-list-page__search",
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm._v(" "), _c('ButtonCreate', {
    attrs: {
      "text": "\u0421\u043E\u0437\u0434\u0430\u0442\u044C ".concat(_vm.types[_vm.type].buttonText)
    },
    on: {
      "click": _vm.showCreate
    }
  })], 1), _vm._v(" "), _vm.centers.length > 1 ? _c('div', {
    staticClass: "base-page__centers"
  }, _vm._l(_vm.centers, function (center) {
    return _c('RadioButton', {
      key: center.id,
      attrs: {
        "value": center.id,
        "label": center.name
      },
      model: {
        value: _vm.centerId,
        callback: function callback($$v) {
          _vm.centerId = $$v;
        },
        expression: "centerId"
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "base-list-page__list"
  }, [_vm._t("list")], 2), _vm._v(" "), _c('Modal', {
    attrs: {
      "visible": _vm.syncedVisible,
      "title": "\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 ".concat(_vm.types[_vm.type].title)
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      },
      "close": _vm.emitClose,
      "enter": _vm.emitCreate
    }
  }, [_c('template', {
    slot: "body"
  }, [_vm._t("create")], 2), _vm._v(" "), _c('template', {
    slot: "footer"
  }, [_c('Button', {
    staticClass: "base-page__create",
    attrs: {
      "disabled": _vm.disabledCreate,
      "color": "green"
    },
    on: {
      "click": _vm.emitCreate
    }
  }, [_vm._v("\n                Создать\n            ")])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }