import { render, staticRenderFns } from "./ChatCardSelector.vue?vue&type=template&id=29ebf974&scoped=true&"
import script from "./ChatCardSelector.vue?vue&type=script&lang=ts&"
export * from "./ChatCardSelector.vue?vue&type=script&lang=ts&"
import style0 from "./ChatCardSelector.vue?vue&type=style&index=0&id=29ebf974&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29ebf974",
  null
  
)

export default component.exports