
















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

const isEmail = (value = '') => new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$').test(value)

@Component({})

export default class EmailInput extends Vue {
    @Prop({ default: { identity: '', valid: false } }) value!: { identity: string, valid: boolean }
    @Prop({ default: 'Email' }) placeholder!: string

    // *************************************************************
    // DATA PARAMS
    errorEmail = ''

    // *************************************************************
    // COMPUTED
    get isValid(): boolean {
        return isEmail(this.value.identity)
    }

    // *************************************************************
    // WATCH
    @Watch('isValid', { immediate: true })
    handleValidChange(): void {
        this.emitInput(this.value.identity)
    }

    // *************************************************************
    // METHODS
    onInputEmail(email: string): void {
        this.errorEmail = ''
        if (email !== this.value.identity) {
            this.emitInput(email)
        }
    }

    onBlurEmail(): void {
        if (!this.isValid) {
            this.errorEmail = 'Email некорректен'
        }
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(phone: string): { identity: string, valid: boolean } {
        return { identity: phone, valid: this.isValid }
    }
}
