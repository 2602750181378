var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-label",
    class: {
      'input-label_error': _vm.errorValue
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('InputBase', {
    ref: "input",
    staticClass: "input-label__input mt-auto",
    attrs: {
      "value": _vm.value,
      "placeholder": "",
      "loading": _vm.loading,
      "required": _vm.required
    },
    on: {
      "focus": _vm.onFocus,
      "blur": _vm.onBlur,
      "input": function input($event) {
        return _vm.onInput($event);
      },
      "enter": _vm.emitEnter
    }
  }), _vm._v(" "), _c('label', {
    staticClass: "input-label__label",
    class: {
      'input-label__label_active': _vm.focus || _vm.value
    }
  }, [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }