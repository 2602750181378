<template>
    <div class="events">
        <div class="flex-row-center">
            <h1 class="mr-3">
                Мои активные документы
            </h1>
            <ListCount :count="documents.length" />
        </div>
        <div class="flex-row-center">
            <Checkbox
                v-for="(ch, key) in checkbox"
                :key="key"
                v-model="ch.checked"
                :label="ch.label"
            />
        </div>

        <div
            v-if="documents.length"
            key="list"
            class="events__list"
        >
            <DocumentCard
                v-for="event in documents"
                :key="event.id"
                :event="event"
                class="mb-3"
            />
        </div>
        <EmptyList
            v-else
            key="empty"
        />
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import ListCount from '@/components/common/ListCount'
import Checkbox from '@/components/common/inputs/Checkbox'
import DocumentCard from '@/components/events/Card'
import EmptyList from '@/components/common/EmptyList'

export default {
    components: { ListCount, Checkbox, DocumentCard, EmptyList },
    data() {
        return {
            checkbox: {
                approval: { label: 'Согласование', checked: true },
                regular: { label: 'Выполнение', checked: true },
                confirmation: { label: 'Подтверждение', checked: true },
            }
        }
    },
    computed: {
        ...mapGetters('me', ['getMe']),
        ...mapGetters('workflow_event', { 'approvalEventsByActor': 'getByActor' }),
        tab() {
            return this.$route.query.type
        },
        documents() {
            const events = this.approvalEventsByActor(this.getMe.id)
            return events.filter(event => {
                let match = false
                if (this.checkbox.approval.checked && event.type === 'approval') {
                    match = true
                }
                if (this.checkbox.regular.checked && event.task && (event.task.type === 'regular' || event.task.type === 'width_confirmation')) {
                    match = true
                }
                if (this.checkbox.confirmation.checked && event.task && event.task.type === 'confirmation') {
                    match = true
                }
                return match
            })
        }
    },
    watch: {
        tab: {
            immediate: true,
            handler(to) {
                this.setChecked(to)
            }
        }
    },
    methods: {
        setChecked(tab) {
            if (!tab) {
                this.checkbox.approval.checked = true
                this.checkbox.regular.checked = true
                this.checkbox.confirmation.checked = true
                return
            }
            if (tab === 'approval') {
                this.checkbox.approval.checked = true
                this.checkbox.regular.checked = false
                this.checkbox.confirmation.checked = false
                return
            }
            if (tab === 'tasks') {
                this.checkbox.approval.checked = false
                this.checkbox.regular.checked = true
                this.checkbox.confirmation.checked = true
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.events
    display flex
    flex-direction column
    height 100%
    overflow hidden

.events__list
    flex-grow 1
    padding 2px
    overflow-y auto
</style>
