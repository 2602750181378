import { ActionTree } from 'vuex'
import { RootState } from '@/store'

import { CompanyStructureGetters } from '@/store/company_structure/_getters'
import UnitsActions from '@/store/company_structure/_actions/units'
import UsersActions from '@/store/company_structure/_actions/users'
import CompanyStructureMutations from '@/store/company_structure/_mutations'

import { User } from '@/model/User'
import { Unit } from '@/model/Unit'

export class CompanyStructureState {
    units: Array<Unit> = []
    users: Array<User> = []
    unitsObject: { [unitId: string]: Unit } = {}
    userObject: { [userId: string]: User } = {}
}

const actions: ActionTree<CompanyStructureState, RootState> = {
    ...UsersActions,
    ...UnitsActions,

    cleanUpState({ commit }) {
        commit('cleanUpState')
    }
}

const CompanyStructureSubModule = {
    namespaced: true,
    state: new CompanyStructureState(),
    getters: CompanyStructureGetters,
    mutations: CompanyStructureMutations,
    actions
}

export default CompanyStructureSubModule
