



















import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import InputBase from '@/components/common/inputs/InputBase.vue'

@Component({
    components: { InputBase }
})

export default class CodeInput extends Vue {
    @Ref('code') code!: HTMLInputElement

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) value!: string
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: '** ** **' }) mask!: string

    // *************************************************************
    // DATA PARAMS
    model = ''
    errorCode = ''

    // *************************************************************
    // COMPUTED
    get codeLength(): number {
        return this.mask.replaceAll(' ', '').length
    }

    get isValid(): boolean {
        return this.value.length === this.codeLength
    }

    get normalizedCode(): string {
        // Убираем буквы и пробелы
        const model = this.model.replace(/[A-Z]| |\*/gi, '')
        // убираем символы по бокам
        const removeNonIntegers = model.replace(/^[^\d]+|[^\d]+$/g,'')
        return removeNonIntegers.substring(0, this.codeLength)
    }

    get denormalizedCode(): string {
        let result = this.mask
        const countSymbols = this.mask.split('*').length

        const onlyNumbers = this.value.replace(/[^\d]/gi, '')
        for (let i = 0; i < countSymbols; i++) {
            if (onlyNumbers[i]) {
                result = result.replace('*', onlyNumbers[i])
            } else {
                break
            }
        }
        return result
    }

    // *************************************************************
    // WATCH
    @Watch('value')
    handleValueChange(to: string, from: string): void {
        let type = 'input'
        if (from && from.length > to.length) {
            type = 'delete'
        }
        this.setModel(type)
    }

    mounted(): void {
        this.setModel('input')
    }

    // *************************************************************
    // METHODS
    onInputCode(): void {
        // const target = e?.target as HTMLInputElement
        this.errorCode = ''
        this.emitInput()
        this.model = this.denormalizedCode
    }

    onBlurCode(): void {
        if (!this.isValid) {
            this.errorCode = 'Код некорректен'
        }
    }

    setModel(inputType: string): void {
        this.model = this.denormalizedCode
        this.setCursorPosition(inputType)
    }

    setCursorPosition(inputType: string): void {
        const ctrl = this.$refs.code as HTMLInputElement

        let position = this.model.indexOf('*')
        if (inputType === 'delete' && this.value) {
            const lastDigital = this.value.substring(this.value.length - 1)
            const lastIndexOfDigital = this.model.lastIndexOf(lastDigital)
            if (lastIndexOfDigital + 1 < position) {
                position = lastIndexOfDigital + 1
            }
        }
        // const pos = position || this.model.indexOf('*')
        if (ctrl?.setSelectionRange) {
            setTimeout(() => {
                ctrl.setSelectionRange(position, position)
            }, 0)
            ctrl.focus()
        }
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(): string {
        return this.normalizedCode
    }
}
