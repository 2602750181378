var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ajaja"
  }, [_c('div', {
    staticClass: "ajaja-user"
  }, [_vm._v("\n        Уважаемый/ая " + _vm._s(_vm.name) + "!\n    ")]), _vm._v(" "), _vm._m(0)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "ajaja__text"
  }, [_vm._v("\n        Ваше любопытство не знает пределов.\n        "), _c('br'), _vm._v("\n        Удивляет только то, что вы тратите собственное время на совершенно идиотское занятие.\n        "), _c('br'), _vm._v("\n        Вы могли бы заняться спортом, почитать книгу, пойти собрать мусор во дворе.\n        "), _c('br'), _vm._v("\n        Жизнь - интересная штука, найдите себя в ней!\n    ")]);
}]

export { render, staticRenderFns }