




















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { Actor, UserInfo, UnitInfo } from '@/model/Actor'

import { ApprovalTemplate, WorkflowTemplate } from '@/model/Workflow'
import { DocumentType } from '@/model/DocumentType'
import { Unit } from '@/model/Unit'
import { User } from '@/model/User'

import Popover from '@/components/common/Popover.vue'
import RadioButton from '@/components/common/inputs/RadioButton.vue'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import ButtonGreen from '@/components/common/buttons/ButtonGreen.vue'

@Component({
    components: {
        Popover,
        RadioButton,
        ActionSelectCard,
        InputBase,
        ButtonGreen
    }
})

export default class WFTActionApproval extends Vue {
    // *************************************************************
    // DATA PARAMS
    approvalType = ''
    selectCardId = ''
    specialName = ''

    // *************************************************************
    // COMPUTED
    @Getter('workflow/getWorkflow') getWorkflow!: WorkflowTemplate
    @Getter('workflow/getActiveApproval') getActiveApproval!: ApprovalTemplate | null

    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    @Getter('company_structure/getUnitsByCenterId') getUnitsByCenterId!: (centerId: string) => Array<Unit>
    @Getter('company_structure/getUsersByCenterId') getUsersByCenterId!: (centerId: string) => Array<User>
    @Getter('company_structure/getUserById') getUserById!: (id: string) => User | null
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get centerId(): string {
        const type = this.getTypeById(this.$route.params.id)
        return type?.centerId || ''
    }

    get users(): Array<User> {
        return this.getUsersByCenterId(this.centerId)
    }

    get units(): Array<Unit> {
        return this.getUnitsByCenterId(this.centerId)
    }

    // *************************************************************
    // WATCH
    @Watch('getWorkflow', { immediate: true, deep: true })
    handleWorkflowChange(val: WorkflowTemplate): void {
        if (val.action === 'addApproval') {
            if (val.staticFlag) {
                // Если статическая цепочка согласования, то автоматически выбираем 'user'
                this.approvalType = 'user'
            } else {
                // Если динамическая цепочка - автоматически выбираем 'unit'
                this.approvalType = 'unit'
            }
        }
    }

    @Watch('getActiveApproval', { immediate: true, deep: true })
    handleActiveNodeChange(val: ApprovalTemplate | null): void {
        if (val) {
            if (this.getWorkflow.action === 'changeApproval') {
                if (val.specialName) {
                    this.specialName = val.specialName
                    this.approvalType = 'special'
                    this.selectCardId = ''
                } else if (val.actor?.user) {
                    this.approvalType = 'user'
                    this.selectCardId = val.actor.user.id
                } else if (val.actor?.unit) {
                    this.approvalType = 'unit'
                    this.selectCardId = val.actor.unit.id
                }
            }
        }
    }

    // *************************************************************
    // METHODS
    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFApproval')
    changeWFApproval!: (params: { id: string, actor: Actor | null, specialName: string }) => void

    @Action('workflow/addWFApproval')
    addWFApproval!: (params: { approvalActor: Actor | null, specialName: string }) => Promise<void>

    selectCard(entityId: string): void {
        if (!entityId) {
            // Обнуляем выбранного actor
            this.selectCardId = ''
            return
        }

        // Отмечаем наличие изменений в workFlow
        this.workflowChange(true)

        // прокидывается из дочернего компонента ActionSelectCard
        // создаем новый approval с заполненым user or unit в зависимости от выбранного типа
        const approvalActor = new Actor({ User: undefined, Unit: undefined })
        if (this.approvalType === 'unit') {
            const unit = this.getUnitById(entityId)
            if (!unit) {
                this.$snotify.warning('Обратитесь в службу поддержки', `Подразделение не найдено: ${entityId}`)
                return
            }
            approvalActor.unit = new UnitInfo({ ID: entityId, Name: unit.name })
        } else if (this.approvalType === 'user') {
            const user = this.getUserById(entityId)
            if (!user) {
                this.$snotify.error('Обратитесь в службу поддержки', `Пользователь не найден: ${entityId}`)
                return
            }
            approvalActor.user = new UserInfo({
                ID: entityId,
                Name: {
                    First: user.name.first,
                    Middle: user.name.middle,
                    Last: user.name.last,
                }
            })
        }


        if (this.getWorkflow.action === 'addApproval') {
            this.addWFApproval({ approvalActor: approvalActor, specialName: '' })
            this.changeWFAction('')
        } else if (this.getWorkflow.action === 'changeApproval') {
            this.changeWFApproval({
                id: this.getWorkflow.activeNode,
                actor: approvalActor,
                specialName: ''
            })
            this.changeWFAction('')
        } else {
            console.error('Component::ACTION-APPROVAL - пытаемся выполнить непонятное действие')
        }
    }

    setSpecialApproval(): void {
        if (!this.specialName) {
            this.$snotify.warning('Введите название узла')
            return
        }

        this.workflowChange(true)

        if (this.getWorkflow.action === 'addApproval') {
            this.addWFApproval({
                approvalActor: null,
                specialName: this.specialName
            })
        } else if (this.getWorkflow.action === 'changeApproval') {
            this.changeWFApproval({
                id: this.getWorkflow.activeNode,
                actor: null,
                specialName: this.specialName
            })
        } else {
            console.error('Component::ACTION-APPROVAL - пытаемся выполнить непонятное действие')
        }

        this.changeWFAction('')
    }
}
