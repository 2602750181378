






















































































import { Component, Emit, Mixins } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { User } from '@/model/User'

import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'
import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import Attribute from '@/components/common/Attribute.vue'
import TaskCardMixin from '@/components/tasks/cards/TaskCardMixin.vue'

@Component({
    components: {
        ButtonAdd,
        ButtonViolet,
        Attribute
    }
})

export default class TaskCardBig extends Mixins(TaskCardMixin) {
    // *************************************************************
    // DATA PARAMS
    time = Date.now()
    $_timeNow: ReturnType<typeof setInterval> | undefined = undefined

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    get buttons(): Array<{ key: string, text: string }> {
        if (this.task.closed) {
            return []
        } else if (this.task.assignee.user?.id === this.getMe.id && !this.task.completed) {
            // Если исполнитель и задача не выполнена
            return [{
                key: 'complete',
                text: 'Выполнить'
            }]
        } else if (this.task.confirmer && this.task.confirmer.user?.id === this.getMe.id && this.task.completed && !this.task.confirmed ) {
            // Если требует подтверждения данного пользователя и еще не подтверждена, но уже выполнена
            return [
                {
                    key: 'confirm',
                    text: 'Подтвердить'
                },
                {
                    key: 'reject',
                    text: 'Отклонить'
                }
            ]
        } else {
            return []
        }
    }

    // *************************************************************
    // CREATED
    created(): void {
        this.$_timeNow = setInterval(
            (() => {
                return () => {
                    this.time = Date.now()
                }
            })(),
            20000
        )
    }

    // *************************************************************
    // BEFORE DESTROY
    beforeDestroy(): void {
        clearInterval(this.$_timeNow)
    }

    // *************************************************************
    // METHODS
    @Action('task/callRemove')
    callRemove!: (id: string) => Promise<void>

    remove(): void {
        this.callRemove(this.task.id)
    }

    // *************************************************************
    // EMIT
    @Emit('create')
    emitCreate(taskId: string): string {
        return taskId
    }
}

