var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkbox-container",
    class: [_vm.classes]
  }, [_c('div', {
    staticClass: "checkbox-group"
  }, [_c('div', {
    staticClass: "checkbox-ripple-container",
    on: {
      "click": _vm.toggle
    }
  }, [_c('input', {
    staticClass: "checkbox__input",
    attrs: {
      "id": _vm.checkboxId,
      "disabled": _vm.disabled,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.checked
    },
    on: {
      "change": _vm.toggle
    }
  })])]), _vm._v(" "), _vm.label ? _c('label', {
    staticClass: "checkbox_label",
    attrs: {
      "for": _vm.checkboxId
    },
    on: {
      "click": _vm.toggle
    }
  }, [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }