import { render, staticRenderFns } from "./SelectDocumentRequisiteCard.vue?vue&type=template&id=05d7db38&scoped=true&"
import script from "./SelectDocumentRequisiteCard.vue?vue&type=script&lang=ts&"
export * from "./SelectDocumentRequisiteCard.vue?vue&type=script&lang=ts&"
import style0 from "./SelectDocumentRequisiteCard.vue?vue&type=style&index=0&id=05d7db38&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05d7db38",
  null
  
)

export default component.exports