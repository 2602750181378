var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "work-flow-stage",
    class: {
      'work-flow-stage_active': _vm.active
    }
  }, _vm.$listeners), [_c('div', {
    staticClass: "work-flow-stage__header",
    class: {
      'work-flow-stage__header_approval': _vm.stageType === 'approval',
      'work-flow-stage__header_border': _vm.showHeaderBorder
    },
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.emitClickStage($event);
      }
    }
  }, [!_vm.first && _vm.stageType ? _c('div', {
    attrs: {
      "tooltip": "Переместить блок вверх",
      "flow": "left"
    },
    on: {
      "click": _vm.emitMoveUp
    }
  }, [_c('FAIcon', {
    staticClass: "c-pointer",
    attrs: {
      "icon": "arrow-up"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.last && _vm.stageType ? _c('div', {
    staticClass: "ml-2",
    attrs: {
      "tooltip": "Переместить блок вниз",
      "flow": "left"
    },
    on: {
      "click": _vm.emitMoveDown
    }
  }, [_c('FAIcon', {
    staticClass: "c-pointer",
    attrs: {
      "icon": "arrow-down"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "ml-5 text-right fs-20",
    class: {
      'pink-1': _vm.active
    },
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.emitClickStage($event);
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")])]), _vm._v(" "), _vm._t("body")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }