











import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { StageTemplate } from '@/model/Workflow'

import InputBase from '@/components/common/inputs/InputBase.vue'

@Component({
    components: { InputBase }
})

export default class WFTStageName extends Vue {
    // *************************************************************
    // DATA PARAMS
    stageName = ''

    // *************************************************************
    // COMPUTED
    @Getter('workflow/getActiveStage') getActiveStage!: StageTemplate | null

    // *************************************************************
    // WATCH
    @Watch('getActiveStage', { immediate: true })
    handleActiveStageChange(val: StageTemplate | null): void {
        if (val) {
            this.stageName = val.displayName
        }
    }

    // *************************************************************
    // METHODS
    @Action('workflow/changeWFStageDisplayName')
    changeWFStageDisplayName!: (name: string) => void

    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    onBlur(): void {
        if (this.stageName !== this.getActiveStage?.displayName) {
            this.changeWFStageDisplayName(this.stageName)
            // Отмечаем наличие изменений в workFlow
            this.workflowChange(true)
        }
    }
}
