var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-base-wrapper"
  }, [_c('input', {
    ref: "input",
    staticClass: "input-base input",
    class: {
      'input-base__search': _vm.search,
      'input-base_focus': _vm.activeFocus,
      'input_error': _vm.required && !_vm.value,
      'input-base_center': _vm.center
    },
    attrs: {
      "type": "text",
      "disabled": _vm.disabledInput,
      "placeholder": _vm.placeholder,
      "aria-label": "search"
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "focus": _vm.emitFocus,
      "blur": _vm.emitBlur,
      "input": function input($event) {
        return _vm.emitInput($event.target.value);
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.emitEnter($event);
      }
    }
  }), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('div')]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }