










































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import { Attachment } from '@/model/File'

import OneFile from '@/components/common/files/OneFile.vue'
import Uploader from '@/components/common/Uploader.vue'
import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'

@Component<Files>({
    components: {
        OneFile,
        Uploader,
        ButtonAdd
    }
})

export default class Files extends Vue {
    @Ref() readonly uploader!: Uploader
    @Ref() readonly dropZoneFiles!: HTMLDivElement

    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) files!: Array<Attachment>
    @Prop({ default: '' }) documentId!: string
    @Prop({ default: '' }) groupName!: string
    @Prop({ default: '' }) name!: string
    @Prop({ default: false }) canAddFiles!: boolean
    @Prop({ default: false }) canChange!: boolean

    // *************************************************************
    // DATA PARAMS
    uploadingFileId = ''

    // *************************************************************
    // MOUNTED
    mounted(): void {
        document.addEventListener('drop', function (e) {
            e.preventDefault()
            e.stopPropagation()
        }, false)
        document.addEventListener('dragover', function (e) {
            e.preventDefault()
            e.stopPropagation()
        }, false)

    }

    beforeUpload(uploadingFiles: Array<File>, upload: (formData: FormData, address: string) => void): void {
        // Этот метод через props прокидываем в компонент Uploader для отправки file на сервер
        const formData = new FormData()
        formData.append('file', uploadingFiles[0])

        const address = `https://kant.themake.rs/api/storage/${this.groupName}/upload?${this.name}=${this.documentId}&file=${this.uploadingFileId}`
        console.log('address', address)

        // Задаем размер === 10мб для последующей проверки размера прикрепляемого файла
        const attach_size = 10 * 1024 * 1024 // 10мб

        if (uploadingFiles[0].size > attach_size) {
            this.$snotify.warning('Слишком большой размер файла. Можно прикреплять файлы размером до 10МБ', 'Отклонено!')
        } else {
            upload(formData, address)
        }
    }

    addFile(): void {
        // Если это автор документа
        // Обнуляем uploadingFileId в data, показывая, что добавляем новый файл
        this.uploadingFileId = ''
        // При клике по иконке 'Добавить файл' инициируем
        // клик по input - type === file в компоненте Uploader для открытия папки и выбора файла на компьютере
        this.uploader.clickInput()
    }

    replaceFile(fileId: string): void {
        // Устанавливаем uploadingFileId в data равный id файлу, который заменяется
        this.uploadingFileId = fileId
        // При клике по иконке 'Заменить файл' в компоненте Files инициируем
        // клик по input - type === file в компоненте Uploader для открытия папки и выбора файла на компьютере
        this.uploader.clickInput()
    }

    dragEnter(event: DragEvent): void {
        // Убираем автоматическое открытие картинки
        event.preventDefault()
        event.stopPropagation()
        this.dropZoneFiles.style.backgroundColor = '#ccc'
    }

    dragLeave(event: DragEvent): void {
        // Убираем автоматическое открытие картинки
        event.preventDefault()
        event.stopPropagation()
        this.dropZoneFiles.style.backgroundColor = '#fff'
    }

    drop(event: DragEvent): void {
        event.preventDefault()
        event.stopPropagation()
        this.dropZoneFiles.style.backgroundColor = '#fff'
        // Вызываем метод в компоненте Uploader для отправки avatar
        this.uploader.sendFile(event)
    }
}
