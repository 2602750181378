var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "clickOutside",
      rawName: "v-clickOutside",
      value: _vm.handleClickOutside,
      expression: "handleClickOutside"
    }],
    staticClass: "select",
    class: {
      'select_disabled': _vm.selectDisabled
    }
  }, [_c('div', {
    staticClass: "select__selected",
    on: {
      "click": _vm.onClickSelect
    }
  }, [_c('span', {
    staticClass: "text-wrap",
    class: {
      'red': !_vm.value && _vm.required,
      'placeholder': !_vm.value && !_vm.required
    }
  }, [_vm._v(_vm._s(_vm.selectedName))]), _vm._v(" "), _c('div', {
    staticClass: "select__arrow select__arrow_bottom",
    class: {
      'select__arrow_top': _vm.showSelect,
      'select__arrow_red': !_vm.value && _vm.required
    }
  })]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSelect,
      expression: "showSelect"
    }],
    staticClass: "select__list"
  }, _vm._l(_vm.optionsWithDisabled, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "select__item",
      attrs: {
        "disabled": option.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.selectOption(option);
        }
      }
    }, [_vm._v("\n            " + _vm._s(option.name) + "\n        ")]);
  }), 0), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('div')]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }