var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.task !== null ? _c('li', _vm._g({
    staticClass: "doc-wf-task-stage-node",
    class: {
      'doc-wf-task-stage-node__with-child': _vm.task.inputs.length !== 0
    }
  }, _vm.$listeners), [_vm.task.outputs.length !== 0 ? _c('div', {
    staticClass: "doc-wf-task-stage-node__drop",
    on: {
      "click": _vm.clickDrop
    }
  }, [_vm._v("\n        " + _vm._s(!!_vm.drop ? '‒' : '+') + "\n    ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "pr-2 pb-1 pl-5"
  }, [_c('DWF_TaskCard', {
    attrs: {
      "task": _vm.task,
      "child-task": _vm.childTask,
      "stage-type": _vm.activeStageType
    }
  })], 1), _vm._v(" "), _vm.drop ? _c('ul', _vm._l(_vm.task.outputs, function (output) {
    return _c('DWF_TaskStageNode', {
      key: output,
      attrs: {
        "task-id": output,
        "stage-index": _vm.stageIndex
      }
    });
  }), 1) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }