import { cuntPB } from 'api'

/**
 * req_do - Ссылка на другой справочник
 * req_editor - Editor
 * */
export type DocumentTypeRequisiteType = 'req_number' | 'req_date' | 'req_string' | 'req_table' | 'req_do' | 'req_editor'

export class DocumentTypeRequisite {
    public id = ''
    public name = ''
    public type: DocumentTypeRequisiteType = 'req_string'
    public subType = ''
    public required = false
    public editable = false
    public fromExternalSource = false

    constructor(req: cuntPB.DocumentTypeRequisite | null) {
        if (req) {
            this.id = req.ID
            this.name = req.Name
            this.type = req.Type as DocumentTypeRequisiteType
            this.subType = req.SubType
            this.required = req.Required
            this.editable = req.Editable
            this.fromExternalSource = req.FromExternalSource
        }
    }
}

export class DocumentTypeOneAss {
    public collectionID: string

    constructor(oneAss: cuntPB.DocumentTypeOneAss) {
        this.collectionID = oneAss.CollectionID
    }
}

export class DocumentType {
    public id: string
    public centerId: string
    public name: string
    public description: string
    public code: string
    public numerator: string
    public requisites: Array<DocumentTypeRequisite>
    public oneAss: DocumentTypeOneAss | null
    public modificationTime: number

    constructor(type: cuntPB.DocumentType) {
        this.id = type.ID
        this.centerId = type.CenterID
        this.name = type.Name
        this.description = type.Description
        this.code = type.Code
        this.numerator = type.Numerator
        this.requisites = type.Requisites.map((r: cuntPB.DocumentTypeRequisite) => new DocumentTypeRequisite(r)) // repeated RequisiteType DocumentTypeRequisite
        this.oneAss = type.OneAss ? new DocumentTypeOneAss(type.OneAss) : null
        this.modificationTime = type.ModificationTime
    }
}
