/**
 * @fileoverview gRPC-Web generated client stub for api.themakers.sc
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.api = {};
proto.api.themakers = {};
proto.api.themakers.sc = require('./sc_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.UserEventsSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.UserEventsSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.UserEventsSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.UserEventsSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.EventStreamReq,
 *   !proto.api.themakers.sc.UserEvent>}
 */
const methodInfo_UserEventsSvc_EventStream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.UserEvent,
  /** @param {!proto.api.themakers.sc.EventStreamReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.UserEvent.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.EventStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.UserEvent>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserEventsSvcClient.prototype.eventStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.UserEventsSvc/EventStream',
      request,
      metadata,
      methodInfo_UserEventsSvc_EventStream);
};


/**
 * @param {!proto.api.themakers.sc.EventStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.UserEvent>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserEventsSvcPromiseClient.prototype.eventStream =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.UserEventsSvc/EventStream',
      request,
      metadata,
      methodInfo_UserEventsSvc_EventStream);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.UserSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.UserSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.UserSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.UserSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.GetUsersByRolesReq,
 *   !proto.api.themakers.sc.User>}
 */
const methodInfo_UserSvc_GetUsersByRoles = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.User,
  /** @param {!proto.api.themakers.sc.GetUsersByRolesReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.User.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.GetUsersByRolesReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.User>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcClient.prototype.getUsersByRoles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.UserSvc/GetUsersByRoles',
      request,
      metadata,
      methodInfo_UserSvc_GetUsersByRoles);
};


/**
 * @param {!proto.api.themakers.sc.GetUsersByRolesReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.User>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcPromiseClient.prototype.getUsersByRoles =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.UserSvc/GetUsersByRoles',
      request,
      metadata,
      methodInfo_UserSvc_GetUsersByRoles);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.GetUserReq,
 *   !proto.api.themakers.sc.User>}
 */
const methodInfo_UserSvc_GetUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.User,
  /** @param {!proto.api.themakers.sc.GetUserReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.User.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.GetUserReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UserSvc/GetUser',
      request,
      metadata,
      methodInfo_UserSvc_GetUser,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.GetUserReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.User>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcPromiseClient.prototype.getUser =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.getUser(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetUserNameReq,
 *   !proto.api.themakers.sc.User>}
 */
const methodInfo_UserSvc_SetName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.User,
  /** @param {!proto.api.themakers.sc.SetUserNameReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.User.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetUserNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcClient.prototype.setName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UserSvc/SetName',
      request,
      metadata,
      methodInfo_UserSvc_SetName,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetUserNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.User>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcPromiseClient.prototype.setName =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setName(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetUserEmailReq,
 *   !proto.api.themakers.sc.User>}
 */
const methodInfo_UserSvc_SetEmail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.User,
  /** @param {!proto.api.themakers.sc.SetUserEmailReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.User.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetUserEmailReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcClient.prototype.setEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UserSvc/SetEmail',
      request,
      metadata,
      methodInfo_UserSvc_SetEmail,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetUserEmailReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.User>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcPromiseClient.prototype.setEmail =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setEmail(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetUserPhoneReq,
 *   !proto.api.themakers.sc.User>}
 */
const methodInfo_UserSvc_SetPhone = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.User,
  /** @param {!proto.api.themakers.sc.SetUserPhoneReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.User.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetUserPhoneReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcClient.prototype.setPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UserSvc/SetPhone',
      request,
      metadata,
      methodInfo_UserSvc_SetPhone,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetUserPhoneReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.User>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcPromiseClient.prototype.setPhone =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setPhone(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RetireReq,
 *   !proto.api.themakers.sc.User>}
 */
const methodInfo_UserSvc_Retire = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.User,
  /** @param {!proto.api.themakers.sc.RetireReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.User.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RetireReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcClient.prototype.retire =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UserSvc/Retire',
      request,
      metadata,
      methodInfo_UserSvc_Retire,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RetireReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.User>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcPromiseClient.prototype.retire =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.retire(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RolesOp,
 *   !proto.api.themakers.sc.User>}
 */
const methodInfo_UserSvc_ChangeRoles = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.User,
  /** @param {!proto.api.themakers.sc.RolesOp} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.User.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RolesOp} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcClient.prototype.changeRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UserSvc/ChangeRoles',
      request,
      metadata,
      methodInfo_UserSvc_ChangeRoles,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RolesOp} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.User>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UserSvcPromiseClient.prototype.changeRoles =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.changeRoles(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.DocumentTypeSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.DocumentTypeSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.DocumentTypeSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.CreateDocumentTypeReq,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.CreateDocumentTypeReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.CreateDocumentTypeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/Create',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_Create,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.CreateDocumentTypeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.create =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.create(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_DocumentTypeSvc_Remove = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.RemoveReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/Remove',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_Remove,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.remove =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.remove(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetNameReq,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_SetName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.SetNameReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.setName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/SetName',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_SetName,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.setName =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setName(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetDescriptionReq,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_SetDescription = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.SetDescriptionReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetDescriptionReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.setDescription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/SetDescription',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_SetDescription,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetDescriptionReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.setDescription =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setDescription(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetCodeReq,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_SetCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.SetCodeReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetCodeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.setCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/SetCode',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_SetCode,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetCodeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.setCode =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setCode(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetNumerator_DocumentTypeReq,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_SetNumerator = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.SetNumerator_DocumentTypeReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetNumerator_DocumentTypeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.setNumerator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/SetNumerator',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_SetNumerator,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetNumerator_DocumentTypeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.setNumerator =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setNumerator(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RearangeRequisitesReq,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_RearangeRequisites = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.RearangeRequisitesReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RearangeRequisitesReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.rearangeRequisites =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/RearangeRequisites',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_RearangeRequisites,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RearangeRequisitesReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.rearangeRequisites =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.rearangeRequisites(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.AddDocumentTypeRequisiteReq,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_AddRequisite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.AddDocumentTypeRequisiteReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.AddDocumentTypeRequisiteReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.addRequisite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/AddRequisite',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_AddRequisite,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.AddDocumentTypeRequisiteReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.addRequisite =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.addRequisite(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_UpdateRequisite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.updateRequisite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/UpdateRequisite',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_UpdateRequisite,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.UpdateDocumentTypeRequisiteReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.updateRequisite =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.updateRequisite(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveDocumentTypeRequisite,
 *   !proto.api.themakers.sc.DocumentType>}
 */
const methodInfo_DocumentTypeSvc_RemoveRequisite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentType,
  /** @param {!proto.api.themakers.sc.RemoveDocumentTypeRequisite} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentType.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveDocumentTypeRequisite} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcClient.prototype.removeRequisite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentTypeSvc/RemoveRequisite',
      request,
      metadata,
      methodInfo_DocumentTypeSvc_RemoveRequisite,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveDocumentTypeRequisite} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentType>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentTypeSvcPromiseClient.prototype.removeRequisite =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.removeRequisite(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.DocumentSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.DocumentSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.DocumentSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.DocumentSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.ListStreamReq,
 *   !proto.api.themakers.sc.DocumentListStreamMessage>}
 */
const methodInfo_DocumentSvc_ListStream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentListStreamMessage,
  /** @param {!proto.api.themakers.sc.ListStreamReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentListStreamMessage.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.ListStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentListStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.listStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/ListStream',
      request,
      metadata,
      methodInfo_DocumentSvc_ListStream);
};


/**
 * @param {!proto.api.themakers.sc.ListStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentListStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.listStream =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.DocumentSvc/ListStream',
      request,
      metadata,
      methodInfo_DocumentSvc_ListStream);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.DocumentStreamReq,
 *   !proto.api.themakers.sc.DocumentStreamMessage>}
 */
const methodInfo_DocumentSvc_Stream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentStreamMessage,
  /** @param {!proto.api.themakers.sc.DocumentStreamReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentStreamMessage.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.DocumentStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.stream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/Stream',
      request,
      metadata,
      methodInfo_DocumentSvc_Stream);
};


/**
 * @param {!proto.api.themakers.sc.DocumentStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.stream =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.DocumentSvc/Stream',
      request,
      metadata,
      methodInfo_DocumentSvc_Stream);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.ArchivedListStreamReq,
 *   !proto.api.themakers.sc.ArchivedListStreamMessage>}
 */
const methodInfo_DocumentSvc_ArchivedListStream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.ArchivedListStreamMessage,
  /** @param {!proto.api.themakers.sc.ArchivedListStreamReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.ArchivedListStreamMessage.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.ArchivedListStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.ArchivedListStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.archivedListStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/ArchivedListStream',
      request,
      metadata,
      methodInfo_DocumentSvc_ArchivedListStream);
};


/**
 * @param {!proto.api.themakers.sc.ArchivedListStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.ArchivedListStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.archivedListStream =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.DocumentSvc/ArchivedListStream',
      request,
      metadata,
      methodInfo_DocumentSvc_ArchivedListStream);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.NewDocumentReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_NewDocument = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.NewDocumentReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.NewDocumentReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.newDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/NewDocument',
      request,
      metadata,
      methodInfo_DocumentSvc_NewDocument,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.NewDocumentReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.newDocument =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.newDocument(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveDocumentReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_DocumentSvc_Remove = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.RemoveDocumentReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveDocumentReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/Remove',
      request,
      metadata,
      methodInfo_DocumentSvc_Remove,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveDocumentReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.remove =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.remove(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.DocumentDraftReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_Draft = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.DocumentDraftReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.DocumentDraftReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.draft =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/Draft',
      request,
      metadata,
      methodInfo_DocumentSvc_Draft,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.DocumentDraftReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.draft =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.draft(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.DocumentArchiveReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_Archive = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.DocumentArchiveReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.DocumentArchiveReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.archive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/Archive',
      request,
      metadata,
      methodInfo_DocumentSvc_Archive,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.DocumentArchiveReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.archive =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.archive(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.DocumentStartReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_Start = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.DocumentStartReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.DocumentStartReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.start =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/Start',
      request,
      metadata,
      methodInfo_DocumentSvc_Start,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.DocumentStartReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.start =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.start(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.UpdateDocumentNameReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_UpdateName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.UpdateDocumentNameReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.UpdateDocumentNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.updateName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/UpdateName',
      request,
      metadata,
      methodInfo_DocumentSvc_UpdateName,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.UpdateDocumentNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.updateName =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.updateName(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.UpdateDocumentRequisiteReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_UpdateRequisite = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.UpdateDocumentRequisiteReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.UpdateDocumentRequisiteReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.updateRequisite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/UpdateRequisite',
      request,
      metadata,
      methodInfo_DocumentSvc_UpdateRequisite,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.UpdateDocumentRequisiteReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.updateRequisite =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.updateRequisite(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.UpdateDocumentCommentReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_UpdateComment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.UpdateDocumentCommentReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.UpdateDocumentCommentReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.updateComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/UpdateComment',
      request,
      metadata,
      methodInfo_DocumentSvc_UpdateComment,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.UpdateDocumentCommentReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.updateComment =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.updateComment(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.DocumentPutMessageReq,
 *   !proto.api.themakers.sc.DocumentEvent>}
 */
const methodInfo_DocumentSvc_PutMessage = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.DocumentEvent,
  /** @param {!proto.api.themakers.sc.DocumentPutMessageReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.DocumentEvent.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.DocumentPutMessageReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.DocumentEvent)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.DocumentEvent>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.putMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/PutMessage',
      request,
      metadata,
      methodInfo_DocumentSvc_PutMessage,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.DocumentPutMessageReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.DocumentEvent>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.putMessage =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.putMessage(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.DocumentRegisterReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_Register = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.DocumentRegisterReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.DocumentRegisterReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/Register',
      request,
      metadata,
      methodInfo_DocumentSvc_Register,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.DocumentRegisterReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.register =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.register(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetLinksReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_SetLinks = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.SetLinksReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetLinksReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.setLinks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/SetLinks',
      request,
      metadata,
      methodInfo_DocumentSvc_SetLinks,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetLinksReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.setLinks =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setLinks(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.AddWorkflowTaskReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_AddWorkflowTask = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.AddWorkflowTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.AddWorkflowTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.addWorkflowTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/AddWorkflowTask',
      request,
      metadata,
      methodInfo_DocumentSvc_AddWorkflowTask,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.AddWorkflowTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.addWorkflowTask =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.addWorkflowTask(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.AddActorToWorkflowApprovalReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_AddActorToWorkflowApproval = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.AddActorToWorkflowApprovalReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.AddActorToWorkflowApprovalReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.addActorToWorkflowApproval =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/AddActorToWorkflowApproval',
      request,
      metadata,
      methodInfo_DocumentSvc_AddActorToWorkflowApproval,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.AddActorToWorkflowApprovalReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.addActorToWorkflowApproval =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.addActorToWorkflowApproval(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveWorkflowTaskReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_DocumentSvc_RemoveWorkflowTask = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.RemoveWorkflowTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveWorkflowTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcClient.prototype.removeWorkflowTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DocumentSvc/RemoveWorkflowTask',
      request,
      metadata,
      methodInfo_DocumentSvc_RemoveWorkflowTask,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveWorkflowTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DocumentSvcPromiseClient.prototype.removeWorkflowTask =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.removeWorkflowTask(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.WorkflowSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.WorkflowSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.WorkflowSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.WorkflowSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.Workflow_Approval_AcceptReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_WorkflowSvc_Approval_Accept = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.Workflow_Approval_AcceptReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.Workflow_Approval_AcceptReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcClient.prototype.approval_Accept =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.WorkflowSvc/Approval_Accept',
      request,
      metadata,
      methodInfo_WorkflowSvc_Approval_Accept,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.Workflow_Approval_AcceptReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcPromiseClient.prototype.approval_Accept =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.approval_Accept(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.Workflow_Approval_DropToReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_WorkflowSvc_Approval_DropTo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.Workflow_Approval_DropToReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.Workflow_Approval_DropToReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcClient.prototype.approval_DropTo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.WorkflowSvc/Approval_DropTo',
      request,
      metadata,
      methodInfo_WorkflowSvc_Approval_DropTo,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.Workflow_Approval_DropToReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcPromiseClient.prototype.approval_DropTo =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.approval_DropTo(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.Workflow_Task_CompleteReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_WorkflowSvc_Task_Complete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.Workflow_Task_CompleteReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.Workflow_Task_CompleteReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcClient.prototype.task_Complete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.WorkflowSvc/Task_Complete',
      request,
      metadata,
      methodInfo_WorkflowSvc_Task_Complete,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.Workflow_Task_CompleteReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcPromiseClient.prototype.task_Complete =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.task_Complete(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.Workflow_Task_ConfirmReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_WorkflowSvc_Task_Confirm = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.Workflow_Task_ConfirmReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.Workflow_Task_ConfirmReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcClient.prototype.task_Confirm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.WorkflowSvc/Task_Confirm',
      request,
      metadata,
      methodInfo_WorkflowSvc_Task_Confirm,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.Workflow_Task_ConfirmReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcPromiseClient.prototype.task_Confirm =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.task_Confirm(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.Workflow_Task_RejectReq,
 *   !proto.api.themakers.sc.Document>}
 */
const methodInfo_WorkflowSvc_Task_Reject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Document,
  /** @param {!proto.api.themakers.sc.Workflow_Task_RejectReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Document.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.Workflow_Task_RejectReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Document)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Document>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcClient.prototype.task_Reject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.WorkflowSvc/Task_Reject',
      request,
      metadata,
      methodInfo_WorkflowSvc_Task_Reject,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.Workflow_Task_RejectReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Document>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcPromiseClient.prototype.task_Reject =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.task_Reject(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetWorkflowTemplateReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_WorkflowSvc_SetWorkflowTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.SetWorkflowTemplateReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetWorkflowTemplateReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcClient.prototype.setWorkflowTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.WorkflowSvc/SetWorkflowTemplate',
      request,
      metadata,
      methodInfo_WorkflowSvc_SetWorkflowTemplate,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetWorkflowTemplateReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcPromiseClient.prototype.setWorkflowTemplate =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setWorkflowTemplate(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.GetWorkflowTemplateReq,
 *   !proto.api.themakers.sc.WorkflowTemplate>}
 */
const methodInfo_WorkflowSvc_GetWorkflowTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.WorkflowTemplate,
  /** @param {!proto.api.themakers.sc.GetWorkflowTemplateReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.WorkflowTemplate.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.GetWorkflowTemplateReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.WorkflowTemplate)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.WorkflowTemplate>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcClient.prototype.getWorkflowTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.WorkflowSvc/GetWorkflowTemplate',
      request,
      metadata,
      methodInfo_WorkflowSvc_GetWorkflowTemplate,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.GetWorkflowTemplateReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.WorkflowTemplate>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.WorkflowSvcPromiseClient.prototype.getWorkflowTemplate =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.getWorkflowTemplate(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.TaskSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.TaskSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.TaskSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.TaskSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.ArchivedTasksStreamReq,
 *   !proto.api.themakers.sc.ArchivedTasksStreamMessage>}
 */
const methodInfo_TaskSvc_ArchivedTasksStream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.ArchivedTasksStreamMessage,
  /** @param {!proto.api.themakers.sc.ArchivedTasksStreamReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.ArchivedTasksStreamMessage.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.ArchivedTasksStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.ArchivedTasksStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.archivedTasksStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.TaskSvc/ArchivedTasksStream',
      request,
      metadata,
      methodInfo_TaskSvc_ArchivedTasksStream);
};


/**
 * @param {!proto.api.themakers.sc.ArchivedTasksStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.ArchivedTasksStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.archivedTasksStream =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.TaskSvc/ArchivedTasksStream',
      request,
      metadata,
      methodInfo_TaskSvc_ArchivedTasksStream);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.CreateTaskReq,
 *   !proto.api.themakers.sc.Task>}
 */
const methodInfo_TaskSvc_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Task,
  /** @param {!proto.api.themakers.sc.CreateTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Task.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.CreateTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/Create',
      request,
      metadata,
      methodInfo_TaskSvc_Create,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.CreateTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Task>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.create =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.create(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.CompleteTaskReq,
 *   !proto.api.themakers.sc.Task>}
 */
const methodInfo_TaskSvc_Complete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Task,
  /** @param {!proto.api.themakers.sc.CompleteTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Task.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.CompleteTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.complete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/Complete',
      request,
      metadata,
      methodInfo_TaskSvc_Complete,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.CompleteTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Task>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.complete =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.complete(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RejectTaskReq,
 *   !proto.api.themakers.sc.Task>}
 */
const methodInfo_TaskSvc_Reject = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Task,
  /** @param {!proto.api.themakers.sc.RejectTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Task.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RejectTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.reject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/Reject',
      request,
      metadata,
      methodInfo_TaskSvc_Reject,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RejectTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Task>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.reject =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.reject(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.ConfirmTaskReq,
 *   !proto.api.themakers.sc.Task>}
 */
const methodInfo_TaskSvc_Confirm = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Task,
  /** @param {!proto.api.themakers.sc.ConfirmTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Task.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.ConfirmTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.confirm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/Confirm',
      request,
      metadata,
      methodInfo_TaskSvc_Confirm,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.ConfirmTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Task>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.confirm =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.confirm(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.ReopenReq,
 *   !proto.api.themakers.sc.Task>}
 */
const methodInfo_TaskSvc_Reopen = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Task,
  /** @param {!proto.api.themakers.sc.ReopenReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Task.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.ReopenReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.reopen =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/Reopen',
      request,
      metadata,
      methodInfo_TaskSvc_Reopen,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.ReopenReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Task>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.reopen =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.reopen(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.CloseTaskReq,
 *   !proto.api.themakers.sc.Task>}
 */
const methodInfo_TaskSvc_Close = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Task,
  /** @param {!proto.api.themakers.sc.CloseTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Task.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.CloseTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.close =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/Close',
      request,
      metadata,
      methodInfo_TaskSvc_Close,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.CloseTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Task>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.close =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.close(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveTaskReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_TaskSvc_Remove = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.RemoveTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/Remove',
      request,
      metadata,
      methodInfo_TaskSvc_Remove,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.remove =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.remove(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.DelegateTaskReq,
 *   !proto.api.themakers.sc.Task>}
 */
const methodInfo_TaskSvc_Delegate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Task,
  /** @param {!proto.api.themakers.sc.DelegateTaskReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Task.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.DelegateTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.delegate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/Delegate',
      request,
      metadata,
      methodInfo_TaskSvc_Delegate,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.DelegateTaskReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Task>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.delegate =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.delegate(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.TaskStreamReq,
 *   !proto.api.themakers.sc.TaskStreamMessage>}
 */
const methodInfo_TaskSvc_TaskStream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.TaskStreamMessage,
  /** @param {!proto.api.themakers.sc.TaskStreamReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.TaskStreamMessage.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.TaskStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.TaskStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.taskStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.TaskSvc/TaskStream',
      request,
      metadata,
      methodInfo_TaskSvc_TaskStream);
};


/**
 * @param {!proto.api.themakers.sc.TaskStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.TaskStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.taskStream =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.TaskSvc/TaskStream',
      request,
      metadata,
      methodInfo_TaskSvc_TaskStream);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.TaskPutMessageReq,
 *   !proto.api.themakers.sc.TaskEvent>}
 */
const methodInfo_TaskSvc_PutMessage = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.TaskEvent,
  /** @param {!proto.api.themakers.sc.TaskPutMessageReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.TaskEvent.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.TaskPutMessageReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.TaskEvent)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.TaskEvent>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcClient.prototype.putMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.TaskSvc/PutMessage',
      request,
      metadata,
      methodInfo_TaskSvc_PutMessage,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.TaskPutMessageReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.TaskEvent>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.TaskSvcPromiseClient.prototype.putMessage =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.putMessage(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.UnitSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.UnitSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.UnitSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.UnitSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.CreateUnitReq,
 *   !proto.api.themakers.sc.Unit>}
 */
const methodInfo_UnitSvc_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Unit,
  /** @param {!proto.api.themakers.sc.CreateUnitReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Unit.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.CreateUnitReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Unit)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Unit>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UnitSvc/Create',
      request,
      metadata,
      methodInfo_UnitSvc_Create,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.CreateUnitReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Unit>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcPromiseClient.prototype.create =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.create(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveUnitReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_UnitSvc_Remove = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.RemoveUnitReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveUnitReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UnitSvc/Remove',
      request,
      metadata,
      methodInfo_UnitSvc_Remove,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveUnitReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcPromiseClient.prototype.remove =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.remove(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.MoveUnitReq,
 *   !proto.api.themakers.sc.Unit>}
 */
const methodInfo_UnitSvc_Move = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Unit,
  /** @param {!proto.api.themakers.sc.MoveUnitReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Unit.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.MoveUnitReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Unit)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Unit>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcClient.prototype.move =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UnitSvc/Move',
      request,
      metadata,
      methodInfo_UnitSvc_Move,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.MoveUnitReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Unit>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcPromiseClient.prototype.move =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.move(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.MarkAsRootReq,
 *   !proto.api.themakers.sc.Unit>}
 */
const methodInfo_UnitSvc_MarkAsRoot = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Unit,
  /** @param {!proto.api.themakers.sc.MarkAsRootReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Unit.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.MarkAsRootReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Unit)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Unit>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcClient.prototype.markAsRoot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UnitSvc/MarkAsRoot',
      request,
      metadata,
      methodInfo_UnitSvc_MarkAsRoot,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.MarkAsRootReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Unit>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcPromiseClient.prototype.markAsRoot =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.markAsRoot(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetNameReq,
 *   !proto.api.themakers.sc.Unit>}
 */
const methodInfo_UnitSvc_SetName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Unit,
  /** @param {!proto.api.themakers.sc.SetNameReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Unit.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Unit)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Unit>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcClient.prototype.setName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UnitSvc/SetName',
      request,
      metadata,
      methodInfo_UnitSvc_SetName,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Unit>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcPromiseClient.prototype.setName =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setName(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetCodeReq,
 *   !proto.api.themakers.sc.Unit>}
 */
const methodInfo_UnitSvc_SetCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Unit,
  /** @param {!proto.api.themakers.sc.SetCodeReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Unit.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetCodeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Unit)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Unit>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcClient.prototype.setCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UnitSvc/SetCode',
      request,
      metadata,
      methodInfo_UnitSvc_SetCode,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetCodeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Unit>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcPromiseClient.prototype.setCode =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setCode(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.GetTosserReq,
 *   !proto.api.themakers.sc.UnitTosser>}
 */
const methodInfo_UnitSvc_GetTosser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.UnitTosser,
  /** @param {!proto.api.themakers.sc.GetTosserReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.UnitTosser.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.GetTosserReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.UnitTosser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.UnitTosser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcClient.prototype.getTosser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UnitSvc/GetTosser',
      request,
      metadata,
      methodInfo_UnitSvc_GetTosser,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.GetTosserReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.UnitTosser>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcPromiseClient.prototype.getTosser =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.getTosser(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetTosserReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_UnitSvc_SetTosser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.SetTosserReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetTosserReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcClient.prototype.setTosser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.UnitSvc/SetTosser',
      request,
      metadata,
      methodInfo_UnitSvc_SetTosser,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetTosserReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.UnitSvcPromiseClient.prototype.setTosser =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setTosser(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.FamiliarizationSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.FamiliarizationSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.FamiliarizationSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.FamiliarizationStreamReq,
 *   !proto.api.themakers.sc.FamiliarizationStreamMessage>}
 */
const methodInfo_FamiliarizationSvc_Stream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.FamiliarizationStreamMessage,
  /** @param {!proto.api.themakers.sc.FamiliarizationStreamReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.FamiliarizationStreamMessage.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.FamiliarizationStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.FamiliarizationStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.stream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Stream',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Stream);
};


/**
 * @param {!proto.api.themakers.sc.FamiliarizationStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.FamiliarizationStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.stream =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Stream',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Stream);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq,
 *   !proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage>}
 */
const methodInfo_FamiliarizationSvc_ArchivedListStream = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage,
  /** @param {!proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.archivedListStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/ArchivedListStream',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_ArchivedListStream);
};


/**
 * @param {!proto.api.themakers.sc.ArchivedListFamiliarizationStreamReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.ArchivedFamiliarizationStreamMessage>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.archivedListStream =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/ArchivedListStream',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_ArchivedListStream);
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.CreateFamiliarizationReq,
 *   !proto.api.themakers.sc.Familiarization>}
 */
const methodInfo_FamiliarizationSvc_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Familiarization,
  /** @param {!proto.api.themakers.sc.CreateFamiliarizationReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Familiarization.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.CreateFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Familiarization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Familiarization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Create',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Create,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.CreateFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Familiarization>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.create =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.create(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.UpdateFamiliarizationReq,
 *   !proto.api.themakers.sc.Familiarization>}
 */
const methodInfo_FamiliarizationSvc_Update = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Familiarization,
  /** @param {!proto.api.themakers.sc.UpdateFamiliarizationReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Familiarization.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.UpdateFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Familiarization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Familiarization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Update',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Update,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.UpdateFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Familiarization>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.update =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.update(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.AddMembersReq,
 *   !proto.api.themakers.sc.Familiarization>}
 */
const methodInfo_FamiliarizationSvc_AddMembers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Familiarization,
  /** @param {!proto.api.themakers.sc.AddMembersReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Familiarization.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.AddMembersReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Familiarization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Familiarization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.addMembers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/AddMembers',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_AddMembers,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.AddMembersReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Familiarization>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.addMembers =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.addMembers(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.FamiliarizeReq,
 *   !proto.api.themakers.sc.Familiarization>}
 */
const methodInfo_FamiliarizationSvc_Familiarize = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Familiarization,
  /** @param {!proto.api.themakers.sc.FamiliarizeReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Familiarization.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.FamiliarizeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Familiarization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Familiarization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.familiarize =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Familiarize',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Familiarize,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.FamiliarizeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Familiarization>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.familiarize =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.familiarize(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveFamiliarizationReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_FamiliarizationSvc_Remove = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.RemoveFamiliarizationReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Remove',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Remove,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.remove =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.remove(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.ActiveFamiliarizationReq,
 *   !proto.api.themakers.sc.Familiarization>}
 */
const methodInfo_FamiliarizationSvc_Active = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Familiarization,
  /** @param {!proto.api.themakers.sc.ActiveFamiliarizationReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Familiarization.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.ActiveFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Familiarization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Familiarization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.active =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Active',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Active,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.ActiveFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Familiarization>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.active =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.active(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.DraftFamiliarizationReq,
 *   !proto.api.themakers.sc.Familiarization>}
 */
const methodInfo_FamiliarizationSvc_Draft = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Familiarization,
  /** @param {!proto.api.themakers.sc.DraftFamiliarizationReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Familiarization.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.DraftFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Familiarization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Familiarization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.draft =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Draft',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Draft,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.DraftFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Familiarization>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.draft =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.draft(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.ArchiveFamiliarizationReq,
 *   !proto.api.themakers.sc.Familiarization>}
 */
const methodInfo_FamiliarizationSvc_Archive = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Familiarization,
  /** @param {!proto.api.themakers.sc.ArchiveFamiliarizationReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Familiarization.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.ArchiveFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Familiarization)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Familiarization>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcClient.prototype.archive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.FamiliarizationSvc/Archive',
      request,
      metadata,
      methodInfo_FamiliarizationSvc_Archive,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.ArchiveFamiliarizationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Familiarization>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.FamiliarizationSvcPromiseClient.prototype.archive =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.archive(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.DirectoriesSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.DirectoriesSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.DirectoriesSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.CreateDirectoryReq,
 *   !proto.api.themakers.sc.Directory>}
 */
const methodInfo_DirectoriesSvc_CreateDirectory = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Directory,
  /** @param {!proto.api.themakers.sc.CreateDirectoryReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Directory.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.CreateDirectoryReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Directory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Directory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.createDirectory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/CreateDirectory',
      request,
      metadata,
      methodInfo_DirectoriesSvc_CreateDirectory,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.CreateDirectoryReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Directory>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.createDirectory =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.createDirectory(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveDirectoryReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_DirectoriesSvc_RemoveDirectory = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.RemoveDirectoryReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveDirectoryReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.removeDirectory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/RemoveDirectory',
      request,
      metadata,
      methodInfo_DirectoriesSvc_RemoveDirectory,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveDirectoryReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.removeDirectory =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.removeDirectory(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetDirectoryNameReq,
 *   !proto.api.themakers.sc.Directory>}
 */
const methodInfo_DirectoriesSvc_SetDirectoryName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Directory,
  /** @param {!proto.api.themakers.sc.SetDirectoryNameReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Directory.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetDirectoryNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Directory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Directory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.setDirectoryName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/SetDirectoryName',
      request,
      metadata,
      methodInfo_DirectoriesSvc_SetDirectoryName,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetDirectoryNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Directory>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.setDirectoryName =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setDirectoryName(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetDirectoryDisplayNameReq,
 *   !proto.api.themakers.sc.Directory>}
 */
const methodInfo_DirectoriesSvc_SetDirectoryDisplayName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Directory,
  /** @param {!proto.api.themakers.sc.SetDirectoryDisplayNameReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Directory.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetDirectoryDisplayNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Directory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Directory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.setDirectoryDisplayName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/SetDirectoryDisplayName',
      request,
      metadata,
      methodInfo_DirectoriesSvc_SetDirectoryDisplayName,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetDirectoryDisplayNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Directory>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.setDirectoryDisplayName =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setDirectoryDisplayName(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetDirectoryArchivedReq,
 *   !proto.api.themakers.sc.Directory>}
 */
const methodInfo_DirectoriesSvc_SetDirectoryArchived = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Directory,
  /** @param {!proto.api.themakers.sc.SetDirectoryArchivedReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Directory.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetDirectoryArchivedReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Directory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Directory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.setDirectoryArchived =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/SetDirectoryArchived',
      request,
      metadata,
      methodInfo_DirectoriesSvc_SetDirectoryArchived,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetDirectoryArchivedReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Directory>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.setDirectoryArchived =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setDirectoryArchived(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.AddDirectoryFieldReq,
 *   !proto.api.themakers.sc.Directory>}
 */
const methodInfo_DirectoriesSvc_AddDirectoryField = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Directory,
  /** @param {!proto.api.themakers.sc.AddDirectoryFieldReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Directory.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.AddDirectoryFieldReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Directory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Directory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.addDirectoryField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/AddDirectoryField',
      request,
      metadata,
      methodInfo_DirectoriesSvc_AddDirectoryField,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.AddDirectoryFieldReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Directory>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.addDirectoryField =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.addDirectoryField(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveDirectoryFieldReq,
 *   !proto.api.themakers.sc.Directory>}
 */
const methodInfo_DirectoriesSvc_RemoveDirectoryField = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Directory,
  /** @param {!proto.api.themakers.sc.RemoveDirectoryFieldReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Directory.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveDirectoryFieldReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Directory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Directory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.removeDirectoryField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/RemoveDirectoryField',
      request,
      metadata,
      methodInfo_DirectoriesSvc_RemoveDirectoryField,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveDirectoryFieldReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Directory>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.removeDirectoryField =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.removeDirectoryField(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetDirectoryFieldNameReq,
 *   !proto.api.themakers.sc.Directory>}
 */
const methodInfo_DirectoriesSvc_SetDirectoryFieldName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Directory,
  /** @param {!proto.api.themakers.sc.SetDirectoryFieldNameReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Directory.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetDirectoryFieldNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Directory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Directory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.setDirectoryFieldName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/SetDirectoryFieldName',
      request,
      metadata,
      methodInfo_DirectoriesSvc_SetDirectoryFieldName,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetDirectoryFieldNameReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Directory>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.setDirectoryFieldName =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setDirectoryFieldName(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetDirectoryFieldRequiredReq,
 *   !proto.api.themakers.sc.Directory>}
 */
const methodInfo_DirectoriesSvc_SetDirectoryFieldRequired = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Directory,
  /** @param {!proto.api.themakers.sc.SetDirectoryFieldRequiredReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Directory.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetDirectoryFieldRequiredReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Directory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Directory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.setDirectoryFieldRequired =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/SetDirectoryFieldRequired',
      request,
      metadata,
      methodInfo_DirectoriesSvc_SetDirectoryFieldRequired,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetDirectoryFieldRequiredReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Directory>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.setDirectoryFieldRequired =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setDirectoryFieldRequired(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.AddRecordReq,
 *   !proto.api.themakers.sc.Record>}
 */
const methodInfo_DirectoriesSvc_AddRecord = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Record,
  /** @param {!proto.api.themakers.sc.AddRecordReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Record.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.AddRecordReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Record)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Record>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.addRecord =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/AddRecord',
      request,
      metadata,
      methodInfo_DirectoriesSvc_AddRecord,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.AddRecordReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Record>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.addRecord =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.addRecord(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveRecordReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_DirectoriesSvc_RemoveRecord = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.RemoveRecordReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveRecordReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.removeRecord =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/RemoveRecord',
      request,
      metadata,
      methodInfo_DirectoriesSvc_RemoveRecord,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveRecordReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.removeRecord =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.removeRecord(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetRecordArchivedReq,
 *   !proto.api.themakers.sc.Record>}
 */
const methodInfo_DirectoriesSvc_SetRecordArchived = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Record,
  /** @param {!proto.api.themakers.sc.SetRecordArchivedReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Record.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetRecordArchivedReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Record)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Record>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.setRecordArchived =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/SetRecordArchived',
      request,
      metadata,
      methodInfo_DirectoriesSvc_SetRecordArchived,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetRecordArchivedReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Record>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.setRecordArchived =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.setRecordArchived(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.UpdateRecordFieldsReq,
 *   !proto.api.themakers.sc.Record>}
 */
const methodInfo_DirectoriesSvc_UpdateRecordFields = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Record,
  /** @param {!proto.api.themakers.sc.UpdateRecordFieldsReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Record.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.UpdateRecordFieldsReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Record)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Record>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcClient.prototype.updateRecordFields =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.DirectoriesSvc/UpdateRecordFields',
      request,
      metadata,
      methodInfo_DirectoriesSvc_UpdateRecordFields,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.UpdateRecordFieldsReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Record>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.DirectoriesSvcPromiseClient.prototype.updateRecordFields =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.updateRecordFields(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.OneAssClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.OneAssPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.OneAssClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.OneAssClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.OneAssQueryAndWatchReq,
 *   !proto.api.themakers.sc.OneAssQueryAndWatchMsg>}
 */
const methodInfo_OneAss_QueryAndWatch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.OneAssQueryAndWatchMsg,
  /** @param {!proto.api.themakers.sc.OneAssQueryAndWatchReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.OneAssQueryAndWatchMsg.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.OneAssQueryAndWatchReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.OneAssQueryAndWatchMsg>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.OneAssClient.prototype.queryAndWatch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.themakers.sc.OneAss/QueryAndWatch',
      request,
      metadata,
      methodInfo_OneAss_QueryAndWatch);
};


/**
 * @param {!proto.api.themakers.sc.OneAssQueryAndWatchReq} request The request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.OneAssQueryAndWatchMsg>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.OneAssPromiseClient.prototype.queryAndWatch =
    function(request, metadata) {
  return this.delegateClient_.client_.serverStreaming(this.delegateClient_.hostname_ +
      '/api.themakers.sc.OneAss/QueryAndWatch',
      request,
      metadata,
      methodInfo_OneAss_QueryAndWatch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.BackDoorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.BackDoorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.BackDoorClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.BackDoorClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.LoginAsReq,
 *   !proto.api.themakers.sc.LoginAsResp>}
 */
const methodInfo_BackDoor_LoginAs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.LoginAsResp,
  /** @param {!proto.api.themakers.sc.LoginAsReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.LoginAsResp.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.LoginAsReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.LoginAsResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.LoginAsResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.BackDoorClient.prototype.loginAs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.BackDoor/LoginAs',
      request,
      metadata,
      methodInfo_BackDoor_LoginAs,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.LoginAsReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.LoginAsResp>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.BackDoorPromiseClient.prototype.loginAs =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.loginAs(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.NumeratorSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.sc.NumeratorSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.sc.NumeratorSvcClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.sc.NumeratorSvcClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.CreateNumeratorReq,
 *   !proto.api.themakers.sc.Numerator>}
 */
const methodInfo_NumeratorSvc_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Numerator,
  /** @param {!proto.api.themakers.sc.CreateNumeratorReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Numerator.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.CreateNumeratorReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Numerator)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Numerator>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.NumeratorSvcClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.NumeratorSvc/Create',
      request,
      metadata,
      methodInfo_NumeratorSvc_Create,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.CreateNumeratorReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Numerator>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.NumeratorSvcPromiseClient.prototype.create =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.create(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.GetNumeratorReq,
 *   !proto.api.themakers.sc.Numerator>}
 */
const methodInfo_NumeratorSvc_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Numerator,
  /** @param {!proto.api.themakers.sc.GetNumeratorReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Numerator.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.GetNumeratorReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Numerator)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Numerator>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.NumeratorSvcClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.NumeratorSvc/Get',
      request,
      metadata,
      methodInfo_NumeratorSvc_Get,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.GetNumeratorReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Numerator>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.NumeratorSvcPromiseClient.prototype.get =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.get(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.RemoveNumeratorReq,
 *   !proto.api.themakers.sc.Dummy>}
 */
const methodInfo_NumeratorSvc_Remove = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Dummy,
  /** @param {!proto.api.themakers.sc.RemoveNumeratorReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Dummy.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.RemoveNumeratorReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Dummy)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Dummy>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.NumeratorSvcClient.prototype.remove =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.NumeratorSvc/Remove',
      request,
      metadata,
      methodInfo_NumeratorSvc_Remove,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.RemoveNumeratorReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Dummy>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.NumeratorSvcPromiseClient.prototype.remove =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.remove(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.sc.SetNumeratorReq,
 *   !proto.api.themakers.sc.Numerator>}
 */
const methodInfo_NumeratorSvc_Set = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.sc.Numerator,
  /** @param {!proto.api.themakers.sc.SetNumeratorReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.sc.Numerator.deserializeBinary
);


/**
 * @param {!proto.api.themakers.sc.SetNumeratorReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.sc.Numerator)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.sc.Numerator>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.NumeratorSvcClient.prototype.set =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.sc.NumeratorSvc/Set',
      request,
      metadata,
      methodInfo_NumeratorSvc_Set,
      callback);
};


/**
 * @param {!proto.api.themakers.sc.SetNumeratorReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.sc.Numerator>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.sc.NumeratorSvcPromiseClient.prototype.set =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.set(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


module.exports = proto.api.themakers.sc;

