import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { MeState } from '@/store/me/meState'

import api1 from 'api1'
import { service, identityPB } from 'api'
import { AuthenticationStatus, IdentitiesAndVerifiersList } from '@/model/Authentication'

import _actions_callStartAttach from './callStartAttach'

const AuthenticationActions: ActionTree<MeState, RootState> = {
    async callCheckStatus({ dispatch }) {
        const status: identityPB.Status = await service.identity.CheckStatus({})
        console.log('::DATA::Identity.checkStatus:', status)
        return dispatch('setStatus', new AuthenticationStatus(status))
    },

    async callStartSignIn({ dispatch }) {
        const data: identityPB.Status = await service.identity.StartSignIn({})
        console.log('::DATA::Identity.startSignIn:', data)
        return dispatch('setStatus', new AuthenticationStatus(data))
    },

    async callStartSignUp({ dispatch }) {
        const data: identityPB.Status = await service.identity.StartSignUp({})
        console.log('::DATA::Identity.startSignUp:', data)
        return dispatch('setStatus', new AuthenticationStatus(data))
    },

    async callCancelAuthentication({ dispatch }) {
        const data: identityPB.Status = await service.identity.CancelAuthentication({})
        console.log('::DATA::Identity.cancelAuthentication:', data)
        return dispatch('setStatus', new AuthenticationStatus(data))
    },

    async callStart(ctx, params: identityPB.StartReq ) {
        return service.identity.Start(params)
    },

    async callVerify({ dispatch }, params: identityPB.VerifyReq) {
        const data: identityPB.Status = await service.identity.Verify(params)
        console.log('::DATA::Identity.verify:', data)
        return dispatch('setStatus', new AuthenticationStatus(data))
    },

    async callListMyIdentitiesAndVerifiers({ dispatch }) {
        const data: identityPB.ListMyIdentitiesAndVerifiersResp = await service.identity.ListMyIdentitiesAndVerifiers({})
        console.log('::DATA::Identity.listMyIdentitiesAndVerifiers:', data)

        dispatch('putListMyIdentitiesAndVerifiers', new IdentitiesAndVerifiersList(data))
    },

    callStartAttach: _actions_callStartAttach,

    putListMyIdentitiesAndVerifiers({ commit }, listMyIdentitiesAndVerifiers: IdentitiesAndVerifiersList) {
        commit('putListMyIdentitiesAndVerifiers', listMyIdentitiesAndVerifiers)
    },

    async logOut({ commit, dispatch }) {
        const data: identityPB.Status = await service.identity.Logout({ AllSessions: true })
        console.log('::DATA::Identity.logout:', data)

        api1._setSessionToken('')
        localStorage.removeItem('authorization')
        commit('setMe', null)
        commit('setEventId', '')

        dispatch('cleanUpStates', null, { root: true })
        return dispatch('setStatus', new AuthenticationStatus(data))
    }
}

export default AuthenticationActions
