













import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { TaskTemplate } from '@/model/Workflow'

import Button from '@/components/common/buttons/Button.vue'

@Component({
    components: { Button }
})

export default class WFTActionDeleteTask extends Vue {
    // *************************************************************
    // COMPUTED
    @Getter('workflow/getActiveTask') getActiveTask!: TaskTemplate | null

    // *************************************************************
    // METHODS
    @Action('workflow/removeWFTask')
    removeWFTask!: () => void

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    onRemoveTask(): void {
        this.removeWFTask()
        this.changeWFAction('')
        // Отмечаем наличие изменений в workFlow
        this.workflowChange(true)
    }
}
