









































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import Tabs from '@/components/common/tabs/Tabs.vue'
import TaskCardSmall from '@/components/tasks/cards/TaskCardSmall.vue'
import TaskModal from '@/components/tasks/TaskModal.vue'

import { Tab } from '@/components/common/tabs/model'
import { User } from '@/model/User'
import { Task } from '@/model/Task'

const tabs: Array<Tab> = [
    { id: 'incoming', name: 'Входящие' },
    { id: 'outgoing', name: 'Исходящие' }
]

@Component({
    components: {
        Tabs,
        TaskCardSmall,
        TaskModal
    }
})

export default class UserTasks extends Vue {
    @Ref() readonly modal!: TaskModal

    // *************************************************************
    // DATA PARAMS
    tabs = tabs
    tab = 'incoming'

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    @Getter('task/getByUser') getByUser!: (userId: string) => Array<Task>
    @Getter('task/getByAuthor') getByAuthor!: (userId: string) => Array<Task>
    @Getter('task/getNotImplemented') getNotImplemented!: Array<Task>

    get tasks(): Array<Task> {
        if (this.tab === 'incoming') {
            // Если открыта вкладка 'входящие' - выводим все задачи, выставленные на этого пользователя другими
            return this.getNotImplemented.filter((t: Task) => t.assignee.user?.id === this.getMe.id || (t.confirmer && t.confirmer.user?.id === this.getMe.id))
        } else {
            // Иначе возвращаем все исходящие задачи
            return this.getNotImplemented.filter((t: Task) => t.author.user?.id === this.getMe.id)
        }
    }

    get tasksByAuthor(): Array<Task> {
        return this.getNotImplemented.filter((t: Task) => t.author.user?.id === this.getMe.id)
    }

    get tasksByAssignee(): Array<Task> {
        return this.getNotImplemented.filter((t: Task) => t.assignee.user?.id === this.getMe.id && !t.completed)
    }

    get tasksByConfirmer(): Array<Task> {
        return this.getNotImplemented.filter((t: Task) => t.completed && t.confirmer && t.confirmer.user?.id === this.getMe.id)
    }

    // *************************************************************
    // METHODS
    openTask(taskId: string): void {
        this.modal.show(taskId)
    }

    openTasks(tab: string): void {
        this.$router.push({
            name: 'module',
            params: {
                section: 'system',
                module: 'tasks'
            },
            query: {
                type: tab
            }
        })
    }
}
