







































import { Component, Vue } from 'vue-property-decorator'

import Modal from '@/components/common/modals/Modal.vue'

@Component<TableModal>({
    components: { Modal }
})

export default class TableModal extends Vue {
    // *************************************************************
    // DATA PARAMS
    headlines: Array<string> = []
    body: Array<Array<string>> = []

    visibleModal = false

    // *************************************************************
    // METHODS
    show(value: string): void {
        // Этот метод вызывается из родительского компонента
        // Показываем Modal
        this.visibleModal = true
        // Превращаем строку в объект
        const valueToObject = JSON.parse(value)

        this.headlines = valueToObject.headlines
        this.body = valueToObject.data
    }

    closeModal(): void {
        this.headlines = []
        this.body = []
    }
}
