var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    ref: "modal",
    staticClass: "document-creation",
    attrs: {
      "visible": _vm.syncedVisible,
      "title": _vm.title
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      },
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "document-creation__body",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('SelectRoleUnit', {
    attrs: {
      "roles-list": _vm.rolesList,
      "center-id": _vm.centerId
    },
    on: {
      "oneUnitWithRoles": function oneUnitWithRoles($event) {
        return _vm.$emit('oneUnitWithRoles', $event);
      },
      "changeOriginUnit": function changeOriginUnit($event) {
        _vm.originUnit = $event;
      }
    }
  }), _vm._v(" "), _vm.originUnit !== 'empty' ? _c('FormInput', {
    staticClass: "mb-3",
    attrs: {
      "label": "Название документа",
      "required": ""
    }
  }, [_c('InputBase', {
    attrs: {
      "slot": "form-input-body",
      "required": ""
    },
    slot: "form-input-body",
    model: {
      value: _vm.newDocumentName,
      callback: function callback($$v) {
        _vm.newDocumentName = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "newDocumentName"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "p-4 flex-row-center justify-end",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "disabled": _vm.disabledCreate,
      "color": "green"
    },
    on: {
      "click": _vm.addNewDocument
    }
  }, [_vm._v("\n            Создать\n        ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }