






















































































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { Unit } from '@/model/Unit'

import Checkbox from '@/components/common/inputs/Checkbox.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'
import ButtonClose from '@/components/common/buttons/ButtonClose.vue'
import SelectEmployees from '@/components/company_structure/SelectEmployees.vue'
import SelectUnits from '@/components/company_structure/select_units/SelectUnits.vue'
import RemoveModal from '@/components/common/modals/RemoveModal.vue'

@Component({
    components: {
        Checkbox,
        InputBase,
        SelectEmployees,
        SelectUnits,
        ButtonDelete,
        ButtonClose,
        RemoveModal
    }
})

export default class EditUnit extends Vue {
    @Ref() readonly removeModal!: RemoveModal

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) unitId!: string

    // *************************************************************
    // DATA PARAMS
    name = ''
    code = ''
    root = false
    visibleRemoveModal = false
    visibleUserModal = false
    visibleUnitsModal = false

    loadingName = false
    loadingCode = false
    loadingRoot = false

    // *************************************************************
    // COMPUTED
    @Getter('me/isCenterManager') isCenterManager!: (unitId: string) => boolean

    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>

    get unit(): Unit | null {
        return this.getUnitById(this.unitId)
    }

    get manager(): boolean {
        if (this.unitId === 'root') {
            return false
        }
        const centerId = this.unit?.centerId
        if (!centerId) {
            return false
        }
        return this.isCenterManager(centerId)
    }

    get removeTooltip(): string {
        if (this.unit?.parent === 'root') {
            return 'Нельзя удалять центральное подразделение'
        }

        if (this.getUnits.find((u: Unit) => u.parent === this.unitId)) {
            return 'Есть дочерние подразделения'
        }
        return ''
    }

    // *************************************************************
    // WATCH
    @Watch('unit', { immediate: true, deep: true })
    handleCentersChange(to: Unit | null, from: Unit | null): void {
        if (to) {
            if (to.id !== from?.id) {
                this.name = to.name
                this.code = to.code
                this.root = to.root
            }
        } else {
            this.name = ''
            this.code = ''
            this.root = false
        }
    }

    // *************************************************************
    // METHODS
    @Action('company_structure/callSetUnitName')
    callSetUnitName!: (params: cuntPB.SetNameReq) => Promise<void>

    @Action('company_structure/callMarkAsRoot')
    callMarkAsRoot!: (params: cuntPB.MarkAsRootReq) => Promise<void>

    @Action('company_structure/callSetUnitCode')
    callSetUnitCode!: (params: cuntPB.SetCodeReq) => Promise<void>

    @Action('company_structure/callRemoveUnit')
    callRemoveUnit!: (unitId: string) => Promise<void>

    rename(): void {
        if (this.loadingName) {
            return
        }

        const thisUnit = this.unit
        if (!thisUnit) {
            return
        }
        if (this.name === thisUnit.name) {
            return
        }

        if (!this.name) {
            this.$snotify.warning('Имя подразделения должно быть заполнено')
            return
        }

        this.loadingName = true

        this.callSetUnitName({
            ID: this.unitId,
            Name: this.name
        })
            .then(() => {
                this.$snotify.success(`Имя сохранено: ${this.name}`)
            })
            .catch((error: GrpcError) => {
                this.name = thisUnit.name
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingName = false
            })
    }

    setCode(): void {
        if (this.loadingCode) {
            return
        }

        if (!this.code) {
            this.$snotify.warning('Код подразделения должен быть заполнен')
            return
        }

        const thisUnit = this.unit
        if (!thisUnit) {
            return
        }
        if (this.code === thisUnit.code) {
            return
        }

        this.loadingCode = true

        this.callSetUnitCode({
            ID: this.unitId,
            Code: this.code
        })
            .then(() => {
                this.$snotify.success(`Присвоен код: ${thisUnit.code}`)
            })
            .catch((error: GrpcError) => {
                this.code = thisUnit.code
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingCode = false
            })
    }

    markAsRoot(): void {
        if (this.loadingRoot) {
            return
        }

        const thisUnit = this.unit
        if (!thisUnit) {
            return
        }

        this.loadingRoot = true
        this.callMarkAsRoot({
            ID: this.unitId,
            Root: this.root
        })
            .then(() => {
                this.$snotify.success(thisUnit.root ? 'Подразделение помечено как "Организация"' : 'Снята пометка "Организация"')
            })
            .catch((error: GrpcError) => {
                this.root = thisUnit.root
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingRoot = false
            })
    }

    showRemove(): void {
        if (this.removeTooltip) {
            return
        }

        this.removeModal.show({
            itemName: this.unit?.name ?? '',
            itemType: 'Подразделения',
            warning: '',
            callback: this.remove
        })
    }

    remove(): void {
        const cancelPreloaderRemoveUnit = this.$preloader('remove_unit', 'Удаление подразделения')
        console.log('unitId', this.unitId)

        this.callRemoveUnit(this.unitId)
            .then(() => {
                this.visibleRemoveModal = false
                // Выкидываем событие о закрытии, чтобы обнулить unitId
                this.emitClose()
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderRemoveUnit())
    }

    openUnits(): void {
        this.visibleUnitsModal = true
    }

    openUsers(): void {
        this.visibleUserModal = true
    }

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose(): void {
        // Do nothing
    }

    @Emit('selectUnit')
    emitSelectUnit(unitId: string): string {
        return unitId
    }
}
