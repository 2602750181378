









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component<Link>({})

export default class Link extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) link!: string
}
