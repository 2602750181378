import moment from 'moment/moment'

export class Familiarization {
    constructor(id, centerId, actor, name, description, creationTime, forceNewMembers, files, status, unitIds, until, documents, сheckList, modificationTime) {
        this.id = id
        this.centerId = centerId
        this.actor = actor
        this.name = name
        this.description = description
        this.creationTime = creationTime
        this.forceNewMembers = forceNewMembers // boolean
        this.files = files
        this.status = status
        this.unitIds = unitIds
        this.until = until
        this.documents = documents // repeated FamiliarizationDocument
        this.сheckList = сheckList // repeated FamiliarizationLog
        this.modificationTime = modificationTime
    }

    get creationTimeLocalizedLongString() {
        return moment(this.creationTime / 1000).format('LLLL')
    }
    get creationTimeLocalizedShortString() {
        return moment(this.creationTime / 1000).format('L')
    }
    get untilLocalizedShortString() {
        return moment(this.until).format('DD/MM/YYYY HH:mm')
    }
}

export class FamiliarizationDocument {
    constructor(id, name, type) {
        this.id = id
        this.name = name
        this.type = type
    }
}

export class FamiliarizationLog {
    constructor(user, status, when) {
        this.user = user // UserInfo
        this.status = status // Boolean
        this.when = when
    }
    get creationTimeLocalizedLongString() {
        return moment(this.when / 1000).format('LLLL')
    }
}

////////////////////////////////////////////////////////////////
////                         ///////////////////////////////////
////  FamiliarizationEvent   ///////////////////////////////////
////                         ///////////////////////////////////
////////////////////////////////////////////////////////////////

export class FamiliarizationEvent {
    constructor(id, type, familiarizationId, actor, ts, fields) {
        this.id = id
        this.type = type
        this.familiarizationId = familiarizationId
        this.actor = actor
        this.ts = ts
        this.fields = fields
    }
    get creationTimeLocalizedLongString() {
        return moment(this.ts / 1000).format('LLLL')
    }
    get creationTimeStampString() {
        return moment(this.ts / 1000).format('L') + ' ' + moment(this.ts / 1000).format('LT')
        // return moment(this.ts / 1000).format("L")
    }
}

export class FamiliarizationEventStatusTransition {
    constructor(previous, current) {
        this.previous = previous
        this.current = current
    }
}

export class FamiliarizationEventFamiliarize {
    constructor(actor) {
        this.actor = actor
    }
}

export class FamiliarizationEventNewMemebers {
    constructor(users, units) {
        this.users = users
        this.units = units
    }
}
