<template>
    <EditNumerator />
</template>

<script>
import EditNumerator from '@/components/numerators/EditNumerator'

export default {
    name: 'Numerator',
    components: {
        EditNumerator
    }
}
</script>
