import { DashboardItem } from '@/store/items/model'

const dashItems: Array<DashboardItem> = [
    new DashboardItem('documents', 'Документы', 'Создание новых документов, просмотр документов, согласование. Создание привязанных к документу задач.', 'root', null, null, ''),
    new DashboardItem('system', 'Система', 'Задачи, Справочники, Тоссер, Оргструктура, Типы документов, Отчеты.', 'root', null, null, ''),
    new DashboardItem('human-resources', 'Кадры', 'Замещение, увольнение', 'root', null, null, 'WorkflowManager'),
    new DashboardItem('about', 'О системе', 'Описания системных модулей, новости, правила работы.', 'root', null, null, ''),

    new DashboardItem(
        'me',
        'Я',
        'Информация о пользователе',
        'system',
        'User',
        '',
        ''
    ),
    new DashboardItem(
        'events',
        'Активные документы',
        'Требуют Вашего внимания',
        'system',
        'Events',
        '',
        ''
    ),
    new DashboardItem(
        'tasks',
        'Задачи',
        'Создание, редактирование задач.',
        'system',
        'Tasks',
        'Task',
        ''
    ),
    new DashboardItem(
        'familiarization',
        'Ознакомление',
        '',
        'system',
        'Familiarizations',
        'Familiarization',
        ''
    ),
    new DashboardItem(
        'directories',
        'Справочники системы',
        'Внутренние справочники и справочники внешних систем. Просмотр, создание, редактирование.',
        'system',
        'Directories',
        'Directory',
        'WorkflowManager'
    ),
    new DashboardItem(
        'tosser',
        'Тоссер',
        'Перенапревление согласования документов на своих сотрудников и дочерние подразделения.',
        'system',
        'Tosser',
        '',
        'Head'
    ),
    new DashboardItem(
        'document-types',
        'Типы документов',
        'Создание, редактирование реквизитов и цепочек жизненного цикла.',
        'system',
        'DocumentTypes',
        'DocumentTypePage',
        'WorkflowManager'
    ),
    new DashboardItem(
        'company-structure',
        'Оргструктура',
        'Создание, редактирование подразделений. Управление ролями пользователей.',
        'system',
        'CompanyStructure',
        '',
        ''
    ),
    new DashboardItem(
        'numerators',
        'Нумераторы',
        'Конструктор нумераторов.',
        'system',
        'Numerators',
        'Numerator',
        'WorkflowManager'
    ),
    new DashboardItem(
        'reports',
        'Отчеты',
        'Все виды отчетов.',
        'system',
        'Reports',
        'Report',
        'WorkflowManager'
    ),

    new DashboardItem('replacement', 'Замещение', 'Замещение сотрудников', 'human-resources', 'Replacement', '', 'WorkflowManager'),
    new DashboardItem('dismissal', 'Увольнение', 'Увольнение сотрудников', 'human-resources', 'Dismissal', '', 'WorkflowManager'),

    new DashboardItem('privacy', 'Политика конфиденциальности', '', 'about', 'PrivacyPolicy', '', ''),
    new DashboardItem('news', 'Новости', 'Последние обновления', 'about', 'News', '', ''),
    new DashboardItem('document-list-filter', 'Фильтры документов', 'Правила использования фильтров в таблицах с документами. Ограничение доступа по ролям.', 'about', 'HelpDocumentListFilter', '', ''),
    new DashboardItem('document-creation', 'Создание документа', 'Инструкция по созданию нового документа.', 'about', 'HelpDocumentCreation', '', ''),
    new DashboardItem('work-with-document', 'Работа с документом', 'Инструкция по работе с документом.', 'about', 'HelpToWorkWithDocument', '', ''),
    new DashboardItem('help-tasks', 'Задачи', 'Инструкция по созданию задачи, работы с фильтрами. Ограничение доступа по ролям.', 'about', 'HelpTasks', '', ''),
    new DashboardItem('help-tosser', 'Тоссер', 'Что такое Тоссер и как с ним работать.', 'about', 'HelpTosser', '', 'Head'),
    new DashboardItem('help-company-structure', 'Оргструктура', 'Инструкция по Оргструктуре.', 'about', 'HelpCompanyStructure', '', ''),
    new DashboardItem('help-numerator', 'Нумератор', 'Инструкция по Нумераторам.', 'about', 'HelpNumerator', '', 'WorkflowManager'),
    new DashboardItem('help-dismissal', 'Увольнение', 'Инструкция по Увольнению.', 'about', 'HelpDismissal', '', 'WorkflowManager')
]

export default dashItems
