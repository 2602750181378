<template>
    <div class="report-all-users">
        <Transition
            name="filters"
        >
            <div
                v-if="filters"
                class="report-all-users__filters"
            >
                <InputSearch
                    v-model="searchByName"
                    placeholder="Поиск по имени пользователя"
                    class="report-all-users__field"
                />
                <div class="report-all-users__grid">
                    <Checkbox
                        v-model="withFullName"
                        label="С именем"
                    />

                    <Checkbox
                        v-model="withoutFullName"
                        label="Без имени"
                    />
                </div>

                <InputSearch
                    v-model="searchByRole"
                    placeholder="Поиск по роли"
                    class="report-all-users__field"
                />
                <div class="report-all-users__grid">
                    <Checkbox
                        v-model="withRoles"
                        label="С ролями"
                    />

                    <Checkbox
                        v-model="withoutRoles"
                        label="Без ролей"
                    />
                </div>

                <InputSearch
                    v-model="searchByUnit"
                    placeholder="Поиск по подразделению"
                    class="report-all-users__field"
                />

                <div
                    v-if="centers.length > 1"
                    class="report-all-users__grid"
                >
                    <div
                        v-for="center in centers"
                        :key="center.id"
                    >
                        <CheckboxGroup
                            v-model="selectedCenters"
                            :value="center.id"
                            :label="center.name"
                        />
                    </div>
                </div>
            </div>
        </Transition>

        <div class="report-all-users__table-wrapper">
            <table class="table">
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Ф.И.О.: идентификатор</th>
                        <th>Email</th>
                        <th>Роли</th>
                        <th>Телефон</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(user, index) in usersFilter"
                        :key="user.id"
                    >
                        <td>{{ index + 1 }}</td>
                        <td> {{ user.name.fullName }}: {{ user.id }}</td>
                        <td> {{ user.phone }}</td>
                        <td> {{ user.email }}</td>
                        <td>
                            <ul>
                                <li
                                    v-for="role in usersRoles[user.id]"
                                    :key="`${role.unit}-${role.name}`"
                                >
                                    <span
                                        :style="{color: centers.length > 1 ? rolesColors[centers.findIndex(c => c.id === role.centerId)] : 'black'}"
                                    >
                                        {{ role.displayName }}: {{ role.unitName }}
                                    </span>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import InputSearch from '@/components/common/inputs/InputSearch'
import Checkbox from '@/components/common/inputs/Checkbox'
import CheckboxGroup from '@/components/common/inputs/CheckboxGroup'

import dynamicSort from '@/tools/sort/dynamicSort'
import errors from '@/tools/errors/errors.ts'

const rolesColors = ['blue', 'red', 'purple']

export default {
    name: 'ReportByAllUsers',
    components: {
        Checkbox,
        InputSearch,
        CheckboxGroup
    },
    props: {
        filters: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            users: [],
            rolesColors,
            searchByName: '',
            searchByUnit: '',
            searchByRole: '',
            withRoles: true,
            withoutRoles: true,
            withFullName: true,
            withoutFullName: true,
            selectedCenters: []
        }
    },
    computed: {
        ...mapGetters('me', ['getMe']),
        ...mapGetters('company_structure', ['getUnitById', 'getUsersByCenterId']),
        managerRoles() {
            // Все роли с именем WorkflowManager
            return this.getMe.roles.filter(role => role.name === 'WorkflowManager')
        },
        managerUnits() {
            return this.managerRoles.map(r => r.unit)
        },
        centers() {
            const centers = this.managerRoles.filter(role => role.unit !== 'root')
            return centers.map(role => {
                const unit = this.getUnitById(role.unit)
                return {
                    id: role.unit,
                    name: unit ? unit.name : 'Имя не определено'
                }
            })
        },
        usersByCenter() {
            const usersByCenter = {} // centeId: []user
            this.centers.forEach(center => usersByCenter[center.id] = this.getUsersByCenterId(center.id))
            return usersByCenter
        },
        usersIdsByCenter() {
            const usersIdsByCenter = {} // centerId: []userId
            for (const id in this.usersByCenter) {
                usersIdsByCenter[id] = this.usersByCenter[id].map(user => user.id)
            }
            return usersIdsByCenter
        },
        usersByCenters() {
            const usersByCenters = []
            for (const id in this.usersByCenter) {
                usersByCenters.push(...this.usersByCenter[id])
            }
            return usersByCenters
        },
        usersRoles() {
            const roles = {} // userId: []role
            this.users.forEach(user => {
                const userRoles = user.roles.filter(role => {
                    const unit = this.getUnitById(role.unit)
                    if (!unit) {
                        return false
                    }
                    role['unitName'] = unit.name
                    role['centerId'] = unit.centerId
                    if (this.centers.length > 1 && this.selectedCenters.length) {
                        return this.selectedCenters.includes(role.centerId)
                    } else {
                        return true
                    }
                })
                roles[user.id] = userRoles
            })
            return roles
        },
        usersFilter() {
            // Фильтрация сотрудников по введенному в строку поиска значению
            let users = this.users

            // Показываем только пользователей без ролей
            if (!this.withRoles) {
                users = users.filter(user => !user.roles.length)
            }

            // Показываем только пользователей с ролями
            if (!this.withoutRoles) {
                users = users.filter(user => !!user.roles.length)
            }

            // Показываем только пользователей без имен
            if (!this.withFullName) {
                // Нельзя проверять shortName или fullName, т.к. они всегда true
                users = users.filter(user => !user.name.first && !user.name.middle && !user.name.last )
            }

            // Показываем только пользователей с именами
            if (!this.withoutFullName) {
                // Нельзя проверять shortName или fullName, т.к. они всегда true
                users = users.filter(user => !!user.name.first && !!user.name.middle && !!user.name.last )
            }

            // Фильтруем пользователей по имени
            if (this.searchByName) {
                users = users.filter(user =>
                    user.name.fullName.toLowerCase().includes(this.searchByName.toLowerCase())
                )
            }

            // Фильтруем пользователей по имени подразделения
            if (this.searchByUnit) {
                users = users.filter(user => {
                    let roles = user.roles.filter(role => role.unitName.toLowerCase().includes(this.searchByUnit.toLowerCase()))

                    return !!roles.length
                })
            }

            // Фильтруем пользователей по роли
            if (this.searchByRole) {
                users = users.filter(user => {
                    let roles = user.roles.filter(role => {
                        if (role.displayName.toLowerCase().includes(this.searchByRole.toLowerCase())) {
                            return role
                        }
                    })

                    if (roles.length) {
                        return  user
                    }
                })
            }

            if (this.centers.length > 1 && this.selectedCenters.length) {
                users = users.filter(user => {
                    let show = false
                    this.selectedCenters.forEach(center => {
                        if (this.usersIdsByCenter[center].includes(user.id)) {
                            show = true
                        }
                    })
                    return show
                })
            }

            // Сортируем по алфавиту
            users.sort(dynamicSort('name', 'fullName'))

            return users
        }
    },
    mounted() {
        if (this.managerUnits.includes('root')) {
            this.getAllUsers()
        } else {
            this.users = this.usersByCenters
        }
    },
    methods: {
        ...mapActions('company_structure', ['callGetUsersByRoles']),
        updateReport() {
            // Этот метод вызывается из родительского компонента Report

            // Обнуляем все фильтры
            this.searchByName = ''
            this.searchByUnit = ''
            this.searchByRole = ''
            this.withRoles = true
            this.withoutRoles = true
            this.withoutFullName = true

            // Запрашиваем актуальные данные
            if (this.managerUnits.includes('root')) {
                this.getAllUsers()
            }
        },
        getAllUsers() {
            this._cancelPreloaderGetUsersByRoles = this.$preloader('get_users_by_roles', 'Идет получение пользователей')

            // Запрашиваем всех пользователей системы
            this.callGetUsersByRoles({
                roles: []
            }).then(
                (users) => {
                    this._cancelPreloaderGetUsersByRoles()
                    this.users = users
                })
                .catch(
                    error => {
                        this._cancelPreloaderGetUsersByRoles()
                        this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                            timeout: 0
                        })
                    }
                )
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/table.styl'
@import '~@/assets/styles/transition_filters.styl'

.report-all-users
    display flex
    flex-direction column
    height 100%
    overflow hidden

.report-all-users__filters
    display grid
    grid-column-gap 10px
    grid-row-gap 10px
    align-items center
    margin-bottom 10px

.report-all-users__grid
    display grid
    grid-template-columns 1fr 1fr

.report-all-users__field
    height 40px

.report-all-users__table-wrapper
    flex-grow 1
    overflow auto

///////////////////////////////////////////////////////
////                 //////////////////////////////////
////  Media          //////////////////////////////////
////                 //////////////////////////////////
///////////////////////////////////////////////////////
@media screen and (min-width: 1024px)
    .report-all-users__filters
        grid-template-columns 1fr 1fr
</style>
