import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { MeState } from '@/store/me/meState'

import { service, cuntPB } from 'api'

import { AuthenticationStatus } from '@/model/Authentication'
import { User } from '@/model/User'

import _actions_callSetPhone from './callSetPhone'
import _actions_callSetEmail from './callSetEmail'

import _actions_manageUserEventStream from './manageUserEventStream.js'

const MeActions: ActionTree<MeState, RootState> = {
    async callLoginAs({ dispatch }, params: cuntPB.LoginAsReq) {
        const data: cuntPB.LoginAsResp = await service.backDoor.LoginAs(params)
        console.log('::DATA::BackDoorClient.loginAs:', data)
        // await dispatch('callCheckStatus')

        return data

        // Install backdoor for e2e tests
        // if (navigator.webdriver) {
        //     window.$loginAs = (payload) => login(payload)
        //         .then(() => store.dispatch('me/callCheckStatus'))
        // }
    },

    callSetPhone: _actions_callSetPhone,
    callSetEmail: _actions_callSetEmail,

    async callSetName({ commit }, params: cuntPB.SetUserNameReq) {
        const data: cuntPB.User = await service.user.SetName(params)
        console.log('::DATA::UserClient.setName:', data)
        commit('setMe', new User(data))
    },

    manageUserEventStream: _actions_manageUserEventStream,

    setStatus({ commit }, status: AuthenticationStatus | null) {
        commit('setStatus', status)
        return status
    }
}

export default MeActions
