









































































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import getError, { GrpcError } from '@/tools/errors/errors'
import { COLUMN_TYPES, Directory } from '@/model/Directory'
import { TDirectoryField } from '@/components/directory/model'
import { cuntPB } from 'api'
import { SelectOption } from '@/components/common/select/model'

import InputBase from '@/components/common/inputs/InputBase.vue'
import Select from '@/components/common/select/Select.vue'
import Checkbox from '@/components/common/inputs/Checkbox.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'
import Button from '@/components/common/buttons/Button.vue'

const columnTypes: Array<SelectOption> = Object.entries(COLUMN_TYPES).map((x) => {
    return {
        id: x[0],
        name: x[1]
    }
})

@Component({
    components: {
        InputBase,
        Select,
        Checkbox,
        ButtonDelete,
        Button
    }
})

export default class DirectoryColumn extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) directoryId!: string
    @Prop({ default: null }) columnInfo!: TDirectoryField

    // *************************************************************
    // DATA PARAMS
    name = ''
    type = ''
    subType = ''
    required = false

    columnTypes = columnTypes

    loadingName = false
    loadingRequired = false

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectories') getDirectories!: Array<Directory>
    @Getter('directories/getDirectoryById') getDirectoryById!: (dirId: string) => Directory | null

    get external(): boolean {
        if (!this.directoryId) {
            return false
        }

        const directory = this.getDirectoryById(this.directoryId)
        if (directory) {
            return directory.external
        } else {
            return true
        }
    }

    get directories(): Array<Directory> {
        return this.getDirectories.filter((dir: Directory) => dir.id !== this.directoryId && !dir.archived)
    }

    get addColumnDisabled(): boolean {
        if (!this.name.trim() || !this.type) {
            return true
        }

        if (this.type === 'reference' && !this.subType) {
            return true
        }

        return false
    }

    // *************************************************************
    // WATCH
    @Watch('columnInfo', { immediate: true, deep: true })
    handleColumnInfoChange(val: TDirectoryField): void {
        if (!this.loadingName) {
            this.name = val.name
        }
        if (!this.loadingRequired) {
            this.required = val.required
        }
        this.type = val.type
        this.subType = val.subType
    }

    // *************************************************************
    // METHODS
    @Action('directories/callAddDirectoryField')
    callAddDirectoryField!: (params: cuntPB.AddDirectoryFieldReq) => Promise<void>

    @Action('directories/callSetDirectoryFieldName')
    callSetDirectoryFieldName!: (params: cuntPB.SetDirectoryFieldNameReq) => Promise<void>

    @Action('directories/callSetDirectoryFieldRequired')
    callSetDirectoryFieldRequired!: (params: cuntPB.SetDirectoryFieldRequiredReq) => Promise<void>

    onChangeName(): void {
        const name = this.name.trim()
        if (name === this.columnInfo.name) {
            return
        }

        // Если редактируем в уже созданном справочнике и уже созданной колонке
        if (this.directoryId && !this.columnInfo.new) {
            this.setName()
            return
        }

        if (!this.directoryId) {
            // Если создается новый справочник
            this.emitChangeColumn({
                ...this.columnInfo,
                name
            })
        }
    }

    onChangeType(): void {
        if (!this.directoryId && this.type !== this.columnInfo.type) {
            // Если создается новый справочник
            // Если меняем тип,  то обнуляем обязательно subType
            this.emitChangeColumn({
                ...this.columnInfo,
                type: this.type,
                subType: ''
            })
        }
    }

    onChangeSubType(): void {
        if (!this.directoryId && this.subType !== this.columnInfo.subType) {
            // Если создается новый справочник
            this.emitChangeColumn({
                ...this.columnInfo,
                subType: this.subType
            })
        }
    }

    onChangeRequired(): void {
        if (this.required === this.columnInfo.required) {
            return
        }

        // Если редактируем в уже созданном справочнике и уже созданной колонке
        if (this.directoryId && !this.columnInfo.new) {
            this.setRequired()
            return
        }

        // Если создается новый справочник
        if (!this.directoryId) {
            this.emitChangeColumn({
                ...this.columnInfo,
                required: this.required
            })
        }
    }

    setName(): void {
        this.loadingName = true

        this.callSetDirectoryFieldName({
            DirectoryID: this.directoryId,
            Position: this.columnInfo.index,
            Name: this.name.trim()
        })
            .catch((error: GrpcError) => {
                this.name = this.columnInfo.name
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingName = false
            })
    }

    setRequired(): void {
        this.loadingRequired = true

        this.callSetDirectoryFieldRequired({
            DirectoryID: this.directoryId,
            Position: this.columnInfo.index,
            Required: this.required
        })
            .catch((error: GrpcError) => {
                this.required = this.columnInfo.required
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingRequired = false
            })
    }

    addColumn(): void {
        if (this.addColumnDisabled) {
            return
        }

        const cancelPreloaderAddDirectoryField = this.$preloader('add_directory_field', 'Добавление поля')
        this.callAddDirectoryField({
            DirectoryID: this.directoryId,
            Position: this.columnInfo.index,
            Field: {
                Name: this.name,
                Required: this.required,
                Type: this.type,
                SubType: this.subType
            }
        })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                cancelPreloaderAddDirectoryField()
            })
    }

    removeColumn(): void {
        if (this.external) {
            this.$snotify.warning('Нельзя удалять поля в справочниках внешних систем', 'Отклонено')
            return
        }

        if (this.columnInfo.removeDisabledReason) {
            this.$snotify.warning('Нельзя удалить единственное обязательное поле', 'Отклонено')
            return
        }

        this.emitRemoveColumn()
    }

    // *************************************************************
    // EMIT
    @Emit('changeColumn')
    emitChangeColumn(field: TDirectoryField): TDirectoryField {
        console.log('emitChangeColumn', field)
        return field
    }

    @Emit('removeColumn')
    emitRemoveColumn(): void {
        // Do nothing
    }
}
