var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "action-task"
  }, [_c('div', {
    staticClass: "action-task__header"
  }, [_vm._v("\n        " + _vm._s(_vm.blockTitle) + "\n    ")]), _vm._v(" "), _c('InputBase', {
    staticClass: "action-task__name",
    attrs: {
      "type": "text",
      "multi-lines": false,
      "placeholder": "Название задачи"
    },
    model: {
      value: _vm.taskName,
      callback: function callback($$v) {
        _vm.taskName = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "taskName"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "action-task__select"
  }, [_c('div', {
    staticClass: "action-task__select_title"
  }, [_vm._v("\n            Исполнитель:\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "action-task__check-radio-container"
  }, [_c('RadioButton', {
    staticClass: "action-task__check-radio",
    attrs: {
      "value": "unit",
      "label": "Подразделение"
    },
    model: {
      value: _vm.assigneeType,
      callback: function callback($$v) {
        _vm.assigneeType = $$v;
      },
      expression: "assigneeType"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "action-task__check-radio",
    attrs: {
      "value": "user",
      "label": "Пользователь"
    },
    model: {
      value: _vm.assigneeType,
      callback: function callback($$v) {
        _vm.assigneeType = $$v;
      },
      expression: "assigneeType"
    }
  })], 1), _vm._v(" "), _c('ActionSelectCard', {
    staticClass: "action-task__select-card",
    attrs: {
      "placeholder": "Исполнитель",
      "entity": _vm.assigneeType,
      "items": _vm.assigneeType === 'user' ? _vm.users : _vm.units,
      "sort": "name",
      "sub-sort-property": _vm.assigneeType === 'user' ? 'fullName' : ''
    },
    on: {
      "input": function input($event) {
        return _vm.selectAssignee($event);
      }
    },
    model: {
      value: _vm.assignee,
      callback: function callback($$v) {
        _vm.assignee = $$v;
      },
      expression: "assignee"
    }
  })], 1), _vm._v(" "), _c('Textarea', {
    staticClass: "action-task__description",
    attrs: {
      "min-lines": 1,
      "max-lines": 5,
      "placeholder": "Описание задачи"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "description"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "action-task__select"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "action-task__check-radio-container"
  }, [_c('RadioButton', {
    staticClass: "action-task__check-radio",
    attrs: {
      "value": "unit",
      "label": "Подразделение"
    },
    model: {
      value: _vm.conformationType,
      callback: function callback($$v) {
        _vm.conformationType = $$v;
      },
      expression: "conformationType"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "action-task__check-radio",
    attrs: {
      "value": "user",
      "label": "Пользователь"
    },
    model: {
      value: _vm.conformationType,
      callback: function callback($$v) {
        _vm.conformationType = $$v;
      },
      expression: "conformationType"
    }
  }), _vm._v(" "), _c('RadioButton', {
    staticClass: "action-task__check-radio",
    attrs: {
      "value": "",
      "label": "Не требуется"
    },
    model: {
      value: _vm.conformationType,
      callback: function callback($$v) {
        _vm.conformationType = $$v;
      },
      expression: "conformationType"
    }
  })], 1), _vm._v(" "), _c('ActionSelectCard', {
    staticClass: "action-task__select-card",
    attrs: {
      "placeholder": "Подтверждает задачу",
      "entity": _vm.conformationType,
      "items": _vm.conformationType === 'user' ? _vm.users : _vm.units,
      "sort": "name",
      "sub-sort-property": _vm.conformationType === 'user' ? 'fullName' : ''
    },
    on: {
      "input": function input($event) {
        return _vm.selectConformation($event);
      }
    },
    model: {
      value: _vm.confirmer,
      callback: function callback($$v) {
        _vm.confirmer = $$v;
      },
      expression: "confirmer"
    }
  })], 1), _vm._v(" "), _c('ButtonGreen', {
    staticClass: "action-task__button_save",
    attrs: {
      "text": "OK"
    },
    on: {
      "click": _vm.onSave
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "action-task__select_title"
  }, [_vm._v("\n            Подтверждает\n            "), _c('br'), _vm._v("\n            выполнение:\n        ")]);
}]

export { render, staticRenderFns }