

























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { cuntPB } from 'api'
import { Unit } from '@/model/Unit'
import getError, { GrpcError } from '@/tools/errors/errors'

import InputSearch from '@/components/common/inputs/InputSearch.vue'
import UnitCard from '@/components/common/cards/UnitCard.vue'
import Button from '@/components/common/buttons/Button.vue'

@Component({
    components: {
        Button,
        UnitCard,
        InputSearch
    }
})

export default class SearchUnit extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) unitId!: string

    // *************************************************************
    // DATA PARAMS
    searchText = ''

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get unit(): Unit | null {
        return this.getUnitById(this.unitId)
    }

    get units(): Array<Unit> {
        const thisUnit = this.unit
        if (!thisUnit) {
            return []
        }

        // Убираем подразделения из других центров
        const unitsFromCenter = this.getUnits.filter((unit: Unit) => unit.centerId === thisUnit.centerId)

        const parentIds: Array<string> = []

        const searchParent = (unitId: string) => {
            parentIds.push(unitId)
            const unit = unitsFromCenter.find((u: Unit) => u.id === unitId)
            if (unit && unit.parent) {
                searchParent(unit.parent)
            }
        }

        searchParent(this.unitId)

        // Убираем из списка units всех родителей
        // и потомков первого порядка (они и так уже в этом подразделении)
        const filteredUnits: Array<Unit> = unitsFromCenter.filter((unit: Unit) => !parentIds.includes(unit.id) && unit.parent !== thisUnit.id)

        if (!this.searchText) {
            // Возвращаем полный список добаленных в систему подразделений
            return filteredUnits
        }

        // Возвращаем полный список добаленных в систему подразделений + добавляем фильтрацию по имени в строке поиска
        return filteredUnits.filter((u: Unit) => u.name.toLowerCase().includes(this.searchText.toLowerCase()))
    }

    // *************************************************************
    // METHODS
    @Action('company_structure/callMoveUnit')
    callMoveUnit!: (params: cuntPB.MoveUnitReq) => Promise<void>

    canMoveUnit(id: string): void {
        const child = this.getUnitById(id)
        if (!child) {
            this.$snotify.warning(`Обратитесь в техподдержку: Дочернее подразделение не найдено: ${id}`)
            return
        }

        const oldParent = this.getUnitById(child.parent)
        if (!oldParent) {
            this.$snotify.warning(`Обратитесь в техподдержку: Существующее родительское подразделение не найдено: ${child.parent}`)
            return
        }

        const newParent = this.unit
        if (!newParent) {
            this.$snotify.warning(`Обратитесь в техподдержку: Новое родительское подразделение не найдено: ${this.unitId}`)
            return
        }
        const answer = window.confirm(`Вы действительно хотите перенести подразделение ${child.name} из ${oldParent.name} в ${newParent.name}?`)
        if (answer) {
            this.moveUnit(id, this.unitId)
        }
    }

    moveUnit(id: string, parent: string): void {
        const cancelPreloaderMoveUnit = this.$preloader('move_unit', 'Изменение родительского подразделения')

        this.callMoveUnit({
            ID: id,
            Parent: parent
        })
            .then(() => this.emitMoved())
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderMoveUnit())
    }

    // *************************************************************
    // EMIT
    @Emit('moved')
    emitMoved(): void {
        // Do nothing
    }

    @Emit('goToCreate')
    emitGoToCreate(): void {
        // Do nothing
    }
}
