














































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Unit } from '@/model/Unit'

import UnitListCard from '@/components/company_structure/UnitListCard.vue'

@Component({
    components: { UnitListCard }
})

export default class UnitsListBlock extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) unitId!: string
    @Prop({ default: () => []}) filteredUnits!: Array<Unit>

    // *************************************************************
    // DATA PARAMS
    drop = true

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUnits') getUnits!: Array<Unit>
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get unit(): Unit | null {
        if (this.filteredUnits.find((u: Unit) => u.id === this.unitId)) {
            return this.getUnitById(this.unitId)
        }

        return null
    }

    get children(): Array<Unit> {
        return this.getUnits.filter((u: Unit) => u.parent === this.unitId)
    }

    get showDrop(): boolean {
        if (!this.unit) {
            return false
        }
        if (!this.children.length) {
            return false
        }

        return !!this.children.filter((unit: Unit) => this.filteredUnits.find((u: Unit) => u.id === unit.id)).length
    }

    onClickDrop(): void {
        // Меняем по клику значение drop
        this.drop = !this.drop
    }

    // *************************************************************
    // EMIT
    @Emit('selectUnit')
    emitSelectUnit(unitId: string): string {
        return unitId
    }
}
