



























import { Component, Emit, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Directory, Record } from '@/model/Directory'

import Modal from '@/components/common/modals/Modal.vue'
import InputSearch from '@/components/common/inputs/InputSearch.vue'
import DirectoryRecordsSelect from '@/components/directory/DirectoryRecordsSelect.vue'

@Component({
    components: {
        Modal,
        InputSearch,
        DirectoryRecordsSelect
    }
})

export default class RecordSelectModal extends Vue {
    // *************************************************************
    // DATA PARAMS
    searchText = ''

    directoryId = ''
    documentUnitId = ''
    visibleModal = false

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryById') getDirectoryById!: (dirId: string) => Directory | null

    get directory(): Directory | null {
        return this.getDirectoryById(this.directoryId)
    }

    get modalTitle(): string {
        return this.directory?.name ?? 'Справочник не найден!'
    }

    // *************************************************************
    // METHODS
    show(directoryId: string, documentUnitId: string): void {
        // Этот метод вызывается из родительского компонента
        // Показываем Modal и передаем в данный компонент id справочника
        this.directoryId = directoryId
        this.documentUnitId = documentUnitId
        this.visibleModal = true
    }

    changeItem(record: Record): void {
        // прокидываем событие в родительский компонент
        this.emitChangeItem(record)
        // Скрываем Modal
        this.visibleModal = false
        this.close()
    }

    close(): void {
        this.searchText = ''
        this.directoryId = ''
        this.documentUnitId = ''

        this.emitClose()
    }

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose(): void {
        // Do nothing
    }

    @Emit('changeItem')
    emitChangeItem(record: Record): Record {
        return record
    }
}
