import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { WorkflowState } from '@/store/workflow/index'
import { WorkflowTemplate, StageTemplate, ApprovalTemplate, TaskTemplate } from '@/model/Workflow'

// возвращаем активный node со всеми полями
import viewData, { LinkTemplate, NodeTemplate } from '@/tools/template_builder/viewData'

const getNodes = (
    filterApprovals: Array<NodeTemplate>,
    stageNodes: Array<NodeTemplate>,
    inputs: Array<string>,
    outputs: Array<string>
): Array<NodeTemplate> => {
    // Не добавляем дочерние и родительские nodes getActiveNode
    filterApprovals = stageNodes.filter((node: NodeTemplate) => (!inputs.includes(node.id) && !outputs.includes(node.id)))

    const newInputs: Array<string> = []

    const newOutputs: Array<string> = []

    // по всем inputs собираем их inputs
    inputs.forEach((input: string) => {
        const inputNode = stageNodes.find((node: NodeTemplate) => node.id === input)

        if (inputNode) {
            inputNode.inputs.forEach((inp: string) => newInputs.push(inp))

            inputNode.outputs.forEach((out: string) => {
                filterApprovals = filterApprovals.filter((node: NodeTemplate) => node.id !== out)
            })
        }
    })

    // по всем outputs собираем их outputs
    outputs.forEach(output => {
        const outputNode = stageNodes.find((node: NodeTemplate) => node.id === output)
        if (outputNode) {
            outputNode.outputs.forEach((out: string) => newOutputs.push(out))
        }
    })

    if (newInputs.length || newOutputs.length) {
        return getNodes(filterApprovals, filterApprovals, newInputs, newOutputs)
    } else {
        return filterApprovals
    }
}

const WorkflowGetters: GetterTree<WorkflowState, RootState> = {
    /** Собираем массив nodes, к которым можно прокинуть связь из активного node */
    getNodesCanLinked: (state, getters): Array<NodeTemplate> => {
        const activeStage = getters.getActiveStage
        const activeNode = getters.getActiveNode
        if (!activeNode || !activeStage) {
            return []
        }

        // Прокидываем всему массиву approvals outputs
        const stageApprovalsWidthOutputs: { nodes: Array<NodeTemplate>, links: Array<LinkTemplate> } = viewData(40, 0, 0, activeStage.approvals, false, false)

        // Берем весь массив approvals активного stage, убираем из него активный node
        const stageApprovals = stageApprovalsWidthOutputs.nodes.filter((node: NodeTemplate) => node.id !== activeNode.id)

        return getNodes([], stageApprovals, activeNode.inputs, activeNode.outputs)
    },

    getWorkflow: (state: WorkflowState): WorkflowTemplate => state.workflow,

    getActiveStage: (state: WorkflowState): StageTemplate | null => {
        if (state.workflow.activeStage === null) {
            return null
        }
        return state.workflow.stages[state.workflow.activeStage]
    },

    getActiveApproval: (state, getters): ApprovalTemplate | null => {
        const activeStage = getters.getActiveStage

        if (!activeStage || !state.workflow.activeNode) {
            return null
        }
        return activeStage.approvals.find((node: ApprovalTemplate) => node.id === state.workflow.activeNode) ?? null
    },

    getActiveNode: (state: WorkflowState): NodeTemplate | null => {
        if (state.workflow.activeStage == null) {
            return null
        }
        if (!state.workflow.activeNode) {
            return null
        }

        // прокидываем в массив с approvals в активном stage viewData
        const approvals = state.workflow.stages[state.workflow.activeStage].approvals
        if (!approvals.length) {
            return null
        }

        const verticesWithViewData: { nodes: Array<NodeTemplate>, links: Array<LinkTemplate> } = viewData(40, 0, 0, approvals, false, true)
        // Возвращаем активный node со всеми показателями viewData
        return verticesWithViewData.nodes.find((node: NodeTemplate) => node.id === state.workflow.activeNode) ?? null
    },

    getActiveTask: (state: WorkflowState): TaskTemplate | null => {
        if (state.workflow.activeTask && state.workflow.activeStage !== null) {
            // Берем все задачи активного stage
            const stageTasks = state.workflow.stages[state.workflow.activeStage].tasks
            // Возвращаем активную задачу
            return stageTasks.find((task: TaskTemplate) => task.id === state.workflow.activeTask) ?? null
        } else {
            return null
        }
    },

    getApprovalChildrenWithFewParents: (state, getters): Array<ApprovalTemplate> => {
        const activeNode: NodeTemplate | null = getters.getActiveNode
        const activeStage: StageTemplate | null = getters.getActiveStage
        if (!activeNode || !activeNode.outputs.length || !activeStage) {
            return []
        }

        // Если есть исходящие связи, то проверяем, есть ли у каждой исходящей связи другие входящие связи
        // Дочерние nodes с несколькими связями собираем в массив
        const nodesWithFewParents: Array<ApprovalTemplate> = []

        activeNode.outputs.forEach((output: string) => {
            const node = activeStage.approvals.find((app: ApprovalTemplate) => app.id === output)
            if (node && node.inputs.length !== 1) {
                nodesWithFewParents.push(node)
            }
            return false
        })

        return nodesWithFewParents
    }
}

export default WorkflowGetters
