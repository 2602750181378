var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100"
  }, [_c('GoBack', {
    attrs: {
      "text": "Вернуться к списку задач",
      "link": _vm.goBackPath
    }
  }), _vm._v(" "), _c('TaskCardFull', {
    staticClass: "mt-3 task__body",
    attrs: {
      "task": _vm.task
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }