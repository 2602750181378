














































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import SideBarItem from '@/components/sidebar/SideBarItem.vue'
import SideBarPanel from '@/components/sidebar/SideBarPanel.vue'

import { User } from '@/model/User'
import { WorkflowEvent } from '@/model/WorkflowEvent'
import { Task } from '@/model/Task'
import { Familiarization } from '@/model/familiarization.js'
import { Route } from 'vue-router'

@Component({
    components: {
        SideBarItem,
        SideBarPanel
    }
})

export default class SideBar extends Vue {
    // *************************************************************
    // DATA PARAMS
    marginLeft = ''
    root = false
    events: Array<WorkflowEvent> = []
    title = ''
    type = ''

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    @Getter('workflow_event/getByActor') getByActor!: (actorId: string) => Array<WorkflowEvent>

    @Getter('task/getNotImplemented') getNotImplemented!: Array<Task>

    @Getter('familiarization/getUserFamiliarization') getUserFamiliarization!: (id: string) => Array<Familiarization>

    get component(): string {
        switch (this.type) {
        case 'me':
            return 'Me'
        case 'approval':
            return 'UserEvents'
        case 'approvalTasks':
            return 'UserEvents'
        case 'tasks':
            return 'UserTasks'
        case 'familiarization':
            return 'UserFamiliarization'
        default:
            return 'Me'
        }
    }

    get approvalEvents(): Array<WorkflowEvent> {
        return this.getByActor(this.getMe.id).filter(event => event.type === 'approval')
    }

    get taskEvents(): Array<WorkflowEvent> {
        return this.getByActor(this.getMe.id).filter(event => event.type === 'task')
    }

    get tasks(): string | number {
        // Получаем массив задач по данному user, у которых completed === false, т.е. незавершены
        const tasks = this.getNotImplemented
        const incoming = tasks.filter((t: Task) => (t.assignee.user?.id === this.getMe.id && !t.completed) || (t.completed && t.confirmer && t.confirmer.user?.id === this.getMe.id))
        const outgoing = tasks.filter((t: Task) => t.author.user?.id === this.getMe.id)
        if (!incoming.length && !outgoing.length) {
            return 0
        }
        return `${incoming.length}/${outgoing.length}`
    }

    get familiarization(): Array<Familiarization> {
        return this.getUserFamiliarization(this.getMe.id)
    }

    // *************************************************************
    // WATCH
    @Watch('$route', { immediate: true })
    handleRouteChange(to: Route): void {
        if (to.name === 'root') {
            // Ставим SideBar отступ, т.е. автоматически отнимаем этот размер у левого контейнера с items
            // чтобы items смогли масштабироваться под размеры родительского flex-контейнера
            this.marginLeft = '25vw'
            // Убираем класс SideBarPanel, чтобы на него не действовало наведение и изменялось значение right
            this.root = true
        } else {
            this.marginLeft = '0'
            // Ставим класс SideBarPanel, чтобы на него влияло наведение и изменялось значение right
            this.root = false
        }
    }

    setActiveItem(type: string, events: Array<WorkflowEvent>, title: string): void {
        this.events = events
        this.title = title
        this.type = type
    }
}
