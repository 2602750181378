





































































import { Component, Vue, Prop, Watch, Emit, Ref } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import getError, { GrpcError } from '@/tools/errors/errors'
import { Numerator, NumeratorField } from '@/model/numerator.js'
import { Location } from 'vue-router'

import GoBack from '@/components/common/GoBack.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'
import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'
import NumeratorRecord from '@/components/numerators/NumeratorRecord.vue'
import RemoveModal from '@/components/common/modals/RemoveModal.vue'

@Component({
    components: {
        GoBack,
        InputBase,
        ButtonDelete,
        ButtonAdd,
        NumeratorRecord,
        RemoveModal
    }
})

export default class EditNumerator extends Vue {
    @Ref() readonly removeModal!: RemoveModal

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) centerId!: string

    // *************************************************************
    // DATA PARAMS
    name = ''
    fields: Array<NumeratorField> = []
    visibleRemoveModal = false

    // *************************************************************
    // COMPUTED
    @Getter('numerator/getNumeratorById') getNumeratorById!: (id: string) => Numerator | null
    @Getter('numerator/getNumeratorVersion') getNumeratorVersion!: (id: string) => number

    get numeratorId(): string {
        return this.$route.params.id
    }

    get numerator(): Numerator | null {
        return this.getNumeratorById(this.numeratorId)
    }

    get version(): number | null {
        if (this.numerator) {
            return this.getNumeratorVersion(this.numerator.id)
        }
        return null
    }

    get goBackPath(): Location {
        return {
            name: 'module',
            params: {
                section: 'system',
                module: 'numerators'
            }
        }
    }

    get canSave(): { disabled: boolean, reason: string } {
        if (this.numerator && !!this.version) {
            return {
                disabled: true,
                reason: 'Сохранение изменений'
            }
        }
        if (!this.numerator && !this.centerId) {
            return {
                disabled: true,
                reason: 'Необходимо выбрать организацию'
            }
        }
        if (!this.name) {
            return {
                disabled: true,
                reason: 'Имя нумератора не может быть пустым'
            }
        }
        if (!this.fields.length) {
            return {
                disabled: true,
                reason: 'Нумератор должен содержать хотя бы одно поле'
            }
        }
        let emptyFields = ''

        this.fields.forEach((field: NumeratorField, index: number) => {
            if (!field.type) {
                emptyFields = `${emptyFields} №${index + 1} `
            } else {
                switch (field.type) {
                case 'field_type_code':
                    if (!field.code) {
                        emptyFields = `${emptyFields} №${index + 1} `
                    }
                    break
                case 'field_type_text':
                    if (!field.text) {
                        emptyFields = `${emptyFields} №${index + 1} `
                    }
                    break
                case 'field_type_incr':
                    if (!field.pad || !field.resetPeriod) {
                        emptyFields = `${emptyFields} №${index + 1} `
                    }
                    break
                default:
                    console.error('undefined field.type', field.type)
                }
            }
        })

        if (emptyFields) {
            return {
                disabled: true,
                reason: `Заполните значения в полях: ${emptyFields}`
            }
        } else {
            let incr = this.fields.filter(f => f.type === 'field_type_incr').length
            if (!incr) {
                return {
                    disabled: true,
                    reason: "Нумератор должен содержать хотя бы одно поле с типом 'Номер'"
                }
            }
            return {
                disabled: false,
                reason: ''
            }
        }
    }

    // *************************************************************
    // WATCH
    @Watch('canSave', { immediate: true, deep: true })
    handleCanSaveChange(val: { disabled: boolean, reason: string}): void {
        this.emitSaveDisabled(val)
    }

    @Watch('numerator', { immediate: true, deep: true })
    handleNumeratorChange(to: Numerator | null, from: Numerator | null): void {
        if (to) {
            this.name = to.name
            this.fields = to.fields

            if (this.version) {
                this.setNumerator(to, this.version)
            }
        } else if (!to && !!from && !!this.numeratorId) {
            // Для исключительной ситуации, когда у одного пользователя открыто окно редактирования
            // нумератора, а другой пользователь его удалил
            this.openNumerators()
        } else {
            this.name = ''
            this.fields = [new NumeratorField(
                Math.random().toString(36).substring(7),
                'field_type_incr', '', '', ''
            )]
        }
    }

    // *************************************************************
    // METHODS
    @Action('numerator/callSet')
    callSet!: (params: { numerator: Numerator, version: number }) => Promise<void>

    @Action('numerator/callCreate')
    callCreate!: (params: { centerId: string, name: string, fields: Array<NumeratorField> }) => Promise<string>

    @Action('numerator/callRemove')
    callRemove!: (id: string) => Promise<void>

    @Action('numerator/setName')
    setName!: (params: { numeratorId: string, name: string }) => void

    @Action('numerator/moveFields')
    moveFields!: (params: { numeratorId: string, fieldIndex: number, newFieldIndex: number }) => void

    openNumerators(): void {
        this.$router.push(this.goBackPath)
    }

    create(): void {
        console.log('create===============')
        // Вызывается из родительского компонента
        const cancelPreloaderCreateNumerator = this.$preloader('create_numerator', 'Создание нумератора')

        this.callCreate({
            centerId: this.centerId,
            name: this.name,
            fields: this.fields
        })
            .then((numeratorId: string) => {
                this.emitCreate(numeratorId)
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderCreateNumerator())
    }

    showRemove() {
        this.removeModal.show({
            itemName: this.name,
            itemType: 'Нумератора',
            warning: '',
            callback: this.removeNumerator
        })
    }

    removeNumerator(): void {
        if (!this.numerator) {
            return
        }

        const cancelPreloaderRemoveNumerator = this.$preloader('remove_numerator', 'Удаление нумератора')
        this.callRemove(this.numerator.id)
            .then(() => {
                this.visibleRemoveModal = false
                // this.openNumerators()
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderRemoveNumerator())
    }

    setNumerator(numerator: Numerator, version: number): void {
        this.callSet({
            numerator,
            version
        })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
    }

    addField(): void {
        this.fields.push(new NumeratorField(Math.random().toString(36).substring(7), '', '', '', ''))
    }

    removeField(index: number): void {
        this.fields.splice(index, 1)
    }

    changeField(field: NumeratorField, index: number): void {
        if (this.fields[index].type !== field.type) {
            this.fields[index].code = ''
            this.fields[index].text = ''
            this.fields[index].pad = ''
            this.fields[index].resetPeriod = ''
        } else {
            this.fields[index].code = field.code
            this.fields[index].text = field.text
            this.fields[index].pad = field.pad
            this.fields[index].resetPeriod = field.resetPeriod
        }
        this.fields[index].type = field.type
    }

    moveDown(fieldIndex: number): void {
        if (this.numerator) {
            this.moveFields({
                numeratorId: this.numerator.id,
                fieldIndex,
                newFieldIndex: fieldIndex + 1
            })
            return
        }
        let movingField = this.fields[fieldIndex]
        Vue.set(this.fields, fieldIndex, this.fields[fieldIndex + 1])
        Vue.set(this.fields, fieldIndex + 1, movingField)
    }

    moveUp(fieldIndex: number): void {
        if (this.numerator) {
            this.moveFields({
                numeratorId: this.numerator.id,
                fieldIndex,
                newFieldIndex: fieldIndex - 1
            })
            return
        }
        let movingField = this.fields[fieldIndex]
        Vue.set(this.fields, fieldIndex, this.fields[fieldIndex - 1])
        Vue.set(this.fields, fieldIndex - 1, movingField)
    }

    saveName(): void {
        if (!this.numerator) {
            return
        }

        this.setName({
            numeratorId: this.numerator.id,
            name: this.name
        })
    }

    // *************************************************************
    // EMIT
    @Emit('saveDisabled')
    emitSaveDisabled(val: { disabled: boolean, reason: string}): { disabled: boolean, reason: string} {
        return val
    }

    @Emit('create')
    emitCreate(val: string): string {
        return val
    }
}
