





























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User } from '@/model/User'
import { TaskEvent } from '@/model/Task'

import MessageEvent from '@/components/common/chat/MessageEvent.vue'
import BaseEventCard from '@/components/common/chat/BaseEventCard.vue'

@Component({
    components: {
        MessageEvent,
        BaseEventCard
    }
})

export default class ChatCardSelector extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) event!: TaskEvent
    @Prop({ default: () => null }) options!: { name: string }

    // *************************************************************
    // DATA PARAMS
    alignment = 'none'

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null in this component impossible

    @Getter('company_structure/getUserById') getUserById!: (id: string) => User | null

    get smallAvatar(): string {
        const actorId = this.event.actor?.id
        if (!actorId) {
            return ''
        }

        const actor = this.getUserById(actorId)
        if (!actor) {
            return ''
        }

        return actor.avatar.small
    }

    // *************************************************************
    // WATCH
    @Watch('event', { immediate: true, deep: true })
    handleEventChange(to: TaskEvent): void {
        if (to) {
            if (to.type === 'message') {
                // Если тип = сообщение, показываем справа или слева в зависимости от пользователя
                if (to.actor?.id === this.getMe.id) {
                    this.alignment = 'right'
                } else {
                    this.alignment = 'left'
                }
            } else {
                // Если тип = НЕ сообщение
                this.alignment = 'none'
            }
        }
    }
}
