var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.event ? _c('div', {
    staticClass: "flex-row-center my-2 chat-card",
    class: {
      'justify-center': _vm.event.type !== 'message'
    }
  }, [_vm.event.type === 'message' ? _c('div', {
    staticClass: "flex-row-center",
    class: {
      'chat-card_left': _vm.alignment === 'left',
      'ml-auto': _vm.alignment === 'right'
    }
  }, [_c('DocumentEventMessageCard', {
    attrs: {
      "alignment": _vm.alignment,
      "event": _vm.event
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "chat-card__avatar",
    style: {
      'background-image': 'url(' + _vm.smallAvatar + ')'
    }
  })], 1) : _vm.event.type === 'registration' ? _c('DocumentEventRegistrationCard', {
    attrs: {
      "event": _vm.event
    }
  }) : _vm.event.type === 'status_transition' ? _c('DocumentEventStatusTransitionCard', {
    attrs: {
      "event": _vm.event
    }
  }) : _vm.event.type === 'approved' ? _c('DocumentEventApprovedCard', {
    attrs: {
      "event": _vm.event
    }
  }) : _vm.event.type === 'rejected' ? _c('DocumentEventRejectedCard', {
    attrs: {
      "event": _vm.event
    }
  }) : _vm.event.type === 'optional_requisite_update' ? _c('DocumentEventRequisiteCard', {
    attrs: {
      "event": _vm.event
    }
  }) : _c('DocumentEventTaskCard', {
    attrs: {
      "event": _vm.event
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }