var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseListPage', {
    attrs: {
      "type": "numerator",
      "disabled-create": _vm.saveDisabled.disabled,
      "visibleModal": _vm.visibleCreationModal
    },
    on: {
      "update:visibleModal": function updateVisibleModal($event) {
        _vm.visibleCreationModal = $event;
      },
      "update:visible-modal": function updateVisibleModal($event) {
        _vm.visibleCreationModal = $event;
      },
      "changeCenterId": function changeCenterId($event) {
        _vm.searchCenterId = $event;
      },
      "create": _vm.create
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }, [_c('template', {
    slot: "list"
  }, _vm._l(_vm.filteredNumerators, function (num) {
    return _c('ListCard', {
      key: num.id,
      attrs: {
        "item": num
      },
      on: {
        "click": function click($event) {
          return _vm.openNumerator(num.id);
        }
      }
    });
  }), 1), _vm._v(" "), _c('div', {
    staticClass: "numerators__create",
    attrs: {
      "slot": "create"
    },
    slot: "create"
  }, [_c('SelectCenterId', {
    staticClass: "m-5",
    model: {
      value: _vm.centerId,
      callback: function callback($$v) {
        _vm.centerId = $$v;
      },
      expression: "centerId"
    }
  }), _vm._v(" "), _c('EditNumerator', {
    ref: "editNumerator",
    staticClass: "m-5",
    attrs: {
      "center-id": _vm.centerId
    },
    on: {
      "saveDisabled": function saveDisabled($event) {
        return _vm.changeSaveDisabled($event);
      },
      "create": function create($event) {
        return _vm.onCreated($event);
      }
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }