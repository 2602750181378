<template>
    <div>Этот отчет ждет реализации!</div>
</template>

<script>
export default {
    name: 'ReportByAllUsers'
}
</script>

<style lang="stylus" scoped>
</style>
