var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-event-card"
  }, [_c('div', {
    staticClass: "mr-4"
  }, [_c('div', [_vm._v(_vm._s(_vm.event.actor.name.shortName))]), _vm._v(" "), _c('div', {
    staticClass: "fs-12"
  }, [_vm._v("\n            " + _vm._s(_vm.event.creationTimeStampString) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "base-event-card__info_name"
  }, [_vm._v("\n            " + _vm._s(_vm.options.name) + "\n        ")])]), _vm._v(" "), _c('div', _vm._l(_vm.fields, function (field) {
    return _c('div', {
      key: field
    }, [_c('div', {
      staticClass: "fs-14"
    }, [_vm._v("\n                " + _vm._s(_vm.requiredFields[field]) + ":\n            ")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.event.fields[field]))])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }