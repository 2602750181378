import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { WorkflowEvent } from '@/model/WorkflowEvent'

export class WorkflowEventState {
    events: Array<WorkflowEvent> = []
}

const getters: GetterTree<WorkflowEventState, RootState> = {
    getByActor: (state: WorkflowEventState) => (actorId: string): Array<WorkflowEvent> => state.events.filter((event: WorkflowEvent) => event.actor.user?.id === actorId),
    getByDocument: (state: WorkflowEventState) => (documentId: string): Array<WorkflowEvent> => state.events.filter((event: WorkflowEvent) => event.document?.id === documentId)
}

const mutations: MutationTree<WorkflowEventState> = {
    putWorkflowEvent(state: WorkflowEventState, event: WorkflowEvent) {
        const index = state.events.findIndex((e: WorkflowEvent) => e.id === event.id)
        if (index === -1) {
            state.events.push(event)
        } else {
            Vue.set(state.events, index, event)
        }
    },

    removeWorkflowEvent(state: WorkflowEventState, eventId: string) {
        const index = state.events.findIndex((e: WorkflowEvent) => e.id === eventId)
        if (index === -1) {
            console.error('Попытка удалить WorkflowEvent по не существующему id', eventId)
        } else {
            state.events.splice(index, 1)
        }
    },

    cleanUpState(state: WorkflowEventState) {
        state.events = []
    }
}

const actions: ActionTree<WorkflowEventState, RootState> = {
    putWorkflowEvent({ commit }, event: WorkflowEvent) {
        commit('putWorkflowEvent', event)
    },

    removeWorkflowEvent({ commit }, eventId: string) {
        commit('removeWorkflowEvent', eventId)
    },

    cleanUpState({ commit }) {
        commit('cleanUpState')
    }
}

const WorkflowEventSubModule = {
    namespaced: true,
    state: new WorkflowEventState(),
    getters,
    mutations,
    actions
}

export default WorkflowEventSubModule
