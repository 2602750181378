<template>
    <div>
        <Tabs
            v-model="mode"
            :tabs="modes"
        />
        <FamiliarizationEdit
            v-if="mode === 'edit'"
            key="edit"
        />
        <FamiliarizationReport
            v-if="mode === 'report'"
            key="report"
            :events="events"
        />
        <FamiliarizationJournal
            v-else-if="mode === 'journal'"
            key="journal"
            :events="events"
        />
    </div>
</template>

<script>
import Tabs from '@/components/common/tabs/Tabs'
import FamiliarizationEdit from '@/components/familiarization/FamiliarizationEdit'
import FamiliarizationReport from '@/components/familiarization/FamiliarizationReport'
import FamiliarizationJournal from '@/components/familiarization/FamiliarizationJournal'

const modes = [
    {
        id: 'edit',
        name: 'Редактирование'
    },
    {
        id: 'report',
        name: 'Отчет'
    },
    {
        id: 'journal',
        name: 'Журнал'
    }
]

export default {
    components: { Tabs, FamiliarizationEdit, FamiliarizationReport, FamiliarizationJournal },
    props: {
        events: { type: Array, default() {return []} }
    },
    data() {
        return {
            modes,
            mode: 'edit'
        }
    }
}
</script>
