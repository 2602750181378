





















































import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import dayjs from 'dayjs'
import { Document, DocumentRequisite } from '@/model/Document'
import { SelectOption } from '@/components/common/select/model'

import Modal from '@/components/common/modals/Modal.vue'
import Select from '@/components/common/select/Select.vue'
import ButtonGreen from '@/components/common/buttons/ButtonGreen.vue'
import TextEditor from '@/components/common/editor/TextEditor.vue'
import EditorViewer from '@/components/common/editor/EditorViewer.vue'

@Component<EditorModal>({
    components: {
        Modal,
        Select,
        TextEditor,
        EditorViewer,
        ButtonGreen
    }
})

export default class EditorModal extends Vue {
    @Ref() readonly editor!: TextEditor

    // *************************************************************
    // PROPS
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean
    @Prop({ default: '' }) value!: string
    @Prop({ default: false }) canChangeRequisite!: boolean
    @Prop({ default: () => []}) documentRequisites!: Array<DocumentRequisite>

    // *************************************************************
    // DATA PARAMS
    requisiteId = ''
    title = ''
    model = ''

    // *************************************************************
    // COMPUTED
    @Getter('document/getDocumentById') getDocumentById!: (documentId: string) => Document | null

    get requisites(): Array<SelectOption> {
        return this.documentRequisites
            .filter((req: DocumentRequisite) => req.type.type !== 'req_editor' && req.value)
            .map((req: DocumentRequisite) => {
                return {
                    id: req.id,
                    name: req.type.name
                }
            })
    }

    // *************************************************************
    // WATCH
    @Watch('value', { immediate: true })
    handleValueChange(val: string): void {
        this.model = val
    }

    // *************************************************************
    // METHODS
    copyRequisite(): void {
        console.log('copyRequisite')
        const requisite = this.documentRequisites.find((req: DocumentRequisite) => req.id === this.requisiteId)
        if (!requisite) {
            return
        }

        if (requisite.type.type === 'req_number' || requisite.type.type === 'req_string') {
            this.model = this.model + `<p>${requisite.value}</p>`
        }

        if (requisite.type.type === 'req_do') {
            this.model = this.model + `<p>${requisite.recordName}</p>`
        }

        if (requisite.type.type === 'req_date') {
            const value = dayjs.unix(Number(requisite.value) / 1000).format('DD/MM/YYYY HH:mm')
            this.model = this.model + `<p>${value}</p>`
        }

        if (requisite.type.type === 'req_table') {
            let valueToObject = JSON.parse(requisite.value)
            let head = ''
            valueToObject.headlines.forEach((h: string) => head = head + `<th>${h}</th>`)
            const thead = `<thead><tr>${head}</tr></thead>`
            let body = ''
            valueToObject.data.forEach((b: Array<string>) => {
                let row = ''
                b.forEach((td: string) => row = row + `<td>${td}</td>`)
                body = body + `<tr>${row}</tr>`
            })
            const tbody = `<tbody>${body}</tbody>`

            this.model = this.model + `<table ref="table">${thead}${tbody}</table>`
        }

        this.editor.insertText(`${this.model}`)
    }

    save(): void {
        this.emitChangeItem(this.model)
        this.syncedVisible = false
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(val: string): string {
        return val
    }

    @Emit('changeItem')
    emitChangeItem(val: string): string {
        return val
    }
}

