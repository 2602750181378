import { ActionTree } from 'vuex'
import { RootState } from '@/store'

import { UserOptionsState } from '@/store/user_options/user_options'
import { DocumentListFilters } from '@/model/UserOptions'

const UserOptionsActions: ActionTree<UserOptionsState, RootState> = {
    setDocumentListFilter({ commit }, params: { docTypeId: string, mut: (filter: DocumentListFilters) => void  }) {
        commit('setDocumentListFilter', params)
    },

    setDocumentHiddenCells({ commit }, params: { docTypeId: string, cells: Array<string> }) {
        commit('setDocumentHiddenCells', params)
    },

    resetDocumentListFilter({ commit }, docTypeId: string) {
        commit('resetDocumentListFilter', docTypeId)
    },

    setReadNewsIds({ commit }, readNewsIds: Array<string>) {
        commit('setReadNewsIds', readNewsIds)
    },

    setArchivedDocuments({ commit }, docTypeId: string) {
        commit('setArchivedDocuments', docTypeId)
    },

    setArchived({ commit }, key: string) {
        commit('setArchived', key)
    }
}

export default UserOptionsActions
