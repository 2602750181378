
























































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { DocumentEvent } from '@/model/Document'

import Checkbox from '@/components/common/inputs/Checkbox.vue'
import TextareaNew from '@/components/common/inputs/TextareaNew.vue'
import DocumentChartCard from '@/components/document/common_document/chat/DocumentChartCard.vue'

@Component({
    components: {
        TextareaNew,
        DocumentChartCard,
        Checkbox
    }
})

export default class DocumentChat extends Vue {
    @Ref() readonly chatBody!: HTMLDivElement

    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) events!: Array<DocumentEvent>
    @Prop({ default: 'none' }) title!: string
    @Prop({ default: '' }) openDocumentId!: string

    // *************************************************************
    // DATA PARAMS
    message = ''
    checkEvents = true
    checkMessages = true
    loadingMessage = false

    // *************************************************************
    // COMPUTED
    get filteredEvents(): Array<DocumentEvent> {
        // Фильтруем показывать или скрывать пользовательские сообщения в Chat
        if (this.checkMessages && !this.checkEvents) {
            // Сообщения
            return this.events.filter((event: DocumentEvent) => event.type === 'message')
        }

        if (!this.checkMessages && this.checkEvents) {
            // События
            return this.events.filter((event: DocumentEvent) => event.type !== 'message')
        }

        return this.events
    }

    // *************************************************************
    // WATCH
    @Watch('events', { immediate: true })
    handleEventsChange(): void {
        // Наблюдаем за массивом событий в store - при изменениях окно chat прокручивается автоматически в самый низ диалогового окна
        // Это необходимо оставить в watch, а не переносить в метод, т.к. события могут быть добавлены другими пользователями
        this.$nextTick(this.scrollChat)
    }

    // *************************************************************
    // METHODS
    @Action('document/callPutMessage')
    callPutMessage!: (params: cuntPB.DocumentPutMessageReq) => Promise<DocumentEvent>

    onCheckMessages(checkMessages: boolean): void {
        // Если выключен,то автоматически включаем другую галочку
        if (!checkMessages) {
            this.checkEvents = true
        }
    }

    onCheckEvents(checkEvents: boolean): void {
        // Если выключен,то автоматически включаем другую галочку
        if (!checkEvents) {
            this.checkMessages = true
        }
    }

    spendEvent(): void {
        console.log('spendEvent', this.message, this.loadingMessage)
        if (!this.message || this.loadingMessage) {
            return
        }

        this.loadingMessage = true
        this.callPutMessage({
            Document: this.openDocumentId,
            Message: this.message
        })
            .then((event: DocumentEvent) => {
                this.$snotify.success('Добавлено сообщение')
                this.emitAddEvent(event)
                this.message = ''
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingMessage = false
            })
    }

    scrollChat(): void {
        // Метод для прокрутки в самый низ диалогового окна chat
        // Узнаем высоту элемента 'chat-body' вместе с высотой прокрутки.
        // В зависимости от браузера могут быть различия, поэтому выбираем из всех возможных вариантов максимальное значение
        // И прокручиваем данный элемент на это значение
        this.chatBody.scrollTop = Math.max(
            this.chatBody.scrollHeight,
            this.chatBody.offsetHeight,
            this.chatBody.clientHeight
        )
    }

    // *************************************************************
    // EMIT
    @Emit('addEvent')
    emitAddEvent(event: DocumentEvent): DocumentEvent {
        return event
    }
}
