var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "box-shadow__double border-4 p-1 c-pointer"
  }, _vm.$listeners), [_c('div', {
    staticClass: "flex-row-center justify-between"
  }, [_vm.userName ? _c('div', {
    staticClass: "fs-20"
  }, [_vm._v("\n            " + _vm._s(_vm.userName) + "\n        ")]) : _c('div', {
    staticClass: "flex-row-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.task.assignee.user.name.shortName))]), _vm._v(" "), _c('FAIcon', {
    staticClass: "pink-1 mx-1",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "icon": "long-arrow-alt-left"
    }
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.task.author.user.name.shortName))])], 1), _vm._v(" "), _vm.options.status ? _c('div', {
    class: _vm.statusColor
  }, [_vm._v("\n            " + _vm._s(_vm.options.status) + "\n        ")]) : _c('div', _vm._l(_vm.options.buttons, function (button) {
    return _c('ButtonViolet', {
      key: button.key,
      staticClass: "ml-3",
      attrs: {
        "text": button.text,
        "small": true
      },
      on: {
        "click": [function ($event) {
          $event.stopPropagation();
        }, function ($event) {
          return _vm.action(button.key);
        }]
      }
    });
  }), 1)]), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center justify-between mt-3"
  }, [_c('div', {
    staticClass: "text-wrap py-1"
  }, [_vm._v("\n            " + _vm._s(_vm.task.text) + "\n        ")]), _vm._v(" "), !_vm.userName && _vm.task.confirmer ? _c('div', [_vm._v("\n            Подтверждает: " + _vm._s(_vm.task.confirmer.user.name.shortName) + "\n        ")]) : _vm._e()]), _vm._v(" "), _vm.task.document && _vm.tab ? _c('Attribute', {
    staticClass: "mt-1",
    attrs: {
      "attribute": _vm.typeName
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": _vm.documentPath
    }
  }, [_vm._v("\n            " + _vm._s(_vm.task.document.name) + "\n        ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }