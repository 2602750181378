




















import { Component, Prop, Vue } from 'vue-property-decorator'

import { DocumentEvent, DocumentEventLinkedTask } from '@/model/Document'

import DocumentEventBase from '@/components/document/common_document/chat/document_events/DocumentEventBase.vue'

@Component({
    components: { DocumentEventBase }
})

export default class DocumentEventTaskCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) event!: DocumentEvent

    get name(): string {
        if (this.event.fields instanceof DocumentEventLinkedTask) {
            return this.event.fields.displayName
        }
        return ''
    }

    get fields(): Array<{ id: string, name: string, body: string }> {
        const fields = this.event.fields

        if (fields instanceof DocumentEventLinkedTask) {

            const newFields = [
                {
                    id: 'text',
                    name: 'Задача',
                    body: fields.text
                }
            ]

            let author = ''
            if (fields.author.user) {
                author = fields.author.user.name.shortName
            }
            if (fields.author.unit) {
                author += ` ${fields.author.unit.name}`
            }
            newFields.push({
                id: 'author',
                name: 'Автор',
                body: author.trim()
            })

            let assignee = ''
            if (fields.assignee.user) {
                assignee = fields.assignee.user.name.shortName
            }
            if (fields.assignee.unit) {
                assignee += ` ${fields.assignee.unit.name}`
            }
            newFields.push({
                id: 'assignee',
                name: 'Исполнитель',
                body: assignee.trim()
            })

            let confirmer = ''
            if (!fields.confirmer) {
                confirmer = 'Не требует подтверждения'
            } else {
                if (fields.confirmer.user) {
                    confirmer = fields.confirmer.user.name.shortName
                }
                if (fields.confirmer.unit) {
                    confirmer += ` ${fields.confirmer.unit.name}`
                }
            }
            newFields.push({
                id: 'confirmer',
                name: 'Подтверждает',
                body: confirmer.trim()
            })

            return newFields
        }

        return []
    }
}
