var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.centers.length > 1 ? _c('Select', {
    staticClass: "h-40",
    attrs: {
      "options": _vm.centers,
      "required": "",
      "placeholder": "Выбрать организацию"
    },
    on: {
      "change": function change($event) {
        return _vm.emitInput($event);
      }
    },
    model: {
      value: _vm.centerId,
      callback: function callback($$v) {
        _vm.centerId = $$v;
      },
      expression: "centerId"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.centerId ? _c('div', {
    staticClass: "form__error"
  }, [_vm._v("Необходимо выбрать организацию")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }