var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "circle-spinner"
  }, [_c('svg', {
    attrs: {
      "viewBox": "0 0 100 100"
    }
  }, [_c('defs', [_c('filter', {
    attrs: {
      "id": "shadow"
    }
  }, [_c('feDropShadow', {
    attrs: {
      "dx": "0",
      "dy": "0",
      "stdDeviation": "1.5",
      "flood-color": "#fc6767"
    }
  })], 1)]), _vm._v(" "), _c('circle', {
    staticStyle: {
      "fill": "transparent",
      "stroke": "#3700B3",
      "stroke-width": "9px",
      "stroke-linecap": "round",
      "filter": "url(#shadow)"
    },
    attrs: {
      "id": "spinner",
      "cx": "50",
      "cy": "50",
      "r": "45"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }