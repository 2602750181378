import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { DocumentTypeState } from '@/store/document_type/documentTypeState'
import { service, cuntPB } from 'api'

import { DocumentType } from '@/model/DocumentType'
import { DashboardItem } from '@/store/items/model'

const TypeActions: ActionTree<DocumentTypeState, RootState> = {
    putDocumentType({ state, commit, dispatch, rootGetters }, type: DocumentType) {
        // Получаем по id информацию по данному type, которая хранится в state
        const existingType = state.documentTypes.find((t: DocumentType) => t.id === type.id)
        if (existingType) {
            if (type.modificationTime > existingType.modificationTime) {
                // Вызываем мутацию для добавлени нового unit в state
                commit('putDocumentType', type)
                dispatch('items/setDocumentItem', new DashboardItem(
                    type.id,
                    type.name,
                    type.description,
                    'documents',
                    'DocumentList',
                    'CommonDocument',
                    ''
                ), { root: true })
            }
        } else {
            commit('putDocumentType', type)
            dispatch('items/setDocumentItem', new DashboardItem(
                type.id,
                type.name,
                type.description,
                'documents',
                'DocumentList',
                'CommonDocument',
                ''
            ), { root: true })

            const cells = rootGetters['user_options/getDocumentHiddenCells'](type.id)
            if (!cells) {
                dispatch('user_options/setDocumentHiddenCells', {
                    docTypeId: type.id,
                    cells: []
                }, { root: true })
            }
        }
    },

    async callCreateDocumentType({ dispatch }, params: cuntPB.CreateDocumentTypeReq) {
        const data: cuntPB.DocumentType = await service.documentType.Create(params)
        console.log('::DATA::DocumentTypeSvc.create:', data)

        dispatch('putDocumentType', new DocumentType(data))
    },

    async callSetName({ dispatch }, params: cuntPB.SetNameReq) {
        const data: cuntPB.DocumentType = await service.documentType.SetName(params)
        console.log('::DATA::DocumentTypeSvc.setName:', data)

        dispatch('putDocumentType', new DocumentType(data))
    },

    async callSetNumerator({ dispatch }, params: cuntPB.SetnumeratorDocumenttypereq) {
        const data: cuntPB.DocumentType = await service.documentType.SetNumerator(params)
        console.log('::DATA::DocumentTypeSvc.setNumerator:', data)

        dispatch('putDocumentType', new DocumentType(data))
    },

    async callSetDescription({ dispatch }, params: cuntPB.SetDescriptionReq) {
        const data: cuntPB.DocumentType = await service.documentType.SetDescription(params)
        console.log('::DATA::DocumentTypeSvc.setDescription:', data)

        dispatch('putDocumentType', new DocumentType(data))
    },

    async callSetCode({ dispatch }, params: cuntPB.SetCodeReq) {
        const data: cuntPB.DocumentType = await service.documentType.SetCode(params)
        console.log('::DATA::DocumentTypeSvc.setCode:', data)

        dispatch('putDocumentType', new DocumentType(data))
    },

    async callRemove({ dispatch }, id: string) {
        await service.documentType.Remove({ ID: id })
        console.log('::DATA::DocumentTypeSvc.remove:', id)

        dispatch('removeDocumentType', id)
    },

    removeDocumentType({ commit }, typeId: string) {
        commit('removeDocumentType', typeId)
        commit('items/removeDocumentItem', typeId, { root: true })
    }
}

export default TypeActions
