var render = function () {
  var _vm$news;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "news"
  }, [_c('div', {
    staticClass: "news__title",
    class: {
      'news__title_read': !!_vm.read
    }
  }, [_vm._v("\n        " + _vm._s(_vm.help ? _vm.title : _vm.news.title) + "\n    ")]), _vm._v(" "), _c('hr', {
    staticClass: "news__hr news__hr_big",
    class: {
      'news__hr_read': !!_vm.read
    }
  }), _vm._v(" "), _c('hr', {
    staticClass: "news__hr news__hr_small",
    class: {
      'news__hr_read': !!_vm.read
    }
  }), _vm._v(" "), !!((_vm$news = _vm.news) !== null && _vm$news !== void 0 && _vm$news.date) ? _c('div', {
    staticClass: "news__date",
    class: {
      'news__date_read': !!_vm.read
    }
  }, [_vm._v("\n        " + _vm._s(_vm.news.date) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "news__content",
    class: {
      'news__content_read': !!_vm.read
    }
  }, [_vm._t("content"), _vm._v(" "), !_vm.help ? _c('div', _vm._l(_vm.news.links, function (link, index) {
    return _c('div', {
      key: index
    }, [_vm._v("\n                " + _vm._s(link.description) + "\n                "), _c('RouterLink', {
      class: {
        'news__a_read': !!_vm.read
      },
      attrs: {
        "target": "self",
        "to": link.to
      }
    }, [_c('span', {
      on: {
        "click": _vm.emitClose
      }
    }, [_vm._v(_vm._s(link.title))])])], 1);
  }), 0) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }