


































































































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { cuntPB } from 'api'

import { Directory, DirectoryField, Record, RecordField } from '@/model/Directory'
import getError, { GrpcError } from '@/tools/errors/errors'

import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'
import EditRecord from '@/components/directory/EditRecord.vue'
import RemoveModal from '@/components/common/modals/RemoveModal.vue'

@Component({
    components: {
        EditRecord,
        ButtonDelete,
        RemoveModal
    }
})

export default class DirectoryRecords extends Vue {
    @Ref() readonly removeModal!: RemoveModal
    @Ref() readonly tableBody!: HTMLTableElement

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) searchText!: string
    @Prop({ default: () => null }) directory!: Directory // null on this page impossible
    @Prop({ default: '' }) unitId!: string
    @Prop({ default: false }) showAddRecord!: boolean

    // *************************************************************
    // DATA PARAMS
    cellWidth: Array<number> = []
    tableBodyWidth = 0
    selectedRecordId = ''
    showEditRecord = false
    visibleRemoveModal = false

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryRecords') getDirectoryRecords!: (unitId: string, directoryId: string) => Array<Record>

    get directoryFields(): Array<DirectoryField> {
        return this.directory.fields
    }

    get records(): Array<Record> {
        let records: Array<Record> = []
        if (this.directory.external && this.unitId) {
            records = this.getDirectoryRecords(this.unitId, this.directory.id)
        } else if (!this.directory.external) {
            records = this.getDirectoryRecords(this.directory.centerId, this.directory.id)
        }

        // Фильтрация по строке поиска
        const query = this.searchText.toLowerCase().trim()
        const isIncludeQuery = (rec: Record) => rec.fields.some((f: RecordField) => f.displayName.toLowerCase().includes(query))

        console.log('records', records)
        return records.filter(isIncludeQuery)
    }

    get recordsAccessible(): boolean {
        // Через ref получаем вторую таблицу, в которой отражены records - ее верстаем
        // в отдельном блоке, чтобы была возможность добавить overflow.
        // Проверяем произошел ли рендеринг, т.е. сработали все v-for
        // Данный компонент из родительского компонента получает directory,
        // только после этого сработают v-for
        const table = this.tableBody
        return !!(table && table.children && table.children[0])
    }

    // *************************************************************
    // WATCH
    @Watch('records', { immediate: true, deep: true })
    handleRecordsChange(to: Array<Record>): void {
        if (to.length) {
            // nextTick необходим, иначе метод сработает раньше, чем необходимо
            Vue.nextTick(this.realignHeader)
        }
    }

    // *************************************************************
    // METHODS
    @Action('directories/callSetRecordArchived')
    callSetRecordArchived!: (params: cuntPB.SetRecordArchivedReq) => Promise<void>

    @Action('directories/callRemoveRecord')
    callRemoveRecord!: (id: string) => Promise<void>

    realignHeader(): void {
        // Передаем ширину table body в data, чтобы потом установить ее в шапку таблицы,
        // иначе она съедет на ширину прокрутки
        this.tableBodyWidth = this.tableBody.offsetWidth
        // Вычисляем ширину каждой ячейки в первой строке в body-table
        // передаем эти размеры в data в cellWidth, чтобы по ним устанавливать
        // ширину ячеек в заголовке таблицы
        const table = this.tableBody
        if (this.recordsAccessible) {
            for (let i = 0; i < table.children[0].children.length; i++) {
                if (this.cellWidth[i] !== table.children[0].children[i].clientWidth) {
                    Vue.set(
                        this.cellWidth,
                        i,
                        table.children[0].children[i].clientWidth + 1
                    )
                }
            }
        }
    }

    editRecord(recordId: string): void {
        // Показываем компонент для редактирования
        this.showEditRecord = true
        this.selectedRecordId = recordId
    }

    closeEditRecord(): void {
        this.selectedRecordId = ''
        this.showEditRecord = false
        this.emitCloseAddRecord()
    }

    showRemove(recordId: string): void {
        this.removeModal.show({
            itemName: '',
            itemType: 'Элемента справочника',
            warning: '',
            callback: () => {
                this.remove(recordId)
            }
        })
    }

    remove(recordId: string): void {
        const cancelPreloaderRemoveDirectoryRecord = this.$preloader('remove_directory_record', 'Удаление элемента справочника')

        this.callRemoveRecord(recordId)
            .then(() => {
                this.visibleRemoveModal = false

                // Закрываем окно добавления record
                this.emitClose()
            })
            .catch((error: GrpcError) => {
                if (error.message === 'record is used') {
                    // Если справочник используется в документах - предлагаем перевести его в архив
                    const answer = window.confirm('Элемент не может быть удален! Он используется в созданных документах! Перевести его в Архив?')
                    if (answer) {
                        this.archive(recordId, true)
                    }
                } else {
                    this.$snotify.error(getError(error))
                }
            })
            .finally(() => cancelPreloaderRemoveDirectoryRecord())

    }

    archive(recordId: string, archived: boolean): void {
        const cancelPreloaderSetArchivedDirectoryRecord = this.$preloader('set_archive_directory_record', 'Перемещение элемента справочника в Архив')
        this.callSetRecordArchived({
            ID: recordId,
            Archived: archived
        })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderSetArchivedDirectoryRecord())
    }

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose(): void {
        // Do nothing
    }

    @Emit('closeAddRecord')
    emitCloseAddRecord(): void {
        // Do nothing
    }
}
