


















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { StageTemplate } from '@/model/Workflow'

@Component({})

export default class WFTStage extends Vue {
    @Prop({ default: '' }) title!: string
    @Prop({ default: '' }) active!: boolean // если блок сейчас активен
    @Prop({ default: null }) stage!: StageTemplate | null
    @Prop({ default: '' }) first!: boolean // если блок первый в списке
    @Prop({ default: '' }) last!: boolean // если блок последний в списке

    // *************************************************************
    // COMPUTED
    get showHeaderBorder(): boolean {
        if (!this.stage) {
            return false
        }
        if (this.stage.type === 'approval' && !this.stage.approvals.length && !this.stage.vvs) {
            return true
        }
        return this.stage.type !== 'approval'
    }

    get stageType(): string {
        if (!this.stage) {
            return ''
        }
        return this.stage.type
    }

    // *************************************************************
    // EMIT
    @Emit('clickStage')
    emitClickStage(): void {
        // Do nothing
    }

    @Emit('moveUp')
    emitMoveUp(): void {
        // Do nothing
    }

    @Emit('moveDown')
    emitMoveDown(): void {
        // Do nothing
    }
}
