var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DocumentEventBase', {
    attrs: {
      "event": _vm.event,
      "name": "Согласовано"
    }
  }, [_vm.approvedUnit ? _c('div', {
    attrs: {
      "slot": "fields"
    },
    slot: "fields"
  }, [_c('div', [_vm._v("Подразделение:")]), _vm._v(" "), _c('div', {
    staticClass: "fw-600"
  }, [_vm._v(_vm._s(_vm.approvedUnit))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }