


















import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Task } from '@/model/Task'

import Modal from '@/components/common/modals/Modal.vue'
import TaskCardFull from '@/components/tasks/cards/TaskCardFull.vue'

@Component({
    components: {
        Modal,
        TaskCardFull
    }
})

export default class TaskModal extends Vue {
    // *************************************************************
    // DATA PARAMS
    taskId = ''
    visibleModal = false

    // *************************************************************
    // COMPUTED
    @Getter('task/getTaskById') getTaskById!: (id: string) => Task | null

    get task(): Task | null {
        return this.getTaskById(this.taskId)
    }

    // *************************************************************
    // WATCH
    @Watch('$route')
    handleRouteChange(): void {
        this.hide()
    }

    // *************************************************************
    // METHODS
    show(taskId: string): void {
        this.visibleModal = true
        this.taskId = taskId
    }

    hide(): void {
        this.visibleModal = false
    }

    close(): void {
        this.taskId = ''
    }
}
