var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.task ? _c('div', {
    staticClass: "task-full-card"
  }, [_c('div', {
    staticClass: "task-full-card__main task-full-card__grid"
  }, [_c('div', {
    staticClass: "task-full-card__grid"
  }, [_c('Attribute', {
    attrs: {
      "attribute": "ID",
      "text": _vm.task.id
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Статус"
    }
  }, [_c('span', {
    class: _vm.statusColor
  }, [_vm._v(_vm._s(_vm.status))])]), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Задача"
    }
  }, [_c('span', {
    staticClass: "fs-18"
  }, [_vm._v(_vm._s(_vm.task.text || 'Без названия'))])]), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Описание",
      "text": _vm.task.description || 'Без описания'
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Автор",
      "text": _vm.author
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Исполнитель"
    }
  }, [_c('span', [_vm.assignees.length ? _c('Select', {
    key: "assignees",
    staticClass: "task__select",
    attrs: {
      "value": _vm.task.assignee.user.id,
      "options": _vm.assignees,
      "loading": _vm.loadingDelegate === 'assignee',
      "placeholder": "Изменить исполнителя"
    },
    on: {
      "change": function change($event) {
        return _vm.delegate($event, 'assignee');
      }
    }
  }) : _c('span', {
    key: "assignee"
  }, [_vm._v(_vm._s(_vm.assignee))])], 1)]), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Контроль"
    }
  }, [_c('span', [_vm.confirmers.length ? _c('Select', {
    key: "confirmers",
    staticClass: "task__select",
    attrs: {
      "value": _vm.task.confirmer.user.id,
      "options": _vm.confirmers,
      "loading": _vm.loadingDelegate === 'confirmer',
      "placeholder": "Изменить подтверждающего"
    },
    on: {
      "change": function change($event) {
        return _vm.delegate($event, 'confirmer');
      }
    }
  }) : _c('span', {
    key: "confirmer"
  }, [_vm._v(_vm._s(_vm.confirmer))])], 1)]), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Создана",
      "text": _vm.task.creationTimeLocalizedShortString
    }
  }), _vm._v(" "), _vm.task.document ? _c('Attribute', {
    attrs: {
      "attribute": _vm.typeName
    }
  }, [_c('router-link', {
    staticClass: "link fs-18",
    attrs: {
      "to": _vm.documentPath
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.task.document.name) + "\n                ")])], 1) : _vm._e(), _vm._v(" "), _vm.task.dueTime ? _c('Attribute', {
    attrs: {
      "attribute": "Выполнить до"
    }
  }, [_vm.task.dueTime ? _c('span', {
    key: "dueTime",
    class: {
      'red': _vm.task.dueTime < _vm.time
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.task.dueTimeLocalizedShortString) + "\n                ")]) : _c('span', {
    key: "notDueTime"
  }, [_vm._v("Срок не установлен")])]) : _vm._e(), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Есть родительская задача",
      "text": _vm.task.parent ? 'Да' : 'Нет'
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Есть дочерние задачи",
      "text": _vm.task.children.length ? 'Да' : 'Нет'
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "task-full-card__grid mt-auto"
  }, _vm._l(_vm.buttons, function (button) {
    return _c('ButtonViolet', {
      key: button.key,
      staticClass: "task-full-card__button",
      attrs: {
        "text": button.text
      },
      on: {
        "click": function click($event) {
          return _vm.action(button.key);
        }
      }
    });
  }), 1)]), _vm._v(" "), _c('TaskChat', {
    staticClass: "task__chat",
    attrs: {
      "events": _vm.filterEvents,
      "event-field-types": _vm.eventFieldTypes
    },
    on: {
      "spendMessage": function spendMessage($event) {
        return _vm.spendMessage($event);
      },
      "switchMessages": function switchMessages($event) {
        _vm.typeMessages = $event;
      },
      "switchEvents": function switchEvents($event) {
        _vm.typeEvents = $event;
      }
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }