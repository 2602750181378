var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "work__version"
  }, [_vm._v("\n        Версия от 22 ноября 2022г.\n    ")]), _vm._v(" "), _c('Transition', {
    attrs: {
      "name": "routerTransitionRoot"
    }
  }, [_c('RouterView')], 1), _vm._v(" "), _c('SideBar'), _vm._v(" "), _c('InfoModal'), _vm._v(" "), _c('ScalePreloader')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }