export default function ({ state, commit }, { numeratorId, fieldId }) {
    let existingNumerator = state.numerators.find(num => num.id === numeratorId)

    commit('saveVersion', {
        numerator: existingNumerator
    })

    commit('removeField', {
        numeratorId,
        fieldId
    })
}
