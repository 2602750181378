<template>
    <div
        v-if="familiarization"
        class="my-3"
    >
        <div class="flex-row-center justify-between mb-3">
            <Checkbox
                v-model="familiarized"
                label="Ознакомлены"
            />
            <Checkbox
                v-model="notFamiliarized"
                label="Не ознакомлены"
            />
            <div
                tooltip="Распечатать журнал"
                flow="left"
                class="familiarization__print"
                @click="print"
            >
                <FAIcon icon="print" />
            </div>
        </div>

        <table
            ref="table"
            class="table"
        >
            <thead>
                <th>Статус</th>
                <th>Пользователь</th>
                <th>Дата</th>
            </thead>
            <tbody>
                <tr
                    v-for="item in checklist"
                    :key="item.user.id"
                >
                    <td :style="{color: item.status ? 'black': 'red'}">
                        {{ item.status ? 'Ознакомлен' : 'Не ознакомлен' }}
                    </td>
                    <td>{{ item.user.name.fullName }}</td>
                    <td>
                        <span
                            v-if="item.when"
                            :style="{color: (familiarization.until && (item.when / 1000) > familiarization.until) ? 'red': 'black'}"
                        >{{ item.creationTimeLocalizedLongString }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Printd from 'printd'
import Checkbox from '@/components/common/inputs/Checkbox'

export default {
    components: { Checkbox },
    data() {
        return {
            familiarized: true,
            notFamiliarized: true
        }
    },
    computed: {
        ...mapGetters('familiarization', ['getFamiliarizationById']),
        familiarizationId() {
            return this.$route.params.id
        },
        familiarization() {
            return this.getFamiliarizationById(this.familiarizationId)
        },
        checklist() {
            if (!this.familiarization || !this.familiarization.сheckList.length) {
                return []
            }
            return this.familiarization.сheckList.filter(item => {
                if (!this.familiarized && item.status) {
                    return false
                }
                if (!this.notFamiliarized && !item.status) {
                    return false
                }
                return true
            })
        }
    },
    mounted() {
        this.d = new Printd()
    },
    methods: {
        print() {
            this.d.print( this.$refs.table, [
                'table { border: 2px solid black; border-collapse: collapse; width: 100% }',
                'th { border: 2px solid black; border-collapse: collapse; height: 90px; font-size: 14px; padding: 5px }',
                'td { border: 2px solid black; border-collapse: collapse; height: 50px; font-size: 14px; padding: 5px }'
            ])
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/table.styl'
@import '~@/assets/styles/colors.styl'

.familiarization__print
    margin-left auto
    color $pink-1
    font-size 20px
    cursor pointer
</style>
