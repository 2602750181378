

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import { cuntPB } from 'api'
import getError, { GrpcError } from '@/tools/errors/errors'
import { DocumentRequisite } from '@/model/Document'

import InputNumber from '@/components/common/inputs/InputNumber.vue'
import InputBase from '@/components/common/inputs/InputBase.vue'
import SelectDocumentRequisiteCard from '@/components/document/common_document/requisites/SelectDocumentRequisiteCard.vue'
import Datepicker from '@/components/common/datepicker/Datepicker.vue'

@Component<DocumentRequisiteCard>({
    components: {
        InputNumber,
        InputBase,
        SelectDocumentRequisiteCard,
        Datepicker
    }
})

export default class DocumentRequisiteCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) requisite!: DocumentRequisite
    @Prop({ default: false }) canChangeRequisite!: boolean
    @Prop({ default: false }) deleted!: boolean

    // *************************************************************
    // DATA PARAMS
    requisiteValue = ''

    loadingRequisite = false

    // *************************************************************
    // COMPUTED
    @Getter('directories/getRecordDisplayName') getRecordDisplayName!: (recordId: string) => string

    get disabled(): boolean {
        if (this.requisite.type.editable) {
            // Если это Редактируемый реквизит, то его можно редактировать всегда
            return false
        }

        return this.loadingRequisite || !this.canChangeRequisite
    }

    // *************************************************************
    // WATCH
    @Watch('requisite', { immediate: true, deep: true })
    handleRequisiteChange(to: DocumentRequisite): void {
        if (to) {
            if (to.type.type !== 'req_table') {
                this.requisiteValue = to.value
            }
        }
    }

    // *************************************************************
    // METHODS
    @Action('document/callUpdateRequisite')
    callUpdateRequisite!: (params: cuntPB.UpdateDocumentRequisiteReq) => Promise<void>

    // 'req_number' | 'req_string' | 'req_date'
    updateSimple(): void {
        if (this.disabled) {
            return
        }

        const newValue = String(this.requisiteValue).trim()

        if (newValue === this.requisite.value) {
            return
        }

        this.updateRequisite(newValue, '')
    }

    // req_editor
    updateEditor(val: string): void {
        if (this.disabled) {
            return
        }

        if (val === this.requisite.value) {
            return
        }

        this.updateRequisite(val, '')
    }

    // req_do
    updateRecord(recordId: string): void {
        if (this.disabled) {
            return
        }

        if (recordId === this.requisite.value) {
            return
        }

        this.updateRequisite(recordId, this.getRecordDisplayName(recordId))
    }

    updateRequisite(value: string, recordName: string): void {
        console.log('updateRequisite', value)
        this.loadingRequisite = true

        this.callUpdateRequisite({
            ID: this.$route.params.id,
            Requisite: this.requisite.id,
            Value: value,
            RecordName: recordName
        })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingRequisite = false
            })
    }
}
