var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    class: {
      'side-bar-item': true,
      blink: _vm.event
    },
    attrs: {
      "event": _vm.pending
    }
  }, _vm.$listeners), [_c('FAIcon', {
    class: {
      icon: true,
      active: _vm.active
    },
    attrs: {
      "icon": _vm.icon
    }
  }), _vm._v(" "), _c('hr')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }