import api from 'api1'

export default function (_, { docType, template }) {
    // Собираем массив stages
    let stages = template.stages.map(stage => {
        let s = {}
        s.Type = stage.type
        s.DisplayName = stage.displayName
        if (stage.type === 'approval') {
            s.Approvals = api.cunt.WorkflowTemplateStageApprovals({
                Approvals: stage.approvals.map(approval => {
                    if (approval.actor) {
                        return api.cunt.ApprovalTemplate({
                            ID: approval.id,
                            SpecialName: approval.specialName,
                            Actor: approval.actor.user ? api.cunt.Actor({
                                User: api.cunt.UserInfo({
                                    ID: approval.actor.user.id,
                                    Name: api.cunt.UserName({
                                        First: approval.actor.user.name.first,
                                        Middle: approval.actor.user.name.middle,
                                        Last: approval.actor.user.name.last
                                    })
                                })
                            }) : api.cunt.Actor({
                                Unit: api.cunt.UnitInfo({
                                    ID: approval.actor.unit.id,
                                    Name: approval.actor.unit.name
                                })
                            }),
                            Inputs: approval.inputs
                        })
                    } else {
                        return api.cunt.ApprovalTemplate({
                            ID: approval.id,
                            SpecialName: approval.specialName,
                            Inputs: approval.inputs
                        })
                    }
                })
            })
        } else if (stage.type === 'execution') {
            s.Tasks = api.cunt.WorkflowTemplateStageTasks({
                Tasks: stage.tasks.map(task => api.cunt.TaskTemplate({
                    ID: task.id,
                    Assignee: task.assignee.user ? api.cunt.Actor({
                        User: api.cunt.UserInfo({
                            ID: task.assignee.user.id,
                            Name: api.cunt.UserName({
                                First: task.assignee.user.name.first,
                                Middle: task.assignee.user.name.middle,
                                Last: task.assignee.user.name.last
                            })
                        })
                    }) : api.cunt.Actor({
                        Unit: api.cunt.UnitInfo({
                            ID: task.assignee.unit.id,
                            Name: task.assignee.unit.name
                        })
                    }),
                    Text: task.text,
                    Description: task.description,
                    Confirmer: !task.confirmer ? null : task.confirmer.user ? api.cunt.Actor({
                        User: api.cunt.UserInfo({
                            ID: task.confirmer.user.id,
                            Name: api.cunt.UserName({
                                First: task.confirmer.user.name.first,
                                Middle: task.confirmer.user.name.middle,
                                Last: task.confirmer.user.name.last
                            })
                        })
                    }) : task.confirmer.unit ?  api.cunt.Actor({
                        Unit: api.cunt.UnitInfo({
                            ID: task.confirmer.unit.id,
                            Name: task.confirmer.unit.name
                        })
                    }) : api.cunt.Actor({}),
                    Inputs: task.inputs
                }))
            })
        }
        return api.cunt.WorkflowTemplateStage(s)
    })


    return new Promise((resolve, reject) => {
        api.cunt.WorkflowSvcClient.setWorkflowTemplate(api.cunt.SetWorkflowTemplateReq({
            DocType: docType,
            Template: api.cunt.WorkflowTemplate({
                VVS: template.vvs,
                Static: template.staticFlag,
                Stages: stages
            })
        }), data => {
            console.log('::DATA::WorkflowSvc.setWorkflowTemplate:', data)

            resolve()
        }, error => {
            (error ? console.warn : console.log)('::DONE::WorkflowSvc.setWorkflowTemplate:', error)
            reject(error)
        })
    })
}

