<template>
    <HelpCard
        title="Работа с документом"
        :help="true"
    >
        <template slot="content" />
    </HelpCard>
</template>

<script>
import HelpCard from '@/components/about_system/HelpCard'

export default {
    name: 'HelpToWorkWithDocument',
    components: {
        HelpCard
    }
}
</script>

<style lang="stylus" scoped>
</style>
