













































































































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import getError, { GrpcError } from '@/tools/errors/errors'
import { cuntPB } from 'api'

import { Directory, DirectoryField } from '@/model/Directory'
import { TDirectoryField } from '@/components/directory/model'

import InputBase from '@/components/common/inputs/InputBase.vue'
import Button from '@/components/common/buttons/Button.vue'
import DirectoryColumn from '@/components/directory/DirectoryColumn.vue'
import RemoveModal from '@/components/common/modals/RemoveModal.vue'

@Component({
    components: {
        InputBase,
        Button,
        DirectoryColumn,
        RemoveModal
    }
})

export default class EditDirectory extends Vue {
    @Ref() readonly removeModal!: RemoveModal

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) centerId!: string

    // *************************************************************
    // DATA PARAMS
    name = ''
    fields: Array<TDirectoryField> = []
    displayName = ''

    loadingName = false
    loadingDisplayName = false

    visibleRemoveModal = false

    // *************************************************************
    // COMPUTED
    @Getter('directories/getDirectoryById') getDirectoryById!: (dirId: string) => Directory | null

    get directoryId(): string {
        return this.$route.params.id
    }

    get directory(): Directory | null {
        return this.getDirectoryById(this.directoryId)
    }

    get newFields(): Array<TDirectoryField> {
        return this.fields.filter((f: TDirectoryField) => f.new)
    }

    get errorFields(): boolean {
        if (this.directory?.external) {
            return false
        }
        return !this.fields.some(f => f.required)
    }

    get canSave(): { disabled: boolean, reason: string } {
        console.log('fields----', this.fields)
        if (!this.centerId) {
            return {
                disabled: true,
                reason: 'Необходимо выбрать организацию'
            }
        } else if (!this.name.trim()) {
            return {
                disabled: true,
                reason: 'Имя справочника не может быть пустым'
            }
        } else if (!this.fields.length) {
            return {
                disabled: true,
                reason: 'В справочнике должно быть хотя бы одно поле'
            }
        } else if (!this.fields.every((f: TDirectoryField) => (f.name && f.type && ((f.type !== 'reference') || (f.type === 'reference' && f.subType))))) {
            return {
                disabled: true,
                reason: 'В каждом поле должны быть заполнены имя и тип, а в случае типа Справочник - выбран Справочник'
            }
        } else if (!this.fields.some((f: TDirectoryField) => f.required)) {
            return {
                disabled: true,
                reason: 'Должно быть хотя бы одно обязательное поле'
            }
        } else if (!this.displayName.trim()) {
            return {
                disabled: true,
                reason: 'Отображаемое значение справочника должно быть заполнено'
            }
        } else {
            return {
                disabled: false,
                reason: ''
            }
        }
    }

    get addFieldDisabled(): { disabled: boolean, reason: string } {
        const thisDirectory = this.directory
        if (!thisDirectory) {
            return {
                disabled: false,
                reason: ''
            }
        }

        if (thisDirectory.external) {
            return {
                disabled: true,
                reason: 'Нельзя добавлять поля в справочники внешних систем'
            }
        }

        if (this.fields.length > thisDirectory.fields.length) {
            return {
                disabled: true,
                reason: 'Сначала сохраните предыдущее поле'
            }
        }

        return {
            disabled: false,
            reason: ''
        }
    }

    /** Функция подстановки в строку конкретных значений между {} */
    get resultDisplayName(): string {
        if (!this.displayName) {
            return ''
        }

        return this.displayName.replace(/\{(?:[0-9]+)\}/gi, (match) => {
            const n = +match.slice(1, match.length - 1)

            if (!this.fields[n]) {
                return `Поля №${n} не существует!`
            } else {
                return '' + this.fields[n].name
            }
        })
    }

    // *************************************************************
    // WATCH
    @Watch('directory', { immediate: true, deep: true })
    handleDirectoryChange(to: Directory | null): void {
        if (to) {
            if (!this.loadingName) {
                this.name = to.name
            }
            if (!this.loadingDisplayName) {
                this.displayName = to.displayNameFormatString
            }

            // Выводим columns
            if (to.fields.length) {
                const requiredFields = to.fields.filter((f: DirectoryField) => f.required)

                this.fields = to.fields.map((field: DirectoryField, index: number) => {
                    let removeDisabledReason = ''
                    if (to.fields.length === 1) {
                        removeDisabledReason = 'Запрещено удалять последнее поле'
                    } else if (field.required && requiredFields.length === 1) {
                        removeDisabledReason = 'Должно быть минимум одно обязательное поле'
                    }
                    return Object.assign({
                        index,
                        removeDisabledReason,
                        new: false,
                        deleted: false
                    }, field)
                })
            } else {
                this.fields = []
            }
        } else {
            this.name = ''
            this.fields = [{
                index: 0,
                removeDisabledReason: 'Запрещено удалять последнее поле',
                new: true,
                name: '',
                required: true,
                deleted: false,
                type: '',
                subType: ''
            }]
            this.displayName = ''
        }
    }

    @Watch('canSave', { immediate: true, deep: true })
    handleCanSaveChange(to: { disabled: boolean, reason: string }): void {
        this.emitSaveDisabled(to)
    }

    @Watch('fields.length')
    handleFieldsLengthChange(val: number) {
        this.fields.forEach((f: TDirectoryField, i: number) => {
            this.fields[i].removeDisabledReason = val < 2 ? 'Запрещено удалять последнее поле' : ''
        })
    }

    // *************************************************************
    // METHODS
    @Action('directories/callCreateDirectory')
    callCreateDirectory!: (params: cuntPB.CreateDirectoryReq) => Promise<string>

    @Action('directories/callSetDirectoryName')
    callSetDirectoryName!: (params: cuntPB.SetDirectoryNameReq) => Promise<void>

    @Action('directories/callSetDirectoryDisplayName')
    callSetDirectoryDisplayName!: (params: cuntPB.SetDirectoryDisplayNameReq) => Promise<void>

    @Action('directories/callRemoveDirectoryField')
    callRemoveDirectoryField!: (params: cuntPB.RemoveDirectoryFieldReq) => Promise<void>

    create(): void {
        // Вызывается из родительского компонента
        if (this.canSave.disabled) {
            this.$snotify.warning(`Ошибка: "${this.canSave.reason}"`)
            return
        }

        if (!this.centerId) {
            this.$snotify.error('Ошибка системы. Обратитесь в службу поддержки: нет centerId')
            return
        }

        const cancelPreloaderCreateDirectory = this.$preloader('create_directory', 'Создание справочника')

        this.callCreateDirectory({
            CenterID: this.centerId,
            Name: this.name,
            Fields: this.fields.map((field: TDirectoryField) => {
                return {
                    Name: field.name,
                    Required: field.required,
                    Type: field.type,
                    SubType: field.subType
                }
            }),
            DisplayNameFormatString: this.displayName
        })
            .then((directoryId: string) => this.emitCreate(directoryId))
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderCreateDirectory())
    }

    updateName(): void {
        if (!this.directory) {
            return
        }

        const name = this.name.trim()
        if (name === this.directory?.name) {
            return
        }
        if (!name) {
            this.$snotify.warning('Имя справочника не может быть пустым')
            return
        }

        this.loadingName = true

        this.callSetDirectoryName({
            ID: this.directoryId,
            Name: this.name
        })
            .catch((error: GrpcError) => {
                this.name = this.directory?.name ?? ''
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingName = false
            })
    }

    updateDisplayName(): void {
        if (!this.directory) {
            return
        }

        const displayName = this.displayName.trim()
        if (displayName === this.directory?.displayNameFormatString) {
            return
        }
        if (!displayName) {
            this.$snotify.warning('Отображаемое значение справочника должно быть заполнено')
            return
        }

        this.loadingDisplayName = true

        this.callSetDirectoryDisplayName({
            ID: this.directoryId,
            DisplayName: this.displayName
        })
            .catch((error: GrpcError) => {
                this.displayName = this.directory?.displayNameFormatString ?? ''
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                this.loadingDisplayName = false
            })
    }

    addColumn(): void {
        if (this.addFieldDisabled.disabled) {
            this.$snotify.error(`Отклонено: "${this.addFieldDisabled.reason}"`, {
                timeout: 0
            })
            return
        }

        this.fields.push({
            index: this.fields.length,
            removeDisabledReason: '',
            new: true,
            name: '',
            required: false,
            deleted: false,
            type: '',
            subType: ''
        })
    }

    changeColumn(field: TDirectoryField): void {
        Vue.set(this.fields, field.index, field)
    }

    showRemoveColumn(index: number): void {
        // Если это новое поле - то без проблем удаляем
        if (this.fields[index].new) {
            this.fields.splice(index, 1)
            return
        }

        this.removeModal.show({
            itemName: this.fields[index].name,
            itemType: 'Поля',
            warning: 'Удаляя поле справочника Вы подтверждаете его удаление во всех элементах справочника',
            callback: () => {
                this.removeColumn(index)
            }
        })
    }

    removeColumn(index: number): void {
        const cancelPreloaderRemoveDirectoryField = this.$preloader('remove_directory_field', 'Удаление поля справочника')

        this.callRemoveDirectoryField({
            DirectoryID: this.directoryId,
            Position: index
        })
            .then(() => {
                this.visibleRemoveModal = false
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => {
                cancelPreloaderRemoveDirectoryField()
            })
    }

    // *************************************************************
    // EMIT
    @Emit('create')
    emitCreate(dirId: string): string {
        return dirId
    }

    @Emit('saveDisabled')
    emitSaveDisabled(val: { disabled: boolean, reason: string }): { disabled: boolean, reason: string } {
        return val
    }
}
