










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class ListCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: null }) item!: { name: string }
}
