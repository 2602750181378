




























import { Component, Vue, Prop } from 'vue-property-decorator'

import { TaskEvent } from '@/model/Task'

const requiredFields: { [key: string]: string} = {
    newAssigneeShortName: 'Новый исполнитель',
    newConfirmerShortName: 'Новый подтверждающий'
}

@Component({})

export default class BaseEventCard extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) event!: TaskEvent
    @Prop({ default: () => null }) options!: { name: string }

    // *************************************************************
    // DATA PARAMS
    requiredFields = requiredFields

    // *************************************************************
    // COMPUTED
    get fields(): Array<string> {
        return Object.keys(this.event.fields).filter((f: string) => requiredFields[f])
    }
}
