import { DirectiveOptions } from 'vue'

// interface HTMLElementClickOutside extends HTMLElement {
//     clickOutsideEvent: (event: Event) => void;
// }

const clickOutside: DirectiveOptions = {
    bind(el: any, binding: any, vNode: any) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) {
                warn += `Found in component '${compName}'`
            }
            // tslint:disable-next-line
            console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e: Event) => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                binding.value(e)
            }
        }
        el.__vueClickOutside__ = handler
        // add Event Listeners
        document.addEventListener('click', handler)
    },

    unbind(el: any, binding: any) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
    }
}

export default clickOutside

//
// import { DirectiveBinding } from 'vue/types/options'
//
// interface HTMLElementClickOutside extends HTMLElement {
//     clickOutsideEvent: (event: Event) => void;
// }
//
// const clickOutside = {
//     beforeMount (el: HTMLElementClickOutside, binding: DirectiveBinding): void {
//         el.clickOutsideEvent = (event: Event) => {
//             if (!(el === event.target || el.contains(event.target as Node))) {
//                 binding.value(event)
//             }
//         }
//         document.addEventListener('click', el.clickOutsideEvent)
//     },
//
//     unmounted (el: HTMLElementClickOutside): void {
//         document.removeEventListener('click', el.clickOutsideEvent)
//     }
// }
//
// export default clickOutside
