




















import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component<TextareaNew>({})

export default class TextareaNew extends Vue {
    @Ref() readonly textarea!: HTMLTextAreaElement

    // *************************************************************
    // PROPS
    @Prop({ default: 'Ввод' }) placeholder!: string
    @Prop({ default: 14 }) fontSize!: number
    @Prop({ default: 1.2 }) lineHeight!: number
    @Prop({ default: '' }) value!: string
    @Prop({ default: 1 }) minLines!: number
    @Prop({ default: 1 }) maxLines!: number
    @Prop({ default: false }) required!: boolean
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: false }) loading!: boolean

    // *************************************************************
    // COMPUTED
    get styleObject(): { [key: string]: string } {
        return {
            'max-height': this.maxLines * this.fontSize * this.lineHeight + 'px',
            'min-height': this.minLines * this.fontSize * this.lineHeight + 'px'
        }
    }

    get disabledArea(): boolean {
        return this.disabled || this.loading
    }

    // *************************************************************
    // WATCH
    @Watch('value')
    handleValueChange(to: string, from: string): void {
        if (to && from) {
            // Автоподгон высоты textarea под содержимое value
            // Обязательно !!from, т.к. при инициализации $refs еще не доступны
            // Обходим это в mounted
            this.textarea.style.height = '26px'
            this.textarea.style.height = this.textarea.scrollHeight + 'px'
        } else if (!to && !!from) {
            // Это запускается, когда мы скидываем значение
            // Скидываем высоту в ноль
            this.textarea.style.height = '26px'
        }
    }

    mounted(): void {
        // Мы устанавливаем нашему textarea высоту в одну строку по умолчанию
        // Далее через props maxLines && minLines устанавливаем максимальную и минимальную высоты
        // Прокрутка в textarea появляется после превышения максимальной высоты
        // В mounted устанавливаем ему height = scrollHeight, далее автоподгон высоты регулируем в watch 'value'
        // if (this.$refs.textarea.clientHeight) {
        //     this.$refs.textarea.style.maxHeight = this.$refs.textarea.clientHeight * this.maxLines + 'px'
        //     this.$refs.textarea.style.minHeight = this.$refs.textarea.clientHeight * this.minLines + 'px'
        // } else {
        //     this.$refs.textarea.style.maxHeight = '19px'
        //     this.$refs.textarea.style.minHeight = '19px'
        // }
        if (this.textarea.scrollHeight) {
            this.textarea.style.height = this.textarea.scrollHeight + 'px'
        } else {
            this.textarea.style.height = this.fontSize * this.lineHeight + 'px'
        }
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(val: string): string {
        return val
    }

    @Emit('blur')
    emitBlur(): void {
        // Do nothing
    }
}
