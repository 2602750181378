export const NUMERATOR_FIELD_TYPES = {
    field_type_code: 'Шифр',
    field_type_text: 'Разделитель',
    field_type_incr: 'Номер'
}


export const NUMERATOR_CODE_TYPES = {
    source_unit: 'Подразделение',
    source_organization: 'Организация',
    source_documentType: 'Тип документа'
}

export const NUMERATOR_RESET_PERIOD = {
    reset_in_day: 'Сутки',
    reset_in_month: 'Месяц',
    reset_in_year: 'Год',
    reset_in_never: 'Никогда'
}

export class Numerator {
    constructor(id, centerId, name, fields) {
        this.id = id
        this.centerId = centerId
        this.name = name
        this.fields = fields // repeated Field
    }
}

export class NumeratorField {
    constructor(id, type, code, text, pad, resetPeriod) {
        this.id = id
        this.type = type
        this.code = code
        this.text = text
        this.pad = pad
        this.resetPeriod = resetPeriod
    }
}
