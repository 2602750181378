var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "modal-select",
    attrs: {
      "visible": _vm.syncedVisible,
      "title": _vm.title,
      "max-width": "1300px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "m-5 h-100",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_c('div', {
    staticClass: "editor-modal"
  }, [_vm.canChangeRequisite && _vm.requisites.length ? _c('Select', {
    staticClass: "editor-modal__select",
    attrs: {
      "options": _vm.requisites,
      "disabledChangedOption": false,
      "placeholder": "Скопировать реквизит"
    },
    on: {
      "change": _vm.copyRequisite
    },
    model: {
      value: _vm.requisiteId,
      callback: function callback($$v) {
        _vm.requisiteId = $$v;
      },
      expression: "requisiteId"
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.canChangeRequisite ? _c('TextEditor', {
    key: "edit",
    ref: "editor",
    staticClass: "editor",
    attrs: {
      "height": "calc(100% - 72px)"
    },
    on: {
      "input": function input($event) {
        return _vm.emitInput($event);
      }
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }) : _c('EditorViewer', {
    key: "view",
    ref: "viewer",
    attrs: {
      "value": _vm.value
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_vm.canChangeRequisite ? _c('ButtonGreen', {
    staticClass: "ml-auto mb-3 mt-2 mr-3",
    attrs: {
      "text": "Сохранить"
    },
    on: {
      "click": _vm.save
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }