















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { User, Role } from '@/model/User'
import { Unit } from '@/model/Unit'
import { SelectOption } from '@/components/common/select/model'

import Select from '@/components/common/select/Select.vue'

@Component({
    components: { Select }
})

export default class SelectCenterId extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) value!: string

    // *************************************************************
    // DATA PARAMS
    centerId = ''

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible
    @Getter('company_structure/getUnitById') getUnitById!: (id: string) => Unit | null

    get centers(): Array<SelectOption> {
        return this.getMe.roles
            .filter((role: Role) => role.name === 'WorkflowManager' && role.unit !== 'root')
            .map((role: Role) => {
                console.log('role', role, role.unitName)
                return {
                    id: role.unit,
                    name: role.unitName
                }
            })
    }

    // *************************************************************
    // WATCH
    @Watch('centers', { immediate: true, deep: true })
    handleCentersChange(): void {
        this.setCenterId()
    }

    setCenterId(): void {
        if (!this.centers.length) {
            this.centerId = ''
            return
        }

        if (this.centers.length === 1) {
            this.centerId = this.centers[0].id
            this.emitInput(this.centerId)
        }
    }

    // *************************************************************
    // EMIT
    @Emit('input')
    emitInput(val: string): string {
        return val
    }
}
