var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.count ? _c('div', {
    staticClass: "count"
  }, [_vm._v("\n    " + _vm._s(_vm.count) + "\n")]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }