import api from 'api1'
import newNumerator from '@/model/convert/newNumerator'

export default function ({ dispatch }, { numeratorID }) {
    return new Promise((resolve, reject) => {
        api.cunt.NumeratorSvcClient.get(api.cunt.GetNumeratorReq({
            ID: numeratorID,
        }), data => {
            data = api.cunt.Numerator(data)
            console.log('::DATA::NumeratorSvcClient.get:', data)

            dispatch('putNumerator', {
                numerator: newNumerator(data)
            })

            resolve()
        }, error => {
            (error ? console.warn : console.log)('::DONE::NumeratorSvcClient.get:', error)
            reject(error)
        })
    })
}
