export default function ({ state, commit }, { familiarization }) {
    let existingFamiliarization = state.familiarizations.find(f => f.id === familiarization.id)
    if (existingFamiliarization) {
        if (familiarization.modificationTime > existingFamiliarization.modificationTime) {
            commit('replaceFamiliarization', {
                familiarization
            })
        }
    } else {
        commit('putFamiliarization', {
            familiarization
        })
    }
}
