import { render, staticRenderFns } from "./News.vue?vue&type=template&id=051c5457&scoped=true&"
import script from "./News.vue?vue&type=script&lang=js&"
export * from "./News.vue?vue&type=script&lang=js&"
import style0 from "./News.vue?vue&type=style&index=0&id=051c5457&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051c5457",
  null
  
)

export default component.exports