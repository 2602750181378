var render = function () {
  var _vm$task$actor$user, _vm$task$actor$user2, _vm$childTask$actor$u, _vm$childTask$actor$u2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "doc-wf-task-card box-shadow__double"
  }, [_c('div', {
    staticClass: "doc-wf-task-card__block",
    class: {
      'doc-wf-task-card__pending': _vm.task.pending,
      'doc-wf-task-card__pending_actor': _vm.task.pending && _vm.getMe.id === ((_vm$task$actor$user = _vm.task.actor.user) === null || _vm$task$actor$user === void 0 ? void 0 : _vm$task$actor$user.id),
      'doc-wf-task-card__passed': _vm.task.passed
    }
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "doc-wf-task-card__text"
  }, [_vm._v("\n                " + _vm._s(_vm.task.task.text) + "\n            ")]), _vm._v(" "), _c('div', [_vm._v("\n                " + _vm._s(_vm.actorName) + "\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "doc-wf-task-card__text"
  }, [_vm._v("\n                " + _vm._s(_vm.task.task.description) + "\n            ")])]), _vm._v(" "), ((_vm$task$actor$user2 = _vm.task.actor.user) === null || _vm$task$actor$user2 === void 0 ? void 0 : _vm$task$actor$user2.id) === _vm.getMe.id ? _c('ButtonViolet', {
    staticClass: "doc-wf-task-card__button",
    class: {
      'doc-wf-task-card__button_info': !_vm.task.pending
    },
    attrs: {
      "small": true,
      "text": _vm.task.passed ? 'Завершена' : _vm.task.pending ? 'Выполнить' : 'Неактивна',
      "disabled": !_vm.task.passed && !_vm.task.pending
    },
    on: {
      "click": _vm.canComplete
    }
  }) : _c('ButtonViolet', {
    staticClass: "doc-wf-task-card__button doc-wf-task-card__button_info",
    class: {
      'doc-wf-task-card__button_pending': _vm.task.pending
    },
    attrs: {
      "small": true,
      "text": _vm.task.passed ? 'Завершена' : _vm.task.pending ? 'На исполнении' : 'Неактивна',
      "disabled": !_vm.task.passed && !_vm.task.pending
    }
  })], 1), _vm._v(" "), _vm.task.task.type === 'width_confirmation' ? _c('div', [_vm.childTask ? _c('div', {
    staticClass: "doc-wf-task-card__block",
    class: {
      'doc-wf-task-card__pending': _vm.childTask.pending,
      'doc-wf-task-card__pending_actor': _vm.childTask.pending && _vm.getMe.id === ((_vm$childTask$actor$u = _vm.childTask.actor.user) === null || _vm$childTask$actor$u === void 0 ? void 0 : _vm$childTask$actor$u.id),
      'doc-wf-task-card__passed doc-wf-task-card__passed_assignee': _vm.childTask.passed
    }
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_vm._v("\n                Контроль:\n                "), _c('br'), _vm._v("\n                " + _vm._s(_vm.assigneeName) + "\n            ")]), _vm._v(" "), _vm.childTask.passed ? _c('Button', {
    staticClass: "doc-wf-task-card__button doc-wf-task-card__button_info pink-1",
    attrs: {
      "size": "small"
    }
  }, [_vm._v("Подтверждена")]) : !_vm.childTask.pending ? _c('ButtonViolet', {
    staticClass: "doc-wf-task-card__button",
    attrs: {
      "text": "Неактивна",
      "small": true,
      "disabled": true
    }
  }) : ((_vm$childTask$actor$u2 = _vm.childTask.actor.user) === null || _vm$childTask$actor$u2 === void 0 ? void 0 : _vm$childTask$actor$u2.id) === _vm.getMe.id && _vm.childTask.pending ? _c('div', [_c('ButtonViolet', {
    staticClass: "doc-wf-task-card__button",
    attrs: {
      "small": true,
      "text": "Подтвердить"
    },
    on: {
      "click": _vm.canConfirm
    }
  }), _vm._v(" "), _c('ButtonViolet', {
    staticClass: "doc-wf-task-card__button",
    attrs: {
      "small": true,
      "text": "Отклонить"
    },
    on: {
      "click": _vm.reject
    }
  })], 1) : _c('ButtonViolet', {
    staticClass: "doc-wf-task-card__button doc-wf-task-card__button_info",
    attrs: {
      "small": true,
      "text": "На подтверждении"
    }
  })], 1) : _c('div', [_vm._v("Обратитесь в службу поддержки!!!!!!!!!!!!!!!!!")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }