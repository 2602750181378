import api from 'api1'
import { WorkflowTemplate, StageTemplate, ApprovalTemplate, TaskTemplate, WFVvs } from '@/model/Workflow.ts'
import { newActor } from '@/model/convert/newActor.js'

export default function ({ dispatch }, docTypeId) {

    return new Promise((resolve, reject) => {
        api.cunt.WorkflowSvcClient.getWorkflowTemplate(api.cunt.GetWorkflowTemplateReq({
            DocType: docTypeId
        }), data => {
            data = api.cunt.WorkflowTemplate(data)
            console.log('::DATA::WorkflowSvc.getWorkflowTemplate:', data)

            const workflow = new WorkflowTemplate(
                data.Static,
                data.VVS ? data.VVS : WFVvs.NONE,
                null,
                '',
                '',
                '',
                false,
                (data.Stages || []).map((stage, index) => {
                    if (stage.Type === 'approval') {
                        return new StageTemplate(
                            stage.Type,
                            stage.DisplayName,
                            stage.Approvals.Approvals.map(s => new ApprovalTemplate(
                                s.ID,
                                newActor(s.Actor),
                                s.SpecialName,
                                s.Inputs
                            )),
                            [],
                            (index === 0 && data.VVS !== 'none')
                        )
                    } else if (stage.Type === 'execution') {
                        return new StageTemplate(
                            stage.Type,
                            stage.DisplayName,
                            [],
                            stage.Tasks.Tasks.map(s => new TaskTemplate(
                                s.ID,
                                newActor(s.Assignee),
                                s.Text,
                                s.Description,
                                newActor(s.Confirmer),
                                s.Inputs
                            )),
                            false
                        )
                    } else {
                        return new StageTemplate(
                            stage.Type,
                            stage.DisplayName,
                            [],
                            [],
                            false
                        )
                    }
                })
            )

            dispatch('putWorkflow', workflow)

            resolve()
        }, error => {
            (error ? console.warn : console.log)('::DONE::WorkflowSvc.getWorkflowTemplate:', error)
            reject(error)
        })
    })
}

