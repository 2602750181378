var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dir-records"
  }, [_c('table', {
    style: {
      width: _vm.records.length ? _vm.tableBodyWidth + 'px' : '100%'
    }
  }, [_c('tr', [_vm._l(_vm.directoryFields, function (field, index) {
    return _c('th', {
      key: field.name,
      style: {
        width: _vm.cellWidth[index] + 'px'
      }
    }, [_c('span', [_vm._v(_vm._s(field.name))]), _vm._v(" "), field.required ? _c('span', {
      staticClass: "dir-records__required"
    }, [_vm._v("*")]) : _vm._e()]);
  }), _vm._v(" "), _vm.directory && !_vm.directory.external ? _c('th', {
    staticClass: "dir-records__icons-cell"
  }) : _vm._e()], 2), _vm._v(" "), _vm.showAddRecord || _vm.showEditRecord ? _c('EditRecord', {
    staticClass: "h-40",
    attrs: {
      "recordId": _vm.selectedRecordId
    },
    on: {
      "close": _vm.closeEditRecord
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    ref: "container",
    staticClass: "dir-records__records-container",
    style: {
      width: _vm.records.length ? _vm.tableBodyWidth + 'px' : '100%'
    }
  }, [_c('table', {
    ref: "tableBody"
  }, _vm._l(_vm.records, function (record) {
    return _c('tr', {
      key: record.id,
      staticClass: "dir-records__record"
    }, [_vm._l(record.fields, function (f, i) {
      return _c('td', {
        key: "".concat(record.id, "-").concat(i),
        staticClass: "dir-records__record-cell"
      }, [_vm._v("\n                    " + _vm._s(f.displayName) + "\n                ")]);
    }), _vm._v(" "), !_vm.directory.external ? _c('td', {
      staticClass: "dir-record__icons-cell"
    }, [_c('div', {
      staticClass: "dir-record__icons"
    }, [!record.archived ? _c('div', {
      attrs: {
        "tooltip": "Редактировать элемент",
        "flow": "left"
      },
      on: {
        "click": function click($event) {
          return _vm.editRecord(record.id);
        }
      }
    }, [_c('FAIcon', {
      staticClass: "dir-record__icon pink-1",
      attrs: {
        "icon": "edit"
      }
    })], 1) : _vm._e(), _vm._v(" "), !record.archived ? _c('ButtonDelete', {
      attrs: {
        "tooltip": "Удалить элемент",
        "flow": "left"
      },
      on: {
        "click": function click($event) {
          return _vm.showRemove(record.id);
        }
      }
    }) : _vm._e(), _vm._v(" "), record.archived ? _c('div', {
      attrs: {
        "tooltip": "Вернуть из архива",
        "flow": "left"
      },
      on: {
        "click": function click($event) {
          return _vm.archive(record.id, false);
        }
      }
    }, [_c('FAIcon', {
      staticClass: "dir-record__icon pink-1",
      attrs: {
        "icon": "archive"
      }
    })], 1) : _vm._e()], 1)]) : _vm._e()], 2);
  }), 0)]), _vm._v(" "), _c('RemoveModal', {
    ref: "removeModal",
    attrs: {
      "visible": _vm.visibleRemoveModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleRemoveModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }