import { ActionTree } from 'vuex'
import { RootState } from '@/store'
import { CompanyStructureState } from '@/store/company_structure/companyStructureState'

import { cuntPB, service } from 'api'
import { User } from '@/model/User'

import _actions_callGetUsersByRoles from './callGetUsersByRoles'

const UsersActions: ActionTree<CompanyStructureState, RootState> = {
    callGetUsersByRoles: _actions_callGetUsersByRoles,

    async callChangeRoles({ dispatch }, params: cuntPB.RolesOp) {
        const data: cuntPB.User = await service.user.ChangeRoles(params)
        console.log('::DATA::UserSvcClient.changeRoles:', data)

        // Если у пользователя меняли или добавляли роли
        if (data.Roles.length) {
            dispatch('putUser', new User(data))
        } else {
            // Если пользователю удалили все роли
            dispatch('removeUser', data.ID)
        }
    },

    async callGetUser({ dispatch }, id: string) {
        const data: cuntPB.User = await service.user.GetUser({ ID: id })
        console.log('::DATA::UserSvcClient.getUser:', data)
        const user = new User(data)
        dispatch('putUser', user)
        return user
    },

    async callRetire({ dispatch }, params: cuntPB.RetireReq) {
        const data: cuntPB.User = await service.user.Retire(params)
        console.log('::DATA::UserSvcClient.getUser:', data)
        // Если у пользователя меняли или добавляли роли
        if (data.Roles.length) {
            dispatch('putUser', new User(data))
        } else {
            // Если пользователю удалили все роли
            dispatch('removeUser', data.ID)
        }
    },

    putUser({ commit, getters }, user: User) {
        const existingUser = getters.getUsers.find((u: User) => u.id === user.id)
        if (existingUser) {
            if (user.modificationTime > existingUser.modificationTime) {
                commit('putUser', user)
            }
        } else {
            commit('putUser', user)
        }
    },

    removeUser({ commit }, userId: string) {
        commit('removeUser', userId)
    }
}

export default UsersActions
