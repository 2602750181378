var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.syncedVisible,
      expression: "syncedVisible"
    }],
    staticClass: "confirm-modal",
    on: {
      "click": function click($event) {
        return _vm.hide();
      }
    }
  }, [_c('div', {
    staticClass: "confirm-modal__content",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "confirm-modal__header"
  }, [_c('div', {
    staticClass: "confirm-modal__title"
  }, [_vm._v("\n                " + _vm._s(_vm.title) + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "confirm-modal__body"
  }, [_vm._v("\n            " + _vm._s(_vm.text) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "confirm-modal__footer"
  }, [_c('ButtonBase', {
    attrs: {
      "text": "Отмена"
    },
    on: {
      "click": _vm.onCancel
    }
  }), _vm._v(" "), _c('ButtonGreen', {
    attrs: {
      "text": "OK"
    },
    on: {
      "click": _vm.onConfirm
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }