var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-base"
  }, [_c('div', {
    staticClass: "document-base__name fs-16"
  }, [_vm._v("\n        " + _vm._s(_vm.base.name) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center flex-wrap justify-between"
  }, [_c('Attribute', {
    attrs: {
      "attribute": "Статус"
    }
  }, [_c('DocumentStatus', {
    staticClass: "d-inline",
    attrs: {
      "open-document": _vm.openDocument
    }
  })], 1), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "QR"
    }
  }, [_c('ButtonViolet', {
    attrs: {
      "text": "Распечатать",
      "small": true
    },
    on: {
      "click": _vm.printQr
    }
  })], 1), _vm._v(" "), _c('ButtonDelete', {
    attrs: {
      "tooltip": _vm.removeDisable.tooltip,
      "flow": "left",
      "disabled": _vm.removeDisable.disabled
    },
    on: {
      "click": _vm.removeDocument
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center flex-wrap justify-between"
  }, [_c('Attribute', {
    attrs: {
      "attribute": "Тип",
      "text": _vm.typeName
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "от",
      "text": _vm.base.creationTimeLocalizedShortString
    }
  }), _vm._v(" "), _vm.base.number ? _c('Attribute', {
    attrs: {
      "attribute": "№",
      "text": _vm.base.number
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center flex-wrap justify-between"
  }, [_c('Attribute', {
    attrs: {
      "attribute": "Организация",
      "text": _vm.unitName
    }
  }), _vm._v(" "), _c('Attribute', {
    attrs: {
      "attribute": "Подразделение",
      "text": _vm.originUnitName
    }
  })], 1), _vm._v(" "), _c('VueQRCodeComponent', {
    ref: "qr",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "text": _vm.qrText,
      "error-level": "L"
    }
  }), _vm._v(" "), _c('ConfirmModalNew', {
    ref: "confirmModal",
    attrs: {
      "visible": _vm.visibleConfirm
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleConfirm = $event;
      }
    }
  }), _vm._v(" "), _c('RemoveModal', {
    ref: "removeModal",
    attrs: {
      "visible": _vm.visibleRemoveModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleRemoveModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }