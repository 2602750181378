var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InputBase', {
    staticClass: "work-flow-stage-name",
    attrs: {
      "type": "text",
      "multi-lines": false,
      "placeholder": "Введите имя блока"
    },
    on: {
      "blur": _vm.onBlur
    },
    model: {
      value: _vm.stageName,
      callback: function callback($$v) {
        _vm.stageName = $$v;
      },
      expression: "stageName"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }