var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "events"
  }, [_c('div', {
    staticClass: "flex-row-center"
  }, [_c('h1', {
    staticClass: "mr-3"
  }, [_vm._v("\n            Мои активные документы\n        ")]), _vm._v(" "), _c('ListCount', {
    attrs: {
      "count": _vm.documents.length
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, _vm._l(_vm.checkbox, function (ch, key) {
    return _c('Checkbox', {
      key: key,
      attrs: {
        "label": ch.label
      },
      model: {
        value: ch.checked,
        callback: function callback($$v) {
          _vm.$set(ch, "checked", $$v);
        },
        expression: "ch.checked"
      }
    });
  }), 1), _vm._v(" "), _vm.documents.length ? _c('div', {
    key: "list",
    staticClass: "events__list"
  }, _vm._l(_vm.documents, function (event) {
    return _c('DocumentCard', {
      key: event.id,
      staticClass: "mb-3",
      attrs: {
        "event": event
      }
    });
  }), 1) : _c('EmptyList', {
    key: "empty"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }