var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.familiarization ? _c('div', {
    staticClass: "familiarization-edit"
  }, [_c('div', {
    staticClass: "flex-row-center familiarization__margin"
  }, [_c('div', [_vm._v("\n            Дата создания:\n            "), _c('span', [_vm._v(_vm._s(_vm.familiarization.creationTimeLocalizedShortString))])]), _vm._v(" "), _c('div', {
    staticClass: "mx-auto flex-row-center"
  }, [_c('span', [_vm._v("Статус:")]), _vm._v(" "), _c('Select', {
    staticClass: "familiarization__item familiarization-edit__status h-36 ml-2",
    attrs: {
      "placeholder": "Тип поля",
      "options": _vm.statusesWithDisabled
    },
    on: {
      "change": _vm.changeStatus
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1), _vm._v(" "), _c('div', [_vm._v("\n            Автор:\n            "), _c('span', {
    staticClass: "familiarization__item"
  }, [_vm._v(_vm._s(_vm.familiarization.actor.user.name.shortName))])])]), _vm._v(" "), _c('InputBase', {
    staticClass: "familiarization__title familiarization__margin",
    attrs: {
      "disabled": _vm.familiarization.status !== 'draft',
      "type": "text",
      "placeholder": "Название"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _vm.familiarization.status === 'draft' ? _c('TextEditor', {
    key: "draft",
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }) : _c('EditorViewer', {
    key: "active",
    staticClass: "familiarization__description",
    attrs: {
      "value": _vm.familiarization.description
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "familiarization__margin flex-row-center"
  }, [_vm.familiarization.status === 'draft' || _vm.familiarization.documents.length ? _c('div', [_vm._v("\n            Документы:\n        ")]) : _vm._e(), _vm._v(" "), _vm.familiarization.status === 'draft' ? _c('div', {
    staticClass: "familiarization__icon",
    attrs: {
      "tooltip": "Редактировать",
      "flow": "right"
    },
    on: {
      "click": _vm.addDocument
    }
  }, [_c('FAIcon', {
    staticClass: "pink-1",
    attrs: {
      "icon": "edit"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _vm._l(_vm.documents, function (doc) {
    return _c('div', {
      key: doc.id,
      staticClass: "familiarization__document",
      on: {
        "click": function click($event) {
          return _vm.openDocument(doc);
        }
      }
    }, [_c('div', [_vm._v(_vm._s(_vm.typeNames[doc.type]) + ": " + _vm._s(doc.name))])]);
  }), _vm._v(" "), _vm.familiarization.status === 'draft' || _vm.familiarization.files.length ? _c('Files', {
    staticClass: "familiarization__margin",
    attrs: {
      "files": _vm.familiarization.files,
      "document-id": _vm.familiarization.id,
      "can-add-files": _vm.familiarization.status === 'draft',
      "can-change": _vm.familiarization.status === 'draft',
      "group-name": "familiarization_attachment",
      "name": "familiarization"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center"
  }, [_c('h4', [_vm._v("Кого необходимо ознакомить:")]), _vm._v(" "), _vm.familiarization.status !== 'draft' ? _c('ButtonAdd', {
    staticClass: "ml-5",
    attrs: {
      "tooltip": "Добавить пользователей",
      "flow": "right"
    },
    on: {
      "click": _vm.showAddMembers
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.familiarization.status === 'draft' ? _c('div', {
    key: "select-edit"
  }, [_c('ActionSelectCard', {
    attrs: {
      "multiple": true,
      "placeholder": "Выбрать пользователей",
      "entity": "user",
      "items": _vm.users,
      "sort": "name",
      "sub-sort-property": "fullName"
    },
    model: {
      value: _vm.userIds,
      callback: function callback($$v) {
        _vm.userIds = $$v;
      },
      expression: "userIds"
    }
  }), _vm._v(" "), _vm.familiarization.status === 'draft' ? _c('ActionSelectCard', {
    staticClass: "familiarization__margin",
    attrs: {
      "multiple": true,
      "placeholder": "Выбрать подразделения",
      "entity": "unit",
      "items": _vm.units,
      "sort": "name"
    },
    model: {
      value: _vm.unitIds,
      callback: function callback($$v) {
        _vm.unitIds = $$v;
      },
      expression: "unitIds"
    }
  }) : _vm._e()], 1) : _c('ol', {
    key: "select-view"
  }, _vm._l(_vm.familiarization.сheckList, function (item) {
    return _c('li', {
      key: item.user.id
    }, [_vm._v("\n            " + _vm._s(item.user.name.fullName) + "\n        ")]);
  }), 0), _vm._v(" "), _vm.familiarization.status === 'draft' ? _c('div', {
    key: "until-edit"
  }, [_c('div', {
    staticClass: "familiarization__margin"
  }, [_vm._v("\n            Ознакомиться до:\n        ")]), _vm._v(" "), _c('DateTimeInput', {
    staticClass: "familiarization__until",
    attrs: {
      "value": _vm.until
    },
    model: {
      value: _vm.until,
      callback: function callback($$v) {
        _vm.until = $$v;
      },
      expression: "until"
    }
  })], 1) : _vm.familiarization.until ? _c('div', {
    key: "until-view"
  }, [_c('div', {
    staticClass: "familiarization__margin"
  }, [_vm._v("\n            Ознакомиться до:\n        ")]), _vm._v(" "), _c('div', {
    style: {
      color: _vm.familiarization.until < _vm.timeNow ? 'red' : 'black'
    }
  }, [_vm._v("\n            " + _vm._s(_vm.familiarization.untilLocalizedShortString) + "\n        ")])]) : _vm._e(), _vm._v(" "), _vm.familiarization.status === 'draft' ? _c('ButtonGreen', {
    staticClass: "ml-auto mt-5",
    attrs: {
      "text": "Сохранить"
    },
    on: {
      "click": _vm.save
    }
  }) : _vm._e(), _vm._v(" "), _c('FamiliarizationAddDocument', {
    ref: "addDocument",
    attrs: {
      "center-id": _vm.familiarization.centerId
    },
    model: {
      value: _vm.documents,
      callback: function callback($$v) {
        _vm.documents = $$v;
      },
      expression: "documents"
    }
  }), _vm._v(" "), _c('FamiliarizationAddMemebers', {
    ref: "addMembers",
    attrs: {
      "users": _vm.usersForAdd,
      "units": _vm.units
    }
  }), _vm._v(" "), _c('ConfirmModal', {
    ref: "confirm"
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }