var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-list"
  }, [_c('div', {
    staticClass: "document-list__header"
  }, [_c('div', {
    staticClass: "document-list__title flex-row-center"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_vm._v("\n                " + _vm._s(_vm.type.name) + "\n            ")]), _vm._v(" "), _c('ListCount', {
    attrs: {
      "count": _vm.filteredDocuments.length
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "document-list__datetimepicker"
  }, [_c('Datepicker', {
    staticClass: "document-list__date-input",
    attrs: {
      "value": _vm.docTypeFilters.startTime
    },
    on: {
      "input": function input($event) {
        return _vm.changeTime('startTime', $event);
      }
    }
  }), _vm._v(" "), _c('FAIcon', {
    staticClass: "document-list__datetimepicker_arrow pink-1",
    attrs: {
      "icon": "arrow-right"
    }
  }), _vm._v(" "), _c('Datepicker', {
    staticClass: "document-list__date-input",
    attrs: {
      "value": _vm.docTypeFilters.endTime
    },
    on: {
      "input": function input($event) {
        return _vm.changeTime('endTime', $event);
      }
    }
  })], 1), _vm._v(" "), _c('InputSearch', {
    staticClass: "document-list__search",
    attrs: {
      "value": _vm.docTypeFilters.searchText,
      "placeholder": "Поиск"
    },
    on: {
      "input": function input($event) {
        return _vm.changeSearchText($event);
      }
    }
  }), _vm._v(" "), _c('Button', {
    staticClass: "document-list__filters-button",
    attrs: {
      "active": _vm.showFilters,
      "color": "blue",
      "reverse": ""
    },
    on: {
      "click": function click($event) {
        _vm.showFilters = !_vm.showFilters;
      }
    }
  }, [_vm._v(_vm._s(_vm.showFilters ? 'Скрыть фильтры' : 'Показать фильтры'))]), _vm._v(" "), _c('ButtonCreate', {
    staticClass: "document-list__create-button",
    attrs: {
      "text": _vm.type.oneAss.collectionID ? 'Документ создается в 1С' : 'Создать документ',
      "disabled": !!_vm.type.oneAss.collectionID
    },
    on: {
      "click": _vm.showDocumentCreate
    }
  }), _vm._v(" "), _c('ButtonAdd', {
    staticClass: "document-list__create-button_small",
    attrs: {
      "tooltip": "Создать документ",
      "flow": "left"
    },
    on: {
      "click": _vm.showDocumentCreate
    }
  })], 1), _vm._v(" "), _c('DocumentCreationModal', {
    attrs: {
      "visible": _vm.visibleCreation,
      "type-id": _vm.type.id,
      "title": "Создание нового документа"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleCreation = $event;
      }
    }
  }), _vm._v(" "), _c('Transition', {
    attrs: {
      "name": "filters"
    }
  }, [_vm.showFilters ? _c('DocumentsFilters', {
    on: {
      "print": _vm.print
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    ref: "tableContainer",
    staticClass: "table-container",
    style: {
      'min-height': _vm.filteredDocuments.length ? '200px' : '70px'
    },
    on: {
      "scroll": function scroll($event) {
        return _vm.onScroll($event);
      }
    }
  }, [_c('table', {
    ref: "summaryTable",
    staticClass: "table",
    style: {
      'margin-top': _vm.tableTopMargin + 'px',
      'margin-bottom': _vm.tableBottomMargin + 'px'
    }
  }, [_c('thead', [_c('tr', [_c('th'), _vm._v(" "), _vm._l(_vm.visibleMainCells, function (cell) {
    return _c('th', {
      key: cell
    }, [_vm._v("\n                        " + _vm._s(_vm.DOC_TABLE_CELLS_DISPLAY_NAME[cell]) + "\n                    ")]);
  }), _vm._v(" "), _vm._l(_vm.visibleRequisites, function (requisite) {
    return _c('th', {
      key: requisite.id
    }, [_vm._v("\n                        " + _vm._s(requisite.name) + "\n                    ")]);
  })], 2)]), _vm._v(" "), _vm._l(_vm.visibleDocuments, function (document) {
    return _c('DocumentLine', {
      key: document.base.id,
      staticClass: "c-pointer",
      attrs: {
        "selected": _vm.selectedDocumentIds.includes(document.base.id),
        "document": document,
        "requisites": _vm.visibleRequisites,
        "document-hidden-cells": _vm.documentHiddenCells
      },
      on: {
        "selectDocument": function selectDocument($event) {
          return _vm.checkedDocument(document, $event);
        }
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.clickDocument(document.base.id);
        }
      }
    });
  })], 2)]), _vm._v(" "), !!_vm.selectedDocuments.length ? _c('ApproveDocuments', {
    attrs: {
      "selected-documents": _vm.selectedDocuments
    },
    on: {
      "clear": _vm.cleanCheckedDocuments,
      "removeFromSelected": function removeFromSelected($event) {
        return _vm.removeFromSelected($event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }