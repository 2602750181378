var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('g', _vm._g({
    staticClass: "approval-node",
    class: {
      'c-pointer': !!_vm.dynamic
    }
  }, _vm.$listeners), [!!_vm.pending || _vm.node.id === 'vvs' ? _c('rect', {
    staticClass: "approval-node__rect",
    class: {
      'approval-node__rect_vvs': _vm.node.id === 'vvs'
    },
    attrs: {
      "height": "30",
      "width": "110",
      "rx": "10",
      "ry": "10"
    }
  }) : _vm._e(), _vm._v(" "), !!_vm.root ? _c('polygon', {
    staticClass: "approval-node__star",
    attrs: {
      "points": "15,0 19,9 28,11 21,16 26,26 15,21 4,26 9,16 2,11 11,9"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.root && _vm.node.id !== 'vvs' ? _c('circle', {
    staticClass: "approval-node__circle",
    class: {
      'approval-node_blink': !!_vm.pending
    },
    attrs: {
      "r": "13",
      "cx": "15",
      "cy": "15"
    }
  }) : _vm._e(), _vm._v("\n    />\n    "), _vm.node.id !== 'vvs' ? _c('text', {
    staticClass: "approval-node__text",
    attrs: {
      "x": "30",
      "y": "20"
    }
  }, [_vm._v("\n        " + _vm._s(!_vm.root ? _vm.actor : 'Автор') + "\n    ")]) : _vm._e(), _vm._v(" "), _vm.node.id === 'vvs' ? _c('text', {
    staticClass: "approval-node__text",
    attrs: {
      "x": "30",
      "y": "20"
    }
  }, [_vm._v("\n        Вертикальное всплывание\n    ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }