






































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Route } from 'vue-router'
import { WorkflowTemplate, StageTemplate, TaskTemplate, ApprovalTemplate } from '@/model/Workflow'
import { User } from '@/model/User'
import { Unit } from '@/model/Unit'
import getError from '@/tools/errors/errors'

import { NodeTemplate } from '@/tools/template_builder/viewData'

import Button from '@/components/common/buttons/Button.vue'
import Popover from '@/components/common/Popover.vue'

import WFTStageName from '@/components/workflow_template_builder/actions/WFTStageName.vue'
import WFTAirForce from '@/components/workflow_template_builder/actions/WFTAirForce.vue'

import WTBActions from '@/components/workflow_template_builder/actions/WTBActions.vue'

import WorkFlowTemplate from '@/components/workflow_template_builder/workflow_template/WFTemplate.vue'

interface Action {
    id: string
    type: string
    text: string
    disabled: boolean
    tooltip?: string
}

@Component({
    components: {
        Button,
        Popover,
        WFTStageName,
        WFTAirForce,
        WTBActions,
        WorkFlowTemplate
    }
})

export default class LifeCycleTemplateBuilder extends Vue {
    // *************************************************************
    // DATA PARAMS
    selectedAction = {}

    // *************************************************************
    // COMPUTED
    @Getter('company_structure/getUserById') getUserById!: (userId: string) => User | null
    @Getter('company_structure/getUnitById') getUnitById!: (unitId: string) => Unit | null

    @Getter('workflow/getWorkflow') getWorkflow!: WorkflowTemplate
    @Getter('workflow/getActiveStage') getActiveStage!: StageTemplate | null

    @Getter('workflow/getNodesCanLinked') getNodesCanLinked!: Array<NodeTemplate>
    @Getter('workflow/getApprovalChildrenWithFewParents') getApprovalChildrenWithFewParents!: Array<ApprovalTemplate>

    get messageAboutChildren(): string {
        if (!this.getActiveStage) {
            return ''
        }

        // APPROVAL
        if (this.getActiveStage.type === 'approval') {
            // Если уже созданы nodes
            if (this.getActiveStage.approvals.length && !this.getWorkflow.activeNode) {
                return 'Если Вы хотите добавить дочерние узлы, удалить или отредактировать существующие узлы, то выберите в графе конкретный узел согласования.'
            }
            return ''
        }

        // EXECUTION
        if (this.getActiveStage.type === 'execution') {
            // Если уже созданы tasks
            if (this.getActiveStage.tasks.length && !this.getWorkflow.activeTask) {
                // Если мы сейчас в меню блока
                return 'Если Вы хотите добавить дочерние задачи, удалить или отредактировать существующую задачу, то кликните в графе по конкретной задаче.'
            }

            return ''
        }

        return 'Это блок со свободной цепочкой, которая редактируется непосредственно в созданном документе.'
    }

    get namesActiveNodeChildsWithOneParent(): Array<string> {
        if (!this.getWorkflow.activeNode || !this.getActiveStage) {
            return []
        }

        const namesActiveNodeChildsWithOneParent: Array<string> = []

        this.getActiveStage.approvals.forEach((app: ApprovalTemplate) => {
            if (app.inputs.length === 1 && app.inputs[0] === this.getWorkflow.activeNode) {
                if (!app.actor) {
                    namesActiveNodeChildsWithOneParent.push(app.specialName)
                } else if (app.actor.user) {
                    namesActiveNodeChildsWithOneParent.push(app.actor.user.name.fullName)
                } else if (app.actor.unit) {
                    namesActiveNodeChildsWithOneParent.push(app.actor.unit.name)
                }
            }
        })

        return namesActiveNodeChildsWithOneParent
    }

    get canChangeStaticFlag(): { can: boolean, reason: string } {
        if (!this.getWorkflow.staticFlag) {
            // Если сейчас выбрана динамическая цепочка
            if (this.getWorkflow.vvs !== 'none') {
                // Если стоит пометка VVS - Вертикальное всплывание
                return {
                    can: false,
                    reason: "У Вас стоит пометка 'Вертикальное всплывание'. С ней нельзя поменять динамическое согласование на статическое."
                }
            } else {
                // Если хоть в одном элементе задействован unit, то запрещаем менять динамическую цепочку на статическую
                const can = !this.getWorkflow.stages.some((stage: StageTemplate) => {
                    if (stage.approvals.some((app: ApprovalTemplate) => app.actor?.unit)) {
                        return true
                    }
                    return stage.tasks.some((task: TaskTemplate) => task.assignee?.unit || task.confirmer?.unit)
                })

                return {
                    can: can,
                    reason: 'Нельзя изменить тип согласования на Статический. Сначала удалите все подразделения из цепочек согласования.'
                }
            }
        } else {
            // Если сейчас выбрана статическая цепочка - если где-то указан конкретный user -> то запрещено менять
            const can = !this.getWorkflow.stages.some((stage: StageTemplate) => {
                if (stage.approvals.some((app: ApprovalTemplate) => app.actor?.user)) {
                    return true
                }
                return stage.tasks.some((task: TaskTemplate) => task.assignee?.user || task.confirmer?.user)
            })

            return {
                can: can,
                reason: 'Нельзя изменить тип согласования на Динамический. Сначала удалите всех пользователей из цепочек согласования.'
            }
        }
    }

    get actions(): Array<Action> {
        if (!this.getActiveStage) {
            // Если сейчас никакой блок не активен, то мы тоже не можем отразить никакие данные
            return [
                {
                    id: 'approval',
                    type: 'stage',
                    text: "Добавить блок 'Согласование'",
                    disabled: false
                },
                {
                    id: 'execution',
                    type: 'stage',
                    text: "Добавить блок 'В работу'",
                    disabled: false
                },
                {
                    id: 'fluent',
                    type: 'stage',
                    text: "Добавить блок 'Свободное согласование'",
                    disabled: !!this.getWorkflow.stages.find(s => s.type === 'fluent'),
                    tooltip: 'Блок со свободным согласованием можно добавлять не более одного раза.'
                }
            ]
        } else if (this.getActiveStage.type === 'approval') {
            if (!this.getWorkflow.activeNode) {
                return [
                    {
                        id: 'addApproval',
                        type: 'action',
                        text: 'Добавить узел согласования',
                        disabled: false
                    },
                    {
                        id: 'deleteStage',
                        type: 'action',
                        text: 'Удалить блок согласования',
                        disabled: this.getActiveStage.vvs,
                        tooltip: 'Данный блок содержит вертикальное всплывание. Сначала удалите пометку о вертикальном всплывании в данном типе документа.'
                    }
                ]
            } else {
                return [
                    {
                        id: 'addApproval',
                        type: 'action',
                        text: 'Добавить дочерний узел согласования',
                        disabled: false
                    },
                    {
                        id: 'changeApproval',
                        type: 'action',
                        text: 'Изменить узел согласования',
                        disabled: !this.getWorkflow.activeNode,
                        tooltip: 'Кликните на узел согласования, который необходимо изменить.'
                    },
                    {
                        id: 'link',
                        type: 'action',
                        text: 'Добавить связь',
                        disabled: !this.getNodesCanLinked.length,
                        tooltip: 'Для того чтобы можно было выбрать данный пункт, необходимо наличие несвязанных с выбранным узлов согласования.'
                    },
                    {
                        id: 'deleteNode',
                        type: 'action',
                        text: 'Удалить',
                        disabled: !!this.namesActiveNodeChildsWithOneParent.length,
                        tooltip: `Удаление данного узла согласования запрещено, т.к. у дочерних узлов есть только одна связь, ведущая к удаляемому узлу. Добавьте следующим дочерним узлам другие связи или удалите их: ${this.namesActiveNodeChildsWithOneParent.join(', ')}`
                    },
                    // Массив getApprovalChildrenWithFewParents будет пуст, если у всех дочерних nodes активного node только одна
                    // входящая связь от активного node, следовательно эту связь удалить нельзя, т.к. непонятно к какому
                    // узлу перепривязать дочерние nodes
                    // Если массив getApprovalChildrenWithFewParents не пуст, то он возвращает список дочерних nodes, у которых
                    // можно удалить связь с активныйм node - это возможно только в том случае, если у дочернего node
                    // есть иные входящие связи кроме активного node
                    {
                        id: 'deleteLink',
                        type: 'action',
                        text: 'Удалить связь',
                        disabled: !this.getApprovalChildrenWithFewParents.length,
                        tooltip: 'У выделенного узла невозможно удалить никакие связи, т.к. у всех дочерних узлов существует только по одной входящей связи. Вы можете перепривязать дочерние узлы к другим родительским узлам.'
                    }
                ]
            }
        } else if (this.getActiveStage.type === 'execution') {
            if (!this.getWorkflow.activeTask) {
                return [
                    {
                        id: 'addTask',
                        type: 'action',
                        text: 'Добавить задачу',
                        disabled: false
                    },
                    {
                        id: 'deleteStage',
                        type: 'action',
                        text: "Удалить блок 'В работу'",
                        disabled: false
                    }
                ]
            } else {
                const childrenTasks = this.getActiveStage.tasks.find((task: TaskTemplate) => task.inputs.includes(this.getWorkflow.activeTask))

                // Если выбрана активная задача
                return [
                    {
                        id: 'addTask',
                        type: 'action',
                        text: 'Добавить дочернюю задачу',
                        disabled: false
                    },
                    {
                        id: 'changeTask',
                        type: 'action',
                        text: 'Редактировать задачу',
                        disabled: false
                    },
                    {
                        id: 'deleteTask',
                        type: 'action',
                        text: 'Удалить задачу',
                        disabled: !!childrenTasks,
                        tooltip: 'Необходимо сначала удалить дочерние задачи.'
                    }
                ]
            }
        } else {
            // if this.getActiveStage.type === 'fluent'
            return [
                {
                    id: 'deleteStage',
                    type: 'action',
                    text: "Удалить блок 'Свободное согласование'",
                    disabled: false
                }
            ]
        }
    }

    // *************************************************************
    // WATCH
    @Watch('$route', { immediate: true })
    handleRouteChange(val: Route): void {
        this.addWorkflow(val.params.id)
    }

    // *************************************************************
    // METHODS
    @Action('workflow/workflowChange')
    workflowChange!: (change: boolean) => void

    @Action('workflow/changeWFStatic')
    changeWFStatic!: (staticFlag: boolean) => Promise<void>

    @Action('workflow/addWFStage')
    addWFStage!: (params: { stage: StageTemplate, position: string }) => Promise<void>

    @Action('workflow/changeWFActiveStage')
    changeWFActiveStage!: (activeStage: number | null) => void

    @Action('workflow/changeWFActiveNode')
    changeWFActiveNode!: (node: string) => Promise<void>

    @Action('workflow/changeWFAction')
    changeWFAction!: (actionId: string) => void

    @Action('workflow/changeWFActiveTask')
    changeWFActiveTask!: (taskId: string) => Promise<void>

    @Action('workflow/callSetWorkflowTemplate')
    callSetWorkflowTemplate!: (params: { docType: string, template: WorkflowTemplate }) => Promise<void>

    @Action('workflow/callGetWorkflowTemplate')
    callGetWorkflowTemplate!: (docTypeId: string) => Promise<void>

    addWorkflow(docTypeId: string): void {
        const cancelPreloaderGetWorkflow = this.$preloader('get_workflow', 'Получение конструктора жизненного цикла')
        this.callGetWorkflowTemplate(docTypeId)
            .catch(error => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderGetWorkflow())
    }

    changeStaticFlag(): void {
        if (this.canChangeStaticFlag.can) {
            this.changeWFStatic(!this.getWorkflow.staticFlag)
        }
    }

    clickAddStage(action: Action): void {
        if (!action.disabled) {
            if (action.type === 'stage') {
                // Если тип === stage, значит по клику создается новый блок
                let displayName = ''
                if (action.id === 'approval') {
                    displayName = 'Согласование'
                } else if (action.id === 'execution') {
                    displayName = 'В работу'
                } else if (action.id === 'fluent') {
                    displayName = 'Свободное согласование'
                }
                this.addWFStage({
                    stage: new StageTemplate(
                        action.id,
                        displayName,
                        [],
                        [],
                        false
                    ),
                    position: 'end'
                })
                // Отмечаем наличие изменений в workFlow
                this.workflowChange(true)
            } else if (action.type === 'action') {
                // Если тип === action, значит по клику идет выбор компонента с действием
                this.changeWFAction(action.id)
            }
        }
    }

    clearAction(): void {
        this.changeWFAction('')
    }

    clearActiveStage(): void {
        // Удаляем активный блок в state, т.к. это кнопка нажимается только для добавления нового блока
        this.changeWFActiveStage(null)

        // Обнуляем активный node и task
        this.changeWFActiveNode('')
        this.changeWFActiveTask('')
        this.clearAction()
    }

    saveWorkFlow(): void {
        // Только если есть изменения
        if (this.getWorkflow.change) {
            // Если выбран vvs, то уже может сформировать хоть какой-то конструктор
            // Даже если stages - пустой массив
            if (this.getWorkflow.vvs !== 'none' && !this.getWorkflow.stages.length) {
                this.setWorkflowTemplate()
            } else if (this.getWorkflow.stages.length) {
                // Если есть stages, то смотрим, заполнены ли они
                const emptyStages: Array<string> = []

                this.getWorkflow.stages.forEach((stage, index) => {
                    if (stage.type === 'approval' && !stage.approvals.length ) {
                        // Если это первый stage и стоит пометка VVS, то данный блок может быть пустой
                        if (index === 0 && this.getWorkflow.vvs !== 'none') {
                            this.setWorkflowTemplate()
                        } else {
                            emptyStages.push(stage.displayName)
                        }
                    }
                    if (stage.type === 'execution' && !stage.tasks.length) {
                        emptyStages.push(stage.displayName)
                    }
                })

                // Если все блоки заполнены
                if (!emptyStages.length) {
                    this.setWorkflowTemplate()
                } else {
                    this.$snotify.error(`Есть незаполненные блоки: ${emptyStages.join(', ')}`, 'Отклонено!', {
                        timeout: 0
                    })
                }
            } else {
                this.$snotify.error('Добавьте хотя бы один блок', 'Отклонено!', {
                    timeout: 0
                })
            }
        }
    }

    setWorkflowTemplate(): void {
        const cancelPreloaderSetWorkflow = this.$preloader('set_workflow', 'Обновление конструктора жизненного цикла')

        this.callSetWorkflowTemplate({
            docType: this.$route.params.id,
            template: this.getWorkflow
        })
            .then(() => {
                // Убираем наличие изменений в workFlow
                this.workflowChange(false)
                this.clearAction()
                // Обнуляем активный stage
                this.changeWFActiveStage(null)
                // Обнуляем активный node и task
                this.changeWFActiveNode('')
                this.changeWFActiveTask('')
                this.addWorkflow(this.$route.params.id)
                cancelPreloaderSetWorkflow()
            })
            .catch(error => {
                this.$snotify.error(getError(error), {
                    timeout: 0
                })
            })
    }
}
