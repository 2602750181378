





























































































import { Component, Vue } from 'vue-property-decorator'

import DocumentCreationModal from '@/components/document/list/DocumentCreationModal.vue'
import HelpCard from '@/components/about_system/HelpCard.vue'
import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'

@Component({
    components: {
        DocumentCreationModal,
        HelpCard,
        ButtonAdd
    }
})

export default class HelpDocumentCreation extends Vue {
    // *************************************************************
    // DATA PARAMS
    oneUnitWithRoles = false
    visibleCreationModal = false

    // *************************************************************
    // METHODS
    showDocumentCreate(): void {
        this.visibleCreationModal = true
    }
}
