var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "report-all-users"
  }, [_c('Transition', {
    attrs: {
      "name": "filters"
    }
  }, [_vm.filters ? _c('div', {
    staticClass: "report-all-users__filters"
  }, [_c('InputSearch', {
    staticClass: "report-all-users__field",
    attrs: {
      "placeholder": "Поиск по имени пользователя"
    },
    model: {
      value: _vm.searchByName,
      callback: function callback($$v) {
        _vm.searchByName = $$v;
      },
      expression: "searchByName"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "report-all-users__grid"
  }, [_c('Checkbox', {
    attrs: {
      "label": "С именем"
    },
    model: {
      value: _vm.withFullName,
      callback: function callback($$v) {
        _vm.withFullName = $$v;
      },
      expression: "withFullName"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Без имени"
    },
    model: {
      value: _vm.withoutFullName,
      callback: function callback($$v) {
        _vm.withoutFullName = $$v;
      },
      expression: "withoutFullName"
    }
  })], 1), _vm._v(" "), _c('InputSearch', {
    staticClass: "report-all-users__field",
    attrs: {
      "placeholder": "Поиск по роли"
    },
    model: {
      value: _vm.searchByRole,
      callback: function callback($$v) {
        _vm.searchByRole = $$v;
      },
      expression: "searchByRole"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "report-all-users__grid"
  }, [_c('Checkbox', {
    attrs: {
      "label": "С ролями"
    },
    model: {
      value: _vm.withRoles,
      callback: function callback($$v) {
        _vm.withRoles = $$v;
      },
      expression: "withRoles"
    }
  }), _vm._v(" "), _c('Checkbox', {
    attrs: {
      "label": "Без ролей"
    },
    model: {
      value: _vm.withoutRoles,
      callback: function callback($$v) {
        _vm.withoutRoles = $$v;
      },
      expression: "withoutRoles"
    }
  })], 1), _vm._v(" "), _c('InputSearch', {
    staticClass: "report-all-users__field",
    attrs: {
      "placeholder": "Поиск по подразделению"
    },
    model: {
      value: _vm.searchByUnit,
      callback: function callback($$v) {
        _vm.searchByUnit = $$v;
      },
      expression: "searchByUnit"
    }
  }), _vm._v(" "), _vm.centers.length > 1 ? _c('div', {
    staticClass: "report-all-users__grid"
  }, _vm._l(_vm.centers, function (center) {
    return _c('div', {
      key: center.id
    }, [_c('CheckboxGroup', {
      attrs: {
        "value": center.id,
        "label": center.name
      },
      model: {
        value: _vm.selectedCenters,
        callback: function callback($$v) {
          _vm.selectedCenters = $$v;
        },
        expression: "selectedCenters"
      }
    })], 1);
  }), 0) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "report-all-users__table-wrapper"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _vm._v(" "), _c('tbody', _vm._l(_vm.usersFilter, function (user, index) {
    return _c('tr', {
      key: user.id
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _vm._v(" "), _c('td', [_vm._v(" " + _vm._s(user.name.fullName) + ": " + _vm._s(user.id))]), _vm._v(" "), _c('td', [_vm._v(" " + _vm._s(user.phone))]), _vm._v(" "), _c('td', [_vm._v(" " + _vm._s(user.email))]), _vm._v(" "), _c('td', [_c('ul', _vm._l(_vm.usersRoles[user.id], function (role) {
      return _c('li', {
        key: "".concat(role.unit, "-").concat(role.name)
      }, [_c('span', {
        style: {
          color: _vm.centers.length > 1 ? _vm.rolesColors[_vm.centers.findIndex(function (c) {
            return c.id === role.centerId;
          })] : 'black'
        }
      }, [_vm._v("\n                                    " + _vm._s(role.displayName) + ": " + _vm._s(role.unitName) + "\n                                ")])]);
    }), 0)])]);
  }), 0)])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("№")]), _vm._v(" "), _c('th', [_vm._v("Ф.И.О.: идентификатор")]), _vm._v(" "), _c('th', [_vm._v("Email")]), _vm._v(" "), _c('th', [_vm._v("Роли")]), _vm._v(" "), _c('th', [_vm._v("Телефон")])])]);
}]

export { render, staticRenderFns }