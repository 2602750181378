var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', _vm._g({
    staticClass: "task-list",
    class: {
      'task-list_child': _vm.taskHasParent
    }
  }, _vm.$listeners), [_c('div', {
    staticClass: "task-list__card"
  }, [_vm.children.length !== 0 ? _c('div', {
    staticClass: "task-list__drop",
    on: {
      "click": _vm.onClickDrop
    }
  }, [_c('div', {
    staticClass: "task-list__drop-arrow",
    class: {
      'task-list__drop-arrow_bottom': _vm.drop
    }
  })]) : _vm._e(), _vm._v(" "), _c('TaskCardBig', {
    attrs: {
      "task": _vm.task
    },
    on: {
      "create": function create($event) {
        return _vm.emitCreate($event);
      },
      "click": function click($event) {
        return _vm.emitOpenTask(_vm.task.id);
      }
    }
  })], 1), _vm._v(" "), _vm.drop ? _c('ul', {
    staticClass: "pl-6"
  }, _vm._l(_vm.children, function (child) {
    return _c('TaskList', {
      key: child,
      attrs: {
        "task-id": child,
        "filteredTasks": _vm.filteredTasks
      },
      on: {
        "create": function create($event) {
          return _vm.emitCreate($event);
        },
        "openTask": function openTask($event) {
          return _vm.emitOpenTask($event);
        }
      }
    });
  }), 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }