/* eslint-disable */
import Long from "long";
import { grpc } from "@improbable-eng/grpc-web";
import _m0 from "protobufjs/minimal";
import { Observable } from "rxjs";
import { BrowserHeaders } from "browser-headers";
import { share } from "rxjs/operators";

export const protobufPackage = "api.themakers.sc";

export interface UserEvent {
  EventID: string;
  Me: User | undefined;
  Historical: Event | undefined;
  Created: Event | undefined;
  Updated: Event | undefined;
  Deleted: DeletetionEvent | undefined;
  EndOfHistoricalEntities: Dummy | undefined;
  RolesChange: boolean | undefined;
  Ping: Ping | undefined;
}

export interface Event {
  User: User | undefined;
  Unit: Unit | undefined;
  DocumentType: DocumentType | undefined;
  WorkflowEvent: WorkflowEvent | undefined;
  Task: Task | undefined;
  Directory: Directory | undefined;
  Numerator: Numerator | undefined;
  Familiarization: Familiarization | undefined;
  Record: Record | undefined;
}

export interface DeletetionEvent {
  User: string | undefined;
  Unit: string | undefined;
  DocumentType: string | undefined;
  WorkflowEvent: string | undefined;
  Task: string | undefined;
  Directory: string | undefined;
  Record: string | undefined;
  Numerator: string | undefined;
  Familiarization: string | undefined;
}

export interface EventStreamReq {
  EventID: string;
}

export interface RetireReq {
  UserID: string;
  DefaultAlternateUserID: string;
  CenterID: string;
  Substitutions: Substitute[];
}

export interface Substitute {
  UnitID: string;
  Name: string;
  UserID: string;
}

export interface User {
  ID: string;
  Name: UserName | undefined;
  Roles: Role[];
  Avatar: string;
  Email: string;
  Phone: string;
  OneAss: UserOneAss | undefined;
  ModificationTime: number;
}

export interface UserName {
  First: string;
  Middle: string;
  Last: string;
}

export interface UserOneAss {
  IDs: string[];
}

export interface Role {
  Unit: string;
  Name: string;
}

export interface RolesOp {
  User: string;
  Grant: Role[];
  Revoke: Role[];
}

export interface SetUserNameReq {
  ID: string;
  Name: UserName | undefined;
}

export interface GetUserReq {
  ID: string;
}

export interface SetUserEmailReq {
  ID: string;
  Email: string;
}

export interface SetUserPhoneReq {
  ID: string;
  Phone: string;
}

export interface GetUsersByRolesReq {
  Roles: Role[];
}

export interface DocumentType {
  ID: string;
  CenterID: string;
  Name: string;
  Description: string;
  Code: string;
  Numerator: string;
  Requisites: DocumentTypeRequisite[];
  OneAss: DocumentTypeOneAss | undefined;
  ModificationTime: number;
}

export interface CreateDocumentTypeReq {
  CenterID: string;
  Name: string;
}

export interface DocumentTypeOneAss {
  CollectionID: string;
}

export interface SetnumeratorDocumenttypereq {
  DocumentTypeID: string;
  NumeratorID: string;
}

export interface DocumentTypeRequisite {
  ID: string;
  Name: string;
  Type: string;
  SubType: string;
  Required: boolean;
  Editable: boolean;
  FromExternalSource: boolean;
}

export interface RearangeRequisitesReq {
  TypeID: string;
  Order: string[];
}

export interface AddDocumentTypeRequisiteReq {
  ID: string;
  Requisite: DocumentTypeRequisite | undefined;
}

export interface UpdateDocumentTypeRequisiteReq {
  ID: string;
  RequisiteID: string;
  Name: string;
  Required: boolean;
  Editable: boolean;
}

export interface RemoveDocumentTypeRequisite {
  ID: string;
  Requisite: string;
}

export interface DocumentStreamReq {
  ID: string;
}

export interface DocumentStreamMessage {
  Dummy: Dummy | undefined;
  Document: Document | undefined;
  Event: DocumentEvent | undefined;
  Task: Task | undefined;
  TaskDeleted: string | undefined;
}

export interface ArchivedListStreamReq {
  DocumentTypeID: string;
}

export interface ArchivedListStreamMessage {
  EOS: string | undefined;
  Document: Document | undefined;
}

export interface DocumentEvent {
  ID: string;
  DocumentID: string;
  Type: string;
  Author: UserInfo | undefined;
  TS: number;
  Message: Message | undefined;
  StatusTransition: StatusTransition | undefined;
  Registration: Registration | undefined;
  Approved: ActorElementIDPair | undefined;
  Rejected: Rejected | undefined;
  OptionRequisiteUpdated: OptionRequisiteUpdated | undefined;
  LinkedTask: LinkedTask | undefined;
}

export interface Message {
  Message: string;
}

export interface StatusTransition {
  Previous: string;
  Current: string;
}

export interface Registration {
  Number: string;
}

export interface ActorElementIDPair {
  Actor: Actor | undefined;
  ElementID: string;
}

export interface Rejected {
  Actor: ActorElementIDPair | undefined;
  Reason: string;
  Victims: ActorElementIDPair[];
}

export interface OptionRequisiteUpdated {
  RecordID: string;
  RequisiteID: string;
  RequisiteName: string;
  RequisiteType: string;
  Value: string;
}

export interface LinkedTask {
  Type: string;
  ID: string;
  Text: string;
  Author: Actor | undefined;
  Assignee: Actor | undefined;
  Confirmer: Actor | undefined;
}

export interface ListStreamReq {}

export interface DocumentListStreamMessage {
  Dummy: Dummy | undefined;
  Document: Document | undefined;
  DocumentDeleted: string | undefined;
}

export interface Document {
  Base: DocumentBase | undefined;
  Comment: string;
  Requisites: DocumentRequisite[];
  Files: File[];
  Links: DocumentLink[];
  Workflow: Workflow | undefined;
  OneAss: DocumentOneAss | undefined;
}

export interface DocumentDraftReq {
  ID: string;
}

export interface DocumentArchiveReq {
  ID: string;
}

export interface DocumentStartReq {
  ID: string;
}

export interface DocumentOneAss {
  Author: DocumentOneAssAuthor | undefined;
}

export interface DocumentOneAssAuthor {
  ID: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
}

export interface File {
  ID: string;
  Name: string;
  Type: string;
  Size: number;
  Version: number;
}

export interface DocumentBase {
  ID: string;
  Name: string;
  Type: string;
  Unit: string;
  OriginUnit: string;
  Author: UserInfo | undefined;
  Number: string;
  Status: string;
  CreationTime: number;
  StatusChangeTime: number;
  ModificationTime: number;
}

export interface UserInfo {
  ID: string;
  Name: UserName | undefined;
}

export interface DocumentRequisite {
  ID: string;
  Type: DocumentTypeRequisite | undefined;
  Value: string;
  RecordName: string;
}

export interface GetAllByTypeReq {
  Type: string;
}

export interface NewDocumentReq {
  Name: string;
  Type: string;
  OriginUnit: string;
}

export interface RemoveDocumentReq {
  ID: string;
}

export interface UpdateDocumentNameReq {
  ID: string;
  Name: string;
}

export interface UpdateDocumentRequisiteReq {
  ID: string;
  Requisite: string;
  Value: string;
  RecordName: string;
}

export interface UpdateDocumentCommentReq {
  ID: string;
  Comment: string;
}

export interface DocumentPutMessageReq {
  Document: string;
  Message: string;
}

export interface DocumentRegisterReq {
  ID: string;
  Number: string;
}

export interface SetLinksReq {
  ID: string;
  Links: DocumentLink[];
}

export interface DocumentLink {
  Target: string;
  Kind: string;
}

export interface AddWorkflowTaskReq {
  Assignee: ActorIDs | undefined;
  Text: string;
  Description: string;
  Confirmer: ActorIDs | undefined;
  Document: string;
}

export interface AddActorToWorkflowApprovalReq {
  DocumentID: string;
  ApprovalID: string;
  Actor: ActorIDs | undefined;
}

export interface RemoveWorkflowTaskReq {
  TaskID: string;
  DocumentID: string;
}

export interface Actor {
  User: UserInfo | undefined;
  Unit: UnitInfo | undefined;
}

export interface ActorIDs {
  UserID: string;
  UnitID: string;
}

export interface UnitInfo {
  ID: string;
  Name: string;
}

export interface WorkflowTemplate {
  VVS: string;
  Static: boolean;
  Stages: WorkflowTemplateStage[];
}

export interface WorkflowTemplateStage {
  Type: string;
  DisplayName: string;
  /**
   * FIXME
   * oneof Stage {
   */
  Approvals: WorkflowTemplateStageApprovals | undefined;
  /** } */
  Tasks: WorkflowTemplateStageTasks | undefined;
}

export interface WorkflowTemplateStageApprovals {
  Approvals: ApprovalTemplate[];
}

export interface ApprovalTemplate {
  ID: string;
  Actor: Actor | undefined;
  SpecialName: string;
  Inputs: string[];
}

export interface WorkflowTemplateStageTasks {
  Tasks: TaskTemplate[];
}

export interface TaskTemplate {
  ID: string;
  Assignee: Actor | undefined;
  Text: string;
  Description: string;
  Confirmer: Actor | undefined;
  Inputs: string[];
}

export interface SetWorkflowTemplateReq {
  DocType: string;
  Template: WorkflowTemplate | undefined;
}

export interface GetWorkflowTemplateReq {
  DocType: string;
}

export interface WorkflowApprovalAcceptreq {
  Document: string;
  ID: string;
}

export interface WorkflowApprovalDroptoreq {
  Document: string;
  Approval: string;
  DropTo: string[];
  Reason: string;
}

export interface WorkflowTaskCompletereq {
  ID: string;
  Document: string;
}

export interface WorkflowTaskConfirmreq {
  ID: string;
  Document: string;
}

export interface WorkflowTaskRejectreq {
  ID: string;
  Document: string;
}

export interface Workflow {
  Stages: WorkflowStage[];
}

export interface WorkflowStage {
  Type: string;
  DisplayName: string;
  Completed: boolean;
  Graph: WorkflowElement[];
}

export interface WorkflowElement {
  ID: string;
  Actor: Actor | undefined;
  Special: WorkflowElementSpecial | undefined;
  Inputs: string[];
  Outputs: string[];
  Passed: boolean;
  Pending: boolean;
  AutoAccept: boolean;
  Approval: WorkflowApproval | undefined;
  Task: WorkflowTask | undefined;
}

export interface WorkflowElementSpecial {
  Name: string;
}

export interface WorkflowApproval {}

export interface WorkflowTask {
  Text: string;
  Description: string;
  Type: string;
}

export interface WorkflowEvent {
  ID: string;
  Document: WorkflowEventDocument | undefined;
  Actor: Actor | undefined;
  Type: string;
  Approval: WorkflowEventApproval | undefined;
  Task: WorkflowEventTask | undefined;
}

export interface WorkflowEventApproval {}

export interface WorkflowEventTask {
  Text: string;
  Description: string;
  Type: string;
}

export interface WorkflowEventDocument {
  ID: string;
  Name: string;
  Type: string;
  CreationTime: number;
}

export interface DelegateTaskReq {
  TaskID: string;
  NewAssigneeID: string;
  NewConfirmerID: string;
}

export interface ArchivedTasksStreamReq {}

export interface ArchivedTasksStreamMessage {
  EOS: string | undefined;
  Task: Task | undefined;
}

export interface TaskStreamReq {
  TaskID: string;
}

export interface TaskEvent {
  ID: string;
  TaskID: string;
  Type: string;
  Actor: UserInfo | undefined;
  TS: number;
  Fields: { [key: string]: string };
}

export interface TaskEvent_FieldsEntry {
  key: string;
  value: string;
}

export interface TaskStreamMessage {
  Task: Task | undefined;
  Event: TaskEvent | undefined;
}

export interface TaskPutMessageReq {
  TaskID: string;
  Message: string;
}

export interface Task {
  ID: string;
  Author: Actor | undefined;
  Assignee: Actor | undefined;
  Text: string;
  Description: string;
  Confirmer: Actor | undefined;
  Document: TaskDocument | undefined;
  Completed: boolean;
  Confirmed: boolean;
  Closed: boolean;
  Parent: string;
  Children: string[];
  CreationTime: number;
  DueTime: number;
  ModificationTime: number;
}

export interface TaskDocument {
  ID: string;
  Name: string;
  Type: string;
  CreationTime: number;
}

export interface CreateTaskReq {
  AuthorUnitID: string;
  Assignee: ActorIDs | undefined;
  Text: string;
  Description: string;
  Confirmer: ActorIDs | undefined;
  Document: string;
  Parent: string;
  DueTime: number;
}

export interface CompleteTaskReq {
  ID: string;
}

export interface RejectTaskReq {
  ID: string;
}

export interface ConfirmTaskReq {
  ID: string;
}

export interface ReopenReq {
  ID: string;
}

export interface CloseTaskReq {
  ID: string;
}

export interface RemoveTaskReq {
  ID: string;
}

export interface Unit {
  ID: string;
  CenterID: string;
  Parent: string;
  Root: boolean;
  Name: string;
  Code: string;
  OneAss: UnitOneAss | undefined;
  ModificationTime: number;
}

export interface UnitOneAss {
  ClientIDs: string[];
}

export interface CreateUnitReq {
  Name: string;
  Parent: string;
}

export interface RemoveUnitReq {
  ID: string;
}

export interface GetTosserReq {
  UnitID: string;
}

export interface UnitTosser {
  Rules: TosserRule[];
}

export interface TosserRule {
  DocumentType: string;
  Terms: TosserRuleTerm[];
  Actor: ActorIDs | undefined;
  AutoAccept: boolean;
}

export interface TosserRuleTerm {
  Requisite: string;
  Operator: string;
  Value: string;
}

export interface SetTosserReq {
  UnitID: string;
  DocTypeID: string;
  Rules: TosserRule[];
}

export interface FamiliarizationStreamReq {
  ID: string;
}

export interface ArchivedListFamiliarizationStreamReq {}

export interface ArchivedFamiliarizationStreamMessage {
  EOS: string | undefined;
  Familiarization: Familiarization | undefined;
}

export interface FamiliarizationStreamMessage {
  Dummy: Dummy | undefined;
  Familiarization: Familiarization | undefined;
  Event: FamiliarizationEvent | undefined;
}

export interface FamiliarizationEvent {
  ID: string;
  FamiliarizationID: string;
  Type: string;
  Author: UserInfo | undefined;
  TS: number;
  StatusTransition: StatusTransition | undefined;
  Familiarize: Familiarize | undefined;
  NewMembers: NewMembers | undefined;
}

export interface Familiarize {
  Actor: UserInfo | undefined;
}

export interface NewMembers {
  Users: UserInfo[];
  Units: UnitInfo[];
}

export interface Familiarization {
  ID: string;
  CenterID: string;
  Actor: Actor | undefined;
  Title: string;
  Description: string;
  CreationTime: number;
  ForceNewMembers: boolean;
  Files: File[];
  Status: string;
  UnitIDs: string[];
  Until: number;
  Documents: FamiliarizationDocument[];
  FamiliarizationLog: FamiliarizationLog[];
  ModificationTime: number;
}

export interface FamiliarizationDocument {
  ID: string;
  Name: string;
  Type: string;
}

export interface ActiveFamiliarizationReq {
  ID: string;
}

export interface DraftFamiliarizationReq {
  ID: string;
}

export interface ArchiveFamiliarizationReq {
  ID: string;
}

export interface CreateFamiliarizationReq {
  UnitID: string;
  Title: string;
}

export interface UpdateFamiliarizationReq {
  ID: string;
  Title: string;
  Description: string;
  ForceNewMembers: boolean;
  UnitIDs: string[];
  Until: number;
  DocumentIDs: string[];
  UserIDs: string[];
}

export interface AddMembersReq {
  ID: string;
  UserIDs: string[];
  UnitIDs: string[];
}

export interface FamiliarizationLog {
  User: UserInfo | undefined;
  Status: boolean;
  When: number;
}

export interface FamiliarizeReq {
  ID: string;
}

export interface RemoveFamiliarizationReq {
  ID: string;
}

export interface CreateDirectoryReq {
  CenterID: string;
  Name: string;
  Fields: DirectoryField[];
  DisplayNameFormatString: string;
}

export interface RemoveDirectoryReq {
  ID: string;
}

export interface SetDirectoryNameReq {
  ID: string;
  Name: string;
}

export interface SetDirectoryDisplayNameReq {
  ID: string;
  DisplayName: string;
}

export interface AddDirectoryFieldReq {
  DirectoryID: string;
  Position: number;
  Field: DirectoryField | undefined;
}

export interface RemoveDirectoryFieldReq {
  DirectoryID: string;
  Position: number;
}

export interface SetDirectoryFieldNameReq {
  DirectoryID: string;
  Position: number;
  Name: string;
}

export interface SetDirectoryFieldRequiredReq {
  DirectoryID: string;
  Position: number;
  Required: boolean;
}

export interface SetDirectoryArchivedReq {
  ID: string;
  Archived: boolean;
}

export interface AddRecordReq {
  DirectoryID: string;
  Fields: RecordField[];
}

export interface RemoveRecordReq {
  ID: string;
}

export interface SetRecordArchivedReq {
  ID: string;
  Archived: boolean;
}

export interface UpdateRecordFieldsReq {
  ID: string;
  Fields: RecordField[];
}

export interface Directory {
  ID: string;
  CenterID: string;
  Name: string;
  DisplayNameFormatString: string;
  ReadOnly: boolean;
  External: boolean;
  Archived: boolean;
  Fields: DirectoryField[];
  ModificationTime: number;
}

export interface DirectoryField {
  Name: string;
  Required: boolean;
  Type: string;
  SubType: string;
}

export interface Record {
  ID: string;
  DirectoryID: string;
  CenterID: string;
  UnitID: string;
  Archived: boolean;
  Fields: RecordField[];
  ModificationTime: number;
}

export interface RecordField {
  String: string;
  Number: string;
  Boolean: boolean;
  Reference: string;
  Time: Timex | undefined;
}

export interface Timex {
  UTC: number;
  Local: number;
  Offset: number;
  OffsetAbsent: boolean;
}

export interface MarkAsRootReq {
  ID: string;
  Root: boolean;
}

export interface MoveUnitReq {
  ID: string;
  Parent: string;
}

export interface SetUnitHeadReq {
  ID: string;
  Chief: string;
}

export interface AddEmployeeToUnitReq {
  Employee: string;
  Unit: string;
}

export interface RemoveEmployeeFromUnitReq {
  Employee: string;
  Unit: string;
}

export interface OneAssQueryAndWatchReq {}

export interface OneAssQueryAndWatchMsg {
  Directory: Directory | undefined;
  RecordEvent: OneAssRecordEvent | undefined;
  Dummy: Dummy | undefined;
}

export interface OneAssRecordEvent {
  ExistingRecord: Record | undefined;
  RecordCreated: Record | undefined;
  RecordUpdated: Record | undefined;
  RecordDeleted: Record | undefined;
}

export interface LoginAsReq {
  User: string;
  Secret: string;
}

export interface LoginAsResp {
  Session: string;
  User: string;
  Error: string;
}

export interface Dummy {}

export interface RemoveReq {
  ID: string;
}

export interface SetDescriptionReq {
  ID: string;
  Description: string;
}

export interface SetNameReq {
  ID: string;
  Name: string;
}

export interface SetCodeReq {
  ID: string;
  Code: string;
}

export interface Numerator {
  ID: string;
  CenterID: string;
  Name: string;
  Fields: NumeratorField[];
  ModificationTime: number;
}

export interface NumeratorField {
  ID: string;
  Type: string;
  Code: NumeratorCodeField | undefined;
  Text: NumeratorTextField | undefined;
  Incr: NumeratorIncrField | undefined;
}

export interface SetNumeratorReq {
  ID: string;
  Name: string;
  Fields: NumeratorField[];
}

export interface CreateNumeratorReq {
  CenterID: string;
  Name: string;
  Fields: NumeratorField[];
}

export interface GetNumeratorReq {
  ID: string;
}

export interface RemoveNumeratorReq {
  ID: string;
}

export interface RemoveNumeratorFieldReq {
  ID: string;
  FieldID: string;
}

export interface NumeratorCodeField {
  Source: string;
}

export interface NumeratorTextField {
  Text: string;
}

export interface NumeratorIncrField {
  Pad: number;
  ResetPeriod: string;
}

export interface Ping {
  Timestamp: number;
}

const baseUserEvent: object = { EventID: "" };

export const UserEvent = {
  encode(
    message: UserEvent,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.EventID !== "") {
      writer.uint32(10).string(message.EventID);
    }
    if (message.Me !== undefined) {
      User.encode(message.Me, writer.uint32(82).fork()).ldelim();
    }
    if (message.Historical !== undefined) {
      Event.encode(message.Historical, writer.uint32(90).fork()).ldelim();
    }
    if (message.Created !== undefined) {
      Event.encode(message.Created, writer.uint32(98).fork()).ldelim();
    }
    if (message.Updated !== undefined) {
      Event.encode(message.Updated, writer.uint32(106).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      DeletetionEvent.encode(
        message.Deleted,
        writer.uint32(114).fork()
      ).ldelim();
    }
    if (message.EndOfHistoricalEntities !== undefined) {
      Dummy.encode(
        message.EndOfHistoricalEntities,
        writer.uint32(122).fork()
      ).ldelim();
    }
    if (message.RolesChange !== undefined) {
      writer.uint32(800).bool(message.RolesChange);
    }
    if (message.Ping !== undefined) {
      Ping.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserEvent } as UserEvent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.EventID = reader.string();
          break;
        case 10:
          message.Me = User.decode(reader, reader.uint32());
          break;
        case 11:
          message.Historical = Event.decode(reader, reader.uint32());
          break;
        case 12:
          message.Created = Event.decode(reader, reader.uint32());
          break;
        case 13:
          message.Updated = Event.decode(reader, reader.uint32());
          break;
        case 14:
          message.Deleted = DeletetionEvent.decode(reader, reader.uint32());
          break;
        case 15:
          message.EndOfHistoricalEntities = Dummy.decode(
            reader,
            reader.uint32()
          );
          break;
        case 100:
          message.RolesChange = reader.bool();
          break;
        case 101:
          message.Ping = Ping.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserEvent {
    const message = { ...baseUserEvent } as UserEvent;
    if (object.EventID !== undefined && object.EventID !== null) {
      message.EventID = String(object.EventID);
    } else {
      message.EventID = "";
    }
    if (object.Me !== undefined && object.Me !== null) {
      message.Me = User.fromJSON(object.Me);
    } else {
      message.Me = undefined;
    }
    if (object.Historical !== undefined && object.Historical !== null) {
      message.Historical = Event.fromJSON(object.Historical);
    } else {
      message.Historical = undefined;
    }
    if (object.Created !== undefined && object.Created !== null) {
      message.Created = Event.fromJSON(object.Created);
    } else {
      message.Created = undefined;
    }
    if (object.Updated !== undefined && object.Updated !== null) {
      message.Updated = Event.fromJSON(object.Updated);
    } else {
      message.Updated = undefined;
    }
    if (object.Deleted !== undefined && object.Deleted !== null) {
      message.Deleted = DeletetionEvent.fromJSON(object.Deleted);
    } else {
      message.Deleted = undefined;
    }
    if (
      object.EndOfHistoricalEntities !== undefined &&
      object.EndOfHistoricalEntities !== null
    ) {
      message.EndOfHistoricalEntities = Dummy.fromJSON(
        object.EndOfHistoricalEntities
      );
    } else {
      message.EndOfHistoricalEntities = undefined;
    }
    if (object.RolesChange !== undefined && object.RolesChange !== null) {
      message.RolesChange = Boolean(object.RolesChange);
    } else {
      message.RolesChange = undefined;
    }
    if (object.Ping !== undefined && object.Ping !== null) {
      message.Ping = Ping.fromJSON(object.Ping);
    } else {
      message.Ping = undefined;
    }
    return message;
  },

  toJSON(message: UserEvent): unknown {
    const obj: any = {};
    message.EventID !== undefined && (obj.EventID = message.EventID);
    message.Me !== undefined &&
      (obj.Me = message.Me ? User.toJSON(message.Me) : undefined);
    message.Historical !== undefined &&
      (obj.Historical = message.Historical
        ? Event.toJSON(message.Historical)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Event.toJSON(message.Created)
        : undefined);
    message.Updated !== undefined &&
      (obj.Updated = message.Updated
        ? Event.toJSON(message.Updated)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? DeletetionEvent.toJSON(message.Deleted)
        : undefined);
    message.EndOfHistoricalEntities !== undefined &&
      (obj.EndOfHistoricalEntities = message.EndOfHistoricalEntities
        ? Dummy.toJSON(message.EndOfHistoricalEntities)
        : undefined);
    message.RolesChange !== undefined &&
      (obj.RolesChange = message.RolesChange);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Ping.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<UserEvent>): UserEvent {
    const message = { ...baseUserEvent } as UserEvent;
    if (object.EventID !== undefined && object.EventID !== null) {
      message.EventID = object.EventID;
    } else {
      message.EventID = "";
    }
    if (object.Me !== undefined && object.Me !== null) {
      message.Me = User.fromPartial(object.Me);
    } else {
      message.Me = undefined;
    }
    if (object.Historical !== undefined && object.Historical !== null) {
      message.Historical = Event.fromPartial(object.Historical);
    } else {
      message.Historical = undefined;
    }
    if (object.Created !== undefined && object.Created !== null) {
      message.Created = Event.fromPartial(object.Created);
    } else {
      message.Created = undefined;
    }
    if (object.Updated !== undefined && object.Updated !== null) {
      message.Updated = Event.fromPartial(object.Updated);
    } else {
      message.Updated = undefined;
    }
    if (object.Deleted !== undefined && object.Deleted !== null) {
      message.Deleted = DeletetionEvent.fromPartial(object.Deleted);
    } else {
      message.Deleted = undefined;
    }
    if (
      object.EndOfHistoricalEntities !== undefined &&
      object.EndOfHistoricalEntities !== null
    ) {
      message.EndOfHistoricalEntities = Dummy.fromPartial(
        object.EndOfHistoricalEntities
      );
    } else {
      message.EndOfHistoricalEntities = undefined;
    }
    if (object.RolesChange !== undefined && object.RolesChange !== null) {
      message.RolesChange = object.RolesChange;
    } else {
      message.RolesChange = undefined;
    }
    if (object.Ping !== undefined && object.Ping !== null) {
      message.Ping = Ping.fromPartial(object.Ping);
    } else {
      message.Ping = undefined;
    }
    return message;
  },
};

const baseEvent: object = {};

export const Event = {
  encode(message: Event, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(82).fork()).ldelim();
    }
    if (message.Unit !== undefined) {
      Unit.encode(message.Unit, writer.uint32(90).fork()).ldelim();
    }
    if (message.DocumentType !== undefined) {
      DocumentType.encode(
        message.DocumentType,
        writer.uint32(98).fork()
      ).ldelim();
    }
    if (message.WorkflowEvent !== undefined) {
      WorkflowEvent.encode(
        message.WorkflowEvent,
        writer.uint32(106).fork()
      ).ldelim();
    }
    if (message.Task !== undefined) {
      Task.encode(message.Task, writer.uint32(114).fork()).ldelim();
    }
    if (message.Directory !== undefined) {
      Directory.encode(message.Directory, writer.uint32(122).fork()).ldelim();
    }
    if (message.Numerator !== undefined) {
      Numerator.encode(message.Numerator, writer.uint32(130).fork()).ldelim();
    }
    if (message.Familiarization !== undefined) {
      Familiarization.encode(
        message.Familiarization,
        writer.uint32(138).fork()
      ).ldelim();
    }
    if (message.Record !== undefined) {
      Record.encode(message.Record, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEvent } as Event;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 10:
          message.User = User.decode(reader, reader.uint32());
          break;
        case 11:
          message.Unit = Unit.decode(reader, reader.uint32());
          break;
        case 12:
          message.DocumentType = DocumentType.decode(reader, reader.uint32());
          break;
        case 13:
          message.WorkflowEvent = WorkflowEvent.decode(reader, reader.uint32());
          break;
        case 14:
          message.Task = Task.decode(reader, reader.uint32());
          break;
        case 15:
          message.Directory = Directory.decode(reader, reader.uint32());
          break;
        case 16:
          message.Numerator = Numerator.decode(reader, reader.uint32());
          break;
        case 17:
          message.Familiarization = Familiarization.decode(
            reader,
            reader.uint32()
          );
          break;
        case 18:
          message.Record = Record.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Event {
    const message = { ...baseEvent } as Event;
    if (object.User !== undefined && object.User !== null) {
      message.User = User.fromJSON(object.User);
    } else {
      message.User = undefined;
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = Unit.fromJSON(object.Unit);
    } else {
      message.Unit = undefined;
    }
    if (object.DocumentType !== undefined && object.DocumentType !== null) {
      message.DocumentType = DocumentType.fromJSON(object.DocumentType);
    } else {
      message.DocumentType = undefined;
    }
    if (object.WorkflowEvent !== undefined && object.WorkflowEvent !== null) {
      message.WorkflowEvent = WorkflowEvent.fromJSON(object.WorkflowEvent);
    } else {
      message.WorkflowEvent = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = Task.fromJSON(object.Task);
    } else {
      message.Task = undefined;
    }
    if (object.Directory !== undefined && object.Directory !== null) {
      message.Directory = Directory.fromJSON(object.Directory);
    } else {
      message.Directory = undefined;
    }
    if (object.Numerator !== undefined && object.Numerator !== null) {
      message.Numerator = Numerator.fromJSON(object.Numerator);
    } else {
      message.Numerator = undefined;
    }
    if (
      object.Familiarization !== undefined &&
      object.Familiarization !== null
    ) {
      message.Familiarization = Familiarization.fromJSON(
        object.Familiarization
      );
    } else {
      message.Familiarization = undefined;
    }
    if (object.Record !== undefined && object.Record !== null) {
      message.Record = Record.fromJSON(object.Record);
    } else {
      message.Record = undefined;
    }
    return message;
  },

  toJSON(message: Event): unknown {
    const obj: any = {};
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    message.Unit !== undefined &&
      (obj.Unit = message.Unit ? Unit.toJSON(message.Unit) : undefined);
    message.DocumentType !== undefined &&
      (obj.DocumentType = message.DocumentType
        ? DocumentType.toJSON(message.DocumentType)
        : undefined);
    message.WorkflowEvent !== undefined &&
      (obj.WorkflowEvent = message.WorkflowEvent
        ? WorkflowEvent.toJSON(message.WorkflowEvent)
        : undefined);
    message.Task !== undefined &&
      (obj.Task = message.Task ? Task.toJSON(message.Task) : undefined);
    message.Directory !== undefined &&
      (obj.Directory = message.Directory
        ? Directory.toJSON(message.Directory)
        : undefined);
    message.Numerator !== undefined &&
      (obj.Numerator = message.Numerator
        ? Numerator.toJSON(message.Numerator)
        : undefined);
    message.Familiarization !== undefined &&
      (obj.Familiarization = message.Familiarization
        ? Familiarization.toJSON(message.Familiarization)
        : undefined);
    message.Record !== undefined &&
      (obj.Record = message.Record ? Record.toJSON(message.Record) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Event>): Event {
    const message = { ...baseEvent } as Event;
    if (object.User !== undefined && object.User !== null) {
      message.User = User.fromPartial(object.User);
    } else {
      message.User = undefined;
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = Unit.fromPartial(object.Unit);
    } else {
      message.Unit = undefined;
    }
    if (object.DocumentType !== undefined && object.DocumentType !== null) {
      message.DocumentType = DocumentType.fromPartial(object.DocumentType);
    } else {
      message.DocumentType = undefined;
    }
    if (object.WorkflowEvent !== undefined && object.WorkflowEvent !== null) {
      message.WorkflowEvent = WorkflowEvent.fromPartial(object.WorkflowEvent);
    } else {
      message.WorkflowEvent = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = Task.fromPartial(object.Task);
    } else {
      message.Task = undefined;
    }
    if (object.Directory !== undefined && object.Directory !== null) {
      message.Directory = Directory.fromPartial(object.Directory);
    } else {
      message.Directory = undefined;
    }
    if (object.Numerator !== undefined && object.Numerator !== null) {
      message.Numerator = Numerator.fromPartial(object.Numerator);
    } else {
      message.Numerator = undefined;
    }
    if (
      object.Familiarization !== undefined &&
      object.Familiarization !== null
    ) {
      message.Familiarization = Familiarization.fromPartial(
        object.Familiarization
      );
    } else {
      message.Familiarization = undefined;
    }
    if (object.Record !== undefined && object.Record !== null) {
      message.Record = Record.fromPartial(object.Record);
    } else {
      message.Record = undefined;
    }
    return message;
  },
};

const baseDeletetionEvent: object = {};

export const DeletetionEvent = {
  encode(
    message: DeletetionEvent,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.User !== undefined) {
      writer.uint32(162).string(message.User);
    }
    if (message.Unit !== undefined) {
      writer.uint32(170).string(message.Unit);
    }
    if (message.DocumentType !== undefined) {
      writer.uint32(186).string(message.DocumentType);
    }
    if (message.WorkflowEvent !== undefined) {
      writer.uint32(194).string(message.WorkflowEvent);
    }
    if (message.Task !== undefined) {
      writer.uint32(202).string(message.Task);
    }
    if (message.Directory !== undefined) {
      writer.uint32(210).string(message.Directory);
    }
    if (message.Record !== undefined) {
      writer.uint32(218).string(message.Record);
    }
    if (message.Numerator !== undefined) {
      writer.uint32(226).string(message.Numerator);
    }
    if (message.Familiarization !== undefined) {
      writer.uint32(234).string(message.Familiarization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletetionEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDeletetionEvent } as DeletetionEvent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 20:
          message.User = reader.string();
          break;
        case 21:
          message.Unit = reader.string();
          break;
        case 23:
          message.DocumentType = reader.string();
          break;
        case 24:
          message.WorkflowEvent = reader.string();
          break;
        case 25:
          message.Task = reader.string();
          break;
        case 26:
          message.Directory = reader.string();
          break;
        case 27:
          message.Record = reader.string();
          break;
        case 28:
          message.Numerator = reader.string();
          break;
        case 29:
          message.Familiarization = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeletetionEvent {
    const message = { ...baseDeletetionEvent } as DeletetionEvent;
    if (object.User !== undefined && object.User !== null) {
      message.User = String(object.User);
    } else {
      message.User = undefined;
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = String(object.Unit);
    } else {
      message.Unit = undefined;
    }
    if (object.DocumentType !== undefined && object.DocumentType !== null) {
      message.DocumentType = String(object.DocumentType);
    } else {
      message.DocumentType = undefined;
    }
    if (object.WorkflowEvent !== undefined && object.WorkflowEvent !== null) {
      message.WorkflowEvent = String(object.WorkflowEvent);
    } else {
      message.WorkflowEvent = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = String(object.Task);
    } else {
      message.Task = undefined;
    }
    if (object.Directory !== undefined && object.Directory !== null) {
      message.Directory = String(object.Directory);
    } else {
      message.Directory = undefined;
    }
    if (object.Record !== undefined && object.Record !== null) {
      message.Record = String(object.Record);
    } else {
      message.Record = undefined;
    }
    if (object.Numerator !== undefined && object.Numerator !== null) {
      message.Numerator = String(object.Numerator);
    } else {
      message.Numerator = undefined;
    }
    if (
      object.Familiarization !== undefined &&
      object.Familiarization !== null
    ) {
      message.Familiarization = String(object.Familiarization);
    } else {
      message.Familiarization = undefined;
    }
    return message;
  },

  toJSON(message: DeletetionEvent): unknown {
    const obj: any = {};
    message.User !== undefined && (obj.User = message.User);
    message.Unit !== undefined && (obj.Unit = message.Unit);
    message.DocumentType !== undefined &&
      (obj.DocumentType = message.DocumentType);
    message.WorkflowEvent !== undefined &&
      (obj.WorkflowEvent = message.WorkflowEvent);
    message.Task !== undefined && (obj.Task = message.Task);
    message.Directory !== undefined && (obj.Directory = message.Directory);
    message.Record !== undefined && (obj.Record = message.Record);
    message.Numerator !== undefined && (obj.Numerator = message.Numerator);
    message.Familiarization !== undefined &&
      (obj.Familiarization = message.Familiarization);
    return obj;
  },

  fromPartial(object: DeepPartial<DeletetionEvent>): DeletetionEvent {
    const message = { ...baseDeletetionEvent } as DeletetionEvent;
    if (object.User !== undefined && object.User !== null) {
      message.User = object.User;
    } else {
      message.User = undefined;
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = object.Unit;
    } else {
      message.Unit = undefined;
    }
    if (object.DocumentType !== undefined && object.DocumentType !== null) {
      message.DocumentType = object.DocumentType;
    } else {
      message.DocumentType = undefined;
    }
    if (object.WorkflowEvent !== undefined && object.WorkflowEvent !== null) {
      message.WorkflowEvent = object.WorkflowEvent;
    } else {
      message.WorkflowEvent = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = object.Task;
    } else {
      message.Task = undefined;
    }
    if (object.Directory !== undefined && object.Directory !== null) {
      message.Directory = object.Directory;
    } else {
      message.Directory = undefined;
    }
    if (object.Record !== undefined && object.Record !== null) {
      message.Record = object.Record;
    } else {
      message.Record = undefined;
    }
    if (object.Numerator !== undefined && object.Numerator !== null) {
      message.Numerator = object.Numerator;
    } else {
      message.Numerator = undefined;
    }
    if (
      object.Familiarization !== undefined &&
      object.Familiarization !== null
    ) {
      message.Familiarization = object.Familiarization;
    } else {
      message.Familiarization = undefined;
    }
    return message;
  },
};

const baseEventStreamReq: object = { EventID: "" };

export const EventStreamReq = {
  encode(
    message: EventStreamReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.EventID !== "") {
      writer.uint32(10).string(message.EventID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseEventStreamReq } as EventStreamReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.EventID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EventStreamReq {
    const message = { ...baseEventStreamReq } as EventStreamReq;
    if (object.EventID !== undefined && object.EventID !== null) {
      message.EventID = String(object.EventID);
    } else {
      message.EventID = "";
    }
    return message;
  },

  toJSON(message: EventStreamReq): unknown {
    const obj: any = {};
    message.EventID !== undefined && (obj.EventID = message.EventID);
    return obj;
  },

  fromPartial(object: DeepPartial<EventStreamReq>): EventStreamReq {
    const message = { ...baseEventStreamReq } as EventStreamReq;
    if (object.EventID !== undefined && object.EventID !== null) {
      message.EventID = object.EventID;
    } else {
      message.EventID = "";
    }
    return message;
  },
};

const baseRetireReq: object = {
  UserID: "",
  DefaultAlternateUserID: "",
  CenterID: "",
};

export const RetireReq = {
  encode(
    message: RetireReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.UserID !== "") {
      writer.uint32(10).string(message.UserID);
    }
    if (message.DefaultAlternateUserID !== "") {
      writer.uint32(18).string(message.DefaultAlternateUserID);
    }
    if (message.CenterID !== "") {
      writer.uint32(26).string(message.CenterID);
    }
    for (const v of message.Substitutions) {
      Substitute.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RetireReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRetireReq } as RetireReq;
    message.Substitutions = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.DefaultAlternateUserID = reader.string();
          break;
        case 3:
          message.CenterID = reader.string();
          break;
        case 4:
          message.Substitutions.push(
            Substitute.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RetireReq {
    const message = { ...baseRetireReq } as RetireReq;
    message.Substitutions = [];
    if (object.UserID !== undefined && object.UserID !== null) {
      message.UserID = String(object.UserID);
    } else {
      message.UserID = "";
    }
    if (
      object.DefaultAlternateUserID !== undefined &&
      object.DefaultAlternateUserID !== null
    ) {
      message.DefaultAlternateUserID = String(object.DefaultAlternateUserID);
    } else {
      message.DefaultAlternateUserID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Substitutions !== undefined && object.Substitutions !== null) {
      for (const e of object.Substitutions) {
        message.Substitutions.push(Substitute.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: RetireReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.DefaultAlternateUserID !== undefined &&
      (obj.DefaultAlternateUserID = message.DefaultAlternateUserID);
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    if (message.Substitutions) {
      obj.Substitutions = message.Substitutions.map((e) =>
        e ? Substitute.toJSON(e) : undefined
      );
    } else {
      obj.Substitutions = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<RetireReq>): RetireReq {
    const message = { ...baseRetireReq } as RetireReq;
    message.Substitutions = [];
    if (object.UserID !== undefined && object.UserID !== null) {
      message.UserID = object.UserID;
    } else {
      message.UserID = "";
    }
    if (
      object.DefaultAlternateUserID !== undefined &&
      object.DefaultAlternateUserID !== null
    ) {
      message.DefaultAlternateUserID = object.DefaultAlternateUserID;
    } else {
      message.DefaultAlternateUserID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Substitutions !== undefined && object.Substitutions !== null) {
      for (const e of object.Substitutions) {
        message.Substitutions.push(Substitute.fromPartial(e));
      }
    }
    return message;
  },
};

const baseSubstitute: object = { UnitID: "", Name: "", UserID: "" };

export const Substitute = {
  encode(
    message: Substitute,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.UnitID !== "") {
      writer.uint32(10).string(message.UnitID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.UserID !== "") {
      writer.uint32(26).string(message.UserID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Substitute {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSubstitute } as Substitute;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UnitID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.UserID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Substitute {
    const message = { ...baseSubstitute } as Substitute;
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = String(object.UnitID);
    } else {
      message.UnitID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.UserID !== undefined && object.UserID !== null) {
      message.UserID = String(object.UserID);
    } else {
      message.UserID = "";
    }
    return message;
  },

  toJSON(message: Substitute): unknown {
    const obj: any = {};
    message.UnitID !== undefined && (obj.UnitID = message.UnitID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.UserID !== undefined && (obj.UserID = message.UserID);
    return obj;
  },

  fromPartial(object: DeepPartial<Substitute>): Substitute {
    const message = { ...baseSubstitute } as Substitute;
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = object.UnitID;
    } else {
      message.UnitID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.UserID !== undefined && object.UserID !== null) {
      message.UserID = object.UserID;
    } else {
      message.UserID = "";
    }
    return message;
  },
};

const baseUser: object = {
  ID: "",
  Avatar: "",
  Email: "",
  Phone: "",
  ModificationTime: 0,
};

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== undefined) {
      UserName.encode(message.Name, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Roles) {
      Role.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.Avatar !== "") {
      writer.uint32(34).string(message.Avatar);
    }
    if (message.Email !== "") {
      writer.uint32(42).string(message.Email);
    }
    if (message.Phone !== "") {
      writer.uint32(50).string(message.Phone);
    }
    if (message.OneAss !== undefined) {
      UserOneAss.encode(message.OneAss, writer.uint32(58).fork()).ldelim();
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUser } as User;
    message.Roles = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = UserName.decode(reader, reader.uint32());
          break;
        case 3:
          message.Roles.push(Role.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Avatar = reader.string();
          break;
        case 5:
          message.Email = reader.string();
          break;
        case 6:
          message.Phone = reader.string();
          break;
        case 7:
          message.OneAss = UserOneAss.decode(reader, reader.uint32());
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): User {
    const message = { ...baseUser } as User;
    message.Roles = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = UserName.fromJSON(object.Name);
    } else {
      message.Name = undefined;
    }
    if (object.Roles !== undefined && object.Roles !== null) {
      for (const e of object.Roles) {
        message.Roles.push(Role.fromJSON(e));
      }
    }
    if (object.Avatar !== undefined && object.Avatar !== null) {
      message.Avatar = String(object.Avatar);
    } else {
      message.Avatar = "";
    }
    if (object.Email !== undefined && object.Email !== null) {
      message.Email = String(object.Email);
    } else {
      message.Email = "";
    }
    if (object.Phone !== undefined && object.Phone !== null) {
      message.Phone = String(object.Phone);
    } else {
      message.Phone = "";
    }
    if (object.OneAss !== undefined && object.OneAss !== null) {
      message.OneAss = UserOneAss.fromJSON(object.OneAss);
    } else {
      message.OneAss = undefined;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined &&
      (obj.Name = message.Name ? UserName.toJSON(message.Name) : undefined);
    if (message.Roles) {
      obj.Roles = message.Roles.map((e) => (e ? Role.toJSON(e) : undefined));
    } else {
      obj.Roles = [];
    }
    message.Avatar !== undefined && (obj.Avatar = message.Avatar);
    message.Email !== undefined && (obj.Email = message.Email);
    message.Phone !== undefined && (obj.Phone = message.Phone);
    message.OneAss !== undefined &&
      (obj.OneAss = message.OneAss
        ? UserOneAss.toJSON(message.OneAss)
        : undefined);
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<User>): User {
    const message = { ...baseUser } as User;
    message.Roles = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = UserName.fromPartial(object.Name);
    } else {
      message.Name = undefined;
    }
    if (object.Roles !== undefined && object.Roles !== null) {
      for (const e of object.Roles) {
        message.Roles.push(Role.fromPartial(e));
      }
    }
    if (object.Avatar !== undefined && object.Avatar !== null) {
      message.Avatar = object.Avatar;
    } else {
      message.Avatar = "";
    }
    if (object.Email !== undefined && object.Email !== null) {
      message.Email = object.Email;
    } else {
      message.Email = "";
    }
    if (object.Phone !== undefined && object.Phone !== null) {
      message.Phone = object.Phone;
    } else {
      message.Phone = "";
    }
    if (object.OneAss !== undefined && object.OneAss !== null) {
      message.OneAss = UserOneAss.fromPartial(object.OneAss);
    } else {
      message.OneAss = undefined;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseUserName: object = { First: "", Middle: "", Last: "" };

export const UserName = {
  encode(
    message: UserName,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.First !== "") {
      writer.uint32(10).string(message.First);
    }
    if (message.Middle !== "") {
      writer.uint32(18).string(message.Middle);
    }
    if (message.Last !== "") {
      writer.uint32(26).string(message.Last);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserName {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserName } as UserName;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.First = reader.string();
          break;
        case 2:
          message.Middle = reader.string();
          break;
        case 3:
          message.Last = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserName {
    const message = { ...baseUserName } as UserName;
    if (object.First !== undefined && object.First !== null) {
      message.First = String(object.First);
    } else {
      message.First = "";
    }
    if (object.Middle !== undefined && object.Middle !== null) {
      message.Middle = String(object.Middle);
    } else {
      message.Middle = "";
    }
    if (object.Last !== undefined && object.Last !== null) {
      message.Last = String(object.Last);
    } else {
      message.Last = "";
    }
    return message;
  },

  toJSON(message: UserName): unknown {
    const obj: any = {};
    message.First !== undefined && (obj.First = message.First);
    message.Middle !== undefined && (obj.Middle = message.Middle);
    message.Last !== undefined && (obj.Last = message.Last);
    return obj;
  },

  fromPartial(object: DeepPartial<UserName>): UserName {
    const message = { ...baseUserName } as UserName;
    if (object.First !== undefined && object.First !== null) {
      message.First = object.First;
    } else {
      message.First = "";
    }
    if (object.Middle !== undefined && object.Middle !== null) {
      message.Middle = object.Middle;
    } else {
      message.Middle = "";
    }
    if (object.Last !== undefined && object.Last !== null) {
      message.Last = object.Last;
    } else {
      message.Last = "";
    }
    return message;
  },
};

const baseUserOneAss: object = { IDs: "" };

export const UserOneAss = {
  encode(
    message: UserOneAss,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.IDs) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserOneAss {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserOneAss } as UserOneAss;
    message.IDs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserOneAss {
    const message = { ...baseUserOneAss } as UserOneAss;
    message.IDs = [];
    if (object.IDs !== undefined && object.IDs !== null) {
      for (const e of object.IDs) {
        message.IDs.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: UserOneAss): unknown {
    const obj: any = {};
    if (message.IDs) {
      obj.IDs = message.IDs.map((e) => e);
    } else {
      obj.IDs = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<UserOneAss>): UserOneAss {
    const message = { ...baseUserOneAss } as UserOneAss;
    message.IDs = [];
    if (object.IDs !== undefined && object.IDs !== null) {
      for (const e of object.IDs) {
        message.IDs.push(e);
      }
    }
    return message;
  },
};

const baseRole: object = { Unit: "", Name: "" };

export const Role = {
  encode(message: Role, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Unit !== "") {
      writer.uint32(10).string(message.Unit);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Role {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRole } as Role;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Unit = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Role {
    const message = { ...baseRole } as Role;
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = String(object.Unit);
    } else {
      message.Unit = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    return message;
  },

  toJSON(message: Role): unknown {
    const obj: any = {};
    message.Unit !== undefined && (obj.Unit = message.Unit);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial(object: DeepPartial<Role>): Role {
    const message = { ...baseRole } as Role;
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = object.Unit;
    } else {
      message.Unit = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    return message;
  },
};

const baseRolesOp: object = { User: "" };

export const RolesOp = {
  encode(
    message: RolesOp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.User !== "") {
      writer.uint32(10).string(message.User);
    }
    for (const v of message.Grant) {
      Role.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Revoke) {
      Role.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RolesOp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRolesOp } as RolesOp;
    message.Grant = [];
    message.Revoke = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = reader.string();
          break;
        case 2:
          message.Grant.push(Role.decode(reader, reader.uint32()));
          break;
        case 3:
          message.Revoke.push(Role.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RolesOp {
    const message = { ...baseRolesOp } as RolesOp;
    message.Grant = [];
    message.Revoke = [];
    if (object.User !== undefined && object.User !== null) {
      message.User = String(object.User);
    } else {
      message.User = "";
    }
    if (object.Grant !== undefined && object.Grant !== null) {
      for (const e of object.Grant) {
        message.Grant.push(Role.fromJSON(e));
      }
    }
    if (object.Revoke !== undefined && object.Revoke !== null) {
      for (const e of object.Revoke) {
        message.Revoke.push(Role.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: RolesOp): unknown {
    const obj: any = {};
    message.User !== undefined && (obj.User = message.User);
    if (message.Grant) {
      obj.Grant = message.Grant.map((e) => (e ? Role.toJSON(e) : undefined));
    } else {
      obj.Grant = [];
    }
    if (message.Revoke) {
      obj.Revoke = message.Revoke.map((e) => (e ? Role.toJSON(e) : undefined));
    } else {
      obj.Revoke = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<RolesOp>): RolesOp {
    const message = { ...baseRolesOp } as RolesOp;
    message.Grant = [];
    message.Revoke = [];
    if (object.User !== undefined && object.User !== null) {
      message.User = object.User;
    } else {
      message.User = "";
    }
    if (object.Grant !== undefined && object.Grant !== null) {
      for (const e of object.Grant) {
        message.Grant.push(Role.fromPartial(e));
      }
    }
    if (object.Revoke !== undefined && object.Revoke !== null) {
      for (const e of object.Revoke) {
        message.Revoke.push(Role.fromPartial(e));
      }
    }
    return message;
  },
};

const baseSetUserNameReq: object = { ID: "" };

export const SetUserNameReq = {
  encode(
    message: SetUserNameReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== undefined) {
      UserName.encode(message.Name, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetUserNameReq } as SetUserNameReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = UserName.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserNameReq {
    const message = { ...baseSetUserNameReq } as SetUserNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = UserName.fromJSON(object.Name);
    } else {
      message.Name = undefined;
    }
    return message;
  },

  toJSON(message: SetUserNameReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined &&
      (obj.Name = message.Name ? UserName.toJSON(message.Name) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<SetUserNameReq>): SetUserNameReq {
    const message = { ...baseSetUserNameReq } as SetUserNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = UserName.fromPartial(object.Name);
    } else {
      message.Name = undefined;
    }
    return message;
  },
};

const baseGetUserReq: object = { ID: "" };

export const GetUserReq = {
  encode(
    message: GetUserReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserReq } as GetUserReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetUserReq {
    const message = { ...baseGetUserReq } as GetUserReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: GetUserReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<GetUserReq>): GetUserReq {
    const message = { ...baseGetUserReq } as GetUserReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseSetUserEmailReq: object = { ID: "", Email: "" };

export const SetUserEmailReq = {
  encode(
    message: SetUserEmailReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Email !== "") {
      writer.uint32(18).string(message.Email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserEmailReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetUserEmailReq } as SetUserEmailReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserEmailReq {
    const message = { ...baseSetUserEmailReq } as SetUserEmailReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Email !== undefined && object.Email !== null) {
      message.Email = String(object.Email);
    } else {
      message.Email = "";
    }
    return message;
  },

  toJSON(message: SetUserEmailReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial(object: DeepPartial<SetUserEmailReq>): SetUserEmailReq {
    const message = { ...baseSetUserEmailReq } as SetUserEmailReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Email !== undefined && object.Email !== null) {
      message.Email = object.Email;
    } else {
      message.Email = "";
    }
    return message;
  },
};

const baseSetUserPhoneReq: object = { ID: "", Phone: "" };

export const SetUserPhoneReq = {
  encode(
    message: SetUserPhoneReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Phone !== "") {
      writer.uint32(18).string(message.Phone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUserPhoneReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetUserPhoneReq } as SetUserPhoneReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Phone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUserPhoneReq {
    const message = { ...baseSetUserPhoneReq } as SetUserPhoneReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Phone !== undefined && object.Phone !== null) {
      message.Phone = String(object.Phone);
    } else {
      message.Phone = "";
    }
    return message;
  },

  toJSON(message: SetUserPhoneReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Phone !== undefined && (obj.Phone = message.Phone);
    return obj;
  },

  fromPartial(object: DeepPartial<SetUserPhoneReq>): SetUserPhoneReq {
    const message = { ...baseSetUserPhoneReq } as SetUserPhoneReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Phone !== undefined && object.Phone !== null) {
      message.Phone = object.Phone;
    } else {
      message.Phone = "";
    }
    return message;
  },
};

const baseGetUsersByRolesReq: object = {};

export const GetUsersByRolesReq = {
  encode(
    message: GetUsersByRolesReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Roles) {
      Role.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUsersByRolesReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUsersByRolesReq } as GetUsersByRolesReq;
    message.Roles = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Roles.push(Role.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetUsersByRolesReq {
    const message = { ...baseGetUsersByRolesReq } as GetUsersByRolesReq;
    message.Roles = [];
    if (object.Roles !== undefined && object.Roles !== null) {
      for (const e of object.Roles) {
        message.Roles.push(Role.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetUsersByRolesReq): unknown {
    const obj: any = {};
    if (message.Roles) {
      obj.Roles = message.Roles.map((e) => (e ? Role.toJSON(e) : undefined));
    } else {
      obj.Roles = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetUsersByRolesReq>): GetUsersByRolesReq {
    const message = { ...baseGetUsersByRolesReq } as GetUsersByRolesReq;
    message.Roles = [];
    if (object.Roles !== undefined && object.Roles !== null) {
      for (const e of object.Roles) {
        message.Roles.push(Role.fromPartial(e));
      }
    }
    return message;
  },
};

const baseDocumentType: object = {
  ID: "",
  CenterID: "",
  Name: "",
  Description: "",
  Code: "",
  Numerator: "",
  ModificationTime: 0,
};

export const DocumentType = {
  encode(
    message: DocumentType,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.CenterID !== "") {
      writer.uint32(18).string(message.CenterID);
    }
    if (message.Name !== "") {
      writer.uint32(26).string(message.Name);
    }
    if (message.Description !== "") {
      writer.uint32(34).string(message.Description);
    }
    if (message.Code !== "") {
      writer.uint32(42).string(message.Code);
    }
    if (message.Numerator !== "") {
      writer.uint32(50).string(message.Numerator);
    }
    for (const v of message.Requisites) {
      DocumentTypeRequisite.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.OneAss !== undefined) {
      DocumentTypeOneAss.encode(
        message.OneAss,
        writer.uint32(66).fork()
      ).ldelim();
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentType {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentType } as DocumentType;
    message.Requisites = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.CenterID = reader.string();
          break;
        case 3:
          message.Name = reader.string();
          break;
        case 4:
          message.Description = reader.string();
          break;
        case 5:
          message.Code = reader.string();
          break;
        case 6:
          message.Numerator = reader.string();
          break;
        case 7:
          message.Requisites.push(
            DocumentTypeRequisite.decode(reader, reader.uint32())
          );
          break;
        case 8:
          message.OneAss = DocumentTypeOneAss.decode(reader, reader.uint32());
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentType {
    const message = { ...baseDocumentType } as DocumentType;
    message.Requisites = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (object.Code !== undefined && object.Code !== null) {
      message.Code = String(object.Code);
    } else {
      message.Code = "";
    }
    if (object.Numerator !== undefined && object.Numerator !== null) {
      message.Numerator = String(object.Numerator);
    } else {
      message.Numerator = "";
    }
    if (object.Requisites !== undefined && object.Requisites !== null) {
      for (const e of object.Requisites) {
        message.Requisites.push(DocumentTypeRequisite.fromJSON(e));
      }
    }
    if (object.OneAss !== undefined && object.OneAss !== null) {
      message.OneAss = DocumentTypeOneAss.fromJSON(object.OneAss);
    } else {
      message.OneAss = undefined;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: DocumentType): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Code !== undefined && (obj.Code = message.Code);
    message.Numerator !== undefined && (obj.Numerator = message.Numerator);
    if (message.Requisites) {
      obj.Requisites = message.Requisites.map((e) =>
        e ? DocumentTypeRequisite.toJSON(e) : undefined
      );
    } else {
      obj.Requisites = [];
    }
    message.OneAss !== undefined &&
      (obj.OneAss = message.OneAss
        ? DocumentTypeOneAss.toJSON(message.OneAss)
        : undefined);
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentType>): DocumentType {
    const message = { ...baseDocumentType } as DocumentType;
    message.Requisites = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (object.Code !== undefined && object.Code !== null) {
      message.Code = object.Code;
    } else {
      message.Code = "";
    }
    if (object.Numerator !== undefined && object.Numerator !== null) {
      message.Numerator = object.Numerator;
    } else {
      message.Numerator = "";
    }
    if (object.Requisites !== undefined && object.Requisites !== null) {
      for (const e of object.Requisites) {
        message.Requisites.push(DocumentTypeRequisite.fromPartial(e));
      }
    }
    if (object.OneAss !== undefined && object.OneAss !== null) {
      message.OneAss = DocumentTypeOneAss.fromPartial(object.OneAss);
    } else {
      message.OneAss = undefined;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseCreateDocumentTypeReq: object = { CenterID: "", Name: "" };

export const CreateDocumentTypeReq = {
  encode(
    message: CreateDocumentTypeReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.CenterID !== "") {
      writer.uint32(10).string(message.CenterID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateDocumentTypeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateDocumentTypeReq } as CreateDocumentTypeReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CenterID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateDocumentTypeReq {
    const message = { ...baseCreateDocumentTypeReq } as CreateDocumentTypeReq;
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    return message;
  },

  toJSON(message: CreateDocumentTypeReq): unknown {
    const obj: any = {};
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial(
    object: DeepPartial<CreateDocumentTypeReq>
  ): CreateDocumentTypeReq {
    const message = { ...baseCreateDocumentTypeReq } as CreateDocumentTypeReq;
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    return message;
  },
};

const baseDocumentTypeOneAss: object = { CollectionID: "" };

export const DocumentTypeOneAss = {
  encode(
    message: DocumentTypeOneAss,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.CollectionID !== "") {
      writer.uint32(10).string(message.CollectionID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentTypeOneAss {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentTypeOneAss } as DocumentTypeOneAss;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CollectionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentTypeOneAss {
    const message = { ...baseDocumentTypeOneAss } as DocumentTypeOneAss;
    if (object.CollectionID !== undefined && object.CollectionID !== null) {
      message.CollectionID = String(object.CollectionID);
    } else {
      message.CollectionID = "";
    }
    return message;
  },

  toJSON(message: DocumentTypeOneAss): unknown {
    const obj: any = {};
    message.CollectionID !== undefined &&
      (obj.CollectionID = message.CollectionID);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentTypeOneAss>): DocumentTypeOneAss {
    const message = { ...baseDocumentTypeOneAss } as DocumentTypeOneAss;
    if (object.CollectionID !== undefined && object.CollectionID !== null) {
      message.CollectionID = object.CollectionID;
    } else {
      message.CollectionID = "";
    }
    return message;
  },
};

const baseSetnumeratorDocumenttypereq: object = {
  DocumentTypeID: "",
  NumeratorID: "",
};

export const SetnumeratorDocumenttypereq = {
  encode(
    message: SetnumeratorDocumenttypereq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DocumentTypeID !== "") {
      writer.uint32(10).string(message.DocumentTypeID);
    }
    if (message.NumeratorID !== "") {
      writer.uint32(18).string(message.NumeratorID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SetnumeratorDocumenttypereq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseSetnumeratorDocumenttypereq,
    } as SetnumeratorDocumenttypereq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DocumentTypeID = reader.string();
          break;
        case 2:
          message.NumeratorID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetnumeratorDocumenttypereq {
    const message = {
      ...baseSetnumeratorDocumenttypereq,
    } as SetnumeratorDocumenttypereq;
    if (object.DocumentTypeID !== undefined && object.DocumentTypeID !== null) {
      message.DocumentTypeID = String(object.DocumentTypeID);
    } else {
      message.DocumentTypeID = "";
    }
    if (object.NumeratorID !== undefined && object.NumeratorID !== null) {
      message.NumeratorID = String(object.NumeratorID);
    } else {
      message.NumeratorID = "";
    }
    return message;
  },

  toJSON(message: SetnumeratorDocumenttypereq): unknown {
    const obj: any = {};
    message.DocumentTypeID !== undefined &&
      (obj.DocumentTypeID = message.DocumentTypeID);
    message.NumeratorID !== undefined &&
      (obj.NumeratorID = message.NumeratorID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SetnumeratorDocumenttypereq>
  ): SetnumeratorDocumenttypereq {
    const message = {
      ...baseSetnumeratorDocumenttypereq,
    } as SetnumeratorDocumenttypereq;
    if (object.DocumentTypeID !== undefined && object.DocumentTypeID !== null) {
      message.DocumentTypeID = object.DocumentTypeID;
    } else {
      message.DocumentTypeID = "";
    }
    if (object.NumeratorID !== undefined && object.NumeratorID !== null) {
      message.NumeratorID = object.NumeratorID;
    } else {
      message.NumeratorID = "";
    }
    return message;
  },
};

const baseDocumentTypeRequisite: object = {
  ID: "",
  Name: "",
  Type: "",
  SubType: "",
  Required: false,
  Editable: false,
  FromExternalSource: false,
};

export const DocumentTypeRequisite = {
  encode(
    message: DocumentTypeRequisite,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    if (message.SubType !== "") {
      writer.uint32(34).string(message.SubType);
    }
    if (message.Required === true) {
      writer.uint32(40).bool(message.Required);
    }
    if (message.Editable === true) {
      writer.uint32(48).bool(message.Editable);
    }
    if (message.FromExternalSource === true) {
      writer.uint32(56).bool(message.FromExternalSource);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DocumentTypeRequisite {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentTypeRequisite } as DocumentTypeRequisite;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        case 4:
          message.SubType = reader.string();
          break;
        case 5:
          message.Required = reader.bool();
          break;
        case 6:
          message.Editable = reader.bool();
          break;
        case 7:
          message.FromExternalSource = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentTypeRequisite {
    const message = { ...baseDocumentTypeRequisite } as DocumentTypeRequisite;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.SubType !== undefined && object.SubType !== null) {
      message.SubType = String(object.SubType);
    } else {
      message.SubType = "";
    }
    if (object.Required !== undefined && object.Required !== null) {
      message.Required = Boolean(object.Required);
    } else {
      message.Required = false;
    }
    if (object.Editable !== undefined && object.Editable !== null) {
      message.Editable = Boolean(object.Editable);
    } else {
      message.Editable = false;
    }
    if (
      object.FromExternalSource !== undefined &&
      object.FromExternalSource !== null
    ) {
      message.FromExternalSource = Boolean(object.FromExternalSource);
    } else {
      message.FromExternalSource = false;
    }
    return message;
  },

  toJSON(message: DocumentTypeRequisite): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined && (obj.Type = message.Type);
    message.SubType !== undefined && (obj.SubType = message.SubType);
    message.Required !== undefined && (obj.Required = message.Required);
    message.Editable !== undefined && (obj.Editable = message.Editable);
    message.FromExternalSource !== undefined &&
      (obj.FromExternalSource = message.FromExternalSource);
    return obj;
  },

  fromPartial(
    object: DeepPartial<DocumentTypeRequisite>
  ): DocumentTypeRequisite {
    const message = { ...baseDocumentTypeRequisite } as DocumentTypeRequisite;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.SubType !== undefined && object.SubType !== null) {
      message.SubType = object.SubType;
    } else {
      message.SubType = "";
    }
    if (object.Required !== undefined && object.Required !== null) {
      message.Required = object.Required;
    } else {
      message.Required = false;
    }
    if (object.Editable !== undefined && object.Editable !== null) {
      message.Editable = object.Editable;
    } else {
      message.Editable = false;
    }
    if (
      object.FromExternalSource !== undefined &&
      object.FromExternalSource !== null
    ) {
      message.FromExternalSource = object.FromExternalSource;
    } else {
      message.FromExternalSource = false;
    }
    return message;
  },
};

const baseRearangeRequisitesReq: object = { TypeID: "", Order: "" };

export const RearangeRequisitesReq = {
  encode(
    message: RearangeRequisitesReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.TypeID !== "") {
      writer.uint32(10).string(message.TypeID);
    }
    for (const v of message.Order) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RearangeRequisitesReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRearangeRequisitesReq } as RearangeRequisitesReq;
    message.Order = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TypeID = reader.string();
          break;
        case 2:
          message.Order.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RearangeRequisitesReq {
    const message = { ...baseRearangeRequisitesReq } as RearangeRequisitesReq;
    message.Order = [];
    if (object.TypeID !== undefined && object.TypeID !== null) {
      message.TypeID = String(object.TypeID);
    } else {
      message.TypeID = "";
    }
    if (object.Order !== undefined && object.Order !== null) {
      for (const e of object.Order) {
        message.Order.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: RearangeRequisitesReq): unknown {
    const obj: any = {};
    message.TypeID !== undefined && (obj.TypeID = message.TypeID);
    if (message.Order) {
      obj.Order = message.Order.map((e) => e);
    } else {
      obj.Order = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<RearangeRequisitesReq>
  ): RearangeRequisitesReq {
    const message = { ...baseRearangeRequisitesReq } as RearangeRequisitesReq;
    message.Order = [];
    if (object.TypeID !== undefined && object.TypeID !== null) {
      message.TypeID = object.TypeID;
    } else {
      message.TypeID = "";
    }
    if (object.Order !== undefined && object.Order !== null) {
      for (const e of object.Order) {
        message.Order.push(e);
      }
    }
    return message;
  },
};

const baseAddDocumentTypeRequisiteReq: object = { ID: "" };

export const AddDocumentTypeRequisiteReq = {
  encode(
    message: AddDocumentTypeRequisiteReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Requisite !== undefined) {
      DocumentTypeRequisite.encode(
        message.Requisite,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): AddDocumentTypeRequisiteReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseAddDocumentTypeRequisiteReq,
    } as AddDocumentTypeRequisiteReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Requisite = DocumentTypeRequisite.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddDocumentTypeRequisiteReq {
    const message = {
      ...baseAddDocumentTypeRequisiteReq,
    } as AddDocumentTypeRequisiteReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Requisite !== undefined && object.Requisite !== null) {
      message.Requisite = DocumentTypeRequisite.fromJSON(object.Requisite);
    } else {
      message.Requisite = undefined;
    }
    return message;
  },

  toJSON(message: AddDocumentTypeRequisiteReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Requisite !== undefined &&
      (obj.Requisite = message.Requisite
        ? DocumentTypeRequisite.toJSON(message.Requisite)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<AddDocumentTypeRequisiteReq>
  ): AddDocumentTypeRequisiteReq {
    const message = {
      ...baseAddDocumentTypeRequisiteReq,
    } as AddDocumentTypeRequisiteReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Requisite !== undefined && object.Requisite !== null) {
      message.Requisite = DocumentTypeRequisite.fromPartial(object.Requisite);
    } else {
      message.Requisite = undefined;
    }
    return message;
  },
};

const baseUpdateDocumentTypeRequisiteReq: object = {
  ID: "",
  RequisiteID: "",
  Name: "",
  Required: false,
  Editable: false,
};

export const UpdateDocumentTypeRequisiteReq = {
  encode(
    message: UpdateDocumentTypeRequisiteReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.RequisiteID !== "") {
      writer.uint32(18).string(message.RequisiteID);
    }
    if (message.Name !== "") {
      writer.uint32(26).string(message.Name);
    }
    if (message.Required === true) {
      writer.uint32(32).bool(message.Required);
    }
    if (message.Editable === true) {
      writer.uint32(40).bool(message.Editable);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateDocumentTypeRequisiteReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseUpdateDocumentTypeRequisiteReq,
    } as UpdateDocumentTypeRequisiteReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.RequisiteID = reader.string();
          break;
        case 3:
          message.Name = reader.string();
          break;
        case 4:
          message.Required = reader.bool();
          break;
        case 5:
          message.Editable = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateDocumentTypeRequisiteReq {
    const message = {
      ...baseUpdateDocumentTypeRequisiteReq,
    } as UpdateDocumentTypeRequisiteReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.RequisiteID !== undefined && object.RequisiteID !== null) {
      message.RequisiteID = String(object.RequisiteID);
    } else {
      message.RequisiteID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Required !== undefined && object.Required !== null) {
      message.Required = Boolean(object.Required);
    } else {
      message.Required = false;
    }
    if (object.Editable !== undefined && object.Editable !== null) {
      message.Editable = Boolean(object.Editable);
    } else {
      message.Editable = false;
    }
    return message;
  },

  toJSON(message: UpdateDocumentTypeRequisiteReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.RequisiteID !== undefined &&
      (obj.RequisiteID = message.RequisiteID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Required !== undefined && (obj.Required = message.Required);
    message.Editable !== undefined && (obj.Editable = message.Editable);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UpdateDocumentTypeRequisiteReq>
  ): UpdateDocumentTypeRequisiteReq {
    const message = {
      ...baseUpdateDocumentTypeRequisiteReq,
    } as UpdateDocumentTypeRequisiteReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.RequisiteID !== undefined && object.RequisiteID !== null) {
      message.RequisiteID = object.RequisiteID;
    } else {
      message.RequisiteID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Required !== undefined && object.Required !== null) {
      message.Required = object.Required;
    } else {
      message.Required = false;
    }
    if (object.Editable !== undefined && object.Editable !== null) {
      message.Editable = object.Editable;
    } else {
      message.Editable = false;
    }
    return message;
  },
};

const baseRemoveDocumentTypeRequisite: object = { ID: "", Requisite: "" };

export const RemoveDocumentTypeRequisite = {
  encode(
    message: RemoveDocumentTypeRequisite,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Requisite !== "") {
      writer.uint32(18).string(message.Requisite);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RemoveDocumentTypeRequisite {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseRemoveDocumentTypeRequisite,
    } as RemoveDocumentTypeRequisite;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Requisite = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveDocumentTypeRequisite {
    const message = {
      ...baseRemoveDocumentTypeRequisite,
    } as RemoveDocumentTypeRequisite;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Requisite !== undefined && object.Requisite !== null) {
      message.Requisite = String(object.Requisite);
    } else {
      message.Requisite = "";
    }
    return message;
  },

  toJSON(message: RemoveDocumentTypeRequisite): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Requisite !== undefined && (obj.Requisite = message.Requisite);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RemoveDocumentTypeRequisite>
  ): RemoveDocumentTypeRequisite {
    const message = {
      ...baseRemoveDocumentTypeRequisite,
    } as RemoveDocumentTypeRequisite;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Requisite !== undefined && object.Requisite !== null) {
      message.Requisite = object.Requisite;
    } else {
      message.Requisite = "";
    }
    return message;
  },
};

const baseDocumentStreamReq: object = { ID: "" };

export const DocumentStreamReq = {
  encode(
    message: DocumentStreamReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentStreamReq } as DocumentStreamReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentStreamReq {
    const message = { ...baseDocumentStreamReq } as DocumentStreamReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: DocumentStreamReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentStreamReq>): DocumentStreamReq {
    const message = { ...baseDocumentStreamReq } as DocumentStreamReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseDocumentStreamMessage: object = {};

export const DocumentStreamMessage = {
  encode(
    message: DocumentStreamMessage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Dummy !== undefined) {
      Dummy.encode(message.Dummy, writer.uint32(10).fork()).ldelim();
    }
    if (message.Document !== undefined) {
      Document.encode(message.Document, writer.uint32(18).fork()).ldelim();
    }
    if (message.Event !== undefined) {
      DocumentEvent.encode(message.Event, writer.uint32(26).fork()).ldelim();
    }
    if (message.Task !== undefined) {
      Task.encode(message.Task, writer.uint32(34).fork()).ldelim();
    }
    if (message.TaskDeleted !== undefined) {
      writer.uint32(42).string(message.TaskDeleted);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DocumentStreamMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentStreamMessage } as DocumentStreamMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Dummy = Dummy.decode(reader, reader.uint32());
          break;
        case 2:
          message.Document = Document.decode(reader, reader.uint32());
          break;
        case 3:
          message.Event = DocumentEvent.decode(reader, reader.uint32());
          break;
        case 4:
          message.Task = Task.decode(reader, reader.uint32());
          break;
        case 5:
          message.TaskDeleted = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentStreamMessage {
    const message = { ...baseDocumentStreamMessage } as DocumentStreamMessage;
    if (object.Dummy !== undefined && object.Dummy !== null) {
      message.Dummy = Dummy.fromJSON(object.Dummy);
    } else {
      message.Dummy = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = Document.fromJSON(object.Document);
    } else {
      message.Document = undefined;
    }
    if (object.Event !== undefined && object.Event !== null) {
      message.Event = DocumentEvent.fromJSON(object.Event);
    } else {
      message.Event = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = Task.fromJSON(object.Task);
    } else {
      message.Task = undefined;
    }
    if (object.TaskDeleted !== undefined && object.TaskDeleted !== null) {
      message.TaskDeleted = String(object.TaskDeleted);
    } else {
      message.TaskDeleted = undefined;
    }
    return message;
  },

  toJSON(message: DocumentStreamMessage): unknown {
    const obj: any = {};
    message.Dummy !== undefined &&
      (obj.Dummy = message.Dummy ? Dummy.toJSON(message.Dummy) : undefined);
    message.Document !== undefined &&
      (obj.Document = message.Document
        ? Document.toJSON(message.Document)
        : undefined);
    message.Event !== undefined &&
      (obj.Event = message.Event
        ? DocumentEvent.toJSON(message.Event)
        : undefined);
    message.Task !== undefined &&
      (obj.Task = message.Task ? Task.toJSON(message.Task) : undefined);
    message.TaskDeleted !== undefined &&
      (obj.TaskDeleted = message.TaskDeleted);
    return obj;
  },

  fromPartial(
    object: DeepPartial<DocumentStreamMessage>
  ): DocumentStreamMessage {
    const message = { ...baseDocumentStreamMessage } as DocumentStreamMessage;
    if (object.Dummy !== undefined && object.Dummy !== null) {
      message.Dummy = Dummy.fromPartial(object.Dummy);
    } else {
      message.Dummy = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = Document.fromPartial(object.Document);
    } else {
      message.Document = undefined;
    }
    if (object.Event !== undefined && object.Event !== null) {
      message.Event = DocumentEvent.fromPartial(object.Event);
    } else {
      message.Event = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = Task.fromPartial(object.Task);
    } else {
      message.Task = undefined;
    }
    if (object.TaskDeleted !== undefined && object.TaskDeleted !== null) {
      message.TaskDeleted = object.TaskDeleted;
    } else {
      message.TaskDeleted = undefined;
    }
    return message;
  },
};

const baseArchivedListStreamReq: object = { DocumentTypeID: "" };

export const ArchivedListStreamReq = {
  encode(
    message: ArchivedListStreamReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DocumentTypeID !== "") {
      writer.uint32(10).string(message.DocumentTypeID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ArchivedListStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseArchivedListStreamReq } as ArchivedListStreamReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DocumentTypeID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ArchivedListStreamReq {
    const message = { ...baseArchivedListStreamReq } as ArchivedListStreamReq;
    if (object.DocumentTypeID !== undefined && object.DocumentTypeID !== null) {
      message.DocumentTypeID = String(object.DocumentTypeID);
    } else {
      message.DocumentTypeID = "";
    }
    return message;
  },

  toJSON(message: ArchivedListStreamReq): unknown {
    const obj: any = {};
    message.DocumentTypeID !== undefined &&
      (obj.DocumentTypeID = message.DocumentTypeID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ArchivedListStreamReq>
  ): ArchivedListStreamReq {
    const message = { ...baseArchivedListStreamReq } as ArchivedListStreamReq;
    if (object.DocumentTypeID !== undefined && object.DocumentTypeID !== null) {
      message.DocumentTypeID = object.DocumentTypeID;
    } else {
      message.DocumentTypeID = "";
    }
    return message;
  },
};

const baseArchivedListStreamMessage: object = {};

export const ArchivedListStreamMessage = {
  encode(
    message: ArchivedListStreamMessage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.EOS !== undefined) {
      writer.uint32(10).string(message.EOS);
    }
    if (message.Document !== undefined) {
      Document.encode(message.Document, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ArchivedListStreamMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseArchivedListStreamMessage,
    } as ArchivedListStreamMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.EOS = reader.string();
          break;
        case 2:
          message.Document = Document.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ArchivedListStreamMessage {
    const message = {
      ...baseArchivedListStreamMessage,
    } as ArchivedListStreamMessage;
    if (object.EOS !== undefined && object.EOS !== null) {
      message.EOS = String(object.EOS);
    } else {
      message.EOS = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = Document.fromJSON(object.Document);
    } else {
      message.Document = undefined;
    }
    return message;
  },

  toJSON(message: ArchivedListStreamMessage): unknown {
    const obj: any = {};
    message.EOS !== undefined && (obj.EOS = message.EOS);
    message.Document !== undefined &&
      (obj.Document = message.Document
        ? Document.toJSON(message.Document)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ArchivedListStreamMessage>
  ): ArchivedListStreamMessage {
    const message = {
      ...baseArchivedListStreamMessage,
    } as ArchivedListStreamMessage;
    if (object.EOS !== undefined && object.EOS !== null) {
      message.EOS = object.EOS;
    } else {
      message.EOS = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = Document.fromPartial(object.Document);
    } else {
      message.Document = undefined;
    }
    return message;
  },
};

const baseDocumentEvent: object = { ID: "", DocumentID: "", Type: "", TS: 0 };

export const DocumentEvent = {
  encode(
    message: DocumentEvent,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.DocumentID !== "") {
      writer.uint32(18).string(message.DocumentID);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    if (message.Author !== undefined) {
      UserInfo.encode(message.Author, writer.uint32(34).fork()).ldelim();
    }
    if (message.TS !== 0) {
      writer.uint32(40).int64(message.TS);
    }
    if (message.Message !== undefined) {
      Message.encode(message.Message, writer.uint32(50).fork()).ldelim();
    }
    if (message.StatusTransition !== undefined) {
      StatusTransition.encode(
        message.StatusTransition,
        writer.uint32(58).fork()
      ).ldelim();
    }
    if (message.Registration !== undefined) {
      Registration.encode(
        message.Registration,
        writer.uint32(66).fork()
      ).ldelim();
    }
    if (message.Approved !== undefined) {
      ActorElementIDPair.encode(
        message.Approved,
        writer.uint32(74).fork()
      ).ldelim();
    }
    if (message.Rejected !== undefined) {
      Rejected.encode(message.Rejected, writer.uint32(82).fork()).ldelim();
    }
    if (message.OptionRequisiteUpdated !== undefined) {
      OptionRequisiteUpdated.encode(
        message.OptionRequisiteUpdated,
        writer.uint32(90).fork()
      ).ldelim();
    }
    if (message.LinkedTask !== undefined) {
      LinkedTask.encode(message.LinkedTask, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentEvent } as DocumentEvent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.DocumentID = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        case 4:
          message.Author = UserInfo.decode(reader, reader.uint32());
          break;
        case 5:
          message.TS = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.Message = Message.decode(reader, reader.uint32());
          break;
        case 7:
          message.StatusTransition = StatusTransition.decode(
            reader,
            reader.uint32()
          );
          break;
        case 8:
          message.Registration = Registration.decode(reader, reader.uint32());
          break;
        case 9:
          message.Approved = ActorElementIDPair.decode(reader, reader.uint32());
          break;
        case 10:
          message.Rejected = Rejected.decode(reader, reader.uint32());
          break;
        case 11:
          message.OptionRequisiteUpdated = OptionRequisiteUpdated.decode(
            reader,
            reader.uint32()
          );
          break;
        case 12:
          message.LinkedTask = LinkedTask.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentEvent {
    const message = { ...baseDocumentEvent } as DocumentEvent;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.DocumentID !== undefined && object.DocumentID !== null) {
      message.DocumentID = String(object.DocumentID);
    } else {
      message.DocumentID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = UserInfo.fromJSON(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.TS !== undefined && object.TS !== null) {
      message.TS = Number(object.TS);
    } else {
      message.TS = 0;
    }
    if (object.Message !== undefined && object.Message !== null) {
      message.Message = Message.fromJSON(object.Message);
    } else {
      message.Message = undefined;
    }
    if (
      object.StatusTransition !== undefined &&
      object.StatusTransition !== null
    ) {
      message.StatusTransition = StatusTransition.fromJSON(
        object.StatusTransition
      );
    } else {
      message.StatusTransition = undefined;
    }
    if (object.Registration !== undefined && object.Registration !== null) {
      message.Registration = Registration.fromJSON(object.Registration);
    } else {
      message.Registration = undefined;
    }
    if (object.Approved !== undefined && object.Approved !== null) {
      message.Approved = ActorElementIDPair.fromJSON(object.Approved);
    } else {
      message.Approved = undefined;
    }
    if (object.Rejected !== undefined && object.Rejected !== null) {
      message.Rejected = Rejected.fromJSON(object.Rejected);
    } else {
      message.Rejected = undefined;
    }
    if (
      object.OptionRequisiteUpdated !== undefined &&
      object.OptionRequisiteUpdated !== null
    ) {
      message.OptionRequisiteUpdated = OptionRequisiteUpdated.fromJSON(
        object.OptionRequisiteUpdated
      );
    } else {
      message.OptionRequisiteUpdated = undefined;
    }
    if (object.LinkedTask !== undefined && object.LinkedTask !== null) {
      message.LinkedTask = LinkedTask.fromJSON(object.LinkedTask);
    } else {
      message.LinkedTask = undefined;
    }
    return message;
  },

  toJSON(message: DocumentEvent): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.DocumentID !== undefined && (obj.DocumentID = message.DocumentID);
    message.Type !== undefined && (obj.Type = message.Type);
    message.Author !== undefined &&
      (obj.Author = message.Author
        ? UserInfo.toJSON(message.Author)
        : undefined);
    message.TS !== undefined && (obj.TS = message.TS);
    message.Message !== undefined &&
      (obj.Message = message.Message
        ? Message.toJSON(message.Message)
        : undefined);
    message.StatusTransition !== undefined &&
      (obj.StatusTransition = message.StatusTransition
        ? StatusTransition.toJSON(message.StatusTransition)
        : undefined);
    message.Registration !== undefined &&
      (obj.Registration = message.Registration
        ? Registration.toJSON(message.Registration)
        : undefined);
    message.Approved !== undefined &&
      (obj.Approved = message.Approved
        ? ActorElementIDPair.toJSON(message.Approved)
        : undefined);
    message.Rejected !== undefined &&
      (obj.Rejected = message.Rejected
        ? Rejected.toJSON(message.Rejected)
        : undefined);
    message.OptionRequisiteUpdated !== undefined &&
      (obj.OptionRequisiteUpdated = message.OptionRequisiteUpdated
        ? OptionRequisiteUpdated.toJSON(message.OptionRequisiteUpdated)
        : undefined);
    message.LinkedTask !== undefined &&
      (obj.LinkedTask = message.LinkedTask
        ? LinkedTask.toJSON(message.LinkedTask)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentEvent>): DocumentEvent {
    const message = { ...baseDocumentEvent } as DocumentEvent;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.DocumentID !== undefined && object.DocumentID !== null) {
      message.DocumentID = object.DocumentID;
    } else {
      message.DocumentID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = UserInfo.fromPartial(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.TS !== undefined && object.TS !== null) {
      message.TS = object.TS;
    } else {
      message.TS = 0;
    }
    if (object.Message !== undefined && object.Message !== null) {
      message.Message = Message.fromPartial(object.Message);
    } else {
      message.Message = undefined;
    }
    if (
      object.StatusTransition !== undefined &&
      object.StatusTransition !== null
    ) {
      message.StatusTransition = StatusTransition.fromPartial(
        object.StatusTransition
      );
    } else {
      message.StatusTransition = undefined;
    }
    if (object.Registration !== undefined && object.Registration !== null) {
      message.Registration = Registration.fromPartial(object.Registration);
    } else {
      message.Registration = undefined;
    }
    if (object.Approved !== undefined && object.Approved !== null) {
      message.Approved = ActorElementIDPair.fromPartial(object.Approved);
    } else {
      message.Approved = undefined;
    }
    if (object.Rejected !== undefined && object.Rejected !== null) {
      message.Rejected = Rejected.fromPartial(object.Rejected);
    } else {
      message.Rejected = undefined;
    }
    if (
      object.OptionRequisiteUpdated !== undefined &&
      object.OptionRequisiteUpdated !== null
    ) {
      message.OptionRequisiteUpdated = OptionRequisiteUpdated.fromPartial(
        object.OptionRequisiteUpdated
      );
    } else {
      message.OptionRequisiteUpdated = undefined;
    }
    if (object.LinkedTask !== undefined && object.LinkedTask !== null) {
      message.LinkedTask = LinkedTask.fromPartial(object.LinkedTask);
    } else {
      message.LinkedTask = undefined;
    }
    return message;
  },
};

const baseMessage: object = { Message: "" };

export const Message = {
  encode(
    message: Message,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Message !== "") {
      writer.uint32(10).string(message.Message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMessage } as Message;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Message {
    const message = { ...baseMessage } as Message;
    if (object.Message !== undefined && object.Message !== null) {
      message.Message = String(object.Message);
    } else {
      message.Message = "";
    }
    return message;
  },

  toJSON(message: Message): unknown {
    const obj: any = {};
    message.Message !== undefined && (obj.Message = message.Message);
    return obj;
  },

  fromPartial(object: DeepPartial<Message>): Message {
    const message = { ...baseMessage } as Message;
    if (object.Message !== undefined && object.Message !== null) {
      message.Message = object.Message;
    } else {
      message.Message = "";
    }
    return message;
  },
};

const baseStatusTransition: object = { Previous: "", Current: "" };

export const StatusTransition = {
  encode(
    message: StatusTransition,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Previous !== "") {
      writer.uint32(10).string(message.Previous);
    }
    if (message.Current !== "") {
      writer.uint32(18).string(message.Current);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StatusTransition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseStatusTransition } as StatusTransition;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Previous = reader.string();
          break;
        case 2:
          message.Current = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StatusTransition {
    const message = { ...baseStatusTransition } as StatusTransition;
    if (object.Previous !== undefined && object.Previous !== null) {
      message.Previous = String(object.Previous);
    } else {
      message.Previous = "";
    }
    if (object.Current !== undefined && object.Current !== null) {
      message.Current = String(object.Current);
    } else {
      message.Current = "";
    }
    return message;
  },

  toJSON(message: StatusTransition): unknown {
    const obj: any = {};
    message.Previous !== undefined && (obj.Previous = message.Previous);
    message.Current !== undefined && (obj.Current = message.Current);
    return obj;
  },

  fromPartial(object: DeepPartial<StatusTransition>): StatusTransition {
    const message = { ...baseStatusTransition } as StatusTransition;
    if (object.Previous !== undefined && object.Previous !== null) {
      message.Previous = object.Previous;
    } else {
      message.Previous = "";
    }
    if (object.Current !== undefined && object.Current !== null) {
      message.Current = object.Current;
    } else {
      message.Current = "";
    }
    return message;
  },
};

const baseRegistration: object = { Number: "" };

export const Registration = {
  encode(
    message: Registration,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Number !== "") {
      writer.uint32(10).string(message.Number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Registration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRegistration } as Registration;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Number = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Registration {
    const message = { ...baseRegistration } as Registration;
    if (object.Number !== undefined && object.Number !== null) {
      message.Number = String(object.Number);
    } else {
      message.Number = "";
    }
    return message;
  },

  toJSON(message: Registration): unknown {
    const obj: any = {};
    message.Number !== undefined && (obj.Number = message.Number);
    return obj;
  },

  fromPartial(object: DeepPartial<Registration>): Registration {
    const message = { ...baseRegistration } as Registration;
    if (object.Number !== undefined && object.Number !== null) {
      message.Number = object.Number;
    } else {
      message.Number = "";
    }
    return message;
  },
};

const baseActorElementIDPair: object = { ElementID: "" };

export const ActorElementIDPair = {
  encode(
    message: ActorElementIDPair,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Actor !== undefined) {
      Actor.encode(message.Actor, writer.uint32(10).fork()).ldelim();
    }
    if (message.ElementID !== "") {
      writer.uint32(18).string(message.ElementID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActorElementIDPair {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseActorElementIDPair } as ActorElementIDPair;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Actor = Actor.decode(reader, reader.uint32());
          break;
        case 2:
          message.ElementID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActorElementIDPair {
    const message = { ...baseActorElementIDPair } as ActorElementIDPair;
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.ElementID !== undefined && object.ElementID !== null) {
      message.ElementID = String(object.ElementID);
    } else {
      message.ElementID = "";
    }
    return message;
  },

  toJSON(message: ActorElementIDPair): unknown {
    const obj: any = {};
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? Actor.toJSON(message.Actor) : undefined);
    message.ElementID !== undefined && (obj.ElementID = message.ElementID);
    return obj;
  },

  fromPartial(object: DeepPartial<ActorElementIDPair>): ActorElementIDPair {
    const message = { ...baseActorElementIDPair } as ActorElementIDPair;
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.ElementID !== undefined && object.ElementID !== null) {
      message.ElementID = object.ElementID;
    } else {
      message.ElementID = "";
    }
    return message;
  },
};

const baseRejected: object = { Reason: "" };

export const Rejected = {
  encode(
    message: Rejected,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Actor !== undefined) {
      ActorElementIDPair.encode(
        message.Actor,
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.Reason !== "") {
      writer.uint32(18).string(message.Reason);
    }
    for (const v of message.Victims) {
      ActorElementIDPair.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Rejected {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRejected } as Rejected;
    message.Victims = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Actor = ActorElementIDPair.decode(reader, reader.uint32());
          break;
        case 2:
          message.Reason = reader.string();
          break;
        case 3:
          message.Victims.push(
            ActorElementIDPair.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Rejected {
    const message = { ...baseRejected } as Rejected;
    message.Victims = [];
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = ActorElementIDPair.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.Reason !== undefined && object.Reason !== null) {
      message.Reason = String(object.Reason);
    } else {
      message.Reason = "";
    }
    if (object.Victims !== undefined && object.Victims !== null) {
      for (const e of object.Victims) {
        message.Victims.push(ActorElementIDPair.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: Rejected): unknown {
    const obj: any = {};
    message.Actor !== undefined &&
      (obj.Actor = message.Actor
        ? ActorElementIDPair.toJSON(message.Actor)
        : undefined);
    message.Reason !== undefined && (obj.Reason = message.Reason);
    if (message.Victims) {
      obj.Victims = message.Victims.map((e) =>
        e ? ActorElementIDPair.toJSON(e) : undefined
      );
    } else {
      obj.Victims = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Rejected>): Rejected {
    const message = { ...baseRejected } as Rejected;
    message.Victims = [];
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = ActorElementIDPair.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.Reason !== undefined && object.Reason !== null) {
      message.Reason = object.Reason;
    } else {
      message.Reason = "";
    }
    if (object.Victims !== undefined && object.Victims !== null) {
      for (const e of object.Victims) {
        message.Victims.push(ActorElementIDPair.fromPartial(e));
      }
    }
    return message;
  },
};

const baseOptionRequisiteUpdated: object = {
  RecordID: "",
  RequisiteID: "",
  RequisiteName: "",
  RequisiteType: "",
  Value: "",
};

export const OptionRequisiteUpdated = {
  encode(
    message: OptionRequisiteUpdated,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.RecordID !== "") {
      writer.uint32(10).string(message.RecordID);
    }
    if (message.RequisiteID !== "") {
      writer.uint32(18).string(message.RequisiteID);
    }
    if (message.RequisiteName !== "") {
      writer.uint32(26).string(message.RequisiteName);
    }
    if (message.RequisiteType !== "") {
      writer.uint32(34).string(message.RequisiteType);
    }
    if (message.Value !== "") {
      writer.uint32(42).string(message.Value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): OptionRequisiteUpdated {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOptionRequisiteUpdated } as OptionRequisiteUpdated;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.RecordID = reader.string();
          break;
        case 2:
          message.RequisiteID = reader.string();
          break;
        case 3:
          message.RequisiteName = reader.string();
          break;
        case 4:
          message.RequisiteType = reader.string();
          break;
        case 5:
          message.Value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OptionRequisiteUpdated {
    const message = { ...baseOptionRequisiteUpdated } as OptionRequisiteUpdated;
    if (object.RecordID !== undefined && object.RecordID !== null) {
      message.RecordID = String(object.RecordID);
    } else {
      message.RecordID = "";
    }
    if (object.RequisiteID !== undefined && object.RequisiteID !== null) {
      message.RequisiteID = String(object.RequisiteID);
    } else {
      message.RequisiteID = "";
    }
    if (object.RequisiteName !== undefined && object.RequisiteName !== null) {
      message.RequisiteName = String(object.RequisiteName);
    } else {
      message.RequisiteName = "";
    }
    if (object.RequisiteType !== undefined && object.RequisiteType !== null) {
      message.RequisiteType = String(object.RequisiteType);
    } else {
      message.RequisiteType = "";
    }
    if (object.Value !== undefined && object.Value !== null) {
      message.Value = String(object.Value);
    } else {
      message.Value = "";
    }
    return message;
  },

  toJSON(message: OptionRequisiteUpdated): unknown {
    const obj: any = {};
    message.RecordID !== undefined && (obj.RecordID = message.RecordID);
    message.RequisiteID !== undefined &&
      (obj.RequisiteID = message.RequisiteID);
    message.RequisiteName !== undefined &&
      (obj.RequisiteName = message.RequisiteName);
    message.RequisiteType !== undefined &&
      (obj.RequisiteType = message.RequisiteType);
    message.Value !== undefined && (obj.Value = message.Value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<OptionRequisiteUpdated>
  ): OptionRequisiteUpdated {
    const message = { ...baseOptionRequisiteUpdated } as OptionRequisiteUpdated;
    if (object.RecordID !== undefined && object.RecordID !== null) {
      message.RecordID = object.RecordID;
    } else {
      message.RecordID = "";
    }
    if (object.RequisiteID !== undefined && object.RequisiteID !== null) {
      message.RequisiteID = object.RequisiteID;
    } else {
      message.RequisiteID = "";
    }
    if (object.RequisiteName !== undefined && object.RequisiteName !== null) {
      message.RequisiteName = object.RequisiteName;
    } else {
      message.RequisiteName = "";
    }
    if (object.RequisiteType !== undefined && object.RequisiteType !== null) {
      message.RequisiteType = object.RequisiteType;
    } else {
      message.RequisiteType = "";
    }
    if (object.Value !== undefined && object.Value !== null) {
      message.Value = object.Value;
    } else {
      message.Value = "";
    }
    return message;
  },
};

const baseLinkedTask: object = { Type: "", ID: "", Text: "" };

export const LinkedTask = {
  encode(
    message: LinkedTask,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Type !== "") {
      writer.uint32(10).string(message.Type);
    }
    if (message.ID !== "") {
      writer.uint32(18).string(message.ID);
    }
    if (message.Text !== "") {
      writer.uint32(26).string(message.Text);
    }
    if (message.Author !== undefined) {
      Actor.encode(message.Author, writer.uint32(34).fork()).ldelim();
    }
    if (message.Assignee !== undefined) {
      Actor.encode(message.Assignee, writer.uint32(42).fork()).ldelim();
    }
    if (message.Confirmer !== undefined) {
      Actor.encode(message.Confirmer, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LinkedTask {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLinkedTask } as LinkedTask;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.string();
          break;
        case 2:
          message.ID = reader.string();
          break;
        case 3:
          message.Text = reader.string();
          break;
        case 4:
          message.Author = Actor.decode(reader, reader.uint32());
          break;
        case 5:
          message.Assignee = Actor.decode(reader, reader.uint32());
          break;
        case 6:
          message.Confirmer = Actor.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LinkedTask {
    const message = { ...baseLinkedTask } as LinkedTask;
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = String(object.Text);
    } else {
      message.Text = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = Actor.fromJSON(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = Actor.fromJSON(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = Actor.fromJSON(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    return message;
  },

  toJSON(message: LinkedTask): unknown {
    const obj: any = {};
    message.Type !== undefined && (obj.Type = message.Type);
    message.ID !== undefined && (obj.ID = message.ID);
    message.Text !== undefined && (obj.Text = message.Text);
    message.Author !== undefined &&
      (obj.Author = message.Author ? Actor.toJSON(message.Author) : undefined);
    message.Assignee !== undefined &&
      (obj.Assignee = message.Assignee
        ? Actor.toJSON(message.Assignee)
        : undefined);
    message.Confirmer !== undefined &&
      (obj.Confirmer = message.Confirmer
        ? Actor.toJSON(message.Confirmer)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<LinkedTask>): LinkedTask {
    const message = { ...baseLinkedTask } as LinkedTask;
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = object.Text;
    } else {
      message.Text = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = Actor.fromPartial(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = Actor.fromPartial(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = Actor.fromPartial(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    return message;
  },
};

const baseListStreamReq: object = {};

export const ListStreamReq = {
  encode(
    _: ListStreamReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListStreamReq } as ListStreamReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ListStreamReq {
    const message = { ...baseListStreamReq } as ListStreamReq;
    return message;
  },

  toJSON(_: ListStreamReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<ListStreamReq>): ListStreamReq {
    const message = { ...baseListStreamReq } as ListStreamReq;
    return message;
  },
};

const baseDocumentListStreamMessage: object = {};

export const DocumentListStreamMessage = {
  encode(
    message: DocumentListStreamMessage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Dummy !== undefined) {
      Dummy.encode(message.Dummy, writer.uint32(10).fork()).ldelim();
    }
    if (message.Document !== undefined) {
      Document.encode(message.Document, writer.uint32(18).fork()).ldelim();
    }
    if (message.DocumentDeleted !== undefined) {
      writer.uint32(26).string(message.DocumentDeleted);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DocumentListStreamMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseDocumentListStreamMessage,
    } as DocumentListStreamMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Dummy = Dummy.decode(reader, reader.uint32());
          break;
        case 2:
          message.Document = Document.decode(reader, reader.uint32());
          break;
        case 3:
          message.DocumentDeleted = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentListStreamMessage {
    const message = {
      ...baseDocumentListStreamMessage,
    } as DocumentListStreamMessage;
    if (object.Dummy !== undefined && object.Dummy !== null) {
      message.Dummy = Dummy.fromJSON(object.Dummy);
    } else {
      message.Dummy = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = Document.fromJSON(object.Document);
    } else {
      message.Document = undefined;
    }
    if (
      object.DocumentDeleted !== undefined &&
      object.DocumentDeleted !== null
    ) {
      message.DocumentDeleted = String(object.DocumentDeleted);
    } else {
      message.DocumentDeleted = undefined;
    }
    return message;
  },

  toJSON(message: DocumentListStreamMessage): unknown {
    const obj: any = {};
    message.Dummy !== undefined &&
      (obj.Dummy = message.Dummy ? Dummy.toJSON(message.Dummy) : undefined);
    message.Document !== undefined &&
      (obj.Document = message.Document
        ? Document.toJSON(message.Document)
        : undefined);
    message.DocumentDeleted !== undefined &&
      (obj.DocumentDeleted = message.DocumentDeleted);
    return obj;
  },

  fromPartial(
    object: DeepPartial<DocumentListStreamMessage>
  ): DocumentListStreamMessage {
    const message = {
      ...baseDocumentListStreamMessage,
    } as DocumentListStreamMessage;
    if (object.Dummy !== undefined && object.Dummy !== null) {
      message.Dummy = Dummy.fromPartial(object.Dummy);
    } else {
      message.Dummy = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = Document.fromPartial(object.Document);
    } else {
      message.Document = undefined;
    }
    if (
      object.DocumentDeleted !== undefined &&
      object.DocumentDeleted !== null
    ) {
      message.DocumentDeleted = object.DocumentDeleted;
    } else {
      message.DocumentDeleted = undefined;
    }
    return message;
  },
};

const baseDocument: object = { Comment: "" };

export const Document = {
  encode(
    message: Document,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Base !== undefined) {
      DocumentBase.encode(message.Base, writer.uint32(10).fork()).ldelim();
    }
    if (message.Comment !== "") {
      writer.uint32(26).string(message.Comment);
    }
    for (const v of message.Requisites) {
      DocumentRequisite.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Files) {
      File.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.Links) {
      DocumentLink.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.Workflow !== undefined) {
      Workflow.encode(message.Workflow, writer.uint32(58).fork()).ldelim();
    }
    if (message.OneAss !== undefined) {
      DocumentOneAss.encode(message.OneAss, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Document {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocument } as Document;
    message.Requisites = [];
    message.Files = [];
    message.Links = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Base = DocumentBase.decode(reader, reader.uint32());
          break;
        case 3:
          message.Comment = reader.string();
          break;
        case 2:
          message.Requisites.push(
            DocumentRequisite.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.Files.push(File.decode(reader, reader.uint32()));
          break;
        case 6:
          message.Links.push(DocumentLink.decode(reader, reader.uint32()));
          break;
        case 7:
          message.Workflow = Workflow.decode(reader, reader.uint32());
          break;
        case 8:
          message.OneAss = DocumentOneAss.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Document {
    const message = { ...baseDocument } as Document;
    message.Requisites = [];
    message.Files = [];
    message.Links = [];
    if (object.Base !== undefined && object.Base !== null) {
      message.Base = DocumentBase.fromJSON(object.Base);
    } else {
      message.Base = undefined;
    }
    if (object.Comment !== undefined && object.Comment !== null) {
      message.Comment = String(object.Comment);
    } else {
      message.Comment = "";
    }
    if (object.Requisites !== undefined && object.Requisites !== null) {
      for (const e of object.Requisites) {
        message.Requisites.push(DocumentRequisite.fromJSON(e));
      }
    }
    if (object.Files !== undefined && object.Files !== null) {
      for (const e of object.Files) {
        message.Files.push(File.fromJSON(e));
      }
    }
    if (object.Links !== undefined && object.Links !== null) {
      for (const e of object.Links) {
        message.Links.push(DocumentLink.fromJSON(e));
      }
    }
    if (object.Workflow !== undefined && object.Workflow !== null) {
      message.Workflow = Workflow.fromJSON(object.Workflow);
    } else {
      message.Workflow = undefined;
    }
    if (object.OneAss !== undefined && object.OneAss !== null) {
      message.OneAss = DocumentOneAss.fromJSON(object.OneAss);
    } else {
      message.OneAss = undefined;
    }
    return message;
  },

  toJSON(message: Document): unknown {
    const obj: any = {};
    message.Base !== undefined &&
      (obj.Base = message.Base ? DocumentBase.toJSON(message.Base) : undefined);
    message.Comment !== undefined && (obj.Comment = message.Comment);
    if (message.Requisites) {
      obj.Requisites = message.Requisites.map((e) =>
        e ? DocumentRequisite.toJSON(e) : undefined
      );
    } else {
      obj.Requisites = [];
    }
    if (message.Files) {
      obj.Files = message.Files.map((e) => (e ? File.toJSON(e) : undefined));
    } else {
      obj.Files = [];
    }
    if (message.Links) {
      obj.Links = message.Links.map((e) =>
        e ? DocumentLink.toJSON(e) : undefined
      );
    } else {
      obj.Links = [];
    }
    message.Workflow !== undefined &&
      (obj.Workflow = message.Workflow
        ? Workflow.toJSON(message.Workflow)
        : undefined);
    message.OneAss !== undefined &&
      (obj.OneAss = message.OneAss
        ? DocumentOneAss.toJSON(message.OneAss)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Document>): Document {
    const message = { ...baseDocument } as Document;
    message.Requisites = [];
    message.Files = [];
    message.Links = [];
    if (object.Base !== undefined && object.Base !== null) {
      message.Base = DocumentBase.fromPartial(object.Base);
    } else {
      message.Base = undefined;
    }
    if (object.Comment !== undefined && object.Comment !== null) {
      message.Comment = object.Comment;
    } else {
      message.Comment = "";
    }
    if (object.Requisites !== undefined && object.Requisites !== null) {
      for (const e of object.Requisites) {
        message.Requisites.push(DocumentRequisite.fromPartial(e));
      }
    }
    if (object.Files !== undefined && object.Files !== null) {
      for (const e of object.Files) {
        message.Files.push(File.fromPartial(e));
      }
    }
    if (object.Links !== undefined && object.Links !== null) {
      for (const e of object.Links) {
        message.Links.push(DocumentLink.fromPartial(e));
      }
    }
    if (object.Workflow !== undefined && object.Workflow !== null) {
      message.Workflow = Workflow.fromPartial(object.Workflow);
    } else {
      message.Workflow = undefined;
    }
    if (object.OneAss !== undefined && object.OneAss !== null) {
      message.OneAss = DocumentOneAss.fromPartial(object.OneAss);
    } else {
      message.OneAss = undefined;
    }
    return message;
  },
};

const baseDocumentDraftReq: object = { ID: "" };

export const DocumentDraftReq = {
  encode(
    message: DocumentDraftReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentDraftReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentDraftReq } as DocumentDraftReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentDraftReq {
    const message = { ...baseDocumentDraftReq } as DocumentDraftReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: DocumentDraftReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentDraftReq>): DocumentDraftReq {
    const message = { ...baseDocumentDraftReq } as DocumentDraftReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseDocumentArchiveReq: object = { ID: "" };

export const DocumentArchiveReq = {
  encode(
    message: DocumentArchiveReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentArchiveReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentArchiveReq } as DocumentArchiveReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentArchiveReq {
    const message = { ...baseDocumentArchiveReq } as DocumentArchiveReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: DocumentArchiveReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentArchiveReq>): DocumentArchiveReq {
    const message = { ...baseDocumentArchiveReq } as DocumentArchiveReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseDocumentStartReq: object = { ID: "" };

export const DocumentStartReq = {
  encode(
    message: DocumentStartReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentStartReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentStartReq } as DocumentStartReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentStartReq {
    const message = { ...baseDocumentStartReq } as DocumentStartReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: DocumentStartReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentStartReq>): DocumentStartReq {
    const message = { ...baseDocumentStartReq } as DocumentStartReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseDocumentOneAss: object = {};

export const DocumentOneAss = {
  encode(
    message: DocumentOneAss,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Author !== undefined) {
      DocumentOneAssAuthor.encode(
        message.Author,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentOneAss {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentOneAss } as DocumentOneAss;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Author = DocumentOneAssAuthor.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentOneAss {
    const message = { ...baseDocumentOneAss } as DocumentOneAss;
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = DocumentOneAssAuthor.fromJSON(object.Author);
    } else {
      message.Author = undefined;
    }
    return message;
  },

  toJSON(message: DocumentOneAss): unknown {
    const obj: any = {};
    message.Author !== undefined &&
      (obj.Author = message.Author
        ? DocumentOneAssAuthor.toJSON(message.Author)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentOneAss>): DocumentOneAss {
    const message = { ...baseDocumentOneAss } as DocumentOneAss;
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = DocumentOneAssAuthor.fromPartial(object.Author);
    } else {
      message.Author = undefined;
    }
    return message;
  },
};

const baseDocumentOneAssAuthor: object = {
  ID: "",
  FirstName: "",
  MiddleName: "",
  LastName: "",
};

export const DocumentOneAssAuthor = {
  encode(
    message: DocumentOneAssAuthor,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.FirstName !== "") {
      writer.uint32(18).string(message.FirstName);
    }
    if (message.MiddleName !== "") {
      writer.uint32(26).string(message.MiddleName);
    }
    if (message.LastName !== "") {
      writer.uint32(34).string(message.LastName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DocumentOneAssAuthor {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentOneAssAuthor } as DocumentOneAssAuthor;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.FirstName = reader.string();
          break;
        case 3:
          message.MiddleName = reader.string();
          break;
        case 4:
          message.LastName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentOneAssAuthor {
    const message = { ...baseDocumentOneAssAuthor } as DocumentOneAssAuthor;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.FirstName !== undefined && object.FirstName !== null) {
      message.FirstName = String(object.FirstName);
    } else {
      message.FirstName = "";
    }
    if (object.MiddleName !== undefined && object.MiddleName !== null) {
      message.MiddleName = String(object.MiddleName);
    } else {
      message.MiddleName = "";
    }
    if (object.LastName !== undefined && object.LastName !== null) {
      message.LastName = String(object.LastName);
    } else {
      message.LastName = "";
    }
    return message;
  },

  toJSON(message: DocumentOneAssAuthor): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.MiddleName !== undefined && (obj.MiddleName = message.MiddleName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentOneAssAuthor>): DocumentOneAssAuthor {
    const message = { ...baseDocumentOneAssAuthor } as DocumentOneAssAuthor;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.FirstName !== undefined && object.FirstName !== null) {
      message.FirstName = object.FirstName;
    } else {
      message.FirstName = "";
    }
    if (object.MiddleName !== undefined && object.MiddleName !== null) {
      message.MiddleName = object.MiddleName;
    } else {
      message.MiddleName = "";
    }
    if (object.LastName !== undefined && object.LastName !== null) {
      message.LastName = object.LastName;
    } else {
      message.LastName = "";
    }
    return message;
  },
};

const baseFile: object = { ID: "", Name: "", Type: "", Size: 0, Version: 0 };

export const File = {
  encode(message: File, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    if (message.Size !== 0) {
      writer.uint32(32).int64(message.Size);
    }
    if (message.Version !== 0) {
      writer.uint32(40).int64(message.Version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): File {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFile } as File;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        case 4:
          message.Size = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.Version = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): File {
    const message = { ...baseFile } as File;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.Size !== undefined && object.Size !== null) {
      message.Size = Number(object.Size);
    } else {
      message.Size = 0;
    }
    if (object.Version !== undefined && object.Version !== null) {
      message.Version = Number(object.Version);
    } else {
      message.Version = 0;
    }
    return message;
  },

  toJSON(message: File): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined && (obj.Type = message.Type);
    message.Size !== undefined && (obj.Size = message.Size);
    message.Version !== undefined && (obj.Version = message.Version);
    return obj;
  },

  fromPartial(object: DeepPartial<File>): File {
    const message = { ...baseFile } as File;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.Size !== undefined && object.Size !== null) {
      message.Size = object.Size;
    } else {
      message.Size = 0;
    }
    if (object.Version !== undefined && object.Version !== null) {
      message.Version = object.Version;
    } else {
      message.Version = 0;
    }
    return message;
  },
};

const baseDocumentBase: object = {
  ID: "",
  Name: "",
  Type: "",
  Unit: "",
  OriginUnit: "",
  Number: "",
  Status: "",
  CreationTime: 0,
  StatusChangeTime: 0,
  ModificationTime: 0,
};

export const DocumentBase = {
  encode(
    message: DocumentBase,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    if (message.Unit !== "") {
      writer.uint32(34).string(message.Unit);
    }
    if (message.OriginUnit !== "") {
      writer.uint32(42).string(message.OriginUnit);
    }
    if (message.Author !== undefined) {
      UserInfo.encode(message.Author, writer.uint32(50).fork()).ldelim();
    }
    if (message.Number !== "") {
      writer.uint32(58).string(message.Number);
    }
    if (message.Status !== "") {
      writer.uint32(66).string(message.Status);
    }
    if (message.CreationTime !== 0) {
      writer.uint32(72).int64(message.CreationTime);
    }
    if (message.StatusChangeTime !== 0) {
      writer.uint32(80).int64(message.StatusChangeTime);
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentBase {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentBase } as DocumentBase;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        case 4:
          message.Unit = reader.string();
          break;
        case 5:
          message.OriginUnit = reader.string();
          break;
        case 6:
          message.Author = UserInfo.decode(reader, reader.uint32());
          break;
        case 7:
          message.Number = reader.string();
          break;
        case 8:
          message.Status = reader.string();
          break;
        case 9:
          message.CreationTime = longToNumber(reader.int64() as Long);
          break;
        case 10:
          message.StatusChangeTime = longToNumber(reader.int64() as Long);
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentBase {
    const message = { ...baseDocumentBase } as DocumentBase;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = String(object.Unit);
    } else {
      message.Unit = "";
    }
    if (object.OriginUnit !== undefined && object.OriginUnit !== null) {
      message.OriginUnit = String(object.OriginUnit);
    } else {
      message.OriginUnit = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = UserInfo.fromJSON(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.Number !== undefined && object.Number !== null) {
      message.Number = String(object.Number);
    } else {
      message.Number = "";
    }
    if (object.Status !== undefined && object.Status !== null) {
      message.Status = String(object.Status);
    } else {
      message.Status = "";
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = Number(object.CreationTime);
    } else {
      message.CreationTime = 0;
    }
    if (
      object.StatusChangeTime !== undefined &&
      object.StatusChangeTime !== null
    ) {
      message.StatusChangeTime = Number(object.StatusChangeTime);
    } else {
      message.StatusChangeTime = 0;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: DocumentBase): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined && (obj.Type = message.Type);
    message.Unit !== undefined && (obj.Unit = message.Unit);
    message.OriginUnit !== undefined && (obj.OriginUnit = message.OriginUnit);
    message.Author !== undefined &&
      (obj.Author = message.Author
        ? UserInfo.toJSON(message.Author)
        : undefined);
    message.Number !== undefined && (obj.Number = message.Number);
    message.Status !== undefined && (obj.Status = message.Status);
    message.CreationTime !== undefined &&
      (obj.CreationTime = message.CreationTime);
    message.StatusChangeTime !== undefined &&
      (obj.StatusChangeTime = message.StatusChangeTime);
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentBase>): DocumentBase {
    const message = { ...baseDocumentBase } as DocumentBase;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = object.Unit;
    } else {
      message.Unit = "";
    }
    if (object.OriginUnit !== undefined && object.OriginUnit !== null) {
      message.OriginUnit = object.OriginUnit;
    } else {
      message.OriginUnit = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = UserInfo.fromPartial(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.Number !== undefined && object.Number !== null) {
      message.Number = object.Number;
    } else {
      message.Number = "";
    }
    if (object.Status !== undefined && object.Status !== null) {
      message.Status = object.Status;
    } else {
      message.Status = "";
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = object.CreationTime;
    } else {
      message.CreationTime = 0;
    }
    if (
      object.StatusChangeTime !== undefined &&
      object.StatusChangeTime !== null
    ) {
      message.StatusChangeTime = object.StatusChangeTime;
    } else {
      message.StatusChangeTime = 0;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseUserInfo: object = { ID: "" };

export const UserInfo = {
  encode(
    message: UserInfo,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== undefined) {
      UserName.encode(message.Name, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUserInfo } as UserInfo;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = UserName.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserInfo {
    const message = { ...baseUserInfo } as UserInfo;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = UserName.fromJSON(object.Name);
    } else {
      message.Name = undefined;
    }
    return message;
  },

  toJSON(message: UserInfo): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined &&
      (obj.Name = message.Name ? UserName.toJSON(message.Name) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<UserInfo>): UserInfo {
    const message = { ...baseUserInfo } as UserInfo;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = UserName.fromPartial(object.Name);
    } else {
      message.Name = undefined;
    }
    return message;
  },
};

const baseDocumentRequisite: object = { ID: "", Value: "", RecordName: "" };

export const DocumentRequisite = {
  encode(
    message: DocumentRequisite,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Type !== undefined) {
      DocumentTypeRequisite.encode(
        message.Type,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.Value !== "") {
      writer.uint32(26).string(message.Value);
    }
    if (message.RecordName !== "") {
      writer.uint32(34).string(message.RecordName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentRequisite {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentRequisite } as DocumentRequisite;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Type = DocumentTypeRequisite.decode(reader, reader.uint32());
          break;
        case 3:
          message.Value = reader.string();
          break;
        case 4:
          message.RecordName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentRequisite {
    const message = { ...baseDocumentRequisite } as DocumentRequisite;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = DocumentTypeRequisite.fromJSON(object.Type);
    } else {
      message.Type = undefined;
    }
    if (object.Value !== undefined && object.Value !== null) {
      message.Value = String(object.Value);
    } else {
      message.Value = "";
    }
    if (object.RecordName !== undefined && object.RecordName !== null) {
      message.RecordName = String(object.RecordName);
    } else {
      message.RecordName = "";
    }
    return message;
  },

  toJSON(message: DocumentRequisite): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Type !== undefined &&
      (obj.Type = message.Type
        ? DocumentTypeRequisite.toJSON(message.Type)
        : undefined);
    message.Value !== undefined && (obj.Value = message.Value);
    message.RecordName !== undefined && (obj.RecordName = message.RecordName);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentRequisite>): DocumentRequisite {
    const message = { ...baseDocumentRequisite } as DocumentRequisite;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = DocumentTypeRequisite.fromPartial(object.Type);
    } else {
      message.Type = undefined;
    }
    if (object.Value !== undefined && object.Value !== null) {
      message.Value = object.Value;
    } else {
      message.Value = "";
    }
    if (object.RecordName !== undefined && object.RecordName !== null) {
      message.RecordName = object.RecordName;
    } else {
      message.RecordName = "";
    }
    return message;
  },
};

const baseGetAllByTypeReq: object = { Type: "" };

export const GetAllByTypeReq = {
  encode(
    message: GetAllByTypeReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Type !== "") {
      writer.uint32(10).string(message.Type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllByTypeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAllByTypeReq } as GetAllByTypeReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllByTypeReq {
    const message = { ...baseGetAllByTypeReq } as GetAllByTypeReq;
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    return message;
  },

  toJSON(message: GetAllByTypeReq): unknown {
    const obj: any = {};
    message.Type !== undefined && (obj.Type = message.Type);
    return obj;
  },

  fromPartial(object: DeepPartial<GetAllByTypeReq>): GetAllByTypeReq {
    const message = { ...baseGetAllByTypeReq } as GetAllByTypeReq;
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    return message;
  },
};

const baseNewDocumentReq: object = { Name: "", Type: "", OriginUnit: "" };

export const NewDocumentReq = {
  encode(
    message: NewDocumentReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Name !== "") {
      writer.uint32(10).string(message.Name);
    }
    if (message.Type !== "") {
      writer.uint32(18).string(message.Type);
    }
    if (message.OriginUnit !== "") {
      writer.uint32(34).string(message.OriginUnit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewDocumentReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNewDocumentReq } as NewDocumentReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Name = reader.string();
          break;
        case 2:
          message.Type = reader.string();
          break;
        case 4:
          message.OriginUnit = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NewDocumentReq {
    const message = { ...baseNewDocumentReq } as NewDocumentReq;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.OriginUnit !== undefined && object.OriginUnit !== null) {
      message.OriginUnit = String(object.OriginUnit);
    } else {
      message.OriginUnit = "";
    }
    return message;
  },

  toJSON(message: NewDocumentReq): unknown {
    const obj: any = {};
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined && (obj.Type = message.Type);
    message.OriginUnit !== undefined && (obj.OriginUnit = message.OriginUnit);
    return obj;
  },

  fromPartial(object: DeepPartial<NewDocumentReq>): NewDocumentReq {
    const message = { ...baseNewDocumentReq } as NewDocumentReq;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.OriginUnit !== undefined && object.OriginUnit !== null) {
      message.OriginUnit = object.OriginUnit;
    } else {
      message.OriginUnit = "";
    }
    return message;
  },
};

const baseRemoveDocumentReq: object = { ID: "" };

export const RemoveDocumentReq = {
  encode(
    message: RemoveDocumentReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDocumentReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveDocumentReq } as RemoveDocumentReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveDocumentReq {
    const message = { ...baseRemoveDocumentReq } as RemoveDocumentReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RemoveDocumentReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<RemoveDocumentReq>): RemoveDocumentReq {
    const message = { ...baseRemoveDocumentReq } as RemoveDocumentReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseUpdateDocumentNameReq: object = { ID: "", Name: "" };

export const UpdateDocumentNameReq = {
  encode(
    message: UpdateDocumentNameReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateDocumentNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUpdateDocumentNameReq } as UpdateDocumentNameReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateDocumentNameReq {
    const message = { ...baseUpdateDocumentNameReq } as UpdateDocumentNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    return message;
  },

  toJSON(message: UpdateDocumentNameReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UpdateDocumentNameReq>
  ): UpdateDocumentNameReq {
    const message = { ...baseUpdateDocumentNameReq } as UpdateDocumentNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    return message;
  },
};

const baseUpdateDocumentRequisiteReq: object = {
  ID: "",
  Requisite: "",
  Value: "",
  RecordName: "",
};

export const UpdateDocumentRequisiteReq = {
  encode(
    message: UpdateDocumentRequisiteReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Requisite !== "") {
      writer.uint32(18).string(message.Requisite);
    }
    if (message.Value !== "") {
      writer.uint32(26).string(message.Value);
    }
    if (message.RecordName !== "") {
      writer.uint32(34).string(message.RecordName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateDocumentRequisiteReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseUpdateDocumentRequisiteReq,
    } as UpdateDocumentRequisiteReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Requisite = reader.string();
          break;
        case 3:
          message.Value = reader.string();
          break;
        case 4:
          message.RecordName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateDocumentRequisiteReq {
    const message = {
      ...baseUpdateDocumentRequisiteReq,
    } as UpdateDocumentRequisiteReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Requisite !== undefined && object.Requisite !== null) {
      message.Requisite = String(object.Requisite);
    } else {
      message.Requisite = "";
    }
    if (object.Value !== undefined && object.Value !== null) {
      message.Value = String(object.Value);
    } else {
      message.Value = "";
    }
    if (object.RecordName !== undefined && object.RecordName !== null) {
      message.RecordName = String(object.RecordName);
    } else {
      message.RecordName = "";
    }
    return message;
  },

  toJSON(message: UpdateDocumentRequisiteReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Requisite !== undefined && (obj.Requisite = message.Requisite);
    message.Value !== undefined && (obj.Value = message.Value);
    message.RecordName !== undefined && (obj.RecordName = message.RecordName);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UpdateDocumentRequisiteReq>
  ): UpdateDocumentRequisiteReq {
    const message = {
      ...baseUpdateDocumentRequisiteReq,
    } as UpdateDocumentRequisiteReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Requisite !== undefined && object.Requisite !== null) {
      message.Requisite = object.Requisite;
    } else {
      message.Requisite = "";
    }
    if (object.Value !== undefined && object.Value !== null) {
      message.Value = object.Value;
    } else {
      message.Value = "";
    }
    if (object.RecordName !== undefined && object.RecordName !== null) {
      message.RecordName = object.RecordName;
    } else {
      message.RecordName = "";
    }
    return message;
  },
};

const baseUpdateDocumentCommentReq: object = { ID: "", Comment: "" };

export const UpdateDocumentCommentReq = {
  encode(
    message: UpdateDocumentCommentReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Comment !== "") {
      writer.uint32(18).string(message.Comment);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateDocumentCommentReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseUpdateDocumentCommentReq,
    } as UpdateDocumentCommentReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Comment = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateDocumentCommentReq {
    const message = {
      ...baseUpdateDocumentCommentReq,
    } as UpdateDocumentCommentReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Comment !== undefined && object.Comment !== null) {
      message.Comment = String(object.Comment);
    } else {
      message.Comment = "";
    }
    return message;
  },

  toJSON(message: UpdateDocumentCommentReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Comment !== undefined && (obj.Comment = message.Comment);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UpdateDocumentCommentReq>
  ): UpdateDocumentCommentReq {
    const message = {
      ...baseUpdateDocumentCommentReq,
    } as UpdateDocumentCommentReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Comment !== undefined && object.Comment !== null) {
      message.Comment = object.Comment;
    } else {
      message.Comment = "";
    }
    return message;
  },
};

const baseDocumentPutMessageReq: object = { Document: "", Message: "" };

export const DocumentPutMessageReq = {
  encode(
    message: DocumentPutMessageReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Document !== "") {
      writer.uint32(10).string(message.Document);
    }
    if (message.Message !== "") {
      writer.uint32(18).string(message.Message);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DocumentPutMessageReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentPutMessageReq } as DocumentPutMessageReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Document = reader.string();
          break;
        case 2:
          message.Message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentPutMessageReq {
    const message = { ...baseDocumentPutMessageReq } as DocumentPutMessageReq;
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = String(object.Document);
    } else {
      message.Document = "";
    }
    if (object.Message !== undefined && object.Message !== null) {
      message.Message = String(object.Message);
    } else {
      message.Message = "";
    }
    return message;
  },

  toJSON(message: DocumentPutMessageReq): unknown {
    const obj: any = {};
    message.Document !== undefined && (obj.Document = message.Document);
    message.Message !== undefined && (obj.Message = message.Message);
    return obj;
  },

  fromPartial(
    object: DeepPartial<DocumentPutMessageReq>
  ): DocumentPutMessageReq {
    const message = { ...baseDocumentPutMessageReq } as DocumentPutMessageReq;
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = object.Document;
    } else {
      message.Document = "";
    }
    if (object.Message !== undefined && object.Message !== null) {
      message.Message = object.Message;
    } else {
      message.Message = "";
    }
    return message;
  },
};

const baseDocumentRegisterReq: object = { ID: "", Number: "" };

export const DocumentRegisterReq = {
  encode(
    message: DocumentRegisterReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Number !== "") {
      writer.uint32(18).string(message.Number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentRegisterReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentRegisterReq } as DocumentRegisterReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Number = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentRegisterReq {
    const message = { ...baseDocumentRegisterReq } as DocumentRegisterReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Number !== undefined && object.Number !== null) {
      message.Number = String(object.Number);
    } else {
      message.Number = "";
    }
    return message;
  },

  toJSON(message: DocumentRegisterReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Number !== undefined && (obj.Number = message.Number);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentRegisterReq>): DocumentRegisterReq {
    const message = { ...baseDocumentRegisterReq } as DocumentRegisterReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Number !== undefined && object.Number !== null) {
      message.Number = object.Number;
    } else {
      message.Number = "";
    }
    return message;
  },
};

const baseSetLinksReq: object = { ID: "" };

export const SetLinksReq = {
  encode(
    message: SetLinksReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    for (const v of message.Links) {
      DocumentLink.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetLinksReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetLinksReq } as SetLinksReq;
    message.Links = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Links.push(DocumentLink.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetLinksReq {
    const message = { ...baseSetLinksReq } as SetLinksReq;
    message.Links = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Links !== undefined && object.Links !== null) {
      for (const e of object.Links) {
        message.Links.push(DocumentLink.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: SetLinksReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    if (message.Links) {
      obj.Links = message.Links.map((e) =>
        e ? DocumentLink.toJSON(e) : undefined
      );
    } else {
      obj.Links = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SetLinksReq>): SetLinksReq {
    const message = { ...baseSetLinksReq } as SetLinksReq;
    message.Links = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Links !== undefined && object.Links !== null) {
      for (const e of object.Links) {
        message.Links.push(DocumentLink.fromPartial(e));
      }
    }
    return message;
  },
};

const baseDocumentLink: object = { Target: "", Kind: "" };

export const DocumentLink = {
  encode(
    message: DocumentLink,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Target !== "") {
      writer.uint32(10).string(message.Target);
    }
    if (message.Kind !== "") {
      writer.uint32(18).string(message.Kind);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentLink {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDocumentLink } as DocumentLink;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Target = reader.string();
          break;
        case 2:
          message.Kind = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DocumentLink {
    const message = { ...baseDocumentLink } as DocumentLink;
    if (object.Target !== undefined && object.Target !== null) {
      message.Target = String(object.Target);
    } else {
      message.Target = "";
    }
    if (object.Kind !== undefined && object.Kind !== null) {
      message.Kind = String(object.Kind);
    } else {
      message.Kind = "";
    }
    return message;
  },

  toJSON(message: DocumentLink): unknown {
    const obj: any = {};
    message.Target !== undefined && (obj.Target = message.Target);
    message.Kind !== undefined && (obj.Kind = message.Kind);
    return obj;
  },

  fromPartial(object: DeepPartial<DocumentLink>): DocumentLink {
    const message = { ...baseDocumentLink } as DocumentLink;
    if (object.Target !== undefined && object.Target !== null) {
      message.Target = object.Target;
    } else {
      message.Target = "";
    }
    if (object.Kind !== undefined && object.Kind !== null) {
      message.Kind = object.Kind;
    } else {
      message.Kind = "";
    }
    return message;
  },
};

const baseAddWorkflowTaskReq: object = {
  Text: "",
  Description: "",
  Document: "",
};

export const AddWorkflowTaskReq = {
  encode(
    message: AddWorkflowTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Assignee !== undefined) {
      ActorIDs.encode(message.Assignee, writer.uint32(10).fork()).ldelim();
    }
    if (message.Text !== "") {
      writer.uint32(18).string(message.Text);
    }
    if (message.Description !== "") {
      writer.uint32(26).string(message.Description);
    }
    if (message.Confirmer !== undefined) {
      ActorIDs.encode(message.Confirmer, writer.uint32(34).fork()).ldelim();
    }
    if (message.Document !== "") {
      writer.uint32(42).string(message.Document);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddWorkflowTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddWorkflowTaskReq } as AddWorkflowTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Assignee = ActorIDs.decode(reader, reader.uint32());
          break;
        case 2:
          message.Text = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        case 4:
          message.Confirmer = ActorIDs.decode(reader, reader.uint32());
          break;
        case 5:
          message.Document = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddWorkflowTaskReq {
    const message = { ...baseAddWorkflowTaskReq } as AddWorkflowTaskReq;
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = ActorIDs.fromJSON(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = String(object.Text);
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = ActorIDs.fromJSON(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = String(object.Document);
    } else {
      message.Document = "";
    }
    return message;
  },

  toJSON(message: AddWorkflowTaskReq): unknown {
    const obj: any = {};
    message.Assignee !== undefined &&
      (obj.Assignee = message.Assignee
        ? ActorIDs.toJSON(message.Assignee)
        : undefined);
    message.Text !== undefined && (obj.Text = message.Text);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Confirmer !== undefined &&
      (obj.Confirmer = message.Confirmer
        ? ActorIDs.toJSON(message.Confirmer)
        : undefined);
    message.Document !== undefined && (obj.Document = message.Document);
    return obj;
  },

  fromPartial(object: DeepPartial<AddWorkflowTaskReq>): AddWorkflowTaskReq {
    const message = { ...baseAddWorkflowTaskReq } as AddWorkflowTaskReq;
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = ActorIDs.fromPartial(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = object.Text;
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = ActorIDs.fromPartial(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = object.Document;
    } else {
      message.Document = "";
    }
    return message;
  },
};

const baseAddActorToWorkflowApprovalReq: object = {
  DocumentID: "",
  ApprovalID: "",
};

export const AddActorToWorkflowApprovalReq = {
  encode(
    message: AddActorToWorkflowApprovalReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DocumentID !== "") {
      writer.uint32(10).string(message.DocumentID);
    }
    if (message.ApprovalID !== "") {
      writer.uint32(18).string(message.ApprovalID);
    }
    if (message.Actor !== undefined) {
      ActorIDs.encode(message.Actor, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): AddActorToWorkflowApprovalReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseAddActorToWorkflowApprovalReq,
    } as AddActorToWorkflowApprovalReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DocumentID = reader.string();
          break;
        case 2:
          message.ApprovalID = reader.string();
          break;
        case 3:
          message.Actor = ActorIDs.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddActorToWorkflowApprovalReq {
    const message = {
      ...baseAddActorToWorkflowApprovalReq,
    } as AddActorToWorkflowApprovalReq;
    if (object.DocumentID !== undefined && object.DocumentID !== null) {
      message.DocumentID = String(object.DocumentID);
    } else {
      message.DocumentID = "";
    }
    if (object.ApprovalID !== undefined && object.ApprovalID !== null) {
      message.ApprovalID = String(object.ApprovalID);
    } else {
      message.ApprovalID = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = ActorIDs.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    return message;
  },

  toJSON(message: AddActorToWorkflowApprovalReq): unknown {
    const obj: any = {};
    message.DocumentID !== undefined && (obj.DocumentID = message.DocumentID);
    message.ApprovalID !== undefined && (obj.ApprovalID = message.ApprovalID);
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? ActorIDs.toJSON(message.Actor) : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<AddActorToWorkflowApprovalReq>
  ): AddActorToWorkflowApprovalReq {
    const message = {
      ...baseAddActorToWorkflowApprovalReq,
    } as AddActorToWorkflowApprovalReq;
    if (object.DocumentID !== undefined && object.DocumentID !== null) {
      message.DocumentID = object.DocumentID;
    } else {
      message.DocumentID = "";
    }
    if (object.ApprovalID !== undefined && object.ApprovalID !== null) {
      message.ApprovalID = object.ApprovalID;
    } else {
      message.ApprovalID = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = ActorIDs.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    return message;
  },
};

const baseRemoveWorkflowTaskReq: object = { TaskID: "", DocumentID: "" };

export const RemoveWorkflowTaskReq = {
  encode(
    message: RemoveWorkflowTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.TaskID !== "") {
      writer.uint32(10).string(message.TaskID);
    }
    if (message.DocumentID !== "") {
      writer.uint32(18).string(message.DocumentID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RemoveWorkflowTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveWorkflowTaskReq } as RemoveWorkflowTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        case 2:
          message.DocumentID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveWorkflowTaskReq {
    const message = { ...baseRemoveWorkflowTaskReq } as RemoveWorkflowTaskReq;
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = String(object.TaskID);
    } else {
      message.TaskID = "";
    }
    if (object.DocumentID !== undefined && object.DocumentID !== null) {
      message.DocumentID = String(object.DocumentID);
    } else {
      message.DocumentID = "";
    }
    return message;
  },

  toJSON(message: RemoveWorkflowTaskReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.DocumentID !== undefined && (obj.DocumentID = message.DocumentID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RemoveWorkflowTaskReq>
  ): RemoveWorkflowTaskReq {
    const message = { ...baseRemoveWorkflowTaskReq } as RemoveWorkflowTaskReq;
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = object.TaskID;
    } else {
      message.TaskID = "";
    }
    if (object.DocumentID !== undefined && object.DocumentID !== null) {
      message.DocumentID = object.DocumentID;
    } else {
      message.DocumentID = "";
    }
    return message;
  },
};

const baseActor: object = {};

export const Actor = {
  encode(message: Actor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.User !== undefined) {
      UserInfo.encode(message.User, writer.uint32(10).fork()).ldelim();
    }
    if (message.Unit !== undefined) {
      UnitInfo.encode(message.Unit, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Actor {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseActor } as Actor;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = UserInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Unit = UnitInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Actor {
    const message = { ...baseActor } as Actor;
    if (object.User !== undefined && object.User !== null) {
      message.User = UserInfo.fromJSON(object.User);
    } else {
      message.User = undefined;
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = UnitInfo.fromJSON(object.Unit);
    } else {
      message.Unit = undefined;
    }
    return message;
  },

  toJSON(message: Actor): unknown {
    const obj: any = {};
    message.User !== undefined &&
      (obj.User = message.User ? UserInfo.toJSON(message.User) : undefined);
    message.Unit !== undefined &&
      (obj.Unit = message.Unit ? UnitInfo.toJSON(message.Unit) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Actor>): Actor {
    const message = { ...baseActor } as Actor;
    if (object.User !== undefined && object.User !== null) {
      message.User = UserInfo.fromPartial(object.User);
    } else {
      message.User = undefined;
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = UnitInfo.fromPartial(object.Unit);
    } else {
      message.Unit = undefined;
    }
    return message;
  },
};

const baseActorIDs: object = { UserID: "", UnitID: "" };

export const ActorIDs = {
  encode(
    message: ActorIDs,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.UserID !== "") {
      writer.uint32(10).string(message.UserID);
    }
    if (message.UnitID !== "") {
      writer.uint32(18).string(message.UnitID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActorIDs {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseActorIDs } as ActorIDs;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.UnitID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActorIDs {
    const message = { ...baseActorIDs } as ActorIDs;
    if (object.UserID !== undefined && object.UserID !== null) {
      message.UserID = String(object.UserID);
    } else {
      message.UserID = "";
    }
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = String(object.UnitID);
    } else {
      message.UnitID = "";
    }
    return message;
  },

  toJSON(message: ActorIDs): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.UnitID !== undefined && (obj.UnitID = message.UnitID);
    return obj;
  },

  fromPartial(object: DeepPartial<ActorIDs>): ActorIDs {
    const message = { ...baseActorIDs } as ActorIDs;
    if (object.UserID !== undefined && object.UserID !== null) {
      message.UserID = object.UserID;
    } else {
      message.UserID = "";
    }
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = object.UnitID;
    } else {
      message.UnitID = "";
    }
    return message;
  },
};

const baseUnitInfo: object = { ID: "", Name: "" };

export const UnitInfo = {
  encode(
    message: UnitInfo,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUnitInfo } as UnitInfo;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UnitInfo {
    const message = { ...baseUnitInfo } as UnitInfo;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    return message;
  },

  toJSON(message: UnitInfo): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial(object: DeepPartial<UnitInfo>): UnitInfo {
    const message = { ...baseUnitInfo } as UnitInfo;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    return message;
  },
};

const baseWorkflowTemplate: object = { VVS: "", Static: false };

export const WorkflowTemplate = {
  encode(
    message: WorkflowTemplate,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.VVS !== "") {
      writer.uint32(10).string(message.VVS);
    }
    if (message.Static === true) {
      writer.uint32(24).bool(message.Static);
    }
    for (const v of message.Stages) {
      WorkflowTemplateStage.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowTemplate {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowTemplate } as WorkflowTemplate;
    message.Stages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.VVS = reader.string();
          break;
        case 3:
          message.Static = reader.bool();
          break;
        case 4:
          message.Stages.push(
            WorkflowTemplateStage.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowTemplate {
    const message = { ...baseWorkflowTemplate } as WorkflowTemplate;
    message.Stages = [];
    if (object.VVS !== undefined && object.VVS !== null) {
      message.VVS = String(object.VVS);
    } else {
      message.VVS = "";
    }
    if (object.Static !== undefined && object.Static !== null) {
      message.Static = Boolean(object.Static);
    } else {
      message.Static = false;
    }
    if (object.Stages !== undefined && object.Stages !== null) {
      for (const e of object.Stages) {
        message.Stages.push(WorkflowTemplateStage.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: WorkflowTemplate): unknown {
    const obj: any = {};
    message.VVS !== undefined && (obj.VVS = message.VVS);
    message.Static !== undefined && (obj.Static = message.Static);
    if (message.Stages) {
      obj.Stages = message.Stages.map((e) =>
        e ? WorkflowTemplateStage.toJSON(e) : undefined
      );
    } else {
      obj.Stages = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<WorkflowTemplate>): WorkflowTemplate {
    const message = { ...baseWorkflowTemplate } as WorkflowTemplate;
    message.Stages = [];
    if (object.VVS !== undefined && object.VVS !== null) {
      message.VVS = object.VVS;
    } else {
      message.VVS = "";
    }
    if (object.Static !== undefined && object.Static !== null) {
      message.Static = object.Static;
    } else {
      message.Static = false;
    }
    if (object.Stages !== undefined && object.Stages !== null) {
      for (const e of object.Stages) {
        message.Stages.push(WorkflowTemplateStage.fromPartial(e));
      }
    }
    return message;
  },
};

const baseWorkflowTemplateStage: object = { Type: "", DisplayName: "" };

export const WorkflowTemplateStage = {
  encode(
    message: WorkflowTemplateStage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Type !== "") {
      writer.uint32(10).string(message.Type);
    }
    if (message.DisplayName !== "") {
      writer.uint32(18).string(message.DisplayName);
    }
    if (message.Approvals !== undefined) {
      WorkflowTemplateStageApprovals.encode(
        message.Approvals,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.Tasks !== undefined) {
      WorkflowTemplateStageTasks.encode(
        message.Tasks,
        writer.uint32(34).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowTemplateStage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowTemplateStage } as WorkflowTemplateStage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.string();
          break;
        case 2:
          message.DisplayName = reader.string();
          break;
        case 3:
          message.Approvals = WorkflowTemplateStageApprovals.decode(
            reader,
            reader.uint32()
          );
          break;
        case 4:
          message.Tasks = WorkflowTemplateStageTasks.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowTemplateStage {
    const message = { ...baseWorkflowTemplateStage } as WorkflowTemplateStage;
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.DisplayName !== undefined && object.DisplayName !== null) {
      message.DisplayName = String(object.DisplayName);
    } else {
      message.DisplayName = "";
    }
    if (object.Approvals !== undefined && object.Approvals !== null) {
      message.Approvals = WorkflowTemplateStageApprovals.fromJSON(
        object.Approvals
      );
    } else {
      message.Approvals = undefined;
    }
    if (object.Tasks !== undefined && object.Tasks !== null) {
      message.Tasks = WorkflowTemplateStageTasks.fromJSON(object.Tasks);
    } else {
      message.Tasks = undefined;
    }
    return message;
  },

  toJSON(message: WorkflowTemplateStage): unknown {
    const obj: any = {};
    message.Type !== undefined && (obj.Type = message.Type);
    message.DisplayName !== undefined &&
      (obj.DisplayName = message.DisplayName);
    message.Approvals !== undefined &&
      (obj.Approvals = message.Approvals
        ? WorkflowTemplateStageApprovals.toJSON(message.Approvals)
        : undefined);
    message.Tasks !== undefined &&
      (obj.Tasks = message.Tasks
        ? WorkflowTemplateStageTasks.toJSON(message.Tasks)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowTemplateStage>
  ): WorkflowTemplateStage {
    const message = { ...baseWorkflowTemplateStage } as WorkflowTemplateStage;
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.DisplayName !== undefined && object.DisplayName !== null) {
      message.DisplayName = object.DisplayName;
    } else {
      message.DisplayName = "";
    }
    if (object.Approvals !== undefined && object.Approvals !== null) {
      message.Approvals = WorkflowTemplateStageApprovals.fromPartial(
        object.Approvals
      );
    } else {
      message.Approvals = undefined;
    }
    if (object.Tasks !== undefined && object.Tasks !== null) {
      message.Tasks = WorkflowTemplateStageTasks.fromPartial(object.Tasks);
    } else {
      message.Tasks = undefined;
    }
    return message;
  },
};

const baseWorkflowTemplateStageApprovals: object = {};

export const WorkflowTemplateStageApprovals = {
  encode(
    message: WorkflowTemplateStageApprovals,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Approvals) {
      ApprovalTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowTemplateStageApprovals {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseWorkflowTemplateStageApprovals,
    } as WorkflowTemplateStageApprovals;
    message.Approvals = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Approvals.push(
            ApprovalTemplate.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowTemplateStageApprovals {
    const message = {
      ...baseWorkflowTemplateStageApprovals,
    } as WorkflowTemplateStageApprovals;
    message.Approvals = [];
    if (object.Approvals !== undefined && object.Approvals !== null) {
      for (const e of object.Approvals) {
        message.Approvals.push(ApprovalTemplate.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: WorkflowTemplateStageApprovals): unknown {
    const obj: any = {};
    if (message.Approvals) {
      obj.Approvals = message.Approvals.map((e) =>
        e ? ApprovalTemplate.toJSON(e) : undefined
      );
    } else {
      obj.Approvals = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowTemplateStageApprovals>
  ): WorkflowTemplateStageApprovals {
    const message = {
      ...baseWorkflowTemplateStageApprovals,
    } as WorkflowTemplateStageApprovals;
    message.Approvals = [];
    if (object.Approvals !== undefined && object.Approvals !== null) {
      for (const e of object.Approvals) {
        message.Approvals.push(ApprovalTemplate.fromPartial(e));
      }
    }
    return message;
  },
};

const baseApprovalTemplate: object = { ID: "", SpecialName: "", Inputs: "" };

export const ApprovalTemplate = {
  encode(
    message: ApprovalTemplate,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Actor !== undefined) {
      Actor.encode(message.Actor, writer.uint32(18).fork()).ldelim();
    }
    if (message.SpecialName !== "") {
      writer.uint32(26).string(message.SpecialName);
    }
    for (const v of message.Inputs) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApprovalTemplate {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseApprovalTemplate } as ApprovalTemplate;
    message.Inputs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Actor = Actor.decode(reader, reader.uint32());
          break;
        case 3:
          message.SpecialName = reader.string();
          break;
        case 4:
          message.Inputs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApprovalTemplate {
    const message = { ...baseApprovalTemplate } as ApprovalTemplate;
    message.Inputs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.SpecialName !== undefined && object.SpecialName !== null) {
      message.SpecialName = String(object.SpecialName);
    } else {
      message.SpecialName = "";
    }
    if (object.Inputs !== undefined && object.Inputs !== null) {
      for (const e of object.Inputs) {
        message.Inputs.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: ApprovalTemplate): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? Actor.toJSON(message.Actor) : undefined);
    message.SpecialName !== undefined &&
      (obj.SpecialName = message.SpecialName);
    if (message.Inputs) {
      obj.Inputs = message.Inputs.map((e) => e);
    } else {
      obj.Inputs = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ApprovalTemplate>): ApprovalTemplate {
    const message = { ...baseApprovalTemplate } as ApprovalTemplate;
    message.Inputs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.SpecialName !== undefined && object.SpecialName !== null) {
      message.SpecialName = object.SpecialName;
    } else {
      message.SpecialName = "";
    }
    if (object.Inputs !== undefined && object.Inputs !== null) {
      for (const e of object.Inputs) {
        message.Inputs.push(e);
      }
    }
    return message;
  },
};

const baseWorkflowTemplateStageTasks: object = {};

export const WorkflowTemplateStageTasks = {
  encode(
    message: WorkflowTemplateStageTasks,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Tasks) {
      TaskTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowTemplateStageTasks {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseWorkflowTemplateStageTasks,
    } as WorkflowTemplateStageTasks;
    message.Tasks = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tasks.push(TaskTemplate.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowTemplateStageTasks {
    const message = {
      ...baseWorkflowTemplateStageTasks,
    } as WorkflowTemplateStageTasks;
    message.Tasks = [];
    if (object.Tasks !== undefined && object.Tasks !== null) {
      for (const e of object.Tasks) {
        message.Tasks.push(TaskTemplate.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: WorkflowTemplateStageTasks): unknown {
    const obj: any = {};
    if (message.Tasks) {
      obj.Tasks = message.Tasks.map((e) =>
        e ? TaskTemplate.toJSON(e) : undefined
      );
    } else {
      obj.Tasks = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowTemplateStageTasks>
  ): WorkflowTemplateStageTasks {
    const message = {
      ...baseWorkflowTemplateStageTasks,
    } as WorkflowTemplateStageTasks;
    message.Tasks = [];
    if (object.Tasks !== undefined && object.Tasks !== null) {
      for (const e of object.Tasks) {
        message.Tasks.push(TaskTemplate.fromPartial(e));
      }
    }
    return message;
  },
};

const baseTaskTemplate: object = {
  ID: "",
  Text: "",
  Description: "",
  Inputs: "",
};

export const TaskTemplate = {
  encode(
    message: TaskTemplate,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Assignee !== undefined) {
      Actor.encode(message.Assignee, writer.uint32(18).fork()).ldelim();
    }
    if (message.Text !== "") {
      writer.uint32(26).string(message.Text);
    }
    if (message.Description !== "") {
      writer.uint32(34).string(message.Description);
    }
    if (message.Confirmer !== undefined) {
      Actor.encode(message.Confirmer, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.Inputs) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskTemplate {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTaskTemplate } as TaskTemplate;
    message.Inputs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Assignee = Actor.decode(reader, reader.uint32());
          break;
        case 3:
          message.Text = reader.string();
          break;
        case 4:
          message.Description = reader.string();
          break;
        case 5:
          message.Confirmer = Actor.decode(reader, reader.uint32());
          break;
        case 6:
          message.Inputs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TaskTemplate {
    const message = { ...baseTaskTemplate } as TaskTemplate;
    message.Inputs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = Actor.fromJSON(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = String(object.Text);
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = Actor.fromJSON(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    if (object.Inputs !== undefined && object.Inputs !== null) {
      for (const e of object.Inputs) {
        message.Inputs.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: TaskTemplate): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Assignee !== undefined &&
      (obj.Assignee = message.Assignee
        ? Actor.toJSON(message.Assignee)
        : undefined);
    message.Text !== undefined && (obj.Text = message.Text);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Confirmer !== undefined &&
      (obj.Confirmer = message.Confirmer
        ? Actor.toJSON(message.Confirmer)
        : undefined);
    if (message.Inputs) {
      obj.Inputs = message.Inputs.map((e) => e);
    } else {
      obj.Inputs = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<TaskTemplate>): TaskTemplate {
    const message = { ...baseTaskTemplate } as TaskTemplate;
    message.Inputs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = Actor.fromPartial(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = object.Text;
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = Actor.fromPartial(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    if (object.Inputs !== undefined && object.Inputs !== null) {
      for (const e of object.Inputs) {
        message.Inputs.push(e);
      }
    }
    return message;
  },
};

const baseSetWorkflowTemplateReq: object = { DocType: "" };

export const SetWorkflowTemplateReq = {
  encode(
    message: SetWorkflowTemplateReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DocType !== "") {
      writer.uint32(10).string(message.DocType);
    }
    if (message.Template !== undefined) {
      WorkflowTemplate.encode(
        message.Template,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SetWorkflowTemplateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetWorkflowTemplateReq } as SetWorkflowTemplateReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DocType = reader.string();
          break;
        case 2:
          message.Template = WorkflowTemplate.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetWorkflowTemplateReq {
    const message = { ...baseSetWorkflowTemplateReq } as SetWorkflowTemplateReq;
    if (object.DocType !== undefined && object.DocType !== null) {
      message.DocType = String(object.DocType);
    } else {
      message.DocType = "";
    }
    if (object.Template !== undefined && object.Template !== null) {
      message.Template = WorkflowTemplate.fromJSON(object.Template);
    } else {
      message.Template = undefined;
    }
    return message;
  },

  toJSON(message: SetWorkflowTemplateReq): unknown {
    const obj: any = {};
    message.DocType !== undefined && (obj.DocType = message.DocType);
    message.Template !== undefined &&
      (obj.Template = message.Template
        ? WorkflowTemplate.toJSON(message.Template)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SetWorkflowTemplateReq>
  ): SetWorkflowTemplateReq {
    const message = { ...baseSetWorkflowTemplateReq } as SetWorkflowTemplateReq;
    if (object.DocType !== undefined && object.DocType !== null) {
      message.DocType = object.DocType;
    } else {
      message.DocType = "";
    }
    if (object.Template !== undefined && object.Template !== null) {
      message.Template = WorkflowTemplate.fromPartial(object.Template);
    } else {
      message.Template = undefined;
    }
    return message;
  },
};

const baseGetWorkflowTemplateReq: object = { DocType: "" };

export const GetWorkflowTemplateReq = {
  encode(
    message: GetWorkflowTemplateReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DocType !== "") {
      writer.uint32(10).string(message.DocType);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetWorkflowTemplateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetWorkflowTemplateReq } as GetWorkflowTemplateReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DocType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetWorkflowTemplateReq {
    const message = { ...baseGetWorkflowTemplateReq } as GetWorkflowTemplateReq;
    if (object.DocType !== undefined && object.DocType !== null) {
      message.DocType = String(object.DocType);
    } else {
      message.DocType = "";
    }
    return message;
  },

  toJSON(message: GetWorkflowTemplateReq): unknown {
    const obj: any = {};
    message.DocType !== undefined && (obj.DocType = message.DocType);
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetWorkflowTemplateReq>
  ): GetWorkflowTemplateReq {
    const message = { ...baseGetWorkflowTemplateReq } as GetWorkflowTemplateReq;
    if (object.DocType !== undefined && object.DocType !== null) {
      message.DocType = object.DocType;
    } else {
      message.DocType = "";
    }
    return message;
  },
};

const baseWorkflowApprovalAcceptreq: object = { Document: "", ID: "" };

export const WorkflowApprovalAcceptreq = {
  encode(
    message: WorkflowApprovalAcceptreq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Document !== "") {
      writer.uint32(10).string(message.Document);
    }
    if (message.ID !== "") {
      writer.uint32(18).string(message.ID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowApprovalAcceptreq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseWorkflowApprovalAcceptreq,
    } as WorkflowApprovalAcceptreq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Document = reader.string();
          break;
        case 2:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowApprovalAcceptreq {
    const message = {
      ...baseWorkflowApprovalAcceptreq,
    } as WorkflowApprovalAcceptreq;
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = String(object.Document);
    } else {
      message.Document = "";
    }
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: WorkflowApprovalAcceptreq): unknown {
    const obj: any = {};
    message.Document !== undefined && (obj.Document = message.Document);
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowApprovalAcceptreq>
  ): WorkflowApprovalAcceptreq {
    const message = {
      ...baseWorkflowApprovalAcceptreq,
    } as WorkflowApprovalAcceptreq;
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = object.Document;
    } else {
      message.Document = "";
    }
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseWorkflowApprovalDroptoreq: object = {
  Document: "",
  Approval: "",
  DropTo: "",
  Reason: "",
};

export const WorkflowApprovalDroptoreq = {
  encode(
    message: WorkflowApprovalDroptoreq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Document !== "") {
      writer.uint32(10).string(message.Document);
    }
    if (message.Approval !== "") {
      writer.uint32(18).string(message.Approval);
    }
    for (const v of message.DropTo) {
      writer.uint32(26).string(v!);
    }
    if (message.Reason !== "") {
      writer.uint32(34).string(message.Reason);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowApprovalDroptoreq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseWorkflowApprovalDroptoreq,
    } as WorkflowApprovalDroptoreq;
    message.DropTo = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Document = reader.string();
          break;
        case 2:
          message.Approval = reader.string();
          break;
        case 3:
          message.DropTo.push(reader.string());
          break;
        case 4:
          message.Reason = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowApprovalDroptoreq {
    const message = {
      ...baseWorkflowApprovalDroptoreq,
    } as WorkflowApprovalDroptoreq;
    message.DropTo = [];
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = String(object.Document);
    } else {
      message.Document = "";
    }
    if (object.Approval !== undefined && object.Approval !== null) {
      message.Approval = String(object.Approval);
    } else {
      message.Approval = "";
    }
    if (object.DropTo !== undefined && object.DropTo !== null) {
      for (const e of object.DropTo) {
        message.DropTo.push(String(e));
      }
    }
    if (object.Reason !== undefined && object.Reason !== null) {
      message.Reason = String(object.Reason);
    } else {
      message.Reason = "";
    }
    return message;
  },

  toJSON(message: WorkflowApprovalDroptoreq): unknown {
    const obj: any = {};
    message.Document !== undefined && (obj.Document = message.Document);
    message.Approval !== undefined && (obj.Approval = message.Approval);
    if (message.DropTo) {
      obj.DropTo = message.DropTo.map((e) => e);
    } else {
      obj.DropTo = [];
    }
    message.Reason !== undefined && (obj.Reason = message.Reason);
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowApprovalDroptoreq>
  ): WorkflowApprovalDroptoreq {
    const message = {
      ...baseWorkflowApprovalDroptoreq,
    } as WorkflowApprovalDroptoreq;
    message.DropTo = [];
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = object.Document;
    } else {
      message.Document = "";
    }
    if (object.Approval !== undefined && object.Approval !== null) {
      message.Approval = object.Approval;
    } else {
      message.Approval = "";
    }
    if (object.DropTo !== undefined && object.DropTo !== null) {
      for (const e of object.DropTo) {
        message.DropTo.push(e);
      }
    }
    if (object.Reason !== undefined && object.Reason !== null) {
      message.Reason = object.Reason;
    } else {
      message.Reason = "";
    }
    return message;
  },
};

const baseWorkflowTaskCompletereq: object = { ID: "", Document: "" };

export const WorkflowTaskCompletereq = {
  encode(
    message: WorkflowTaskCompletereq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Document !== "") {
      writer.uint32(18).string(message.Document);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowTaskCompletereq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseWorkflowTaskCompletereq,
    } as WorkflowTaskCompletereq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Document = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowTaskCompletereq {
    const message = {
      ...baseWorkflowTaskCompletereq,
    } as WorkflowTaskCompletereq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = String(object.Document);
    } else {
      message.Document = "";
    }
    return message;
  },

  toJSON(message: WorkflowTaskCompletereq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Document !== undefined && (obj.Document = message.Document);
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowTaskCompletereq>
  ): WorkflowTaskCompletereq {
    const message = {
      ...baseWorkflowTaskCompletereq,
    } as WorkflowTaskCompletereq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = object.Document;
    } else {
      message.Document = "";
    }
    return message;
  },
};

const baseWorkflowTaskConfirmreq: object = { ID: "", Document: "" };

export const WorkflowTaskConfirmreq = {
  encode(
    message: WorkflowTaskConfirmreq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Document !== "") {
      writer.uint32(18).string(message.Document);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowTaskConfirmreq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowTaskConfirmreq } as WorkflowTaskConfirmreq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Document = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowTaskConfirmreq {
    const message = { ...baseWorkflowTaskConfirmreq } as WorkflowTaskConfirmreq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = String(object.Document);
    } else {
      message.Document = "";
    }
    return message;
  },

  toJSON(message: WorkflowTaskConfirmreq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Document !== undefined && (obj.Document = message.Document);
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowTaskConfirmreq>
  ): WorkflowTaskConfirmreq {
    const message = { ...baseWorkflowTaskConfirmreq } as WorkflowTaskConfirmreq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = object.Document;
    } else {
      message.Document = "";
    }
    return message;
  },
};

const baseWorkflowTaskRejectreq: object = { ID: "", Document: "" };

export const WorkflowTaskRejectreq = {
  encode(
    message: WorkflowTaskRejectreq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Document !== "") {
      writer.uint32(18).string(message.Document);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowTaskRejectreq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowTaskRejectreq } as WorkflowTaskRejectreq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Document = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowTaskRejectreq {
    const message = { ...baseWorkflowTaskRejectreq } as WorkflowTaskRejectreq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = String(object.Document);
    } else {
      message.Document = "";
    }
    return message;
  },

  toJSON(message: WorkflowTaskRejectreq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Document !== undefined && (obj.Document = message.Document);
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowTaskRejectreq>
  ): WorkflowTaskRejectreq {
    const message = { ...baseWorkflowTaskRejectreq } as WorkflowTaskRejectreq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = object.Document;
    } else {
      message.Document = "";
    }
    return message;
  },
};

const baseWorkflow: object = {};

export const Workflow = {
  encode(
    message: Workflow,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Stages) {
      WorkflowStage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Workflow {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflow } as Workflow;
    message.Stages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Stages.push(WorkflowStage.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Workflow {
    const message = { ...baseWorkflow } as Workflow;
    message.Stages = [];
    if (object.Stages !== undefined && object.Stages !== null) {
      for (const e of object.Stages) {
        message.Stages.push(WorkflowStage.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: Workflow): unknown {
    const obj: any = {};
    if (message.Stages) {
      obj.Stages = message.Stages.map((e) =>
        e ? WorkflowStage.toJSON(e) : undefined
      );
    } else {
      obj.Stages = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Workflow>): Workflow {
    const message = { ...baseWorkflow } as Workflow;
    message.Stages = [];
    if (object.Stages !== undefined && object.Stages !== null) {
      for (const e of object.Stages) {
        message.Stages.push(WorkflowStage.fromPartial(e));
      }
    }
    return message;
  },
};

const baseWorkflowStage: object = {
  Type: "",
  DisplayName: "",
  Completed: false,
};

export const WorkflowStage = {
  encode(
    message: WorkflowStage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Type !== "") {
      writer.uint32(10).string(message.Type);
    }
    if (message.DisplayName !== "") {
      writer.uint32(18).string(message.DisplayName);
    }
    if (message.Completed === true) {
      writer.uint32(24).bool(message.Completed);
    }
    for (const v of message.Graph) {
      WorkflowElement.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowStage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowStage } as WorkflowStage;
    message.Graph = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.string();
          break;
        case 2:
          message.DisplayName = reader.string();
          break;
        case 3:
          message.Completed = reader.bool();
          break;
        case 4:
          message.Graph.push(WorkflowElement.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowStage {
    const message = { ...baseWorkflowStage } as WorkflowStage;
    message.Graph = [];
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.DisplayName !== undefined && object.DisplayName !== null) {
      message.DisplayName = String(object.DisplayName);
    } else {
      message.DisplayName = "";
    }
    if (object.Completed !== undefined && object.Completed !== null) {
      message.Completed = Boolean(object.Completed);
    } else {
      message.Completed = false;
    }
    if (object.Graph !== undefined && object.Graph !== null) {
      for (const e of object.Graph) {
        message.Graph.push(WorkflowElement.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: WorkflowStage): unknown {
    const obj: any = {};
    message.Type !== undefined && (obj.Type = message.Type);
    message.DisplayName !== undefined &&
      (obj.DisplayName = message.DisplayName);
    message.Completed !== undefined && (obj.Completed = message.Completed);
    if (message.Graph) {
      obj.Graph = message.Graph.map((e) =>
        e ? WorkflowElement.toJSON(e) : undefined
      );
    } else {
      obj.Graph = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<WorkflowStage>): WorkflowStage {
    const message = { ...baseWorkflowStage } as WorkflowStage;
    message.Graph = [];
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.DisplayName !== undefined && object.DisplayName !== null) {
      message.DisplayName = object.DisplayName;
    } else {
      message.DisplayName = "";
    }
    if (object.Completed !== undefined && object.Completed !== null) {
      message.Completed = object.Completed;
    } else {
      message.Completed = false;
    }
    if (object.Graph !== undefined && object.Graph !== null) {
      for (const e of object.Graph) {
        message.Graph.push(WorkflowElement.fromPartial(e));
      }
    }
    return message;
  },
};

const baseWorkflowElement: object = {
  ID: "",
  Inputs: "",
  Outputs: "",
  Passed: false,
  Pending: false,
  AutoAccept: false,
};

export const WorkflowElement = {
  encode(
    message: WorkflowElement,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Actor !== undefined) {
      Actor.encode(message.Actor, writer.uint32(18).fork()).ldelim();
    }
    if (message.Special !== undefined) {
      WorkflowElementSpecial.encode(
        message.Special,
        writer.uint32(26).fork()
      ).ldelim();
    }
    for (const v of message.Inputs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.Outputs) {
      writer.uint32(42).string(v!);
    }
    if (message.Passed === true) {
      writer.uint32(48).bool(message.Passed);
    }
    if (message.Pending === true) {
      writer.uint32(56).bool(message.Pending);
    }
    if (message.AutoAccept === true) {
      writer.uint32(64).bool(message.AutoAccept);
    }
    if (message.Approval !== undefined) {
      WorkflowApproval.encode(
        message.Approval,
        writer.uint32(74).fork()
      ).ldelim();
    }
    if (message.Task !== undefined) {
      WorkflowTask.encode(message.Task, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowElement {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowElement } as WorkflowElement;
    message.Inputs = [];
    message.Outputs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Actor = Actor.decode(reader, reader.uint32());
          break;
        case 3:
          message.Special = WorkflowElementSpecial.decode(
            reader,
            reader.uint32()
          );
          break;
        case 4:
          message.Inputs.push(reader.string());
          break;
        case 5:
          message.Outputs.push(reader.string());
          break;
        case 6:
          message.Passed = reader.bool();
          break;
        case 7:
          message.Pending = reader.bool();
          break;
        case 8:
          message.AutoAccept = reader.bool();
          break;
        case 9:
          message.Approval = WorkflowApproval.decode(reader, reader.uint32());
          break;
        case 10:
          message.Task = WorkflowTask.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowElement {
    const message = { ...baseWorkflowElement } as WorkflowElement;
    message.Inputs = [];
    message.Outputs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.Special !== undefined && object.Special !== null) {
      message.Special = WorkflowElementSpecial.fromJSON(object.Special);
    } else {
      message.Special = undefined;
    }
    if (object.Inputs !== undefined && object.Inputs !== null) {
      for (const e of object.Inputs) {
        message.Inputs.push(String(e));
      }
    }
    if (object.Outputs !== undefined && object.Outputs !== null) {
      for (const e of object.Outputs) {
        message.Outputs.push(String(e));
      }
    }
    if (object.Passed !== undefined && object.Passed !== null) {
      message.Passed = Boolean(object.Passed);
    } else {
      message.Passed = false;
    }
    if (object.Pending !== undefined && object.Pending !== null) {
      message.Pending = Boolean(object.Pending);
    } else {
      message.Pending = false;
    }
    if (object.AutoAccept !== undefined && object.AutoAccept !== null) {
      message.AutoAccept = Boolean(object.AutoAccept);
    } else {
      message.AutoAccept = false;
    }
    if (object.Approval !== undefined && object.Approval !== null) {
      message.Approval = WorkflowApproval.fromJSON(object.Approval);
    } else {
      message.Approval = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = WorkflowTask.fromJSON(object.Task);
    } else {
      message.Task = undefined;
    }
    return message;
  },

  toJSON(message: WorkflowElement): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? Actor.toJSON(message.Actor) : undefined);
    message.Special !== undefined &&
      (obj.Special = message.Special
        ? WorkflowElementSpecial.toJSON(message.Special)
        : undefined);
    if (message.Inputs) {
      obj.Inputs = message.Inputs.map((e) => e);
    } else {
      obj.Inputs = [];
    }
    if (message.Outputs) {
      obj.Outputs = message.Outputs.map((e) => e);
    } else {
      obj.Outputs = [];
    }
    message.Passed !== undefined && (obj.Passed = message.Passed);
    message.Pending !== undefined && (obj.Pending = message.Pending);
    message.AutoAccept !== undefined && (obj.AutoAccept = message.AutoAccept);
    message.Approval !== undefined &&
      (obj.Approval = message.Approval
        ? WorkflowApproval.toJSON(message.Approval)
        : undefined);
    message.Task !== undefined &&
      (obj.Task = message.Task ? WorkflowTask.toJSON(message.Task) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<WorkflowElement>): WorkflowElement {
    const message = { ...baseWorkflowElement } as WorkflowElement;
    message.Inputs = [];
    message.Outputs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.Special !== undefined && object.Special !== null) {
      message.Special = WorkflowElementSpecial.fromPartial(object.Special);
    } else {
      message.Special = undefined;
    }
    if (object.Inputs !== undefined && object.Inputs !== null) {
      for (const e of object.Inputs) {
        message.Inputs.push(e);
      }
    }
    if (object.Outputs !== undefined && object.Outputs !== null) {
      for (const e of object.Outputs) {
        message.Outputs.push(e);
      }
    }
    if (object.Passed !== undefined && object.Passed !== null) {
      message.Passed = object.Passed;
    } else {
      message.Passed = false;
    }
    if (object.Pending !== undefined && object.Pending !== null) {
      message.Pending = object.Pending;
    } else {
      message.Pending = false;
    }
    if (object.AutoAccept !== undefined && object.AutoAccept !== null) {
      message.AutoAccept = object.AutoAccept;
    } else {
      message.AutoAccept = false;
    }
    if (object.Approval !== undefined && object.Approval !== null) {
      message.Approval = WorkflowApproval.fromPartial(object.Approval);
    } else {
      message.Approval = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = WorkflowTask.fromPartial(object.Task);
    } else {
      message.Task = undefined;
    }
    return message;
  },
};

const baseWorkflowElementSpecial: object = { Name: "" };

export const WorkflowElementSpecial = {
  encode(
    message: WorkflowElementSpecial,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Name !== "") {
      writer.uint32(10).string(message.Name);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowElementSpecial {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowElementSpecial } as WorkflowElementSpecial;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowElementSpecial {
    const message = { ...baseWorkflowElementSpecial } as WorkflowElementSpecial;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    return message;
  },

  toJSON(message: WorkflowElementSpecial): unknown {
    const obj: any = {};
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowElementSpecial>
  ): WorkflowElementSpecial {
    const message = { ...baseWorkflowElementSpecial } as WorkflowElementSpecial;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    return message;
  },
};

const baseWorkflowApproval: object = {};

export const WorkflowApproval = {
  encode(
    _: WorkflowApproval,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowApproval {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowApproval } as WorkflowApproval;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): WorkflowApproval {
    const message = { ...baseWorkflowApproval } as WorkflowApproval;
    return message;
  },

  toJSON(_: WorkflowApproval): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<WorkflowApproval>): WorkflowApproval {
    const message = { ...baseWorkflowApproval } as WorkflowApproval;
    return message;
  },
};

const baseWorkflowTask: object = { Text: "", Description: "", Type: "" };

export const WorkflowTask = {
  encode(
    message: WorkflowTask,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Text !== "") {
      writer.uint32(10).string(message.Text);
    }
    if (message.Description !== "") {
      writer.uint32(18).string(message.Description);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowTask {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowTask } as WorkflowTask;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Text = reader.string();
          break;
        case 2:
          message.Description = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowTask {
    const message = { ...baseWorkflowTask } as WorkflowTask;
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = String(object.Text);
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    return message;
  },

  toJSON(message: WorkflowTask): unknown {
    const obj: any = {};
    message.Text !== undefined && (obj.Text = message.Text);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Type !== undefined && (obj.Type = message.Type);
    return obj;
  },

  fromPartial(object: DeepPartial<WorkflowTask>): WorkflowTask {
    const message = { ...baseWorkflowTask } as WorkflowTask;
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = object.Text;
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    return message;
  },
};

const baseWorkflowEvent: object = { ID: "", Type: "" };

export const WorkflowEvent = {
  encode(
    message: WorkflowEvent,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Document !== undefined) {
      WorkflowEventDocument.encode(
        message.Document,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.Actor !== undefined) {
      Actor.encode(message.Actor, writer.uint32(26).fork()).ldelim();
    }
    if (message.Type !== "") {
      writer.uint32(34).string(message.Type);
    }
    if (message.Approval !== undefined) {
      WorkflowEventApproval.encode(
        message.Approval,
        writer.uint32(42).fork()
      ).ldelim();
    }
    if (message.Task !== undefined) {
      WorkflowEventTask.encode(message.Task, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowEvent } as WorkflowEvent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Document = WorkflowEventDocument.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.Actor = Actor.decode(reader, reader.uint32());
          break;
        case 4:
          message.Type = reader.string();
          break;
        case 5:
          message.Approval = WorkflowEventApproval.decode(
            reader,
            reader.uint32()
          );
          break;
        case 6:
          message.Task = WorkflowEventTask.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowEvent {
    const message = { ...baseWorkflowEvent } as WorkflowEvent;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = WorkflowEventDocument.fromJSON(object.Document);
    } else {
      message.Document = undefined;
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.Approval !== undefined && object.Approval !== null) {
      message.Approval = WorkflowEventApproval.fromJSON(object.Approval);
    } else {
      message.Approval = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = WorkflowEventTask.fromJSON(object.Task);
    } else {
      message.Task = undefined;
    }
    return message;
  },

  toJSON(message: WorkflowEvent): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Document !== undefined &&
      (obj.Document = message.Document
        ? WorkflowEventDocument.toJSON(message.Document)
        : undefined);
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? Actor.toJSON(message.Actor) : undefined);
    message.Type !== undefined && (obj.Type = message.Type);
    message.Approval !== undefined &&
      (obj.Approval = message.Approval
        ? WorkflowEventApproval.toJSON(message.Approval)
        : undefined);
    message.Task !== undefined &&
      (obj.Task = message.Task
        ? WorkflowEventTask.toJSON(message.Task)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<WorkflowEvent>): WorkflowEvent {
    const message = { ...baseWorkflowEvent } as WorkflowEvent;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = WorkflowEventDocument.fromPartial(object.Document);
    } else {
      message.Document = undefined;
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.Approval !== undefined && object.Approval !== null) {
      message.Approval = WorkflowEventApproval.fromPartial(object.Approval);
    } else {
      message.Approval = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = WorkflowEventTask.fromPartial(object.Task);
    } else {
      message.Task = undefined;
    }
    return message;
  },
};

const baseWorkflowEventApproval: object = {};

export const WorkflowEventApproval = {
  encode(
    _: WorkflowEventApproval,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowEventApproval {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowEventApproval } as WorkflowEventApproval;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): WorkflowEventApproval {
    const message = { ...baseWorkflowEventApproval } as WorkflowEventApproval;
    return message;
  },

  toJSON(_: WorkflowEventApproval): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<WorkflowEventApproval>): WorkflowEventApproval {
    const message = { ...baseWorkflowEventApproval } as WorkflowEventApproval;
    return message;
  },
};

const baseWorkflowEventTask: object = { Text: "", Description: "", Type: "" };

export const WorkflowEventTask = {
  encode(
    message: WorkflowEventTask,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Text !== "") {
      writer.uint32(10).string(message.Text);
    }
    if (message.Description !== "") {
      writer.uint32(18).string(message.Description);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowEventTask {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowEventTask } as WorkflowEventTask;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Text = reader.string();
          break;
        case 2:
          message.Description = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowEventTask {
    const message = { ...baseWorkflowEventTask } as WorkflowEventTask;
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = String(object.Text);
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    return message;
  },

  toJSON(message: WorkflowEventTask): unknown {
    const obj: any = {};
    message.Text !== undefined && (obj.Text = message.Text);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Type !== undefined && (obj.Type = message.Type);
    return obj;
  },

  fromPartial(object: DeepPartial<WorkflowEventTask>): WorkflowEventTask {
    const message = { ...baseWorkflowEventTask } as WorkflowEventTask;
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = object.Text;
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    return message;
  },
};

const baseWorkflowEventDocument: object = {
  ID: "",
  Name: "",
  Type: "",
  CreationTime: 0,
};

export const WorkflowEventDocument = {
  encode(
    message: WorkflowEventDocument,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    if (message.CreationTime !== 0) {
      writer.uint32(32).int64(message.CreationTime);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): WorkflowEventDocument {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseWorkflowEventDocument } as WorkflowEventDocument;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        case 4:
          message.CreationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WorkflowEventDocument {
    const message = { ...baseWorkflowEventDocument } as WorkflowEventDocument;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = Number(object.CreationTime);
    } else {
      message.CreationTime = 0;
    }
    return message;
  },

  toJSON(message: WorkflowEventDocument): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined && (obj.Type = message.Type);
    message.CreationTime !== undefined &&
      (obj.CreationTime = message.CreationTime);
    return obj;
  },

  fromPartial(
    object: DeepPartial<WorkflowEventDocument>
  ): WorkflowEventDocument {
    const message = { ...baseWorkflowEventDocument } as WorkflowEventDocument;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = object.CreationTime;
    } else {
      message.CreationTime = 0;
    }
    return message;
  },
};

const baseDelegateTaskReq: object = {
  TaskID: "",
  NewAssigneeID: "",
  NewConfirmerID: "",
};

export const DelegateTaskReq = {
  encode(
    message: DelegateTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.TaskID !== "") {
      writer.uint32(10).string(message.TaskID);
    }
    if (message.NewAssigneeID !== "") {
      writer.uint32(18).string(message.NewAssigneeID);
    }
    if (message.NewConfirmerID !== "") {
      writer.uint32(26).string(message.NewConfirmerID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DelegateTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDelegateTaskReq } as DelegateTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        case 2:
          message.NewAssigneeID = reader.string();
          break;
        case 3:
          message.NewConfirmerID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DelegateTaskReq {
    const message = { ...baseDelegateTaskReq } as DelegateTaskReq;
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = String(object.TaskID);
    } else {
      message.TaskID = "";
    }
    if (object.NewAssigneeID !== undefined && object.NewAssigneeID !== null) {
      message.NewAssigneeID = String(object.NewAssigneeID);
    } else {
      message.NewAssigneeID = "";
    }
    if (object.NewConfirmerID !== undefined && object.NewConfirmerID !== null) {
      message.NewConfirmerID = String(object.NewConfirmerID);
    } else {
      message.NewConfirmerID = "";
    }
    return message;
  },

  toJSON(message: DelegateTaskReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.NewAssigneeID !== undefined &&
      (obj.NewAssigneeID = message.NewAssigneeID);
    message.NewConfirmerID !== undefined &&
      (obj.NewConfirmerID = message.NewConfirmerID);
    return obj;
  },

  fromPartial(object: DeepPartial<DelegateTaskReq>): DelegateTaskReq {
    const message = { ...baseDelegateTaskReq } as DelegateTaskReq;
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = object.TaskID;
    } else {
      message.TaskID = "";
    }
    if (object.NewAssigneeID !== undefined && object.NewAssigneeID !== null) {
      message.NewAssigneeID = object.NewAssigneeID;
    } else {
      message.NewAssigneeID = "";
    }
    if (object.NewConfirmerID !== undefined && object.NewConfirmerID !== null) {
      message.NewConfirmerID = object.NewConfirmerID;
    } else {
      message.NewConfirmerID = "";
    }
    return message;
  },
};

const baseArchivedTasksStreamReq: object = {};

export const ArchivedTasksStreamReq = {
  encode(
    _: ArchivedTasksStreamReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ArchivedTasksStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseArchivedTasksStreamReq } as ArchivedTasksStreamReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ArchivedTasksStreamReq {
    const message = { ...baseArchivedTasksStreamReq } as ArchivedTasksStreamReq;
    return message;
  },

  toJSON(_: ArchivedTasksStreamReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<ArchivedTasksStreamReq>): ArchivedTasksStreamReq {
    const message = { ...baseArchivedTasksStreamReq } as ArchivedTasksStreamReq;
    return message;
  },
};

const baseArchivedTasksStreamMessage: object = {};

export const ArchivedTasksStreamMessage = {
  encode(
    message: ArchivedTasksStreamMessage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.EOS !== undefined) {
      writer.uint32(10).string(message.EOS);
    }
    if (message.Task !== undefined) {
      Task.encode(message.Task, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ArchivedTasksStreamMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseArchivedTasksStreamMessage,
    } as ArchivedTasksStreamMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.EOS = reader.string();
          break;
        case 2:
          message.Task = Task.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ArchivedTasksStreamMessage {
    const message = {
      ...baseArchivedTasksStreamMessage,
    } as ArchivedTasksStreamMessage;
    if (object.EOS !== undefined && object.EOS !== null) {
      message.EOS = String(object.EOS);
    } else {
      message.EOS = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = Task.fromJSON(object.Task);
    } else {
      message.Task = undefined;
    }
    return message;
  },

  toJSON(message: ArchivedTasksStreamMessage): unknown {
    const obj: any = {};
    message.EOS !== undefined && (obj.EOS = message.EOS);
    message.Task !== undefined &&
      (obj.Task = message.Task ? Task.toJSON(message.Task) : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ArchivedTasksStreamMessage>
  ): ArchivedTasksStreamMessage {
    const message = {
      ...baseArchivedTasksStreamMessage,
    } as ArchivedTasksStreamMessage;
    if (object.EOS !== undefined && object.EOS !== null) {
      message.EOS = object.EOS;
    } else {
      message.EOS = undefined;
    }
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = Task.fromPartial(object.Task);
    } else {
      message.Task = undefined;
    }
    return message;
  },
};

const baseTaskStreamReq: object = { TaskID: "" };

export const TaskStreamReq = {
  encode(
    message: TaskStreamReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.TaskID !== "") {
      writer.uint32(10).string(message.TaskID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTaskStreamReq } as TaskStreamReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TaskStreamReq {
    const message = { ...baseTaskStreamReq } as TaskStreamReq;
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = String(object.TaskID);
    } else {
      message.TaskID = "";
    }
    return message;
  },

  toJSON(message: TaskStreamReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    return obj;
  },

  fromPartial(object: DeepPartial<TaskStreamReq>): TaskStreamReq {
    const message = { ...baseTaskStreamReq } as TaskStreamReq;
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = object.TaskID;
    } else {
      message.TaskID = "";
    }
    return message;
  },
};

const baseTaskEvent: object = { ID: "", TaskID: "", Type: "", TS: 0 };

export const TaskEvent = {
  encode(
    message: TaskEvent,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.TaskID !== "") {
      writer.uint32(18).string(message.TaskID);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    if (message.Actor !== undefined) {
      UserInfo.encode(message.Actor, writer.uint32(34).fork()).ldelim();
    }
    if (message.TS !== 0) {
      writer.uint32(40).int64(message.TS);
    }
    Object.entries(message.Fields).forEach(([key, value]) => {
      TaskEvent_FieldsEntry.encode(
        { key: key as any, value },
        writer.uint32(50).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTaskEvent } as TaskEvent;
    message.Fields = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.TaskID = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        case 4:
          message.Actor = UserInfo.decode(reader, reader.uint32());
          break;
        case 5:
          message.TS = longToNumber(reader.int64() as Long);
          break;
        case 6:
          const entry6 = TaskEvent_FieldsEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.Fields[entry6.key] = entry6.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TaskEvent {
    const message = { ...baseTaskEvent } as TaskEvent;
    message.Fields = {};
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = String(object.TaskID);
    } else {
      message.TaskID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = UserInfo.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.TS !== undefined && object.TS !== null) {
      message.TS = Number(object.TS);
    } else {
      message.TS = 0;
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      Object.entries(object.Fields).forEach(([key, value]) => {
        message.Fields[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: TaskEvent): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.Type !== undefined && (obj.Type = message.Type);
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? UserInfo.toJSON(message.Actor) : undefined);
    message.TS !== undefined && (obj.TS = message.TS);
    obj.Fields = {};
    if (message.Fields) {
      Object.entries(message.Fields).forEach(([k, v]) => {
        obj.Fields[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<TaskEvent>): TaskEvent {
    const message = { ...baseTaskEvent } as TaskEvent;
    message.Fields = {};
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = object.TaskID;
    } else {
      message.TaskID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = UserInfo.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.TS !== undefined && object.TS !== null) {
      message.TS = object.TS;
    } else {
      message.TS = 0;
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      Object.entries(object.Fields).forEach(([key, value]) => {
        if (value !== undefined) {
          message.Fields[key] = String(value);
        }
      });
    }
    return message;
  },
};

const baseTaskEvent_FieldsEntry: object = { key: "", value: "" };

export const TaskEvent_FieldsEntry = {
  encode(
    message: TaskEvent_FieldsEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): TaskEvent_FieldsEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTaskEvent_FieldsEntry } as TaskEvent_FieldsEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TaskEvent_FieldsEntry {
    const message = { ...baseTaskEvent_FieldsEntry } as TaskEvent_FieldsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: TaskEvent_FieldsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<TaskEvent_FieldsEntry>
  ): TaskEvent_FieldsEntry {
    const message = { ...baseTaskEvent_FieldsEntry } as TaskEvent_FieldsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

const baseTaskStreamMessage: object = {};

export const TaskStreamMessage = {
  encode(
    message: TaskStreamMessage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Task !== undefined) {
      Task.encode(message.Task, writer.uint32(10).fork()).ldelim();
    }
    if (message.Event !== undefined) {
      TaskEvent.encode(message.Event, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskStreamMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTaskStreamMessage } as TaskStreamMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Task = Task.decode(reader, reader.uint32());
          break;
        case 2:
          message.Event = TaskEvent.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TaskStreamMessage {
    const message = { ...baseTaskStreamMessage } as TaskStreamMessage;
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = Task.fromJSON(object.Task);
    } else {
      message.Task = undefined;
    }
    if (object.Event !== undefined && object.Event !== null) {
      message.Event = TaskEvent.fromJSON(object.Event);
    } else {
      message.Event = undefined;
    }
    return message;
  },

  toJSON(message: TaskStreamMessage): unknown {
    const obj: any = {};
    message.Task !== undefined &&
      (obj.Task = message.Task ? Task.toJSON(message.Task) : undefined);
    message.Event !== undefined &&
      (obj.Event = message.Event ? TaskEvent.toJSON(message.Event) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<TaskStreamMessage>): TaskStreamMessage {
    const message = { ...baseTaskStreamMessage } as TaskStreamMessage;
    if (object.Task !== undefined && object.Task !== null) {
      message.Task = Task.fromPartial(object.Task);
    } else {
      message.Task = undefined;
    }
    if (object.Event !== undefined && object.Event !== null) {
      message.Event = TaskEvent.fromPartial(object.Event);
    } else {
      message.Event = undefined;
    }
    return message;
  },
};

const baseTaskPutMessageReq: object = { TaskID: "", Message: "" };

export const TaskPutMessageReq = {
  encode(
    message: TaskPutMessageReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.TaskID !== "") {
      writer.uint32(10).string(message.TaskID);
    }
    if (message.Message !== "") {
      writer.uint32(18).string(message.Message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskPutMessageReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTaskPutMessageReq } as TaskPutMessageReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.TaskID = reader.string();
          break;
        case 2:
          message.Message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TaskPutMessageReq {
    const message = { ...baseTaskPutMessageReq } as TaskPutMessageReq;
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = String(object.TaskID);
    } else {
      message.TaskID = "";
    }
    if (object.Message !== undefined && object.Message !== null) {
      message.Message = String(object.Message);
    } else {
      message.Message = "";
    }
    return message;
  },

  toJSON(message: TaskPutMessageReq): unknown {
    const obj: any = {};
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.Message !== undefined && (obj.Message = message.Message);
    return obj;
  },

  fromPartial(object: DeepPartial<TaskPutMessageReq>): TaskPutMessageReq {
    const message = { ...baseTaskPutMessageReq } as TaskPutMessageReq;
    if (object.TaskID !== undefined && object.TaskID !== null) {
      message.TaskID = object.TaskID;
    } else {
      message.TaskID = "";
    }
    if (object.Message !== undefined && object.Message !== null) {
      message.Message = object.Message;
    } else {
      message.Message = "";
    }
    return message;
  },
};

const baseTask: object = {
  ID: "",
  Text: "",
  Description: "",
  Completed: false,
  Confirmed: false,
  Closed: false,
  Parent: "",
  Children: "",
  CreationTime: 0,
  DueTime: 0,
  ModificationTime: 0,
};

export const Task = {
  encode(message: Task, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Author !== undefined) {
      Actor.encode(message.Author, writer.uint32(18).fork()).ldelim();
    }
    if (message.Assignee !== undefined) {
      Actor.encode(message.Assignee, writer.uint32(26).fork()).ldelim();
    }
    if (message.Text !== "") {
      writer.uint32(34).string(message.Text);
    }
    if (message.Description !== "") {
      writer.uint32(42).string(message.Description);
    }
    if (message.Confirmer !== undefined) {
      Actor.encode(message.Confirmer, writer.uint32(50).fork()).ldelim();
    }
    if (message.Document !== undefined) {
      TaskDocument.encode(message.Document, writer.uint32(58).fork()).ldelim();
    }
    if (message.Completed === true) {
      writer.uint32(64).bool(message.Completed);
    }
    if (message.Confirmed === true) {
      writer.uint32(72).bool(message.Confirmed);
    }
    if (message.Closed === true) {
      writer.uint32(80).bool(message.Closed);
    }
    if (message.Parent !== "") {
      writer.uint32(90).string(message.Parent);
    }
    for (const v of message.Children) {
      writer.uint32(98).string(v!);
    }
    if (message.CreationTime !== 0) {
      writer.uint32(104).int64(message.CreationTime);
    }
    if (message.DueTime !== 0) {
      writer.uint32(112).int64(message.DueTime);
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Task {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTask } as Task;
    message.Children = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Author = Actor.decode(reader, reader.uint32());
          break;
        case 3:
          message.Assignee = Actor.decode(reader, reader.uint32());
          break;
        case 4:
          message.Text = reader.string();
          break;
        case 5:
          message.Description = reader.string();
          break;
        case 6:
          message.Confirmer = Actor.decode(reader, reader.uint32());
          break;
        case 7:
          message.Document = TaskDocument.decode(reader, reader.uint32());
          break;
        case 8:
          message.Completed = reader.bool();
          break;
        case 9:
          message.Confirmed = reader.bool();
          break;
        case 10:
          message.Closed = reader.bool();
          break;
        case 11:
          message.Parent = reader.string();
          break;
        case 12:
          message.Children.push(reader.string());
          break;
        case 13:
          message.CreationTime = longToNumber(reader.int64() as Long);
          break;
        case 14:
          message.DueTime = longToNumber(reader.int64() as Long);
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Task {
    const message = { ...baseTask } as Task;
    message.Children = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = Actor.fromJSON(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = Actor.fromJSON(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = String(object.Text);
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = Actor.fromJSON(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = TaskDocument.fromJSON(object.Document);
    } else {
      message.Document = undefined;
    }
    if (object.Completed !== undefined && object.Completed !== null) {
      message.Completed = Boolean(object.Completed);
    } else {
      message.Completed = false;
    }
    if (object.Confirmed !== undefined && object.Confirmed !== null) {
      message.Confirmed = Boolean(object.Confirmed);
    } else {
      message.Confirmed = false;
    }
    if (object.Closed !== undefined && object.Closed !== null) {
      message.Closed = Boolean(object.Closed);
    } else {
      message.Closed = false;
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = String(object.Parent);
    } else {
      message.Parent = "";
    }
    if (object.Children !== undefined && object.Children !== null) {
      for (const e of object.Children) {
        message.Children.push(String(e));
      }
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = Number(object.CreationTime);
    } else {
      message.CreationTime = 0;
    }
    if (object.DueTime !== undefined && object.DueTime !== null) {
      message.DueTime = Number(object.DueTime);
    } else {
      message.DueTime = 0;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: Task): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Author !== undefined &&
      (obj.Author = message.Author ? Actor.toJSON(message.Author) : undefined);
    message.Assignee !== undefined &&
      (obj.Assignee = message.Assignee
        ? Actor.toJSON(message.Assignee)
        : undefined);
    message.Text !== undefined && (obj.Text = message.Text);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Confirmer !== undefined &&
      (obj.Confirmer = message.Confirmer
        ? Actor.toJSON(message.Confirmer)
        : undefined);
    message.Document !== undefined &&
      (obj.Document = message.Document
        ? TaskDocument.toJSON(message.Document)
        : undefined);
    message.Completed !== undefined && (obj.Completed = message.Completed);
    message.Confirmed !== undefined && (obj.Confirmed = message.Confirmed);
    message.Closed !== undefined && (obj.Closed = message.Closed);
    message.Parent !== undefined && (obj.Parent = message.Parent);
    if (message.Children) {
      obj.Children = message.Children.map((e) => e);
    } else {
      obj.Children = [];
    }
    message.CreationTime !== undefined &&
      (obj.CreationTime = message.CreationTime);
    message.DueTime !== undefined && (obj.DueTime = message.DueTime);
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<Task>): Task {
    const message = { ...baseTask } as Task;
    message.Children = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = Actor.fromPartial(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = Actor.fromPartial(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = object.Text;
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = Actor.fromPartial(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = TaskDocument.fromPartial(object.Document);
    } else {
      message.Document = undefined;
    }
    if (object.Completed !== undefined && object.Completed !== null) {
      message.Completed = object.Completed;
    } else {
      message.Completed = false;
    }
    if (object.Confirmed !== undefined && object.Confirmed !== null) {
      message.Confirmed = object.Confirmed;
    } else {
      message.Confirmed = false;
    }
    if (object.Closed !== undefined && object.Closed !== null) {
      message.Closed = object.Closed;
    } else {
      message.Closed = false;
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = object.Parent;
    } else {
      message.Parent = "";
    }
    if (object.Children !== undefined && object.Children !== null) {
      for (const e of object.Children) {
        message.Children.push(e);
      }
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = object.CreationTime;
    } else {
      message.CreationTime = 0;
    }
    if (object.DueTime !== undefined && object.DueTime !== null) {
      message.DueTime = object.DueTime;
    } else {
      message.DueTime = 0;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseTaskDocument: object = {
  ID: "",
  Name: "",
  Type: "",
  CreationTime: 0,
};

export const TaskDocument = {
  encode(
    message: TaskDocument,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    if (message.CreationTime !== 0) {
      writer.uint32(32).int64(message.CreationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskDocument {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTaskDocument } as TaskDocument;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        case 4:
          message.CreationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TaskDocument {
    const message = { ...baseTaskDocument } as TaskDocument;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = Number(object.CreationTime);
    } else {
      message.CreationTime = 0;
    }
    return message;
  },

  toJSON(message: TaskDocument): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined && (obj.Type = message.Type);
    message.CreationTime !== undefined &&
      (obj.CreationTime = message.CreationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<TaskDocument>): TaskDocument {
    const message = { ...baseTaskDocument } as TaskDocument;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = object.CreationTime;
    } else {
      message.CreationTime = 0;
    }
    return message;
  },
};

const baseCreateTaskReq: object = {
  AuthorUnitID: "",
  Text: "",
  Description: "",
  Document: "",
  Parent: "",
  DueTime: 0,
};

export const CreateTaskReq = {
  encode(
    message: CreateTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.AuthorUnitID !== "") {
      writer.uint32(10).string(message.AuthorUnitID);
    }
    if (message.Assignee !== undefined) {
      ActorIDs.encode(message.Assignee, writer.uint32(18).fork()).ldelim();
    }
    if (message.Text !== "") {
      writer.uint32(26).string(message.Text);
    }
    if (message.Description !== "") {
      writer.uint32(34).string(message.Description);
    }
    if (message.Confirmer !== undefined) {
      ActorIDs.encode(message.Confirmer, writer.uint32(42).fork()).ldelim();
    }
    if (message.Document !== "") {
      writer.uint32(50).string(message.Document);
    }
    if (message.Parent !== "") {
      writer.uint32(58).string(message.Parent);
    }
    if (message.DueTime !== 0) {
      writer.uint32(64).int64(message.DueTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateTaskReq } as CreateTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AuthorUnitID = reader.string();
          break;
        case 2:
          message.Assignee = ActorIDs.decode(reader, reader.uint32());
          break;
        case 3:
          message.Text = reader.string();
          break;
        case 4:
          message.Description = reader.string();
          break;
        case 5:
          message.Confirmer = ActorIDs.decode(reader, reader.uint32());
          break;
        case 6:
          message.Document = reader.string();
          break;
        case 7:
          message.Parent = reader.string();
          break;
        case 8:
          message.DueTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateTaskReq {
    const message = { ...baseCreateTaskReq } as CreateTaskReq;
    if (object.AuthorUnitID !== undefined && object.AuthorUnitID !== null) {
      message.AuthorUnitID = String(object.AuthorUnitID);
    } else {
      message.AuthorUnitID = "";
    }
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = ActorIDs.fromJSON(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = String(object.Text);
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = ActorIDs.fromJSON(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = String(object.Document);
    } else {
      message.Document = "";
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = String(object.Parent);
    } else {
      message.Parent = "";
    }
    if (object.DueTime !== undefined && object.DueTime !== null) {
      message.DueTime = Number(object.DueTime);
    } else {
      message.DueTime = 0;
    }
    return message;
  },

  toJSON(message: CreateTaskReq): unknown {
    const obj: any = {};
    message.AuthorUnitID !== undefined &&
      (obj.AuthorUnitID = message.AuthorUnitID);
    message.Assignee !== undefined &&
      (obj.Assignee = message.Assignee
        ? ActorIDs.toJSON(message.Assignee)
        : undefined);
    message.Text !== undefined && (obj.Text = message.Text);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.Confirmer !== undefined &&
      (obj.Confirmer = message.Confirmer
        ? ActorIDs.toJSON(message.Confirmer)
        : undefined);
    message.Document !== undefined && (obj.Document = message.Document);
    message.Parent !== undefined && (obj.Parent = message.Parent);
    message.DueTime !== undefined && (obj.DueTime = message.DueTime);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateTaskReq>): CreateTaskReq {
    const message = { ...baseCreateTaskReq } as CreateTaskReq;
    if (object.AuthorUnitID !== undefined && object.AuthorUnitID !== null) {
      message.AuthorUnitID = object.AuthorUnitID;
    } else {
      message.AuthorUnitID = "";
    }
    if (object.Assignee !== undefined && object.Assignee !== null) {
      message.Assignee = ActorIDs.fromPartial(object.Assignee);
    } else {
      message.Assignee = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = object.Text;
    } else {
      message.Text = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (object.Confirmer !== undefined && object.Confirmer !== null) {
      message.Confirmer = ActorIDs.fromPartial(object.Confirmer);
    } else {
      message.Confirmer = undefined;
    }
    if (object.Document !== undefined && object.Document !== null) {
      message.Document = object.Document;
    } else {
      message.Document = "";
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = object.Parent;
    } else {
      message.Parent = "";
    }
    if (object.DueTime !== undefined && object.DueTime !== null) {
      message.DueTime = object.DueTime;
    } else {
      message.DueTime = 0;
    }
    return message;
  },
};

const baseCompleteTaskReq: object = { ID: "" };

export const CompleteTaskReq = {
  encode(
    message: CompleteTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompleteTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCompleteTaskReq } as CompleteTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompleteTaskReq {
    const message = { ...baseCompleteTaskReq } as CompleteTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: CompleteTaskReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<CompleteTaskReq>): CompleteTaskReq {
    const message = { ...baseCompleteTaskReq } as CompleteTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseRejectTaskReq: object = { ID: "" };

export const RejectTaskReq = {
  encode(
    message: RejectTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RejectTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRejectTaskReq } as RejectTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RejectTaskReq {
    const message = { ...baseRejectTaskReq } as RejectTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RejectTaskReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<RejectTaskReq>): RejectTaskReq {
    const message = { ...baseRejectTaskReq } as RejectTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseConfirmTaskReq: object = { ID: "" };

export const ConfirmTaskReq = {
  encode(
    message: ConfirmTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfirmTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseConfirmTaskReq } as ConfirmTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConfirmTaskReq {
    const message = { ...baseConfirmTaskReq } as ConfirmTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: ConfirmTaskReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<ConfirmTaskReq>): ConfirmTaskReq {
    const message = { ...baseConfirmTaskReq } as ConfirmTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseReopenReq: object = { ID: "" };

export const ReopenReq = {
  encode(
    message: ReopenReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReopenReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReopenReq } as ReopenReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReopenReq {
    const message = { ...baseReopenReq } as ReopenReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: ReopenReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<ReopenReq>): ReopenReq {
    const message = { ...baseReopenReq } as ReopenReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseCloseTaskReq: object = { ID: "" };

export const CloseTaskReq = {
  encode(
    message: CloseTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloseTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCloseTaskReq } as CloseTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CloseTaskReq {
    const message = { ...baseCloseTaskReq } as CloseTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: CloseTaskReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<CloseTaskReq>): CloseTaskReq {
    const message = { ...baseCloseTaskReq } as CloseTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseRemoveTaskReq: object = { ID: "" };

export const RemoveTaskReq = {
  encode(
    message: RemoveTaskReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveTaskReq } as RemoveTaskReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveTaskReq {
    const message = { ...baseRemoveTaskReq } as RemoveTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RemoveTaskReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<RemoveTaskReq>): RemoveTaskReq {
    const message = { ...baseRemoveTaskReq } as RemoveTaskReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseUnit: object = {
  ID: "",
  CenterID: "",
  Parent: "",
  Root: false,
  Name: "",
  Code: "",
  ModificationTime: 0,
};

export const Unit = {
  encode(message: Unit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.CenterID !== "") {
      writer.uint32(18).string(message.CenterID);
    }
    if (message.Parent !== "") {
      writer.uint32(26).string(message.Parent);
    }
    if (message.Root === true) {
      writer.uint32(32).bool(message.Root);
    }
    if (message.Name !== "") {
      writer.uint32(42).string(message.Name);
    }
    if (message.Code !== "") {
      writer.uint32(50).string(message.Code);
    }
    if (message.OneAss !== undefined) {
      UnitOneAss.encode(message.OneAss, writer.uint32(58).fork()).ldelim();
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Unit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUnit } as Unit;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.CenterID = reader.string();
          break;
        case 3:
          message.Parent = reader.string();
          break;
        case 4:
          message.Root = reader.bool();
          break;
        case 5:
          message.Name = reader.string();
          break;
        case 6:
          message.Code = reader.string();
          break;
        case 7:
          message.OneAss = UnitOneAss.decode(reader, reader.uint32());
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Unit {
    const message = { ...baseUnit } as Unit;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = String(object.Parent);
    } else {
      message.Parent = "";
    }
    if (object.Root !== undefined && object.Root !== null) {
      message.Root = Boolean(object.Root);
    } else {
      message.Root = false;
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Code !== undefined && object.Code !== null) {
      message.Code = String(object.Code);
    } else {
      message.Code = "";
    }
    if (object.OneAss !== undefined && object.OneAss !== null) {
      message.OneAss = UnitOneAss.fromJSON(object.OneAss);
    } else {
      message.OneAss = undefined;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: Unit): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.Parent !== undefined && (obj.Parent = message.Parent);
    message.Root !== undefined && (obj.Root = message.Root);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Code !== undefined && (obj.Code = message.Code);
    message.OneAss !== undefined &&
      (obj.OneAss = message.OneAss
        ? UnitOneAss.toJSON(message.OneAss)
        : undefined);
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<Unit>): Unit {
    const message = { ...baseUnit } as Unit;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = object.Parent;
    } else {
      message.Parent = "";
    }
    if (object.Root !== undefined && object.Root !== null) {
      message.Root = object.Root;
    } else {
      message.Root = false;
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Code !== undefined && object.Code !== null) {
      message.Code = object.Code;
    } else {
      message.Code = "";
    }
    if (object.OneAss !== undefined && object.OneAss !== null) {
      message.OneAss = UnitOneAss.fromPartial(object.OneAss);
    } else {
      message.OneAss = undefined;
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseUnitOneAss: object = { ClientIDs: "" };

export const UnitOneAss = {
  encode(
    message: UnitOneAss,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.ClientIDs) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitOneAss {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUnitOneAss } as UnitOneAss;
    message.ClientIDs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ClientIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UnitOneAss {
    const message = { ...baseUnitOneAss } as UnitOneAss;
    message.ClientIDs = [];
    if (object.ClientIDs !== undefined && object.ClientIDs !== null) {
      for (const e of object.ClientIDs) {
        message.ClientIDs.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: UnitOneAss): unknown {
    const obj: any = {};
    if (message.ClientIDs) {
      obj.ClientIDs = message.ClientIDs.map((e) => e);
    } else {
      obj.ClientIDs = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<UnitOneAss>): UnitOneAss {
    const message = { ...baseUnitOneAss } as UnitOneAss;
    message.ClientIDs = [];
    if (object.ClientIDs !== undefined && object.ClientIDs !== null) {
      for (const e of object.ClientIDs) {
        message.ClientIDs.push(e);
      }
    }
    return message;
  },
};

const baseCreateUnitReq: object = { Name: "", Parent: "" };

export const CreateUnitReq = {
  encode(
    message: CreateUnitReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Name !== "") {
      writer.uint32(10).string(message.Name);
    }
    if (message.Parent !== "") {
      writer.uint32(18).string(message.Parent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUnitReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateUnitReq } as CreateUnitReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Name = reader.string();
          break;
        case 2:
          message.Parent = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateUnitReq {
    const message = { ...baseCreateUnitReq } as CreateUnitReq;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = String(object.Parent);
    } else {
      message.Parent = "";
    }
    return message;
  },

  toJSON(message: CreateUnitReq): unknown {
    const obj: any = {};
    message.Name !== undefined && (obj.Name = message.Name);
    message.Parent !== undefined && (obj.Parent = message.Parent);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateUnitReq>): CreateUnitReq {
    const message = { ...baseCreateUnitReq } as CreateUnitReq;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = object.Parent;
    } else {
      message.Parent = "";
    }
    return message;
  },
};

const baseRemoveUnitReq: object = { ID: "" };

export const RemoveUnitReq = {
  encode(
    message: RemoveUnitReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveUnitReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveUnitReq } as RemoveUnitReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveUnitReq {
    const message = { ...baseRemoveUnitReq } as RemoveUnitReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RemoveUnitReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<RemoveUnitReq>): RemoveUnitReq {
    const message = { ...baseRemoveUnitReq } as RemoveUnitReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseGetTosserReq: object = { UnitID: "" };

export const GetTosserReq = {
  encode(
    message: GetTosserReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.UnitID !== "") {
      writer.uint32(10).string(message.UnitID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTosserReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetTosserReq } as GetTosserReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UnitID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetTosserReq {
    const message = { ...baseGetTosserReq } as GetTosserReq;
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = String(object.UnitID);
    } else {
      message.UnitID = "";
    }
    return message;
  },

  toJSON(message: GetTosserReq): unknown {
    const obj: any = {};
    message.UnitID !== undefined && (obj.UnitID = message.UnitID);
    return obj;
  },

  fromPartial(object: DeepPartial<GetTosserReq>): GetTosserReq {
    const message = { ...baseGetTosserReq } as GetTosserReq;
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = object.UnitID;
    } else {
      message.UnitID = "";
    }
    return message;
  },
};

const baseUnitTosser: object = {};

export const UnitTosser = {
  encode(
    message: UnitTosser,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Rules) {
      TosserRule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitTosser {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUnitTosser } as UnitTosser;
    message.Rules = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Rules.push(TosserRule.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UnitTosser {
    const message = { ...baseUnitTosser } as UnitTosser;
    message.Rules = [];
    if (object.Rules !== undefined && object.Rules !== null) {
      for (const e of object.Rules) {
        message.Rules.push(TosserRule.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: UnitTosser): unknown {
    const obj: any = {};
    if (message.Rules) {
      obj.Rules = message.Rules.map((e) =>
        e ? TosserRule.toJSON(e) : undefined
      );
    } else {
      obj.Rules = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<UnitTosser>): UnitTosser {
    const message = { ...baseUnitTosser } as UnitTosser;
    message.Rules = [];
    if (object.Rules !== undefined && object.Rules !== null) {
      for (const e of object.Rules) {
        message.Rules.push(TosserRule.fromPartial(e));
      }
    }
    return message;
  },
};

const baseTosserRule: object = { DocumentType: "", AutoAccept: false };

export const TosserRule = {
  encode(
    message: TosserRule,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DocumentType !== "") {
      writer.uint32(18).string(message.DocumentType);
    }
    for (const v of message.Terms) {
      TosserRuleTerm.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.Actor !== undefined) {
      ActorIDs.encode(message.Actor, writer.uint32(34).fork()).ldelim();
    }
    if (message.AutoAccept === true) {
      writer.uint32(40).bool(message.AutoAccept);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TosserRule {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTosserRule } as TosserRule;
    message.Terms = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.DocumentType = reader.string();
          break;
        case 3:
          message.Terms.push(TosserRuleTerm.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Actor = ActorIDs.decode(reader, reader.uint32());
          break;
        case 5:
          message.AutoAccept = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TosserRule {
    const message = { ...baseTosserRule } as TosserRule;
    message.Terms = [];
    if (object.DocumentType !== undefined && object.DocumentType !== null) {
      message.DocumentType = String(object.DocumentType);
    } else {
      message.DocumentType = "";
    }
    if (object.Terms !== undefined && object.Terms !== null) {
      for (const e of object.Terms) {
        message.Terms.push(TosserRuleTerm.fromJSON(e));
      }
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = ActorIDs.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.AutoAccept !== undefined && object.AutoAccept !== null) {
      message.AutoAccept = Boolean(object.AutoAccept);
    } else {
      message.AutoAccept = false;
    }
    return message;
  },

  toJSON(message: TosserRule): unknown {
    const obj: any = {};
    message.DocumentType !== undefined &&
      (obj.DocumentType = message.DocumentType);
    if (message.Terms) {
      obj.Terms = message.Terms.map((e) =>
        e ? TosserRuleTerm.toJSON(e) : undefined
      );
    } else {
      obj.Terms = [];
    }
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? ActorIDs.toJSON(message.Actor) : undefined);
    message.AutoAccept !== undefined && (obj.AutoAccept = message.AutoAccept);
    return obj;
  },

  fromPartial(object: DeepPartial<TosserRule>): TosserRule {
    const message = { ...baseTosserRule } as TosserRule;
    message.Terms = [];
    if (object.DocumentType !== undefined && object.DocumentType !== null) {
      message.DocumentType = object.DocumentType;
    } else {
      message.DocumentType = "";
    }
    if (object.Terms !== undefined && object.Terms !== null) {
      for (const e of object.Terms) {
        message.Terms.push(TosserRuleTerm.fromPartial(e));
      }
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = ActorIDs.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.AutoAccept !== undefined && object.AutoAccept !== null) {
      message.AutoAccept = object.AutoAccept;
    } else {
      message.AutoAccept = false;
    }
    return message;
  },
};

const baseTosserRuleTerm: object = { Requisite: "", Operator: "", Value: "" };

export const TosserRuleTerm = {
  encode(
    message: TosserRuleTerm,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Requisite !== "") {
      writer.uint32(10).string(message.Requisite);
    }
    if (message.Operator !== "") {
      writer.uint32(18).string(message.Operator);
    }
    if (message.Value !== "") {
      writer.uint32(26).string(message.Value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TosserRuleTerm {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTosserRuleTerm } as TosserRuleTerm;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Requisite = reader.string();
          break;
        case 2:
          message.Operator = reader.string();
          break;
        case 3:
          message.Value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TosserRuleTerm {
    const message = { ...baseTosserRuleTerm } as TosserRuleTerm;
    if (object.Requisite !== undefined && object.Requisite !== null) {
      message.Requisite = String(object.Requisite);
    } else {
      message.Requisite = "";
    }
    if (object.Operator !== undefined && object.Operator !== null) {
      message.Operator = String(object.Operator);
    } else {
      message.Operator = "";
    }
    if (object.Value !== undefined && object.Value !== null) {
      message.Value = String(object.Value);
    } else {
      message.Value = "";
    }
    return message;
  },

  toJSON(message: TosserRuleTerm): unknown {
    const obj: any = {};
    message.Requisite !== undefined && (obj.Requisite = message.Requisite);
    message.Operator !== undefined && (obj.Operator = message.Operator);
    message.Value !== undefined && (obj.Value = message.Value);
    return obj;
  },

  fromPartial(object: DeepPartial<TosserRuleTerm>): TosserRuleTerm {
    const message = { ...baseTosserRuleTerm } as TosserRuleTerm;
    if (object.Requisite !== undefined && object.Requisite !== null) {
      message.Requisite = object.Requisite;
    } else {
      message.Requisite = "";
    }
    if (object.Operator !== undefined && object.Operator !== null) {
      message.Operator = object.Operator;
    } else {
      message.Operator = "";
    }
    if (object.Value !== undefined && object.Value !== null) {
      message.Value = object.Value;
    } else {
      message.Value = "";
    }
    return message;
  },
};

const baseSetTosserReq: object = { UnitID: "", DocTypeID: "" };

export const SetTosserReq = {
  encode(
    message: SetTosserReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.UnitID !== "") {
      writer.uint32(10).string(message.UnitID);
    }
    if (message.DocTypeID !== "") {
      writer.uint32(18).string(message.DocTypeID);
    }
    for (const v of message.Rules) {
      TosserRule.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetTosserReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetTosserReq } as SetTosserReq;
    message.Rules = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UnitID = reader.string();
          break;
        case 2:
          message.DocTypeID = reader.string();
          break;
        case 3:
          message.Rules.push(TosserRule.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetTosserReq {
    const message = { ...baseSetTosserReq } as SetTosserReq;
    message.Rules = [];
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = String(object.UnitID);
    } else {
      message.UnitID = "";
    }
    if (object.DocTypeID !== undefined && object.DocTypeID !== null) {
      message.DocTypeID = String(object.DocTypeID);
    } else {
      message.DocTypeID = "";
    }
    if (object.Rules !== undefined && object.Rules !== null) {
      for (const e of object.Rules) {
        message.Rules.push(TosserRule.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: SetTosserReq): unknown {
    const obj: any = {};
    message.UnitID !== undefined && (obj.UnitID = message.UnitID);
    message.DocTypeID !== undefined && (obj.DocTypeID = message.DocTypeID);
    if (message.Rules) {
      obj.Rules = message.Rules.map((e) =>
        e ? TosserRule.toJSON(e) : undefined
      );
    } else {
      obj.Rules = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SetTosserReq>): SetTosserReq {
    const message = { ...baseSetTosserReq } as SetTosserReq;
    message.Rules = [];
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = object.UnitID;
    } else {
      message.UnitID = "";
    }
    if (object.DocTypeID !== undefined && object.DocTypeID !== null) {
      message.DocTypeID = object.DocTypeID;
    } else {
      message.DocTypeID = "";
    }
    if (object.Rules !== undefined && object.Rules !== null) {
      for (const e of object.Rules) {
        message.Rules.push(TosserRule.fromPartial(e));
      }
    }
    return message;
  },
};

const baseFamiliarizationStreamReq: object = { ID: "" };

export const FamiliarizationStreamReq = {
  encode(
    message: FamiliarizationStreamReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): FamiliarizationStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseFamiliarizationStreamReq,
    } as FamiliarizationStreamReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FamiliarizationStreamReq {
    const message = {
      ...baseFamiliarizationStreamReq,
    } as FamiliarizationStreamReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: FamiliarizationStreamReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<FamiliarizationStreamReq>
  ): FamiliarizationStreamReq {
    const message = {
      ...baseFamiliarizationStreamReq,
    } as FamiliarizationStreamReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseArchivedListFamiliarizationStreamReq: object = {};

export const ArchivedListFamiliarizationStreamReq = {
  encode(
    _: ArchivedListFamiliarizationStreamReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ArchivedListFamiliarizationStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseArchivedListFamiliarizationStreamReq,
    } as ArchivedListFamiliarizationStreamReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ArchivedListFamiliarizationStreamReq {
    const message = {
      ...baseArchivedListFamiliarizationStreamReq,
    } as ArchivedListFamiliarizationStreamReq;
    return message;
  },

  toJSON(_: ArchivedListFamiliarizationStreamReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<ArchivedListFamiliarizationStreamReq>
  ): ArchivedListFamiliarizationStreamReq {
    const message = {
      ...baseArchivedListFamiliarizationStreamReq,
    } as ArchivedListFamiliarizationStreamReq;
    return message;
  },
};

const baseArchivedFamiliarizationStreamMessage: object = {};

export const ArchivedFamiliarizationStreamMessage = {
  encode(
    message: ArchivedFamiliarizationStreamMessage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.EOS !== undefined) {
      writer.uint32(10).string(message.EOS);
    }
    if (message.Familiarization !== undefined) {
      Familiarization.encode(
        message.Familiarization,
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ArchivedFamiliarizationStreamMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseArchivedFamiliarizationStreamMessage,
    } as ArchivedFamiliarizationStreamMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.EOS = reader.string();
          break;
        case 2:
          message.Familiarization = Familiarization.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ArchivedFamiliarizationStreamMessage {
    const message = {
      ...baseArchivedFamiliarizationStreamMessage,
    } as ArchivedFamiliarizationStreamMessage;
    if (object.EOS !== undefined && object.EOS !== null) {
      message.EOS = String(object.EOS);
    } else {
      message.EOS = undefined;
    }
    if (
      object.Familiarization !== undefined &&
      object.Familiarization !== null
    ) {
      message.Familiarization = Familiarization.fromJSON(
        object.Familiarization
      );
    } else {
      message.Familiarization = undefined;
    }
    return message;
  },

  toJSON(message: ArchivedFamiliarizationStreamMessage): unknown {
    const obj: any = {};
    message.EOS !== undefined && (obj.EOS = message.EOS);
    message.Familiarization !== undefined &&
      (obj.Familiarization = message.Familiarization
        ? Familiarization.toJSON(message.Familiarization)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ArchivedFamiliarizationStreamMessage>
  ): ArchivedFamiliarizationStreamMessage {
    const message = {
      ...baseArchivedFamiliarizationStreamMessage,
    } as ArchivedFamiliarizationStreamMessage;
    if (object.EOS !== undefined && object.EOS !== null) {
      message.EOS = object.EOS;
    } else {
      message.EOS = undefined;
    }
    if (
      object.Familiarization !== undefined &&
      object.Familiarization !== null
    ) {
      message.Familiarization = Familiarization.fromPartial(
        object.Familiarization
      );
    } else {
      message.Familiarization = undefined;
    }
    return message;
  },
};

const baseFamiliarizationStreamMessage: object = {};

export const FamiliarizationStreamMessage = {
  encode(
    message: FamiliarizationStreamMessage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Dummy !== undefined) {
      Dummy.encode(message.Dummy, writer.uint32(10).fork()).ldelim();
    }
    if (message.Familiarization !== undefined) {
      Familiarization.encode(
        message.Familiarization,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.Event !== undefined) {
      FamiliarizationEvent.encode(
        message.Event,
        writer.uint32(26).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): FamiliarizationStreamMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseFamiliarizationStreamMessage,
    } as FamiliarizationStreamMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Dummy = Dummy.decode(reader, reader.uint32());
          break;
        case 2:
          message.Familiarization = Familiarization.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.Event = FamiliarizationEvent.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FamiliarizationStreamMessage {
    const message = {
      ...baseFamiliarizationStreamMessage,
    } as FamiliarizationStreamMessage;
    if (object.Dummy !== undefined && object.Dummy !== null) {
      message.Dummy = Dummy.fromJSON(object.Dummy);
    } else {
      message.Dummy = undefined;
    }
    if (
      object.Familiarization !== undefined &&
      object.Familiarization !== null
    ) {
      message.Familiarization = Familiarization.fromJSON(
        object.Familiarization
      );
    } else {
      message.Familiarization = undefined;
    }
    if (object.Event !== undefined && object.Event !== null) {
      message.Event = FamiliarizationEvent.fromJSON(object.Event);
    } else {
      message.Event = undefined;
    }
    return message;
  },

  toJSON(message: FamiliarizationStreamMessage): unknown {
    const obj: any = {};
    message.Dummy !== undefined &&
      (obj.Dummy = message.Dummy ? Dummy.toJSON(message.Dummy) : undefined);
    message.Familiarization !== undefined &&
      (obj.Familiarization = message.Familiarization
        ? Familiarization.toJSON(message.Familiarization)
        : undefined);
    message.Event !== undefined &&
      (obj.Event = message.Event
        ? FamiliarizationEvent.toJSON(message.Event)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<FamiliarizationStreamMessage>
  ): FamiliarizationStreamMessage {
    const message = {
      ...baseFamiliarizationStreamMessage,
    } as FamiliarizationStreamMessage;
    if (object.Dummy !== undefined && object.Dummy !== null) {
      message.Dummy = Dummy.fromPartial(object.Dummy);
    } else {
      message.Dummy = undefined;
    }
    if (
      object.Familiarization !== undefined &&
      object.Familiarization !== null
    ) {
      message.Familiarization = Familiarization.fromPartial(
        object.Familiarization
      );
    } else {
      message.Familiarization = undefined;
    }
    if (object.Event !== undefined && object.Event !== null) {
      message.Event = FamiliarizationEvent.fromPartial(object.Event);
    } else {
      message.Event = undefined;
    }
    return message;
  },
};

const baseFamiliarizationEvent: object = {
  ID: "",
  FamiliarizationID: "",
  Type: "",
  TS: 0,
};

export const FamiliarizationEvent = {
  encode(
    message: FamiliarizationEvent,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.FamiliarizationID !== "") {
      writer.uint32(18).string(message.FamiliarizationID);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    if (message.Author !== undefined) {
      UserInfo.encode(message.Author, writer.uint32(34).fork()).ldelim();
    }
    if (message.TS !== 0) {
      writer.uint32(40).int64(message.TS);
    }
    if (message.StatusTransition !== undefined) {
      StatusTransition.encode(
        message.StatusTransition,
        writer.uint32(50).fork()
      ).ldelim();
    }
    if (message.Familiarize !== undefined) {
      Familiarize.encode(
        message.Familiarize,
        writer.uint32(58).fork()
      ).ldelim();
    }
    if (message.NewMembers !== undefined) {
      NewMembers.encode(message.NewMembers, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): FamiliarizationEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFamiliarizationEvent } as FamiliarizationEvent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.FamiliarizationID = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        case 4:
          message.Author = UserInfo.decode(reader, reader.uint32());
          break;
        case 5:
          message.TS = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.StatusTransition = StatusTransition.decode(
            reader,
            reader.uint32()
          );
          break;
        case 7:
          message.Familiarize = Familiarize.decode(reader, reader.uint32());
          break;
        case 8:
          message.NewMembers = NewMembers.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FamiliarizationEvent {
    const message = { ...baseFamiliarizationEvent } as FamiliarizationEvent;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (
      object.FamiliarizationID !== undefined &&
      object.FamiliarizationID !== null
    ) {
      message.FamiliarizationID = String(object.FamiliarizationID);
    } else {
      message.FamiliarizationID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = UserInfo.fromJSON(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.TS !== undefined && object.TS !== null) {
      message.TS = Number(object.TS);
    } else {
      message.TS = 0;
    }
    if (
      object.StatusTransition !== undefined &&
      object.StatusTransition !== null
    ) {
      message.StatusTransition = StatusTransition.fromJSON(
        object.StatusTransition
      );
    } else {
      message.StatusTransition = undefined;
    }
    if (object.Familiarize !== undefined && object.Familiarize !== null) {
      message.Familiarize = Familiarize.fromJSON(object.Familiarize);
    } else {
      message.Familiarize = undefined;
    }
    if (object.NewMembers !== undefined && object.NewMembers !== null) {
      message.NewMembers = NewMembers.fromJSON(object.NewMembers);
    } else {
      message.NewMembers = undefined;
    }
    return message;
  },

  toJSON(message: FamiliarizationEvent): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.FamiliarizationID !== undefined &&
      (obj.FamiliarizationID = message.FamiliarizationID);
    message.Type !== undefined && (obj.Type = message.Type);
    message.Author !== undefined &&
      (obj.Author = message.Author
        ? UserInfo.toJSON(message.Author)
        : undefined);
    message.TS !== undefined && (obj.TS = message.TS);
    message.StatusTransition !== undefined &&
      (obj.StatusTransition = message.StatusTransition
        ? StatusTransition.toJSON(message.StatusTransition)
        : undefined);
    message.Familiarize !== undefined &&
      (obj.Familiarize = message.Familiarize
        ? Familiarize.toJSON(message.Familiarize)
        : undefined);
    message.NewMembers !== undefined &&
      (obj.NewMembers = message.NewMembers
        ? NewMembers.toJSON(message.NewMembers)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<FamiliarizationEvent>): FamiliarizationEvent {
    const message = { ...baseFamiliarizationEvent } as FamiliarizationEvent;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (
      object.FamiliarizationID !== undefined &&
      object.FamiliarizationID !== null
    ) {
      message.FamiliarizationID = object.FamiliarizationID;
    } else {
      message.FamiliarizationID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.Author !== undefined && object.Author !== null) {
      message.Author = UserInfo.fromPartial(object.Author);
    } else {
      message.Author = undefined;
    }
    if (object.TS !== undefined && object.TS !== null) {
      message.TS = object.TS;
    } else {
      message.TS = 0;
    }
    if (
      object.StatusTransition !== undefined &&
      object.StatusTransition !== null
    ) {
      message.StatusTransition = StatusTransition.fromPartial(
        object.StatusTransition
      );
    } else {
      message.StatusTransition = undefined;
    }
    if (object.Familiarize !== undefined && object.Familiarize !== null) {
      message.Familiarize = Familiarize.fromPartial(object.Familiarize);
    } else {
      message.Familiarize = undefined;
    }
    if (object.NewMembers !== undefined && object.NewMembers !== null) {
      message.NewMembers = NewMembers.fromPartial(object.NewMembers);
    } else {
      message.NewMembers = undefined;
    }
    return message;
  },
};

const baseFamiliarize: object = {};

export const Familiarize = {
  encode(
    message: Familiarize,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Actor !== undefined) {
      UserInfo.encode(message.Actor, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Familiarize {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFamiliarize } as Familiarize;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Actor = UserInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Familiarize {
    const message = { ...baseFamiliarize } as Familiarize;
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = UserInfo.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    return message;
  },

  toJSON(message: Familiarize): unknown {
    const obj: any = {};
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? UserInfo.toJSON(message.Actor) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Familiarize>): Familiarize {
    const message = { ...baseFamiliarize } as Familiarize;
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = UserInfo.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    return message;
  },
};

const baseNewMembers: object = {};

export const NewMembers = {
  encode(
    message: NewMembers,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.Users) {
      UserInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Units) {
      UnitInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewMembers {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNewMembers } as NewMembers;
    message.Users = [];
    message.Units = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Users.push(UserInfo.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Units.push(UnitInfo.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NewMembers {
    const message = { ...baseNewMembers } as NewMembers;
    message.Users = [];
    message.Units = [];
    if (object.Users !== undefined && object.Users !== null) {
      for (const e of object.Users) {
        message.Users.push(UserInfo.fromJSON(e));
      }
    }
    if (object.Units !== undefined && object.Units !== null) {
      for (const e of object.Units) {
        message.Units.push(UnitInfo.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: NewMembers): unknown {
    const obj: any = {};
    if (message.Users) {
      obj.Users = message.Users.map((e) =>
        e ? UserInfo.toJSON(e) : undefined
      );
    } else {
      obj.Users = [];
    }
    if (message.Units) {
      obj.Units = message.Units.map((e) =>
        e ? UnitInfo.toJSON(e) : undefined
      );
    } else {
      obj.Units = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<NewMembers>): NewMembers {
    const message = { ...baseNewMembers } as NewMembers;
    message.Users = [];
    message.Units = [];
    if (object.Users !== undefined && object.Users !== null) {
      for (const e of object.Users) {
        message.Users.push(UserInfo.fromPartial(e));
      }
    }
    if (object.Units !== undefined && object.Units !== null) {
      for (const e of object.Units) {
        message.Units.push(UnitInfo.fromPartial(e));
      }
    }
    return message;
  },
};

const baseFamiliarization: object = {
  ID: "",
  CenterID: "",
  Title: "",
  Description: "",
  CreationTime: 0,
  ForceNewMembers: false,
  Status: "",
  UnitIDs: "",
  Until: 0,
  ModificationTime: 0,
};

export const Familiarization = {
  encode(
    message: Familiarization,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.CenterID !== "") {
      writer.uint32(18).string(message.CenterID);
    }
    if (message.Actor !== undefined) {
      Actor.encode(message.Actor, writer.uint32(26).fork()).ldelim();
    }
    if (message.Title !== "") {
      writer.uint32(34).string(message.Title);
    }
    if (message.Description !== "") {
      writer.uint32(42).string(message.Description);
    }
    if (message.CreationTime !== 0) {
      writer.uint32(48).int64(message.CreationTime);
    }
    if (message.ForceNewMembers === true) {
      writer.uint32(56).bool(message.ForceNewMembers);
    }
    for (const v of message.Files) {
      File.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.Status !== "") {
      writer.uint32(74).string(message.Status);
    }
    for (const v of message.UnitIDs) {
      writer.uint32(82).string(v!);
    }
    if (message.Until !== 0) {
      writer.uint32(88).int64(message.Until);
    }
    for (const v of message.Documents) {
      FamiliarizationDocument.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.FamiliarizationLog) {
      FamiliarizationLog.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Familiarization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFamiliarization } as Familiarization;
    message.Files = [];
    message.UnitIDs = [];
    message.Documents = [];
    message.FamiliarizationLog = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.CenterID = reader.string();
          break;
        case 3:
          message.Actor = Actor.decode(reader, reader.uint32());
          break;
        case 4:
          message.Title = reader.string();
          break;
        case 5:
          message.Description = reader.string();
          break;
        case 6:
          message.CreationTime = longToNumber(reader.int64() as Long);
          break;
        case 7:
          message.ForceNewMembers = reader.bool();
          break;
        case 8:
          message.Files.push(File.decode(reader, reader.uint32()));
          break;
        case 9:
          message.Status = reader.string();
          break;
        case 10:
          message.UnitIDs.push(reader.string());
          break;
        case 11:
          message.Until = longToNumber(reader.int64() as Long);
          break;
        case 12:
          message.Documents.push(
            FamiliarizationDocument.decode(reader, reader.uint32())
          );
          break;
        case 13:
          message.FamiliarizationLog.push(
            FamiliarizationLog.decode(reader, reader.uint32())
          );
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Familiarization {
    const message = { ...baseFamiliarization } as Familiarization;
    message.Files = [];
    message.UnitIDs = [];
    message.Documents = [];
    message.FamiliarizationLog = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromJSON(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.Title !== undefined && object.Title !== null) {
      message.Title = String(object.Title);
    } else {
      message.Title = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = Number(object.CreationTime);
    } else {
      message.CreationTime = 0;
    }
    if (
      object.ForceNewMembers !== undefined &&
      object.ForceNewMembers !== null
    ) {
      message.ForceNewMembers = Boolean(object.ForceNewMembers);
    } else {
      message.ForceNewMembers = false;
    }
    if (object.Files !== undefined && object.Files !== null) {
      for (const e of object.Files) {
        message.Files.push(File.fromJSON(e));
      }
    }
    if (object.Status !== undefined && object.Status !== null) {
      message.Status = String(object.Status);
    } else {
      message.Status = "";
    }
    if (object.UnitIDs !== undefined && object.UnitIDs !== null) {
      for (const e of object.UnitIDs) {
        message.UnitIDs.push(String(e));
      }
    }
    if (object.Until !== undefined && object.Until !== null) {
      message.Until = Number(object.Until);
    } else {
      message.Until = 0;
    }
    if (object.Documents !== undefined && object.Documents !== null) {
      for (const e of object.Documents) {
        message.Documents.push(FamiliarizationDocument.fromJSON(e));
      }
    }
    if (
      object.FamiliarizationLog !== undefined &&
      object.FamiliarizationLog !== null
    ) {
      for (const e of object.FamiliarizationLog) {
        message.FamiliarizationLog.push(FamiliarizationLog.fromJSON(e));
      }
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: Familiarization): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.Actor !== undefined &&
      (obj.Actor = message.Actor ? Actor.toJSON(message.Actor) : undefined);
    message.Title !== undefined && (obj.Title = message.Title);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.CreationTime !== undefined &&
      (obj.CreationTime = message.CreationTime);
    message.ForceNewMembers !== undefined &&
      (obj.ForceNewMembers = message.ForceNewMembers);
    if (message.Files) {
      obj.Files = message.Files.map((e) => (e ? File.toJSON(e) : undefined));
    } else {
      obj.Files = [];
    }
    message.Status !== undefined && (obj.Status = message.Status);
    if (message.UnitIDs) {
      obj.UnitIDs = message.UnitIDs.map((e) => e);
    } else {
      obj.UnitIDs = [];
    }
    message.Until !== undefined && (obj.Until = message.Until);
    if (message.Documents) {
      obj.Documents = message.Documents.map((e) =>
        e ? FamiliarizationDocument.toJSON(e) : undefined
      );
    } else {
      obj.Documents = [];
    }
    if (message.FamiliarizationLog) {
      obj.FamiliarizationLog = message.FamiliarizationLog.map((e) =>
        e ? FamiliarizationLog.toJSON(e) : undefined
      );
    } else {
      obj.FamiliarizationLog = [];
    }
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<Familiarization>): Familiarization {
    const message = { ...baseFamiliarization } as Familiarization;
    message.Files = [];
    message.UnitIDs = [];
    message.Documents = [];
    message.FamiliarizationLog = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Actor !== undefined && object.Actor !== null) {
      message.Actor = Actor.fromPartial(object.Actor);
    } else {
      message.Actor = undefined;
    }
    if (object.Title !== undefined && object.Title !== null) {
      message.Title = object.Title;
    } else {
      message.Title = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (object.CreationTime !== undefined && object.CreationTime !== null) {
      message.CreationTime = object.CreationTime;
    } else {
      message.CreationTime = 0;
    }
    if (
      object.ForceNewMembers !== undefined &&
      object.ForceNewMembers !== null
    ) {
      message.ForceNewMembers = object.ForceNewMembers;
    } else {
      message.ForceNewMembers = false;
    }
    if (object.Files !== undefined && object.Files !== null) {
      for (const e of object.Files) {
        message.Files.push(File.fromPartial(e));
      }
    }
    if (object.Status !== undefined && object.Status !== null) {
      message.Status = object.Status;
    } else {
      message.Status = "";
    }
    if (object.UnitIDs !== undefined && object.UnitIDs !== null) {
      for (const e of object.UnitIDs) {
        message.UnitIDs.push(e);
      }
    }
    if (object.Until !== undefined && object.Until !== null) {
      message.Until = object.Until;
    } else {
      message.Until = 0;
    }
    if (object.Documents !== undefined && object.Documents !== null) {
      for (const e of object.Documents) {
        message.Documents.push(FamiliarizationDocument.fromPartial(e));
      }
    }
    if (
      object.FamiliarizationLog !== undefined &&
      object.FamiliarizationLog !== null
    ) {
      for (const e of object.FamiliarizationLog) {
        message.FamiliarizationLog.push(FamiliarizationLog.fromPartial(e));
      }
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseFamiliarizationDocument: object = { ID: "", Name: "", Type: "" };

export const FamiliarizationDocument = {
  encode(
    message: FamiliarizationDocument,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Type !== "") {
      writer.uint32(26).string(message.Type);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): FamiliarizationDocument {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseFamiliarizationDocument,
    } as FamiliarizationDocument;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Type = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FamiliarizationDocument {
    const message = {
      ...baseFamiliarizationDocument,
    } as FamiliarizationDocument;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    return message;
  },

  toJSON(message: FamiliarizationDocument): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined && (obj.Type = message.Type);
    return obj;
  },

  fromPartial(
    object: DeepPartial<FamiliarizationDocument>
  ): FamiliarizationDocument {
    const message = {
      ...baseFamiliarizationDocument,
    } as FamiliarizationDocument;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    return message;
  },
};

const baseActiveFamiliarizationReq: object = { ID: "" };

export const ActiveFamiliarizationReq = {
  encode(
    message: ActiveFamiliarizationReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ActiveFamiliarizationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseActiveFamiliarizationReq,
    } as ActiveFamiliarizationReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActiveFamiliarizationReq {
    const message = {
      ...baseActiveFamiliarizationReq,
    } as ActiveFamiliarizationReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: ActiveFamiliarizationReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ActiveFamiliarizationReq>
  ): ActiveFamiliarizationReq {
    const message = {
      ...baseActiveFamiliarizationReq,
    } as ActiveFamiliarizationReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseDraftFamiliarizationReq: object = { ID: "" };

export const DraftFamiliarizationReq = {
  encode(
    message: DraftFamiliarizationReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DraftFamiliarizationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseDraftFamiliarizationReq,
    } as DraftFamiliarizationReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DraftFamiliarizationReq {
    const message = {
      ...baseDraftFamiliarizationReq,
    } as DraftFamiliarizationReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: DraftFamiliarizationReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<DraftFamiliarizationReq>
  ): DraftFamiliarizationReq {
    const message = {
      ...baseDraftFamiliarizationReq,
    } as DraftFamiliarizationReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseArchiveFamiliarizationReq: object = { ID: "" };

export const ArchiveFamiliarizationReq = {
  encode(
    message: ArchiveFamiliarizationReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ArchiveFamiliarizationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseArchiveFamiliarizationReq,
    } as ArchiveFamiliarizationReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ArchiveFamiliarizationReq {
    const message = {
      ...baseArchiveFamiliarizationReq,
    } as ArchiveFamiliarizationReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: ArchiveFamiliarizationReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ArchiveFamiliarizationReq>
  ): ArchiveFamiliarizationReq {
    const message = {
      ...baseArchiveFamiliarizationReq,
    } as ArchiveFamiliarizationReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseCreateFamiliarizationReq: object = { UnitID: "", Title: "" };

export const CreateFamiliarizationReq = {
  encode(
    message: CreateFamiliarizationReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.UnitID !== "") {
      writer.uint32(10).string(message.UnitID);
    }
    if (message.Title !== "") {
      writer.uint32(18).string(message.Title);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateFamiliarizationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseCreateFamiliarizationReq,
    } as CreateFamiliarizationReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UnitID = reader.string();
          break;
        case 2:
          message.Title = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateFamiliarizationReq {
    const message = {
      ...baseCreateFamiliarizationReq,
    } as CreateFamiliarizationReq;
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = String(object.UnitID);
    } else {
      message.UnitID = "";
    }
    if (object.Title !== undefined && object.Title !== null) {
      message.Title = String(object.Title);
    } else {
      message.Title = "";
    }
    return message;
  },

  toJSON(message: CreateFamiliarizationReq): unknown {
    const obj: any = {};
    message.UnitID !== undefined && (obj.UnitID = message.UnitID);
    message.Title !== undefined && (obj.Title = message.Title);
    return obj;
  },

  fromPartial(
    object: DeepPartial<CreateFamiliarizationReq>
  ): CreateFamiliarizationReq {
    const message = {
      ...baseCreateFamiliarizationReq,
    } as CreateFamiliarizationReq;
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = object.UnitID;
    } else {
      message.UnitID = "";
    }
    if (object.Title !== undefined && object.Title !== null) {
      message.Title = object.Title;
    } else {
      message.Title = "";
    }
    return message;
  },
};

const baseUpdateFamiliarizationReq: object = {
  ID: "",
  Title: "",
  Description: "",
  ForceNewMembers: false,
  UnitIDs: "",
  Until: 0,
  DocumentIDs: "",
  UserIDs: "",
};

export const UpdateFamiliarizationReq = {
  encode(
    message: UpdateFamiliarizationReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Title !== "") {
      writer.uint32(18).string(message.Title);
    }
    if (message.Description !== "") {
      writer.uint32(26).string(message.Description);
    }
    if (message.ForceNewMembers === true) {
      writer.uint32(32).bool(message.ForceNewMembers);
    }
    for (const v of message.UnitIDs) {
      writer.uint32(50).string(v!);
    }
    if (message.Until !== 0) {
      writer.uint32(56).int64(message.Until);
    }
    for (const v of message.DocumentIDs) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.UserIDs) {
      writer.uint32(74).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateFamiliarizationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseUpdateFamiliarizationReq,
    } as UpdateFamiliarizationReq;
    message.UnitIDs = [];
    message.DocumentIDs = [];
    message.UserIDs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Title = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        case 4:
          message.ForceNewMembers = reader.bool();
          break;
        case 6:
          message.UnitIDs.push(reader.string());
          break;
        case 7:
          message.Until = longToNumber(reader.int64() as Long);
          break;
        case 8:
          message.DocumentIDs.push(reader.string());
          break;
        case 9:
          message.UserIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateFamiliarizationReq {
    const message = {
      ...baseUpdateFamiliarizationReq,
    } as UpdateFamiliarizationReq;
    message.UnitIDs = [];
    message.DocumentIDs = [];
    message.UserIDs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Title !== undefined && object.Title !== null) {
      message.Title = String(object.Title);
    } else {
      message.Title = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    if (
      object.ForceNewMembers !== undefined &&
      object.ForceNewMembers !== null
    ) {
      message.ForceNewMembers = Boolean(object.ForceNewMembers);
    } else {
      message.ForceNewMembers = false;
    }
    if (object.UnitIDs !== undefined && object.UnitIDs !== null) {
      for (const e of object.UnitIDs) {
        message.UnitIDs.push(String(e));
      }
    }
    if (object.Until !== undefined && object.Until !== null) {
      message.Until = Number(object.Until);
    } else {
      message.Until = 0;
    }
    if (object.DocumentIDs !== undefined && object.DocumentIDs !== null) {
      for (const e of object.DocumentIDs) {
        message.DocumentIDs.push(String(e));
      }
    }
    if (object.UserIDs !== undefined && object.UserIDs !== null) {
      for (const e of object.UserIDs) {
        message.UserIDs.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: UpdateFamiliarizationReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Title !== undefined && (obj.Title = message.Title);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.ForceNewMembers !== undefined &&
      (obj.ForceNewMembers = message.ForceNewMembers);
    if (message.UnitIDs) {
      obj.UnitIDs = message.UnitIDs.map((e) => e);
    } else {
      obj.UnitIDs = [];
    }
    message.Until !== undefined && (obj.Until = message.Until);
    if (message.DocumentIDs) {
      obj.DocumentIDs = message.DocumentIDs.map((e) => e);
    } else {
      obj.DocumentIDs = [];
    }
    if (message.UserIDs) {
      obj.UserIDs = message.UserIDs.map((e) => e);
    } else {
      obj.UserIDs = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<UpdateFamiliarizationReq>
  ): UpdateFamiliarizationReq {
    const message = {
      ...baseUpdateFamiliarizationReq,
    } as UpdateFamiliarizationReq;
    message.UnitIDs = [];
    message.DocumentIDs = [];
    message.UserIDs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Title !== undefined && object.Title !== null) {
      message.Title = object.Title;
    } else {
      message.Title = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    if (
      object.ForceNewMembers !== undefined &&
      object.ForceNewMembers !== null
    ) {
      message.ForceNewMembers = object.ForceNewMembers;
    } else {
      message.ForceNewMembers = false;
    }
    if (object.UnitIDs !== undefined && object.UnitIDs !== null) {
      for (const e of object.UnitIDs) {
        message.UnitIDs.push(e);
      }
    }
    if (object.Until !== undefined && object.Until !== null) {
      message.Until = object.Until;
    } else {
      message.Until = 0;
    }
    if (object.DocumentIDs !== undefined && object.DocumentIDs !== null) {
      for (const e of object.DocumentIDs) {
        message.DocumentIDs.push(e);
      }
    }
    if (object.UserIDs !== undefined && object.UserIDs !== null) {
      for (const e of object.UserIDs) {
        message.UserIDs.push(e);
      }
    }
    return message;
  },
};

const baseAddMembersReq: object = { ID: "", UserIDs: "", UnitIDs: "" };

export const AddMembersReq = {
  encode(
    message: AddMembersReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    for (const v of message.UserIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.UnitIDs) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddMembersReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddMembersReq } as AddMembersReq;
    message.UserIDs = [];
    message.UnitIDs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.UserIDs.push(reader.string());
          break;
        case 3:
          message.UnitIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddMembersReq {
    const message = { ...baseAddMembersReq } as AddMembersReq;
    message.UserIDs = [];
    message.UnitIDs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.UserIDs !== undefined && object.UserIDs !== null) {
      for (const e of object.UserIDs) {
        message.UserIDs.push(String(e));
      }
    }
    if (object.UnitIDs !== undefined && object.UnitIDs !== null) {
      for (const e of object.UnitIDs) {
        message.UnitIDs.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: AddMembersReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    if (message.UserIDs) {
      obj.UserIDs = message.UserIDs.map((e) => e);
    } else {
      obj.UserIDs = [];
    }
    if (message.UnitIDs) {
      obj.UnitIDs = message.UnitIDs.map((e) => e);
    } else {
      obj.UnitIDs = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<AddMembersReq>): AddMembersReq {
    const message = { ...baseAddMembersReq } as AddMembersReq;
    message.UserIDs = [];
    message.UnitIDs = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.UserIDs !== undefined && object.UserIDs !== null) {
      for (const e of object.UserIDs) {
        message.UserIDs.push(e);
      }
    }
    if (object.UnitIDs !== undefined && object.UnitIDs !== null) {
      for (const e of object.UnitIDs) {
        message.UnitIDs.push(e);
      }
    }
    return message;
  },
};

const baseFamiliarizationLog: object = { Status: false, When: 0 };

export const FamiliarizationLog = {
  encode(
    message: FamiliarizationLog,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.User !== undefined) {
      UserInfo.encode(message.User, writer.uint32(10).fork()).ldelim();
    }
    if (message.Status === true) {
      writer.uint32(16).bool(message.Status);
    }
    if (message.When !== 0) {
      writer.uint32(24).int64(message.When);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FamiliarizationLog {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFamiliarizationLog } as FamiliarizationLog;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = UserInfo.decode(reader, reader.uint32());
          break;
        case 2:
          message.Status = reader.bool();
          break;
        case 3:
          message.When = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FamiliarizationLog {
    const message = { ...baseFamiliarizationLog } as FamiliarizationLog;
    if (object.User !== undefined && object.User !== null) {
      message.User = UserInfo.fromJSON(object.User);
    } else {
      message.User = undefined;
    }
    if (object.Status !== undefined && object.Status !== null) {
      message.Status = Boolean(object.Status);
    } else {
      message.Status = false;
    }
    if (object.When !== undefined && object.When !== null) {
      message.When = Number(object.When);
    } else {
      message.When = 0;
    }
    return message;
  },

  toJSON(message: FamiliarizationLog): unknown {
    const obj: any = {};
    message.User !== undefined &&
      (obj.User = message.User ? UserInfo.toJSON(message.User) : undefined);
    message.Status !== undefined && (obj.Status = message.Status);
    message.When !== undefined && (obj.When = message.When);
    return obj;
  },

  fromPartial(object: DeepPartial<FamiliarizationLog>): FamiliarizationLog {
    const message = { ...baseFamiliarizationLog } as FamiliarizationLog;
    if (object.User !== undefined && object.User !== null) {
      message.User = UserInfo.fromPartial(object.User);
    } else {
      message.User = undefined;
    }
    if (object.Status !== undefined && object.Status !== null) {
      message.Status = object.Status;
    } else {
      message.Status = false;
    }
    if (object.When !== undefined && object.When !== null) {
      message.When = object.When;
    } else {
      message.When = 0;
    }
    return message;
  },
};

const baseFamiliarizeReq: object = { ID: "" };

export const FamiliarizeReq = {
  encode(
    message: FamiliarizeReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FamiliarizeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFamiliarizeReq } as FamiliarizeReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FamiliarizeReq {
    const message = { ...baseFamiliarizeReq } as FamiliarizeReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: FamiliarizeReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<FamiliarizeReq>): FamiliarizeReq {
    const message = { ...baseFamiliarizeReq } as FamiliarizeReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseRemoveFamiliarizationReq: object = { ID: "" };

export const RemoveFamiliarizationReq = {
  encode(
    message: RemoveFamiliarizationReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RemoveFamiliarizationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseRemoveFamiliarizationReq,
    } as RemoveFamiliarizationReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveFamiliarizationReq {
    const message = {
      ...baseRemoveFamiliarizationReq,
    } as RemoveFamiliarizationReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RemoveFamiliarizationReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RemoveFamiliarizationReq>
  ): RemoveFamiliarizationReq {
    const message = {
      ...baseRemoveFamiliarizationReq,
    } as RemoveFamiliarizationReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseCreateDirectoryReq: object = {
  CenterID: "",
  Name: "",
  DisplayNameFormatString: "",
};

export const CreateDirectoryReq = {
  encode(
    message: CreateDirectoryReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.CenterID !== "") {
      writer.uint32(10).string(message.CenterID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    for (const v of message.Fields) {
      DirectoryField.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.DisplayNameFormatString !== "") {
      writer.uint32(34).string(message.DisplayNameFormatString);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateDirectoryReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateDirectoryReq } as CreateDirectoryReq;
    message.Fields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CenterID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Fields.push(DirectoryField.decode(reader, reader.uint32()));
          break;
        case 4:
          message.DisplayNameFormatString = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateDirectoryReq {
    const message = { ...baseCreateDirectoryReq } as CreateDirectoryReq;
    message.Fields = [];
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(DirectoryField.fromJSON(e));
      }
    }
    if (
      object.DisplayNameFormatString !== undefined &&
      object.DisplayNameFormatString !== null
    ) {
      message.DisplayNameFormatString = String(object.DisplayNameFormatString);
    } else {
      message.DisplayNameFormatString = "";
    }
    return message;
  },

  toJSON(message: CreateDirectoryReq): unknown {
    const obj: any = {};
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.Name !== undefined && (obj.Name = message.Name);
    if (message.Fields) {
      obj.Fields = message.Fields.map((e) =>
        e ? DirectoryField.toJSON(e) : undefined
      );
    } else {
      obj.Fields = [];
    }
    message.DisplayNameFormatString !== undefined &&
      (obj.DisplayNameFormatString = message.DisplayNameFormatString);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateDirectoryReq>): CreateDirectoryReq {
    const message = { ...baseCreateDirectoryReq } as CreateDirectoryReq;
    message.Fields = [];
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(DirectoryField.fromPartial(e));
      }
    }
    if (
      object.DisplayNameFormatString !== undefined &&
      object.DisplayNameFormatString !== null
    ) {
      message.DisplayNameFormatString = object.DisplayNameFormatString;
    } else {
      message.DisplayNameFormatString = "";
    }
    return message;
  },
};

const baseRemoveDirectoryReq: object = { ID: "" };

export const RemoveDirectoryReq = {
  encode(
    message: RemoveDirectoryReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDirectoryReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveDirectoryReq } as RemoveDirectoryReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveDirectoryReq {
    const message = { ...baseRemoveDirectoryReq } as RemoveDirectoryReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RemoveDirectoryReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<RemoveDirectoryReq>): RemoveDirectoryReq {
    const message = { ...baseRemoveDirectoryReq } as RemoveDirectoryReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseSetDirectoryNameReq: object = { ID: "", Name: "" };

export const SetDirectoryNameReq = {
  encode(
    message: SetDirectoryNameReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDirectoryNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetDirectoryNameReq } as SetDirectoryNameReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetDirectoryNameReq {
    const message = { ...baseSetDirectoryNameReq } as SetDirectoryNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    return message;
  },

  toJSON(message: SetDirectoryNameReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial(object: DeepPartial<SetDirectoryNameReq>): SetDirectoryNameReq {
    const message = { ...baseSetDirectoryNameReq } as SetDirectoryNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    return message;
  },
};

const baseSetDirectoryDisplayNameReq: object = { ID: "", DisplayName: "" };

export const SetDirectoryDisplayNameReq = {
  encode(
    message: SetDirectoryDisplayNameReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.DisplayName !== "") {
      writer.uint32(18).string(message.DisplayName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SetDirectoryDisplayNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseSetDirectoryDisplayNameReq,
    } as SetDirectoryDisplayNameReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.DisplayName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetDirectoryDisplayNameReq {
    const message = {
      ...baseSetDirectoryDisplayNameReq,
    } as SetDirectoryDisplayNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.DisplayName !== undefined && object.DisplayName !== null) {
      message.DisplayName = String(object.DisplayName);
    } else {
      message.DisplayName = "";
    }
    return message;
  },

  toJSON(message: SetDirectoryDisplayNameReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.DisplayName !== undefined &&
      (obj.DisplayName = message.DisplayName);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SetDirectoryDisplayNameReq>
  ): SetDirectoryDisplayNameReq {
    const message = {
      ...baseSetDirectoryDisplayNameReq,
    } as SetDirectoryDisplayNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.DisplayName !== undefined && object.DisplayName !== null) {
      message.DisplayName = object.DisplayName;
    } else {
      message.DisplayName = "";
    }
    return message;
  },
};

const baseAddDirectoryFieldReq: object = { DirectoryID: "", Position: 0 };

export const AddDirectoryFieldReq = {
  encode(
    message: AddDirectoryFieldReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DirectoryID !== "") {
      writer.uint32(10).string(message.DirectoryID);
    }
    if (message.Position !== 0) {
      writer.uint32(16).int64(message.Position);
    }
    if (message.Field !== undefined) {
      DirectoryField.encode(message.Field, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): AddDirectoryFieldReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddDirectoryFieldReq } as AddDirectoryFieldReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DirectoryID = reader.string();
          break;
        case 2:
          message.Position = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.Field = DirectoryField.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddDirectoryFieldReq {
    const message = { ...baseAddDirectoryFieldReq } as AddDirectoryFieldReq;
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = String(object.DirectoryID);
    } else {
      message.DirectoryID = "";
    }
    if (object.Position !== undefined && object.Position !== null) {
      message.Position = Number(object.Position);
    } else {
      message.Position = 0;
    }
    if (object.Field !== undefined && object.Field !== null) {
      message.Field = DirectoryField.fromJSON(object.Field);
    } else {
      message.Field = undefined;
    }
    return message;
  },

  toJSON(message: AddDirectoryFieldReq): unknown {
    const obj: any = {};
    message.DirectoryID !== undefined &&
      (obj.DirectoryID = message.DirectoryID);
    message.Position !== undefined && (obj.Position = message.Position);
    message.Field !== undefined &&
      (obj.Field = message.Field
        ? DirectoryField.toJSON(message.Field)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<AddDirectoryFieldReq>): AddDirectoryFieldReq {
    const message = { ...baseAddDirectoryFieldReq } as AddDirectoryFieldReq;
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = object.DirectoryID;
    } else {
      message.DirectoryID = "";
    }
    if (object.Position !== undefined && object.Position !== null) {
      message.Position = object.Position;
    } else {
      message.Position = 0;
    }
    if (object.Field !== undefined && object.Field !== null) {
      message.Field = DirectoryField.fromPartial(object.Field);
    } else {
      message.Field = undefined;
    }
    return message;
  },
};

const baseRemoveDirectoryFieldReq: object = { DirectoryID: "", Position: 0 };

export const RemoveDirectoryFieldReq = {
  encode(
    message: RemoveDirectoryFieldReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DirectoryID !== "") {
      writer.uint32(10).string(message.DirectoryID);
    }
    if (message.Position !== 0) {
      writer.uint32(24).int64(message.Position);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RemoveDirectoryFieldReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseRemoveDirectoryFieldReq,
    } as RemoveDirectoryFieldReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DirectoryID = reader.string();
          break;
        case 3:
          message.Position = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveDirectoryFieldReq {
    const message = {
      ...baseRemoveDirectoryFieldReq,
    } as RemoveDirectoryFieldReq;
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = String(object.DirectoryID);
    } else {
      message.DirectoryID = "";
    }
    if (object.Position !== undefined && object.Position !== null) {
      message.Position = Number(object.Position);
    } else {
      message.Position = 0;
    }
    return message;
  },

  toJSON(message: RemoveDirectoryFieldReq): unknown {
    const obj: any = {};
    message.DirectoryID !== undefined &&
      (obj.DirectoryID = message.DirectoryID);
    message.Position !== undefined && (obj.Position = message.Position);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RemoveDirectoryFieldReq>
  ): RemoveDirectoryFieldReq {
    const message = {
      ...baseRemoveDirectoryFieldReq,
    } as RemoveDirectoryFieldReq;
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = object.DirectoryID;
    } else {
      message.DirectoryID = "";
    }
    if (object.Position !== undefined && object.Position !== null) {
      message.Position = object.Position;
    } else {
      message.Position = 0;
    }
    return message;
  },
};

const baseSetDirectoryFieldNameReq: object = {
  DirectoryID: "",
  Position: 0,
  Name: "",
};

export const SetDirectoryFieldNameReq = {
  encode(
    message: SetDirectoryFieldNameReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DirectoryID !== "") {
      writer.uint32(10).string(message.DirectoryID);
    }
    if (message.Position !== 0) {
      writer.uint32(16).int64(message.Position);
    }
    if (message.Name !== "") {
      writer.uint32(26).string(message.Name);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SetDirectoryFieldNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseSetDirectoryFieldNameReq,
    } as SetDirectoryFieldNameReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DirectoryID = reader.string();
          break;
        case 2:
          message.Position = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetDirectoryFieldNameReq {
    const message = {
      ...baseSetDirectoryFieldNameReq,
    } as SetDirectoryFieldNameReq;
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = String(object.DirectoryID);
    } else {
      message.DirectoryID = "";
    }
    if (object.Position !== undefined && object.Position !== null) {
      message.Position = Number(object.Position);
    } else {
      message.Position = 0;
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    return message;
  },

  toJSON(message: SetDirectoryFieldNameReq): unknown {
    const obj: any = {};
    message.DirectoryID !== undefined &&
      (obj.DirectoryID = message.DirectoryID);
    message.Position !== undefined && (obj.Position = message.Position);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SetDirectoryFieldNameReq>
  ): SetDirectoryFieldNameReq {
    const message = {
      ...baseSetDirectoryFieldNameReq,
    } as SetDirectoryFieldNameReq;
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = object.DirectoryID;
    } else {
      message.DirectoryID = "";
    }
    if (object.Position !== undefined && object.Position !== null) {
      message.Position = object.Position;
    } else {
      message.Position = 0;
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    return message;
  },
};

const baseSetDirectoryFieldRequiredReq: object = {
  DirectoryID: "",
  Position: 0,
  Required: false,
};

export const SetDirectoryFieldRequiredReq = {
  encode(
    message: SetDirectoryFieldRequiredReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DirectoryID !== "") {
      writer.uint32(10).string(message.DirectoryID);
    }
    if (message.Position !== 0) {
      writer.uint32(16).int64(message.Position);
    }
    if (message.Required === true) {
      writer.uint32(24).bool(message.Required);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SetDirectoryFieldRequiredReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseSetDirectoryFieldRequiredReq,
    } as SetDirectoryFieldRequiredReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DirectoryID = reader.string();
          break;
        case 2:
          message.Position = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.Required = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetDirectoryFieldRequiredReq {
    const message = {
      ...baseSetDirectoryFieldRequiredReq,
    } as SetDirectoryFieldRequiredReq;
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = String(object.DirectoryID);
    } else {
      message.DirectoryID = "";
    }
    if (object.Position !== undefined && object.Position !== null) {
      message.Position = Number(object.Position);
    } else {
      message.Position = 0;
    }
    if (object.Required !== undefined && object.Required !== null) {
      message.Required = Boolean(object.Required);
    } else {
      message.Required = false;
    }
    return message;
  },

  toJSON(message: SetDirectoryFieldRequiredReq): unknown {
    const obj: any = {};
    message.DirectoryID !== undefined &&
      (obj.DirectoryID = message.DirectoryID);
    message.Position !== undefined && (obj.Position = message.Position);
    message.Required !== undefined && (obj.Required = message.Required);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SetDirectoryFieldRequiredReq>
  ): SetDirectoryFieldRequiredReq {
    const message = {
      ...baseSetDirectoryFieldRequiredReq,
    } as SetDirectoryFieldRequiredReq;
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = object.DirectoryID;
    } else {
      message.DirectoryID = "";
    }
    if (object.Position !== undefined && object.Position !== null) {
      message.Position = object.Position;
    } else {
      message.Position = 0;
    }
    if (object.Required !== undefined && object.Required !== null) {
      message.Required = object.Required;
    } else {
      message.Required = false;
    }
    return message;
  },
};

const baseSetDirectoryArchivedReq: object = { ID: "", Archived: false };

export const SetDirectoryArchivedReq = {
  encode(
    message: SetDirectoryArchivedReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Archived === true) {
      writer.uint32(16).bool(message.Archived);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SetDirectoryArchivedReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseSetDirectoryArchivedReq,
    } as SetDirectoryArchivedReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Archived = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetDirectoryArchivedReq {
    const message = {
      ...baseSetDirectoryArchivedReq,
    } as SetDirectoryArchivedReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Archived !== undefined && object.Archived !== null) {
      message.Archived = Boolean(object.Archived);
    } else {
      message.Archived = false;
    }
    return message;
  },

  toJSON(message: SetDirectoryArchivedReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Archived !== undefined && (obj.Archived = message.Archived);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SetDirectoryArchivedReq>
  ): SetDirectoryArchivedReq {
    const message = {
      ...baseSetDirectoryArchivedReq,
    } as SetDirectoryArchivedReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Archived !== undefined && object.Archived !== null) {
      message.Archived = object.Archived;
    } else {
      message.Archived = false;
    }
    return message;
  },
};

const baseAddRecordReq: object = { DirectoryID: "" };

export const AddRecordReq = {
  encode(
    message: AddRecordReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.DirectoryID !== "") {
      writer.uint32(10).string(message.DirectoryID);
    }
    for (const v of message.Fields) {
      RecordField.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddRecordReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddRecordReq } as AddRecordReq;
    message.Fields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DirectoryID = reader.string();
          break;
        case 4:
          message.Fields.push(RecordField.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddRecordReq {
    const message = { ...baseAddRecordReq } as AddRecordReq;
    message.Fields = [];
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = String(object.DirectoryID);
    } else {
      message.DirectoryID = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(RecordField.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: AddRecordReq): unknown {
    const obj: any = {};
    message.DirectoryID !== undefined &&
      (obj.DirectoryID = message.DirectoryID);
    if (message.Fields) {
      obj.Fields = message.Fields.map((e) =>
        e ? RecordField.toJSON(e) : undefined
      );
    } else {
      obj.Fields = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<AddRecordReq>): AddRecordReq {
    const message = { ...baseAddRecordReq } as AddRecordReq;
    message.Fields = [];
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = object.DirectoryID;
    } else {
      message.DirectoryID = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(RecordField.fromPartial(e));
      }
    }
    return message;
  },
};

const baseRemoveRecordReq: object = { ID: "" };

export const RemoveRecordReq = {
  encode(
    message: RemoveRecordReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveRecordReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveRecordReq } as RemoveRecordReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveRecordReq {
    const message = { ...baseRemoveRecordReq } as RemoveRecordReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RemoveRecordReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<RemoveRecordReq>): RemoveRecordReq {
    const message = { ...baseRemoveRecordReq } as RemoveRecordReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseSetRecordArchivedReq: object = { ID: "", Archived: false };

export const SetRecordArchivedReq = {
  encode(
    message: SetRecordArchivedReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Archived === true) {
      writer.uint32(16).bool(message.Archived);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SetRecordArchivedReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetRecordArchivedReq } as SetRecordArchivedReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Archived = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetRecordArchivedReq {
    const message = { ...baseSetRecordArchivedReq } as SetRecordArchivedReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Archived !== undefined && object.Archived !== null) {
      message.Archived = Boolean(object.Archived);
    } else {
      message.Archived = false;
    }
    return message;
  },

  toJSON(message: SetRecordArchivedReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Archived !== undefined && (obj.Archived = message.Archived);
    return obj;
  },

  fromPartial(object: DeepPartial<SetRecordArchivedReq>): SetRecordArchivedReq {
    const message = { ...baseSetRecordArchivedReq } as SetRecordArchivedReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Archived !== undefined && object.Archived !== null) {
      message.Archived = object.Archived;
    } else {
      message.Archived = false;
    }
    return message;
  },
};

const baseUpdateRecordFieldsReq: object = { ID: "" };

export const UpdateRecordFieldsReq = {
  encode(
    message: UpdateRecordFieldsReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    for (const v of message.Fields) {
      RecordField.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateRecordFieldsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUpdateRecordFieldsReq } as UpdateRecordFieldsReq;
    message.Fields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 4:
          message.Fields.push(RecordField.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateRecordFieldsReq {
    const message = { ...baseUpdateRecordFieldsReq } as UpdateRecordFieldsReq;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(RecordField.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: UpdateRecordFieldsReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    if (message.Fields) {
      obj.Fields = message.Fields.map((e) =>
        e ? RecordField.toJSON(e) : undefined
      );
    } else {
      obj.Fields = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<UpdateRecordFieldsReq>
  ): UpdateRecordFieldsReq {
    const message = { ...baseUpdateRecordFieldsReq } as UpdateRecordFieldsReq;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(RecordField.fromPartial(e));
      }
    }
    return message;
  },
};

const baseDirectory: object = {
  ID: "",
  CenterID: "",
  Name: "",
  DisplayNameFormatString: "",
  ReadOnly: false,
  External: false,
  Archived: false,
  ModificationTime: 0,
};

export const Directory = {
  encode(
    message: Directory,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.CenterID !== "") {
      writer.uint32(18).string(message.CenterID);
    }
    if (message.Name !== "") {
      writer.uint32(26).string(message.Name);
    }
    if (message.DisplayNameFormatString !== "") {
      writer.uint32(34).string(message.DisplayNameFormatString);
    }
    if (message.ReadOnly === true) {
      writer.uint32(40).bool(message.ReadOnly);
    }
    if (message.External === true) {
      writer.uint32(48).bool(message.External);
    }
    if (message.Archived === true) {
      writer.uint32(56).bool(message.Archived);
    }
    for (const v of message.Fields) {
      DirectoryField.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Directory {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDirectory } as Directory;
    message.Fields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.CenterID = reader.string();
          break;
        case 3:
          message.Name = reader.string();
          break;
        case 4:
          message.DisplayNameFormatString = reader.string();
          break;
        case 5:
          message.ReadOnly = reader.bool();
          break;
        case 6:
          message.External = reader.bool();
          break;
        case 7:
          message.Archived = reader.bool();
          break;
        case 8:
          message.Fields.push(DirectoryField.decode(reader, reader.uint32()));
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Directory {
    const message = { ...baseDirectory } as Directory;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (
      object.DisplayNameFormatString !== undefined &&
      object.DisplayNameFormatString !== null
    ) {
      message.DisplayNameFormatString = String(object.DisplayNameFormatString);
    } else {
      message.DisplayNameFormatString = "";
    }
    if (object.ReadOnly !== undefined && object.ReadOnly !== null) {
      message.ReadOnly = Boolean(object.ReadOnly);
    } else {
      message.ReadOnly = false;
    }
    if (object.External !== undefined && object.External !== null) {
      message.External = Boolean(object.External);
    } else {
      message.External = false;
    }
    if (object.Archived !== undefined && object.Archived !== null) {
      message.Archived = Boolean(object.Archived);
    } else {
      message.Archived = false;
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(DirectoryField.fromJSON(e));
      }
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: Directory): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.DisplayNameFormatString !== undefined &&
      (obj.DisplayNameFormatString = message.DisplayNameFormatString);
    message.ReadOnly !== undefined && (obj.ReadOnly = message.ReadOnly);
    message.External !== undefined && (obj.External = message.External);
    message.Archived !== undefined && (obj.Archived = message.Archived);
    if (message.Fields) {
      obj.Fields = message.Fields.map((e) =>
        e ? DirectoryField.toJSON(e) : undefined
      );
    } else {
      obj.Fields = [];
    }
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<Directory>): Directory {
    const message = { ...baseDirectory } as Directory;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (
      object.DisplayNameFormatString !== undefined &&
      object.DisplayNameFormatString !== null
    ) {
      message.DisplayNameFormatString = object.DisplayNameFormatString;
    } else {
      message.DisplayNameFormatString = "";
    }
    if (object.ReadOnly !== undefined && object.ReadOnly !== null) {
      message.ReadOnly = object.ReadOnly;
    } else {
      message.ReadOnly = false;
    }
    if (object.External !== undefined && object.External !== null) {
      message.External = object.External;
    } else {
      message.External = false;
    }
    if (object.Archived !== undefined && object.Archived !== null) {
      message.Archived = object.Archived;
    } else {
      message.Archived = false;
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(DirectoryField.fromPartial(e));
      }
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseDirectoryField: object = {
  Name: "",
  Required: false,
  Type: "",
  SubType: "",
};

export const DirectoryField = {
  encode(
    message: DirectoryField,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Name !== "") {
      writer.uint32(10).string(message.Name);
    }
    if (message.Required === true) {
      writer.uint32(16).bool(message.Required);
    }
    if (message.Type !== "") {
      writer.uint32(34).string(message.Type);
    }
    if (message.SubType !== "") {
      writer.uint32(42).string(message.SubType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DirectoryField {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDirectoryField } as DirectoryField;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Name = reader.string();
          break;
        case 2:
          message.Required = reader.bool();
          break;
        case 4:
          message.Type = reader.string();
          break;
        case 5:
          message.SubType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DirectoryField {
    const message = { ...baseDirectoryField } as DirectoryField;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Required !== undefined && object.Required !== null) {
      message.Required = Boolean(object.Required);
    } else {
      message.Required = false;
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.SubType !== undefined && object.SubType !== null) {
      message.SubType = String(object.SubType);
    } else {
      message.SubType = "";
    }
    return message;
  },

  toJSON(message: DirectoryField): unknown {
    const obj: any = {};
    message.Name !== undefined && (obj.Name = message.Name);
    message.Required !== undefined && (obj.Required = message.Required);
    message.Type !== undefined && (obj.Type = message.Type);
    message.SubType !== undefined && (obj.SubType = message.SubType);
    return obj;
  },

  fromPartial(object: DeepPartial<DirectoryField>): DirectoryField {
    const message = { ...baseDirectoryField } as DirectoryField;
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Required !== undefined && object.Required !== null) {
      message.Required = object.Required;
    } else {
      message.Required = false;
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.SubType !== undefined && object.SubType !== null) {
      message.SubType = object.SubType;
    } else {
      message.SubType = "";
    }
    return message;
  },
};

const baseRecord: object = {
  ID: "",
  DirectoryID: "",
  CenterID: "",
  UnitID: "",
  Archived: false,
  ModificationTime: 0,
};

export const Record = {
  encode(
    message: Record,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.DirectoryID !== "") {
      writer.uint32(18).string(message.DirectoryID);
    }
    if (message.CenterID !== "") {
      writer.uint32(26).string(message.CenterID);
    }
    if (message.UnitID !== "") {
      writer.uint32(34).string(message.UnitID);
    }
    if (message.Archived === true) {
      writer.uint32(40).bool(message.Archived);
    }
    for (const v of message.Fields) {
      RecordField.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Record {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRecord } as Record;
    message.Fields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.DirectoryID = reader.string();
          break;
        case 3:
          message.CenterID = reader.string();
          break;
        case 4:
          message.UnitID = reader.string();
          break;
        case 5:
          message.Archived = reader.bool();
          break;
        case 6:
          message.Fields.push(RecordField.decode(reader, reader.uint32()));
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Record {
    const message = { ...baseRecord } as Record;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = String(object.DirectoryID);
    } else {
      message.DirectoryID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = String(object.UnitID);
    } else {
      message.UnitID = "";
    }
    if (object.Archived !== undefined && object.Archived !== null) {
      message.Archived = Boolean(object.Archived);
    } else {
      message.Archived = false;
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(RecordField.fromJSON(e));
      }
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: Record): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.DirectoryID !== undefined &&
      (obj.DirectoryID = message.DirectoryID);
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.UnitID !== undefined && (obj.UnitID = message.UnitID);
    message.Archived !== undefined && (obj.Archived = message.Archived);
    if (message.Fields) {
      obj.Fields = message.Fields.map((e) =>
        e ? RecordField.toJSON(e) : undefined
      );
    } else {
      obj.Fields = [];
    }
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<Record>): Record {
    const message = { ...baseRecord } as Record;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.DirectoryID !== undefined && object.DirectoryID !== null) {
      message.DirectoryID = object.DirectoryID;
    } else {
      message.DirectoryID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.UnitID !== undefined && object.UnitID !== null) {
      message.UnitID = object.UnitID;
    } else {
      message.UnitID = "";
    }
    if (object.Archived !== undefined && object.Archived !== null) {
      message.Archived = object.Archived;
    } else {
      message.Archived = false;
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(RecordField.fromPartial(e));
      }
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseRecordField: object = {
  String: "",
  Number: "",
  Boolean: false,
  Reference: "",
};

export const RecordField = {
  encode(
    message: RecordField,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.String !== "") {
      writer.uint32(10).string(message.String);
    }
    if (message.Number !== "") {
      writer.uint32(18).string(message.Number);
    }
    if (message.Boolean === true) {
      writer.uint32(24).bool(message.Boolean);
    }
    if (message.Reference !== "") {
      writer.uint32(34).string(message.Reference);
    }
    if (message.Time !== undefined) {
      Timex.encode(message.Time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordField {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRecordField } as RecordField;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.String = reader.string();
          break;
        case 2:
          message.Number = reader.string();
          break;
        case 3:
          message.Boolean = reader.bool();
          break;
        case 4:
          message.Reference = reader.string();
          break;
        case 5:
          message.Time = Timex.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RecordField {
    const message = { ...baseRecordField } as RecordField;
    if (object.String !== undefined && object.String !== null) {
      message.String = String(object.String);
    } else {
      message.String = "";
    }
    if (object.Number !== undefined && object.Number !== null) {
      message.Number = String(object.Number);
    } else {
      message.Number = "";
    }
    if (object.Boolean !== undefined && object.Boolean !== null) {
      message.Boolean = Boolean(object.Boolean);
    } else {
      message.Boolean = false;
    }
    if (object.Reference !== undefined && object.Reference !== null) {
      message.Reference = String(object.Reference);
    } else {
      message.Reference = "";
    }
    if (object.Time !== undefined && object.Time !== null) {
      message.Time = Timex.fromJSON(object.Time);
    } else {
      message.Time = undefined;
    }
    return message;
  },

  toJSON(message: RecordField): unknown {
    const obj: any = {};
    message.String !== undefined && (obj.String = message.String);
    message.Number !== undefined && (obj.Number = message.Number);
    message.Boolean !== undefined && (obj.Boolean = message.Boolean);
    message.Reference !== undefined && (obj.Reference = message.Reference);
    message.Time !== undefined &&
      (obj.Time = message.Time ? Timex.toJSON(message.Time) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<RecordField>): RecordField {
    const message = { ...baseRecordField } as RecordField;
    if (object.String !== undefined && object.String !== null) {
      message.String = object.String;
    } else {
      message.String = "";
    }
    if (object.Number !== undefined && object.Number !== null) {
      message.Number = object.Number;
    } else {
      message.Number = "";
    }
    if (object.Boolean !== undefined && object.Boolean !== null) {
      message.Boolean = object.Boolean;
    } else {
      message.Boolean = false;
    }
    if (object.Reference !== undefined && object.Reference !== null) {
      message.Reference = object.Reference;
    } else {
      message.Reference = "";
    }
    if (object.Time !== undefined && object.Time !== null) {
      message.Time = Timex.fromPartial(object.Time);
    } else {
      message.Time = undefined;
    }
    return message;
  },
};

const baseTimex: object = { UTC: 0, Local: 0, Offset: 0, OffsetAbsent: false };

export const Timex = {
  encode(message: Timex, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.UTC !== 0) {
      writer.uint32(8).int64(message.UTC);
    }
    if (message.Local !== 0) {
      writer.uint32(16).int64(message.Local);
    }
    if (message.Offset !== 0) {
      writer.uint32(24).int64(message.Offset);
    }
    if (message.OffsetAbsent === true) {
      writer.uint32(32).bool(message.OffsetAbsent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Timex {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseTimex } as Timex;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UTC = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.Local = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.Offset = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.OffsetAbsent = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Timex {
    const message = { ...baseTimex } as Timex;
    if (object.UTC !== undefined && object.UTC !== null) {
      message.UTC = Number(object.UTC);
    } else {
      message.UTC = 0;
    }
    if (object.Local !== undefined && object.Local !== null) {
      message.Local = Number(object.Local);
    } else {
      message.Local = 0;
    }
    if (object.Offset !== undefined && object.Offset !== null) {
      message.Offset = Number(object.Offset);
    } else {
      message.Offset = 0;
    }
    if (object.OffsetAbsent !== undefined && object.OffsetAbsent !== null) {
      message.OffsetAbsent = Boolean(object.OffsetAbsent);
    } else {
      message.OffsetAbsent = false;
    }
    return message;
  },

  toJSON(message: Timex): unknown {
    const obj: any = {};
    message.UTC !== undefined && (obj.UTC = message.UTC);
    message.Local !== undefined && (obj.Local = message.Local);
    message.Offset !== undefined && (obj.Offset = message.Offset);
    message.OffsetAbsent !== undefined &&
      (obj.OffsetAbsent = message.OffsetAbsent);
    return obj;
  },

  fromPartial(object: DeepPartial<Timex>): Timex {
    const message = { ...baseTimex } as Timex;
    if (object.UTC !== undefined && object.UTC !== null) {
      message.UTC = object.UTC;
    } else {
      message.UTC = 0;
    }
    if (object.Local !== undefined && object.Local !== null) {
      message.Local = object.Local;
    } else {
      message.Local = 0;
    }
    if (object.Offset !== undefined && object.Offset !== null) {
      message.Offset = object.Offset;
    } else {
      message.Offset = 0;
    }
    if (object.OffsetAbsent !== undefined && object.OffsetAbsent !== null) {
      message.OffsetAbsent = object.OffsetAbsent;
    } else {
      message.OffsetAbsent = false;
    }
    return message;
  },
};

const baseMarkAsRootReq: object = { ID: "", Root: false };

export const MarkAsRootReq = {
  encode(
    message: MarkAsRootReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Root === true) {
      writer.uint32(16).bool(message.Root);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarkAsRootReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMarkAsRootReq } as MarkAsRootReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Root = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarkAsRootReq {
    const message = { ...baseMarkAsRootReq } as MarkAsRootReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Root !== undefined && object.Root !== null) {
      message.Root = Boolean(object.Root);
    } else {
      message.Root = false;
    }
    return message;
  },

  toJSON(message: MarkAsRootReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Root !== undefined && (obj.Root = message.Root);
    return obj;
  },

  fromPartial(object: DeepPartial<MarkAsRootReq>): MarkAsRootReq {
    const message = { ...baseMarkAsRootReq } as MarkAsRootReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Root !== undefined && object.Root !== null) {
      message.Root = object.Root;
    } else {
      message.Root = false;
    }
    return message;
  },
};

const baseMoveUnitReq: object = { ID: "", Parent: "" };

export const MoveUnitReq = {
  encode(
    message: MoveUnitReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Parent !== "") {
      writer.uint32(18).string(message.Parent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MoveUnitReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMoveUnitReq } as MoveUnitReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Parent = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MoveUnitReq {
    const message = { ...baseMoveUnitReq } as MoveUnitReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = String(object.Parent);
    } else {
      message.Parent = "";
    }
    return message;
  },

  toJSON(message: MoveUnitReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Parent !== undefined && (obj.Parent = message.Parent);
    return obj;
  },

  fromPartial(object: DeepPartial<MoveUnitReq>): MoveUnitReq {
    const message = { ...baseMoveUnitReq } as MoveUnitReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Parent !== undefined && object.Parent !== null) {
      message.Parent = object.Parent;
    } else {
      message.Parent = "";
    }
    return message;
  },
};

const baseSetUnitHeadReq: object = { ID: "", Chief: "" };

export const SetUnitHeadReq = {
  encode(
    message: SetUnitHeadReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Chief !== "") {
      writer.uint32(18).string(message.Chief);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetUnitHeadReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetUnitHeadReq } as SetUnitHeadReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Chief = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetUnitHeadReq {
    const message = { ...baseSetUnitHeadReq } as SetUnitHeadReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Chief !== undefined && object.Chief !== null) {
      message.Chief = String(object.Chief);
    } else {
      message.Chief = "";
    }
    return message;
  },

  toJSON(message: SetUnitHeadReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Chief !== undefined && (obj.Chief = message.Chief);
    return obj;
  },

  fromPartial(object: DeepPartial<SetUnitHeadReq>): SetUnitHeadReq {
    const message = { ...baseSetUnitHeadReq } as SetUnitHeadReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Chief !== undefined && object.Chief !== null) {
      message.Chief = object.Chief;
    } else {
      message.Chief = "";
    }
    return message;
  },
};

const baseAddEmployeeToUnitReq: object = { Employee: "", Unit: "" };

export const AddEmployeeToUnitReq = {
  encode(
    message: AddEmployeeToUnitReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Employee !== "") {
      writer.uint32(10).string(message.Employee);
    }
    if (message.Unit !== "") {
      writer.uint32(18).string(message.Unit);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): AddEmployeeToUnitReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAddEmployeeToUnitReq } as AddEmployeeToUnitReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Employee = reader.string();
          break;
        case 2:
          message.Unit = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddEmployeeToUnitReq {
    const message = { ...baseAddEmployeeToUnitReq } as AddEmployeeToUnitReq;
    if (object.Employee !== undefined && object.Employee !== null) {
      message.Employee = String(object.Employee);
    } else {
      message.Employee = "";
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = String(object.Unit);
    } else {
      message.Unit = "";
    }
    return message;
  },

  toJSON(message: AddEmployeeToUnitReq): unknown {
    const obj: any = {};
    message.Employee !== undefined && (obj.Employee = message.Employee);
    message.Unit !== undefined && (obj.Unit = message.Unit);
    return obj;
  },

  fromPartial(object: DeepPartial<AddEmployeeToUnitReq>): AddEmployeeToUnitReq {
    const message = { ...baseAddEmployeeToUnitReq } as AddEmployeeToUnitReq;
    if (object.Employee !== undefined && object.Employee !== null) {
      message.Employee = object.Employee;
    } else {
      message.Employee = "";
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = object.Unit;
    } else {
      message.Unit = "";
    }
    return message;
  },
};

const baseRemoveEmployeeFromUnitReq: object = { Employee: "", Unit: "" };

export const RemoveEmployeeFromUnitReq = {
  encode(
    message: RemoveEmployeeFromUnitReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Employee !== "") {
      writer.uint32(10).string(message.Employee);
    }
    if (message.Unit !== "") {
      writer.uint32(18).string(message.Unit);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RemoveEmployeeFromUnitReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseRemoveEmployeeFromUnitReq,
    } as RemoveEmployeeFromUnitReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Employee = reader.string();
          break;
        case 2:
          message.Unit = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveEmployeeFromUnitReq {
    const message = {
      ...baseRemoveEmployeeFromUnitReq,
    } as RemoveEmployeeFromUnitReq;
    if (object.Employee !== undefined && object.Employee !== null) {
      message.Employee = String(object.Employee);
    } else {
      message.Employee = "";
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = String(object.Unit);
    } else {
      message.Unit = "";
    }
    return message;
  },

  toJSON(message: RemoveEmployeeFromUnitReq): unknown {
    const obj: any = {};
    message.Employee !== undefined && (obj.Employee = message.Employee);
    message.Unit !== undefined && (obj.Unit = message.Unit);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RemoveEmployeeFromUnitReq>
  ): RemoveEmployeeFromUnitReq {
    const message = {
      ...baseRemoveEmployeeFromUnitReq,
    } as RemoveEmployeeFromUnitReq;
    if (object.Employee !== undefined && object.Employee !== null) {
      message.Employee = object.Employee;
    } else {
      message.Employee = "";
    }
    if (object.Unit !== undefined && object.Unit !== null) {
      message.Unit = object.Unit;
    } else {
      message.Unit = "";
    }
    return message;
  },
};

const baseOneAssQueryAndWatchReq: object = {};

export const OneAssQueryAndWatchReq = {
  encode(
    _: OneAssQueryAndWatchReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): OneAssQueryAndWatchReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOneAssQueryAndWatchReq } as OneAssQueryAndWatchReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): OneAssQueryAndWatchReq {
    const message = { ...baseOneAssQueryAndWatchReq } as OneAssQueryAndWatchReq;
    return message;
  },

  toJSON(_: OneAssQueryAndWatchReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<OneAssQueryAndWatchReq>): OneAssQueryAndWatchReq {
    const message = { ...baseOneAssQueryAndWatchReq } as OneAssQueryAndWatchReq;
    return message;
  },
};

const baseOneAssQueryAndWatchMsg: object = {};

export const OneAssQueryAndWatchMsg = {
  encode(
    message: OneAssQueryAndWatchMsg,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Directory !== undefined) {
      Directory.encode(message.Directory, writer.uint32(10).fork()).ldelim();
    }
    if (message.RecordEvent !== undefined) {
      OneAssRecordEvent.encode(
        message.RecordEvent,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.Dummy !== undefined) {
      Dummy.encode(message.Dummy, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): OneAssQueryAndWatchMsg {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOneAssQueryAndWatchMsg } as OneAssQueryAndWatchMsg;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Directory = Directory.decode(reader, reader.uint32());
          break;
        case 2:
          message.RecordEvent = OneAssRecordEvent.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.Dummy = Dummy.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OneAssQueryAndWatchMsg {
    const message = { ...baseOneAssQueryAndWatchMsg } as OneAssQueryAndWatchMsg;
    if (object.Directory !== undefined && object.Directory !== null) {
      message.Directory = Directory.fromJSON(object.Directory);
    } else {
      message.Directory = undefined;
    }
    if (object.RecordEvent !== undefined && object.RecordEvent !== null) {
      message.RecordEvent = OneAssRecordEvent.fromJSON(object.RecordEvent);
    } else {
      message.RecordEvent = undefined;
    }
    if (object.Dummy !== undefined && object.Dummy !== null) {
      message.Dummy = Dummy.fromJSON(object.Dummy);
    } else {
      message.Dummy = undefined;
    }
    return message;
  },

  toJSON(message: OneAssQueryAndWatchMsg): unknown {
    const obj: any = {};
    message.Directory !== undefined &&
      (obj.Directory = message.Directory
        ? Directory.toJSON(message.Directory)
        : undefined);
    message.RecordEvent !== undefined &&
      (obj.RecordEvent = message.RecordEvent
        ? OneAssRecordEvent.toJSON(message.RecordEvent)
        : undefined);
    message.Dummy !== undefined &&
      (obj.Dummy = message.Dummy ? Dummy.toJSON(message.Dummy) : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<OneAssQueryAndWatchMsg>
  ): OneAssQueryAndWatchMsg {
    const message = { ...baseOneAssQueryAndWatchMsg } as OneAssQueryAndWatchMsg;
    if (object.Directory !== undefined && object.Directory !== null) {
      message.Directory = Directory.fromPartial(object.Directory);
    } else {
      message.Directory = undefined;
    }
    if (object.RecordEvent !== undefined && object.RecordEvent !== null) {
      message.RecordEvent = OneAssRecordEvent.fromPartial(object.RecordEvent);
    } else {
      message.RecordEvent = undefined;
    }
    if (object.Dummy !== undefined && object.Dummy !== null) {
      message.Dummy = Dummy.fromPartial(object.Dummy);
    } else {
      message.Dummy = undefined;
    }
    return message;
  },
};

const baseOneAssRecordEvent: object = {};

export const OneAssRecordEvent = {
  encode(
    message: OneAssRecordEvent,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ExistingRecord !== undefined) {
      Record.encode(message.ExistingRecord, writer.uint32(66).fork()).ldelim();
    }
    if (message.RecordCreated !== undefined) {
      Record.encode(message.RecordCreated, writer.uint32(74).fork()).ldelim();
    }
    if (message.RecordUpdated !== undefined) {
      Record.encode(message.RecordUpdated, writer.uint32(82).fork()).ldelim();
    }
    if (message.RecordDeleted !== undefined) {
      Record.encode(message.RecordDeleted, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OneAssRecordEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOneAssRecordEvent } as OneAssRecordEvent;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 8:
          message.ExistingRecord = Record.decode(reader, reader.uint32());
          break;
        case 9:
          message.RecordCreated = Record.decode(reader, reader.uint32());
          break;
        case 10:
          message.RecordUpdated = Record.decode(reader, reader.uint32());
          break;
        case 11:
          message.RecordDeleted = Record.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OneAssRecordEvent {
    const message = { ...baseOneAssRecordEvent } as OneAssRecordEvent;
    if (object.ExistingRecord !== undefined && object.ExistingRecord !== null) {
      message.ExistingRecord = Record.fromJSON(object.ExistingRecord);
    } else {
      message.ExistingRecord = undefined;
    }
    if (object.RecordCreated !== undefined && object.RecordCreated !== null) {
      message.RecordCreated = Record.fromJSON(object.RecordCreated);
    } else {
      message.RecordCreated = undefined;
    }
    if (object.RecordUpdated !== undefined && object.RecordUpdated !== null) {
      message.RecordUpdated = Record.fromJSON(object.RecordUpdated);
    } else {
      message.RecordUpdated = undefined;
    }
    if (object.RecordDeleted !== undefined && object.RecordDeleted !== null) {
      message.RecordDeleted = Record.fromJSON(object.RecordDeleted);
    } else {
      message.RecordDeleted = undefined;
    }
    return message;
  },

  toJSON(message: OneAssRecordEvent): unknown {
    const obj: any = {};
    message.ExistingRecord !== undefined &&
      (obj.ExistingRecord = message.ExistingRecord
        ? Record.toJSON(message.ExistingRecord)
        : undefined);
    message.RecordCreated !== undefined &&
      (obj.RecordCreated = message.RecordCreated
        ? Record.toJSON(message.RecordCreated)
        : undefined);
    message.RecordUpdated !== undefined &&
      (obj.RecordUpdated = message.RecordUpdated
        ? Record.toJSON(message.RecordUpdated)
        : undefined);
    message.RecordDeleted !== undefined &&
      (obj.RecordDeleted = message.RecordDeleted
        ? Record.toJSON(message.RecordDeleted)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<OneAssRecordEvent>): OneAssRecordEvent {
    const message = { ...baseOneAssRecordEvent } as OneAssRecordEvent;
    if (object.ExistingRecord !== undefined && object.ExistingRecord !== null) {
      message.ExistingRecord = Record.fromPartial(object.ExistingRecord);
    } else {
      message.ExistingRecord = undefined;
    }
    if (object.RecordCreated !== undefined && object.RecordCreated !== null) {
      message.RecordCreated = Record.fromPartial(object.RecordCreated);
    } else {
      message.RecordCreated = undefined;
    }
    if (object.RecordUpdated !== undefined && object.RecordUpdated !== null) {
      message.RecordUpdated = Record.fromPartial(object.RecordUpdated);
    } else {
      message.RecordUpdated = undefined;
    }
    if (object.RecordDeleted !== undefined && object.RecordDeleted !== null) {
      message.RecordDeleted = Record.fromPartial(object.RecordDeleted);
    } else {
      message.RecordDeleted = undefined;
    }
    return message;
  },
};

const baseLoginAsReq: object = { User: "", Secret: "" };

export const LoginAsReq = {
  encode(
    message: LoginAsReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.User !== "") {
      writer.uint32(10).string(message.User);
    }
    if (message.Secret !== "") {
      writer.uint32(18).string(message.Secret);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginAsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLoginAsReq } as LoginAsReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.User = reader.string();
          break;
        case 2:
          message.Secret = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LoginAsReq {
    const message = { ...baseLoginAsReq } as LoginAsReq;
    if (object.User !== undefined && object.User !== null) {
      message.User = String(object.User);
    } else {
      message.User = "";
    }
    if (object.Secret !== undefined && object.Secret !== null) {
      message.Secret = String(object.Secret);
    } else {
      message.Secret = "";
    }
    return message;
  },

  toJSON(message: LoginAsReq): unknown {
    const obj: any = {};
    message.User !== undefined && (obj.User = message.User);
    message.Secret !== undefined && (obj.Secret = message.Secret);
    return obj;
  },

  fromPartial(object: DeepPartial<LoginAsReq>): LoginAsReq {
    const message = { ...baseLoginAsReq } as LoginAsReq;
    if (object.User !== undefined && object.User !== null) {
      message.User = object.User;
    } else {
      message.User = "";
    }
    if (object.Secret !== undefined && object.Secret !== null) {
      message.Secret = object.Secret;
    } else {
      message.Secret = "";
    }
    return message;
  },
};

const baseLoginAsResp: object = { Session: "", User: "", Error: "" };

export const LoginAsResp = {
  encode(
    message: LoginAsResp,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Session !== "") {
      writer.uint32(10).string(message.Session);
    }
    if (message.User !== "") {
      writer.uint32(18).string(message.User);
    }
    if (message.Error !== "") {
      writer.uint32(26).string(message.Error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginAsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseLoginAsResp } as LoginAsResp;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Session = reader.string();
          break;
        case 2:
          message.User = reader.string();
          break;
        case 3:
          message.Error = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LoginAsResp {
    const message = { ...baseLoginAsResp } as LoginAsResp;
    if (object.Session !== undefined && object.Session !== null) {
      message.Session = String(object.Session);
    } else {
      message.Session = "";
    }
    if (object.User !== undefined && object.User !== null) {
      message.User = String(object.User);
    } else {
      message.User = "";
    }
    if (object.Error !== undefined && object.Error !== null) {
      message.Error = String(object.Error);
    } else {
      message.Error = "";
    }
    return message;
  },

  toJSON(message: LoginAsResp): unknown {
    const obj: any = {};
    message.Session !== undefined && (obj.Session = message.Session);
    message.User !== undefined && (obj.User = message.User);
    message.Error !== undefined && (obj.Error = message.Error);
    return obj;
  },

  fromPartial(object: DeepPartial<LoginAsResp>): LoginAsResp {
    const message = { ...baseLoginAsResp } as LoginAsResp;
    if (object.Session !== undefined && object.Session !== null) {
      message.Session = object.Session;
    } else {
      message.Session = "";
    }
    if (object.User !== undefined && object.User !== null) {
      message.User = object.User;
    } else {
      message.User = "";
    }
    if (object.Error !== undefined && object.Error !== null) {
      message.Error = object.Error;
    } else {
      message.Error = "";
    }
    return message;
  },
};

const baseDummy: object = {};

export const Dummy = {
  encode(_: Dummy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Dummy {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDummy } as Dummy;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): Dummy {
    const message = { ...baseDummy } as Dummy;
    return message;
  },

  toJSON(_: Dummy): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<Dummy>): Dummy {
    const message = { ...baseDummy } as Dummy;
    return message;
  },
};

const baseRemoveReq: object = { ID: "" };

export const RemoveReq = {
  encode(
    message: RemoveReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveReq } as RemoveReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveReq {
    const message = { ...baseRemoveReq } as RemoveReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RemoveReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<RemoveReq>): RemoveReq {
    const message = { ...baseRemoveReq } as RemoveReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseSetDescriptionReq: object = { ID: "", Description: "" };

export const SetDescriptionReq = {
  encode(
    message: SetDescriptionReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Description !== "") {
      writer.uint32(18).string(message.Description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDescriptionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetDescriptionReq } as SetDescriptionReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetDescriptionReq {
    const message = { ...baseSetDescriptionReq } as SetDescriptionReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = String(object.Description);
    } else {
      message.Description = "";
    }
    return message;
  },

  toJSON(message: SetDescriptionReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    return obj;
  },

  fromPartial(object: DeepPartial<SetDescriptionReq>): SetDescriptionReq {
    const message = { ...baseSetDescriptionReq } as SetDescriptionReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Description !== undefined && object.Description !== null) {
      message.Description = object.Description;
    } else {
      message.Description = "";
    }
    return message;
  },
};

const baseSetNameReq: object = { ID: "", Name: "" };

export const SetNameReq = {
  encode(
    message: SetNameReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetNameReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetNameReq } as SetNameReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetNameReq {
    const message = { ...baseSetNameReq } as SetNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    return message;
  },

  toJSON(message: SetNameReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    return obj;
  },

  fromPartial(object: DeepPartial<SetNameReq>): SetNameReq {
    const message = { ...baseSetNameReq } as SetNameReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    return message;
  },
};

const baseSetCodeReq: object = { ID: "", Code: "" };

export const SetCodeReq = {
  encode(
    message: SetCodeReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Code !== "") {
      writer.uint32(18).string(message.Code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetCodeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetCodeReq } as SetCodeReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Code = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetCodeReq {
    const message = { ...baseSetCodeReq } as SetCodeReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Code !== undefined && object.Code !== null) {
      message.Code = String(object.Code);
    } else {
      message.Code = "";
    }
    return message;
  },

  toJSON(message: SetCodeReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Code !== undefined && (obj.Code = message.Code);
    return obj;
  },

  fromPartial(object: DeepPartial<SetCodeReq>): SetCodeReq {
    const message = { ...baseSetCodeReq } as SetCodeReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Code !== undefined && object.Code !== null) {
      message.Code = object.Code;
    } else {
      message.Code = "";
    }
    return message;
  },
};

const baseNumerator: object = {
  ID: "",
  CenterID: "",
  Name: "",
  ModificationTime: 0,
};

export const Numerator = {
  encode(
    message: Numerator,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.CenterID !== "") {
      writer.uint32(18).string(message.CenterID);
    }
    if (message.Name !== "") {
      writer.uint32(26).string(message.Name);
    }
    for (const v of message.Fields) {
      NumeratorField.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.ModificationTime !== 0) {
      writer.uint32(800).int64(message.ModificationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Numerator {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNumerator } as Numerator;
    message.Fields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.CenterID = reader.string();
          break;
        case 3:
          message.Name = reader.string();
          break;
        case 4:
          message.Fields.push(NumeratorField.decode(reader, reader.uint32()));
          break;
        case 100:
          message.ModificationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Numerator {
    const message = { ...baseNumerator } as Numerator;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(NumeratorField.fromJSON(e));
      }
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = Number(object.ModificationTime);
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },

  toJSON(message: Numerator): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.Name !== undefined && (obj.Name = message.Name);
    if (message.Fields) {
      obj.Fields = message.Fields.map((e) =>
        e ? NumeratorField.toJSON(e) : undefined
      );
    } else {
      obj.Fields = [];
    }
    message.ModificationTime !== undefined &&
      (obj.ModificationTime = message.ModificationTime);
    return obj;
  },

  fromPartial(object: DeepPartial<Numerator>): Numerator {
    const message = { ...baseNumerator } as Numerator;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(NumeratorField.fromPartial(e));
      }
    }
    if (
      object.ModificationTime !== undefined &&
      object.ModificationTime !== null
    ) {
      message.ModificationTime = object.ModificationTime;
    } else {
      message.ModificationTime = 0;
    }
    return message;
  },
};

const baseNumeratorField: object = { ID: "", Type: "" };

export const NumeratorField = {
  encode(
    message: NumeratorField,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Type !== "") {
      writer.uint32(18).string(message.Type);
    }
    if (message.Code !== undefined) {
      NumeratorCodeField.encode(
        message.Code,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.Text !== undefined) {
      NumeratorTextField.encode(
        message.Text,
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.Incr !== undefined) {
      NumeratorIncrField.encode(
        message.Incr,
        writer.uint32(42).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NumeratorField {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNumeratorField } as NumeratorField;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Type = reader.string();
          break;
        case 3:
          message.Code = NumeratorCodeField.decode(reader, reader.uint32());
          break;
        case 4:
          message.Text = NumeratorTextField.decode(reader, reader.uint32());
          break;
        case 5:
          message.Incr = NumeratorIncrField.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NumeratorField {
    const message = { ...baseNumeratorField } as NumeratorField;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = String(object.Type);
    } else {
      message.Type = "";
    }
    if (object.Code !== undefined && object.Code !== null) {
      message.Code = NumeratorCodeField.fromJSON(object.Code);
    } else {
      message.Code = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = NumeratorTextField.fromJSON(object.Text);
    } else {
      message.Text = undefined;
    }
    if (object.Incr !== undefined && object.Incr !== null) {
      message.Incr = NumeratorIncrField.fromJSON(object.Incr);
    } else {
      message.Incr = undefined;
    }
    return message;
  },

  toJSON(message: NumeratorField): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Type !== undefined && (obj.Type = message.Type);
    message.Code !== undefined &&
      (obj.Code = message.Code
        ? NumeratorCodeField.toJSON(message.Code)
        : undefined);
    message.Text !== undefined &&
      (obj.Text = message.Text
        ? NumeratorTextField.toJSON(message.Text)
        : undefined);
    message.Incr !== undefined &&
      (obj.Incr = message.Incr
        ? NumeratorIncrField.toJSON(message.Incr)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<NumeratorField>): NumeratorField {
    const message = { ...baseNumeratorField } as NumeratorField;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Type !== undefined && object.Type !== null) {
      message.Type = object.Type;
    } else {
      message.Type = "";
    }
    if (object.Code !== undefined && object.Code !== null) {
      message.Code = NumeratorCodeField.fromPartial(object.Code);
    } else {
      message.Code = undefined;
    }
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = NumeratorTextField.fromPartial(object.Text);
    } else {
      message.Text = undefined;
    }
    if (object.Incr !== undefined && object.Incr !== null) {
      message.Incr = NumeratorIncrField.fromPartial(object.Incr);
    } else {
      message.Incr = undefined;
    }
    return message;
  },
};

const baseSetNumeratorReq: object = { ID: "", Name: "" };

export const SetNumeratorReq = {
  encode(
    message: SetNumeratorReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    for (const v of message.Fields) {
      NumeratorField.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetNumeratorReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetNumeratorReq } as SetNumeratorReq;
    message.Fields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Fields.push(NumeratorField.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetNumeratorReq {
    const message = { ...baseSetNumeratorReq } as SetNumeratorReq;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(NumeratorField.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: SetNumeratorReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    if (message.Fields) {
      obj.Fields = message.Fields.map((e) =>
        e ? NumeratorField.toJSON(e) : undefined
      );
    } else {
      obj.Fields = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SetNumeratorReq>): SetNumeratorReq {
    const message = { ...baseSetNumeratorReq } as SetNumeratorReq;
    message.Fields = [];
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(NumeratorField.fromPartial(e));
      }
    }
    return message;
  },
};

const baseCreateNumeratorReq: object = { CenterID: "", Name: "" };

export const CreateNumeratorReq = {
  encode(
    message: CreateNumeratorReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.CenterID !== "") {
      writer.uint32(10).string(message.CenterID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    for (const v of message.Fields) {
      NumeratorField.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateNumeratorReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateNumeratorReq } as CreateNumeratorReq;
    message.Fields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CenterID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Fields.push(NumeratorField.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateNumeratorReq {
    const message = { ...baseCreateNumeratorReq } as CreateNumeratorReq;
    message.Fields = [];
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = String(object.CenterID);
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = String(object.Name);
    } else {
      message.Name = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(NumeratorField.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: CreateNumeratorReq): unknown {
    const obj: any = {};
    message.CenterID !== undefined && (obj.CenterID = message.CenterID);
    message.Name !== undefined && (obj.Name = message.Name);
    if (message.Fields) {
      obj.Fields = message.Fields.map((e) =>
        e ? NumeratorField.toJSON(e) : undefined
      );
    } else {
      obj.Fields = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<CreateNumeratorReq>): CreateNumeratorReq {
    const message = { ...baseCreateNumeratorReq } as CreateNumeratorReq;
    message.Fields = [];
    if (object.CenterID !== undefined && object.CenterID !== null) {
      message.CenterID = object.CenterID;
    } else {
      message.CenterID = "";
    }
    if (object.Name !== undefined && object.Name !== null) {
      message.Name = object.Name;
    } else {
      message.Name = "";
    }
    if (object.Fields !== undefined && object.Fields !== null) {
      for (const e of object.Fields) {
        message.Fields.push(NumeratorField.fromPartial(e));
      }
    }
    return message;
  },
};

const baseGetNumeratorReq: object = { ID: "" };

export const GetNumeratorReq = {
  encode(
    message: GetNumeratorReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNumeratorReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetNumeratorReq } as GetNumeratorReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetNumeratorReq {
    const message = { ...baseGetNumeratorReq } as GetNumeratorReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: GetNumeratorReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<GetNumeratorReq>): GetNumeratorReq {
    const message = { ...baseGetNumeratorReq } as GetNumeratorReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseRemoveNumeratorReq: object = { ID: "" };

export const RemoveNumeratorReq = {
  encode(
    message: RemoveNumeratorReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveNumeratorReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRemoveNumeratorReq } as RemoveNumeratorReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveNumeratorReq {
    const message = { ...baseRemoveNumeratorReq } as RemoveNumeratorReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    return message;
  },

  toJSON(message: RemoveNumeratorReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial(object: DeepPartial<RemoveNumeratorReq>): RemoveNumeratorReq {
    const message = { ...baseRemoveNumeratorReq } as RemoveNumeratorReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    return message;
  },
};

const baseRemoveNumeratorFieldReq: object = { ID: "", FieldID: "" };

export const RemoveNumeratorFieldReq = {
  encode(
    message: RemoveNumeratorFieldReq,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.FieldID !== "") {
      writer.uint32(18).string(message.FieldID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RemoveNumeratorFieldReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseRemoveNumeratorFieldReq,
    } as RemoveNumeratorFieldReq;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.FieldID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveNumeratorFieldReq {
    const message = {
      ...baseRemoveNumeratorFieldReq,
    } as RemoveNumeratorFieldReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = String(object.ID);
    } else {
      message.ID = "";
    }
    if (object.FieldID !== undefined && object.FieldID !== null) {
      message.FieldID = String(object.FieldID);
    } else {
      message.FieldID = "";
    }
    return message;
  },

  toJSON(message: RemoveNumeratorFieldReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.FieldID !== undefined && (obj.FieldID = message.FieldID);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RemoveNumeratorFieldReq>
  ): RemoveNumeratorFieldReq {
    const message = {
      ...baseRemoveNumeratorFieldReq,
    } as RemoveNumeratorFieldReq;
    if (object.ID !== undefined && object.ID !== null) {
      message.ID = object.ID;
    } else {
      message.ID = "";
    }
    if (object.FieldID !== undefined && object.FieldID !== null) {
      message.FieldID = object.FieldID;
    } else {
      message.FieldID = "";
    }
    return message;
  },
};

const baseNumeratorCodeField: object = { Source: "" };

export const NumeratorCodeField = {
  encode(
    message: NumeratorCodeField,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Source !== "") {
      writer.uint32(10).string(message.Source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NumeratorCodeField {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNumeratorCodeField } as NumeratorCodeField;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Source = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NumeratorCodeField {
    const message = { ...baseNumeratorCodeField } as NumeratorCodeField;
    if (object.Source !== undefined && object.Source !== null) {
      message.Source = String(object.Source);
    } else {
      message.Source = "";
    }
    return message;
  },

  toJSON(message: NumeratorCodeField): unknown {
    const obj: any = {};
    message.Source !== undefined && (obj.Source = message.Source);
    return obj;
  },

  fromPartial(object: DeepPartial<NumeratorCodeField>): NumeratorCodeField {
    const message = { ...baseNumeratorCodeField } as NumeratorCodeField;
    if (object.Source !== undefined && object.Source !== null) {
      message.Source = object.Source;
    } else {
      message.Source = "";
    }
    return message;
  },
};

const baseNumeratorTextField: object = { Text: "" };

export const NumeratorTextField = {
  encode(
    message: NumeratorTextField,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Text !== "") {
      writer.uint32(10).string(message.Text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NumeratorTextField {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNumeratorTextField } as NumeratorTextField;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Text = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NumeratorTextField {
    const message = { ...baseNumeratorTextField } as NumeratorTextField;
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = String(object.Text);
    } else {
      message.Text = "";
    }
    return message;
  },

  toJSON(message: NumeratorTextField): unknown {
    const obj: any = {};
    message.Text !== undefined && (obj.Text = message.Text);
    return obj;
  },

  fromPartial(object: DeepPartial<NumeratorTextField>): NumeratorTextField {
    const message = { ...baseNumeratorTextField } as NumeratorTextField;
    if (object.Text !== undefined && object.Text !== null) {
      message.Text = object.Text;
    } else {
      message.Text = "";
    }
    return message;
  },
};

const baseNumeratorIncrField: object = { Pad: 0, ResetPeriod: "" };

export const NumeratorIncrField = {
  encode(
    message: NumeratorIncrField,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.Pad !== 0) {
      writer.uint32(8).int64(message.Pad);
    }
    if (message.ResetPeriod !== "") {
      writer.uint32(18).string(message.ResetPeriod);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NumeratorIncrField {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseNumeratorIncrField } as NumeratorIncrField;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Pad = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.ResetPeriod = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NumeratorIncrField {
    const message = { ...baseNumeratorIncrField } as NumeratorIncrField;
    if (object.Pad !== undefined && object.Pad !== null) {
      message.Pad = Number(object.Pad);
    } else {
      message.Pad = 0;
    }
    if (object.ResetPeriod !== undefined && object.ResetPeriod !== null) {
      message.ResetPeriod = String(object.ResetPeriod);
    } else {
      message.ResetPeriod = "";
    }
    return message;
  },

  toJSON(message: NumeratorIncrField): unknown {
    const obj: any = {};
    message.Pad !== undefined && (obj.Pad = message.Pad);
    message.ResetPeriod !== undefined &&
      (obj.ResetPeriod = message.ResetPeriod);
    return obj;
  },

  fromPartial(object: DeepPartial<NumeratorIncrField>): NumeratorIncrField {
    const message = { ...baseNumeratorIncrField } as NumeratorIncrField;
    if (object.Pad !== undefined && object.Pad !== null) {
      message.Pad = object.Pad;
    } else {
      message.Pad = 0;
    }
    if (object.ResetPeriod !== undefined && object.ResetPeriod !== null) {
      message.ResetPeriod = object.ResetPeriod;
    } else {
      message.ResetPeriod = "";
    }
    return message;
  },
};

const basePing: object = { Timestamp: 0 };

export const Ping = {
  encode(message: Ping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Timestamp !== 0) {
      writer.uint32(8).int64(message.Timestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ping {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePing } as Ping;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Timestamp = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Ping {
    const message = { ...basePing } as Ping;
    if (object.Timestamp !== undefined && object.Timestamp !== null) {
      message.Timestamp = Number(object.Timestamp);
    } else {
      message.Timestamp = 0;
    }
    return message;
  },

  toJSON(message: Ping): unknown {
    const obj: any = {};
    message.Timestamp !== undefined && (obj.Timestamp = message.Timestamp);
    return obj;
  },

  fromPartial(object: DeepPartial<Ping>): Ping {
    const message = { ...basePing } as Ping;
    if (object.Timestamp !== undefined && object.Timestamp !== null) {
      message.Timestamp = object.Timestamp;
    } else {
      message.Timestamp = 0;
    }
    return message;
  },
};

export interface UserEventsSvc {
  EventStream(
    request: DeepPartial<EventStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<UserEvent>;
}

export class UserEventsSvcClientImpl implements UserEventsSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.EventStream = this.EventStream.bind(this);
  }

  EventStream(
    request: DeepPartial<EventStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<UserEvent> {
    return this.rpc.invoke(
      UserEventsSvcEventStreamDesc,
      EventStreamReq.fromPartial(request),
      metadata
    );
  }
}

export const UserEventsSvcDesc = {
  serviceName: "api.themakers.sc.UserEventsSvc",
};

export const UserEventsSvcEventStreamDesc: UnaryMethodDefinitionish = {
  methodName: "EventStream",
  service: UserEventsSvcDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return EventStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...UserEvent.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface UserSvc {
  GetUsersByRoles(
    request: DeepPartial<GetUsersByRolesReq>,
    metadata?: grpc.Metadata
  ): Observable<User>;
  GetUser(
    request: DeepPartial<GetUserReq>,
    metadata?: grpc.Metadata
  ): Promise<User>;
  SetName(
    request: DeepPartial<SetUserNameReq>,
    metadata?: grpc.Metadata
  ): Promise<User>;
  SetEmail(
    request: DeepPartial<SetUserEmailReq>,
    metadata?: grpc.Metadata
  ): Promise<User>;
  SetPhone(
    request: DeepPartial<SetUserPhoneReq>,
    metadata?: grpc.Metadata
  ): Promise<User>;
  Retire(
    request: DeepPartial<RetireReq>,
    metadata?: grpc.Metadata
  ): Promise<User>;
  ChangeRoles(
    request: DeepPartial<RolesOp>,
    metadata?: grpc.Metadata
  ): Promise<User>;
}

export class UserSvcClientImpl implements UserSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetUsersByRoles = this.GetUsersByRoles.bind(this);
    this.GetUser = this.GetUser.bind(this);
    this.SetName = this.SetName.bind(this);
    this.SetEmail = this.SetEmail.bind(this);
    this.SetPhone = this.SetPhone.bind(this);
    this.Retire = this.Retire.bind(this);
    this.ChangeRoles = this.ChangeRoles.bind(this);
  }

  GetUsersByRoles(
    request: DeepPartial<GetUsersByRolesReq>,
    metadata?: grpc.Metadata
  ): Observable<User> {
    return this.rpc.invoke(
      UserSvcGetUsersByRolesDesc,
      GetUsersByRolesReq.fromPartial(request),
      metadata
    );
  }

  GetUser(
    request: DeepPartial<GetUserReq>,
    metadata?: grpc.Metadata
  ): Promise<User> {
    return this.rpc.unary(
      UserSvcGetUserDesc,
      GetUserReq.fromPartial(request),
      metadata
    );
  }

  SetName(
    request: DeepPartial<SetUserNameReq>,
    metadata?: grpc.Metadata
  ): Promise<User> {
    return this.rpc.unary(
      UserSvcSetNameDesc,
      SetUserNameReq.fromPartial(request),
      metadata
    );
  }

  SetEmail(
    request: DeepPartial<SetUserEmailReq>,
    metadata?: grpc.Metadata
  ): Promise<User> {
    return this.rpc.unary(
      UserSvcSetEmailDesc,
      SetUserEmailReq.fromPartial(request),
      metadata
    );
  }

  SetPhone(
    request: DeepPartial<SetUserPhoneReq>,
    metadata?: grpc.Metadata
  ): Promise<User> {
    return this.rpc.unary(
      UserSvcSetPhoneDesc,
      SetUserPhoneReq.fromPartial(request),
      metadata
    );
  }

  Retire(
    request: DeepPartial<RetireReq>,
    metadata?: grpc.Metadata
  ): Promise<User> {
    return this.rpc.unary(
      UserSvcRetireDesc,
      RetireReq.fromPartial(request),
      metadata
    );
  }

  ChangeRoles(
    request: DeepPartial<RolesOp>,
    metadata?: grpc.Metadata
  ): Promise<User> {
    return this.rpc.unary(
      UserSvcChangeRolesDesc,
      RolesOp.fromPartial(request),
      metadata
    );
  }
}

export const UserSvcDesc = {
  serviceName: "api.themakers.sc.UserSvc",
};

export const UserSvcGetUsersByRolesDesc: UnaryMethodDefinitionish = {
  methodName: "GetUsersByRoles",
  service: UserSvcDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return GetUsersByRolesReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...User.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserSvcGetUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetUser",
  service: UserSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...User.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserSvcSetNameDesc: UnaryMethodDefinitionish = {
  methodName: "SetName",
  service: UserSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUserNameReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...User.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserSvcSetEmailDesc: UnaryMethodDefinitionish = {
  methodName: "SetEmail",
  service: UserSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUserEmailReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...User.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserSvcSetPhoneDesc: UnaryMethodDefinitionish = {
  methodName: "SetPhone",
  service: UserSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetUserPhoneReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...User.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserSvcRetireDesc: UnaryMethodDefinitionish = {
  methodName: "Retire",
  service: UserSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RetireReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...User.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UserSvcChangeRolesDesc: UnaryMethodDefinitionish = {
  methodName: "ChangeRoles",
  service: UserSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RolesOp.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...User.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface DocumentTypeSvc {
  Create(
    request: DeepPartial<CreateDocumentTypeReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
  Remove(
    request: DeepPartial<RemoveReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  SetName(
    request: DeepPartial<SetNameReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
  SetDescription(
    request: DeepPartial<SetDescriptionReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
  SetCode(
    request: DeepPartial<SetCodeReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
  SetNumerator(
    request: DeepPartial<SetnumeratorDocumenttypereq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
  RearangeRequisites(
    request: DeepPartial<RearangeRequisitesReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
  AddRequisite(
    request: DeepPartial<AddDocumentTypeRequisiteReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
  UpdateRequisite(
    request: DeepPartial<UpdateDocumentTypeRequisiteReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
  RemoveRequisite(
    request: DeepPartial<RemoveDocumentTypeRequisite>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType>;
}

export class DocumentTypeSvcClientImpl implements DocumentTypeSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Create = this.Create.bind(this);
    this.Remove = this.Remove.bind(this);
    this.SetName = this.SetName.bind(this);
    this.SetDescription = this.SetDescription.bind(this);
    this.SetCode = this.SetCode.bind(this);
    this.SetNumerator = this.SetNumerator.bind(this);
    this.RearangeRequisites = this.RearangeRequisites.bind(this);
    this.AddRequisite = this.AddRequisite.bind(this);
    this.UpdateRequisite = this.UpdateRequisite.bind(this);
    this.RemoveRequisite = this.RemoveRequisite.bind(this);
  }

  Create(
    request: DeepPartial<CreateDocumentTypeReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcCreateDesc,
      CreateDocumentTypeReq.fromPartial(request),
      metadata
    );
  }

  Remove(
    request: DeepPartial<RemoveReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      DocumentTypeSvcRemoveDesc,
      RemoveReq.fromPartial(request),
      metadata
    );
  }

  SetName(
    request: DeepPartial<SetNameReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcSetNameDesc,
      SetNameReq.fromPartial(request),
      metadata
    );
  }

  SetDescription(
    request: DeepPartial<SetDescriptionReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcSetDescriptionDesc,
      SetDescriptionReq.fromPartial(request),
      metadata
    );
  }

  SetCode(
    request: DeepPartial<SetCodeReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcSetCodeDesc,
      SetCodeReq.fromPartial(request),
      metadata
    );
  }

  SetNumerator(
    request: DeepPartial<SetnumeratorDocumenttypereq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcSetNumeratorDesc,
      SetnumeratorDocumenttypereq.fromPartial(request),
      metadata
    );
  }

  RearangeRequisites(
    request: DeepPartial<RearangeRequisitesReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcRearangeRequisitesDesc,
      RearangeRequisitesReq.fromPartial(request),
      metadata
    );
  }

  AddRequisite(
    request: DeepPartial<AddDocumentTypeRequisiteReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcAddRequisiteDesc,
      AddDocumentTypeRequisiteReq.fromPartial(request),
      metadata
    );
  }

  UpdateRequisite(
    request: DeepPartial<UpdateDocumentTypeRequisiteReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcUpdateRequisiteDesc,
      UpdateDocumentTypeRequisiteReq.fromPartial(request),
      metadata
    );
  }

  RemoveRequisite(
    request: DeepPartial<RemoveDocumentTypeRequisite>,
    metadata?: grpc.Metadata
  ): Promise<DocumentType> {
    return this.rpc.unary(
      DocumentTypeSvcRemoveRequisiteDesc,
      RemoveDocumentTypeRequisite.fromPartial(request),
      metadata
    );
  }
}

export const DocumentTypeSvcDesc = {
  serviceName: "api.themakers.sc.DocumentTypeSvc",
};

export const DocumentTypeSvcCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateDocumentTypeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcRemoveDesc: UnaryMethodDefinitionish = {
  methodName: "Remove",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcSetNameDesc: UnaryMethodDefinitionish = {
  methodName: "SetName",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetNameReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcSetDescriptionDesc: UnaryMethodDefinitionish = {
  methodName: "SetDescription",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetDescriptionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcSetCodeDesc: UnaryMethodDefinitionish = {
  methodName: "SetCode",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetCodeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcSetNumeratorDesc: UnaryMethodDefinitionish = {
  methodName: "SetNumerator",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetnumeratorDocumenttypereq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcRearangeRequisitesDesc: UnaryMethodDefinitionish = {
  methodName: "RearangeRequisites",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RearangeRequisitesReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcAddRequisiteDesc: UnaryMethodDefinitionish = {
  methodName: "AddRequisite",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddDocumentTypeRequisiteReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcUpdateRequisiteDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateRequisite",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDocumentTypeRequisiteReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentTypeSvcRemoveRequisiteDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveRequisite",
  service: DocumentTypeSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveDocumentTypeRequisite.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentType.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface DocumentSvc {
  ListStream(
    request: DeepPartial<ListStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<DocumentListStreamMessage>;
  Stream(
    request: DeepPartial<DocumentStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<DocumentStreamMessage>;
  ArchivedListStream(
    request: DeepPartial<ArchivedListStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<ArchivedListStreamMessage>;
  NewDocument(
    request: DeepPartial<NewDocumentReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  Remove(
    request: DeepPartial<RemoveDocumentReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  Draft(
    request: DeepPartial<DocumentDraftReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  Archive(
    request: DeepPartial<DocumentArchiveReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  Start(
    request: DeepPartial<DocumentStartReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  UpdateName(
    request: DeepPartial<UpdateDocumentNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  UpdateRequisite(
    request: DeepPartial<UpdateDocumentRequisiteReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  UpdateComment(
    request: DeepPartial<UpdateDocumentCommentReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  PutMessage(
    request: DeepPartial<DocumentPutMessageReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentEvent>;
  Register(
    request: DeepPartial<DocumentRegisterReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  SetLinks(
    request: DeepPartial<SetLinksReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  AddWorkflowTask(
    request: DeepPartial<AddWorkflowTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  AddActorToWorkflowApproval(
    request: DeepPartial<AddActorToWorkflowApprovalReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  RemoveWorkflowTask(
    request: DeepPartial<RemoveWorkflowTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
}

export class DocumentSvcClientImpl implements DocumentSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListStream = this.ListStream.bind(this);
    this.Stream = this.Stream.bind(this);
    this.ArchivedListStream = this.ArchivedListStream.bind(this);
    this.NewDocument = this.NewDocument.bind(this);
    this.Remove = this.Remove.bind(this);
    this.Draft = this.Draft.bind(this);
    this.Archive = this.Archive.bind(this);
    this.Start = this.Start.bind(this);
    this.UpdateName = this.UpdateName.bind(this);
    this.UpdateRequisite = this.UpdateRequisite.bind(this);
    this.UpdateComment = this.UpdateComment.bind(this);
    this.PutMessage = this.PutMessage.bind(this);
    this.Register = this.Register.bind(this);
    this.SetLinks = this.SetLinks.bind(this);
    this.AddWorkflowTask = this.AddWorkflowTask.bind(this);
    this.AddActorToWorkflowApproval =
      this.AddActorToWorkflowApproval.bind(this);
    this.RemoveWorkflowTask = this.RemoveWorkflowTask.bind(this);
  }

  ListStream(
    request: DeepPartial<ListStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<DocumentListStreamMessage> {
    return this.rpc.invoke(
      DocumentSvcListStreamDesc,
      ListStreamReq.fromPartial(request),
      metadata
    );
  }

  Stream(
    request: DeepPartial<DocumentStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<DocumentStreamMessage> {
    return this.rpc.invoke(
      DocumentSvcStreamDesc,
      DocumentStreamReq.fromPartial(request),
      metadata
    );
  }

  ArchivedListStream(
    request: DeepPartial<ArchivedListStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<ArchivedListStreamMessage> {
    return this.rpc.invoke(
      DocumentSvcArchivedListStreamDesc,
      ArchivedListStreamReq.fromPartial(request),
      metadata
    );
  }

  NewDocument(
    request: DeepPartial<NewDocumentReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcNewDocumentDesc,
      NewDocumentReq.fromPartial(request),
      metadata
    );
  }

  Remove(
    request: DeepPartial<RemoveDocumentReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      DocumentSvcRemoveDesc,
      RemoveDocumentReq.fromPartial(request),
      metadata
    );
  }

  Draft(
    request: DeepPartial<DocumentDraftReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcDraftDesc,
      DocumentDraftReq.fromPartial(request),
      metadata
    );
  }

  Archive(
    request: DeepPartial<DocumentArchiveReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcArchiveDesc,
      DocumentArchiveReq.fromPartial(request),
      metadata
    );
  }

  Start(
    request: DeepPartial<DocumentStartReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcStartDesc,
      DocumentStartReq.fromPartial(request),
      metadata
    );
  }

  UpdateName(
    request: DeepPartial<UpdateDocumentNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcUpdateNameDesc,
      UpdateDocumentNameReq.fromPartial(request),
      metadata
    );
  }

  UpdateRequisite(
    request: DeepPartial<UpdateDocumentRequisiteReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcUpdateRequisiteDesc,
      UpdateDocumentRequisiteReq.fromPartial(request),
      metadata
    );
  }

  UpdateComment(
    request: DeepPartial<UpdateDocumentCommentReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcUpdateCommentDesc,
      UpdateDocumentCommentReq.fromPartial(request),
      metadata
    );
  }

  PutMessage(
    request: DeepPartial<DocumentPutMessageReq>,
    metadata?: grpc.Metadata
  ): Promise<DocumentEvent> {
    return this.rpc.unary(
      DocumentSvcPutMessageDesc,
      DocumentPutMessageReq.fromPartial(request),
      metadata
    );
  }

  Register(
    request: DeepPartial<DocumentRegisterReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcRegisterDesc,
      DocumentRegisterReq.fromPartial(request),
      metadata
    );
  }

  SetLinks(
    request: DeepPartial<SetLinksReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcSetLinksDesc,
      SetLinksReq.fromPartial(request),
      metadata
    );
  }

  AddWorkflowTask(
    request: DeepPartial<AddWorkflowTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcAddWorkflowTaskDesc,
      AddWorkflowTaskReq.fromPartial(request),
      metadata
    );
  }

  AddActorToWorkflowApproval(
    request: DeepPartial<AddActorToWorkflowApprovalReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcAddActorToWorkflowApprovalDesc,
      AddActorToWorkflowApprovalReq.fromPartial(request),
      metadata
    );
  }

  RemoveWorkflowTask(
    request: DeepPartial<RemoveWorkflowTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      DocumentSvcRemoveWorkflowTaskDesc,
      RemoveWorkflowTaskReq.fromPartial(request),
      metadata
    );
  }
}

export const DocumentSvcDesc = {
  serviceName: "api.themakers.sc.DocumentSvc",
};

export const DocumentSvcListStreamDesc: UnaryMethodDefinitionish = {
  methodName: "ListStream",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return ListStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentListStreamMessage.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcStreamDesc: UnaryMethodDefinitionish = {
  methodName: "Stream",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return DocumentStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentStreamMessage.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcArchivedListStreamDesc: UnaryMethodDefinitionish = {
  methodName: "ArchivedListStream",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return ArchivedListStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ArchivedListStreamMessage.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcNewDocumentDesc: UnaryMethodDefinitionish = {
  methodName: "NewDocument",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return NewDocumentReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcRemoveDesc: UnaryMethodDefinitionish = {
  methodName: "Remove",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveDocumentReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcDraftDesc: UnaryMethodDefinitionish = {
  methodName: "Draft",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DocumentDraftReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcArchiveDesc: UnaryMethodDefinitionish = {
  methodName: "Archive",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DocumentArchiveReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcStartDesc: UnaryMethodDefinitionish = {
  methodName: "Start",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DocumentStartReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcUpdateNameDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateName",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDocumentNameReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcUpdateRequisiteDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateRequisite",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDocumentRequisiteReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcUpdateCommentDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateComment",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDocumentCommentReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcPutMessageDesc: UnaryMethodDefinitionish = {
  methodName: "PutMessage",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DocumentPutMessageReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DocumentEvent.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcRegisterDesc: UnaryMethodDefinitionish = {
  methodName: "Register",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DocumentRegisterReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcSetLinksDesc: UnaryMethodDefinitionish = {
  methodName: "SetLinks",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetLinksReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcAddWorkflowTaskDesc: UnaryMethodDefinitionish = {
  methodName: "AddWorkflowTask",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddWorkflowTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DocumentSvcAddActorToWorkflowApprovalDesc: UnaryMethodDefinitionish =
  {
    methodName: "AddActorToWorkflowApproval",
    service: DocumentSvcDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return AddActorToWorkflowApprovalReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...Document.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const DocumentSvcRemoveWorkflowTaskDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveWorkflowTask",
  service: DocumentSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveWorkflowTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface WorkflowSvc {
  Approval_Accept(
    request: DeepPartial<WorkflowApprovalAcceptreq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  Approval_DropTo(
    request: DeepPartial<WorkflowApprovalDroptoreq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  Task_Complete(
    request: DeepPartial<WorkflowTaskCompletereq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  Task_Confirm(
    request: DeepPartial<WorkflowTaskConfirmreq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  Task_Reject(
    request: DeepPartial<WorkflowTaskRejectreq>,
    metadata?: grpc.Metadata
  ): Promise<Document>;
  SetWorkflowTemplate(
    request: DeepPartial<SetWorkflowTemplateReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  GetWorkflowTemplate(
    request: DeepPartial<GetWorkflowTemplateReq>,
    metadata?: grpc.Metadata
  ): Promise<WorkflowTemplate>;
}

export class WorkflowSvcClientImpl implements WorkflowSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Approval_Accept = this.Approval_Accept.bind(this);
    this.Approval_DropTo = this.Approval_DropTo.bind(this);
    this.Task_Complete = this.Task_Complete.bind(this);
    this.Task_Confirm = this.Task_Confirm.bind(this);
    this.Task_Reject = this.Task_Reject.bind(this);
    this.SetWorkflowTemplate = this.SetWorkflowTemplate.bind(this);
    this.GetWorkflowTemplate = this.GetWorkflowTemplate.bind(this);
  }

  Approval_Accept(
    request: DeepPartial<WorkflowApprovalAcceptreq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      WorkflowSvcApproval_AcceptDesc,
      WorkflowApprovalAcceptreq.fromPartial(request),
      metadata
    );
  }

  Approval_DropTo(
    request: DeepPartial<WorkflowApprovalDroptoreq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      WorkflowSvcApproval_DropToDesc,
      WorkflowApprovalDroptoreq.fromPartial(request),
      metadata
    );
  }

  Task_Complete(
    request: DeepPartial<WorkflowTaskCompletereq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      WorkflowSvcTask_CompleteDesc,
      WorkflowTaskCompletereq.fromPartial(request),
      metadata
    );
  }

  Task_Confirm(
    request: DeepPartial<WorkflowTaskConfirmreq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      WorkflowSvcTask_ConfirmDesc,
      WorkflowTaskConfirmreq.fromPartial(request),
      metadata
    );
  }

  Task_Reject(
    request: DeepPartial<WorkflowTaskRejectreq>,
    metadata?: grpc.Metadata
  ): Promise<Document> {
    return this.rpc.unary(
      WorkflowSvcTask_RejectDesc,
      WorkflowTaskRejectreq.fromPartial(request),
      metadata
    );
  }

  SetWorkflowTemplate(
    request: DeepPartial<SetWorkflowTemplateReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      WorkflowSvcSetWorkflowTemplateDesc,
      SetWorkflowTemplateReq.fromPartial(request),
      metadata
    );
  }

  GetWorkflowTemplate(
    request: DeepPartial<GetWorkflowTemplateReq>,
    metadata?: grpc.Metadata
  ): Promise<WorkflowTemplate> {
    return this.rpc.unary(
      WorkflowSvcGetWorkflowTemplateDesc,
      GetWorkflowTemplateReq.fromPartial(request),
      metadata
    );
  }
}

export const WorkflowSvcDesc = {
  serviceName: "api.themakers.sc.WorkflowSvc",
};

export const WorkflowSvcApproval_AcceptDesc: UnaryMethodDefinitionish = {
  methodName: "Approval_Accept",
  service: WorkflowSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return WorkflowApprovalAcceptreq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const WorkflowSvcApproval_DropToDesc: UnaryMethodDefinitionish = {
  methodName: "Approval_DropTo",
  service: WorkflowSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return WorkflowApprovalDroptoreq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const WorkflowSvcTask_CompleteDesc: UnaryMethodDefinitionish = {
  methodName: "Task_Complete",
  service: WorkflowSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return WorkflowTaskCompletereq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const WorkflowSvcTask_ConfirmDesc: UnaryMethodDefinitionish = {
  methodName: "Task_Confirm",
  service: WorkflowSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return WorkflowTaskConfirmreq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const WorkflowSvcTask_RejectDesc: UnaryMethodDefinitionish = {
  methodName: "Task_Reject",
  service: WorkflowSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return WorkflowTaskRejectreq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Document.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const WorkflowSvcSetWorkflowTemplateDesc: UnaryMethodDefinitionish = {
  methodName: "SetWorkflowTemplate",
  service: WorkflowSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetWorkflowTemplateReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const WorkflowSvcGetWorkflowTemplateDesc: UnaryMethodDefinitionish = {
  methodName: "GetWorkflowTemplate",
  service: WorkflowSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetWorkflowTemplateReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...WorkflowTemplate.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface TaskSvc {
  ArchivedTasksStream(
    request: DeepPartial<ArchivedTasksStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<ArchivedTasksStreamMessage>;
  Create(
    request: DeepPartial<CreateTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task>;
  Complete(
    request: DeepPartial<CompleteTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task>;
  Reject(
    request: DeepPartial<RejectTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task>;
  Confirm(
    request: DeepPartial<ConfirmTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task>;
  Reopen(
    request: DeepPartial<ReopenReq>,
    metadata?: grpc.Metadata
  ): Promise<Task>;
  Close(
    request: DeepPartial<CloseTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task>;
  Remove(
    request: DeepPartial<RemoveTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  Delegate(
    request: DeepPartial<DelegateTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task>;
  TaskStream(
    request: DeepPartial<TaskStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<TaskStreamMessage>;
  PutMessage(
    request: DeepPartial<TaskPutMessageReq>,
    metadata?: grpc.Metadata
  ): Promise<TaskEvent>;
}

export class TaskSvcClientImpl implements TaskSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ArchivedTasksStream = this.ArchivedTasksStream.bind(this);
    this.Create = this.Create.bind(this);
    this.Complete = this.Complete.bind(this);
    this.Reject = this.Reject.bind(this);
    this.Confirm = this.Confirm.bind(this);
    this.Reopen = this.Reopen.bind(this);
    this.Close = this.Close.bind(this);
    this.Remove = this.Remove.bind(this);
    this.Delegate = this.Delegate.bind(this);
    this.TaskStream = this.TaskStream.bind(this);
    this.PutMessage = this.PutMessage.bind(this);
  }

  ArchivedTasksStream(
    request: DeepPartial<ArchivedTasksStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<ArchivedTasksStreamMessage> {
    return this.rpc.invoke(
      TaskSvcArchivedTasksStreamDesc,
      ArchivedTasksStreamReq.fromPartial(request),
      metadata
    );
  }

  Create(
    request: DeepPartial<CreateTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task> {
    return this.rpc.unary(
      TaskSvcCreateDesc,
      CreateTaskReq.fromPartial(request),
      metadata
    );
  }

  Complete(
    request: DeepPartial<CompleteTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task> {
    return this.rpc.unary(
      TaskSvcCompleteDesc,
      CompleteTaskReq.fromPartial(request),
      metadata
    );
  }

  Reject(
    request: DeepPartial<RejectTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task> {
    return this.rpc.unary(
      TaskSvcRejectDesc,
      RejectTaskReq.fromPartial(request),
      metadata
    );
  }

  Confirm(
    request: DeepPartial<ConfirmTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task> {
    return this.rpc.unary(
      TaskSvcConfirmDesc,
      ConfirmTaskReq.fromPartial(request),
      metadata
    );
  }

  Reopen(
    request: DeepPartial<ReopenReq>,
    metadata?: grpc.Metadata
  ): Promise<Task> {
    return this.rpc.unary(
      TaskSvcReopenDesc,
      ReopenReq.fromPartial(request),
      metadata
    );
  }

  Close(
    request: DeepPartial<CloseTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task> {
    return this.rpc.unary(
      TaskSvcCloseDesc,
      CloseTaskReq.fromPartial(request),
      metadata
    );
  }

  Remove(
    request: DeepPartial<RemoveTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      TaskSvcRemoveDesc,
      RemoveTaskReq.fromPartial(request),
      metadata
    );
  }

  Delegate(
    request: DeepPartial<DelegateTaskReq>,
    metadata?: grpc.Metadata
  ): Promise<Task> {
    return this.rpc.unary(
      TaskSvcDelegateDesc,
      DelegateTaskReq.fromPartial(request),
      metadata
    );
  }

  TaskStream(
    request: DeepPartial<TaskStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<TaskStreamMessage> {
    return this.rpc.invoke(
      TaskSvcTaskStreamDesc,
      TaskStreamReq.fromPartial(request),
      metadata
    );
  }

  PutMessage(
    request: DeepPartial<TaskPutMessageReq>,
    metadata?: grpc.Metadata
  ): Promise<TaskEvent> {
    return this.rpc.unary(
      TaskSvcPutMessageDesc,
      TaskPutMessageReq.fromPartial(request),
      metadata
    );
  }
}

export const TaskSvcDesc = {
  serviceName: "api.themakers.sc.TaskSvc",
};

export const TaskSvcArchivedTasksStreamDesc: UnaryMethodDefinitionish = {
  methodName: "ArchivedTasksStream",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return ArchivedTasksStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ArchivedTasksStreamMessage.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Task.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcCompleteDesc: UnaryMethodDefinitionish = {
  methodName: "Complete",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CompleteTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Task.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcRejectDesc: UnaryMethodDefinitionish = {
  methodName: "Reject",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RejectTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Task.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcConfirmDesc: UnaryMethodDefinitionish = {
  methodName: "Confirm",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ConfirmTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Task.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcReopenDesc: UnaryMethodDefinitionish = {
  methodName: "Reopen",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ReopenReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Task.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcCloseDesc: UnaryMethodDefinitionish = {
  methodName: "Close",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CloseTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Task.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcRemoveDesc: UnaryMethodDefinitionish = {
  methodName: "Remove",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcDelegateDesc: UnaryMethodDefinitionish = {
  methodName: "Delegate",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DelegateTaskReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Task.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcTaskStreamDesc: UnaryMethodDefinitionish = {
  methodName: "TaskStream",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return TaskStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...TaskStreamMessage.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const TaskSvcPutMessageDesc: UnaryMethodDefinitionish = {
  methodName: "PutMessage",
  service: TaskSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return TaskPutMessageReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...TaskEvent.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

/**
 * //////////////////////////////////////////////////////////////
 * //                ////////////////////////////////////////////
 * //  Unit          ////////////////////////////////////////////
 * //                ////////////////////////////////////////////
 * //////////////////////////////////////////////////////////////
 */
export interface UnitSvc {
  Create(
    request: DeepPartial<CreateUnitReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit>;
  Remove(
    request: DeepPartial<RemoveUnitReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  Move(
    request: DeepPartial<MoveUnitReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit>;
  MarkAsRoot(
    request: DeepPartial<MarkAsRootReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit>;
  SetName(
    request: DeepPartial<SetNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit>;
  SetCode(
    request: DeepPartial<SetCodeReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit>;
  GetTosser(
    request: DeepPartial<GetTosserReq>,
    metadata?: grpc.Metadata
  ): Promise<UnitTosser>;
  SetTosser(
    request: DeepPartial<SetTosserReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
}

export class UnitSvcClientImpl implements UnitSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Create = this.Create.bind(this);
    this.Remove = this.Remove.bind(this);
    this.Move = this.Move.bind(this);
    this.MarkAsRoot = this.MarkAsRoot.bind(this);
    this.SetName = this.SetName.bind(this);
    this.SetCode = this.SetCode.bind(this);
    this.GetTosser = this.GetTosser.bind(this);
    this.SetTosser = this.SetTosser.bind(this);
  }

  Create(
    request: DeepPartial<CreateUnitReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit> {
    return this.rpc.unary(
      UnitSvcCreateDesc,
      CreateUnitReq.fromPartial(request),
      metadata
    );
  }

  Remove(
    request: DeepPartial<RemoveUnitReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      UnitSvcRemoveDesc,
      RemoveUnitReq.fromPartial(request),
      metadata
    );
  }

  Move(
    request: DeepPartial<MoveUnitReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit> {
    return this.rpc.unary(
      UnitSvcMoveDesc,
      MoveUnitReq.fromPartial(request),
      metadata
    );
  }

  MarkAsRoot(
    request: DeepPartial<MarkAsRootReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit> {
    return this.rpc.unary(
      UnitSvcMarkAsRootDesc,
      MarkAsRootReq.fromPartial(request),
      metadata
    );
  }

  SetName(
    request: DeepPartial<SetNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit> {
    return this.rpc.unary(
      UnitSvcSetNameDesc,
      SetNameReq.fromPartial(request),
      metadata
    );
  }

  SetCode(
    request: DeepPartial<SetCodeReq>,
    metadata?: grpc.Metadata
  ): Promise<Unit> {
    return this.rpc.unary(
      UnitSvcSetCodeDesc,
      SetCodeReq.fromPartial(request),
      metadata
    );
  }

  GetTosser(
    request: DeepPartial<GetTosserReq>,
    metadata?: grpc.Metadata
  ): Promise<UnitTosser> {
    return this.rpc.unary(
      UnitSvcGetTosserDesc,
      GetTosserReq.fromPartial(request),
      metadata
    );
  }

  SetTosser(
    request: DeepPartial<SetTosserReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      UnitSvcSetTosserDesc,
      SetTosserReq.fromPartial(request),
      metadata
    );
  }
}

export const UnitSvcDesc = {
  serviceName: "api.themakers.sc.UnitSvc",
};

export const UnitSvcCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: UnitSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateUnitReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Unit.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UnitSvcRemoveDesc: UnaryMethodDefinitionish = {
  methodName: "Remove",
  service: UnitSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveUnitReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UnitSvcMoveDesc: UnaryMethodDefinitionish = {
  methodName: "Move",
  service: UnitSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MoveUnitReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Unit.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UnitSvcMarkAsRootDesc: UnaryMethodDefinitionish = {
  methodName: "MarkAsRoot",
  service: UnitSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MarkAsRootReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Unit.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UnitSvcSetNameDesc: UnaryMethodDefinitionish = {
  methodName: "SetName",
  service: UnitSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetNameReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Unit.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UnitSvcSetCodeDesc: UnaryMethodDefinitionish = {
  methodName: "SetCode",
  service: UnitSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetCodeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Unit.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UnitSvcGetTosserDesc: UnaryMethodDefinitionish = {
  methodName: "GetTosser",
  service: UnitSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTosserReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...UnitTosser.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const UnitSvcSetTosserDesc: UnaryMethodDefinitionish = {
  methodName: "SetTosser",
  service: UnitSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetTosserReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface FamiliarizationSvc {
  Stream(
    request: DeepPartial<FamiliarizationStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<FamiliarizationStreamMessage>;
  ArchivedListStream(
    request: DeepPartial<ArchivedListFamiliarizationStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<ArchivedFamiliarizationStreamMessage>;
  /**  */
  Create(
    request: DeepPartial<CreateFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization>;
  Update(
    request: DeepPartial<UpdateFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization>;
  AddMembers(
    request: DeepPartial<AddMembersReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization>;
  Familiarize(
    request: DeepPartial<FamiliarizeReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization>;
  Remove(
    request: DeepPartial<RemoveFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  Active(
    request: DeepPartial<ActiveFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization>;
  Draft(
    request: DeepPartial<DraftFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization>;
  Archive(
    request: DeepPartial<ArchiveFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization>;
}

export class FamiliarizationSvcClientImpl implements FamiliarizationSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Stream = this.Stream.bind(this);
    this.ArchivedListStream = this.ArchivedListStream.bind(this);
    this.Create = this.Create.bind(this);
    this.Update = this.Update.bind(this);
    this.AddMembers = this.AddMembers.bind(this);
    this.Familiarize = this.Familiarize.bind(this);
    this.Remove = this.Remove.bind(this);
    this.Active = this.Active.bind(this);
    this.Draft = this.Draft.bind(this);
    this.Archive = this.Archive.bind(this);
  }

  Stream(
    request: DeepPartial<FamiliarizationStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<FamiliarizationStreamMessage> {
    return this.rpc.invoke(
      FamiliarizationSvcStreamDesc,
      FamiliarizationStreamReq.fromPartial(request),
      metadata
    );
  }

  ArchivedListStream(
    request: DeepPartial<ArchivedListFamiliarizationStreamReq>,
    metadata?: grpc.Metadata
  ): Observable<ArchivedFamiliarizationStreamMessage> {
    return this.rpc.invoke(
      FamiliarizationSvcArchivedListStreamDesc,
      ArchivedListFamiliarizationStreamReq.fromPartial(request),
      metadata
    );
  }

  Create(
    request: DeepPartial<CreateFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization> {
    return this.rpc.unary(
      FamiliarizationSvcCreateDesc,
      CreateFamiliarizationReq.fromPartial(request),
      metadata
    );
  }

  Update(
    request: DeepPartial<UpdateFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization> {
    return this.rpc.unary(
      FamiliarizationSvcUpdateDesc,
      UpdateFamiliarizationReq.fromPartial(request),
      metadata
    );
  }

  AddMembers(
    request: DeepPartial<AddMembersReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization> {
    return this.rpc.unary(
      FamiliarizationSvcAddMembersDesc,
      AddMembersReq.fromPartial(request),
      metadata
    );
  }

  Familiarize(
    request: DeepPartial<FamiliarizeReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization> {
    return this.rpc.unary(
      FamiliarizationSvcFamiliarizeDesc,
      FamiliarizeReq.fromPartial(request),
      metadata
    );
  }

  Remove(
    request: DeepPartial<RemoveFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      FamiliarizationSvcRemoveDesc,
      RemoveFamiliarizationReq.fromPartial(request),
      metadata
    );
  }

  Active(
    request: DeepPartial<ActiveFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization> {
    return this.rpc.unary(
      FamiliarizationSvcActiveDesc,
      ActiveFamiliarizationReq.fromPartial(request),
      metadata
    );
  }

  Draft(
    request: DeepPartial<DraftFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization> {
    return this.rpc.unary(
      FamiliarizationSvcDraftDesc,
      DraftFamiliarizationReq.fromPartial(request),
      metadata
    );
  }

  Archive(
    request: DeepPartial<ArchiveFamiliarizationReq>,
    metadata?: grpc.Metadata
  ): Promise<Familiarization> {
    return this.rpc.unary(
      FamiliarizationSvcArchiveDesc,
      ArchiveFamiliarizationReq.fromPartial(request),
      metadata
    );
  }
}

export const FamiliarizationSvcDesc = {
  serviceName: "api.themakers.sc.FamiliarizationSvc",
};

export const FamiliarizationSvcStreamDesc: UnaryMethodDefinitionish = {
  methodName: "Stream",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return FamiliarizationStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...FamiliarizationStreamMessage.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const FamiliarizationSvcArchivedListStreamDesc: UnaryMethodDefinitionish =
  {
    methodName: "ArchivedListStream",
    service: FamiliarizationSvcDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
      serializeBinary() {
        return ArchivedListFamiliarizationStreamReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ArchivedFamiliarizationStreamMessage.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const FamiliarizationSvcCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateFamiliarizationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Familiarization.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const FamiliarizationSvcUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "Update",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFamiliarizationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Familiarization.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const FamiliarizationSvcAddMembersDesc: UnaryMethodDefinitionish = {
  methodName: "AddMembers",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddMembersReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Familiarization.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const FamiliarizationSvcFamiliarizeDesc: UnaryMethodDefinitionish = {
  methodName: "Familiarize",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FamiliarizeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Familiarization.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const FamiliarizationSvcRemoveDesc: UnaryMethodDefinitionish = {
  methodName: "Remove",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveFamiliarizationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const FamiliarizationSvcActiveDesc: UnaryMethodDefinitionish = {
  methodName: "Active",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ActiveFamiliarizationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Familiarization.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const FamiliarizationSvcDraftDesc: UnaryMethodDefinitionish = {
  methodName: "Draft",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DraftFamiliarizationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Familiarization.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const FamiliarizationSvcArchiveDesc: UnaryMethodDefinitionish = {
  methodName: "Archive",
  service: FamiliarizationSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ArchiveFamiliarizationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Familiarization.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

/**
 * //////////////////////////////////////////////////////////////
 * //                ////////////////////////////////////////////
 * //  Directories   ////////////////////////////////////////////
 * //                ////////////////////////////////////////////
 * //////////////////////////////////////////////////////////////
 */
export interface DirectoriesSvc {
  CreateDirectory(
    request: DeepPartial<CreateDirectoryReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory>;
  RemoveDirectory(
    request: DeepPartial<RemoveDirectoryReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  SetDirectoryName(
    request: DeepPartial<SetDirectoryNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory>;
  SetDirectoryDisplayName(
    request: DeepPartial<SetDirectoryDisplayNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory>;
  SetDirectoryArchived(
    request: DeepPartial<SetDirectoryArchivedReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory>;
  AddDirectoryField(
    request: DeepPartial<AddDirectoryFieldReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory>;
  RemoveDirectoryField(
    request: DeepPartial<RemoveDirectoryFieldReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory>;
  SetDirectoryFieldName(
    request: DeepPartial<SetDirectoryFieldNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory>;
  SetDirectoryFieldRequired(
    request: DeepPartial<SetDirectoryFieldRequiredReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory>;
  AddRecord(
    request: DeepPartial<AddRecordReq>,
    metadata?: grpc.Metadata
  ): Promise<Record>;
  RemoveRecord(
    request: DeepPartial<RemoveRecordReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  SetRecordArchived(
    request: DeepPartial<SetRecordArchivedReq>,
    metadata?: grpc.Metadata
  ): Promise<Record>;
  UpdateRecordFields(
    request: DeepPartial<UpdateRecordFieldsReq>,
    metadata?: grpc.Metadata
  ): Promise<Record>;
}

export class DirectoriesSvcClientImpl implements DirectoriesSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateDirectory = this.CreateDirectory.bind(this);
    this.RemoveDirectory = this.RemoveDirectory.bind(this);
    this.SetDirectoryName = this.SetDirectoryName.bind(this);
    this.SetDirectoryDisplayName = this.SetDirectoryDisplayName.bind(this);
    this.SetDirectoryArchived = this.SetDirectoryArchived.bind(this);
    this.AddDirectoryField = this.AddDirectoryField.bind(this);
    this.RemoveDirectoryField = this.RemoveDirectoryField.bind(this);
    this.SetDirectoryFieldName = this.SetDirectoryFieldName.bind(this);
    this.SetDirectoryFieldRequired = this.SetDirectoryFieldRequired.bind(this);
    this.AddRecord = this.AddRecord.bind(this);
    this.RemoveRecord = this.RemoveRecord.bind(this);
    this.SetRecordArchived = this.SetRecordArchived.bind(this);
    this.UpdateRecordFields = this.UpdateRecordFields.bind(this);
  }

  CreateDirectory(
    request: DeepPartial<CreateDirectoryReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory> {
    return this.rpc.unary(
      DirectoriesSvcCreateDirectoryDesc,
      CreateDirectoryReq.fromPartial(request),
      metadata
    );
  }

  RemoveDirectory(
    request: DeepPartial<RemoveDirectoryReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      DirectoriesSvcRemoveDirectoryDesc,
      RemoveDirectoryReq.fromPartial(request),
      metadata
    );
  }

  SetDirectoryName(
    request: DeepPartial<SetDirectoryNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory> {
    return this.rpc.unary(
      DirectoriesSvcSetDirectoryNameDesc,
      SetDirectoryNameReq.fromPartial(request),
      metadata
    );
  }

  SetDirectoryDisplayName(
    request: DeepPartial<SetDirectoryDisplayNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory> {
    return this.rpc.unary(
      DirectoriesSvcSetDirectoryDisplayNameDesc,
      SetDirectoryDisplayNameReq.fromPartial(request),
      metadata
    );
  }

  SetDirectoryArchived(
    request: DeepPartial<SetDirectoryArchivedReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory> {
    return this.rpc.unary(
      DirectoriesSvcSetDirectoryArchivedDesc,
      SetDirectoryArchivedReq.fromPartial(request),
      metadata
    );
  }

  AddDirectoryField(
    request: DeepPartial<AddDirectoryFieldReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory> {
    return this.rpc.unary(
      DirectoriesSvcAddDirectoryFieldDesc,
      AddDirectoryFieldReq.fromPartial(request),
      metadata
    );
  }

  RemoveDirectoryField(
    request: DeepPartial<RemoveDirectoryFieldReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory> {
    return this.rpc.unary(
      DirectoriesSvcRemoveDirectoryFieldDesc,
      RemoveDirectoryFieldReq.fromPartial(request),
      metadata
    );
  }

  SetDirectoryFieldName(
    request: DeepPartial<SetDirectoryFieldNameReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory> {
    return this.rpc.unary(
      DirectoriesSvcSetDirectoryFieldNameDesc,
      SetDirectoryFieldNameReq.fromPartial(request),
      metadata
    );
  }

  SetDirectoryFieldRequired(
    request: DeepPartial<SetDirectoryFieldRequiredReq>,
    metadata?: grpc.Metadata
  ): Promise<Directory> {
    return this.rpc.unary(
      DirectoriesSvcSetDirectoryFieldRequiredDesc,
      SetDirectoryFieldRequiredReq.fromPartial(request),
      metadata
    );
  }

  AddRecord(
    request: DeepPartial<AddRecordReq>,
    metadata?: grpc.Metadata
  ): Promise<Record> {
    return this.rpc.unary(
      DirectoriesSvcAddRecordDesc,
      AddRecordReq.fromPartial(request),
      metadata
    );
  }

  RemoveRecord(
    request: DeepPartial<RemoveRecordReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      DirectoriesSvcRemoveRecordDesc,
      RemoveRecordReq.fromPartial(request),
      metadata
    );
  }

  SetRecordArchived(
    request: DeepPartial<SetRecordArchivedReq>,
    metadata?: grpc.Metadata
  ): Promise<Record> {
    return this.rpc.unary(
      DirectoriesSvcSetRecordArchivedDesc,
      SetRecordArchivedReq.fromPartial(request),
      metadata
    );
  }

  UpdateRecordFields(
    request: DeepPartial<UpdateRecordFieldsReq>,
    metadata?: grpc.Metadata
  ): Promise<Record> {
    return this.rpc.unary(
      DirectoriesSvcUpdateRecordFieldsDesc,
      UpdateRecordFieldsReq.fromPartial(request),
      metadata
    );
  }
}

export const DirectoriesSvcDesc = {
  serviceName: "api.themakers.sc.DirectoriesSvc",
};

export const DirectoriesSvcCreateDirectoryDesc: UnaryMethodDefinitionish = {
  methodName: "CreateDirectory",
  service: DirectoriesSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateDirectoryReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Directory.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DirectoriesSvcRemoveDirectoryDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveDirectory",
  service: DirectoriesSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveDirectoryReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DirectoriesSvcSetDirectoryNameDesc: UnaryMethodDefinitionish = {
  methodName: "SetDirectoryName",
  service: DirectoriesSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetDirectoryNameReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Directory.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DirectoriesSvcSetDirectoryDisplayNameDesc: UnaryMethodDefinitionish =
  {
    methodName: "SetDirectoryDisplayName",
    service: DirectoriesSvcDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetDirectoryDisplayNameReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...Directory.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const DirectoriesSvcSetDirectoryArchivedDesc: UnaryMethodDefinitionish =
  {
    methodName: "SetDirectoryArchived",
    service: DirectoriesSvcDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetDirectoryArchivedReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...Directory.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const DirectoriesSvcAddDirectoryFieldDesc: UnaryMethodDefinitionish = {
  methodName: "AddDirectoryField",
  service: DirectoriesSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddDirectoryFieldReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Directory.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DirectoriesSvcRemoveDirectoryFieldDesc: UnaryMethodDefinitionish =
  {
    methodName: "RemoveDirectoryField",
    service: DirectoriesSvcDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RemoveDirectoryFieldReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...Directory.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const DirectoriesSvcSetDirectoryFieldNameDesc: UnaryMethodDefinitionish =
  {
    methodName: "SetDirectoryFieldName",
    service: DirectoriesSvcDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetDirectoryFieldNameReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...Directory.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const DirectoriesSvcSetDirectoryFieldRequiredDesc: UnaryMethodDefinitionish =
  {
    methodName: "SetDirectoryFieldRequired",
    service: DirectoriesSvcDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetDirectoryFieldRequiredReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...Directory.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const DirectoriesSvcAddRecordDesc: UnaryMethodDefinitionish = {
  methodName: "AddRecord",
  service: DirectoriesSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddRecordReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Record.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DirectoriesSvcRemoveRecordDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveRecord",
  service: DirectoriesSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveRecordReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DirectoriesSvcSetRecordArchivedDesc: UnaryMethodDefinitionish = {
  methodName: "SetRecordArchived",
  service: DirectoriesSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetRecordArchivedReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Record.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const DirectoriesSvcUpdateRecordFieldsDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateRecordFields",
  service: DirectoriesSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateRecordFieldsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Record.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface OneAss {
  QueryAndWatch(
    request: DeepPartial<OneAssQueryAndWatchReq>,
    metadata?: grpc.Metadata
  ): Observable<OneAssQueryAndWatchMsg>;
}

export class OneAssClientImpl implements OneAss {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.QueryAndWatch = this.QueryAndWatch.bind(this);
  }

  QueryAndWatch(
    request: DeepPartial<OneAssQueryAndWatchReq>,
    metadata?: grpc.Metadata
  ): Observable<OneAssQueryAndWatchMsg> {
    return this.rpc.invoke(
      OneAssQueryAndWatchDesc,
      OneAssQueryAndWatchReq.fromPartial(request),
      metadata
    );
  }
}

export const OneAssDesc = {
  serviceName: "api.themakers.sc.OneAss",
};

export const OneAssQueryAndWatchDesc: UnaryMethodDefinitionish = {
  methodName: "QueryAndWatch",
  service: OneAssDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return OneAssQueryAndWatchReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...OneAssQueryAndWatchMsg.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface BackDoor {
  LoginAs(
    request: DeepPartial<LoginAsReq>,
    metadata?: grpc.Metadata
  ): Promise<LoginAsResp>;
}

export class BackDoorClientImpl implements BackDoor {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.LoginAs = this.LoginAs.bind(this);
  }

  LoginAs(
    request: DeepPartial<LoginAsReq>,
    metadata?: grpc.Metadata
  ): Promise<LoginAsResp> {
    return this.rpc.unary(
      BackDoorLoginAsDesc,
      LoginAsReq.fromPartial(request),
      metadata
    );
  }
}

export const BackDoorDesc = {
  serviceName: "api.themakers.sc.BackDoor",
};

export const BackDoorLoginAsDesc: UnaryMethodDefinitionish = {
  methodName: "LoginAs",
  service: BackDoorDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LoginAsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...LoginAsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export interface NumeratorSvc {
  Create(
    request: DeepPartial<CreateNumeratorReq>,
    metadata?: grpc.Metadata
  ): Promise<Numerator>;
  Get(
    request: DeepPartial<GetNumeratorReq>,
    metadata?: grpc.Metadata
  ): Promise<Numerator>;
  Remove(
    request: DeepPartial<RemoveNumeratorReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy>;
  Set(
    request: DeepPartial<SetNumeratorReq>,
    metadata?: grpc.Metadata
  ): Promise<Numerator>;
}

export class NumeratorSvcClientImpl implements NumeratorSvc {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Create = this.Create.bind(this);
    this.Get = this.Get.bind(this);
    this.Remove = this.Remove.bind(this);
    this.Set = this.Set.bind(this);
  }

  Create(
    request: DeepPartial<CreateNumeratorReq>,
    metadata?: grpc.Metadata
  ): Promise<Numerator> {
    return this.rpc.unary(
      NumeratorSvcCreateDesc,
      CreateNumeratorReq.fromPartial(request),
      metadata
    );
  }

  Get(
    request: DeepPartial<GetNumeratorReq>,
    metadata?: grpc.Metadata
  ): Promise<Numerator> {
    return this.rpc.unary(
      NumeratorSvcGetDesc,
      GetNumeratorReq.fromPartial(request),
      metadata
    );
  }

  Remove(
    request: DeepPartial<RemoveNumeratorReq>,
    metadata?: grpc.Metadata
  ): Promise<Dummy> {
    return this.rpc.unary(
      NumeratorSvcRemoveDesc,
      RemoveNumeratorReq.fromPartial(request),
      metadata
    );
  }

  Set(
    request: DeepPartial<SetNumeratorReq>,
    metadata?: grpc.Metadata
  ): Promise<Numerator> {
    return this.rpc.unary(
      NumeratorSvcSetDesc,
      SetNumeratorReq.fromPartial(request),
      metadata
    );
  }
}

export const NumeratorSvcDesc = {
  serviceName: "api.themakers.sc.NumeratorSvc",
};

export const NumeratorSvcCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: NumeratorSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateNumeratorReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Numerator.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const NumeratorSvcGetDesc: UnaryMethodDefinitionish = {
  methodName: "Get",
  service: NumeratorSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNumeratorReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Numerator.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const NumeratorSvcRemoveDesc: UnaryMethodDefinitionish = {
  methodName: "Remove",
  service: NumeratorSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveNumeratorReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Dummy.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const NumeratorSvcSetDesc: UnaryMethodDefinitionish = {
  methodName: "Set",
  service: NumeratorSvcDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetNumeratorReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Numerator.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    }
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
            // Todo
            const authorization = response.trailers?.headersMap?.authorization
            console.log('authorization??????????', authorization)
            if (authorization && authorization[0]) {
                localStorage.setItem('authorization', authorization[0])
            }
            if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
