var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.syncedVisible ? _c('div', {
    staticClass: "modal",
    on: {
      "click": _vm.hide,
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.emitEnter($event);
      }
    }
  }, [_c('div', {
    staticClass: "modal__content",
    style: {
      'max-width': _vm.maxWidth
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "modal__header modal__background"
  }, [_c('p', {
    staticClass: "modal__title"
  }, [_vm._v("\n                " + _vm._s(_vm.title) + "\n            ")]), _vm._v(" "), _c('ButtonClose', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.closable,
      expression: "closable"
    }],
    staticClass: "ml-3",
    attrs: {
      "color": "violet"
    },
    on: {
      "click": _vm.hide
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "modal__body"
  }, [_vm._t("body")], 2), _vm._v(" "), _c('div', {
    staticClass: "modal__background"
  }, [_vm._t("footer")], 2)])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }