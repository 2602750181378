var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-structure"
  }, [_c('div', {
    staticClass: "company-structure__header"
  }, [_vm.centers.length ? _c('Select', {
    staticClass: "company-structure__select",
    attrs: {
      "options": _vm.centers
    },
    model: {
      value: _vm.center,
      callback: function callback($$v) {
        _vm.center = $$v;
      },
      expression: "center"
    }
  }) : _vm._e(), _vm._v(" "), _c('Select', {
    staticClass: "company-structure__select ml-3",
    attrs: {
      "options": _vm.viewTypes
    },
    model: {
      value: _vm.view,
      callback: function callback($$v) {
        _vm.view = $$v;
      },
      expression: "view"
    }
  })], 1), _vm._v(" "), _vm.view === 'tree' ? _c('UnitsTree', {
    staticClass: "company-structure__units_tree",
    attrs: {
      "selected-unit": _vm.unitId,
      "selected-center": _vm.center
    },
    on: {
      "selectUnit": function selectUnit($event) {
        _vm.unitId = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.view === 'list' ? _c('UnitsList', {
    staticClass: "company-structure__units_list",
    attrs: {
      "selected-unit": _vm.unitId
    },
    on: {
      "selectUnit": function selectUnit($event) {
        _vm.unitId = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.unitId ? _c('UnitInfo', {
    staticClass: "company-structure__unit-info box-shadow__double",
    attrs: {
      "unit-id": _vm.unitId
    },
    on: {
      "close": function close($event) {
        _vm.unitId = '';
      },
      "selectUnit": function selectUnit($event) {
        _vm.unitId = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }