


































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import getError, { GrpcError } from '@/tools/errors/errors'
import { User } from '@/model/User'
import { DocumentType, DocumentTypeRequisite } from '@/model/DocumentType'
import { TosserRule, TosserTerm } from '@/model/Tosser'
import { Unit } from '@/model/Unit'

import Rule from '@/components/tosser/TosserRule.vue'
import ButtonGreen from '@/components/common/buttons/ButtonGreen.vue'

@Component({
    components: {
        Rule,
        ButtonGreen
    }
})

export default class TosserBuilder extends Vue {
    @Ref('rules') rules!: HTMLDivElement

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) unitId!: string
    @Prop({ default: '' }) documentTypeId!: string

    // *************************************************************
    // DATA PARAMS
    lastType = ''

    // *************************************************************
    // COMPUTED
    @Getter('me/getMe') getMe!: User // null on this page impossible

    @Getter('document_type/getTypeById') getTypeById!: (id: string) => DocumentType | null

    @Getter('tosser/getRules') getRules!: Array<TosserRule>
    @Getter('tosser/getTosserChange') getTosserChange!: boolean

    @Getter('company_structure/getUnits') getUnits!: Array<Unit>
    @Getter('company_structure/getRootUnit') getRootUnit!: (unitId: string) => Unit | null
    @Getter('company_structure/getUsersByUnit') getUsersByUnit!: (unitId: string) => Array<User>

    get rootUnitId(): string {
        return this.getRootUnit(this.unitId)?.id ?? ''
    }

    get rulesFilterByDocType(): Array<TosserRule> {
        // Фильтрация правил по getDocumentTypeId
        return this.getRules.filter((r: TosserRule) => r.documentType === this.documentTypeId && r.unitId === this.unitId)
    }

    /** Возвращаем все реквизиты данного типа документа */
    get requisites(): Array<DocumentTypeRequisite> {
        const type = this.getTypeById(this.documentTypeId)
        if (!type) {
            return []
        }
        return type.requisites
    }

    get users(): Array<User> {
        // Находим всех users в подразделении независимо от роли
        // Не убираем из массива начальника подразделения, т.к.
        // на него может быть перенаправлено правило в случае Увольнения
        return this.getUsersByUnit(this.unitId)
    }

    get units(): Array<Unit> {
        // Разрешаем добавлять только дочерние units первого порядка
        return this.getUnits.filter((unit: Unit) => unit.parent === this.unitId)
    }

    // *************************************************************
    // WATCH
    @Watch('documentTypeId', { immediate: true })
    handleDocumentTypeIdChange(to: string, from: string): void {
        if (!from && !!to) {
            // Если первый раз выбрали тип документа, то сразу запрашиваем правила
            this.getTosser()
            this.lastType = ''
        } else if (from && to) {
            if (to !== this.lastType) {
                // Если это идет не возврат к несохраненным изменениям
                if (this.getTosserChange) {
                    const answer = window.confirm('У Вас есть несохраненные изменения. Вы действительно хотите уйти?')
                    if (answer) {
                        this.getTosser()
                        this.lastType = ''
                    } else {
                        // Это когда пользователь ответил, что не хочет терять
                        // несохраненные изменения
                        // НИЧЕГО НЕ ДЕЛАТЬ
                        this.lastType = from
                        this.emitChangeDocTypeId(from)
                    }
                } else {
                    this.getTosser()
                    this.lastType = ''
                }
            } else {
                this.lastType = ''
            }
        }
    }

    // *************************************************************
    // METHODS
    @Action('tosser/callGetTosser')
    callGetTosser!: (unitId: string) => Promise<void>

    @Action('tosser/callSetTosser')
    callSetTosser!: (params: { unitId: string, docTypeId: string, rules: Array<TosserRule> }) =>  Promise<void>

    @Action('tosser/setTosserChange')
    setTosserChange!: (tosserChange: boolean) => void

    @Action('tosser/addNewRule')
    addNewRule!: (params: { unitId: string, docTypeId: string }) => void

    addRule(): void {
        // Добавляем новое правило
        this.addNewRule({
            unitId: this.unitId,
            docTypeId: this.documentTypeId
        })
        // Фиксируем изменения tosser
        this.setTosserChange(true)
        this.scrollTo()
    }

    save() {
        const emptyRules: Array<TosserRule> = []
        this.rulesFilterByDocType.forEach((rule: TosserRule, index: number) => {
            // Задаем массив для незаполненных условий в правиле
            const emptyTerms: Array<TosserTerm> = []

            // Проходим по всему массиву условий, если есть недозаполненные,
            // то добавляем их в массив и выводим сообщение об ошибке
            rule.terms.forEach(term => {
                if (!term.requisite || !term.operator || !term.value) {
                    emptyTerms.push(term)
                    emptyRules.push(rule)
                }
            })

            // Если есть недозаполненные правила, то выводим ошибку
            if (emptyTerms.length) {
                emptyRules.push(rule)
                this.$snotify.warning(`Заполните все условия в правиле №${index + 1}!`, 'Отклонено!')
            }

            if (!rule.actor?.userId && !rule.actor?.unitId && !rule.autoAccept) {
                // Если не заполнен согласующий, то выводим ошибку
                emptyRules.push(rule)
                this.$snotify.warning(`Добавьте в правило №${index + 1} Согласующего!`, 'Отклонено!')
            }
        })

        if (emptyRules.length) {
            return
        }

        // Если все правила полностью заполнены, то сохраняем изменения
        const cancelPreloaderSetTosser = this.$preloader('set_tosser', 'Обновление данных Тоссера')

        this.callSetTosser({
            unitId: this.unitId,
            docTypeId: this.documentTypeId,
            rules: this.rulesFilterByDocType
        })
            .then(() => this.getTosser())
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderSetTosser())
    }

    getTosser() {
        const cancelPreloaderGetTosser = this.$preloader('get_tosser', 'Получение данных Тоссера')

        this.callGetTosser(this.unitId)
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderGetTosser())
    }

    async scrollTo() {
        await this.$nextTick()

        try {
            this.rules.scrollIntoView({
                block: 'end',
                behavior: 'smooth'
            })
        } catch (e) {
            console.log(e)
        }
    }

    // *************************************************************
    // EMIT
    @Emit('changeDocTypeId')
    emitChangeDocTypeId(docTypeId: string): string {
        return docTypeId
    }
}
