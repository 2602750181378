var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({}, _vm.$listeners), [_c('span', {
    staticClass: "gray mr-1"
  }, [_vm._v(_vm._s(_vm.attribute) + ":")]), _vm._v(" "), _vm.text ? _c('span', {
    key: "text",
    staticClass: "text-wrap"
  }, [_vm._v(_vm._s(_vm.text))]) : _vm._e(), _vm._v(" "), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }