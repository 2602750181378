var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wf-approval",
    style: {
      height: _vm.svgHeight + 'px'
    }
  }, [_c('svg', {
    ref: "svg",
    staticClass: "wf-approval__svg",
    attrs: {
      "height": _vm.svgHeight,
      "width": _vm.svgWidth
    }
  }, [_vm._l(_vm.vertices.links, function (link) {
    return _c('path', {
      key: link.id,
      staticClass: "wf-approval__line",
      attrs: {
        "d": link.d
      }
    });
  }), _vm._v(" "), _vm._l(_vm.vertices.nodes, function (node) {
    var _vm$nodesOptions$node, _vm$nodesOptions$node2, _vm$nodesOptions$node3;

    return _c('DWF_ApprovalNode', {
      key: node.id,
      style: {
        transform: node.viewData.cssTranslate
      },
      attrs: {
        "node": node,
        "cursor": (_vm$nodesOptions$node = _vm.nodesOptions[node.id]) === null || _vm$nodesOptions$node === void 0 ? void 0 : _vm$nodesOptions$node.cursor,
        "selected": (_vm$nodesOptions$node2 = (_vm$nodesOptions$node3 = _vm.nodesOptions[node.id]) === null || _vm$nodesOptions$node3 === void 0 ? void 0 : _vm$nodesOptions$node3.selected) !== null && _vm$nodesOptions$node2 !== void 0 ? _vm$nodesOptions$node2 : ''
      },
      on: {
        "click": function click($event) {
          return _vm.checkItem(node);
        },
        "mouseenter": function mouseenter($event) {
          _vm.selectedNode = node;
        },
        "mouseleave": _vm.nodeMouseLeave
      }
    });
  })], 2), _vm._v(" "), _vm.showPopover ? _c('DWF_NodePopover', {
    staticClass: "wf-approval__node-popover",
    attrs: {
      "node": _vm.selectedNode
    },
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.nodePopover = true;
      },
      "mouseleave": _vm.nodePopoverMouseLeave
    }
  }) : _vm._e(), _vm._v(" "), _c('DWF_AddActorModal', {
    ref: "addActor"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }