

































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { Document, WorkflowElement, WorkflowStage, WorkflowStageType } from '@/model/Document'

import DWF_TaskCard from '@/components/document/workflow/DWF_TaskCard.vue'

@Component<DWF_TaskStageNode>({
    name: 'DWF_TaskStageNode',
    components: { DWF_TaskCard, DWF_TaskStageNode }
})

export default class DWF_TaskStageNode extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: null }) stageIndex!: number | null
    @Prop({ default: '' }) taskId!: string

    // *************************************************************
    // DATA PARAMS
    drop = true

    // *************************************************************
    // COMPUTED
    @Getter('document/getDocumentById') getDocumentById!: (documentId: string) => Document | null

    get activeStage(): WorkflowStage | null {
        const document: Document | null = this.getDocumentById(this.$route.params.id)

        if (this.stageIndex === null || !document) {
            return null
        }
        return document.workflow.stages[this.stageIndex]
    }

    get activeStageType(): WorkflowStageType | null {
        if (!this.activeStage) {
            return null
        }
        return this.activeStage.type
    }

    get task(): WorkflowElement | null {
        if (!this.activeStage) {
            return null
        }

        return this.activeStage.graph.find((t: WorkflowElement) => t.id === this.taskId) ?? null
    }

    get childTask(): WorkflowElement | null {
        // Если задача требует подтверждения
        if (!this.activeStage) {
            return null
        }

        const parentTask = this.task
        if (parentTask == null || !parentTask.task || parentTask.task.type !== 'width_confirmation') {
            return null
        } else {
            return this.activeStage.graph.find((t: WorkflowElement) => t.id === parentTask.outputs[0]) ?? null
        }
    }

    // *************************************************************
    // METHODS
    clickDrop(): void {
        // Меняем по клику значение drop
        this.drop = !this.drop
    }
}
