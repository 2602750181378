import { ActionTree } from 'vuex'
import { RootState } from '@/store'

import DirectoryGetters from '@/store/directories/_getters'
import DirectoryActions from '@/store/directories/_actions/directory'
import RecordActions from '@/store/directories/_actions/record'
import DirectoryMutations from '@/store/directories/_mutations'

import { Directory, Record } from '@/model/Directory'

export class DirectoryState {
    directories: Array<Directory> = []
    directoriesObject: { [directoryId: string]: Directory } = {}
    dirsModificationTime: { [directoryId: string]: number } = {} // directoryId: modificationTime
    records: Array<Record> = []
    recordsObject: { [recordId: string]: Record } = {}
}

const actions: ActionTree<DirectoryState, RootState> = {
    ...DirectoryActions,
    ...RecordActions,

    cleanUpState({ commit }) {
        console.log('cleanUpState==============================')
        commit('cleanUpState')
    }
}

const DirectorySubModule = {
    namespaced: true,
    state: new DirectoryState(),
    getters: DirectoryGetters,
    mutations: DirectoryMutations,
    actions
}

export default DirectorySubModule
