export default function ({ state, commit }, { numeratorId, field }) {
    let existingNumerator = state.numerators.find(num => num.id === numeratorId)
    commit('saveVersion', {
        numerator: existingNumerator
    })

    commit('setField', {
        numeratorId,
        field
    })
}
