var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Viewer', {
    staticClass: "viewer",
    attrs: {
      "height": "200px",
      "initial-value": _vm.value
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }