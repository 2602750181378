var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "go-back",
    attrs: {
      "to": _vm.link
    }
  }, [_c('svg', {
    attrs: {
      "width": "17",
      "height": "8",
      "viewBox": "0 0 17 8",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM17 3.5L1 3.5V4.5L17 4.5V3.5Z"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "go-back__text"
  }, [_vm._v("\n        " + _vm._s(_vm.text) + "\n    ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }