export class RequisiteType {
    public id: string
    public name: string
    public type: string
    public subType: string
    public required: boolean
    public editable: boolean
    public fromExternalSource: boolean

    constructor(
        id: string,
        name: string,
        type: string,
        subType: string,
        required: boolean,
        editable: boolean,
        fromExternalSource: boolean
    ) {
        this.id = id
        this.name = name
        this.type = type
        this.subType = subType
        this.required = required
        this.editable = editable
        this.fromExternalSource = fromExternalSource
    }
}

export class DocumentTypeOneAss {
    public collectionID: string

    constructor(collectionID: string) {
        this.collectionID = collectionID
    }
}

export class DocumentType {
    public id: string
    public centerId: string
    public name: string
    public description: string
    public code: string
    public numerator: string
    public requisites: Array<RequisiteType>
    public oneAss: DocumentTypeOneAss
    public modificationTime: number

    constructor(
        id: string,
        centerId: string,
        name: string,
        description: string,
        code: string,
        numerator: string,
        requisites: Array<RequisiteType>,
        oneAss: DocumentTypeOneAss,
        modificationTime: number
    ) {
        this.id = id
        this.centerId = centerId
        this.name = name
        this.description = description
        this.code = code
        this.numerator = numerator
        this.requisites = requisites // repeated RequisiteType
        this.oneAss = oneAss // string DocumentTypeOneAss
        this.modificationTime = modificationTime
    }
}
