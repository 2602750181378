import { TASK_STATUS } from '@/model/Task.ts'

let me = {}
let sourse = 'https://kant.themake.rs/'

if (!process.env.NODE_ENV) {
    sourse = 'http://localhost:8080'
} else if (process.env.NODE_ENV === 'production') {
    sourse = 'https://kant.themake.rs/'
}

export function setMe(user) {
    me = user
}

function showNotify(message, options) {
    const notification = new Notification(message, options)
    console.log('notification', notification)
    notification.onclick = (e) => {
        console.log('EEEEE', e, navigator)
        navigator.serviceWorker.onmessage = () => {
            console.log('onmessage', self)
            const urlToOpen = new URL(options.pageUrl, self.location.origin).href

            self.clients.matchAll({
                type: 'window',
                includeUncontrolled: true
            })
                .then((windowClients) => {
                    let matchingClient = null

                    for (let i = 0; i < windowClients.length; i++) {
                        const windowClient = windowClients[i]
                        if (windowClient.url === urlToOpen) {
                            matchingClient = windowClient
                            break
                        }
                    }

                    if (matchingClient) {
                        return matchingClient.focus()
                    } else {
                        return self.clients.openWindow(urlToOpen)
                    }
                })

        }

        // e.waitUntil(async function() {
        //     const urlToOpen = new URL(options.pageUrl, self.location.origin).href
        //
        //     await self.clients.matchAll({
        //         type: 'window',
        //         includeUncontrolled: true
        //     })
        //         .then((windowClients) => {
        //             let matchingClient = null
        //
        //             for (let i = 0; i < windowClients.length; i++) {
        //                 const windowClient = windowClients[i]
        //                 if (windowClient.url === urlToOpen) {
        //                     matchingClient = windowClient
        //                     break
        //                 }
        //             }
        //
        //             if (matchingClient) {
        //                 return matchingClient.focus()
        //             } else {
        //                 return self.clients.openWindow(urlToOpen)
        //             }
        //         })
        // })
    }
}

function notifyMe(message, options) {
    console.log('notifyMe', message, options)
    // Проверка поддержки браузером уведомлений
    if (!('Notification' in window)) {
        alert('This browser does not support desktop notification')
    }

    // Проверка разрешения на отправку уведомлений
    else if (Notification.permission === 'granted') {
        // Если разрешено, то создаем уведомление
        showNotify(message, options)
    }

    // В противном случае, запрашиваем разрешение
    else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
            // Если пользователь разрешил, то создаем уведомление
            if (permission === 'granted') {
                showNotify(message, options)
            }
        })
    }
}

export function workflowNotify(event) {
    console.log('workflowNotify', event)

    let message = ''
    if (event.type === 'approval') {
        message = 'Требуется Ваше согласование!'
    } else if (event.type === 'task') {
        switch (event.task.type) {
        case 'regular':
            message = `Требуется выполнение: ${event.task.text}`
            break
        case 'width_confirmation':
            message = `Требуется выполнение: ${event.task.text}`
            break
        case 'confirmation':
            message = `Требуется подтверждение: ${event.task.text}`
            break
        default:
            message = `Требуется выполнение: ${event.task.text}`
        }
    }

    notifyMe(message, {
        body: event.document.name,
        tag: `${event.id}_${Date.now()}`,
        renotify: true,
        pageUrl: `${sourse}documents/${event.document.type}/${event.document.id}`
    })
}

export function notifyTask(task) {
    console.log('notifyTask', task)
    let message = ''

    if (task.status === TASK_STATUS.STATUS_ON_EXECUTION && me.id === task.assignee.user.id) {
        message = 'Новая задача!'
    } else if (task.status === TASK_STATUS.STATUS_ON_CONFIRMATION && me.id === task.confirmer.user.id) {
        message = 'Требуется подтверждение задачи!'
    }

    if (message) {
        notifyMe(message, {
            body: task.text,
            tag: `${task.id}_${Date.now()}`,
            renotify: true,
            pageUrl: `${sourse}system/tasks/${task.id}`
        })
    }
}

export function familiarizationNotify(fam) {
    console.log('familiarizationNotify', fam)

    let message = ''

    const myFam = fam.сheckList.length && fam.сheckList.find(u => u.user.id === me.id && !u.status)
    if (fam.status === 'active' && myFam) {
        message = 'Необходимо ознакомиться!'
    }

    if (message) {
        notifyMe(message, {
            body: fam.name,
            tag: fam.id,
            renotify: true,
            pageUrl: `${sourse}system/familiarization/${fam.id}`
        })
    }
}
