import { TaskTemplate } from '@/model/Workflow'

export class TaskTemplateWithOutputs extends TaskTemplate {
    public outputs: Array<string> = [] // исходящие связи

    constructor(task: TaskTemplate) {
        super(task.id, task.assignee, task.text, task.description, task.confirmer, task.inputs)
    }
}

/** Мы получаем задачи только с массивом inputs, для построения дерева нам необходим массив outputs.
 *  Данная функция прокидвает массив outputs в каждую задачу
 */
export default function (tasks: Array<TaskTemplate>): Array<TaskTemplateWithOutputs> {
    const tasksWithOutputs: Array<TaskTemplateWithOutputs> = tasks.map(task => new TaskTemplateWithOutputs(task))

    // В каждой задаче берем inputs[0], т.к. у задачи может быть только одно вхождение
    // По этому id находим задачу и ставим ей в outputs id текущей задачи
    tasks.forEach((task: TaskTemplate) => {
        // Если у задачи есть входящие связи
        if (task.inputs.length) {
            const parent = tasksWithOutputs.find((t: TaskTemplateWithOutputs) => t.id === task.inputs[0])
            if (!parent) {
                console.error('introduceTaskOutputs:: parent task not found', task.inputs[0])
            } else {
                parent.outputs.push(task.id)
            }
        }
    })

    return tasksWithOutputs
}
