import Vue from 'vue'

import 'normalize.css'

import '@/assets/fonts.css'

import '@/assets/styles/main.styl'

// https://github.com/FortAwesome/vue-fontawesome
import '@fortawesome/fontawesome-free'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUserSecret,
    faTasks,
    faFileSignature,
    faSave,
    faUndo,
    faBell,
    faPlusSquare,
    faBan,
    faSignOutAlt,
    faSyncAlt,
    faFileImage,
    faFileWord,
    faFileExcel,
    faFilePdf,
    faQuestionCircle,
    faAngleLeft,
    faAngleRight,
    faCalendarAlt,
    faClock,
    faPrint,
    faArrowUp,
    faArrowDown,
    faArrowRight,
    faLongArrowAltLeft,
    faArrowLeft,
    faCog,
    faThumbtack,
    faEdit,
    faArchive,
    faCompressArrowsAlt,
    faExpandArrowsAlt,
    faUsers,
    faSitemap,
    faInfo,
    faEye,
    faDatabase,
    faHome
} from '@fortawesome/free-solid-svg-icons'


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faUserSecret, //
    faTasks, // SideBar
    faSave, // InputSave
    faUndo, // InputSave
    faBell, //
    faFileSignature, // SideBar
    faPlusSquare, //DocumentTypes, DocumentRequisites
    faBan, // RequisiteCard, File
    faSignOutAlt, // SideBarPanel
    faSyncAlt, // File
    faFileImage, // File
    faFileWord, // File
    faFileExcel, // File
    faFilePdf, // File
    faQuestionCircle, // CommonDocument
    faAngleLeft, // DatePicker
    faAngleRight, // DatePicker
    faCalendarAlt, // DatepickerInput
    faClock, // TimepickerInput
    faPrint, // DocumentList
    faArrowUp, // WorkFlowTemplateStage
    faArrowDown, // WorkFlowTemplateStage
    faArrowRight, // DocumentList
    faLongArrowAltLeft,
    faArrowLeft, // TemplateBuilder
    faCog, // DocumentTypeCard
    faThumbtack, // SideBar
    faEdit, // DirectoryRecord
    faArchive, // DirectoryRecords
    faCompressArrowsAlt,
    faExpandArrowsAlt,
    faUsers,
    faSitemap,
    faInfo,
    faEye,
    faDatabase,
    faHome
)

Vue.component('FAIcon', FontAwesomeIcon)
