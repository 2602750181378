var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkbox-container",
    class: [_vm.classes],
    on: {
      "click": _vm.changeCheck
    }
  }, [_c('div', {
    staticClass: "checkbox-group"
  }, [!_vm.loading ? _c('input', {
    staticClass: "checkbox__input",
    attrs: {
      "disabled": _vm.checkboxDisabled,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.checked
    },
    on: {
      "change": _vm.changeCheck
    }
  }) : _c('CircleSpinner')], 1), _vm._v(" "), _vm.label ? _c('label', {
    staticClass: "checkbox_label"
  }, [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }