var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "one-ass-modal",
    attrs: {
      "visible": _vm.syncedVisible,
      "title": "Настройка 1С"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.syncedVisible = $event;
      }
    }
  }, [_c('template', {
    slot: "body"
  }, [_c('div', {
    staticClass: "one-ass__request-container"
  }, [_c('TextareaNew', {
    staticClass: "my-3",
    attrs: {
      "min-lines": 2,
      "max-lines": 7,
      "placeholder": "Запрос №1"
    },
    model: {
      value: _vm.requestOne,
      callback: function callback($$v) {
        _vm.requestOne = $$v;
      },
      expression: "requestOne"
    }
  }), _vm._v(" "), _c('TextareaNew', {
    staticClass: "my-3",
    attrs: {
      "min-lines": 2,
      "max-lines": 7,
      "placeholder": "Запрос №2"
    },
    model: {
      value: _vm.requestTwo,
      callback: function callback($$v) {
        _vm.requestTwo = $$v;
      },
      expression: "requestTwo"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "flex-row-center justify-end p-3",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "color": "green"
    }
  }, [_vm._v("OK")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }