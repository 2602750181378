







































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { TosserRule, TosserTerm } from '@/model/Tosser'
import { DocumentTypeRequisite } from '@/model/DocumentType'
import { User } from '@/model/User'
import { Unit } from '@/model/Unit'
import { ActorIds } from '@/model/Actor'
import { TosserRuleRequisite } from '@/components/tosser/model'
import { SelectOption } from '@/components/common/select/model'

import ButtonAdd from '@/components/common/buttons/ButtonAdd.vue'
import ButtonDelete from '@/components/common/buttons/ButtonDelete.vue'
import Select from '@/components/common/select/Select.vue'
import ActionSelectCard from '@/components/common/cards/ActionSelectCard.vue'
import TosserRuleTerm from '@/components/tosser/TosserRuleTerm.vue'

@Component({
    components: {
        ButtonAdd,
        ButtonDelete,
        Select,
        TosserRuleTerm,
        ActionSelectCard
    }
})

export default class RuleTosser extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) unitId!: string
    @Prop({ default: null }) ruleInfo!: TosserRule
    @Prop({ default: () => []}) requisites!: Array<DocumentTypeRequisite>
    @Prop({ default: () => []}) users!: Array<User>
    @Prop({ default: () => []}) units!: Array<Unit>
    @Prop({ default: false }) firstRule!: boolean
    @Prop({ default: false }) lastRule!: boolean

    // *************************************************************
    // DATA PARAMS
    actor: ActorIds = new ActorIds({ UserID: '', UnitID: '' })

    type = 'user'

    // *************************************************************
    // COMPUTED
    @Getter('tosser/getTosserChange') getTosserChange!: boolean

    get typeOptions(): Array<SelectOption> {
        return [
            {
                id: 'unit',
                name: 'Подразделение',
                disabled: !this.units.length
            },
            {
                id: 'user',
                name: 'Сотрудник',
                disabled: !this.users.length
            },
            {
                id: 'auto',
                name: 'Автоматически'
            }
        ]
    }

    get requisitesFilter(): { [id: string]: TosserRuleRequisite } {
        // Берем весь список реквизитов данного типа вместе с required
        // Записываем их для удобства в ассоциативный список
        // Ключ = id реквизита, значение =  все данные реквизита
        const requisites: { [id: string]: TosserRuleRequisite } = {}

        // Убираем тип === таблица, потому как непонятно, как его фильтровать
        // и пока в Тоссере таблицы не учавствуют
        let requisitesWithoutTable: Array<DocumentTypeRequisite> = this.requisites.filter((req: DocumentTypeRequisite) => req.type !== 'req_table')

        requisitesWithoutTable.forEach((req: DocumentTypeRequisite) => {

            // Копируем все поля реквизита
            const reqCopy: TosserRuleRequisite = Object.assign({
                disabled: false,
                operators: []
            }, req)

            if (req.type === 'req_do' || req.type === 'req_string') {
                reqCopy.operators = [{
                    id: 'eq',
                    name: '=',
                    disabled: false
                }]
            } else if (req.type === 'req_number' || req.type === 'req_date') {
                reqCopy.operators = [
                    {
                        id: 'lt', // less
                        name: '<',
                        disabled: false
                    },
                    {
                        id: 'eq', // equally
                        name: '=',
                        disabled: false
                    },
                    {
                        id: 'gt', // greater
                        name: '>',
                        disabled: false
                    }
                ]
            }

            requisites[req.id] = reqCopy
        })

        this.ruleInfo.terms.forEach((term: TosserTerm) => {
            const req: TosserRuleRequisite = requisites[term.requisite]

            if (!req) {
                console.error('cannot find requisite', term.requisite)
            } else {
                // Если правило с таким реквизитом пока только одно, то ничего не блокируем
                if (term.operator && term.value) {
                    // Каждый оператор может быть выбран только единожды
                    const operator = req.operators.find(operator => operator.id === term.operator)
                    if (!operator) {
                        console.error('cannot find operator', term.operator)
                    } else {
                        operator.disabled = true
                    }

                    if (term.operator !== 'eq') {
                        // Если в каком-то условии используется оператор не равно, то равно уже использовать нельзя
                        const operator = req.operators.find(operator => operator.id === 'eq')
                        if (!operator) {
                            console.error('cannot find operator', term.operator)
                        } else {
                            operator.disabled = true
                        }
                    } else {
                        // Если используется оператор равно, то не равно нужно заблокировать
                        req.operators.filter(operator => operator.id !== 'eq').forEach(o => o.disabled = true)
                    }
                }
                if (!!term.requisite && req.operators.every(operator => operator.disabled)) {
                    // Если все операторы заблокированы, то блокируем и реквизит
                    req.disabled = true
                }
            }
        })

        return requisites
    }

    get addButtonDisabled(): boolean {
        // Если мы перечислили в правилах все условия, то блокируем возможность добавления,
        // чтобы не создавать повторы
        console.log('Object.values(this.requisitesFilter)', Object.values(this.requisitesFilter))
        return Object.values(this.requisitesFilter).every((req: TosserRuleRequisite) => req.disabled)
    }

    // *************************************************************
    // WATCH
    @Watch('ruleInfo', { immediate: true, deep: true })
    handleRuleInfoChange(to: TosserRule): void {
        console.log('handleRuleInfoChange', to)
        if (to.actor) {
            this.actor = to.actor
        }

        if (to.autoAccept) {
            this.type = 'auto'
        } else if (to.actor?.userId) {
            this.type = 'user'
        } else if (to.actor?.unitId) {
            this.type = 'unit'
        } else if (!this.users.length && this.units.length) {
            // Если правило только создается - в поле actor никто не указан
            // Если users заблокированы для выбора, а units - не заблокированы,
            // то возвращаем unit
            this.type = 'unit'
        } else if (this.users.length) {
            // - users не заблокированы
            this.type = 'user'
        } else {
            // Во всех остальных случаях возращаем auto:
            // - и users и units заблокированы
            this.type = 'auto'
        }
    }

    @Watch('actor', { deep: true })
    handleActorChange(): void {
        this.setNewActor()
    }

    @Watch('type')
    handleTypeChange(): void {
        // На любое изменение типа обнуляем actor
        this.actor.userId = ''
        this.actor.unitId = ''
    }

    // *************************************************************
    // METHODS
    @Action('tosser/setTosserChange')
    setTosserChange!: (tosserChange: boolean) => void

    @Action('tosser/removeRule')
    removeRule!: (ruleId: string) => void

    @Action('tosser/changeRuleActor')
    changeRuleActor!: (params: { ruleId: string, actor: ActorIds, autoAccept: boolean }) => void

    @Action('tosser/addNewTerm')
    addNewTerm!: (ruleId: string) => void

    @Action('tosser/changeRuleTerm')
    changeRuleTerm!: (params: { ruleId: string, term: TosserTerm }) => void

    @Action('tosser/removeRuleTerm')
    removeRuleTerm!: (params: { ruleId: string, termId: string }) => void

    @Action('tosser/moveRuleDown')
    moveRuleDown!: (ruleId: string) => void

    @Action('tosser/moveRuleUp')
    moveRuleUp!: (ruleId: string) => void

    remove(): void {
        // Фиксируем изменения tosser
        this.setTosserChange(true)
        this.removeRule(this.ruleInfo.id)
    }

    setNewActor(): void {
        this.changeRuleActor({
            ruleId: this.ruleInfo.id,
            actor: this.actor,
            autoAccept: this.type === 'auto'
        })
        this.setTosserChange(true)
    }

    canAddTerm(): void {
        if (this.addButtonDisabled) {
            return
        }

        if (!this.ruleInfo.terms.length) {
            // Вызываем action для добавления нового условия
            this.addNewTerm(this.ruleInfo.id)
            // Фиксируем изменения tosser
            this.setTosserChange(true)
        } else {
            const lastTerm = this.ruleInfo.terms[this.ruleInfo.terms.length - 1]
            // Если последнее условия незаполнено - выводим ошибку
            if (!lastTerm.operator || !lastTerm.requisite || !lastTerm.value) {
                this.$snotify.warning('Заполните предыдущее условие!', 'Отклонено!')
            } else {
                // Вызываем action для добавления нового условия
                this.addNewTerm(this.ruleInfo.id)
                // Фиксируем изменения tosser
                this.setTosserChange(true)
            }
        }
    }

    changeTerm(event: TosserTerm): void {
        this.changeRuleTerm({
            ruleId: this.ruleInfo.id,
            term: event
        })
        // Фиксируем изменения tosser
        this.setTosserChange(true)
    }

    removeTerm(termId: string): void {
        // Если в правиле пытаются удалить последнее условие - выводим сообщение об ошибке
        if (this.ruleInfo.terms.length === 1) {
            this.$snotify.warning('В правиле должно быть хотя бы одно условие. Вы можете удалить правило!', 'Отклонено!')
            return
        }

        this.removeRuleTerm({
            ruleId: this.ruleInfo.id,
            termId: termId
        })
        // Фиксируем изменения tosser
        this.setTosserChange(true)
    }

    moveDown(): void {
        // Проверяем, не является ли правило последним
        if (!this.lastRule) {
            this.moveRuleDown(this.ruleInfo.id)
            // Фиксируем изменения tosser
            this.setTosserChange(true)
        }
    }

    moveUp(): void {
        // Проверяем, не является ли правило первым
        if (!this.firstRule) {
            this.moveRuleUp(this.ruleInfo.id)
            // Фиксируем изменения tosser
            this.setTosserChange(true)
        }
    }
}
