import { cuntPB } from 'api'

class UnitOneAss {
    clientIds: Array<string>

    constructor(oneAss: cuntPB.UnitOneAss) {
        this.clientIds = oneAss.ClientIDs
    }
}

export class Unit {
    public id: string
    public centerId: string
    public parent: string
    public root: boolean
    public name: string
    public code: string
    public oneAss: UnitOneAss | null
    public modificationTime: number

    constructor(unit: cuntPB.Unit) {
        this.id = unit.ID
        this.centerId = unit.CenterID
        this.parent = unit.Parent
        this.root = unit.Root
        this.name = unit.Name
        this.code = unit.Code
        this.oneAss = unit.OneAss ? new UnitOneAss(unit.OneAss) : null
        this.modificationTime = unit.ModificationTime
    }
}
