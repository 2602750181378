






























import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator'

import ButtonClose from '@/components/common/buttons/ButtonClose.vue'

@Component({
    components: { ButtonClose }
})

export default class Modal extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: true }) closable!: boolean
    @PropSync('visible', { type: Boolean }) syncedVisible!: boolean
    @Prop({ default: 'none' }) transition!: string
    @Prop({ default: 'none' }) title!: string
    @Prop({ default: '900px' }) maxWidth!: string

    // *************************************************************
    // METHODS
    show(): void {
        this.syncedVisible = true
    }

    hide(): void {
        this.syncedVisible = false
        this.emitClose()
    }

    // *************************************************************
    // EMIT
    @Emit('close')
    emitClose(): void {
        // Do nothing
    }

    @Emit('enter')
    emitEnter(): void {
        // Do nothing
    }
}
