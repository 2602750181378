import { MutationTree } from 'vuex'
import { NumeratorState } from '@/store/numerator/numeratorState'

import _mutations_putNumerator from '@/store/numerator/_mutations/putNumerator';
import _mutations_removeNumerator from '@/store/numerator/_mutations/removeNumerator';
import _mutations_setName from '@/store/numerator/_mutations/setName';
import _mutations_setField from '@/store/numerator/_mutations/setField';
import _mutations_removeField from '@/store/numerator/_mutations/removeField';
import _mutations_moveFields from '@/store/numerator/_mutations/moveFields';
import _mutations_setModificationTime from '@/store/numerator/_mutations/setModificationTime';
import _mutations_resetVersion from '@/store/numerator/_mutations/resetVersion';
import _mutations_saveVersion from '@/store/numerator/_mutations/saveVersion';

const NumeratorMutations: MutationTree<NumeratorState> = {
    putNumerator: _mutations_putNumerator,
    removeNumerator: _mutations_removeNumerator,
    setName: _mutations_setName,
    setField: _mutations_setField,
    removeField: _mutations_removeField,
    moveFields: _mutations_moveFields,

    setModificationTime: _mutations_setModificationTime,
    resetVersion: _mutations_resetVersion,
    saveVersion: _mutations_saveVersion,

    cleanUpState(state: NumeratorState) {
        state.numerators = []
        state.numModificationTime = {}
        state.versions = {}
        state.savesNumerators = {}
    }
}

export default NumeratorMutations
