/* eslint-disable */
import identity from "./proto/identity-proto/identity_grpc_web_pb"
import cunt from "./proto/sad-cunt-backend-proto/sc_grpc_web_pb"

let protos = {identity, cunt}

let sessionToken = localStorage.getItem('authorization')

const api = {}

function bind(func, context) {
    return function() {
        return func.apply(context, arguments)
    }
}

function CallWrapper(call) {

    this.cancel = () => {
        call.cancel()
    }

    return this
}

function Meta(options, meta) {
    // if (options.deadline !== undefined) {
    //     if (options.deadline !== 0) {
    //         meta.deadline = new Date().getTime() + options.deadline
    //     } else {
    //         // Deadline is equal to zero
    //         meta.deadline = new Date().getTime() + 10 * 60 * 1000
    //     }
    // } else {
    //     meta.deadline = new Date().getTime() + 10 * 60 * 1000
    // }
    if (localStorage.getItem('authorization')) {
        meta.Authorization = localStorage.getItem('authorization')
    }
    return meta
}

function WrapUnaryCall(fn, client) {
    return function(msg, data, done, status, options) {
        options = (options || {})
        let meta = Meta(options, {})
        const callDone = (arg) => {
            if (typeof done === typeof function() {}) {
                done(arg)
                done = undefined
            }
        }
        // console.log('META', meta)
        let call = bind(fn, client)(msg, meta, function(error, msg) {
            if (!error) {
                data(msg)
                callDone()
            } else {
                callDone(error)
            }
        })
        call.on("status", function(stat) {
            // console.warn('status-WrapUnaryCall', stat)
            if (stat.metadata && stat.metadata.authorization) {
                api._setSessionToken(stat.metadata.authorization)
            }
            if (typeof status === typeof function() {}) {
                status(stat)
            }
            if (stat.code !== 0) {
                callDone({
                    code: stat.code,
                    message: stat.details
                })
            }
            callDone()
        })

        return new CallWrapper(call)
    }
}

function WrapStreamCall(fn, client) {
    return (msg, data, done, status, options) => {
        options = (options || {})
        let meta = Meta(options, {})
        const callDone = (arg) => {
            if (typeof done === typeof function() {}) {
                done(arg)
                done = undefined
            }
        }
        // console.log('META', meta)
        let call = bind(fn, client)(msg, meta)
        call.on("data", msg => {
            data(msg)
        })
        call.on("end", () => {
            callDone()
        })
        call.on("status", stat => {
            // console.log('status-WrapStreamCall', stat)
            if (stat.metadata && stat.metadata.authorization) {
                api._setSessionToken(stat.metadata.authorization)
            }
            if (stat.code !== 0) {
                callDone({code: stat.code, message: stat.details})
                return
            }
            if (typeof status === typeof function() {}) {
                status(stat)
            }
            callDone()
        })
        call.on("error", error => {
            // console.log('error-WrapStreamCall', error)
            callDone(error)
        })

        return new CallWrapper(call)
    }
}

function valueHandler(val) {
    if (val) {
        if (val.serializeBinary) {
            return ReformProtoMessage(val)
        } else if (typeof val === typeof []) {
            return val.map(val => {
                return valueHandler(val)
            })
        } else {
            return val
        }
    } else {
        return val
    }
}

function ReformProtoMessage(msg) {
    const object = {}
    // FIXME Implement better proxy
    // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Proxy
    // https://learn.javascript.ru/proxy
    const proxy = new Proxy(object, {
        get(target, prop) {
            // console.warn(typeof prop, prop, target)

            if (typeof prop === typeof "") {
                return target[prop.toLowerCase()]
            } else {
                return target[prop]
            }
        },
        set(target, prop, value) {
            // console.warn(typeof prop, prop, value, target)

            if (typeof prop === typeof "") {
                target[prop.toLowerCase()] = value
            } else {
                target[prop] = value
            }
            return true
        }
    })
    for (let msgField in msg) {
        if (
            msg.__proto__.hasOwnProperty(msgField) &&
            /^get[A-Z]/i.test(msgField)
        ) {
            let fieldName = msgField.replace(/^(get)/, "").toLowerCase()
            const getFn = msg[msgField]
            let val = bind(getFn, msg)()

            // console.warn('PMSG', msgField, val)
            val = valueHandler(val)

            // FIXME hack for array names
            if (typeof val === typeof []) {
                fieldName = fieldName.replace(/(list)$/, "")
            }
            object[fieldName] = val
        }
    }
    proxy._msg = msg
    return proxy
}

function MessageWrapper(pmsg) {
    return function(msg) {
        if (msg.serializeBinary) {
            return ReformProtoMessage(msg)
        } else {
            const m = {}
            for (let msgField in msg) {
                m[msgField.toLocaleLowerCase()] = msg[msgField]
            }
            const pmsgInstance = new pmsg()
            for (let pmsgField in pmsgInstance.__proto__) {
                if (
                    pmsgInstance.__proto__.hasOwnProperty(pmsgField) &&
                    /^set[A-Z]/i.test(pmsgField)
                ) {
                    const shortName = pmsgField
                        .replace(/^(set)/, "")
                        .toLocaleLowerCase().replace(/(list)$/, "")
                    if (m[shortName]) {
                        const setFn = pmsgInstance[pmsgField]
                        bind(setFn, pmsgInstance)(m[shortName])
                    } else {
                        // throw new Error("field not set: " + shortName)
                        console.warn("field not set: " + shortName)
                    }
                }
            }
            return pmsgInstance
        }
    }
}

api._setSessionToken = (token) => {
    sessionToken = token
    localStorage.setItem('authorization', token)
}

for (let moduleName in protos) {
    let proto = protos[moduleName]
    api[moduleName] = {}
    for (let entityName in proto) {
        if (
            entityName.endsWith("Client") &&
            !entityName.endsWith("PromiseClient")
        ) {
            let client = proto[entityName]
            client = new client("https://kant.themake.rs")
            api[moduleName][entityName] = {}

            for (let name in client) {
                let fn = client[name]
                let clientName = name // .replace(/(Client)$/, '')
                if (typeof fn === typeof function() {}) {
                    if (fn.length === 3) {
                        api[moduleName][entityName][clientName] = WrapUnaryCall(
                            fn,
                            client
                        )
                    } else if (fn.length === 2) {
                        api[moduleName][entityName][
                            clientName
                        ] = WrapStreamCall(fn, client)
                    }
                }
            }
        } else if (!entityName.endsWith("Client")) {
            const msg = proto[entityName]
            api[moduleName][entityName] = MessageWrapper(msg)
        }
    }
}

console.log("API", api)
console.log("Если Вам что-то не нравится, то история... Однажды Пятачок пришел к Винни и говорит: 'Знаешь, Винни, ты как свинья. Полы в доме грязные, стол в варенье, носки на люстре...'. А Винни отвечает: 'Зато зеркало в коридоре чистое. И отражение в нем тебя порадует'.")
export default api
