












import { Component, Vue, Prop } from 'vue-property-decorator'

@Component<Attribute>({})

export default class Attribute extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) attribute!: string
    @Prop({ default: '' }) text!: string
}
