var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module",
    style: {
      width: _vm.moduleConfig.width,
      left: _vm.moduleConfig.left
    }
  }, [_c(_vm.moduleComponentName, {
    tag: "Component"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }