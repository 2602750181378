
































import { Component, Prop, Vue } from 'vue-property-decorator'

import { Actor, UserInfo } from '@/model/Actor'

// Импортируем функцию для расчетов глубин для графа
import viewData, { NodeTemplate, LinkTemplate } from '@/tools/template_builder/viewData'

import DWF_ApprovalNode from '@/components/document/workflow/DWF_ApprovalNode.vue'
import DWF_NodePopover from '@/components/document/workflow/DWF_NodePopover.vue'

@Component<DWF_Draft>({
    components: {
        DWF_ApprovalNode,
        DWF_NodePopover
    }
})

export default class DWF_Draft extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => null }) author!: UserInfo
    @Prop({ default: false }) passed!: boolean
    @Prop({ default: 0 }) svgWidth!: number

    // *************************************************************
    // DATA PARAMS
    svgHeight = 80
    selectedNode: NodeTemplate | null = null
    nodePopover = false

    // *************************************************************
    // COMPUTED
    get vertices(): { nodes: Array<NodeTemplate>, links: Array<LinkTemplate> } {
        // В черновике у нас присутствует только автор
        const author = new Actor(null)
        author.user = this.author

        const draftNode = {
            id: 'draft',
            actor: author,
            specialName: '',
            inputs: [],
            passed: this.passed
        }

        return viewData(40, this.svgWidth, this.svgHeight, [draftNode], false, true)
    }

    // *************************************************************
    // METHODS
    nodeMouseLeave(): void {
        // setTimeout необходим, т.к. нужно учитывать наведение на Popover
        // без него работать не будет
        setTimeout(() => {
            if (!this.nodePopover) {
                this.selectedNode = null
            }
        }, 10)
    }

    nodePopoverMouseLeave(): void {
        this.nodePopover = false
        this.selectedNode = null
    }
}
