var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat"
  }, [_c('div', {
    staticClass: "chat__header"
  }, [_c('Checkbox', {
    staticClass: "chat__title_filter-message",
    attrs: {
      "label": "cобытия"
    },
    on: {
      "change": function change($event) {
        return _vm.onCheckEvents($event);
      }
    },
    model: {
      value: _vm.checkEvents,
      callback: function callback($$v) {
        _vm.checkEvents = $$v;
      },
      expression: "checkEvents"
    }
  }), _vm._v(" "), _c('Checkbox', {
    staticClass: "chat__title_filter-message",
    attrs: {
      "label": "cообщения"
    },
    on: {
      "change": function change($event) {
        return _vm.onCheckMessages($event);
      }
    },
    model: {
      value: _vm.checkMessages,
      callback: function callback($$v) {
        _vm.checkMessages = $$v;
      },
      expression: "checkMessages"
    }
  })], 1), _vm._v(" "), _c('div', {
    ref: "chatBody",
    staticClass: "chat__body"
  }, _vm._l(_vm.filteredEvents, function (event) {
    return _c('DocumentChartCard', {
      key: event.id,
      attrs: {
        "event": event
      }
    });
  }), 1), _vm._v(" "), _c('div', {
    staticClass: "chat__footer"
  }, [_c('TextareaNew', {
    staticClass: "chat__footer_textarea",
    attrs: {
      "min-lines": 1,
      "max-lines": 5,
      "loading": _vm.loadingMessage,
      "name": "chat-message",
      "placeholder": "Type a message"
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "message"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "chat__footer_spend",
    on: {
      "click": _vm.spendEvent
    }
  }, [_c('svg', {
    staticClass: "chat__spend-icon",
    class: {
      'chat__spend-icon_disabled': _vm.loadingMessage || !_vm.message
    },
    attrs: {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
    }
  })])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }