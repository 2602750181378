
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component<ButtonClose>({})

export default class ButtonClose extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: false }) small!: boolean
    @Prop({ default: 'grey' }) color!: 'grey' | 'violet'
}
