var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "document-tasks"
  }, _vm.$listeners), [_c('DocumentBlockTitle', {
    attrs: {
      "title": "Задачи"
    }
  }, [_c('ButtonAdd', {
    staticClass: "ml-5",
    attrs: {
      "tooltip": "Добавить задачу",
      "flow": "left"
    },
    on: {
      "click": _vm.openCreateModal
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "document-tasks__list"
  }, _vm._l(_vm.tasks, function (task) {
    return _c('TaskCardSmall', {
      key: task.id,
      staticClass: "document-tasks__card",
      attrs: {
        "task": task,
        "document": true
      },
      on: {
        "click": function click($event) {
          return _vm.openTask(task.id);
        }
      }
    });
  }), 1), _vm._v(" "), _c('ModalCreateTask', {
    attrs: {
      "visible": _vm.visibleCreateModal,
      "documentId": _vm.openDocumentId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleCreateModal = $event;
      }
    }
  }), _vm._v(" "), _c('TaskModal', {
    ref: "modal"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }