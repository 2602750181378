var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dir-records-select"
  }, [_c('table', {
    style: {
      width: _vm.records.length ? _vm.tableBodyWidth + 'px' : '100%'
    }
  }, [_c('tr', _vm._l(_vm.directoryFields, function (field, index) {
    return _c('th', {
      key: field.name,
      style: {
        width: _vm.cellWidth[index] + 'px'
      }
    }, [_c('span', [_vm._v(_vm._s(field.name))]), _vm._v(" "), field.required ? _c('span', [_vm._v("*")]) : _vm._e()]);
  }), 0)]), _vm._v(" "), _c('div', {
    ref: "container",
    staticClass: "dir-records-select__records",
    style: {
      width: _vm.records.length ? _vm.tableBodyWidth + 'px' : '100%'
    }
  }, [_c('table', {
    ref: "tableBody"
  }, _vm._l(_vm.records, function (record) {
    return _c('tr', {
      key: record.id,
      staticClass: "dir-records-select__record",
      on: {
        "click": function click($event) {
          return _vm.emitChangeRecord(record);
        }
      }
    }, _vm._l(record.fields, function (f, i) {
      return _c('td', {
        key: "".concat(record.id, "-").concat(i),
        staticClass: "dir-records-select__record-cell"
      }, [_vm._v("\n                    " + _vm._s(f.displayName) + "\n                ")]);
    }), 0);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }