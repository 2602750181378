var render = function () {
  var _vm$directory;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "directory"
  }, [_c('GoBack', {
    attrs: {
      "text": "Вернуться к списку справочников",
      "link": _vm.goBackPath
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "directory__header"
  }, [_c('Tabs', {
    staticClass: "directory__select-mode",
    attrs: {
      "tabs": _vm.modes
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }), _vm._v(" "), _vm.directory ? _c('div', {
    staticClass: "directory__archive pink-1",
    class: {
      'directory__archive_disabled': _vm.directory.external
    },
    attrs: {
      "tooltip": (_vm$directory = _vm.directory) !== null && _vm$directory !== void 0 && _vm$directory.archived ? 'Вернуть из архива' : 'Убрать в архив',
      "flow": "left"
    },
    on: {
      "click": _vm.archive
    }
  }, [_c('FAIcon', {
    staticClass: "directory__archive_icon",
    attrs: {
      "icon": "archive"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.directory ? _c('ButtonDelete', {
    attrs: {
      "tooltip": "Удалить справочник",
      "disabled": _vm.canDelete.disabled,
      "flow": "left"
    },
    on: {
      "click": _vm.showRemoveDirectory
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.mode === 'directory' && _vm.directory ? _c('EditDirectory', {
    staticClass: "directory__body directory__body_directory"
  }) : _vm._e(), _vm._v(" "), _vm.mode === 'records' && _vm.directory ? _c('EditRecords', {
    staticClass: "directory__body"
  }) : _vm._e(), _vm._v(" "), _c('RemoveModal', {
    ref: "removeModal",
    attrs: {
      "visible": _vm.visibleRemoveModal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleRemoveModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }