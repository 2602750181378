var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pos-relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.numberModel,
      expression: "numberModel"
    }],
    staticClass: "input w-100 h-100",
    attrs: {
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabledInput
    },
    domProps: {
      "value": _vm.numberModel
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.numberModel = $event.target.value;
      }, function ($event) {
        return _vm.onInput($event.target.value);
      }],
      "blur": _vm.emitBlur,
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.emitEnter($event);
      }
    }
  }), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('div')]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }