















import { Component, Vue, Prop } from 'vue-property-decorator'

import Button from '@/components/common/buttons/Button.vue'

@Component({
    components: { Button }
})

export default class ButtonCreate extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) text!: string
    @Prop({ default: false }) disabled!: boolean
}
