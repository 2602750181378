import { render, staticRenderFns } from "./DocumentLinks.vue?vue&type=template&id=382298cc&scoped=true&"
import script from "./DocumentLinks.vue?vue&type=script&lang=ts&"
export * from "./DocumentLinks.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentLinks.vue?vue&type=style&index=0&id=382298cc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "382298cc",
  null
  
)

export default component.exports