













import { Component, Vue, Ref, Prop } from 'vue-property-decorator'

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getLocaleError } from '@/tools/errors/errors'

const axios = require('axios')

@Component({})

export default class Uploader extends Vue {
    @Ref() readonly inputField!: HTMLInputElement

    // *************************************************************
    // PROPS
    @Prop({ default: '' }) userId!: string
    @Prop({ default: () => true }) beforeUpload!: (files: Array<File>, upload: (formData: FormData, address: string) => void) => void

    // *************************************************************
    // DATA PARAMS
    uploadFinished = false
    percentage = '\xa0\n'

    // *************************************************************
    // METHODS
    clickInput(): void {
        this.inputField.click()
    }

    sendFile(event: DragEvent): void {
        console.log('sendFile', event)
        // Создаем list в зависимости от того, как мы получили файлы для отправки
        let files: Array<File> = []
        if (event.dataTransfer) {
            // При выборе через drop
            files = [...event.dataTransfer.files]
        } else if (event.target) {
            // При выборе файла с компа
            const target= event.target as HTMLInputElement;
            files = [...(target.files as FileList)]
        }

        // Проверяем, не пустой ли массив files - это может быть, если пользователь в момент выбора
        // файла нажал Отмена
        if (files.length) {
            // Получаем address & FormData для отправки - это метод из props
            this.beforeUpload(files, (formData: FormData, address: string) => {
                const config: AxiosRequestConfig = {
                    onUploadProgress: progressEvent => {
                        this.percentage = Math.floor((progressEvent.loaded * 100) / progressEvent.total) + '%'
                        if (progressEvent.loaded === progressEvent.total) {
                            // Когда 100% загрузка, сбрасываем проценты и uploadFinished
                            this.uploadFinished = true
                            this.percentage = '\xa0\n'
                        }
                        // do whatever you like with the percentage complete
                        // maybe dispatch an action that will update a progress bar or something
                        // console.log('percentCompleted', progressEvent, percentCompleted)
                    },
                    headers: { 'Access-Control-Allow-Origin': '*' }
                }

                const cancelPreloaderUploader = this.$preloader('uploader', 'Загрузка')

                this.percentage = '\xa0\n'
                this.uploadFinished = false
                return axios.post(address, formData, config)
                    .then((response: AxiosResponse) =>  {
                        console.log('AxiosResponse', response)
                    })
                    .catch((error: AxiosError) => {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.warn('error.response', error.response)
                            this.$snotify.error(`Ошибка: ${getLocaleError(error.response.data)}`)
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.warn('error.request', error.request)

                            this.$snotify.error(`Ошибка: "${error.request}"`)
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.warn('Error', error.message)

                            this.$snotify.error(`Ошибка: "${error.message}"`)
                        }
                    })
                    .finally(() => cancelPreloaderUploader())
            })
        }

        // Необходимо обнулить value в uploader, иначе при повторной загрузке подряд этого же файла
        // событие @change не сработает
        this.inputField.value = ''
    }
}
