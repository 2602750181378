
















































import { Component, Prop, Vue } from 'vue-property-decorator'

import { NodeTemplate } from '@/tools/template_builder/viewData'

@Component({})

export default class WFTNode extends Vue {
    @Prop({ default: false }) root!: boolean // пометка для автора
    @Prop({ default: null }) node!: NodeTemplate // пометка для автора
    @Prop({ default: false }) pending!: boolean // пометка для автора
    @Prop({ default: false }) dynamic!: boolean // не Черновик

    // *************************************************************
    // COMPUTED
    get actor(): string {
        if (!this.node.actor) {
            return this.node.specialName
        } else if (this.node.actor.user) {
            return this.node.actor.user.name.shortName
        } else {
            return this.node.actor.unit?.name ?? ''
        }
    }
}
