





































import { Component, Emit, Prop, Vue, Model } from 'vue-property-decorator'
import { Tab } from '@/components/common/tabs/model'

@Component({})

export default class Tabs extends Vue {
    @Model('change', { type: String }) readonly value!: string

    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) tabs!: Array<Tab>
    @Prop({ default: 50 }) tabHeight!: number

    // *************************************************************
    // COMPUTED
    get widthHr(): number {
        return 100 / this.tabs.length
    }

    get leftHr(): number {
        const activeIndex = this.tabs.findIndex(t => t.id === this.value)
        return 100 / this.tabs.length * activeIndex
    }

    // *************************************************************
    // EMIT
    @Emit('change')
    emitChange(tabId: string): string {
        return tabId
    }
}
