var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wf-draft"
  }, [_c('svg', {
    staticClass: "wf-draft__svg",
    attrs: {
      "height": _vm.svgHeight,
      "width": _vm.svgWidth
    }
  }, [_vm._l(_vm.vertices.links, function (link) {
    return _c('path', {
      key: link.id,
      staticClass: "wf-draft__line",
      attrs: {
        "d": link.d
      }
    });
  }), _vm._v(" "), _vm._l(_vm.vertices.nodes, function (node) {
    return _c('DWF_ApprovalNode', {
      key: node.id,
      style: {
        transform: node.viewData.cssTranslate
      },
      attrs: {
        "node": node
      },
      on: {
        "mouseenter": function mouseenter($event) {
          _vm.selectedNode = node;
        },
        "mouseleave": _vm.nodeMouseLeave
      }
    });
  })], 2), _vm._v(" "), _vm.selectedNode ? _c('DWF_NodePopover', {
    staticClass: "wf-draft__node-popover",
    attrs: {
      "node": _vm.selectedNode
    },
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.nodePopover = true;
      },
      "mouseleave": _vm.nodePopoverMouseLeave
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }