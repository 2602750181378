import errorsLocales from '@/tools/errors/errorsLocale'

export interface GRPCStatus {
    code: number
}

export interface GrpcErrorMetaData {
    'content-type': string
    authorization: string
}

export interface GrpcError {
    message: string
    code: number
    metadata: GrpcErrorMetaData
}

function isJsonString(str: string) {
    try {
        return JSON.parse(str)
    } catch (e) {
        return str
    }
}

export function getLocaleError(error: string): string {
    return errorsLocales[error] ?? error
}

export default function (error: GrpcError): string {
    let result = 'Ошибка: '

    console.log('error!!!!!', error)
    if (error && error.message) {
        const message = isJsonString(error.message)

        if (typeof message === 'string') {
            const split = message.split('desc = ')
            console.log('split', split)

            if (split.length < 2) {
                return result + getLocaleError(message)
            }
            return result + getLocaleError(split[1])
        }

        result += errorsLocales[message.Message]
        if (message.InvolvedInName) {
            result += message.InvolvedInName
        }
        return result
    }

    return result + 'Обратитесь в техподдержку'
}
