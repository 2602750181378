var render = function () {
  var _vm$directory;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-records"
  }, [_c('div', {
    staticClass: "edit-records__header"
  }, [(_vm$directory = _vm.directory) !== null && _vm$directory !== void 0 && _vm$directory.external ? _c('ActionSelectCard', {
    staticClass: "edit-records__unit",
    attrs: {
      "placeholder": "Выбор организации",
      "entity": "unit",
      "items": _vm.units,
      "sort": "name"
    },
    model: {
      value: _vm.selectedUnitId,
      callback: function callback($$v) {
        _vm.selectedUnitId = $$v;
      },
      expression: "selectedUnitId"
    }
  }) : _vm._e(), _vm._v(" "), _c('InputSearch', {
    staticClass: "edit-records__search",
    attrs: {
      "placeholder": "Поиск элементов справочника"
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _vm._v(" "), _c('Button', {
    attrs: {
      "color": "green",
      "disabled": _vm.showAddRecord.disabled
    },
    on: {
      "click": _vm.addRecord
    }
  }, [_vm._v("\n            " + _vm._s(_vm.showAddRecord.reason || 'Добавить элемент') + "\n        ")])], 1), _vm._v(" "), _vm.directory ? _c('DirectoryRecords', {
    staticClass: "edit-records__body",
    attrs: {
      "search-text": _vm.searchText,
      "unit-id": _vm.selectedOrganisationId,
      "directory": _vm.directory,
      "show-add-record": _vm.addingRecord
    },
    on: {
      "closeAddRecord": function closeAddRecord($event) {
        _vm.addingRecord = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }