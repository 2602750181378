





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Location } from 'vue-router'

@Component({})

export default class GoBack extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: '' }) text!: string
    @Prop({ default: { name: 'root' } }) link!: Location
}
