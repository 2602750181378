import api from 'api1'

export default function ({ dispatch }, numeratorId) {
    return new Promise((resolve, reject) => {
        api.cunt.NumeratorSvcClient.remove(api.cunt.RemoveNumeratorReq({
            ID: numeratorId
        }), data => {
            console.log('::DATA::NumeratorSvcClient.remove:', data)

            dispatch('removeNumerator', {
                numeratorId: numeratorId
            })

            resolve()
        }, error => {
            (error ? console.warn : console.log)('::DONE::NumeratorSvcClient.remove:', error)
            reject(error)
        })
    })
}
