export const statuses = {
    draft: 'Черновик',
    active: 'Ознакомление',
    archived: 'Архив'
}

export const statusesArray = [
    { id: 'draft', name: 'Черновик' },
    { id: 'active', name: 'Ознакомление' },
    { id: 'archived', name: 'Архив' },
]

export const eventTypes = {
    'status_transition': {
        title: 'Изменение статуса',
        fields: {
            current: 'Новый',
            previous: 'Предыдущий'
        }
    },
    'familiarize': {
        title: 'Ознакомлен',
        fields: {
            actor: 'Пользователь'
        }
    },
    'new_members': {
        title: 'Добавлены участники',
        fields: {
            users: 'Пользователи',
            units: 'Подразделения'
        }
    }
}
