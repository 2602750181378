<template>
    <div class="support">
        <div class="support__container">
            <h1>Technical support is available only to authorized users.</h1>
            Unauthorized users: please contact us via email
            <a :href="`mailto:support@themake.rs`">support@themake.rs</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Support'
}
</script>

<style lang="stylus" scoped>
.support
    display flex
    height 100vh
    background-color #ffffff

.support__container
    margin 50px auto
</style>
