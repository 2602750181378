import api from 'api1'
import { setMe, workflowNotify, notifyTask, familiarizationNotify } from '@/tools/notification.js'
import Vue from 'vue'

import newUser from '@/model/convert/newUser'
import newDocumentType from '@/model/convert/newDocumentType'
import newNumerator from '@/model/convert/newNumerator'
import newFamiliarization from '@/model/convert/newFamiliarization'
import moment from 'moment'

import { Directory, Record } from '@/model/Directory'
import { Unit } from '@/model/Unit'
import { WorkflowEvent } from '@/model/WorkflowEvent'
import { Task } from '@/model/Task'

let userEventsStream = undefined
let userEventsStreamRestartTimeout = undefined
let activeVector = false
let preloader = undefined

function setPreloader() {
    preloader = Vue.prototype.$preloader('user_stream', 'Загрузка актуальных данных')
}

let endOfHistorical = false
let me = {}

function handleEvent(bullshit, event) {
    // console.log('EVENT_ID', event.eventid, event)
    // USER
    // UNIT
    // DOCUMENT_TYPE
    // WORKFLOW_EVENT
    // TASK
    // DIRECTORY
    // RECORD
    // NUMERATOR
    // FAMILIARIZATION

    // ********** ME **********

    if (event.eventid) {
        bullshit.commit('me/setEventId', event.eventid, { root: true })
    }

    if (event.endOfHistoricalEntities) {
        console.log('event.endOfHistoricalEntities', event.endOfHistoricalEntities, moment().format('HH:mm:ss'))
        preloader()
        endOfHistorical = true
    }

    else if (event.me) {
        const user = newUser(event.me)
        bullshit.commit('setMe', user)
        setMe(user)
        me = user
    }

    // ********** ROLES_CHANGES **********

    else if (event.RolesChange) {
        console.log('event.ROLES_CHANGE', event.RolesChange)
        setPreloader()
        bullshit.commit('me/setEventId', '', { root: true })
        bullshit.dispatch('cleanUpStates', null, { root: true })
    }

    // ********** HISTORICAL **********

    else if (event.historical) {
        // console.log('event.Historical', event.historical)
        if (event.historical.user) {
            // console.log('event.historical.user', event.historical.user)
            bullshit.dispatch('company_structure/putUser', newUser(event.historical.user), { root: true })
        } else if (event.historical.unit) {
            // console.log('event.historical.unit', event.historical.unit)
            bullshit.dispatch('company_structure/putUnit', new Unit(event.historical.unit), { root: true })
        } else if (event.historical.documentType) {
            // console.log('event.historical.documentType', event.historical.documentType)
            bullshit.dispatch('document_type/putDocumentType', newDocumentType(event.historical.documentType), { root: true })
        } else if (event.historical.workflowEvent) {
            // console.log('event.historical.workflowEvent', event.historical.workflowEvent)
            bullshit.dispatch('workflow_event/putWorkflowEvent', new WorkflowEvent(event.historical.workflowEvent), { root: true })
        } else if (event.historical.task) {
            // console.log('event.historical.task', event.historical.task)
            bullshit.dispatch('task/putTask', new Task(event.historical.task), { root: true })
        } else if (event.historical.directory) {
            // console.log('event.historical.directory', event.historical.directory)
            bullshit.dispatch('directories/putDirectory', new Directory(event.historical.directory), { root: true })
        } else if (event.historical.record) {
            // console.log('event.historical.record', event.historical.record)
            bullshit.dispatch('directories/putRecord', new Record(event.historical.record), { root: true })
        } else if (event.historical.numerator) {
            // console.log('event.historical.numerator', event.historical.numerator)
            bullshit.dispatch('numerator/putNumerator', {
                numerator: newNumerator(event.historical.numerator),
                modificationTime: event.historical.numerator.ModificationTime
            }, { root: true })
        } else if (event.historical.familiarization) {
            // console.log('event.historical.familiarization', event.historical.familiarization)
            bullshit.dispatch('familiarization/putFamiliarization', {
                familiarization: newFamiliarization(event.historical.familiarization),
            }, { root: true })
        }
    }

    // ********** CREATED **********

    else if (event.created) {
        if (event.created.user) {
            bullshit.dispatch('company_structure/putUser', newUser(event.created.user), { root: true })
        } else if (event.created.unit) {
            bullshit.dispatch('company_structure/putUnit', new Unit(event.created.unit), { root: true })
        } else if (event.created.documentType) {
            bullshit.dispatch('document_type/putDocumentType', newDocumentType(event.created.documentType), { root: true })
        } else if (event.created.workflowEvent) {
            bullshit.dispatch('workflow_event/putWorkflowEvent', new WorkflowEvent(event.created.workflowEvent), { root: true })
            workflowNotify(new WorkflowEvent(event.created.workflowEvent))
        } else if (event.created.task) {
            const task = new Task(event.created.task)
            bullshit.dispatch('task/putTask', task, { root: true })
            notifyTask(task)
        } else if (event.created.directory) {
            bullshit.dispatch('directories/putDirectory', new Directory(event.created.directory), { root: true })
        } else if (event.created.record) {
            bullshit.dispatch('directories/putRecord', new Record(event.created.record), { root: true })
        } else if (event.created.numerator) {
            bullshit.dispatch('numerator/putNumerator', {
                numerator: newNumerator(event.created.numerator),
                modificationTime: event.created.numerator.ModificationTime
            }, { root: true })
        } else if (event.created.familiarization) {
            bullshit.dispatch('familiarization/putFamiliarization', {
                familiarization: newFamiliarization(event.created.familiarization),
            }, { root: true })
        }
    }

    // ********** UPDATED **********

    else if (event.updated) {
        if (event.updated.user) {
            bullshit.dispatch('company_structure/putUser', newUser(event.updated.user), { root: true })
        } else if (event.updated.unit) {
            bullshit.dispatch('company_structure/putUnit', new Unit(event.updated.unit), { root: true })
        } else if (event.updated.documentType) {
            bullshit.dispatch('document_type/putDocumentType', newDocumentType(event.updated.documentType), { root: true })
        } else if (event.updated.workflowEvent) {
            bullshit.dispatch('workflow_event/putWorkflowEvent', new WorkflowEvent(event.updated.workflowEvent), { root: true })
        } else if (event.updated.task) {
            const task = new Task(event.updated.task)
            bullshit.dispatch('task/putTask', task, { root: true })
            notifyTask(task)
        } else if (event.updated.directory) {
            bullshit.dispatch('directories/putDirectory', new Directory(event.updated.directory), { root: true })
        } else if (event.updated.record) {
            bullshit.dispatch('directories/putRecord', new Record(event.updated.record), { root: true })
        } else if (event.updated.numerator) {
            bullshit.dispatch('numerator/putNumerator', {
                numerator: newNumerator(event.updated.numerator),
                modificationTime: event.updated.numerator.ModificationTime
            }, { root: true })
        } else if (event.updated.familiarization) {
            const familiarization = newFamiliarization(event.updated.familiarization)
            const existingFamiliarization = bullshit.rootGetters['familiarization/getFamiliarizationById'](familiarization.id)

            if (existingFamiliarization.status === 'draft' && familiarization.status === 'active' && me.id !== familiarization.actor.user.id) {
                familiarizationNotify(familiarization)
            }

            bullshit.dispatch('familiarization/putFamiliarization', {
                familiarization,
            }, { root: true })
        }
    }

    // ********** DELETED **********

    else if (event.deleted) {
        // console.log('event.deleted', event.deleted)
        if (event.deleted.user) {
            bullshit.commit('company_structure/removeUser', event.deleted.user, { root: true })
        } else if (event.deleted.unit) {
            bullshit.dispatch('company_structure/removeUnit', event.deleted.unit, { root: true })
        } else if (event.deleted.documentType) {
            bullshit.dispatch('document_type/removeDocumentType', event.deleted.documentType, { root: true })
        } else if (event.deleted.workflowEvent) {
            bullshit.dispatch('workflow_event/removeWorkflowEvent', event.deleted.workflowEvent, { root: true })
        } else if (event.deleted.task) {
            bullshit.dispatch('task/removeTask', event.deleted.task, { root: true })
        } else if (event.deleted.directory) {
            bullshit.dispatch('directories/removeDirectory', event.deleted.directory, { root: true })
        } else if (event.deleted.record) {
            bullshit.dispatch('directories/removeRecord', event.deleted.record, { root: true })
        } else if (event.deleted.numerator) {
            bullshit.dispatch('numerator/removeNumerator', {
                numeratorId: event.deleted.numerator
            }, { root: true })
        } else if (event.deleted.familiarization) {
            bullshit.dispatch('familiarization/removeFamiliarization', {
                familiarizationId: event.deleted.familiarization
            }, { root: true })
        }
    } else if (!event.ping) {
        console.log('DATA', event)
    }
}

function openUserEventStream(bullshit) {
    if (!activeVector) {
        return
    }

    if (!endOfHistorical) {
        setPreloader()
    }

    navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Received a message from service worker: ', event.data, event)
    })

    userEventsStream = api.cunt.UserEventsSvcClient.eventStream(
        api.cunt.EventStreamReq({
            EventID: bullshit.getters.getEventId
        }),
        data => {
            data = api.cunt.UserEvent(data)
            // console.log("::DATA::UserEventsSvc_newClient:", data)

            handleEvent(bullshit, data)
        },
        error => {
            (error ? console.warn : console.log)('::DONE::UserEventsStream:', error, moment().format('HH:mm:ss'))

            if (error?.message.includes('Resume of change stream was not possible, as the resume point may no longer be in the oplog')) {
                bullshit.commit('me/setEventId', '', { root: true })
            }
            // console.log('EVENT_ID', bullshit.getters.getEventId)

            preloader()

            // FIXME
            if (!!error && error.code === 16 && error.message === 'unauthenticated') {
                console.warn('unauthenticated => logout')
                bullshit.dispatch('logOut')
            }

            userEventsStream = undefined

            if (activeVector) {
                userEventsStreamRestartTimeout = setTimeout(() => {
                    openUserEventStream(bullshit)
                }, 1000)
            }
        },
        status => {
            // console.log("::STATUS::UserEventsStream:", status)
            if (status.code === 16 && status.details === 'unauthenticated') {
                console.warn('unauthenticated => logout')
                bullshit.dispatch('logOut')
            }
        }, {
            deadline: 0
        })
}

export default function ({ commit, dispatch, getters, rootGetters }, active ) {
    activeVector = active

    if (userEventsStream) {
        userEventsStream.cancel()
        userEventsStream = undefined
    }

    if (userEventsStreamRestartTimeout) {
        clearTimeout(userEventsStreamRestartTimeout)
        userEventsStreamRestartTimeout = undefined
    }

    if (activeVector) {
        openUserEventStream({ commit, dispatch, getters, rootGetters })
    }
}
