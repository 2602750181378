<template>
    <div
        v-if="familiarization"
        class="familiarization-view__grid mt-5"
    >
        <div class="familiarization-view__text familiarization-view__title">
            {{ familiarization.name }}
        </div>
        <div>
            <div>
                Дата создания:
                <span class="familiarization__item">{{ familiarization.creationTimeLocalizedShortString }}</span>
            </div>
            <div>
                Статус:
                <span class="familiarization__item">{{ statuses[familiarization.status] }}</span>
            </div>
            <div>
                Автор:
                <span class="familiarization__item">{{ familiarization.actor.user.name.shortName }}</span>
            </div>
            <div v-if="familiarization.until">
                Ознакомиться до:
                <span
                    :style="{color: familiarization.until < timeNow ? 'red': 'black'}"
                >{{ familiarization.untilLocalizedShortString }}
                </span>
            </div>
        </div>

        <div>
            <EditorViewer
                v-if="familiarization.description"
                :value="familiarization.description"
                class="familiarization-view__description"
            />
            <h3 v-if="familiarization.documents.length">
                Документы:
            </h3>
            <div>
                <div
                    v-for="doc in familiarization.documents"
                    :key="doc.id"
                    class="familiarization__document"
                    @click="openDocument(doc)"
                >
                    <div>{{ typeNames[doc.type] }}: <span class="pink-1">{{ doc.name }}</span></div>
                </div>
            </div>

            <Files
                v-if="familiarization.files.length"
                class="familiarization__margin"
                :files="familiarization.files"
                :document-id="familiarization.id"
                :can-add-files="false"
                :can-change="false"
                group-name="familiarization_attachment"
                name="familiarization"
            />
        </div>
        <div>
            <div v-if="meFamiliarized">
                <ButtonGreen
                    v-if="!meFamiliarized.status"
                    :disabled="familiarization.status !== 'active'"
                    text="Я ознакомлен"
                    class="mb-3 w-100"
                    @click="familiarize"
                />
                <div class="mb-1 flex-row-center">
                    <div
                        class="familiarization__check"
                        :class="{ 'familiarization__check_checked': meFamiliarized.status}"
                    />
                    <EmployeeCard
                        :unit-user="meFamiliarized"
                        :small="true"
                        type="system"
                    />
                </div>
            </div>
            <div class="mt-2">
                <div
                    v-for="user in users"
                    :key="user.id"
                    class="mb-1 flex-row-center"
                >
                    <div
                        class="familiarization__check"
                        :class="{ 'familiarization__check_checked': user.status}"
                    />
                    <EmployeeCard
                        :unit-user="user"
                        :small="true"
                        type="system"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Files from '@/components/common/files/Files'
import FamiliarizationMixin from '@/components/familiarization/FamiliarizationMixin'
import EmployeeCard from '@/components/common/cards/EmployeeCard'
import ButtonGreen from '@/components/common/buttons/ButtonGreen'
import EditorViewer from '@/components/common/editor/EditorViewer'
import { statuses } from '@/components/familiarization/util'
import errors from '@/tools/errors/errors.ts'

export default {
    components: { Files, EmployeeCard, ButtonGreen, EditorViewer },
    mixins: [FamiliarizationMixin],
    data() {
        return {
            statuses,
            timeNow: Date.now()
        }
    },
    computed: {
        ...mapGetters('me', ['getMe']),
        ...mapGetters('company_structure', ['getUserById']),
        meFamiliarized() {
            const me = this.familiarization.сheckList.find(u => u.user.id === this.getMe.id)
            if (me) {
                return { ...this.getMe, status: me.status, when: me.when }
            }
            return undefined
        },
        users() {
            const users = []
            // Сначала - те, кто уже ознакомился
            const familiarized = this.familiarization.сheckList.filter(u => u.user.id !== this.getMe.id && u.status)
            familiarized.forEach(u => {
                const user = this.getUserById(u.user.id)
                if (user) {
                    users.push({ ...user, status: u.status, when: u.when })
                }
            })
            // В конце - те, кто пока не ознакомился
            const notFamiliarized = this.familiarization.сheckList.filter(u => u.user.id !== this.getMe.id && !u.status)
            notFamiliarized.forEach(u => {
                const user = this.getUserById(u.user.id)
                if (user) {
                    users.push({ ...user, status: u.status, when: u.when })
                }
            })
            return users
        }
    },
    methods: {
        ...mapActions('familiarization', ['callFamiliarize']),
        familiarize() {
            if (this.familiarization.status !== 'active') {
                return
            }
            this._cancelPreloaderFamiliarize = this.$preloader('familiarize', 'Сохранение')
            this.callFamiliarize({
                id: this.familiarization.id
            }).then(() => this._cancelPreloaderFamiliarize())
                .catch(
                    error => {
                        this._cancelPreloaderFamiliarize()
                        this.$snotify.error(`Ошибка: "${errors(error) || error.message}"`, {
                            timeout: 0
                        })
                    }
                )
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/colors.styl'

.familiarization-view__grid
    display grid
    grid-template-columns 1fr 250px
    grid-column-gap 10px
    grid-row-gap 20px

.familiarization-view__title
    font-size 25px
    font-weight bold

.familiarization-view__text
    word-break break-all
    word-wrap break-word

.familiarization-view__description
    padding 5px
    border 1px solid #ccc
    box-shadow 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)

.familiarization__check
    position relative
    margin-right 15px
    height 20px
    width 20px
    border 2px solid #d9d9d9
    box-sizing border-box
    transition: .4s cubic-bezier(.25,.8,.25,1)
    &.familiarization__check_checked
        border-color $green-2
        &:after
            box-sizing inherit
            content: ''
            position: absolute
            transition: transform .25s ease
            width: 6px
            height: 13px
            top: 0
            left: 5px
            z-index: 6
            border: 2px solid $green-2
            border-top: 0
            border-left: 0
            opacity: 1
            transform rotate(45deg) scale3d(1,1,1)
</style>
