import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { TaskState } from '@/store/task/task'

import { Task } from '@/model/Task'

const TaskGetters: GetterTree<TaskState, RootState> = {
    getTasks: (state: TaskState): Array<Task> => state.tasks,

    getTaskById: (state: TaskState) => (id: string): Task | null => state.tasksMap[id],

    getByDocument: (state: TaskState) => (documentId: string): Array<Task> => state.tasks.filter((t: Task) => t.document && t.document.id === documentId),

    /** Если пользователь assignee || confirmer */
    getByUser: (state: TaskState) => (userId: string): Array<Task> => {
        return state.tasks.filter((t: Task) => t.assignee.user?.id === userId || (t.confirmer && t.confirmer.user?.id === userId))
    },

    getByAuthor: (state: TaskState) => (userId: string): Array<Task> => state.tasks.filter((t: Task) => t.author.user?.id === userId),

    /**
     * Задача должна быть не закрыта и
     * либо не выполнена либо требует подтверждения и не подтверждена
     * */
    getNotImplemented: (state: TaskState): Array<Task> => state.tasks.filter((t: Task) => !t.closed && (!t.completed || (!t.confirmed && t.confirmer)))
}

export default TaskGetters
