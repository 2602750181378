var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DocumentEventBase', {
    attrs: {
      "event": _vm.event,
      "name": "Отклонено"
    }
  }, [_c('div', {
    attrs: {
      "slot": "fields"
    },
    slot: "fields"
  }, [_c('div', [_vm._v("Крайний:")]), _vm._v(" "), _vm._l(_vm.victims, function (v) {
    return _c('div', {
      key: v.elementId,
      staticClass: "fw-600"
    }, [_vm._v("\n            " + _vm._s(v.fullName) + "\n        ")]);
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "document-event-rejected__reason mt-1",
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_vm._v("\n        Причина: "), _c('span', {
    staticClass: "fw-600"
  }, [_vm._v(_vm._s(_vm.event.fields.reason))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }