























import { Component, Prop, Vue } from 'vue-property-decorator'

import { Action } from 'vuex-class'
import getError, { GrpcError } from '@/tools/errors/errors'
import { cuntPB } from 'api'

import ButtonViolet from '@/components/common/buttons/ButtonViolet.vue'
import DWF_RejectedReason from '@/components/document/workflow/DWF_RejectedReason.vue'

@Component<ApprovalActions>({
    components: {
        ButtonViolet,
        DWF_RejectedReason
    }
})

export default class ApprovalActions extends Vue {
    // *************************************************************
    // PROPS
    @Prop({ default: () => []}) checkedApprovals!: Array<string>

    // *************************************************************
    // DATA PARAMS
    visibleReject = false

    // *************************************************************
    // COMPUTED
    get documentId(): string {
        return this.$route.params.id
    }

    // *************************************************************
    // METHODS
    @Action('document/callAccept')
    callAccept!: (params: cuntPB.WorkflowApprovalAcceptreq) => Promise<void>

    @Action('document/callDropTo')
    callDropTo!: (params: cuntPB.WorkflowApprovalDroptoreq) => Promise<void>

    /** Вызов этого метода возможен только, если массив checkedApprovals имеет одно значение */
    accept(): void {
        const cancelPreloaderAcceptDocument = this.$preloader('accept_document', 'Согласование документа')

        this.callAccept({
            Document: this.documentId,
            ID: this.checkedApprovals[0]
        })
            .then(() => {
                this.$snotify.success('Согласовано!')
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderAcceptDocument())
    }

    showReason(): void {
        this.visibleReject = true
    }

    drop(reason: string): void {
        const cancelPreloaderDropToDocument = this.$preloader('drop_to_document', 'Отклонение документа')

        // Находим первый в массиве checkedApprovals элемент - это тот, кто отклоняет наше согласование
        // Остальные - это те, на кого отклоняется документ
        this.callDropTo({
            Document: this.documentId,
            Approval: this.checkedApprovals[0],
            DropTo: this.checkedApprovals.filter((x, index) => index !== 0),
            Reason: reason
        })
            .then(() => {
                this.visibleReject = false
                this.$snotify.success('Отклонено!')
            })
            .catch((error: GrpcError) => {
                this.$snotify.error(getError(error))
            })
            .finally(() => cancelPreloaderDropToDocument())
    }
}
