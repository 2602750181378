var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g({
    staticClass: "employee-card",
    class: {
      'employee-card_small': _vm.small
    }
  }, _vm.$listeners), [_c('Popover', {
    attrs: {
      "shift-ypopover": 10,
      "arrow": _vm.arrow
    }
  }, [_c('div', {
    staticClass: "employee-card__photo",
    class: {
      'employee-card__photo_small': _vm.small
    },
    style: _vm.styleObjectType,
    attrs: {
      "slot": "host"
    },
    slot: "host"
  }), _vm._v(" "), _vm.popover ? _c('div', {
    staticClass: "employee-card__popover",
    attrs: {
      "slot": "popover"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    },
    slot: "popover"
  }, [_vm._v("\n            Ф.И.О.: " + _vm._s(_vm.unitUser.name.fullName) + " "), _c('br'), _vm._v("\n            Должность: " + _vm._s(_vm.unitUser.positionName) + " "), _c('br'), _vm._v("\n            Телефон: " + _vm._s(_vm.unitUser.phone) + " "), _c('br'), _vm._v("\n            Email: " + _vm._s(_vm.unitUser.email) + "\n        ")]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "employee-card__info",
    on: {
      "click": function click($event) {
        return _vm.$emit('changeEmployee');
      }
    }
  }, [_c('div', {
    staticClass: "employee-card__info_full-name"
  }, [_vm._v("\n            " + _vm._s(_vm.unitUser.name.shortName) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "employee-card__info_post"
  }, [_vm._v("\n            " + _vm._s(_vm.unitUser.positionName) + "\n        ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }