/**
 * @fileoverview gRPC-Web generated client stub for api.themakers.identity
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.api = {};
proto.api.themakers = {};
proto.api.themakers.identity = require('./identity_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.identity.IdentityClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.identity.IdentityPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.identity.IdentityClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.identity.IdentityClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq,
 *   !proto.api.themakers.identity.VerifierDetailsResp>}
 */
const methodInfo_Identity_ListSupportedIdentitiesAndVerifiers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.VerifierDetailsResp,
  /** @param {!proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.VerifierDetailsResp.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.VerifierDetailsResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.VerifierDetailsResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.listSupportedIdentitiesAndVerifiers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/ListSupportedIdentitiesAndVerifiers',
      request,
      metadata,
      methodInfo_Identity_ListSupportedIdentitiesAndVerifiers,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.ListSupportedIdentitiesAndVerifiersReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.VerifierDetailsResp>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.listSupportedIdentitiesAndVerifiers =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.listSupportedIdentitiesAndVerifiers(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.StatusReq,
 *   !proto.api.themakers.identity.Status>}
 */
const methodInfo_Identity_CheckStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.Status,
  /** @param {!proto.api.themakers.identity.StatusReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.Status.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.StatusReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.checkStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/CheckStatus',
      request,
      metadata,
      methodInfo_Identity_CheckStatus,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.StatusReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.Status>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.checkStatus =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.checkStatus(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.LogoutReq,
 *   !proto.api.themakers.identity.Status>}
 */
const methodInfo_Identity_Logout = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.Status,
  /** @param {!proto.api.themakers.identity.LogoutReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.Status.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.LogoutReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/Logout',
      request,
      metadata,
      methodInfo_Identity_Logout,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.LogoutReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.Status>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.logout =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.logout(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.UserMergeReq,
 *   !proto.api.themakers.identity.UserMergeResp>}
 */
const methodInfo_Identity_UserMerge = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.UserMergeResp,
  /** @param {!proto.api.themakers.identity.UserMergeReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.UserMergeResp.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.UserMergeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.UserMergeResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.UserMergeResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.userMerge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/UserMerge',
      request,
      metadata,
      methodInfo_Identity_UserMerge,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.UserMergeReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.UserMergeResp>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.userMerge =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.userMerge(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.StartSignInReq,
 *   !proto.api.themakers.identity.Status>}
 */
const methodInfo_Identity_StartSignIn = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.Status,
  /** @param {!proto.api.themakers.identity.StartSignInReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.Status.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.StartSignInReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.startSignIn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/StartSignIn',
      request,
      metadata,
      methodInfo_Identity_StartSignIn,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.StartSignInReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.Status>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.startSignIn =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.startSignIn(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.StartSignUpReq,
 *   !proto.api.themakers.identity.Status>}
 */
const methodInfo_Identity_StartSignUp = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.Status,
  /** @param {!proto.api.themakers.identity.StartSignUpReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.Status.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.StartSignUpReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.startSignUp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/StartSignUp',
      request,
      metadata,
      methodInfo_Identity_StartSignUp,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.StartSignUpReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.Status>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.startSignUp =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.startSignUp(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.StartAttachReq,
 *   !proto.api.themakers.identity.Status>}
 */
const methodInfo_Identity_StartAttach = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.Status,
  /** @param {!proto.api.themakers.identity.StartAttachReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.Status.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.StartAttachReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.startAttach =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/StartAttach',
      request,
      metadata,
      methodInfo_Identity_StartAttach,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.StartAttachReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.Status>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.startAttach =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.startAttach(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.CancelAuthenticationReq,
 *   !proto.api.themakers.identity.Status>}
 */
const methodInfo_Identity_CancelAuthentication = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.Status,
  /** @param {!proto.api.themakers.identity.CancelAuthenticationReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.Status.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.CancelAuthenticationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.cancelAuthentication =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/CancelAuthentication',
      request,
      metadata,
      methodInfo_Identity_CancelAuthentication,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.CancelAuthenticationReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.Status>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.cancelAuthentication =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.cancelAuthentication(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq,
 *   !proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp>}
 */
const methodInfo_Identity_ListMyIdentitiesAndVerifiers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp,
  /** @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.listMyIdentitiesAndVerifiers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/ListMyIdentitiesAndVerifiers',
      request,
      metadata,
      methodInfo_Identity_ListMyIdentitiesAndVerifiers,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.ListMyIdentitiesAndVerifiersResp>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.listMyIdentitiesAndVerifiers =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.listMyIdentitiesAndVerifiers(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.StartReq,
 *   !proto.api.themakers.identity.StartResp>}
 */
const methodInfo_Identity_Start = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.StartResp,
  /** @param {!proto.api.themakers.identity.StartReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.StartResp.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.StartReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.StartResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.StartResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.start =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/Start',
      request,
      metadata,
      methodInfo_Identity_Start,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.StartReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.StartResp>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.start =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.start(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.VerifyReq,
 *   !proto.api.themakers.identity.Status>}
 */
const methodInfo_Identity_Verify = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.Status,
  /** @param {!proto.api.themakers.identity.VerifyReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.Status.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.VerifyReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityClient.prototype.verify =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.Identity/Verify',
      request,
      metadata,
      methodInfo_Identity_Verify,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.VerifyReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.Status>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPromiseClient.prototype.verify =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.verify(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.identity.IdentityPrivateClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

  /**
   * @private @const {?Object} The credentials to be used to connect
   *    to the server
   */
  this.credentials_ = credentials;

  /**
   * @private @const {?Object} Options for the client
   */
  this.options_ = options;
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.api.themakers.identity.IdentityPrivatePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!proto.api.themakers.identity.IdentityPrivateClient} The delegate callback based client
   */
  this.delegateClient_ = new proto.api.themakers.identity.IdentityPrivateClient(
      hostname, credentials, options);

};


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.api.themakers.identity.LoginAsReq,
 *   !proto.api.themakers.identity.LoginAsResp>}
 */
const methodInfo_IdentityPrivate_LoginAs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.api.themakers.identity.LoginAsResp,
  /** @param {!proto.api.themakers.identity.LoginAsReq} request */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.themakers.identity.LoginAsResp.deserializeBinary
);


/**
 * @param {!proto.api.themakers.identity.LoginAsReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.api.themakers.identity.LoginAsResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.themakers.identity.LoginAsResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPrivateClient.prototype.loginAs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.themakers.identity.IdentityPrivate/LoginAs',
      request,
      metadata,
      methodInfo_IdentityPrivate_LoginAs,
      callback);
};


/**
 * @param {!proto.api.themakers.identity.LoginAsReq} request The
 *     request proto
 * @param {!Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.themakers.identity.LoginAsResp>}
 *     The XHR Node Readable Stream
 */
proto.api.themakers.identity.IdentityPrivatePromiseClient.prototype.loginAs =
    function(request, metadata) {
  return new Promise((resolve, reject) => {
    this.delegateClient_.loginAs(
      request, metadata, (error, response) => {
        error ? reject(error) : resolve(response);
      });
  });
};


module.exports = proto.api.themakers.identity;

