import { ActionTree } from 'vuex'
import { TosserState } from '@/store/tosser/tosser'
import { RootState } from '@/store'
import { cuntPB, service } from 'api'

import { TosserRule, TosserTerm } from '@/model/Tosser'
import { ActorIds } from '@/model/Actor'

const TosserActions: ActionTree<TosserState, RootState> = {
    async callGetTosser({ dispatch }, unitId: string) {
        const data: cuntPB.UnitTosser = await service.unit.GetTosser({ UnitID: unitId })
        console.log('::DATA::UnitSvcClient.getTosser:', data)

        dispatch('putRules', data.Rules.map((rule: cuntPB.TosserRule) => new TosserRule(rule, unitId)))
        // Убираем пометку об изменениях в тоссере
        dispatch('setTosserChange', false)
    },

    async callSetTosser(ctx, params: { unitId: string, docTypeId: string, rules: Array<TosserRule> }) {
        await service.unit.SetTosser({
            UnitID: params.unitId,
            DocTypeID: params.docTypeId,
            Rules: params.rules.map((rule: TosserRule) => {
                return {
                    DocumentType: rule.documentType,
                    Terms: rule.terms.map((t: TosserTerm) => {
                        return {
                            Requisite: t.requisite,
                            Operator: t.operator,
                            Value: t.value
                        }
                    }),
                    Actor: rule.autoAccept ? undefined : { UserID: rule.actor?.userId ?? '', UnitID: rule.actor?.unitId ?? '' },
                    AutoAccept: rule.autoAccept
                }
            })
        })
    },

    putRules({ commit }, rules: Array<TosserRule>) {
        commit('putRules', rules)
    },

    setTosserChange({ commit }, tosserChange: boolean) {
        commit('setTosserChange', tosserChange)
    },

    addNewRule({ commit }, params: { unitId: string, docTypeId: string }) {
        const rule = new TosserRule({
            DocumentType: params.docTypeId,
            Actor: { UserID: '', UnitID: '' },
            AutoAccept: false,
            Terms: [{ Requisite: '', Operator: '', Value: '' }]
        }, params.unitId)

        commit('addNewRule', rule)
    },

    removeRule({ commit, getters }, ruleId: string) {
        const ruleIndex = getters.getRules.findIndex((rule: TosserRule) => rule.id === ruleId)
        commit('removeRule', ruleIndex)
    },

    changeRuleActor({ commit, getters }, params: { ruleId: string, actor: ActorIds, autoAccept: boolean }) {
        const ruleIndex = getters.getRules.findIndex((rule: TosserRule) => rule.id === params.ruleId)
        if (ruleIndex === -1) {
            console.error('changeRuleActor - not found rule by index', params.ruleId)
        } else {
            commit('changeRuleActor', {
                ruleIndex: ruleIndex,
                actor: params.actor,
                autoAccept: params.autoAccept
            })
        }
    },

    moveRuleDown({ commit, getters }, ruleId: string) {
        const ruleIndex = getters.getRules.findIndex((rule: TosserRule) => rule.id === ruleId)
        if (ruleIndex === -1) {
            console.error('cannot find tosser rule:: moveRuleDown: ruleId: ', ruleId)
        } else {
            commit('moveRuleDown', ruleIndex)
        }
    },

    moveRuleUp({ commit, getters }, ruleId: string) {
        const ruleIndex = getters.getRules.findIndex((rule: TosserRule) => rule.id === ruleId)
        if (ruleIndex === -1) {
            console.error('cannot find tosser rule:: moveRuleUp: ruleId: ', ruleId)
        } else {
            commit('moveRuleUp', ruleIndex)
        }
    },

    ////////////////////////////////////////////////////////////////
    ////            ////////////////////////////////////////////////
    ////  TERMS     ////////////////////////////////////////////////
    ////            ////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    addNewTerm({ commit, getters }, ruleId: string) {
        const ruleIndex = getters.getRules.findIndex((rule: TosserRule) => rule.id === ruleId)
        if (ruleIndex === -1) {
            console.error('cannot find tosser rule:: addNewTerm: ruleId: ', ruleId)
        } else {
            commit('addNewTerm', {
                ruleIndex: ruleIndex,
                term: new TosserTerm({ Requisite: '', Operator: '', Value: '' })
            })
        }
    },

    changeRuleTerm({ commit, getters }, params: { ruleId: string, term: TosserTerm }) {
        const ruleIndex = getters.getRules.findIndex((rule: TosserRule) => rule.id === params.ruleId)
        if (ruleIndex === -1) {
            console.error('cannot find tosser rule:: changeRuleTerm: ruleId: ', params.ruleId)
        } else {
            const termIndex = getters.getRules[ruleIndex].terms.findIndex((t: TosserTerm) => t.id === params.term.id)
            if (termIndex === -1) {
                console.error('cannot find tosser term:: changeRuleTerm : temId: ', params.term.id)
            } else {
                commit('changeRuleTerm', {
                    ruleIndex,
                    termIndex,
                    term: params.term
                })
            }
        }
    },

    removeRuleTerm({ commit, getters }, params: { ruleId: string, termId: string }) {
        const ruleIndex = getters.getRules.findIndex((rule: TosserRule) => rule.id === params.ruleId)
        if (ruleIndex === -1) {
            console.error('cannot find tosser rule:: removeRuleTerm : ruleId: ', params.ruleId)
        } else {
            const termIndex = getters.getRules[ruleIndex].terms.findIndex((t: TosserRule) => t.id === params.termId)
            if (termIndex === -1) {
                console.error('cannot find tosser term:: removeRuleTerm : temId: ', params.termId)
            } else {
                commit('removeRuleTerm', {
                    ruleIndex,
                    termIndex
                })
            }
        }
    }
}

export default TosserActions
