var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-authentication"
  }, [_c('div', {
    staticClass: "page-subtitle mb-3 text-center"
  }, [_vm._v("\n        Аутентификация:\n    ")]), _vm._v(" "), _c('div', {
    key: "table",
    staticClass: "user-authentication__table-container"
  }, [_c('table', {
    staticClass: "user-authentication__table"
  }, [_c('tbody', [_vm._l(_vm.list.phones, function (item) {
    return _c('tr', {
      key: item.identity
    }, [_c('td', [_vm._v("Номер телефона")]), _vm._v(" "), _c('td', {
      staticClass: "flex-row-center"
    }, [_c('div', {
      key: "identity_empty",
      staticClass: "flex-1"
    }, [_vm._v("\n                            " + _vm._s(item.identity || 'Не установлен') + "\n                        ")])])]);
  }), _vm._v(" "), _vm._l(_vm.list.emails, function (item) {
    return _c('tr', {
      key: item.identity
    }, [_c('td', [_vm._v("Email")]), _vm._v(" "), _c('td', {
      staticClass: "flex-row-center"
    }, [_c('div', {
      key: "identity_empty",
      staticClass: "flex-1"
    }, [_vm._v("\n                            " + _vm._s(item.identity || 'Не установлен') + "\n                        ")])])]);
  })], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }