import { render, staticRenderFns } from "./Familiarizations.vue?vue&type=template&id=2b835dd8&scoped=true&"
import script from "./Familiarizations.vue?vue&type=script&lang=js&"
export * from "./Familiarizations.vue?vue&type=script&lang=js&"
import style0 from "./Familiarizations.vue?vue&type=style&index=0&id=2b835dd8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b835dd8",
  null
  
)

export default component.exports