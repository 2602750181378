var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-row-center justify-center p-3"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('FAIcon', {
    staticClass: "empty-list__icon",
    attrs: {
      "icon": "database"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "my-3 fs-20"
  }, [_vm._v("\n            Нет данных\n        ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }