


























import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator'

import CircleSpinner from '@/components/common/spinners/CircleSpinner.vue'

@Component({
    components: { CircleSpinner }
})

export default class Checkbox extends Vue {
    @Model('change', { type: Boolean }) readonly checked!: boolean

    // *************************************************************
    // PROPS
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: false }) loading!: boolean
    @Prop({ default: '' }) label!: string

    // *************************************************************
    // COMPUTED
    get classes(): { [key: string]: boolean } {
        return {
            'checkbox-disabled': this.disabled,
            'checkbox-loading': this.loading,
            'checkbox-active': this.checked
        }
    }

    get checkboxDisabled(): boolean {
        return this.disabled || this.loading
    }

    // *************************************************************
    // METHODS
    changeCheck(): void {
        if (!this.checkboxDisabled) {
            this.emitChange()
        }
    }

    // *************************************************************
    // EMIT
    @Emit('change')
    emitChange(): boolean {
        return !this.checked
    }
}
