import { MutationTree } from 'vuex'
import { TosserState } from '@/store/tosser/tosser'
import Vue from 'vue'

import { TosserRule, TosserTerm } from '@/model/Tosser'
import { ActorIds } from '@/model/Actor'

const TosserMutations: MutationTree<TosserState> = {
    putRules(state: TosserState, rules: Array<TosserRule>) {
        state.rules = rules
    },

    setTosserChange(state: TosserState, tosserChange: boolean) {
        state.tosserChange = tosserChange
    },

    addNewRule(state: TosserState, rule: TosserRule) {
        state.rules.push(rule)
    },

    removeRule(state: TosserState, ruleIndex: number) {
        state.rules.splice(ruleIndex, 1)
    },

    changeRuleActor(state: TosserState,  params: { ruleIndex: number, actor: ActorIds, autoAccept: boolean }) {
        state.rules[params.ruleIndex].actor = params.actor
        state.rules[params.ruleIndex].autoAccept = params.autoAccept
    },

    moveRuleDown(state: TosserState, ruleIndex: number) {
        const ruleA = state.rules[ruleIndex]
        const ruleB = state.rules[ruleIndex + 1]
        // Меняем местами rules
        Vue.set(state.rules, ruleIndex, ruleB)
        Vue.set(state.rules, ruleIndex + 1, ruleA)
    },

    moveRuleUp(state: TosserState, ruleIndex: number) {
        const ruleA = state.rules[ruleIndex]
        const ruleB = state.rules[ruleIndex - 1]
        // Меняем местами rules
        Vue.set(state.rules, ruleIndex, ruleB)
        Vue.set(state.rules, ruleIndex - 1, ruleA)
    },

    ////////////////////////////////////////////////////////////////
    ////            ////////////////////////////////////////////////
    ////  TERMS     ////////////////////////////////////////////////
    ////            ////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////
    addNewTerm(state: TosserState, params: { ruleIndex: number, term: TosserTerm }) {
        state.rules[params.ruleIndex].terms.push(params.term)
    },

    changeRuleTerm(state: TosserState, params: { ruleIndex: number, termIndex: number, term: TosserTerm }) {
        Vue.set(state.rules[params.ruleIndex].terms, params.termIndex, params.term)
    },

    removeRuleTerm(state: TosserState, params: { ruleIndex: number, termIndex: number }) {
        state.rules[params.ruleIndex].terms.splice(params.termIndex, 1)
    }
}

export default TosserMutations
